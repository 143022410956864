// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link, Text } from '@plesk/ui-library';

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ResetDataLinkProps = {
    onReset: () => void;
};
export const ResetDataLink = ({ onReset }: ResetDataLinkProps) => {
    const translate = useTranslate('security-dashboard.FilteredDataListTitle');
    return (
        <Link onClick={onReset} component="span">
            <Text fontSize="sm">{translate('showAll')}</Text>
        </Link>
    );
};
