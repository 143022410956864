// Copyright 2024. WebPros International GmbH. All rights reserved.

import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';

export const useLicenseIsActive = () => {
    const { data: license, isLoading } = useLicenseQuery();
    if (isLoading || license === undefined) {
        return undefined;
    }
    // TODO: temporary solution, remove "license === null" when trial is turned on
    return license === null || license.status === 'active';
};
