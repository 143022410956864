// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { Drawer } from '@plesk/ui-library';
import TasksList from '@platform360/security-dashboard/web/components/GlobalTaskManagerDrawer/TasksList';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import useFilter from '@platform360/libs/shared-web/helpers/useFilter';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    OperationsStatusFilter,
    OperationsTypeFilter,
    FILTER_ID_OPERATION_STATUS,
    FILTER_ID_OPERATION_TYPE,
} from '@platform360/security-dashboard/web/types';

export type GlobalTaskManagerDrawerProps = ClosableProps;

const GlobalTaskManagerDrawer = ({ isOpen, onClose, ...props }: GlobalTaskManagerDrawerProps) => {
    const translate = useTranslate('security-dashboard.GlobalTaskManagerDrawer');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const operationsStatusFilter = useFilter<OperationsStatusFilter>(FILTER_ID_OPERATION_STATUS);
    const operationsTypeFilter = useFilter<OperationsTypeFilter>(FILTER_ID_OPERATION_TYPE);

    const { resetFilter: resetOperationsStatusFilter } = operationsStatusFilter;
    const { resetFilter: resetOperationsTypeFilter } = operationsTypeFilter;

    const handleClose = () => {
        onClose();
        resetOperationsStatusFilter();
        resetOperationsTypeFilter();
        analyticsEvents.wpTaskManagerClosed();
    };

    return (
        <Drawer
            title={translate('title')}
            size="md"
            isOpen={isOpen}
            onClose={handleClose}
            {...props}
        >
            <TasksList
                operationsStatusFilter={operationsStatusFilter}
                operationsTypeFilter={operationsTypeFilter}
            />
        </Drawer>
    );
};

export default GlobalTaskManagerDrawer;
