// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveServerSettings } from '@platform360/security-dashboard/web/api/servers';
import {
    useServersQuery,
    useServerSettingsQuery,
} from '@platform360/security-dashboard/web/queries';

const useSaveServerSettingsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: saveServerSettings,
        onSuccess: async (_, { variables: { serverId } }) => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServerSettingsQuery.getQueryKey({ serverId }),
                }),
            ]);
        },
    });
};

export default useSaveServerSettingsMutation;
