// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Section, Grid, GridCol, Popover, PopoverProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Fragment, ReactElement } from 'react';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import { own, terminated } from '@platform360/licenses/web/helpers/utils';
import { ONCE } from '@platform360/licenses/web/constants/billingPeriod';
import { License } from '@platform360/licenses/web/api/ka/responseTypes/License';

type MoreDetailsProps = {
    target: ReactElement;
    placement: PopoverProps['placement'];
    keyInfo: License;
};

const MoreDetails = (props: MoreDetailsProps) => {
    const translate = useTranslate();
    const analyticsEvents = useLicensesAnalyticsEvents();

    const handleEditPaymentMethodClick = () => {
        analyticsEvents.licensesItemEditPaymentMethodClicked();
    };

    return (
        <Popover target={props.target} placement={props.placement}>
            {(props.keyInfo.createDate !== null || props.keyInfo.activationCode !== null) && (
                <Section title={translate('keyLicenseDetailsTitle')}>
                    {props.keyInfo.createDate !== null && (
                        <p>
                            {translate('keyLicenseDetailsCreationDate')}
                            <br />
                            {props.keyInfo.createDate}
                        </p>
                    )}
                    {props.keyInfo.activationCode !== null && (
                        <p data-type="Keys--keyMoreDetails--activationCode">
                            {translate('keyLicenseDetailsActivationCode')}
                            <br />
                            {props.keyInfo.activationCode}
                        </p>
                    )}
                </Section>
            )}
            <Grid gap="sm">
                <GridCol xs={props.keyInfo.isPlesk ? 6 : 12}>
                    <Section title={translate('keyBillingDetailsTitle')}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {own(props.keyInfo) ? (
                            // eslint-disable-next-line no-nested-ternary
                            props.keyInfo.componentInfo === null &&
                            props.keyInfo.billingPeriod === null ? (
                                props.keyInfo.isTrial ? (
                                    <p>
                                        {translate('keyBillingDetailsTrial', {
                                            date: props.keyInfo.expirationDate,
                                            a: (chunk) => (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    // todo move link to backend in scope of standalone WPT
                                                    href={
                                                        props.keyInfo.code === 'solus-io'
                                                            ? 'https://www.solus.io/prices/'
                                                            : 'https://www.plesk.com/pricing/'
                                                    }
                                                >
                                                    {chunk}
                                                </a>
                                            ),
                                        })}
                                    </p>
                                ) : (
                                    <p>{translate('keyBillingDetailsNotRetail')}</p>
                                )
                            ) : (
                                <Fragment>
                                    {terminated(props.keyInfo) && (
                                        <p>{translate('keyBillingDetailsTerminated')}</p>
                                    )}
                                    {/* eslint-disable-next-line no-eq-null*/}
                                    {props.keyInfo.componentInfo?.subscriptionId != null && (
                                        <p>
                                            {translate('keyBillingDetailsSubscriptionId')}
                                            <br />
                                            {props.keyInfo.componentInfo.subscriptionId}
                                        </p>
                                    )}
                                    {props.keyInfo.billingPeriod !== null &&
                                        (props.keyInfo.billingPeriod === '1m' ||
                                            props.keyInfo.billingPeriod === '1y') && (
                                            <p>
                                                {translate('keyBillingDetailsCycle')}
                                                <br />
                                                {props.keyInfo.billingPeriod === '1m'
                                                    ? translate('monthly')
                                                    : translate('yearly')}
                                            </p>
                                        )}
                                    {props.keyInfo.changePaymentSubscriptionUrl &&
                                        props.keyInfo.billingPeriod !== ONCE && (
                                            <p>
                                                <a
                                                    data-type={
                                                        'license-more-details-edit-payment-method-link'
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                        props.keyInfo.changePaymentSubscriptionUrl
                                                    }
                                                    onClick={handleEditPaymentMethodClick}
                                                >
                                                    {translate('editPaymentMethod')}
                                                </a>
                                            </p>
                                        )}
                                </Fragment>
                            )
                        ) : (
                            <p>{translate('keyBillingDetailsForeign')}</p>
                        )}
                    </Section>
                </GridCol>
                <GridCol xs={props.keyInfo.isPlesk ? 6 : undefined}>
                    {props.keyInfo.isPlesk && (
                        <Section title={translate('keyServerDetailsTitle')}>
                            {props.keyInfo.ips !== null ||
                            props.keyInfo.reportedOs !== null ||
                            props.keyInfo.reportedVersion !== null ? (
                                <Fragment>
                                    {props.keyInfo.ips !== null && (
                                        <p>
                                            {translate('keyServerDetailsAddresses')}
                                            <br />
                                            {props.keyInfo.ips.join(', ')}
                                        </p>
                                    )}
                                    {props.keyInfo.reportedOs !== null && (
                                        <p>
                                            {translate('keyServerDetailsOs')}
                                            <br />
                                            {props.keyInfo.reportedOs}
                                        </p>
                                    )}
                                    {props.keyInfo.reportedVersion !== null && (
                                        <p>
                                            {translate('keyServerDetailsProductVersion')}
                                            <br />
                                            {props.keyInfo.reportedVersion}
                                        </p>
                                    )}
                                </Fragment>
                            ) : (
                                <p>{translate('keyServerDetailsAbsent')}</p>
                            )}
                        </Section>
                    )}
                </GridCol>
            </Grid>
        </Popover>
    );
};

export default MoreDetails;
