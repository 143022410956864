// Copyright 2024. WebPros International GmbH. All rights reserved.

import { License as BackendLicense } from '@platform360/licenses/web/api/ka/responseTypes/License';
import { DEA } from '@platform360/licenses/web/api/ka/const/cleverbridgeItemStatus';

/**
 * Regular and trial Plesk licenses must not for cancel
 * All active non-Plesk licenses of the current user can be canceled
 * Already canceled licenses must not for cancel
 */
const isCancelPossible = (license: BackendLicense): boolean =>
    !license.isTrial &&
    license.ownLicense &&
    license.cbItemStatusId !== null &&
    license.cbItemStatusId !== DEA;

export default isCancelPossible;
