// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getVulnerabilitiesSummary } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseVulnerabilitiesSummaryQueryVariables = undefined;

export type UseVulnerabilitiesSummaryQueryData = PromiseValue<
    ReturnType<typeof getVulnerabilitiesSummary>
>;

const useVulnerabilitiesSummaryQuery = createQuery<
    UseVulnerabilitiesSummaryQueryVariables,
    UseVulnerabilitiesSummaryQueryData
>({
    queryName: 'security-dashboard/useVulnerabilitiesSummaryQuery',
    fetcher: getVulnerabilitiesSummary,
    useQuery: (options) =>
        useQuery({
            ...options,
            // Refetch every minute.
            refetchInterval: 60000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            meta: {
                defaultErrorHandler: false,
            },
        }),
});

export default useVulnerabilitiesSummaryQuery;
