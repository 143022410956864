// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Status, StatusProps, Text } from '@plesk/ui-library';
import { ServerStatus } from '@platform360/security-dashboard/web/types';

import styles from './ServerNameColumn.module.less';

const STATUSES_CONFIGS_MAP: {
    [key in ServerStatus]: Partial<StatusProps> | null;
} = {
    ok: null,
    installingAgent: null,
    agentInstallationError: { intent: 'danger' },
    connectionError: { intent: 'danger' },
    connectionBlocked: { intent: 'danger' },
};

type ServerNameColumnProps = {
    name: string;
    status: ServerStatus;
    unsupportedAgent: boolean;
};

const ServerNameColumn = ({ name, status, unsupportedAgent }: ServerNameColumnProps) => {
    const statusConfig = STATUSES_CONFIGS_MAP[status];
    const text = (
        <Text bold className={styles.name}>
            {name}
        </Text>
    );
    if (unsupportedAgent) {
        return (
            <Status compact className={styles.status} intent="danger">
                {text}
            </Status>
        );
    }
    if (!statusConfig) {
        return text;
    }

    return (
        <Status compact className={styles.status} {...statusConfig}>
            {text}
        </Status>
    );
};

export default ServerNameColumn;
