// Copyright 2024. WebPros International GmbH. All rights reserved.

import doRequest from '@platform360/licenses/web/api/doRequest';
import { Dispatch } from 'react';
import {
    InitialState,
    SubmittedTypes,
} from '@platform360/licenses/web/units/adjust/reducer/initialState';
import { SUBMIT, SUBMITTED } from '@platform360/licenses/web/units/adjust/constants/actions';
import { AdjustActions } from '@platform360/licenses/web/units/adjust/reducer/reducer';
import getProductsToSubmit from '@platform360/licenses/web/units/adjust/helpers/getProductsToSubmit';
import update, { Item } from '@platform360/licenses/web/api/ka/extensions/update';

export type SubmitAction = {
    type: typeof SUBMIT;
};

export type SubmittedAction = {
    type: typeof SUBMITTED;
    payload: SubmittedTypes;
};

export const submit =
    () => async (dispatch: Dispatch<AdjustActions>, getState: () => InitialState) => {
        dispatch({ type: SUBMIT });
        const { licenseId, subscriptionId } = getState();

        try {
            await doRequest(
                update({
                    keyNumber: Number(licenseId),
                    subscriptionId: String(subscriptionId),
                    items: getProductsToSubmit(getState()).map<Item>(
                        ({ productId, subscriptionItem, currentValue }) => ({
                            productId: Number(productId),
                            itemId: subscriptionItem,
                            quantity: currentValue,
                        }),
                    ),
                }),
            );

            dispatch({ type: SUBMITTED, payload: 'success' });
        } catch {
            dispatch({ type: SUBMITTED, payload: 'failed' });
        }
    };
