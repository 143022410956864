// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddServerWithExternalRedirectDialog, {
    DialogStep,
    FormValues,
} from './AddServerWithExternalRedirectDialog';
import useAddingServer from '@platform360/server-inventory/web/mutations/useAddingServer';
import { normalizeAddress } from '@platform360/server-inventory/web/components/Servers/utils';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { setIn } from '@plesk/ui-library';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const redirectPath = '/si/servers';

export const AddServerWithExternalRedirectDialogContainer = () => {
    const navigate = useNavigate();
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { email } = useCurrentUser();

    const [searchParams] = useSearchParams();
    const address = String(searchParams.get('address'));
    const challenge = String(searchParams.get('challenge'));
    const product = String(searchParams.get('product'));

    const redirectWithAttachedJWT = (url: string, jwt: string) => {
        const redirectUrl = new URL(url);
        redirectUrl.searchParams.set('jwt', jwt);
        window.location.href = redirectUrl.href;
    };

    useEffectOnce(() => {
        analyticsEvents.fromPleskConfirmOpen();
    });

    const [values, setValues] = useState<FormValues>({
        enableIpRestriction: true,
        allowUnsecureConnection: false,
    });
    const [step, setStep] = useState<DialogStep>('form');

    const {
        mutate: addServer,
        isPending: isLoading,
        errors,
    } = useAddingServer({
        onSuccess: ({ url, jwt }) => redirectWithAttachedJWT(url, jwt),
        onSettled: () => {
            setValues((values) => ({ ...values, allowUnsecureConnection: false }));
            setStep('form');
        },
    });

    const handleDeny = () => {
        analyticsEvents.addServerDeclined({ address: normalizeAddress(address) });
        navigate(redirectPath);
    };

    const handleConfirm = (values: FormValues) => {
        analyticsEvents.addExternalServerPressed();
        addServer({
            address: normalizeAddress(address),
            product,
            panelChallenge: challenge,
            ...values,
        });
    };

    const handleChange = (key: string, value: unknown) => setValues(setIn(values, key, value));

    return (
        <AddServerWithExternalRedirectDialog
            step={step}
            setStep={setStep}
            errors={errors}
            isLoading={isLoading}
            values={values}
            address={address}
            email={email}
            product={product}
            onConfirm={handleConfirm}
            onDeny={handleDeny}
            onChange={handleChange}
        />
    );
};

export default AddServerWithExternalRedirectDialogContainer;
