// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, FormErrors, FormFieldText, Heading, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import FormFieldInputFile from '@platform360/libs/shared-web/components/FormFieldInputFile';
import RegionSelect from './RegionSelect';
import BundleSelect from './BundleSelect';
import { LightsailBundle, LightsailRegion } from '@platform360/web-installer/shared/api-contract';
import Public from '@platform360/libs/shared-web/components/Public';

export type FormValues = {
    awsAccessKey: string;
    awsSecretKey: string;
    region: string;
    bundleId: string;
    publicKey: string;
    instanceName: string;
};

type LightsailInstallationProps = {
    regions: LightsailRegion[];
    bundles: LightsailBundle[];
    isLoading: boolean;
    values: FormValues;
    errors?: FormErrors;
    onSubmit: (values: FormValues) => void;
    onFieldChange: (name: string, value: string) => void;
};

const LightsailInstallation = ({
    regions,
    bundles,
    isLoading,
    values,
    errors,
    onSubmit,
    onFieldChange,
}: LightsailInstallationProps) => {
    const translate = useTranslate('web-installer.LightsailInstallation');
    const generalErrors = Object.values(errors?.general || []);

    return (
        <Public isWide>
            <Form<FormValues>
                vertical
                applyButton={false}
                cancelButton={false}
                submitButton={{
                    children: translate('submitButton'),
                }}
                onSubmit={onSubmit}
                onFieldChange={onFieldChange}
                state={isLoading ? 'submit' : undefined}
                values={values}
                errors={errors}
            >
                <Heading level={1}>{translate('title')}</Heading>
                {generalErrors.map((error, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <StatusMessage key={i.toString()} intent="danger">
                        {error}
                    </StatusMessage>
                ))}

                <FormFieldText
                    name="awsAccessKey"
                    label={translate('awsAccessKey')}
                    size="xl"
                    required
                />
                <FormFieldText
                    name="awsSecretKey"
                    label={translate('awsSecretKey')}
                    size="xl"
                    required
                />
                <RegionSelect
                    name="region"
                    label={translate('region')}
                    required
                    regions={regions}
                />
                <BundleSelect
                    name="bundleId"
                    label={translate('bundleTitle')}
                    required
                    bundles={bundles}
                />
                <FormFieldInputFile name="publicKey" label={translate('publicKeyTitle')} required />
                <FormFieldText
                    name="instanceName"
                    label={translate('instanceName')}
                    size="xl"
                    required
                />
            </Form>
        </Public>
    );
};

export default LightsailInstallation;
