// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph, Link, PopoverProps } from '@plesk/ui-library';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useRemoveServersMutation } from '@platform360/security-dashboard/web/mutations';
import { Server } from '@platform360/security-dashboard/web/types';
import { ReactElement } from 'react';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ComponentWithLoader = ({ isLoading }: { isLoading: boolean }) => ReactElement;

export type RemoveServersButtonProps = {
    servers: Server[];
    onSuccess: (removedServersCount: number) => void;
    target: ComponentWithLoader | ReactElement<{ onClick: () => void }>;
    placement?: PopoverProps['placement'];
    onConfirmationShown?: () => void;
    onConfirm?: () => void;
};

export const RemoveServersPopover = ({
    servers,
    onSuccess,
    target,
    onConfirmationShown,
    onConfirm,
    ...props
}: RemoveServersButtonProps) => {
    const translate = useTranslate('security-dashboard.Servers.RemoveServersButton');
    const { mutate: removeServers, isPending: isLoading } = useRemoveServersMutation({
        onSuccess,
    });
    const {
        securityDashboard: { docs },
    } = useConfig();

    const handleConfirmationClick = () => {
        if (onConfirm) {
            onConfirm();
        }
        removeServers(
            servers.map(({ id, displayTitle }) => ({
                serverId: id,
                displayTitle,
            })),
        );
        if (typeof target !== 'function') {
            target.props.onClick();
        }
    };

    return (
        <ConfirmationPopover
            onClick={handleConfirmationClick}
            onShow={onConfirmationShown}
            target={typeof target === 'function' ? target({ isLoading }) : target}
            actionButtonText={translate('remove')}
            {...props}
        >
            <Paragraph>
                {translate('removeDescription', {
                    a: (parts) => (
                        <Link
                            href={concatUrl(
                                'wpg/knowledge-base/uninstalling-wpg-agent.html',
                                docs.baseUrl,
                            )}
                            target="_blank"
                        >
                            {parts}
                        </Link>
                    ),
                })}
                <br />
                {servers.length === 1 && servers[0]?.displayTitle
                    ? translate('removeSingleServerConfirmation', {
                          selectedServerTitle: servers[0]?.displayTitle,
                      })
                    : translate('removeSeveralServersConfirmation', {
                          selectedServersCount: servers.length,
                      })}
            </Paragraph>
        </ConfirmationPopover>
    );
};

export default RemoveServersPopover;
