// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';

export const useLastCheckAtTooltip = (lastVulnerabilityCheckAt: DateTime | null): string | null => {
    const formatDateTime = useFormatDateTime();
    const translate = useTranslate('security-dashboard.Servers.SiteHealthColumn');

    if (lastVulnerabilityCheckAt === null) {
        return null;
    }

    const lastVulnerabilityCheckAtToday = lastVulnerabilityCheckAt.hasSame(DateTime.now(), 'day');

    // TODO (vvolodko) Use single message with select and date format.
    if (lastVulnerabilityCheckAtToday) {
        return translate('lastCheckToday', {
            time: formatDateTime(lastVulnerabilityCheckAt, 't'),
        });
    }

    return translate('lastCheck', {
        dateTime: formatDateTime(lastVulnerabilityCheckAt, 'f'),
    });
};
