// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext, useContext, ElementType } from 'react';
import { useSearchParams } from 'react-router-dom';
import NotFoundActivateLicense from '@platform360/licenses/web/components/ActivateLicense/NotFoundActivateLicense';

export type ActivateLicenseComponentContextValue = Record<
    string,
    {
        products: string[];
        component: ElementType;
    }
>;

export const ActivateLicenseComponentContext = createContext<ActivateLicenseComponentContextValue>(
    {},
);

const ActivateLicense = () => {
    const [searchParams] = useSearchParams();
    const product = searchParams.get('product') || 'unknown';

    const componentContext = useContext(ActivateLicenseComponentContext);
    const { component: Component } =
        Object.values(componentContext).find(({ products }) => products.includes(product)) || {};

    if (!Component) {
        return <NotFoundActivateLicense />;
    }

    return <Component product={product} />;
};

export default ActivateLicense;
