// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getInstallationsVulnerabilities } from '@platform360/security-dashboard/web/api/installations';
import { createQuery } from '@platform360/libs/shared-web/query';
import { IdsFilterQuery } from '@platform360/libs/shared-web/typings/api';

export type UseInstallationsVulnerabilitiesQueryVariables = {
    installationId: number;
} & IdsFilterQuery;

export type UseInstallationsVulnerabilitiesQueryData = PromiseValue<
    ReturnType<typeof getInstallationsVulnerabilities>
>;

const useInstallationsVulnerabilitiesQuery = createQuery<
    UseInstallationsVulnerabilitiesQueryVariables,
    UseInstallationsVulnerabilitiesQueryData
>({
    queryName: 'security-dashboard/useInstallationsVulnerabilitiesQuery',
    fetcher: getInstallationsVulnerabilities,
    useQuery: (options) =>
        useQuery({
            refetchOnWindowFocus: false,
            ...options,
        }),
});

export default useInstallationsVulnerabilitiesQuery;
