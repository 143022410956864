// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useReducer } from 'react';
import { Toaster as UILibraryToaster } from '@plesk/ui-library';
import { EventPayload, EVENT_NAME } from '@platform360/libs/shared-web/toaster/toaster';
import reducer from '@platform360/libs/shared-web/toaster/reducer';

export const Toaster = () => {
    const [toasts, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        const handleEvent = ({ detail: { type, ...rest } }: CustomEvent<EventPayload>) => {
            switch (type) {
                case 'clear-toasts':
                    dispatch({ type: 'CLEAR_TOASTS' });
                    break;
                case 'send-error-toast':
                    dispatch({
                        type: 'ADD_TOAST',
                        payload: {
                            intent: 'danger',
                            ...rest,
                        },
                    });
                    break;
                case 'send-success-toast':
                    dispatch({
                        type: 'ADD_TOAST',
                        payload: {
                            intent: 'success',
                            ...rest,
                        },
                    });
                    break;
                case 'send-warning-toast':
                    dispatch({
                        type: 'ADD_TOAST',
                        payload: {
                            intent: 'warning',
                            ...rest,
                        },
                    });
                    break;
            }
        };

        document.addEventListener(EVENT_NAME, handleEvent);

        return () => {
            document.removeEventListener(EVENT_NAME, handleEvent);
        };
    }, [dispatch]);

    const handleToastClose = (key: string) => dispatch({ type: 'REMOVE_TOAST', payload: key });

    return <UILibraryToaster toasts={toasts} onToastClose={handleToastClose} />;
};

export default Toaster;
