// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Alert, Skeleton, Text } from '@plesk/ui-library';
import { formatSeconds } from '@platform360/libs/shared-web/components/RetryTimer';
import { useClipboard } from '@platform360/libs/shared-web/helpers/useClipboard';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useInstallationSnippet from '@platform360/security-dashboard/web/components/Servers/InstallationSnippet/useInstallationSnippet';
import styles from './InstallationSnippet.module.css';

export type InstallationSnippetProps = {
    isTimerHidden: boolean;
    timer?: number;
    token: string;
    isLoading: boolean;
    timerIntent?: 'danger' | 'muted';
    onCopy: () => void;
    isRefreshing: boolean;
    onRefreshClick: () => void;
};

const InstallationSnippet = ({
    isTimerHidden,
    timer,
    timerIntent,
    token,
    isLoading,
    onCopy,
    isRefreshing,
    onRefreshClick,
}: InstallationSnippetProps) => {
    const translate = useTranslate('security-dashboard.Servers.InstallationSnippet');

    const { securityDashboard } = useConfig();
    const { snippet, formattedSnippet } = useInstallationSnippet(
        securityDashboard.wpAgentInstallerUrl,
        securityDashboard.wpAgentRepoUrl,
        token,
    );

    const { copyText } = useClipboard();
    const handleCopy = () => {
        void copyText(snippet, { successMessage: translate('copied') });
        onCopy();
    };

    if (isLoading) {
        return <Skeleton height={140} />;
    }

    return (
        <Alert intent="info" className={styles.container}>
            <div className={styles.snippet}>{formattedSnippet}</div>
            <div className={styles.actions}>
                <Button ghost icon="copy" onClick={handleCopy}>
                    {translate('copy')}
                </Button>
                {!isTimerHidden && (
                    <div className={styles.timerContainer}>
                        <Text bold intent={timerIntent} className={styles.timer}>
                            {formatSeconds(timer)}
                        </Text>
                        <Button
                            data-type="refresh-timer"
                            tooltip={translate('refreshTooltip')}
                            state={isRefreshing ? 'loading' : undefined}
                            icon="refresh"
                            onClick={onRefreshClick}
                            ghost
                        />
                    </div>
                )}
            </div>
        </Alert>
    );
};

export default InstallationSnippet;
