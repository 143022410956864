// Copyright 2024. WebPros International GmbH. All rights reserved.

import MonitoringActivation from './MonitoringActivation';
import { useMonitoringSetup } from '@platform360/monitoring/web/mutations';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { startMonitoringTrial } from '@platform360/libs/shared-web/helpers/googleAnalytics';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { ContentLoader } from '@plesk/ui-library';
import { useExternalRedirect } from '@platform360/libs/shared-web/helpers/useExternalRedirect';
import { useMonitoringAnalyticsEvents } from '@platform360/monitoring/web/helpers/analytics';

const MonitoringActivationContainer = () => {
    const {
        monitoring: { baseUrl: monitoring360url },
    } = useConfig();
    const { monitoring } = useCurrentUser();
    const isMonitoringActive = monitoring?.userId !== undefined;
    const analyticsEvents = useMonitoringAnalyticsEvents();
    const { externalRedirect, redirectError } = useExternalRedirect();

    const { mutate: handleTrialBegin, isPending: isTrialBeginProcessing } = useMonitoringSetup({
        onSuccess: () => {
            analyticsEvents.monitoringActivationActivateButtonClicked();
            startMonitoringTrial();
            externalRedirect(monitoring360url);
        },
    });

    useEffectOnce(() => {
        analyticsEvents.monitoringActivationPageOpen(isMonitoringActive);

        if (isMonitoringActive) {
            externalRedirect(monitoring360url);
        }
    });

    if (isMonitoringActive && !redirectError) {
        return <ContentLoader />;
    }

    return (
        <MonitoringActivation
            error={redirectError}
            onTrialBegin={handleTrialBegin}
            isTrialBeginProcessing={isTrialBeginProcessing}
        />
    );
};

export default MonitoringActivationContainer;
