// Copyright 2024. WebPros International GmbH. All rights reserved.

import { MenuItem } from '@plesk/ui-library';
import LabelsList from '@platform360/security-dashboard/web/components/LabelsList';
import { Server, Label } from '@platform360/security-dashboard/web/types';
import { LabelTypes } from '@platform360/security-dashboard/shared/label-type';

type ServerLabelsListProps = {
    server: Server;
    onRemoveLabel: (label: Label) => void;
    onFilterByLabel: (label: Label) => void;
};

const ServerLabelsList = ({ server, onRemoveLabel, onFilterByLabel }: ServerLabelsListProps) => (
    <LabelsList
        labels={server.labels}
        updates={server.updateSettings}
        renderMenuItems={({ label, localisation: { filter, remove } }) => {
            const result = [
                <MenuItem key="filter" icon="filter" onClick={() => onFilterByLabel(label)}>
                    {filter}
                </MenuItem>,
            ];
            if (label.type === LabelTypes.custom) {
                result.push(
                    <MenuItem key="remove" icon="recycle" onClick={() => onRemoveLabel(label)}>
                        {remove}
                    </MenuItem>,
                );
            }

            return result;
        }}
    />
);

export default ServerLabelsList;
