// Copyright 2024. WebPros International GmbH. All rights reserved.

import doRequest from '@platform360/licenses/web/api/doRequest';
import { Dispatch } from 'react';
import { InitialState } from '@platform360/licenses/web/units/adjust/reducer/initialState';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import { AdjustActions } from '@platform360/licenses/web/units/adjust/reducer/reducer';
import { calculated } from '@platform360/licenses/web/units/adjust/actions/calculated';
import previewUpdate from '@platform360/licenses/web/api/ka/extensions/previewUpdate';

export const calculateSummaryCost =
    () =>
    async (dispatch: Dispatch<AdjustActions>, getState: () => InitialState): Promise<void> => {
        const {
            propertiesOfLicense: properties,
            newValues: products,
            licenseId,
            subscriptionId,
        } = getState();

        if (properties === null) {
            return;
        }

        const costs = await doRequest<CalculatedSubscriptionState>(
            previewUpdate({
                keyNumber: Number(licenseId),
                subscriptionId: String(subscriptionId),
                items: properties.keyProperties.map(({ productId, subscriptionItem }) => ({
                    productId: Number(productId),
                    itemId: subscriptionItem,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    quantity: products[Number(productId)]!.quantity,
                })),
            }),
        );

        dispatch(calculated(costs));
    };
