// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateBusinessProfile } from '@platform360/accounts/web/api/me';

type UseUpdateBusinessProfileMutationOptions = {
    onSuccess?: () => void;
};

const useUpdateBusinessProfileMutation = ({
    onSuccess,
}: UseUpdateBusinessProfileMutationOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateBusinessProfile,
        onSuccess: async (data) => {
            await queryClient.cancelQueries({
                queryKey: ['/accounts/me/business-profile'],
                exact: true,
            });
            queryClient.setQueryData<PromiseValue<ReturnType<typeof updateBusinessProfile>>>(
                ['/accounts/me/business-profile'],
                data,
            );

            onSuccess?.();
        },
    });
};

export default useUpdateBusinessProfileMutation;
