// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';

type ID = number | string;
type LoadingState<T extends ID = ID, Operation extends string = string> = Partial<
    Record<Operation, T[]>
>;

const emptyHandler = {
    get<T extends LoadingState>(...args: [T, keyof T, unknown]) {
        const [target, prop] = args;
        if (target[prop] === undefined) return [];
        return Reflect.get(...args);
    },
};

export const useLoadingRows = <T extends ID, Operation extends string = string>() => {
    type State = LoadingState<T, Operation>;
    const [loadingState, setLoadingState] = useState<State>({});

    const loadingRows = Object.values(loadingState).reduce<T[]>(
        (ids, idsByOperation) => [...ids, ...(idsByOperation as T[])],
        [],
    );

    const setLoadingRows = (ids: ID[], operation: Operation) =>
        setLoadingState((currentState) => ({
            ...currentState,
            [operation]: [...(currentState[operation] || []), ...ids],
        }));

    const unsetLoadingRows = (ids: ID[], operation: Operation) =>
        setLoadingState((currentState) => ({
            ...currentState,
            [operation]: (currentState[operation] || []).filter((id) => !ids.includes(id)),
        }));

    return {
        loadingRows,
        loadingRowsState: new Proxy(loadingState, emptyHandler) as Required<State>,
        setLoadingRows,
        unsetLoadingRows,
    } as const;
};
