// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { mitigateVulnerabilities } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { useVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

type MutationOptions = {
    onSuccess?: () => void;
    onSettled?: () => void;
};

const useMitigateVulnerabilitiesMutation = ({ onSuccess, onSettled }: MutationOptions = {}) =>
    useMutation({
        mutationFn: mitigateVulnerabilities,
        onSuccess: () => {
            refetchList(useVulnerabilitiesQuery.getQueryKey());

            if (onSuccess) {
                onSuccess();
            }
        },
        onSettled,
    });

export default useMitigateVulnerabilitiesMutation;
