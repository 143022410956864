// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext, ReactNode, useMemo } from 'react';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { io, Socket } from 'socket.io-client';

export const WebsocketContext = createContext<Socket | undefined>(undefined);

type WebsocketProviderProps = {
    children: ReactNode;
    namespace: string;
};

export const WebsocketProvider = ({ namespace, children }: WebsocketProviderProps) => {
    const { session, isAuthorized } = useAuth();

    const socket = useMemo(() => {
        if (isAuthorized) {
            return io(`/${namespace}`, {
                transports: ['websocket'],
                auth: {
                    token: session?.accessToken ?? '',
                },
            });
        }

        return undefined;
    }, [isAuthorized, namespace, session?.accessToken]);

    return <WebsocketContext.Provider value={socket}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
