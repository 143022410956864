// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext, ReactNode, useMemo, useState } from 'react';

type StepFormContextValue = {
    stepTitle: ReactNode;
    setStepTitle: (stepTitle: ReactNode) => void;
    stepSubTitle: ReactNode;
    setStepSubTitle: (stepTitle: ReactNode) => void;
};

export const StepFormContext = createContext<StepFormContextValue>({
    stepTitle: null,
    setStepTitle: () => {},
    stepSubTitle: null,
    setStepSubTitle: () => {},
});

export type StepFormProviderProps = {
    children: ReactNode;
};

export const StepFormProvider = ({ children }: StepFormProviderProps) => {
    const [stepTitle, setStepTitle] = useState<ReactNode>(null);
    const [stepSubTitle, setStepSubTitle] = useState<ReactNode>(null);
    const stepFormContextValue: StepFormContextValue = useMemo(
        () => ({
            stepTitle,
            setStepTitle,
            stepSubTitle,
            setStepSubTitle,
        }),
        [stepTitle, setStepTitle, stepSubTitle, setStepSubTitle],
    );

    return (
        <StepFormContext.Provider value={stepFormContextValue}>{children}</StepFormContext.Provider>
    );
};
