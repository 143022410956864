// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Alert } from '@plesk/ui-library';
import { useState } from 'react';
import Layout from '@platform360/libs/shared-web/components/Public';
import Loading from '@platform360/libs/shared-web/components/Loading';
import Header from '@platform360/auth/web/components/Header';
import { linkEmail } from '@platform360/licenses/web/store/linkedEmails';
import {
    ValidationError,
    toFlatMessages,
    translateFlatMessages,
} from '@platform360/libs/shared-web/helpers/errors';
import { useNavigate } from 'react-router-dom';
import { emailsUri } from '@platform360/licenses/web/helpers';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import axios from 'axios';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const LinkEmail = () => {
    const translate = useTranslate('LinkEmail');
    const [errors, setErrors] = useState();

    const navigate = useNavigate();

    useEffectOnce(() => {
        const fetch = async () => {
            try {
                await linkEmail();
                navigate(emailsUri);
            } catch (e) {
                if (e instanceof ValidationError) {
                    setErrors(toFlatMessages(e.data));
                    return;
                }

                if (axios.isAxiosError(e) && e.response?.status === 403) {
                    setErrors(['errorLinkIsOutdated']);
                    return;
                }

                throw e;
            }
        };

        fetch();
    });

    if (errors) {
        return (
            <Layout>
                <Header>{translate('title')}</Header>
                {translateFlatMessages(errors, (message) => (
                    <Alert intent="danger">{message}</Alert>
                ))}
            </Layout>
        );
    }

    return <Loading />;
};

export default LinkEmail;
