// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './NameFormField.module.less';
import { FormField, FormFieldText } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type NameFormFieldProps = {
    autoFocus?: boolean;
};

const NameFormField = ({ autoFocus }: NameFormFieldProps = {}) => {
    const translate = useTranslate('Auth.SignUp.SignUpForm.NameFormField');

    return (
        <FormField label={translate('nameLabel')} className={styles.root} required>
            <div className={styles.multiFields}>
                <FormFieldText
                    autoFocus={autoFocus}
                    label={null}
                    name="firstName"
                    size="fill"
                    className={styles.inlineInput}
                    placeholder={translate('firstNamePlaceholder')}
                />{' '}
                <FormFieldText
                    label={null}
                    name="lastName"
                    size="fill"
                    className={styles.inlineInput}
                    placeholder={translate('lastNamePlaceholder')}
                />
            </div>
        </FormField>
    );
};

export default NameFormField;
