// Copyright 2024. WebPros International GmbH. All rights reserved.

import { delayedCalculatingSummaryCost } from './delayedCalculatingSummaryCost';
import { Dispatch } from 'react';
import { InitialState } from '@platform360/licenses/web/units/adjust/reducer/initialState';
import {
    INCREASE,
    DECREASE,
    NEUTRAL,
} from '@platform360/licenses/web/units/adjust/constants/productState';
import { AdjustActions } from '@platform360/licenses/web/units/adjust/reducer/reducer';
import { set } from './set';

export const setValue =
    (productId: number, value: number) =>
    (dispatch: Dispatch<AdjustActions>, getState: () => InitialState) => {
        const { propertiesForChange: properties } = getState();

        if (properties.length === 0) {
            return;
        }

        const currentValue = Number(
            // @ts-expect-error Object is possibly undefined
            properties.find((property) => Number(property.productId) === productId).currentValue,
        );
        dispatch(
            set(productId, {
                quantity: value,
                // eslint-disable-next-line no-nested-ternary
                state: value > currentValue ? INCREASE : value < currentValue ? DECREASE : NEUTRAL,
            }),
        );

        // @ts-expect-error TS2345
        dispatch(delayedCalculatingSummaryCost());
    };
