// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Route,
    // eslint-disable-next-line no-restricted-imports
    Routes,
    Navigate,
    RouteObject,
} from 'react-router-dom';
import { Router as LicensesRouter } from '@platform360/licenses/web/index';
import { routes as homePageRoutes } from '@platform360/home-page/web/routes';
import RedirectGateway from '@platform360/app/web/components/RedirectGateway';
import { routes as webInstallerRoutes } from '@platform360/web-installer/web/routes';
import Legal from '@platform360/app/web/components/Legal';
import LinkEmail from '@platform360/licenses/web/LinkEmail';
import NotFound from '@platform360/app/web/components/NotFound';
import * as Auth from '@platform360/auth/web/components';
import { moduleId, licensesPath, licensesUri } from '@platform360/licenses/web/helpers';
import { routes as serverInventoryRoutes } from '@platform360/server-inventory/web/routes';
import { routes as authRoutes } from '@platform360/auth/web/routes';
import { Router as MonitoringRouter } from '@platform360/monitoring/web/index';
import { Router as TeamRouter, UpdateEmail } from '@platform360/accounts/web/index';
import { SecurityDashboardApp } from '@platform360/security-dashboard/web/index';
import { AdminPanelApp } from '@platform360/admin-panel/web/index';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import App from '@scp/frontend/components/App';

const buildLicenseRedirectPath = (path: string) =>
    moduleId.concat(licensesPath).concat(path).concat(`?${window.location.search}`);

const LegacyRoutes = () => (
    <Routes>
        {/* Redirects. */}
        <Route path="/keys" element={<Navigate to={licensesUri} />} />
        <Route
            path="/upgrade/:keyId"
            element={<Navigate to={buildLicenseRedirectPath('/:keyId/upgrade')} />}
        />
        <Route
            path="/upsell/:keyId"
            element={<Navigate to={buildLicenseRedirectPath('/:keyId/upsell')} />}
        />
        <Route
            path="/licenses/upgrade/:keyId"
            element={<Navigate to={buildLicenseRedirectPath('/:keyId/upgrade')} />}
        />
        <Route
            path="/licenses/upsell/:keyId"
            element={<Navigate to={buildLicenseRedirectPath('/:keyId/upsell')} />}
        />
        <Route path="/licenses/all" element={<Navigate to={licensesUri} />} />
        <Route path="/redirects/monitoring/promo" element={<Navigate to="/monitoring/promo" />} />
        {/* Processes redirect URLs for the marketing promo companies, save acquisition source on user sign-up */}
        <Route path="/promo/:acquisitionSource" element={<Auth.AcquisitionSourceRedirect />} />

        {/* Private routes. */}
        <Route element={<PrivateRoutes />}>
            <Route path="/digitalocean/*" element={<Auth.DigitalOceanRedirect />} />
            <Route path="/redirects/*" element={<RedirectGateway />} />
        </Route>

        {/* Module routes. */}
        <Route path={`${moduleId}/*`} element={<LicensesRouter />} />
        <Route path="/organization/*" element={<TeamRouter />} />
        <Route path="/monitoring/*" element={<MonitoringRouter />} />
        <Route path="/security-dashboard/*" element={<SecurityDashboardApp />} />
        <Route path="/admin-panel/*" element={<AdminPanelApp />} />

        {/* Public routes. */}
        <Route path="/legal/*" element={<Legal />} />
        <Route path="/linkEmail" element={<LinkEmail />} />
        <Route path="/accounts/update-email" element={<UpdateEmail />} />
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export const routes: RouteObject[] = [
    {
        path: '*',
        element: <App />,
        children: [
            ...homePageRoutes,
            ...serverInventoryRoutes,
            ...webInstallerRoutes,
            ...authRoutes,
            {
                path: '*',
                element: <LegacyRoutes />,
            },
        ],
    },
];
