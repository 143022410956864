// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { useQuery } from '@tanstack/react-query';
import { getSettings } from '@platform360/security-dashboard/web/api/settings';

export type UseSettingsQueryData = PromiseValue<ReturnType<typeof getSettings>>;

const useSettingsQuery = createQuery<undefined, UseSettingsQueryData>({
    queryName: 'security-dashboard/useSettingsQuery',
    fetcher: getSettings,
    useQuery,
});

export default useSettingsQuery;
