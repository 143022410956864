// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '@platform360/libs/shared-web/components/Loading';

const AuthGateway = () => {
    const { isAuthorized, login } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthorized) {
            navigate('/si/servers');
        } else {
            void login({
                redirectUrl: '/si/servers',
            });
        }
    }, [isAuthorized, login, navigate]);

    return <Loading />;
};

export default AuthGateway;
