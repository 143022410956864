// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useClipboard } from '@platform360/libs/shared-web/helpers/useClipboard';
import { RequestInfo } from '@platform360/admin-panel/web/components/security-dashboard/Requests/useRequestStorage';
import styles from './ResponseDataPreview.module.less';

export type ResponseData = Exclude<RequestInfo['response'], null>['data'];

const EMPTY_DATA: ResponseData[] = [undefined, null, ''];

type ResponseDataPreviewProps = {
    data: ResponseData;
    isResponseLarge?: boolean;
};

const ResponseDataPreview = ({ data, isResponseLarge }: ResponseDataPreviewProps) => {
    const translate = useTranslate(
        'admin-panel.security-dashboard.Requests.Network.ResponseDataPreview',
    );
    const { copyText } = useClipboard();
    const { successToast } = useToaster();

    if (isResponseLarge) {
        return translate('largeResponse');
    }

    if (EMPTY_DATA.includes(data)) {
        return translate('emptyResponse');
    }

    const stringifiedData = typeof data === 'string' ? data : JSON.stringify(data, undefined, 2);

    return (
        <div className={styles.responseContainer}>
            <pre className={styles.response}>{stringifiedData}</pre>
            <Button
                ghost
                icon="copy"
                className={styles.copy}
                onClick={() => {
                    void copyText(stringifiedData);
                    successToast(translate('copied'));
                }}
            />
        </div>
    );
};

export default ResponseDataPreview;
