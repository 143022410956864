// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Alert,
    Column,
    Columns,
    Grid,
    GridCol,
    Hint,
    Icon,
    Media,
    Popover,
    Toolbar,
    ToolbarGroup,
} from '@plesk/ui-library';
import PropTypes from 'prop-types';
import Cost from '@platform360/licenses/web/components/Cost';
import orderInfo from './upgrade-order-info.png';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

/**
 * Confirm component
 * @param [billingPeriod] string
 * @param [calculatedSubscriptionState] object
 */
const Confirm = ({
    billingPeriod = null,
    calculatedSubscriptionState = null,
    delayedChangesProducts = [],
    instantChangesProducts = [],
    ...other
}) => {
    const translate = useTranslate();

    return (
        <div {...other}>
            <div data-type="Confirm">
                <Alert intent="info">
                    <Media image={<Icon src={orderInfo} size="16" />}>
                        <Grid>
                            <GridCol xl={12}>
                                {delayedChangesProducts.length > 0 && (
                                    <div>
                                        {translate('Keys.Adjust.delayedChangesProducts', {
                                            date: calculatedSubscriptionState.nextBillingDate,
                                        })}
                                        <ul>
                                            {delayedChangesProducts.map(
                                                ({ currentValue, description }) => (
                                                    <li
                                                        key={currentValue + description}
                                                    >{`${currentValue} ${description}`}</li>
                                                ),
                                            )}
                                        </ul>
                                        <p />
                                    </div>
                                )}
                                {instantChangesProducts.length > 0 && (
                                    <Columns gap="xl">
                                        <Column ratio={1}>{translate('chargeNowShort')}</Column>
                                        <Column ratio={1}>
                                            <div>
                                                <Cost
                                                    value={
                                                        calculatedSubscriptionState.alignmentGrossPrice
                                                    }
                                                    currency={calculatedSubscriptionState.currency}
                                                />
                                            </div>
                                            <div>
                                                <Hint>
                                                    {translate('inclVatShort', {
                                                        vatPrice: (
                                                            <Cost
                                                                value={
                                                                    calculatedSubscriptionState.alignmentVatPrice
                                                                }
                                                                currency={
                                                                    calculatedSubscriptionState.currency
                                                                }
                                                            />
                                                        ),
                                                    })}
                                                </Hint>
                                            </div>
                                        </Column>
                                    </Columns>
                                )}
                            </GridCol>
                            <GridCol xl={12}>
                                <Columns gap="xl">
                                    {/* eslint-disable-next-line react/jsx-max-depth */}
                                    <Column ratio={1}>
                                        {translate('nextChargeShort', {
                                            nextBillingDate:
                                                calculatedSubscriptionState.nextBillingDate,
                                        })}
                                    </Column>
                                    <Column ratio={1}>
                                        <div data-type={'Charge--total'}>
                                            <BillingInterval period={billingPeriod}>
                                                {/* eslint-disable-next-line react/jsx-max-depth */}
                                                <Cost
                                                    value={
                                                        calculatedSubscriptionState.nextBillingGrossPrice
                                                    }
                                                    currency={calculatedSubscriptionState.currency}
                                                />
                                            </BillingInterval>
                                        </div>
                                        <div>
                                            {/* eslint-disable-next-line react/jsx-max-depth */}
                                            <Hint>
                                                {translate('inclVatShort', {
                                                    vatPrice: (
                                                        <Cost
                                                            value={
                                                                calculatedSubscriptionState.nextBillingVatPrice
                                                            }
                                                            currency={
                                                                calculatedSubscriptionState.currency
                                                            }
                                                        />
                                                    ),
                                                })}
                                            </Hint>
                                        </div>
                                    </Column>
                                </Columns>
                            </GridCol>
                            {!delayedChangesProducts && (
                                <GridCol xl={12}>
                                    <Toolbar>
                                        <ToolbarGroup title="why-icon">
                                            <Icon name={'question-mark-circle'} size={12} />
                                        </ToolbarGroup>
                                        <ToolbarGroup title="why-popup">
                                            <Popover target={<a>{translate('why')}</a>}>
                                                {translate('whyCharge')}
                                            </Popover>
                                        </ToolbarGroup>
                                    </Toolbar>
                                </GridCol>
                            )}
                        </Grid>
                    </Media>
                </Alert>
            </div>
        </div>
    );
};

Confirm.propTypes = {
    billingPeriod: PropTypes.string,
    calculatedSubscriptionState: PropTypes.object,
    delayedChangesProducts: PropTypes.array,
    instantChangesProducts: PropTypes.array,
};

export default Confirm;
