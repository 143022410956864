// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

import { Button, Column, Columns, Form, FormFieldText, Heading, Panel } from '@plesk/ui-library';
import useLeikaEmailHashesMutation from '@platform360/admin-panel/web/mutations/useLeikaEmailHashesMutation';
import { useClipboard } from '@platform360/libs/shared-web/helpers/useClipboard';

export const LeikaEmailHashes = () => {
    const translate = useTranslate('admin-panel.LeikaEmailHashes');

    const {
        data,
        errors,
        isPending,
        mutate: generateLeikaEmailHashes,
    } = useLeikaEmailHashesMutation();

    const { copyText } = useClipboard();

    const handleSubmit = ({ emails }: { emails?: string }) => {
        const filtered = emails?.split('\n').filter((item) => item.trim().length > 0);
        if (filtered?.length) {
            generateLeikaEmailHashes({ emails: filtered });
        }
    };

    return (
        <>
            <Heading>{translate('title')}</Heading>
            <Columns gap="md">
                <Column ratio={1}>
                    <Form
                        applyButton={false}
                        cancelButton={false}
                        submitButton={{
                            type: 'submit',
                            children: translate('hashEmails'),
                            fill: true,
                        }}
                        onSubmit={handleSubmit}
                        state={isPending ? 'submit' : undefined}
                        errors={errors}
                        vertical
                    >
                        <FormFieldText
                            size={'fill'}
                            rows={20}
                            label={translate('label')}
                            name={'emails'}
                            multiline
                            placeholder={translate('placeholder')}
                        />
                    </Form>
                </Column>
                <Column ratio={1}>
                    {data?.hashedEmails.length && (
                        <Panel
                            title={translate('resultLabel')}
                            style={{ height: '100%' }}
                            extra={[
                                <Button
                                    ghost
                                    key={'submit'}
                                    icon="copy-indicator"
                                    onClick={() =>
                                        copyText(data.hashedEmails.join('\n'), {
                                            successMessage: translate('copied'),
                                        })
                                    }
                                />,
                            ]}
                        >
                            <code>
                                {data.hashedEmails.map((s: string) => (
                                    <>
                                        {s}
                                        <br />
                                    </>
                                ))}
                            </code>
                        </Panel>
                    )}
                </Column>
            </Columns>
        </>
    );
};
