// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ChangeEvent, HTMLInputTypeAttribute, useState } from 'react';
import { Action, Button, FormField, Input } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './InPlaceEdit.module.css';

type InPlaceEditProps = {
    value: string;
    onSave: (value: string) => Promise<void>;
    onCancel: () => void;
    type?: HTMLInputTypeAttribute;
    name?: string;
    placeholder?: string;
};

// TODO: improve ui library InPlaceEdit.
export const InPlaceEdit = ({
    value,
    onSave,
    onCancel,
    type,
    name,
    placeholder,
}: InPlaceEditProps) => {
    const translate = useTranslate('admin-panel.InPlaceEdit');
    const [val, setVal] = useState<string>(value);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEdit = () => setIsShown(true);
    const handleSave = async () => {
        try {
            setIsLoading(true);

            await onSave(val);
            setIsShown(false);
        } catch {
            // do nothing.
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setVal(value);
        setIsShown(false);
        onCancel();
    };

    if (isLoading) {
        return <Action progress>{translate('updating')}</Action>;
    }

    if (!isShown) {
        return (
            <>
                {val}
                <Button onClick={handleEdit} icon="pencil" data-type="edit-button" ghost />
            </>
        );
    }

    return (
        <FormField name={name} label={null}>
            <div className={styles.root}>
                <Input
                    type={type}
                    value={val}
                    placeholder={placeholder}
                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                        setVal(value)
                    }
                    autoFocus
                />
                <div>
                    <Button icon="check-mark" onClick={handleSave} data-type="save-button" ghost />
                    <Button
                        icon="cross-mark"
                        onClick={handleCancel}
                        data-type="cancel-button"
                        ghost
                    />
                </div>
            </div>
        </FormField>
    );
};
