// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './MultifactorStatus.module.css';

type MultifactorStatusProps = {
    enabled: boolean;
};

const MultifactorStatus = ({ enabled }: MultifactorStatusProps) => {
    const translate = useTranslate('Team.Members.MultifactorStatus');

    if (enabled) {
        return (
            <>
                <Icon className={styles.icon} name="check-mark-circle" intent="success" />
                {translate('enabled')}
            </>
        );
    }

    return (
        <>
            <Icon className={styles.icon} name="exclamation-mark-circle-filled" intent="danger" />
            {translate('disabled')}
        </>
    );
};

export default MultifactorStatus;
