// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import {
    SafeUpdateOption,
    SafeUpdateOptions,
} from '@platform360/security-dashboard/shared/safe-update-option';

import {
    UpdateSettingsOption,
    UpdateSettingsOptions,
} from '@platform360/security-dashboard/shared/update-settings-option';
import { SaveInstallationSettingsRequest } from '@platform360/security-dashboard/web/api/installations';
import SettingsDrawer, {
    SettingsFormValues,
} from '@platform360/security-dashboard/web/components/Installations/SettingsDrawer/SettingsDrawer';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useSaveInstallationSettings } from '@platform360/security-dashboard/web/mutations';
import {
    useInstallationInfoQuery,
    useInstallationSettingsQuery,
} from '@platform360/security-dashboard/web/queries';
import { Skeleton } from '@plesk/ui-library';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export type SingleSettingsDrawerProps = ClosableProps;

type SingleSettingsFormValues = {
    updates: {
        core: UpdateSettingsOption | 'parent';
        plugins: UpdateSettingsOption | 'parent';
        themes: UpdateSettingsOption | 'parent';
        safeUpdate: SafeUpdateOption | 'parent';
    };
};

const formValuesDefaultState: SingleSettingsFormValues = Object.freeze({
    updates: {
        core: UpdateSettingsOptions.notSet,
        plugins: UpdateSettingsOptions.notSet,
        themes: UpdateSettingsOptions.notSet,
        safeUpdate: SafeUpdateOptions.notSet,
    },
});

const SingleSettingsDrawer = ({ isOpen, onClose, ...props }: SingleSettingsDrawerProps) => {
    const { installationId: instId } = useParams<{ installationId: string }>();
    const installationId = Number(instId);
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const {
        data: installationData,
        isLoading: isInstallationLoading,
        refetch: fetchInstallation,
    } = useInstallationInfoQuery({
        variables: {
            installationId,
        },
        enabled: false,
    });

    const {
        data: installationSettings,
        isLoading: areSettingsLoading,
        error,
        isError,
        refetch: fetchSettings,
    } = useInstallationSettingsQuery({
        variables: {
            installationId,
        },
        enabled: false,
        meta: {
            defaultErrorHandler: false,
        },
    });

    const formValues: SettingsFormValues = useMemo(() => {
        if (!installationSettings) {
            return formValuesDefaultState;
        }
        const {
            updates: { core, plugins, themes, safeUpdate },
        } = installationSettings;
        return {
            updates: {
                core: core.value ?? 'parent',
                themes: themes.value ?? 'parent',
                plugins: plugins.value ?? 'parent',
                safeUpdate: safeUpdate.value ?? 'parent',
            },
        };
    }, [installationSettings]);

    const translate = useTranslate('security-dashboard.Installations.SettingsDrawer');
    const { successToast } = useToaster();
    const { mutate: saveSettings, isPending: isSaving } = useSaveInstallationSettings({
        onSuccess: () => {
            successToast(translate('successToast'));
            onClose();
        },
    });

    useEffectOnce(() => {
        // We should fetch data only once, without any re-fetches to avoid data collisions.
        void fetchInstallation();
        void fetchSettings();
    });

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpSglAutoupdateSettingsShown();
        } else {
            analyticsEvents.wpSglAutoupdateSettingsClosed();
        }
    }, [isOpen, analyticsEvents]);

    const handleSave = (request: SaveInstallationSettingsRequest) => {
        saveSettings({
            variables: {
                installationId,
                request,
            },
        });
    };

    const renderSubtitle = () => {
        if (isInstallationLoading) {
            return <Skeleton height={15} />;
        }

        return translate('subTitle.single', { installationName: installationData?.displayTitle });
    };

    return (
        <SettingsDrawer
            {...props}
            isOpen={isOpen}
            onClose={onClose}
            isError={isError}
            error={error}
            isLoading={areSettingsLoading}
            isSaving={isSaving}
            formValuesDefaultState={formValues}
            subtitle={renderSubtitle()}
            parentSettings={installationSettings?.updates}
            confirmationPopoverMessage={translate('confirmationPopover.description.single', {
                installationName: installationData?.displayTitle,
            })}
            onSave={handleSave}
        />
    );
};

export default SingleSettingsDrawer;
