// Copyright 2024. WebPros International GmbH. All rights reserved.

import { memo, useEffect } from 'react';
import BusinessProfile from './BusinessProfile';
import useModal, { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';
import { UpdateBusinessProfilePayload } from '@platform360/accounts/web/api/me';
import { useNavigate } from 'react-router-dom';
import useUpdateBusinessProfileMutation from '@platform360/accounts/web/mutations/useUpdateBusinessProfileMutation';
import useFirstTime from './useFirstTime';
import useTour from './useTour';
import { useBusinessProfileQuery } from '@platform360/accounts/web/queries';

type BusinessProfileContainerProps = {
    modalPath: string;
};

const BusinessProfileContainer = ({ modalPath }: BusinessProfileContainerProps) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useModal(modalPath);
    const getModalUrl = useGetModalUrl();
    const returnUrl = getModalUrl(modalPath, false);
    const {
        data: defaultValues,
        isLoading,
        refetch: loadBusinessProfile,
    } = useBusinessProfileQuery({
        enabled: false,
    });
    const { isFirstTime, setAnswerLater } = useFirstTime(modalPath, defaultValues);
    const { tour, showTour, hideTour } = useTour();
    const { isPending: isSubmit, mutate: submit } = useUpdateBusinessProfileMutation({
        onSuccess: () => {
            navigate(returnUrl);
        },
    });
    const handleSubmit = (values: UpdateBusinessProfilePayload) => {
        submit(values);
    };
    const handleLater = () => {
        navigate(returnUrl);
        setAnswerLater();
        showTour();

        // hide the tour after 5 seconds timeout automatically
        setTimeout(hideTour, 5000);
    };

    useEffect(() => {
        // prevent unnecessary api calls due to rerenders from parent
        if (!defaultValues) {
            void loadBusinessProfile();
        }
    }, [defaultValues, loadBusinessProfile]);

    if (isLoading || !defaultValues) {
        // prevent render form without saved values
        return null;
    }

    return (
        <>
            <BusinessProfile
                isOpen={isOpen}
                defaultValues={defaultValues}
                onClose={() => setIsOpen(false)}
                onLater={isFirstTime ? handleLater : undefined}
                onSubmit={handleSubmit}
                state={isSubmit ? 'submit' : undefined}
            />
            {tour}
        </>
    );
};

export default memo(BusinessProfileContainer);
