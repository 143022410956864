// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    ToolbarGroup,
    Toolbar as UILibToolbar,
    SearchBar,
    ToolbarExpander,
    Button,
    Badge,
    Icon,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { InstallationVulnerability } from '@platform360/security-dashboard/web/types';
import UpdateConfirmation from '@platform360/security-dashboard/web/components/Installations/WebsiteDrawer/TabVulnerabilities/UpdateConfirmation';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

type ToolbarProps = {
    selectedItems: string[];
    displayTitle: string;
    searchValue?: string;
    onSearch?: (value: string) => void;
    updateOutdatedHandler: () => void;
    updateBtnDisabled: boolean;
    isFilterOpen: boolean;
    filter: InstallationComponent[];
    onFilterClick: () => void;
    enableAfterUpdate: boolean;
    setEnableAfterUpdate: (b: boolean) => void;
    data: InstallationVulnerability[];
    isProcessing: boolean;
};

export const Toolbar = ({
    selectedItems,
    displayTitle,
    searchValue,
    onSearch,
    updateOutdatedHandler,
    updateBtnDisabled,
    isFilterOpen,
    onFilterClick,
    filter,
    enableAfterUpdate,
    setEnableAfterUpdate,
    data,
    isProcessing,
}: ToolbarProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translateVulnerabilities = useTranslate(
        'security-dashboard.Installations.WebsiteDrawer.tabs.vulnerabilities',
    );
    const translate = useTranslate(
        'security-dashboard.Installations.WebsiteDrawer.tabs.vulnerabilities.toolbar',
    );

    const renderUpdateButton = () => {
        if (isProcessing) {
            return (
                <Button intent="primary" disabled>
                    <Icon name={'refresh'} animation={'spin'} size={'12'} />
                    {'  '}
                    {translate('actions.buttons.processing')}
                </Button>
            );
        }

        if (updateBtnDisabled) {
            return (
                <Button
                    intent="primary"
                    disabled={updateBtnDisabled}
                    tooltip={translateVulnerabilities('updateByProtect')}
                >
                    {translate('actions.buttons.update')}
                </Button>
            );
        }

        return (
            <UpdateConfirmation
                onClick={updateOutdatedHandler}
                onShow={() =>
                    analyticsEvents.wpSglSiteVulnerabilityUpdateClick(selectedItems.length)
                }
                target={
                    <Button intent="primary" disabled={updateBtnDisabled}>
                        {translate('actions.buttons.update')}
                    </Button>
                }
                data={data}
                enableAfterUpdate={enableAfterUpdate}
                selectedItems={selectedItems}
                setEnableAfterUpdate={setEnableAfterUpdate}
                siteDisplayTitle={displayTitle}
            />
        );
    };

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('actions.title')}>{renderUpdateButton()}</ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('filters.groupFiltersTitle')}>
                <Badge intent="info" hidden={filter.length === 0}>
                    <Button
                        icon={filter.length > 0 ? 'filter-check-mark' : 'filter'}
                        state={isFilterOpen ? 'active' : undefined}
                        onClick={onFilterClick}
                        data-type="vulnerability-component-filter"
                    />
                </Badge>
            </ToolbarGroup>
            <ToolbarGroup title={translate('filters.search.title')}>
                <SearchBar
                    minWidth={300}
                    inputProps={{
                        value: searchValue,
                        placeholder: translate('filters.search.placeholder'),
                    }}
                    onSearch={(value: string) => onSearch?.(value)}
                    onTyping={onSearch}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
