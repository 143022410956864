// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { FilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getClients } from '@platform360/server-inventory/web/api/servers';

export type UseServerClientsQueryVariables = {
    serverId: number;
} & PaginatedQuery &
    FilterQuery;

export type UseServerClientsQueryData = PromiseValue<ReturnType<typeof getClients>>;

const useServerClientsQuery = createQuery<
    UseServerClientsQueryVariables,
    UseServerClientsQueryData
>({
    queryName: 'server-inventory/useServerClientsQuery',
    fetcher: getClients,
    useQuery,
});

export default useServerClientsQuery;
