// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';
import InstallationSummaryPanel from './InstallationSummaryPanel';

type InstallationAssetsSummaryProps = {
    installationId: number;
    title: string;
    vulnerabilities?: number;
    outdated?: number | undefined;
    onClickVulnerable: () => void;
    onClickUpdate: () => void;
    onClickUpdateConfirmation: () => void;
    onClickOutdated: () => void;
    updateButtonText: TranslationProps;
    componentType: InstallationComponent;
    isUpdating: boolean;
};

const InstallationAssetsSummary = ({
    installationId,
    title,
    vulnerabilities,
    outdated,
    onClickUpdate,
    onClickUpdateConfirmation,
    onClickOutdated,
    onClickVulnerable,
    updateButtonText,
    componentType,
    isUpdating,
}: InstallationAssetsSummaryProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.InstallationSummary.AssetSummary',
    );

    const vulnerabilitiesText = vulnerabilities
        ? translate('vulnerabilities', {
              count: vulnerabilities,
          })
        : undefined;

    const outdatedText = outdated ? translate('outdated', { count: outdated }) : undefined;

    return (
        <InstallationSummaryPanel
            outdatedText={outdatedText}
            vulnerableText={vulnerabilitiesText}
            installationId={installationId}
            title={<Text intent="muted">{title}</Text>}
            onClickUpdate={onClickUpdate}
            onClickUpdateConfirmation={onClickUpdateConfirmation}
            onClickOutdated={onClickOutdated}
            onClickVulnerable={onClickVulnerable}
            updateButtonText={updateButtonText}
            componentType={componentType}
            isUpdating={isUpdating}
        />
    );
};

export default InstallationAssetsSummary;
