// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getInstallationInfo } from '@platform360/security-dashboard/web/api/installations';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseInstallationInfoQueryVariables = { installationId: number };

export type UseInstallationInfoQueryData = PromiseValue<ReturnType<typeof getInstallationInfo>>;

const useInstallationInfoQuery = createQuery<
    UseInstallationInfoQueryVariables,
    UseInstallationInfoQueryData
>({
    queryName: 'security-dashboard/useInstallationInfoQuery',
    fetcher: getInstallationInfo,
    useQuery: (options) =>
        useQuery({
            refetchOnWindowFocus: false,
            ...options,
        }),
});

export default useInstallationInfoQuery;
