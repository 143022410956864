// Copyright 2024. WebPros International GmbH. All rights reserved.

import { OperationType } from '@platform360/security-dashboard/shared/operation-type';
import { OperationSubType } from '@platform360/security-dashboard/shared/operation-sub-type';
import { OperationStatus } from '@platform360/security-dashboard/shared/operation-status';

export const WS_NAMESPACE = 'security-dashboard';

type TaskStep = {
    code: string;
    title: string;
    progress: number;
    status: 'not_started' | 'started' | 'running' | 'canceled' | 'error' | 'done';
};

export type TaskInfo = {
    title: string;
    steps: TaskStep[];
};

export type Operation = {
    type: OperationType;
    subType: OperationSubType;
    status: OperationStatus;
    success: number;
    failed: number;
};

export type TaskCompletedEventPayload = {
    operation?: Operation;
};

export type TaskUpdatedEventPayload = {
    id: number;
    info: TaskInfo;
};

export type EventsMap = {
    /* eslint-disable @typescript-eslint/naming-convention */
    'server-added': () => void;
    'notification-created': () => void;
    'task-updated': (payload: TaskUpdatedEventPayload) => void;
    'task-completed': (payload: TaskCompletedEventPayload) => void;
    'installations-found': () => void;
    'installation-detached': () => void;
    /* eslint-enable @typescript-eslint/naming-convention */
};
