// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment, ReactNode } from 'react';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class ValidationError<T extends Record<string, unknown>> extends Error {
    public readonly data: T;

    public constructor(data: T) {
        super();
        this.name = 'ValidationError';
        this.data = data;
        this.message = 'Validation error';

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (Error.captureStackTrace === undefined) {
            this.stack = new Error().stack;
        } else {
            Error.captureStackTrace(this, ValidationError);
        }
    }
}

export const toFlatMessages = (errors: Record<string, string[]>) =>
    Object.values(errors).reduce((acc, error) => {
        error.forEach((message) => {
            acc.push(message);
        });
        return acc;
    }, []);

export const translateFlatMessages = (
    messages: string[],
    wrapElement: (el: ReactNode) => ReactNode,
) =>
    messages.map((message, i) => {
        // eslint-disable-next-line react/no-array-index-key, deprecation/deprecation
        const element = <Translate content={message} fallback={message} key={i.toString()} />;
        if (typeof wrapElement === 'function') {
            // eslint-disable-next-line react/no-array-index-key
            return <Fragment key={i.toString()}>{wrapElement(element)}</Fragment>;
        }
        return element;
    });
