// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';

/**
 * Scroll to top of page
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 *
 * @return {null}
 */
const ScrollToTop = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    });

    return null;
};

export default ScrollToTop;
