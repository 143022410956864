// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext } from 'react';
import { AuthState, initialAuthState, Session } from './auth-state';
import { Branding } from '@platform360/libs/shared-web/types';

export type LoginOptions = {
    email?: string;
    branding?: Branding;
    message?: string;
    success?: string;
    passwordless?: boolean;
    redirectUrl?: string;
    backUrl?: string;
    prompt?: 'none' | 'login' | 'consent' | 'select_account';
};

/**
 * Contains the authenticated state and authentication methods provided by the `useAuth` hook.
 */
export type AuthContextInterface = {
    /**
     * After the browser redirects back to the callback page,
     * call `processLogout` to handle logout processing steps.
     * Clears the application session, notifies external services and sends
     * statistics.
     */
    processLogout: () => Promise<void>;

    /**
     * Redirects to auth provider logout URL to clear auth session and logout the user.
     */
    logout: () => void;

    /**
     * Performs embedded login via user credentials and redirects to auth provider
     */
    login: (options?: LoginOptions) => Promise<void>;

    /**
     * After the browser redirects back to the callback page,
     * call `processAuthentication` to handle success and error
     * responses from auth provider. If the response is successful, results
     * will be valid according to their expiration times.
     *
     * @throws {EmailNotVerifiedException} If the user does not verify their email.
     */
    processAuthentication: () => Promise<Session>;

    /**
     * Notifies external services to update authentication state when a user logs out.
     * This function can be used to terminate user sessions or re-issue JWT tokens.
     */
    notifyExternalServicesOnLogout: () => Promise<void>;

    /**
     * Contains authorization error fired in auth operations
     */
    error?: Error;
} & AuthState;

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

export const initialContext = {
    ...initialAuthState,
    logout: stub,
    login: stub,
    processLogout: stub,
    processAuthentication: stub,
    notifyExternalServicesOnLogout: stub,
};

/**
 * The Auth Context
 */
export const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
