// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { signUp } from '@platform360/web-installer/web/api/web-installer';

export type UseCustomInstallOptions = {
    onSuccess: () => void;
    onError: () => void;
};

const useSignUp = ({ onSuccess, onError }: UseCustomInstallOptions) =>
    useMutation({
        mutationFn: signUp,
        onSuccess,
        onError,
    });

export default useSignUp;
