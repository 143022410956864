// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Icon } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './SearchButton.module.less';
import useShowButtonText from './useShowButtonText';

type SearchButtonProps = {
    openSearch: () => void;
};

const SearchButton = ({ openSearch }: SearchButtonProps) => {
    const translate = useTranslate('Page.GlobalSearch.SearchButton');
    const showButtonText = useShowButtonText();
    return (
        <Button
            className={styles.root}
            icon={<Icon name="search" size="16" className={styles.icon} />}
            ghost
            onClick={openSearch}
            data-type="search-button"
        >
            {showButtonText && translate('search')}
        </Button>
    );
};
export default SearchButton;
