// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SET_CALCULATED_COST } from '@platform360/licenses/web/units/adjust/constants/actions';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';

export type CalculatedAction = {
    type: typeof SET_CALCULATED_COST;
    payload: CalculatedSubscriptionState;
};

export const calculated = (summaryCost: CalculatedSubscriptionState): CalculatedAction => ({
    type: SET_CALCULATED_COST,
    payload: summaryCost,
});
