// Copyright 2024. WebPros International GmbH. All rights reserved.

import { List, Radio } from '@plesk/ui-library';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '@platform360/licenses/web/WizardCommon/style.module.css';
import Cost from '@platform360/licenses/web/components/Cost';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';

const PRE_SELECTED_KEY = 'pre-selected';
const CURRENT = 'currentEdition';

const preSelectedOffer = (offer, multiOffer, preSelectedProductNamePrefix) => {
    if ((offer && !multiOffer) || (offer && offer.subscriptionItem)) {
        return PRE_SELECTED_KEY;
    }

    if (multiOffer?.upsells && preSelectedProductNamePrefix) {
        const index = multiOffer.upsells.findIndex(({ productName }) =>
            productName.startsWith(preSelectedProductNamePrefix),
        );
        if (index >= 0) {
            return `${index + 1}`;
        }
    }

    if (multiOffer && multiOffer.subscriptionItem) {
        return CURRENT;
    }
    return [];
};

class UpgradeOffer extends Component {
    state = {
        rowSelected: preSelectedOffer(
            this.props.offer,
            this.props.multiOffer,
            this.props.preSelectedProductNamePrefix,
        ),
    };

    componentDidMount() {
        const { multiOffer, preSelectedProductNamePrefix } = this.props;
        const { rowSelected } = this.state;
        if (
            preSelectedProductNamePrefix &&
            rowSelected !== PRE_SELECTED_KEY &&
            rowSelected !== CURRENT &&
            multiOffer
        ) {
            this.handleOfferChange({
                row: rowSelected,
                subscriptionItem: multiOffer.subscriptionItem,
                productName: multiOffer.productName,
                extensionCatalogCode: multiOffer.extensionCatalogCode,
                currency: multiOffer.currency,
                upsells: multiOffer.upsells.filter(({ productName }) =>
                    productName.startsWith(preSelectedProductNamePrefix),
                ),
            });
        }
    }

    handleOfferChange = (extension) => {
        const { onOfferChange } = this.props;
        this.setState({
            rowSelected: extension.row,
        });
        onOfferChange(extension);
    };

    addPurchasedRow = (offerOrMultioffer, i) => ({
        buy: (
            <label disabled={'true'} className={styles.container}>
                <input
                    type="radio"
                    checked={this.state.rowSelected === i.toString()}
                    disabled
                    data-type="MultiOffer--button"
                    onChange={(e) => {
                        e.preventDefault();
                    }}
                />
                <span disabled={'true'} className={styles.checkmark} />
            </label>
        ),
        key: i.toString(),
        product: offerOrMultioffer.productName,
        price: <Translate content="purchased" />,
    });

    addRow = (key, offer, i) => {
        const { disableSelect } = this.props;
        return {
            buy: (
                <Radio
                    data-type={'MultiOffer--button'}
                    name={offer.productName}
                    checked={this.state.rowSelected === i.toString()}
                    disabled={disableSelect}
                    onChange={() => {
                        this.handleOfferChange({
                            row: i.toString(),
                            subscriptionItem: offer.subscriptionItem,
                            productName: offer.productName,
                            extensionCatalogCode: offer.extensionCatalogCode,
                            currency: offer.currency,
                            upsells: [key],
                        });
                    }}
                />
            ),
            key: i.toString(),
            product: key.productName,
            price: (
                <>
                    <Translate content="startingFrom" />
                    &nbsp;
                    <BillingInterval period={key.billignPeriod}>
                        <Cost
                            value={key.price}
                            currency={offer.currency}
                            period={key.billingPeriod}
                        />
                    </BillingInterval>
                </>
            ),
        };
    };

    getOfferUpgradesData = (offer) => {
        const data = [];
        let i = 0;
        if (offer.subscriptionItem) {
            data.push(this.addPurchasedRow(offer, CURRENT));
        }
        if (offer.upsells) {
            offer.upsells.forEach((key) => {
                data.push(this.addRow(key, offer, ++i));
            });
        } else {
            data.push(this.addRow(offer.upsell, offer, PRE_SELECTED_KEY));
        }

        return data;
    };

    render() {
        const { offer, multiOffer } = this.props;
        const data =
            offer && !multiOffer
                ? this.getOfferUpgradesData(offer)
                : this.getOfferUpgradesData(multiOffer);

        return (
            <List
                selection={[this.state.rowSelected]}
                columns={[{ key: 'buy' }, { key: 'product' }, { key: 'price' }]}
                data={data}
                vertical={false}
            />
        );
    }
}

UpgradeOffer.propTypes = {
    preSelectedProductNamePrefix: PropTypes.string,
    offer: PropTypes.object,
    multiOffer: PropTypes.object,
    onOfferChange: PropTypes.func.isRequired,
    disableSelect: PropTypes.bool.isRequired,
};

UpgradeOffer.defaultProps = {
    preSelectedProductNamePrefix: null,
    offer: null,
    multiOffer: { subscriptionItem: null, upsells: [] },
};

export default UpgradeOffer;
