// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type StatusProps = {
    status: number;
    statusText: string;
};

const Status = ({ status, statusText }: StatusProps) => {
    const translate = useTranslate('admin-panel.security-dashboard.Requests.Network.Status');

    return (
        <span>
            {translate('status', {
                status: (
                    <Text intent={status < 500 ? 'success' : 'danger'}>
                        {`${status} ${statusText}`}
                    </Text>
                ),
            })}
        </span>
    );
};

export default Status;
