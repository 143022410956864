// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ListEmptyView } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import happyServerImage from './images/happy-server.svg';

type ConnectServerProps = {
    connectLink: string;
};

const ConnectServer = ({ connectLink }: ConnectServerProps) => {
    const translate = useTranslate('monitoring.ServerViewMonitoring');

    return (
        <ListEmptyView
            image={happyServerImage}
            title={translate('connectServerTitle')}
            description={translate('connectServerDescription')}
            actions={
                <Button
                    component="a"
                    intent="primary"
                    rel="noopener noreferrer"
                    href={connectLink}
                    data-type="connect-monitoring-link"
                >
                    {translate('connectServerButton')}
                </Button>
            }
        />
    );
};

export default ConnectServer;
