// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeServer } from '@platform360/security-dashboard/web/api/servers';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import {
    useServersSummaryQuery,
    useInstallationsSummaryQuery,
    useVulnerabilitiesSummaryQuery,
} from '@platform360/security-dashboard/web/queries';

type MutationOptions = {
    onSuccess: (successServersCount: number) => void;
};

type Server = {
    serverId: number;
    displayTitle: string;
    deleted?: boolean;
};

const useRemoveServersMutation = ({ onSuccess }: MutationOptions) => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useRemoveServersMutation');
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: (servers: Server[]) =>
            Promise.all(
                servers.map(async ({ serverId, displayTitle }) => {
                    try {
                        await removeServer(serverId);
                        return {
                            serverId,
                            displayTitle,
                            deleted: true,
                        };
                    } catch {
                        return {
                            serverId,
                            displayTitle,
                            deleted: false,
                        };
                    }
                }),
            ),
        meta: {
            defaultErrorHandler: false,
        },
        onSuccess: async (results: Server[]) => {
            const successServers = results.filter(({ deleted }) => deleted);
            const successServersCount = successServers.length;
            const failedServersCount = results.length - successServersCount;
            await Promise.all([
                queryClient.cancelQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServersSummaryQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useInstallationsSummaryQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useVulnerabilitiesSummaryQuery.getQueryKey(),
                }),
            ]);

            onSuccess(successServersCount);

            if (successServersCount > 1) {
                successToast(
                    translate('notifySeveralServers', {
                        selectedServersCount: successServersCount,
                    }),
                );
                return;
            }

            const [successServer] = successServers;

            if (successServer) {
                successToast(
                    translate('notifySingleServer', {
                        selectedServerTitle: successServer.displayTitle,
                    }),
                );
                return;
            }

            if (failedServersCount > 1) {
                errorToast(
                    translate('notifySeveralServersFail', {
                        selectedServersCount: failedServersCount,
                    }),
                );
                return;
            }

            const failedServer = results.find((res) => !res.deleted);

            if (failedServer) {
                errorToast(
                    translate('notifySingleServerFail', {
                        selectedServerTitle: failedServer.displayTitle,
                    }),
                );
            }
        },
    });
};

export default useRemoveServersMutation;
