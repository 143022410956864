// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type LegalLinkProps = {
    to: string;
    children: ReactNode;
};

const LegalLink = ({ to, children }: LegalLinkProps) => (
    <RouterLink to={to} target="_blank">
        {children}
    </RouterLink>
);

export default LegalLink;
