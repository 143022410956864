// Copyright 2024. WebPros International GmbH. All rights reserved.

import { combineReducers } from 'redux';
import * as ka from '@platform360/licenses/web/store/index';
import licensesReducer from '@platform360/licenses/web/reducer';

const rootReducer = combineReducers({
    [ka.STATE_KEY]: ka.default,
    licenses: licensesReducer,
});

export default rootReducer;
