// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, useEffect, createContext, useState, useCallback } from 'react';
import { LocaleProvider as UILibraryLocaleProvider } from '@plesk/ui-library';
import { applyLocale, detectLocaleCode, findLocaleByCode } from './helpers';
import { LocaleCode, Locales } from '@platform360/libs/shared-web/locale/types';
import { getCookie } from '@platform360/libs/shared-web/cookies';
import { useLocalStorage } from 'usehooks-ts';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const LocalesContext = createContext<Locales>({});
export const LocaleCodeStateContext = createContext<LocaleCode>('en');
export const LocaleCodeDispatchContext = createContext<((value: LocaleCode) => void) | undefined>(
    undefined,
);

type LocaleProviderProps = {
    locales: Locales;
    fallbackLocaleCode: LocaleCode;
    children: ReactElement;
    onLocaleChange?: (localeCode: LocaleCode) => void;
    getInitialLocaleCode?: () => Promise<string>;
};

export const LocaleProvider = ({
    locales,
    fallbackLocaleCode,
    children,
    onLocaleChange,
    getInitialLocaleCode,
}: LocaleProviderProps) => {
    const [localStorageLocaleCode] = useLocalStorage('locale', undefined);
    const [localeCode, setLocaleCode] = useState<LocaleCode>(() => {
        const cookieLocaleCode = getCookie('locale');
        if (cookieLocaleCode && locales[cookieLocaleCode]) {
            return cookieLocaleCode;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (localStorageLocaleCode && locales[localStorageLocaleCode]) {
            return localStorageLocaleCode;
        }

        return detectLocaleCode(locales, fallbackLocaleCode);
    });

    useEffect(() => {
        applyLocale(localeCode, locales);
    }, [localeCode, locales]);

    useEffectOnce(() => {
        if (!getInitialLocaleCode) {
            return;
        }

        const fetch = async () => {
            const localeCode = await getInitialLocaleCode();

            if (localeCode && locales[localeCode]) {
                setLocaleCode(localeCode);
            }
        };

        void fetch();
    });

    const handleSetLocaleCode = useCallback(
        (value: LocaleCode) => {
            setLocaleCode(value);
            onLocaleChange?.(value);
        },
        [onLocaleChange],
    );

    return (
        <LocalesContext.Provider value={locales}>
            <LocaleCodeStateContext.Provider value={localeCode}>
                <LocaleCodeDispatchContext.Provider value={handleSetLocaleCode}>
                    <UILibraryLocaleProvider
                        messages={findLocaleByCode(localeCode, locales, fallbackLocaleCode)}
                    >
                        {children}
                    </UILibraryLocaleProvider>
                </LocaleCodeDispatchContext.Provider>
            </LocaleCodeStateContext.Provider>
        </LocalesContext.Provider>
    );
};

export default LocaleProvider;
