// Copyright 2024. WebPros International GmbH. All rights reserved.

import logo from './logo.svg';
import logoCompact from './logo-compact.svg';
import { useMediaQuery } from 'usehooks-ts';

export const Logo = ({ ...props }) => {
    const isCompactLogoShown = useMediaQuery('(max-width: 767px)');

    return <img src={isCompactLogoShown ? logoCompact : logo} alt="WP Guardian" {...props} />;
};

export default Logo;
