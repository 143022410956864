// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import ErrorMessage from '@platform360/auth/web/components/ErrorMessage/ErrorMessage';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import validate, {
    isNotEmpty,
    ValidationErrors,
} from '@platform360/libs/shared-web/helpers/formValidator';
import styles from '@platform360/auth/web/components/Login/LoginForm/LoginForm.module.css';
import { Form, Button, FormFieldText, FormState, Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { PrivacyPolicy } from '@platform360/auth/web/components/PrivacyPolicy';

type LoginFormProps = {
    // forwarding user email to ResetPassword drawer to pre-fill the input
    onEmailChange: (email: string) => void;
    onSubmit: (email: string) => Promise<void>;
    errorMessage?: string;
};

type FormValues = {
    email: string;
};

const DataTestIds = {
    FORM: 'Login--form',
    RESET_PASSWORD_LINK: 'Login--resetPasswordLink',
};

const LoginForm = ({ onSubmit, onEmailChange, errorMessage }: LoginFormProps) => {
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl');
    const resetPasswordUrl = redirectUrl
        ? `reset-password?redirectUrl=${redirectUrl}`
        : 'reset-password';

    const translate = useTranslate('Auth.Login');
    const rootTranslate = useTranslate();

    const [errors, setErrors] = useState<ValidationErrors>();
    const [state, setState] = useState<FormState>();

    const handleSubmit = async (values: FormValues) => {
        const { isValid, errors } = validate(values, {
            email: [
                {
                    message: rootTranslate('isEmpty'),
                    validator: isNotEmpty,
                },
            ],
        });

        if (!isValid) {
            setErrors(errors);
            return;
        }

        try {
            setErrors({});
            setState('submit');

            await onSubmit(values.email);
        } finally {
            setState(undefined);
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            errors={errors}
            state={state}
            applyButton={false}
            submitButton={false}
            cancelButton={false}
            hideRequiredLegend
            vertical
            data-type={DataTestIds.FORM}
        >
            <div className={styles.errorMessageWrapper}>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
            <FormFieldText
                onChange={onEmailChange}
                name="email"
                label={translate('emailLabel')}
                size="fill"
                className={styles.formField}
                autoFocus
                required
            />
            <Button className={styles.loginButton} type="submit" intent="primary" size="md" fill>
                {translate('submitButton')}
            </Button>
            <PrivacyPolicy />
            <Link
                component={RouterLink}
                to={resetPasswordUrl}
                data-type={DataTestIds.RESET_PASSWORD_LINK}
            >
                {translate('forgotYourPasswordLink')}
            </Link>
        </Form>
    );
};

export default LoginForm;
