// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './PageIntro.module.less';
import { TranslationProps } from '@plesk/ui-library';

type PageIntroProps = {
    title: TranslationProps;
    subTitle: TranslationProps;
    children: ReactNode;
    image: {
        src: string;
        src2x: string;
    };
};

export const PageIntro = ({ title, subTitle, children, image: { src, src2x } }: PageIntroProps) => (
    <>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.text}>
            <h3 className={styles.subTitle}>{subTitle}</h3>
            {children}
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={src} srcSet={`${src2x} 2x`} className={styles.image} />
        </div>
    </>
);

export default PageIntro;
