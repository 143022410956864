// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useDebounceValue } from 'usehooks-ts';
import { useLicensesQuery } from '@platform360/admin-panel/web/queries/security-dashboard';
import { useState } from 'react';
import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import filteredImage from '@plesk/ui-library/dist/images/filtered.png';
import { ContentLoader, Heading, SearchBar } from '@plesk/ui-library';
import LicenseCard from './LicenseCard';

const SEARCH_DEBOUNCE_MS = 800;

export const Licenses = () => {
    const translate = useTranslate('admin-panel.security-dashboard.Licenses');
    const [filter, setFilter] = useState('');
    const [debouncedFilter] = useDebounceValue(filter, SEARCH_DEBOUNCE_MS);

    const { data = [], isLoading } = useLicensesQuery({
        variables: {
            filter: debouncedFilter,
        },
    });

    const renderEmptyView = () => {
        if (isLoading) {
            return <ContentLoader />;
        }

        if (data.length > 0) {
            return null;
        }

        const hasFilter = !!debouncedFilter;

        return (
            <ListEmptyView
                reason="filtered"
                image={filteredImage}
                title={
                    hasFilter
                        ? translate('emptyView.noResults.title')
                        : translate('emptyView.initialState.title')
                }
                description={
                    hasFilter
                        ? translate('emptyView.noResults.description')
                        : translate('emptyView.initialState.description')
                }
            />
        );
    };

    return (
        <>
            <Heading>{translate('title')}</Heading>
            <SearchBar
                loading={isLoading}
                onTyping={setFilter}
                inputProps={{ placeholder: translate('searchPlaceholder'), autoFocus: true }}
            />
            {renderEmptyView()}
            <br />
            {data.map((license) => (
                <LicenseCard key={license.id} license={license} />
            ))}
        </>
    );
};
