// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Unbekannter Fehler: {message}.',
        newServer: 'Ein Server mit derselben Adresse ist bereits vorhanden.',
        domainName: 'Der Domainname kann nicht aufgelöst werden.',
        serverMisconfigured: 'Der Server ist nicht richtig konfiguriert und kann nicht hinzugefügt werden.',
        pleskConfiguration: 'Diese Plesk-Version wird nicht unterstützt (unter {pleskMinVersion}) oder Plesk ist falsch konfiguriert.',
        unsupportedServerVersion: 'Die installierte Version von Plesk ist veraltet und kann nicht hinzugefügt werden. {kbLink}',
        connection: 'Die Netzwerkverbindung wurde abgebrochen.',
        connectionRefused: 'Die Netzwerkverbindung wurde abgelehnt. Überprüfen Sie die angegebene Domain und den Port.',
        timeout: 'Die Netzwerkverbindung zum Server ist abgelaufen.',
        apiKey: 'Der Remote-Server autorisiert diese Verbindung nicht. Fügen Sie den Server erneut zum Serverinventar hinzu.',
        httpsRequired: 'Der Server unterstützt HTTPS nicht.',
        certHasExpired: 'Zertifikat abgelaufen. {kbLink}',
        errTlsCertAltnameInvalid: 'Der Hostname oder die IP-Adresse des Servers stimmen nicht mit den AltNames des Zertifikats überein. {kbLink}',
        depthZeroSelfSignedCert: 'Ein selbstsigniertes Zertifikat ist installiert. {kbLink}',
        selfSignedCertInChain: 'Die Zertifikatskette enthält ein selbstsigniertes Zertifikat. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'Einstellungen für Benachrichtigungen wurden aktualisiert.',
        },
    },
    Layout: {
        title: 'Serverinventar',
        serversTitle: 'Server',
        clientsTitle: 'Kunden',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Einstellungen für Benachrichtigungen',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Benachrichtigungen',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Welche Benachrichtigungen gesendet werden sollen',
        notifyServerUnreachable: 'Server nicht erreichbar',
        notifyServerUnsupported: 'Server wird nicht unterstützt',
    },
    AllDomains: {
        title: 'Websites',
        name: 'Name',
        hostingType: 'Hosting-Typ',
        virtualHostingType: 'Website',
        forwardingHostingType: 'Weiterleitung',
        noneHostingType: 'Kein Hosting',
        mainDomainType: 'Hauptdomain',
        subDomainType: 'Subdomain',
        addonDomainType: 'Add-on-Domain',
        parkedDomainType: 'Alias',
        hostingServer: 'Hosting-Server',
        manage: 'Verwalten',
        manageLinkUnavailableHint: 'Plesk RESTful API 1.4.0 oder höher ist erforderlich, um einen direkten Link zum Plesk Panel herzustellen. Weitere Informationen finden Sie in der Dokumentation.',
        open: 'Öffnen',
    },
    AllClients: {
        name: 'Kontaktname',
        email: 'E-Mail-Adresse',
        login: 'Benutzername',
        type: 'Typ',
        status: 'Status',
        typeAdmin: 'Admin',
        typeReseller: 'Reseller',
        typeCustomer: 'Kunde',
        typeCustomerWithOwner: 'Kunde von {owner}',
        statusActive: 'Aktiv',
        statusSuspended: 'Gesperrt',
        hostingServer: 'Hosting-Server',
        manage: 'Verwalten',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Wird synchronisiert',
            installationRunning: 'Die Installation wird ausgeführt',
            installationFailure: 'Fehlerr bei der Installation',
            installationLicenseRequired: 'Lizenz benötigt',
            active: 'Aktiv, geschützt',
            inactiveUnreachable: 'Inaktiv, nicht erreichbar',
            inactiveUnreachableTooltip: 'Der Server kann seit mindestens 14 Tagen nicht erreicht werden',
            inactiveUnsupported: 'Inaktiv, nicht unterstützt',
            inactiveUnsupportedTooltip: 'Auf dem Server läuft eine nicht unterstützte Plesk-Version',
            insecure: 'Aktiv, nicht geschützt',
            outdated: 'Aktiv, veraltet',
            outdatedTooltip: '{version, select,_ {Ihre derzeitige Plesk-Version ist veraltet.}other {Ihre derzeitige Plesk-Version {version} ist veraltet.}} Wir empfehlen eine Aktualisierung Ihres Servers auf die neuste verfügbare Plesk Obsidian-Version und die Aktivierung der Auto-Aktualisierung.',
            unsupportedVersion: 'Aktiv, nicht unterstützt',
            unsupportedVersionTooltip: '{version, select,_ {Ihre derzeitige Plesk-Version wird nicht unterstützt}other {Ihre derzeitige Plesk-Version {version} wird nicht unterstützt}} und Ihr Server wird in 14 Tagen deaktiviert. Wir empfehlen eine Aktualisierung Ihres Servers auf die neuste verfügbare Plesk Obsidian-Version und die Aktivierung der Auto-Aktualisierung.',
            certificateWarning: 'Zertifikatswarnung',
            certificateError: 'Zertifikatsfehler',
            connectionBlocked: 'Verbindung blockiert',
            connectionError: 'Verbindungsfehler',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'Keine Installationsprotokolle gefunden.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select,_ {Ihre derzeitige Plesk-Version ist veraltet.}other {Ihre derzeitige Plesk-Version {version} ist veraltet.}}',
                        fixDescription: 'Wir empfehlen eine Aktualisierung Ihres Servers auf die neuste verfügbare Plesk Obsidian-Version und die Aktivierung der Auto-Aktualisierung. Lesen Sie die <link>Plesk Richtlinien zum Lebenszyklus</link>, um mehr zu erfahren.',
                        goToUpdatesButton: 'Zu {product}-Aktualisierungen gehen',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select,_ {Ihre derzeitige Plesk-Version wird nicht unterstützt}other {Ihre derzeitige Plesk-Version {version} wird nicht unterstützt}} und Ihr Server wird in 14 Tagen deaktiviert.',
                        fixDescription: 'Wir empfehlen eine Aktualisierung Ihres Servers auf die neuste verfügbare Plesk Obsidian-Version und die Aktivierung der Auto-Aktualisierung. Lesen Sie die <link>Plesk Richtlinien zum Lebenszyklus</link>, um mehr zu erfahren.',
                        goToUpdatesButton: 'Zu {product}-Aktualisierungen gehen',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'Da wir den Server 14 Tage lang nicht erreichen konnten, haben wir ihn als inaktiv markiert.',
                        lastCheckMessage: 'Letzte Überprüfung des Verbindungsstatus: {lastCheckConnection}',
                        fixDescription: 'Um ihn wieder zu verwenden, klicken Sie auf "Verbindung erneut überprüfen".',
                        recheckConnectionButton: 'Verbindung erneut überprüfen',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'Der Server ist als inaktiv markiert, denn auf ihm läuft {version, select,_ {eine nicht unterstützte Plesk-Version}other {eine nicht unterstützte Plesk-Version {version}}}.',
                        fixDescription: 'Um den Server zu aktivieren, müssen Sie Plesk aktualisieren und eine Server-Synchronisierung durchführen.',
                        goToUpdatesButton: 'Zu {product}-Aktualisierungen gehen',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Server hinzufügen',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Neuen Server hinzufügen',
                closeButton: 'Schließen',
                serverInstallTitle: '{productType} installieren',
                installNewProduct: 'Ein neues Produkt auf einem Linux-Server installieren',
                connectInstallation: 'Einen Server mit einem installierten Produkt verknüpfen',
                serverInstallDescription: '{productType} auf einem physischen oder virtuellen Server installieren und zum Serverinventar hinzufügen.',
                connectPleskTitle: 'Plesk-Server verknüpfen',
                connectPleskDescription: 'Server, auf dem Plesk installiert ist, zum Serverinventar hinzufügen.',
                connectCPanelTitle: 'cPanel-Server verknüpfen',
                connectCPanelDescription: 'Server, auf dem cPanel installiert ist, zum Serverinventar hinzufügen.',
            },
            CustomInstallationStep: {
                title: '{productType} automatisch auf einem Linux-Server installieren',
                backButton: 'Zurück',
                submitButton: 'Installieren',
                successMessage: '{productType} wird auf <b>{host}</b> installiert.',
            },
            AddServerWithRedirectStep: {
                title: 'Plesk-Server verknüpfen',
                description: 'Nachdem Sie auf "Verknüpfen" geklickt haben, werden Sie zu Plesk weitergeleitet. Melden Sie sich dort mit Administratorberechtigungen an.',
                proceed: 'Verknüpfen',
                proceedAnyway: 'Trotzdem verknüpfen',
                nextStep: 'Nächster Schritt',
                back: 'Zurück',
                addressLabel: 'Serverhostname',
                addressDescription: 'Server-URL und Port, um eine Verbindung zum herzustellen, z. B. "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'cPanel-Server verknüpfen',
                close: 'Schließen',
                back: 'Zurück',
                paragraph1: 'Laden Sie das cPanel-Plugin-Installationsprogramm herunter und führen Sie es aus: Kopieren Sie den folgenden Ausschnitt und fügen Sie ihn mit Root-Rechten in die Serverkonsole ein.',
                paragraph2: 'Sie benötigen einen <a>Plesk 360-API-Token</a>. Sie können einen neuen erstellen oder einen vorhandenen verwenden.',
                paragraph3: 'Der neu erstellte Server wird im Serverinventar angezeigt.',
                copied: 'Kopiert.',
            },
        },
    },
};