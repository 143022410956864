// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'Este email ya está registrado.',
        linkEmailMinimalInterval: 'Los mensajes de acción de email vinculado no pueden enviarse con demasiada frecuencia.',
        linkEmailUnknownError: 'Se ha producido un error. Compruebe su conexión a Internet o inténtelo más tarde.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Confirmar cancelación de activación',
                description: 'Va a cancelar la activación de la licencia de {product}. Puede proceder con la activación de la licencia en cualquier momento haciendo clic de nuevo en el enlace de activación. Si la licencia se ha comprado para otra cuenta, acceda a la cuenta y haga clic en el enlace de activación.',
                cancelButton: 'Cancelar',
                continueButton: 'Continuar con la activación',
            },
            MonitoringActivateLicense: {
                title: 'Confirme la activación de la licencia de Monitoring',
                description: 'Confirme la activación de la licencia de <b>{product}</b> para su cuenta 360 <mailto>{userEmail}</mailto>. La licencia solo puede activarse para una cuenta 360.',
                fixableErrorDescription: 'Algo salió mal durante la activación de la cuenta de Monitoring. Inténtelo de nuevo o envíe una petición de soporte.',
                nonFixableErrorDescription: 'Algo salió mal durante la activación de la licencia. Póngase en contacto con el proveedor de la licencia o envíe una petición de soporte.',
                loaderText: 'Su cuenta 360 Monitoring está siendo configurada',
                redirectText: 'Redireccionándole a 360 Monitoring',
                confirmButton: 'Confirmar',
                cancelButton: 'Cancelar',
                retryButton: 'Volver a intentar',
                gotItButton: 'Entendido',
                errors: {
                    linkError: 'El enlace de activación no es válido.',
                    unknownError: 'Error interno de servicio de activación.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'Error de activación de licencia',
            description: 'El enlace de activación no es correcto. Póngase en contacto con el proveedor de la licencia o envíe una petición de soporte.',
            gotIt: 'Entendido',
        },
    },
};