// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMemo } from 'react';
import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { IconName } from '@plesk/ui-library/types/src/components/Icon/Icon';

type WidgetProps = {
    navigateTo: string;
    isExternal?: boolean;
    title: TranslationProps;
    description: TranslationProps;
    icon: IconName;
};

const useWidgetsList = (): WidgetProps[] => {
    const translate = useTranslate('home-page.WidgetsList');

    return useMemo(
        () => [
            {
                title: translate('serverInventory.title'),
                description: translate('serverInventory.description'),
                icon: 'servers',
                navigateTo: '/si',
            },
            {
                title: translate('websiteInventory.title'),
                description: translate('websiteInventory.description'),
                icon: 'web',
                navigateTo: '/websites',
            },
            {
                title: translate('licenseManagement.title'),
                description: translate('licenseManagement.description'),
                icon: 'card-ribbon',
                navigateTo: '/licensing',
            },
            {
                title: translate('feedback.title'),
                description: translate('feedback.description'),
                icon: 'feedback',
                navigateTo: 'https://pt-research.typeform.com/to/BVtcGgRL',
                isExternal: true,
            },
        ],
        [translate],
    );
};

export default useWidgetsList;
