// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    FindServersResponse,
    FindServersRequest,
} from '@platform360/admin-panel/shared/api-contract';
import { FetcherOptions } from '@platform360/libs/shared-web/typings/api';

type FindServersOptions = FetcherOptions<FindServersRequest>;

export const findServers = async ({
    variables,
}: FindServersOptions): Promise<FindServersResponse> => {
    const {
        data: { data },
    } = await apiClient.get('/admin-panel/security-dashboard/agent-request/find-servers', {
        params: variables,
    });
    return data;
};
