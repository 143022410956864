// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type CuttableProps = {
    text: string;
    length: number;
};

export const Cuttable = ({ text, length }: CuttableProps) => {
    const translate = useTranslate('security-dashboard.Cuttable');
    const textExceedsDesiredLength = text.length > length;
    const [collapsed, setCollapsed] = useState(textExceedsDesiredLength);

    if (collapsed) {
        const partOfText = text.substring(0, length);
        return (
            <>
                {`${partOfText}...`}&nbsp;
                <Link onClick={() => setCollapsed(false)}>{translate('showMore')}</Link>
            </>
        );
    }

    return (
        <>
            {text}&nbsp;
            {textExceedsDesiredLength && (
                <Link onClick={() => setCollapsed(true)}>{translate('showLess')}</Link>
            )}
        </>
    );
};
