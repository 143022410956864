// Copyright 2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { StoreState } from '@platform360/licenses/web/store/types';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import { CleverBridgePaymentMethod } from '@platform360/licenses/web/components/PaymentMethod';

const mstp = (state: StoreState) => {
    const {
        onlineStoreData: { paymentInfo, changePaymentUrl },
    } = buyExtensionSelector(state);

    return {
        paymentInfo,
        changePaymentUrl,
    };
};

export default connect(mstp)(CleverBridgePaymentMethod);
