// Copyright 2024. WebPros International GmbH. All rights reserved.

import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { captureException } from '@sentry/browser';
import { toaster } from '@platform360/libs/shared-web/toaster';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { takeRequestIdFromAxiosError } from '@platform360/libs/shared-web/helpers/takeRequestIdFromAxiosError';
import { isClientError } from '@platform360/libs/shared-web/helpers/httpErrors';
import { TOKEN_RENEWAL_FAILURE_CODE } from '@platform360/libs/shared-web/helpers/apiClient';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

const dumpErrorResponse = (error: AxiosError, requestId: string | undefined) => {
    const response = JSON.stringify(error.response?.data);

    return (
        <>
            <p>{error.message}</p>
            <p>{`Request ID: ${requestId || '-'}`}</p>
            {response && (
                <p>
                    {'Response:'}
                    <br />
                    <code>{response}</code>
                </p>
            )}
        </>
    );
};

type AxiosErrorHandler = (error: AxiosError) => void;

const useApiDefaultErrorHandler = (): AxiosErrorHandler => {
    const translate = useTranslate();
    const { dumpResponse } = useConfig();

    return useCallback(
        (error: AxiosError) => {
            if (axios.isCancel(error)) {
                return;
            }

            if (error.code === AxiosError.ERR_NETWORK) {
                toaster.error(translate('errorNetworkIssue'), {
                    merge: true,
                });
                return;
            }

            if (error.code === TOKEN_RENEWAL_FAILURE_CODE) {
                toaster.error(translate('errorTokenRenewal'));
                return;
            }

            if (error.response?.status === 429) {
                toaster.error(translate('errorTooManyRequests'));
                return;
            }

            if (isClientError(error.response?.status || 0)) {
                return;
            }

            const requestId = takeRequestIdFromAxiosError(error);

            captureException(error);
            toaster.error(
                dumpResponse
                    ? dumpErrorResponse(error, requestId)
                    : translate(requestId ? 'errorDefaultWithReqId' : 'errorDefault', {
                          requestId,
                      }),
                {
                    merge: true,
                },
            );
        },
        [dumpResponse, translate],
    );
};

export default useApiDefaultErrorHandler;
