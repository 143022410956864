// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getServers } from '@platform360/server-inventory/web/api/servers';
import { createQuery } from '@platform360/libs/shared-web/query';
import useServerQuery from './useServerQuery';

export type UseServersQueryVariables = FilterQuery & PaginatedQuery;

export type UseServersQueryData = PromiseValue<ReturnType<typeof getServers>>;

const useServersQuery = createQuery<UseServersQueryVariables, UseServersQueryData>({
    queryName: 'server-inventory/useServersQuery',
    fetcher: getServers,
    useQuery: (options) => {
        const queryClient = useQueryClient();

        return useQuery({
            ...options,
            queryFn: async (params) => {
                const data = await options.queryFn(params);

                await Promise.all(
                    data.data.map(({ id }) =>
                        queryClient.cancelQueries({
                            queryKey: useServerQuery.getQueryKey({ id }),
                        }),
                    ),
                );
                data.data.forEach((server) => {
                    useServerQuery.setQueryData(
                        queryClient,
                        useServerQuery.getQueryKey({ id: server.id }),
                        server,
                    );
                });

                return data;
            },
        });
    },
});

export default useServersQuery;
