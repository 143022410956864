// Copyright 2024. WebPros International GmbH. All rights reserved.

import { signUp } from '@platform360/auth/web/api/sign-up';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useMutation } from '@tanstack/react-query';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const useSignUp = (onSuccess: () => void) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { error, ...rest } = useMutation({
        mutationFn: signUp,
        onSuccess,
    });

    return { ...rest, errors: translateValidationErrors(getValidationErrors(error)) };
};

export default useSignUp;
