// Copyright 2024. WebPros International GmbH. All rights reserved.

import { memo } from 'react';
import styles from './Title.module.css';
import { Heading, Action, TranslationProps } from '@plesk/ui-library';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

type TitleProps = {
    text: TranslationProps;
};

const Title = ({ text }: TitleProps) => {
    const { monitoring } = useConfig();
    return (
        <Heading className={styles.title} level={3}>
            {text}
            <span className={styles.arrowLink}>
                <Action
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    href={monitoring.baseUrl}
                    icon="arrow-diagonal-out"
                />
            </span>
        </Heading>
    );
};

export default memo(Title);
