// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Popover, Section, SectionItem, Text, TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { BuyInfo as CleverbridgeBuyInfo } from '@platform360/licenses/web/units/cleverbridge';

type ConfirmPopoverButtonProps = {
    onSubmit: () => void;
    disabled: boolean;
    tooltip?: TranslationProps;
};

const ConfirmPopoverButton = ({
    onSubmit,
    tooltip,
    disabled = false,
}: ConfirmPopoverButtonProps) => {
    const translate = useTranslate();

    return (
        <Popover
            key="popover"
            title={translate('confirmationPopupTitle')}
            target={
                <Button
                    data-type="Upsell--upsellAccept"
                    intent="primary"
                    size="lg"
                    disabled={disabled}
                    tooltip={tooltip}
                >
                    {translate('Keys.general.confirm')}
                </Button>
            }
            placement="bottom"
        >
            <Section key={'confirmPopoverDescription'}>
                <SectionItem>
                    <Text fontSize={'sm'}>
                        <CleverbridgeBuyInfo />
                    </Text>
                </SectionItem>
            </Section>
            <Section key={'confirmPopoverButton'}>
                <SectionItem>
                    <Button
                        data-type={'Upsell--upsellConfirm'}
                        intent={'success'}
                        onClick={onSubmit}
                        size={'lg'}
                    >
                        {translate('Keys.general.buyNow')}
                    </Button>
                </SectionItem>
            </Section>
        </Popover>
    );
};

export default ConfirmPopoverButton;
