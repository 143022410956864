// Copyright 2024. WebPros International GmbH. All rights reserved.

export const INITIALIZATION = '@licensing/license/adjust/initialization';
export const FAILED_INITIALIZATION = '@licensing/license/adjust/initialization/failed';
export const INITIALIZED = '@licensing/license/adjust/initialized';
export const FINALIZATION = '@licensing/license/adjust/finalization';

export const CALCULATE_SUMMARY_COST = '@licensing/license/adjust/calculateSummaryCost';
export const SET_CALCULATED_COST = '@licensing/license/adjust/setCalculatedSummaryCost';

export const SET_VALUE = '@licensing/license/adjust/product/set';

export const SUBMIT = '@licensing/license/adjust/submit';
export const SUBMITTING = '@licensing/license/adjust/submitting';
export const SUBMITTED = '@licensing/license/adjust/submitted';
