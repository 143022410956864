// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ReactNode } from 'react';
import { captureMessage } from '@sentry/browser';
import { InstallationErr } from '@platform360/web-installer/shared/api-contract';
import { Paragraph } from '@plesk/ui-library';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';
import {
    ProductTypesTranslations,
    alreadyInstalledPanelSurveyLink,
} from '@platform360/web-installer/web/constants';

type UseTranslateInstallationErrorParams = {
    host: string;
    error: InstallationErr | null;
    productType?: WebInstallerProduct;
};

const SUPPORTED_OS_LINKS = {
    plesk: 'https://docs.plesk.com/release-notes/current/software-requirements',
    cpanel: 'https://support.cpanel.net/hc/en-us/articles/1500001216582-What-operating-systems-are-supported-by-cPanel',
};

const surveyLink = (chunk: ReactNode, productType: WebInstallerProduct) => (
    <a
        href={alreadyInstalledPanelSurveyLink[productType]}
        target="_blank"
        rel="noopener noreferrer"
    >
        {chunk}
    </a>
);

export const useTranslateInstallationError = ({
    host,
    error,
    productType,
}: UseTranslateInstallationErrorParams) => {
    const translate = useTranslate('web-installer.useTranslateInstallationError');
    let title: string | null = null;
    let description: string | null = null;

    const productTypeName = productType ? ProductTypesTranslations[productType] : undefined;

    if (!error) {
        return { title, description };
    }

    const details = error.message.replace(/[\s.]*$/, '');

    switch (error.type) {
        case 'sshdisconnect':
            title = translate('sshDisconnect.title');
            description = translate('sshDisconnect.description');
            break;
        case 'connectfail':
            title = translate('connectFail.title');
            description = translate('connectFail.description', {
                details,
                p: (chunk) => <Paragraph>{chunk}</Paragraph>,
            });
            break;
        case 'pleskdetecterror':
            title = translate('pleskDetectError.title');
            description = translate('pleskDetectError.description', {
                details,
            });
            break;
        case 'autoinstallunavailable':
            title = translate('autoinstallUnavailable.title');
            description = translate('autoinstallUnavailable.description', {
                details,
            });
            break;
        case 'pleskinstalled':
            if (!productType) {
                return {};
            }

            title = translate('pleskInstalled.title');
            description = translate('pleskInstalled.description', {
                host,
                surveyLink: (chunk) => surveyLink(chunk, productType),
                productType: productTypeName,
            });
            break;
        case 'pleskoutdated':
            if (!productType) {
                return {};
            }

            title = translate('pleskOutdated.title');
            description = translate('pleskOutdated.description', {
                host,
                surveyLink: (chunk) => surveyLink(chunk, productType),
                productType: productTypeName,
            });
            break;
        case 'unsupportedarch': {
            const arch = typeof error.arch === 'string' ? error.arch : undefined;
            title = translate('unsupportedArch.title');
            description = translate('unsupportedArch.description', {
                arch,
                productType: productTypeName,
            });
            break;
        }
        case 'unsupportedos': {
            const os = typeof error.os === 'string' ? error.os : undefined;
            const link = productType ? SUPPORTED_OS_LINKS[productType] : '';

            title = translate('unsupportedOs.title');
            description = translate('unsupportedOs.description', {
                supportedOsLink: (chunk) => (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {chunk}
                    </a>
                ),
                os,
                productType: productTypeName,
            });
            break;
        }
        case 'notenoughdiskspace':
            title = translate('notEnoughDiskSpace.title');
            description = error.message;
            break;
        case 'cpanelinstalled':
            if (!productType) {
                return {};
            }

            title = translate('cpanelInstalled.title');
            description = translate('cpanelInstalled.description', {
                host,
                surveyLink: (chunk) => surveyLink(chunk, productType),
                migrationGuideLink: (chunk) => (
                    <a
                        href="https://docs.plesk.com/en-US/current/migration-guide/introduction.75496/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {chunk}
                    </a>
                ),
                productType: productTypeName,
            });
            break;
        case 'packagemanagerbusy':
            title = translate('packageManagerBusy.title');
            description = translate('packageManagerBusy.description', {
                details,
            });
            break;
        case 'reponotcached':
        case 'reponotenabled':
        case 'reponotsupported':
        case 'configmanagernotinstalled':
            title = translate('packageManagerConfigurationFailed.title');
            description = translate('packageManagerConfigurationFailed.description', {
                details,
            });
            break;
        case 'mysqlcreateuserfailed':
        case 'mysqlsetupfailed':
        case 'mysqlnomycnf':
            title = translate('dbConfigurationFailed.title');
            description = error.message;
            break;
        default:
            captureMessage('Unknown installation error type', { extra: { errorType: error.type } });
            description = error.message;
            break;
    }
    return { title, description };
};
