// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './FeedbackButton.module.css';
import { Icon } from '@plesk/ui-library';
import { SECURITY_DASHBOARD_FEEDBACK_LINK } from '@platform360/libs/common/constants/security-dashboard';

const FeedbackButton = () => {
    const translate = useTranslate('security-dashboard.Layout.HeaderAddon.FeedbackButton');

    return (
        <a
            href={SECURITY_DASHBOARD_FEEDBACK_LINK}
            target="_blank"
            className={styles.feedbackButton}
            rel="noreferrer"
        >
            <Icon className={styles.feedbackIcon} name="feedback" intent="info" size="16" />
            <span>{translate('feedbackBtn')}</span>
        </a>
    );
};

export default FeedbackButton;
