// Copyright 2024. WebPros International GmbH. All rights reserved.

export const load = (key: string) => {
    try {
        const buf = localStorage.getItem(key);
        return buf ? JSON.parse(buf) : null;
    } catch {
        return null;
    }
};
