// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { activateLicenseUri } from '@platform360/licenses/web/helpers';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useMonitoringAnalyticsEvents } from '@platform360/monitoring/web/helpers/analytics';
import {
    PRODUCT_MONITORING_PRO,
    PRODUCT_MONITORING_BUSINESS,
    PRODUCT_MONITORING_ENTERPRISE,
    PRODUCT_MONITORING_ENTERPRISE_FOR_PARTNERS,
    PRODUCT_MONITORING_PRO_FLEXIBLE,
    PRODUCT_MONITORING_LITE,
    PRODUCT_MONITORING_LITE_CUSTOM,
    PRODUCT_MONITORING_LITE_CUSTOM_NO_ALERTS,
    PRODUCT_MONITORING_PRO_SMALL,
    PRODUCT_MONITORING_PRO_DAILY_FLEXIBLE,
    PRODUCT_WP_GUARDIAN_1,
    PRODUCT_WP_GUARDIAN_5,
    PRODUCT_WP_GUARDIAN_10,
    PRODUCT_WP_GUARDIAN_20,
    PRODUCT_WP_GUARDIAN_30,
    PRODUCT_WP_GUARDIAN_50,
    PRODUCT_WP_GUARDIAN_1_RETAIL,
    PRODUCT_WP_GUARDIAN_5_RETAIL,
    PRODUCT_WP_GUARDIAN_10_RETAIL,
    PRODUCT_WP_GUARDIAN_30_RETAIL,
    PRODUCT_WP_GUARDIAN_50_RETAIL,
    PRODUCT_WP_GUARDIAN_100,
    PRODUCT_WP_GUARDIAN_500,
    PRODUCT_WP_GUARDIAN_1000,
    PRODUCT_WP_GUARDIAN_10000,
    PRODUCT_WP_GUARDIAN_UNLIMITED_30,
    PRODUCT_WP_GUARDIAN_UNLIMITED,
} from '@platform360/libs/shared-web/constants/products';

// TODO: need to invert dependency to avoid cycle imports and move this handler to the shared-web.
const AnalyticsRouteHandler = () => {
    const { pathname, search } = useLocation();
    const securityDashboardAnalyticsEvents = useSecurityDashboardAnalyticsEvents();
    const monitoringAnalyticsEvents = useMonitoringAnalyticsEvents();

    useEffect(() => {
        switch (pathname) {
            case activateLicenseUri: {
                const searchParams = new URLSearchParams(search);
                const product = searchParams.get('product');
                switch (product) {
                    case PRODUCT_MONITORING_PRO_FLEXIBLE:
                    case PRODUCT_MONITORING_PRO:
                    case PRODUCT_MONITORING_BUSINESS:
                    case PRODUCT_MONITORING_ENTERPRISE:
                    case PRODUCT_MONITORING_ENTERPRISE_FOR_PARTNERS:
                    case PRODUCT_MONITORING_LITE:
                    case PRODUCT_MONITORING_LITE_CUSTOM:
                    case PRODUCT_MONITORING_LITE_CUSTOM_NO_ALERTS:
                    case PRODUCT_MONITORING_PRO_SMALL:
                    case PRODUCT_MONITORING_PRO_DAILY_FLEXIBLE:
                        monitoringAnalyticsEvents.monitoringLicenseActivationLinkVisit(product);
                        break;
                    case PRODUCT_WP_GUARDIAN_1:
                    case PRODUCT_WP_GUARDIAN_5:
                    case PRODUCT_WP_GUARDIAN_10:
                    case PRODUCT_WP_GUARDIAN_20:
                    case PRODUCT_WP_GUARDIAN_30:
                    case PRODUCT_WP_GUARDIAN_50:
                    case PRODUCT_WP_GUARDIAN_1_RETAIL:
                    case PRODUCT_WP_GUARDIAN_5_RETAIL:
                    case PRODUCT_WP_GUARDIAN_10_RETAIL:
                    case PRODUCT_WP_GUARDIAN_30_RETAIL:
                    case PRODUCT_WP_GUARDIAN_50_RETAIL:
                    case PRODUCT_WP_GUARDIAN_100:
                    case PRODUCT_WP_GUARDIAN_500:
                    case PRODUCT_WP_GUARDIAN_1000:
                    case PRODUCT_WP_GUARDIAN_10000:
                    case PRODUCT_WP_GUARDIAN_UNLIMITED_30:
                    case PRODUCT_WP_GUARDIAN_UNLIMITED:
                        securityDashboardAnalyticsEvents.wpLicenseActivationLinkVisit(product);
                        break;
                    default:
                        break;
                }
                break;
            }
            default:
                break;
        }
    }, [monitoringAnalyticsEvents, securityDashboardAnalyticsEvents, pathname, search]);

    return null;
};

export default AnalyticsRouteHandler;
