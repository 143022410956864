// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

import { InstallationComponents } from '@platform360/security-dashboard/shared/installation-component';
import InstallationSummaryPanel from './InstallationSummaryPanel';

type InstallationCoreSummaryProps = {
    installationId: number;
    vulnerabilities?: number;
    outdated?: boolean;
    onClickOutdated: () => void;
    onClickUpdate: () => void;
    onClickUpdateConfirmation: () => void;
    onClickVulnerable: () => void;
    version?: string;
    availableVersion?: string | null;
    isUpdating: boolean;
};

const InstallationCoreSummary = ({
    installationId,
    vulnerabilities,
    outdated,
    onClickUpdate,
    onClickUpdateConfirmation,
    onClickOutdated,
    onClickVulnerable,
    version,
    availableVersion,
    isUpdating,
}: InstallationCoreSummaryProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.InstallationSummary.CoreSummary',
    );

    const vulnerableText = vulnerabilities
        ? translate('vulnerabilities', {
              count: vulnerabilities,
          })
        : undefined;

    const outdatedText = outdated ? translate('isOutdated') : undefined;

    return (
        <InstallationSummaryPanel
            outdatedText={outdatedText}
            vulnerableText={vulnerableText}
            installationId={installationId}
            title={<Text intent="muted">{translate('title', { version })}</Text>}
            onClickUpdate={onClickUpdate}
            onClickUpdateConfirmation={onClickUpdateConfirmation}
            onClickOutdated={onClickOutdated}
            onClickVulnerable={onClickVulnerable}
            updateButtonText={translate('update', { version: availableVersion })}
            componentType={InstallationComponents.core}
            isUpdating={isUpdating}
        />
    );
};

export default InstallationCoreSummary;
