// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, Heading } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

import styles from './DisableProtectionDialog.module.less';
import banner from './banner.png';

export type DisableProtectionDialogProps = {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
};

const DisableProtectionDialog = ({ isOpen, onSubmit, onClose }: DisableProtectionDialogProps) => {
    const translate = useTranslate('security-dashboard.Servers.DisableProtectionDialog');

    return (
        <Dialog
            className={styles.modal}
            data-type="wp-server-add-drawer"
            title=" "
            size="sm"
            isOpen={isOpen}
            onClose={onClose}
            banner={<img src={banner} alt="" />}
            form={{
                onSubmit: () => {
                    onSubmit();
                    onClose();
                },
                submitButton: { children: translate('confirm') },
                cancelButton: { ghost: true, children: translate('cancel') },
            }}
        >
            <Heading level={2}>{translate('title')}</Heading>
            {translate('paragraph1')}
        </Dialog>
    );
};

export default DisableProtectionDialog;
