// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { activateTrial } from '@platform360/security-dashboard/web/api/license';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';

type MutationOptions = {
    onError: () => void;
};

const useActivateTrialMutation = ({ onError }: MutationOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: activateTrial,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: useLicenseQuery.getQueryKey(),
            });
        },
        onError,
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useActivateTrialMutation;
