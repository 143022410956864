// Copyright 2024. WebPros International GmbH. All rights reserved.

import qs from 'qs';
import { connect } from 'react-redux';
import { Component } from 'react';
import PropTypes from 'prop-types';
import UpgradeView from '@platform360/licenses/web/UpgradeWizard/UpgradeView';
import ReturnToPlesk from '@platform360/licenses/web/WizardCommon/ReturnToPlesk';
import {
    changeUpgradeCompleteness,
    fetchAvailableUpgrades,
    fetchKey,
    fetchPriceCalculationForSubscriptionItem,
    getAvailableUpgrades,
    getKey,
    getPriceCalculation,
    getPriceCalculationError,
    getUpgradeCompleteness,
    replaceSubscriptionItem,
    resetKey,
} from '@platform360/licenses/web/store';
import { useSearchParams, Navigate } from 'react-router-dom';

import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';
import { navigate } from '@platform360/libs/shared-web/helpers/history';
import { toaster } from '@platform360/libs/shared-web/toaster';
import Message from '@platform360/licenses/web/WizardCommon/Message';
import { licensesUri } from '@platform360/licenses/web/helpers';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

class UpgradeWizard extends Component {
    constructor(props) {
        super(props);
        const { returnUrl } = qs.parse(window.location.search.slice(1));
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            ready: false,
            offer: null,
            multiOffer: null,
            returnUrl,
        };
        if (returnUrl) {
            props.onGoTo(window.location.pathname);
        }
    }

    static getDerivedStateFromProps({ availableUpgrades, mainKey, onGoTo }, { ready }) {
        if (ready) {
            return null;
        }
        if (availableUpgrades && mainKey) {
            if (!availableUpgrades.upsells || availableUpgrades.upsells.length === 0) {
                onGoTo(licensesUri);
                return null;
            }

            const productUpsells = availableUpgrades.upsells;
            if (productUpsells.length > 1) {
                return {
                    ready: true,
                    multiOffer: {
                        subscriptionItem: availableUpgrades.subscriptionItem,
                        productName: availableUpgrades.productName,
                        extensionCatalogCode: availableUpgrades.extensionCatalogCode,
                        currency: availableUpgrades.currency,
                        upsells: productUpsells,
                    },
                };
            }
            return {
                ready: true,
                offer: {
                    subscriptionItem: availableUpgrades.subscriptionItem,
                    productName: availableUpgrades.productName,
                    extensionCatalogCode: availableUpgrades.extensionCatalogCode,
                    currency: availableUpgrades.currency,
                    upsell: productUpsells[0],
                },
            };
        }
        return null;
    }

    componentDidMount() {
        const { onMount } = this.props;
        onMount();
    }

    componentDidUpdate(prevProps, prevState) {
        const { onPriceCalculationRequired, mainKey, onAvailableUpgradesRequired } = this.props;
        const { offer } = this.state;
        if (mainKey && !prevProps.mainKey) {
            onAvailableUpgradesRequired();
        }
        if (offer && prevState.offer !== offer) {
            onPriceCalculationRequired({ offer });
        }
    }

    componentWillUnmount() {
        const { onUnmount } = this.props;
        onUnmount();
    }

    handleOfferChange = (extension) => {
        if (extension.upsells.length > 1) {
            this.setState({
                multiOffer: {
                    subscriptionItem: extension.subscriptionItem,
                    productName: extension.productName,
                    extensionCatalogCode: extension.extensionCatalogCode,
                    currency: extension.currency,
                    upsells: extension.upsells,
                },
            });
        } else {
            this.setState({
                offer: {
                    subscriptionItem: extension.subscriptionItem,
                    productName: extension.productName,
                    extensionCatalogCode: extension.extensionCatalogCode,
                    currency: extension.currency,
                    upsell: extension.upsells[0],
                },
            });
        }
    };

    handleReset = (offerType) => () => {
        this.setState({
            [offerType]: null,
        });
        this.props.onOfferCancel();
    };

    handleSubscriptionItemConfirm = () => {
        const { offer, returnUrl } = this.state;
        const { mainKey, onSubscriptionItemConfirm } = this.props;
        onSubscriptionItemConfirm({ offer, mainKey, returnUrl });
    };

    render() {
        const { mainKey, priceCalculation, priceCalculationError, done, onGoTo, searchParams } =
            this.props;
        const { offer, multiOffer, returnUrl } = this.state;

        const preSelectedProductNamePrefix = searchParams.get('product');

        if (done) {
            return returnUrl ? (
                <ReturnToPlesk returnUrl={returnUrl} />
            ) : (
                <Navigate to={licensesUri} />
            );
        }

        return (
            <UpgradeView
                mainKey={mainKey}
                offer={offer}
                multiOffer={multiOffer}
                priceCalculation={priceCalculation}
                priceCalculationError={priceCalculationError}
                onOfferChange={this.handleOfferChange}
                onOfferReset={this.handleReset('offer')}
                onMultiOfferReset={this.handleReset('multiOffer')}
                onSubscriptionItemConfirm={this.handleSubscriptionItemConfirm}
                onGoTo={onGoTo}
                preSelectedProductNamePrefix={preSelectedProductNamePrefix}
            />
        );
    }
}

UpgradeWizard.propTypes = {
    mainKey: PropTypes.object,
    searchParams: PropTypes.object.isRequired,
    availableUpgrades: PropTypes.any,
    priceCalculation: PropTypes.object,
    priceCalculationError: PropTypes.string,
    onMount: PropTypes.func.isRequired,
    onAvailableUpgradesRequired: PropTypes.func.isRequired,
    onPriceCalculationRequired: PropTypes.func.isRequired,
    onSubscriptionItemConfirm: PropTypes.func.isRequired,
    onGoTo: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    onOfferCancel: PropTypes.func.isRequired,
    done: PropTypes.bool.isRequired,
};

UpgradeWizard.defaultProps = {
    mainKey: null,
    availableUpgrades: null,
    priceCalculation: null,
    priceCalculationError: null,
};

const mapStateToProps = (state, { licenseId }) => ({
    licenseId,
    mainKey: getKey(state, licenseId),
    availableUpgrades: getAvailableUpgrades(state, licenseId),
    priceCalculation: getPriceCalculation(state),
    priceCalculationError: getPriceCalculationError(state),
    done: getUpgradeCompleteness(state),
});

const mapDispatchToProps = (dispatch, { licenseId, replaceSubscriptionItemCallbacks }) => ({
    onMount: () => dispatch(fetchKey(licenseId)),
    onAvailableUpgradesRequired: () => dispatch(fetchAvailableUpgrades(licenseId)),
    onPriceCalculationRequired: ({ offer }) => {
        const {
            upsell: { productId },
            subscriptionItem,
        } = offer;

        dispatch(fetchPriceCalculationForSubscriptionItem(subscriptionItem, productId));
    },
    onSubscriptionItemConfirm: ({ offer, mainKey, returnUrl }) => {
        const { upsell, subscriptionItem, extensionCatalogCode } = offer;
        const { productId, productName } = upsell;
        const onRefresh = () => {
            dispatch(refreshKeyList());
            toaster.clear();
        };
        let message;
        if (!returnUrl) {
            message = (
                <Message
                    content="pleskUpgradedMessage"
                    params={{
                        id: mainKey.keyNumber,
                        to: productName,
                        from: mainKey.type,
                        parentId: mainKey.keyNumber,
                    }}
                    onRefresh={onRefresh}
                />
            );
        }
        dispatch(
            replaceSubscriptionItem({
                extensionCatalogCode,
                subscriptionItem,
                productId,
                message,
                replaceSubscriptionItemCallbacks,
            }),
        );
    },
    onGoTo: (url) => {
        dispatch(navigate(url));
    },
    onOfferCancel: () => {
        dispatch(navigate(licensesUri));
    },
    onUnmount: () => {
        dispatch(changeUpgradeCompleteness(false));
        dispatch(resetKey(licenseId));
    },
});

export { UpgradeWizard };

const ConnectedUpgradeWizard = connect(mapStateToProps, mapDispatchToProps)(UpgradeWizard);

const UpgradeWizardWrapper = (props) => {
    const analyticsEvents = useLicensesAnalyticsEvents();
    const [searchParams] = useSearchParams();

    return (
        <ConnectedUpgradeWizard
            {...props}
            searchParams={searchParams}
            replaceSubscriptionItemCallbacks={{
                onSuccess: (extensionCatalogCode) =>
                    analyticsEvents.licenseUpgradeSuccessful(extensionCatalogCode),
                onFail: (extensionCatalogCode) =>
                    analyticsEvents.licenseUpgradeFailed(extensionCatalogCode),
            }}
        />
    );
};

export default UpgradeWizardWrapper;
