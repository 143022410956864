// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Alert,
    ContentLoader,
    Grid,
    GridCol,
    Heading,
    Popover,
    Section,
    Text,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import PropTypes from 'prop-types';
import Cost from '@platform360/licenses/web/components/Cost';
import { CleverBridgePaymentMethod } from '@platform360/licenses/web/components/PaymentMethod';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import {
    ConfirmSubscriptionInfo,
    Footer,
    PaymentInfo as CleverbridgePaymentInfo,
} from '@platform360/licenses/web/units/cleverbridge';
import { AG } from '@platform360/licenses/web/units/cleverbridge/constants/merchantOfRecordType';
import style from './Confirm.module.css';

const Confirm = ({
    priceCalculation = null,
    billingPeriod,
    hideChargeInfo = null,
    changesDelayed = null,
    offer = null,
}) => {
    const translate = useTranslate();
    const { paymentInfo } = priceCalculation;

    const renderChargeNow = () => (
        <Text>
            {translate('chargeNow', {
                grossPrice: (
                    <Cost
                        value={priceCalculation.alignmentGrossPrice}
                        currency={priceCalculation.currency}
                    />
                ),
                vatPrice: (
                    <Cost
                        value={priceCalculation.alignmentVatPrice}
                        currency={priceCalculation.currency}
                    />
                ),
            })}{' '}
            <Popover placement="top" target={<a>{translate('why')}</a>}>
                {translate('whyCharge')}
            </Popover>
        </Text>
    );

    return (
        <div>
            {priceCalculation ? (
                <div data-type="Confirm">
                    {!hideChargeInfo && (
                        <Alert className={style.chargeInfo}>
                            <Grid>
                                <GridCol xl={12}>
                                    {changesDelayed
                                        ? translate('delayedCharge', {
                                              limit: offer.keyProperties[0].currentValue,
                                              property: offer.keyProperties[0].description,
                                          })
                                        : renderChargeNow()}
                                </GridCol>
                                <GridCol xl={12}>
                                    {translate('nextCharge', {
                                        grossPrice: (
                                            <BillingInterval period={billingPeriod}>
                                                <Cost
                                                    value={priceCalculation.nextBillingGrossPrice}
                                                    currency={priceCalculation.currency}
                                                />
                                            </BillingInterval>
                                        ),
                                        vatPrice: (
                                            <Cost
                                                value={priceCalculation.nextBillingVatPrice}
                                                currency={priceCalculation.currency}
                                            />
                                        ),
                                        nextBillingDate: priceCalculation.nextBillingDate,
                                    })}
                                </GridCol>
                            </Grid>
                        </Alert>
                    )}
                    {paymentInfo && (
                        <Section>
                            <Heading level={4}>{translate('Keys.headers.payment')}</Heading>
                            <CleverbridgePaymentInfo
                                merchantOfRecordType={priceCalculation.merchantOfRecordType || AG}
                            />
                            <CleverBridgePaymentMethod
                                paymentInfo={paymentInfo}
                                changePaymentUrl={priceCalculation.changePaymentUrl}
                            />
                        </Section>
                    )}
                    <Section className={style.confirmSubscription}>
                        <Heading level={4}>
                            <Text intent="muted">
                                {translate('Keys.headers.confirmSubscription')}
                            </Text>
                        </Heading>
                        <ConfirmSubscriptionInfo />
                        <Footer
                            merchantOfRecordType={priceCalculation?.merchantOfRecordType || AG}
                        />
                    </Section>
                </div>
            ) : (
                <ContentLoader />
            )}
        </div>
    );
};

Confirm.propTypes = {
    billingPeriod: PropTypes.string.isRequired,
    priceCalculation: PropTypes.object,
    hideChargeInfo: PropTypes.bool,
    changesDelayed: PropTypes.bool,
    offer: PropTypes.object,
};

export default Confirm;
