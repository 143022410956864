// Copyright 2024. WebPros International GmbH. All rights reserved.

import logoWhite from './logo-white.svg';
import logoBlack from './logo-black.svg';

type PlatformLogoProps = {
    inverse?: boolean;
    className?: string;
};
export const PlatformLogo = ({ inverse, ...props }: PlatformLogoProps) => (
    <img src={inverse ? logoWhite : logoBlack} alt="Plesk360 logo" {...props} />
);

export default PlatformLogo;
