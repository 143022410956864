// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Navigate,
    // eslint-disable-next-line no-restricted-imports
    Routes as ReactRoutes,
    Route,
} from 'react-router-dom';
import { Layout } from '@platform360/security-dashboard/web/components/Layout';
import { PageLayout } from '@platform360/security-dashboard/web/components/PageLayout';
import Servers from '@platform360/security-dashboard/web/components/Servers';
import Vulnerabilities from '@platform360/security-dashboard/web/components/Vulnerabilities';
import Installations from '@platform360/security-dashboard/web/components/Installations';
import LicensePage from '@platform360/security-dashboard/web/components/LicensePage';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import ConfirmNotificationsEmail from '@platform360/security-dashboard/web/components/ConfirmNotificationsEmail';
import Invitation from '@platform360/security-dashboard/web/components/Invitation';
import { DigitalOceanLogin } from '@platform360/security-dashboard/web/components/DigitalOceanLogin/DigitalOceanLogin';

const Routes = () => (
    <ReactRoutes>
        <Route path="sso/login" element={<DigitalOceanLogin />} />
        <Route path="confirm-notifications-email" element={<ConfirmNotificationsEmail />} />
        <Route element={<PrivateRoutes />}>
            <Route path="/invitation" element={<Invitation />} />
            <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="servers" />} />
                <Route path="/servers/*" element={<Servers />} />
                <Route path="/installations/*" element={<Installations />} />
                <Route path="/vulnerabilities/*" element={<Vulnerabilities />} />
                <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Route>
            <Route element={<PageLayout />}>
                <Route path="/license-page/*" element={<LicensePage />} />
            </Route>
        </Route>
    </ReactRoutes>
);

export default Routes;
