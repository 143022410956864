// Copyright 2024. WebPros International GmbH. All rights reserved.

import React, { useEffect, useRef, useState } from 'react';
import AccountRemoval from '@platform360/accounts/web/profile/AccountRemoval';
import { useDeleteTeamMemberMutation } from '@platform360/accounts/web/mutations';
import { Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type CurrentMember = {
    id: number;
    fullName: string;
    email: string;
};

const useSetTimeout = () => {
    const timerId = useRef<number | undefined>(undefined);
    const setTimeout = (handler: () => void, timeout: number) => {
        clearTimeout(timerId.current);
        timerId.current = window.setTimeout(handler, timeout);
    };
    useEffect(() => () => {
        clearTimeout(timerId.current);
    });

    return { setTimeout, id: timerId.current };
};

const DIALOG_ANIMATION_TIMEOUT = 300;

export const useMemberRemoval = () => {
    const translate = useTranslate('Team.Members');
    const [currentMember, setCurrentMember] = useState<CurrentMember | undefined>();
    const [isOpen, setIsOpen] = useState(false);
    const { setTimeout } = useSetTimeout();
    const removeMember = (member: CurrentMember) => {
        setCurrentMember(member);
        setIsOpen(true);
    };
    const closeDialog = () => {
        setIsOpen(false);
        setTimeout(() => {
            setCurrentMember(undefined);
        }, DIALOG_ANIMATION_TIMEOUT);
    };
    const { mutate: deleteTeamMember, isPending: isRemoving } = useDeleteTeamMemberMutation({
        onSuccess: () => {
            closeDialog();
        },
    });
    const memberRemoval = currentMember ? (
        <AccountRemoval
            isOpen={isOpen}
            isRemoving={isRemoving}
            onRemove={() => deleteTeamMember(currentMember.id)}
            onCancel={closeDialog}
        >
            <Paragraph>
                {translate('removeMemberConfirmation', { name: currentMember.fullName })}
            </Paragraph>
        </AccountRemoval>
    ) : undefined;

    return { removeMember, memberRemoval };
};
