// Copyright 2024. WebPros International GmbH. All rights reserved.

import useModal from '@platform360/libs/shared-web/helpers/useModal';
import { NotificationsSettingsDrawer } from './NotificationsSettingsDrawer';

export const HeaderAddon = () => {
    const [notificationsSettingsDrawerOpen, setNotificationsSettingsDrawerOpen] =
        useModal('notifications-settings');

    return (
        <NotificationsSettingsDrawer
            isOpen={notificationsSettingsDrawerOpen}
            onClose={() => setNotificationsSettingsDrawerOpen(false)}
        />
    );
};
