// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    InstallationComponent,
    InstallationComponents,
} from '@platform360/security-dashboard/shared/installation-component';

const useInstallationComponentTranslate = () => {
    const translate = useTranslate('security-dashboard.installationComponents');

    const translationsMap: Record<InstallationComponent, TranslationProps> = {
        [InstallationComponents.core]: translate('core'),
        [InstallationComponents.plugin]: translate('plugin'),
        [InstallationComponents.theme]: translate('theme'),
    };

    return (installationComponent: InstallationComponent) => translationsMap[installationComponent];
};

export default useInstallationComponentTranslate;
