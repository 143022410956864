// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import MonitoringColumn from './MonitoringColumn';
import useServersQuery from '@platform360/monitoring/web/queries/useServersQuery';
import { ProductType } from '@platform360/server-inventory/shared/product-type';
import { PlatformType } from '@platform360/server-inventory/shared/platform-type';

type ServerProps = {
    guid: string;
    address: URL;
    product: ProductType;
    platform: PlatformType | null;
    isMonitoringConnectionSupported?: boolean;
};

type UseMonitoringColumnOptions = {
    serverGuids: string[];
    refetchInterval: number;
};

const overrideMonitoringSupport = (
    backendValue: boolean | undefined,
    product: ProductType,
    platform: PlatformType | null,
): boolean | undefined => {
    if (product === 'plesk' && platform === 'Windows') {
        return backendValue;
    }

    return backendValue;
};

const useMonitoringColumn = ({ serverGuids, refetchInterval }: UseMonitoringColumnOptions) => {
    const translate = useTranslate('monitoring.MonitoringColumn');
    const {
        isLoading,
        data = {},
        error,
    } = useServersQuery({
        variables: {
            serverGuids,
        },
        enabled: serverGuids.length > 0,
        refetchInterval,
        meta: {
            defaultErrorHandler: false,
        },
    });

    return {
        key: 'monitoring',
        title: translate('title'),
        width: '150px',
        render: ({
            guid,
            address,
            product,
            platform,
            isMonitoringConnectionSupported,
        }: ServerProps) => (
            <MonitoringColumn
                isLoading={isLoading}
                isMonitoringConnectionSupported={overrideMonitoringSupport(
                    isMonitoringConnectionSupported,
                    product,
                    platform,
                )}
                serverGuid={guid}
                serverAddress={address.toString()}
                monitoringServer={data[guid]}
                hasError={!!error}
            />
        ),
    };
};

export default useMonitoringColumn;
