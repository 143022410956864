// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';
import { isAxiosError } from 'axios';
import {
    translateError,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';

// eslint-disable-next-line deprecation/deprecation
type AxiosValidationError = UntypedAxiosError & {
    response: { data: { type: 'validation-error' } };
};

type AxiosError = {
    response?: {
        data?: {
            type?: string;
        };
    };
};

export const isValidationError = (axiosError: unknown): axiosError is AxiosValidationError =>
    axiosError !== null && (axiosError as AxiosError).response?.data?.type === 'validation-error';

export const getValidationErrors = (axiosError: unknown | null): ValidationError[] | undefined => {
    if (axiosError && isValidationError(axiosError)) {
        return axiosError.response.data.errors;
    }

    return undefined;
};

const asTrimmedString = (s: unknown): string | undefined =>
    typeof s === 'string' ? s.trim() : undefined;

export const toGeneralError = (axiosError: unknown): ReactNode | undefined => {
    if (!isAxiosError(axiosError)) {
        return undefined;
    }

    if (!isValidationError(axiosError)) {
        const { type, category, message, ...params } = axiosError.response?.data ?? {};
        return translateError({
            type: asTrimmedString(type) || 'unknown',
            category: asTrimmedString(category) || 'common',
            message: asTrimmedString(message) ?? '',
            params,
        });
    }

    return undefined;
};
