// Copyright 2024. WebPros International GmbH. All rights reserved.

import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { NavLink as ReactNavLink } from 'react-router-dom';
import classNames from 'classnames';

export type NavLinkProps = {
    to: string;
    children: ReactNode;
    isExternal?: boolean;
    target?: HTMLAttributeAnchorTarget;
    className?: string;
    activeClassName?: string;
};

/**
 * NavLink wrapper provides the ability to render external links as well as internal with the same API
 */
const NavLink = ({ isExternal, children, to, target, activeClassName, ...props }: NavLinkProps) => {
    if (isExternal) {
        return (
            <a href={to} target={target} {...props}>
                {children}
            </a>
        );
    }

    return (
        <ReactNavLink
            to={to}
            {...props}
            className={({ isActive }) => {
                if (!isActive) {
                    return props.className;
                }

                if (activeClassName) {
                    return classNames(props.className, activeClassName);
                }

                return 'active';
            }}
        >
            {children}
        </ReactNavLink>
    );
};

export default NavLink;
