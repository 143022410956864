// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ApplicationSettings } from '@platform360/libs/shared-web/application-settings/context';
import Footer from '@platform360/libs/shared-web/components/Footer';
import AuthLayout from '@platform360/admin-panel/web/components/AuthLayout';
import PlatformLogo from '@platform360/libs/shared-web/components/PlatformLogo';
import NotFoundPage from '@platform360/admin-panel/web/components/NotFound';
import Sidebar from '@platform360/auth/web/components/AuthLayout/Sidebar';
import { useSignUp } from '@platform360/auth/web/mutations';
import { Navigate } from 'react-router-dom';
import { legalDocumentsLoader } from '@platform360/app/web/helpers/legalDocumentsLoader';
import {
    CookiesList,
    cookieModify,
    cookieWithdraw,
} from '@platform360/app/web/components/CookieManager';
import { lazy } from 'react';
import { TermsOfUseLink } from '@platform360/auth/web/components/TermsOfUseLink';

const PleskEula = lazy(async () => ({ default: (await legalDocumentsLoader()).PleskEula }));
const CPanelEula = lazy(async () => ({ default: (await legalDocumentsLoader()).CPanelEula }));
const Privacy = lazy(async () => ({ default: (await legalDocumentsLoader()).Privacy }));
const Terms = lazy(async () => ({ default: (await legalDocumentsLoader()).Terms }));
const DataProcessingAgreement = lazy(async () => ({
    default: (await legalDocumentsLoader()).DataProcessingAgreement,
}));
const CookiesContent = lazy(async () => ({
    default: (await legalDocumentsLoader()).CookiesContent,
}));

const adminPanelApplicationSettings: ApplicationSettings = {
    document: {
        title: 'Plesk 360 Admin Panel',
        favicon: '/favicon.ico',
        darkThemeDataAttribute: 'legacy-dark',
    },
    logo: <PlatformLogo />,
    homePage: {
        isPrivate: true,
        component: <Navigate to="/admin-panel" />,
    },
    notFoundPage: <NotFoundPage />,
    header: {
        apiTokensEnabled: false,
        businessProfileEnabled: true,
        globalSearchEnabled: true,
        dotsMenuEnabled: true,
        mainMenuEnabled: true,
        baseUrl: '/admin-panel',
    },
    footer: <Footer />,
    authLayout: AuthLayout,
    authSidebar: Sidebar,
    auth: {
        signUpMutation: useSignUp,
        branding: 'platform',
        termsOfUseLink: <TermsOfUseLink />,
        preferredSignup: false,
        areConsentsShown: true,
        signUpEmailFieldDescriptionShown: true,
        shouldNotifyLogout: true,
    },
    defaultLoginRedirect: '/',
    legalPage: {
        dpa: <DataProcessingAgreement />,
        pleskEula: <PleskEula />,
        cPanelEula: <CPanelEula />,
        privacy: <Privacy />,
        terms: <Terms />,
        cookiesContent: <CookiesContent cookiesList={<CookiesList />} />,
    },
    cookieManager: {
        cookieModify,
        cookieWithdraw,
    },
    licencesModule: {},
    usePlatform360LocaleCode: true,
};

export default adminPanelApplicationSettings;
