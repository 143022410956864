// Copyright 2024. WebPros International GmbH. All rights reserved.
import styles from './TotalCount.module.css';
import { Icon, Text } from '@plesk/ui-library';
import { IconName } from '@plesk/ui-library/types/src/components/Icon/constants';

export type TotalCountProps = {
    icon: IconName;
    count: number;
};

const TotalCount = ({ icon, count }: TotalCountProps) => (
    <div className={styles.container}>
        <Icon size="12" className={styles.icon} name={icon} intent="inactive" />
        <Text className={styles.text}>{count}</Text>
    </div>
);

export default TotalCount;
