// Copyright 2024. WebPros International GmbH. All rights reserved.

import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Extension from '@platform360/licenses/web/units/extensions/types/Extension';
import { STARTED, FINISHED, FAILURE } from '@platform360/licenses/web/units/extensions/constants';
import { UpsellSubscription as BackedUpsellSubscription } from '@platform360/licenses/web/api/ka/responseTypes/Upsells';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';

export type BuyProcessing = typeof STARTED | typeof FINISHED | typeof FAILURE | false;

export type InternalState = {
    initialized: boolean;
    isFetching: boolean;
    processing: BuyProcessing;
};

export type PaygrProducts = Record<number, number>;

export type InitialState = {
    licenseId?: string | undefined;

    isPaygr: boolean;

    currency: Currency;
    period: BillingPeriod;

    selectedProduct: string;
    selectedPaygrProducts?: PaygrProducts;
    selectedSubscription: string;

    subscriptions: BackedUpsellSubscription[];
    extension: Extension;
};

export type FullInitialState = InternalState &
    InitialState & { onlineStoreData: CalculatedSubscriptionState };

export const initialState: FullInitialState = {
    initialized: false,
    isFetching: false,

    isPaygr: false,

    // @ts-expect-error TS2322: Type 'undefined' is not assignable to type
    currency: undefined,
    // @ts-expect-error TS2322: Type 'undefined' is not assignable to type
    period: undefined,

    selectedPaygrProducts: {},

    // @ts-expect-error TS2322: Type 'null' is not assignable to type 'string'.
    selectedProduct: null,
    // @ts-expect-error TS2322: Type 'null' is not assignable to type 'string'.
    selectedSubscription: null,

    subscriptions: [],

    processing: false,

    // @ts-expect-error TS2322
    onlineStoreData: null,
};
