// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { OperationTypes, operationTypeValues } = makeEnum(
    'OperationType',
    'connect',
    'update',
    'fixVulnerabilitiesViaUpdate',
    'refresh',
    'detach',
    'findNewSites',
    'configureUpdates',
    'protect',
    'unprotect',
    'disconnect',
    'mitigationDeactivateAsset',
    'mitigationActivateAsset',
    'demo',
    'ignoreVulnerabilities',
    'cancelIgnoreVulnerabilities',
    'setDailyTaskTime',
);

export type OperationType = UnionOf<typeof operationTypeValues>;
