// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ignoreVulnerabilities } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { useMutation } from '@tanstack/react-query';
import { useVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

type MutationOptions = {
    onSettled?: () => void;
};

const useIgnoreVulnerabilitiesMutation = ({ onSettled }: MutationOptions = {}) =>
    useMutation({
        mutationFn: ignoreVulnerabilities,
        onSuccess: () => {
            refetchList(useVulnerabilitiesQuery.getQueryKey());
        },
        onSettled,
    });

export default useIgnoreVulnerabilitiesMutation;
