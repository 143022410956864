// Copyright 2024. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import { useState, useMemo } from 'react';

import FormFieldInputFile from '@platform360/libs/shared-web/components/FormFieldInputFile';
import { InstallationMethod } from '@platform360/web-installer/shared/installation-method';
import {
    FormFieldText,
    FormFieldPassword,
    FormFieldRadioButtons,
    FormFieldCheckbox,
    Paragraph,
    Icon,
    Section,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useAnimatedHide } from './useAnimatedHide';
import { ValidationError } from '@platform360/libs/shared-web/helpers/translateErrors';
import { InstallationErrorMessage } from '../InstallationErrorMessage/InstallationErrorMessage';
import SelectInstallationMethodFormField from '@platform360/web-installer/web/components/CustomInstallationForm/SelectInstallationMethodFormField';
import FormFieldNumber from '@platform360/web-installer/web/components/CustomInstallationForm/FormFieldNumber';
import { ProductTypesTranslations } from '@platform360/web-installer/web/constants';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

import styles from './CustomInstallationForm.module.css';

type CustomInstallationFormProps = {
    installationErrors: ValidationError[];
    onHostChange?: (value: string) => void;
    techDomain?: string;
    submittedHost: string;
    chooseLicenseTypeEnabled?: boolean;
    productType: WebInstallerProduct;
};

export type FormValues = {
    host: string;
    port?: number;
    login: string;
    password?: string;
    key?: string;
    updateOs?: boolean;
    installationMethod: InstallationMethod;
    licenseType?: 'trial' | 'paid';
    licenseKey?: string;
};

export const DEFAULT_FORM_VALUES: FormValues = Object.freeze({
    host: '',
    login: 'root',
    password: '',
    key: '',
    installationMethod: 'password',
    updateOs: false,
    licenseType: 'trial',
    licenseKey: '',
});

const PanelSupportedOsLinks = {
    plesk: 'https://docs.plesk.com/release-notes/obsidian/software-requirements/#s2-1',
    cpanel: 'https://support.cpanel.net/hc/en-us/articles/1500001216582-What-operating-systems-are-supported-by-cPanel',
};

const CustomInstallationForm = ({
    installationErrors,
    onHostChange,
    techDomain,
    submittedHost,
    chooseLicenseTypeEnabled,
    productType,
}: CustomInstallationFormProps) => {
    const translate = useTranslate('web-installer.CustomInstallationForm');
    const [currentLicenseType, setCurrentLicenseType] = useState<'trial' | 'paid'>('trial');
    useAnimatedHide(
        '.licenseTypeField .pul-form-field-radio-buttons__radio:nth-child(1) .pul-form-field-radio-buttons__content',
        currentLicenseType === 'paid',
    );
    useAnimatedHide(
        '.licenseTypeField .pul-form-field-radio-buttons__radio:nth-child(2) .pul-form-field-radio-buttons__content',
        currentLicenseType === 'trial',
    );

    const productTypeName = ProductTypesTranslations[productType];
    const hostDescription = useMemo(
        () =>
            techDomain
                ? translate('techDomainDescription', { techDomain })
                : translate('description.p2', {
                      productType: productTypeName,
                      a: (chunk) => (
                          <a
                              target="_blank"
                              href={PanelSupportedOsLinks[productType]}
                              rel="noreferrer"
                          >
                              {chunk}
                          </a>
                      ),
                  }),
        [techDomain, productType, productTypeName, translate],
    );

    return (
        <>
            {installationErrors.map((error, index) => (
                <InstallationErrorMessage
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    error={{ ...error, ...error.params }}
                    host={submittedHost}
                    productType={productType}
                />
            ))}

            <Paragraph>
                <Icon name="lock-closed" />
                &nbsp;
                {translate('description.p1', {
                    productType: productTypeName,
                })}{' '}
                {translate('notice')}
            </Paragraph>
            <Section
                collapsible
                className={styles.section}
                title={translate('serverConnectionSection')}
            >
                <div className={styles.hostFieldsContainer}>
                    <FormFieldText
                        name="host"
                        label={translate('hostLabel')}
                        placeholder="your-server-host.com"
                        required
                        autoFocus
                        size="lg"
                        onChange={onHostChange}
                    />
                    {/* TODO replace by InputNumber from ui-library if it will be updated */}
                    {/* TODO decide if this element should be shown by condition */}
                    <FormFieldNumber
                        name="port"
                        label={translate('portLabel')}
                        placeholder="22"
                        size="fill"
                        min={0}
                        max={65535}
                    />
                </div>
                <div className={styles.hostDescription}>{hostDescription}</div>
                <FormFieldText name="login" label={translate('loginLabel')} size="xl" required />
                <SelectInstallationMethodFormField
                    name="installationMethod"
                    label={translate('installationMethodLabel')}
                    required
                    fields={{
                        password: (
                            <FormFieldPassword
                                key="password"
                                name="password"
                                hideGenerateButton
                                hidePasswordMeter
                                size="fill"
                                style={{ width: 300 }}
                            />
                        ),
                        'private-key': <FormFieldInputFile key="key" name="key" />,
                    }}
                >
                    <option value="password">{translate('sshPasswordLabel')}</option>
                    <option value="private-key">{translate('sshKeyLabel')}</option>
                </SelectInstallationMethodFormField>
            </Section>
            {chooseLicenseTypeEnabled && productType !== 'cpanel' ? (
                <Section collapsible className={styles.section} title={translate('licenseSection')}>
                    <FormFieldRadioButtons
                        label={translate('licenseTypeLabel')}
                        name="licenseType"
                        onChange={(value: 'trial' | 'paid') => setCurrentLicenseType(value)}
                        className={classNames('licenseTypeField')}
                        options={[
                            {
                                label: translate('trialLicenseLabel'),
                                value: 'trial',
                                description: translate('trialLicenseDescription', {
                                    privacyPolicyLink: (chunk) => (
                                        <a
                                            href="https://www.plesk.com/legal/#privacy-policy"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {chunk}
                                        </a>
                                    ),
                                }),
                            },
                            {
                                label: translate('paidLicenseLabel'),
                                value: 'paid',
                                description: translate('paidLicenseDescription', {
                                    productType: productTypeName,
                                    onlineStoreLink: (chunk) => (
                                        <a
                                            href="https://go.plesk.com/buy-plesk/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {chunk}
                                        </a>
                                    ),
                                }),
                                indentFields: [
                                    <FormFieldText key="licenseKey" name="licenseKey" />,
                                ],
                            },
                        ]}
                        required
                    />
                </Section>
            ) : undefined}
            <Section
                collapsible
                collapsed
                className={styles.section}
                title={translate('advancedSection')}
            >
                <FormFieldCheckbox
                    name="updateOs"
                    label={translate('installOsUpdatesLabel', {
                        productType: productTypeName,
                    })}
                    description={translate('installOsUpdatesDescription')}
                />
            </Section>
        </>
    );
};

export default CustomInstallationForm;
