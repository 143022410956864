// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { cpanelVerify } from '@platform360/web-installer/web/api/web-installer';
import { CustomVerifyResponse } from '@platform360/web-installer/shared/api-contract';

export type UseCpanelVerifyOptions = {
    onSuccess: (data: CustomVerifyResponse) => void;
    onError: () => void;
};

const useCpanelVerify = ({ onSuccess, onError }: UseCpanelVerifyOptions) =>
    useMutation({
        mutationFn: cpanelVerify,
        onSuccess,
        onError,
    });

export default useCpanelVerify;
