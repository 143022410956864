// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, isValidElement, ComponentProps } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { List as BaseList, Pagination, PaginationProps } from '@plesk/ui-library';

// TODO: export types from ui library.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ListData = Record<string, any>;
type BaseListProps<T extends ListData> = ComponentProps<typeof BaseList<T>>;

export type ListProps<T extends ListData> = {
    pagination?: ReactElement | PaginationProps;
} & Omit<BaseListProps<T>, 'pagination'>;

const makePagination = (pagination?: ReactElement<PaginationProps> | PaginationProps) => {
    if (pagination === undefined) {
        return undefined;
    }

    if (isValidElement(pagination)) {
        return pagination;
    }

    return <Pagination data-type="pagination" {...pagination} />;
};

const List = <T extends ListData>({
    locale,
    filtered,
    totalRows,
    pagination,
    rowKey = 'id',
    selection,
    ...props
}: ListProps<T>) => {
    const translate = useTranslate('components.List');

    return (
        <BaseList
            locale={{
                selectAll: translate('selectAll'),
                deselectAll: translate('deselectAll'),
                selectedRows: translate('selectedRows', {
                    selectedRows: selection?.length,
                    totalRows,
                }),
                totalRows: filtered
                    ? translate('foundRows', { foundRows: totalRows })
                    : translate('totalRows', { totalRows }),
                ...locale,
            }}
            rowKey={rowKey}
            totalRows={totalRows}
            filtered={filtered}
            pagination={makePagination(pagination)}
            selection={selection}
            {...props}
        />
    );
};

export default List;
