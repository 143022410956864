// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getUpdateSettings } from '@platform360/security-dashboard/web/api/settings';

export type UseUpdateSettingsQueryData = PromiseValue<ReturnType<typeof getUpdateSettings>>;

const useUpdateSettingsQuery = createQuery<undefined, UseUpdateSettingsQueryData>({
    queryName: 'security-dashboard/useUpdateSettingsQuery',
    fetcher: getUpdateSettings,
    useQuery: (options) =>
        useQuery({
            ...options,
            // Refetch every 30 sec.
            refetchInterval: 30000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            meta: {
                defaultErrorHandler: false,
            },
        }),
});

export default useUpdateSettingsQuery;
