// Copyright 2024. WebPros International GmbH. All rights reserved.

import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';

const expectedErrors = [
    {
        key: 'missingQueryParameter',
        type: '',
    },
    {
        key: 'nonActivatableKey',
        type: '24239',
    },
    {
        key: 'incorrectActivationCode',
        type: '25233',
    },
];

export type TransformedError = { key: 'linkError' | 'unknownError'; isFixable: boolean };

// eslint-disable-next-line deprecation/deprecation
const transformError = ({ response }: UntypedAxiosError): TransformedError => {
    const isLinkError = expectedErrors.find(
        (v) => v.key === response?.data?.details || v.type === response?.data?.type,
    );

    if (response?.status === 404 || isLinkError) {
        return {
            key: 'linkError',
            isFixable: false,
        };
    }

    return {
        key: 'unknownError',
        isFixable: true,
    };
};

export default transformError;
