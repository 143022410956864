// Copyright 2024. WebPros International GmbH. All rights reserved.

import { InitialState } from '@platform360/licenses/web/units/adjust/reducer/initialState';
import {
    DECREASE,
    INCREASE,
    NEUTRAL,
    ProductState,
} from '@platform360/licenses/web/units/adjust/constants/productState';

export const getChangesProductsByState = (productState: ProductState) => (state: InitialState) => {
    const { newValues, propertiesForChange: properties } = state;

    return properties.filter(
        (property) =>
            property.productId in newValues &&
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            newValues[Number(property.productId)]!.state === productState,
    );
};

export const getDelayedChangesProducts = getChangesProductsByState(DECREASE);
export const getInstantChangesProducts = getChangesProductsByState(INCREASE);
export const getNotChangedProducts = getChangesProductsByState(NEUTRAL);
