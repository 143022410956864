// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment } from 'react';
import {
    Client,
    CLIENT_TYPE_ADMIN,
    CLIENT_TYPE_RESELLER,
} from '@platform360/server-inventory/web/types';
import { Text, ListActions, ListAction } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DataList from '@platform360/libs/shared-web/components/DataList';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { Link, Outlet } from 'react-router-dom';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import useAllClientsQuery from '@platform360/server-inventory/web/queries/useAllClientsQuery';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';

export type AllClientsProps = object;

const AllClients = (props: AllClientsProps) => {
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const translate = useTranslate('server-inventory.AllClients');
    const pagination = usePagination('si-all-clients');
    const search = useSearch();
    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useAllClientsQuery({
        variables: {
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
            filter: search.debouncedValue,
        },
        placeholderData: (prev) => prev,
    });

    const onSearch = (term: string) => {
        if (term) {
            analyticsEvents.searchClients();
        }
        search.onSearch(term);
    };

    return (
        <Fragment>
            <DataList<Client>
                {...props}
                data={data?.data}
                totalRows={data?.totalCount}
                isPreviousData={isPreviousData}
                loading={isLoading}
                pagination={pagination}
                search={{
                    debouncedValue: search.debouncedValue,
                    value: search.value,
                    onSearch,
                }}
                rowKey={({ server, id }) => `${server.id}:${id}`}
                columns={[
                    {
                        key: 'name',
                        type: 'title',
                        title: translate('name'),
                        render: ({ fields }) => (
                            <Text bold>{fields.product === 'plesk' ? fields.name : null}</Text>
                        ),
                    },
                    {
                        key: 'email',
                        title: translate('email'),
                    },
                    {
                        key: 'login',
                        title: translate('login'),
                    },
                    {
                        key: 'type',
                        title: translate('type'),
                        render: ({ type, owner }) => {
                            if (type === CLIENT_TYPE_ADMIN) {
                                return translate('typeAdmin');
                            }

                            if (type === CLIENT_TYPE_RESELLER) {
                                return translate('typeReseller');
                            }

                            if (owner) {
                                return translate('typeCustomerWithOwner', { owner: owner.name });
                            }

                            return translate('typeCustomer');
                        },
                    },
                    {
                        key: 'status',
                        title: translate('status'),
                        render: ({ type, isSuspended }) => {
                            if (type === CLIENT_TYPE_ADMIN) {
                                return null;
                            }

                            if (isSuspended) {
                                return translate('statusSuspended');
                            }

                            return translate('statusActive');
                        },
                    },
                    {
                        key: 'hostingServer',
                        title: translate('hostingServer'),
                        render: ({ server }) => (
                            <Link to={`server/${server.id}`}>{server.address.hostname}</Link>
                        ),
                    },
                    {
                        key: 'actions',
                        type: 'actions',
                        render: ({ id, server }) => (
                            <ListActions>
                                <ListAction
                                    icon="sliders"
                                    primary
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`/si/servers/${server.id}/login/${LoginTargets.client}?clientId=${id}`}
                                    data-type="manage-client-link"
                                    onClick={() => analyticsEvents.clientsItemManageClicked()}
                                >
                                    {translate('manage')}
                                </ListAction>
                            </ListActions>
                        ),
                    },
                ]}
            />
            <Outlet />
        </Fragment>
    );
};

export default AllClients;
