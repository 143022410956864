// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, Paragraph, TranslationProps } from '@plesk/ui-library';
import styles from './LicenseNotificationDialog.module.css';
import trialStarted from './assets/trial-started.png';
import trialExpired from './assets/trial-expired.png';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useNavigate } from 'react-router-dom';
import { CustomerType, CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';

type NotificationType = 'trial-expired' | 'trial-started' | 'license-terminated';

const banners: Record<NotificationType, string> = {
    'trial-expired': trialExpired,
    'trial-started': trialStarted,
    'license-terminated': trialExpired,
};

export type LicenseNotificationDialogProps = {
    title: TranslationProps;
    description: TranslationProps;
    onClose: () => void;
    isOpen: boolean;
    type: NotificationType;
    customerType: CustomerType;
};

export const LicenseNotificationDialog = ({
    title,
    description,
    onClose,
    isOpen,
    type,
    customerType,
}: LicenseNotificationDialogProps) => {
    const navigate = useNavigate();
    const showFooter = type !== 'trial-started' && customerType !== CustomerTypes.vps;
    const translate = useTranslate(
        'security-dashboard.Layout.LicenseName.LicenseNotificationDialog',
    );

    const handleSubmit = () => {
        navigate('/security-dashboard/license-page');
        onClose();
    };

    return (
        <Dialog
            title={title}
            banner={<img src={banners[type]} alt={type} />}
            size="xs"
            form={{
                onSubmit: handleSubmit,
                footerClassName: showFooter ? undefined : styles.hiddenFooter,
                applyButton: false,
                submitButton: { children: translate('chooseLicenseButton') },
            }}
            onClose={onClose}
            isOpen={isOpen}
        >
            {description}
            {customerType === CustomerTypes.vps && (
                <>
                    {type === 'license-terminated' && (
                        <Paragraph>{translate('contactResellerToPurchase')}</Paragraph>
                    )}
                    {type === 'trial-expired' && (
                        <Paragraph>{translate('contactResellerToUpdate')}</Paragraph>
                    )}
                </>
            )}
        </Dialog>
    );
};
