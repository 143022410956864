// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import AddServerWithRedirectStep, { DialogStep, FormValues } from './AddServerWithRedirectStep';
import useAddingServer from '@platform360/server-inventory/web/mutations/useAddingServer';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { normalizeAddress } from '@platform360/server-inventory/web/components/Servers/utils';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { setIn } from '@plesk/ui-library';

export type AddServerWithRedirectContainerProps = {
    onClose: () => void;
    onBack: () => void;
};

export const AddServerWithRedirectContainer = ({
    onClose,
    onBack,
    ...props
}: AddServerWithRedirectContainerProps) => {
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const [values, setValues] = useState<FormValues>({ allowUnsecureConnection: false });
    const [step, setStep] = useState<DialogStep>('form');
    const {
        mutate: addingServer,
        errors,
        isPending: isLoading,
    } = useAddingServer({
        onSuccess: ({ url, jwt }) => {
            const redirectUrl = new URL(url);
            redirectUrl.searchParams.set('jwt', jwt);
            window.location.href = redirectUrl.href;
            onClose();
        },
        onSettled: () => {
            setValues((values) => ({ ...values, allowUnsecureConnection: false }));
            setStep('form');
        },
    });
    const handleSubmit = (values: FormValues) => {
        const address = normalizeAddress(values.address);
        analyticsEvents.addServerPressed();
        addingServer({
            ...values,
            address,
            product: 'plesk',
        });
    };
    const handleChange = (key: string, value: unknown) =>
        setValues((values) => setIn(values, key, value));

    useEffectOnce(() => {
        analyticsEvents.addServerFormOpen('plesk');
    });

    return (
        <AddServerWithRedirectStep
            {...props}
            isLoading={isLoading}
            step={step}
            setStep={setStep}
            values={values}
            errors={errors}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBack={onBack}
        />
    );
};

export default AddServerWithRedirectContainer;
