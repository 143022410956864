// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ClientResponse, Client } from '@platform360/server-inventory/web/types';
import {
    ApiListResponse,
    FetcherOptions,
    FilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';

export const normalizeClient = (domain: ClientResponse): Client => ({
    ...domain,
    server: {
        ...domain.server,
        address: new URL(domain.server.address),
    },
});

export type GetAllClientsOptions = FetcherOptions<PaginatedQuery & FilterQuery>;

export const getAllClients = async ({ variables, signal }: GetAllClientsOptions) => {
    const { data } = await apiClient.get<ApiListResponse<ClientResponse>>('/si/clients', {
        params: variables,
        signal,
    });

    return {
        ...data,
        data: data.data.map(normalizeClient),
    };
};
