// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateNotificationsSettings } from '@platform360/security-dashboard/web/api/notifications-settings';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useNotificationsSettingsQuery from '@platform360/security-dashboard/web/queries/useNotificationsSettingsQuery';

type MutationOptions = {
    onSettled?: () => void;
};

const useUpdateNotificationsSettingsMutation = ({ onSettled }: MutationOptions = {}) => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useUpdateNotificationsSettingsMutation');
    const { successToast } = useToaster();

    return useMutation({
        mutationFn: updateNotificationsSettings,
        onSuccess: async () => {
            successToast(translate('successMessage'), {
                'data-type': 'update-notifications-settings-success',
            });

            await queryClient.invalidateQueries({
                queryKey: useNotificationsSettingsQuery.getQueryKey(),
            });
        },
        onSettled,
    });
};
export default useUpdateNotificationsSettingsMutation;
