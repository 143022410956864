// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ApplicationSettings } from '@platform360/libs/shared-web/application-settings/context';
import Footer from '@platform360/libs/shared-web/components/Footer';
import AuthLayout from '@platform360/auth/web/components/AuthLayout';
import PlatformLogo from '@platform360/libs/shared-web/components/PlatformLogo';
import PlatformHomePage from '@platform360/home-page/web/components/PlatformHomePage/PlatformHomePage';
import PlatformNotFoundPage from '@platform360/libs/shared-web/components/PlatformNotFoundPage';
import Sidebar from '@platform360/auth/web/components/AuthLayout/Sidebar';
import { useSignUp } from '@platform360/auth/web/mutations';
import { legalDocumentsLoader } from '@platform360/app/web/helpers/legalDocumentsLoader';
import {
    CookiesList,
    cookieModify,
    cookieWithdraw,
} from '@platform360/app/web/components/CookieManager';
import { lazy } from 'react';
import { TermsOfUseLink } from '@platform360/auth/web/components/TermsOfUseLink';
import HeaderAddon from '@platform360/server-inventory/web/components/Layout/HeaderAddon';
import NotificationsSettingsMenuItem from '@platform360/server-inventory/web/components/Layout/NotificationsSettingsMenuItem';
import WebProsLogo from '@platform360/libs/shared-web/components/WebProsLogo';

const PleskEula = lazy(async () => ({ default: (await legalDocumentsLoader()).PleskEula }));
const CPanelEula = lazy(async () => ({ default: (await legalDocumentsLoader()).CPanelEula }));
const Privacy = lazy(async () => ({ default: (await legalDocumentsLoader()).Privacy }));
const Terms = lazy(async () => ({ default: (await legalDocumentsLoader()).Terms }));
const DataProcessingAgreement = lazy(async () => ({
    default: (await legalDocumentsLoader()).DataProcessingAgreement,
}));
const CookiesContent = lazy(async () => ({
    default: (await legalDocumentsLoader()).CookiesContent,
}));

const platformApplicationSettings: ApplicationSettings = {
    document: {
        title: 'Plesk 360',
        favicon: '/favicon.ico',
        darkThemeDataAttribute: 'legacy-dark',
    },
    logo: <PlatformLogo />,
    homePage: {
        isPrivate: true,
        component: <PlatformHomePage />,
    },
    notFoundPage: <PlatformNotFoundPage />,
    header: {
        apiTokensEnabled: true,
        businessProfileEnabled: true,
        globalSearchEnabled: true,
        dotsMenuEnabled: true,
        mainMenuEnabled: true,
        addon: <HeaderAddon />,
        userMenuAddons: [<NotificationsSettingsMenuItem key="notifications-settings-menu-item" />],
        baseUrl: '/',
    },
    footer: <Footer logo={<WebProsLogo isLink invert />} />,
    authLayout: AuthLayout,
    authSidebar: Sidebar,
    auth: {
        signUpMutation: useSignUp,
        branding: 'platform',
        termsOfUseLink: <TermsOfUseLink />,
        preferredSignup: false,
        areConsentsShown: true,
        signUpEmailFieldDescriptionShown: true,
        shouldNotifyLogout: true,
    },
    defaultLoginRedirect: '/',
    zendeskChat: {
        title: 'Chat with Plesk 360!',
        welcomeMessage: (name: string) =>
            `Hello, ${name}! You have contacted Plesk 360 Customer Support. One of our representatives will be with you shortly. Thank you for your patience.`,
        apiKey: 'b9c5db3b-c176-446f-9c1e-1c1f41a0be7d',
        department: '[Webpros] Plesk 360 CS Chat',
        concierge: {
            title: 'Plesk 360 Support',
            avatar: 'platform360-avatar.png',
        },
    },
    legalPage: {
        dpa: <DataProcessingAgreement />,
        pleskEula: <PleskEula />,
        cPanelEula: <CPanelEula />,
        privacy: <Privacy />,
        terms: <Terms />,
        cookiesContent: <CookiesContent cookiesList={<CookiesList />} />,
    },
    cookieManager: {
        cookieModify,
        cookieWithdraw,
    },
    licencesModule: {},
    usePlatform360LocaleCode: false,
};

export default platformApplicationSettings;
