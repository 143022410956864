// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ApplicationSettingsContext } from '@platform360/libs/shared-web/application-settings/context';
import { useContext } from 'react';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

/**
 * Returns a boolean value indicating whether or not to use notify logout.
 * We can't use `useApplicationSettings` hook inside `AuthProvider` since it depends on react router,
 * but it's not initialized there yet.
 */
const useNotifyLogout = (): boolean => {
    const { application } = useConfig();
    const applicationSettingsContext = useContext(ApplicationSettingsContext);

    if (applicationSettingsContext === undefined) {
        throw new Error('Application settings context must be initialized.');
    }

    const {
        auth: { shouldNotifyLogout },
    } = applicationSettingsContext[application];

    return shouldNotifyLogout;
};

export default useNotifyLogout;
