// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SET_VALUE } from '@platform360/licenses/web/units/adjust/constants/actions';
import { NewValuesOfProducts } from '@platform360/licenses/web/units/adjust/reducer/initialState';

export type SetValueAction = {
    type: typeof SET_VALUE;
    payload: {
        productId: number;
        value: NewValuesOfProducts;
    };
};

export const set = (productId: number, value: NewValuesOfProducts): SetValueAction => ({
    type: SET_VALUE,
    payload: { productId, value },
});
