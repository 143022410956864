// Copyright 2024. WebPros International GmbH. All rights reserved.

import StaticWidget from './StaticWidget';
import DynamicWidget from './DynamicWidget';
import { useCurrentUser } from '@platform360/libs/shared-web/user';

const HomePageWidgetContainer = ({ ...props }) => {
    const { monitoring } = useCurrentUser();

    if (!monitoring?.userId) {
        return <StaticWidget {...props} />;
    }

    return <DynamicWidget {...props} />;
};

export default HomePageWidgetContainer;
