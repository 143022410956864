// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentProps, KeyboardEventHandler, useState } from 'react';
import * as React from 'react';
import { Button, Cuttable, TextArea, TextAreaProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './InPlaceText.module.css';

type ViewTextProps = {
    value: string;
    onStartEditing: () => void;
};
const ViewText = ({ value, onStartEditing, ...props }: ViewTextProps) => {
    const translate = useTranslate('InPlaceText');

    return (
        <div {...props}>
            <Cuttable className={styles.preformatted} maxHeight="6rem">
                {value}
            </Cuttable>
            <Button
                className={styles.editButton}
                icon="pencil"
                onClick={onStartEditing}
                data-type="edit-button"
            >
                {value === '' ? translate('add') : translate('edit')}
            </Button>
        </div>
    );
};

type EditTextProps = {
    value: string;
    saving: boolean;
    onSave: (value: string) => void;
    onCancel: () => void;
    textareaProps?: ComponentProps<typeof TextArea>;
};
const EditText = ({ value, saving, onSave, onCancel, textareaProps, ...props }: EditTextProps) => {
    const translate = useTranslate('InPlaceText');
    const [draft, setDraft] = useState(value);
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDraft(event.target.value);
    };
    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        if (event.key === 'Escape') {
            onCancel();
        } else if (event.key === 'Enter' && event.ctrlKey) {
            onSave(draft);
        }
    };

    return (
        <div {...props}>
            <TextArea
                className={styles.textarea}
                value={draft}
                // @ts-expect-error fix type in UI Library
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                disabled={saving}
                size="fill"
                autoFocus
                {...textareaProps}
            />
            <div>
                <Button
                    ghost
                    icon="check-mark"
                    onClick={() => onSave(draft)}
                    state={saving ? 'loading' : 'active'}
                    disabled={saving}
                    data-type="ok-button"
                    tooltip={translate('ok')}
                />
                <Button
                    ghost
                    icon="cross-mark"
                    onClick={onCancel}
                    disabled={saving}
                    data-type="cancel-button"
                    tooltip={translate('cancel')}
                />
            </div>
        </div>
    );
};

export type InPlaceTextProps = {
    value: string;
    onEdit?: () => void;
    onSave: (value: string) => Promise<void>;
    textareaProps?: TextAreaProps;
};

export const InPlaceText = ({ value, onEdit, onSave, textareaProps }: InPlaceTextProps) => {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const handleStartEditing = () => {
        onEdit?.();
        setEditing(true);
    };
    const handleFinishEditing = () => {
        setSaving(false);
        setEditing(false);
    };

    const handleSave = async (value: string) => {
        try {
            setSaving(true);
            // TODO: show error as validation message
            await onSave(value.trim());
        } catch {
        } finally {
            handleFinishEditing();
        }
    };

    return editing ? (
        <EditText
            value={value}
            saving={saving}
            onSave={handleSave}
            onCancel={handleFinishEditing}
            textareaProps={textareaProps}
        />
    ) : (
        <ViewText value={value} onStartEditing={handleStartEditing} />
    );
};

export default InPlaceText;
