// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ChangeEventHandler } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Input } from '@plesk/ui-library';

type Value = {
    name?: string;
    value?: string;
};

type NameValuePairProps = {
    value: Value | undefined;
    onChange: (value: Value) => void;
};

export const NameValuePair = ({
    value: { name = '', value = '' } = {},
    onChange,
}: NameValuePairProps) => {
    const translate = useTranslate(
        'admin-panel.security-dashboard.Requests.RequestForm.NameValuePair',
    );

    const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange({
            name: e.target.value,
            value,
        });
    };

    const handleValueChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange({
            name,
            value: e.target.value,
        });
    };

    return (
        <>
            <Input value={name} onChange={handleNameChange} placeholder={translate('name')} />{' '}
            <Input value={value} onChange={handleValueChange} placeholder={translate('value')} />
        </>
    );
};
