// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, createContext, ComponentType } from 'react';

export const GlobalSearchContext = createContext<ComponentType | undefined>(undefined);

type GlobalSearchProviderProps = {
    globalSearch: ComponentType;
    children: ReactElement;
};

export const GlobalSearchProvider = ({ globalSearch, children }: GlobalSearchProviderProps) => (
    <GlobalSearchContext.Provider value={globalSearch}>{children}</GlobalSearchContext.Provider>
);
