// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createAction, handleActions } from 'redux-actions';
import { toaster } from '@platform360/libs/shared-web/toaster';
import { navigate } from '@platform360/libs/shared-web/helpers/history';
import { authorizedRequest } from './api';
import { FILTER_ACTIVE, FILTER_ALL } from './filter';
import { licensesUri } from '@platform360/licenses/web/helpers';
import doAPIRequest from '@platform360/licenses/web/api/doRequest';
import getAllKeys from '@platform360/licenses/web/api/ka/keys/getAll';
import { BEGIN_ADJUST_BY_KEY, CANCEL_ADJUST } from '@platform360/licenses/web/constants/actions';
import { captureException } from '@sentry/browser';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';

const SET_FILTER = 'KA.SET_FILTER';
const CHANGE_KEY_LIST = 'KA.CHANGE_KEY_LIST';
const RESET_KEY_LISTS = 'KA.RESET_KEY_LISTS';
const CHANGE_KEY = 'KA.CHANGE_KEY';
const CHANGE_KEY_TO_UPGRADE_PROPS = 'KA.CHANGE_KEY_TO_UPGRADE_PROPS';
const RESET_KEY = 'KA.RESET_KEY';
const CHANGE_AVAILABLE_UPGRADES = 'KA.CHANGE_AVAILABLE_UPGRADES';
const CHANGE_AVAILABLE_PROPERTIES_UPGRADES = 'KA.CHANGE_AVAILABLE_PROPERTIES_UPGRADES';
const CHANGE_AVAILABLE_UPSELLS = 'KA.CHANGE_AVAILABLE_UPSELLS';
const CHANGE_PRICE_CALCULATION = 'KA.CHANGE_PRICE_CALCULATION';
const CHANGE_PROPERTY_PRICE_CALCULATION = 'KA.CHANGE_PROPERTY_PRICE_CALCULATION';
const CHANGE_PRICE_CALCULATION_ERROR = 'KA.CHANGE_PRICE_CALCULATION_ERROR';
const CHANGE_PROPERTY_PRICE_CALCULATION_ERROR = 'KA.CHANGE_PROPERTY_PRICE_CALCULATION_ERROR';
const CHANGE_UPGRADE_COMPLETENESS = 'KA.CHANGE_UPGRADE_COMPLETENESS';
const CHANGE_UPGRADE_PROPERTY_COMPLETENESS = 'KA.CHANGE_UPGRADE_PROPERTY_COMPLETENESS';

export const setFilter = createAction(SET_FILTER);
export const changeKeyList = createAction(CHANGE_KEY_LIST);
export const changeKey = createAction(CHANGE_KEY);
export const changeKeyToUpgradeProps = createAction(CHANGE_KEY_TO_UPGRADE_PROPS);
export const resetKey = createAction(RESET_KEY);
export const changeAvailableUpgrades = createAction(CHANGE_AVAILABLE_UPGRADES);
export const changeAvailablePropertiesUpgrades = createAction(CHANGE_AVAILABLE_PROPERTIES_UPGRADES);
export const changeAvailableUpsells = createAction(CHANGE_AVAILABLE_UPSELLS);
export const changePriceCalculation = createAction(CHANGE_PRICE_CALCULATION);
export const changePriceCalculationError = createAction(CHANGE_PRICE_CALCULATION_ERROR);
export const changePropertyPriceCalculationError = createAction(
    CHANGE_PROPERTY_PRICE_CALCULATION_ERROR,
);
export const changeUpgradeCompleteness = createAction(CHANGE_UPGRADE_COMPLETENESS);

// the actions below are private, not recommended to use them outside this file
export const resetKeyLists = createAction(RESET_KEY_LISTS);

export { FILTER_ACTIVE, FILTER_ALL } from './filter';

// The keys of defaultKeyList must be null on the initial state or when the page is reloaded (refresh action) for showing ContentLoader.
const defaultKeyLists = {
    all: null,
    active: null,
};
export const defaultState = {
    keyLists: defaultKeyLists,
    filter: FILTER_ACTIVE,
    keys: {},
    keyToUpgradeProps: {},
    availableUpgrades: {},
    availableUpsells: {},
    availablePropertiesUpgrades: {},
    priceCalculation: null,
    upgradeCompleteness: false,
    upgradePropertyCompleteness: false,
    licenseNumberForAdjust: null,
};

const BASE_URL = '/ka';

const parseError = (error) => {
    if (
        error.response &&
        error.response.data &&
        error.response.data.details &&
        [
            'keyNotUnderOnlineStore',
            'notOwnLicense',
            'subscriptionNotFound',
            'unableToUpgrade',
            'unknownError',
            'pendingOperationFound',
        ].includes(error.response.data.details)
    ) {
        return error.response.data.details;
    }
    return 'errorKAUnavailable';
};

// eslint-disable-next-line no-unused-vars
const doRequest = (params) => (dispatch) =>
    authorizedRequest(params).catch((error) => {
        const parsedError = parseError(error);
        if (!(params.hideErrors && params.hideErrors.includes(parsedError))) {
            captureException(error);
            toaster.error(<Translate content={parsedError} />);
        }

        throw error;
    });

export const fetchAllKeysOnce = () => (dispatch) => {
    // TODO: propagate monitoring user id as a function argument, to avoid local storage sharing.
    const uid = localStorage.getItem('monitoring-user-id');

    Promise.all([doAPIRequest(getAllKeys(true, uid)), doAPIRequest(getAllKeys(false, uid))]).then(
        ([activeKeys, allKeys]) => {
            dispatch(changeKeyList({ filter: FILTER_ACTIVE, keyList: activeKeys }));
            dispatch(changeKeyList({ filter: FILTER_ALL, keyList: allKeys }));
        },
    );
};

export const changeFilter = (filter) => (dispatch, getState) => {
    if (filter && getFilter(getState()) !== filter) {
        dispatch(setFilter({ filter }));
    }
};

export const fetchKey = (id) => (dispatch) =>
    dispatch(
        doRequest({
            url: `${BASE_URL}/keys/${id}`,
        }),
    )
        .then((key) => {
            dispatch(changeKey({ id, key }));
        })
        .catch(() => {
            dispatch(navigate(licensesUri));
        });

export const fetchAvailableUpgrades = (id) => (dispatch) =>
    dispatch(
        doRequest({
            url: `${BASE_URL}/keys/${id}/upgrades`,
        }),
    )
        .then((availableUpgrades) => {
            dispatch(changeAvailableUpgrades({ id, availableUpgrades }));
        })
        .catch(() => {
            dispatch(navigate(licensesUri));
        });

export const fetchAvailableUpsells = (id) => (dispatch) =>
    dispatch(
        doRequest({
            url: `${BASE_URL}/keys/${id}/upsells`,
        }),
    )
        .then((availableUpsells) => {
            dispatch(changeAvailableUpsells({ id, availableUpsells }));
        })
        .catch(() => {
            dispatch(navigate(licensesUri));
        });

const doAddSubscriptionItemRequest =
    ({ keyId, productId, preview, subscriptionId }) =>
    (dispatch) =>
        dispatch(
            doRequest({
                url: `${BASE_URL}/keys/${keyId}/online-store-add`,
                method: 'POST',
                params: {
                    productId,
                    preview,
                    subscriptionId,
                },
                hideErrors: ['pendingOperationFound'],
            }),
        );

const doReplaceSubscriptionItemRequest = (subscriptionItemId, productId, preview) => (dispatch) =>
    dispatch(
        doRequest({
            url: `${BASE_URL}/online-store-subscription-item/${subscriptionItemId}/upgrade`,
            method: 'POST',
            params: {
                productId,
                preview,
            },
            hideErrors: ['pendingOperationFound'],
        }),
    );

export const fetchPriceCalculationForKey = (keyId, productId, subscriptionId) => (dispatch) => {
    dispatch(changePriceCalculation(null));
    return dispatch(
        doAddSubscriptionItemRequest({ keyId, productId, preview: true, subscriptionId }),
    )
        .then((priceCalculation) => {
            dispatch(changePriceCalculation(priceCalculation));
        })
        .catch((error) => {
            if (error.response && error.response.data && error.response.data.details) {
                dispatch(changePriceCalculationError(error.response.data.details));
            }
        });
};

export const fetchPriceCalculationForSubscriptionItem =
    (replacementFor, productId) => (dispatch) => {
        dispatch(changePriceCalculation(null));
        return dispatch(doReplaceSubscriptionItemRequest(replacementFor, productId, true))
            .then((priceCalculation) => {
                dispatch(changePriceCalculation(priceCalculation));
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.details) {
                    dispatch(changePriceCalculationError(error.response.data.details));
                }
            });
    };

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const addSubscriptionItem =
    ({ keyId, productId, message, subscriptionId }) =>
    (dispatch) =>
        dispatch(doAddSubscriptionItemRequest({ keyId, productId, preview: false, subscriptionId }))
            .then(() => sleep(2000))
            .then(() => {
                dispatch(changeUpgradeCompleteness(true));
                if (message) {
                    toaster.success(message);
                }
            })
            .catch(() => {
                dispatch(navigate(licensesUri));
            });

export const replaceSubscriptionItem =
    ({
        extensionCatalogCode,
        subscriptionItem,
        productId,
        message,
        replaceSubscriptionItemCallbacks,
    }) =>
    (dispatch) =>
        dispatch(doReplaceSubscriptionItemRequest(subscriptionItem, productId, false))
            .then(() => sleep(2000))
            .then(() => {
                replaceSubscriptionItemCallbacks.onSuccess(extensionCatalogCode);
                dispatch(changeUpgradeCompleteness(true));
                if (message) {
                    toaster.success(message);
                }
                setTimeout(() => {
                    // Refresh key list after 5 seconds for the case when the key is not yet updated
                    dispatch(refreshKeyList());
                }, 5000);
            })
            .catch(() => {
                replaceSubscriptionItemCallbacks.onFail(extensionCatalogCode);
                dispatch(navigate(licensesUri));
            });

export default handleActions(
    {
        [SET_FILTER]: (state, { payload: { filter } }) => ({
            ...state,
            filter,
        }),
        [CHANGE_KEY_LIST]: (state, { payload: { filter, keyList } }) => ({
            ...state,
            keyLists: {
                ...state.keyLists,
                [filter]: keyList,
            },
        }),
        [RESET_KEY_LISTS]: (state) => ({
            ...state,
            keyLists: defaultKeyLists,
        }),
        [CHANGE_KEY]: (state, { payload: { id, key } }) => ({
            ...state,
            keys: {
                ...state.keys,
                [id]: key,
            },
        }),
        [CHANGE_KEY_TO_UPGRADE_PROPS]: (state, { payload }) => ({
            ...state,
            keyToUpgradeProps: payload,
        }),
        [RESET_KEY]: (state, { payload: id }) => ({
            ...state,
            keys: Object.keys(state.keys).reduce((acc, k) => {
                if (k !== id) {
                    acc[k] = state.keys[k];
                }
                return acc;
            }, {}),
        }),
        [CHANGE_AVAILABLE_UPGRADES]: (state, { payload: { id, availableUpgrades } }) => ({
            ...state,
            availableUpgrades: {
                ...state.availableUpgrades,
                [id]: availableUpgrades,
            },
        }),
        [CHANGE_AVAILABLE_PROPERTIES_UPGRADES]: (
            state,
            { payload: { id, availablePropertiesUpgrades } },
        ) => ({
            ...state,
            availablePropertiesUpgrades: {
                ...state.availablePropertiesUpgrades,
                [id]: availablePropertiesUpgrades,
            },
        }),
        [CHANGE_AVAILABLE_UPSELLS]: (state, { payload: { id, availableUpsells } }) => ({
            ...state,
            availableUpsells: {
                ...state.availableUpsells,
                [id]: availableUpsells,
            },
        }),
        [CHANGE_PRICE_CALCULATION]: (state, { payload }) => ({
            ...state,
            priceCalculation: payload,
            priceCalculationError: null,
        }),
        [CHANGE_PRICE_CALCULATION_ERROR]: (state, { payload }) => ({
            ...state,
            priceCalculationError: payload,
            priceCalculation: null,
        }),
        [CHANGE_PROPERTY_PRICE_CALCULATION]: (state, { payload }) => ({
            ...state,
            propertyPriceCalculation: payload,
            propertyPriceCalculationError: null,
        }),
        [CHANGE_PROPERTY_PRICE_CALCULATION_ERROR]: (state, { payload }) => ({
            ...state,
            propertyPriceCalculationError: payload,
            propertyPriceCalculation: null,
        }),
        [CHANGE_UPGRADE_COMPLETENESS]: (state, { payload }) => ({
            ...state,
            upgradeCompleteness: payload,
        }),
        // @todo if this used only for adjust - remove
        [CHANGE_UPGRADE_PROPERTY_COMPLETENESS]: (state, { payload }) => ({
            ...state,
            upgradePropertyCompleteness: payload,
        }),

        [BEGIN_ADJUST_BY_KEY]: (state, { payload }) => ({
            ...state,
            licenseNumberForAdjust: payload,
        }),
        [CANCEL_ADJUST]: (state) => ({
            ...state,
            licenseNumberForAdjust: null,
        }),
    },
    defaultState,
);

export const STATE_KEY = 'ka';

export const getFilter = (state) => state[STATE_KEY].filter;
export const getKeyToUpgradeProps = (state) => state[STATE_KEY].keyToUpgradeProps || null;
export const getKeyLists = (state) => state[STATE_KEY].keyLists;
export const getKey = (state, id) => state[STATE_KEY].keys[id] || null;
export const getAvailableUpgrades = (state, id) => state[STATE_KEY].availableUpgrades[id] || null;
export const getAvailableUpsells = (state, id) => state[STATE_KEY].availableUpsells[id] || null;
export const getPriceCalculation = (state) => state[STATE_KEY].priceCalculation;
export const getPriceCalculationError = (state) => state[STATE_KEY].priceCalculationError;
export const getUpgradeCompleteness = (state) => state[STATE_KEY].upgradeCompleteness;
