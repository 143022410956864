// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Suspense } from 'react';
import {
    // eslint-disable-next-line no-restricted-imports
    Routes,
    Route,
    useLocation,
    Navigate,
    Link,
} from 'react-router-dom';
import { Tabs, Tab, ContentLoader } from '@plesk/ui-library';
import Layout from '@platform360/libs/shared-web/components/Public';
import OptOuts from './OptOuts';
import Cookies from './Cookies';
import styles from './Legal.module.css';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { insertIf } from '@platform360/libs/common/insert-if';

const legalPath = '/legal';

const Legal = () => {
    const translate = useTranslate('app.Legal');
    const {
        legalPage: { terms, privacy, dpa, eula, pleskEula, cPanelEula },
    } = useApplicationSettings();

    const pages = [
        {
            url: 'terms',
            title: translate('terms'),
            icon: 'four-squares',
            component: terms,
        },
        ...insertIf(!!eula, {
            url: 'eula',
            title: translate('eula'),
            icon: 'globe',
            component: eula,
        }),
        ...insertIf(!!pleskEula, {
            url: 'plesk-eula',
            title: translate('pleskEula'),
            icon: 'globe',
            component: pleskEula,
        }),
        ...insertIf(!!cPanelEula, {
            url: 'cpanel-eula',
            title: translate('cPanelEula'),
            icon: 'globe',
            component: cPanelEula,
        }),
        {
            url: 'privacy-policy',
            title: translate('privacyPolicy'),
            icon: 'eye-closed',
            component: privacy,
        },
        {
            url: 'data-processing-agreement',
            title: translate('dpa'),
            icon: 'database',
            component: dpa,
        },
        {
            url: 'opt-outs',
            title: translate('optOuts'),
            icon: 'check-mark-circle',
            component: <OptOuts />,
        },
        {
            url: 'cookie-statement',
            title: translate('cookies'),
            icon: 'check-list',
            component: <Cookies />,
        },
    ] as const;

    const { pathname } = useLocation();
    const activeTab = pages.findIndex(({ url }) => pathname.includes(url)) + 1;
    return (
        <Layout isWide>
            <div className={styles.panel}>
                <Tabs active={activeTab}>
                    {pages.map(({ url, title, icon }) => (
                        <Tab
                            key={url}
                            title={title}
                            icon={icon}
                            component={Link}
                            to={`${legalPath}/${url}`}
                        />
                    ))}
                </Tabs>
                <Routes>
                    {pages.map(({ url, component }) => (
                        <Route
                            key={url}
                            path={url}
                            element={<Suspense fallback={<ContentLoader />}>{component}</Suspense>}
                        />
                    ))}
                    <Route path="*" element={<Navigate to={pages[0].url} replace />} />
                </Routes>
            </div>
        </Layout>
    );
};

export default Legal;
