// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getServersSummary } from '@platform360/security-dashboard/web/api/servers';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseServersSummaryQueryVariables = undefined;

export type UseServersSummaryQueryData = PromiseValue<ReturnType<typeof getServersSummary>>;

const useServersSummaryQuery = createQuery<
    UseServersSummaryQueryVariables,
    UseServersSummaryQueryData
>({
    queryName: 'security-dashboard/useServersSummaryQuery',
    fetcher: getServersSummary,
    useQuery: (options) => useQuery({ ...options, refetchOnWindowFocus: false }),
});

export default useServersSummaryQuery;
