// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComboBox, ComboBoxProps, ComboBoxOption } from '@plesk/ui-library';

type ComboBoxTimeBaseProps = {
    value: number;
    max: number;
    onChange: (value: number) => void;
};

type ComboBoxTimeProps = ComboBoxTimeBaseProps &
    Omit<ComboBoxProps, keyof ComboBoxTimeBaseProps | 'children'>;

const renderValue = (value: number) => `${value < 10 ? '0' : ''}${value}`;

const ComboBoxTime = ({ value: externalValue, onChange, max, ...props }: ComboBoxTimeProps) => {
    const value = renderValue(externalValue);

    const handleChange = (value: string) => {
        let val = Number(value.replace(/\D/, ''));

        val = val > max ? val % 10 : Number(val);

        onChange(val);
    };

    return (
        <ComboBox
            onChange={handleChange}
            value={value}
            // @ts-expect-error
            onClick={() => {
                if (document.activeElement?.tagName !== 'INPUT') return;
                (document.activeElement as HTMLInputElement).select();
            }}
            {...props}
        >
            {Array(max + 1)
                .fill(1)
                .map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ComboBoxOption key={index}>{renderValue(index)}</ComboBoxOption>
                ))}
        </ComboBox>
    );
};

export default ComboBoxTime;
