// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Label, UpdateSettings } from '@platform360/security-dashboard/web/types';
import { ReactElement, ReactNode } from 'react';
import LabelItem from './LabelItem';
import styles from './LabelsList.module.css';

import { LabelType, LabelTypes } from '@platform360/security-dashboard/shared/label-type';
import useTranslateLabel from '@platform360/security-dashboard/web/hooks/useTranslateLabel';
import { Dropdown, LabelIntent, Menu, Tooltip } from '@plesk/ui-library';
import UpdatesLabel from './UpdatesLabel';

export type LabelsListProps = {
    labels: Label[];
    mitigationLabels?: ReactNode;
    shouldDisplayUnmanagedLabel?: boolean;
    updates?: UpdateSettings;
    renderMenuItems?: (options: {
        label: Label;
        localisation: {
            remove: ReactElement;
            filter: ReactElement;
        };
    }) => ReactElement[] | null;
};

const intentionsByLabelType: Partial<Record<LabelType, LabelIntent>> = {
    [LabelTypes.ignore]: 'danger',
    [LabelTypes.doNotProtect]: 'danger',
    [LabelTypes.ignoredDueToLowRisk]: 'danger',
};

export const LabelsList = ({
    labels,
    mitigationLabels,
    shouldDisplayUnmanagedLabel,
    updates,
    renderMenuItems,
    ...props
}: LabelsListProps) => {
    const translate = useTranslate('security-dashboard.LabelsList');
    const translateLabel = useTranslateLabel();

    return (
        <div className={styles.container} {...props}>
            {shouldDisplayUnmanagedLabel && (
                <Tooltip title={translate('unmanaged.tooltip')}>
                    <LabelItem intent="danger" data-type="unmanaged-label">
                        {translate('unmanaged.title')}
                    </LabelItem>
                </Tooltip>
            )}
            {mitigationLabels}

            {updates !== undefined && <UpdatesLabel updates={updates} />}
            {labels.map((label) => {
                const translation = translateLabel(label);

                const intent = intentionsByLabelType[label.type];

                const labelItem = (
                    <LabelItem
                        key={label.id}
                        intent={intent ?? 'inactive'}
                        tooltip={translation.tooltip}
                    >
                        {translation.title}
                    </LabelItem>
                );

                const menuItems: ReactElement[] = [];

                const additionalMenuItems = renderMenuItems?.({
                    label,
                    localisation: {
                        filter: translate('menu.filter'),
                        remove: translate('menu.remove'),
                    },
                });

                if (additionalMenuItems) {
                    menuItems.push(...additionalMenuItems);
                }

                if (!menuItems.length) return labelItem;

                const menu = <Menu>{menuItems}</Menu>;

                return (
                    <Dropdown key={label.id} menu={menu} className={styles.dropdown}>
                        {labelItem}
                    </Dropdown>
                );
            })}
        </div>
    );
};
