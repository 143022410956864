// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';
import { editLabel } from '@platform360/security-dashboard/web/api/labels';
import { isValidationError } from '@platform360/libs/shared-web/helpers/apiErrors';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

const useEditLabelMutation = () => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useEditLabelMutation');
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: editLabel,
        onSuccess: async () => {
            refetchList(useInstallationsQuery.getQueryKey());

            await queryClient.invalidateQueries({
                queryKey: useLabelsQuery.getQueryKey(),
            });
            void queryClient.invalidateQueries({
                queryKey: useServersQuery.getQueryKey(),
            });

            successToast(translate('success'));
        },
        onError: (e) => {
            if (isValidationError(e)) return;

            errorToast(translate('failed'));
        },
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useEditLabelMutation;
