// Copyright 2024. WebPros International GmbH. All rights reserved.
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import SettingsDrawer, {
    FormValues,
} from '@platform360/security-dashboard/web/components/Servers/SettingsDrawer/SettingsDrawer';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { UpdateSettingsOptions } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import { SaveServerSettingsRequest } from '@platform360/security-dashboard/web/api/servers';
import useSaveServersSettingsMutation from '@platform360/security-dashboard/web/mutations/useSaveServersSettingsMutation';
import { useParams } from 'react-router-dom';

export const defaultInitialFormValues: FormValues = Object.freeze({
    updates: {
        core: UpdateSettingsOptions.doNotTouch,
        plugins: UpdateSettingsOptions.doNotTouch,
        themes: UpdateSettingsOptions.doNotTouch,
        safeUpdate: SafeUpdateOptions.doNotTouch,
    },
    dailyTask: undefined,
});

export type MassSettingsDrawerProps = { onSave: (serversIds: number[]) => void } & ClosableProps;

const MassSettingsDrawer = ({ isOpen, onClose, onSave, ...props }: MassSettingsDrawerProps) => {
    const translate = useTranslate('security-dashboard.Servers.SettingsDrawer');
    const { serverIds: srvIds } = useParams<{ serverIds: string }>();
    const serverIds: number[] = srvIds?.split(',').map(Number) ?? [];

    const { mutate, isPending: isSaving } = useSaveServersSettingsMutation();

    const handleSave = (requestData: SaveServerSettingsRequest, onSuccess: () => void) => {
        mutate(
            { serverIds, settings: requestData },
            {
                onSuccess: () => {
                    onSuccess();
                    onSave(serverIds);
                },
            },
        );
    };

    const renderSubtitle = () => translate('subTitle.mass', { count: serverIds.length });

    return (
        <SettingsDrawer
            subTitle={renderSubtitle()}
            initialFormValues={defaultInitialFormValues}
            isMassOperation
            drawerBaseUrl={`/security-dashboard/servers/mass-settings/${srvIds}`}
            isSaving={isSaving}
            onSave={handleSave}
            isOpen={isOpen}
            onClose={onClose}
            {...props}
        />
    );
};

export default MassSettingsDrawer;
