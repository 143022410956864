// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { depersonate } from '@platform360/accounts/web/api/me';

type UseDepersonateMutationParams = {
    onSuccess: () => void;
};

const useDepersonateMutation = ({ onSuccess }: UseDepersonateMutationParams) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: depersonate,
        onSuccess: async () => {
            await queryClient.invalidateQueries();
            onSuccess();
        },
    });
};

export default useDepersonateMutation;
