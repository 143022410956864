// Copyright 2024. WebPros International GmbH. All rights reserved.

import logo from './logo.svg';
import styles from './Logo.module.css';
import { Link } from 'react-router-dom';

export const Logo = ({ ...props }) => (
    <div className={styles.root}>
        <Link to="/admin-panel">
            <img src={logo} alt="Platform360 Admin Panel" {...props} />
        </Link>
    </div>
);
