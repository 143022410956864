// Copyright 2024. WebPros International GmbH. All rights reserved.
import { ItemList, Section } from '@plesk/ui-library';
import Extension, {
    ExtensionProps,
} from '@platform360/licenses/web/units/extensions/components/Extension';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

export type GroupProps = {
    name: string;
    list: ExtensionProps[];
};

export type ExtensionsProps = {
    groups: GroupProps[];
    onExtensionSelect: (id: string) => void;
};

const Extensions = ({ groups, onExtensionSelect }: ExtensionsProps) => {
    const analyticsEvents = useLicensesAnalyticsEvents();

    return (
        <>
            {groups.map(({ name, list }: GroupProps) => (
                <Section key={name} title={name}>
                    <ItemList minColumnWidth={220} gap="md">
                        {list.map((item: ExtensionProps) => (
                            <Extension
                                key={`${item.catalogCode}_${item.catalogCode}`}
                                {...item}
                                onClick={() => {
                                    analyticsEvents.licensesAddExtensionDialogSelectClicked();
                                    onExtensionSelect(`${name}.${item.catalogCode}`);
                                }}
                            />
                        ))}
                    </ItemList>
                </Section>
            ))}
        </>
    );
};

export default Extensions;
