// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createAction } from 'redux-actions';

const NAVIGATE = 'HISTORY.NAVIGATE';

export const navigate = createAction(
    NAVIGATE,
    (url, options) => ({
        url,
        ...options,
    }),
    () => ({
        history: true,
    }),
);
