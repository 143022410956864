// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: '不明なエラー：{message}.',
        newServer: '同じアドレスのサーバが既に存在します。',
        domainName: 'ドメイン名を解決できません。',
        serverMisconfigured: 'このサーバは正しく構成されていないため、追加できません。',
        pleskConfiguration: 'Plesk がサポート対象外のバージョン（{pleskMinVersion} より前）であるか、正しく構成されていません。',
        unsupportedServerVersion: 'インストールされている Plesk のバージョンが古いため、追加できません。{kbLink}',
        connection: 'ネットワーク接続が切断されました。',
        connectionRefused: 'ネットワーク接続が拒否されました。指定されたドメインとポートをチェックしてください。',
        timeout: 'サーバへのネットワーク接続がタイムアウトしました。',
        apiKey: 'この接続はリモートサーバによって認証されていません。サーバインベントリにサーバを再度追加してください。',
        httpsRequired: 'このサーバは HTTPS に対応していません。',
        certHasExpired: '証明書の期限が切れています。{kbLink}',
        errTlsCertAltnameInvalid: 'サーバのホスト名または IP アドレスが証明書の代替名と一致しません。{kbLink}',
        depthZeroSelfSignedCert: '自己署名証明書がインストールされました。{kbLink}',
        selfSignedCertInChain: '証明書チェーンに自己署名証明書があります。{kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: '通知設定が更新されました。',
        },
    },
    Layout: {
        title: 'サーバインベントリ',
        serversTitle: 'サーバ',
        clientsTitle: '顧客',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: '通知設定',
            },
        },
        NotificationsSettingsMenuItem: {
            title: '通知',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: '送信する通知',
        notifyServerUnreachable: 'サーバが到達不能',
        notifyServerUnsupported: 'サーバがサポート対象外',
    },
    AllDomains: {
        title: 'ウェブサイト',
        name: '名前',
        hostingType: 'ホスティングタイプ',
        virtualHostingType: 'ウェブサイト',
        forwardingHostingType: '転送',
        noneHostingType: 'ホスティングなし',
        mainDomainType: 'メインドメイン',
        subDomainType: 'サブドメイン',
        addonDomainType: 'アドオンドメイン',
        parkedDomainType: 'エイリアス',
        hostingServer: 'ホスティングサーバ',
        manage: '管理',
        manageLinkUnavailableHint: 'Plesk パネルへのダイレクトリンクを生成するには、Plesk RESTful API 1.4.0 以降が必要です。詳細はドキュメントを参照してください。',
        open: '開く',
    },
    AllClients: {
        name: '担当者名',
        email: 'メールアドレス',
        login: 'ユーザ名',
        type: 'タイプ',
        status: 'ステータス',
        typeAdmin: '管理者',
        typeReseller: 'リセラー',
        typeCustomer: '顧客',
        typeCustomerWithOwner: '{owner} の顧客',
        statusActive: 'アクティブ',
        statusSuspended: '一時停止',
        hostingServer: 'ホスティングサーバ',
        manage: '管理',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: '同期を実行中',
            installationRunning: 'インストールを実行中です',
            installationFailure: 'インストールエラー',
            installationLicenseRequired: 'ライセンスが必要',
            active: 'アクティブ、安全',
            inactiveUnreachable: '非アクティブ、到達不能',
            inactiveUnreachableTooltip: 'サーバに少なくとも 14 日間到達できませんでした',
            inactiveUnsupported: '非アクティブ、サポート対象外',
            inactiveUnsupportedTooltip: 'サーバがサポート対象外の Plesk バージョンを実行しています',
            insecure: 'アクティブ、安全ではない',
            outdated: 'アクティブ、期限切れ',
            outdatedTooltip: '{version, select, _ {現在の Plesk は古いバージョンです。} other {現在の Plesk バージョンは {version} で古いバージョンです。}} 利用可能な最新の Plesk Obsidian バージョンにアップデートして自動更新を有効にすることをお勧めします。',
            unsupportedVersion: 'アクティブ、サポート対象外',
            unsupportedVersionTooltip: '{version, select, _ {現在の Plesk バージョンはサポート対象外です。} other {現在の Plesk バージョンは {version} でサポート対象外です。}} サーバは 14 日以内に非アクティブ化されます。利用可能な最新の Plesk Obsidian バージョンにアップデートして自動更新を有効にすることをお勧めします。',
            certificateWarning: '証明書の警告',
            certificateError: '証明書のエラー',
            connectionBlocked: '接続がブロックされました',
            connectionError: '接続エラー',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'インストールのログが見つかりません。',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {現在の Plesk は古いバージョンです。} other {現在の Plesk バージョンは {version} で古いバージョンです。}}',
                        fixDescription: '利用可能な最新の Plesk Obsidian バージョンにアップデートして自動更新を有効にすることをお勧めします。詳細は <link>Plesk ライフサイクルポリシー</link>をお読みください。',
                        goToUpdatesButton: '{product} アップデートに移動',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {現在の Plesk バージョンはサポート対象外です。} other {現在の Plesk バージョンは {version} でサポート対象外です。}} サーバは 14 日以内に非アクティブ化されます。',
                        fixDescription: '利用可能な最新の Plesk Obsidian バージョンにアップデートして自動更新を有効にすることをお勧めします。詳細は <link>Plesk ライフサイクルポリシー</link>をお読みください。',
                        goToUpdatesButton: '{product} アップデートに移動',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: '14 日間サーバに接続できなかったため、このサーバを非アクティブとしました。',
                        lastCheckMessage: '前回の接続ステータスチェック：{lastCheckConnection}',
                        fixDescription: '使用を再開するには、［接続を再チェック］をクリックしてください',
                        recheckConnectionButton: '接続を再チェック',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'サーバは{version, select, _ {サポート対象外の Plesk バージョン} other {サポート対象外の Plesk バージョン {version}}}を実行しているため、非アクティブとマークされています。',
                        fixDescription: 'サーバをアクティブにするには、Plesk をアップデートしてサーバ同期を実行してください。',
                        goToUpdatesButton: '{product} アップデートに移動',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'サーバを追加',
        },
        AddingServerWizard: {
            StartStep: {
                title: '新規サーバを追加',
                closeButton: '終了',
                serverInstallTitle: '{productType} をインストール',
                installNewProduct: '新しい製品を Linux サーバにインストール',
                connectInstallation: '製品がインストールされたサーバに接続',
                serverInstallDescription: '物理サーバまたは仮想サーバに {productType} をインストールしてサーバインベントリに追加します。',
                connectPleskTitle: 'Plesk を接続',
                connectPleskDescription: 'Plesk がインストールされたサーバをサーバインベントリに追加します。',
                connectCPanelTitle: 'cPanel を接続',
                connectCPanelDescription: 'cPanel がインストールされたサーバをサーバインベントリに追加します。',
            },
            CustomInstallationStep: {
                title: 'Linux サーバへの {productType} の自動インストール',
                backButton: '戻る',
                submitButton: 'インストール',
                successMessage: '{productType} を <b>{host}</b> にインストール中です。',
            },
            AddServerWithRedirectStep: {
                title: 'Plesk サーバを接続',
                description: '［接続］をクリックすると、Plesk にリダイレクトされます。ここで、管理者権限でログインする必要があります。',
                proceed: '接続',
                proceedAnyway: 'このまま接続する',
                nextStep: '次のステップ',
                back: '戻る',
                addressLabel: 'サーバのホスト名',
                addressDescription: '接続先のサーバの URL とポート（例：https://plesk.com:8443）。',
            },
            ConnectCpanelServerStep: {
                title: 'cPanel サーバを接続',
                close: '終了',
                back: '戻る',
                paragraph1: 'cPanel プラグインインストーラをダウンロードして実行します。root 権限を使用してサーバコンソールに以下のスニペットをコピー＆ペーストしてください。',
                paragraph2: '<a>Plesk 360 API トークン</a>が必要です。新規作成するか、既存のものを使用できます。',
                paragraph3: '新規作成したサーバはサーバインベントリに表示されます。',
                copied: 'コピー済み.',
            },
        },
    },
};