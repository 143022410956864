// Copyright 2024. WebPros International GmbH. All rights reserved.

import EmailNotVerifiedException from '@platform360/libs/shared-web/auth/EmailNotVerifiedException';
import parseJson from '@platform360/libs/common/parse-json';

/**
 * Custom error triggered in Auth0 custom action that attached to auth flow before login.
 * @see https://auth0.com/docs/customize/actions
 */
export type EmailNotVerifiedError = {
    code: 'email-not-verified';
    reason: string;
    email: string;
};

export const isEmailNotVerifiedError = (err: unknown): err is EmailNotVerifiedError => {
    if (typeof err !== 'object' || err === null) {
        return false;
    }

    const { code, reason, email } = err as Partial<EmailNotVerifiedError>;
    return code === 'email-not-verified' && typeof reason === 'string' && typeof email === 'string';
};

const parseEmailNotVerifiedJson = (error: Error): EmailNotVerifiedException | undefined => {
    const json = parseJson(error.message);

    if (isEmailNotVerifiedError(json)) {
        const { reason, email } = json;

        return new EmailNotVerifiedException(reason, email);
    }

    return undefined;
};

export default parseEmailNotVerifiedJson;
