// Copyright 2024. WebPros International GmbH. All rights reserved.

import { JSX } from 'react';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { Navigate } from 'react-router-dom';
import Loading from '@platform360/libs/shared-web/components/Loading';

export type ProtectedRouteProps = {
    permission: string;
    children: JSX.Element;
};

export const ProtectedRoute = ({
    permission: requiredPermission,
    children,
}: ProtectedRouteProps) => {
    const { permissions: userPermissions, teamGuid } = useCurrentUser();

    if (!teamGuid) {
        return <Loading />;
    }

    if (!userPermissions?.includes(requiredPermission)) {
        return <Navigate to="/not-found" replace />;
    }

    return children;
};
