// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Select, SelectOption, Toolbar } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import periodToText from '../../../helpers/periodToText';
import Subscription from '@platform360/licenses/web/units/extensions/types/Subscription';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';

type SubscriptionSelectorProps = {
    subscriptions: Subscription[];
    period: BillingPeriod;
    onChange: (subscriptionId: string | number | undefined) => void;
    selected: string;
};

const SubscriptionSelector = ({
    subscriptions,
    period,
    onChange,
    selected,
}: SubscriptionSelectorProps) => {
    const translate = useTranslate();
    const billingCycle = translate(periodToText(period));

    return (
        <>
            <Heading>{translate('subscriptionToUpgradeHeader')}</Heading>
            <Toolbar>
                <Select
                    onChange={onChange}
                    value={selected}
                    size="fill"
                    data-type="subscription-select"
                >
                    {subscriptions.map(({ subscriptionId: id, currency }) => (
                        <SelectOption value={id} label={id} key={id}>
                            {translate('selectedSubscription', {
                                subscriptionId: id,
                                billingDate: currency,
                                billingCycle,
                            })}
                        </SelectOption>
                    ))}
                </Select>
            </Toolbar>
        </>
    );
};

export default SubscriptionSelector;
