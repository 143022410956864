// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Button,
    Popover,
    Heading,
    Badge,
    List,
    ListEmptyView,
    ListActions,
    ListAction,
    Toolbar,
    ToolbarGroup,
    Action,
} from '@plesk/ui-library';
import { Notification } from '@platform360/security-dashboard/web/types';
import notificationsEmptyImage from './notifications-empty.png';
import { categoryToIntentsMap, getMostIntent } from './helpers';
import TitleColumn from './TitleColumn';
import DateColumn from './DateColumn';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useNavigate } from 'react-router-dom';
import styles from './Notifications.module.css';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';

export type NotificationsProps = {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    isLoading: boolean;
    notifications?: Notification[];
    onChangeReadRow: (id: number, value: boolean) => void;
    onDeleteRow: (id: number) => void;
    onMarkAllAsRead: () => void;
    onSelectRow: (id: number) => void;
};

export const Notifications = ({
    isOpen,
    onOpen,
    onClose,
    isLoading,
    notifications,
    onChangeReadRow,
    onDeleteRow,
    onMarkAllAsRead,
    onSelectRow,
    ...props
}: NotificationsProps) => {
    const translate = useTranslate('security-dashboard.Notifications');
    const unreadNotifications = notifications?.filter((notification) => !notification.isRead) || [];
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const navigate = useNavigate();
    const getModalUrl = useGetModalUrl();
    const notificationSettingsModalUrl = getModalUrl('notifications-settings');
    const handleNotificationsOpen = () => {
        analyticsEvents.wpGloNotificationsClick(unreadNotifications.length);
        onOpen();
    };

    const button = (
        <Badge
            label={unreadNotifications.length > 9 ? '9+' : unreadNotifications.length}
            intent={getMostIntent(unreadNotifications)}
            hidden={unreadNotifications.length === 0}
            data-type="notifications-badge"
        >
            <Button
                icon="bell"
                title={translate('title')}
                ghost
                onClick={handleNotificationsOpen}
                data-type="show-notifications-button"
            />
        </Badge>
    );

    const handleOpenNotificationsSettings = () => {
        onClose();
        navigate(notificationSettingsModalUrl);
        analyticsEvents.wpGloNotificationSettingsClick();
    };

    return (
        <Popover
            className={styles.popover}
            visible={isOpen}
            onClose={onClose}
            target={button}
            title={<Heading level={3}>{translate('title')}</Heading>}
            placement="bottom"
            width="sm"
            height={600}
            data-type="notifications-popover"
            extra={
                <Action
                    icon="gear"
                    onClick={handleOpenNotificationsSettings}
                    data-type="notifications-settings-link"
                >
                    {translate('settings')}
                </Action>
            }
            {...props}
        >
            <List
                data={notifications}
                rowKey="id"
                loading={isLoading}
                toolbar={
                    <Toolbar>
                        <ToolbarGroup title="">
                            <Button
                                icon="mail"
                                onClick={onMarkAllAsRead}
                                data-type="read-all-notifications-button"
                            >
                                {translate('markAllAsRead')}
                            </Button>
                        </ToolbarGroup>
                    </Toolbar>
                }
                columns={[
                    {
                        key: 'title',
                        type: 'title',
                        render: ({ id, category, code, params, isRead }) => (
                            <TitleColumn
                                id={id}
                                code={code}
                                params={params}
                                isRead={isRead}
                                intent={categoryToIntentsMap[category]}
                                onChangeReadRow={onChangeReadRow}
                                onSelectRow={onSelectRow}
                            />
                        ),
                    },
                    {
                        key: 'createdAt',
                        render: ({ id, createdAt }) => (
                            <DateColumn date={createdAt} onClick={() => onSelectRow(id)} />
                        ),
                        width: '12%',
                    },
                    {
                        key: 'actions',
                        type: 'actions',
                        render: ({ id }) => (
                            <ListActions>
                                <ListAction
                                    icon="recycle"
                                    primary
                                    onClick={() => onDeleteRow(id)}
                                    data-type="delete-notification-button"
                                >
                                    {translate('deleteNotification')}
                                </ListAction>
                            </ListActions>
                        ),
                        width: '1%',
                    },
                ]}
                emptyView={
                    <ListEmptyView
                        title={translate('listEmptyTitle')}
                        image={notificationsEmptyImage}
                        description={translate('listEmptyDescription')}
                    />
                }
            />
        </Popover>
    );
};
