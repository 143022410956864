// Copyright 2024. WebPros International GmbH. All rights reserved.
import * as currencies from '../../constants/currency';
import Currency from '@platform360/licenses/web/types/currency';

type CostProps = {
    value: number;
    currency: Currency;
};

const Cost = ({ value, currency }: CostProps) => {
    const val = value.toFixed(2);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{currency in currencies ? `${currencies[currency]}${val}` : `${val} ${currency}`}</>;
};

export default Cost;
