// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement } from 'react';
import styles from './SocialLink.module.css';

const FaceBookIcon = () => (
    <svg viewBox="1 1 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.54,1h-19A3.51,3.51,0,0,0,1,4.51v19A3.51,3.51,0,0,0,4.51,27h9.38l0-9.31H11.49a.57.57,0,0,1-.57-.57v-3a.57.57,0,0,1,.57-.57h2.41V10.7c0-3.36,2.05-5.2,5.06-5.2h2.46a.57.57,0,0,1,.57.57V8.6a.57.57,0,0,1-.57.57H19.9c-1.63,0-1.95.78-1.95,1.91V13.6h3.59a.57.57,0,0,1,.57.64l-.36,3a.57.57,0,0,1-.57.5H18L18,27h5.58A3.51,3.51,0,0,0,27,23.54v-19A3.51,3.51,0,0,0,23.54,1Zm0,0" />
    </svg>
);

const GithubIcon = () => (
    <svg viewBox="1 1 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.54,1h-19A3.51,3.51,0,0,0,1,4.51v19A3.51,3.51,0,0,0,4.51,27h19A3.51,3.51,0,0,0,27,23.54v-19A3.51,3.51,0,0,0,23.54,1Zm-8,18.43H13.19c-1,0-5.13-.08-5.13-5a3.72,3.72,0,0,1,1-2.66,6.09,6.09,0,0,1,.44-3,7.74,7.74,0,0,1,3.13,1.43A7.44,7.44,0,0,1,14.38,10a7.39,7.39,0,0,1,1.73.16,7.74,7.74,0,0,1,3.13-1.43,6.11,6.11,0,0,1,.44,3,3.72,3.72,0,0,1,1,2.66C20.7,19.35,16.6,19.43,15.57,19.43Z" />
        <path d="M18.29,14.37C17.44,13.59,16,14,14.4,14h0c-1.62,0-3-.41-3.89.37A2.13,2.13,0,0,0,9.75,16c0,2.52,2,2.83,4.61,2.83h0C17,18.82,19,18.51,19,16A2.13,2.13,0,0,0,18.29,14.37Zm-6.1,3.08c-.49,0-.89-.55-.89-1.24s.4-1.24.89-1.24.89.55.89,1.24S12.69,17.44,12.19,17.44Zm4.37,0c-.49,0-.89-.55-.89-1.24s.4-1.24.89-1.24.89.55.89,1.24S17.05,17.44,16.56,17.44Z" />
    </svg>
);

const LinkedInIcon = () => (
    <svg viewBox="1 1 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.54,1h-19A3.51,3.51,0,0,0,1,4.51v19A3.51,3.51,0,0,0,4.51,27h19A3.51,3.51,0,0,0,27,23.54v-19A3.51,3.51,0,0,0,23.54,1Zm-13,20H7.33V11.42H10.5ZM8.91,10.12h0a1.65,1.65,0,1,1,0-3.29,1.65,1.65,0,1,1,0,3.29ZM21.92,21H18.75v-5.1c0-1.28-.46-2.16-1.61-2.16a1.73,1.73,0,0,0-1.63,1.16,2.15,2.15,0,0,0-.1.77V21H12.25s0-8.64,0-9.53h3.17v1.35a3.15,3.15,0,0,1,2.86-1.58c2.09,0,3.65,1.36,3.65,4.29Z" />
    </svg>
);

const TwitterIcon = () => (
    <svg viewBox="1 1 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.54,1h-19A3.51,3.51,0,0,0,1,4.51v19A3.51,3.51,0,0,0,4.51,27h19A3.51,3.51,0,0,0,27,23.54v-19A3.51,3.51,0,0,0,23.54,1ZM20.76,11.24c0,.13,0,.27,0,.4A8.88,8.88,0,0,1,7.1,19.13a6.32,6.32,0,0,0,4.62-1.29A3.13,3.13,0,0,1,8.8,15.67a3.12,3.12,0,0,0,1.41-.05,3.12,3.12,0,0,1-2.5-3.06v0a3.11,3.11,0,0,0,1.41.39,3.13,3.13,0,0,1-1-4.17A8.86,8.86,0,0,0,14.59,12a3.12,3.12,0,0,1,5.32-2.85,6.27,6.27,0,0,0,2-.76,3.14,3.14,0,0,1-1.37,1.73,6.24,6.24,0,0,0,1.79-.49A6.34,6.34,0,0,1,20.76,11.24Z" />
    </svg>
);

const YouTubeIcon = () => (
    <svg viewBox="1 1 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.54,1h-19A3.51,3.51,0,0,0,1,4.51v19A3.51,3.51,0,0,0,4.51,27h19A3.51,3.51,0,0,0,27,23.54v-19A3.51,3.51,0,0,0,23.54,1ZM20,14.54,9.91,19.38A.41.41,0,0,1,9.32,19V9a.41.41,0,0,1,.59-.36l10.14,5.14A.41.41,0,0,1,20,14.54Z" />
    </svg>
);

type Icon = 'facebook' | 'github' | 'linkedin' | 'twitter' | 'youtube';

const icons: Record<Icon, ReactElement> = {
    facebook: <FaceBookIcon />,
    github: <GithubIcon />,
    linkedin: <LinkedInIcon />,
    twitter: <TwitterIcon />,
    youtube: <YouTubeIcon />,
};

type SocialLinkProps = {
    href: string;
    title: string;
    icon: Icon;
};

export const SocialLink = ({ href, title, icon, ...props }: SocialLinkProps) => (
    <a
        className={styles.root}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
        {...props}
    >
        {icons[icon]}
    </a>
);
