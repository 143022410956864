// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';
import { useAccountRemoval } from '@platform360/accounts/web/profile/AccountRemoval';
import MfaControl from '@platform360/accounts/web/profile/MfaControl';
import ConsentManager from '@platform360/accounts/web/profile/ConsentManager';
import ChangeableName from '@platform360/accounts/web/profile/ChangeableName';
import { Button, Section, SectionItem, Toolbar, ToolbarExpander } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Timezone } from '@platform360/accounts/web/profile/Timezone';
import { ChangeableEmail } from '@platform360/accounts/web/profile/ChangeableEmail';
import { DpaManager } from '@platform360/accounts/web/profile/DpaManager';

export type ViewProfileProps = {
    isShowDpa: boolean;
};

const ViewProfile = ({ isShowDpa }: ViewProfileProps) => {
    const translate = useTranslate('accounts.profile.ViewProfile');
    const getModalUrl = useGetModalUrl();
    const changePasswordUrl = getModalUrl('change-password');
    const { showAccountRemoval, accountRemoval } = useAccountRemoval();

    return (
        <>
            <Toolbar>
                <Button component={Link} to={changePasswordUrl} data-type="change-password-link">
                    {translate('changePasswordLink')}
                </Button>
                <ToolbarExpander />
                <Button icon="recycle" onClick={showAccountRemoval} data-type="remove-account-link">
                    {translate('removeAccountLink')}
                </Button>
            </Toolbar>
            <Section>
                <SectionItem title={translate('emailAddressField')}>
                    <ChangeableEmail />
                </SectionItem>
                <SectionItem title={translate('nameField')}>
                    <ChangeableName />
                </SectionItem>
                <SectionItem title={translate('timezoneField')}>
                    <Timezone />
                </SectionItem>
            </Section>
            <Section>
                <SectionItem title={translate('mfaField')} data-type="mfa-address-field">
                    <MfaControl />
                </SectionItem>
            </Section>
            <Section>
                {isShowDpa ? <DpaManager /> : undefined}
                <ConsentManager />
            </Section>
            {accountRemoval}
        </>
    );
};

export default ViewProfile;
