// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'This email is already registered.',
        linkEmailMinimalInterval: 'Linked email action messages cannot be send too often.',
        linkEmailUnknownError: 'An error has occurred. Check your internet connection or try again later.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Confirm activation cancel',
                description: 'You are about to cancel activating {product} License. You can proceed with activating the license any time later by following the activation link again. If the license is purchased for another account then login to the account and click the activation link.',
                cancelButton: 'Cancel',
                continueButton: 'Continue activation',
            },
            MonitoringActivateLicense: {
                title: 'Confirm activating monitoring license',
                description: 'Please confirm activating the <b>{product}</b> License for your 360 account <mailto>{userEmail}</mailto>. The license may be activated only for one 360 account.',
                fixableErrorDescription: 'Something went wrong during the monitoring account activation. Please try again or submit a support request.',
                nonFixableErrorDescription: 'Something went wrong during the license activation. Please contact the license provider or submit a support request.',
                loaderText: 'Your 360 Monitoring account is being configured',
                redirectText: 'Redirecting you to 360 Monitoring',
                confirmButton: 'Confirm',
                cancelButton: 'Cancel',
                retryButton: 'Retry',
                gotItButton: 'Got it',
                errors: {
                    linkError: 'The activation link is invalid.',
                    unknownError: 'Internal activation service error.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'License activation error',
            description: 'The activation link is incorrect. Please contact the license provider or submit a support request.',
            gotIt: 'Got it',
        },
    },
};
