// Copyright 2024. WebPros International GmbH. All rights reserved.

import { signUp } from '@platform360/security-dashboard/web/api/auth';
import { useMutation } from '@tanstack/react-query';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import {
    getValidationErrors,
    toGeneralError,
} from '@platform360/libs/shared-web/helpers/apiErrors';

const useSignUp = (onSuccess: () => void) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { errorToast } = useToaster();

    const { error, ...rest } = useMutation({
        mutationFn: signUp,
        onSuccess,
        onError: (error) => {
            const formattedError = toGeneralError(error);

            if (formattedError) {
                errorToast(formattedError);
            }
        },
    });

    return { ...rest, errors: translateValidationErrors(getValidationErrors(error)) };
};

export default useSignUp;
