// Copyright 2024. WebPros International GmbH. All rights reserved.

import { captureException } from '@sentry/browser';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type CopyTextOptions = {
    successMessage?: TranslationProps;
};

export const useClipboard = () => {
    const translate = useTranslate();
    const { successToast, errorToast } = useToaster();
    const copyText = async (text: string, { successMessage }: CopyTextOptions = {}) => {
        try {
            await navigator.clipboard.writeText(text);
            if (successMessage) {
                successToast(successMessage);
            }
        } catch (err) {
            captureException(err);
            errorToast(translate('errorCopyToClipboard'), { autoClosable: true });
        }
    };

    return {
        copyText,
    };
};
