// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text, ContentLoader, Toolbar, Button, ListActions, ListAction } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import List from '@platform360/libs/shared-web/components/List';
import { Member } from '@platform360/accounts/web/api/team';
import Onboarding from '@platform360/accounts/web/components/Team/Members/Onboarding';
import { Link } from 'react-router-dom';
import SpecialMarks from './SpecialMarks';
import { MultifactorStatus } from '@platform360/accounts/web/components/Team/Members/MultiFactorStatus';
import { useMemberRemoval } from './useMemberRemoval';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useTeamMembersQuery } from '@platform360/accounts/web/queries';

const hasTeammates = (team?: Member[]) => team && team.length > 1;

const Members = () => {
    const translate = useTranslate('Team.Members');
    const currentUser = useCurrentUser();
    const { data: team, isLoading } = useTeamMembersQuery();
    const { removeMember, memberRemoval } = useMemberRemoval();

    if (isLoading) {
        return <ContentLoader />;
    }

    return (
        <>
            {memberRemoval}
            <List<Member>
                columns={[
                    {
                        key: 'email',
                        title: translate('email'),
                        type: 'title',
                        render: ({ email }) => <Text bold>{email}</Text>,
                    },
                    {
                        key: 'fullName',
                        title: translate('fullName'),
                        render: ({ fullName }) => <Text>{fullName}</Text>,
                    },
                    {
                        key: 'isMfaEnabled',
                        title: translate('mfaStatus'),
                        render: ({ isMfaEnabled }) => <MultifactorStatus enabled={isMfaEnabled} />,
                    },
                    {
                        key: 'tags',
                        title: translate('tags'),
                        render: ({ tags }) => <SpecialMarks tags={tags} />,
                    },
                    {
                        key: 'action',
                        type: 'actions',
                        render: ({ id, fullName, email }) =>
                            currentUser.email === email ? undefined : (
                                <ListActions>
                                    <ListAction
                                        icon="recycle"
                                        primary
                                        onClick={() =>
                                            removeMember({
                                                id,
                                                fullName,
                                                email,
                                            })
                                        }
                                        data-type="remove-member-button"
                                    >
                                        {translate('remove')}
                                    </ListAction>
                                </ListActions>
                            ),
                    },
                ]}
                toolbar={
                    <Toolbar>
                        <Button
                            component={Link}
                            to={'/organization/invitations/add'}
                            data-type="add-invitations-button"
                            intent="primary"
                            icon="plus"
                        />
                    </Toolbar>
                }
                data={hasTeammates(team) ? team : []}
                data-type="team-members-list"
                emptyView={<Onboarding />}
            />
        </>
    );
};

export default Members;
