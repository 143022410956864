// Copyright 2024. WebPros International GmbH. All rights reserved.

import useUpdateServer from '@platform360/server-inventory/web/mutations/useUpdateServer';
import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';

export type IgnoreCertificateErrorsButtonProps = {
    serverId: number;
};
const IgnoreCertificateErrorsButton = ({
    serverId,
    ...props
}: IgnoreCertificateErrorsButtonProps) => {
    const translate = useTranslate('ViewServer');
    const { mutate, isPending: isLoading } = useUpdateServer(serverId);
    return (
        <ConfirmationPopover
            onClick={() => mutate({ allowInvalidCertificate: true })}
            actionButtonText={translate('confirmAndProceed')}
            target={
                <Button
                    {...props}
                    state={isLoading ? 'loading' : undefined}
                    data-type="ignore-certificate-errors-button"
                >
                    {translate('ignoreCertificateErrorsButton')}
                </Button>
            }
        >
            <Paragraph>{translate('ignoreCertificateConfirmation')}</Paragraph>
        </ConfirmationPopover>
    );
};

export default IgnoreCertificateErrorsButton;
