// Copyright 2024. WebPros International GmbH. All rights reserved.

import { InstallationErr } from '@platform360/web-installer/shared/api-contract';
import { Alert, Heading } from '@plesk/ui-library';
import { useTranslateInstallationError } from '@platform360/web-installer/web/hooks/useTranslateInstallationError';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

export type InstallationErrorMessageProps = {
    host: string;
    error: InstallationErr | null;
    productType?: WebInstallerProduct;
};

export const InstallationErrorMessage = ({
    host,
    error,
    productType,
    ...props
}: InstallationErrorMessageProps) => {
    const { title, description } = useTranslateInstallationError({ host, error, productType });

    if (!title && !description) {
        return null;
    }

    return (
        <Alert {...props} intent="danger" data-type="installation-error-message">
            {title ? (
                <Heading level={4} data-type="installation-error-title">
                    {title}
                </Heading>
            ) : null}
            {description}
        </Alert>
    );
};
