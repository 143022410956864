// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { FormField, Select } from '@plesk/ui-library';

export type FormFieldSelectProps = {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fill';
    children: ReactNode;
    searchable?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    placeholder?: ReactNode;
};

/**
 * This component has workaround for bug UILIB-1176.
 *
 * @todo (vvolodko) remove workaround -- UILIB-1176 is closed.
 */
const FormFieldSelect = ({
    size,
    searchable,
    clearable,
    multiple,
    placeholder,
    children,
    ...props
}: FormFieldSelectProps) => (
    <FormField {...props}>
        {({ getId, getName, setValue, getValue, isDisabled }) => (
            <Select
                id={getId()}
                name={getName()}
                value={getValue(multiple ? [] : '')}
                onChange={setValue}
                disabled={isDisabled()}
                size={size}
                searchable={searchable}
                clearable={clearable}
                multiple={multiple}
                // @ts-expect-error fix type in UI Library
                placeholder={placeholder}
            >
                {children}
            </Select>
        )}
    </FormField>
);

export default FormFieldSelect;
