// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

export class WebInstallerAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    private logWIEvent(evtName: string, params?: Record<string, string>) {
        this.client.logEvent(evtName, { domain: window.location.hostname, ...params });
    }

    public emailConfirmationPageShown() {
        this.logWIEvent('WI:CONFIRMATION_PAGE_SHOWN');
    }

    public dpaIsSubmited() {
        this.logWIEvent('WI:DPA_SUBMITTED');
    }

    public dpaSubmitSuccess() {
        this.logWIEvent('WI:DPA_SUBMITTED_SUCCESS');
    }

    public consentsAreSubmited() {
        this.logWIEvent('WI:CONSENTS_SUBMITTED');
    }

    public consentsSubmitSuccess() {
        this.logWIEvent('WI:CONSENTS_SUBMITTED_SUCCESS');
    }

    public consentsSubmitError() {
        this.logWIEvent('WI:CONSENTS_SUBMITTED_ERROR');
    }

    public dpaSubmitError() {
        this.logWIEvent('WI:DPA_SUBMITTED_ERROR');
    }

    public userInfoPageIsShown() {
        this.logWIEvent('WI:USER_INFO_PAGE_SHOWN');
    }

    public userInfoIsSubmitted() {
        this.logWIEvent('WI:USER_INFO_SUBMITTED');
    }

    public userInfoSubmitSuccess() {
        this.logWIEvent('WI:USER_INFO_SUBMITTED_SUCCESS');
    }

    public userInfoSubmitError() {
        this.logWIEvent('WI:USER_INFO_SUBMITTED_ERROR');
    }

    public installationSubmit() {
        this.logWIEvent('WI:INSTALLATION_REQUEST_SUBMITTED');
    }

    public verificationSubmit() {
        this.logWIEvent('WI:VERIFICATION_REQUEST_SUBMITTED');
    }

    public verificationSubmitSuccess() {
        this.logWIEvent('WI:VERIFICATION_REQUEST_SUBMITTED_SUCCESS');
    }

    public verificationSubmitError() {
        this.logWIEvent('WI:VERIFICATION_REQUEST_SUBMITTED_ERROR');
    }

    public emailSubmit() {
        this.logWIEvent('WI:EMAIL_SUBMITTED');
    }

    public emailSubmitSuccess() {
        this.logWIEvent('WI:EMAIL_SUBMITTED_SUCCESS');
    }

    public emailSubmitError() {
        this.logWIEvent('WI:EMAIL_SUBMITTED_ERROR');
    }

    public gotoManualInstallationInstructions(controlPanel: WebInstallerProduct) {
        this.logWIEvent('WI User navigated to manual installation instructions', { controlPanel });
    }

    public gotoSoftwareRequirements(controlPanel: WebInstallerProduct) {
        this.logWIEvent('WI User navigated to software/system requirements', { controlPanel });
    }

    public gotoVersionsAndComponents(controlPanel: WebInstallerProduct) {
        this.logWIEvent('WI User navigated to versions and components', { controlPanel });
    }

    public gotoKnownIssues(controlPanel: WebInstallerProduct) {
        this.logWIEvent('WI User navigated to known issues', { controlPanel });
    }
}
