// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link, Paragraph, Section, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import RecheckConnectionButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/RecheckConnectionButton';
import StatusMessageButtons from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/ServerStatusMessage/StatusMessageButtons';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

export type ConnectionBlockedStatusMessageProps = {
    serverId: number;
    isRecheckConnectionEnabled: boolean;
};

const ConnectionBlockedStatusMessage = ({
    serverId,
    isRecheckConnectionEnabled = true,
}: ConnectionBlockedStatusMessageProps) => {
    const translate = useTranslate('ViewServer.AccessProblemStatusMessage');
    const { publicIps } = useConfig();
    return (
        <StatusMessage intent="danger" data-type="access-problem-status-message">
            <Section>
                {translate('causesTitle')}
                <ul>
                    <li>
                        {translate('caseRestrictionManagement', {
                            a: (chink) => (
                                <Link
                                    href="https://docs.plesk.com/en-US/obsidian/administrator-guide/plesk-administration/securing-plesk/restricting-administrative-access.59465/"
                                    target="_blank"
                                >
                                    {chink}
                                </Link>
                            ),
                        })}
                    </li>
                    <li>
                        {translate('casePanelIniApiSection', {
                            a: (chink) => (
                                <Link
                                    href="https://docs.plesk.com/en-US/obsidian/administrator-guide/plesk-administration/securing-plesk/restricting-remote-access-via-plesk-api.71930/"
                                    target="_blank"
                                >
                                    {chink}
                                </Link>
                            ),
                        })}
                    </li>
                </ul>
            </Section>
            <Section>
                {translate('listPublicIps', {
                    publicIps: publicIps.join(', '),
                })}
            </Section>
            <Paragraph>{translate('fixDescription')}</Paragraph>
            {isRecheckConnectionEnabled && (
                <StatusMessageButtons>
                    <RecheckConnectionButton
                        serverId={serverId}
                        title={translate('recheckConnectionButton')}
                    />
                </StatusMessageButtons>
            )}
        </StatusMessage>
    );
};

export default ConnectionBlockedStatusMessage;
