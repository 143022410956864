// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TOKEN_RENEWAL_FAILURE_CODE } from '@platform360/libs/shared-web/helpers/apiClient';
import axios from 'axios';

export const isClientError = (status: number) => status >= 400 && status < 500;

export const isTokenRenewalError = (e: unknown) => {
    if (!axios.isAxiosError(e)) {
        return false;
    }

    return e.response?.status === 401 || e.code === TOKEN_RENEWAL_FAILURE_CODE;
};
