// Copyright 2024. WebPros International GmbH. All rights reserved.

import { LocaleCode, Locales } from '@platform360/libs/shared-web/locale/types';

export const applyLocale = (localeCode: LocaleCode, locales: Locales): void => {
    const locale = locales[localeCode];
    if (!locale) {
        return;
    }

    document.documentElement.lang = localeCode;
    document.documentElement.dir = locale.direction;
    const link = document.querySelector<HTMLLinkElement>(
        'link[href*="plesk-ui-library.css"], link[href*="plesk-ui-library-rtl.css"]',
    );
    if (link) {
        const { href } = link;
        const nextHref = link.href.replace(
            /plesk-ui-library(-rtl|)\.css/,
            `plesk-ui-library${locale.direction === 'rtl' ? '-rtl' : ''}.css`,
        );
        if (href !== nextHref) {
            link.href = nextHref;
        }
    }
};
