// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ButtonProps } from '@plesk/ui-library';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';
import { Products } from '@platform360/server-inventory/shared/product-type';
import { Server } from '@platform360/server-inventory/web/types';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { UpdateServerTriggers } from '@platform360/server-inventory/web/helpers/analytics/ServerInventoryAnalyticsEvents';

export type ServerUpdatesButtonProps = ButtonProps<'a'> & {
    server: Server;
    trigger:
        | typeof UpdateServerTriggers.serverInfoUpdateServerButton
        | typeof UpdateServerTriggers.serverInfoOutdatedWarningUpdateServerButton;
};

export const ServerUpdatesButton = ({
    server,
    trigger,
    children,
    ...props
}: ServerUpdatesButtonProps) => {
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { product } = server;

    if (product !== Products.plesk) {
        return null;
    }

    return (
        <Button
            component="a"
            icon="arrow-up-circle"
            target="_blank"
            rel="noopener noreferrer"
            href={`/si/servers/${server.id}/login/${LoginTargets.updates}`}
            data-type="server-updates-link"
            onClick={() => {
                analyticsEvents.serverItemUpdateServerClicked({
                    product,
                    address: server.address.toString(),
                    trigger,
                });
            }}
            {...props}
        >
            {children}
        </Button>
    );
};
