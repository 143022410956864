// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DailyTaskTimeSection, {
    DailyTaskTimeSectionProps,
} from '@platform360/security-dashboard/web/components/DailyTaskTimeSection';
import styles from './DailyTaskTime.module.css';

type DailyTaskTimeProps = DailyTaskTimeSectionProps;

const DailyTaskTime = (props: DailyTaskTimeProps) => {
    const translate = useTranslate('security-dashboard.SettingsDrawer.DailyTaskTime');

    return (
        <>
            <div className={styles.description}>{translate('description')}</div>
            <DailyTaskTimeSection {...props} />
        </>
    );
};

export default DailyTaskTime;
