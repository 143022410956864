// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { Button, Paragraph } from '@plesk/ui-library';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import image from './linked-emails.svg';
import { emailsUri } from '@platform360/licenses/web/helpers';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

const Onboarding = () => {
    const translate = useTranslate('Keys.LinkedEmails.Onboarding');
    const analyticsEvents = useLicensesAnalyticsEvents();
    const {
        licencesModule: { hideDocumentationLinks = false },
    } = useApplicationSettings();
    const { docs } = useConfig();

    return (
        <ListEmptyView
            title={translate('title')}
            image={image}
            description={
                <Paragraph>
                    {translate('description')}{' '}
                    {!hideDocumentationLinks && (
                        <a
                            href={concatUrl('/scp/licenses/linked-emails.html', docs.baseUrl)}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => analyticsEvents.documentationLinkClicked()}
                        >
                            {translate('docsLink')}
                        </a>
                    )}
                </Paragraph>
            }
            actions={[
                <Button
                    key="1"
                    intent="primary"
                    component={Link}
                    to={emailsUri.concat('/add')}
                    data-type="add-emails-button"
                >
                    {translate('addEmailsButton')}
                </Button>,
            ]}
            data-type="emails-onboarding"
        />
    );
};

export default Onboarding;
