// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ContentLoader, Dialog } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { lazy, Suspense } from 'react';
import { legalDocumentsLoader } from '@platform360/app/web/helpers/legalDocumentsLoader';

const AsyncDataProcessingAgreement = lazy(async () => ({
    default: (await legalDocumentsLoader()).DataProcessingAgreement,
}));

export const DPA_DIALOG_DATA_TYPE = 'edit-dpa-dialog';

export type DpaDialogProps = {
    isOpen: boolean;
    isSaving: boolean;
    isTextScrolled: boolean;
    value: boolean;
    onClose: () => void;
    onSubmit: () => void;
    size: 'xs' | 'sm' | 'md' | 'lg';
};

export const DpaDialog = ({
    isOpen,
    isSaving,
    isTextScrolled,
    value,
    onClose,
    onSubmit,
    size,
    ...props
}: DpaDialogProps) => {
    const translate = useTranslate('accounts.profile.DpaDialog');
    const saveButtonProps = value
        ? ({ intent: 'danger', children: translate('buttonDecline') } as const)
        : ({ children: translate('buttonAccept') } as const);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title={translate('title')}
            subtitle={translate('subtitle')}
            form={{
                state: isSaving ? 'submit' : undefined,
                onSubmit,
                submitButton: {
                    disabled: value ? false : !isTextScrolled,
                    ...saveButtonProps,
                    'data-type': 'save-button',
                },
            }}
            size={size}
            data-type={DPA_DIALOG_DATA_TYPE}
            {...props}
        >
            <Suspense fallback={<ContentLoader />}>
                <AsyncDataProcessingAgreement />
            </Suspense>
        </Dialog>
    );
};
