// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import { Drawer } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useCreateLabelMutation } from '@platform360/security-dashboard/web/mutations';
import LabelForm, { FormValues } from '../LabelForm';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

export type LabelsDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CreateLabelDrawer = ({ isOpen, onClose }: LabelsDrawerProps) => {
    const translate = useTranslate('security-dashboard.LabelsDrawer.CreateLabelDrawer');
    const translateValidationErrors = useTranslateValidationErrors();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpCreateLabelDrawerShown();
        } else {
            analyticsEvents.wpCreateLabelDrawerClosed();
        }
    }, [analyticsEvents, isOpen]);

    const { mutate: createLabel, isPending: isCreating, error } = useCreateLabelMutation();

    const handleSubmit = (values: FormValues) => {
        createLabel(values, {
            onSuccess: () => {
                onClose();
            },
        });
        analyticsEvents.wpCreateLabelDrawerSubmit();
    };

    return (
        <Drawer
            data-type="wp-create-label-drawer"
            title={translate('title')}
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
            form={
                <LabelForm
                    onSubmit={handleSubmit}
                    state={isCreating ? 'loading' : undefined}
                    errors={translateValidationErrors(getValidationErrors(error))}
                />
            }
        />
    );
};

export default CreateLabelDrawer;
