// Copyright 2024. WebPros International GmbH. All rights reserved.

import Page from '@platform360/libs/shared-web/components/Page';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    const translate = useTranslate('Team.Layout');

    return (
        <Page
            title={translate('title')}
            tabs={[
                {
                    to: '/organization/members',
                    title: translate('members'),
                },
                {
                    to: '/organization/invitations',
                    title: translate('invitations'),
                },
            ]}
        >
            <Outlet />
        </Page>
    );
};

export default Layout;
