// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ListEmptyView } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type EmptyViewProps = {
    notFound: boolean;
};

export const EmptyView = ({ notFound }: EmptyViewProps) => {
    const translate = useTranslate('admin-panel.security-dashboard.Requests.EmptyView');

    if (notFound) {
        return (
            <ListEmptyView reason="filtered" title={translate('notFoundTitle')} description="" />
        );
    }

    return <ListEmptyView title={translate('initialTitle')} description="" />;
};
