// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { sendRequest } from '@platform360/admin-panel/web/api/security-dashboard/agent-request';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';

const useRequestWpAgentMutation = () => {
    const translateValidationErrors = useTranslateValidationErrors();

    const { error, ...rest } = useMutation({
        mutationFn: sendRequest,
    });

    const errors = translateValidationErrors(getValidationErrors(error));

    return {
        ...rest,
        errors,
    };
};

export default useRequestWpAgentMutation;
