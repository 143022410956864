// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SELECT_ITEM } from '@platform360/licenses/web/units/extensions/constants/actions';

export type SelectItemAction = {
    type: typeof SELECT_ITEM;
    payload: string;
};

const selectItem = (itemId: string): SelectItemAction => ({
    type: SELECT_ITEM,
    payload: itemId,
});

export default selectItem;
