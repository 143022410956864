// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    VulnerabilitiesComponentFilter,
    VulnerabilitiesStateFilter,
    VulnerabilitiesSummary,
    Vulnerability,
    Task,
    Label,
    CountOfAffectedSitesResponse,
    VulnerabilityProvider,
} from '@platform360/security-dashboard/web/types';
import { VulnerabilityInstallationOperationType } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import {
    ApiListResponse,
    ApiResponse,
    FetcherOptions,
    FilterQuery,
    IdsFilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';
import { DateTime } from 'luxon';
import { VulnerabilityRiskRank } from '@platform360/security-dashboard/shared/vulnerability-risk-rank';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

export const MITIGATION_TYPE_DEACTIVATE_ASSET = 'deactivateAsset';
export const MITIGATION_TYPE_UPDATE = 'update';

export type MitigationType =
    | typeof MITIGATION_TYPE_DEACTIVATE_ASSET
    | typeof MITIGATION_TYPE_UPDATE;

export const MITIGATION_OPERATION_UPDATE = 'fixVulnerabilitiesViaUpdate';
export const MITIGATION_OPERATION_DEACTIVATE_ASSET = 'mitigationDeactivateAsset';
export const MITIGATION_OPERATION_ACTIVATE_ASSET = 'mitigationActivateAsset';

export type MitigateVulnerabilitiesOperation =
    | typeof MITIGATION_OPERATION_UPDATE
    | typeof MITIGATION_OPERATION_DEACTIVATE_ASSET
    | typeof MITIGATION_OPERATION_ACTIVATE_ASSET;

export type MitigationParams = {
    type: MitigationType;
    applied: boolean;
};

export const MITIGATION_POLICY_ACTIVATE_AFTER_UPDATE = 'activateAfterUpdate';

type MitigationPolicy = typeof MITIGATION_POLICY_ACTIVATE_AFTER_UPDATE;

export type VulnerabilityMitigationsParams = {
    vulnerabilityId: string;
    mitigations: MitigationParams[];
    policy?: MitigationPolicy;
};

export type MitigateVulnerabilitiesParams = {
    operation: MitigateVulnerabilitiesOperation;
    vulnerabilityMitigations: VulnerabilityMitigationsParams[];
};

type VulnerabilityStateQuery = {
    vulnerabilityStateFilter?: VulnerabilitiesStateFilter[];
};

type VulnerabilityComponentQuery = {
    vulnerabilityComponentFilter?: VulnerabilitiesComponentFilter[];
};

export type VulnerabilitiesResponse = {
    id: number;
    title: string;
    description: string;
    installationsCount: number;
    installationsWithEnabledPluginCount: number;
    installationsWithPluginDisabledByMitigationCount: number;
    serversCount: number;
    vulnerabilityId: string;
    url: string;
    component: InstallationComponent;
    cvssScore: string | null;
    exploited: boolean | null;
    disclosedAt: string | null;
    cve: string[];
    tasks: Task[];
    labels: Label[];
    providers: VulnerabilityProvider[];
    installationsWithMitigationUpdateAvailable: number;
    mitigated: boolean;
    mitigatedByProtectionCount: number;
    mitigatedByDeactivationCount: number;
    unknownMitigationsCount: number;
    installationsWithMitigationPatchAvailable: number;
    protectedInstallationsCount: number;
    riskRank: VulnerabilityRiskRank;
};

export type VulnerabilitiesSummaryResponse = {
    lastUpdateAt: number | null;
    exploited: number;
    needsProtection: number;
    highestScore: number;
};

type GetVulnerabilitiesOptions = FetcherOptions<
    PaginatedQuery &
        FilterQuery &
        IdsFilterQuery &
        VulnerabilityStateQuery &
        VulnerabilityComponentQuery
>;

export type GetVulnerabilitiesResponse = ApiListResponse<VulnerabilitiesResponse>;

export const normalizeVulnerability = ({
    disclosedAt,
    ...vulnerability
}: VulnerabilitiesResponse): Vulnerability => ({
    disclosedAt: disclosedAt ? DateTime.fromISO(disclosedAt) : null,
    ...vulnerability,
});

export const getVulnerabilities = async ({
    variables,
    signal,
}: GetVulnerabilitiesOptions): Promise<ApiListResponse<Vulnerability>> => {
    const { data } = await apiClient.get<GetVulnerabilitiesResponse>(
        '/security-dashboard/vulnerabilities',
        {
            signal,
            params: {
                ...variables,
                vulnerabilityStateFilter: variables.vulnerabilityStateFilter?.join(','),
                vulnerabilityComponentFilter: variables.vulnerabilityComponentFilter?.join(','),
                idsFilter: variables.idsFilter?.join(','),
            },
        },
    );

    return {
        ...data,
        data: data.data.map(normalizeVulnerability),
    };
};

const normalizeVulnerabilitiesSummary = ({
    lastUpdateAt,
    ...summary
}: VulnerabilitiesSummaryResponse): VulnerabilitiesSummary => ({
    lastUpdateAt: lastUpdateAt ? DateTime.fromSeconds(lastUpdateAt) : null,
    ...summary,
});

export const getVulnerabilitiesSummary = async (): Promise<VulnerabilitiesSummary> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<VulnerabilitiesSummaryResponse>>(
        '/security-dashboard/vulnerabilities/summary',
    );

    return normalizeVulnerabilitiesSummary(data);
};

export const mitigateVulnerabilities = async (
    data: MitigateVulnerabilitiesParams,
): Promise<void> => {
    await apiClient.post('/security-dashboard/vulnerabilities/mitigator', data);
};

type GetCountOfAffectedSitesOptions = FetcherOptions<{
    vulnerabilitiesIds: string[];
    operation: VulnerabilityInstallationOperationType;
}>;

export const getCountOfAffectedSitesByIds = async ({
    variables,
}: GetCountOfAffectedSitesOptions): Promise<CountOfAffectedSitesResponse> => {
    const { data } = await apiClient.get<ApiResponse<CountOfAffectedSitesResponse>>(
        '/security-dashboard/vulnerabilities/affected-sites-counter',
        {
            params: {
                ids: variables.vulnerabilitiesIds.join(','),
                operation: variables.operation,
            },
        },
    );

    return data.data;
};

type IgnoreVulnerabilitiesOptions = {
    vulnerabilityIds: string[];
};

export const ignoreVulnerabilities = async (data: IgnoreVulnerabilitiesOptions): Promise<void> => {
    await apiClient.post('/security-dashboard/vulnerabilities/ignore', data);
};

type RemoveVulnerabilityLabelsOptions = {
    vulnerabilityIds: string[];
    labelIds: number[];
};

export const removeVulnerabilityLabels = async (
    data: RemoveVulnerabilityLabelsOptions,
): Promise<void> => {
    await apiClient.post('/security-dashboard/vulnerabilities/remove-labels', data);
};

type PatchVulnerabilitiesOptions = {
    vulnerabilityIds: string[];
    ignoreDoNotProtect?: boolean;
};

export const patchVulnerabilities = async (data: PatchVulnerabilitiesOptions): Promise<void> => {
    await apiClient.post('/security-dashboard/vulnerabilities/patch', data);
};
