// Copyright 2024. WebPros International GmbH. All rights reserved.

import { updateServer } from '@platform360/server-inventory/web/api/servers';
import { PatchServerRequest } from '@platform360/server-inventory/shared/api-contract';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';

const useUpdateServer = (serverId: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (payload: PatchServerRequest) => updateServer(serverId, payload),
        onSuccess: async (data) => {
            await queryClient.cancelQueries({
                queryKey: useServerQuery.getQueryKey({ id: data.id }),
            });
            useServerQuery.setQueryData(
                queryClient,
                useServerQuery.getQueryKey({ id: data.id }),
                data,
            );
        },
    });
};

export default useUpdateServer;
