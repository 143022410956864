// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FINISH_PROCESSING } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type FinishProcessingAction = {
    type: typeof FINISH_PROCESSING;
};

const finishProcessing = (): FinishProcessingAction => ({
    type: FINISH_PROCESSING,
});

export default finishProcessing;
