// Copyright 2024. WebPros International GmbH. All rights reserved.

import { LicenseWithSubscription } from '@platform360/licenses/web/api/ka/responseTypes/License';
import { DEA } from '@platform360/licenses/web/api/ka/const/cleverbridgeItemStatus';
import { active } from '@platform360/licenses/web/helpers/utils';

const isPropertyChangeAvailable = (license: LicenseWithSubscription): boolean =>
    DEA !== license.cbItemStatusId && active(license) && license.hasProperiesUpgrades;

export default isPropertyChangeAvailable;
