// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { TranslationProps } from '@plesk/ui-library';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';
import { ReportConsentsRequest } from '@platform360/accounts/shared/api-contract';

export type Consent = {
    type: string;
    enabled: boolean;
    text?: TranslationProps;
};

export type GetConsentsResponse = ApiResponse<Consent[]>;

export const getConsents = async (): Promise<Consent[]> => {
    const {
        data: { data },
    } = await apiClient.get<GetConsentsResponse>('/accounts/me/consents');

    return data;
};

export const postConsents = async (params: ReportConsentsRequest) => {
    await apiClient.post('/accounts/me/consents', params);

    return params.consents;
};
