// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';
import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { UpdateServerTriggers, updateServerTriggerValues } = makeEnum(
    'UpdateServerTrigger',
    'serverListItemUpdateServerButton',
    'serverInfoUpdateServerButton',
    'serverInfoOutdatedWarningUpdateServerButton',
);

export type UpdateServerTrigger = UnionOf<typeof updateServerTriggerValues>;

type ServerInfo = {
    address: string;
};

type UpdateServerEventParams = ServerInfo & {
    product: string;
    trigger: UpdateServerTrigger;
};

type ErrorMessage = string;

export class ServerInventoryAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    public pageOpen() {
        this.client.logEvent('SI Page open');
    }

    public fromPleskConfirmOpen() {
        this.client.logEvent('The user with Adding redirect link is shown Confirm dialogue');
    }

    public addingServerWizardFirstStepOpen() {
        this.client.logEvent('SI Adding server wizard first step open');
    }

    public addingServerWizardClose() {
        this.client.logEvent('SI Adding server wizard close');
    }

    public addingServerWizardSelectSecondStep(selectedStep: string) {
        this.client.logEvent('SI Adding server wizard select second step', { selectedStep });
    }

    public serverInstallationFormOpen(controlPanel: 'plesk' | 'cpanel') {
        this.client.logEvent('SI Server installation form open', { controlPanel });
    }

    public serverInstallationFormSubmit(params: {
        installationMethod: string;
        licenseType?: string;
        updateOs: boolean;
        controlPanel: 'plesk' | 'cpanel';
    }) {
        this.client.logEvent('SI Server installation form submit', params);
    }

    public addServerFormOpen(controlPanel: 'plesk' | 'cpanel') {
        this.client.logEvent('SI Add server form open', { controlPanel });
    }

    public addServerPressed() {
        this.client.logEvent('SI: go to server to add pressed');
    }

    public addExternalServerPressed() {
        this.client.logEvent('SI: continue to Plesk 360 to add pressed');
    }

    public addServerDeclined(server: ServerInfo) {
        this.client.logEvent('SI Add server Declined', { server });
    }

    public serverListSyncClicked() {
        this.client.logEvent('SI ServerList Sync Clicked');
    }

    public serverListDisconnectClicked() {
        this.client.logEvent('SI ServerList Disconnect Clicked');
    }

    public serverItemGoToServerClicked(controlPanel: string) {
        this.client.logEvent('SI ServerItem GoToServer Clicked', { controlPanel });
    }

    public serverItemUpdateServerClicked(info: UpdateServerEventParams) {
        this.client.logEvent('SI ServerItem UpdateServer Clicked', info);
    }

    public serverItemSyncClicked() {
        this.client.logEvent('SI ServerItem Sync Clicked');
    }

    public serverViewOpened(params: { campaign?: string; medium?: string } = {}) {
        this.client.logEvent('SI Server View Opened', params);
    }

    public serverViewGoToServerClicked(controlPanel: string) {
        this.client.logEvent('SI ServerView GoToServer Clicked', { controlPanel });
    }

    public serverViewSyncClicked() {
        this.client.logEvent('SI ServerView Sync Clicked');
    }

    public serverViewDisconnectClicked() {
        this.client.logEvent('SI ServerView Disconnect Clicked');
    }

    public serverViewRecheckConnectionClicked() {
        this.client.logEvent('SI ServerView RecheckConnection Clicked');
    }

    public serverViewNotesEditClicked() {
        this.client.logEvent('SI ServerView Notes Edit Clicked');
    }

    public serverViewNotesSaved() {
        this.client.logEvent('SI ServerView Notes Saved');
    }

    public serverWebsitesOpenClicked() {
        this.client.logEvent('SI ServerWebsites Open Clicked');
    }

    public serverWebsitesManageClicked() {
        this.client.logEvent('SI ServerWebsites Manage Clicked');
    }

    public serverClientsManageClicked() {
        this.client.logEvent('SI ServerClients Manage Clicked');
    }

    public websitesItemOpenClicked() {
        this.client.logEvent('Websites Item Open Clicked');
    }

    public websitesItemManageClicked() {
        this.client.logEvent('Websites Item Manage Clicked');
    }

    public clientsItemManageClicked() {
        this.client.logEvent('SI Clients Item Manage Clicked');
    }

    public userLoginToPleskSuccess(server: ServerInfo) {
        this.client.logEvent('User logged to Plesk panel successfully', { server });
    }

    public userLoginToPleskFail(server: ServerInfo, loginToPleskError: ErrorMessage) {
        this.client.logEvent('User failed to log to Plesk panel', { server, loginToPleskError });
    }

    public removeServerSuccess(server: ServerInfo, controlPanel: string) {
        this.client.logEvent('Server disconnected successfully', { server, controlPanel });
    }

    public removeServerFail(
        server: ServerInfo,
        removeServerError: ErrorMessage,
        controlPanel: string,
    ) {
        this.client.logEvent('Disconnecting Server Failed', {
            server,
            removeServerError,
            controlPanel,
        });
    }

    public documentationLinkClicked() {
        this.client.logEvent('Documentation Link Clicked');
    }

    /* Search */

    public searchDomains() {
        this.client.logEvent('Search Domains');
    }

    public searchClients() {
        this.client.logEvent('Search Clients');
    }

    /* Global Search */

    public globalSearchMade() {
        this.client.logEvent('Global Search made');
    }

    public globalSearchResultsFound(count: number) {
        this.client.logEvent('Global Search results found', { count });
    }

    public globalSearchResultsEmpty() {
        this.client.logEvent('Global Search results empty');
    }

    public globalSearchResultClicked() {
        this.client.logEvent('Global Search result clicked');
    }
}
