// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Navigate, Link, useParams } from 'react-router-dom';
import useInstallation from '@platform360/web-installer/web/queries/useInstallation';
import useProgressStep from './useProgressStep';
import { ContentLoader, ConsoleOutput, Progress, ProgressStep } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import InstallStep from './InstallStep';
import useInstallationLog from '@platform360/web-installer/web/queries/useInstallationLog';
import styles from './Installation.module.css';
import Public from '@platform360/libs/shared-web/components/Public';

type RouteParams = {
    id: string;
};

const Installation = () => {
    const { id } = useParams<RouteParams>();
    const installationId = String(id);
    const translate = useTranslate('web-installer.Installation');

    const { isLoading, data, error } = useInstallation({
        variables: {
            id: installationId,
        },
    });
    const { data: log } = useInstallationLog({
        variables: {
            id: installationId,
        },
        staleTime: data?.status === 'running' ? 0 : Infinity,
    });

    const progressStep = useProgressStep(data);

    if (error?.response?.status === 404) {
        return <Navigate to="/not-found" />;
    }

    if (isLoading || !data) {
        return <ContentLoader />;
    }

    return (
        <Public isWide>
            <InstallStep
                status={data.status === 'error' && data.error ? data.error.type : data.status}
            >
                <Progress>
                    <ProgressStep {...progressStep} />
                </Progress>
                {log && (
                    <ConsoleOutput className={styles.log} wide>
                        {log}
                    </ConsoleOutput>
                )}
                {data.status === 'running' ? null : (
                    <Link to="/web-installer">{translate('startAnotherInstallation')}</Link>
                )}
            </InstallStep>
        </Public>
    );
};

export default Installation;
