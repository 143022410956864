// Copyright 2024. WebPros International GmbH. All rights reserved.

import React, { ReactNode } from 'react';
import { ItemList, Item, FormField, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { LightsailBundle } from '@platform360/web-installer/shared/api-contract';

type FormFieldApi = {
    getValue: () => string;
    setValue: (value: string) => void;
};

export type BundleSelectProps = {
    name: string;
    label: ReactNode;
    required: boolean;
    bundles: LightsailBundle[];
};

const BundleSelect = ({ name, label, required, bundles }: BundleSelectProps) => {
    const translate = useTranslate('web-installer.LightsailInstallation.BundleSelect');

    return (
        <FormField name={name} label={label} required={required}>
            {({ getValue, setValue }: FormFieldApi) => (
                <ItemList
                    minColumnWidth={180}
                    gap="md"
                    selectable
                    stretchable
                    value={getValue()}
                    // @ts-expect-error
                    onSelect={setValue}
                >
                    {bundles.map((bundle) => (
                        <Item
                            key={bundle.BundleId}
                            value={bundle.BundleId}
                            view="card"
                            title={translate('price', { value: bundle.Price })}
                        >
                            <ul>
                                <li>{translate('cpu', { value: bundle.CpuCount })}</li>
                                <li>{translate('memory', { value: bundle.RamSizeInGb })}</li>
                                <li>{translate('storage', { value: bundle.DiskSizeInGb })}</li>
                                <li>
                                    {translate('transfer', { value: bundle.TransferPerMonthInGb })}
                                </li>
                            </ul>
                            <div>
                                <Text intent="muted">
                                    {translate(
                                        `descriptions.${bundle.InstanceType}`,
                                        undefined,
                                        '',
                                    )}
                                </Text>
                            </div>
                        </Item>
                    ))}
                </ItemList>
            )}
        </FormField>
    );
};

export default BundleSelect;
