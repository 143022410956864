// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCallback, useMemo } from 'react';
import { CarouselCardProps } from './CarouselCard';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

// preview images
import serverInventory from './preview-images/server-inventory.png';
import serverInventory2x from './preview-images/server-inventory@2x.png';
import licenses from './preview-images/licenses.png';
import licenses2x from './preview-images/licenses@2x.png';
import monitoring from './preview-images/monitoring.png';
import monitoring2x from './preview-images/monitoring@2x.png';
import websiteInventory from './preview-images/website-inventory.png';
import websiteInventory2x from './preview-images/website-inventory@2x.png';

import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useHomePageAnalyticsEvents } from '@platform360/home-page/web/helpers/analytics';

const useCarouselCards = (): CarouselCardProps[] => {
    const translate = useTranslate('home-page.Carousel');
    const { docs } = useConfig();
    const analyticsEvents = useHomePageAnalyticsEvents();

    const handleDocumentationLinkClick = useCallback(
        () => analyticsEvents.documentationLinkClicked(),
        [analyticsEvents],
    );

    return useMemo(
        () => [
            {
                title: translate('monitoring.title'),
                description: translate('monitoring.description'),
                background: 'linear-gradient(97.05deg, #1B16E9 4.57%, #6F00B3 98.57%)',
                previewImages: [monitoring, monitoring2x],
                primaryButton: {
                    to: '/monitoring',
                    children: translate('monitoring.primaryButton'),
                    isExternal: true,
                },
                secondaryButton: {
                    to: 'https://docs.360monitoring.com/docs',
                    onClick: handleDocumentationLinkClick,
                    children: translate('monitoring.secondaryButton'),
                    isExternal: true,
                    icon: { name: 'plans' },
                },
            },
            {
                title: translate('serverInventory.title'),
                description: translate('serverInventory.description'),
                background: '#384859',
                previewImages: [serverInventory, serverInventory2x],
                primaryButton: {
                    to: '/si',
                    children: translate('serverInventory.primaryButton'),
                },
                secondaryButton: {
                    to: concatUrl('/scp/servers/servers.html', docs.baseUrl),
                    onClick: handleDocumentationLinkClick,
                    isExternal: true,
                    children: translate('serverInventory.secondaryButton'),
                    icon: { name: 'plans' },
                },
            },
            {
                title: translate('websiteInventory.title'),
                description: translate('websiteInventory.description'),
                background: '#46556b',
                previewImages: [websiteInventory, websiteInventory2x],
                primaryButton: {
                    to: '/websites',
                    children: translate('websiteInventory.primaryButton'),
                    isExternal: true,
                },
                secondaryButton: {
                    to: concatUrl('/scp/websites/websites.html', docs.baseUrl),
                    onClick: handleDocumentationLinkClick,
                    isExternal: true,
                    children: translate('websiteInventory.secondaryButton'),
                    icon: { name: 'plans' },
                },
            },
            {
                title: translate('licenses.title'),
                description: translate('licenses.description'),
                background: '#536A7D',
                previewImages: [licenses, licenses2x],
                primaryButton: {
                    to: '/licensing',
                    children: translate('licenses.primaryButton'),
                },
                secondaryButton: {
                    to: concatUrl('/scp/licenses/licenses.html', docs.baseUrl),
                    onClick: handleDocumentationLinkClick,
                    isExternal: true,
                    children: translate('licenses.secondaryButton'),
                    icon: { name: 'plans' },
                },
            },
        ],
        [translate, docs, handleDocumentationLinkClick],
    );
};

export default useCarouselCards;
