// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import monitoring, { SetupPerServerResponse } from '@platform360/monitoring/web/api/monitoring';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';

type UseMonitoringServerSetupProps = {
    serverGuid: string;
    monitoringUserId?: string;
    monitoringServerId?: string;
    onSuccess: (response: SetupPerServerResponse) => void;
};

const useMonitoringServerSetup = ({
    serverGuid,
    monitoringUserId,
    monitoringServerId,
    onSuccess,
}: UseMonitoringServerSetupProps) =>
    // eslint-disable-next-line deprecation/deprecation
    useMutation<SetupPerServerResponse, UntypedAxiosError>({
        mutationFn: () =>
            monitoring.setupServer(serverGuid, { monitoringUserId, monitoringServerId }),
        onSuccess,
    });

export default useMonitoringServerSetup;
