// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import {
    UpdateBusinessProfileRequest,
    UpdateEmailRequest,
    UpdatePasswordRequest,
    UpdateProfileRequest,
    UpdateProfileResponse,
} from '@platform360/accounts/shared/api-contract';
import { BusinessProfileValidName } from '@platform360/libs/common/business-profile-valid-name';

const arrayToObject = (data: BusinessProfileItem[]) =>
    data.reduce<UpdateBusinessProfilePayload>((values, { name, value }) => {
        values[name] = value;
        return values;
    }, {});

type BusinessProfileItem = {
    name: BusinessProfileValidName;
    value: string | string[];
};

export type GetBusinessProfileResponse = {
    data: BusinessProfileItem[];
};

export const getBusinessProfile = async ({ signal }: FetcherOptions) => {
    const { data } = await apiClient.get<GetBusinessProfileResponse>(
        '/accounts/me/business-profile',
        { signal },
    );
    return arrayToObject(data.data);
};

export type UpdateBusinessProfilePayload = Partial<
    Record<BusinessProfileValidName, string | string[]>
>;

export type UpdateBusinessProfileResponse = {
    data: BusinessProfileItem[];
};

export const updateBusinessProfile = async (profile: UpdateBusinessProfilePayload) => {
    const requestBody: UpdateBusinessProfileRequest = {
        items: Object.entries(profile).map(([name, value]) => ({
            name: name as BusinessProfileValidName,
            value,
        })),
    };

    const { data } = await apiClient.put<UpdateBusinessProfileResponse>(
        '/accounts/me/business-profile',
        requestBody,
    );
    return arrayToObject(data.data);
};

export const updateProfile = async (profile: UpdateProfileRequest) => {
    const {
        data: { data },
    } = await apiClient.patch<ApiResponse<UpdateProfileResponse>>('/accounts/me', profile);

    return data;
};

export const deleteProfile = () => apiClient.delete('/accounts/me');

export const changePassword = async (payload: UpdatePasswordRequest) =>
    apiClient.post('/accounts/me/password', payload);

export type UpdateEmailResponse = {
    email: string;
};

export const updateEmail = async (payload: UpdateEmailRequest) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<UpdateEmailResponse>>(
        '/accounts/me/update-email',
        payload,
    );
    return data;
};

export const confirmEmail = async (): Promise<string> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<{ newEmail: string }>>('/accounts/me/confirm-email');

    return data.newEmail;
};

export const depersonate = () => apiClient.delete(`/accounts/me/impersonation`);
