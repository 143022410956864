// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'The 360 Tools',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Server inventory',
            description: 'All registered instances from a single location & one set of credentials',
        },
        licenseManagement: {
            title: 'License management',
            description: 'Buy and manage all your licenses for Plesk, its extensions and 360 Tools',
        },
        feedback: {
            title: 'Provide your feedback',
            description: 'This will help us with future development',
        },
        websiteInventory: {
            title: 'Website Inventory',
            description: 'View and manage all your websites',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Monitor your servers and websites from a single dashboard',
            description: 'Centralized solution to keep track of performance and prevent downtime, which offers configurable dashboards, personalized alerts, one-minute checks, network history and more',
            primaryButton: 'Try for free for 14 days',
            secondaryButton: 'Learn more',
        },
        licenses: {
            title: 'Manage your licenses',
            description: 'Buy, adjust, and terminate all your licenses for Plesk, its extensions, and 360 Tools from one place',
            primaryButton: 'Let’s go',
            secondaryButton: 'Learn more',
        },
        serverInventory: {
            title: 'All registered instances from a single location & one set of credentials',
            description: 'Connect all your Plesk servers to the Server inventory to get the full list of your websites and arrange a one-click access to them',
            primaryButton: 'Connect servers',
            secondaryButton: 'Learn more',
        },
        websiteInventory: {
            title: 'All your websites and domains in a single list',
            description: 'View, search, filter your websites and get to managing them in one click',
            primaryButton: 'View websites',
            secondaryButton: 'Learn more',
        },
    },
};
