// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server } from '@platform360/security-dashboard/web/types';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import { useRunScannerMutation } from '@platform360/security-dashboard/web/mutations';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';

type FindNewSitesButtonProps = {
    selectedServers: Server[];
    onTaskStarting: (serversIds: number[], operation: 'findNewSites') => void;
    onTaskStarted: (serversIds: number[], operation: 'findNewSites') => void;
};

export const FindNewSitesButton = ({
    selectedServers,
    onTaskStarted,
    onTaskStarting,
    ...props
}: FindNewSitesButtonProps) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar.FindNewSitesButton');
    const { mutateAsync: runServersScanner, isPending: isLoading } = useRunScannerMutation();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const serversIds = selectedServers.map((el) => el.id);
    const handleStartScan = async () => {
        analyticsEvents.wpMltFindSitesClick(selectedServers.length);
        onTaskStarting(serversIds, 'findNewSites');
        try {
            await runServersScanner(selectedServers);
        } finally {
            onTaskStarted(serversIds, 'findNewSites');
        }
    };

    return (
        <NoSelectionPopover
            selectionCount={selectedServers.length}
            target={
                <Button
                    state={isLoading ? 'loading' : undefined}
                    onClick={handleStartScan}
                    {...props}
                >
                    {translate('buttonText')}
                </Button>
            }
        >
            {translate('noSelectedServers')}
        </NoSelectionPopover>
    );
};

export default FindNewSitesButton;
