// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    SendAgentRequestRequest,
    SendAgentRequestResponse,
} from '@platform360/admin-panel/shared/api-contract';

export const sendRequest = async ({
    data: requestData,
}: {
    data: SendAgentRequestRequest;
}): Promise<SendAgentRequestResponse> => {
    const {
        data: { data },
    } = await apiClient.post(
        '/admin-panel/security-dashboard/agent-request/send-request',
        requestData,
    );
    return data;
};
