// Copyright 2024. WebPros International GmbH. All rights reserved.

import { InitialState } from '@platform360/licenses/web/units/extensions/reducer/buyExtension/initialState';
import { INITIALIZED } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type InitializedAction = {
    type: typeof INITIALIZED;
    payload: InitialState;
};

const initialized = (initializedState: InitialState): InitializedAction => ({
    type: INITIALIZED,
    payload: initializedState,
});

export default initialized;
