// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import image from './licenses.svg';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { licensesUri } from '@platform360/licenses/web/helpers';

type DefaultOnboardingProps = {
    onBuyClick?: () => void;
};

const DefaultOnboarding = ({ onBuyClick }: DefaultOnboardingProps) => {
    const analyticsEvents = useLicensesAnalyticsEvents();
    const translate = useTranslate('Keys.Licenses.Onboarding.DefaultOnboarding');
    const { docs } = useConfig();
    const {
        licencesModule: { simpleView = false, hideDocumentationLinks = false },
    } = useApplicationSettings();

    return (
        <ListEmptyView
            title={translate('title')}
            image={image}
            description={
                <>
                    {!simpleView && <Paragraph>{translate('description1')}</Paragraph>}
                    <Paragraph>
                        {translate('description2')}{' '}
                        {!hideDocumentationLinks && (
                            <a
                                href={concatUrl('/scp/licenses/licenses.html', docs.baseUrl)}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => analyticsEvents.documentationLinkClicked()}
                            >
                                {translate('docsLink')}
                            </a>
                        )}
                    </Paragraph>
                </>
            }
            actions={[
                simpleView ? null : (
                    <Button
                        key="1"
                        intent="primary"
                        component="a"
                        href="https://www.plesk.com/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onBuyClick}
                    >
                        {translate('buyButton')}
                    </Button>
                ),
                <Button
                    key="2"
                    intent="primary"
                    component={Link}
                    to={licensesUri.concat('/add-emails')}
                    data-type="add-emails-button"
                >
                    {translate('addEmailsButton')}
                </Button>,
            ]}
            data-type="default-onboarding"
        />
    );
};

export default DefaultOnboarding;
