// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'Este e-mail já está registado.',
        linkEmailMinimalInterval: 'As mensagens de ação de e-mail vinculadas não podem ser enviadas com muita frequência.',
        linkEmailUnknownError: 'Ocorreu um erro. Verifique sua conexão com a Internet ou tente novamente mais tarde.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Confirmar cancelamento de ativação',
                description: 'Você está prestes a cancelar a ativação da Licença {product}. Você pode continuar ativando a licença a qualquer momento seguindo o link de ativação novamente. Se a licença for comprada para outra conta, faça login na conta e clique no link de ativação.',
                cancelButton: 'Cancelar',
                continueButton: 'Continuar ativação',
            },
            MonitoringActivateLicense: {
                title: 'Confirme a ativação da licença de monitoramento',
                description: 'Confirme a ativação da licença <b>{product}</b> para sua conta <mailto>{userEmail}</mailto> do 360. A licença pode ser ativada apenas para uma conta 360.',
                fixableErrorDescription: 'Algo deu errado durante a ativação da conta de monitoramento. Tente novamente ou envie uma solicitação de suporte.',
                nonFixableErrorDescription: 'Algo deu errado durante a ativação da licença. Entre em contato com o provedor de licença ou envie uma solicitação de suporte.',
                loaderText: 'Sua conta do 360 Monitoring está sendo configurada',
                redirectText: 'Redirecionando você para o 360 Monitoring',
                confirmButton: 'Confirmar',
                cancelButton: 'Cancelar',
                retryButton: 'Tentar novamente',
                gotItButton: 'Entendi',
                errors: {
                    linkError: 'O link de ativação é inválido.',
                    unknownError: 'Erro interno do serviço de ativação.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'Erro de ativação de licença',
            description: 'O link de ativação está incorreto. Entre em contato com o provedor de licença ou envie uma solicitação de suporte.',
            gotIt: 'Entendi',
        },
    },
};