// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useOutletContext } from 'react-router-dom';
import SignUp from './SignUp';
import SignUpByInvitation from './SignUpByInvitation';
import { AuthOutletContext } from '@platform360/auth/web/types';

const getInvitationEmailFromLocation = ({ hash }: Location): string | undefined =>
    hash === '' ? undefined : hash.slice(1);

const SignUpContainer = () => {
    const { setOperationStatus } = useOutletContext<AuthOutletContext>();

    const invitationEmail = getInvitationEmailFromLocation(window.location);
    const isInvitation = invitationEmail !== undefined;

    return isInvitation ? (
        <SignUpByInvitation email={invitationEmail} setOperationStatus={setOperationStatus} />
    ) : (
        <SignUp />
    );
};

export default SignUpContainer;
