// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Dialog,
    FormErrors,
    FormFieldPassword,
    FormFieldText,
    Heading,
    Paragraph,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { FormFieldConsents } from './FormFieldConsents';

type Consent = {
    type: string;
    text: string;
};

export type Values = {
    email?: string;
    currentPassword?: string;
    otp?: string;
    consents?: Consent[];
};

export type ChangeableEmailDialogProps = {
    isOpen: boolean;
    isMfaEnabled: boolean;
    isLoading: boolean;
    values: Values;
    onSave: (values: Values) => void;
    onCancel: () => void;
    errors?: FormErrors;
};

export const ChangeableEmailDialog = ({
    isOpen,
    isMfaEnabled,
    isLoading,
    values,
    onSave,
    onCancel,
    errors,
}: ChangeableEmailDialogProps) => {
    const translate = useTranslate('accounts.profile.ChangeableEmail');

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onCancel}
            title={translate('dialogTitle')}
            size="xs"
            data-type="email-edit-dialog"
            form={{
                state: isLoading ? 'submit' : undefined,
                values,
                errors,
                onSubmit: (values: Values) => onSave(values),
                submitButton: { children: translate('buttonSave'), 'data-type': 'save-button' },
                cancelButton: {
                    children: translate('buttonCancel'),
                    'data-type': 'cancel-button',
                },
            }}
        >
            <Paragraph>{translate('description')}</Paragraph>
            <FormFieldText label={translate('email')} name="email" size="lg" autoFocus required />
            <FormFieldPassword
                label={translate('currentPassword')}
                name="currentPassword"
                size="lg"
                required
                hideGenerateButton
                hidePasswordMeter
            />
            {isMfaEnabled ? (
                <FormFieldText
                    label={translate('otp')}
                    name="otp"
                    size="lg"
                    required
                    description={translate('otpDescription')}
                />
            ) : undefined}
            <FormFieldConsents
                label={<Heading level={3}>{translate('consentsTitle')}</Heading>}
                name="consents"
            />
        </Dialog>
    );
};
