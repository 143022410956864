// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Heading, Panel } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useCurrentUserQuery } from '@platform360/accounts/web/queries';
import useGetDpaQuery from '@platform360/web-installer/web/queries/useGetDpaQuery';
import useGetMarketingConsentQuery from '@platform360/web-installer/web/queries/useGetMarketingConsentsQuery';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { usePostConsentsMutation } from '@platform360/accounts/web/mutations';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useWebInstallerAnalyticsEvents } from '@platform360/web-installer/web/helpers/analytics';
import { useWebInstallerProduct } from '@platform360/web-installer/web/hooks/useWebInstallerProduct';

import Loading from '@platform360/libs/shared-web/components/Loading';
import Layout from '@platform360/web-installer/web/components/Layout';
import ControlPanelLogo from '@platform360/web-installer/web/components/ControlPanelLogo';
import UserInfoConfirmationForm from '@platform360/web-installer/web/components/UserInfoConfirmationPage/UserInfoConfirmationForm';
import WebInstallerBottomLinks from '@platform360/web-installer/web/components/WebInstallerBottomLinks';
import { AGREEMENT_TYPE_DPA } from '@platform360/libs/common/agreements';

import styles from './UserInfoConfirmationPage.module.css';

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

const UserInfoConfirmationPage = () => {
    const { platform } = useConfig();
    const translate = useTranslate('web-installer.UserDataConfirmationPage');

    const navigate = useNavigate();
    const analyticsEvents = useWebInstallerAnalyticsEvents();
    const [isUserDataLoading, setIsUserDataLoading] = useState(true);
    const [isWaitingDpaUpdate, setIsWaitingDpaUpdate] = useState(false);

    const productType = useWebInstallerProduct();

    const [searchParams] = useSearchParams();
    const installId = searchParams.get('installId');

    const { data: personalInfo } = useCurrentUserQuery();
    const { data: dpa } = useGetDpaQuery();
    const { data: marketingConsent } = useGetMarketingConsentQuery();

    const { mutate: updateConsents } = usePostConsentsMutation({
        onSuccess: () => {
            analyticsEvents.dpaSubmitSuccess();
            navigate(`/?installId=${installId}`);
        },
        onError: () => {
            analyticsEvents.dpaSubmitError();
            setIsUserDataLoading(false);
            setIsWaitingDpaUpdate(false);
        },
    });
    const submitDpa = useCallback(() => {
        analyticsEvents.dpaIsSubmited();
        updateConsents({
            consents: [
                {
                    type: AGREEMENT_TYPE_DPA,
                    text: concatUrl('/legal/data-processing-agreement', platform.baseUrl),
                    enabled: true,
                },
            ],
        });
    }, [analyticsEvents, updateConsents, platform.baseUrl]);
    const handleUserInfoUpdated = async () => {
        setIsWaitingDpaUpdate(true);
        // Workaround for https://webpros.atlassian.net/browse/MC-7830
        await sleep(3000);
        submitDpa();
    };

    useEffectOnce(() => {
        analyticsEvents.userInfoPageIsShown();
    });

    useEffect(() => {
        if (!isUserDataLoading || !personalInfo || !dpa) {
            return;
        }

        const hasFullName = !!(personalInfo.firstName && personalInfo.lastName);

        if (!dpa.dpaSigned && hasFullName) {
            submitDpa();
            setIsUserDataLoading(false);
            return;
        }

        if (dpa.dpaSigned && hasFullName) {
            navigate(`/?installId=${installId}`);
            return;
        }

        setIsUserDataLoading(false);
    }, [personalInfo, dpa, navigate, installId, isUserDataLoading, submitDpa]);

    const formValues = useMemo(
        () => ({
            firstName: personalInfo?.firstName ?? '',
            lastName: personalInfo?.lastName ?? '',
            marketingConsent: marketingConsent?.isSigned ?? false,
        }),
        [personalInfo, marketingConsent],
    );

    if (isUserDataLoading) {
        return <Loading />;
    }

    return (
        <Layout hideUserMenu>
            <div className={styles.userInfoConfirmationPageContainer}>
                <div>
                    <Panel className={styles.userInfoConfirmationPageContainerOuterPanel}>
                        <ControlPanelLogo productType={productType} />
                        <Panel
                            className={styles.userInfoConfirmationPageContainerInnerPanel}
                            data-type="email-confirmation-panel"
                        >
                            <Heading level={2}>{translate('panelTitle')}</Heading>
                            <UserInfoConfirmationForm
                                formValues={formValues}
                                isLoading={isWaitingDpaUpdate}
                                onUserInfoUpdated={handleUserInfoUpdated}
                            />
                        </Panel>
                    </Panel>
                    <WebInstallerBottomLinks productType={productType} />
                </div>
            </div>
        </Layout>
    );
};

export default UserInfoConfirmationPage;
