// Copyright 2024. WebPros International GmbH. All rights reserved.

import ViewServer from './ViewServer';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';

export type ViewServerContainerProps = {
    isOpen: boolean;
    onClose: () => void;
};

const ViewServerContainer = ({ onClose, ...props }: ViewServerContainerProps) => {
    const { serverId: sId } = useParams<{ serverId: string }>();
    const serverId = Number(sId);
    const location = useLocation();
    const {
        data: server,
        isLoading: isServerLoading,
        error: serverError,
    } = useServerQuery({
        variables: { id: serverId },
        enabled: !isNaN(serverId),
    });

    if (isNaN(serverId) || serverError?.response?.status === 404) {
        return <Navigate to="/not-found" />;
    }

    if (isServerLoading || !server) {
        return null;
    }

    return <ViewServer location={location} server={server} onClose={onClose} {...props} />;
};

export default ViewServerContainer;
