// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { SitesHealthFilter, Installation } from '@platform360/security-dashboard/web/types';
import {
    Badge,
    Button,
    SearchBar,
    Toolbar as UILibToolbar,
    ToolbarExpander,
    ToolbarGroup,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { ProtectInstallationsPopover } from './ProtectInstallationsPopover';
import ToolbarButton from '@platform360/security-dashboard/web/components/ToolbarButton';
import { UpdateInstallationPopover } from './UpdateInstallationPopover';

import {
    LabelsPopover,
    AssignRemoveLabelsOptions,
} from '@platform360/security-dashboard/web/components/LabelsPopover';
import { LabelTypes } from '@platform360/security-dashboard/shared/label-type';
import { useLocation, useNavigate } from 'react-router-dom';

export enum SitesHealthGeneralFilter {
    All = 0,
    Alerts = 1,
    NotSelected = 2,
}

type ToolbarProps = {
    searchValue?: string;
    selectedInstallationDisplayTitle?: string;
    onSearch?: (value: string) => void;
    onEnableProtection: (
        installationsIds: number[],
        ignoreDoNotProtect: boolean,
        doNotProtectCount: number,
    ) => void;
    onInstallationsUpdate: (installationsIds: number[]) => void;
    onInstallationsUpdateConfirmationShown: (installationsIds: number[]) => void;
    onProtectConfirmationShown: (installationsIds: number[]) => void;
    onRefresh: (installationsIds: number[]) => void;
    handleAssignInstallationLabels: (data: AssignRemoveLabelsOptions) => void;
    handleRemoveInstallationLabels: (data: AssignRemoveLabelsOptions) => void;
    isSidebarOpen: boolean;
    setSidebarOpen: (newValue: boolean) => void;
    filter: SitesHealthFilter[];
    labelIdsFilter: number[];
    selectedInstallations: Installation[];
    handleUnprocessableInstallationsModalVisibility: (
        installationIds: number[],
        { isSingleOperationAllowed }: { isSingleOperationAllowed: boolean },
    ) => void;
};

export const Toolbar = ({
    searchValue,
    onSearch,
    handleAssignInstallationLabels,
    handleRemoveInstallationLabels,
    selectedInstallationDisplayTitle,
    onInstallationsUpdate,
    onInstallationsUpdateConfirmationShown,
    onProtectConfirmationShown,
    onEnableProtection,
    onRefresh,
    setSidebarOpen,
    isSidebarOpen,
    filter,
    labelIdsFilter,
    selectedInstallations,
    handleUnprocessableInstallationsModalVisibility,
}: ToolbarProps) => {
    const translate = useTranslate('security-dashboard.Installations.Toolbar');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const navigate = useNavigate();
    const { search } = useLocation();
    const selection = selectedInstallations.map((i) => i.id);

    const [showLabelsPopover, setShowLabelsPopover] = useState(false);
    const handleLabelsPopoverShown = () => {
        setShowLabelsPopover(true);
        analyticsEvents.wpLabelsPopoverShown('SITE_TAB');
    };
    const handleLabelsPopoverClosed = () => {
        setShowLabelsPopover(false);
        analyticsEvents.wpLabelsPopoverClosed('SITE_TAB');
    };

    const selectionWithLabels = selectedInstallations.map((i) => ({
        id: i.id,
        labels: i.labels.map((l) => l.id),
    }));

    const doNotProtectCount = selectedInstallations.filter(
        ({ labels, managed, operable }) =>
            managed && operable && labels.some(({ type }) => type === LabelTypes.doNotProtect),
    ).length;

    const openSettingsDrawer = () => {
        analyticsEvents.wpMltSiteSettingsClick(selectedInstallations.length);

        const unprocessableInstallations = selectedInstallations.filter(
            ({ managed, operable }) => !managed || !operable,
        );
        if (unprocessableInstallations.length === selectedInstallations.length) {
            handleUnprocessableInstallationsModalVisibility(
                unprocessableInstallations.map(({ id }) => id),
                { isSingleOperationAllowed: false },
            );
            return;
        }

        if (selectedInstallations.length === 1) {
            navigate({
                pathname: `/security-dashboard/installations/${selection[0]}/update-settings`,
                search,
            });
            return;
        }
        navigate({
            pathname: `/security-dashboard/installations/mass-update-settings/${selection.join(
                ',',
            )}`,
            search,
        });
    };

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('groupActions')}>
                <ToolbarGroup title={translate('groupProtect')}>
                    <ProtectInstallationsPopover
                        doNotProtectCount={doNotProtectCount}
                        onClick={(ignoreDoNotProtect) =>
                            onEnableProtection(selection, ignoreDoNotProtect, doNotProtectCount)
                        }
                        selection={selection}
                        selectedInstallationDisplayTitle={selectedInstallationDisplayTitle}
                        onConfirmationShown={() => onProtectConfirmationShown(selection)}
                        target={
                            <ToolbarButton
                                selectedItems={selection}
                                buttonText={translate('buttons.protect.text')}
                                noSelectionText={translate('buttons.protect.noSelection')}
                                intent="primary"
                                icon={{ name: 'shield', size: '16' }}
                                data-type="batch-protect-button"
                            />
                        }
                    />
                </ToolbarGroup>
            </ToolbarGroup>
            <ToolbarGroup title={translate('groupActions')}>
                <ToolbarGroup title="" groupable={false}>
                    <UpdateInstallationPopover
                        onClick={() => onInstallationsUpdate(selection)}
                        selection={selection}
                        selectedInstallationDisplayTitle={selectedInstallationDisplayTitle}
                        onConfirmationShown={() =>
                            onInstallationsUpdateConfirmationShown(selection)
                        }
                        target={
                            <ToolbarButton
                                selectedItems={selection}
                                buttonText={translate('buttons.update.text')}
                                noSelectionText={translate('buttons.update.noSelection')}
                                data-type="batch-update-button"
                            />
                        }
                    />
                    <LabelsPopover
                        visible={showLabelsPopover}
                        selectionWithLabels={selectionWithLabels}
                        placement="bottom-right"
                        onAssign={handleAssignInstallationLabels}
                        onRemove={handleRemoveInstallationLabels}
                        onClose={handleLabelsPopoverClosed}
                    >
                        <Button onClick={handleLabelsPopoverShown}>
                            {translate('buttons.label.text')}
                        </Button>
                    </LabelsPopover>
                </ToolbarGroup>
                <ToolbarGroup title="" groupable={false}>
                    <ToolbarButton
                        selectedItems={selection}
                        onClick={() => onRefresh(selection)}
                        icon="refresh"
                        tooltip={translate('buttons.refresh.text')}
                        noSelectionText={translate('buttons.refresh.noSelection')}
                    />

                    <ToolbarButton
                        selectedItems={selection}
                        icon="gear"
                        tooltip={translate('buttons.settings.text')}
                        noSelectionText={translate('buttons.settings.noSelection')}
                        onClick={() => openSettingsDrawer()}
                    />
                </ToolbarGroup>
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('groupFilterMenu')}>
                <Badge hidden={filter.length === 0 && labelIdsFilter.length === 0} intent="info">
                    <Button
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                        icon={filter.length > 0 ? 'filter-check-mark' : 'filter'}
                        data-type="filters"
                    />
                </Badge>
            </ToolbarGroup>
            <ToolbarGroup title={translate('groupSearch')}>
                <SearchBar
                    inputProps={{
                        placeholder: translate('searchPlaceholder'),
                        value: searchValue,
                    }}
                    onSearch={(value: string) => onSearch?.(value)}
                    onTyping={onSearch}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
