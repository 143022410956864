// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import { GlobalSearchContext } from './GlobalSearchProvider';

export const useGlobalSearch = () => {
    const GlobalSearch = useContext(GlobalSearchContext);

    if (!GlobalSearch) {
        throw new Error('`GlobalSearchProvider` is not attached to the application');
    }

    return GlobalSearch;
};
