// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Properties, Property } from '@platform360/licenses/web/api/ka/responseTypes/Property';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import { ProductState } from '@platform360/licenses/web/units/adjust/constants/productState';

export type SubmittedTypes = null | 'failed' | 'success';

export type InitialState = {
    failedInitialization: boolean;
    initialization: boolean;
    recalculation: boolean;
    submitting: boolean;
    submitted: SubmittedTypes;

    licenseId: number | null;
    subscriptionId: string | null;
    isAdjustPending: boolean;
    readonly propertiesOfLicense: Properties | null;
    propertiesForChange: Property[];
    newValues: Record<number, NewValuesOfProducts>;
    calculatedSubscriptionState: null | CalculatedSubscriptionState;
};

export type NewValuesOfProducts = {
    quantity: number;
    state: ProductState;
};

export const initialState: InitialState = {
    failedInitialization: false,
    initialization: false,
    recalculation: false,
    submitting: false,
    submitted: null,

    licenseId: null,
    isAdjustPending: false,
    subscriptionId: null,
    propertiesOfLicense: null,
    propertiesForChange: [],
    newValues: {},
    calculatedSubscriptionState: null,
};
