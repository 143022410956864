// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Alert, Button } from '@plesk/ui-library';
import styles from './ImpersonationBanner.module.css';
import CopyText from '@platform360/libs/shared-web/components/CopyText';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useState } from 'react';
import { useToaster } from '@platform360/libs/shared-web/toaster';

export const ImpersonationBanner = () => {
    const translate = useTranslate('Page.ImpersonationBanner');
    const { depersonate, impersonation } = useCurrentUser();
    const { successToast } = useToaster();
    const [isLoading, setIsLoading] = useState(false);

    const handleDepersonate = async () => {
        try {
            setIsLoading(true);

            await depersonate();

            successToast(translate('successToast'));
        } finally {
            setIsLoading(false);
        }
    };

    if (!impersonation) {
        return null;
    }

    return (
        <Alert intent="danger" className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <b>{translate('title')}</b>
                    &nbsp;
                    <Button
                        intent="primary"
                        icon="arrow-right-out"
                        onClick={handleDepersonate}
                        state={isLoading ? 'loading' : undefined}
                    >
                        {translate('depersonateButton')}
                    </Button>
                </div>
                <div className={styles.credentials}>
                    <b>{translate('auth0IdLabel')}</b>
                    <CopyText text={impersonation.auth0Id} />
                    &nbsp;
                    <b>{translate('teamGuidLabel')}</b>
                    <CopyText text={impersonation.teamGuid} />
                </div>
            </div>
        </Alert>
    );
};
