// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment, useEffect } from 'react';
import { SimpleLayout } from '@platform360/libs/shared-web/components/SimpleLayout';
import {
    Button,
    ContentLoader,
    Status,
    StatusMessage,
    Toolbar,
    ToolbarGroup,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Navigate } from 'react-router-dom';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { useServerInventoryErrorsTranslate } from '@platform360/monitoring/web/helpers/useServerInventoryErrorsTranslate';
import useServerLoginLinkQuery from '@platform360/server-inventory/web/queries/useServerLoginLinkQuery';
import {
    isClientPageLogin,
    LoginLinkParams,
} from '@platform360/server-inventory/shared/login-link-params';

export type ServerLoginProps = {
    variables: LoginLinkParams;
};

export const ServerLogin = ({ variables }: ServerLoginProps) => {
    const translate = useTranslate('ServerLogin');
    const siErrorsTranslate = useServerInventoryErrorsTranslate();
    const analyticsEvents = useServerInventoryAnalyticsEvents();

    const {
        data: server,
        isLoading: isServerLoading,
        error: serverError,
    } = useServerQuery({ variables: { id: variables.serverId } });

    const {
        data: loginLink,
        isLoading: isLoginLinkLoading,
        isError: isLoginLinkError,
        error: loginLinkError,
        refetch: refetchLoginLink,
    } = useServerLoginLinkQuery({ variables });

    useEffect(() => {
        if (server && loginLink) {
            analyticsEvents.userLoginToPleskSuccess({ address: server.address.href });
            window.location.href = new URL(loginLink, server.address.href).href;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server, loginLink]);

    useEffect(() => {
        if (server && isLoginLinkError) {
            analyticsEvents.userLoginToPleskFail(
                { address: server.address.href },
                loginLinkError.response?.data?.type,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server, isLoginLinkError]);

    if (serverError?.response?.status === 404 || loginLinkError?.response?.status === 404) {
        return <Navigate to="/not-found" />;
    }

    if (isServerLoading || !server) {
        return (
            <SimpleLayout>
                <ContentLoader />
            </SimpleLayout>
        );
    }

    return (
        <SimpleLayout>
            <h2>{translate('title', { address: server.address.hostname })}</h2>
            {isLoginLinkLoading || loginLink ? (
                <Fragment>
                    {isClientPageLogin(variables) ? <p>{translate('clientNote')}</p> : null}
                    <Status progress>{translate('progress')}</Status>
                </Fragment>
            ) : undefined}
            {isLoginLinkError ? (
                <Fragment>
                    <StatusMessage intent="danger" data-type="error-message">
                        {siErrorsTranslate(
                            loginLinkError.response?.data?.type,
                            loginLinkError.response?.data?.message,
                        )}
                    </StatusMessage>
                    <Toolbar>
                        <ToolbarGroup title={translate('btnGroupLogin')}>
                            <Button intent="primary" onClick={() => refetchLoginLink()}>
                                {translate('tryAgain')}
                            </Button>
                            <Button component="a" href={server.address.href}>
                                {translate('loginDirectly')}
                            </Button>
                        </ToolbarGroup>
                        <ToolbarGroup title={translate('btnGroupCancel')}>
                            <Button onClick={() => window.close()}>{translate('cancel')}</Button>
                        </ToolbarGroup>
                    </Toolbar>
                </Fragment>
            ) : undefined}
        </SimpleLayout>
    );
};
