// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'Nessun allarme attivo',
        openAlerts: 'Allarmi attivi: {alerts}',
        loadingError: 'Servizio non disponibile',
        connectServer: 'Connetti server',
        notSupported: 'Non supportato',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'Visualizza in 360 Monitoring',
        connectServerTitle: 'Questo server non è connesso a 360 Monitoring',
        connectServerDescription: 'Quando il server è connesso, questa pagina mostra una breve panoramica dello stato del server e degli avvisi attivi.',
        connectServerButton: 'Connetti server',
        pausedServerTitle: 'Questo server non è monitorato',
        pausedServerDescription: 'Usa l\'interfaccia utente di 360 Monitoring per determinare il motivo.',
        preparingServerTitle: 'Stiamo lavorando per te...',
        preparingServerDescription1: 'Il tuo server Plesk è stato connesso a 360 Monitoring.',
        preparingServerDescription2: 'Stiamo lavorando per preparare una dashboard. Prova ad aggiornare la pagina o torna tra 10-30 minuti.',
        refreshButton: 'Aggiorna',
        loadingError: 'Si è verificato un problema. Riprova più tardi.',
        loadAverageTitle: 'Carico medio',
        alertOpened: 'Attivato {timeAgo}.',
        viewAlertsInMonitoring: 'Visualizza avvisi in 360 Monitoring',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Monitoraggio',
            description: 'Monitora tutti i tuoi server e i tuoi siti web da un\'unica dashboard',
        },
        DynamicWidget: {
            title: 'Monitoraggio',
            WidgetContent: {
                servers: 'Server',
                websites: 'Siti web',
                lastUpdate: 'Ultimo aggiornamento: {lastUpdate}',
                loading: 'Caricamento in corso...',
                Loader: {
                    loading: 'Caricamento in corso...',
                    notRespondingText1: '360 Monitoring non risponde.',
                    notRespondingText2: 'Tentativo di riconnessione.',
                },
                InfoAggregateColumn: {
                    allActive: 'Tutto attivo',
                    noAlerts: 'Nessun allarme',
                    alerts: 'Allarmi attivi: {alerts}',
                    down: '{down, plural, one{# non risponde} other{# non rispondono}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: 'Attiva 360 Monitoring',
        description: '360 Monitoring Lite è e sarà sempre gratuito. Attivando il tuo account oggi, otterrai un periodo di prova gratuito di 14 giorni della versione Pro, completa di tutte le funzionalità premium. Al termine delle due settimane, tornerai automaticamente alla versione Lite gratuita, a meno che tu non decida di confermare l\'upgrade!',
        beginTrialButton: 'Attiva 360 Monitoring *',
        footnote: '* L\'attivazione di 360 Monitoring Lite e del periodo di prova della versione Pro non richiede alcuna carta di credito.',
        backButton: 'Torna a Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Annulla',
            confirmationText: 'Prova a configurare il tuo account 360 Monitoring aprendo la voce di menu Monitoraggio o invia una richiesta di assistenza.',
            acceptButtonText: 'OK',
            declineButtonText: 'Annulla',
        },
        retryButtonText: 'Riprova',
        inProgressText: 'È in corso la configurazione del tuo account 360 Monitoring',
        successText: 'Reindirizzamento a 360 Monitoring',
        failedText: 'Si è verificato un errore durante l\'attivazione dell\'account di Monitoring. Riprova o invia una richiesta di supporto.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Conferma l\'attivazione di 360 Monitoring',
            description: 'L\'agente "360 Monitoring" verrà installato nel pannello di controllo di Plesk all\'indirizzo <b>{address}</b> e collegato all\'account <b>{email}</b> di Plesk 360.',
        },
        monitoringInactive: {
            title: 'Attiva 360 Monitoring e aggiungi il server',
            description: 'Inizia a usare 360 Monitoring con un <tooltip>periodo di prova</tooltip> e conferma l\'installazione dell\'agente "360 Monitoring" nel tuo pannello di controllo di Plesk all\'indirizzo <b>{address}</b>, collegato all\'account <b>{email}</b> di Plesk 360.',
            trialTooltip: 'Durante il periodo di prova di 14 giorni ricevi un servizio completo di tutte le funzionalità premium per 14 giorni. Alla fine del periodo di prova, 360 Monitoring passerà alla versione gratuita “Lite” con disponibili le sole funzioni di base. Puoi anche attivare una versione “Pro” quando vuoi acquistando una licenza.',
        },
        buttons: {
            confirm: 'Conferma',
            deny: 'Nega',
            cancel: 'Annulla',
        },
        errors: {
            apiKey: 'Sembra che la connessione a Plesk 360 sia stata interrotta. Riconnettiti il tuo server a Plesk 360, quindi attiva il monitoraggio',
            default: 'Installazione di 360 Monitoring non riuscita. Riprova più tardi',
        },
    },
};