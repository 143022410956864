// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSearchParams } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Heading, SearchBar, Text, StatusMessage } from '@plesk/ui-library';
import { useFindServersQuery } from '@platform360/admin-panel/web/queries/security-dashboard';
import EmptyView from './EmptyView';
import RequestForm from './RequestForm';
import Network from './Network';
import { useRequestStorage } from './useRequestStorage';
import styles from './Requests.module.css';

export const Requests = () => {
    const translate = useTranslate('admin-panel.security-dashboard.Requests');

    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get('searchQuery') ?? '';

    const { data: { servers } = { servers: [] }, isLoading } = useFindServersQuery({
        variables: { searchQuery },
        enabled: !!searchQuery,
        refetchOnWindowFocus: false,
    });

    const handleSearch = (value: string) => {
        if (!value) return;
        setSearchParams({ searchQuery: value });
    };

    const server = servers[0] && servers.length === 1 ? servers[0] : null;

    const serverAddress = server ? `${new URL(server.address).hostname}, ` : '';

    const { requests, handleRequestSent } = useRequestStorage();

    return (
        <>
            <Heading>{translate('title')}</Heading>

            <SearchBar
                onSearch={handleSearch}
                loading={isLoading}
                inputProps={{
                    placeholder: translate('searchPlaceholder'),
                    maxLength: 400,
                }}
                className={styles.searchBar}
            />

            {!servers.length && !isLoading && <EmptyView notFound={!!searchQuery} />}

            {servers.length > 1 && (
                <StatusMessage intent="info">{translate('multipleServersFound')}</StatusMessage>
            )}

            {server && (
                <Heading component="div" level={3}>
                    {translate('selectedServer', { server: server.hostname ?? serverAddress })}
                    <Text component="div" fontSize="sm" intent="muted">
                        {serverAddress}
                        {server.guid}
                    </Text>
                </Heading>
            )}

            <div style={{ display: server ? 'block' : 'none' }}>
                <RequestForm serverId={server?.id} onSent={handleRequestSent} />
            </div>

            {!!requests.length && <Network requests={requests} className={styles.network} />}
        </>
    );
};
