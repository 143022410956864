// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, FormFieldCheckbox, FormFieldText } from '@plesk/ui-library';
import styles from '@platform360/web-installer/web/components/UserInfoConfirmationPage/UserInfoConfirmationPage.module.css';
import { AGREEMENT_TYPE_MARKETING } from '@platform360/libs/common/agreements';
import {
    usePostConsentsMutation,
    useUpdateCurrentUserMutation,
} from '@platform360/accounts/web/mutations';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useWebInstallerAnalyticsEvents } from '@platform360/web-installer/web/helpers/analytics';

type FormValues = {
    firstName: string;
    lastName: string;
    marketingConsent: boolean;
};

type UserInfoConfirmationFormProps = {
    formValues: FormValues;
    isLoading: boolean;
    onUserInfoUpdated: () => void;
};

const UserInfoConfirmationForm = ({
    formValues,
    isLoading,
    onUserInfoUpdated,
}: UserInfoConfirmationFormProps) => {
    const analyticsEvents = useWebInstallerAnalyticsEvents();
    const translateValidationErrors = useTranslateValidationErrors();
    const translate = useTranslate('web-installer.UserDataConfirmationPage');

    const {
        mutate: updateUserInfo,
        isPending: isUpdatingUserInfo,
        error,
    } = useUpdateCurrentUserMutation({
        onSuccess: () => {
            analyticsEvents.userInfoSubmitSuccess();
            onUserInfoUpdated();
        },
        onMutate: () => {
            analyticsEvents.userInfoIsSubmitted();
        },
        onError: () => {
            analyticsEvents.userInfoSubmitError();
        },
    });
    const { mutate: updateConsents, isPending: isUpdatingConsents } = usePostConsentsMutation({
        onSuccess: () => {
            analyticsEvents.consentsSubmitSuccess();
        },
        onMutate: () => {
            analyticsEvents.consentsAreSubmited();
        },
        onError: () => {
            analyticsEvents.consentsSubmitError();
        },
    });

    const validationErrors = getValidationErrors(error) ?? [];
    const handleSubmit = (values: FormValues): void => {
        updateUserInfo(values);
        // TODO Update consents only if a user change consents
        updateConsents({
            consents: [
                {
                    type: AGREEMENT_TYPE_MARKETING,
                    text: translate('marketingConsent.text', {
                        a: (chunk) => chunk,
                    }),
                    enabled: values.marketingConsent,
                },
            ],
        });
    };

    return (
        <Form
            vertical
            values={formValues}
            applyButton={false}
            cancelButton={false}
            submitButton={{
                children: translate('submitButton'),
                type: 'submit',
            }}
            state={isUpdatingConsents || isUpdatingUserInfo || isLoading ? 'submit' : undefined}
            errors={translateValidationErrors(validationErrors)}
            onSubmit={handleSubmit}
        >
            <div className={styles.userInfoFieldsContainer}>
                <FormFieldText
                    label={translate('firstName')}
                    name="firstName"
                    size="fill"
                    autoFocus
                    required
                />
                <FormFieldText label={translate('lastName')} name="lastName" size="fill" required />
            </div>
            <FormFieldCheckbox
                className={styles.userInfoMarketingConsent}
                name="marketingConsent"
                label={translate('marketingConsent.title', {
                    a: (chunk) => (
                        <a href="/legal/privacy-policy" target="_blank">
                            {chunk}
                        </a>
                    ),
                })}
                description={translate('marketingConsent.text')}
            />
        </Form>
    );
};

export default UserInfoConfirmationForm;
