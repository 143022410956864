// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import LightsailInstallation, { FormValues } from './LightsailInstallation';
import useLightsailInstall from '@platform360/web-installer/web/mutations/useLightsailInstall';
import { useNavigate } from 'react-router-dom';
import { generateInstanceName } from './helpers';
import useLightsailOptions from '@platform360/web-installer/web/queries/useLightsailOptions';
import { ContentLoader } from '@plesk/ui-library';
import { LightsailRegion } from '@platform360/web-installer/shared/api-contract';

const getDefaultsForRegionAndBundleId = (
    region: string,
    bundleId: string,
    regions?: LightsailRegion[],
) => {
    if (!regions) {
        return { region, bundleId };
    }

    const currentRegion = regions.find((r) => r.Name === region) || regions[0];
    if (!currentRegion) {
        return { region, bundleId };
    }

    const currentBundle =
        currentRegion.Bundles.find((b) => b.BundleId === bundleId) || currentRegion.Bundles[0];
    if (!currentBundle) {
        return { region: currentRegion.Name, bundleId };
    }

    return { region: currentRegion.Name, bundleId: currentBundle.BundleId };
};

const LightsailInstallationContainer = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState<FormValues>({
        awsAccessKey: '',
        awsSecretKey: '',
        region: '',
        bundleId: '',
        publicKey: '',
        instanceName: generateInstanceName(),
    });
    const { data: lightsailOptions } = useLightsailOptions({
        // Don't refetch options
        staleTime: Infinity,
    });
    const bundles = lightsailOptions?.Regions.find((r) => r.Name === values.region)?.Bundles;
    const {
        isPending: isLoading,
        errors,
        mutate,
    } = useLightsailInstall({
        onSuccess: (id) => {
            navigate(`/web-installer/installations/${id}`);
        },
    });

    useEffect(() => {
        if (lightsailOptions) {
            setValues({
                ...values,
                ...getDefaultsForRegionAndBundleId(
                    values.region,
                    values.bundleId,
                    lightsailOptions.Regions,
                ),
            });
        }
    }, [lightsailOptions, values]);

    const handleSubmit = (values: FormValues) => {
        const region = lightsailOptions?.Regions.find((r) => r.Name === values.region);

        mutate({
            availabilityZone: region?.AvailabilityZones[0]?.ZoneName || '',
            blueprintId: region?.BlueprintId || '',
            ...values,
        });
    };
    const handleFieldChange = (name: string, value: string) => {
        if (name === 'region') {
            setValues({
                ...values,
                ...getDefaultsForRegionAndBundleId(
                    value,
                    values.bundleId,
                    lightsailOptions?.Regions,
                ),
            });
            return;
        }

        setValues({ ...values, [name]: value });
    };

    if (!lightsailOptions || !bundles) {
        return <ContentLoader />;
    }

    return (
        <LightsailInstallation
            regions={lightsailOptions.Regions}
            bundles={bundles}
            isLoading={isLoading}
            values={values}
            errors={errors}
            onSubmit={handleSubmit}
            onFieldChange={handleFieldChange}
        />
    );
};

export default LightsailInstallationContainer;
