// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const {
    versionStatusValues,
    isVersionStatus,
    VersionStatuss: VersionStatuses,
} = makeEnum('VersionStatus', 'latest', 'supported', 'outdated', 'unknown', 'unsupported');

/**
 * Product version status values.
 *
 * - 'latest' -- the latest product installed.
 * - 'supported' -- the product version is supported but can be updated.
 * - 'outdated' -- the product version is not supported anymore, the product should be updated.
 * - 'unknown' -- the product version cannot be determined at the moment
 * - 'unsupported' -- the product version is behind five latest releases
 */
export type VersionStatus = UnionOf<typeof versionStatusValues>;
