// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

const getKey = (path: string) => `modals[${path}]`;

export const useGetModalUrl = () => {
    const location = useLocation();

    return useCallback(
        (path: string, isOpened = true): string => {
            const key = getKey(path);
            const { pathname, search } = location;
            const params = new URLSearchParams(search);

            if (isOpened) {
                params.set(key, 'true');
            } else {
                params.delete(key);
            }

            return `${pathname}?${params.toString()}`;
        },
        [location],
    );
};

const useModal = (path: string): [boolean, (next: boolean) => void] => {
    const navigate = useNavigate();
    const location = useLocation();
    const getModalUrl = useGetModalUrl();
    const currentParams = new URLSearchParams(location.search);
    const value = currentParams.get(getKey(path)) === 'true';

    const setValue = (next: boolean) => {
        navigate(getModalUrl(path, next));
    };

    return [value, setValue];
};

export default useModal;
