// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { Text } from '@plesk/ui-library';

type RowDateProps = {
    date: string;
};
export const RowDate = ({ date }: RowDateProps) => {
    const formatDateTime = useFormatDateTime();
    const dateTime = DateTime.fromISO(date);

    if (dateTime.hasSame(DateTime.now(), 'day')) {
        return <Text>{formatDateTime(dateTime, 't')}</Text>;
    } else if (dateTime.hasSame(DateTime.now(), 'year')) {
        return <Text>{formatDateTime(dateTime, 't, dd LLL')}</Text>;
    }
    return <Text>{formatDateTime(dateTime, 't, dd LLL yy')}</Text>;
};

export default RowDate;
