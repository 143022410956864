// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Alert, Section, Status } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { normalizeAddress } from '@platform360/server-inventory/web/components/Servers/utils';

export type UnsecureConfirmationProps = {
    address?: string;
};

const parseHostname = (address?: string): string => {
    try {
        address = normalizeAddress(address);
        if (address === '') return '';
        const url = new URL(address);
        return url.hostname;
    } catch (_e) {
        return '';
    }
};

const UnsecureConfirmation = ({ address }: UnsecureConfirmationProps) => {
    const translate = useTranslate('Servers.UnsecureConfirmation');

    const hostname = parseHostname(address);

    return (
        <>
            <Section>
                <Status icon={{ name: 'lock-open-filled', size: '24' }} intent="danger">
                    {translate('wouldLikeAddUnsecuredServer', { hostname })}
                </Status>
            </Section>

            <Section>
                <Status icon={{ name: 'zero-circle-filled', size: '24' }} intent="danger">
                    {translate('understandSecurityIssues')}
                </Status>
            </Section>

            <Section>
                <Status icon={{ name: 'zero-circle-filled', size: '24' }} intent="danger">
                    {translate('understandDataCanLost')}
                </Status>
            </Section>

            <Alert intent="danger">{translate('agreeNotResponsibility')}</Alert>
        </>
    );
};

export default UnsecureConfirmation;
