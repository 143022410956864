// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Heading, Paragraph } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './MfaRequired.module.css';
import octopus from './octopus.gif';

export const MfaRequired = () => {
    const translate = useTranslate('admin-panel.Layout.MfaRequired');

    return (
        <div className={styles.root}>
            <img className={styles.image} src={octopus} alt="Octopus" />
            <div className={styles.content}>
                <Heading level={2}>{translate('title')}</Heading>
                <Paragraph>{translate('description')}</Paragraph>
                <Button component={Link} size="lg" intent="primary" to="/auth/logout" fill>
                    {translate('logoutBtn')}
                </Button>
            </div>
        </div>
    );
};
