// Copyright 2024. WebPros International GmbH. All rights reserved.

import { checkConnectionServer } from '@platform360/server-inventory/web/api/servers';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';
import { useServerInventoryErrorsTranslate } from '@platform360/monitoring/web/helpers/useServerInventoryErrorsTranslate';

const useCheckConnectionServer = (serverId: number) => {
    const translate = useTranslate('ViewServer');
    const siErrorsTranslate = useServerInventoryErrorsTranslate();
    const queryClient = useQueryClient();
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: () => checkConnectionServer(serverId),
        onSuccess: async (server) => {
            await queryClient.cancelQueries({
                queryKey: useServerQuery.getQueryKey({ id: server.id }),
            });
            useServerQuery.setQueryData(
                queryClient,
                useServerQuery.getQueryKey({ id: server.id }),
                server,
            );

            if (server.lastRequestResultType.status === 'ok') {
                successToast(translate('recheckResultSuccess'));
            } else {
                const messageKeys = {
                    certificateError: 'recheckResultCertificateError',
                    connectionError: 'recheckResultConnectionError',
                    connectionBlocked: 'recheckResultConnectionBlocked',
                };

                errorToast(
                    translate(messageKeys[server.lastRequestResultType.status], {
                        serverName: server.address.hostname,
                        errorMessage: (
                            <>
                                {siErrorsTranslate(
                                    server.lastRequestResultType.errorCode,
                                    server.lastRequestResultType.errorDetail,
                                )}
                            </>
                        ),
                    }),
                );
            }
        },
    });
};

export default useCheckConnectionServer;
