// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './Nowrap.module.less';
import { ReactNode } from 'react';

type NowrapProps = {
    children: ReactNode;
};

const Nowrap = ({ children }: NowrapProps) => <span className={styles.nowrap}>{children}</span>;

export default Nowrap;
