// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Action } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { LicenseWithSubscription as BackendLicenseWithSubscription } from '@platform360/licenses/web/api/ka/responseTypes/License';
import isPropertyChangeAvailable from '@platform360/licenses/web/units/adjust/helpers/isPropertyChangeAvailable';
import NonBreakingSpace from '@platform360/licenses/web/components/HTMLEntities/NonBreakingSpace';

type PropertiesProps = {
    license: BackendLicenseWithSubscription;
    onClick: () => void;
};

const Properties = ({ license, onClick }: PropertiesProps) => {
    const translate = useTranslate();
    const isChangeAvailable = isPropertyChangeAvailable(license);

    return (
        <>
            <div>{translate('limit')}</div>
            {license.propertiesUpgradeInfo?.map(({ name, keyValue, description }) => (
                <div key={name.concat(keyValue)}>
                    {keyValue} {description}
                    <NonBreakingSpace />
                </div>
            ))}
            {isChangeAvailable && (
                <Action icon="sliders" onClick={onClick} data-type={'Adjust--action-button'}>
                    {translate('upgradePropertiesButton')}
                </Action>
            )}
        </>
    );
};

export default Properties;
