// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ListEmptyView, ListEmptyViewProps } from '@plesk/ui-library';
import imageEmptyFilter from './empty-filter.png';

type ListEmptyViewFilterProps = ListEmptyViewProps;

export const ListEmptyViewFilter = ({ ...props }: ListEmptyViewFilterProps) => (
    <ListEmptyView reason="filtered" image={imageEmptyFilter} {...props} />
);
