// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getServer } from '@platform360/server-inventory/web/api/servers';
import { createQuery } from '@platform360/libs/shared-web/query';
import { Server } from '@platform360/server-inventory/web/types';

export type UseServerQueryVariables = {
    id: number;
};

export type UseServerQueryData = Server;

const useServerQuery = createQuery<UseServerQueryVariables, UseServerQueryData>({
    queryName: 'server-inventory/useServerQuery',
    fetcher: getServer,
    useQuery,
});

export default useServerQuery;
