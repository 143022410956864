// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link as RouterLink } from 'react-router-dom';
import { ReactElement, ReactNode } from 'react';
import { Link, ProgressBar as UILibProgressBar, Icon } from '@plesk/ui-library';
import styles from './ProgressBar.module.css';
import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import {
    SITE_HEALTH_FILTER_ID,
    SitesHealthFilter,
} from '@platform360/security-dashboard/web/types';
import { IconName } from '@plesk/ui-library/types/src/components/Icon/constants';

export type ProgressBarProps = {
    current: number;
    children: ReactNode;
    intent: 'success' | 'warning' | 'danger' | 'info' | 'muted';
    siteHealthFilter?: SitesHealthFilter;
    icon: IconName | ReactElement;
    total: number;
    onClick?: () => void;
};

export const ProgressBar = ({
    current,
    children,
    intent,
    icon,
    total,
    siteHealthFilter,
    onClick,
}: ProgressBarProps) => {
    const mutedClass = intent === 'muted' && styles.muted ? styles.muted : '';
    const progressClass = styles.progress ?? '';

    const drawIcon = () => {
        if (typeof icon === 'string') {
            return <Icon name={icon} intent={intent === 'muted' ? 'inactive' : intent} />;
        }
        return icon;
    };

    return (
        <div className={styles.root}>
            <UILibProgressBar
                intent={intent === 'muted' ? undefined : intent}
                progress={total <= 0 ? 0 : (current / total) * 100}
                className={`${progressClass} ${mutedClass}`}
            />
            <div className={styles.status}>
                {drawIcon()}
                <Link
                    component={RouterLink}
                    to={buildUrl('/security-dashboard/installations', {
                        [SITE_HEALTH_FILTER_ID]: siteHealthFilter,
                    })}
                    onClick={onClick}
                >
                    {children}
                </Link>
            </div>
        </div>
    );
};
