// Copyright 2024. WebPros International GmbH. All rights reserved.

import amplitude from 'amplitude-js';
import { getUtmProperties } from '@platform360/libs/common/analytics/utm-properties';
import debug from 'debug';

const log = debug('app:analytics');

class AnalyticsClient {
    public constructor(private readonly client?: amplitude.AmplitudeClient) {}

    /* Analytics Client API */

    public setUserId(userId: string | null) {
        this.client?.setUserId(userId);
    }

    public setUserProperties(userProperties: Record<string, unknown>) {
        this.client?.setUserProperties(userProperties);
    }

    public setUtmProperties(searchParams: URLSearchParams) {
        if (!this.client) {
            return;
        }

        const utmProperties = Object.entries(getUtmProperties(searchParams));
        if (utmProperties.length === 0) {
            return;
        }

        const identify = new amplitude.Identify();
        utmProperties.forEach(([name, value]) => {
            identify.setOnce(`initial_${name}`, value);
            identify.set(name, value);
        });

        this.client.identify(identify);
    }

    public sessionStart(user: string) {
        this.logEvent('Session started', { user });
    }

    public logEvent(name: string, ...params: unknown[]) {
        log(name, ...params);
        this.client?.logEvent(name, ...params);
    }
}

export default AnalyticsClient;
