// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Section, SectionItem, Switch, TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useUpdateNotificationsSettings from '@platform360/server-inventory/web/mutations/useUpdateNotificationsSettings';
import {
    NotificationsSettingsPatchRequest,
    NotificationsSettingsResponse,
} from '@platform360/server-inventory/shared/api-contract';

type NotificationsSettingsProps = {
    settings: NotificationsSettingsResponse;
};

export const NotificationsSettings = ({ settings }: NotificationsSettingsProps) => {
    const translate = useTranslate('server-inventory.NotificationsSettings');

    return (
        <Section
            title={
                <Heading icon="bell" level={5}>
                    {translate('notifySectionTitle')}
                </Heading>
            }
        >
            <SwitchSectionItem
                title={translate('notifyServerUnreachable')}
                checked={settings.notifyServerUnreachable}
                togglePayload={(checked) => ({ notifyServerUnreachable: checked })}
                dataType="notify-server-unreachable-switch"
            />
            <SwitchSectionItem
                title={translate('notifyServerUnsupported')}
                checked={settings.notifyServerUnsupported}
                togglePayload={(checked) => ({ notifyServerUnsupported: checked })}
                dataType="notify-server-unsupported-switch"
            />
        </Section>
    );
};

type SwitchSectionItemProps = {
    title: TranslationProps;
    checked: boolean;
    togglePayload: (checked: boolean) => NotificationsSettingsPatchRequest;
    dataType?: string;
};

const SwitchSectionItem = ({ title, checked, togglePayload, dataType }: SwitchSectionItemProps) => {
    const { mutate, isPending } = useUpdateNotificationsSettings();
    const toggle = (checked: boolean): void => mutate(togglePayload(checked));

    return (
        <SectionItem title={title}>
            <Switch checked={checked} onChange={toggle} loading={isPending} data-type={dataType} />
        </SectionItem>
    );
};
