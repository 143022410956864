// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useToaster } from '@platform360/libs/shared-web/toaster';
import { toGeneralError } from '@platform360/libs/shared-web/helpers/apiErrors';

export const useErrorToast = (error: Error) => {
    const { errorToast } = useToaster();

    const formattedError = toGeneralError(error);

    if (formattedError) {
        errorToast(formattedError);
    }
};
