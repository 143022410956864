// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SegmentedControl } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { MONTHLY, YEARLY } from '@platform360/licenses/web/constants/billingPeriod';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';

type PeriodControlProps = {
    selected?: BillingPeriod;
    onChange: (period: BillingPeriod) => void;
    disabled?: boolean;
};

/**
 * Control between periods
 * By default selected Monthly period
 * @param selected
 * @param onChange
 * @param disabled
 */
const PeriodControl = ({
    selected,
    onChange,

    disabled = false,
}: PeriodControlProps) => {
    const translate = useTranslate();

    return (
        <SegmentedControl
            buttons={[
                {
                    value: MONTHLY,
                    title: translate('monthlyPrices'),
                    'data-type': `SwitchBilling--${MONTHLY}`,
                    disabled,
                },
                {
                    value: YEARLY,
                    title: translate('yearlyPrices'),
                    'data-type': `SwitchBilling--${YEARLY}`,
                    disabled,
                },
            ]}
            monospaced
            selected={selected}
            onChange={onChange}
        />
    );
};

export default PeriodControl;
