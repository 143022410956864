// Copyright 2024. WebPros International GmbH. All rights reserved.
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { SALES_LINK } from '@platform360/security-dashboard/shared/external-links';
import {
    LicenseBenefit,
    AvailableBenefits,
} from '@platform360/security-dashboard/web/components/LicenseBenefit';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { Button, Heading, Paragraph, Text } from '@plesk/ui-library';
import { PromoCodeForm } from '@platform360/security-dashboard/web/components/PromoCodeForm';

import { useActivateTrialMutation } from '@platform360/security-dashboard/web/mutations';
import styles from './ActivateTrial.module.css';

const BENEFITS: AvailableBenefits[] = [
    'vulnerabilityDetection',
    'secureSites',
    'autoUpdates',
    'safeUpdates',
    'notifications',
];

const ActivateTrial = () => {
    const translate = useTranslate('security-dashboard.Layout.ActivateTrial');

    const { errorToast } = useToaster();

    const { mutate: activateTrial, isPending } = useActivateTrialMutation({
        onError: () => {
            errorToast(translate('activationFailure'));
        },
    });

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.cardHead}>
                    <Heading level={4}>{translate('havePromoCode')}</Heading>
                    <div className={styles.inputContainer}>
                        <PromoCodeForm buttonProps={{ ghost: true }} inputProps={{ size: 'md' }} />
                    </div>
                </div>

                <div className={styles.cardBody}>
                    <Heading level={2}>{translate('trialTitle')}</Heading>
                    <Paragraph>{translate('trialText')}</Paragraph>
                    <Button intent="primary" disabled={isPending} onClick={() => activateTrial()}>
                        {translate('activateTrial')}
                    </Button>
                    <div className={styles.becomePartnerParagraph}>
                        <Text intent="muted">
                            {translate('becomePartner', {
                                a: (becomePartner) => (
                                    <a href={SALES_LINK} target="_blank" rel="noopener noreferrer">
                                        {becomePartner}
                                    </a>
                                ),
                            })}
                        </Text>
                    </div>
                </div>
            </div>

            <div className={styles.benefitsContainer}>
                <Heading level={2}>{translate('benefits.benefitsTitle')}</Heading>
                <div className={styles.benefits}>
                    {BENEFITS.map((benefit) => (
                        <div
                            key={benefit}
                            className={`${styles.benefit} ${styles[`benefit-${benefit}`]}`}
                        >
                            <LicenseBenefit benefit={benefit} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ActivateTrial;
