// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, FormErrors, FormFieldText, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { PrivacyPolicy } from '@platform360/auth/web/components/PrivacyPolicy';

type ResetPasswordFormProps = {
    onEmailChange: (email: string) => void;
    onSubmit: (email: string) => void;
    isLoading: boolean;
    errors?: FormErrors;
    email: string;
    isUserLoggedIn?: boolean;
};

type FormValues = {
    email: string;
};

const DataTestIds = {
    FORM: 'ResetPassword--form',
};

const ResetPasswordForm = ({
    onSubmit,
    errors,
    isLoading,
    onEmailChange,
    email,
    isUserLoggedIn,
}: ResetPasswordFormProps) => {
    const translate = useTranslate('Auth.ResetPassword');

    const handleSubmit = ({ email }: FormValues) => onSubmit(email);

    return (
        <Form
            onSubmit={handleSubmit}
            values={{ email }}
            errors={errors}
            state={isLoading ? 'submit' : undefined}
            applyButton={false}
            submitButton={{
                children: translate('submitButton'),
                type: 'submit',
            }}
            cancelButton={false}
            hideRequiredLegend
            vertical
            data-type={DataTestIds.FORM}
        >
            <Paragraph>{translate('text')}</Paragraph>
            <FormFieldText
                name="email"
                onChange={onEmailChange}
                label={translate('emailLabel')}
                disabled={isUserLoggedIn}
            />
            <PrivacyPolicy />
        </Form>
    );
};

export default ResetPasswordForm;
