// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './SimpleLayout.module.css';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export type SimpleLayoutProps = {
    children: ReactNode;
    logo?: ReactNode;
};

const SimpleLayout = ({ children, logo }: SimpleLayoutProps) => {
    const { logo: appLogo } = useApplicationSettings();

    return (
        <div className={styles.root}>
            {logo ? logo : appLogo}
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default SimpleLayout;
