// Copyright 2024. WebPros International GmbH. All rights reserved.

import useNotificationsSettingsQuery from '@platform360/server-inventory/web/queries/useNotificationsSettingsQuery';
import Loading from '@platform360/libs/shared-web/components/Loading';
import { NotificationsSettings } from './NotificationsSettings';

const NotificationsSettingsContainer = () => {
    const { isLoading, data } = useNotificationsSettingsQuery();

    if (isLoading || !data) {
        return <Loading />;
    }

    return <NotificationsSettings settings={data} />;
};

export default NotificationsSettingsContainer;
