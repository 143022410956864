// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'Es wurde ein öffentlicher Schlüssel anstelle des erwarteten privaten Schlüssels erkannt.',
        passphrasePrivateKeyError: 'Geben Sie bitte einen Schlüssel an, der nicht durch eine Passphrase geschützt ist.',
        unsupportedPrivateKeyError: 'Der angegebene Schlüsseltyp wird nicht unterstützt.',
        invalidPrivateKeyError: 'Bitte vergewissern Sie sich, dass Sie den richtigen privaten SSH-Schlüssel verwenden.',
        emptyPrivateKeyError: 'Der angegebene private SSH-Schlüssel ist ungültig, da er leer ist.',
        tooBigPrivateKeyError: 'Bitte stellen Sie eine Schlüsseldatei unter 30 KB bereit.',
    },
    Installation: {
        startAnotherInstallation: 'Eine weitere Installation starten',
        steps: {
            running: {
                title: 'Plesk wird installiert',
                description: 'Installation von Plesk auf <b>{host}</b>',
            },
            error: {
                title: 'Fehlerr bei der Installation',
            },
            completed: {
                title: 'Installation abgeschlossen',
                pleskHostText: 'Plesk wurde auf {host} installiert.',
                goToPleskText: 'Klicken Sie auf "Zu Plesk", um sich anzumelden. Nach einem kurzen Einrichtungsprozess können Sie Plesk verwenden.',
                goToPleskButton: 'Zu Plesk wechseln',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Serververbindung getrennt',
            description: 'SSH-Verbindung zum Server wurde unterbrochen.',
        },
        connectFail: {
            title: 'Serververbindung fehlgeschlagen',
            description: '<p>Keine Verbindung zum Server möglich: {details}.</p><p>Wenn der Server einen benutzerdefinierten SSH-Port verwendet, geben Sie diesen an, indem Sie nach dem Hostnamen oder der IP-Adresse einen Doppelpunkt und die Portnummer hinzufügen (zum Beispiel: example.com:1024 oder 192.0.2.0:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk ist bereits installiert',
            description: 'Fehler beim Erkennen, ob Plesk installiert ist: {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com ist nicht verfügbar',
            description: 'Plesk-Distributionen können nicht abgerufen werden. Die Website autoinstall.plesk.com ist nicht verfügbar: {details}.',
        },
        pleskInstalled: {
            title: 'Plesk ist installiert',
            description: 'Mit dem Web Installer wird nur {productType} installiert. Auf <b>{host}</b> ist bereits die stabile Version von Plesk installiert. {productType, select, Plesk {Haben Sie etwas anderes erwartet? Lassen Sie es uns wissen, <surveyLink>indem Sie diese Frage beantworten</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'Upgrade verfügbar',
            description: 'Mit dem Web Installer wird nur {productType} installiert. Auf <b>{host}</b> ist Plesk bereits installiert. Dies ist nicht die aktuellste stabile Version. {productType, select,Plesk {Wollten Sie über den Plesk Web Installer ein Upgrade durchführen? Lassen Sie es uns wissen, <surveyLink>indem Sie diese Frage beantworten</surveyLink>.}other {}}',
        },
        unsupportedArch: {
            title: 'Nicht unterstützte Serverarchitektur',
            description: 'Sie versuchen, {productType} in einer Architektur zu installieren, die nicht unterstützt wird: {arch}.',
        },
        unsupportedOs: {
            title: 'Nicht unterstütztes Betriebssystem',
            description: 'Sie versuchen, {productType} auf einem Betriebssystem (Architektur) zu installieren, das nicht unterstützt wird: {os}. <supportedOsLink>Liste der unterstützten Betriebssysteme</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Nicht genug Festplattenplatz',
        },
        cpanelInstalled: {
            title: 'cPanel ist installiert',
            description: 'Mit dem Web Installer kann {productType} nur auf bereinigten Servern installiert werden. Auf <b>{host}</b> ist bereits cPanel installiert. {productType, select, Plesk {Im <migrationGuideLink>Migrationshandbuch</migrationGuideLink> finden Sie Details dazu, wie gehostete Websites zu Plesk übertragen werden können.} other {Haben Sie eine andere Funktionalität erwartet? Lassen Sie es uns wissen, <surveyLink>indem Sie diese Frage beantworten</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Status der Paketverwaltung überprüfen',
            description: 'Die Paketverwaltung ist ausgelastet oder reagiert nicht: {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Die Repository-Konfiguration für die Paketverwaltung ist fehlgeschlagen',
            description: 'Bei der Repository-Konfiguration für die Paketverwaltung ist ein unerwarteter Fehler aufgetreten: {details}.',
        },
        dbConfigurationFailed: {
            title: 'Datenbankkonfiguration fehlgeschlagen',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'Ihre Sicherheit ist vollkommen gewährleistet: WebPros speichert Ihren Benutzernamen und Ihr Passwort nach Abschluss der Installation nicht.',
            p2: 'Sie können {productType} <a>auf unterstützten Linux-basierten Betriebssystemen installieren</a>.',
        },
        hostLabel: 'Server IP-Adresse oder Hostname',
        portLabel: 'Port',
        loginLabel: 'SSH-Anmeldung',
        installationMethodLabel: 'Verbinden Sie sich mit dem Server über',
        sshPasswordLabel: 'SSH-Passwort',
        sshKeyLabel: 'Privater SSH-Schlüssel',
        licenseTypeLabel: 'Lizenztyp auswählen',
        trialLicenseLabel: 'Mit der Lizenz einer voll funktionsfähigen Testversion fortfahren',
        trialLicenseDescription: 'Um eine Testlizenz auszustellen, verwendet und verarbeitet WebPros die zur Verfügung gestellten Informationen in seinen internen Systemen gemäß der <privacyPolicyLink>WebPros Datenschutzrichtlinien</privacyPolicyLink>.',
        paidLicenseLabel: 'Mit einer bezahlten Lizenz fortfahren',
        paidLicenseDescription: 'Wenn Sie bereits eine {productType} Lizenz erworben haben, fügen Sie hier den Aktivierungscode ein, den Sie erhalten haben. Anderenfalls können Sie in unserem <onlineStoreLink>Online-Shop</onlineStoreLink> eine neue Lizenz erwerben.',
        installOsUpdatesLabel: 'Installieren Sie Betriebssystem-Updates vor der Installation von {productType}',
        installOsUpdatesDescription: 'Wenn Sie diese Option auswählen, wird die Installation möglicherweise erheblich verlangsamt oder schlägt fehl.',
        notice: 'Die Installation dauert in der Regel 15 Minuten.',
        techDomainDescription: 'Ihr Server wird mit dem Namen <b>{techDomain}</b> hinzugefügt.',
        serverConnectionSection: 'Serververbindung',
        licenseSection: 'Lizenz',
        advancedSection: 'Erweiterte Optionen',
        submitButton: 'Weiter',
    },
    ServerInstallationPage: {
        submitButton: 'Weiter',
        panelTitle: '{productType} automatisch auf einem Linux-Server installieren',
        licenceLabel: 'Testlizenz',
        installationWillTake: 'Die Installation dauert in der Regel 15 Minuten',
        helpUsImprove: 'Helfen Sie uns, den {productType}-Web Installer zu verbessern',
        takeOurSurvey: 'Nehmen Sie an unserer Umfrage teil',
        links: {
            manualInstallationInstructions: 'Schritt-für-Schritt-Installationsanleitung',
            softwareRequirements: {
                plesk: 'Software-Anforderungen',
                cpanel: 'System-Anforderungen',
            },
            versionsAndComponents: 'Versionen und Komponenten',
            knownIssues: 'Bekannte Probleme',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'Ihre E-Mail-Adresse',
        legalAgreementTitle: 'Ich akzeptiere die <termsOfUseLink>Nutzungsbedingungen</termsOfUseLink> und <privacyPolicyLink>Datenschutzrichtlinien</privacyPolicyLink> und bestätige hiermit, die Plesk <dpaLink>Vereinbarung zur Datenverarbeitung</dpaLink> zu unterschreiben',
        emailHint: 'Wenn Sie bereits ein WebPros-Konto haben, sollten Sie diese E-Mail-Adresse nutzen. Wenn nicht, wird ein Konto für Sie erstellt werden.',
        serversMonitoring: 'Überwachen Sie kostenlos Server-Verfügbarkeit, -Zustand und -Leistung für einen Server und fünf Websites',
        loginNoCredentials: 'Erstellen Sie Ihr WebPros-Konto für einen schnellen Zugriff auf alle Dienste des Unternehmens mit nur einem Klick',
        login: 'Melden Sie sich bei Ihrer {productType}-Installation an, ohne Ihre Anmeldedaten einzugeben',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'Vorname',
        lastName: 'Nachname',
        submitButton: 'Installation starten',
        marketingConsent: {
            title: 'WebPros darf mir Newsletter und individuelle Angebote gemäß der <a>Datenschutzrichtlinie</a> von WebPros senden.',
            text: 'Ich kann diese Zustimmung widerrufen, indem ich auf den Link in einer erhaltenen Mitteilung klicke',
        },
    },
    LightsailInstallation: {
        title: 'Automatisch auf einem Cloud-Server installieren',
        awsAccessKey: 'Zugangskennungs-ID (Key)',
        awsSecretKey: 'Geheime Zugangskennung (Key)',
        region: 'Region',
        bundleTitle: 'Instanz-Plan',
        publicKeyTitle: 'Öffentliche SSH-Erkennung (Key)',
        instanceName: 'Instanzname',
        submitButton: 'Weiter',
        BundleSelect: {
            price: '${value} pro Monat', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Speicherplatz {value} GB',
            storage: 'Speicher {value} GB',
            transfer: 'Transfer {value} GB',
            descriptions: {
                micro: 'Am besten geeignet, um Plesk auszuprobieren.',
                small: 'Für eine oder mehrere persönliche Websites.',
                medium: 'Für Web-Profis oder Web-Agenturen, die an einer kleinen Anzahl von Websites arbeiten.',
                large: 'Für große Webagenturen oder kleine Hoster, die sich auf mehrere Websites mit geringem Traffic oder ein paar Websites mit hohem Traffic spezialisieren.',
                xlarge: 'Für Hoster, die mehrere Websites mit viel Traffic und hoher Auslastung verwalten.',
                '2xlarge': 'Für besonders ambitionierte Projekte.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'Um sich in Ihrem cPanel-Konto anzumelden, klicken Sie auf "Bei cPanel anmelden". <b>HINWEIS:</b> Die Schaltfläche wird nur ein einziges Mal funktionieren!',
        loginButton: 'Bei cPanel anmelden',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'Plesk automatische Installation FAQ',
            versionsAndComponents: 'Versionen und Komponenten',
            knownIssues: 'Bekannte Probleme',
            whatVersionIsInstalledTitle: 'Welche Plesk-Version wird im automatischen Modus installiert?',
            whatVersionIsInstalledText: 'Die neuste stabile Version von Plesk mitsamt allen zur Zeit verfügbaren Aktualisierungen. Um eine andere Plesk-Version zu installieren, folgen Sie bitte der <link>Installationsanleitung</link>.',
            whatComponentsAreInstalledTitle: 'Welche Komponenten werden im automatischen Modus installiert?',
            whatComponentsAreInstalledText: 'Die "empfohlen Voreinstellung" wird installiert. Diese beinhaltet alle benötigten Dienste zum Hosten von Websites (Web, DNS, FTP und Mail) und kommt mit den beliebtesten Komponenten wie WordPress Toolkit, Advisor, Let’s Encrypt, etc.',
            canISelectComponentsTitle: 'Kann ich auswählen, welche Plesk-Komponenten installiert werden sollen?',
            canISelectComponentsText: 'Zurzeit nicht. Wir sind uns über diese Einschränkung im Klaren und haben vor, diese Funktion in zukünftigen Aktualisierungen hinzuzufügen. Im Moment müssen die gewünschten Komponenten hinzugefügt oder entfernt werden, nachdem der Installationsprozess abgeschlossen wurde.',
            doesInstalledPleskHaveLicenseTitle: 'Gibt es für die Plesk-Installation eine Lizenz?',
            doesInstalledPleskHaveLicenseText: 'Sie können eine 14-tägige Probeversion der Web Pro Edition ausprobieren, sobald Sie sich zum ersten Mal bei Plesk anmelden, wenn Sie dies nicht zuvor schon getan haben. Sie können auch Ihre eigene erworbene Lizenz nutzen.',
            supportedOSsTitle: 'Welche Betriebssysteme werden im automatischen Modus unterstützt? Kann ich Plesk unter Windows installieren?',
            supportedOSsText: 'Alle <a>von Plesk unterstützten Linux-basierten Betriebssysteme</a>. Um Plesk unter Windows zu installieren, folgen Sie bitte der <link>Installationsanleitung</link>.',
            usePrivateKeyTitle: 'Kann ich einen verschlüsselten, privaten SSH-Schlüssel verwenden?',
            usePrivateKeyText: 'Zurzeit nicht. Wir sind uns über diese Einschränkung im Klaren und haben vor, diese Funktion in zukünftigen Aktualisierungen hinzuzufügen.',
            isItSafeToSubmitPasswordTitle: 'Ist es sicher, ein Passwort oder einen privaten SSH-Schlüssel bei der Installation von Plesk im automatischen Modus einzugeben?',
            isItSafeToSubmitPasswordText: 'Ja. Die Anmeldedaten werden nur für den Verbindungsaufbau zum Server und zur Installation von Plesk verwendet und werden nicht gespeichert. Alle übertragenen Daten werden sicher über verschlüsselte SSH- und HTTPS-Kanäle übertragen. Für ein Maximum an Sicherheit sollten Sie darüber nachdenken, Ihre Server-Anmeldedaten nach der Installation zu ändern.',
            helpUsMakeWebInstallerBetter: 'Helfen Sie uns, den Plesk Web Installer für alle ein bisschen besser zu machen, indem Sie an einer <a>kurzen Umfrage teilnehmen.</a>',
        },
        cpanel: {
            header: 'cPanel automatische Installation FAQ',
            versionsAndComponents: 'Versionen und Komponenten',
            knownIssues: 'Bekannte Probleme',
            whatVersionIsInstalledTitle: 'Welche cPanel-Version wird im automatischen Modus installiert?',
            whatVersionIsInstalledText: 'Die neuste Version von cPanel mitsamt allen zur Zeit verfügbaren Aktualisierungen. Um eine andere cPanel-Version zu installieren, folgen Sie bitte der <link>Installationsanleitung</link>.',
            canICustomizeParametersOfInstallationTitle: 'Kann ich die Installations-Parameter anpassen?',
            canICustomizeParametersOfInstallationText: 'Zurzeit gibt es keine Möglichkeit zur Anpassung der Installation mit dem Web Installer. Um Ihre Installation anzupassen, folgen Sie bitte unserer <link>Dokumentation</link>.',
            doesInstalledCpanelHaveLicenseTitle: 'Gibt es für die cPanel-Installation eine Lizenz?',
            doesInstalledCpanelHaveLicenseText: 'Wenn Sie eine neue cPanel & WHM-Instanz auf einem Server installieren, haben Sie die Möglichkeit, eine 15-tägige Probeversion zu testen. Folgen Sie der Anleitung in <link>diesem Guide</link>, um sich für eine Probeversion anzumelden. Sie können auch Ihre eigene erworbene Lizenz verwenden.',
            supportedOSsTitle: 'Welche Betriebssysteme werden im automatischen Modus unterstützt?',
            supportedOSsText: 'Alle <link>von cPanel unterstützten</link> Linux-basierten Betriebssysteme.',
            usePrivateKeyTitle: 'Kann ich einen verschlüsselten, privaten SSH-Schlüssel verwenden?',
            usePrivateKeyText: 'Zurzeit nicht. Wir sind uns über diese Einschränkung im Klaren und haben vor, diese Funktion in zukünftigen Aktualisierungen hinzuzufügen.',
            isItSafeToSubmitPasswordTitle: 'Ist es sicher, ein Passwort oder einen privaten SSH-Schlüssel bei der Installation von cPanel im automatischen Modus einzugeben?',
            isItSafeToSubmitPasswordText: 'Ja. Die Anmeldedaten werden nur für den Verbindungsaufbau zum Server und zur Installation von cPanel verwendet und werden nicht gespeichert. Alle übertragenen Daten werden sicher über verschlüsselte SSH- und HTTPS-Kanäle übertragen. Für ein Maximum an Sicherheit sollten Sie darüber nachdenken, Ihre Server-Anmeldedaten nach der Installation zu ändern.',
            helpUsMakeWebInstallerBetter: 'Helfen Sie uns, den cPanel Web Installer für alle ein bisschen besser zu machen, indem Sie an einer <a>kurzen Umfrage teilnehmen.</a>',
        },
    },
};