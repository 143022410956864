// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import SubscriptionSelector from '@platform360/licenses/web/units/extensions/components/extensionDetails/SubscriptionSelector';
import selectSubscription from '@platform360/licenses/web/units/extensions/actions/buyExtension/selectSubscription';
import { StoreState } from '@platform360/licenses/web/store/types';
import calculate from '@platform360/licenses/web/units/extensions/actions/buyExtension/calculate';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';

const mstp = (state: StoreState) => {
    const { subscriptions, selectedSubscription: selected, period } = buyExtensionSelector(state);

    return {
        subscriptions,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selected: selected || subscriptions[0]!.subscriptionId,
        period,
    };
};

const mdtp = (dispatch: Dispatch) => {
    const onChange = (subscriptionId: string | number | undefined) => {
        dispatch(selectSubscription(String(subscriptionId)));
        //  @ts-expect-error TS2345
        dispatch(calculate());
    };

    return {
        onChange,
    };
};

export default connect(mstp, mdtp)(SubscriptionSelector);
