// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'Вместо ожидаемого секретного ключа обнаружен открытый ключ.',
        passphrasePrivateKeyError: 'Укажите ключ, не защищенный парольной фразой.',
        unsupportedPrivateKeyError: 'Этот тип ключа не поддерживается.',
        invalidPrivateKeyError: 'Пожалуйста, убедитесь, что используете действительный секретный ключ SSH.',
        emptyPrivateKeyError: 'Указанный секретный ключ SSH недействителен, так как он пуст.',
        tooBigPrivateKeyError: 'Предоставьте файл ключа размером менее 30 КБ.',
    },
    Installation: {
        startAnotherInstallation: 'Начать еще одну установку',
        steps: {
            running: {
                title: 'Plesk устанавливается',
                description: 'Установка Plesk на <b>{host}</b>',
            },
            error: {
                title: 'Ошибка установки',
            },
            completed: {
                title: 'Установка завершена',
                pleskHostText: 'Plesk установлен на {host}.',
                goToPleskText: 'Нажмите "Перейти в Plesk", чтобы войти. После небольшой начальной настройки вы сможете начать пользоваться Plesk.',
                goToPleskButton: 'Перейти в Plesk',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Сервер отключен',
            description: 'SSH-подключение к серверу потеряно.',
        },
        connectFail: {
            title: 'Не удалось подключиться к серверу',
            description: '<p>Невозможно подключиться к серверу: {details}.</p><p>Если сервер использует персональный порт SSH, укажите его, добавив двоеточие и номер порта после имени хоста или IP-адреса (например, example.com:1024 или 192.0.2.0:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk уже установлен',
            description: 'При проверке установки Plesk произошла ошибка: {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com недоступен',
            description: 'Невозможно извлечь расширения Plesk. Сайт autoinstall.plesk.com недоступен: {details}.',
        },
        pleskInstalled: {
            title: 'Plesk установлен',
            description: 'Онлайн-установщик может установить только {productType}, а на <b>{host}</b> стабильная версия Plesk уже установлена. {productType, select, Plesk {Нужно было что-то другое? Дайте нам знать: <surveyLink>для этого нужно лишь ответить на 1 вопрос</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'Доступно повышение',
            description: 'Онлайн-установщик может только установить {productType}. На <b>{host}</b> уже установлен Plesk, и это не последняя стабильная версия. {productType, select, Plesk {Вы ожидали, что онлайн-установщик Plesk повысит версию? Дайте нам знать: <surveyLink>для этого нужно лишь ответить на 1 вопрос</surveyLink>.} other {}}',
        },
        unsupportedArch: {
            title: 'Неподдерживаемая архитектура сервера',
            description: 'Вы пытаетесь установить {productType} на архитектуре, которая не поддерживается: {arch}.',
        },
        unsupportedOs: {
            title: 'Неподдерживаемая операционная система',
            description: 'Вы пытаетесь установить {productType} на операционной системе или архитектуре, которая не поддерживается: {os}. <supportedOsLink>Проверьте список поддерживаемых операционных систем</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Недостаточно пространства на диске',
        },
        cpanelInstalled: {
            title: 'cPanel установлен',
            description: 'Веб-установщик может установить {productType} только на чистый сервер, а на <b>{host}</b> уже есть cPanel. {productType, select, Plesk {Узнайте, как перенести хостинг сайтов на Plesk, в <migrationGuideLink>Руководстве по миграции</migrationGuideLink>.} other {Вы ожидали от онлайн-установщика чего-то другого? Дайте нам знать: <surveyLink>для этого нужно лишь ответить на 1 вопрос</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Проверить статус менеджера пакетов',
            description: 'Менеджер пакетов занят или не отвечает: {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Не удалось настроить хранилище менеджера пакетов',
            description: 'При настройке хранилища менеджера пакетов произошла непредвиденная ошибка: {details}.',
        },
        dbConfigurationFailed: {
            title: 'Не удалось выполнить конфигурацию базы данных',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'Вы в полной безопасности: WebPros не сохраняет ваш логин и пароль после завершения установки.',
            p2: 'Вы можете установить {productType} на <a>любую поддерживаемую ОС на базе Linux</a>.',
        },
        hostLabel: 'IP-адрес сервера или имя хоста',
        portLabel: 'Порт',
        loginLabel: 'Имя пользователя SSH',
        installationMethodLabel: 'Подключиться к серверу с помощью',
        sshPasswordLabel: 'Пароль SSH',
        sshKeyLabel: 'Закрытый ключ SSH',
        licenseTypeLabel: 'Выберите тип лицензии',
        trialLicenseLabel: 'Продолжить с полнофункциональной пробной лицензией',
        trialLicenseDescription: 'Для выпуска пробной лицензии WebPros International GmbH будет использовать и обрабатывать предоставленную мной информацию в своей внутренней системе в соответствии с <privacyPolicyLink>Политикой конфиденциальности WebPros</privacyPolicyLink>.',
        paidLicenseLabel: 'Продолжить с платной лицензией',
        paidLicenseDescription: 'Если вы уже купили лицензию на {productType}, вставьте сюда полученный код активации. Если нет — вы можете купить новую лицензию в нашем <onlineStoreLink>интернет-магазине</onlineStoreLink>.',
        installOsUpdatesLabel: 'Установите обновления ОС перед началом установки {productType}',
        installOsUpdatesDescription: 'Выбор этой опции может значительно замедлить процесс установки или привести к сбою.',
        notice: 'Установка обычно занимает около 15 минут.',
        techDomainDescription: 'Ваш сервер будет добавлен под именем «‎<b>{techDomain}</b>»‎.',
        serverConnectionSection: 'Подключение сервера',
        licenseSection: 'Лицензия',
        advancedSection: 'Дополнительно',
        submitButton: 'Далее',
    },
    ServerInstallationPage: {
        submitButton: 'Далее',
        panelTitle: 'Установить {productType} автоматически на сервер Linux',
        licenceLabel: 'Пробная лицензия',
        installationWillTake: 'Установка обычно занимает около 15 минут',
        helpUsImprove: 'Помогите нам улучшить онлайн-установщик {productType}',
        takeOurSurvey: 'Пройти опрос',
        links: {
            manualInstallationInstructions: 'Инструкции по установке вручную',
            softwareRequirements: {
                plesk: 'Требования к ПО',
                cpanel: 'Системные требования',
            },
            versionsAndComponents: 'Версии и компоненты',
            knownIssues: 'Известные проблемы',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'Ваш адрес электронной почты',
        legalAgreementTitle: 'Я принимаю <termsOfUseLink>Условия использования</termsOfUseLink> и <privacyPolicyLink>Политику конфиденциальности</privacyPolicyLink> и настоящим соглашаюсь подписать <dpaLink>Соглашение на обработку данных</dpaLink> Plesk',
        emailHint: 'Если у вас уже есть учетная запись WebPros, используйте этот адрес электронной почты. В противном случае для вас будет создана новая учетная запись.',
        serversMonitoring: 'Отслеживайте доступность, состояние и производительность серверов бесплатно для 1 сервера и 5 сайтов',
        loginNoCredentials: 'Создайте учетную запись WebPros, чтобы получить легкий доступ ко всем службам компании в один клик.',
        login: 'Входите в свою установку {productType} без ввода учетных данных',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'Имя',
        lastName: 'Фамилия',
        submitButton: 'Начать установку',
        marketingConsent: {
            title: 'WebPros может создавать для меня информационные сообщения и индивидуальные предложения в соответствии с <a>Политикой конфиденциальности</a> WebPros.',
            text: 'Я могу отозвать это согласие, нажав на ссылку в любом полученном сообщении',
        },
    },
    LightsailInstallation: {
        title: 'Установить автоматически на облачный сервер',
        awsAccessKey: 'ID ключа доступа',
        awsSecretKey: 'Секретный ключ доступа',
        region: 'Регион',
        bundleTitle: 'План сервера',
        publicKeyTitle: 'Открытый ключ SSH',
        instanceName: 'Имя сервера',
        submitButton: 'Продолжить',
        BundleSelect: {
            price: '${value} в месяц', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Память: {value} Гб',
            storage: 'Хранилище: {value} Гб',
            transfer: 'Перенос: {value} Гб',
            descriptions: {
                micro: 'Лучший вариант для знакомства с Plesk.',
                small: 'Для одного или нескольких личных сайтов.',
                medium: 'Для сетевых профессионалов или агентств, которые работают с небольшим количеством сайтов.',
                large: 'Для больших агенств или небольших хоств, которые поддерживают множество сайтов с низким трафиком или несколько с высоким.',
                xlarge: 'Для хостов, которые поддерживают множество сайтов с высоким трафиком и большой нагрузкой.',
                '2xlarge': 'Для самых амбициозных проектов.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'Для входа в свою учетную запись cPanel нажмите кнопку "Войти в cPanel". <b>ПРИМЕЧАНИЕ:</b> эта кнопка сработает только один раз!',
        loginButton: 'Войти в cPanel',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'Вопросы и ответы по автоматической установке Plesk',
            versionsAndComponents: 'Версии и компоненты',
            knownIssues: 'Известные проблемы',
            whatVersionIsInstalledTitle: 'Какая версия Plesk устанавливается в автоматическом режиме?',
            whatVersionIsInstalledText: 'Самая последняя стабильная версия Plesk со всеми обновлениями, доступными на текущий момент. Чтобы установить другую версию Plesk, следуйте <link>инструкциям по установке</link>.',
            whatComponentsAreInstalledTitle: 'Какие компоненты устанавливаются в автоматическом режиме?',
            whatComponentsAreInstalledText: '"Рекомендуемый пресет", который включает все службы, необходимые для хостинга сайтов (Web, DNS, FTP и почта), а также самые популярные компоненты и расширения, такие как WordPress Toolkit, Advisor, Let’s Encrypt и т. д.',
            canISelectComponentsTitle: 'Можно ли выбрать компоненты Plesk для установки?',
            canISelectComponentsText: 'В настоящее время это невозможно. Мы планируем устранить данное ограничение плана в будущем. Пока же, как обходное решение, вы можете добавить или удалить требуемые компоненты после завершения процесса установки.',
            doesInstalledPleskHaveLicenseTitle: 'Включает ли установка Plesk лицензию?',
            doesInstalledPleskHaveLicenseText: 'Вы можете запросить пробную 14-дневную лицензию на издание Web Pro сразу после первого входа в Plesk, если вы этого еще не делали ранее. Вы также можете использовать собственную купленную лицензию.',
            supportedOSsTitle: 'Какие ОС поддерживаются в автоматическом режиме? Можно ли установить Plesk для Windows?',
            supportedOSsText: '<a>Plesk поддерживает все ОС на базе Linux</a>. Чтобы установить Plesk для Windows, следуйте <link>инструкциям по установке</link>.',
            usePrivateKeyTitle: 'Можно ли использовать зашифрованный закрытый ключ SSH?',
            usePrivateKeyText: 'В настоящее время это невозможно. Мы планируем устранить данное ограничение плана в будущем.',
            isItSafeToSubmitPasswordTitle: 'Безопасна ли передача пароля и закрытого ключа SSH при установке Plesk в автоматическом режиме?',
            isItSafeToSubmitPasswordText: 'Да. Эти учетные данные не сохраняются. Они используются только для подключения к серверу и установки Plesk. Все данные безопасно передаются по зашифрованным каналам SSH и HTTPS. Для обеспечения максимальной безопасности вы можете после завершения установки изменить учетные данные для доступа к серверу.',
            helpUsMakeWebInstallerBetter: 'Помогите нам сделать онлайн-установщик Plesk лучше для всех, <a>пройдя короткий опрос</a>.',
        },
        cpanel: {
            header: 'Вопросы и ответы по автоматической установке cPanel',
            versionsAndComponents: 'Версии и компоненты',
            knownIssues: 'Известные проблемы',
            whatVersionIsInstalledTitle: 'Какая версия cPanel устанавливается в автоматическом режиме?',
            whatVersionIsInstalledText: 'Самая последняя версия cPanel со всеми обновлениями, доступными на текущий момент. Чтобы установить другую версию cPanel, следуйте <link>инструкциям по установке</link>.',
            canICustomizeParametersOfInstallationTitle: 'Можно ли настроить параметры установки?',
            canICustomizeParametersOfInstallationText: 'В настоящее время невозможно настроить установку с помощью онлайн-установщика. Чтобы изменить параметры установки, следуйте инструкциям в <link>нашей документации</link>.',
            doesInstalledCpanelHaveLicenseTitle: 'Включает ли установка cPanel лицензию?',
            doesInstalledCpanelHaveLicenseText: 'Если вы устанавливаете новый экземпляр cPanel и WHM на сервер, вы можете воспользоваться 15-дневной пробной лицензией. Следуйте инструкциям в <link>этом руководстве</link>, чтобы получить пробную лицензию. Вы также можете использовать собственную купленную лицензию.',
            supportedOSsTitle: 'Какие ОС поддерживаются в автоматическом режиме?',
            supportedOSsText: '<link>cPanel поддерживает</link> все ОС на базе Linux.',
            usePrivateKeyTitle: 'Можно ли использовать зашифрованный закрытый ключ SSH?',
            usePrivateKeyText: 'В настоящее время это невозможно. Мы планируем устранить данное ограничение плана в будущем.',
            isItSafeToSubmitPasswordTitle: 'Безопасна ли передача пароля и закрытого ключа SSH при установке cPanel в автоматическом режиме?',
            isItSafeToSubmitPasswordText: 'Да. Эти учетные данные не сохраняются. Они используются только для подключения к серверу и установки cPanel. Все данные безопасно передаются по зашифрованным каналам SSH и HTTPS. Для обеспечения максимальной безопасности вы можете после завершения установки изменить учетные данные для доступа к серверу.',
            helpUsMakeWebInstallerBetter: 'Помогите нам сделать онлайн-установщик cPanel лучше для всех, <a>пройдя короткий опрос</a>.',
        },
    },
};