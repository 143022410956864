// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery, useQueryClient } from '@tanstack/react-query';
import monitoring from '@platform360/monitoring/web/api/monitoring';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseServerQueryVariables = {
    serverGuid: string;
};

export type UseServerQueryData = PromiseValue<ReturnType<typeof monitoring.server>>;

const useServerQuery = createQuery<UseServerQueryVariables, UseServerQueryData>({
    queryName: 'monitoring/useServerQuery',
    fetcher: monitoring.server,
    useQuery: (options) => {
        const queryClient = useQueryClient();

        return useQuery({
            ...options,
            queryFn: async (params) => {
                try {
                    return await options.queryFn(params);
                } catch (e) {
                    if (e.response?.status === 404) {
                        const query = queryClient.getQueryCache().find({
                            queryKey: options.queryKey,
                        });

                        if (query) {
                            query.setState({ ...query.state, data: undefined });
                        }
                    }

                    throw e;
                }
            },
            meta: {
                defaultErrorHandler: false,
            },
        });
    },
});

export default useServerQuery;
