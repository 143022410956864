// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './Sidebar.module.less';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';

type SidebarProps = {
    children: ReactNode;
    logo: ReactNode;
};

const Sidebar = ({ children, logo }: SidebarProps) => (
    <div className={styles.sidebar}>
        <div className={styles.sidebarOverlay}>
            <div className={styles.sidebarI}>
                <div className={styles.sidebarHead}>
                    {logo}
                    <LocaleSwitcher />
                </div>
                <div className={styles.sidebarBody}>{children}</div>
            </div>
        </div>
    </div>
);

export default Sidebar;
