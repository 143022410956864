// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext, useEffect } from 'react';
import { WebsocketContext } from './components/WebsocketProvider';
import { EventsMap } from '@socket.io/component-emitter';
import { Socket } from 'socket.io-client';
import { ReservedOrUserEventNames } from 'socket.io/dist/typed-events';
import { SocketReservedEventsMap } from 'socket.io/dist/socket';

type ExtendedSocketEvents = SocketReservedEventsMap & {
    connect: () => void;
};

export type Event<ListenEvents extends EventsMap> = {
    name: ReservedOrUserEventNames<ExtendedSocketEvents, ListenEvents>;
    handler: ListenEvents[keyof ListenEvents];
};

/**
 * Attaches event listeners to a WebSocket socket based on the provided events array.
 *
 * @param {Event[]} events - An array of events to attach listeners to.
 *
 * @example
 * const events = [
 *   {
 *     name: 'message',
 *     handler: (data) => {
 *       console.log(data);
 *     },
 *   },
 *   {
 *     name: 'error',
 *     handler: (err) => {
 *       console.error(err);
 *     },
 *   },
 * ];
 *
 * useWebsocket(events);
 */
const useWebsocket = <ListenEvents extends EventsMap>(events: Event<ListenEvents>[]) => {
    const socket: Socket<ListenEvents> | undefined = useContext(WebsocketContext);

    useEffect(() => {
        if (socket) {
            for (const { name, handler } of events) {
                socket.on(name, handler);
            }
        }

        return () => {
            if (socket) {
                for (const { name, handler } of events) {
                    socket.off(name, handler);
                }
            }
        };
    }, [events, socket]);
};

export default useWebsocket;
