// Copyright 2024. WebPros International GmbH. All rights reserved.

import { StoreState } from '@platform360/licenses/web/store/types';
import { FullInitialState as BuyExtensionInitialState } from '../reducer/buyExtension/initialState';
import { STATE_KEY } from '../../../index';

const buyExtensionSelector = (state: StoreState): BuyExtensionInitialState =>
    state[STATE_KEY].buyExtension;

export default buyExtensionSelector;
