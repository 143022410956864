// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ChangeableName from './ChangeableName';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useUpdateCurrentUserMutation } from '@platform360/accounts/web/mutations';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const ChangeableNameContainer = () => {
    const translate = useTranslate('accounts.profile.ChangeableName');
    const translateValidationErrors = useTranslateValidationErrors();
    const currentUser = useCurrentUser();
    const { successToast } = useToaster();

    const [showForm, setShowForm] = useState<boolean>(false);
    const {
        isPending: isNameChanging,
        mutate: handleNameChange,
        error,
        reset,
    } = useUpdateCurrentUserMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
            setShowForm(false);
        },
    });
    const onEdit = () => setShowForm(true);
    const onCancel = () => {
        reset();
        setShowForm(false);
    };

    return (
        <ChangeableName
            name={currentUser.name}
            lastName={currentUser.lastName}
            firstName={currentUser.firstName}
            onEdit={onEdit}
            onCancel={onCancel}
            onSave={handleNameChange}
            isLoading={isNameChanging}
            showForm={showForm}
            errors={translateValidationErrors(getValidationErrors(error))}
        />
    );
};

export default ChangeableNameContainer;
