// Copyright 2024. WebPros International GmbH. All rights reserved.

import { LocaleCode, Locales } from '@platform360/libs/shared-web/locale/types';

export const detectLocaleCode = (locales: Locales, fallbackLocaleCode: LocaleCode): LocaleCode => {
    const language = navigator.languages?.[0] || navigator.language || navigator.userLanguage;
    const [localeCode] = language.toLowerCase().split(/[_-]+/);

    if (localeCode && locales[localeCode]) {
        return localeCode;
    }

    return fallbackLocaleCode;
};
