// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import getButtonLink from '@platform360/licenses/web/units/buy360MonitoringButton/helpers/getButtonLink';
import { Link } from 'react-router-dom';

export type Buy360MonitoringButtonProps = {
    monitoringAccount: string | undefined;
    has360Monitoring: boolean;
    onClick?: () => void;
};

const Buy360MonitoringButton = ({
    monitoringAccount = undefined,
    has360Monitoring = false,
    onClick,
}: Buy360MonitoringButtonProps) => {
    const translate = useTranslate();

    if (has360Monitoring) {
        return null;
    }

    const Component = monitoringAccount ? 'a' : Link;
    const props = {
        target: '_blank',
        [monitoringAccount ? 'href' : 'to']: getButtonLink(monitoringAccount),
        onClick,
    };

    return (
        <Button component={Component} {...props} data-type={'buy360Monitoring--button'}>
            {translate('Keys.Licenses.MyLicenses.buyButtons.monitoring')}
        </Button>
    );
};

export default Buy360MonitoringButton;
