// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'Инструменты 360',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Перечень серверов',
            description: 'Все зарегистрированные серверы в одном месте с одним набором учетных данных',
        },
        licenseManagement: {
            title: 'Управление лицензиями',
            description: 'Покупайте все лицензии на Plesk, его расширения и инструменты 360, а также управляйте ими',
        },
        feedback: {
            title: 'Оставьте отзыв',
            description: 'Это поможет нам в дальнейшей разработке',
        },
        websiteInventory: {
            title: 'Перечень сайтов',
            description: 'Просмотр и управление всеми вашими сайтами',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Информация о ваших серверах и сайтах в одной панели',
            description: 'Централизованное решение для отслеживания производительности и предотвращения простоев с настраиваемыми информационными панелями, персонализированными оповещениями, ежеминутными проверками, сетевой историей и т.д.',
            primaryButton: 'Попробуйте бесплатно в течение 14 дней',
            secondaryButton: 'Подробнее',
        },
        licenses: {
            title: 'Управляйте лицензиями',
            description: 'Покупайте, настраивайте и отменяйте лицензии на Plesk, его расширения и инструменты 360',
            primaryButton: 'Вперед',
            secondaryButton: 'Подробнее',
        },
        serverInventory: {
            title: 'Все зарегистрированные серверы в одном месте с одним набором учетных данных',
            description: 'Подключите все свои серверы Plesk к Переченю серверов, чтобы получить полный список своих сайтов и доступ к каждому из них одним кликом',
            primaryButton: 'Подключить серверы',
            secondaryButton: 'Подробнее',
        },
        websiteInventory: {
            title: 'Все ваши сайты и домены в одном списке',
            description: 'Просматривайте, ищите, фильтруйте свои сайты и управляйте ими одним кликом',
            primaryButton: 'Смотреть сайты',
            secondaryButton: 'Подробнее',
        },
    },
};