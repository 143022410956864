// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'このメールアドレスは既に登録されています。',
        linkEmailMinimalInterval: 'リンクされたメールアクションはあまり頻繁に送信できません。',
        linkEmailUnknownError: 'エラーが発生しました。インターネットに接続されていることを確認するか、後でやり直してください。',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'アクティブ化のキャンセルを確定',
                description: '{product} ライセンスのアクティブ化をキャンセルしようとしています。アクティベーションリンクを再びフォローして、いつでもライセンスのアクティブ化を進められます。別のアカウント用に購入したライセンスの場合、そのアカウントにログインしてアクティベーションリンクをクリックしてください。',
                cancelButton: 'キャンセル',
                continueButton: 'アクティブ化を続行',
            },
            MonitoringActivateLicense: {
                title: 'Monitoring ライセンスのアクティブ化を確認',
                description: '360 アカウント <mailto>{userEmail}</mailto> 用の <b>{product}</b> ライセンスのアクティブ化を確認してください。このライセンスは 1 つの 360 アカウントに対してのみアクティブ化されます。',
                fixableErrorDescription: 'Monitoring アカウントのアクティブ化中に問題が発生しました。やり直すか、サポートリクエストを送信してください。',
                nonFixableErrorDescription: 'ライセンスのアクティブ化中に問題が発生しました。ライセンスプロバイダに連絡するか、サポートリクエストを送信してください。',
                loaderText: '360 Monitoring アカウントを構成中です',
                redirectText: '360 Monitoring にリダイレクトしています',
                confirmButton: '確認',
                cancelButton: 'キャンセル',
                retryButton: '再試行',
                gotItButton: '了解しました',
                errors: {
                    linkError: 'アクティベーションリンクが無効です。',
                    unknownError: '内部アクティベーションサービスエラー。',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'ライセンスのアクティブ化エラー',
            description: 'アクティベーションリンクが正しくありません。ライセンスプロバイダに連絡するか、サポートリクエストを送信してください。',
            gotIt: '了解しました',
        },
    },
};