// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './ImpersonationWidget.module.css';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useState } from 'react';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { Button, Item, Status, Toolbar, ToolbarExpander } from '@plesk/ui-library';
import Label from './Label';

export const ImpersonationWidget = ({ ...props }) => {
    const translate = useTranslate('admin-panel.Layout.Widgets.ImpersonationWidget');
    const { successToast } = useToaster();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { impersonation, depersonate } = useCurrentUser();

    const handleDepersonate = async () => {
        try {
            setIsLoading(true);

            await depersonate();

            successToast(translate('successToast'));
        } finally {
            setIsLoading(false);
        }
    };

    const renderCard = () => {
        if (!impersonation) {
            return translate('depersonated');
        }

        const { auth0Id, teamGuid } = impersonation;

        return (
            <div className={styles.cardInner}>
                <Label title={translate('auth0Id')} value={auth0Id} />
                <Label title={translate('teamGuid')} value={teamGuid} />
            </div>
        );
    };

    return (
        <Item
            view="card"
            title={<b>{translate('title')}</b>}
            footer={
                <Toolbar>
                    <Status intent={impersonation ? 'success' : 'inactive'} compact>
                        {impersonation ? translate('active') : translate('inactive')}
                    </Status>
                    <ToolbarExpander />
                    {impersonation && (
                        <Button
                            icon="arrow-right-out"
                            onClick={handleDepersonate}
                            state={isLoading ? 'loading' : undefined}
                            ghost
                        >
                            {translate('depersonateButton')}
                        </Button>
                    )}
                </Toolbar>
            }
            {...props}
        >
            {renderCard()}
        </Item>
    );
};
