// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNotification } from '@platform360/security-dashboard/web/api/notifications';
import { useNotificationsQuery } from '@platform360/security-dashboard/web/queries';

const useDeleteNotificationsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteNotification,
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: useNotificationsQuery.getQueryKey() });
            useNotificationsQuery.setQueryData(
                queryClient,
                useNotificationsQuery.getQueryKey(),
                (data) => {
                    if (!data) {
                        return data;
                    }

                    return data.filter((notification) => notification.id !== id);
                },
            );
        },
    });
};

export default useDeleteNotificationsMutation;
