// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getLabels } from '@platform360/security-dashboard/web/api/labels';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseLabelsQueryVariables = undefined;

export type UseLabelsQueryData = PromiseValue<ReturnType<typeof getLabels>>;

const useLabelsQuery = createQuery<UseLabelsQueryVariables, UseLabelsQueryData>({
    queryName: 'security-dashboard/useLabelsQuery',
    fetcher: getLabels,
    useQuery,
});

export default useLabelsQuery;
