// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Navigate, useParams } from 'react-router-dom';
import { licensesUri } from '@platform360/licenses/web/helpers';
import { useDispatch } from 'react-redux';
import { beginAdjustByKey } from '@platform360/licenses/web/actions';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

const RedirectToAdjustProperty = () => {
    const dispatch = useDispatch();

    const { licenseId } = useParams<{ licenseId: string }>();

    useEffectOnce(() => {
        dispatch(beginAdjustByKey(Number(licenseId)));
    });

    return <Navigate to={licensesUri} />;
};

export default RedirectToAdjustProperty;
