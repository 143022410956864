// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SET_ONLINE_STORE_DATA } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';

export type SetOnlineStoreDataAction = {
    type: typeof SET_ONLINE_STORE_DATA;
    payload: unknown;
};

const setOnlineStoreData = (data: CalculatedSubscriptionState): SetOnlineStoreDataAction => ({
    type: SET_ONLINE_STORE_DATA,
    payload: data,
});

export default setOnlineStoreData;
