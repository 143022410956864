// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { runUpdater } from '@platform360/security-dashboard/web/api/servers';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import { Server } from '@platform360/security-dashboard/web/types';

type ScannedServer = {
    serverId: number;
    hostname: string;
    executed?: boolean;
};

const useServerUpdaterMutation = () => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useServerUpdaterMutation');
    const { errorToast } = useToaster();

    return useMutation({
        mutationFn: async (servers: Server[]) => {
            try {
                await runUpdater(servers.map(({ id }) => id));
                return servers.map<ScannedServer>(
                    ({ id, displayTitle }): ScannedServer => ({
                        serverId: id,
                        hostname: displayTitle,
                        executed: true,
                    }),
                );
            } catch {
                return servers.map<ScannedServer>(({ id, displayTitle }) => ({
                    serverId: id,
                    hostname: displayTitle,
                    executed: false,
                }));
            }
        },
        meta: {
            defaultErrorHandler: false,
        },
        onSuccess: async (results: ScannedServer[]) => {
            const failedServers = results.filter(({ executed }) => !executed);
            const failedServersCount = failedServers.length;
            await Promise.all([
                queryClient.cancelQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
            ]);

            const [failedServer] = failedServers;
            if (failedServersCount > 1) {
                errorToast(
                    translate('notifySeveralServersFail', {
                        selectedServersCount: failedServersCount,
                    }),
                );
                return;
            }

            if (failedServer) {
                errorToast(
                    translate('notifySingleServerFail', {
                        selectedServerTitle: failedServer.hostname,
                    }),
                );
            }
        },
    });
};

export default useServerUpdaterMutation;
