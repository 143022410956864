// Copyright 2024. WebPros International GmbH. All rights reserved.

import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export const Cookies = () => {
    const {
        legalPage: { cookiesContent },
    } = useApplicationSettings();

    return <div>{cookiesContent}</div>;
};

export default Cookies;
