// Copyright 2024. WebPros International GmbH. All rights reserved.

const parseJson = (value: unknown): unknown | undefined => {
    if (typeof value !== 'string') {
        return undefined;
    }

    try {
        return JSON.parse(value);
    } catch {
        return undefined;
    }
};

export default parseJson;
