// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { IdsFilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getInstallations } from '@platform360/security-dashboard/web/api/installations';
import { SitesHealthFilter } from '@platform360/security-dashboard/web/types';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseInstallationsQueryVariables = {
    filter?: string;
    sitesHealthFilter?: SitesHealthFilter[];
    labelIdsFilter?: string[];
} & PaginatedQuery &
    IdsFilterQuery;

export type UseInstallationsQueryData = PromiseValue<ReturnType<typeof getInstallations>>;

const useInstallationsQuery = createQuery<
    UseInstallationsQueryVariables,
    UseInstallationsQueryData
>({
    queryName: 'security-dashboard/useInstallationsQuery',
    fetcher: getInstallations,
    useQuery: (options) =>
        useQuery({
            refetchOnWindowFocus: false,
            placeholderData: (prev) => prev,
            ...options,
        }),
});

export default useInstallationsQuery;
