// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './Widgets.module.less';
import UpdatesWidget from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets/UpdatesWidget/UpdatesWidget';
import PluginWidget from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets/PluginWidget';
import PatchesWidget, {
    PatchesWidgetProps,
} from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets/PatchesWidget/PatchesWidget';
import {
    InstallationComponent,
    InstallationComponents,
} from '@platform360/security-dashboard/shared/installation-component';

type WidgetsProps = {
    component: InstallationComponent;
    vulnerabilityId: string;
    installationsCount: number;
    installationsWithEnabledPluginCount: number;
    installationsWithPluginDisabledByMitigationCount: number;
    hasUpdate: boolean;
    onUpdate: (enableAfterUpdate: boolean) => void;
    onDisablePlugins: () => void;
    onEnablePlugins: () => void;
    isPluginsMitigationStarting: boolean;
    isUpdateStarting: boolean;
    updateInProgress: boolean;
    pluginsMitigationInProgress: boolean;
    installationsWithMitigationUpdateAvailable: number;
    installationsWithMitigationPatchAvailable: number;
    protectedInstallationsCount: number;
    handlePatch: PatchesWidgetProps['handlePatch'];
    isPatchStarting: boolean;
    patchInProgress: boolean;
};

export const Widgets = ({
    component,
    vulnerabilityId,
    installationsCount,
    installationsWithEnabledPluginCount,
    installationsWithPluginDisabledByMitigationCount,
    hasUpdate,
    onUpdate,
    onDisablePlugins,
    onEnablePlugins,
    isUpdateStarting,
    updateInProgress,
    pluginsMitigationInProgress,
    isPluginsMitigationStarting,
    installationsWithMitigationUpdateAvailable,
    installationsWithMitigationPatchAvailable,
    protectedInstallationsCount,
    handlePatch,
    isPatchStarting,
    patchInProgress,
}: WidgetsProps) => {
    const displayDisablePluginWidget = component === InstallationComponents.plugin;
    const displayPatchWidget = installationsWithMitigationPatchAvailable > 0;

    return (
        <div className={styles.widgets}>
            {displayDisablePluginWidget && (
                <PluginWidget
                    vulnerabilityId={vulnerabilityId}
                    installationsWithEnabledPluginCount={installationsWithEnabledPluginCount}
                    installationsWithPluginDisabledByMitigationCount={
                        installationsWithPluginDisabledByMitigationCount
                    }
                    isPluginsMitigationStarting={isPluginsMitigationStarting}
                    onDisablePlugins={onDisablePlugins}
                    onEnablePlugins={onEnablePlugins}
                    pluginsMitigationInProgress={pluginsMitigationInProgress}
                    installationsCount={installationsCount}
                />
            )}
            <UpdatesWidget
                component={component}
                vulnerabilityId={vulnerabilityId}
                installationsWithPluginDisabledByMitigationCount={
                    installationsWithPluginDisabledByMitigationCount
                }
                installationsCount={installationsWithMitigationUpdateAvailable}
                hasUpdate={hasUpdate}
                onUpdate={onUpdate}
                isUpdateStarting={isUpdateStarting}
                updateInProgress={updateInProgress}
            />
            {displayPatchWidget && (
                <PatchesWidget
                    vulnerabilityId={vulnerabilityId}
                    handlePatch={handlePatch}
                    installationsWithMitigationPatchAvailable={
                        installationsWithMitigationPatchAvailable
                    }
                    protectedInstallationsCount={protectedInstallationsCount}
                    isPatchStarting={isPatchStarting}
                    patchInProgress={patchInProgress}
                />
            )}
        </div>
    );
};

export default Widgets;
