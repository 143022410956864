// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Reducer } from 'react';
import { ToastProps, ToastPropsWithKey } from '@plesk/ui-library';
import omit from 'lodash/omit';

type Action =
    | { type: 'ADD_TOAST'; payload: ToastProps & { merge?: boolean } }
    | { type: 'REMOVE_TOAST'; payload: string }
    | { type: 'CLEAR_TOASTS' };

const randomKey = () => Math.random().toString(36).substring(2, 20);

const reducer: Reducer<ToastPropsWithKey[], Action> = (state, action) => {
    switch (action.type) {
        case 'ADD_TOAST':
            if (action.payload.merge && state.find((s) => s.message === action.payload.message)) {
                return state;
            }

            return [...state, { ...omit(action.payload, 'merge'), key: randomKey() }];
        case 'REMOVE_TOAST':
            return state.filter((toast) => toast.key !== action.payload);
        case 'CLEAR_TOASTS':
            return [];
        default:
            return state;
    }
};

export default reducer;
