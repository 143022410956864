// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import DialogLayout from '@platform360/libs/shared-web/components/DialogLayout';
import { ButtonProps, FormFieldCheckbox, Heading, Paragraph, Section } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import AddServerControlStatus from '@platform360/server-inventory/web/components/Servers/AddServerControlStatus';
import UnsecureConfirmation from '@platform360/server-inventory/web/components/Servers/UnsecureConfirmation';
import { ValidationError } from '@platform360/libs/shared-web/helpers/translateErrors';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import styles from './AddServerWithExternalRedirectDialog.module.css';
import { CertErrors } from '@platform360/libs/common/cert-error-codes';

export type DialogStep = 'form' | 'unsecureConfirmation';

export type FormValues = {
    enableIpRestriction: boolean;
    allowUnsecureConnection: boolean;
};

type AddServerWithExternalRedirectDialogProps = {
    onConfirm: (values: FormValues) => void;
    onChange: (key: string, value: unknown) => void;
    onDeny: () => void;
    step: DialogStep;
    setStep: (step: DialogStep) => void;
    values: FormValues;
    errors?: ValidationError[];
    isLoading: boolean;
    product: string;
    email: string;
    address: string;
};

const AddServerWithExternalRedirectDialog = ({
    step = 'form',
    setStep,
    values,
    email,
    address,
    product,
    onConfirm,
    onChange,
    onDeny,
    isLoading,
    errors = [],
}: AddServerWithExternalRedirectDialogProps) => {
    const translate = useTranslate('Servers.AddServerWithExternalRedirectDialog');
    const productsTranslationMap: Record<string, ReactNode> = {
        plesk: translate('products.plesk'),
        cpanel: translate('products.cpanel'),
    };
    const { publicIps } = useConfig();

    const addressError = errors.find((e) => e.path === 'address');

    const renderSubmitButton = (): ButtonProps => {
        if (step === 'unsecureConfirmation') {
            return {
                children: translate('buttons.confirmAnyway'),
                'data-type': 'confirm-anyway-button',
                intent: 'danger',
            };
        }

        if (addressError && values.allowUnsecureConnection) {
            switch (addressError.type) {
                case 'isHttps':
                case CertErrors.CertExpired:
                case CertErrors.CertAltNameInvalid: {
                    return {
                        children: translate('buttons.nextStep'),
                        'data-type': 'next-step-button',
                        intent: 'danger',
                        onClick: () => setStep('unsecureConfirmation'),
                    };
                }
            }
        }
        return {
            icon: 'check-mark',
            children: translate('buttons.confirm'),
            'data-type': 'confirm-button',
        };
    };

    return (
        <DialogLayout
            data-type="add-server-with-external-redirect-dialog"
            form={{
                state: isLoading ? 'submit' : undefined,
                values,
                // HACK: We should use cancelButton instead of applyButton, but
                //       when we set `closable` to false UI, Library hides cancel button as well
                applyButton: {
                    icon: 'remove',
                    onClick: onDeny,
                    intent: undefined,
                    children: translate('buttons.deny'),
                    'data-type': 'cancel-button',
                },
                submitButton: renderSubmitButton(),
                onFieldChange: onChange,
                onSubmit: onConfirm,
            }}
        >
            <div>
                <Heading level={2}>{translate('title')}</Heading>

                {step === 'form' && (
                    <>
                        <AddServerControlStatus error={addressError} />

                        <br />
                        <div>
                            <Paragraph>
                                {translate('description', {
                                    product: productsTranslationMap[product],
                                    email,
                                    address,
                                })}
                            </Paragraph>
                            <Section title={translate('accessTitle')}>
                                <Paragraph>{translate('accessMessage1', { address })}</Paragraph>
                                <Paragraph>
                                    {translate('accessMessage2', {
                                        email,
                                    })}
                                </Paragraph>
                            </Section>

                            <ul>
                                <li>{translate('accessItem1')}</li>
                                <li>{translate('accessItem2')}</li>
                                <li>{translate('accessItem3')}</li>
                                <li>{translate('accessItem4')}</li>
                            </ul>
                            {publicIps.length > 0 && (
                                <Section title={translate('enableIpRestrictionTitle')}>
                                    <FormFieldCheckbox
                                        name="enableIpRestriction"
                                        label={translate('fields.enableIpRestriction')}
                                    />
                                    <ul className={styles.enableIpRestrictionIpList}>
                                        {publicIps.map((ip) => (
                                            <li key={ip}>{ip}</li>
                                        ))}
                                    </ul>
                                </Section>
                            )}
                        </div>
                    </>
                )}

                {step === 'unsecureConfirmation' && <UnsecureConfirmation address={address} />}
            </div>
        </DialogLayout>
    );
};

export default AddServerWithExternalRedirectDialog;
