// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSearchParams, useParams } from 'react-router-dom';
import {
    isLoginTarget,
    LoginLinkParams,
    LoginTargets,
} from '@platform360/server-inventory/shared/login-link-params';
import { ServerLogin } from './ServerLogin';

const ServerLoginContainer = () => {
    const [searchParams] = useSearchParams();

    const { serverId: sId, target: targetCode } = useParams<{
        serverId: string;
        target: string;
    }>();
    const serverId = Number(sId);
    const target = isLoginTarget(targetCode) ? targetCode : LoginTargets.home;

    let variables: LoginLinkParams = { target: LoginTargets.home, serverId };
    switch (target) {
        case LoginTargets.home:
            break;
        case LoginTargets.updates:
            variables = { target, serverId };
            break;
        case LoginTargets.client:
            if (searchParams.has('clientId')) {
                variables = {
                    target,
                    serverId,
                    clientId: Number(searchParams.get('clientId')),
                };
            }
            break;
        case LoginTargets.domain:
            if (searchParams.has('domainId')) {
                variables = {
                    target,
                    serverId,
                    domainId: Number(searchParams.get('domainId')),
                };
            }
            break;
    }

    return <ServerLogin variables={variables} />;
};
export default ServerLoginContainer;
