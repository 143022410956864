// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import IgnoreCertificateErrorsButton from './IgnoreCertificateErrorsButton';
import RecheckConnectionButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/RecheckConnectionButton';
import ServerStatusMessage from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/ServerStatusMessage';
import { concatUrl } from '@platform360/libs/common/concat-url';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

export type CertificateErrorStatusMessageProps = {
    serverId: number;
    serverName: string;
    errorCode: string;
    errorDetail: string;
    isRecheckConnectionEnabled: boolean;
};

const CertificateErrorStatusMessage = ({
    serverId,
    serverName,
    errorCode,
    errorDetail,
    isRecheckConnectionEnabled = true,
    ...props
}: CertificateErrorStatusMessageProps) => {
    const translate = useTranslate('ViewServer');
    const { docs } = useConfig();
    return (
        <ServerStatusMessage
            intent="danger"
            message={translate('certificateErrorStatusMessage', {
                serverName,
            })}
            errorCode={errorCode}
            errorDetail={errorDetail}
            fixMessage={translate('certificateErrorFixDescription', {
                a: (chunk) => (
                    <a
                        href={concatUrl(
                            '/scp/knowledge-base/certificate-issues.html',
                            docs.baseUrl,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {chunk}
                    </a>
                ),
            })}
            actions={
                <>
                    {isRecheckConnectionEnabled && (
                        <RecheckConnectionButton
                            serverId={serverId}
                            title={translate('recheckCertificateButton')}
                        />
                    )}
                    <IgnoreCertificateErrorsButton serverId={serverId} />
                </>
            }
            data-type="certificate-error-status-message"
            {...props}
        />
    );
};

export default CertificateErrorStatusMessage;
