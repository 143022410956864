// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getOperationsSummary } from '@platform360/security-dashboard/web/api/operations';

export type UseOperationsSummaryQueryVariables = undefined;

export type UseOperationsSummaryQueryData = PromiseValue<ReturnType<typeof getOperationsSummary>>;

const useOperationsSummaryQuery = createQuery<
    UseOperationsSummaryQueryVariables,
    UseOperationsSummaryQueryData
>({
    queryName: 'security-dashboard/useOperationsSummaryQuery',
    fetcher: getOperationsSummary,
    useQuery: (options) =>
        useQuery({
            ...options,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }),
});

export default useOperationsSummaryQuery;
