// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Grid, GridCol } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Cost from '@platform360/licenses/web/components/Cost';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';

export type GrossVatProps = {
    currency: Currency;
    period: BillingPeriod;
    vat: number;
    gross: number;
};

const GrossVat = ({ currency, period, vat, gross }: GrossVatProps) => {
    const translate = useTranslate();

    return (
        <Grid>
            <GridCol xs={8} />
            <GridCol xs={4}>
                <Grid gap="md">
                    <GridCol xs={3}>{translate('vat')}</GridCol>
                    <GridCol xs={9}>
                        <BillingInterval period={period}>
                            <Cost value={vat} currency={currency} />
                        </BillingInterval>
                    </GridCol>
                    <GridCol xs={3}>{translate('total')}</GridCol>
                    <GridCol xs={9}>
                        <BillingInterval period={period}>
                            <Cost value={gross} currency={currency} />
                        </BillingInterval>
                    </GridCol>
                </Grid>
            </GridCol>
        </Grid>
    );
};

export default GrossVat;
