// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Ya existe una etiqueta con este nombre',
        invitationNotExist: 'La invitación no existe.',
        wpApiClientUnreachable: 'Parece que este servidor o sitio web está offline en este momento. Verifique si se puede acceder a su servidor o sitio web e inténtelo de nuevo. Si el servidor o sitio web funciona correctamente y parece que no hay problemas con su conexión a WP Guardian, contacte con nuestro servicio de asistencia.',
    },
    termsOfUseLink: 'Términos de uso de WP Guardian',
    NotFoundPage: {
        title: 'Página no encontrada',
        description: "Lo sentimos, no se encontró la página solicitada. Es posible que haya hecho clic en un enlace incorrecto o que la página haya sido eliminada.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Confirme la cancelación de la activación de la licencia',
            description: 'Va a cancelar la activación de la licencia de {product}. Si lo desea, más adelante puede activar esta licencia volviendo a hacer clic en el enlace de activación. Si la licencia se ha comprado para otra cuenta, acceda a la cuenta en cuestión y haga clic en el enlace de activación.',
            cancelButton: 'Cancelar',
            continueButton: 'Continuar con la activación',
        },
        SecurityDashboardActivateLicense: {
            title: 'Confirme la activación de la licencia de WP Guardian',
            description: 'Confirme la activación de la licencia de <b>{product}</b> para la siguiente cuenta: <mailto>{userEmail}</mailto>. La licencia solo puede activarse para una cuenta.',
            fixableErrorDescription: 'Algo salió mal durante la activación de la licencia de WP Guardian. Inténtelo de nuevo o envíe una petición de soporte.',
            nonFixableErrorDescription: 'Algo salió mal durante la activación de la licencia de WP Guardian. Envíe una petición de soporte.',
            loaderText: 'Su cuenta de WP Guardian está siendo configurada',
            redirectText: 'Redireccionándole a WP Guardian',
            confirmButton: 'Confirmar',
            cancelButton: 'Cancelar',
            retryButton: 'Volver a intentar',
            gotItButton: 'Entendido',
            errors: {
                linkError: 'El enlace de activación no es válido.',
                unknownError: 'Error interno de servicio de activación.',
            },
        },
    },
    Filters: {
        all: 'Todos',
    },
    FiltersForm: {
        title: 'Filtrar',
        clear: 'Borrar',
        apply: 'Aplicar filtro',
    },
    Invitation: {
        activationFailure: 'No se ha podido activar el enlace de invitación',
    },
    UnderConstructionPopover: {
        title: 'En construcción',
        description: "Lo sentimos. ¡Todavía estamos en ello!",
    },
    RiskRankTitle: {
        title: 'Riesgo',
        description: 'El grado de riesgo es una valoración global del impacto de la vulnerabilidad. Se basa en la valoración CVSS, la valoración EPSS, la prioridad de aplicación de parches de Patchstack y otros factores.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'Esta vulnerabilidad ya se está explotando de forma activa; se recomienda encarecidamente tomar acción de inmediato.',
        highRisk: 'Esta es una vulnerabilidad de alto riesgo que debe abordarse lo antes posible',
        mediumRisk: 'Se trata de una vulnerabilidad de riesgo medio que no precisa intervención urgente, pero que debería abordarse en algún momento',
        lowRisk: 'Esta es una vulnerabilidad de bajo riesgo que se puede ignorar sin que afecte a la seguridad',
        cvss: 'CVSS: {score}',
        emptyCvss: 'N/D',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protección desactivada',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Ignorada',
        },
        ignoredDueToLowRisk: {
            title: 'Se ignora automáticamente',
        },
        doNotIgnoreAutomatically: {
            title: 'No ignorar automáticamente',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Ilimitada',
    },
    taskTypes: {
        scanForNewSites: 'Detección de nuevos sitios WordPress',
        refreshInstallations: 'Actualizando',
        detachInstallations: 'Cancelando la asociación',
        updateInstallation: 'Actualizando',
        massUpdateInstallations: 'Actualizando',
        applyVirtualPatches: 'Enable vulnerability protection',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Guardando la configuración de actualización',
        clearInstallationCache: 'Eliminando caché de instalación',
        checkVulnerability: 'Comprobando la existencia de vulnerabilidades',
        mitigateVulnerabilities: 'Mitigando vulnerabilidades',
        setDailyTaskStartTime: 'Cambio de programación de la verificación de seguridad',
    },
    installationComponents: {
        core: 'Núcleo',
        plugin: 'Plugin',
        theme: 'Tema',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {La etiqueta ha sido asignada} other {Las etiquetas han sido asignadas}}',
        failed: '{count, select, 1 {No pudo asignarse la etiqueta} other {No pudieron asignarse las etiquetas}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {La etiqueta ha sido eliminada} other {La etiquetas han sido eliminadas}}',
        failed: '{count, select, 1 {No pudo eliminarse la etiqueta} other {No pudieron eliminarse las etiquetas}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {La etiqueta ha sido asignada} other {Las etiquetas han sido asignadas}}',
        failed: '{count, select, 1 {No pudo asignarse la etiqueta} other {No pudieron asignarse las etiquetas}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {La etiqueta ha sido eliminada} other {La etiquetas han sido eliminadas}}',
        failed: '{count, select, 1 {No pudo eliminarse la etiqueta} other {No pudieron eliminarse las etiquetas}}',
    },
    useCreateLabelMutation: {
        success: 'La etiqueta fue creada',
        failed: 'No pudo crearse la etiqueta',
    },
    useEditLabelMutation: {
        success: 'La etiqueta fue modificada',
        failed: 'No pudo modificarse la etiqueta',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {La etiqueta ha sido eliminada} other {Las etiquetas han sido eliminadas}}',
        failed: '{count, select, 1 {La etiqueta no pudo eliminarse} other {Las etiquetas no pudieron eliminarse}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'La configuración de notificaciones ha sido actualizada.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'No pudo programarse una actualización de datos del sitio en el servidor <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {No pudo programarse una actualización de datos del sitio en <b>#</b> servidor.} other {No pudo programarse una actualización de datos del sitio en <b>#</b> servidores.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'No pudo programarse la detección de nuevos sitios WordPress en el servidor <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {No pudo programarse la detección de nuevos sitios WordPress en <b>#</b> servidor.} other {No pudo programarse la detección de nuevos sitios WordPress en <b>#</b> servidores.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'El servidor <b>{selectedServerTitle}</b> fue desconectado.',
        notifySeveralServers: '{selectedServersCount, plural, one {<b>#</b> servidor fue desconectado.} other {<b>#</b> servidores fueron desconectados.}}',
        notifySingleServerFail: 'El servidor <b>{selectedServerTitle}</b> no fue desconectado.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {<b>#</b> servidor no fue desconectado.} other {<b>#</b> servidores no fueron desconectados.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'No pudieron programarse actualizaciones de sitio en el servidor <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {No pudieron programarse actualizaciones de sitio en <b>#</b> servidor.} other {No pudieron programarse actualizaciones de sitio en <b>#</b> servidores.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Global',
            server: 'Servidor',
        },
        coreLabels: {
            disabled: 'No instalar ninguna actualización automáticamente',
            major: 'Instalar todas las actualizaciones automáticamente',
            minor: 'Instalar actualizaciones secundarias (de seguridad) automáticamente',
            notSet: 'Definido a nivel del sitio',
            minorDescription: 'Por ejemplo, instalar automáticamente 4.7.1 pero no 4.8',
            doNotTouch: 'No cambiar',
        },
        pluginsLabels: {
            disabled: 'Dejar al admin decidir los plugins que quiere actualizar automáticamente',
            disabledDescription: 'WordPress permite a los admins de sitio activar las actualizaciones automáticas para cada plugin por separado',
            major: 'Instalar todas las actualizaciones de plugins automáticamente',
            majorDescription: 'Ignorar la configuración individual de actualización automática de plugins para cada plugin',
            minor: 'Instalar únicamente actualizaciones de seguridad automáticamente',
            minorDescription: 'Usar la configuración individual de actualización automática de plugins, pero instalar las actualizaciones automáticamente si estas corrigen una vulnerabilidad conocida',
            notSet: 'Definido a nivel del sitio',
            doNotTouch: 'No cambiar',
        },
        themesLabels: {
            disabled: 'Dejar al admin decidir los temas que quiere actualizar automáticamente',
            disabledDescription: 'WordPress permite a los admins de sitio activar las actualizaciones automáticas para cada tema por separado',
            major: 'Instalar todas las actualizaciones de temas automáticamente',
            majorDescription: 'Ignorar la configuración individual de actualización automática de temas para cada tema',
            minor: 'Instalar únicamente actualizaciones de seguridad automáticamente',
            minorDescription: 'Usar la configuración individual de actualización automática de temas, pero instalar las actualizaciones automáticamente si estas corrigen una vulnerabilidad conocida',
            notSet: 'Definido a nivel del sitio',
            doNotTouch: 'No cambiar',
        },
        safeUpdatesLabels: {
            notSet: 'Definido a nivel del sitio',
            enabled: 'Activar actualizaciones seguras',
            disabled: 'Desactivar actualizaciones seguras',
            doNotTouch: 'No cambiar',
        },
        safeUpdates: {
            title: 'Actualizaciones seguras',
            description: 'Cree un backup del sitio antes de actualizarlo. Si una actualización daña el sitio, el backup se restaurará de forma automática',
            checkbox: 'Activar actualizaciones seguras',
        },
        labels: {
            core: 'Núcleo de WordPress',
            plugins: 'Plugins',
            themes: 'Temas',
        },
        doNotTouch: 'No cambiar',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'La configuración de actualización se aplicará a todos los servidores actuales y a los que se añadan nuevos, sin configuraciones de actualización personalizadas',
        checkboxDescription: 'También se aplica la configuración a todos los servidores y sitios web con una configuración de actualización personalizada',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Programación de la verificación de seguridad',
        typeOptions: {
            doNotTouch: 'No cambiar',
            unset: 'Definido por el servidor',
            custom: 'Hora personalizada',
        },
        timeLabel: 'Hora (24 horas)',
        timezoneDescription: 'Zona horaria: predeterminada del servidor',
    },
    SettingsDrawer: {
        title: 'Configuración',
        subTitle: 'Configuración para todos los sitios y servidores conectados',
        loadFailed: 'Error al cargar la configuración.',
        tabs: {
            updates: 'Actualizaciones',
            dailyTaskTime: 'Programación',
            vulnerabilities: 'Vulnerabilidades',
        },
        DailyTaskTime: {
            description: 'WP Guardian comprueba todos los servidores en busca de nuevas vulnerabilidades y actualizaciones disponibles cada 24 horas. Esta es una operación que afecta al rendimiento, por lo que puede programarla para que se ejecute a una hora determinada en todos los servidores de forma predeterminada.',
        },
        successToast: 'La configuración ha sido guardada.',
        buttonSave: 'Guardar',
    },
    Operations: {
        openTaskManagerLinkText: 'Más información',
        operationsFailed: '{count, plural, one {Usted tiene # operación fallida} other {Usted tiene # operaciones fallidas}}',
        fallback: {
            success: 'Operación realizada',
            failed: 'No fue posible efectuar la operación',
        },
        servers: {
            demo: {
                success: 'Operación en un servidor demo realizada.',
                failed: 'La operación en un servidor demo resultó fallida.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Failed to enable vulnerability protection',
            },
            update: {
                success: '{count, plural, one {Instalaciones en # servidor actualizadas} other {Instalaciones en # servidores actualizadas}}',
                failed: '{count, plural, one {No pudieron actualizarse instalaciones en # servidor} other {No pudieron actualizarse instalaciones en # servidores}}',
            },
            refresh: {
                success: 'Actualización de datos del sitio finalizada',
                failed: 'No fue posible actualizar los datos de sitio',
            },
            findNewSites: {
                success: '{count, plural, one {Detección de nuevas instalaciones de WordPress en # servidor completada} other {Detección de nuevas instalaciones de WordPress en # servidores completada}}',
                failed: '{count, plural, one {No pudieron detectarse nuevas instalaciones de WordPress en # servidor} other {No pudieron detectarse nuevas instalaciones de WordPress en # servidores}}',
            },
            configureUpdates: {
                success: 'La configuración de actualización ha sido configurada',
                failed: 'No pudieron configurarse los parámetros de actualización',
            },
            disconnect: {
                success: '{count, plural, one {# servidor ha sido desconectado} other {# servidores han sido desconectados}}',
                failed: '{count, plural, one {No pudo desconectarse # servidor} other {No pudieron desconectarse # servidores}}',
            },
        },
        installations: {
            demo: {
                success: 'La operación en un servidor demo se ha efectuado correctamente.',
                failed: 'La operación en un servidor demo resultó fallida.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'Se han actualizado todas las instalaciones seleccionadas de WordPress',
                failed: 'Error al actualizar las instalaciones seleccionadas de WordPress',
            },
            configureUpdates: {
                success: 'La configuración de actualización se ha actualizado',
                failed: 'No pudo cambiarse la configuración de actualización',
            },
            detach: {
                success: 'Se ha cancelado la asociación de la instalación de WordPress de WP Guardian. Esta será ignorada durante los próximos análisis del servidor. Para que esta instalación sea visible para WP Guardian, elimine el archivo .wp-toolkit-ignore del directorio de la instalación',
                failed: 'No pudo cancelarse la asociación de la instalación de WordPress',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'Las vulnerabilidades se corrigieron mediante la instalación de actualizaciones',
                failed: '{count, plural, one {No pudieron corregirse vulnerabilidades en # instalación} other {No pudieron corregirse vulnerabilidades en # instalaciones}}',
            },
            mitigationDeactivateAsset: {
                success: 'Las vulnerabilidades se mitigaron mediante la desactivación del plugin',
                failed: '{count, plural, one {No pudo mitigarse la vulnerabilidad mediante la desactivación del plugin en # instalación} other {No pudieron mitigarse las vulnerabilidades mediante la desactivación del plugin en # instalaciones}}',
            },
            mitigationActivateAsset: {
                success: 'Plugins desactivados debido a la reactivación de vulnerabilidades',
                failed: '{count, plural, one {No pudieron reactivarse los plugins vulnerables previamente en # instalación} other {No pudieron reactivarse los plugins vulnerables previamente en # instalaciones}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'Las vulnerabilidades se corrigieron mediante la instalación de actualizaciones',
                failed: '{count, plural, one {No pudo corregirse # vulnerabilidad} other {No pudieron corregirse # vulnerabilidades}}',
            },
            mitigationDeactivateAsset: {
                success: 'Las vulnerabilidades se mitigaron mediante la desactivación del plugin',
                failed: '{count, plural, one {No fue posible mitigar # vulnerabilidad mediante la desactivación del plugin} other {No fue posible mitigar # vulnerabilidades mediante la desactivación del plugin}}',
            },
            mitigationActivateAsset: {
                success: 'Plugins desactivados debido a la reactivación de vulnerabilidades',
                failed: '{count, plural, one {No pudieron reactivarse plugins afectados por # vulnerabilidad} other {No pudieron reactivarse plugins afectados por # vulnerabilidades}}',
            },
            ignoreVulnerabilities: {
                success: 'Las vulnerabilidades fueron ignoradas',
                failed: '{count, plural, one {No fue posible ignorar # vulnerabilidad} other {No fue posible ignorar # vulnerabilidades}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'Las vulnerabilidades ya no son ignoradas',
                failed: '{count, plural, one {No pudo dejarse de ignorar # vulnerabilidad} other {No pudieron dejarse de ignorar # vulnerabilidades}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Inicio rápido',
        QuickStartWizard: {
            startStepName: 'Inicio',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Notificaciones',
            securityCheckScheduleStepName: 'Verificación de seguridad',
            updateStepName: 'Actualizaciones',
        },
        QuickStartStep: {
            pagination: '{current} de {total}',
            ok: 'ACEPTAR',
            next: 'Siguiente',
            back: 'Atrás',
        },
        StartStep: {
            title: 'Le damos la bienvenida a WP Guardian',
            text: 'Configure todo lo necesario para mantener a salvo sus sitios de WordPress',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Hora de verificación de seguridad',
            checkingTimeText: 'WP Guardian comprueba todos los servidores y sitios en busca de cambios cada 24 horas. Esta es una operación que afecta al rendimiento que lleva tiempo y usas recursos del servidor.',
            vulnerabilitiesUpdateTitle: 'Actualizaciones de la base de datos de vulnerabilidades',
            vulnerabilitiesUpdateText: 'La base de datos de vulnerabilidades se actualiza automáticamente cada hora. Esta operación no afecta al rendimiento del servidor ni del sitio web',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'Cómo funciona',
            howItWorksText: 'Se instala un plugin dentro de WordPress que actúa como un firewall de aplicaciones web. No modifica los archivos de WordPress ni el contenido del sitio web, pero aplica reglas de firewall dirigidas únicamente a las vulnerabilidades presentes en un sitio web.',
            poweredBy: 'con la tecnología de {providerLogo}',
        },
        UpdatesStep: {
            next: "Siguiente",
            updateSettings: 'Configuración de actualización',
            updateSettingsText: 'Puede forzar las actualizaciones automáticas en todos los sitios web conectados a WP Guardian, incluidos los sitios agregados posteriormente. Esta opción se puede configurar independientemente por sitio o por servidor más adelante.',
            backup: 'Backup antes de actualizar',
            backupText: 'Al habilitar las actualizaciones seguras, WP Guardian hará una copia de seguridad de un sitio web antes de actualizarlo. Si algo sale mal, el sitio se restaurará automáticamente usando la copia de seguridad.',
            safeUpdates: 'Actualizaciones seguras',
            customizedSettings: 'Configuración personalizada',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Registro de tareas',
        Toolbar: {
            buttons: {
                groupTitle: 'Botones',
                allTasks: 'Todas las tareas',
                failed: 'Con errores',
            },
            filters: {
                groupTitle: 'Filtros',
                typeUpdate: 'Instalar actualizaciones',
                refresh: 'Actualizar los datos',
                typeFindNewSites: 'Detectar sitios nuevos',
                typeConfigureUpdates: 'Configurar actualizaciones',
                typeFixVulnerabilitiesViaUpdate: 'Corregir vulnerabilidades mediante actualización',
                mitigationDeactivateAsset: 'Mitigar vulnerabilidades mediante la desactivación del plugin',
                mitigationActivateAsset: 'Reactivar plugins desactivados debido a vulnerabilidades',
                ignoreVulnerabilities: 'Ignorar vulnerabilidades',
                cancelIgnoreVulnerabilities: 'Dejar de ignorar vulnerabilidades',
                setDailyTaskTime: 'Cambiar programación de la verificación de seguridad',
            },
            search: {
                title: 'Buscar',
                placeholder: 'Buscar',
            },
        },
        TasksList: {
            columns: {
                task: 'Tarea',
                status: 'Estado',
                started: 'Inicio',
                finished: 'Fin',
            },
            taskTypes: {
                fallBack: "Operación con código ''{type}''",
                demo: 'Operación en un servidor demo',
                update: 'Instalar actualizaciones',
                refresh: 'Actualizar los datos',
                detach: 'Cancelar asociación',
                findNewSites: 'Detectar sitios nuevos',
                configureUpdates: 'Configurar actualizaciones',
                setDailyTaskTime: 'Cambiar programación de la verificación de seguridad',
                protect: 'Enable vulnerability protection',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Desconectar',
                fixVulnerabilitiesViaUpdate: 'Corregir vulnerabilidades mediante actualización',
                changeInstallationPluginStatus: 'Cambiar estado del plugin',
                mitigationDeactivateAsset: 'Mitigar vulnerabilidades mediante la desactivación del plugin',
                mitigationActivateAsset: 'Reactivar plugins desactivados debido a vulnerabilidades',
                ignoreVulnerabilities: 'Ignorar vulnerabilidades',
                cancelIgnoreVulnerabilities: 'Dejar de ignorar vulnerabilidades',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# servidor} other {# servidores}}',
                sites: '{count, plural, one {# sitio} other {# sitios}}',
                vulnerabilities: '{count, plural, one {# vulnerabilidad} other {# vulnerabilidades}}',
            },
            statuses: {
                success: 'Correcto',
                inProgress: 'En curso',
                failed: 'Con errores',
                errors: 'Errores',
                unknown: 'Desconocido',
            },
            emptyList: {
                title: 'Ninguna tarea',
                description: 'Ninguna tarea se ejecutó todavía.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Sitios con protección deshabilitada',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Reactivar la protección en este sitio web} other {Reactivar la protección en estos sitios web}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "No dispone de ninguna licencia",
        trialDaysLeft: '{days, plural, one {Prueba (Queda # día)} other {Prueba (Quedan # días)}}',
        trialUntil: 'Prueba',
    },
    PromoCodeForm: {
        promoCode: 'Su código de promoción',
        apply: 'Aplicar',
        activationFailure: 'No se ha podido activar el código de promoción',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Aplicar',
            trialTitle: 'Prueba de 14 días',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial:'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Elija una licencia',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: '¡Su prueba ha comenzado!',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: 'Su periodo de prueba ha caducado',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'Sin licencia',
            expired: '(expirada)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Denos su opinión',
            },
        },
        tabs: {
            servers: 'Servidores',
            installations: 'Sitios WordPress',
            vulnerabilities: 'Vulnerabilidades',
        },
        Footer: {
            Links: {
                followUs: 'Síganos:',
                company: 'Empresa',
                aboutWebpros: 'Acerca de WebPros',
                cookiehub: 'Configuración de cookies',
                privacyPolicy: 'política de privacidad',
                knowledgeBase: 'Base de conocimiento',
                documentation: 'Documentación',
                helpCenter: 'Centro de ayuda',
                contactUs: 'Contacte con nosotros',
                legal: 'Avisos legales',
            },
            copyright: '© {year} WebPros International GmbH. Todos los derechos reservados. WP Guardian y el logo de WP Guardian son marcas comerciales de WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Configuración de actualización automática',
            },
            CardLoader: {
                loading: 'Cargando...',
            },
            InstallationsCard: {
                title: 'Sitios WordPress',
                vulnerable: 'Vulnerables: {count}',
                outdated: 'Obsoletos: {count}',
                protected: 'Protegidos: {count}',
                safe: 'OK: {count}',
                notOperable: 'Inoperables: {count}',
                poweredBy: '<whiteSpaceNoWrap>Con la tecnología de {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Vulnerabilidades',
                databaseUpdate: 'Actualización de base de datos',
                momentAgo: 'hace un momento',
                noData: 'Sin datos',
                lastUpdateMinutes: 'Hace {minutes, plural, one {# minuto} other {# minutos}}',
                lastUpdateHours: 'Hace {hours, plural, one {# hora} other {# horas}}',
                lastUpdateDays: 'Hace {days, plural, one {# día} other {# días}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Máxima puntuación CVSS',
                needsProtection: '{count, plural, one {# necesita protección} other {# necesitan protección}}',
            },
            QuickStartCard: {
                title: 'Inicio rápido',
                ctaBlockTitle: 'Configure la seguridad',
                ctaBlockText: 'en unos pocos clics',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'Detalles de la licencia',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'En este momento no existe ninguna actualización que corrija esta vulnerabilidad.',
        columns: {
            position: 'Dónde',
        },
        Onboarding: {
            title: 'No se encontró ninguna vulnerabilidad',
            description: 'Conecte servidores para encontrar sitios WordPress y detectar si en estos existen vulnerabilidades y/o actualizaciones.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Mitigada',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Mitigado desactivando el plugin',
                tooltip: {
                    'singleSiteMitigatedByOther': 'El sitio web afectado por esta vulnerabilidad está protegido actualmente por recursos externos a WP Guardian.',
                    'mitigatedByOther': 'Los sitios web afectados por esta vulnerabilidad están protegidos actualmente por recursos externos a WP Guardian',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Origen:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'Este registro contiene material sujeto a derechos de autor.',
            wordfenceCopyright: 'Copyright 2012-2024 Defiant Inc. Licencia: por la presente, Defiant le concede una licencia perpetua, mundial, no exclusiva, gratuita y de derechos de autor irrevocable para reproducir, crear trabajos derivados, mostrar públicamente, sublicenciar y distribuir la información de vulnerabilidad de este software. Se autoriza cualquier copia de dicha información que realice a estos efectos siempre que incluya un hipervínculo al registro de esta vulnerabilidad y reproduzca la designación de derechos de autor de Defiant y esta licencia en dicha copia.',
            readMore: 'Más información',
        },
        Toolbar: {
            groupFilterMenu: 'Filtrar',
            groupSearch: 'Buscar',
            manage: 'Gestionar',
            patch: 'Protection',
            searchPlaceholder: 'Encontrar vulnerabilidad...',
            UpdateButton: {
                update: 'Instalar actualizaciones',
                cancel: 'Cancelar',
                noSelection: 'Seleccione al menos una vulnerabilidad a solucionar mediante la actualización.',
                updateConfirmation: '{totalVulnerabilities, plural, one {Va a actualizar múltiples sitios web para corregir # vulnerabilidad} other {Va a actualizar múltiples sitios web para corregir # vulnerabilidades}}. El proceso de actualización no puede interrumpirse una vez iniciado.',
                updateConfirmationPluginTitle: 'Se han desactivado varios plugins',
                updateConfirmationPluginCheckbox: 'Reactivar plugins desactivados por directivas de seguridad',
                updateConfirmationPluginCheckboxHint: 'Los plugins vulnerables desactivados por directivas de seguridad se reactivarán de forma automática en el caso de que sus vulnerabilidades sean corregidas en las actualizaciones.',
                numberOfSites: 'Número de sitios web que hay que actualizar:',
                numberOfSitesUnmanaged: 'Número de sitios web que hay que actualizar (se omitirán los sitios no gestionados):',
                sitesCalculating: 'Calculando...',
                sitesCalculatingError: 'No se ha podido calcular',
                affectedSites: '{count, plural, one {{count} sitio} other {{count} sitios}}',
                unmanagedLabel: '{count, plural, one {# sin gestionar} other {# sin gestionar}}',
                updateByProtect: 'En este momento no existe ninguna actualización que corrija esta vulnerabilidad.',
                upgradeLicense: 'Mejora de la licencia',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Ignorar',
                noSelection: 'Seleccione al menos una vulnerabilidad a ignorar o dejar de ignorar',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} sitio} other {{count} sitios}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Cancelar',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Enable Protection',
                providerLogo: '<whiteSpaceNoWrap>Con la tecnología de {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Calculando...',
                sitesCalculatingError: 'No se ha podido calcular la cantidad de sitios web afectados',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# sin gestionar} other {# sin gestionar}}',
                update: 'Enable Protection',
                upgradeLicense: 'Mejora de la licencia',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Necesita protección',
            filterStateIgnored: 'Ignorada',
            filterComponentCore: 'Núcleo',
            filterComponentPlugin: 'Plugin',
            filterComponentTheme: 'Tema',
            everywhere: 'En todas partes',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {Va a actualizar # sitio web.} other {Va a actualizar # sitios web.}} El proceso de actualización no puede interrumpirse una vez iniciado.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Plugins',
                installations: '{enabledCount, plural, one {# activo} other {# activos}}',
                installationsDeactivated: '{count, plural, one {Desactivado en # sitio web} other {Desactivado en # sitios web}}',
                confirmationDeactivation: '{count, plural, one {Va a desactivar el plugin vulnerable en # sitio web.} other {Va a desactivar el plugin vulnerable en # sitios web.}} Este proceso no puede interrumpirse una vez iniciado.',
                confirmationReactivation: '{count, plural, one {Va a reactivar el plugin previamente desactivado por motivos de seguridad en # sitio web. Esta acción expone el sitio web a vulnerabilidades potenciales.} other {Va a reactivar el plugin previamente desactivado por motivos de seguridad en # sitios web. Esta acción expone el sitio web a vulnerabilidades potenciales.}} Este proceso no puede interrumpirse una vez iniciado.',
                dropdownDeactivate: 'Desactivar',
                buttonDeactivateWithCount: 'Desactivar {count}',
                buttonReactivateWithCount: 'Reactivar {count}',
                buttonDeactivateAll: 'Desactivar todo',
                buttonReactivate: 'Reactivar',
                tooltipAllPluginsDeactivatedByUser: 'WP Guardian no reactiva plugins desactivados manualmente por los administradores de sitio.',
            },
            updates: {
                title: 'Instalar actualizaciones',
                installations: '{count, plural, one {# sitio} other {# sitios}}',
                waitingForUpdatesTitle: 'Todavía no existe ninguna actualización',
                buttonName: 'Actualizar todo',
            },
            patches: {
                allProtected: '{count, plural, one {# sitio} other {# sitios}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Cancelar',
                enableButton: 'Activar',
                installations: '{count} de {total}',
                protectAllSites: 'Proteger todos',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Sitios web protegidos',
                titleAllProtected: 'Todo protegido',
            },
        },
        installations: '{count, plural, one {# sitio} other {# sitios}}',
        servers: '{count, plural, one {# servidor} other {# servidores}}',
    },
    Onboarding: {
        title: 'Ningún servidor conectado',
        description: 'Conecte servidores para encontrar sitios WordPress y detectar si en estos existen vulnerabilidades y/o actualizaciones.',
        demoServerWasAddedSuccessfully: 'Servidor demo añadido.',
        connectServers: '<addServerButton>Conecte su servidor</addServerButton> <delimiter>o</delimiter> <addDemoServerButton>Use nuestro servidor demo</addDemoServerButton>',
        hint: 'El servidor de demostración lo proporciona el equipo de WP Guardian. Contiene varios sitios web de WordPress para que pueda explorar fácilmente las funcionalidades del producto antes de conectar su propio servidor.',
        feedbackButton: 'Denos su opinión',
        demoServerPopover: {
            description: 'El servidor demo funciona en modo Solo lectura. Operaciones invasivas como la desinstalación de actualizaciones no cambiarán el estado de los sitios web presentes en el servidor demo.',
            connectBtn: 'Conectar',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Búsqueda: {searchValue}',
        searchByServer: 'Servidor: {searchValue}',
        searchByVulnerability: 'Vulnerabilidad: {searchValue}',
        showAll: 'Mostrar todo',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# de los sitios web de los servidores seleccionados no funciona actualmente} other {# de los sitios web de los servidores seleccionados no funcionan actualmente}}. La operación no se ejecutará en dichos sitios web porque no funcionan o no están disponibles.',
        state: {
            installingAgent: 'Instalando el agente',
            connectionError: 'Error de conexión',
            agentInstallationError: 'Instalación del agente no completada',
            connectionBlocked: 'Conexión bloqueada',
            synchronizationInProgress: 'Sincronización en curso',
            connectionErrorStatusMessage: 'La conexión al servidor no puede establecerse correctamente.',
            connectionErrorFixDescription: 'Compruebe la configuración y los registros en su servidor ({ipAddress}) y espere a la recomprobación.',
            connectionBlockedStatusMessage: 'La conexión al servidor está bloqueada por el firewall.',
            connectionBlockedFixDescription: 'Compruebe que el puerto 878 en el servidor {ipAddress} está abierto y espere a la nueva comprobación.',
            agentInstallationErrorMessage: 'Instalación del agente no completada',
            agentInstallationErrorFixDescription: "En 5 minutos no hemos recibido ninguna respuesta por parte de WP Guardian agent acerca de la correcta finalización de la instalación. Esto significa que la instalación todavía no ha finalizado o que ha resultado fallida. Compruebe el progreso de la instalación en la consola del servidor o examine el registro de instalación presente en el archivo '/root/wp-agent-install.log'",
        },
        AddServerDrawer: {
            title: 'Conecte su servidor',
            done: 'Hecho',
            paragraph1: 'Abra el puerto 878 para las conexiones entrantes desde las siguientes IPs en cada servidor que desee conectar: 34.254.37.129, 52.51.23.204 y 52.213.169.7',
            paragraph2: 'Copie y pegue el siguiente snippet a la consola de servidores y espere a que finalice el proceso de instalación',
            errors: {
                failedToGenerateToken: 'Error al generar el comando de instalación.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Copiar',
            copied: 'El snippet ha sido copiado',
            refreshTooltip: 'Durante este periodo, el comando de conexión es válido para un número ilimitado de servidores. Si necesita más tiempo, actualice el comando',
        },
        SettingsDrawer: {
            title: 'Configuración',
            subTitle: {
                single: 'Servidor: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Actualizaciones',
                dailyTaskTime: 'Programación',
            },
            loadFailed: 'Error al cargar la configuración.',
            buttonSave: 'Guardar',
            successToast: 'La configuración ha sido guardada.',
            Updates: {
                customizedSection: {
                    description: 'La configuración de actualización se aplicará a todos los sitios web actuales y a los que se añadan nuevos en este servidor, sin configuraciones de actualización personalizadas',
                    checkboxDescription: 'También se aplica la configuración a todos los sitios web con una configuración de actualización personalizada',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian busca nuevas vulnerabilidades y actualizaciones disponibles cada 24 horas. Esta es una operación que afecta al rendimiento, por lo que puede programarla para que se ejecute a una hora determinada.',
                unsetOption: 'Definido en la configuración global',
            },
        },
        RemoveServersButton: {
            remove: 'Desconectar',
            noSelectedServers: 'Seleccione al menos un servidor que quiera desconectar.',
            removeDescription: 'WP Guardian agent no se elimina automáticamente cuando se desconecta el servidor. Use el script del uninstaller para eliminar WP Guardian agent (<a>instrucciones</a>).',
            removeSingleServerConfirmation: '¿Desea desconectar {selectedServerTitle}?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {¿Desea desconectar <b>#</b> servidor?} other {¿Desea desconectar <b>#</b> servidores?}}',
        },
        ListActions: {
            remove: 'Desconectar',
            refresh: 'Actualizar',
            findNewSites: 'Detectar sitios nuevos',
            settings: 'Configuración',
            disablePatch: 'Unprotect',
            update: 'Actualizar todos los sitios',
            manageLabels: 'Aplicar etiquetas',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Cancelar',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# sitio WP} other {# sitios WP}}',
            notOperable: 'Inoperables: {count}',
            vulnerable: 'Vulnerables: {count}',
            vulnerabilities: 'Vulnerabilidades: {count}',
            outdated: 'Obsoletos: {count}',
            lastCheck: 'Última comprobación: {dateTime}',
            lastCheckToday: 'Última comprobación: hoy a las {time}',
            unsupportedAgent: 'Versión del agente no soportada',
            coreOutdated: 'Obsoleto',
        },
        columns: {
            server: 'Servidor',
        },
        OutdatedAgentError: {
            message: 'La versión de WP Guardian agent (wp-agent) presente en el servidor no está debidamente actualizada',
            agentVersion: '{version, select, _ {Su versión de WP Guardian agent no está disponible.} other {Su versión de WP Guardian agent es {version}.}}',
            updateDescription: 'Actualice el paquete wp-agent a la versión {wpAgentMinSupportedVersion} o a una versión posterior.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Configuración de actualización',
            ipAddress: '<b>IP del servidor:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Filtrar',
            manage: 'Gestionar',
            remove: 'Desconectar',
            add: 'Conectar',
            groupFilter: 'Filtrar',
            groupSearch: 'Buscar',
            searchPlaceholder: 'Buscar servidor...',
            noSelectedServers: 'Seleccione al menos un servidor que quiera desconectar.',
            FilterPanel: {
                filterAlerts: 'Alertas',
                labelsPlaceholder: 'Todas las etiquetas',
            },
            RefreshButton: {
                buttonText: 'Actualizar',
                noSelectedServers: 'Seleccione al menos un servidor que quiera actualizar.',
            },
            FindNewSitesButton: {
                buttonText: 'Detectar sitios nuevos',
                noSelectedServers: 'Seleccione al menos un servidor para encontrar sitios WordPress nuevos.',
            },
            UpdateButton: {
                buttonText: 'Instalar actualizaciones',
                confirmButtonText: 'Actualizar todo',
                noSelectedServers: 'Seleccione al menos un servidor para instalar todas las actualizaciones disponibles en todos los sitios WordPress.',
                updateSingleServerConfirmation: 'Todas las actualizaciones disponibles se instalarán en todos los sitios WordPress presentes en el servidor <b>{selectedServerTitle}</b>.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {Todas las actualizaciones disponibles se instalarán en todos los sitios WordPress presentes en el <b>#</b> servidor seleccionado.} other {Todas las actualizaciones disponibles se instalarán en todos los sitios WordPress presentes en los <b>#</b> servidores seleccionados.}}',
            },
            ProtectButton: {
                buttonText: 'Proteger',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Aplicar etiquetas',
            },
            SettingsButton: {
                buttonText: 'Configuración',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Conectar',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Ignorando automáticamente las vulnerabilidades de bajo riesgo',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Ignorar automáticamente las vulnerabilidades de bajo riesgo',
            warningMessage: 'Si esta opción está desactivada, se eliminarán todas las etiquetas de "Se ignora automáticamente" y todas las vulnerabilidades ignoradas automáticamente volverán a ser completamente visibles',
        },
    },
    Installations: {
        yes: 'Sí',
        no: 'No',
        update: 'Instalar actualizaciones',
        refresh: 'Actualizar',
        detach: 'Cancelar asociación',
        updateSettings: 'Configuración de actualización',
        sitesAreNotOperable: '{notOperable, plural, one {# de los sitios web seleccionados no funciona actualmente} other {# de los sitios web seleccionados no funcionan actualmente}}. La operación no se ejecutará en dichos sitios web porque no funcionan o no están disponibles.',

        DetachInstallationsButton: {
            detach: 'Cancelar asociación',
            detachSingleInstallationConfirmation: '¿Desea cancelar la asociación de <b>{selectedInstallationTitle}</b> de WP Guardian? Las instalaciones cuya asociación haya sido cancelada serán ignoradas durante los sucesivos análisis del servidor.',
            detachSeveralInstallationsConfirmation: '¿Desea cancelar la asociación de <b>{selectedInstallationsCount}</b> instalaciones de WP Guardian? Las instalaciones cuya asociación haya sido cancelada serán ignoradas durante los sucesivos análisis del servidor.',
        },
        Onboarding: {
            title: 'No se encontró ningún sitio WordPress',
            description: 'Conecte servidores para encontrar sitios WordPress y detectar si en estos existen vulnerabilidades y componentes obsoletos.',
        },
        columns: {
            website: 'Sitio',
            server: 'Servidor',
            synchronized: 'Conectada',
            lastCheck: 'Última comprobación',
            protect: 'Proteger',
            siteHealth: 'Estado del sitio',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Filter Menu',
                filterAll: 'Todos',
                filterVulnerable: 'Vulnerable',
                filterOutdated: 'Obsoleto',
                filterNotOperable: 'Inoperativo',
                filterProtected: 'Protegido',
                filterUnmanaged: 'No gestionado',
                filterNotProtected: 'Desprotegido',
                filterSafe: 'Ninguna incidencia',
            },
            labelsPlaceholder: 'Todas las etiquetas',
        },
        Toolbar: {
            groupFilterMenu: 'Filtrar',
            groupSearch: 'Buscar',
            searchPlaceholder: 'Buscar sitio web...',
            groupActions: 'Acciones',
            groupUpdate: 'Instalar actualizaciones',
            groupProtect: 'Proteger',
            subGroupActions: 'Acciones',
            buttons: {
                update: {
                    text: 'Instalar actualizaciones',
                    noSelection: 'Seleccione al menos un sitio para instalar todas las actualizaciones disponibles.',
                },
                refresh: {
                    text: 'Actualizar',
                    noSelection: 'Seleccione al menos un sitio que quiera actualizar.',
                },
                protect: {
                    text: 'Proteger',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Aplicar etiquetas',
                },
                settings: {
                    text: 'Configuración',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: '¿Desea actualizar <b>{selectedInstallationTitle}</b>?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {Va a actualizar <b>#</b> sitio WordPress} other {Va a actualizar <b>#</b> sitios WordPress}}',
                confirmButton: 'Actualizar',
                cancelButton: 'Cancelar',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Proteger',
                cancelButton: 'Cancelar',
            },
        },
        SiteHealthColumn: {
            outdated: 'Obsoletos: {count}',
            safe: 'ACEPTAR',
            notOperable: 'Inoperativo',
            unsupportedAgent: 'Versión del agente no soportada',
            vulnerabilities: 'Vulnerabilidades: {count}',
            lastCheckAtTooltip: 'Última comprobación: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'La configuración ha sido guardada.',
            title: 'Configuración de actualización',
            subTitle: {
                single: 'Sitio web: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: 'Se omitirán {notOperableCount, plural, one {# sitio web inorperativo} other {# sitios web inoperativos}} y {unmanagedCount, plural, one {# sitio web sin gestionar} other {# sitios web sin gestionar}}.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {Se omitirá # sitio web no gestionado} other {Se omitirán # sitios web no gestionados} }.',
            },
            buttonSave: 'Guardar',
            confirmationPopover: {
                confirmBtn: 'Guardar',
                description: {
                    single: 'Va a modificar la configuración de actualización para el sitio WordPress {installationName}. ¿Desea continuar?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Actualizaciones',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Enable Vulnerability Protection',
        },
        InstallationSummary: {
            updateAll: 'Actualizar todo',
            updateSettings: 'Configuración de actualización',
            upToDate: 'Actualizada',
            AssetSummary: {
                plugin: {
                    title: 'Plugins [{count}]',
                    update: 'Instalar actualizaciones de plugins',
                },
                theme: {
                    title: 'Temas [{count}]',
                    update: 'Instalar actualizaciones de temas',
                },
                safe: 'ACEPTAR',
                vulnerable: 'Vulnerables: {count}',
                outdated: 'Obsoletos: {count}',
                vulnerabilities: 'Vulnerabilidades: {count}',
                isOutdated: 'Obsoleto',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Actualizar a {version}',
                updateSettingsTitle: 'Configuración de actualización',
                vulnerabilities: '{count, plural, one {# vulnerabilidad} other {# vulnerabilidades}}',
                isOutdated: 'Obsoleto',
            },
            unsupportedAgentError: 'La versión de wp-agent en un servidor es obsoleta. La versión mínima requerida es {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'Se ha producido un error desconocido mientras se estaba detectando la posible presencia de vulnerabilidades en el sitio.',
            hostname: 'Nombre de host del servidor:',
            ipAddress: 'IP del servidor:',
            UpdateConfirmation: {
                title: 'Confirmar la actualización',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Cancelar',
                continueButton: 'Actualizar',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Protección ante vulnerabilidades con la tecnología de <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'La protección ante vulnerabilidades está desactivada',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Enable protection',
            },
            ProtectInfoPopover: {
                label: '¿Vulnerabilidad aún activa?',
                message: 'Neutralizar una vulnerabilidad requiere reglas de protección específicas. A continuación se enumeran los motivos por los que las reglas de protección podrían no estar disponibles para una vulnerabilidad determinada:',
                noPatch: {
                    title: 'Labor en curso',
                    description: 'Las reglas para vulnerabilidades de gran impacto suelen estar disponibles unas pocas horas después de ser descubiertas. Las reglas para vulnerabilidades de menor impacto podrían tardar algo más en crearse.',
                },
                unlikelyExploited: {
                    title: 'Riesgo bajo',
                    description: 'Algunas vulnerabilidades tienen un impacto mínimo o carecen de métodos efectivos para explotarse. Dado que no representan una amenaza real para los sitios web, no son necesarias reglas de protección para ellos.',
                },
                dbMatch: {
                    title: 'No están en la base de datos',
                    description: 'Las vulnerabilidades que se encuentran solo en la base de datos de Wordfence o que no coinciden con las entradas correspondientes de la base de datos de Patchstack no reciben reglas de protección.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'háganoslo saber',
            },
        },
        WebsiteDrawer: {
            title: 'Detalles de sitio WordPress',
            titleUpdateInProgress: 'Actualizando {title}',
            subtitleUpdateInProgress: 'Actualizando los elementos seleccionados',
            errors: {
                installationNotFound: "No fue posible cargar la información para la instalación con ID {id}",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Cancelar',
                        updateButton: 'Actualizar',
                        description: '{count, plural, one {Va a corregir # vulnerabilidad mediante la actualización en <b>{website}</b>.} other {Va a corregir # vulnerabilidades mediante la actualización en <b>{website}</b>.}} El proceso de actualización no puede interrumpirse una vez iniciado.',
                        updateConfirmationPluginCheckbox: '{count, plural, one {Reactivar el plugin desactivado por la directiva de seguridad} other {Reactivar los plugins desactivados por la directiva de seguridad}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {El plugin vulnerable desactivado por su directiva de seguridad se reactivará automáticamente si su vulnerabilidad es corregida en la actualización.} other {Los plugins vulnerables desactivados por su directiva de seguridad se reactivarán automáticamente si sus vulnerabilidades son corregidas en las actualizaciones.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Acciones',
                            buttons: {
                                update: 'Actualizar',
                                processing: 'Procesando',
                            },
                        },
                        filters: {
                            title: 'Filtros',
                            groupFiltersTitle: 'Filtrar',
                            search: {
                                title: 'Buscar',
                                placeholder: 'Encontrar vulnerabilidad...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Necesita protección',
                                filterStateIgnored: 'Ignorada',
                                filterComponentHeader: 'Dónde',
                            },
                        },
                    },
                    title: 'Vulnerabilidades',
                    columns: {
                        component: 'Dónde',
                    },
                    emptyList: {
                        title: 'Todo está debidamente protegido',
                        description: 'En este sitio web no se detectó ninguna vulnerabilidad conocida.',
                    },
                    waitingForUpdate: 'No hay actualizaciones disponibles',
                    updateTo: 'Actualizar a {availableVersion}',
					update: 'Actualizar',
                    disablePlugin: 'Desactivar el plugin',
                    enablePlugin: 'Activar el plugin',
                    updateByProtect: 'En este momento no existe ninguna actualización que corrija esta vulnerabilidad.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {Está a punto de actualizar # activo en <b>{website}</b>.} other {Está a punto de actualizar # activos en <b>{website}</b>.}} El proceso de actualización no se puede interrumpir una vez iniciado.',
                        cancelButton: 'Cancelar',
                        updateButton: 'Actualizar',
                    },
                    title: 'Obsoleto',
                    toolbar: {
                        actions: {
                            title: 'Acciones',
                            buttons: {
                                update: 'Actualizar',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Filtros',
                            groupSearchTitle: 'Buscar',
                            search: {
                                title: 'Buscar',
                                placeholder: 'Buscar componente obsoleto...',
                            },
                        },
                    },
                    columns: {
                        name: 'Nombre',
                        type: 'Dónde',
                    },
                    emptyList: {
                        title: 'Todo está debidamente actualizado',
                        description: 'Las versiones más recientes de temas, plugins y núcleo de WordPress se instalan en este sitio.',
                    },
                    updateTo: 'Actualizar a {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Proteger',
            disableProtectButton: 'Unprotect',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Aplicar etiquetas',
        },
        DisableProtectionButton: {
            confirmButton: 'Unprotect',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Notificaciones',
        listEmptyTitle: 'No se pierda ningún evento importante',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Marcar todo como leído',
        deleteNotification: 'Eliminar esta notificación',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'Nueva licencia activa',
                licenseLimitReached: 'Límite de sitios web alcanzado',
                licenseExpired: 'Licencia expirada',
                licenseTerminated: 'Licencia terminada',
                trialCreated: 'Prueba iniciada',
                trialExpired: 'Prueba expirada',
                trialExpiredSoon: 'El periodo de prueba finalizará el {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'Se ha activado su nueva llave de licencia {licenseName} #{licenseKey}.',
                licenseLimitReached: '{limit, plural, one {Su licencia de WP Guardian permite gestionar # sitio,} other {Su licencia de WP Guardian permite gestionar # sitios,}} {total, plural, one {si bien en este momento tiene # sitio conectado.} other {si bien en este momento tiene # sitios conectados.}} Mejore su licencia para poder gestionar todos sus sitios.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Se ha iniciado su periodo de prueba de WP Guardian, lo que le permite disponer de acceso completo a todas las prestaciones de WP Guardian en hasta {sitesLimit, plural, one {# sitio web} other {# sitios web}} durante este periodo.',
                trialExpired: "Su periodo de prueba de WP Guardian ha finalizado. Seleccione y compre una licencia para poder seguir usando el producto o díganos el motivo por el que no ha quedado satisfecho mediante el formulario que se muestra arriba.",
                trialExpiredSoon: 'Su periodo de prueba de WP Guardian finalizará el {expirationDate}. Considere comprar una licencia para poder seguir usando el producto.',
            },
            markAsRead: 'Marcar como leído',
            markAsUnread: 'Marcar como no leído',
        },
        settings: 'Configuración',
    },
    ConfirmNotificationsEmail: {
        title: 'Confirmación del email de notificación',
        error: 'El enlace ha expirado',
        successMessage: 'El email de notificación ha sido cambiado.',
    },
    NotificationsSettingsDrawer: {
        title: 'Configuración de notificaciones',
        Content: {
            sendSectionTitle: 'Dónde notificarme',
            email: 'Email',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Notificaciones enviadas fuera del producto',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'Notificaciones de licencia',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'Cambiar email de notificación',
        buttonSave: 'Enviar email',
        buttonCancel: 'Cancelar',
        buttonEdit: 'Editar',
        email: 'Email',
        description: 'Le enviaremos un email con las instrucciones para actualizar la dirección de email usada para recibir notificaciones.',
        successMessage: 'Se ha enviado un email a <b>{email}</b>. Siga las instrucciones detalladas en el mismo para verificar su dirección de email.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Detección de vulnerabilidades',
            description: 'Actualizaciones en tiempo real sobre nuevas vulnerabilidades de WordPress en sitios conectados',
        },
        secureSites: {
            title: 'Seguridad para sus sitios de WordPress',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Actualizaciones automáticas',
            description: 'Configuración flexible de actualizaciones automáticas para servidores y sitios web',
        },
        safeUpdates: {
            title: 'Actualizaciones seguras',
            description: 'La creación de backups previa a la actualización permite llevar a cabo una reversión automática en caso de que no pueda efectuarse la actualización',
        },
        notifications: {
            title: 'Notificaciones y estado',
            description: 'Esté siempre informado sobre el estado de sus sitios WordPress',
        },
    },
    LicensePage: {
        benefits: {
            title: 'Garantice la seguridad de los sitios WordPress',
        },
        ExpirationLabel: {
            noLicense: "No dispone de ninguna licencia",
            expiredLicense: 'Su licencia ha expirado',
        },
        LicenseHeading: {
            welcome: 'Le damos la bienvenida a WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Escoja su licencia',
            trial: 'Escoja su licencia',
            trialLicenseExpired: 'Su periodo de prueba ha finalizado',
        },
        LicenseDescription: {
            licenseKey: 'Llave de licencia #{number}',
            nextUpdate: 'Siguiente actualización de la llave de licencia: {date}',
            expirationDate: 'Fecha de expiración: {date}',
            noLicense: 'La versión gratuita de WP Guardian solo incluye información sobre vulnerabilidades y actualizaciones manuales de sitios. Si desea acceder a todas las prestaciones de WP Guardian, compre una licencia.',
            trialJustStarted: 'Durante su período de prueba, tiene acceso a todas las funciones de WP Guardian. Después de la prueba, se le recomendará la licencia que mejor se adapte a sus necesidades. También tendrá la opción de seguir usando WP Guardian de forma gratuita con ciertas limitaciones.',
            trial: '{sites, plural, one {Su prueba tiene una duración limitada y permite gestionar hasta # sitio de WordPress.} other {Su prueba tiene una duración limitada y permite gestionar hasta # sitios de WordPress.}} Compre una licencia si quiere seguir usando todas las funciones actuales y futuras de WP Guardian.',
            limit: '{sites, plural, one {Su licencia permite gestionar un máximo de # sitio WordPress.} other {Su licencia permite gestionar un máximo de # sitios WordPress.}}',
            unlimited: 'Su licencia permite gestionar una cantidad ilimitada de sitios WordPress. {sites, plural, one {El precio base de la licencia incluye la gestión de # sitio.} other {El precio base de la licencia incluye la gestión de # sitios.}} Todos los sitios por encima de este límite se facturarán con la modalidad Pay-As-You-Go.',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'Ver detalles',
            nextBillingDate: 'Próxima fecha de facturación: {date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Uso actual:',
            sitesTotal: '{sites, plural, one {# sitio web en total} other {# sitios web en total}}',
            allowedToManage: '{sites, plural,one {# sitio web gestionable} other {# sitios web gestionable}}',
            outOfLimit: '{sites, plural, one {# sitio web por encima del límite de la licencia} other {# sitios web por encima del límite de la licencia}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Ilimitada',
                label: 'Para más de 1000 sitios web',
                feature1: '10.000 sitios incluidos',
                feature2: 'Pague según uso al trabajar con más de 10.000 sitios',
                feature3: 'Todas las prestaciones incluidas',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: 'Para hasta 1000 sitios web',
                feature1: 'Pago antes del uso',
                feature2: 'Todas las prestaciones incluidas',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Usar este plan',
            changePlan: 'Cambiar de plan',
            chooseAnotherOption: 'Escoja otra opción para mejorar su plan',
        },
        LicenseList: {
            columns: {
                license: 'Licencia',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} por sitio',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Comprar',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Reactivar',
            details: 'Details',
        },
        chooseLicenseNow: 'Escoja su licencia',
        licensesTitle: 'Seleccione la licencia que mejor se adapte a sus necesidades',
        faq: {
            title: 'FAQs',
            question1: {
                question: '¿Qué sucederá cuando finalice mi periodo de prueba?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: '¿Qué sucede si se alcanzan los límites de mi licencia?',
                answer: 'Los sitios añadidos a WP Guardian una vez alcanzado el límite de la licencia se marcarán como no gestionados. Estos sitios seguirán disponiendo de información actualizada sobre vulnerabilidades y solo pueden actualizarse de forma manual. Las demás funcionalidades de WP Guardian no estarán a su disposición.',
            },
            question4: {
                question: '¿Cómo funciona la modalidad Pay As You Go?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'Puede obtener su licencia en <kaLink>Key Administrator</kaLink>, en <manage2Link>Manage2</manage2Link> o de su representante comercial.',
            standard: {
                title: '{sites, plural, one {Desea escoger una licencia estándar de # sitios} other {Desea escoger una licencia estándar de # sitios}}',
                licenseLimit: '{sites, plural, one {La licencia solo incluye # sitio} other {La licencia solo incluye # sitios}}',
                feature1: 'La licencia Standard incluye un número específico de sitios web sin capacidades Pay-As-You-Go.',
                feature2: 'Todos los sitios web añadidos tras alcanzar el límite de la licencia se marcarán como No gestionados.',
                feature3: 'Los sitios web no gestionados no pueden gestionarse mediante WP Guardian sin actualizar su licencia.',
                feature4: 'En el caso de aumentar el límite de una licencia o si un slot libre en la licencia pasar a estar disponible, los sitios web no gestionados pasarán a gestionados.',
                feature5: 'Si un sitio web estuvo conectado a WP Guardian más de unos cuantos días, este se contabilizará en el cómputo de uso de su licencia.',
                feature6: 'Los sitios web dañados o no disponibles no se contabilizan en el cómputo del límite de su licencia.',
            },
            unlimited: {
                title: 'Usted escoge una licencia ilimitada',
                feature1: 'La licencia ilimitada incluye 10.000 sitios web en su precio de referencia.',
                feature2: 'Los sitios web que se añadan después de los primeros 10.000 sitios se facturarán según el modelo de pago por uso.',
                feature3: 'Si un sitio web estuvo conectado a WP Guardian más de unos cuantos días, este se contabilizará en el cómputo de uso de su licencia.',
                feature4: 'Los sitios web dañados o no disponibles no se contabilizan en el cómputo del límite de su licencia.',
            },
        },
        UpgradeButton: {
            upgrade: 'Mejorar',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'Todas las prestaciones incluidas',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} por sitio',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Comprar licencia',
            upgradeLicense: 'Mejora de la licencia',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Volver a la pantalla principal',
    },
    LabelsDrawer: {
        title: 'Gestionar etiquetas',
        LabelsList: {
            create: 'Crear etiqueta',
            edit: 'Editar',
            delete: 'Eliminar',
            noSelectedLabels: 'Seleccione al menos una etiqueta a eliminar.',
            columns: {
                title: 'Etiqueta',
            },
            search: {
                title: 'Buscar',
                placeholder: 'Buscar etiqueta',
            },
            emptyList: {
                title: 'Crear una etiqueta',
                description: "No dispone de ninguna etiqueta que permita diferenciar sus servidores y sitios. ¿Desea destacar algunos de estos?",
            },
            filteredEmptyList: {
                title: 'No se encontró ningún elemento que coincida con el filtro',
                description: 'Cambie el texto de la etiqueta que está buscando e inténtelo de nuevo',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Eliminar',
            confirmation: '{count, select, 1 {La eliminación de una etiqueta la eliminará de todos los objetos a los que se aplicó.} other {La eliminación de etiquetas las eliminará de todos los objetos a los que se aplicaron.}} ¿Desea continuar?',
        },
        CreateLabelDrawer: {
            title: 'Crear etiqueta',
        },
        EditLabelDrawer: {
            title: 'Editar etiqueta',
            notFound: 'Etiqueta no encontrada',
        },
        LabelForm: {
            save: 'Guardar',
            cancel: 'Cancelar',
            fields: {
                title: {
                    label: 'Nombre de la etiqueta',
                    description: 'Máximo 30 símbolos',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'ETIQUETAR COMO',
        noSelection: 'Seleccione al menos un elemento de la lista para añadir o eliminar etiquetas.',
        buttons: {
            apply: 'Aplicar',
            create: 'Crear nueva',
            manage: 'Gestionar etiquetas',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'todos',
            tooltip: 'Las actualizaciones seguras están activadas',
            components: {
                core: 'núcleo',
                themes: 'temas',
                plugins: 'plugins',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'actualizaciones de seguridad',
                major: 'todas las actualizaciones',
                disabled: "no instalar",
                notSet: 'a nivel de sitio',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {Su licencia incluye # sitio web gestionado} other {Su licencia incluye # sitios web gestionados}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {Se omitirá # sitio web no gestionado} other {Se omitirán # sitios web no gestionados}}',
        operableNotManagedSitesMessage: 'WP Guardian ignora los sitios web no gestionados porque superan los límites de su licencia. Estos sitios web se omiten al ejecutar acciones en masa. ',
        notOperableNotManagedSitesTitle: 'Se omitirán {notOperableCount, plural, one {# sitio web inorperativo} other {# sitios web inoperativos}} y {unmanagedCount, plural, one {# sitio web sin gestionar} other {# sitios web sin gestionar}}',
        notOperableNotManagedSitesMessage: 'La operación que ha seleccionado no se ejecutará en dichos sitios web porque están inoperativos o exceden los límites de su licencia. Estos sitios web se omiten al ejecutar acciones en masa.',
        managedSitesWillBePerformed: 'La operación seleccionada se ejecutará sin restricciones en todos los sitios web gestionados.',
        chooseLicenseButton: 'Elija una licencia',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'Obtenga una licencia si quiere seguir usando las funcionalidades avanzadas de WP Guardian.',
            chooseLicenseButton: 'Elija una licencia',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Actualizaciones definidas a nivel de sitio',
            autoupdate: 'Actualizaciones automáticas',
            manual: 'Actualizaciones manuales',
            custom: 'Actualizaciones personalizadas',
        },
        unmanaged: {
            title: 'No gestionada',
            tooltip: 'WP Guardian ignora los sitios no gestionados porque superan los límites de su licencia. Los sitios marcados como no gestionados debido a los límites de la licencia seguirán teniendo la información actualizada sobre sus vulnerabilidades y solo pueden actualizarse de forma manual. Este tipo de sitios cambiarán a gestionados si se aumenta el límite de la licencia o si un slot en la licencia actual pasa a estar disponible.',
        },
        menu: {
            remove: 'Eliminar',
            filter: 'Filtrar por esta etiqueta',
        },
    },
    Cuttable: {
        showMore: 'Mostrar más',
        showLess: 'Mostrar menos',
    },
};