// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { invitationTokenCookie } from '@platform360/security-dashboard/web/constants/invitation';

import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { Button, ButtonProps, Input, InputProps } from '@plesk/ui-library';
import Cookies from 'js-cookie';
import { useState } from 'react';

import { useAcceptInvitationMutation } from '@platform360/security-dashboard/web/mutations';

import styles from './PromoCodeForm.module.css';

type PromoCodeFormProps = {
    buttonProps?: ButtonProps;
    inputProps?: InputProps;
};
const PromoCodeForm = ({ buttonProps = {}, inputProps = {} }: PromoCodeFormProps) => {
    const [code, setCode] = useState('');
    const translate = useTranslate('security-dashboard.PromoCodeForm');

    const { errorToast } = useToaster();
    const securityDashboardAnalyticsEvents = useSecurityDashboardAnalyticsEvents();

    const { mutate: activateLicense } = useAcceptInvitationMutation({
        onError: () => {
            errorToast(translate('activationFailure'));
        },
    });

    const onFormSubmit = () => {
        securityDashboardAnalyticsEvents.wpEnterPromoCode(code);
        Cookies.set(invitationTokenCookie, code);
        activateLicense();
    };

    return (
        <div className={styles.promoCode}>
            <Input
                value={code}
                onChange={(e) => setCode(e.currentTarget.value)}
                placeholder={translate('promoCode')}
                size="xl"
                {...inputProps}
            />
            <Button onClick={onFormSubmit} disabled={!code} {...buttonProps}>
                {translate('apply')}
            </Button>
        </div>
    );
};

export default PromoCodeForm;
