// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TOGGLE_FETCHING } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type ToggleFetchingAction = {
    type: typeof TOGGLE_FETCHING;
    payload: boolean;
};

const toggleFetching = (state: boolean): ToggleFetchingAction => ({
    type: TOGGLE_FETCHING,
    payload: state,
});

export default toggleFetching;
