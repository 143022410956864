// Copyright 2024. WebPros International GmbH. All rights reserved.

import { cloneElement, ReactElement, useState } from 'react';
import { Icon, Media, Paragraph, Popover, TranslationProps } from '@plesk/ui-library';

export type NoSelectionPopoverProps = {
    children: TranslationProps;
    selectionCount: number;
    target: ReactElement;
};

const NoSelectionPopover = ({
    children,
    target,
    selectionCount,
    ...props
}: NoSelectionPopoverProps) => {
    const [visible, setVisible] = useState(false);

    if (selectionCount > 0) {
        return cloneElement(target, props);
    }

    return (
        <Popover
            target={cloneElement(target, {
                ...props,
                onClick: (e: MouseEvent) => {
                    setVisible(true);
                    e.stopPropagation();
                },
            })}
            placement="top"
            visible={visible}
            onClose={() => setVisible(false)}
        >
            <Media image={<Icon intent="info" name="info-circle-filled" />}>
                <Paragraph>{children}</Paragraph>
            </Media>
        </Popover>
    );
};

export default NoSelectionPopover;
