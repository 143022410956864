// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode, ComponentProps } from 'react';
import { FormField, InputFile } from '@plesk/ui-library';

type FormFieldInputFileProps = {
    label?: ReactNode;
    required?: boolean;
} & ComponentProps<typeof InputFile>;

const FormFieldInputFile = ({
    name,
    label,
    required,
    className,
    ...props
}: FormFieldInputFileProps) => (
    <FormField name={name} label={label} required={required} className={className}>
        {({ setValue, isDisabled }) => (
            <InputFile
                disabled={isDisabled()}
                onChange={async (file) => {
                    if (!file) {
                        setValue('');
                        return;
                    }

                    setValue(await file.text());
                }}
                {...props}
            />
        )}
    </FormField>
);

export default FormFieldInputFile;
