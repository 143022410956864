// Copyright 2024. WebPros International GmbH. All rights reserved.

import InPlaceText from '@platform360/libs/shared-web/components/InPlaceText';
import useUpdateServer from '@platform360/server-inventory/web/mutations/useUpdateServer';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';

type InPlaceNotesProps = {
    serverId: number;
    notes: string;
};
const InPlaceNotes = ({ serverId, notes }: InPlaceNotesProps) => {
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { mutateAsync } = useUpdateServer(serverId);

    const handleEdit = () => {
        analyticsEvents.serverViewNotesEditClicked();
    };

    const handleSave = async (value: string) => {
        analyticsEvents.serverViewNotesSaved();
        await mutateAsync({ notes: value });
    };

    return (
        <InPlaceText
            value={notes}
            onEdit={handleEdit}
            onSave={handleSave}
            textareaProps={{
                maxLength: 1024,
                autoheight: false,
                rows: { min: 12, max: 12 },
            }}
        />
    );
};

export default InPlaceNotes;
