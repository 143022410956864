// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Alert, Button, Skeleton } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useLoginLinkQuery from '@platform360/web-installer/web/queries/useLoginLinkQuery';

import styles from './CPanelOneTimeLoginAlert.module.css';

type OneTimeLoginLinkProps = {
    serverGuid: string;
};

const CPanelOneTimeLoginAlert = ({ serverGuid }: OneTimeLoginLinkProps) => {
    const translate = useTranslate('web-installer.CPanelOneTimeLoginAlert');

    const { data, isLoading } = useLoginLinkQuery({
        variables: { guid: serverGuid },
    });

    if (isLoading || !data) {
        return <Skeleton height={74} />;
    }

    return (
        <Alert intent="warning">
            <div className={styles.alertText}>{translate('loginMessage')}</div>
            <Button
                intent="primary"
                component="a"
                href={data.loginLink}
                target="_blank"
                rel="noreferrer"
            >
                {translate('loginButton')}
            </Button>
        </Alert>
    );
};

export default CPanelOneTimeLoginAlert;
