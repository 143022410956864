// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCallback, isValidElement, ReactElement, cloneElement } from 'react';
import List, { ListProps } from '@platform360/libs/shared-web/components/List';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { ListData } from '@platform360/libs/shared-web/components/List/List';
import {
    ContentLoader,
    Toolbar,
    ToolbarGroup,
    ToolbarExpander,
    SearchBar,
    PaginationProps as BasePaginationProps,
} from '@plesk/ui-library';

const isNumber = (x: unknown): x is number => typeof x === 'number';

type PaginationProps = Omit<BasePaginationProps, 'total' | 'onItemsPerPageChange'> & {
    resetPagination: () => void;
};

export type DataListProps<T extends ListData> = {
    isPreviousData?: boolean;
    pagination?: ReactElement | PaginationProps;
    search?: ReturnType<typeof useSearch>;
    filteredEmptyView?: ReactElement;
} & Omit<ListProps<T>, 'pagination'>;

const DataList = <T extends ListData>({
    isPreviousData,
    loading,
    filtered,
    search,
    pagination,
    totalRows,
    emptyView,
    filteredEmptyView,
    toolbar,
    ...props
}: DataListProps<T>) => {
    const translate = useTranslate('components.DataList');

    filtered = filtered || !!search?.debouncedValue;
    const handleSearch = useCallback(
        (value: string) => {
            search?.onSearch(value);

            if (pagination && !isValidElement(pagination) && 'onSelect' in pagination) {
                // reset current page due to start new search
                pagination.onSelect(1);
            }
        },
        [search, pagination],
    );

    const getPagination = () => {
        if (isValidElement(pagination) || pagination === undefined) {
            return pagination;
        }

        const { itemsPerPage } = pagination as unknown as PaginationProps;
        const { resetPagination, ...paginationProps } = pagination as unknown as PaginationProps;

        if (!isNumber(itemsPerPage) || itemsPerPage <= 0) {
            return {
                ...paginationProps,
                total: 0,
            };
        }

        return {
            ...paginationProps,
            total: Math.ceil((totalRows || 0) / itemsPerPage),
        };
    };

    const renderToolbar = () => {
        if (!search) {
            return toolbar;
        }

        type ToolbarProps = {
            searchValue: string;
            onSearch: typeof handleSearch;
            'data-type': string;
        };

        if (isValidElement<ToolbarProps>(toolbar)) {
            return cloneElement(toolbar, {
                searchValue: search.value,
                onSearch: handleSearch,
                'data-type': 'data-list-search',
            });
        }

        return (
            <Toolbar>
                <ToolbarExpander />
                <ToolbarGroup title={translate('groupSearch')}>
                    <SearchBar
                        inputProps={{
                            value: search.value,
                            'data-type': 'data-list-search',
                        }}
                        onSearch={handleSearch}
                        onTyping={handleSearch}
                    />
                </ToolbarGroup>
            </Toolbar>
        );
    };

    const renderEmptyView = () => {
        if (loading || isPreviousData) {
            return <ContentLoader />;
        }

        // TODO: move this logic into UI Library
        return filtered ? filteredEmptyView : emptyView;
    };

    if (loading && !filtered) {
        return <ContentLoader />;
    }

    return (
        <List<T>
            {...props}
            loading={loading || isPreviousData}
            filtered={filtered}
            totalRows={Number(totalRows)}
            toolbar={renderToolbar()}
            emptyView={renderEmptyView()}
            pagination={getPagination()}
        />
    );
};

export default DataList;
