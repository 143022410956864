// Copyright 2024. WebPros International GmbH. All rights reserved.

import { IdToken } from '@auth0/auth0-spa-js';

export type Session = {
    email: string;
    accessToken: string;
    userId: string;
    teamId: string;
    isMfaEnabled: boolean;
};

export const toSession = (
    accessToken: string,
    {
        sub,
        'https://accounts.plesk.com/teamId': teamId,
        'https://accounts.plesk.com/isMfaEnabled': isMfaEnabled,
        email,
    }: IdToken,
): Session => {
    if (!email) {
        throw new Error('User email should always present in auth token claims.');
    }

    return {
        accessToken,
        isMfaEnabled,
        teamId,
        userId: sub,
        email,
    };
};

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth` hook.
 */
export type AuthState = {
    session?: Session;
    isAuthorized: boolean;
};

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
    isAuthorized: false,
};
