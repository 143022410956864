// Copyright 2024. WebPros International GmbH. All rights reserved.

import { LICENSE_ICONS_URL } from '@platform360/licenses/web/constants';

const onErrorHandler = (src: string, callback: (s: string) => void) => () => {
    const img = new Image();
    img.onerror = () => callback(`${LICENSE_ICONS_URL}default.png`);
    img.src = src;
};

export default onErrorHandler;
