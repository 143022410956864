// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const useScrollToAnchor = (topOffset = 0) => {
    useEffectOnce(() => {
        const hash = window.location.hash.slice(1);
        if (!hash) {
            return;
        }

        const element = document.getElementById(hash);
        if (!element) {
            return;
        }

        const offsetTop = element.getBoundingClientRect().top + window.scrollY - topOffset;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    });
};
