// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Jurídico',
        eula: 'EULA',
        pleskEula: 'EULA do Plesk',
        cPanelEula: 'EULA do cPanel',
        terms: 'Termos de uso',
        privacyPolicy: 'Política de Privacidade',
        dpa: 'Acordo de Processamento de Dados',
        optOuts: 'Desativações',
        cookies: 'Declaração de cookies',
        gaHeader: 'Desativação do Google Analytics',
        gaOptOut: 'Clique aqui para desativar o Google Analytics',
        cookieHeader: 'Ajustes de Consentimento de Cookies',
        cookieModify: 'Modifique seu consentimento de cookies',
        cookieWithdraw: 'Retirar seu consentimento de cookies',
        cookieSuccess: 'Retirada de consentimento bem-sucedida',
    },
    RedirectGateway: {
        title: 'Redirecionando você {destination}',
        progress: 'Pode levar vários segundos',
        genericError: 'Algo deu errado',
        serverDetails: {
            destinationTitle: 'para a página do servidor no Inventário do Servidor',
            errorTitle: 'Ocorreu um erro ao tentar abrir a página do servidor',
            errorDescription: 'O servidor não existe no Inventário do Servidor',
            redirectDirectly: 'Prossiga para o Inventário do Servidor',
        },
        monitoringServerSetup: {
            destinationTitle: 'para a página de confirmação',
            errorTitle: 'Ocorreu um erro ao tentar abrir a página de confirmação de monitoramento do servidor',
            errorDescription: 'O servidor não existe no Inventário do Servidor',
        },
    },
};