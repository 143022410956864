// Copyright 2024. WebPros International GmbH. All rights reserved.

import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useWebInstallerAnalyticsEvents } from '@platform360/web-installer/web/helpers/analytics';

import styles from './WebInstallerBottomLinks.module.css';

const ManualInstallationLinks = {
    plesk: 'https://docs.plesk.com/en-US/obsidian/deployment-guide/quick-start.76607/#i-don-t-want-to-do-anything-i-just-want-my-plesk-up-now',
    cpanel: 'https://docs.cpanel.net/installation-guide/install/',
};
const SoftwareRequirementsLinks = {
    plesk: 'https://docs.plesk.com/release-notes/obsidian/software-requirements/',
    cpanel: 'https://docs.cpanel.net/installation-guide/system-requirements/',
};
const VersionsAndIssuesLinks = {
    plesk: '/web-installer/plesk-installation-faq',
    cpanel: '/web-installer/cpanel-installation-faq',
};

type InstallationPageLinksProps = {
    productType: WebInstallerProduct;
};

const WebInstallerBottomLinks = ({ productType }: InstallationPageLinksProps) => {
    const analyticsEvents = useWebInstallerAnalyticsEvents();
    const translate = useTranslate('web-installer.ServerInstallationPage.links');

    const SoftwareRequirementsTranslation = {
        plesk: translate('softwareRequirements.plesk'),
        cpanel: translate('softwareRequirements.cpanel'),
    }[productType];

    return (
        <div className={styles.container}>
            <a
                href={ManualInstallationLinks[productType]}
                rel="noreferrer"
                target="_blank"
                onClick={() => analyticsEvents.gotoManualInstallationInstructions(productType)}
            >
                {translate('manualInstallationInstructions')}
            </a>
            <span>{'|'}</span>
            <a
                href={SoftwareRequirementsLinks[productType]}
                rel="noreferrer"
                target="_blank"
                onClick={() => analyticsEvents.gotoSoftwareRequirements(productType)}
            >
                {SoftwareRequirementsTranslation}
            </a>
            <span>{'|'}</span>
            <a
                href={`${VersionsAndIssuesLinks[productType]}#versions`}
                rel="noreferrer"
                target="_blank"
                onClick={() => analyticsEvents.gotoVersionsAndComponents(productType)}
            >
                {translate('versionsAndComponents')}
            </a>
            <span>{'|'}</span>
            <a
                href={`${VersionsAndIssuesLinks[productType]}#issues`}
                rel="noreferrer"
                target="_blank"
                onClick={() => analyticsEvents.gotoKnownIssues(productType)}
            >
                {translate('knownIssues')}
            </a>
        </div>
    );
};

export default WebInstallerBottomLinks;
