// Copyright 2024. WebPros International GmbH. All rights reserved.

import { JSX } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './Footer.module.less';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import WebProsLogo from '@platform360/libs/shared-web/components/WebProsLogo';
import SocialLink from './SocialLink';

type LinksProps = {
    cookieModify: () => void;
    logo: JSX.Element;
};

const SocialLinks = {
    facebook: {
        plesk: 'https://www.facebook.com/Plesk',
        cpanel: 'https://www.facebook.com/cpanel',
    },
    twitter: {
        plesk: 'https://x.com/Plesk',
        cpanel: 'https://x.com/cpanel',
    },
    linkedin: {
        plesk: 'https://www.linkedin.com/company/plesk/',
        cpanel: 'https://www.linkedin.com/company/cpanel',
    },
    youtube: {
        plesk: 'https://www.youtube.com/channel/UCeU-_6YHGQFcVSHLbEXLNlA',
        cpanel: 'https://www.youtube.com/user/cpaneltv',
    },
    github: {
        plesk: 'https://plesk.github.io/',
        cpanel: 'https://github.com/cpanel',
    },
};

export const Links = ({ logo, cookieModify }: LinksProps) => {
    const { docs } = useConfig();
    const translate = useTranslate('Footer');

    const LinksTranslations = {
        followUs: {
            plesk: translate('followPlesk'),
            cpanel: translate('followCPanel'),
        },
    };

    const getSocialLinks = (productType: 'plesk' | 'cpanel') => (
        <div className={styles.socialBlock}>
            <div>{LinksTranslations.followUs[productType]}</div>
            <ul className={styles.socialLinks}>
                <li>
                    <SocialLink
                        href={SocialLinks.facebook[productType]}
                        icon="facebook"
                        title="Facebook"
                    />
                </li>
                <li>
                    <SocialLink
                        href={SocialLinks.twitter[productType]}
                        icon="twitter"
                        title="Twitter"
                    />
                </li>
                <li>
                    <SocialLink
                        href={SocialLinks.linkedin[productType]}
                        icon="linkedin"
                        title="LinkedIn"
                    />
                </li>
                <li>
                    <SocialLink
                        href={SocialLinks.youtube[productType]}
                        icon="youtube"
                        title="YouTube"
                    />
                </li>
                <li>
                    <SocialLink
                        href={SocialLinks.github[productType]}
                        icon="github"
                        title="GitHub"
                    />
                </li>
            </ul>
        </div>
    );

    return (
        <div className={styles.linksGroup}>
            <div className={styles.links}>
                {logo}
                {getSocialLinks('cpanel')}
                {getSocialLinks('plesk')}
            </div>
            <div className={styles.links}>
                <div className={styles.linksHeading}>{translate('company')}</div>
                <ul className={styles.linksList}>
                    <li>
                        <a href="https://cpanel.net/company/">{translate('aboutCPanel')}</a>
                    </li>
                    <li>
                        <a href="https://www.plesk.com/about-us">{translate('aboutPlesk')}</a>
                    </li>
                    <li>
                        <Link to="/legal">{translate('legal')}</Link>
                    </li>
                    <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={cookieModify}>{translate('cookiehub')}</a>
                    </li>
                    <li>
                        <Link to="/legal/privacy-policy">{translate('privacyPolicy')}</Link>
                    </li>
                    <li>
                        <a href="https://www.plesk.com/impressum">{translate('impressum')}</a>
                    </li>
                </ul>
            </div>
            <div className={styles.links}>
                <div className={styles.linksHeading}>{translate('knowledgeBase')}</div>
                <ul className={styles.linksList}>
                    <li>
                        <a href={docs.baseUrl} target="_blank" rel="noreferrer">
                            {translate('documentation')}
                        </a>
                    </li>
                    <li>
                        <a href="https://support.cpanel.net/hc/en-us">
                            {translate('helpCenterCPanel')}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.plesk.com/help-center">
                            {translate('helpCenterPlesk')}
                        </a>
                    </li>
                    <li>
                        <a href="https://webpros.com/contact/">{translate('contactUs')}</a>
                    </li>
                    <li>
                        <a href="https://www.plesk.com/lifecycle-policy">
                            {translate('pleskLifecyclePolicy')}
                        </a>
                    </li>
                </ul>
            </div>
            <div className={styles.links}>
                <div className={styles.linksHeading}>{translate('community')}</div>
                <ul className={styles.linksList}>
                    <li>
                        <a href="https://cpanel.net/blog/">{translate('blogCPanel')}</a>
                    </li>
                    <li>
                        <a href="https://www.plesk.com/blog/">{translate('blogPlesk')}</a>
                    </li>
                    <li>
                        <a href="https://support.cpanel.net/hc/en-us/community/topics">
                            {translate('forumsCPanel')}
                        </a>
                    </li>
                    <li>
                        <a href="https://talk.plesk.com/">{translate('forumsPlesk')}</a>
                    </li>
                    <li>
                        <a href="https://university.cpanel.net/">{translate('cPanelUniversity')}</a>
                    </li>
                    <li>
                        <a href="https://www.plesk.com/university">
                            {translate('pleskUniversity')}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

type FooterProps = {
    compact?: boolean;
    year?: number;
    logo?: JSX.Element;
};

const Footer = ({
    compact = false,
    year = new Date().getFullYear(),
    logo = <WebProsLogo />,
    ...props
}: FooterProps) => {
    const translate = useTranslate('Footer');
    const {
        cookieManager: { cookieModify },
    } = useApplicationSettings();

    return (
        <footer {...props}>
            <div className={styles.footer}>
                {compact ? null : (
                    <div className={classNames(styles.sectionContent, styles.sectionContentMain)}>
                        <Links logo={logo} cookieModify={cookieModify} />
                    </div>
                )}
                <div className={styles.sectionBottom}>
                    <div className={styles.sectionContent}>
                        <div className={styles.copyright}>{translate('copyright', { year })}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
