// Copyright 2024. WebPros International GmbH. All rights reserved.

import { WpAgentRequestMethod } from '@platform360/admin-panel/shared/constants';

export const WS_NAMESPACE = 'admin-panel';

export type WpgAgentRequestUnknownError = {
    requestId: string;
};

export type WpgAgentRequestCompletedEventPayload = {
    requestId: string;
    url: string;
    method: WpAgentRequestMethod;
    request: {
        body: string | null;
        headers: Record<string, string>;
    };
    time: number;
    response: {
        status: number;
        statusText: string;
        data: Record<string, unknown> | unknown[] | number | string | null | undefined;
        headers: Record<string, string>;
    } | null;
    serverUnreachableError: { code?: string } | null;
    server: {
        guid: string;
        hostname: string | null;
        address: string;
    };
};

export type EventsMap = {
    /* eslint-disable @typescript-eslint/naming-convention */
    'wpg-agent-request-completed': (payload: WpgAgentRequestCompletedEventPayload) => void;
    /* eslint-enable @typescript-eslint/naming-convention */
};
