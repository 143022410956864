// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getRedirectUrl } from '@platform360/app/web/api/redirect-gateway';

export type UseRedirectUrlQueryVariables = {
    params: Record<string, string>;
};

export type UseRedirectUrlQueryData = PromiseValue<ReturnType<typeof getRedirectUrl>>;

const useRedirectUrlQuery = createQuery<UseRedirectUrlQueryVariables, UseRedirectUrlQueryData>({
    queryName: 'app/useRedirectUrlQuery',
    fetcher: getRedirectUrl,
    useQuery: (options) =>
        useQuery({
            ...options,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }),
});

export default useRedirectUrlQuery;
