// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSearchParams } from 'react-router-dom';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { getCookie } from '@platform360/libs/shared-web/cookies';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const EmailVerified = () => {
    const email = getCookie('verified-email');
    const [searchParams] = useSearchParams();
    const { login } = useAuth();
    const {
        auth: { branding },
    } = useApplicationSettings();

    useEffectOnce(() => {
        void login({
            email,
            branding,
            message: searchParams.get('message') ?? undefined,
            success: searchParams.get('success') ?? undefined,
        });
    });

    return null;
};

export default EmailVerified;
