// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';
import { LabelType } from '@platform360/security-dashboard/shared/label-type';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';

type LabelsPopoverPlacement = 'SERVER_TAB' | 'SITE_TAB';

type SettingsDrawerSafeUpdateOption = SafeUpdateOption | 'parent';

type LabelAction = 'remove' | 'enablePatching';

type DoNotProtectLabelParams = {
    ignoreDoNotProtect: boolean;
    doNotProtectCount: number;
    protectionDisabledTextShown: boolean;
};

export class SecurityDashboardAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    public setSecurityDashboardPartnerCustomer(value: boolean) {
        this.client.setUserProperties({ securityDashboardPartnerCustomer: value });
    }

    public wpServersShown() {
        this.client.logEvent('WP:SERVERS_SHOWN');
    }

    public wpDshVulnerableClick(amount: number) {
        this.client.logEvent('WP:DSH_VULNERABLE_CLICK', { number: amount });
    }

    public wpDshOutdatedClick(amount: number) {
        this.client.logEvent('WP:DSH_OUTDATED_CLICK', { number: amount });
    }

    public wpDshProtectedClick(amount: number) {
        this.client.logEvent('WP:DSH_PROTECTED_CLICK', { number: amount });
    }

    public wpDshUnknownClick(amount: number) {
        this.client.logEvent('WP:DSH_UNKNOWN_CLICK', { number: amount });
    }

    public wpDshSafeClick(amount: number) {
        this.client.logEvent('WP:DSH_SAFE_CLICK', { number: amount });
    }

    public wpDshExploitedClick(amount: number) {
        this.client.logEvent('WP:DSH_EXPLOITED_CLICK', { number: amount });
    }

    public wpDshProtectionClick(amount: number) {
        this.client.logEvent('WP:DSH_PROTECTION_CLICK', { number: amount });
    }

    public wpDshCvssClick(score: number) {
        this.client.logEvent('WP:DSH_CVSS_CLICK', { number: score });
    }

    public wpGloMaintenanceWindowClick() {
        this.client.logEvent('WP:GLO_MAINTENANCE_WINDOW_CLICK');
    }

    public wpGloTaskManagerClick(unreadOperations: number) {
        this.client.logEvent('WP:GLO_TASK_MANAGER_CLICK', { unreadOperations });
    }

    public wpGloNotificationsClick(unreadNotifications: number) {
        this.client.logEvent('WP:GLO_NOTIFICATIONS_CLICK', { unreadNotifications });
    }

    public wpNotificationsShown() {
        this.client.logEvent('WP:NOTIFICATIONS_SHOWN');
    }

    public wpNotificationsClosed() {
        this.client.logEvent('WP:NOTIFICATIONS_CLOSED');
    }

    public wpGloNotificationSettingsClick() {
        this.client.logEvent('WP:GLO_NOTIFICATION_SETTINGS_CLICK');
    }

    public wpMltNotificationsMarkReadClick() {
        this.client.logEvent('WP:MLT_NOTIFICATIONS_MARK_READ_CLICK');
    }

    public wpSglNotificationsRemoveClick() {
        this.client.logEvent('WP:SGL_NOTIFICATIONS_REMOVE_CLICK');
    }

    public wpNotificationDetailsClick() {
        this.client.logEvent('WP:NOTIFICATION_DETAILS_CLICK');
    }

    public wpNotificationSettingsEventSectionItemToggle(
        enabled: boolean,
        type: 'license' | 'exploitedVulnerabilities',
    ) {
        this.client.logEvent('WP:NOTIFICATION_SETTINGS_EVENT_SECTION_ITEM_TOGGLE', {
            enabled,
            type,
        });
    }

    public wpNotificationSettingsChangeEmailConfirmationErrorShown() {
        this.client.logEvent('WP:NOTIFICATION_SETTINGS_CHANGE_EMAIL_CONFIRMATION_ERROR_SHOWN');
    }

    public wpSglNotificationsMarkReadClick() {
        this.client.logEvent('WP:SGL_NOTIFICATIONS_MARK_READ_CLICK');
    }

    public wpSglNotificationsMarkUnreadClick() {
        this.client.logEvent('WP:SGL_NOTIFICATIONS_MARK_UNREAD_CLICK');
    }

    public wpGloAutoupdateSettingsClick() {
        this.client.logEvent('WP:GLO_AUTOUPDATE_SETTINGS_CLICK');
    }

    public wpGloSettingsShown() {
        this.client.logEvent('WP:GLO_SETTINGS_SHOWN');
    }

    public wpGloSettingsClosed() {
        this.client.logEvent('WP:GLO_SETTINGS_CLOSED');
    }

    public wpGloSettingsTabClick(tab: string) {
        this.client.logEvent('WP:GLO_SETTINGS_TAB_CLICK', { tab });
    }

    public wpGloAutoupdateSettingsSave(
        isDefaultSettings: boolean,
        safeUpdates: string,
        changeAll: boolean,
    ) {
        this.client.logEvent('WP:GLO_AUTOUPDATE_SETTINGS_SAVE', {
            isDefaultSettings,
            safeUpdates,
            changeAll,
        });
    }

    public wpGloDailyTaskTimeSettingsSave(isTimeSet: boolean) {
        this.client.logEvent('WP:GLO_DAILY_TASK_TIME_SETTINGS_SAVE', { isTimeSet });
    }

    public wpGloVulnerabilitySettingsSave(ignoreLowRisk: boolean) {
        this.client.logEvent('WP:GLO_VULNERABILITY_SETTINGS_SAVE', { ignoreLowRisk });
    }

    public wpTabServersClick() {
        this.client.logEvent('WP:TAB_SERVERS_CLICK');
    }

    public wpTabWpsitesClick() {
        this.client.logEvent('WP:TAB_WPSITES_CLICK');
    }

    public wpTabVulnerabilitiesClick() {
        this.client.logEvent('WP:TAB_VULNERABILITIES_CLICK');
    }

    public wpVulnerabilitiesShown(total: number, mitigated: number) {
        this.client.logEvent('WP:VULNERABILITIES_SHOWN', { total, mitigated });
    }

    public wpVulnerabilitiesFilterIgnoredApply() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_IGNORED_APPLY');
    }

    public wpVulnerabilitiesFilterExploitedApply() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_EXPLOITED_APPLY');
    }

    public wpVulnerabilitiesFilterNeedProtectionApply() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_NEED_PROTECTION_APPLY');
    }

    public wpVulnerabilitiesFilterCoreApply() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_CORE_APPLY');
    }

    public wpVulnerabilitiesFilterPluginApply() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_PLUGIN_APPLY');
    }

    public wpVulnerabilitiesFilterThemeApply() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_THEME_APPLY');
    }

    public wpVulnerabilitiesSetIgnoreClick(vulnerabilities: number) {
        this.client.logEvent('WP:VULNERABILITIES_SET_IGNORE_CLICK', { vulnerabilities });
    }

    public wpVulnerabilitiesUnsetIgnoreClick(vulnerabilities: number) {
        this.client.logEvent('WP:VULNERABILITIES_UNSET_IGNORE_CLICK', { vulnerabilities });
    }

    public wpVulnerabilitiesFilterReset() {
        this.client.logEvent('WP:VULNERABILITIES_FILTER_RESET');
    }

    public wpFilterSitesVulnerableClick(sites: number) {
        this.client.logEvent('WP:FILTER_SITES_VULNERABLE_CLICK', { sites });
    }

    public wpFilterServersVulnerableClick(sites: number) {
        this.client.logEvent('WP:FILTER_SERVERS_VULNERABLE_CLICK', { sites });
    }

    public wpConnectServerClick() {
        this.client.logEvent('WP:CONNECT_SERVER_CLICK');
    }

    public wpConnectDemoServerClick() {
        this.client.logEvent('WP:CONNECT_DEMO_SERVER_CLICK');
    }

    public wpConnectDemoServerPopoverShown() {
        this.client.logEvent('WP:CONNECT_DEMO_SERVER_POPOVER_SHOWN');
    }

    public wpMltUpdateServerClick(selection: number) {
        this.client.logEvent('WP:MLT_UPDATE_SERVER_CLICK', { selectedItems: selection });
    }

    public wpMltUpdateServerConfirmationClick(selection: number) {
        this.client.logEvent('WP:MLT_UPDATE_SERVER_CONFIRMATION_CLICK', {
            selectedItems: selection,
        });
    }

    public wpMltPatchServerClick(selection: number) {
        this.client.logEvent('WP:MLT_PATCH_SERVER_CLICK', { selectedItems: selection });
    }

    public wpMltPatchServerConfirmationClick(
        selection: number,
        doNotProtectLabelParams: DoNotProtectLabelParams,
    ) {
        this.client.logEvent('WP:MLT_PATCH_SERVER_CONFIRMATION_CLICK', {
            selectedItems: selection,
            ...doNotProtectLabelParams,
        });
    }

    public wpMltRefreshServerClick(selection: number) {
        this.client.logEvent('WP:MLT_REFRESH_SERVER_CLICK', { selectedItems: selection });
    }

    public wpMltFindSitesClick(selection: number) {
        this.client.logEvent('WP:MLT_FIND_SITES_CLICK', { selectedItems: selection });
    }

    public wpMltDisconnectServerClick(selection: number) {
        this.client.logEvent('WP:MLT_DISCONNECT_SERVER_CLICK', { selectedItems: selection });
    }

    public wpMltDisconnectServerConfirmationClick(selection: number) {
        this.client.logEvent('WP:MLT_DISCONNECT_SERVER_CONFIRMATION_CLICK', {
            selectedItems: selection,
        });
    }

    public wpFilterAllClick() {
        this.client.logEvent('WP:FILTER_ALL_CLICK');
    }

    public wpFilterFailedClick() {
        this.client.logEvent('WP:FILTER_FAILED_CLICK');
    }

    public wpFilterAlertsClick() {
        this.client.logEvent('WP:FILTER_ALERTS_CLICK');
    }

    public wpFilterVulnerableClick() {
        this.client.logEvent('WP:FILTER_VULNERABLE_CLICK');
    }

    public wpFilterOutdatedClick() {
        this.client.logEvent('WP:FILTER_OUTDATED_CLICK');
    }

    public wpFilterProtectedClick() {
        this.client.logEvent('WP:FILTER_PROTECTED_CLICK');
    }

    public wpFilterNotProtectedClick() {
        this.client.logEvent('WP:FILTER_NOT_PROTECTED_CLICK');
    }

    public wpFilterUnknownClick() {
        this.client.logEvent('WP:FILTER_UNKNOWN_CLICK');
    }

    public wpFilterOkClick() {
        this.client.logEvent('WP:FILTER_OK_CLICK');
    }

    public wpFilterUnmanagedClick() {
        this.client.logEvent('WP:FILTER_UNMANAGED_CLICK');
    }

    public wpSearchSubmit() {
        this.client.logEvent('WP:SEARCH_SUBMIT');
    }

    public wpSglFilterWpSitesClick(amount: number) {
        this.client.logEvent('WP:SGL_FILTER_WP_SITES_CLICK', { number: amount });
    }

    public wpSglFilterVulnerableClick(amount: number) {
        this.client.logEvent('WP:SGL_FILTER_VULNERABLE_CLICK', { number: amount });
    }

    public wpSglFilterOutdatedClick(amount: number) {
        this.client.logEvent('WP:SGL_FILTER_OUTDATED_CLICK', { number: amount });
    }

    public wpSglFilterUnknownClick(amount: number) {
        this.client.logEvent('WP:SGL_FILTER_UNKNOWN_CLICK', { number: amount });
    }

    public wpTaskManagerShown(total: number) {
        this.client.logEvent('WP:TASK_MANAGER_SHOWN', { total });
    }

    public wpTaskManagerClosed() {
        this.client.logEvent('WP:TASK_MANAGER_CLOSED');
    }

    public wpCrdVulnerableCoreClick() {
        this.client.logEvent('WP:CRD_VULNERABLE_CORE_CLICK');
    }

    public wpCrdVulnerablePluginsClick() {
        this.client.logEvent('WP:CRD_VULNERABLE_PLUGINS_CLICK');
    }

    public wpCrdVulnerableThemesClick() {
        this.client.logEvent('WP:CRD_VULNERABLE_THEMES_CLICK');
    }

    public wpCrdOutdatedCoreClick() {
        this.client.logEvent('WP:CRD_OUTDATED_CORE_CLICK');
    }

    public wpCrdOutdatedPluginsClick() {
        this.client.logEvent('WP:CRD_OUTDATED_PLUGINS_CLICK');
    }

    public wpCrdOutdatedThemesClick() {
        this.client.logEvent('WP:CRD_OUTDATED_THEMES_CLICK');
    }

    public wpCrdUpdateCoreClick() {
        this.client.logEvent('WP:CRD_UPDATE_CORE_CLICK');
    }

    public wpCrdUpdateCoreConfirmationClick() {
        this.client.logEvent('WP:CRD_UPDATE_CORE_CONFIRMATION_CLICK');
    }

    public wpCrdUpdatePluginsClick() {
        this.client.logEvent('WP:CRD_UPDATE_PLUGINS_CLICK');
    }

    public wpCrdUpdatePluginsConfirmationClick() {
        this.client.logEvent('WP:CRD_UPDATE_PLUGINS_CONFIRMATION_CLICK');
    }

    public wpCrdUpdateThemesClick() {
        this.client.logEvent('WP:CRD_UPDATE_THEMES_CLICK');
    }

    public wpCrdUpdateThemesConfirmationClick() {
        this.client.logEvent('WP:CRD_UPDATE_THEMES_CONFIRMATION_CLICK');
    }

    public wpCrdUpdateAllClick() {
        this.client.logEvent('WP:CRD_UPDATE_ALL_CLICK');
    }

    public wpCrdSglAutoupdateSettingsClick() {
        this.client.logEvent('WP:CRD_SGL_AUTOUPDATE_SETTINGS_CLICK');
    }

    public wpCrdSglAutoupdateSettingsShown() {
        this.client.logEvent('WP:CRD_SGL_AUTOUPDATE_SETTINGS_SHOWN');
    }

    public wpCrdSglAutoupdateSettingsSave(
        isDefaultSettings: boolean,
        safeUpdateOption: SafeUpdateOption,
    ) {
        this.client.logEvent('WP:CRD_SGL_AUTOUPDATE_SETTINGS_SAVE', {
            isDefaultSettings,
            safeUpdates: safeUpdateOption,
        });
    }

    public wpCrdSglAutoupdateSettingsClosed() {
        this.client.logEvent('WP:CRD_SGL_AUTOUPDATE_SETTINGS_CLOSED');
    }

    public wpFilterUpdateClick() {
        this.client.logEvent('WP:FILTER_UPDATE_CLICK');
    }

    public wpFilterFixVulnerabilityViaUpdateClick() {
        this.client.logEvent('WP:FILTER_FIX_VULNERABILITY_VIA_UPDATE_CLICK');
    }

    public wpFilterRefreshClick() {
        this.client.logEvent('WP:FILTER_REFRESH_CLICK');
    }

    public wpFilterFindNewSitesClick() {
        this.client.logEvent('WP:FILTER_FIND_NEW_SITES_CLICK');
    }

    public wpFilterConfigureAutoupdatesClick() {
        this.client.logEvent('WP:FILTER_CONFIGURE_AUTOUPDATES_CLICK');
    }

    public wpFiltersetDailyTaskTimeClick() {
        this.client.logEvent('WP:FILTER_SET_DAILY_TASK_TIME_CLICK');
    }

    public wpFilterMitigationDeactivateAssetClick() {
        this.client.logEvent('WP:FILTER_MITIGATION_DEACTIVATE_ASSET_CLICK');
    }

    public wpFilterMitigationActivateAssetClick() {
        this.client.logEvent('WP:FILTER_MITIGATION_ACTIVATE_ASSET_CLICK');
    }

    public wpFilterIgnoreVulnerabilitiesClick() {
        this.client.logEvent('WP:FILTER_IGNORE_VULNERABILITIES_CLICK');
    }

    public wpFilterCancelIgnoreVulnerabilitiesClick() {
        this.client.logEvent('WP:FILTER_CANCEL_IGNORE_VULNERABILITIES_CLICK');
    }

    public wpSglSiteVulnerabilitiesShown(installationId: number, total: number, mitigated: number) {
        this.client.logEvent('WP:SGL_SITE_VULNERABILITIES_SHOWN', {
            installationId,
            total,
            mitigated,
        });
    }

    public wpSglSiteVulnerabilitiesClose() {
        this.client.logEvent('WP:SGL_SITE_VULNERABILITIES_CLOSE');
    }

    public wpSglSiteOutdatedShown(installationId: number, total: number) {
        this.client.logEvent('WP:SGL_SITE_OUTDATED_SHOWN', { installationId, total });
    }

    public wpSglSiteOutdatedClose() {
        this.client.logEvent('WP:SGL_SITE_OUTDATED_CLOSE');
    }

    public wpTabSiteVulnerabilitiesClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_CLICK');
    }

    public wpTabSiteOutdatedClick() {
        this.client.logEvent('WP:TAB_SITE_OUTDATED_CLICK');
    }

    public wpSglSiteUpdateClick(selectedItems: number) {
        this.client.logEvent('WP:SGL_SITE_UPDATE_CLICK', { selectedItems });
    }

    public wpSglSiteItemUpdateClick() {
        this.client.logEvent('WP:SGL_SITE_ITEM_UPDATE_CLICK');
    }

    public wpSglSiteVulnerabilityUpdateClick(selectedItems: number) {
        this.client.logEvent('WP:SGL_SITE_VULNERABILITY_UPDATE_CLICK', { selectedItems });
    }

    public wpSglSiteVulnerabilityUpdateConfirmationClick(selectedItems: number) {
        this.client.logEvent('WP:SGL_SITE_VULNERABILITY_UPDATE_CONFIRMATION_CLICK', {
            selectedItems,
        });
    }

    public wpSglSiteItemVulnerabilityUpdateClick() {
        this.client.logEvent('WP:SGL_SITE_ITEM_VULNERABILITY_UPDATE_CLICK');
    }

    public wpSglSiteItemVulnerabilityUpdateConfirmationClick() {
        this.client.logEvent('WP:SGL_SITE_ITEM_VULNERABILITY_UPDATE_CONFIRMATION_CLICK');
    }

    public wpTabSiteVulnerabilitiesNotPatchedInfoClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_NOT_PATCHED_INFO_CLICK');
    }

    public wpTabSiteVulnerabilitiesFilterExploitedClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_EXPLOITED_CLICK');
    }

    public wpTabSiteVulnerabilitiesFilterCoreClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_CORE_CLICK');
    }

    public wpTabSiteVulnerabilitiesFilterPluginsClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_PLUGINS_CLICK');
    }

    public wpTabSiteVulnerabilitiesFilterThemesClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_THEMES_CLICK');
    }

    public wpTabSiteVulnerabilitiesFilterAllClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_ALL_CLICK');
    }

    public wpTabSiteVulnerabilitiesSearchSubmit() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_SEARCH_SUBMIT');
    }

    public wpTabSiteOutdatedFilterFormSubmit() {
        this.client.logEvent('WP:TAB_SITE_OUTDATED_FILTER_FORM_SUBMIT');
    }

    public wpTabSiteOutdatedFilterResetClick() {
        this.client.logEvent('WP:TAB_SITE_OUTDATED_FILTER_RESET_CLICK');
    }

    public wpTabSiteOutdatedSearchSubmit() {
        this.client.logEvent('WP:TAB_SITE_OUTDATED_SEARCH_SUBMIT');
    }

    public wpTabSiteHealthDrawerProtectClick() {
        this.client.logEvent('WP:TAB_SITE_HEALTH_DRAWER_PROTECT_CLICK');
    }

    public wpSglKbbUpdateClick() {
        this.client.logEvent('WP:SGL_KBB_UPDATE_CLICK');
    }

    public wpSglKbbPatchClick() {
        this.client.logEvent('WP:SGL_KBB_PATCH_CLICK');
    }

    public wpSglKbbPatchConfirmationClick(doNotProtectLabelParams: DoNotProtectLabelParams) {
        this.client.logEvent('WP:SGL_KBB_PATCH_CONFIRMATION_CLICK', doNotProtectLabelParams);
    }

    public wpSglKbbUpdateConfirmationClick() {
        this.client.logEvent('WP:SGL_KBB_UPDATE_CONFIRMATION_CLICK');
    }

    public wpSglKbbFindVulnerabilitiesClick() {
        this.client.logEvent('WP:SGL_KBB_FIND_VULNERABILITIES_CLICK');
    }

    public wpSglKbbDisablePatchClick() {
        this.client.logEvent('WP:SGL_KBB_DISABLE_PATCH_CLICK');
    }

    public wpSglKbbDisablePatchConfirmationClick() {
        this.client.logEvent('WP:SGL_KBB_DISABLE_PATCH_CONFIRMATION_CLICK');
    }

    public wpSglKbbFindSitesClick() {
        this.client.logEvent('WP:SGL_KBB_FIND_SITES_CLICK');
    }

    public wpWordpressSitesShown() {
        this.client.logEvent('WP:WORDPRESS_SITES_SHOWN');
    }

    public wpMltUpdateSiteClick(selection: number) {
        this.client.logEvent('WP:MLT_UPDATE_SITE_CLICK', { selectedItems: selection });
    }

    public wpMltUpdateSiteConfirmationClick(selection: number) {
        this.client.logEvent('WP:MLT_UPDATE_SITE_CONFIRMATION_CLICK', { selectedItems: selection });
    }

    public wpMltRefreshSiteClick(selection: number) {
        this.client.logEvent('WP:MLT_REFRESH_SITE_CLICK', { selectedItems: selection });
    }

    public wpSglUpdateSiteClick() {
        this.client.logEvent('WP:SGL_UPDATE_SITE_CLICK');
    }

    public wpSglRefreshSiteClick() {
        this.client.logEvent('WP:SGL_REFRESH_SITE_CLICK');
    }

    public wpSglProtectClick() {
        this.client.logEvent('WP:SGL_PROTECT_CLICK');
    }

    public wpSglProtectConfirmationClick() {
        this.client.logEvent('WP:SGL_PROTECT_CONFIRMATION_CLICK');
    }

    public wpSglProtectDisableClick(installationId: number, withConfirmation: boolean) {
        this.client.logEvent('WP:SGL_PROTECT_DISABLE_CLICK', { installationId, withConfirmation });
    }

    public wpSglProtectDisableConfirmationClick(installationId: number) {
        this.client.logEvent('WP:SGL_PROTECT_DISABLE_CONFIRMATION_CLICK', {
            installationId,
        });
    }

    public wpMltProtectInstallationsClick(selectedItems: number) {
        this.client.logEvent('WP:MLT_PROTECT_INSTALLATIONS_CLICK', { selectedItems });
    }

    public wpMltProtectInstallationsConfirmationClick(
        selectedItems: number,
        doNotProtectLabelParams: DoNotProtectLabelParams,
    ) {
        this.client.logEvent('WP:MLT_PROTECT_INSTALLATIONS_CONFIRMATION_CLICK', {
            selectedItems,
            ...doNotProtectLabelParams,
        });
    }

    public wpSglVulnerableClick() {
        this.client.logEvent('WP:SGL_VULNERABLE_CLICK');
    }

    public wpSglOutdatedClick() {
        this.client.logEvent('WP:SGL_OUTDATED_CLICK');
    }

    public wpSglUnsupportedAgentClick() {
        this.client.logEvent('WP:SGL_UNSUPPORTED_AGENT_CLICK');
    }

    public wpSglUnknownClick() {
        this.client.logEvent('WP:SGL_UNKNOWN_CLICK');
    }

    public wpSglKbbMaintenanceWindowClick() {
        this.client.logEvent('WP:SGL_KBB_MAINTENANCE_WINDOW_CLICK');
    }

    public wpSrvKbbAutoupdateSettingsClick() {
        this.client.logEvent('WP:SRV_KBB_AUTOUPDATE_SETTINGS_CLICK');
    }

    public wpMltServerSettingsClick() {
        this.client.logEvent('WP:MLT_SERVER_SETTINGS_CLICK');
    }

    public wpSrvSettingsShown() {
        this.client.logEvent('WP:SRV_SETTINGS_SHOWN');
    }

    public wpSrvSettingsClosed() {
        this.client.logEvent('WP:SRV_SETTINGS_CLOSED');
    }

    public wpSrvSettingsTabClick(tab: string) {
        this.client.logEvent('WP:SRV_SETTINGS_TAB_CLICK', { tab });
    }

    public wpSrvAutoupdateSettingsSave(
        safeUpdate: SettingsDrawerSafeUpdateOption,
        changeAll: boolean,
    ) {
        this.client.logEvent('WP:SRV_AUTOUPDATE_SETTINGS_SAVE', {
            safeUpdate,
            changeAll,
        });
    }

    public wpSrvDailyTaskTimeSettingsSave(isTimeSet: boolean) {
        this.client.logEvent('WP:SRV_DAILY_TASK_TIME_SETTINGS_SAVE', { isTimeSet });
    }

    public wpSglKbbAutoupdateSettingsClick() {
        this.client.logEvent('WP:SGL_KBB_AUTOUPDATE_SETTINGS_CLICK');
    }

    public wpSglAutoupdateSettingsShown() {
        this.client.logEvent('WP:SGL_AUTOUPDATE_SETTINGS_SHOWN');
    }

    public wpSglAutoupdateSettingsSave(safeUpdate: SettingsDrawerSafeUpdateOption) {
        this.client.logEvent('WP:SGL_AUTOUPDATE_SETTINGS_SAVE', {
            safeUpdate,
        });
    }

    public wpSglAutoupdateSettingsClosed() {
        this.client.logEvent('WP:SGL_AUTOUPDATE_SETTINGS_CLOSED');
    }

    public wpSglKbbDisconnectServerClick() {
        this.client.logEvent('WP:SGL_KBB_DISCONNECT_SERVER_CLICK');
    }

    public wpSglKbbDisconnectServerConfirmationClick() {
        this.client.logEvent('WP:SGL_KBB_DISCONNECT_SERVER_CONFIRMATION_CLICK');
    }

    public wpSglKbbDetachInstallationClick() {
        this.client.logEvent('WP:SGL_KBB_DETACH_INSTALLATION_CLICK');
    }

    public wpSglKbbDetachInstallationConfirmationClick() {
        this.client.logEvent('WP:SGL_KBB_DETACH_INSTALLATION_CONFIRMATION_CLICK');
    }

    public wpConnectServerShown() {
        this.client.logEvent('WP:CONNECT_SERVER_SHOWN');
    }

    public wpConnectServerRefreshClick(time: number) {
        this.client.logEvent('WP:CONNECT_SERVER_REFRESH_CLICK', { time });
    }

    public wpConnectServerClosed() {
        this.client.logEvent('WP:CONNECT_SERVER_CLOSED');
    }

    public wpCopyToClipboardClick() {
        this.client.logEvent('WP:COPY_TO_CLIPBOARD_CLICK');
    }

    public wpLicenseActivationLinkVisit(product: string) {
        this.client.logEvent('WP:LICENSE_ACTIVATION_LINK_VISIT', { product });
    }

    public wpLicenseActivationSuccessful(product: string) {
        this.client.logEvent('WP:LICENSE_ACTIVATION_SUCCESSFUL', { product });
    }

    public wpLicenseActivationFailed(product: string) {
        this.client.logEvent('WP:LICENSE_ACTIVATION_FAILED', { product });
    }

    public wpLicenseActivationDialogShown(product: string) {
        this.client.logEvent('WP:LICENSE_ACTIVATION_DIALOG_SHOWN', { product });
    }

    public wpLicenseActivationConfirmed(product: string) {
        this.client.logEvent('WP:LICENSE_ACTIVATION_CONFIRMED', { product });
    }

    public wpLicenseActivationCancelled(product: string) {
        this.client.logEvent('WP:LICENSE_ACTIVATION_CANCELLED', { product });
    }

    public wpMltUpdateVulnerabilitiesClick(selection: number) {
        this.client.logEvent('WP:MLT_UPDATE_VULNERABILITIES_CLICK', { selectedItems: selection });
    }

    public wpMltUpdateVulnerabilitiesConfirmationClick(selection: number) {
        this.client.logEvent('WP:MLT_UPDATE_VULNERABILITIES_CONFIRMATION_CLICK', {
            selectedItems: selection,
        });
    }

    public wpMltProtectVulnerabilitiesClick(selection: number, isLicenseInfoShown: boolean) {
        this.client.logEvent('WP:MLT_PROTECT_VULNERABILITIES_CLICK', {
            selectedItems: selection,
            isLicenseInfoShown,
        });
    }

    public wpMltProtectVulnerabilitiesConfirmationClick(
        selection: number,
        doNotProtectLabelParams: DoNotProtectLabelParams,
    ) {
        this.client.logEvent('WP:MLT_PROTECT_VULNERABILITIES_CONFIRMATION_CLICK', {
            selectedItems: selection,
            ...doNotProtectLabelParams,
        });
    }

    public wpMltProtectVulnerabilitiesLicenseClick() {
        this.client.logEvent('WP:MLT_PROTECT_VULNERABILITIES_LICENSE_CLICK');
    }

    public wpSglUpdateVulnerabilityClick(
        vulnerabilityId: string,
        affectedSites: number,
        enablePluginsAfterUpdate: boolean,
    ) {
        this.client.logEvent('WP:SGL_UPDATE_VULNERABILITY_CLICK', {
            item: vulnerabilityId,
            affectedSites,
            enablePluginsAfterUpdate,
        });
    }

    public wpSglUpdateVulnerabilityConfirmationClick(
        vulnerabilityId: string,
        affectedSites: number,
        enablePluginsAfterUpdate: boolean,
    ) {
        this.client.logEvent('WP:SGL_UPDATE_VULNERABILITY_CONFIRMATION_CLICK', {
            item: vulnerabilityId,
            affectedSites,
            enablePluginsAfterUpdate,
        });
    }

    public wpSglProtectVulnerabilityClick(vulnerabilityId: string, affectedSites: number) {
        this.client.logEvent('WP:SGL_PROTECT_VULNERABILITY_CLICK', {
            item: vulnerabilityId,
            affectedSites,
        });
    }

    public wpSglProtectVulnerabilityConfirmationClick(
        vulnerabilityId: string,
        affectedSites: number,
        doNotProtectLabelParams: DoNotProtectLabelParams,
    ) {
        this.client.logEvent('WP:SGL_PROTECT_VULNERABILITY_CONFIRMATION_CLICK', {
            item: vulnerabilityId,
            affectedSites,
            ...doNotProtectLabelParams,
        });
    }

    public wpSglVulnerabilityDisablePluginsClick(vulnerabilityId: string, affectedSites: number) {
        this.client.logEvent('WP:SGL_VULNERABILITY_DISABLE_PLUGINS_CLICK', {
            item: vulnerabilityId,
            affectedSites,
        });
    }

    public wpSglVulnerabilityDisablePluginsConfirmationClick(
        vulnerabilityId: string,
        affectedSites: number,
    ) {
        this.client.logEvent('WP:SGL_VULNERABILITY_DISABLE_PLUGINS_CONFIRMATION_CLICK', {
            item: vulnerabilityId,
            affectedSites,
        });
    }

    public wpSglVulnerabilityEnablePluginsClick(vulnerabilityId: string, affectedSites: number) {
        this.client.logEvent('WP:SGL_VULNERABILITY_ENABLE_PLUGINS_CLICK', {
            item: vulnerabilityId,
            affectedSites,
        });
    }

    public wpSglVulnerabilityEnablePluginsConfirmationClick(
        vulnerabilityId: string,
        affectedSites: number,
    ) {
        this.client.logEvent('WP:SGL_VULNERABILITY_ENABLE_PLUGINS_CONFIRMATION_CLICK', {
            item: vulnerabilityId,
            affectedSites,
        });
    }

    public wpLicensePageShown() {
        this.client.logEvent('WP:LICENSE_PAGE_SHOWN');
    }

    public wpLicenseDialogUnlimitedShown() {
        this.client.logEvent('WP:LICENSE_DIALOG_UNLIMITED_SHOWN');
    }

    public wpLicenseDialogStandardShown(sitesNumber: number) {
        this.client.logEvent('WP:LICENSE_DIALOG_STANDARD_SHOWN', { sitesNumber });
    }

    public wpKeyAdministratorLinkClicked() {
        this.client.logEvent('WP:LICENSE_KEY_ADMINISTRATOR_LINK_CLICKED');
    }

    public wpManage2LinkClicked() {
        this.client.logEvent('WP:LICENSE_MANAGE2_LINK_CLICKED');
    }

    public wpTabSiteVulnerabilitiesFilterFormSubmit() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_FORM_SUBMIT');
    }

    public wpTabSiteVulnerabilitiesFilterResetClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_FILTER_RESET_CLICK');
    }

    public wpTabSiteVulnerabilitiesDisablePluginClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_DISABLE_PLUGIN_CLICK');
    }

    public wpTabSiteVulnerabilitiesEnablePluginClick() {
        this.client.logEvent('WP:TAB_SITE_VULNERABILITIES_ENABLE_PLUGIN_CLICK');
    }

    public wpLabelsAssignedToSites(sitesAffected: number, labelsAmount: number) {
        this.client.logEvent('WP:LABELS_ASSIGNED_TO_SITES', { sitesAffected, labelsAmount });
    }

    public wpLabelsRemovedFromSites(sitesAffected: number, labelsAmount: number) {
        this.client.logEvent('WP:LABELS_REMOVED_FROM_SITES', { sitesAffected, labelsAmount });
    }

    public wpSiteLabelMenuActionClick(labelType: LabelType, action: LabelAction) {
        this.client.logEvent('WP:SITE_LABEL_MENU_ACTION_CLICK', { labelType, action });
    }

    public wpSiteLabelMenuActionConfirmationClick(labelType: LabelType, action: LabelAction) {
        this.client.logEvent('WP:SITE_LABEL_MENU_ACTION_CONFIRMATION_CLICK', {
            labelType,
            action,
        });
    }

    public wpLabelsAssignedToServers(serversAffected: number, labelsAmount: number) {
        this.client.logEvent('WP:LABELS_ASSIGNED_TO_SERVERS', { serversAffected, labelsAmount });
    }

    public wpLabelsRemovedFromServers(serversAffected: number, labelsAmount: number) {
        this.client.logEvent('WP:LABELS_REMOVED_FROM_SERVERS', { serversAffected, labelsAmount });
    }

    public wpServerLabelMenuActionClick(labelType: LabelType, action: LabelAction) {
        this.client.logEvent('WP:SERVER_LABEL_MENU_ACTION_CLICK', { labelType, action });
    }

    public wpLabelsPopoverShown(placement: LabelsPopoverPlacement) {
        this.client.logEvent('WP:LABELS_POPOVER_SHOWN', { placement });
    }

    public wpLabelsPopoverClosed(placement: LabelsPopoverPlacement) {
        this.client.logEvent('WP:LABELS_POPOVER_CLOSED', { placement });
    }

    public wpLabelsDrawerShown() {
        this.client.logEvent('WP:LABELS_DRAWER_SHOWN');
    }

    public wpLabelsDrawerClosed() {
        this.client.logEvent('WP:LABELS_DRAWER_CLOSED');
    }

    public wpLabelsListSearchSubmit() {
        this.client.logEvent('WP:LABELS_LIST_SEARCH_SUBMIT');
    }

    public wpSglKbbDeleteLabelClick() {
        this.client.logEvent('WP:SGL_KBB_DELETE_LABEL_CLICK');
    }

    public wpSglKbbDeleteLabelConfirmationClick() {
        this.client.logEvent('WP:SGL_KBB_DELETE_LABEL_CONFIRMATION_CLICK');
    }

    public wpMltDeleteLabelsClick() {
        this.client.logEvent('WP:MLT_DELETE_LABELS_CLICK');
    }

    public wpMltDeleteLabelsConfirmationClick() {
        this.client.logEvent('WP:MLT_DELETE_LABELS_CONFIRMATION_CLICK');
    }

    public wpCreateLabelDrawerShown() {
        this.client.logEvent('WP:CREATE_LABEL_DRAWER_SHOWN');
    }

    public wpCreateLabelDrawerClosed() {
        this.client.logEvent('WP:CREATE_LABEL_DRAWER_CLOSED');
    }

    public wpCreateLabelDrawerSubmit() {
        this.client.logEvent('WP:CREATE_LABEL_DRAWER_SUBMIT');
    }

    public wpEditLabelDrawerShown() {
        this.client.logEvent('WP:EDIT_LABEL_DRAWER_SHOWN');
    }

    public wpEditLabelDrawerClosed() {
        this.client.logEvent('WP:EDIT_LABEL_DRAWER_CLOSED');
    }

    public wpEditLabelDrawerSubmit() {
        this.client.logEvent('WP:EDIT_LABEL_DRAWER_SUBMIT');
    }

    public wpFilterLabelsClick(placement: string) {
        this.client.logEvent('WP:FILTER_LABELS_CLICK', { placement });
    }

    public wpQuickStartShown() {
        this.client.logEvent('WP:QUICK_START_SHOWN');
    }

    public wpAddServerDrawerDocumentationClick() {
        this.client.logEvent('WP:ADD_SERVER_DRAWER_DOCUMENTATION_CLICK');
    }

    public wpQuickStartStepSubmitted(stepName: string) {
        this.client.logEvent('WP:QUICK_START_STEP_SUBMITTED', { stepName });
    }

    public wpQuickStartStepOpened(stepName: string) {
        this.client.logEvent('WP:QUICK_START_STEP_OPENED', { stepName });
    }

    public wpQuickStartClosed() {
        this.client.logEvent('WP:QUICK_START_CLOSED');
    }

    public wpQuickStartDailyTaskTimeSettingsSave(isTimeSet: boolean) {
        this.client.logEvent('WP:QUICK_START_DAILY_TASK_TIME_SETTINGS_SAVE', { isTimeSet });
    }

    public wpQuickStartIgnoreLowRiskSettingsSave(ignoreLowRisk: boolean) {
        this.client.logEvent('WP:QUICK_START_IGNORE_LOW_RISK_SETTINGS_SAVE', { ignoreLowRisk });
    }

    public wpEnterPromoCode(promoCode: string) {
        this.client.logEvent('WP:ENTER_PROMO_CODE', { promoCode });
    }

    public wpToasterShown(toasterType: string) {
        this.client.logEvent('WP:TOASTER_SHOWN', { toasterType });
    }

    public wpMltSiteSettingsClick(installationsCount: number) {
        this.client.logEvent('WP:MLT_SITE_SETTINGS_CLICK', { installationsCount });
    }

    public wpLicensePurchaseCompleted(productName: string): void {
        this.client.logEvent('WP:LICENSE_PURCHASE_COMPLETED', { productName });
    }

    public wpLicensePageRetailPurchaseDrawerShown(productName: string): void {
        this.client.logEvent('WP:LICENSE_PAGE_RETAIL_PURCHASE_DRAWER_SHOWN', { productName });
    }

    public wpLicensePageRetailPurchaseDrawerClosed(productName: string): void {
        this.client.logEvent('WP:LICENSE_PAGE_RETAIL_PURCHASE_DRAWER_CLOSED', { productName });
    }

    public wpLicensePageLicenseRetailListBuyClicked(productName: string): void {
        this.client.logEvent('WP:LICENSE_PAGE_LICENSE_RETAIL_LIST_BUY_CLICKED', { productName });
    }

    public wpLicensePageLicenseRetailListUpgradeClicked(productName: string): void {
        this.client.logEvent('WP:LICENSE_PAGE_LICENSE_RETAIL_LIST_UPGRADE_CLICKED', {
            productName,
        });
    }

    public wpLicensePageSeeDetailsLinkEmailPopoverShown(): void {
        this.client.logEvent('WP:LICENSE_PAGE_SEE_DETAILS_LINK_EMAIL_POPOVER_SHOWN');
    }

    public wpLicensePageUpgradeLinkEmailPopoverShown(): void {
        this.client.logEvent('WP:LICENSE_PAGE_UPGRADE_LINK_EMAIL_POPOVER_SHOWN');
    }

    public wpLicensePageLinkEmailClicked(): void {
        this.client.logEvent('WP:LICENSE_PAGE_LINK_EMAIL_CLICKED');
    }

    public wpLicensePageLicenseRetailRecommendedBuyClicked(productName: string): void {
        this.client.logEvent('WP:LICENSE_PAGE_LICENSE_RETAIL_RECOMMENDED_BUY_CLICKED', {
            productName,
        });
    }

    public wpLicensePageLicenseRetailRecommendedUpgradeClicked(productName: string): void {
        this.client.logEvent('WP:LICENSE_PAGE_LICENSE_RETAIL_RECOMMENDED_UPGRADE_CLICKED', {
            productName,
        });
    }

    public wpLicensePageLicenseRetailLinkBecomePartnerClicked(): void {
        this.client.logEvent('WP:LICENSE_PAGE_LICENSE_RETAIL_LINK_BECOME_PARTNER_CLICKED');
    }

    public wpLicensePageRetailDetailsClicked(): void {
        this.client.logEvent('WP:LICENSE_PAGE_RETAIL_DETAILS_CLICKED');
    }

    public wpCleverBridgeError(): void {
        this.client.logEvent('WP:CLEVER_BRIDGE_ERROR');
    }
}
