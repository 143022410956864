// Copyright 2024. WebPros International GmbH. All rights reserved.

import { PaymentInfo } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import isCardPaymentType from '@platform360/licenses/web/helpers/cleverbridge/isCardPaymentType';
import isCardExpired from '@platform360/licenses/web/helpers/isCardExpired';

const isCleverbridgePaymentExpired = (paymentInfo: PaymentInfo | null | undefined): boolean => {
    if (paymentInfo) {
        if (!isCardPaymentType(paymentInfo.PaymentTypeId)) {
            return false;
        }

        if (
            isCardExpired({
                month: Number(paymentInfo.CardExpirationDate?.Month) || 0,
                year: Number(paymentInfo.CardExpirationDate?.Year) || 0,
            })
        ) {
            return true;
        }
    }

    return false;
};

export default isCleverbridgePaymentExpired;
