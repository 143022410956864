// Copyright 2024. WebPros International GmbH. All rights reserved.

import { updateInstallation } from '@platform360/security-dashboard/web/api/installations';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import { useInstallationInfoQuery } from '@platform360/security-dashboard/web/queries';

export const useInstallationUpdaterMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateInstallation,
        onSuccess: async () => {
            refetchList(useInstallationsQuery.getQueryKey());

            await queryClient.invalidateQueries({
                queryKey: useInstallationInfoQuery.getQueryKey(),
            });
        },
    });
};
