// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'Se ha detectado una clave pública en lugar de la privada que se esperaba.',
        passphrasePrivateKeyError: 'Proporcione una clave que no esté protegida con una frase de contraseña.',
        unsupportedPrivateKeyError: 'El tipo de clave proporcionada no es compatible.',
        invalidPrivateKeyError: 'Asegúrese de que está usando la clave privada SSH correcta.',
        emptyPrivateKeyError: 'La clave privada SSH proporcionada no es válida porque está vacía.',
        tooBigPrivateKeyError: 'Proporcione un archivo de clave con un tamaño inferior a 30 KB.',
    },
    Installation: {
        startAnotherInstallation: 'Iniciar otra instalación',
        steps: {
            running: {
                title: 'Plesk se está instalando',
                description: 'Instalando Plesk en <b>{host}</b>',
            },
            error: {
                title: 'Error de instalación',
            },
            completed: {
                title: 'Instalación completada',
                pleskHostText: 'Plesk se instaló en {host}.',
                goToPleskText: 'Haga clic en "Ir a Plesk" para iniciar sesión. Tras un breve proceso de configuración, podrá empezar a usar Plesk.',
                goToPleskButton: 'Ir a Plesk',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Servidor desconectado',
            description: 'Se ha perdido la conexión SSH al servidor.',
        },
        connectFail: {
            title: 'Error de conexión con el servidor',
            description: '<p>No se puede conectar con el servidor: {details}.</p><p>Si el servidor usa un puerto SSH personalizado, especifíquelo añadiendo dos puntos y el número del puerto después del nombre de host o la dirección IP (p. ej.: webdeejemplo.com:1024 o 192.0.2.0:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk ya está instalado',
            description: 'Se ha producido un error inesperado mientras se detectaba si Plesk estaba instalado: {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com no está disponible',
            description: 'No se pueden recuperar los distributivos de Plesk. El sitio web autoinstall.plesk.com no está disponible: {details}.',
        },
        pleskInstalled: {
            title: 'Plesk está instalado',
            description: 'Web Installer solo instala {productType} y <b>{host}</b> ya tiene instalada la versión estable de Plesk. {productType, select, Plesk {¿Esperaba que se efectuara alguna otra operación? Háganoslo saber mediante <surveyLink>esta encuesta de una sola pregunta</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'Mejora disponible',
            description: 'Web Installer solo instala {productType} y <b>{host}</b> ya tiene Plesk instalado, que no es la versión estable más reciente. {productType, select, Plesk {¿Esperaba que Plesk Web Installer la actualizara? Háganoslo saber mediante <surveyLink>esta encuesta de una sola pregunta</surveyLink>.} other {}}',
        },
        unsupportedArch: {
            title: 'Arquitectura del servidor no compatible',
            description: 'Está intentando instalar {productType} en una arquitectura no compatible: {arch}.',
        },
        unsupportedOs: {
            title: 'Sistema operativo no compatible',
            description: 'Está intentando instalar {productType} en un sistema operativo (arquitectura) no compatible: {os}. <supportedOsLink>Consulte la lista de sistemas operativos compatibles</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Espacio en disco insuficiente',
        },
        cpanelInstalled: {
            title: 'cPanel está instalado',
            description: 'Web Installer solo instala {productType} en servidores limpios y <b>{host}</b> ya tiene cPanel instalado. {productType, select, Plesk {Infórmese sobre cómo transferir sitios web alojados a Plesk leyendo nuestra <migrationGuideLink>Guía de migración</migrationGuideLink>} other {¿Esperaba que efectuara alguna otra operación? Háganoslo saber mediante <surveyLink>esta encuesta de una sola pregunta</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Compruebe el estado del administrador de paquetes',
            description: 'El administrador de paquetes está ocupado o no responde: {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Ha habido un error en la configuración del repositorio del administrador de paquetes',
            description: 'Se ha producido un error inesperado mientras se configuraba el repositorio del administrador de paquetes: {details}.',
        },
        dbConfigurationFailed: {
            title: 'Error de configuración de la base de datos',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'Su tranquilidad es lo más importante: WebPros no almacena sus credenciales una vez completada la instalación.',
            p2: 'Puede instalar {productType} en <a>cualquier SO compatible basado en Linux</a>.',
        },
        hostLabel: 'Nombre de host o dirección IP del servidor',
        portLabel: 'Puerto',
        loginLabel: 'Inicio de sesión SSH',
        installationMethodLabel: 'Conectarse al servidor usando',
        sshPasswordLabel: 'Contraseña SSH',
        sshKeyLabel: 'Clave SSH privada',
        licenseTypeLabel: 'Seleccione el tipo de licencia',
        trialLicenseLabel: 'Proceder con una licencia de prueba totalmente funcional',
        trialLicenseDescription: 'Con el fin de poder emitir la licencia de prueba, WebPros International GmbH usará y procesará la información indicada en sus sistemas internos conforme a la <privacyPolicyLink>Política de privacidad de WebPros</privacyPolicyLink>.',
        paidLicenseLabel: 'Proceder con una licencia de pago',
        paidLicenseDescription: 'Si ya ha comprado una licencia de {productType}, pegue aquí el código de activación que ha recibido. De lo contrario, puede comprar una licencia nueva en nuestra <onlineStoreLink>tienda online</onlineStoreLink>.',
        installOsUpdatesLabel: 'Instale actualizaciones del SO antes de iniciar la instalación de {productType}',
        installOsUpdatesDescription: 'Al seleccionar esta opción, el proceso de instalación puede ralentizarse mucho o puede incluso fallar.',
        notice: 'La instalación suele durar unos 15 minutos.',
        techDomainDescription: 'Su servidor se añadirá con el nombre de <b>{techDomain}</b>.',
        serverConnectionSection: 'Conexión del servidor',
        licenseSection: 'Licencia',
        advancedSection: 'Avanzado',
        submitButton: 'Siguiente',
    },
    ServerInstallationPage: {
        submitButton: 'Siguiente',
        panelTitle: 'Instalación automática de {productType} en un servidor Linux',
        licenceLabel: 'Licencia de prueba',
        installationWillTake: 'La instalación suele durar unos 15 minutos',
        helpUsImprove: 'Ayúdenos a mejorar {productType} Web Installer',
        takeOurSurvey: 'Realizar nuestra encuesta',
        links: {
            manualInstallationInstructions: 'Instrucciones de instalación manual',
            softwareRequirements: {
                plesk: 'Requisitos del software',
                cpanel: 'Requisitos del sistema',
            },
            versionsAndComponents: 'Versiones y componentes',
            knownIssues: 'Incidencias conocidas',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'Su email',
        legalAgreementTitle: 'Estoy de acuerdo con los <termsOfUseLink>Términos de uso</termsOfUseLink> y la <privacyPolicyLink>Política de privacidad</privacyPolicyLink>, y por la presente acepto firmar el <dpaLink>Contrato de procesamiento de datos</dpaLink> de Plesk',
        emailHint: 'Si ya tiene una cuenta de WebPros, utilice este email. Si no, se le creará una.',
        serversMonitoring: 'Monitorización de la disponibilidad, el estado y el rendimiento de los servidores de forma gratuita para 1 servidor y 5 sitios web',
        loginNoCredentials: 'Cree su WebPros Account para acceder fácilmente a todos los servicios de la empresa con un solo clic',
        login: 'Inicie sesión en su instalación de {productType} sin introducir credenciales',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        submitButton: 'Iniciar instalación',
        marketingConsent: {
            title: 'WebPros puede enviarme newsletters y ofertas individuales de acuerdo con la <a>Política de privacidad</a> de WebPros.',
            text: 'Puedo revocar este consentimiento haciendo clic en el enlace de cualquier comunicación recibida',
        },
    },
    LightsailInstallation: {
        title: 'Instalación automática en un servidor cloud',
        awsAccessKey: 'ID de clave de acceso',
        awsSecretKey: 'Clave de acceso secreta',
        region: 'Región',
        bundleTitle: 'Plan de la instancia',
        publicKeyTitle: 'Clave SSH pública',
        instanceName: 'Nombre de la instancia',
        submitButton: 'Continuar',
        BundleSelect: {
            price: '${value} al mes', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Memoria {value} GB',
            storage: 'Almacenamiento {value} GB',
            transfer: 'Transferir {value} GB',
            descriptions: {
                micro: 'La mejor opción si desea probar Plesk.',
                small: 'Ideal para uno o dos sitios web personales.',
                medium: 'Apropiado para agencias o profesionales web que trabajen en pocos sitios web.',
                large: 'Adecuado para grandes agencias web o pequeños hosters dedicados a múltiples sitios web de poco tráfico o un par de sitios de tráfico elevado.',
                xlarge: 'Para hosters que gestionen múltiples sitios web de carga y tráfico elevado.',
                '2xlarge': 'Para los proyectos más ambiciosos.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'Para iniciar sesión en su cuenta de cPanel, haga clic en el botón "Iniciar sesión en cPanel". <b>NOTA:</b> ¡El botón solo funcionará una vez!',
        loginButton: 'Iniciar sesión en cPanel',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'FAQs sobre la instalación automática de Plesk',
            versionsAndComponents: 'Versiones y componentes',
            knownIssues: 'Incidencias conocidas',
            whatVersionIsInstalledTitle: '¿Qué versión de Plesk se instala en el modo automático?',
            whatVersionIsInstalledText: 'La última versión estable de Plesk con todas las actualizaciones disponibles en ese momento. Para instalar una versión de Plesk diferente, siga las <link>instrucciones de instalación</link>.',
            whatComponentsAreInstalledTitle: '¿Qué componentes se instalan en el modo automático?',
            whatComponentsAreInstalledText: 'El "preajuste recomendado" que incluye todos los servicios necesarios para alojar sitios web (Web, DNS, FTP y Correo), junto con los componentes y extensiones más populares como WP Toolkit, Advisor, Let\'s Encrypt, etc.',
            canISelectComponentsTitle: '¿Puedo seleccionar qué componentes de Plesk instalar?',
            canISelectComponentsText: 'De momento, no. Somos conscientes de esta limitación y tenemos previsto abordarla en el futuro. Por ahora, la solución consiste en añadir o eliminar los componentes requeridos una vez finalizado el proceso de instalación.',
            doesInstalledPleskHaveLicenseTitle: '¿La instancia de Plesk instalada incluye una licencia?',
            doesInstalledPleskHaveLicenseText: 'Puede solicitar una prueba personal de 14 días de la edición Web Pro inmediatamente después de iniciar sesión por primera vez en Plesk si no lo ha hecho antes. También puede utilizar su propia licencia comprada.',
            supportedOSsTitle: '¿Qué sistemas operativos son compatibles con el modo automático? ¿Puedo instalar Plesk para Windows?',
            supportedOSsText: 'Cualquier <a>SO basado en Linux compatible con Plesk</a>. Para instalar Plesk para Windows, siga las <link>instrucciones de instalación</link>.',
            usePrivateKeyTitle: '¿Puedo utilizar una clave SSH privada cifrada?',
            usePrivateKeyText: 'De momento, no. Somos conscientes de esta limitación y tenemos previsto abordarla en el futuro.',
            isItSafeToSubmitPasswordTitle: '¿Es seguro enviar la contraseña o clave SSH privada al instalar Plesk en el modo automático?',
            isItSafeToSubmitPasswordText: 'Sí. Estas credenciales solo se usan para conectarse al servidor e instalar Plesk, y no se almacenan. Todos los datos se transmiten de forma segura a través de canales HTTPS y SSH cifrados. Para máxima seguridad, considere cambiar las credenciales de acceso al servidor después de la instalación.',
            helpUsMakeWebInstallerBetter: 'Ayúdenos a mejorar Plesk Web Installer mediante <a>esta breve encuesta</a>.',
        },
        cpanel: {
            header: 'FAQs sobre la instalación automática de cPanel',
            versionsAndComponents: 'Versiones y componentes',
            knownIssues: 'Incidencias conocidas',
            whatVersionIsInstalledTitle: '¿Qué versión de cPanel se instala en el modo automático?',
            whatVersionIsInstalledText: 'La última versión de cPanel con todas las actualizaciones disponibles en ese momento. Para instalar una versión de cPanel diferente, siga las <link>instrucciones de instalación</link>.',
            canICustomizeParametersOfInstallationTitle: '¿Puedo personalizar los parámetros de instalación?',
            canICustomizeParametersOfInstallationText: 'Actualmente, no hay forma de personalizar la instalación con Web Installer. Para personalizar la instalación, siga las instrucciones en <link>nuestra documentación</link>.',
            doesInstalledCpanelHaveLicenseTitle: '¿La instancia de cPanel instalada incluye una licencia?',
            doesInstalledCpanelHaveLicenseText: 'Si está instalando una nueva instancia de cPanel y WHM en un servidor, puede optar a una licencia de prueba de 15 días. Siga las instrucciones proporcionadas en <link>esta guía</link> para registrarse para la obtención de una licencia de prueba. También puede utilizar su propia licencia comprada.',
            supportedOSsTitle: '¿Qué sistemas operativos son compatibles con el modo automático?',
            supportedOSsText: 'Cualquier SO basado en Linux <link>compatible con cPanel</link>.',
            usePrivateKeyTitle: '¿Puedo utilizar una clave SSH privada cifrada?',
            usePrivateKeyText: 'De momento, no. Somos conscientes de esta limitación y tenemos previsto abordarla en el futuro.',
            isItSafeToSubmitPasswordTitle: '¿Es seguro enviar la contraseña o clave SSH privada al instalar cPanel en el modo automático?',
            isItSafeToSubmitPasswordText: 'Sí. Estas credenciales solo se usan para conectarse al servidor e instalar cPanel, y no se almacenan. Todos los datos se transmiten de forma segura a través de canales HTTPS y SSH cifrados. Para máxima seguridad, considere cambiar las credenciales de acceso al servidor después de la instalación.',
            helpUsMakeWebInstallerBetter: 'Ayúdenos a mejorar cPanel Web Installer mediante <a>esta breve encuesta</a>.',
        },
    },
};