// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import Keys from '@platform360/licenses/web/api/ka/responseTypes/Keys';

type GetKaKeysOptions = FetcherOptions<{
    activeOnly: boolean;
    uid?: string | null;
}>;

export const getKaKeys = async ({ variables, signal }: GetKaKeysOptions): Promise<Keys> => {
    const { data } = await apiClient.get<Keys>('/ka/keys', {
        signal,
        params: variables,
    });

    return data;
};
