// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { FormFieldCheckbox, Heading, Section } from '@plesk/ui-library';
import styles from './IgnoreLowRiskSection.module.less';

type IgnoreLowRiskSectionProps = {
    showWarning?: boolean;
};

const IgnoreLowRiskSection = ({ showWarning }: IgnoreLowRiskSectionProps) => {
    const translate = useTranslate('security-dashboard.Settings.IgnoreLowRiskSection');

    return (
        <Section
            className={styles.section}
            title={
                <Heading icon="exclamation-mark-circle" level={5}>
                    {translate('title')}
                </Heading>
            }
        >
            {translate('description')}
            <FormFieldCheckbox
                label={translate('ignoreCheckbox')}
                name="vulnerabilities[ignoreLowRisk]"
                className={styles.checkbox}
            />
            {showWarning && (
                <div className={styles.updateSettingsWarning}>{translate('warningMessage')}</div>
            )}
        </Section>
    );
};

export default IgnoreLowRiskSection;
