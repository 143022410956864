// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { Permission } from '@platform360/libs/common/permissions';

const useHasPermission = (permission: Permission): boolean => {
    const currentUser = useCurrentUser();

    if (!currentUser.permissions) {
        return false;
    }

    return currentUser.permissions.includes(permission);
};

export default useHasPermission;
