// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Checkbox } from '@plesk/ui-library';

type UpdateSettingsCustomizedCheckboxProps = {
    className?: string;
    checked: boolean;
    onChange: (newValue: boolean) => void;
};

export const UpdateSettingsCustomizedCheckbox = ({
    className,
    checked,
    onChange,
}: UpdateSettingsCustomizedCheckboxProps) => {
    const translate = useTranslate('security-dashboard.UpdateSettingsCustomizedCheckbox');
    return (
        <div className={className}>
            {translate('description')}
            <Checkbox checked={checked} onChange={onChange}>
                {translate('checkboxDescription')}
            </Checkbox>
        </div>
    );
};
