// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, SegmentedControl, ToolbarExpander, ToolbarGroup } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import { FILTER_ACTIVE, FILTER_ALL, FilterValue } from '@platform360/licenses/web/store/filter';
import { Button as Buy360MonitoringButton } from '@platform360/licenses/web/units/buy360MonitoringButton';
import styles from './KeysToolbar.module.css';

type KeysToolbarProps = {
    filter: FilterValue;
    onRefresh: () => void;
    onChangeFilter: () => void;
};

const KeysToolbar = ({ filter, onRefresh, onChangeFilter }: KeysToolbarProps) => {
    const translate = useTranslate();
    const analyticsEvents = useLicensesAnalyticsEvents();

    const handleBuyPleskClick = () => {
        analyticsEvents.licensesBuyPleskClicked();
    };

    return (
        <div className={styles.keysToolbarGroup}>
            <ToolbarGroup title={translate('keysToolbarBuyTitle')}>
                <Button
                    component="a"
                    href="https://www.plesk.com/pricing"
                    target="_blank"
                    onClick={handleBuyPleskClick}
                >
                    {translate('Keys.Licenses.MyLicenses.buyButtons.plesk')}
                </Button>
                <Buy360MonitoringButton />
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('keysToolbarFilterTitle')}>
                <SegmentedControl
                    label={translate('show')}
                    labelPlacement="left"
                    buttons={[
                        {
                            value: FILTER_ALL,
                            title: translate('filterAll'),
                            'data-type': 'Keys--filterAll',
                        },
                        {
                            value: FILTER_ACTIVE,
                            title: translate('filterActive'),
                            'data-type': 'Keys--filterActive',
                        },
                    ]}
                    selected={filter}
                    onChange={onChangeFilter}
                />
            </ToolbarGroup>
            <ToolbarGroup title={translate('keysToolbarBuyTitle')}>
                <Button icon="refresh" onClick={onRefresh} />
            </ToolbarGroup>
        </div>
    );
};

export default KeysToolbar;
