// Copyright 2024. WebPros International GmbH. All rights reserved.

import { INITIALIZED } from '@platform360/licenses/web/units/extensions/constants/actions';
import { InitialState } from '@platform360/licenses/web/units/extensions/reducer/initialState';

export type InitializedAction = {
    type: typeof INITIALIZED;
    payload: Partial<InitialState>;
};

const initialized = (payload: Partial<InitialState>): InitializedAction => ({
    type: INITIALIZED,
    payload,
});

export default initialized;
