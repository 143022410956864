// Copyright 2024. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './Footer.module.less';
import Links from './Links';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

const Footer = () => {
    const translate = useTranslate('security-dashboard.Layout.Footer');
    const {
        cookieManager: { cookieModify },
    } = useApplicationSettings();

    return (
        <footer>
            <div className={styles.footer}>
                <div className={classNames(styles.sectionContent, styles.sectionContentMain)}>
                    <Links cookieModify={cookieModify} />
                </div>
                <div className={styles.sectionBottom}>
                    <div className={styles.sectionContent}>
                        <div className={styles.copyright}>
                            {translate('copyright', { year: new Date().getFullYear() })}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
