// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Skeleton, Status, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './LicenseName.module.css';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import { Link } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { useDateToTrialDisplayInfo } from '@platform360/security-dashboard/web/helpers/useDateToTrialDisplayInfo';
import { useInstallationsSummaryQuery } from '@platform360/security-dashboard/web/queries';
import NotificationDialog from './NotificationDialog';
import { DateTime } from 'luxon';
import { useLocalStorage } from 'usehooks-ts';
import { SUPPORT_LINK } from '@platform360/security-dashboard/shared/external-links';
import { CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';
import { useLicenseTranslate } from '@platform360/security-dashboard/web/hooks/useLicenseTranslate';

type LicenseNameInfo = {
    product: ReactNode;
    intent: 'danger' | 'warning' | undefined;
    daysLeft?: number;
};

const LicenseName = () => {
    const translate = useTranslate('security-dashboard.Layout.LicenseName');
    const translateLicense = useLicenseTranslate();
    const [trialStartedModalShown, setTrialStartedModalShown] = useState(false);
    const [trialExpiredModalShown, setTrialExpiredModalShown] = useState(false);
    const [licenseTerminatedModalShown, setLicenseTerminatedModalShown] = useState(false);

    // Flag indicating whether the trial started modal has been shown or not.
    // Contains the list of license id's.
    const [trialStartedModalShownFlag, setTrialStartedModalShownFlag] = useLocalStorage<number[]>(
        'trial-started-modal-shown',
        [],
    );
    const [licenseStartedFlag, setLicenseStartedFlag] = useLocalStorage<number[]>(
        'license-started',
        [],
    );
    const [trialExpiredModalShownFlag, setTrialExpiredModalShownFlag] = useLocalStorage(
        'trial-expired-modal-shown',
        false,
    );
    const [licenseTerminatedModalShownFlag, setLicenseTerminatedModalShownFlag] = useLocalStorage(
        'license-terminated-modal-shown',
        false,
    );
    const dateToTrialDisplayInfo = useDateToTrialDisplayInfo();
    const { data: summaryData, isLoading: isSummaryLoading } = useInstallationsSummaryQuery();
    const operable = summaryData ? summaryData.total - summaryData.notOperable : 0;
    const { data, isLoading } = useLicenseQuery();

    useEffect(() => {
        if (data?.status !== 'active') {
            return;
        }

        if (data.trial && !trialStartedModalShownFlag.includes(data.id)) {
            setTrialStartedModalShown(true);
            setTrialExpiredModalShownFlag(false);
            setTrialStartedModalShownFlag((prevState) => [...prevState, data.id]);
        }

        if (!licenseStartedFlag.includes(data.id)) {
            setLicenseTerminatedModalShownFlag(false);
            setLicenseStartedFlag((prevState) => [...prevState, data.id]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data?.trial && data.status === 'terminated' && !trialExpiredModalShownFlag) {
            setTrialExpiredModalShownFlag(true);
            setTrialExpiredModalShown(true);
        }
    }, [trialExpiredModalShownFlag, data, setTrialExpiredModalShownFlag]);

    useEffect(() => {
        if (!data?.trial && data?.status === 'terminated' && !licenseTerminatedModalShownFlag) {
            setLicenseTerminatedModalShownFlag(true);
            setLicenseTerminatedModalShown(true);
        }
    }, [licenseTerminatedModalShownFlag, data, setLicenseTerminatedModalShownFlag]);

    if (isLoading || isSummaryLoading) {
        return <Skeleton className={styles.skeleton} width={180} />;
    }

    if (!data) {
        return undefined;
    }

    const getLicenseNameInfo = (): LicenseNameInfo => {
        if (data.status === 'terminated') {
            return {
                product: translate('noLicense'),
                intent: 'danger',
            };
        }

        if (data.trial) {
            const { text: product, intent, daysLeft } = dateToTrialDisplayInfo(data.expiredAt);
            return { product, intent, daysLeft };
        }

        if (data.sitesLimitHard !== null && operable > data.sitesLimitHard) {
            return {
                product: translateLicense(data.product),
                intent: 'danger',
            };
        }

        return {
            product: translateLicense(data.product),
            intent: undefined,
        };
    };

    const { product, intent, daysLeft } = getLicenseNameInfo();
    const expirationText = data.expiredAt < DateTime.now() && (
        <Text intent="danger">{translate('expired')}</Text>
    );

    const renderLicenseLink = () => (
        <Link
            to="/security-dashboard/license-page"
            className={styles.productName}
            data-type="license-link"
        >
            {product} {expirationText}
        </Link>
    );

    const renderLicenseLinkWithIntent = () => (
        <span className={styles.productName}>
            <Status intent={intent}>
                <Link to="/security-dashboard/license-page" data-type="license-link">
                    <Text intent={intent}>
                        {product} {expirationText}
                    </Text>
                </Link>
            </Status>
        </span>
    );

    return (
        <>
            <NotificationDialog
                customerType={data.customerType}
                title={
                    data.customerType === CustomerTypes.retail && data.sitesLimitHard
                        ? translate('notifications.trialStarted.titleRetail', {
                              days: daysLeft,
                          })
                        : translate('notifications.trialStarted.title')
                }
                description={
                    <Text component="p">
                        {translate('notifications.trialStarted.description', {
                            sitesLimit: data.sitesLimitHard,
                        })}{' '}
                        {data.customerType === CustomerTypes.retail &&
                            translate('notifications.trialStarted.descriptionExpiration', {
                                expirationDate: data.expiredAt.toJSDate(),
                            })}{' '}
                        <Link
                            to={'/security-dashboard/license-page'}
                            onClick={() => setTrialStartedModalShown(false)}
                        >
                            {translate('notifications.trialStarted.seeDetails')}
                        </Link>
                    </Text>
                }
                type="trial-started"
                onClose={() => setTrialStartedModalShown(false)}
                isOpen={trialStartedModalShown}
            />
            <NotificationDialog
                customerType={data.customerType}
                title={translate('notifications.trialExpired.title')}
                description={
                    <Text component="p">{translate('notifications.trialExpired.description')}</Text>
                }
                type="trial-expired"
                onClose={() => setTrialExpiredModalShown(false)}
                isOpen={trialExpiredModalShown}
            />
            <NotificationDialog
                customerType={data.customerType}
                title={translate('notifications.licenseTerminated.title')}
                description={
                    <Text component="p">
                        {translate(`notifications.licenseTerminated.description`)}{' '}
                        {data.customerType !== CustomerTypes.vps &&
                            translate(`notifications.licenseTerminated.descriptionContactSupport`, {
                                a: (feedback) => (
                                    <a
                                        href={SUPPORT_LINK}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {feedback}
                                    </a>
                                ),
                            })}
                    </Text>
                }
                type="license-terminated"
                onClose={() => setLicenseTerminatedModalShown(false)}
                isOpen={licenseTerminatedModalShown}
            />
            {intent === undefined ? renderLicenseLink() : renderLicenseLinkWithIntent()}
        </>
    );
};

export default LicenseName;
