// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Item, Status, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { PaymentInfo } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import getIconById from '@platform360/licenses/web/components/PaymentMethod/getIconById';
import isCardPaymentType from '@platform360/licenses/web/helpers/cleverbridge/isCardPaymentType';
import isCleverbridgePaymentExpired from '@platform360/licenses/web/helpers/cleverbridge/isCleverbridgePaymentExpired';

export type CleverBridgePaymentMethodProps = {
    paymentInfo: PaymentInfo;
    changePaymentUrl?: string;
};

const CleverBridgePaymentMethod = ({
    paymentInfo,
    changePaymentUrl,
}: CleverBridgePaymentMethodProps) => {
    const translate = useTranslate();

    return (
        <>
            {isCleverbridgePaymentExpired(paymentInfo) && (
                <Status intent={'danger'}>
                    <Text intent={'danger'}>{translate('Keys.Payment.card.expired')}</Text>
                </Status>
            )}
            <Item
                icon={getIconById(paymentInfo.PaymentTypeId)}
                title={
                    isCardPaymentType(paymentInfo.PaymentTypeId)
                        ? translate('paymentCard', {
                              paymentType: paymentInfo.PaymentType,
                              cardLastFourDigits: paymentInfo.CardLastFourDigits,
                              cardExpirationMonth: paymentInfo.CardExpirationDate?.Month,
                              cardExpirationYear: paymentInfo.CardExpirationDate?.Year,
                          })
                        : paymentInfo.PaymentType
                }
            >
                {changePaymentUrl && (
                    <a target="_blank" rel="noopener noreferrer" href={changePaymentUrl}>
                        {translate('editPaymentMethod')}
                    </a>
                )}
            </Item>
        </>
    );
};

export default CleverBridgePaymentMethod;
