// Copyright 2024. WebPros International GmbH. All rights reserved.

// @todo need to add description with links to that errors. Can you please help me? ;-)
export const expected = [
    'keyNotUnderOnlineStore',
    'notOwnLicense',
    'subscriptionNotFound',
    'unableToUpgrade',
    'unknownError',
    'pendingOperationFound',
];
