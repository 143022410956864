// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'クリップボードにコピーしました。',
    },
    Page: {
        ImpersonationBanner: {
            title: '別のアカウントをインパーソネートしています。',
            depersonateButton: 'インパーソネートをやめる',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: '別のアカウントをもうインパーソネートしていません。',
        },
        MainMenu: {
            betaVersion: 'ベータ版',
            servers: 'サーバ',
            websites: 'ウェブサイト',
            licenses: 'ライセンス',
            monitoring: 'モニタリング',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'モニタリング',
            feedback: 'フィードバック',
            services: '360 サービス',
        },
        GlobalSearch: {
            SearchButton: {
                search: '検索...',
            },
            ClientResultGroup: {
                clients: '顧客',
                clientsShowMore: '{showMore, plural, other{さらに # 件のクライアントを表示...}}',
            },
            DomainResultGroup: {
                domains: 'ウェブサイト',
                domainsShowMore: '{showMore, plural, other{さらに # 件のウェブサイトを表示...}}',
            },
            ServersResultGroup: {
                servers: 'サーバ',
                serversShowMore: '{showMore, plural, other{さらに # 件のサーバを表示...}}',
            },
            search: '検索...',
            noResults: '何も見つかりませんでした...',
        },
        UserMenu: {
            team: '組織',
            logout: 'ログアウト',
            changePassword: 'パスワード変更',
            viewProfile: '個人プロフィール',
            businessProfile: 'ビジネスプロフィール',
            apiTokens: 'API トークン',
        },
    },
    limit: '上限：',
    upgradePropertiesButton: '調整',

    common: {
        errors: {
            isNotEmpty: '必須フィールドが空欄です。',
            required: '必須フィールドが空欄です。',
            url: '値が有効な URL ではありません。',
            isUrl: '値が有効な URL ではありません。',
            email: '有効な形式のメールアドレスを入力してください。',
            isEmail: '有効な形式のメールアドレスを入力してください。',
            json: '値は有効な JSON でなければなりません。',
            min: '{min, plural, other{値は # 文字以上でなければなりません。}}',
            maxLength: '{max, plural, other{値は # 文字以下でなければなりません。}}',
            isHttps: 'サーバのホスト名は先頭を https:// にする必要があります。',
            unique: '値は一意でなければなりません。',
            isPublicIp: 'サーバのホスト名がパブリック IP アドレスに変換できる必要があります。',
            unknown: '不明なエラー：{message}',
            externalRedirect: '問題が発生しました。',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'パスワードを入力する必要があります。',
            errorPasswordIsWrong: 'パスワードが正しくありません。',
            errorPasswordIsTooWeak: 'パスワードが弱すぎます。',
            errorPasswordContainsPersonalInfo: 'パスワードに個人情報を含めないでください。',
            errorPasswordTooCommon: 'パスワードが一般的すぎます。',
            errorPasswordPreviouslyUsed: '以前に使用したことがあるパスワードです。',
            errorInvalidOtp: '入力したコードが有効ではありません。',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: '契約を確定',
            payment: '支払い',
        },
        general: {
            buyNow: '今すぐ購入',
            confirm: '確認',
            confirmAndFeedback: 'フィードバックを確認して提出する',
            cancel: 'キャンセル',
        },
        tabTitle: 'すべてのライセンス',
        LinkedEmails: {
            tabTitle: 'リンクされたメール',
            emailColumn: 'メールアドレス',
            statusColumn: 'ステータス',
            totalRows: '{totalRows, plural, other{合計 # 件のメールアドレス}}',
            verifiedStatus: '認証済み',
            addButton: 'その他のメールアドレスをリンク',
            deleteButton: 'メールアドレスのリンクを解除',
            AddEmails: {
                stepOne: {
                    title: 'メールアドレスを追加されたライセンスとリンク',
                    emailsLabel: 'ライセンスの購入に使用したメールアドレス',
                    submitButton: '認証を送信',
                },
                stepTwo: {
                    title: '認証レターが送信されました',
                    description: 'メールボックスにメッセージが届いていないかチェックして、認証リンクを選択してください。リンクされたメールの追加を完了するには、このアカウントに再度ログインする必要がある可能性があります。',
                    emailColumn: 'メールアドレス',
                    submitButton: '完了',
                    resendButton: '再送',
                },
            },
            Onboarding: {
                title: '追加のライセンスを表示するには、メールアドレスをこのアカウントにリンクしてください',
                description: '追加のライセンスの管理を開始するには、ライセンスの購入に使用したメールアドレスを入力してください。',
                docsLink: '詳細',
                addEmailsButton: 'メールアドレスを追加',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Plesk を購入',
                    monitoring: '360 Monitoring を購入',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'ここでライセンスの表示や管理ができます',
                    description1: 'まだライセンスがない場合は、［新規ライセンスを購入］をクリックしてください。',
                    description2: '他のメールアドレスでライセンスを購入した場合、［既存のライセンスを追加］をクリックしてライセンスを追加してください。',
                    docsLink: '詳細',
                    buyButton: '新規ライセンスを購入',
                    addEmailsButton: '既存のライセンスを追加',
                },
            },
        },
        CB: {
            paymentInfo: '{companyName} が運営する Plesk オンラインストアでは、選択した契約に紐付けられた決済方法でこの注文が処理されます',
            confirmSubscription: 'この注文を送信すると、定期的に請求が発生するサブスクリプション製品への登録に同意いただいたものとみなされます。購入した製品を継続的に使用するためには、お支払いが条件となります。料金と課金頻度の詳細は、ショッピングカートの上に表示されています。',
            buyInfo: 'この注文を送信すると、Cleverbridge の<termsLink>利用規約</termsLink>に同意いただき、Cleverbridge の<policyLink>プライバシーポリシー</policyLink>と消費者<revocationLink>契約解除権</revocationLink>を読み、理解されたものとみなされます。',
            links: {
                terms: '利用条件',
                contact: 'お問い合わせ',
                revocation: '契約解除権',
                security: 'セキュリティ',
                legal: '法的情報',
                policy: 'プライバシーポリシー',
            },
            footer: 'お客様の注文はオンライン販売店の {companyName} を通して処理されます。決済処理と受注処理は {companyName}、{companyAddress} によって実施されます。',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Cologne, Germany',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654, United States',
            },
        },
        Renew: {
            drawer: {
                title: '契約を再アクティブ化する',
            },
            form: {
                submitButton: '更新を確定',
                confirmText: '以下のボタンをクリックすると、WebPros International GmbH の "{product}" の契約更新が確定されます',
            },
            buttonTitle: '契約を再アクティブ化する',
            successMessage: '{product} の契約が更新されました。ライセンスキーがアクティブになるまでしばらくかかります',
            unavailable: 'この契約を更新することはできません（製品が廃止された場合など）。新しい製品をご購入ください。',
        },
        Adjust: {
            delayedChangesProducts: '課金期間（{date}）の終了まで、以下の制限は変更されません',
        },
        Cancel: {
            buttonTitle: '解除',
            successMessage: '{product} の契約は解除されました。{date} まで使用できます',
            popover: {
                title: '{product} の契約を解除',
                message: 'これにより、今後の請求は停止します。{date} までライセンスをご利用いただけます（当請求期間分の料金は返金されません）。',
            },
        },
        Payment: {
            card: {
                expired: 'カードの有効期限が切れました',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: '契約の設定で支払い方法を変更できます',
                    confirm: '了解しました',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'キャンセル',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Platform 360 では、サードパーティアプリケーションの ZendeskChat を使用しています。',
            description2: '続行することにより、サードパーティアプリケーションの ZendeskChat を使用することに同意します。これには、欧州内外のサードパーティへの私の個人データ（IP アドレスなど）の送信が含まれます。詳細：<a>プライバシーポリシー</a>',
            confirmButton: 'ZendeskChat を起動',
        },
        supportFormPopover: {
            description: '現在、対応できる担当者がいません。お待ちいただくか、チケットフォームからリクエストを送信してください。',
            confirmButton: 'フォームを開く',
        },
        ChatButton: {
            title: 'サポート',
        },
    },

    keysPageTitle: 'ライセンス',
    goBack: 'ホーム画面に戻る',
    keysPageDescription: 'Plesk オンラインストアで購入したライセンスを管理します。',
    keysToolbarBuyTitle: '購入',
    keysToolbarFilterTitle: 'フィルタ',

    keyAdditionalActionsToolbarTitle: '追加アクション',
    keyMoreDetails: 'その他の詳細',

    keyForeignCart: 'あなたはこのライセンスの所有者ではありません',
    keyForeignDescriptionTitle: 'このライセンスは別のアカウントに属しているため、管理することはできません。これは次のような理由で発生する可能性があります：',
    keyForeignDescriptionHosting: 'Plesk ライセンスはホスティング事業者に属しています。',
    keyForeignDescriptionWrongEmail: '別のアカウントでライセンスを購入しました。ライセンスを表示して管理するには、アカウントに関連するメールアドレスでログインしてください。',

    keyTrialDescription: 'これは評価版ライセンスです。{link} を購入しますか？',
    keyTrialDescriptionLink: '有料ライセンス',

    keyTerminatedCart: 'このライセンスは終了されました',
    keyTerminatedDescription: 'このライセンスはもう有効ではありません。',
    keyTerminatedDescriptionRetail: 'これ以上請求されません。',

    keyPartnerCart: 'これは Plesk オンラインストアで購入したライセンスではありません。',
    keyPartnerDescription: 'このライセンスの課金情報を表示できません。',

    keyLicenseDetailsTitle: 'ライセンスの詳細',
    keyLicenseDetailsCreationDate: '作成日：',
    keyLicenseDetailsActivationCode: 'アクティベーションコード：',
    keyBillingDetailsTitle: '課金の詳細',
    keyBillingDetailsTrial: 'これは評価版ライセンスであり、{date} に期限が切れます。<a>こちら</a>でライセンスを購入できます。',
    keyBillingDetailsNotRetail: 'このライセンスに関して表示できる課金情報はありません。Plesk オンラインストアで購入したライセンスではありません。',
    keyBillingDetailsTerminated: '契約がキャンセルされました。',
    keyBillingDetailsSubscriptionId: '契約 ID：',
    keyBillingDetailsCycle: '課金サイクル：',
    keyBillingDetailsForeign: 'このライセンスは別のアカウントに属しているため、表示できる課金情報はありません。',
    keyServerDetailsTitle: 'サーバの詳細',
    keyServerDetailsAddresses: 'サーバの IP アドレス：',
    keyServerDetailsOs: 'サーバ OS：',
    keyServerDetailsProductVersion: '製品バージョン：',
    keyServerDetailsAbsent: 'このライセンスは Plesk サーバにまだインストールされていないようです。',

    pleskUpgradedMessage: '{from} ライセンス（{id}）を {to} にアップグレードしました。',
    propertyUpgradedIncreaseMessage: '{keyName} ライセンスの容量が {to} {name} に増えました',
    propertyUpgradedDecreaseMessage: '{keyName} ライセンスの容量が {to} {name} に減少しました。変更は次の課金期間から有効になります',
    keyUpgradedMessage: '{from} ライセンス（{id}）を {to} にアップグレードしました。お使いの {to} ライセンス（{id}）は {parentId} Plesk ライセンスに割り当てられています。',
    keyAddedMessage: '{addon} ライセンスを購入されました。これは {parent} ライセンス（{parentId}）に割り当てられています。',
    refreshSuggestion: 'これらの変更が表示されない場合、<a>ページを更新</a>してください。',

    upsellPageTitle: 'ライセンスに新しい拡張を追加',
    yearlyPrices: '年額',
    monthlyPrices: '月額',
    subscriptionForBillingPeriodNotFound: 'この拡張ライセンスとサブスクリプションの課金期間が一致しないか、この課金期間に Plesk ライセンスがありません。一致する課金期間を選択するか、オンラインストアで Plesk ライセンスを購入してください。',
    subscriptionToUpgradeHeader: 'アップグレードするサブスクリプション',
    selectedSubscription: '{subscriptionId} （通貨：{billingDate}、課金サイクル：{billingCycle}）',
    toOnlineStoreButton: 'オンラインストアへ',
    why: 'なぜですか？',
    whyCharge: '現在の課金期間の残日数に基づいて請求額が日割計算されています。',
    chargeNow: '<b>{grossPrice}</b> が請求されます（付加価値税 {vatPrice} を含む）。',
    chargeNowShort: '即時請求されます',
    inclVatShort: 'VAT 込み：{vatPrice}',
    delayedCharge: '制限値は、課金期間末まで {limit} {property} のまま維持されます。',
    nextCharge: '<b>{grossPrice}</b> (付加価値税 {vatPrice} を含む)、最低価格 {nextBillingDate}',
    nextChargeShort: '{nextBillingDate} から請求が開始されます',
    noProductForBillingPeriod: '選択した課金期間に課金される製品はありません。',
    confirmationPopupTitle: '現在のサブスクリプションをアップグレード',

    upgradePageTitle: 'ライセンスをアップグレード',
    upgradePageDescription: 'ここでライセンスをアップグレードできます。',
    upgradePanelBeforeTitle: 'アップグレード前',
    upgradePanelOptionsTitle: 'アップグレードオプション',
    upgradePanelAfterTitle: 'アップグレード後',
    upgradePanelOptionsRecommendationsTitle: '推奨事項を確認：',
    upgradePanelOptionsRecommendationsAlt: 'または、以下で {link} してください。',
    upgradePanelOptionsRecommendationsAltLink: '可能なすべてのオプションを参照',
    upgradeSuggestion: 'ライセンスに製品を追加してください',
    upgradeSuggestionFreeCost: '無料で試す',
    upgradeSuggestionDescription: '（詳細は製品情報でご確認ください）',
    upgradeMultiOfferSuggestion: '別のプランを選択',
    upgradeConfirm: '今すぐ購入',
    upgradeAccept: '確認',
    upgradeBack: '製品リストに戻る',
    planUpdate: 'プランのアップデート',
    adjustPending: 'このライセンスの調整は既に保留中です。30 秒後にもう一度やり直してください。',
    waitAMoment: 'お待ちください',
    upgradeConfirmPriceRecalculation: '{chargeInfo} が請求されます（次回の課金日 {nextBillingDate} までの残日数に基づく日割額の合計）。{nextBillingDate} から、この製品に {renewalInfo} 請求されます。',
    buyNowNotification: 'ライセンスの購入またはアップグレードに進むには、［今すぐ購入］をクリックしてください。Cleverbridge AG が運営する Plesk オンラインストアにリダイレクトされます。このストアでは、以前にライセンスの購入やアップグレードに使用した支払方法が使用されます。すべての支払いは Cleverbridge AG によって処理されます。',
    paymentDetailsLinkText: 'このトランザクションの支払情報を表示または編集します。',
    upgradeDoneRedirectToPleskTitle: '購入が成功しました',
    upgradeDoneRedirectToPleskDescription: 'ライセンスのインストールにはしばらく時間がかかります。',
    upgradeDoneRedirectToPleskButton: 'Plesk に戻る',
    upgradeDoneRedirectToKeys: 'ライセンスを表示',
    total: '合計',
    vat: 'VAT',
    newTotal: '新しい合計',
    newVat: '新しい VAT',

    firstLoginWithoutEmailVerification: 'Plesk 360 サービスにご登録いただきありがとうございます。認証リンクをメールで送信しました。リンクからアカウントを確認して、ログインしてください。その後、Plesk 360 でライセンスの管理を開始できるようになります。',
    pleaseVerifyEmailTitle: 'Plesk 360 アカウントを確認',
    secondLoginWithoutEmailVerification: '認証していないアカウントにログインしようとしています。認証リンクが記載されたメールを受信トレイで確認してください。このリンクからアカウントを確認し、ログインしてください。その後、Plesk 360 でライセンスの管理を開始できるようになります。',
    userBlocked: "アカウントはユーザの契約違反により一時停止されました。これが誤りだと思われる場合は、{emailLink} までメールにてお知らせください。",

    errorDefault: '問題が発生しました。後でやり直してください。',
    errorDefaultWithReqId: '何らかの問題が発生しているようです。リクエスト ID：{requestId}。',
    errorTooManyRequests: 'この IP アドレスで API コールの上限に達しました。1 分後にやり直してください。',
    errorTokenRenewal: 'セッションの期限が切れました。再度ログインしてください。',
    errorNetworkIssue: '現在ネットワーク接続で問題が発生しているようです。',

    errorKAUnavailable: 'サービスが一時的に使用できません。後でやり直してください。',
    errorKAUnavailableWithReqId: 'サービスが一時的に使用できません。後でやり直してください。リクエスト ID：{requestId}。',

    footerPurposeExtension: {
        text: '<a>Plesk 拡張カタログ</a>でその他の拡張をご覧ください。',
    },

    filterAll: 'すべてのライセンス',
    filterActive: 'アクティブのみ',
    renewalCost: '更新費用：',
    nextBillingDate: '次回の課金日：',
    canceledSubscriptionDate: '契約がキャンセルされました。ライセンスの有効期限：',
    paymentHistory: '支払履歴',
    licenseStatus: 'ライセンスステータス：',
    upgradeButton: 'アップグレード',
    upsellButton: '拡張を追加',
    upsellText: 'Plesk に新しい拡張が追加されます',
    billingPeriod: '課金期間',
    features: '追加機能：',
    noFeatures: '追加機能なし',
    toPlesk: 'Plesk に移動',
    expiresNote: '（期限：{date}）',
    startingFrom: '開始日：',
    billingIntervalMonth: '{value} / 月',
    billingIntervalYear: '{value} / 年',
    billingIntervalOnce: '{value} / 一度',
    perOne: '/ユニット',

    paymentMethod: '支払い方法',
    editPaymentMethod: '支払い方法を編集',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: '追加済み',
    addresses: 'アドレス',
    back: '戻る',

    monthly: '毎月',
    on: 'on',
    plesk: 'Plesk',
    product: '製品',
    price: '価格',
    products: '製品',
    purchased: '購入済み',
    select: '選択',
    sinceNote: '（開始：{date}）',
    show: '表示',
    upgrade: 'アップグレード',
    yearly: '毎年',

    isEmpty: 'このフィールドは入力必須です。',
    errorPasswordIsEmpty: 'パスワードは必須です。',
    errorPasswordIsWrong: 'パスワードが正しくありません。',
    errorPasswordIsTooWeak: 'パスワードが弱すぎます。',
    errorEmailOrPasswordIsIncorrect: 'メールアドレスまたはパスワードが正しくありません。',
    errorIncorrectEmail: 'メールアドレスが正しくありません。',
    errorUserDoesntExist: 'ユーザが存在しません。',
    errorUserExist: 'ユーザが既に存在します。',
    errorUserIsNotVerified: 'このユーザは存在しますが、まだ確認されていません。新しい確認メールを送信しましたので、メールボックスをチェックしてください。',
    errorUnknownError: '不明なエラー。',
    errorEmailIsAlreadyLinked: 'このメールアドレスは既にあなたのアカウントとリンクされています。',
    errorLinkIsOutdated: 'この認証リンクは期限が切れました。',
    errorBounceOrComplaint: 'このメールアドレスは存在しないか、当社からのメッセージをスパムとしてブロックしています。Plesk 360 チームまでお問い合わせください。',
    errorUserEmailAlreadyVerified: 'このメールアドレスは既に認証されています。',
    errorDoResourceIdExist: 'この Digital Ocean アカウントは既に登録されています。',
    errorCopyToClipboard: 'コピーエラーが発生しました。手作業でコピーしてください。',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH は、<a>プライバシーポリシー</a>に従ってお客様のリクエストに対応するために、お客様から提供されたデータを処理します。',
    },

    Auth: {
        errors: {
            userDoesntExist: 'ユーザが存在しません。',
            bounceOrComplaint: 'このメールアドレスは存在しないか、当社からのメッセージをスパムとしてブロックしています。Plesk 360 チームまでお問い合わせください。',
            userIsNotVerified: 'このユーザは存在しますが、まだ確認されていません。新しい確認メールを送信しましたので、メールボックスをチェックしてください。',
            userExist: 'ユーザが既に存在します。',
            errorPasswordIsEmpty: 'パスワードは必須です。',
            errorPasswordIsWrong: 'パスワードが正しくありません。',
            errorPasswordIsTooWeak: 'パスワードが弱すぎます。',
        },
        AuthContainer: {
            tabs: {
                login: 'ログイン',
                signUp: '登録',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Plesk 360 へようこそ',
                text1: 'サーバを簡単にモニタリング・管理できるようにします。',
                text2: 'パフォーマンスをトラッキングし、ダウンタイムの発生を防ぐには、完全に<b>統合されたサイトおよびサーバモニタリング</b>をご利用ください。',
                text3: 'アップグレードして、<b>構成可能なダッシュボード</b>、パーソナライズされたアラート、簡易チェック、ネットワーク履歴などの機能を利用しましょう。',
                text4: '<b>サーバインベントリ</b>の管理、すべての Plesk ライセンスの確認、契約の購入または更新が可能です。',
                text5: '1 つのプラットフォームで 1 つのアカウントを使ってすべてをできます。',
            },
            MonitoringPageIntro: {
                title: '360 Monitoring へようこそ',
                text1: 'サーバを簡単にモニタリング・管理できるようにします。',
                text2: 'パフォーマンスをトラッキングし、ダウンタイムの発生を防ぐには、完全に統合された<b>アップタイムおよびサーバモニタリング</b>をご利用ください。',
                text3: 'プラグインを介して、<b>構成可能なダッシュボード</b>、パーソナライズされたアラート、簡易チェック、サーバ指標、I/O 指標、ネットワーキングパフォーマンス指標、およびカスタム指標を利用できます。 ',
                text4: 'マルチクラウドおよびプラットフォーム<b>非依存</b>。',
            },
        },
        SignUp: {
            signUp: '登録',
            loginLink: 'ログイン画面に移動',
            emailLabel: 'メールアドレス',
            emailDescription: 'Plesk と拡張の購入に使用したメールアドレスを使用することをお勧めします',
            passwordLabel: 'パスワード',
            privacyPolicyAgreementTitle: '<a>{termsLink}</a>に同意します',
            termsPlatform: 'Plesk 360 利用規約',
            termsMonitoring: '360 Monitoring 利用規約',
            privacyPolicyAgreementDescription: 'プラットフォームでのアカウントの作成と維持を目的として、WebPros International GmbH とその他の WebPros グループ会社は、私のアカウントがアクティブである限り、提供された情報を<a>プライバシーポリシー</a>に従って社内システムで処理することができます。',
            verifyAccountTitle: 'アカウントを確認してください',
            verifyAccountText: '認証リンクを {email} にメールで送信しました。このリンクをクリックしてアカウントを確認し、ログインしてください。',
            resendDescription: 'メールがまだ届かない場合、迷惑メールフォルダを確認するか、［再送］をクリックして新しいメールを受信してください。',
            resendLabel: '再送',
            retryCountdown: 'あと {time} で再送できます',
            SignUpByInvitation: {
                emailDescription: '上のメールアドレスで登録します',
                invitationSucceed: '正常に登録されました。ログインしてください。',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: '名',
                    lastNamePlaceholder: '姓',
                    nameLabel: '名前',
                },
            },
        },
        Login: {
            signUpButton: '登録',
            emailLabel: 'メールアドレス',
            passwordLabel: 'パスワード',
            submitButton: 'ログイン',
            forgotYourPasswordLink: 'パスワードを忘れた場合',
        },
        ResetPassword: {
            title: 'パスワードをリセット',
            text: '入力いただいたメールアドレス宛てに、パスワードのリセット用リンクをメールでお届けします。',
            emailLabel: 'メールアドレス：',
            submitButton: '送信',
            successTitle: 'パスワードリセット用のメールが送信されました',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: '再送',
            loginForm: '今すぐログイン',
            retryCountdown: 'あと {time} で再送できます',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. All rights reserved. Plesk および Plesk ロゴは、WebPros International GmbH の商標です。',
        followPlesk: 'Plesk をフォロー:',
        followCPanel: 'cPanel をフォロー:',
        company: '会社情報',
        aboutCPanel: 'cPanel について',
        aboutPlesk: 'Plesk について',
        legal: '法的情報',
        cookiehub: 'Cookie 設定',
        privacyPolicy: 'プライバシーポリシー',
        impressum: 'サイト管理者情報',
        knowledgeBase: 'ナレッジベース',
        documentation: 'ドキュメント',
        helpCenterCPanel: 'cPanel ヘルプセンター',
        helpCenterPlesk: 'Plesk ヘルプセンター',
        contactUs: 'お問い合わせ',
        pleskLifecyclePolicy: 'Plesk ライフサイクルポリシー',
        community: 'コミュニティ',
        blogCPanel: 'cPanel ブログ',
        blogPlesk: 'Plesk ブログ',
        forumsCPanel: 'cPanel フォーラム',
        forumsPlesk: 'Plesk フォーラム',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: '必須入力フィールド',
        submitButton: 'OK',
        cancelButton: 'キャンセル',
    },

    FormField: {
      addMore: 'もう 1 つ追加',
      remove: '削除',
      clear: 'クリア',
    },

    FormFieldPassword: {
        improvePassword: 'パスワードのセキュリティを強化するには：',
        passwordTooShort: 'パスワードを長くしてください。',
        lettersLowerCase: '小文字を 1 つ以上使用してください。',
        lettersUpperCase: '大文字を 1 つ以上使用してください。',
        numbers1: '数字を 1 つ以上使用してください。',
        numbers3: '数字を 3 つ以上使用してください。',
        specialChar1: '特殊文字を 1 つ以上使用してください（例：!。@、#、$、%、^、&、*、?、_、~）。',
        specialChar2: '特殊文字を 2 つ以上使用してください（例：!。@、#、$、%、^、&、*、?、_、~）。',
        comboUpperAndLower: '大文字と小文字の両方を使用してください。',
        comboLettersAndNumbers: '数字と文字を両方使用してください。',
        comboLettersNumbersSpecial: '英数字と特殊文字を使用します。',
        yourPasswordIsStrong: 'もっと長くすると強度が上がります。',
        passwordStrength: 'パスワードの強度は「%%strength%%」です。',
        strengthVeryStrong: '非常に強い',
        strengthStrong: '強い',
        strengthVeryWeak: '非常に弱い',
        strengthWeak: '弱い',
        strengthMedium: '普通',
        hidePassword: 'パスワードを隠す',
        showPassword: 'パスワードを表示',
        generateButton: '生成',
        generateButtonHint: '強度の高いパスワードを生成',
    },

    Pagination: {
        totalShort: '/ %%total%%',
        itemsPerPage: 'ページあたりのアイテム数',
        all: 'すべて',
        total: '%%current%% / %%total%% ページ',
    },

    ListEmptyView: {
        filteredTitle: 'フィルタと一致するアイテムは見つかりませんでした',
        emptyTitle: 'ここには何もないようです',
        filteredDescription: 'クエリを絞り込むかフィルタ条件の一部を削除して、やり直してください。',
    },

    Dialog: {
        cancelButton: 'キャンセル',
    },
    keyNotUnderOnlineStore: 'Plesk 360 でライセンスをアップグレードできません。これは Plesk オンラインストアで購入したライセンスではありません。このライセンスをホスティング事業者から購入された場合は、アップグレードオプションについてこの事業者にお問い合わせください。',
    notOwnLicense: 'Plesk 360 でライセンスをアップグレードできません。このライセンスは別のアカウントに属しています。ホスティング事業者からこのライセンスを購入した場合は、アップグレードオプションについてこの事業者にお問い合わせください。',
    subscriptionNotFound: '契約のデータが見つかりません',
    unableToUpgrade: 'アップグレードを実行できません。Plesk サポートまでお問い合わせください',
    unknownError: 'サービスが一時的に使用できません。後でやり直してください。',
    pendingOperationFound: '前の処理（支払い方法の変更など）が終了していないため、新しい処理を実行できません。前の処理を完了してから続行してください。',

    LinkEmail: {
        title: 'リンクメール',
        tryAgain: 'やり直してください',
    },

    GaOptOutLink: {
        gaSuccess: 'Google アナリティクスによるトラッキングが停止しました。',
    },

    Servers: {
        sync: '同期',
        syncNoSelection: '同期するサーバを選択していません。すべてを同期しますか？',
        syncAll: 'すべてを同期',
        serverNameColumn: 'サーバ名',
        productColumn: '製品',
        panelColumn: 'ホスティングコントロールパネル',
        stateColumn: 'サーバの状態',
        stateLastSync: '最終同期：{duration}',
        stateLastSyncNow: '数秒前に同期',
        login: {
            plesk: 'Plesk に移動',
            cpanel: 'cPanel に移動',
            wp: 'WordPress に移動',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{<b>{title}</b> サーバの同期がスケジュールされています。} other{<b>#</b> サーバの同期がスケジュールされています。}}',
        updateServer: '{product} をアップデート',
        cancel: 'キャンセル',
        Toolbar: {
            add: '追加',
            disconnect: '接続解除',
            sync: '同期',
            groupSearch: '検索',
        },
        Onboarding: {
            title: 'Plesk サーバの追加や管理ができます',
            description: 'コントロールパネルへのワンクリックアクセスを設定するには、サーバを追加してください。',
            docsLink: '詳細',
            addServersButton: 'サーバを接続',
            feedbackButton: 'フィードバックする',
            installPleskButton: '新規サーバに Plesk をインストール',
        },
        FeedbackButton: {
            heading: 'フィードバックする',
            subHeading: '今後の開発の参考にさせていただきます。',
        },
        Tour: {
            next: '次へ',
            previous: '前へ',
            gotit: '了解しました',
            step1: {
                title: 'サーバインベントリのご紹介',
                description: 'Plesk 360 で複数のサーバを管理するためのツールです。［サーバ］タブまたは Plesk から、Plesk サーバを安全に追加できます。',
            },
            step2: {
                title: 'サーバインベントリのご紹介',
                description: '［サーバ］タブには、サーバでホストされているすべての顧客とドメインのリストも表示され、検索可能です。',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'サーバから「Plesk 360」への接続を確認してください',
            description: 'お使いの {product} コントロールパネル <b>{address}</b> は、Plesk 360 でアカウント <b>{email}</b> とリンクされます。承諾する前に、このアカウントがご自分のものであることを確認してください。',
            accessTitle: '可能になること',
            accessMessage1 : '<b>Plesk 360</b> は <b>{address}</b> で Plesk コントロールパネルの管理者アクセス権限を持ちます',
            accessMessage2 : '<b>Plesk 360</b> はアカウント <b>{email}</b> に対して以下の操作を許可します。',
            accessItem1: 'ログイン情報を送信することなく Plesk に管理者または他のユーザーとしてログインする',
            accessItem2: 'サーバでホストされるクライアントとウェブサイトのリストを取得する',
            accessItem3: 'サーバの外部モニタリングをアクティブ化する',
            accessItem4: '(将来的に) その他の操作を実行する',
            buttons: {
                confirm: '確認',
                confirmAnyway: 'このまま確定する',
                nextStep: '次のステップ',
                deny: '禁止',
            },
            enableIpRestrictionTitle: 'API キーの詳細',
            fields: {
                enableIpRestriction: 'API キーの IP アドレス制限を有効にします。許可される IP：',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'サーバにインターネットからアクセスできる必要があります。',
                certificate: 'サーバは有効な SSL/TLS 証明書で保護されている必要があります。',
            },
            success: {
                access: 'Plesk インストールにインターネットからアクセスできます。',
            },
            ServerAccessError: {
                urlIsRequired: 'サーバの URL を入力してください。',
                urlMaxLength: "{max, plural, other{サーバの URL は # 文字未満にしてください。}}",

                urlInvalid: {
                    title: '提供されたサーバアドレスでは機能しません。',
                    description: '一般に、これが発生するのは、アドレスの「https」を「http」と入力してしまった場合や、URL を誤入力した場合です（トップレベルドメイン .com や .org などのつけ忘れなど）。',
                },

                urlPrivateIp: {
                    title: 'サーバにアクセスできません。現在利用できないようです。',
                    description: 'サーバアドレスのホストは公開されていない IP アドレスです。',
                },

                serverAlreadyAdded: 'このサーバは既に追加されています。 <a>サーバの詳細を確認してください。</a>',

                domainNotResolved: {
                    title: 'サーバにアクセスできません。現在利用できないようです。',
                    description: 'サーバアドレスのドメイン名が Plesk 360 で解決できません。',
                },

                domainResolvedPrivateIp: {
                    title: 'サーバにアクセスできません。現在利用できないようです。',
                    description: 'このドメインにパブリック IP が割り当てられていることを確認してください。',
                },

                timeout: {
                    title: 'サーバにアクセスできません。現在利用できないようです。',
                    description: 'ファイアウォールの設定をチェックして、{publicIps} からの指定ポートを許可してください。',
                },

                connectionAborted: {
                    title: 'サーバにアクセスできません。現在利用できないようです。',
                    description: 'ファイアウォールの設定をチェックして、{publicIps} からの指定ポートを許可してください。',
                },

                connectionRefused: {
                    title: 'ネットワーク接続が拒否されました。',
                    description: '指定されたポートとドメインをチェックしてください。',
                },

                badProtocol: '指定されたアドレスが有効な SSL 証明書によって保護されている必要があります。',

                notAuthenticated: {
                    title: 'リモートサーバによって接続が許可されていません。',
                    description: 'サーバをサーバインベントリに再び追加してください。',
                },

                notAuthorized: '接続がブロックされました。',

                unsupportedServerVersion: {
                    title: 'サーバを追加できません。サポートまでお問い合わせください。',
                    description: 'インストールされた Plesk バージョンが古いか、追加できません。{minSupportedVersion, select, _ {} other { Plesk Obsidian {minSupportedVersion} 以降のみがサポートされます。}} <a>Plesk のアップデート方法を確認してください。</a>',
                },
                serverMisconfigured: {
                    title: 'サーバを追加できません。サポートまでお問い合わせください。',
                    description: 'このサーバは正しく構成されていないため、追加できません。',
                },
                pleskConfiguration: {
                    title: 'このサーバを追加できません。サポートまでお問い合わせください。',
                    description: 'Plesk がサポート対象外のバージョン（{pleskMinVersion} より前）であるか、正しく構成されていません。',
                },

                unknownError:  {
                    title: 'サーバを追加できません。サポートまでお問い合わせください。',
                    description: '不明なエラー：{message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Plesk for Linux で Plesk ファイアウォールを使用してローカルファイアウォールルールを管理する方法。',
                    windows: 'Plesk ファイアウォール（Windows）',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'この Plesk インストールに設定された証明書は期限が切れています。',
                    description: 'このような Plesk 構成を使用して Plesk 360 に接続するのは安全ではありません。自己署名または CA 署名の証明書を設定することをお勧めします。［次へ］をクリックすると、この構成が安全ではないことに同意することになります。',
                },
                certInvalid: {
                    title: 'このサーバの証明書は別のドメイン名で機能することが確認されています。',
                    description: '証明書がこのドメイン名用に発行されたものではないか、有効なサーバのドメイン名の代わりに IP アドレスを入力しました。有効な URL を確かに入力した場合は、<a>こちらの記事</a>に詳細情報が記載されています。',
                },
                certSelfSigned: {
                    title: 'リモートサーバに自己署名証明書が設定されています。',
                    description: 'Plesk インストールを Plesk 360 に追加できますが、Let\'s Encrypt のような CA 署名証明書を使用して Plesk をセキュリティの問題から保護することを強くお勧めします。',
                },
                certUntrustedCa: {
                    title: 'リモートサーバの証明書の CA は Plesk 360 で信頼されていません。',
                    description: 'Plesk インストールを Plesk 360 に追加できますが、Let\'s Encrypt のような CA 署名証明書を使用して Plesk をセキュリティの問題から保護することを強くお勧めします。',
                },
                agreeInvalidCertCheckbox: '有効な証明書なしでこのサーバを追加することに同意します',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: '安全でないサーバ {hostname} を Plesk 360 に追加します',
            understandSecurityIssues: '私は、サーバがネットワークセキュリティに関するさまざまな問題に対して脆弱であることを理解しています',
            understandDataCanLost: '私は、Plesk インストールとデータが失われる可能性があるため、早急に有効な証明書を追加する必要があることを理解しています',
            agreeNotResponsibility: '[このまま接続する] をクリックすることで、Plesk 360 がこのサーバの安全性に責任を負わないことに同意することになります。',
        },
    },
    DisconnectServersOperation: {
        disconnect: '接続解除',
        noSelectedServers: '接続解除するサーバを 1 つ以上選択してください。',
        disconnectConfirmation: '{count, plural, =1{<b>{title}</b> を接続解除しますか？} other{<b>#</b> 件のサーバを接続解除しますか？}}このアクションを取り消すことはできません。',
        notifyDisconnectSuccess: '{count, plural, =1{サーバ <b>{title}</b> が接続解除されました。} other{<b>#</b> 件のサーバが接続解除されました。}}',
        notifyDisconnectFail: '{count, plural, =1{サーバ <b>{title}</b> は接続解除されませんでした。} other{<b>#</b> 件のサーバが接続解除されませんでした。}}',
    },
    ViewServer: {
        title: 'サーバの詳細',
        inGeneral: '概要',
        domains: 'ウェブサイト',
        clients: '顧客',
        installationLog: 'インストールログ',
        address: '住所',
        notes: '備考',
        product: '製品',
        version: 'バージョン',
        unknownVersion: '不明',
        showErrorDetail: '表示数を増やす',
        allowedInvalidCertificateWarning: 'SSL/TLS 証明書の有効性確認で問題が発生したため、サーバへの接続が安全ではない可能性があります。',
        allowedInvalidCertificateRisksNote: '証明書の有効性確認の結果を無視し、関連するリスクをすべて受け入れることを選択されました。',
        allowedInvalidCertificateLinkToDocs: '問題とリスクについて詳しく。',
        certificateErrorStatusMessage: 'サーバ {serverName} への接続時に次のような SSL/TLS 証明書の問題が検出されたため、サーバへの接続は無効化されています',
        certificateErrorFixDescription: 'エラーを修正して証明書を再チェックし、接続を復元してください。あるいは、証明書の有効性確認の結果を無視して、<a>関連するすべてのリスク<a>を受け入れてください。',
        relatedRisks: '関連するすべてのリスク',
        ignoreCertificateErrorsButton: '証明書のチェックを無視して復元する',
        ignoreCertificateConfirmation: '証明書の問題に関連するすべてのリスクに同意し、これを受け入れることを確認してください.',
        confirmAndProceed: '確認して続行',
        recheckCertificateButton: '証明書を再チェック',
        recheckResultSuccess: '接続が正常に再確立されました。',
        recheckResultCertificateError: '証明書の検証が、次のエラーで失敗しました：{errorMessage}',
        recheckResultConnectionError: 'Plesk 360 は {serverName} への接続を確立できません。',
        recheckResultConnectionBlocked: 'サーバ {serverName} への接続がブロックされました',
        connectionErrorStatusMessage: 'サーバ {serverName} への接続を正しく確立できません。',
        connectionErrorFixDescription: 'エラーを修正して以下のボタンを押し、接続を復元してください。',
        updatesButton: '{product} アップデート',
        login: {
            plesk: 'Plesk に移動',
            cpanel: 'cPanel に移動',
            wp: 'WordPress に移動',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'サーバは接続を確立できるように構成されていません。考えられる理由：',
            caseRestrictionManagement: 'Platform 360 IP アドレスが管理者のアクセスから除外されています（<a>手順</a>）',
            casePanelIniApiSection: 'Plesk API 経由のリモートアクセスが panel.ini で無効になっています（<a>手順</a>）',
            listPublicIps: '次の IP アドレスからのアクセスが許可されることを確認してください：<b>{publicIps}</b>.',
            fixDescription: 'サーバを正常に接続するには、対応する設定を調整してください。完了したら、以下のボタンをクリックして接続をチェックします。',
            recheckConnectionButton: '接続を再チェック',
        },

        recheckConnectionButton: '接続を再チェック',
        sync: '同期',
        state: 'サーバの状態',
        Domains: {
            name: '名前',
            hostingType: 'ホスティングタイプ',
            virtualHostingType: 'ウェブサイト',
            forwardingHostingType: '転送',
            noneHostingType: 'ホスティングなし',
            mainDomainType: 'メインドメイン',
            subDomainType: 'サブドメイン',
            addonDomainType: 'アドオンドメイン',
            parkedDomainType: 'エイリアス',
            manage: '管理',
            manageLinkUnavailableHint: 'Plesk パネルへのダイレクトリンクを生成するには、Plesk RESTful API 1.4.0 以降が必要です。詳細はドキュメントを参照してください。',
            open: '開く',
        },
        Clients: {
            name: '担当者名',
            email: 'メールアドレス',
            login: 'ユーザ名',
            type: 'タイプ',
            status: 'ステータス',
            typeAdmin: '管理者',
            typeReseller: 'リセラー',
            typeCustomer: '顧客',
            typeCustomerWithOwner: '{owner} の顧客',
            statusActive: 'アクティブ',
            statusSuspended: '一時停止',
            manage: '管理',
        },
    },
    InPlaceText: {
        ok: '保存（Ctrl+Enter）',
        cancel: 'キャンセル（Esc）',
        edit: '編集',
        add: '追加',
    },
    ServerLogin: {
        title: 'コントロールパネル（{address}）にリダイレクトします',
        progress: 'これには数秒かかる可能性があります',
        clientNote: 'パワーユーザビューでは顧客/リセラーは表示されません。',
        errorTitle: 'サーバへの接続を試行中にエラーが発生しました',
        errorDescription: '適切な認証情報を使用してサーバアドレス経由でサーバに直接ログインするか、後で Plesk 360 経由で認証をやり直してください。',
        btnGroupLogin: 'ログイン',
        tryAgain: 'やり直してください',
        loginDirectly: '直接ログイン',
        btnGroupCancel: 'キャンセル',
        cancel: 'キャンセル',
    },
    Team: {
        Layout: {
            title: '組織',
            members: 'メンバー',
            invitations: '招待',
        },
        settings: '設定',
        Members: {
            email: 'メールアドレス',
            fullName: 'フルネーム',
            mfaStatus: 'MFA ステータス',
            tags: '特別なマーク',
            remove: '削除',
            removeMemberConfirmation: '<b>{name}</b> は組織および内部共有リソース、サーバ、ウェブサイト、ライセンスにアクセスできなくなります。',
            SpecialMarks: {
                you: 'あなた',
                owner: '所有者',
            },
            Onboarding: {
                title: '同僚を追加して、プラットフォーム上に組織を作成する',
                description: '効率的な組織管理とコラボレーションのための機能を備えたプラットフォームです。組織のメンバーは、ここから接続先のサーバに直接アクセスしてコラボレーションできます。もう、安全でない共有パスワードや複雑なアクセス方法を使用する必要はありません。',
                addMembersButton: '組織のメンバーを追加',
            },
            MultifactorStatus: {
                enabled: 'MFA は有効',
                disabled: 'MFA は無効',
            },
        },
        Invitations: {
            email: 'メールアドレス',
            expireAt: '期限日',
            expired: '期限切れ',
            add: '追加',
            revoke: '取り消す',
            Onboarding: {
                title: '組織のメンバーを招待する',
                description: '同僚を追加して、サーバとウェブサイトを共有できます。',
                addButton: '新規メンバーを招待',
            },
            ListActions: {
                resendInvitationTooltip: '招待状を再送します。',
                successMessage: '招待メールをもう一度送信しました。',
            },
            AddInvitations: {
                title: '組織に新規メンバーを追加する',
                infoMessage: '組織のメンバーに参加してもらうために招待を送るには、仕事用メールアドレスを入力してください。',
                infoDescription: 'ユーザの個人情報を守るため、プライベートのメールアドレスではなく仕事用メールアドレスを使用することをお勧めします。',
                emailsLabel: '招待リンクを送るメールアドレス',
                submitButton: '招待を送信',
                successMessage: '{count, plural, =1{{email} への招待が送信されました。} other{# 件の招待が送信されました。}}',
            },
            RevokeInvitationsOperation: {
                revoke: '取り消す',
                noSelectedInvitations: '取り消す招待を 1 つ以上選択してください。',
                revokeInvitationConfirmation: '{count, plural, =1{<b>{email}</b> への招待を取り消しますか？} other{<b>#</b> 件の招待を取り消しますか？}}このアクションをキャンセルすることはできません。',
                successRevokeInvitation: '{count, plural, =1{メールアドレス <b>{email}</b> への招待が取り消されました。} other{<b>#</b> 件の招待が取り消されました。}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'プロジェクトのアップデートとプロモーション',
            text: 'WebPros International GmbH およびその他の WebPros グループ会社は、<a>プライバシーポリシー</a>に従い、ニュースレターや個別のオファーなどのパーソナライズされたマーケティングコンテンツの配信を目的として、私が提供するデータを保存・処理することができます。利用状況や行動データなどの追加情報 (プロファイリング) に基づいて、カスタマイズされたサービスが提供される場合があります。この同意は、プロジェクト設定でいつでも取り消すことができるものとします。',
        },
    },

    components: {
        List: {
            selectAll: 'すべて選択',
            deselectAll: '選択を解除',
            selectedRows: '{selectedRows, plural, other{# / {totalRows, plural, other{# 件}}が選択されました}}',
            totalRows: '{totalRows, plural, other{合計 # 件}}',
            foundRows: '{foundRows, plural, other{# 件が見つかりました}}',
        },
        DataList: {
            groupSearch: '検索',
        },
        KbInstructionsFixLink: 'これを修復する方法は、<a>こちらの KB 記事</a>を参照してください。',
    },
};