// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentType, ComponentProps, useState } from 'react';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Server } from '@platform360/security-dashboard/web/types';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import { useServersSitesProtectMutation } from '@platform360/security-dashboard/web/mutations';
import { IgnoreDoNotProtectMessage } from '@platform360/security-dashboard/web/components/IgnoreDoNotProtectMessage';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';

type BaseProps = {
    selectedServers: Server[];
    onTaskStarting: (serversIds: number[], operation: 'protect') => void;
    onTaskStarted: (serversIds: number[], operation: 'protect') => void;
    onConfirmationShown?: () => void;
    onConfirm: (
        serversIds: number[],
        ignoreDoNotProtect: boolean,
        doNotProtectCount: number,
    ) => void;
};

export type ProtectButtonProps<C extends ComponentType> = BaseProps &
    Omit<ComponentProps<C>, keyof BaseProps> & {
        component?: C;
    };

export const ProtectButton = <C extends ComponentType = typeof Button>({
    selectedServers,
    onTaskStarted,
    onTaskStarting,
    component,
    onConfirmationShown,
    onConfirm,
    ...props
}: ProtectButtonProps<C>) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar.ProtectButton');
    const Component = component || Button;

    const [ignoreDoNotProtect, setIgnoreDoNotProtect] = useState(false);

    const { data: licenseData } = useLicenseQuery();

    const { mutateAsync: runServersSitesProtect } = useServersSitesProtectMutation({
        enable: true,
        ignoreDoNotProtect,
    });
    const withDoNotProtectLabelCount = selectedServers.reduce(
        (count, server) => count + server.withDoNotProtectLabelCount,
        0,
    );

    const serversIds = selectedServers.map((el) => el.id);
    const handleProtectClick = async () => {
        onConfirm(serversIds, ignoreDoNotProtect, withDoNotProtectLabelCount);

        if (licenseData?.status === 'terminated') return;

        onTaskStarting(serversIds, 'protect');
        try {
            await runServersSitesProtect(selectedServers);
        } finally {
            onTaskStarted(serversIds, 'protect');
        }
    };

    const button = <Component {...props}>{translate('buttonText')}</Component>;

    if (selectedServers.length === 0) {
        return (
            <NoSelectionPopover selectionCount={selectedServers.length} target={button}>
                {translate('noSelectedServers')}
            </NoSelectionPopover>
        );
    }

    const renderPopoverDescription = () => {
        if (selectedServers.length === 1) {
            return translate('singleConfirmation', {
                selectedServerTitle: selectedServers[0]?.displayTitle,
                providerLogo: <Patchstack />,
                whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
            });
        }

        return translate('multipleConfirmation', {
            selectedServersCount: selectedServers.length,
            providerLogo: <Patchstack />,
            whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
        });
    };

    const renderIgnoreDoNotProtectMessage = () => {
        if (withDoNotProtectLabelCount === 0) return null;

        const [firstServer] = selectedServers;

        let installationsLinkQueryParams: Record<string, string> | undefined = undefined;
        if (selectedServers.length === 1 && firstServer) {
            installationsLinkQueryParams = {
                search: encodeURIComponent(firstServer.hostname ?? firstServer.address.hostname),
            };
        }

        return (
            <IgnoreDoNotProtectMessage
                doNotProtectCount={withDoNotProtectLabelCount}
                ignoreDoNotProtect={ignoreDoNotProtect}
                onIgnoreDoNotProtectChange={setIgnoreDoNotProtect}
                installationsLinkQueryParams={installationsLinkQueryParams}
            />
        );
    };

    return (
        <ConfirmationPopover
            target={button}
            actionButtonText={translate('buttonText')}
            onClick={handleProtectClick}
            onShow={() => {
                onConfirmationShown?.();
                setIgnoreDoNotProtect(false);
            }}
            confirmationButtonIntent="primary"
        >
            {renderPopoverDescription()}
            {renderIgnoreDoNotProtectMessage()}
        </ConfirmationPopover>
    );
};

export default ProtectButton;
