// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './Links.module.less';
import logo from './wp-guardian.svg';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link } from 'react-router-dom';
import SocialLink from './SocialLink';

type LinksProps = {
    cookieModify: () => void;
};
export const Links = ({ cookieModify, ...props }: LinksProps) => {
    const translate = useTranslate('security-dashboard.Layout.Footer.Links');

    return (
        <div className={styles.root} {...props}>
            <div className={styles.links}>
                <a className={styles.logo} href="https://webpros.com/">
                    <img src={logo} alt="logo" className={styles.logoImg} />
                </a>
                <div className={styles.socialBlock}>
                    <p>{translate('followUs')}</p>
                    <ul className={styles.socialLinks}>
                        <li>
                            <SocialLink
                                href="https://www.facebook.com/Plesk"
                                icon="facebook"
                                title="Facebook"
                            />
                        </li>
                        <li>
                            <SocialLink
                                href="https://www.instagram.com/webpros_official/"
                                icon="instagram"
                                title="Instagram"
                            />
                        </li>
                        <li>
                            <SocialLink
                                href="https://www.linkedin.com/company/plesk/"
                                icon="linkedin"
                                title="LinkedIn"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.links}>
                <div className={styles.linksHeading}>{translate('company')}</div>
                <ul className={styles.linksList}>
                    <li>
                        <a target="_blank" href="https://webpros.com/about-us/" rel="noreferrer">
                            {translate('aboutWebpros')}
                        </a>
                    </li>
                    <li>
                        <Link to="/legal">{translate('legal')}</Link>
                    </li>
                    <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={cookieModify}>{translate('cookiehub')}</a>
                    </li>
                    <li>
                        <Link to="/legal/privacy-policy">{translate('privacyPolicy')}</Link>
                    </li>
                </ul>
            </div>
            <div className={styles.links}>
                <div className={styles.linksHeading}>{translate('knowledgeBase')}</div>
                <ul className={styles.linksList}>
                    <li>
                        <a href="https://docs.wpguardian.io/" target="_blank" rel="noreferrer">
                            {translate('documentation')}
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://support.plesk.com/hc/en-us/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translate('helpCenter')}
                        </a>
                    </li>
                    <li>
                        <a href="https://webpros.com/contact/" target="_blank" rel="noreferrer">
                            {translate('contactUs')}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Links;
