// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { runProtectServers } from '@platform360/security-dashboard/web/api/servers';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import { Server } from '@platform360/security-dashboard/web/types';

type Result = {
    servers: Server[];
    isSuccess: boolean;
};

type Options = {
    enable: boolean;
    ignoreDoNotProtect?: boolean;
};

const useServersSitesProtectMutation = ({ enable, ignoreDoNotProtect }: Options) => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useServersSitesProtectMutation');
    const { errorToast } = useToaster();

    return useMutation({
        mutationFn: async (servers: Server[]) => {
            let isSuccess = false;
            try {
                await runProtectServers({
                    servers: servers.map(({ id }) => id),
                    enable,
                    ignoreDoNotProtect,
                });
                isSuccess = true;
            } catch {
                isSuccess = false;
            }
            return {
                isSuccess,
                servers,
            };
        },
        meta: {
            defaultErrorHandler: false,
        },
        onSuccess: async (result: Result) => {
            await Promise.all([
                queryClient.cancelQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
            ]);

            if (result.isSuccess) return;

            if (result.servers.length > 1) {
                errorToast(
                    translate('notifySeveralServersFail', {
                        selectedServersCount: result.servers.length,
                    }),
                );
                return;
            }

            const [failedServer] = result.servers;
            if (failedServer) {
                errorToast(
                    translate('notifySingleServerFail', {
                        selectedServerTitle: failedServer.displayTitle,
                    }),
                );
            }
        },
    });
};

export default useServersSitesProtectMutation;
