// Copyright 2024. WebPros International GmbH. All rights reserved.

/**
 * Helper to insert item into a list if cond is true.
 *
 * @example
 *     imports: [
 *         ...insertIf(featureEnabled, FeatureModule),
 *     ],
 *     providers: [
 *         ...insertIf<Provider>(featureEnabled, FeatureFooService, FeatureBarService),
 *     ],
 */
export const insertIf = <M>(cond: boolean, ...modules: M[]): M[] => (cond ? modules : []);
