// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ButtonProps, ContentLoader, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DialogLayout from '@platform360/libs/shared-web/components/DialogLayout';
import CancelButton from '@platform360/monitoring/web/components/MonitoringPromoActivation/CancelButton';

export type MonitoringPromoActivationState = 'in-progress' | 'failed' | 'succeed';

export type MonitoringPromoActivationProps = {
    state: MonitoringPromoActivationState;
    onCancel: () => void;
    onRetry: () => void;
};

const MonitoringPromoActivation = ({
    state,
    onCancel,
    onRetry,
}: MonitoringPromoActivationProps) => {
    const translate = useTranslate('monitoring.MonitoringPromoActivation');

    const commonProps = {
        'data-type': 'monitoring-activate-promo',
    } as const;

    const cancelButton = {
        component: CancelButton,
        onClick: onCancel,
        // TODO Fix UI Library types
    } as unknown as ButtonProps;

    const retryButton = {
        onClick: onRetry,
        icon: 'reload',
        children: translate('retryButtonText'),
    };

    if (state === 'in-progress') {
        return (
            <DialogLayout
                {...commonProps}
                form={{
                    applyButton: { ...cancelButton, disabled: true },
                    submitButton: { ...retryButton, disabled: true },
                }}
            >
                <ContentLoader loader="icons" text={translate('inProgressText')} />
            </DialogLayout>
        );
    }

    if (state === 'failed') {
        return (
            <DialogLayout
                {...commonProps}
                form={{
                    applyButton: cancelButton,
                    submitButton: retryButton,
                }}
            >
                <StatusMessage intent="danger">{translate('failedText')}</StatusMessage>
            </DialogLayout>
        );
    }

    // Succeess.
    return (
        <DialogLayout {...commonProps}>
            <ContentLoader text={translate('successText')} />
        </DialogLayout>
    );
};

export default MonitoringPromoActivation;
