// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseLinkedEmailsQueryVariables = undefined;

export type UseLinkedEmailsQueryData = string[];

export const useLinkedEmailsQuery = createQuery<
    UseLinkedEmailsQueryVariables,
    UseLinkedEmailsQueryData
>({
    queryName: 'licenses/useLinkedEmailsQuery',
    fetcher: async () => {
        const { data } = await apiClient.get<ApiResponse<string[]>>('/linked-emails');
        return data.data;
    },
    useQuery,
});
