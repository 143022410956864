// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TaskManagerListRow } from '@platform360/security-dashboard/web/types';
import { Icon, Text } from '@plesk/ui-library';
import { IconName } from '@plesk/ui-library/types/src/components/Icon/constants';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { OperationSubTypes } from '@platform360/security-dashboard/shared/operation-sub-type';

type RowTitleProps = {
    row: TaskManagerListRow;
};

export const RowTitle = ({ row }: RowTitleProps) => {
    const translate = useTranslate('security-dashboard.GlobalTaskManagerDrawer.TasksList');
    let iconName: IconName = 'site-page';
    if (row.subType === OperationSubTypes.servers) {
        iconName = 'server';
    } else if (row.subType === OperationSubTypes.vulnerabilities) {
        iconName = 'wordpress';
    }

    const getTitleItemLocaleKey = (row: TaskManagerListRow) => {
        if (row.subType === OperationSubTypes.servers) {
            return 'servers';
        } else if (row.subType === OperationSubTypes.vulnerabilities) {
            return 'vulnerabilities';
        }
        return 'sites';
    };

    if (row.type && row.subType) {
        const { type } = row;

        return (
            <>
                {
                    <Text bold>
                        {translate(
                            `taskTypes.${type}`,
                            undefined,
                            translate('taskTypes.fallBack', { type }),
                        )}
                    </Text>
                }
                {'  '}
                <Icon intent="inactive" name={iconName} size={'16'} />
                {'  '}
                {row.title ? (
                    <Text>{row.title}</Text>
                ) : (
                    <Text>
                        {translate(`taskSubTypesAffected.${getTitleItemLocaleKey(row)}`, {
                            count: row.itemsAffected ?? 0,
                        })}
                    </Text>
                )}
            </>
        );
    }

    return (
        <>
            <Text bold>{row.title}</Text>
            <br />
            <Text intent={'muted'}>{row.subTitle}</Text>
        </>
    );
};

export default RowTitle;
