// Copyright 2024. WebPros International GmbH. All rights reserved.

import periodToText from '@platform360/licenses/web/units/extensions/helpers/periodToText';
import { StoreState } from '@platform360/licenses/web/store/types';
import Extension from '@platform360/licenses/web/units/extensions/types/Extension';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';
import { STATE_KEY } from '@platform360/licenses/web/index';

const getExtension = (state: StoreState): Omit<Extension, 'minPrice'> => {
    const { period, selectedItemPath } = upsellSelector(state);

    const [group, key] = selectedItemPath.split('.') as [string, string];

    return state[STATE_KEY].upsell[periodToText(period)][group][key];
};

export default getExtension;
