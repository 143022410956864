// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getTeamMembers } from '@platform360/accounts/web/api/team';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseTeamMembersQueryVariables = undefined;

export type UseTeamMembersQueryData = PromiseValue<ReturnType<typeof getTeamMembers>>;

const useTeamMembersQuery = createQuery<UseTeamMembersQueryVariables, UseTeamMembersQueryData>({
    queryName: 'accounts/useTeamMembersQuery',
    fetcher: getTeamMembers,
    useQuery,
});

export default useTeamMembersQuery;
