// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Checkbox, CheckboxProps, ListEmptyView, Text, Tooltip } from '@plesk/ui-library';
import useInstallationsVulnerabilitiesQuery from '@platform360/security-dashboard/web/queries/useInstallationsVulnerabilitiesQuery';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import {
    getInstallationsVulnerabilities,
    InstallationInfoResponse,
} from '@platform360/security-dashboard/web/api/installations';
import { useEffect, useRef, useState } from 'react';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { InstallationVulnerability } from '@platform360/security-dashboard/web/types';
import image from './your-website-is-secure.png';
import { UseFilterProps } from '@platform360/libs/shared-web/helpers/useFilter';
import Toolbar from '@platform360/security-dashboard/web/components/Installations/WebsiteDrawer/TabVulnerabilities/Toolbar';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import InstallationComponentFlag from '@platform360/security-dashboard/web/components/InstallationComponentFlag';
import InfoColumn from '@platform360/security-dashboard/web/components/Vulnerabilities/InfoColumn';
import RiskRankTitle from '@platform360/security-dashboard/web/components/RiskRankTitle';
import RiskRank from '@platform360/security-dashboard/web/components/RiskRank';
import styles from './TabVulnerabilities.module.less';
import { useMediaQuery } from 'usehooks-ts';
import Filters from '@platform360/security-dashboard/web/components/Installations/ComponentFilters/Filters';
import { isIgnoredVulnerability } from '@platform360/security-dashboard/web/helpers/isIgnoredVulnerability';
import useMitigateInstallationVulnerabilitiesMutation from '@platform360/security-dashboard/web/mutations/useMitigateInstallationVulnerabilitiesMutation';
import {
    MITIGATION_OPERATION_ACTIVATE_ASSET,
    MITIGATION_OPERATION_DEACTIVATE_ASSET,
    MITIGATION_OPERATION_UPDATE,
    MITIGATION_POLICY_ACTIVATE_AFTER_UPDATE,
    MITIGATION_TYPE_DEACTIVATE_ASSET,
    MITIGATION_TYPE_UPDATE,
    MitigationParams,
    VulnerabilityMitigationsParams,
} from '@platform360/security-dashboard/web/api/vulnerabilities';
import UpdateConfirmation from '@platform360/security-dashboard/web/components/Installations/WebsiteDrawer/TabVulnerabilities/UpdateConfirmation';
import { useListFetcher } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import { FetcherOptions, IdsFilterQuery } from '@platform360/libs/shared-web/typings/api';
import {
    InstallationComponent,
    InstallationComponents,
} from '@platform360/security-dashboard/shared/installation-component';
import { MITIGATE_VULNERABILITIES_TASK_TYPE } from '@platform360/security-dashboard/shared/constants';

type TabVulnerabilitiesProps = {
    installation: InstallationInfoResponse;
    installationId: number;
    componentFilterProp: UseFilterProps<InstallationComponent>;
};

type InstallationVulnerabilityElement = InstallationVulnerability & {
    disabled: boolean;
    intent: 'inactive' | undefined;
};

const TabVulnerabilities = ({
    installation,
    installationId,
    componentFilterProp,
}: TabVulnerabilitiesProps) => {
    const hasMitigateVulnerabilitiesTask = installation.tasks.some(
        ({ type }) => type === MITIGATE_VULNERABILITIES_TASK_TYPE,
    );
    const [selection, setSelection] = useState<string[]>([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [enableAfterUpdate, setEnableAfterUpdate] = useState(true);
    const vertical = useMediaQuery('(max-width: 992px)');
    const search = useSearch('searchVulnerabilities', () =>
        analyticsEvents.wpTabSiteVulnerabilitiesSearchSubmit(),
    );
    const translate = useTranslate(
        'security-dashboard.Installations.WebsiteDrawer.tabs.vulnerabilities',
    );
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const { resetFilter, setFilter, filter: componentFilter } = componentFilterProp;

    const isVulnerabilitiesShown = useRef<boolean>(false);
    const variables = {
        installationId,
    };

    const {
        data: responseData,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useInstallationsVulnerabilitiesQuery({
        variables,
    });
    const data = responseData?.data || [];

    useEffect(() => {
        if (!responseData) {
            return;
        }

        if (!isVulnerabilitiesShown.current) {
            isVulnerabilitiesShown.current = true;
            analyticsEvents.wpSglSiteVulnerabilitiesShown(
                installationId,
                responseData.totalCount,
                responseData.data.filter(
                    (item) => item.mitigatedByDeactivation || item.mitigatedByProtection,
                ).length,
            );
        }
    }, [analyticsEvents, installationId, responseData]);

    const fetcherFn = (opts: FetcherOptions<IdsFilterQuery>) =>
        getInstallationsVulnerabilities({
            ...opts,
            variables: {
                installationId,
                ...opts.variables,
            },
        });

    useListFetcher(useInstallationsVulnerabilitiesQuery.getQueryKey(variables), fetcherFn);

    const { mutate: mitigateInstallationVulnerabilities, isPending: isVulnerabilityMitigating } =
        useMitigateInstallationVulnerabilitiesMutation();

    const searchApplied = !!search.value;
    const isComponentsFilterApplied = componentFilter.length !== 0;
    const searchValue = search.value.toLowerCase();

    const filteredBySearchData = data.filter((item) => {
        if (!searchApplied) {
            return true;
        }
        const result =
            item.title.toLowerCase().includes(searchValue) ||
            item.cve.includes(searchValue.trim().toUpperCase()) ||
            item.vulnerabilityId.toLowerCase() === searchValue;
        return result;
    });
    const filteredData = filteredBySearchData.filter(({ component }) =>
        isComponentsFilterApplied ? componentFilter.includes(component) : true,
    );
    const totalRows = filteredData.length;

    const dataWithDisabledFlag: InstallationVulnerabilityElement[] = filteredData.map(
        (vulnerability) => ({
            ...vulnerability,
            disabled: !vulnerability.mitigationUpdateAvailable,
            intent:
                vulnerability.mitigatedByDeactivation ||
                vulnerability.mitigatedByProtection ||
                isIgnoredVulnerability(vulnerability)
                    ? 'inactive'
                    : undefined,
        }),
    );
    const updateBtnDisabled = dataWithDisabledFlag.every(({ disabled }) => disabled);

    useEffect(() => {
        const filteredSelection = selection.filter((vulnerabilityId) =>
            filteredData.some((element) => element.vulnerabilityId === vulnerabilityId),
        );
        if (!selection.every((element) => filteredSelection.includes(element))) {
            setSelection(filteredSelection);
        }
    }, [selection, filteredData]);

    const renderSelectionCheckbox = (
        props: CheckboxProps,
        row?: InstallationVulnerabilityElement,
    ) => {
        const { checked, disabled, onChange, inputProps } = props;
        const disabledByRow = row ? row.disabled : updateBtnDisabled;
        const checkbox = (
            <Checkbox
                checked={checked}
                disabled={disabledByRow || disabled}
                onChange={onChange}
                inputProps={inputProps}
            />
        );

        if (disabledByRow) {
            return (
                <Tooltip delay={100} title={translate('updateByProtect')}>
                    {checkbox}
                </Tooltip>
            );
        }

        return checkbox;
    };

    const renderChangePluginStatusButton = (item: InstallationVulnerability) => {
        if (item.component !== InstallationComponents.plugin) {
            return null;
        }
        const plugin = installation.plugins.find((plugin) => plugin.slug === item.slug);
        if (!plugin) {
            return null;
        }
        if (!plugin.status && item.deactivationReason !== 'mitigation') {
            return null;
        }
        return (
            <Button onClick={() => handleChangePluginStatus(item, !plugin.status)}>
                {translate(plugin.status ? 'disablePlugin' : 'enablePlugin')}
            </Button>
        );
    };

    const renderUpdateButton = (item: InstallationVulnerability) => {
        if (!item.mitigationUpdateAvailable) {
            return <Text intent="muted">{translate('waitingForUpdate')}</Text>;
        }

        const version =
            item.component === InstallationComponents.core
                ? item.availableMinorVersion
                : item.availableVersion;
        const localeKey = version === null ? 'update' : 'updateTo';

        return (
            <UpdateConfirmation
                onClick={() => handleMitigateVulnerabilityViaUpdate(item)}
                onShow={() => analyticsEvents.wpSglSiteItemVulnerabilityUpdateClick()}
                siteDisplayTitle={installation.displayTitle}
                target={<Button>{translate(localeKey, { availableVersion: version })}</Button>}
                selectedItems={[item.vulnerabilityId]}
                enableAfterUpdate={enableAfterUpdate}
                setEnableAfterUpdate={setEnableAfterUpdate}
                data={[item]}
            />
        );
    };

    const handleSearch = (value: string) => search.onSearch(value);

    const mitigateViaUpdate = (items: InstallationVulnerability[]) => {
        mitigateInstallationVulnerabilities({
            installationId: installation.id,
            data: {
                operation: MITIGATION_OPERATION_UPDATE,
                vulnerabilityMitigations: items.map((item) => ({
                    vulnerabilityId: item.vulnerabilityId,
                    mitigations: [
                        {
                            type: MITIGATION_TYPE_UPDATE,
                            applied: true,
                        },
                    ],
                    policy: enableAfterUpdate ? MITIGATION_POLICY_ACTIVATE_AFTER_UPDATE : undefined,
                })),
            },
        });
    };

    const handleMitigateVulnerabilityViaUpdate = (item: InstallationVulnerability) => {
        setSelection([]);
        mitigateViaUpdate([item]);
        analyticsEvents.wpSglSiteItemVulnerabilityUpdateConfirmationClick();
    };

    const handleMitigateVulnerabilitiesViaUpdate = () => {
        const filterOutVulnerabilitiesWithoutMitigationViaUpdate = (
            items: InstallationVulnerability[],
        ) => items.filter((item) => item.mitigationUpdateAvailable);

        const selectedItems = selection.length
            ? filteredData.filter(
                  (vulnerability) =>
                      selection.includes(vulnerability.vulnerabilityId) &&
                      filterOutVulnerabilitiesWithoutMitigationViaUpdate([vulnerability]).length,
              )
            : filterOutVulnerabilitiesWithoutMitigationViaUpdate(filteredData);

        setSelection([]);

        if (!selectedItems.length) {
            return;
        }

        mitigateViaUpdate(selectedItems);

        analyticsEvents.wpSglSiteVulnerabilityUpdateConfirmationClick(selectedItems.length);
    };

    const handleChangePluginStatus = (item: InstallationVulnerability, status: boolean) => {
        setSelection([]);
        const operation = status
            ? MITIGATION_OPERATION_ACTIVATE_ASSET
            : MITIGATION_OPERATION_DEACTIVATE_ASSET;
        const mitigations: MitigationParams[] = [
            { type: MITIGATION_TYPE_DEACTIVATE_ASSET, applied: !status },
        ];
        const vulnerabilityMitigations: VulnerabilityMitigationsParams[] = [
            {
                vulnerabilityId: item.vulnerabilityId,
                mitigations,
                policy: undefined,
            },
        ];
        mitigateInstallationVulnerabilities({
            installationId: installation.id,
            data: { operation, vulnerabilityMitigations },
        });
        if (status) {
            analyticsEvents.wpTabSiteVulnerabilitiesEnablePluginClick();
        } else {
            analyticsEvents.wpTabSiteVulnerabilitiesDisablePluginClick();
        }
    };

    const columns: ListProps<InstallationVulnerability>['columns'] = [
        {
            key: 'riskRank',
            title: <RiskRankTitle className={styles.riskRankTitle} />,
            render: ({ cvssScore, exploited, riskRank, mitigated }) => (
                <RiskRank
                    cvssScore={cvssScore}
                    exploited={exploited}
                    riskRank={riskRank}
                    intent={mitigated ? 'inactive' : undefined}
                />
            ),
        },
        {
            key: 'title',
            type: 'title',
            title: '',
            render: (item) => (
                <InfoColumn
                    vulnerability={{
                        ...item,
                        mitigatedByDeactivationCount: item.mitigatedByDeactivation ? 1 : 0,
                        mitigatedByProtectionCount: item.mitigatedByProtection ? 1 : 0,
                        unknownMitigationsCount:
                            item.mitigated &&
                            !item.mitigatedByDeactivation &&
                            !item.mitigatedByProtection
                                ? 1
                                : 0,
                        installationsCount: 1,
                    }}
                    vertical={vertical}
                />
            ),
        },
        {
            key: 'component',
            title: translate('columns.component'),
            render: ({ component }) => <InstallationComponentFlag component={component} />,
        },
        {
            key: 'howToFix',
            title: '',
            align: 'right',
            render: (item) => (
                <div className={styles.actions}>
                    {renderChangePluginStatusButton(item)} {renderUpdateButton(item)}
                </div>
            ),
        },
    ];

    const resetSelection = () => setSelection([]);

    const handleFilter = (filter: InstallationComponent[]) => {
        analyticsEvents.wpTabSiteVulnerabilitiesFilterFormSubmit();
        setFilter(filter);
        resetSelection();
        setIsFilterOpen(false);
    };

    const handleFilterReset = () => {
        resetFilter();
        resetSelection();
        setIsFilterOpen(false);
        analyticsEvents.wpTabSiteVulnerabilitiesFilterResetClick();
    };

    const filters = (
        <Filters<InstallationVulnerability>
            filter={componentFilter}
            onFilterApply={handleFilter}
            onReset={handleFilterReset}
            filteredData={filteredBySearchData}
        />
    );

    return (
        <DataList<InstallationVulnerabilityElement>
            className={styles.installationVulnerabilityList}
            vertical={vertical}
            toolbar={
                <Toolbar
                    selectedItems={selection}
                    displayTitle={installation.displayTitle}
                    searchValue={search.value}
                    onSearch={handleSearch}
                    updateOutdatedHandler={handleMitigateVulnerabilitiesViaUpdate}
                    updateBtnDisabled={updateBtnDisabled}
                    filter={componentFilter}
                    isFilterOpen={isFilterOpen}
                    onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
                    enableAfterUpdate={enableAfterUpdate}
                    setEnableAfterUpdate={setEnableAfterUpdate}
                    data={filteredData}
                    isProcessing={isVulnerabilityMitigating || hasMitigateVulnerabilitiesTask}
                />
            }
            search={search}
            rowKey="vulnerabilityId"
            data={dataWithDisabledFlag}
            loading={isLoading || isPreviousData}
            isPreviousData={
                isPreviousData || isVulnerabilityMitigating || hasMitigateVulnerabilitiesTask
            }
            selection={updateBtnDisabled ? undefined : selection}
            onSelectionChange={updateBtnDisabled ? undefined : setSelection}
            totalRows={Number(totalRows)}
            columns={columns}
            data-type="installation-vulnerabilities-list"
            emptyView={
                <ListEmptyView
                    title={translate('emptyList.title')}
                    description={translate('emptyList.description')}
                    image={image}
                />
            }
            sidebar={isFilterOpen ? filters : undefined}
            renderSelectionCheckbox={renderSelectionCheckbox}
            filtered={searchApplied || isComponentsFilterApplied}
        />
    );
};

export default TabVulnerabilities;
