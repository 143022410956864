// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    VULNERABILITY_PROVIDER_WORDFENCE,
    VulnerabilityProvider,
} from '@platform360/security-dashboard/web/types';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Popover, Text } from '@plesk/ui-library';
import { Cuttable } from '@platform360/security-dashboard/web/components/Cuttable';

export const MAX_DESCRIPTION_LENGTH = 100;

type VulnerabilityDescriptionProps = {
    description: string;
    providers?: VulnerabilityProvider[];
};

export const VulnerabilityDescription = ({
    description,
    providers,
}: VulnerabilityDescriptionProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.VulnerabilityDescription');
    const isWordfenceOnly =
        providers?.every((provider) => provider.provider === VULNERABILITY_PROVIDER_WORDFENCE) ??
        false;

    const descriptionOutput = (
        <Text intent="muted" component="div" fontSize="sm">
            <Cuttable text={description} length={MAX_DESCRIPTION_LENGTH} />
        </Text>
    );

    if (!isWordfenceOnly) {
        return descriptionOutput;
    }

    const url = 'https://www.wordfence.com/wordfence-intelligence-terms-and-conditions/';
    const copyright = translate('wordfenceCopyright');
    const readMore = translate('readMore');

    return (
        <>
            {descriptionOutput}
            <Popover
                target={
                    <Text intent="muted" component="div" fontSize="sm">
                        {translate('copyrightNotice')}
                    </Text>
                }
            >
                <>
                    {copyright}&nbsp;
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {readMore}
                    </a>
                </>
            </Popover>
        </>
    );
};
