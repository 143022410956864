// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import CustomInstallationForm, {
    DEFAULT_FORM_VALUES,
    FormValues,
} from '@platform360/web-installer/web/components/CustomInstallationForm';
import StepForm from '@platform360/server-inventory/web/components/Servers/AddingServer/StepForm';
import {
    useTranslateValidationErrors,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';
import { useGetConsentsQuery } from '@platform360/accounts/web/queries';
import { Navigate } from 'react-router-dom';
import useFeatureFlagEnabled from '@platform360/libs/shared-web/helpers/useFeatureFlagEnabled';
import { isDpaAccepted } from '@platform360/accounts/web/helpers/isDpaAccepted';
import { ProductTypesTranslations } from '@platform360/web-installer/web/constants';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

export type AddingServerWizardProps = {
    isLoading: boolean;
    errors: ValidationError[];
    onSubmit: (values: FormValues) => void;
    onBack: () => void;
    onHostChange?: (value: string) => void;
    techDomain?: string;
    submittedHost: string;
    productType: WebInstallerProduct;
};

export const CustomInstallationStep = ({
    isLoading,
    onSubmit,
    errors,
    onBack,
    onHostChange,
    techDomain,
    submittedHost,
    productType,
    ...props
}: AddingServerWizardProps) => {
    const chooseLicenseTypeEnabled = useFeatureFlagEnabled('webInstallerChooseLicenseType');
    const translate = useTranslate(
        'server-inventory.AddingServer.AddingServerWizard.CustomInstallationStep',
    );
    const translateValidationErrors = useTranslateValidationErrors();
    const installationErrors = errors.filter((e) => e.path === 'general');

    const { isLoading: isConsentLoading, data } = useGetConsentsQuery();
    const dpaAccepted = isDpaAccepted(data);

    if (!isConsentLoading && !dpaAccepted) {
        return <Navigate to="../add/dpa-agreement" />;
    }

    return (
        <StepForm
            {...props}
            title={translate('title', { productType: ProductTypesTranslations[productType] })}
            backButton={{
                children: translate('backButton'),
                onClick: onBack,
            }}
            forwardButton={{
                children: translate('submitButton'),
            }}
            values={DEFAULT_FORM_VALUES}
            state={isLoading || isConsentLoading ? 'submit' : undefined}
            errors={translateValidationErrors(errors)}
            onSubmit={onSubmit}
        >
            <CustomInstallationForm
                chooseLicenseTypeEnabled={chooseLicenseTypeEnabled}
                installationErrors={installationErrors}
                onHostChange={onHostChange}
                techDomain={techDomain}
                submittedHost={submittedHost}
                productType={productType}
            />
        </StepForm>
    );
};
