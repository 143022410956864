// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import OutdatedAgentError from '@platform360/security-dashboard/web/components/Servers/OutdatedAgentError';
import { Installation } from '@platform360/security-dashboard/web/types';
import { Button, Panel, Link, StatusMessage, Text } from '@plesk/ui-library';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';

import InstallationAssetsSummary from './InstallationAssetsSummary';
import InstallationCoreSummary from './InstallationCoreSummary';
import style from './InstallationSummary.module.css';
import UpdateSettingsCard from '@platform360/security-dashboard/web/components/UpdateSettingsCard';
import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import { InstallationComponents } from '@platform360/security-dashboard/shared/installation-component';
import { UPDATE_ITEMS_TASK_TYPE } from '@platform360/security-dashboard/shared/constants';

type InstallationSummaryProps = {
    installation: Installation;
};

const UPDATE_INSTANCE_TASK_CODE = 'updateInstance';
const UPDATE_THEMES_TASK_CODE = 'updateThemes';
const UPDATE_PLUGINS_TASK_CODE = 'updatePlugins';

const InstallationSummary = ({ installation }: InstallationSummaryProps) => {
    const translate = useTranslate('security-dashboard.Installations.InstallationSummary');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    if (installation.server.unsupportedAgent) {
        return <OutdatedAgentError serverVersion={installation.server.agentVersion} />;
    }
    if (!installation.operable) {
        return (
            <StatusMessage intent="danger">
                {installation.error ?? translate('notOperableUnknownError')}
            </StatusMessage>
        );
    }
    const isGlobalUpdating = installation.tasks.some(({ type }) => type === UPDATE_ITEMS_TASK_TYPE);

    const isInstanceUpdating =
        installation.tasks.some((task) =>
            task.info?.steps.some((step) => step.code === UPDATE_INSTANCE_TASK_CODE),
        ) || isGlobalUpdating;
    const isPluginsUpdating =
        installation.tasks.some((task) =>
            task.info?.steps.some((step) => step.code === UPDATE_PLUGINS_TASK_CODE),
        ) || isGlobalUpdating;
    const isThemesUpdating =
        installation.tasks.some((task) =>
            task.info?.steps.some((step) => step.code === UPDATE_THEMES_TASK_CODE),
        ) || isGlobalUpdating;

    const {
        updateSettings: { core, plugins, themes, safeUpdate },
    } = installation;

    return (
        <div>
            <div className={style.summaryRow}>
                {installation.core === null ? null : (
                    <InstallationCoreSummary
                        outdated={!!installation.core.availableVersion}
                        vulnerabilities={installation.core.vulnerabilities}
                        installationId={installation.id}
                        version={installation.core.version}
                        availableVersion={installation.core.availableVersion}
                        isUpdating={isInstanceUpdating}
                        onClickVulnerable={() => analyticsEvents.wpCrdVulnerableCoreClick()}
                        onClickOutdated={() => analyticsEvents.wpCrdOutdatedCoreClick()}
                        onClickUpdate={() => analyticsEvents.wpCrdUpdateCoreClick()}
                        onClickUpdateConfirmation={() =>
                            analyticsEvents.wpCrdUpdateCoreConfirmationClick()
                        }
                    />
                )}
                {installation.plugins === null ? null : (
                    <InstallationAssetsSummary
                        title={translate('AssetSummary.plugin.title', {
                            count: installation.plugins.total,
                        })}
                        outdated={installation.plugins.outdated}
                        vulnerabilities={installation.plugins.vulnerabilities}
                        componentType={InstallationComponents.plugin}
                        installationId={installation.id}
                        updateButtonText={translate('AssetSummary.plugin.update')}
                        onClickVulnerable={() => analyticsEvents.wpCrdVulnerablePluginsClick()}
                        onClickOutdated={() => analyticsEvents.wpCrdOutdatedPluginsClick()}
                        onClickUpdate={() => analyticsEvents.wpCrdUpdatePluginsClick()}
                        onClickUpdateConfirmation={() =>
                            analyticsEvents.wpCrdUpdatePluginsConfirmationClick()
                        }
                        isUpdating={isPluginsUpdating}
                    />
                )}
                {installation.themes === null ? null : (
                    <InstallationAssetsSummary
                        title={translate('AssetSummary.theme.title', {
                            count: installation.themes.total,
                        })}
                        outdated={installation.themes.outdated}
                        vulnerabilities={installation.themes.vulnerabilities}
                        componentType={InstallationComponents.theme}
                        installationId={installation.id}
                        updateButtonText={translate('AssetSummary.theme.update')}
                        onClickVulnerable={() => analyticsEvents.wpCrdVulnerableThemesClick()}
                        onClickOutdated={() => analyticsEvents.wpCrdOutdatedThemesClick()}
                        onClickUpdate={() => analyticsEvents.wpCrdUpdateThemesClick()}
                        onClickUpdateConfirmation={() =>
                            analyticsEvents.wpCrdUpdateThemesConfirmationClick()
                        }
                        isUpdating={isThemesUpdating}
                    />
                )}
                {installation.managed && (
                    <div className={style.tableItem}>
                        <Panel>
                            <UpdateSettingsCard
                                title={translate('updateSettings')}
                                updateSettings={{
                                    core: core.value ?? core.parent,
                                    themes: themes.value ?? themes.parent,
                                    plugins: plugins.value ?? plugins.parent,
                                    safeUpdate: safeUpdate.value ?? safeUpdate.parent,
                                }}
                                button={
                                    <Button
                                        ghost
                                        icon="gear"
                                        component={DrawerLink}
                                        to={`/security-dashboard/installations/${installation.id}/update-settings`}
                                    />
                                }
                            />
                        </Panel>
                    </div>
                )}
            </div>
            <div className={classNames(style.summaryDetails, style.summaryRow)}>
                {Boolean(installation.server.hostname) && (
                    <div>
                        <Text bold>{translate('hostname')}</Text>{' '}
                        <Link
                            component={RouterLink}
                            to={buildUrl('/security-dashboard/installations', {
                                search: encodeURIComponent(String(installation.server.hostname)),
                                searchServer: encodeURIComponent(
                                    String(installation.server.hostname),
                                ),
                            })}
                        >
                            {installation.server.hostname}
                        </Link>{' '}
                    </div>
                )}
                {Boolean(installation.server.ipAddress) && (
                    <div>
                        <Text bold>{translate('ipAddress')}</Text>{' '}
                        <Text>{installation.server.ipAddress}</Text>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InstallationSummary;
