// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Form, setIn } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import useNotificationsSettingsQuery from '@platform360/security-dashboard/web/queries/useNotificationsSettingsQuery';
import { useSaveSettingsMutation } from '@platform360/security-dashboard/web/mutations';
import { useSettingsQuery } from '@platform360/security-dashboard/web/queries';
import { SettingsResponse } from '@platform360/security-dashboard/web/api/settings';
import Loading from '@platform360/libs/shared-web/components/Loading';
import { NotificationSettingsEventSection } from '@platform360/security-dashboard/web/components/NotificationSettingsEventSection';
import IgnoreLowRiskSection from '@platform360/security-dashboard/web/components/Settings/IgnoreLowRiskSection';
import { QuickStartStep, QuickStartStepCommonProps } from '../QuickStartStep/QuickStartStep';

import styles from './NotificationsStep.module.css';

type FormValues = Pick<SettingsResponse, 'vulnerabilities'>;
type PartialFormValues = {
    vulnerabilities: Partial<FormValues['vulnerabilities']>;
};

const defaultValues: PartialFormValues = { vulnerabilities: {} };

const defaultSettings: Pick<SettingsResponse, 'vulnerabilities'> = Object.freeze({
    vulnerabilities: {
        ignoreLowRisk: false,
    },
});

type NotificationsStepProps = QuickStartStepCommonProps;

export const NotificationsStep = ({ onSubmit, ...props }: NotificationsStepProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const { data: notificationsSettings, isLoading: isLoadingNotificationsSettings } =
        useNotificationsSettingsQuery();
    const { data: settings = defaultSettings, isLoading: isLoadingSettings } = useSettingsQuery();

    const [formValues, setFormValues] = useState<PartialFormValues>(defaultValues);

    const values = {
        vulnerabilities: {
            ...settings.vulnerabilities,
            ...formValues.vulnerabilities,
        },
    };

    const handleFieldChange = (key: string, value: FormValues[keyof FormValues]) =>
        setFormValues(setIn(formValues, key, value));

    const { mutate, isPending: isUpdating } = useSaveSettingsMutation({
        onSuccess: () => {
            onSubmit?.();
        },
    });

    const handleSubmit = () => {
        const isDirty = !isEqual(settings.vulnerabilities, values.vulnerabilities);
        if (!isDirty) {
            onSubmit?.();
            return;
        }
        mutate(values);
        analyticsEvents.wpQuickStartIgnoreLowRiskSettingsSave(values.vulnerabilities.ignoreLowRisk);
    };

    const renderContent = () => {
        if (isLoadingNotificationsSettings || isLoadingSettings) {
            return <Loading />;
        }

        return (
            <>
                {notificationsSettings && (
                    <NotificationSettingsEventSection settings={notificationsSettings} />
                )}
                <Form
                    submitButton={false}
                    applyButton={false}
                    cancelButton={false}
                    onFieldChange={handleFieldChange}
                    values={values}
                    footerClassName={styles.footerHidden}
                >
                    <IgnoreLowRiskSection
                        showWarning={
                            settings.vulnerabilities.ignoreLowRisk &&
                            !values.vulnerabilities.ignoreLowRisk
                        }
                    />
                </Form>
            </>
        );
    };

    return (
        <QuickStartStep
            onSubmit={handleSubmit}
            submitButtonProps={{ state: isUpdating ? 'loading' : undefined }}
            {...props}
        >
            {renderContent()}
        </QuickStartStep>
    );
};
