// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Dialog, FormFieldText } from '@plesk/ui-library';
import { useUpdateUserMutation } from '@platform360/admin-panel/web/mutations';
import { useToaster } from '@platform360/libs/shared-web/toaster';

export type UserBlockDialogProps = {
    userId: number;
    isOpen: boolean;
    onClose: () => void;
};

export const UserBlockDialog = ({ userId, isOpen, onClose }: UserBlockDialogProps) => {
    const translate = useTranslate('admin-panel.Users.UserCard.UserBlockDialog');
    const { successToast } = useToaster();
    const {
        mutate,
        isPending: isSaving,
        errors,
    } = useUpdateUserMutation({
        onSuccess: () => {
            onClose();
            successToast(translate('successToast'));
        },
    });

    const handleSubmit = ({ blockingComment }: { blockingComment: string }) => {
        mutate({
            userId,
            data: {
                blocked: true,
                blockingComment,
            },
        });
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title={translate('title')}
            size="sm"
            form={{
                errors,
                vertical: false,
                state: isSaving ? 'submit' : undefined,
                submitButton: {
                    children: translate('blockButton'),
                    intent: 'danger',
                },
                onSubmit: handleSubmit,
            }}
        >
            <FormFieldText
                name="blockingComment"
                size="fill"
                placeholder={translate('placeholder')}
                label={null}
                multiline
            />
        </Dialog>
    );
};
