// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement } from 'react';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Icon, Link, Text, Tooltip, TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type DeleteButtonProps = {
    id: number;
    name: string;
    handleTokenDelete: (id: number) => void;
    copyToClipboard: (text: string) => void;
    wrap: (text: TranslationProps) => ReactElement;
};

const DeleteButton = ({
    id,
    name,
    handleTokenDelete,
    copyToClipboard,
    wrap,
}: DeleteButtonProps) => {
    const translate = useTranslate('accounts.ApiTokens.DeleteButton');
    return (
        <ConfirmationPopover
            onClick={() => handleTokenDelete(id)}
            target={wrap(translate('deleteButton'))}
            actionButtonText={translate('deleteConfirmationButton')}
            placement="right-top"
            confirmString={name}
            confirmStringDescription={translate('deleteConfirmationInputDesc')}
        >
            <p>
                {translate('deleteConfirmation', {
                    tooltip: (chunk) => (
                        <Tooltip title={translate('copy')}>
                            <Link
                                onClick={() => copyToClipboard(name)}
                                data-type="confirmation-popover-token-name"
                            >
                                <Text bold intent="info">
                                    {chunk}
                                </Text>
                                <Icon name="copy-indicator" />
                            </Link>
                        </Tooltip>
                    ),
                    name,
                })}
            </p>
        </ConfirmationPopover>
    );
};

export default DeleteButton;
