// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import ExpirationLabel from '@platform360/security-dashboard/web/components/ExpirationLabel';
import QuickStartStep, { QuickStartStepCommonProps } from '../QuickStartStep';

import styles from './StartStep.module.css';

type StartStepProps = QuickStartStepCommonProps;

export const StartStep = ({ heading: _heading, ...props }: StartStepProps) => {
    const { data } = useLicenseQuery();
    const translate = useTranslate('security-dashboard.QuickStartDialog.StartStep');

    return (
        <QuickStartStep
            heading=""
            closeButtonProps={{ ghost: false, intent: 'info' }}
            ignoreContentIndents
            {...props}
        >
            <div className={styles.imgContainer} />
            <div className={styles.textContainer}>
                {data && <ExpirationLabel license={data} />}
                <Heading level={3}>{translate('title')}</Heading>
                <Text>{translate('text')}</Text>
            </div>
        </QuickStartStep>
    );
};
