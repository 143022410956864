// Copyright 2024. WebPros International GmbH. All rights reserved.

import { EmailMetaResponseData } from '@platform360/admin-panel/shared/api-contract';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import CopyText from '@platform360/libs/shared-web/components/CopyText';
import { Button, Heading, Panel, Section, SectionItem, Status } from '@plesk/ui-library';

type EmailMetaCardProps = {
    data: EmailMetaResponseData;
    isDeleting: boolean;
    onEmailMetaDelete: (email: string) => void;
};

export const EmailMetaCard = ({
    data: { email, diagnosticCode, bounced, complained, createdAt, updatedAt },
    isDeleting,
    onEmailMetaDelete,
}: EmailMetaCardProps) => {
    const translate = useTranslate('admin-panel.EmailMetas.EmailMetaCard');
    const formatDateTime = useFormatDateTime();

    return (
        <Panel
            title={<Heading level={3}>{email}</Heading>}
            extra={[
                <ConfirmationPopover
                    key="unblock"
                    onClick={() => onEmailMetaDelete(email)}
                    target={
                        <Button
                            icon="lock-open"
                            key="button"
                            state={isDeleting ? 'loading' : undefined}
                            tooltip={translate('actions.unblock.tooltip')}
                            ghost
                        >
                            {translate('actions.unblock.buttonText')}
                        </Button>
                    }
                    actionButtonText={translate('actions.unblock.buttonText')}
                >
                    {translate('actions.unblock.description')}
                </ConfirmationPopover>,
            ]}
        >
            <Section vertical={false}>
                <SectionItem title={translate('email')}>
                    <CopyText text={email} />
                </SectionItem>
                <SectionItem title={translate('diagnosticCode')}>
                    {diagnosticCode ?? '—'}
                </SectionItem>
                <SectionItem title={translate('bounced')}>
                    <Status intent={bounced ? 'success' : 'inactive'} />
                </SectionItem>
                <SectionItem title={translate('complained')}>
                    <Status intent={complained ? 'success' : 'inactive'} />
                </SectionItem>
                <SectionItem title={translate('createdAt')}>
                    {createdAt ? formatDateTime(createdAt) : '—'}
                </SectionItem>
                <SectionItem title={translate('updatedAt')}>
                    {updatedAt ? formatDateTime(updatedAt) : '-'}
                </SectionItem>
            </Section>
        </Panel>
    );
};
