// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './CardLoader.module.css';
import { ContentLoader } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const CardLoader = () => {
    const translate = useTranslate('security-dashboard.Layout.Header.CardLoader');

    return (
        <div className={styles.root}>
            <div className={styles.loader}>
                <ContentLoader text={translate('loading')} />
            </div>
        </div>
    );
};

export default CardLoader;
