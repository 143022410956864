// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './root-reducer';
import historyMiddleware from './middleware/history';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;

const configureStore = () =>
    // eslint-disable-next-line deprecation/deprecation
    createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware, historyMiddleware)));

export const store = configureStore();
