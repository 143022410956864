// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { addDemoServer } from '@platform360/security-dashboard/web/api/servers';

type UseDemoServerAddMutationOptions = {
    onSuccess: () => void;
};

const useDemoServerAddMutation = ({ onSuccess }: UseDemoServerAddMutationOptions) =>
    useMutation({
        mutationFn: addDemoServer,
        onSuccess,
    });

export default useDemoServerAddMutation;
