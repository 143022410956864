// Copyright 2024. WebPros International GmbH. All rights reserved.

import alipay from './icons/alipay.svg';
import amex from './icons/amex.svg';
import jcb from './icons/jcb.svg';
import mastercard from './icons/mastercard.svg';
import visacard from './icons/visacard.svg';
import paypal from './icons/paypal.svg';
import webmoney from './icons/webmoney.svg';
import wiretransfer from './icons/wiretransfer.svg';
import cashpay from './icons/cashpay.svg';

const getIconById = (paymentTypeId) => {
    switch (paymentTypeId) {
        case 'ALI_STD':
            return alipay;
        case 'CCA_AMX':
            return amex;
        case 'CCA_JCB':
            return jcb;
        case 'CCA_MAS':
            return mastercard;
        case 'CCA_VIS':
            return visacard;
        case 'PPA_STD':
            return paypal;
        case 'WMO_STD':
            return webmoney;
        case 'WTR_STD':
            return wiretransfer;
        default:
            return cashpay;
    }
};

export default getIconById;
