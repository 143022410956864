// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useServerQuery from '@platform360/monitoring/web/queries/useServerQuery';
import ServerViewMonitoringContainer from './ServerViewMonitoringContainer';

type UseServerViewMonitoringParams = {
    guid: string;
    address: URL;
    isMonitoringConnectionSupported?: boolean;
};

export const useServerViewMonitoring = (
    { guid, address, isMonitoringConnectionSupported }: UseServerViewMonitoringParams,
    isServerReady: boolean,
) => {
    const translate = useTranslate('monitoring.ServerViewMonitoring');
    const isHideMonitoringTab = !isMonitoringConnectionSupported;
    const { data: monitoringServer } = useServerQuery({
        variables: {
            serverGuid: guid,
        },
        enabled: isServerReady && !isHideMonitoringTab,
        // ServerViewMonitoringContainer is responsible for the error handling
        meta: {
            defaultErrorHandler: false,
        },
    });
    const alertsCounter = monitoringServer?.alerts.length ? (
        <>
            {' '}
            <Label intent="danger" data-type="monitoring-tab-alerts-count">
                {monitoringServer.alerts.length}
            </Label>
        </>
    ) : null;

    if (isHideMonitoringTab) {
        return undefined;
    }

    return {
        url: 'monitoring',
        title: (
            <>
                {translate('tabTitle')}
                {alertsCounter}
            </>
        ),
        element: <ServerViewMonitoringContainer serverGuid={guid} address={address} />,
    };
};
