// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'Herramientas 360',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Inventario de servidores',
            description: 'Todas las instancias registradas desde una única ubicación y un conjunto de credenciales',
        },
        licenseManagement: {
            title: 'Administración de licencias',
            description: 'Compre y gestione todas sus licencias para Plesk, sus extensiones y 360 Tools',
        },
        feedback: {
            title: 'Háganos llegar su opinión',
            description: 'Esto nos ayudará con su futuro desarrollo',
        },
        websiteInventory: {
            title: 'Inventario de sitios web',
            description: 'Vea y gestione todos sus sitios web',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Monitorice sus servidores y sitios web desde un único panel',
            description: 'Solución centralizada para efectuar un seguimiento del rendimiento e impedir interrupciones del servicio que ofrece paneles configurables, alertas personalizadas, verificaciones de un minuto, historial de red y mucho más',
            primaryButton: 'Pruébelo gratis durante 14 días',
            secondaryButton: 'Más información',
        },
        licenses: {
            title: 'Gestione sus licencias',
            description: 'Compre, ajuste y termine todas sus licencias para Plesk, sus extensiones y 360 Tools desde un único sitio',
            primaryButton: 'Vamos allá',
            secondaryButton: 'Más información',
        },
        serverInventory: {
            title: 'Todas las instancias registradas desde una única ubicación y un conjunto de credenciales',
            description: 'Conecte todos sus servidores Plesk al inventario de servidores para así obtener la lista completa de sus sitios web y cree un acceso de un solo clic a estos',
            primaryButton: 'Conectar servidores',
            secondaryButton: 'Más información',
        },
        websiteInventory: {
            title: 'Todos sus sitios y dominios en una única lista',
            description: 'Vea, busque y filtre sus sitios web, además de poderlos gestionar mediante un solo clic',
            primaryButton: 'Ver sitios web',
            secondaryButton: 'Más información',
        },
    },
};