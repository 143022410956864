// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEmailMeta } from '@platform360/admin-panel/web/api/email-metas';
import { useEmailMetasQuery } from '@platform360/admin-panel/web/queries';

type UseDeleteEmailMetaMutationOptions = {
    onSuccess: () => void;
};

const useDeleteEmailMetaMutation = ({ onSuccess }: UseDeleteEmailMetaMutationOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteEmailMeta,
        onSuccess: () => {
            queryClient.removeQueries({
                queryKey: useEmailMetasQuery.getQueryKey(),
            });

            onSuccess();
        },
    });
};

export default useDeleteEmailMetaMutation;
