// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { loginTargetValues, isLoginTarget, LoginTargets } = makeEnum(
    'LoginTarget',
    'home',
    'client',
    'domain',
    'updates',
);

export type LoginTarget = UnionOf<typeof loginTargetValues>;

export type HomePageLogin = {
    target: typeof LoginTargets.home;
    serverId: number;
};

export type UpdatePageLogin = {
    target: typeof LoginTargets.updates;
    serverId: number;
};

export type DomainPageLogin = {
    target: typeof LoginTargets.domain;
    serverId: number;
    domainId: number;
};

export type ClientPageLogin = {
    target: typeof LoginTargets.client;
    serverId: number;
    clientId: number;
};

export type LoginLinkParams = HomePageLogin | DomainPageLogin | ClientPageLogin | UpdatePageLogin;

export const isHomePageLogin = (p: LoginLinkParams): p is HomePageLogin =>
    p.target === LoginTargets.home;

export const isClientPageLogin = (params: LoginLinkParams): params is ClientPageLogin =>
    params.target === LoginTargets.client;

export const isDomainPageLogin = (params: LoginLinkParams): params is DomainPageLogin =>
    params.target === LoginTargets.domain;

export const isUpdatePageLogin = (params: LoginLinkParams): params is UpdatePageLogin =>
    params.target === LoginTargets.updates;
