// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import {
    ButtonProps,
    ContentLoader,
    Paragraph,
    StatusMessage,
    TranslationProps,
} from '@plesk/ui-library';
import DialogLayout from '@platform360/libs/shared-web/components/DialogLayout';
import CancelConfirmation, {
    CancelConfirmationLocaleProp,
} from '@platform360/licenses/web/components/ActivateLicense/common/CancelConfirmation';
import { TransformedError } from '@platform360/licenses/web/components/ActivateLicense/common/transformError';

export type ActivateLicenseDialogProps = {
    onCancel: () => void;
    onConfirm: () => void;
    isLoading: boolean;
    isRedirecting: boolean;
    error?: TransformedError;
    userEmail: string;
    product: string;
    locale: {
        activateLicenseDialog: {
            cancelButton: TranslationProps;
            confirmButton: TranslationProps;
            description: TranslationProps;
            errors: {
                linkError: TranslationProps;
                unknownError: TranslationProps;
            };
            fixableErrorDescription: TranslationProps;
            gotItButton: TranslationProps;
            loaderText: TranslationProps;
            nonFixableErrorDescription: TranslationProps;
            redirectText: TranslationProps;
            retryButton: TranslationProps;
            title: TranslationProps;
        };
        cancelConfirmation: CancelConfirmationLocaleProp;
    };
    dataType: 'monitoring-activate-license' | 'security-dashboard-activate-license';
};

const ActivateLicenseDialog = ({
    onCancel,
    onConfirm,
    isLoading,
    isRedirecting,
    error,
    locale,
    dataType,
}: ActivateLicenseDialogProps) => {
    const [isRetriable, setIsRetriable] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (error) {
            setIsRetriable(error.isFixable);
        }
    }, [error]);

    const renderSubmitButton = () => {
        if (isRetriable === undefined) {
            return {
                onClick: onConfirm,
                icon: 'check-mark',
                children: locale.activateLicenseDialog.confirmButton,
                'data-type': 'confirm-button',
                disabled: isLoading || isRedirecting,
            };
        }

        return isRetriable
            ? {
                  onClick: onConfirm,
                  icon: 'reload',
                  children: locale.activateLicenseDialog.retryButton,
                  'data-type': 'retry-button',
                  disabled: isLoading || isRedirecting,
              }
            : false;
    };

    const renderCancelButton = () => {
        if (isRetriable === undefined) {
            return {
                onClick: onCancel,
                component: CancelConfirmation,
                buttonText: locale.activateLicenseDialog.cancelButton,
                locale: locale.cancelConfirmation,
            };
        }

        return isRetriable
            ? {
                  onClick: onCancel,
                  icon: 'remove',
                  intent: undefined,
                  children: locale.activateLicenseDialog.cancelButton,
                  'data-type': 'cancel-button',
              }
            : {
                  onClick: onCancel,
                  icon: 'check-mark',
                  intent: undefined,
                  children: locale.activateLicenseDialog.gotItButton,
                  'data-type': 'got-it-button',
              };
    };

    const renderDescription = () => {
        if (isRetriable === undefined) {
            return locale.activateLicenseDialog.description;
        }

        return isRetriable
            ? locale.activateLicenseDialog.fixableErrorDescription
            : locale.activateLicenseDialog.nonFixableErrorDescription;
    };

    const renderContent = () => {
        if (isRedirecting) {
            return <ContentLoader text={locale.activateLicenseDialog.redirectText} />;
        }

        if (isLoading) {
            return <ContentLoader text={locale.activateLicenseDialog.loaderText} />;
        }
        return (
            <>
                {error && (
                    <StatusMessage intent="danger">
                        {error.key === 'linkError' && locale.activateLicenseDialog.errors.linkError}
                        {error.key === 'unknownError' &&
                            locale.activateLicenseDialog.errors.unknownError}
                    </StatusMessage>
                )}
                <h2>{locale.activateLicenseDialog.title}</h2>
                <Paragraph>{renderDescription()}</Paragraph>
            </>
        );
    };

    return (
        <DialogLayout
            form={{
                // TODO Fix UI Library types
                applyButton: renderCancelButton() as ButtonProps,
                submitButton: renderSubmitButton(),
            }}
            data-type={dataType}
        >
            {renderContent()}
        </DialogLayout>
    );
};

export default ActivateLicenseDialog;
