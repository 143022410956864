// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './ChatButton.module.less';
import { Icon, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Ref } from 'react';

type ChatButtonProps = {
    btnRef?: Ref<HTMLButtonElement>;
};

const ChatButton = ({ btnRef, ...props }: ChatButtonProps) => {
    const translate = useTranslate('ZendeskChat.ChatButton');

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <button className={styles.button} type="button" ref={btnRef} {...props}>
                    <Icon className={styles.icon} name="question-mark-circle" size="24" />
                    <Text fontSize="md" bold>
                        {translate('title')}
                    </Text>
                </button>
            </div>
        </div>
    );
};

export default ChatButton;
