// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import monitoring from '@platform360/monitoring/web/api/monitoring';
import { createQuery } from '@platform360/libs/shared-web/query';
import { ServerMetricsImageRequest } from '@platform360/monitoring/shared/api-contract';

export type UseServerMetricsImageVariables = {
    serverGuid: string;
    params: ServerMetricsImageRequest;
};

export type UseServerMetricsImageData = string;

const useServerMetricsImage = createQuery<
    UseServerMetricsImageVariables,
    UseServerMetricsImageData
>({
    queryName: 'monitoring/useServerMetricsImage',
    fetcher: monitoring.serverMetricsImage,
    useQuery,
});

export default useServerMetricsImage;
