// Copyright 2024. WebPros International GmbH. All rights reserved.

import StartStep from './StartStep';
import CustomInstallationStep from './CustomInstallationStep';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { Dialog, Form } from '@plesk/ui-library';
import { ReactNode, useContext } from 'react';
import AddServerWithRedirectStep from './AddServerWithRedirectStep';
import ConnectCpanelServerStep from './ConnectCpanelServerStep';
import { DpaDialog } from '@platform360/accounts/web/profile/DpaDialog';
import {
    StepFormContext,
    StepFormProvider,
} from '@platform360/server-inventory/web/components/Servers/AddingServer/StepForm';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';

export type AddingServerWizardProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const AddingServerWizard = ({ isOpen, onClose }: AddingServerWizardProps) => {
    const navigate = useNavigate();
    const { pathname } = useResolvedPath('..', { relative: 'path' });
    const { step = 'start' } = useParams<{ step?: string }>();
    const { stepTitle, stepSubTitle } = useContext(StepFormContext);
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const handleBack = () => {
        navigate(pathname);
    };
    const handleClose = () => {
        analyticsEvents.addingServerWizardClose();
        onClose();
    };
    let form: ReactNode | undefined = undefined;

    if (step === 'start') {
        form = <StartStep onClose={handleClose} />;
    }

    if (step === 'plesk') {
        form = <AddServerWithRedirectStep onClose={handleClose} onBack={handleBack} />;
    }

    if (step === 'cpanel') {
        form = <ConnectCpanelServerStep onClose={handleClose} onBack={handleBack} />;
    }

    if (['dpa-agreement-plesk', 'dpa-agreement-cpanel'].includes(step)) {
        const product = step.split('-').pop();

        form = (
            <DpaDialog
                isOpen
                initialValue={false}
                onAccept={() => navigate(`../add/server-installation-${product}`)}
                onDecline={handleBack}
                onClose={handleClose}
                size="sm"
            />
        );
    }

    if (step === 'server-installation-plesk') {
        form = (
            <CustomInstallationStep onClose={handleClose} onBack={handleBack} productType="plesk" />
        );
    }

    if (step === 'server-installation-cpanel') {
        form = (
            <CustomInstallationStep
                onClose={handleClose}
                onBack={handleBack}
                productType="cpanel"
            />
        );
    }

    if (!form) {
        return null;
    }

    return (
        <Dialog
            title={stepTitle}
            subtitle={stepSubTitle}
            isOpen={isOpen}
            onClose={handleClose}
            size="sm"
            form={form as unknown as typeof Form}
            data-type="adding-server-wizard-dialog"
        />
    );
};

export type AddingServerWizardWrapperProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const AddingServerWizardWrapper = ({ ...props }: AddingServerWizardWrapperProps) => (
    <StepFormProvider>
        <AddingServerWizard {...props} />
    </StepFormProvider>
);
