// Copyright 2024. WebPros International GmbH. All rights reserved.

import shared from '@platform360/libs/shared-web/locale/locales/ru';
import webInstaller from '@platform360/web-installer/web/locales/ru';
import accounts from '@platform360/accounts/web/locales/ru';
import homePage from '@platform360/home-page/web/locales/ru';
import monitoring from '@platform360/monitoring/web/locales/ru';
import licenses from '@platform360/licenses/web/locales/ru';
import auth from '@platform360/auth/web/locales/ru';
import serverInventory from '@platform360/server-inventory/web/locales/ru';
import securityDashboard from '@platform360/security-dashboard/web/locales/ru';
import app from '@platform360/app/web/locales/ru';

export default {
    ...shared,
    'web-installer': webInstaller,
    'home-page': homePage,
    accounts,
    monitoring,
    licenses,
    auth,
    'server-inventory': serverInventory,
    'security-dashboard': securityDashboard,
    app,
};
