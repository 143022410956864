// Copyright 2024. WebPros International GmbH. All rights reserved.

import logo from './webpros-logo.svg';
import logoWhite from './webpros-logo-invert.svg';

type WebProsLogoProps = {
    isLink?: boolean;
    invert?: boolean;
    width?: number;
};

const WebProsLogo = ({
    isLink = false,
    invert = false,
    width = 120,
    ...props
}: WebProsLogoProps) => {
    const logoImage = (
        <img width={width} src={invert ? logoWhite : logo} alt="WebPros logo" {...props} />
    );

    return isLink ? (
        <a href="https://www.webpros.com" data-type="Page--linkToWebProsSite">
            {logoImage}
        </a>
    ) : (
        logoImage
    );
};

export default WebProsLogo;
