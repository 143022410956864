// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getServers, SitesHealthFilter } from '@platform360/security-dashboard/web/api/servers';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseServersQueryVariables = {
    filter?: string;
    sitesHealthFilter?: SitesHealthFilter;
    labelIdsFilter?: string[];
} & PaginatedQuery;

export type UseServersQueryData = PromiseValue<ReturnType<typeof getServers>>;

const useServersQuery = createQuery<UseServersQueryVariables, UseServersQueryData>({
    queryName: 'security-dashboard/useServersQuery',
    fetcher: getServers,
    useQuery,
});

export default useServersQuery;
