// Copyright 2024. WebPros International GmbH. All rights reserved.

import { resetPassword } from '@platform360/auth/web/api/passwords';
import { useMutation } from '@tanstack/react-query';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const useResetPassword = (onSuccess: () => void) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { error, ...rest } = useMutation({ mutationFn: resetPassword, onSuccess });

    return { errors: translateValidationErrors(getValidationErrors(error)), ...rest };
};

export default useResetPassword;
