// Copyright 2024. WebPros International GmbH. All rights reserved.

const getButtonLink = (monitoringUserId: string | undefined): string => {
    if (monitoringUserId) {
        // the solution from mdn
        // @link https://developer.mozilla.org/ru/docs/Web/API/btoa
        const base64OfMonitoringUserId = btoa(
            decodeURIComponent(encodeURIComponent(monitoringUserId)),
        );

        return `https://go.plesk.com/buy-plesk-ext/360monitoring?token=${base64OfMonitoringUserId}`;
    }

    return '/monitoring';
};

export default getButtonLink;
