// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Unknown error: {message}.',
        newServer: 'A server with the same address already exists.',
        domainName: 'The domain name cannot be resolved.',
        serverMisconfigured: 'The server is misconfigured and cannot be added.',
        pleskConfiguration: 'Plesk version is not supported (below {pleskMinVersion}) or Plesk is misconfigured.',
        unsupportedServerVersion: 'The installed Plesk version is outdated and cannot be added. {kbLink}',
        connection: 'The network connection was aborted.',
        connectionRefused: 'The network connection was refused. Please check the specified domain and port.',
        timeout: 'The network connection to the server timed out.',
        apiKey: 'The connection is not authorized by the remote server. Please add the server to Server Inventory again.',
        httpsRequired: 'The server does not support HTTPS.',
        certHasExpired: 'The certificate has expired. {kbLink}',
        errTlsCertAltnameInvalid: 'The server’s hostname or IP address does not match the certificate’s altnames. {kbLink}',
        depthZeroSelfSignedCert: 'A self-signed certificate is installed. {kbLink}',
        selfSignedCertInChain: 'There is a self-signed certificate in the certificate chain. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'Notification settings were updated.',
        },
    },
    Layout: {
        title: 'Server Inventory',
        serversTitle: 'Servers',
        clientsTitle: 'Clients',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Notifications Settings',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Notifications',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Which notifications to send',
        notifyServerUnreachable: 'Server unreachable',
        notifyServerUnsupported: 'Server unsupported',
    },
    AllDomains: {
        title: 'Websites',
        name: 'Name',
        hostingType: 'Hosting Type',
        virtualHostingType: 'Website',
        forwardingHostingType: 'Forwarding',
        noneHostingType: 'No hosting',
        mainDomainType: 'Main Domain',
        subDomainType: 'Subdomain',
        addonDomainType: 'Addon Domain',
        parkedDomainType: 'Alias',
        hostingServer: 'Hosting Server',
        manage: 'Manage',
        manageLinkUnavailableHint: 'Plesk RESTful API 1.4.0 or later is required to generate a direct link to the Plesk panel. Check Documentation for more details.',
        open: 'Open',
    },
    AllClients: {
        name: 'Contact Name',
        email: 'Email Address',
        login: 'User Name',
        type: 'Type',
        status: 'Status',
        typeAdmin: 'Admin',
        typeReseller: 'Reseller',
        typeCustomer: 'Customer',
        typeCustomerWithOwner: 'Customer of {owner}',
        statusActive: 'Active',
        statusSuspended: 'Suspended',
        hostingServer: 'Hosting Server',
        manage: 'Manage',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Synchronization in progress',
            installationRunning: 'Installation is running',
            installationFailure: 'Installation error',
            installationLicenseRequired: 'License required',
            active: 'Active, secure',
            inactiveUnreachable: 'Inactive, unreachable',
            inactiveUnreachableTooltip: 'The server could not be reached for at least fourteen days',
            inactiveUnsupported: 'Inactive, unsupported',
            inactiveUnsupportedTooltip: 'The server is running an unsupported Plesk version',
            insecure: 'Active, insecure',
            outdated: 'Active, outdated',
            outdatedTooltip: '{version, select, _ {Your current Plesk version is outdated.} other {Your current Plesk version {version} is outdated.}} We recommend that you update your server to the latest available Plesk Obsidian version and enable auto-updates.',
            unsupportedVersion: 'Active, unsupported',
            unsupportedVersionTooltip: '{version, select, _ {Your current Plesk version is unsupported} other {Your current Plesk version {version} is unsupported}} and your server will be deactivated in 14 days. We recommend that you update your server to the latest available Plesk Obsidian version and enable auto-updates.',
            certificateWarning: 'Certificate warning',
            certificateError: 'Certificate error',
            connectionBlocked: 'Connection blocked',
            connectionError: 'Connection error',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'Installation logs not found.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {Your current Plesk version is outdated.} other {Your current Plesk version {version} is outdated.}}',
                        fixDescription: 'We recommend that you update your server to the latest available Plesk Obsidian version and enable auto-updates. Read the <link>Plesk Lifecycle Policy</link> for more information.',
                        goToUpdatesButton: 'Go to {product} Updates',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {Your current Plesk version is unsupported} other {Your current Plesk version {version} is unsupported}} and will be deactivated in 14 days.',
                        fixDescription: 'We recommend that you update your server to the latest available Plesk Obsidian version and enable auto-updates. Read the <link>Plesk Lifecycle Policy</link> for more information.',
                        goToUpdatesButton: 'Go to {product} Updates',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'Since we couldn\'t reach the server for 14 days, we marked it as inactive.',
                        lastCheckMessage: 'Last time we checked connection status at {lastCheckConnection}',
                        fixDescription: 'To start using it again, please click "Recheck connection"',
                        recheckConnectionButton: 'Recheck the connection',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'The server is marked as inactive because it is running {version, select, _ {an unsupported Plesk version} other {an unsupported Plesk version {version}}}.',
                        fixDescription: 'To make the server active, update Plesk and perform server sync.',
                        goToUpdatesButton: 'Go to {product} Updates',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Add Server',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Add new server',
                closeButton: 'Close',
                serverInstallTitle: 'Install {productType}',
                installNewProduct: 'Install a new product on a Linux server',
                connectInstallation: 'Connect a server with an installed product',
                serverInstallDescription: 'Install {productType} on a physical or virtual server and add it to the Server Inventory.',
                connectPleskTitle: 'Connect Plesk',
                connectPleskDescription: 'Add a server with installed Plesk to the Server Inventory.',
                connectCPanelTitle: 'Connect cPanel',
                connectCPanelDescription: 'Add a server with installed cPanel to the Server Inventory.',
            },
            CustomInstallationStep: {
                title: 'Install {productType} Automatically on a Linux Server',
                backButton: 'Back',
                submitButton: 'Install',
                successMessage: '{productType} is being installed on <b>{host}</b>.',
            },
            AddServerWithRedirectStep: {
                title: 'Connect a Plesk server',
                description: 'After clicking "Connect", you will be redirected to Plesk. You will need to log in there with the administrator permissions.',
                proceed: 'Connect',
                proceedAnyway: 'Connect anyway',
                nextStep: 'Next step',
                back: 'Back',
                addressLabel: 'Server hostname',
                addressDescription: 'The server URL and port to connect to. For example, "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'Connect a cPanel server',
                close: 'Close',
                back: 'Back',
                paragraph1: 'Download and run the cPanel plugin installer: copy and paste the following snippet into the server console using the root privileges.',
                paragraph2: 'You will need a <a>Plesk 360 API token</a>. You can generate a new one or use an existing token.',
                paragraph3: 'The newly created server will appear in the Server Inventory.',
                copied: 'Copied.',
            },
        },
    },
};
