// Copyright 2024. WebPros International GmbH. All rights reserved.
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';

import { MenuItem } from '@plesk/ui-library';
import { Link } from 'react-router-dom';

export const QuickStartAddon = ({ onClick }: { onClick?: () => void }) => {
    const translate = useTranslate('security-dashboard.Layout.UserMenuAddon');

    const getModalUrl = useGetModalUrl();

    return (
        <MenuItem component={Link} to={getModalUrl('quick-start')} onClick={onClick}>
            {translate('quickStart')}
        </MenuItem>
    );
};
