// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Erreur inconnue : {message}.',
        newServer: 'Un serveur avec une adresse identique existe déjà.',
        domainName: 'Impossible de résoudre le nom de domaine.',
        serverMisconfigured: 'Le serveur n\'est pas configuré correctement et ne peut pas être ajouté.',
        pleskConfiguration: 'La version de Plesk n\'est pas prise en charge (antérieure à {pleskMinVersion}) ou la configuration de Plesk est incorrecte.',
        unsupportedServerVersion: 'La version de Plesk installée est obsolète et ne peut pas être ajoutée. {kbLink}',
        connection: 'La connexion réseau a été interrompue.',
        connectionRefused: 'La connexion au réseau a été refusée. Veuillez vérifier le domaine et le port indiqués.',
        timeout: 'La connexion réseau au serveur a dépassé le délai.',
        apiKey: 'La connexion n\'est pas autorisée par le serveur distant. Veuillez ajouter à nouveau le serveur à l\'Inventaire des serveurs.',
        httpsRequired: 'Le serveur ne prend pas en charge HTTPS.',
        certHasExpired: 'Le certificat a expiré. {kbLink}',
        errTlsCertAltnameInvalid: 'Le nom d\'hôte ou l\'adresse IP du serveur ne correspondent pas aux noms alternatifs sur le certificat. {kbLink}',
        depthZeroSelfSignedCert: 'Un certificat autosigné est installé. {kbLink}',
        selfSignedCertInChain: 'Il y a un certificat autosigné dans la chaîne de certificats. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'Les paramètres de notifications ont été mis à jour.',
        },
    },
    Layout: {
        title: 'Inventaire des serveurs',
        serversTitle: 'Serveurs',
        clientsTitle: 'Clients',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Paramètres des notifications',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Notifications',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Notifications à envoyer',
        notifyServerUnreachable: 'Serveur inaccessible',
        notifyServerUnsupported: 'Serveur non pris en charge',
    },
    AllDomains: {
        title: 'Sites Web',
        name: 'Nom complet',
        hostingType: 'Type d\'hébergement',
        virtualHostingType: 'Site Web',
        forwardingHostingType: 'Redirection',
        noneHostingType: 'Aucun hébergement',
        mainDomainType: 'Domaine principal',
        subDomainType: 'Sous-domaine',
        addonDomainType: 'Domaine supplémentaire',
        parkedDomainType: 'Alias',
        hostingServer: 'Serveur d\'hébergement',
        manage: 'Gérer',
        manageLinkUnavailableHint: 'Vous devez disposer de Plesk RESTful API 1.4.0 ou d\'une version ultérieure pour générer un lien direct vers le panneau Plesk. Pour en savoir plus, consultez la documentation.',
        open: 'Ouvrir',
    },
    AllClients: {
        name: 'Nom du contact',
        email: 'Adresse mail',
        login: 'Nom d\'utilisateur',
        type: 'Type',
        status: 'Statut',
        typeAdmin: 'Admin',
        typeReseller: 'Revendeur',
        typeCustomer: 'Client',
        typeCustomerWithOwner: 'Client de {owner}',
        statusActive: 'Actif',
        statusSuspended: 'Suspendu',
        hostingServer: 'Serveur d\'hébergement',
        manage: 'Gérer',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Synchronisation en cours',
            installationRunning: 'Installation en cours…',
            installationFailure: 'Erreur lors de l\'installation',
            installationLicenseRequired: 'Licence requise',
            active: 'Actif, sécurisé',
            inactiveUnreachable: 'Inactif, inaccessible',
            inactiveUnreachableTooltip: 'Le serveur n\'a pas été accessible au cours des 14 derniers jours.',
            inactiveUnsupported: 'Inactif, non pris en charge',
            inactiveUnsupportedTooltip: 'Le serveur exécute une version de Plesk non prise en charge.',
            insecure: 'Actif, non sécurisé',
            outdated: 'Actif, obsolète',
            outdatedTooltip: '{version, select, _ {Votre version actuelle de Plesk est obsolète.} other {Votre version actuelle de Plesk {version} est obsolète.}} Nous vous recommandons de mettre à jour votre serveur vers la dernière version disponible de Plesk Obsidian et d\'activer les mises à jour automatiques.',
            unsupportedVersion: 'Actif, non pris en charge',
            unsupportedVersionTooltip: '{version, select, _ {Votre version actuelle de Plesk n\'est pas prise en charge} other {Votre version actuelle de Plesk {version} n\'est pas prise en charge}} et votre serveur sera désactivé dans 14 jours. Nous vous recommandons de mettre à jour votre serveur vers la dernière version disponible de Plesk Obsidian et d\'activer les mises à jour automatiques.',
            certificateWarning: 'Avertissement lié au certificat',
            certificateError: 'Erreur de certificat',
            connectionBlocked: 'Connexion bloquée',
            connectionError: 'Erreur de connexion',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'Journaux d\'installation non trouvés.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {Votre version actuelle de Plesk est obsolète.} other {Votre version actuelle de Plesk {version} est obsolète.}}',
                        fixDescription: 'Nous vous recommandons de mettre à jour votre serveur vers la dernière version disponible de Plesk Obsidian et d\'activer les mises à jour automatiques. Consultez la <link>Politique régissant le cycle de vie de Plesk</link> pour en savoir plus.',
                        goToUpdatesButton: 'Accéder aux mises à jour {product}',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {Votre version de Plesk actuelle n\'est pas prise en charge} other {Votre version de Plesk {version} actuelle n\'est pas prise en charge}} et sera désactivée dans 14 jours.',
                        fixDescription: 'Nous vous recommandons de mettre à jour votre serveur vers la dernière version disponible de Plesk Obsidian et d\'activer les mises à jour automatiques. Consultez la <link>Politique régissant le cycle de vie de Plesk</link> pour en savoir plus.',
                        goToUpdatesButton: 'Accéder aux mises à jour {product}',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'Nous n\'avons pas pu joindre le serveur depuis 14 jours. Par conséquent, nous l\'avons identifié comme inactif.',
                        lastCheckMessage: 'Date de la dernière vérification du statut de la connexion : {lastCheckConnection}',
                        fixDescription: 'Pour l\'utiliser à nouveau, cliquez sur "Revérifier la connexion".',
                        recheckConnectionButton: 'Revérifier la connexion',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'Ce serveur est indiqué comme inactif, car il exécute {version, select, _ {une version de Plesk non prise en charge} other {la version de Plesk {version} non prise en charge}}.',
                        fixDescription: 'Pour que le statut de ce serveur devienne actif, mettez à jour Plesk et synchronisez le serveur.',
                        goToUpdatesButton: 'Accéder aux mises à jour {product}',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Ajouter un serveur',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Ajouter un serveur',
                closeButton: 'Fermer',
                serverInstallTitle: 'Installer {productType}',
                installNewProduct: 'Installer un nouveau produit sur un serveur Linux',
                connectInstallation: 'Connecter un serveur avec un produit installé',
                serverInstallDescription: 'Installez {productType} sur un serveur physique ou un serveur virtuel et ajoutez-le à l\'inventaire des serveurs.',
                connectPleskTitle: 'Connecter Plesk',
                connectPleskDescription: 'Ajoutez un serveur avec Plesk dans l\'Inventaire des serveurs.',
                connectCPanelTitle: 'Connecter cPanel',
                connectCPanelDescription: 'Ajoutez un serveur avec cPanel dans l\'Inventaire des serveurs.',
            },
            CustomInstallationStep: {
                title: 'Installer automatiquement {productType} sur un serveur Linux',
                backButton: 'Retour',
                submitButton: 'Installer',
                successMessage: '{productType} est en cours d\'installation sur <b>{host}</b>.',
            },
            AddServerWithRedirectStep: {
                title: 'Connecter un serveur Plesk',
                description: 'Lorsque vous aurez cliqué sur "Connecter", vous serez redirigé vers Plesk. Vous devrez vous connecter avec les droits d\'administration.',
                proceed: 'Connecter',
                proceedAnyway: 'Connecter tout de même',
                nextStep: 'Étape suivante',
                back: 'Retour',
                addressLabel: 'Nom d\'hôte du serveur',
                addressDescription: 'L\'URL du serveur et le port auxquels vous vous connectez. Par exemple : "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'Connecter un serveur cPanel',
                close: 'Fermer',
                back: 'Retour',
                paragraph1: 'Téléchargez et exécutez le programme d\'installation du plug-in cPanel : copiez et collez le snippet suivant dans la console du serveur en utilisant les privilèges root.',
                paragraph2: 'Vous aurez besoin d\'un <a>jeton d\'API Plesk 360</a>. Vous pouvez en générer un nouveau ou en utiliser un existant.',
                paragraph3: 'Le serveur nouvellement créé sera répertorié dans l\'Inventaire des serveurs.',
                copied: 'Copié.',
            },
        },
    },
};