// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'O token de autenticação está ausente ou não é válido.',
        invitationNotExist: 'O convite não existe.',
        invitationExpired: 'O convite expirou.',
        invitationConflict: 'O usuário convidado já está registrado.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'Sua senha foi alterada.',
    },
    ChangePasswordForm: {
        resetTitle: 'Nova senha',
        setTitle: 'Definir senha',
        passwordLabel: 'Senha:',
        submitButton: 'Confirmar',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-mail de redefinição de senha enviado',
        successSendResetText: 'Enviamos um e-mail com um link de redefinição de senha para {email}. Clique no link para fazer login no Plesk 360 e definir uma nova senha.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Link expirado',
        resetText: 'O link de redefinição de senha expirou.',
        setText: 'O link para definir a senha não está mais válido. Para sua segurança, o link para definir uma senha é válido por 4 horas. Se você não teve tempo de usar o link para definir sua senha, você pode inserir seu e-mail novamente abaixo e definir a senha por meio do procedimento de alteração de senha.',
        emailLabel: 'E-mail:',
        submitButton: 'Enviar',
        bottomText: 'Você se lembra da senha? Você pode <a>Faça o login agora</a>.',
    },
};