// Copyright 2024. WebPros International GmbH. All rights reserved.

import { StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const LoadingError = () => {
    const translate = useTranslate('monitoring.ServerViewMonitoring');
    return (
        <StatusMessage intent="danger" data-type="loading-error">
            {translate('loadingError')}
        </StatusMessage>
    );
};

export default LoadingError;
