// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ButtonProps, TranslationProps } from '@plesk/ui-library';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';

type ToolbarButtonProps = {
    selectedItems: number[] | string[];
    tooltip?: TranslationProps;
    buttonText?: TranslationProps;
    noSelectionText: TranslationProps;
} & ButtonProps;

export const ToolbarButton = ({
    selectedItems,
    onClick,
    buttonText,
    noSelectionText,
    ...props
}: ToolbarButtonProps) => (
    <NoSelectionPopover
        selectionCount={selectedItems.length}
        target={
            <Button onClick={onClick} {...props}>
                {buttonText}
            </Button>
        }
    >
        {noSelectionText}
    </NoSelectionPopover>
);

export default ToolbarButton;
