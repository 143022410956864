// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: '未解決のアラートはありません',
        openAlerts: '未処理のアラート：{alerts}',
        loadingError: 'サービスを利用できません',
        connectServer: 'サーバを接続',
        notSupported: 'サポート対象外',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: '360 Monitoring で表示',
        connectServerTitle: 'このサーバは 360 Monitoring に接続されていません',
        connectServerDescription: 'サーバが接続されている場合、このページにはサーバのステータスと現在未解決のアラートの簡単な概要が表示されます。',
        connectServerButton: 'サーバを接続',
        pausedServerTitle: 'このサーバはモニタリングされていません',
        pausedServerDescription: '360 Monitoring のユーザインターフェースを使用して理由を確定してください。',
        preparingServerTitle: '現在処理中です',
        preparingServerDescription1: 'Plesk サーバは 360 Monitoring に接続されています。',
        preparingServerDescription2: '現在ダッシュボードを準備中です。ページを更新するか、10 ～ 30 後にもう一度アクセスしてください。',
        refreshButton: '更新',
        loadingError: '問題が発生しました。後でやり直してください。',
        loadAverageTitle: 'ロードアベレージ',
        alertOpened: '{timeAgo} 前に開始。',
        viewAlertsInMonitoring: '360 Monitoring でアラートを表示',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'モニタリング',
            description: '単一のダッシュボードからサーバとウェブサイトをモニタリング',
        },
        DynamicWidget: {
            title: 'モニタリング',
            WidgetContent: {
                servers: 'サーバ',
                websites: 'ウェブサイト',
                lastUpdate: '最終更新：{lastUpdate}',
                loading: 'ロード中...',
                Loader: {
                    loading: 'ロード中...',
                    notRespondingText1: '360 Monitoring が応答していません。',
                    notRespondingText2: '再接続を試みています。',
                },
                InfoAggregateColumn: {
                    allActive: 'すべてアクティブ',
                    noAlerts: 'アラートなし',
                    alerts: '未処理のアラート：{alerts}',
                    down: '{down, plural, other{# 件が応答なし}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: '360 Monitoring をアクティブ化',
        description: '360 Monitoring Lite は無料で最新、いつまでも使えます。アカウントを今すぐアクティブ化すると、すべての有料機能が揃った Pro を 14 日間無料でお試しいただけます。アップグレードして使い続けることを選択した場合を除き、2 週間経過すると無料の Lite バージョンに自動で切り替わります。',
        beginTrialButton: '360 Monitoring をアクティブ化*',
        footnote: '* 360 Monitoring Lite または Pro のトライアルのアクティブ化に、クレジットカード情報は必要ありません。',
        backButton: 'Plesk 360 に戻る',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'キャンセル',
            confirmationText: 'Monitoring メニューアイテムを開くかサポートリクエストを送信して、360 Monitoring アカウントを設定してください。',
            acceptButtonText: 'OK',
            declineButtonText: 'キャンセル',
        },
        retryButtonText: '再試行',
        inProgressText: '360 Monitoring アカウントを構成中です',
        successText: '360 Monitoring にリダイレクトしています',
        failedText: 'Monitoring アカウントのアクティブ化中に問題が発生しました。やり直すか、サポートリクエストを送信してください。',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: '360 Monitoring のアクティブ化を確認',
            description: '「360 Monitoring」エージェントが Plesk コントロールパネル（<b>{address}</b>）にインストールされ、Plesk 360 の <b>{email}</b> アカウントに接続されます。',
        },
        monitoringInactive: {
            title: '360 Monitoring をアクティブ化してサーバを追加',
            description: '<tooltip>試用期間</tooltip>付きで 360 Monitoring の使用を開始して、Plesk 360 の <b>{email}</b> アカウントに接続された <b>{address}</b> で Plesk コントロールパネルへの「360 Monitoring」エージェントのインストールを確認してください。',
            trialTooltip: '14 日間のトライアル期間中は、14 日間にわたってすべての有料機能を完全に使用できます。トライアル期間が終了すると、360 Monitoring は無料の「Lite」バージョンに変わり、基本機能をお使いいただけます。いつでもライセンスを購入することで「Pro」バージョンをアクティブ化できます。',
        },
        buttons: {
            confirm: '確認',
            deny: '禁止',
            cancel: 'キャンセル',
        },
        errors: {
            apiKey: 'Plesk 360 へのサーバの接続が破損しているようです。サーバを Plesk 360 に再接続して、モニタリングをアクティブ化してください。',
            default: '360 Monitoring のインストールに失敗しました。後でやり直してください',
        },
    },
};