// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { sendVerification } from '@platform360/auth/web/api/sign-up';

const useSendVerification = (onSuccess?: () => void) =>
    useMutation({
        mutationFn: sendVerification,
        onSuccess,
    });

export default useSendVerification;
