// Copyright 2024. WebPros International GmbH. All rights reserved.

export class LocationWrapper {
    public assign(url: string | URL): void {
        window.location.assign(url);
    }
}

export const location = new LocationWrapper();

if (Object.prototype.hasOwnProperty.call(window, 'Cypress')) {
    Object.defineProperty(window, 'locationWrapper', {
        value: location,
        writable: true,
        enumerable: true,
        configurable: true,
    });
}
