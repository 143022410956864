// Copyright 2024. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import Tabs from '@platform360/libs/shared-web/components/Page/Tabs';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useLocalStorage } from 'usehooks-ts';
import { useLicenseIsActive } from '@platform360/security-dashboard/web/hooks/useLicenseIsActive';
import { useInstallationsSummaryQuery } from '@platform360/security-dashboard/web/queries';
import { ServersSummaryResponse } from '@platform360/security-dashboard/web/api/servers';
import CardList from './CardList';
import { CardListItem } from './CardList/CardList';
import InstallationsCard from './InstallationsCard';
import QuickStartCard from './QuickStartCard';
import VulnerabilitiesCard from './VulnerabilitiesCard/VulnerabilitiesCard';
import UpdateSettingsCard from './UpdateSettingsCard';

import styles from './Header.module.less';

type HeaderProps = {
    isEmpty: boolean;
    serversSummaryData?: ServersSummaryResponse;
};

const Header = ({ isEmpty, serversSummaryData }: HeaderProps) => {
    const translate = useTranslate('security-dashboard.Layout');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const licenseIsActive = useLicenseIsActive();
    const [isQuickStartHidden, setQuickStartHidden] = useLocalStorage(
        'security-dashboard-quick-start',
        false,
    );

    const { data: installationsSummary, isLoading: isLoadingInstallationsSummary } =
        useInstallationsSummaryQuery();

    const isSettingsCardHidden = !licenseIsActive;
    const isInstallationsHidden = installationsSummary?.total === 0;

    const isNoCards = isSettingsCardHidden && isQuickStartHidden && isInstallationsHidden;

    const dependedOnInstallationsCardClassName = classNames(styles.card, {
        [styles.cardHidden ?? '']: isInstallationsHidden,
    });

    return (
        <div
            className={classNames(styles.header, {
                [styles.hidden ?? '']: isEmpty,
                [styles.noCards ?? '']: isNoCards,
                [styles.noDisplay ?? '']: !serversSummaryData,
            })}
        >
            <div className={styles.content}>
                {!isNoCards && (
                    <CardList>
                        {!isQuickStartHidden && (
                            <QuickStartCard onHide={() => setQuickStartHidden(true)} />
                        )}

                        <CardListItem className={dependedOnInstallationsCardClassName}>
                            <InstallationsCard
                                data={installationsSummary}
                                isLoading={isLoadingInstallationsSummary}
                            />
                        </CardListItem>

                        <CardListItem className={dependedOnInstallationsCardClassName}>
                            <VulnerabilitiesCard />
                        </CardListItem>

                        {!isSettingsCardHidden && (
                            <CardListItem>
                                <UpdateSettingsCard />
                            </CardListItem>
                        )}
                    </CardList>
                )}
                <Tabs
                    className={styles.tabs}
                    tabs={[
                        {
                            to: '/security-dashboard/servers',
                            title: translate('tabs.servers'),
                            onActivate: () => analyticsEvents.wpTabServersClick(),
                        },
                        {
                            to: '/security-dashboard/installations',
                            title: translate('tabs.installations'),
                            onActivate: () => analyticsEvents.wpTabWpsitesClick(),
                        },
                        {
                            to: '/security-dashboard/vulnerabilities',
                            title: translate('tabs.vulnerabilities'),
                            onActivate: () => analyticsEvents.wpTabVulnerabilitiesClick(),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default Header;
