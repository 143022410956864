// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Navigate,
    // eslint-disable-next-line no-restricted-imports
    Routes as ReactRoutes,
    Route,
} from 'react-router-dom';
import Members from '@platform360/accounts/web/components/Team/Members';
import Invitations from '@platform360/accounts/web/components/Team/Invitations/InvitationsContainer';
import Layout from '@platform360/accounts/web/components/Team/Layout';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';

const Routes = () => {
    const { isOwner } = useCurrentUser();

    if (!isOwner) {
        return <Navigate to="/" />;
    }

    return (
        <ReactRoutes>
            <Route element={<PrivateRoutes />}>
                <Route element={<Layout />}>
                    <Route path="/" element={<Navigate to="members" />} />
                    <Route path="members/*" element={<Members />} />
                    <Route path="invitations/*" element={<Invitations />} />
                    <Route path="*" element={<Navigate to="/not-found" replace />} />
                </Route>
            </Route>
        </ReactRoutes>
    );
};

export default Routes;
