// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import withRouteBinding, {
    ClosableProps,
} from '@platform360/libs/shared-web/helpers/withRouteBinding';
import ResetPassword from './ResetPassword';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useAuthAnalyticsEvents } from '@platform360/auth/web/helpers/analytics';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import withGuest from '@platform360/auth/web/components/withGuest';
import { useOperationStatus } from '@platform360/auth/web/components/useOperationStatus';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { Tab, Tabs } from '@plesk/ui-library';
import { AuthOutletContext } from '@platform360/auth/web/types';

const RoutableResetPassword = withRouteBinding<{ email?: string } & ClosableProps>(ResetPassword);

export const AuthContainer = () => {
    const location = useLocation();
    const analyticsEvents = useAuthAnalyticsEvents();
    const translate = useTranslate('Auth.AuthContainer');
    const [email, setEmail] = useState<string>();
    const { statusMessage, setOperationStatus } = useOperationStatus();
    const { authLayout: AuthLayout, authSidebar: Sidebar, footer, logo } = useApplicationSettings();

    const tabs = [
        {
            to: 'login',
            title: translate('tabs.login'),
        },
        {
            to: 'signup',
            title: translate('tabs.signUp'),
        },
    ];

    useEffectOnce(() => {
        if (location.search.search('add-external') > -1) {
            analyticsEvents.userComesFromPlesk();
        }
    });

    const sidebar = (
        <Sidebar logo={logo}>
            {statusMessage}
            <Tabs active={tabs.findIndex(({ to }) => location.pathname.includes(to)) + 1}>
                {tabs.map(({ to, title }) => (
                    <Tab
                        key={to}
                        component={Link}
                        // Here we add same search and hash to all the tabs.
                        // It is required only for AuthContainer component.
                        // Perhaps we should find a better way.
                        to={`${to}${location.search}${location.hash}`}
                        title={title}
                    />
                ))}
            </Tabs>
            <Outlet context={{ setEmail, setOperationStatus } satisfies AuthOutletContext} />
        </Sidebar>
    );

    return (
        <AuthLayout sidebar={sidebar} footer={footer}>
            <RoutableResetPassword
                openPath="login/reset-password"
                closePath="/auth/login"
                email={email}
            />
        </AuthLayout>
    );
};

export default withGuest(AuthContainer);
