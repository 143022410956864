// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Section, SectionItem, Switch } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { NotificationsSettings } from '@platform360/security-dashboard/web/types';
import { useUpdateNotificationsSettingsMutation } from '@platform360/security-dashboard/web/mutations';

type NotificationSettingsEventSectionProps = {
    settings: NotificationsSettings;
};

export const NotificationSettingsEventSection = ({
    settings,
}: NotificationSettingsEventSectionProps) => {
    const translate = useTranslate('security-dashboard.NotificationSettingsEventSection');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const {
        mutate: saveNewExploitedVulnerabilities,
        isPending: handlingCheckNewExploitedVulnerabilities,
    } = useUpdateNotificationsSettingsMutation();

    const { mutate: saveLicenseNotifications, isPending: handlingLicenseNotifications } =
        useUpdateNotificationsSettingsMutation();

    const handleCheckNewExploitedVulnerabilities = (checked: boolean): void => {
        saveNewExploitedVulnerabilities({ newExploitedVulnerabilities: checked });
        analyticsEvents.wpNotificationSettingsEventSectionItemToggle(
            checked,
            'exploitedVulnerabilities',
        );
    };

    const handleCheckLicenseNotifications = (checked: boolean): void => {
        saveLicenseNotifications({ licenseNotifications: checked });
        analyticsEvents.wpNotificationSettingsEventSectionItemToggle(checked, 'license');
    };

    return (
        <Section
            title={
                <Heading icon="bell" level={5}>
                    {translate('title')}
                </Heading>
            }
        >
            <SectionItem title={translate('newExploitedVulnerabilities')}>
                <Switch
                    checked={settings.newExploitedVulnerabilities}
                    onChange={handleCheckNewExploitedVulnerabilities}
                    loading={handlingCheckNewExploitedVulnerabilities}
                    data-type="new-exploited-vulnerabilities-switch"
                />
            </SectionItem>
            <SectionItem title={translate('licenseNotifications')}>
                <Switch
                    checked={settings.licenseNotifications}
                    onChange={handleCheckLicenseNotifications}
                    loading={handlingLicenseNotifications}
                    data-type="license-notifications-switch"
                />
            </SectionItem>
        </Section>
    );
};
