// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import consents from '@platform360/libs/common/consents';
import { Link as RouterLink } from 'react-router-dom';

export type ConsentItem = {
    type: string;
    title: TranslationProps;
    text: TranslationProps;
    renderText: () => TranslationProps;
};

const useConsentItems = (): ConsentItem[] => {
    const translate = useTranslate('consents');

    return consents.map(({ type, titleLocale, textLocale }) => {
        switch (type) {
            case 'send_platform360_announce': {
                return {
                    type,
                    title: translate(titleLocale),
                    text: translate(textLocale, {
                        a: (chunk) => chunk,
                    }),
                    renderText: () =>
                        translate(textLocale, {
                            a: (chunk) => (
                                <RouterLink to="/legal/privacy-policy" target="_blank">
                                    {chunk}
                                </RouterLink>
                            ),
                        }),
                };
            }
            default: {
                return {
                    type,
                    title: translate(titleLocale),
                    text: translate(textLocale),
                    renderText: () => translate(textLocale),
                };
            }
        }
    });
};

export default useConsentItems;
