// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Input, TranslationProps } from '@plesk/ui-library';
import { useClipboard } from '@platform360/libs/shared-web/helpers/useClipboard';

export type InputCopyProps = {
    value: string;
    successMessage: TranslationProps;
    className?: string;
    onCopy?: (value: string) => void;
};
const InputCopy = ({ value, successMessage, className, onCopy }: InputCopyProps) => {
    const { copyText } = useClipboard();
    const handleCopy = (value: string) => () => {
        void copyText(value, { successMessage });
        onCopy?.(value);
    };

    return (
        <Input
            value={value}
            className={className}
            suffix={
                <Button icon="copy" data-type="copy-button" onClick={handleCopy(value)} ghost />
            }
            size="fill"
            readOnly
        />
    );
};

export default InputCopy;
