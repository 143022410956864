// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import {
    InstallationTokenResponse,
    resetInstallationTokenDuration,
} from '@platform360/security-dashboard/web/api/installation';

type MutationOptions = {
    onSuccess?: (data: InstallationTokenResponse) => void;
};

const useResetInstallationTokenDurationMutation = ({ onSuccess }: MutationOptions = {}) =>
    useMutation({
        mutationFn: resetInstallationTokenDuration,
        onSuccess,
    });

export default useResetInstallationTokenDurationMutation;
