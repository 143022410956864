// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copié dans le presse-papier.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Vous empruntez l\'identité d\'un autre compte.',
            depersonateButton: 'Arrêter l\'emprunt d\'identité',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Vous n\'empruntez plus l\'identité d\'un autre compte.',
        },
        MainMenu: {
            betaVersion: 'Version bêta',
            servers: 'Serveurs',
            websites: 'Sites Web',
            licenses: 'Licences',
            monitoring: 'Monitoring',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Monitoring',
            feedback: 'Votre feedback',
            services: 'Services 360',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Rechercher...',
            },
            ClientResultGroup: {
                clients: 'Clients',
                clientsShowMore: '{showMore, plural, one{# client supplémentaire...} other{# clients supplémentaires...}}',
            },
            DomainResultGroup: {
                domains: 'Sites Web',
                domainsShowMore: '{showMore, plural, one{# site web de plus…} other{# sites web de plus…}}',
            },
            ServersResultGroup: {
                servers: 'Serveurs',
                serversShowMore: '{showMore, plural, one{# serveur de plus…} other{# serveurs de plus…}}',
            },
            search: 'Rechercher...',
            noResults: 'Aucun résultat...',
        },
        UserMenu: {
            team: 'Organisation',
            logout: 'Se déconnecter',
            changePassword: 'Changer le mot de passe',
            viewProfile: 'Profil personnel',
            businessProfile: 'Profil entreprise',
            apiTokens: 'Jetons API',
        },
    },
    limit: 'Limite :',
    upgradePropertiesButton: 'Ajuster',

    common: {
        errors: {
            isNotEmpty: 'Ce champ obligatoire est vide.',
            required: 'Ce champ obligatoire est vide.',
            url: 'La valeur ne correspond pas à une URL valide.',
            isUrl: 'La valeur ne correspond pas à une URL valide.',
            email: 'La valeur doit correspondre à une adresse e-mail valide.',
            isEmail: 'La valeur doit correspondre à une adresse e-mail valide.',
            json: 'La valeur doit être conforme au format JSON.',
            min: '{min, plural, one{La valeur doit comporter au moins # caractère.} other{La valeur doit comporter au moins # caractères.}}',
            maxLength: '{max, plural, one{La valeur doit comporter au plus # caractère.} other{La valeur doit comporter au plus # caractères.}}',
            isHttps: 'Le nom d\'hôte du serveur doit commencer par https://.',
            unique: 'La valeur doit être unique.',
            isPublicIp: 'Le nom d\'hôte du serveur doit résoudre vers une adresse IP publique.',
            unknown: 'Erreur inconnue : {message}',
            externalRedirect: 'Un problème est survenu.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Vous devez saisir un mot de passe.',
            errorPasswordIsWrong: 'Mot de passe incorrect.',
            errorPasswordIsTooWeak: 'Le mot de passe est trop faible.',
            errorPasswordContainsPersonalInfo: 'Le mot de passe ne doit contenir aucune information personnelle.',
            errorPasswordTooCommon: 'Ce mot de passe est trop simple.',
            errorPasswordPreviouslyUsed: 'Ce mot de passe a déjà été utilisé par le passé.',
            errorInvalidOtp: 'Le code saisi n\'est pas valide.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Confirmer l\'abonnement',
            payment: 'Paiement',
        },
        general: {
            buyNow: 'Acheter',
            confirm: 'Confirmer',
            confirmAndFeedback: 'Confirmer et envoyer le feedback',
            cancel: 'Annuler',
        },
        tabTitle: 'Toutes les licences',
        LinkedEmails: {
            tabTitle: 'E-mails associés',
            emailColumn: 'E-mail',
            statusColumn: 'Statut',
            totalRows: '{totalRows, plural, one{# e-mail au total} other{# e-mails au total}}',
            verifiedStatus: 'Vérifiée',
            addButton: 'Associer plus d\'e-mails',
            deleteButton: 'Dissocier l\'e-mail',
            AddEmails: {
                stepOne: {
                    title: 'Lier des e-mails avec des licences associées',
                    emailsLabel: 'Adresse e-mail utilisée pour acheter des licences',
                    submitButton: 'Envoyer la vérification',
                },
                stepTwo: {
                    title: 'Les messages de vérification ont été envoyés.',
                    description: 'Consultez les boîtes mail et suivez les liens de vérification envoyés par mail. Vous devrez probablement vous reconnecter à ce compte pour terminer l\'ajout des adresses e-mail associées. Ces liens expireront dans une heure.',
                    emailColumn: 'E-mail',
                    submitButton: 'Effectué',
                    resendButton: 'Renvoyer',
                },
            },
            Onboarding: {
                title: 'Associez votre adresse mail à ce compte pour voir plus de licences.',
                description: 'Pour commencer à gérer plus de licences, saisissez les adresses e-mail utilisées lors de l\'achat.',
                docsLink: 'Plus d\'infos',
                addEmailsButton: 'Ajouter des adresses e-mail',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Acheter Plesk',
                    monitoring: 'Acheter 360 Monitoring',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Sur cette page, vous pouvez voir et gérer vos licences.',
                    description1: 'Cliquez sur "Acheter une nouvelle licence" si vous n\'avez pas encore de licence.',
                    description2: 'Si vous avez acheté des licences avec d\'autres adresses e-mail, cliquez sur "Associer des licences existantes" pour les ajouter.',
                    docsLink: 'Plus d\'infos',
                    buyButton: 'Acheter une nouvelle licence',
                    addEmailsButton: 'Associer des licences existantes',
                },
            },
        },
        CB: {
            paymentInfo: 'Cette commande sera traitée via Plesk Online Store géré par {companyName} à l\'aide du mode de paiement associé à l\'abonnement sélectionné',
            confirmSubscription: 'En soumettant cette commande, vous acceptez de vous abonner à un produit avec un abonnement qui sera facturé à intervalles réguliers. L\'utilisation continue du produit acheté dépend de votre paiement de l\'abonnement. Les détails relatifs au prix et à la fréquence de la facturation sont affichés dans le panier d\'achat ci-dessus.',
            buyInfo: 'En envoyant cette commande, vous acceptez les <termsLink>Conditions générales</termsLink> de Cleverbridge et confirmez que vous avez lu et compris la <policyLink>Politique de confidentialité</policyLink> de Cleverbridge et vos <revocationLink>Droits rétractation</revocationLink> en tant que client.',
            links: {
                terms: 'Conditions générales',
                contact: 'Contact',
                revocation: 'Droit de révocation',
                security: 'Sécurité',
                legal: 'Informations légales',
                policy: 'Politique de confidentialité',
            },
            footer: 'Ce processus de commande est géré par notre revendeur en ligne {companyName}. Le processus de paiement et l\'exécution des commandes est effectué par {companyName}, {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Cologne, Allemagne',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654, États-Unis',
            },
        },
        Renew: {
            drawer: {
                title: 'Réactiver l\'abonnement',
            },
            form: {
                submitButton: 'Confirmer le renouvellement',
                confirmText: 'En cliquant sur le bouton ci-dessous, vous confirmez le renouvellement de votre abonnement à "{product}" de WebPros International GmbH',
            },
            buttonTitle: 'Réactiver l\'abonnement',
            successMessage: 'L\'abonnement à {product} a été renouvelé. Sa clé de licence sera activée prochainement',
            unavailable: 'Vous ne pouvez pas renouveler cet abonnement (par exemple, s\'il a été interrompu). Veuillez acheter un nouveau produit.',
        },
        Adjust: {
            delayedChangesProducts: 'Jusqu\'à la fin de la période de facturation ({date}), la ou les limites suivantes resteront identiques',
        },
        Cancel: {
            buttonTitle: 'Se désabonner',
            successMessage: 'L\'abonnement à {product} a été annulé. Vous pouvez poursuivre l\'utilisation jusqu\'au {date}.',
            popover: {
                title: 'Se désabonner de {product}',
                message: 'Vous ne serez plus facturé pour ce produit. Vous pouvez poursuivre l\'utilisation jusqu\'au {date} (la période de facturation en cours ne sera pas remboursée).',
            },
        },
        Payment: {
            card: {
                expired: 'Votre carte a expiré.',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'Vous pouvez modifier le mode de paiement dans les paramètres de votre abonnement.',
                    confirm: 'J\'ai compris.',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Annuler',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Platform 360 utilise ZendeskChat, une application tierce.',
            description2: 'En poursuivant, j\'accepte d\'utiliser ZendeskChat, une application tierce. Par conséquent, mes données personnelles (par exemple : l\'adresse IP) peuvent être transmises à des tiers en Europe ou hors d\'Europe. Pour en savoir plus, consultez notre <a>Politique de confidentialité</a>.',
            confirmButton: 'Lancer ZendeskChat',
        },
        supportFormPopover: {
            description: 'Aucun agent n\'est disponible pour le moment. Veuillez patienter ou utiliser le formulaire de ticket afin d\'envoyer votre demande.',
            confirmButton: 'Ouvrir le formulaire',
        },
        ChatButton: {
            title: 'Assistance',
        },
    },

    keysPageTitle: 'Licences',
    goBack: 'Revenir à l\'écran d\'accueil',
    keysPageDescription: 'Gérez les licences achetées via la boutique en ligne de Plesk.',
    keysToolbarBuyTitle: 'Acheter',
    keysToolbarFilterTitle: 'Filtre',

    keyAdditionalActionsToolbarTitle: 'Actions supplémentaires',
    keyMoreDetails: 'Plus d\'infos',

    keyForeignCart: 'Cette licence ne vous appartient pas.',
    keyForeignDescriptionTitle: 'Vous ne pouvez pas gérer la licence, car elle appartient à un autre compte. Cela peut se produire pour les raisons suivantes :',
    keyForeignDescriptionHosting: 'Votre licence Plesk appartient à votre fournisseur d\'hébergement.',
    keyForeignDescriptionWrongEmail: 'Vous avez acheté la licence à l\'aide d\'un autre compte. Pour voir et gérer la licence, connectez-vous à l\'aide de l\'adresse e-mail correspondant à ce compte.',

    keyTrialDescription: 'Licence de test. Commander {link} ?',
    keyTrialDescriptionLink: 'licence payante',

    keyTerminatedCart: 'Cette licence a été résiliée.',
    keyTerminatedDescription: 'Cette licence n\'est plus valide.',
    keyTerminatedDescriptionRetail: 'Il n\'y aura plus de facturation pour cette licence.',

    keyPartnerCart: 'Cette licence n\'a pas été achetée via la boutique en ligne de Plesk.',
    keyPartnerDescription: 'Aucune info de facturation n\'est disponible pour cette licence.',

    keyLicenseDetailsTitle: 'Détails de la licence',
    keyLicenseDetailsCreationDate: 'Date de création :',
    keyLicenseDetailsActivationCode: 'Code d\'activation :',
    keyBillingDetailsTitle: 'Détails de facturation',
    keyBillingDetailsTrial: 'Ceci est une licence de test qui expire le {date}. <a>Achetez une licence</a>.',
    keyBillingDetailsNotRetail: 'Il n\'y a pas d\'informations de facturation pour cette licence, car elle n\'a pas été achetée via la boutique en ligne de Plesk.',
    keyBillingDetailsTerminated: 'Votre abonnement a été annulé.',
    keyBillingDetailsSubscriptionId: 'ID d\'abonnement :',
    keyBillingDetailsCycle: 'Cycle de facturation :',
    keyBillingDetailsForeign: 'Il n\'y a pas d\'informations de facturation pour cette licence, car elle appartient à un autre compte.',
    keyServerDetailsTitle: 'Infos serveur',
    keyServerDetailsAddresses: 'Adresses IP serveur :',
    keyServerDetailsOs: 'OS serveur :',
    keyServerDetailsProductVersion: 'Version du produit :',
    keyServerDetailsAbsent: 'Il semble que cette licence n\'a pas déjà été installée sur un serveur Plesk.',

    pleskUpgradedMessage: 'Vous avez effectué une mise à niveau de la licence {from} ({id}) à {to}.',
    propertyUpgradedIncreaseMessage: 'Les capacités de la licence {keyName} ont été augmentées jusqu\'à {to} {name}.',
    propertyUpgradedDecreaseMessage: 'Les capacités de la licence {keyName} ont été réduites à {to} {name}. Les modifications entreront en vigueur lors de la nouvelle période de facturation.',
    keyUpgradedMessage: 'Vous avez effectué une mise à niveau de la licence {from} ({id}) à {to}. Votre licence {to} ({id}) est affectée à la licence Plesk {parentId}.',
    keyAddedMessage: 'Vous avez acheté la licence {addon}. Elle est affectée à la licence {parent} : {parentId}.',
    refreshSuggestion: 'Si vous ne voyez pas ces modifications, <a>actualisez la page</a>.',

    upsellPageTitle: 'Ajouter une extension à votre licence',
    yearlyPrices: 'Tarifs annuels',
    monthlyPrices: 'Tarifs mensuels',
    subscriptionForBillingPeriodNotFound: 'Les périodes de facturation pour la licence de l\'extension et celle de l\'abonnement ne correspondent pas ou aucune licence Plesk n\'existe pour cette période de facturation. Choisissez la période de facturation correspondante ou achetez une licence Plesk dans la Boutique en ligne.',
    subscriptionToUpgradeHeader: 'Abonnement à mettre à niveau',
    selectedSubscription: '{subscriptionId} (devise : {billingDate} ; cycle de facturation : {billingCycle})',
    toOnlineStoreButton: 'Atteindre la Boutique en ligne',
    why: 'Pourquoi ?',
    whyCharge: 'Le montant facturé a été calculé au prorata selon le nombre de jours restants dans votre période de facturation actuelle.',
    chargeNow: 'Vous serez désormais facturé <b>{grossPrice}</b> (TVA incluse {vatPrice}).',
    chargeNowShort: 'Vous serez facturé maintenant.',
    inclVatShort: 'TVA incluse {vatPrice}',
    delayedCharge: 'La limite reste : {limit} {property}, jusqu\'à la fin de la période de facturation.',
    nextCharge: '<b>{grossPrice}</b> ({vatPrice} TVA incluse), à compter du {nextBillingDate}',
    nextChargeShort: 'À compter du {nextBillingDate}, vous serez facturé',
    noProductForBillingPeriod: 'Aucun produit n\'est sélectionné pour cette période de facturation.',
    confirmationPopupTitle: 'Mettre à niveau votre abonnement actuel',

    upgradePageTitle: 'Mettre à niveau la licence',
    upgradePageDescription: 'Ici, vous pouvez mettre à niveau votre licence.',
    upgradePanelBeforeTitle: 'Avant mise à niveau',
    upgradePanelOptionsTitle: 'Options de mise à niveau',
    upgradePanelAfterTitle: 'Après mise à niveau',
    upgradePanelOptionsRecommendationsTitle: 'Consultez nos recommandations :',
    upgradePanelOptionsRecommendationsAlt: 'Ou {link} ci-dessous.',
    upgradePanelOptionsRecommendationsAltLink: 'parcourez toutes les options disponibles',
    upgradeSuggestion: 'Ajouter des produits à votre licence',
    upgradeSuggestionFreeCost: 'Essayez gratuitement !',
    upgradeSuggestionDescription: '(voir le détail des produits pour plus d\'infos)',
    upgradeMultiOfferSuggestion: 'Choisir un autre pack',
    upgradeConfirm: 'Acheter',
    upgradeAccept: 'Confirmer',
    upgradeBack: 'Revenir à la liste de produits',
    planUpdate: 'Mise à jour du pack',
    adjustPending: 'L\'ajustement de cette licence est déjà en attente. Veuillez réessayer dans 30 secondes.',
    waitAMoment: 'Veuillez patienter',
    upgradeConfirmPriceRecalculation: 'Vous serez maintenant facturé {chargeInfo} (prix total calculé au prorata du nombre de jours restant jusqu\'à votre prochaine date de facturation, {nextBillingDate}). À partir du {nextBillingDate}, vous serez facturé {renewalInfo} pour ce produit.',
    buyNowNotification: 'Cliquez sur "Acheter" pour poursuivre l\'achat ou la mise à niveau de la licence. Vous serez redirigé vers la boutique en ligne Plesk gérée par Cleverbridge AG. La boutique utilisera le mode de paiement précédemment choisi pour l\'achat ou la mise à niveau de votre licence. Tous les paiements seront traités par Cleverbridge AG.',
    paymentDetailsLinkText: 'Vérifiez et modifiez les infos de paiement pour cette transaction.',
    upgradeDoneRedirectToPleskTitle: 'Achat bien effectué',
    upgradeDoneRedirectToPleskDescription: 'L\'installation de la licence peut prendre un certain temps.',
    upgradeDoneRedirectToPleskButton: 'Revenir à Plesk',
    upgradeDoneRedirectToKeys: 'Voir mes licences',
    total: 'TOTAL',
    vat: 'TVA',
    newTotal: 'NOUV. TOTAL',
    newVat: 'NOUV. TVA',

    firstLoginWithoutEmailVerification: 'Merci pour votre inscription au service Plesk 360. Nous avons envoyé un e-mail avec un lien de vérification. Utilisez ce lien pour vérifier votre compte et vous connecter, puis vous pourrez gérer vos licences dans Plesk 360.',
    pleaseVerifyEmailTitle: 'Vérifier votre compte Plesk 360',
    secondLoginWithoutEmailVerification: 'Vous tentez de vous connecter à un compte qui n\'a pas encore été vérifié. Consultez vos e-mails pour voir le message contenant le lien de vérification. Utilisez ce lien pour vérifier votre compte et vous connecter, puis vous pourrez gérer vos licences dans Plesk 360.',
    userBlocked: "Le compte a été suspendu en raison d'une violation du contrat utilisateur. Si vous pensez qu'il s'agit d'une erreur, envoyez un e-mail à cette adresse : {emailLink}.",

    errorDefault: 'Un problème est survenu. Veuillez réessayer ultérieurement.',
    errorDefaultWithReqId: 'Un problème s\'est produit. Veuillez réessayer ultérieurement. ID de la demande : {requestId}.',
    errorTooManyRequests: 'La limite d\'appels API a été atteinte pour cette adresse API. Veuillez réessayer dans une minute.',
    errorTokenRenewal: 'Votre session a expiré. Veuillez vous reconnecter.',
    errorNetworkIssue: 'Apparemment, vous rencontrez en ce moment des difficultés à vous connecter au réseau.',

    errorKAUnavailable: 'Service temporairement indisponible. Veuillez réessayer ultérieurement.',
    errorKAUnavailableWithReqId: 'Le service est temporairement indisponible. Veuillez réessayer ultérieurement. ID de la demande : {requestId}.',

    footerPurposeExtension: {
        text: 'Découvrez d\'autres extensions dans le <a>Catalogue des extensions de Plesk</a>.',
    },

    filterAll: 'Toutes les licences',
    filterActive: 'Actives uniquement',
    renewalCost: 'Coût de renouvellement :',
    nextBillingDate: 'Prochaine date de facturation :',
    canceledSubscriptionDate: 'L\'abonnement a été annulé. Date de fin de validité de votre licence :',
    paymentHistory: 'historique des paiements',
    licenseStatus: 'Statut de la licence :',
    upgradeButton: 'Mettre à niveau',
    upsellButton: 'Ajouter une extension',
    upsellText: 'Une nouvelle extension sera ajoutée à votre version de Plesk.',
    billingPeriod: 'Période de facturation',
    features: 'Autres fonctions :',
    noFeatures: 'Pas d\'autres fonctions',
    toPlesk: 'Accéder à Plesk',
    expiresNote: '(expire le {date})',
    startingFrom: 'à partir du',
    billingIntervalMonth: '{value}/mois',
    billingIntervalYear: '{value}/an',
    billingIntervalOnce: '{value}/fois',
    perOne: 'par unité',

    paymentMethod: 'Mode de paiement',
    editPaymentMethod: 'Modifier le mode de paiement',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'ajouté',
    addresses: 'adresses',
    back: 'Retour',

    monthly: 'Tous les mois',
    on: 'le',
    plesk: 'Plesk',
    product: 'Produit',
    price: 'Prix',
    products: 'Produits',
    purchased: 'Acheté',
    select: 'Sélectionner',
    sinceNote: '(à partir du {date})',
    show: 'Afficher',
    upgrade: 'mise à niveau',
    yearly: 'Tous les ans',

    isEmpty: 'Ce champ est obligatoire.',
    errorPasswordIsEmpty: 'Le mot de passe est obligatoire.',
    errorPasswordIsWrong: 'Mot de passe incorrect.',
    errorPasswordIsTooWeak: 'Le mot de passe est trop faible.',
    errorEmailOrPasswordIsIncorrect: 'Erreur d\'e-mail ou de mot de passe.',
    errorIncorrectEmail: 'Adresse e-mail incorrecte.',
    errorUserDoesntExist: 'L\'utilisateur n\'existe pas.',
    errorUserExist: 'L\'utilisateur existe déjà.',
    errorUserIsNotVerified: 'L\'utilisateur existe mais n\'a pas été vérifié. Nous vous avons adressé un nouveau mail de vérification. Veuillez vérifier votre boîte mail.',
    errorUnknownError: 'Erreur inconnue.',
    errorEmailIsAlreadyLinked: 'Cette adresse e-mail est déjà associée à votre compte.',
    errorLinkIsOutdated: 'Le lien de vérification a expiré.',
    errorBounceOrComplaint: 'Cette adresse e-mail n\'existe pas ou a bloqué notre message comme étant du spam. Veuillez contacter l\'équipe d\'assistance Plesk 360.',
    errorUserEmailAlreadyVerified: 'Cette adresse e-mail a déjà été vérifiée.',
    errorDoResourceIdExist: 'Ce compte Digital Ocean est déjà enregistré.',
    errorCopyToClipboard: 'Une erreur s\'est produite pendant la copie. Essayez de copier manuellement.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH traitera les données que vous avez fournies afin de répondre à votre demande conformément à cette <a>Politique de confidentialité</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'L\'utilisateur n\'existe pas.',
            bounceOrComplaint: 'Cette adresse e-mail n\'existe pas ou a bloqué notre message comme étant du spam. Veuillez contacter l\'équipe d\'assistance Plesk 360.',
            userIsNotVerified: 'L\'utilisateur existe mais n\'a pas été vérifié. Nous vous avons adressé un nouveau mail de vérification. Veuillez vérifier votre boîte mail.',
            userExist: 'L\'utilisateur existe déjà.',
            errorPasswordIsEmpty: 'Le mot de passe est obligatoire.',
            errorPasswordIsWrong: 'Mot de passe incorrect.',
            errorPasswordIsTooWeak: 'Le mot de passe est trop faible.',
        },
        AuthContainer: {
            tabs: {
                login: 'Se connecter',
                signUp: 'S\'inscrire',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Bienvenue dans Plesk 360 ',
                text1: 'Des outils puissants pour surveiller et gérer les serveurs. En toute simplicité.',
                text2: 'Bénéficiez d\'une <b>surveillance de site et de serveur totalement intégrée</b> afin de suivre vos performances et d\'éviter les interruptions.',
                text3: 'Mettez à niveau et profitez de <b>tableaux de bord configurables</b>, d\'alertes personnalisées, de vérifications en une minute, de l\'historique du réseau et bien plus encore.',
                text4: 'Gérez <b>l\'inventaire de vos serveurs</b>, affichez l\'ensemble de vos licences Plesk, achetez ou mettez à niveau les abonnements.',
                text5: 'Le tout depuis une plate-forme sous un compte unique.',
            },
            MonitoringPageIntro: {
                title: 'Bienvenue dans 360 Monitoring',
                text1: 'Des outils puissants pour surveiller et gérer les serveurs. En toute simplicité.',
                text2: '<b>Surveillance de sites et de serveurs</b> totalement intégrée afin de suivre vos performances et d\'éviter les interruptions.',
                text3: '<b>Tableaux de bord configurables</b>, alertes personnalisées, vérifications toutes les minutes, métriques serveurs et E/S, performances du réseau et métriques personnalisées à l\'aide de plug-ins. ',
                text4: 'Multi-cloud et <b>compatible</b> avec toutes les plates-formes.',
            },
        },
        SignUp: {
            signUp: 'S\'inscrire',
            loginLink: 'Aller à l\'écran de connexion',
            emailLabel: 'E-mail',
            emailDescription: 'Nous vous recommandons d\'utiliser l\'adresse e-mail que vous avez utilisée lors de l\'achat de Plesk et des extensions.',
            passwordLabel: 'Mot de passe',
            privacyPolicyAgreementTitle: 'J\'accepte les <a>{termsLink}</a>',
            termsPlatform: 'Conditions d\'utilisation de Plesk 360',
            termsMonitoring: 'Conditions d\'utilisation de 360 Monitoring',
            privacyPolicyAgreementDescription: 'À des fins de création et de maintenance de mon compte dans la plate-forme, WebPros International GmbH et les autres entreprises du groupe WebPros traiteront les informations fournies dans leurs systèmes internes conformément à la <a>Politique de confidentialité</a> pour toute la période pendant laquelle mon compte est actif.',
            verifyAccountTitle: 'Vérifier votre compte',
            verifyAccountText: 'Nous avons envoyé un e-mail avec un lien de vérification à l\'adresse {email}. Cliquez sur ce lien pour vérifier votre compte et vous connecter.',
            resendDescription: 'Vous n\'avez pas reçu l\'e-mail ? Vérifiez votre dossier Spam ou cliquez sur Renvoyer pour en recevoir un nouveau.',
            resendLabel: 'Renvoyer',
            retryCountdown: 'Renvoi possible dans {time}',
            SignUpByInvitation: {
                emailDescription: 'Vous serez inscrit avec l\'adresse e-mail ci-dessus.',
                invitationSucceed: 'Votre inscription est réussie. Veuillez vous connecter maintenant.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Prénom',
                    lastNamePlaceholder: 'Nom de famille',
                    nameLabel: 'Nom complet',
                },
            },
        },
        Login: {
            signUpButton: 'S\'inscrire',
            emailLabel: 'E-mail',
            passwordLabel: 'Mot de passe',
            submitButton: 'Connexion',
            forgotYourPasswordLink: 'Mot de passe oublié ?',
        },
        ResetPassword: {
            title: 'Réinitialiser le mot de passe',
            text: 'Saisissez votre adresse e-mail et nous vous renverrons un lien de réinitialisation du mot de passe.',
            emailLabel: 'E-mail :',
            submitButton: 'Envoyer',
            successTitle: 'E-mail de réinitialisation du mot de passe envoyé',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Renvoyer',
            loginForm: 'Se connecter maintenant',
            retryCountdown: 'Renvoi possible dans {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. Tous droits réservés. Le logo Plesk et Plesk sont des marques déposées de WebPros International GmbH.',
        followPlesk: 'Suivre Plesk :',
        followCPanel: 'Suivre cPanel :',
        company: 'Raison sociale',
        aboutCPanel: 'À propos de cPanel',
        aboutPlesk: 'À propos de Plesk',
        legal: 'Informations légales',
        cookiehub: 'Paramètres des cookies',
        privacyPolicy: 'Politique de confidentialité',
        impressum: 'Mentions légales',
        knowledgeBase: 'Base de connaissances',
        documentation: 'Documentation',
        helpCenterCPanel: 'Help Center cPanel',
        helpCenterPlesk: 'Plesk Help Center',
        contactUs: 'Nous contacter',
        pleskLifecyclePolicy: 'Politique régissant le cycle de vie de Plesk',
        community: 'Communauté',
        blogCPanel: 'Blog cPanel',
        blogPlesk: 'Blog Plesk',
        forumsCPanel: 'Forums cPanel',
        forumsPlesk: 'Forums Plesk',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Champs requis',
        submitButton: 'OK',
        cancelButton: 'Annuler',
    },

    FormField: {
      addMore: 'Ajouter une adresse',
      remove: 'Supprimer',
      clear: 'Effacer',
    },

    FormFieldPassword: {
        improvePassword: 'Pour définir un mot de passe plus sécurisé :',
        passwordTooShort: 'Utilisez un mot de passe plus long.',
        lettersLowerCase: 'Utilisez au moins une lettre minuscule.',
        lettersUpperCase: 'Utilisez au moins une lettre majuscule.',
        numbers1: 'Utilisez au moins un chiffre.',
        numbers3: 'Utilisez au moins trois chiffres.',
        specialChar1: 'Utilisez au moins un caractère spécial (par exemple : !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Utilisez au moins deux caractères spéciaux (par exemple : !, @, #, $, % ,^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Utilisez des lettres majuscules et minuscules.',
        comboLettersAndNumbers: 'Utilisez des chiffres et des lettres.',
        comboLettersNumbersSpecial: 'Utilisez des chiffres, des lettres minuscules et majuscules et des caractères spéciaux.',
        yourPasswordIsStrong: 'Vous pouvez renforcer le niveau de sécurité en utilisant un mot de passe plus long.',
        passwordStrength: 'Niveau de sécurité du mot de passe : %%strength%%.',
        strengthVeryStrong: 'TRÈS FORT',
        strengthStrong: 'FORT',
        strengthVeryWeak: 'TRÈS FAIBLE',
        strengthWeak: 'FAIBLE',
        strengthMedium: 'MOYEN',
        hidePassword: 'Masquer le mot de passe',
        showPassword: 'Montrer le mot de passe',
        generateButton: 'Générer',
        generateButtonHint: 'Générer un mot de passe fort',
    },

    Pagination: {
        totalShort: 'sur %%total%%',
        itemsPerPage: 'Éléments par page',
        all: 'Tout',
        total: 'Page %%current%% sur %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Aucun élément ne correspond aux critères du filtre',
        emptyTitle: 'Apparemment, il n\'y a rien ici...',
        filteredDescription: 'Affinez votre requête ou supprimez certains critères du filtre et essayez à nouveau.',
    },

    Dialog: {
        cancelButton: 'Annuler',
    },
    keyNotUnderOnlineStore: 'Vous ne pouvez pas mettre à niveau la licence dans Plesk 360, car elle n\'a pas été achetée via la boutique en ligne de Plesk. Si vous avez acheté cette licence via votre fournisseur d\'hébergement, contactez-le pour connaître les options de mise à niveau.',
    notOwnLicense: 'Vous ne pouvez pas mettre à niveau la licence dans Plesk 360, car elle appartient à un autre compte. Si vous avez acheté cette licence via votre fournisseur d\'hébergement, contactez-le pour connaître les options de mise à niveau.',
    subscriptionNotFound: 'Impossible de trouver les données de l\'abonnement',
    unableToUpgrade: 'La mise à niveau n\'est pas disponible. Veuillez contacter l\'assistance Plesk',
    unknownError: 'Service temporairement indisponible. Veuillez réessayer ultérieurement.',
    pendingOperationFound: 'Impossible d\'exécuter l\'opération en cours parce que l\'opération précédente (par exemple, le changement du mode de paiement) n\'était pas terminée. Terminer l\'opération précédente afin de pouvoir continuer.',

    LinkEmail: {
        title: 'Lien e-mail',
        tryAgain: 'Réessayer',
    },

    GaOptOutLink: {
        gaSuccess: 'Google Analytics a arrêté le suivi.',
    },

    Servers: {
        sync: 'Synchroniser',
        syncNoSelection: 'Vous n\'avez sélectionné aucun serveur à synchroniser. Voulez-vous tous les synchroniser ?',
        syncAll: 'Tout synchroniser',
        serverNameColumn: 'Nom du serveur',
        productColumn: 'Produit',
        panelColumn: 'Panneau de contrôle d\'hébergement',
        stateColumn: 'État du serveur',
        stateLastSync: 'Dernière synchronisation : {duration}',
        stateLastSyncNow: 'Dernière synchronisation : maintenant',
        login: {
            plesk: 'Accéder à Plesk',
            cpanel: 'Aller dans cPanel',
            wp: 'Ouvrir WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{Le serveur <b>{title}</b> est programmé pour la synchronisation.} one{<b>#</b> serveur est programmé pour la synchronisation.} other{<b>#</b> serveurs sont programmés pour la synchronisation.}}',
        updateServer: 'Mettre à jour {product}',
        cancel: 'Annuler',
        Toolbar: {
            add: 'Ajouter',
            disconnect: 'Déconnecter',
            sync: 'Synchroniser',
            groupSearch: 'Rechercher',
        },
        Onboarding: {
            title: 'Sur cette page, vous pouvez ajouter et gérer vos serveurs Plesk.',
            description: 'Ajoutez vos serveurs pour accéder en un clic au panneau de contrôle.',
            docsLink: 'Plus d\'infos',
            addServersButton: 'Connecter les serveurs',
            feedbackButton: 'Partager votre avis',
            installPleskButton: 'Installer Plesk sur un nouveau serveur',
        },
        FeedbackButton: {
            heading: 'Partager votre avis',
            subHeading: 'Cela nous aidera pour la suite du développement.',
        },
        Tour: {
            next: 'Suivant',
            previous: 'Précédent',
            gotit: 'J\'ai compris.',
            step1: {
                title: 'Présentation de l\'Inventaire des serveurs',
                description: 'L\'Inventaire des serveurs est un outil permettant la gestion de multiples serveurs dans Plesk 360. Ajoutez vos serveurs Plesk de manière sécurisée depuis l\'onglet Serveurs ou depuis Plesk.',
            },
            step2: {
                title: 'Présentation de l\'Inventaire des serveurs',
                description: 'L\'onglet Serveurs contient également des listes de tous les clients et de tous les domaines hébergés sur vos serveurs. Vous pouvez effectuer des recherches parmi ces listes.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Confirmer la connexion de votre serveur à "Plesk 360"',
            description: 'Le panneau de contrôle de {product} disponible à cette adresse <b>{address}</b> sera connecté à votre compte <b>{email}</b> chez Plesk 360. Nous vous invitons à vérifier que vous êtes bien propriétaire de ce compte avant de confirmer !',
            accessTitle: 'Actions autorisées',
            accessMessage1 : '<b>Plesk 360</b> disposera de l\'accès administratif au panneau de contrôle Plesk à l\'adresse : <b>{address}</b>.',
            accessMessage2 : '<b>Plesk 360</b> autorisera le compte <b>{email}</b> à :',
            accessItem1: 'se connecter à Plesk en tant qu\'administrateur ou comme un autre utilisateur sans saisir d\'identifiants ;',
            accessItem2: 'récupérer des listes de clients et de sites web hébergés sur le serveur ;',
            accessItem3: 'activer la surveillance externe du serveur ;',
            accessItem4: 'exécuter certaines autres actions (à l\'avenir).',
            buttons: {
                confirm: 'Confirmer',
                confirmAnyway: 'Confirmer quand même',
                nextStep: 'Étape suivante',
                deny: 'Refuser',
            },
            enableIpRestrictionTitle: 'Détails de la clé API',
            fields: {
                enableIpRestriction: 'Activer la restriction des adresses IP de la clé API, IP autorisées : ',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'Le serveur doit être accessible depuis Internet.',
                certificate: 'Le serveur doit être sécurisé à l\'aide d\'un certificat SSL/TLS valide.',
            },
            success: {
                access: 'L\'installation Plesk est accessible depuis Internet.',
            },
            ServerAccessError: {
                urlIsRequired: 'Veuillez saisir l\'URL du serveur.',
                urlMaxLength: "{max, plural, one{L'URL du serveur doit comporter moins de # caractère.} other{L'URL du serveur doit comporter moins de # caractères.}}",

                urlInvalid: {
                    title: 'Impossible d\'utiliser l\'adresse du serveur fournie.',
                    description: 'Cela se produit en général lorsque l\'adresse contient "http" au lieu de "https" ou lorsqu\'elle comporte une erreur de frappe dans l\'URL : par exemple, un nom de domaine de premier niveau absent (.com, .org, etc).',
                },

                urlPrivateIp: {
                    title: 'Malheureusement, votre serveur n\'est pas joignable, car il n\'est pas disponible.',
                    description: 'L\'hôte de l\'adresse du serveur correspond à une adresse IP qui n\'est pas publique.',
                },

                serverAlreadyAdded: 'Ce serveur a déjà été ajouté. <a>Consulter les détails du serveur.</a>',

                domainNotResolved: {
                    title: 'Malheureusement, votre serveur n\'est pas joignable, car il n\'est pas disponible.',
                    description: 'Plesk 360 ne peut pas résoudre le nom de domaine de l\'adresse du serveur.',
                },

                domainResolvedPrivateIp: {
                    title: 'Malheureusement, votre serveur n\'est pas joignable, car il n\'est pas disponible.',
                    description: 'Vérifiez si une adresse IP publique est assignée à ce domaine.',
                },

                timeout: {
                    title: 'Malheureusement, votre serveur n\'est pas joignable, car il n\'est pas disponible.',
                    description: 'Vérifiez les paramètres de votre pare-feu et autorisez le port spécifié par {publicIps}.',
                },

                connectionAborted: {
                    title: 'Malheureusement, votre serveur n\'est pas joignable, car il n\'est pas disponible.',
                    description: 'Vérifiez les paramètres de votre pare-feu et autorisez le port spécifié par {publicIps}.',
                },

                connectionRefused: {
                    title: 'La connexion au réseau a été refusée.',
                    description: 'Veuillez vérifier le domaine et le port spécifiés.',
                },

                badProtocol: 'L\'adresse fournie doit être protégée par un certificat SSL valide.',

                notAuthenticated: {
                    title: 'La connexion n\'est pas autorisée par le serveur distant.',
                    description: 'Veuillez à nouveau ajouter le serveur à l\'Inventaire des serveurs.',
                },

                notAuthorized: 'Connexion bloquée.',

                unsupportedServerVersion: {
                    title: 'Impossible d\'ajouter le serveur. Veuillez contacter l\'assistance.',
                    description: 'La version de Plesk installée est obsolète et ne peut pas être ajoutée. {minSupportedVersion, select, _ {} other { Seules les versions Plesk Obsidian {minSupportedVersion} et ultérieures sont autorisées.}} <a>Découvrez comment mettre à jour Plesk.</a>',
                },
                serverMisconfigured: {
                    title: 'Impossible d\'ajouter le serveur. Veuillez contacter l\'assistance.',
                    description: 'Le serveur n\'est pas configuré correctement et ne peut pas être ajouté.',
                },
                pleskConfiguration: {
                    title: 'Impossible d\'ajouter ce serveur. Veuillez contacter l\'assistance.',
                    description: 'La version de Plesk n\'est pas prise en charge (antérieure à {pleskMinVersion}) ou la configuration de Plesk est incorrecte.',
                },

                unknownError:  {
                    title: 'Impossible d\'ajouter le serveur. Veuillez contacter l\'assistance.',
                    description: 'Erreur inconnue : {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Découvrez comment gérer les règles du pare-feu local à l\'aide de Plesk Firewall dans Plesk pour Linux.',
                    windows: 'Pare-feu Plesk (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'Un certificat expiré est configuré sur cette installation Plesk.',
                    description: 'Utiliser ce type de configuration serveur et la connecter à Plesk 360 n\'est pas sécurisé. Nous vous recommandons de connecter un certificat auto-signé ou un certificat signé par une autorité de certification. En cliquant sur "Suivant", vous reconnaissez et acceptez que cette configuration n\'est pas sécurisée.',
                },
                certInvalid: {
                    title: 'Ce certificat de serveur est confirmé pour un autre nom de domaine.',
                    description: 'Soit le certificat n\'a pas été émis pour ce nom de domaine, soit vous avez saisi l\'adresse IP à la place du nom de domaine du serveur valide. Vous trouverez plus d\'informations dans <a>cet article</a> si vous avez la certitude que l\'URL fournie est valide.',
                },
                certSelfSigned: {
                    title: 'Un certificat auto-signé est configuré sur le serveur distant.',
                    description: 'Vous pouvez ajouter une installation Plesk à Plesk 360. Toutefois, nous vous recommandons vivement d\'utiliser un certificat signé par une autorité de certification, comme Let\'s Encrypt, pour protéger votre serveur contre d\'éventuels problèmes de sécurité.',
                },
                certUntrustedCa: {
                    title: 'Plesk 360 n\'accorde pas sa confiance au certificat CA du serveur distant.',
                    description: 'Vous pouvez ajouter une installation Plesk à Plesk 360. Toutefois, nous vous recommandons vivement d\'utiliser un certificat signé par une autorité de certification, comme Let\'s Encrypt, pour protéger votre serveur contre d\'éventuels problèmes de sécurité.',
                },
                agreeInvalidCertCheckbox: 'J\'accepte d\'ajouter ce serveur sans certificat valide',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'J\'aimerai ajouter un serveur non sécurisé {hostname} à mon compte Plesk 360',
            understandSecurityIssues: 'Je comprends que mon serveur est vulnérable face à divers problèmes de sécurité réseau',
            understandDataCanLost: 'Je comprends que je devrais ajouter au plus vite un certificat valide, sinon l\'accès à mon installation Plesk et aux données risque d\'être perdu',
            agreeNotResponsibility: 'En cliquant sur "Connecter tout de même", j\'accepte que Plesk 360 n\'est pas responsable de la sécurité de ce serveur.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Déconnecter',
        noSelectedServers: 'Sélectionnez un ou plusieurs serveurs à déconnecter.',
        disconnectConfirmation: '{count, plural, =1{Déconnecter <b>{title}</b> ?} one{Déconnecter <b>#</b> serveur ?} other{Déconnecter <b>#</b> serveurs ?}} Vous ne pouvez pas annuler cette action.',
        notifyDisconnectSuccess: '{count, plural, =1{Le serveur <b>{title}</b> a bien été déconnecté.} one{<b>#</b> a bien été déconnecté.} other{<b>#</b> serveurs ont bien été déconnectés.}}',
        notifyDisconnectFail: '{count, plural, =1{Le serveur <b>{title}</b> n\'a pas été déconnecté.} one{<b>#</b> n\'a pas été déconnecté.} other{<b>#</b> serveurs n\'ont pas été déconnectés.}}',
    },
    ViewServer: {
        title: 'Infos serveur',
        inGeneral: 'Vue d\'ensemble',
        domains: 'Sites Web',
        clients: 'Clients',
        installationLog: 'Journaux d\'installation',
        address: 'Adresse',
        notes: 'Remarques',
        product: 'Produit',
        version: 'Version',
        unknownVersion: 'inconnue',
        showErrorDetail: 'Afficher plus.',
        allowedInvalidCertificateWarning: 'La connexion au serveur est risquée en raison de problèmes de validation du certificat SSL/TLS.',
        allowedInvalidCertificateRisksNote: 'Vous choisissez d\'ignorer le résultat de la validation du certificat et vous acceptez tous les risques liés.',
        allowedInvalidCertificateLinkToDocs: 'En savoir plus sur les problèmes et les risques.',
        certificateErrorStatusMessage: 'La connexion au serveur a été désactivée suite au problème de certificat SSL/TLS suivant détecté lors de la connexion au serveur {serverName} ',
        certificateErrorFixDescription: 'Veuillez corriger l\'erreur et revérifier le certificat afin de restaurer cette connexion. Vous pouvez aussi ignorer le résultat de la validation du certificat et accepter <a>tous les risques associés</a>.',
        relatedRisks: 'tous les risques liés',
        ignoreCertificateErrorsButton: 'Ignorer la vérification du certificat et restaurer',
        ignoreCertificateConfirmation: 'Confirmez que vous reconnaissez et acceptez tous les risques liés aux problèmes de certificats.',
        confirmAndProceed: 'Confirmer et poursuivre',
        recheckCertificateButton: 'Revérifier le certificat',
        recheckResultSuccess: 'La connexion a été rétablie avec succès.',
        recheckResultCertificateError: 'La validation du certificat a échoué avec l\'erreur suivante : {errorMessage}',
        recheckResultConnectionError: 'Plesk 360 ne parvient pas à établir la connexion avec {serverName}.',
        recheckResultConnectionBlocked: 'La connexion au serveur {serverName} est bloquée.',
        connectionErrorStatusMessage: 'La connexion au serveur {serverName} ne peut pas être établie correctement.',
        connectionErrorFixDescription: 'Veuillez corriger l\'erreur, puis cliquez sur le bouton ci-dessous pour restaurer la connexion.',
        updatesButton: 'Mises à jour : {product}',
        login: {
            plesk: 'Accéder à Plesk',
            cpanel: 'Aller dans cPanel',
            wp: 'Ouvrir WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'Le serveur est configuré de telle manière qu\'il est impossible d\'établir la connexion. Causes possibles :',
            caseRestrictionManagement: 'Les adresses IP de la plate-forme 360 sont filtrées pour les accès administrateurs (<a>instructions</a>).',
            casePanelIniApiSection: 'L\'accès à distance via l\'API Plesk est désactivé dans panel.ini (<a>instructions</a>).',
            listPublicIps: 'Vérifiez si l\'accès depuis les adresses IP suivantes est autorisé : <b>{publicIps}</b>.',
            fixDescription: 'Pour connecter le serveur, ajustez les paramètres de configuration correspondants. Une fois terminé, cliquez sur le bouton ci-dessous pour vérifier la connexion.',
            recheckConnectionButton: 'Revérifier la connexion',
        },

        recheckConnectionButton: 'Revérifier la connexion',
        sync: 'Synchroniser',
        state: 'État du serveur',
        Domains: {
            name: 'Nom complet',
            hostingType: 'Type d\'hébergement',
            virtualHostingType: 'Site Web',
            forwardingHostingType: 'Redirection',
            noneHostingType: 'Aucun hébergement',
            mainDomainType: 'Domaine principal',
            subDomainType: 'Sous-domaine',
            addonDomainType: 'Domaine supplémentaire',
            parkedDomainType: 'Alias',
            manage: 'Gérer',
            manageLinkUnavailableHint: 'Vous devez disposer de Plesk RESTful API 1.4.0 ou d\'une version ultérieure pour générer un lien direct vers le panneau Plesk. Pour en savoir plus, consultez la documentation.',
            open: 'Ouvrir',
        },
        Clients: {
            name: 'Nom du contact',
            email: 'Adresse mail',
            login: 'Nom d\'utilisateur',
            type: 'Type',
            status: 'Statut',
            typeAdmin: 'Admin',
            typeReseller: 'Revendeur',
            typeCustomer: 'Client',
            typeCustomerWithOwner: 'Client de {owner}',
            statusActive: 'Actif',
            statusSuspended: 'Suspendu',
            manage: 'Gérer',
        },
    },
    InPlaceText: {
        ok: 'Enregistrer (Ctrl+Enter)',
        cancel: 'Annuler (Esc)',
        edit: 'Éditer',
        add: 'Ajouter',
    },
    ServerLogin: {
        title: 'Redirection vers le panneau de contrôle à l\'adresse {address}',
        progress: 'La connexion peut prendre quelques instants... ',
        clientNote: 'Nous vous rappelons que les Clients/Revendeurs ne sont pas visibles dans le Vue Power User.',
        errorTitle: 'Une erreur s\'est produite pendant la tentative de connexion au serveur.',
        errorDescription: 'Connectez-vous directement au serveur via l\'adresse du serveur en utilisant les identifiants adéquats, ou essayez à nouveau ultérieurement pour autoriser la connexion via Plesk 360.',
        btnGroupLogin: 'Connexion',
        tryAgain: 'Réessayer',
        loginDirectly: 'Se connecter directement',
        btnGroupCancel: 'Annuler',
        cancel: 'Annuler',
    },
    Team: {
        Layout: {
            title: 'Organisation',
            members: 'Membres',
            invitations: 'Invitations',
        },
        settings: 'Paramètres',
        Members: {
            email: 'Adresse mail',
            fullName: 'Nom complet',
            mfaStatus: 'Statut MFA',
            tags: 'Étiquettes',
            remove: 'Supprimer',
            removeMemberConfirmation: '<b>{name}</b> ne pourra plus accéder à l\'organisation ni à ses ressources partagées, serveurs, sites web et licences.',
            SpecialMarks: {
                you: 'Vous',
                owner: 'Propriétaire',
            },
            Onboarding: {
                title: 'Créez votre organisation dans notre plate-forme en ajoutant vos collègues.',
                description: 'Notre plate-forme vous propose des fonctionnalités de gestion et de collaboration efficaces pour les organisations. Vous et les membres de votre organisation pouvez travailler sur des serveurs connectés en y accédant directement depuis la plate-forme. Vous n\'avez plus besoin d\'utiliser des mots de passe partagés non sécurisés ou des systèmes d\'accès complexes.',
                addMembersButton: 'Ajouter des membres de l\'organisation',
            },
            MultifactorStatus: {
                enabled: 'MFA activée',
                disabled: 'MFA non activée',
            },
        },
        Invitations: {
            email: 'Adresse mail',
            expireAt: 'Expire le',
            expired: 'Expiré',
            add: 'Ajouter',
            revoke: 'Révoquer',
            Onboarding: {
                title: 'Depuis cet écran, vous pouvez inviter des membres de votre organisation.',
                description: 'Ajoutez vos collègues pour partager des serveurs et des sites web avec eux.',
                addButton: 'Inviter un nouveau membre',
            },
            ListActions: {
                resendInvitationTooltip: 'Renvoyez l\'invitation.',
                successMessage: 'L\'invitation par e-mail a été renvoyée.',
            },
            AddInvitations: {
                title: 'Ajouter de nouveaux membres à l\'organisation',
                infoMessage: 'Invitez les membres de votre organisation à vous rejoindre en saisissant leurs adresses e-mail professionnelles ci-dessous.',
                infoDescription: 'Nous vous recommandons de saisir les adresses e-mail professionnelles et non les adresses privées afin de protéger les informations personnelles de l\'utilisateur.',
                emailsLabel: 'Adresses e-mail pour le lien d\'invitation',
                submitButton: 'Envoyer l\'invitation',
                successMessage: '{count, plural, =1{L\'invitation a été envoyée à {email}.} one{# invitation a été envoyée.} other{# invitations ont été envoyées.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Révoquer',
                noSelectedInvitations: 'Sélectionnez une ou plusieurs invitations à révoquer.',
                revokeInvitationConfirmation: '{count, plural, =1{Révoquer <b>{email}</b> ?} one{Révoquer <b>#</b> invitation ?} other{Révoquer <b>#</b> invitations ?}} Vous ne pouvez pas annuler cette action.',
                successRevokeInvitation: '{count, plural, =1{L\'adresse <b>{email}</b> a été révoquée.} one{<b>#</b> invitation a été révoquée.} other{<b>#</b> invitations ont été révoquées.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Mises à jour du projet et communication commerciale',
            text: 'WebPros International GmbH et les autres entreprises du groupe WebPros peuvent stocker et traiter les données que je fournis à des fins de contenu marketing personnalisé comme des newsletters et des offres individuelles conformément à la <a>Politique de confidentialité</a>. Les offres personnalisées peuvent être basées sur des informations supplémentaires comme des données liées à l\'utilisation et au comportement (profilage). Je peux révoquer ce consentement à tout moment depuis les préférences du projet.',
        },
    },

    components: {
        List: {
            selectAll: 'Tout sélectionner',
            deselectAll: 'Tout retirer de la sélection',
            selectedRows: '{selectedRows, plural, one{# sur {totalRows, plural, one{# élément} other{# éléments}} sélectionné} other{# sur {totalRows, plural, one{# éléments} other{# éléments}} sélectionnés}}',
            totalRows: '{totalRows, plural, one{# élément en tout} other{# éléments en tout}}',
            foundRows: '{foundRows, plural, one{# élément trouvé} other{# éléments trouvés}}',
        },
        DataList: {
            groupSearch: 'Rechercher',
        },
        KbInstructionsFixLink: '<a>Consultez cet article de la base de connaissances</a> pour en savoir plus sur le processus à suivre afin de corriger ceci.',
    },
};