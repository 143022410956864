// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getBusinessProfile } from '@platform360/accounts/web/api/me';

export type UseBusinessProfileQueryVariables = undefined;

export type UseBusinessProfileQueryData = PromiseValue<ReturnType<typeof getBusinessProfile>>;

const useBusinessProfileQuery = createQuery<
    UseBusinessProfileQueryVariables,
    UseBusinessProfileQueryData
>({
    queryName: 'accounts/useBusinessProfileQuery',
    fetcher: getBusinessProfile,
    useQuery,
});

export default useBusinessProfileQuery;
