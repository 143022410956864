// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Status as UILibStatus, TranslationProps } from '@plesk/ui-library';
import styles from './Status.module.css';

type StatusProps = {
    count: number;
    errorIntent: 'warning' | 'danger';
    translations: {
        error: TranslationProps;
        success: TranslationProps;
    };
};

const Status = ({ count, errorIntent, translations: { error, success } }: StatusProps) => (
    <div className={styles.root}>
        <UILibStatus intent={count > 0 ? errorIntent : 'success'} compact>
            {count > 0 ? error : success}
        </UILibStatus>
    </div>
);

export default Status;
