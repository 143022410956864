// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Drawer, StatusMessage, FormFieldText } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import GeneralValidationErrors from '@platform360/libs/shared-web/components/GeneralValidationErrors';
import {
    useTranslateValidationErrors,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';

export type FormValues = {
    invitations: string[];
};

export type AddInvitationsProps = {
    onSubmit: (values: FormValues) => void;
    isLoading: boolean;
    errors?: ValidationError[];
    onClose: () => void;
};

const AddInvitations = ({
    onSubmit,
    onClose,
    isLoading,
    errors = [],
    ...props
}: AddInvitationsProps) => {
    const translate = useTranslate('Team.Invitations.AddInvitations');
    const translateValidationErrors = useTranslateValidationErrors();
    const [values, setValues] = useState<FormValues>({
        invitations: [''],
    });
    const handleSubmit = (values: FormValues) => {
        const nextValues = {
            invitations: values.invitations
                .map((v) => v || '')
                .filter((v, i) => (i === 0 ? true : v)),
        };
        setValues(nextValues);
        onSubmit(nextValues);
    };
    const handleClose = () => {
        setValues({
            invitations: [''],
        });
        onClose();
    };

    const formErrors = errors.filter((e) => e.path !== 'general');
    const generalErrors = errors.filter((e) => e.path === 'general');

    return (
        <Drawer
            title={translate('title')}
            size="xs"
            data-type="add-invitations-drawer"
            form={{
                state: isLoading ? 'submit' : undefined,
                values,
                errors: translateValidationErrors(
                    formErrors.map((err: ValidationError) => ({
                        ...err,
                        path: err.path.replace('.email', ''),
                    })),
                ),
                vertical: true,
                applyButton: false,
                submitButton: {
                    children: translate('submitButton'),
                },
                onSubmit: handleSubmit,
            }}
            onClose={handleClose}
            {...props}
        >
            <StatusMessage intent="info">
                {translate('infoMessage')}
                <br />
                {translate('infoDescription')}
            </StatusMessage>
            <GeneralValidationErrors errors={generalErrors} />
            <FormFieldText
                label={translate('emailsLabel')}
                name="invitations"
                size="lg"
                multi={{ max: 10 }}
            />
        </Drawer>
    );
};

export default AddInvitations;
