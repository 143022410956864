// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Navigate, useParams } from 'react-router-dom';
import { ContentLoader, Heading, Toolbar } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import PeriodControl from '../containers/PeriodControl';
import Extensions from '../containers/Extensions';
import BuyExtension from '../containers/BuyExtension';
import { licensesUri } from '@platform360/licenses/web/helpers';
import LicenseInfo from '@platform360/licenses/web/units/extensions/containers/LicenseInfo';
import NonBreakingSpace from '@platform360/licenses/web/components/HTMLEntities/NonBreakingSpace';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

type PageProps = {
    initialized: boolean;
    initialization: (licenseId: number) => void;
    finalization: () => void;
    failedInitialization: boolean;
    selectedItemPath: string;
};

const Page = ({
    initialized,
    initialization,
    finalization,
    failedInitialization,
    selectedItemPath,
}: PageProps) => {
    const translate = useTranslate();
    const { licenseId } = useParams<{ licenseId: string }>();

    useEffectOnce(() => {
        initialization(Number(licenseId));
        return finalization;
    });

    if (failedInitialization) {
        return <Navigate to={licensesUri} />;
    }

    return (
        <>
            <Heading level={2}>{translate('upsellPageTitle')}</Heading>
            {!initialized && <ContentLoader />}
            {initialized && (
                <>
                    <LicenseInfo />
                    <Toolbar>
                        <PeriodControl />
                        <NonBreakingSpace />
                    </Toolbar>
                    <Extensions />
                </>
            )}
            {selectedItemPath && <BuyExtension />}
        </>
    );
};

export default Page;
