// Copyright 2024. WebPros International GmbH. All rights reserved.

import en from './en';
import de from './de';
import es from './es';
import fr from './fr';
import it from './it';
import pt from './pt';
import ru from './ru';
import ja from './ja';

export const locales = {
    en: {
        name: 'English',
        direction: 'ltr',
        ...en,
    },
    de: {
        name: 'Deutsch',
        direction: 'ltr',
        ...de,
    },
    es: {
        name: 'Español',
        direction: 'ltr',
        ...es,
    },
    fr: {
        name: 'Français',
        direction: 'ltr',
        ...fr,
    },
    it: {
        name: 'Italiano',
        direction: 'ltr',
        ...it,
    },
    pt: {
        name: 'Português (Brasil)',
        direction: 'ltr',
        ...pt,
    },
    ru: {
        name: 'Русский',
        direction: 'ltr',
        ...ru,
    },
    ja: {
        name: '日本語',
        direction: 'ltr',
        ...ja,
    },
};
