// Copyright 2024. WebPros International GmbH. All rights reserved.

import { OperationSubTypes } from '@platform360/security-dashboard/shared/operation-sub-type';
import {
    OperationsApiResponseRow,
    OperationsApiResponseSubRow,
    TaskManagerListRow,
} from '@platform360/security-dashboard/web/types';

type TaskListDataConverterProps = {
    data: OperationsApiResponseRow[];
};

type ConvertedData = {
    data: TaskManagerListRow[];
    notExpandableRows: string[];
};
export const taskListDataConverter = ({ data }: TaskListDataConverterProps): ConvertedData => {
    const notExpandableRows: string[] = [];
    const convertedData: TaskManagerListRow[] = [];

    const getTitleFromSubRow = (row: OperationsApiResponseRow): string | undefined => {
        const [subRow] = row.items;
        if (row.itemsAffected === 1 && subRow?.title) {
            return subRow.title;
        }

        return undefined;
    };

    const getErrorsFromSubRow = (row: OperationsApiResponseRow): string[] => {
        const [subRow] = row.items;
        if (subRow) {
            return subRow.errors;
        }

        return row.errors;
    };

    const getSubRowKey = (row: OperationsApiResponseRow, subRow: OperationsApiResponseSubRow) =>
        `${row.id.toString()}-${subRow.id.toString()}`;

    const getRowWithSubRows = (row: OperationsApiResponseRow): TaskManagerListRow => {
        const subRows = row.items.map((subRow) => ({
            ...subRow,
            key: getSubRowKey(row, subRow),
        }));
        return {
            ...row,
            data: subRows,
            key: row.id.toString(),
            title: getTitleFromSubRow(row),
            errors: getErrorsFromSubRow(row),
        };
    };

    const getRowWithoutSubRows = (row: OperationsApiResponseRow): TaskManagerListRow => ({
        ...row,
        key: row.id.toString(),
        data: [],
        title: getTitleFromSubRow(row),
        errors: getErrorsFromSubRow(row),
    });

    data.forEach((row) => {
        if (row.subType === OperationSubTypes.servers) {
            if (row.status.success === 1 && row.status.failed === 0) {
                notExpandableRows.push(row.id.toString());
                convertedData.push(getRowWithoutSubRows(row));
            } else if (row.status.success === 0 && row.status.failed === 1) {
                convertedData.push(getRowWithoutSubRows(row));
            } else {
                notExpandableRows.push(row.id.toString());
                convertedData.push(getRowWithSubRows(row));
                row.items.forEach((subItem) => {
                    if (subItem.status.failed === 0) {
                        notExpandableRows.push(getSubRowKey(row, subItem));
                    }
                });
            }
        } else if (row.status.failed === 0) {
            notExpandableRows.push(row.id.toString());
            convertedData.push(getRowWithoutSubRows(row));
        } else if (row.status.success === 0 && row.status.failed >= 1) {
            convertedData.push(getRowWithoutSubRows(row));
        } else {
            convertedData.push(getRowWithSubRows(row));
        }
    });

    return {
        data: convertedData,
        notExpandableRows,
    };
};

export default taskListDataConverter;
