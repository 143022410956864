// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, Heading, Panel, Section, SectionItem } from '@plesk/ui-library';
import { GetSecurityDashboardLicenseResponseData } from '@platform360/admin-panel/shared/api-contract';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useUpdateLicenseMutation } from '@platform360/admin-panel/web/mutations/security-dashboard';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import InPlaceEdit from '@platform360/admin-panel/web/components/InPlaceEdit';
import { DateTime } from 'luxon';
import styles from './LicenseCard.module.css';

type LicenseCardProps = {
    license: GetSecurityDashboardLicenseResponseData;
};

export const LicenseCard = ({
    license: { id, product, expiredAt, createdAt },
}: LicenseCardProps) => {
    const formatDateTime = useFormatDateTime();
    const translate = useTranslate('admin-panel.security-dashboard.Licenses.LicenseCard');
    const { successToast } = useToaster();
    const { mutateAsync, errors, reset } = useUpdateLicenseMutation({
        onSuccess: () => {
            successToast(translate('savedSuccessToast'));
        },
    });

    const handleExpiredAtSave = async (expiredAt: string) => {
        await mutateAsync({
            licenseId: id,
            data: {
                expiredAt,
            },
        });
    };

    return (
        <Panel title={<Heading level={3}>{translate('title')}</Heading>}>
            <Form
                errors={errors}
                cancelButton={false}
                submitButton={false}
                applyButton={false}
                footerClassName={styles.footer}
            >
                <Section vertical={false}>
                    <SectionItem title={translate('id')}>{id}</SectionItem>
                    <SectionItem title={translate('product')}>{product}</SectionItem>
                    <SectionItem title={translate('expiredAt')}>
                        <InPlaceEdit
                            type="date"
                            name="expiredAt"
                            onCancel={reset}
                            value={DateTime.fromISO(expiredAt, { zone: 'utc' }).toFormat(
                                'yyyy-MM-dd',
                            )}
                            onSave={handleExpiredAtSave}
                        />
                    </SectionItem>
                    <SectionItem title={translate('createdAt')}>
                        {formatDateTime(createdAt)}
                    </SectionItem>
                </Section>
            </Form>
        </Panel>
    );
};
