// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment, useState } from 'react';
import { ConsentItem } from '@platform360/libs/shared-web/helpers/useConsentItems';
import styles from './SignUpForm.module.css';
import NameFormField from '@platform360/auth/web/components/SignUp/SignUpForm/NameFormField';
import {
    Form,
    FormErrors,
    FormFieldCheckbox,
    FormFieldPassword,
    FormFieldText,
    setIn,
    TranslationProps,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import LegalLink from '@platform360/auth/web/components/LegalLink';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export type SignUpFormProps = {
    onSubmit: (request: FormValues) => void;
    onEmailChange?: (email: string) => void;
    isLoading: boolean;
    consentItems: ConsentItem[];
    errors?: FormErrors;
    email?: string;
    emailReadOnly?: boolean;
    emailFieldDescription: TranslationProps;
};

export type FormValues = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    privacyPolicyAgreement: boolean;
    consents: ConsentFlags;
};

export type ConsentFlags = Record<string, boolean>;

const DataTestIds = {
    FORM: 'SignUp--form',
};

const getConsentFlags = (consentItems: ConsentItem[], value: boolean): ConsentFlags =>
    Object.fromEntries(consentItems.map(({ type }) => [type, value]));

const SignUpForm = ({
    onSubmit,
    onEmailChange,
    isLoading,
    consentItems,
    errors,
    email = '',
    emailReadOnly,
    emailFieldDescription,
}: SignUpFormProps) => {
    const translate = useTranslate('Auth.SignUp');
    const {
        auth: { termsOfUseLink },
    } = useApplicationSettings();

    const [values, setValues] = useState<FormValues>({
        email,
        password: '',
        firstName: '',
        lastName: '',
        privacyPolicyAgreement: false,
        // All consents are opt-in, so they are off by default.
        consents: getConsentFlags(consentItems, false),
    });

    const onFieldChange = <Key extends keyof FormValues>(key: Key, value: FormValues[Key]) =>
        setValues(setIn(values, key, value));

    return (
        <Form
            onSubmit={(values) => onSubmit(values)}
            onFieldChange={onFieldChange}
            errors={errors}
            state={isLoading ? 'submit' : undefined}
            values={values}
            applyButton={false}
            submitButton={{
                children: translate('signUp'),
                disabled: !values.privacyPolicyAgreement,
                type: 'submit',
            }}
            cancelButton={false}
            hideRequiredLegend
            vertical
            data-type={DataTestIds.FORM}
        >
            <FormFieldText
                onChange={onEmailChange}
                name="email"
                label={translate('emailLabel')}
                description={emailFieldDescription}
                size="fill"
                disabled={emailReadOnly}
                required={!emailReadOnly}
                autoFocus={!emailReadOnly}
            />
            <FormFieldPassword
                autoFocus={emailReadOnly}
                name="password"
                label={translate('passwordLabel')}
                size="fill"
                required
            />
            <NameFormField />
            <div className={styles.privacyPolicy}>
                <FormFieldCheckbox
                    name="privacyPolicyAgreement"
                    label={translate('privacyPolicyAgreementTitle', {
                        a: (chunk) => <LegalLink to={'/legal/terms'}>{chunk}</LegalLink>,
                        termsLink: termsOfUseLink,
                    })}
                    description={translate('privacyPolicyAgreementDescription', {
                        a: (chunk) => <LegalLink to={'/legal/privacy-policy'}>{chunk}</LegalLink>,
                    })}
                    required
                />
                {consentItems.map(({ type, title, renderText }) => (
                    <Fragment key={type}>
                        <FormFieldCheckbox
                            name={`consents[${type}]`}
                            label={title}
                            description={renderText()}
                        />
                    </Fragment>
                ))}
            </div>
        </Form>
    );
};

export default SignUpForm;
