// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './DotsMenu.module.css';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { MenuItemProps } from './MenuItem';
import useShowMainMenu from '@platform360/libs/shared-web/components/Page/useShowMainMenu';
import { useMemo } from 'react';
import useMainMenuItems, {
    MainMenuItem,
} from '@platform360/libs/shared-web/components/Page/useMainMenuItems';

const subMenuMapper = ({ beta, ...item }: MainMenuItem): MenuItemProps => ({
    ...item,
    type: 'link',
    children: (
        <ul className={styles.ul}>
            <li>{item.children}</li>
        </ul>
    ),
});

const useNineDotsMenu = (): MenuItemProps[] => {
    const translate = useTranslate('Page.DotsMenu');
    const moveMainToDotsMenu = !useShowMainMenu();
    const mainMenuItems = useMainMenuItems();
    const {
        monitoring: { baseUrl: monitoringBaseUrl },
    } = useConfig();

    return useMemo(() => {
        const dotsMenuItems: MenuItemProps[] = [
            {
                type: 'link',
                children: translate('platform'),
                to: '/',
                icon: 'cloud',
            },
            {
                type: 'groupDivider',
                children: translate('services'),
            },
            {
                type: 'link',
                isExternal: true,
                children: translate('monitoring'),
                to: monitoringBaseUrl,
                icon: 'monitoring',
            },
            {
                type: 'groupDivider',
            },
            {
                type: 'link',
                isExternal: true,
                children: translate('feedback'),
                to: 'https://pt-research.typeform.com/to/BVtcGgRL',
                icon: 'feedback',
                target: '_blank',
            },
        ];

        if (moveMainToDotsMenu && mainMenuItems.length > 0) {
            dotsMenuItems.splice(1, 0, ...mainMenuItems.map(subMenuMapper));
        }

        return dotsMenuItems;
    }, [mainMenuItems, monitoringBaseUrl, moveMainToDotsMenu, translate]);
};

export default useNineDotsMenu;
