// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './MainMenu.module.css';
import Item from './Item';
import NavLink from '@platform360/libs/shared-web/components/Page/NavLink';
import useShowMainMenu from '@platform360/libs/shared-web/components/Page/useShowMainMenu';
import useMainMenuItems from '@platform360/libs/shared-web/components/Page/useMainMenuItems';
import { Tooltip } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const MainMenu = () => {
    const translate = useTranslate('Page.MainMenu');
    const showMainMenu = useShowMainMenu();
    const mainMenuItems = useMainMenuItems();

    if (!showMainMenu) {
        return null;
    }

    return (
        <ul className={styles.root}>
            {mainMenuItems.map(({ children, beta, ...props }) => (
                <Item key={props.to} layout="header">
                    <NavLink {...props}>
                        {beta ? (
                            <Tooltip title={translate('betaVersion')}>
                                <span className={styles.beta}>{children}</span>
                            </Tooltip>
                        ) : (
                            children
                        )}
                    </NavLink>
                </Item>
            ))}
        </ul>
    );
};

export default MainMenu;
