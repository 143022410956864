// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dropdown, Menu, MenuSelectableItem } from '@plesk/ui-library';
import { useContext } from 'react';
import GhostButton from '@platform360/libs/shared-web/components/GhostButton';
import { LocalesContext } from '@platform360/libs/shared-web/locale/components/LocaleProvider/LocaleProvider';
import useLocale from '@platform360/libs/shared-web/locale/useLocale';

export const LocaleSwitcher = () => {
    const locales = useContext(LocalesContext);
    const [localeCode, setLocaleCode] = useLocale();

    const renderMenu = () => (
        <Menu>
            {Object.entries(locales).map(([code, { name }]) => (
                <MenuSelectableItem
                    key={code}
                    selected={code === localeCode}
                    onClick={() => setLocaleCode(code)}
                >
                    {name}
                </MenuSelectableItem>
            ))}
        </Menu>
    );

    return (
        <Dropdown menu={renderMenu()} menuPlacement="bottom-end">
            <GhostButton caret>
                {locales[localeCode]?.name.substring(0, 3).toUpperCase()}
            </GhostButton>
        </Dropdown>
    );
};

export default LocaleSwitcher;
