// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Outlet } from 'react-router-dom';
import Page from '@platform360/libs/shared-web/components/Page';

const PageLayout = () => (
    <div id="pageLayout">
        <Page>
            <Outlet />
        </Page>
    </div>
);

export default PageLayout;
