// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { genHostname } from '@platform360/web-installer/web/api/tech-domain';
import { createQuery } from '@platform360/libs/shared-web/query';
import { GenerateTechDomainRequest } from '@platform360/web-installer/shared/api-contract';

export type UseGenHostnameVariables = GenerateTechDomainRequest;
export type UseGenHostnameData = PromiseValue<ReturnType<typeof genHostname>>;

const useGenHostname = createQuery<UseGenHostnameVariables, UseGenHostnameData>({
    queryName: `web-installer/tech-domain/gen-hostname`,
    fetcher: genHostname,
    useQuery: (options) =>
        useQuery({
            staleTime: Infinity,
            ...options,
        }),
});

export default useGenHostname;
