// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ServerStatusMessage from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/ServerStatusMessage';
import RecheckConnectionButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/RecheckConnectionButton';

export type ConnectionErrorStatusMessageProps = {
    serverId: number;
    serverName: string;
    errorCode: string;
    errorDetail: string;
    isRecheckConnectionEnabled: boolean;
};

const ConnectionErrorStatusMessage = ({
    serverId,
    serverName,
    errorCode,
    errorDetail,
    isRecheckConnectionEnabled = true,
    ...props
}: ConnectionErrorStatusMessageProps) => {
    const translate = useTranslate('ViewServer');

    return (
        <ServerStatusMessage
            intent="danger"
            message={translate('connectionErrorStatusMessage', {
                serverName,
            })}
            errorCode={errorCode}
            errorDetail={errorDetail}
            fixMessage={translate('connectionErrorFixDescription')}
            actions={
                isRecheckConnectionEnabled && (
                    <RecheckConnectionButton
                        serverId={serverId}
                        title={translate('recheckConnectionButton')}
                    />
                )
            }
            data-type="connection-error-status-message"
            {...props}
        />
    );
};

export default ConnectionErrorStatusMessage;
