// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMemo, useRef } from 'react';
import ServerViewMonitoring from './ServerViewMonitoring';
import ConnectServer from './ConnectServer';
import LoadingError from './LoadingError';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { ContentLoader } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useServerQuery from '@platform360/monitoring/web/queries/useServerQuery';
import useServerMetricsImage from '@platform360/monitoring/web/queries/useServerMetricsImage';
import PausedServer from '@platform360/monitoring/web/components/ServerViewMonitoring/PausedServer';

type ServerViewMonitoringContainerProps = {
    serverGuid: string;
    address: URL;
};

const ServerViewMonitoringContainer = ({
    serverGuid,
    address,
}: ServerViewMonitoringContainerProps) => {
    const endDate = useRef(new Date());
    const {
        monitoring: { baseUrl },
    } = useConfig();
    const translateFn = useTranslate('monitoring.ServerViewMonitoring');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const translate = useMemo(() => translateFn, []);

    const {
        data: monitoringServer,
        error: monitoringServerError,
        refetch: handleMonitoringServerRefresh,
        isRefetching: isMonitoringServerRefetching,
    } = useServerQuery({ variables: { serverGuid } });

    const serverMetricsImageParams = useMemo(
        () => ({
            metric: 'la',
            key: '5',
            width: 1600,
            title: translate('loadAverageTitle') as string,
            start: Math.round(new Date().setUTCDate(endDate.current.getUTCDate() - 1) / 1000),
            end: Math.round(endDate.current.getTime() / 1000),
        }),
        [endDate, translate],
    );
    const { data: metricsImageUrl, isError: isMetricsImageError } = useServerMetricsImage({
        variables: {
            serverGuid,
            params: serverMetricsImageParams,
        },
        staleTime: Infinity,
        enabled:
            Boolean(monitoringServer) &&
            monitoringServer?.inSubscription &&
            monitoringServer.isDataAvailable,
    });

    if (monitoringServerError?.response?.status === 404) {
        return (
            <ConnectServer
                connectLink={`/monitoring/server/${serverGuid}?address=${address.toString()}`}
            />
        );
    }

    if (monitoringServerError) {
        return <LoadingError />;
    }

    if (!monitoringServer) {
        return <ContentLoader />;
    }

    const monitoringServerLink = `${baseUrl}server/${monitoringServer.monitoringServerId}`;

    if (!monitoringServer.inSubscription) {
        return <PausedServer monitoringServerLink={monitoringServerLink} />;
    }

    return (
        <ServerViewMonitoring
            monitoringServerLink={monitoringServerLink}
            alerts={monitoringServer.alerts}
            metricsImageUrl={metricsImageUrl}
            isMetricsImageError={isMetricsImageError}
            isDataAvailable={monitoringServer.isDataAvailable}
            isMonitoringServerRefetching={isMonitoringServerRefetching}
            onMonitoringServerRefresh={handleMonitoringServerRefresh}
        />
    );
};

export default ServerViewMonitoringContainer;
