// Copyright 2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import AdditionalInformation, {
    AdditionalInformationProps,
} from '../../components/extensionDetails/AdditionalInformation';
import { StoreState } from '@platform360/licenses/web/store/types';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';

const mstp = (state: StoreState): AdditionalInformationProps => {
    const {
        onlineStoreData: {
            alignmentGrossPrice,
            alignmentVatPrice,
            nextBillingGrossPrice,
            nextBillingVatPrice,
            nextBillingDate,
        },
        currency,
        period,
    } = buyExtensionSelector(state);

    return {
        grossPrice: {
            current: alignmentGrossPrice,
            next: nextBillingGrossPrice,
        },
        vatPrice: {
            current: alignmentVatPrice,
            next: nextBillingVatPrice,
        },
        nextDate: nextBillingDate,
        currency,
        period,
    };
};

export default connect(mstp)(AdditionalInformation);
