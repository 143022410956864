// Copyright 2024. WebPros International GmbH. All rights reserved.

import punycode from 'punycode/';
import {
    Button,
    Section,
    SectionItem,
    Text,
    Toolbar,
    ToolbarExpander,
    ToolbarGroup,
} from '@plesk/ui-library';
import { Products } from '@platform360/server-inventory/shared/product-type';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Server } from '@platform360/server-inventory/web/types';
import DisconnectServersOperation from '@platform360/server-inventory/web/components/Servers/DisconnectServersOperation';
import InPlaceNotes from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/InPlaceNotes';
import ServerUpdatesButton from './ServerUpdatesButton';
import SuperSyncButton from './SyncButton';
import ServerStatus from '@platform360/server-inventory/web/components/Servers/ServerStatus';
import StatusMessages from './StatusMessages';
import ProductIcon from '@platform360/server-inventory/web/components/Servers/ProductIcon';
import CPanelOneTimeLoginAlert from '@platform360/web-installer/web/components/CPanelOneTimeLoginAlert';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { productName } from '@platform360/server-inventory/web/helpers/productName';
import { ServerViewModel } from '@platform360/server-inventory/web/viewModels/ServerViewModel';
import InstallationErrorMessage from '@platform360/web-installer/web/components/InstallationErrorMessage';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';
import { UpdateServerTriggers } from '@platform360/server-inventory/web/helpers/analytics/ServerInventoryAnalyticsEvents';
import { isWebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

export type GeneralInfoProps = {
    server: Server;
    onRemoveServerSuccess: () => void;
};

const punycodeHref = (url: URL): string => {
    const idnHostname = punycode.toUnicode(url.hostname);
    const urlClone = new URL(url.href);
    urlClone.hostname = '___';
    return urlClone.href.replace('___', idnHostname);
};

export const GeneralInfo = ({ server, onRemoveServerSuccess }: GeneralInfoProps) => {
    const translate = useTranslate('ViewServer');
    const analyticsEvents = useServerInventoryAnalyticsEvents();

    const handleRemoveServerConfirm = () => {
        analyticsEvents.serverViewDisconnectClicked();
    };

    const handleServerLoginClick = () => {
        analyticsEvents.serverViewGoToServerClicked(server.product);
    };

    const renderLoginButton = () => {
        const supportedProducts: string[] = [Products.cpanel, Products.plesk];
        if (!supportedProducts.includes(server.product)) {
            return null;
        }

        return (
            <ToolbarGroup title={translate(`login.${server.product}`)}>
                <Button
                    component="a"
                    icon="panel-settings"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/si/servers/${server.id}/login/${LoginTargets.home}`}
                    data-type="login-link"
                    onClick={handleServerLoginClick}
                >
                    {translate(`login.${server.product}`)}
                </Button>
            </ToolbarGroup>
        );
    };

    const isReady = ServerViewModel.isReady(server);
    return (
        <Section>
            <Toolbar>
                {isReady && (
                    <ServerUpdatesButton
                        server={server}
                        trigger={UpdateServerTriggers.serverInfoUpdateServerButton}
                    >
                        {translate('updatesButton', { product: productName[server.product] })}
                    </ServerUpdatesButton>
                )}
                {isReady && renderLoginButton()}
                {isReady && (
                    <ToolbarGroup title={translate('sync')}>
                        <SuperSyncButton serverId={server.id} />
                    </ToolbarGroup>
                )}
                <ToolbarExpander />
                <DisconnectServersOperation
                    servers={[server]}
                    onConfirm={handleRemoveServerConfirm}
                    onSuccess={onRemoveServerSuccess}
                    disabled={ServerViewModel.isInstallationRunning(server)}
                />
            </Toolbar>
            <StatusMessages server={server} />
            {isWebInstallerProduct(server.product) ? (
                <InstallationErrorMessage
                    host={server.address.hostname}
                    error={server.installationError}
                    productType={server.product}
                />
            ) : null}
            {server.product === 'cpanel' && server.installationStatus === 'license-required' ? (
                <CPanelOneTimeLoginAlert serverGuid={server.guid} />
            ) : null}
            <SectionItem title={translate('notes')}>
                <InPlaceNotes serverId={server.id} notes={server.notes || ''} />
            </SectionItem>
            <SectionItem title={translate('address')}>
                <Text data-type="address-field">{punycodeHref(server.address)}</Text>
            </SectionItem>
            <SectionItem title={translate('state')}>
                <ServerStatus server={server} />
            </SectionItem>
            <SectionItem title={translate('product')}>
                <ProductIcon product={server.product} />
            </SectionItem>
            <SectionItem title={translate('version')}>
                <Text>{server.productVersion?.version ?? translate('unknownVersion')}</Text>
            </SectionItem>
        </Section>
    );
};

export default GeneralInfo;
