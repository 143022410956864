// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ToastProps, TranslationProps } from '@plesk/ui-library';

export const EVENT_NAME = 'platform-toaster-event';

export type ToastOptions = Omit<ToastProps, 'message'> & {
    /**
     * Merge toasts with the similar messages.
     */
    merge?: boolean;
};

export type EventType =
    | 'send-error-toast'
    | 'send-success-toast'
    | 'send-warning-toast'
    | 'clear-toasts';

export type EventPayload = ToastProps & {
    type: EventType;
};

const dispatch = (type: EventType, payload?: Omit<EventPayload, 'type'>) => {
    document.dispatchEvent(
        new CustomEvent(EVENT_NAME, {
            cancelable: false,
            detail: {
                type,
                ...payload,
            },
        }),
    );
};

export default {
    success: (message: TranslationProps, options?: ToastOptions) =>
        dispatch('send-success-toast', { message, ...options }),
    warning: (message: TranslationProps, options?: ToastOptions) =>
        dispatch('send-warning-toast', { message, ...options }),
    error: (message: TranslationProps, options?: ToastOptions) =>
        dispatch('send-error-toast', { message, ...options }),
    clear: () => dispatch('clear-toasts'),
};
