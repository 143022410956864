// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { MONTHLY, ONCE, YEARLY } from '@platform360/licenses/web/constants/billingPeriod';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';

// part of string in the context of the locale
const period2Text = {
    [MONTHLY]: 'Month',
    [YEARLY]: 'Year',
    [ONCE]: 'Once',
    default: 'Once',
};

type BillingIntervalProps = {
    period: BillingPeriod;
    children: ReactElement | string;
};

const BillingInterval = ({ period, children }: BillingIntervalProps) => {
    const translate = useTranslate();

    return translate(`billingInterval${period2Text[period] || period2Text.default}`, {
        value: children,
    });
};

export default BillingInterval;
