// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Label as UILibLabel } from '@plesk/ui-library';
import CopyText from '@platform360/libs/shared-web/components/CopyText';
import styles from './Label.module.css';

type LabelProps = {
    title: ReactNode;
    value: string;
};

export const Label = ({ title, value }: LabelProps) => (
    <div className={styles.root}>
        <UILibLabel view="outline" intent="danger">
            <b className={styles.title}>{title}</b>
            <CopyText text={value} intent="danger" />
        </UILibLabel>
    </div>
);
