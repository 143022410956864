// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Jeton d\'authentification absent ou non valide.',
        invitationNotExist: 'Invitation inexistante.',
        invitationExpired: 'L\'invitation a expiré.',
        invitationConflict: 'L\'utilisateur invité est déjà enregistré.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'Votre mot de passe a été changé.',
    },
    ChangePasswordForm: {
        resetTitle: 'Nouveau mot de passe',
        setTitle: 'Définir le mot de passe',
        passwordLabel: 'Mot de passe :',
        submitButton: 'Confirmer',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-mail de réinitialisation du mot de passe envoyé',
        successSendResetText: 'Nous avons envoyé un e-mail avec un lien de réinitialisation du mot de passe à l\'adresse {email}. Cliquez sur ce lien pour vous connecter à Plesk 360 et définir un nouveau mot de passe.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Lien obsolète',
        resetText: 'Le lien de réinitialisation du mot de passe a expiré.',
        setText: 'Le lien de réinitialisation du mot de passe n\'est plus valide. Pour votre sécurité, le lien de réinitialisation du mot de passe envoyé par e-mail est valide pendant 4 heures. Si vous n\'avez pas eu le temps d\'utiliser le lien pour définir votre mot de passe, vous pouvez à nouveau saisir votre adresse e-mail ci-dessous et définir le mot de passe en suivant la procédure de modification du mot de passe.',
        emailLabel: 'E-mail :',
        submitButton: 'Envoyer',
        bottomText: 'Vous souvenez-vous de votre mot de passe ? <a>Connectez-vous</a>.',
    },
};