// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server } from '@platform360/server-inventory/web/types';
import { Hint, Text, Tooltip } from '@plesk/ui-library';
import { DateTime } from 'luxon';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ServerViewModel } from '@platform360/server-inventory/web/viewModels/ServerViewModel';
import { ServerLabel } from './ServerLabel';
import { VersionStatuses } from '@platform360/server-inventory/shared/version-status';
import { InactiveReasons } from '@platform360/server-inventory/shared/inactive-reason';

export type ServerStatusProps = {
    server: Server;
    to?: string;
    className?: string;
};

type ServerState =
    | 'installationRunning'
    | 'installationFailure'
    | 'installationLicenseRequired'
    | 'inactiveUnreachable'
    | 'inactiveUnsupported'
    | 'connectionError'
    | 'connectionBlocked'
    | 'certificateError'
    | 'insecure'
    | 'outdated'
    | 'unsupportedVersion'
    | 'active';

const getServerState = (server: Server): ServerState => {
    if (ServerViewModel.isInstallationRunning(server)) {
        return 'installationRunning';
    }

    if (ServerViewModel.isInstallationFailed(server)) {
        return 'installationFailure';
    }

    if (ServerViewModel.isInstallationLicenseRequired(server)) {
        return 'installationLicenseRequired';
    }

    const {
        active,
        inactiveReason,
        lastRequestResultType,
        allowInvalidCertificate,
        productVersion,
    } = server;

    if (!active) {
        switch (inactiveReason) {
            case InactiveReasons.unsupported:
                return 'inactiveUnsupported';
            case InactiveReasons.unreachable:
                return 'inactiveUnreachable';
            case null:
                // This should never match.
                return 'inactiveUnreachable';
        }
    }

    if (lastRequestResultType.status === 'connectionError') {
        return 'connectionError';
    }

    if (lastRequestResultType.status === 'connectionBlocked') {
        return 'connectionBlocked';
    }

    if (lastRequestResultType.status === 'certificateError') {
        return allowInvalidCertificate ? 'insecure' : 'certificateError';
    }

    if (productVersion?.versionStatus === VersionStatuses.outdated) {
        return 'outdated';
    }

    if (productVersion?.versionStatus === VersionStatuses.unsupported) {
        return 'unsupportedVersion';
    }

    return 'active';
};

type ServerStateLabelProps = { server: Server; to?: string };

const ServerStateLabel = ({ server, to }: ServerStateLabelProps) => {
    const translate = useTranslate('server-inventory.Servers.ServerStatus');
    const serverState = getServerState(server);

    switch (serverState) {
        case 'installationRunning':
            return (
                <ServerLabel intent="info" icon="arrow-down-tray" to={to}>
                    {translate('installationRunning')}
                </ServerLabel>
            );

        case 'installationFailure':
            return (
                <ServerLabel intent="danger" icon="triangle-exclamation-mark-filled" to={to}>
                    {translate('installationFailure')}
                </ServerLabel>
            );

        case 'installationLicenseRequired':
            return (
                <ServerLabel intent="warning" icon="key" to={to}>
                    {translate('installationLicenseRequired')}
                </ServerLabel>
            );

        case 'inactiveUnreachable':
            return (
                <ServerLabel intent="inactive" icon="minus-circle-filled" to={to}>
                    <Tooltip title={translate('inactiveUnreachableTooltip')}>
                        <Text>{translate('inactiveUnreachable')}</Text>
                    </Tooltip>
                </ServerLabel>
            );

        case 'inactiveUnsupported':
            return (
                <ServerLabel intent="inactive" icon="minus-circle-filled" to={to}>
                    <Tooltip title={translate('inactiveUnsupportedTooltip')}>
                        <Text>{translate('inactiveUnsupported')}</Text>
                    </Tooltip>
                </ServerLabel>
            );

        case 'connectionError':
            return (
                <ServerLabel intent="danger" icon="triangle-exclamation-mark-filled" to={to}>
                    {translate('connectionError')}
                </ServerLabel>
            );

        case 'connectionBlocked':
            return (
                <ServerLabel intent="danger" icon="triangle-exclamation-mark-filled" to={to}>
                    {translate('connectionBlocked')}
                </ServerLabel>
            );

        case 'certificateError':
            return (
                <ServerLabel intent="danger" icon="triangle-exclamation-mark-filled" to={to}>
                    {translate('certificateError')}
                </ServerLabel>
            );

        case 'insecure':
            return (
                <ServerLabel intent="warning" icon="lock-open-cross" to={to}>
                    {translate('insecure')}
                </ServerLabel>
            );

        case 'outdated':
            return (
                <ServerLabel intent="warning" icon="triangle-exclamation-mark-filled" to={to}>
                    <Tooltip
                        title={translate('outdatedTooltip', {
                            version: server.productVersion?.version ?? '_',
                        })}
                    >
                        <Text>{translate('outdated')}</Text>
                    </Tooltip>
                </ServerLabel>
            );

        case 'unsupportedVersion':
            return (
                <ServerLabel intent="danger" icon="triangle-exclamation-mark-filled" to={to}>
                    <Tooltip
                        title={translate('unsupportedVersionTooltip', {
                            version: server.productVersion?.version ?? '_',
                        })}
                    >
                        <Text>{translate('unsupportedVersion')}</Text>
                    </Tooltip>
                </ServerLabel>
            );

        case 'active':
            return (
                <ServerLabel intent="success" icon="lock-closed-check" to={to}>
                    {translate('active')}
                </ServerLabel>
            );
    }

    // This prevents ESLint @typescript-eslint/consistent-return: Expected to return a value at the end of arrow function.
    // TODO (vvolodko): Remove the ESlint rule and add `"noImplicitReturns": true` to tsconfig.json.
    // See https://stackoverflow.com/a/75526496
    const _exhaustiveCheck: never = serverState;
    return _exhaustiveCheck;
};

const ServerStatus = ({ server, to, ...props }: ServerStatusProps) => {
    const translate = useTranslate('server-inventory.Servers.ServerStatus');
    const isShowSyncProgressHint =
        ServerViewModel.isReady(server) && server.active && server.nextSyncAt < DateTime.local();

    return (
        <div data-type="view-server-state" {...props}>
            <ServerStateLabel server={server} to={to} />
            <br />
            {isShowSyncProgressHint ? (
                <Hint data-type="sync-progress-text">{translate('synchronizationInProgress')}</Hint>
            ) : undefined}
        </div>
    );
};

export default ServerStatus;
