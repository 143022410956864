// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, ComponentType } from 'react';
import {
    useNavigate,
    // eslint-disable-next-line no-restricted-imports
    Routes,
    Route,
} from 'react-router-dom';

export type ClosableProps = {
    isOpen: boolean;
    onClose: () => void;
};

export type RouteBindingProps = {
    openPath: string;
    closePath: string;
};

type Closable<T> = Omit<T, keyof ClosableProps> & Partial<ClosableProps>;

const withRouteBinding = <T extends ClosableProps>(Component: ComponentType<T>) => {
    const Wrapper = ({ openPath, closePath, ...userProps }: RouteBindingProps & Closable<T>) => {
        const [closing, setClosing] = useState(false);
        const navigate = useNavigate();

        const handleClose = (): Promise<void> => {
            setClosing(true);
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    // We must retrieve a fresh location search value to avoid race conditions
                    // with multiple filters reset.
                    // window.location is only known source to do it right now.
                    navigate(`${closePath}${window.location.search}`);
                    setClosing(false);
                    resolve();
                }, 300);
            });
        };

        return (
            <Routes>
                <Route
                    path={openPath}
                    element={
                        <Component
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            {...(userProps as any)}
                            isOpen={!closing}
                            onClose={handleClose}
                        />
                    }
                />
            </Routes>
        );
    };
    Wrapper.displayName = `withRoute(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default withRouteBinding;
