// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './WidgetContent.module.css';
import { InfoResponse } from '@platform360/monitoring/web/api/monitoring';
import InfoAggregateColumn from './InfoAggregateColumn';
import Loader from './Loader';
import { Text, Columns } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { DateTime } from 'luxon';

type DynamicWidgetProps = {
    isLoading: boolean;
    hasError: boolean;
    updatedAt: number;
    info?: InfoResponse;
};

const WidgetContent = ({ isLoading, hasError, info, updatedAt }: DynamicWidgetProps) => {
    const translate = useTranslate('monitoring.HomePageWidget.DynamicWidget.WidgetContent');
    const formatDateTime = useFormatDateTime();
    const { monitoring } = useConfig();
    const lastUpdate = formatDateTime(DateTime.fromMillis(updatedAt), 'HH:mm');

    const url = (path: string) => concatUrl(path, monitoring.baseUrl);

    if (isLoading || !info || hasError) {
        return <Loader hasError={hasError} />;
    }

    return (
        <>
            <Columns className={styles.columns} vertical={false} gap="lg">
                <InfoAggregateColumn
                    title={translate('servers')}
                    href={url('dashboard/servers')}
                    {...info.servers}
                />
                <InfoAggregateColumn
                    title={translate('websites')}
                    href={url('dashboard/domains')}
                    {...info.websites}
                />
            </Columns>
            <Text intent="muted" fontSize="sm">
                {translate('lastUpdate', { lastUpdate })}
            </Text>
        </>
    );
};

export default WidgetContent;
