// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from '@platform360/licenses/web/WizardCommon/style.module.css';
import { Media, Text } from '@plesk/ui-library';
import Img from '@platform360/licenses/web/components/Img';
import License from '@platform360/licenses/web/units/extensions/types/License';

const LicenseInfo = ({ code, name, keyNumber }: Omit<License, 'id'>) => (
    <div className={styles.licenseInfo}>
        <Media className={styles.productMedia} title={name} image={<Img code={code} />}>
            <Text className={styles.keyNumber}>{keyNumber}</Text>
        </Media>
    </div>
);

export default LicenseInfo;
