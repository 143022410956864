// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const AddingServerButton = ({ ...props }) => {
    const translate = useTranslate('server-inventory.AddingServer.AddingServerButton');

    return (
        <Button icon="plus" intent="primary" component={Link} to="/si/servers/add" {...props}>
            {translate('title')}
        </Button>
    );
};
