// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'Questa e-mail è già registrata.',
        linkEmailMinimalInterval: 'I messaggi di azione collegati alle e-mail non possono essere inviati troppo frequentemente.',
        linkEmailUnknownError: 'Si è verificato un errore. Controlla la tua connessione internet o riprova più tardi.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Conferma l\'annullamento dell\'attivazione',
                description: 'Stai per annullare l\'attivazione della licenza del seguente prodotto: {product}. Puoi continuare l\'attivazione della licenza in un qualsiasi momento facendo di nuovo clic sul link. Se la licenza è stata acquistata per un altro account, accedi a tale account e fai clic sul link di attivazione.',
                cancelButton: 'Annulla',
                continueButton: 'Continua l\'attivazione',
            },
            MonitoringActivateLicense: {
                title: 'Conferma l\'attivazione della licenza di Monitoring',
                description: 'Conferma l\'attivazione della licenza di <b>{product}</b> per il tuo account 360 <mailto>{userEmail}</mailto>. La licenza può essere attivata su un solo account 360.',
                fixableErrorDescription: 'Si è verificato un errore durante l\'attivazione dell\'account di Monitoring. Riprova o invia una richiesta di supporto.',
                nonFixableErrorDescription: 'Si è verificato un errore durante l\'attivazione della licenza. Contatta il fornitore della licenza o invia una richiesta di supporto.',
                loaderText: 'È in corso la configurazione del tuo account 360 Monitoring',
                redirectText: 'Reindirizzamento a 360 Monitoring',
                confirmButton: 'Conferma',
                cancelButton: 'Annulla',
                retryButton: 'Riprova',
                gotItButton: 'OK',
                errors: {
                    linkError: 'Il link di attivazione non è valido.',
                    unknownError: 'Errore interno del servizio di attivazione.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'Errore durante l\'attivazione della licenza',
            description: 'Il link di attivazione è errato. Contatta il fornitore della licenza o invia una richiesta di supporto.',
            gotIt: 'OK',
        },
    },
};