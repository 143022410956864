// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './CardList.module.css';
import { ReactNode } from 'react';
import { Panel } from '@plesk/ui-library';
import classNames from 'classnames';

export type CardListProps = {
    children: ReactNode;
    className?: string;
};

export const CardListItem = ({ children, className }: CardListProps) => (
    <Panel className={classNames(styles.panel, className)}>{children}</Panel>
);

const CardList = ({ children, className }: CardListProps) => (
    <div className={classNames(styles.list, className)}>{children}</div>
);

export default CardList;
