// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Scope } from '@platform360/licenses/web/units/cleverbridge/constants/scope';
import { ReactNode } from 'react';

export const URL = 'https://store.plesk.com/1404/?scope=';

type LegalInfoLinkProps = {
    scope: Scope[keyof Scope];
    children: ReactNode;
};

const LegalInfoLink = ({ scope, children }: LegalInfoLinkProps) => (
    <a href={URL.concat(scope)} target={'_blank'} rel={'nofollow noopener noreferrer'}>
        {children}
    </a>
);

export default LegalInfoLink;
