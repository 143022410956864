// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Label } from '@plesk/ui-library';
import { IconName, IconProps } from '@plesk/ui-library/types/src/components/Icon';

type ServerLabelProps = {
    intent: 'inactive' | 'success' | 'warning' | 'danger' | 'info';
    icon?: IconName | IconProps | ReactElement;
    to?: string;
    children: ReactElement;
};

export const ServerLabel = ({ icon, intent, to, children }: ServerLabelProps) =>
    to ? (
        <Label view="outline" caps={false} icon={icon} intent={intent} component={Link} to={to}>
            {children}
        </Label>
    ) : (
        <Label view="outline" caps={false} icon={icon} intent={intent}>
            {children}
        </Label>
    );
