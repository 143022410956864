// Copyright 2024. WebPros International GmbH. All rights reserved.

/**
 * Known TLS certificate error codes.
 *
 * The values should not be changed because they are part of API.
 */
export const CertErrors = {
    CertAltNameInvalid: 'errTlsCertAltnameInvalid',
    CertExpired: 'certHasExpired',
    CertSelfSigned: 'depthZeroSelfSignedCert',
    CertSelfSignedInChain: 'selfSignedCertInChain',
} as const;

/**
 * Known TLS certificate error code.
 */
export type CertErrorCode = (typeof CertErrors)[keyof typeof CertErrors];

const certErrorCodes = Object.values(CertErrors) as string[];

export const isCertErrorCode = (code: string): code is CertErrorCode =>
    certErrorCodes.includes(code);
