// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ReactElement, useState } from 'react';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import { IgnoreDoNotProtectMessage } from '@platform360/security-dashboard/web/components/IgnoreDoNotProtectMessage';

type ProtectInstallationsPopoverProps = {
    onClick: (ignoreDoNotProtect: boolean) => void;
    target: ReactElement;
    selectedInstallationDisplayTitle?: string;
    selection: number[];
    onConfirmationShown: () => void;
    doNotProtectCount?: number;
};

const ProtectInstallationsPopover = ({
    onClick,
    onConfirmationShown,
    doNotProtectCount,
    selectedInstallationDisplayTitle,
    selection,
    target,
}: ProtectInstallationsPopoverProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.Toolbar.ProtectInstallationsPopover',
    );

    const [ignoreDoNotProtect, setIgnoreDoNotProtect] = useState(false);
    const renderPopoverDescription = () => {
        if (selection.length === 1 && selectedInstallationDisplayTitle) {
            return translate('protectInstallationSingleConfirmation', {
                selectedInstallationTitle: selectedInstallationDisplayTitle,
                providerLogo: <Patchstack />,
                whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
            });
        }

        return translate('protectInstallationsConfirmation', {
            selectedInstallationsCount: selection.length,
            providerLogo: <Patchstack />,
            whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
        });
    };

    return (
        <ConfirmationPopover
            onClick={() => onClick(ignoreDoNotProtect)}
            onShow={onConfirmationShown}
            target={target}
            actionButtonText={translate('confirmButton')}
            cancelButtonText={translate('cancelButton')}
            confirmationButtonIntent="primary"
        >
            <Paragraph>{renderPopoverDescription()}</Paragraph>
            {!!doNotProtectCount && (
                <IgnoreDoNotProtectMessage
                    doNotProtectCount={doNotProtectCount}
                    ignoreDoNotProtect={ignoreDoNotProtect}
                    onIgnoreDoNotProtectChange={setIgnoreDoNotProtect}
                />
            )}
        </ConfirmationPopover>
    );
};

export default ProtectInstallationsPopover;
