// Copyright 2024. WebPros International GmbH. All rights reserved.

import { omit } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useExternalRedirect } from '@platform360/libs/shared-web/helpers/useExternalRedirect';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useCustomVerify from '@platform360/web-installer/web/mutations/useCustomVerify';
import useCustomInstall from '@platform360/web-installer/web/mutations/useCustomInstall';
import useCpanelVerify from '@platform360/web-installer/web/mutations/useCpanelVerify';
import useCpanelInstall from '@platform360/web-installer/web/mutations/useCpanelInstall';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

import { concatUrl } from '@platform360/libs/common/concat-url';
import { AxiosError, isAxiosError } from 'axios';
import { useWebInstallerAnalyticsEvents } from '@platform360/web-installer/web/helpers/analytics';
import {
    CustomInstallRequest,
    CustomVerifyResponse,
} from '@platform360/web-installer/shared/api-contract';

type DpaRequiredError = {
    type: string;
    id: string;
    params: CustomInstallRequest;
};

const isDpaNotAcceptedError = (error: unknown): error is AxiosError<DpaRequiredError> =>
    isAxiosError(error) &&
    error.response?.status === 403 &&
    error.response.data.type === 'dpaRequired';

type VerifySuccessProps = CustomVerifyResponse;

type InstallationApiProps = {
    onVerifySuccess: ({ id, params }: VerifySuccessProps) => void;
    onVerifyError: () => void;
    onInstallSuccess: () => void;
    onInstallError: (err: unknown) => void;
};

const usePleskInstallationApi = ({
    onVerifySuccess,
    onVerifyError,
    onInstallSuccess,
    onInstallError,
}: InstallationApiProps): [
    ReturnType<typeof useCustomVerify>,
    ReturnType<typeof useCustomInstall>,
] => {
    const customVerifyData = useCustomVerify({
        onSuccess: onVerifySuccess,
        onError: onVerifyError,
    });
    const customInstallData = useCustomInstall({
        onSuccess: onInstallSuccess,
        onError: onInstallError,
    });

    return [customVerifyData, customInstallData];
};

const useCpanelInstallationApi = ({
    onVerifySuccess,
    onVerifyError,
    onInstallSuccess,
    onInstallError,
}: InstallationApiProps): [
    ReturnType<typeof useCpanelVerify>,
    ReturnType<typeof useCpanelInstall>,
] => {
    const customVerifyData = useCpanelVerify({
        onSuccess: onVerifySuccess,
        onError: onVerifyError,
    });
    const customInstallData = useCpanelInstall({
        onSuccess: onInstallSuccess,
        onError: onInstallError,
    });

    return [customVerifyData, customInstallData];
};

const useInstallationApi = (productType: 'plesk' | 'cpanel') => {
    const navigate = useNavigate();
    const { platform } = useConfig();
    const [searchParams] = useSearchParams();
    const { externalRedirect } = useExternalRedirect();
    const { isAuthorized } = useAuth();
    const [storageValue, setStorageValue] = useLocalStorage<Record<string, CustomInstallRequest>>(
        'install-plesk-form-data',
        {},
    );
    const analyticsEvents = useWebInstallerAnalyticsEvents();

    const onVerifySuccess = ({ id, params }: VerifySuccessProps) => {
        setStorageValue((storage) => ({
            ...storage,
            [id]: params,
        }));

        analyticsEvents.verificationSubmitSuccess();
        navigate(`/web-installer/email-confirmation?installId=${id}`);
    };
    const onVerifyError = () => {
        analyticsEvents.verificationSubmitError();
    };
    const onInstallSuccess = () => {
        const installId = searchParams.get('installId');

        if (installId) {
            setStorageValue((storageValue) => omit(storageValue, installId));
        }

        externalRedirect(concatUrl('/web-installer/auth-gateway', platform.baseUrl));
    };
    const onInstallError = (err: unknown) => {
        if (isDpaNotAcceptedError(err)) {
            // 1. Store data into local storage.
            const id = err.response?.data.id;
            const params = err.response?.data.params;
            if (id === undefined || params === undefined) {
                throw err;
            }

            setStorageValue((storage) => ({
                ...storage,
                [id]: params,
            }));

            // 2. Redirect to email confirmation page.
            navigate(`/web-installer/email-confirmation?installId=${id}`);
            return;
        }
        throw err;
    };

    const [pleskVerifyData, pleskInstallData] = usePleskInstallationApi({
        onVerifySuccess,
        onVerifyError,
        onInstallSuccess,
        onInstallError,
    });
    const [cpanelVerifyData, cpanelInstallData] = useCpanelInstallationApi({
        onVerifySuccess,
        onVerifyError,
        onInstallSuccess,
        onInstallError,
    });

    if (isAuthorized) {
        const installData = productType === 'plesk' ? pleskInstallData : cpanelInstallData;

        return {
            ...installData,
            storageValue,
            sendSubmitEventToAnalytics: () => analyticsEvents.installationSubmit(),
        };
    }

    const verifyData = productType === 'plesk' ? pleskVerifyData : cpanelVerifyData;

    return {
        ...verifyData,
        storageValue,
        sendSubmitEventToAnalytics: () => analyticsEvents.verificationSubmit(),
    };
};

export default useInstallationApi;
