// Copyright 2024. WebPros International GmbH. All rights reserved.

import { CustomInstallationStep } from './CustomInstallationStep';
import useCustomInstall from '@platform360/web-installer/web/mutations/useCustomInstall';
import useCpanelInstall from '@platform360/web-installer/web/mutations/useCpanelInstall';
import { FormValues } from '@platform360/web-installer/web/components/CustomInstallationForm';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useServersQuery from '@platform360/server-inventory/web/queries/useServersQuery';
import useGenHostname from '@platform360/web-installer/web/queries/useGenHostname';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { InstallationMethods } from '@platform360/web-installer/shared/installation-method';
import { ValidationError } from '@platform360/libs/shared-web/helpers/translateErrors';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';

type AddingServerWizardContainerProps = {
    productType: 'plesk' | 'cpanel';
    onClose: () => void;
    onBack: () => void;
};

const isIp = (value: string) => Boolean(value && /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.exec(value));

export const CustomInstallationStepContainer = ({
    productType,
    onBack,
    onClose,
    ...props
}: AddingServerWizardContainerProps) => {
    const queryClient = useQueryClient();
    const translate = useTranslate(
        'server-inventory.AddingServer.AddingServerWizard.CustomInstallationStep',
    );
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { successToast } = useToaster();
    const [host, setHost] = useState('');
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [submittedHost, setSubmittedHost] = useState('');

    const onInstallationSuccess = () => {
        void queryClient.invalidateQueries({
            queryKey: useServersQuery.getQueryKey(),
        });
        onClose();
        successToast(translate('successMessage', { host: submittedHost, productType }));
    };

    const { data: techDomain } = useGenHostname({
        variables: {
            ip: host,
            vendor: productType,
        },
        enabled: isIp(host),
    });

    const cpanelInstallData = useCpanelInstall({
        onSuccess: onInstallationSuccess,
    });

    const pleskInstallData = useCustomInstall({
        onSuccess: onInstallationSuccess,
    });

    const installationData = productType === 'plesk' ? pleskInstallData : cpanelInstallData;
    const { isPending: isLoading, error, mutate, reset } = installationData;

    const handleSubmit = ({
        login,
        port,
        key,
        updateOs,
        installationMethod,
        password,
        licenseType,
        licenseKey,
    }: FormValues) => {
        if (
            installationMethod === InstallationMethods.privateKey &&
            key &&
            key.length / 1024 > 30
        ) {
            reset();
            setErrors([
                {
                    category: 'web-installer',
                    path: 'key',
                    type: 'tooBigPrivateKeyError',
                    message: 'File too big',
                },
            ]);
            return;
        }

        setErrors([]);
        setSubmittedHost(host);
        analyticsEvents.serverInstallationFormSubmit({
            installationMethod,
            licenseType,
            updateOs: updateOs ?? false,
            controlPanel: productType,
        });
        mutate({
            login,
            host,
            port: port ? Number(port) : undefined,
            updateOs,
            installationMethod,
            ...(installationMethod === InstallationMethods.password ? { password } : { key }),
            licenseType,
            licenseKey,
            setHostname: isIp(host) ? techDomain : undefined,
        });
    };

    useEffectOnce(() => {
        analyticsEvents.serverInstallationFormOpen(productType);
    });

    return (
        <CustomInstallationStep
            isLoading={isLoading}
            errors={[...(getValidationErrors(error) ?? []), ...errors]}
            onSubmit={handleSubmit}
            onBack={onBack}
            onHostChange={setHost}
            techDomain={techDomain}
            submittedHost={submittedHost}
            productType={productType}
            {...props}
        />
    );
};
