// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { StatusMessage, TranslationProps } from '@plesk/ui-library';

type OperationStatusMessageProps = {
    children: TranslationProps;
    isError: boolean;
    onClose?: () => void;
};

const OperationStatusMessage = ({ children, isError, onClose }: OperationStatusMessageProps) => (
    <StatusMessage
        intent={isError ? 'danger' : 'success'}
        // @ts-expect-error
        onClose={onClose}
    >
        {children}
    </StatusMessage>
);

export type OperationStatus = {
    isError: boolean;
    message: TranslationProps;
};

export const useOperationStatus = () => {
    const [operationStatus, setOperationStatus] = useState<OperationStatus | null>(null);

    const statusMessage = operationStatus && (
        <OperationStatusMessage
            isError={operationStatus.isError}
            onClose={() => setOperationStatus(null)}
        >
            {operationStatus.message}
        </OperationStatusMessage>
    );

    return {
        statusMessage,
        setOperationStatus,
    };
};
