// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'react';
import { AdjustActions } from '@platform360/licenses/web/units/adjust/reducer/reducer';
import { calculate } from '@platform360/licenses/web/units/adjust/actions/calculate';
import { calculateSummaryCost } from '@platform360/licenses/web/units/adjust/actions/calculateSummaryCost';

let timeoutID: NodeJS.Timeout | null = null;

export const delayedCalculatingSummaryCost =
    (delay = 1000) =>
    (dispatch: Dispatch<AdjustActions>): void => {
        dispatch(calculate());

        if (timeoutID) {
            clearTimeout(timeoutID);
        }

        timeoutID = setTimeout(() => {
            // @ts-expect-error TS2345
            dispatch(calculateSummaryCost());
            timeoutID = null;
        }, delay);
    };
