// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { getServerSettings } from '@platform360/security-dashboard/web/api/servers';
import { useQuery } from '@tanstack/react-query';

export type UseServerSettingsQueryVariables = {
    serverId: number;
};

export type UseServerSettingsQueryData = PromiseValue<ReturnType<typeof getServerSettings>>;

const useServerSettingsQuery = createQuery<
    UseServerSettingsQueryVariables,
    UseServerSettingsQueryData
>({
    queryName: 'security-dashboard/useServerSettingsQuery',
    fetcher: getServerSettings,
    useQuery,
});

export default useServerSettingsQuery;
