// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Hint, Paragraph } from '@plesk/ui-library';
import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import image from './image.png';
import ConnectServersButton from '@platform360/security-dashboard/web/components/ConnectServersButton';
import { useDemoServerAddMutation } from '@platform360/security-dashboard/web/mutations';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import styles from './Onboarding.module.css';
import ConfirmationPopover, {
    ConfirmationPopoverProps,
} from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const Onboarding = () => {
    const translate = useTranslate('security-dashboard.Onboarding');
    const navigate = useNavigate();
    const toaster = useToaster();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const { mutate, isPending: isLoading } = useDemoServerAddMutation({
        onSuccess: () => {
            toaster.successToast(translate('demoServerWasAddedSuccessfully'));
        },
    });

    useEffect(() => {
        const scrollToTop = () => window.scrollTo(0, 0);
        scrollToTop();
        return scrollToTop;
    }, []);

    const handleDemoServerAdd = () => {
        analyticsEvents.wpConnectDemoServerClick();
        mutate();
    };

    return (
        <ListEmptyView
            title={translate('title')}
            description={translate('description')}
            image={image}
            actions={[
                translate('connectServers', {
                    addServerButton: (parts) => (
                        <ConnectServersButton key="connect-button" buttonText={parts} />
                    ),
                    delimiter: (parts) => (
                        <span key="delimiter" className={styles.delimiter}>
                            {parts}
                        </span>
                    ),
                    addDemoServerButton: (parts) => (
                        <ConnectDemoServerPopover
                            onShow={() => {
                                navigate('/security-dashboard/servers', { replace: true });
                                analyticsEvents.wpConnectDemoServerPopoverShown();
                            }}
                            onConfirm={handleDemoServerAdd}
                        >
                            <Button state={isLoading ? 'loading' : undefined}>{parts}</Button>
                        </ConnectDemoServerPopover>
                    ),
                }),
                <div key="hint" className={styles.hint}>
                    <Hint>{translate('hint')}</Hint>
                </div>,
            ]}
        />
    );
};

type ConnectDemoServerPopoverProps = {
    children: ConfirmationPopoverProps['target'];
    onShow: ConfirmationPopoverProps['onShow'];
    onConfirm: ConfirmationPopoverProps['onClick'];
};

const ConnectDemoServerPopover = ({
    children,
    onShow,
    onConfirm,
}: ConnectDemoServerPopoverProps) => {
    const translate = useTranslate('security-dashboard.Onboarding.demoServerPopover');
    return (
        <ConfirmationPopover
            onShow={onShow}
            key="demo-button"
            onClick={onConfirm}
            target={children}
            actionButtonText={translate('connectBtn')}
            confirmationButtonIntent="primary"
        >
            <Paragraph>{translate('description')}</Paragraph>
        </ConfirmationPopover>
    );
};

export default Onboarding;
