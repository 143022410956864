// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph, PopoverProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Installation } from '@platform360/security-dashboard/web/types';
import { ReactElement } from 'react';

type ComponentWithLoader = ({ isLoading }: { isLoading: boolean }) => ReactElement;

export type DetachInstallationsButtonProps = {
    installations: Installation[];
    target: ComponentWithLoader | ReactElement<{ onClick: () => void }>;
    placement?: PopoverProps['placement'];
    onConfirmationShown?: () => void;
    onConfirm: (installationsIds: number[]) => void;
    isLoading: boolean;
};

export const DetachInstallationsButton = ({
    installations,
    target,
    onConfirmationShown,
    onConfirm,
    isLoading,
    ...props
}: DetachInstallationsButtonProps) => {
    const translate = useTranslate('security-dashboard.Installations.DetachInstallationsButton');

    const handleConfirmationClick = () => {
        onConfirm(installations.map((i) => i.id));
        if (typeof target !== 'function') {
            target.props.onClick();
        }
    };

    const renderPopoverDescription = () => {
        if (installations.length === 1) {
            return translate('detachSingleInstallationConfirmation', {
                selectedInstallationTitle: installations[0]?.displayTitle,
            });
        }

        return translate('detachSeveralInstallationsConfirmation', {
            selectedInstallationsCount: installations.length,
        });
    };

    return (
        <ConfirmationPopover
            onClick={handleConfirmationClick}
            onShow={onConfirmationShown}
            target={typeof target === 'function' ? target({ isLoading }) : target}
            actionButtonText={translate('detach')}
            {...props}
        >
            <Paragraph>{renderPopoverDescription()}</Paragraph>
        </ConfirmationPopover>
    );
};

export default DetachInstallationsButton;
