// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { refreshServer } from '@platform360/security-dashboard/web/api/servers';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import { Server } from '@platform360/security-dashboard/web/types';

type ScannedServer = {
    serverId: number;
    displayTitle: string;
    executed?: boolean;
};

const useRefreshMutation = () => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useRefreshMutation');
    const { errorToast } = useToaster();

    return useMutation({
        mutationFn: async (servers: Server[]) => {
            try {
                await refreshServer(servers.map(({ id }) => id));
                return servers.map<ScannedServer>(
                    ({ id, displayTitle }): ScannedServer => ({
                        serverId: id,
                        displayTitle,
                        executed: true,
                    }),
                );
            } catch {
                return servers.map<ScannedServer>(({ id, displayTitle }) => ({
                    serverId: id,
                    displayTitle,
                    executed: false,
                }));
            }
        },
        meta: {
            defaultErrorHandler: false,
        },
        onSuccess: async (results: ScannedServer[]) => {
            const successServers = results.filter(({ executed }) => executed);
            const successServersCount = successServers.length;
            const failedServersCount = results.length - successServersCount;
            await Promise.all([
                queryClient.cancelQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
            ]);

            if (failedServersCount > 1) {
                errorToast(
                    translate('notifySeveralServersFail', {
                        selectedServersCount: failedServersCount,
                    }),
                );
                return;
            }

            const failedServer = results.find(({ executed }) => !executed);

            if (failedServer) {
                errorToast(
                    translate('notifySingleServerFail', {
                        selectedServerTitle: failedServer.displayTitle,
                    }),
                );
            }
        },
    });
};

export default useRefreshMutation;
