// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Das Auth-Token fehlt oder ist ungültig.',
        invitationNotExist: 'Einladung existiert nicht.',
        invitationExpired: 'Die Einladung ist abgelaufen.',
        invitationConflict: 'Eingeladener Benutzer ist bereits registriert.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'Ihr Passwort wurde geändert.',
    },
    ChangePasswordForm: {
        resetTitle: 'Neues Passwort',
        setTitle: 'Passwort festlegen',
        passwordLabel: 'Passwort:',
        submitButton: 'Bestätigen',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-Mail zum Zurücksetzen des Passworts gesendet',
        successSendResetText: 'Wir haben eine E-Mail mit dem Link zum Zurücksetzen des Passworts an {email} gesendet. Klicken Sie auf den Link in der E-Mail, um sich in Plesk 360 anzumelden und ein neues Passwort festzulegen.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Abgelaufener Link',
        resetText: 'Der Link zum Zurücksetzen des Passworts ist abgelaufen.',
        setText: 'Der Link zum Einrichten des Passworts ist nicht mehr gültig. Aus Sicherheitsgründen ist der Link nur 4 Stunden lang gültig. Falls Sie in diesem Zeitraum kein neues Passwort festgelegt haben, geben Sie Ihre E-Mail-Adresse unten erneut ein und legen Sie das Passwort über die Vorgehensweise zur Passwortänderung fest.',
        emailLabel: 'E-Mail-Adresse:',
        submitButton: 'Senden',
        bottomText: 'Sie erinnern sich an das Passwort? <a>Melden Sie sich jetzt an.</a>',
    },
};