// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { Text } from '@plesk/ui-library';
import { License } from '@platform360/security-dashboard/web/types';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import style from './LicenseDescription.module.css';
import { CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';
import RetailLicenseInfo from '@platform360/security-dashboard/web/components/LicensePage/RetailLicenseInfo';

type LicenseDescriptionProps = {
    license: License | null;
    trialJustStarted: boolean;
};
const LicenseDescription = ({ license, trialJustStarted }: LicenseDescriptionProps) => {
    const translate = useTranslate('security-dashboard.LicensePage.LicenseDescription');
    const formatDateTime = useFormatDateTime();

    const isRetailNotTrial = license?.customerType === CustomerTypes.retail && !license.trial;

    const getText = () => {
        if (license === null || license.status === 'terminated') {
            return translate('noLicense');
        }

        if (trialJustStarted) {
            return translate('trialJustStarted');
        }

        if (license.trial) {
            return translate('trial', { sites: license.sitesLimitHard });
        }

        if (license.sitesLimitHard === null) {
            return translate('unlimited', { sites: license.sitesLimitSoft });
        }

        return translate('limit', { sites: license.sitesLimitHard });
    };

    const getExpirationText = (license: License) => {
        if (license.trial && trialJustStarted) {
            return null;
        }

        if (license.trial) {
            // TODO: add code here if we want to show the expiration date for trial licenses
            return null;
        }

        if (license.expiredAt < DateTime.now()) {
            return (
                <Text intent="danger" fontSize="sm">
                    {translate('expirationDate', {
                        date: formatDateTime(license.expiredAt, 'D'),
                    })}
                </Text>
            );
        }

        if (isRetailNotTrial) return null;

        return (
            <Text intent="muted" fontSize="sm">
                {translate('nextUpdate', {
                    date: formatDateTime(license.nextUpdateAt, 'D'),
                })}
            </Text>
        );
    };

    return (
        <div className={style.description}>
            <Text fontSize="sm">
                {getText()}
                {license?.customerType === CustomerTypes.vps && (
                    <b>
                        {' '}
                        {license.status === 'terminated'
                            ? translate('contactResellerToPurchase')
                            : translate('contactResellerToUpdate')}
                    </b>
                )}
            </Text>
            {license !== null && license.status !== 'terminated' && (
                <div>
                    {license.licenseKey !== null && (
                        <Text intent="muted" fontSize="sm">
                            {translate('licenseKey', {
                                number: license.licenseKey,
                            })}
                            <br />
                        </Text>
                    )}
                    {isRetailNotTrial && (
                        <div>
                            <RetailLicenseInfo />
                        </div>
                    )}
                    {getExpirationText(license)}
                </div>
            )}
        </div>
    );
};

export default LicenseDescription;
