// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useMonitoringServerSetup from '@platform360/monitoring/web/mutations/useMonitoringServerSetup';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import MonitoringServerSetup from '@platform360/monitoring/web/components/MonitoringServerSetup/MonitoringServerSetup';

const MonitoringServerSetupContainer = () => {
    const { serverGuid } = useParams<{ serverGuid: string }>();
    const { email, monitoring } = useCurrentUser();

    const [searchParams] = useSearchParams();
    const address = String(searchParams.get('address'));
    const monitoringUserId = searchParams.get('monitoringUserId') ?? undefined;
    const monitoringServerId = searchParams.get('monitoringServerId') ?? undefined;
    const navigate = useNavigate();

    const {
        mutate: setupMonitoring,
        isPending: isLoading,
        isError,
        error,
    } = useMonitoringServerSetup({
        serverGuid: serverGuid || '',
        monitoringUserId,
        monitoringServerId,
        onSuccess: ({ redirectUrl }) => {
            const url = new URL(redirectUrl);
            window.location.href = url.href;
        },
    });

    const handleDeny = () => navigate('/');

    return (
        <MonitoringServerSetup
            hasError={isError}
            error={error}
            isLoading={isLoading}
            address={address}
            email={email}
            onConfirm={setupMonitoring}
            onDeny={handleDeny}
            isMonitoringActive={!!monitoring?.userId}
        />
    );
};

export default MonitoringServerSetupContainer;
