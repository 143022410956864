// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: '認証トークンがないか、無効です。',
        invitationNotExist: '招待が存在しません。',
        invitationExpired: 'この招待は期限が切れています。',
        invitationConflict: '招待されたユーザは既に登録済みです。',
    },
    ChangePassword: {
        passwordChangedSuccess: 'パスワードが変更されました。',
    },
    ChangePasswordForm: {
        resetTitle: '新規パスワード',
        setTitle: 'パスワードを設定',
        passwordLabel: 'パスワード：',
        submitButton: '確認',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'パスワードリセット用のメールが送信されました',
        successSendResetText: 'パスワードリセットリンクを {email} にメールで送信しました。このリンクをクリックして Plesk 360 にログインし、新しいパスワードを設定してください。',
    },
    ChangePasswordInvalidTokenForm: {
        title: '期限切れのリンク',
        resetText: 'パスワードリセットリンクは期限が切れています。',
        setText: 'パスワードをリセットするためのリンクが有効ではなくなりました。セキュリティのため、パスワード設定用のリンクの有効期間は 4 時間となっています。このリンクを使用してパスワードを設定する時間がなかった場合は、以下にメールアドレスをもう一度入力して、パスワードの変更手続きに従ってパスワードを設定することができます。',
        emailLabel: 'メールアドレス：',
        submitButton: '送信',
        bottomText: 'パスワードを覚えていれば、<a>今すぐログイン</a>できます。',
    },
};