// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Paragraph } from '@plesk/ui-library';
import { ReactElement } from 'react';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

type UpdateConfirmationProps = {
    onClick: () => void;
    onShow: () => void;
    target: ReactElement;
    component: InstallationComponent;
};

const UpdateConfirmation = ({ onClick, onShow, target, component }: UpdateConfirmationProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.InstallationSummary.UpdateConfirmation',
    );

    return (
        <ConfirmationPopover
            onClick={onClick}
            onShow={onShow}
            target={target}
            actionButtonText={translate('continueButton')}
            cancelButtonText={translate('cancelButton')}
            confirmationButtonIntent={'primary'}
        >
            <Paragraph>
                {translate('description', {
                    component,
                })}
            </Paragraph>
        </ConfirmationPopover>
    );
};

export default UpdateConfirmation;
