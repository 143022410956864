// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import Loading from '@platform360/libs/shared-web/components/Loading';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { UpdateSettingsOptions } from '@platform360/security-dashboard/shared/update-settings-option';
import { SettingsResponse } from '@platform360/security-dashboard/web/api/settings';
import UpdateSettingsCustomizedCheckbox from '@platform360/security-dashboard/web/components/UpdateSettingsCustomizedCheckbox';
import { useSaveSettingsMutation } from '@platform360/security-dashboard/web/mutations';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import UpdatesTab from '@platform360/security-dashboard/web/components/UpdatesTab';
import { Button, Form, Heading, setIn } from '@plesk/ui-library';
import { useSettingsQuery } from '@platform360/security-dashboard/web/queries';
import QuickStartStep, { QuickStartStepCommonProps } from '../QuickStartStep';
import { Section } from '../Section';

import styles from './UpdatesStep.module.css';

type UpdatesStepProps = QuickStartStepCommonProps;

type FormValues = Pick<SettingsResponse, 'updates'>;
type PartialFormValues = {
    updates: Partial<FormValues['updates']>;
};

const defaultValues: PartialFormValues = { updates: {} };

const defaultSettings: Pick<SettingsResponse, 'updates'> = Object.freeze({
    updates: {
        core: UpdateSettingsOptions.notSet,
        plugins: UpdateSettingsOptions.notSet,
        themes: UpdateSettingsOptions.notSet,
        safeUpdate: SafeUpdateOptions.notSet,
    },
});

export const UpdatesStep = ({ onSubmit, ...props }: UpdatesStepProps) => {
    const translate = useTranslate('security-dashboard.QuickStartDialog.UpdatesStep');
    const initialValuesRef = useRef(defaultSettings);
    const [formValues, setFormValues] = useState<PartialFormValues>(defaultValues);
    const [overrideDescendants, setOverrideDescendants] = useState(false);

    const { data: settings, isLoading } = useSettingsQuery();

    const { mutate, isPending: isUpdating } = useSaveSettingsMutation({
        onSuccess: () => {
            onSubmit?.();
        },
    });

    useEffect(() => {
        if (settings) {
            initialValuesRef.current = settings;
        }
    }, [settings]);

    const handleFieldChange = (key: string, value: FormValues[keyof FormValues]) =>
        setFormValues(setIn(formValues, key, value));

    const values = {
        updates: {
            ...(settings || defaultSettings).updates,
            ...formValues.updates,
        },
    };

    const saveValues = () => {
        const initialValues = initialValuesRef.current;
        const isDirty = overrideDescendants || !isEqual(initialValues.updates, values.updates);

        if (!isDirty) {
            onSubmit?.();
            return;
        }

        mutate({ updates: { ...values.updates, overrideDescendants } });
    };

    return (
        <QuickStartStep
            {...props}
            submitButton={
                <Button
                    size="md"
                    onClick={saveValues}
                    intent="primary"
                    arrow="backward"
                    state={isUpdating ? 'loading' : undefined}
                >
                    {translate('next')}
                </Button>
            }
        >
            {isLoading ? (
                <Loading />
            ) : (
                <Form
                    submitButton={false}
                    applyButton={false}
                    cancelButton={false}
                    onFieldChange={handleFieldChange}
                    values={values}
                    vertical
                    footerClassName={styles.footerHidden}
                >
                    <Section icon="arrow-up-circle" title={translate('updateSettings')}>
                        {translate('updateSettingsText')}
                    </Section>

                    <UpdatesTab
                        simpleSafeUpdates
                        formFieldClassName={styles.formField}
                        safeUpdatesDescription={
                            <>
                                <Heading level={3} className={styles.heading}>
                                    {translate('safeUpdates')}
                                </Heading>
                                <Section icon="backup2" title={translate('backup')}>
                                    {translate('backupText')}
                                </Section>
                            </>
                        }
                    />

                    <Heading level={3} className={styles.heading}>
                        {translate('customizedSettings')}
                    </Heading>

                    <UpdateSettingsCustomizedCheckbox
                        checked={overrideDescendants}
                        onChange={setOverrideDescendants}
                        className={styles.customizedCheckbox}
                    />
                </Form>
            )}
        </QuickStartStep>
    );
};
