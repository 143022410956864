// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Icon } from '@plesk/ui-library';
import { LICENSE_ICONS_URL } from '@platform360/licenses/web/constants';
import onErrorHandler from './onErrorHandler';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import styles from './Img.module.css';

type ImgProps = {
    code: string;
};

/**
 * ATTENTION! it's not Image in literal sense, it's Icon whom returned a functionally cropped Image
 * @param code
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
const Img = ({ code }: ImgProps) => {
    const [src, setSrc] = useState(`${LICENSE_ICONS_URL}${code}.png`);

    useEffectOnce(onErrorHandler(src, setSrc));

    return <Icon size={'64'} src={src} className={styles.img} />;
};

export default Img;
