// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { connect } from 'react-redux';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { Drawer, FormFieldText, Paragraph, ListActions, ListAction, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import List from '@platform360/libs/shared-web/components/List';
import { sendVerification } from '@platform360/licenses/web/store/linkedEmails';
import { ValidationError as CommonValidationError } from '@platform360/libs/shared-web/helpers/errors';
import GeneralValidationErrors from '@platform360/libs/shared-web/components/GeneralValidationErrors';
import RetryTimer, { formatSeconds } from '@platform360/libs/shared-web/components/RetryTimer';
import classNames from 'classnames';
import styles from './AddEmails.module.css';
import {
    useTranslateValidationErrors,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';

type Values = {
    emails: string[];
};

type Errors = ValidationError[];

type Result = {
    data: Values;
    errors: null;
};

type AddEmailsProps = {
    isOpen: boolean;
    onClose: () => void;
    onVerify: (values: string[]) => Promise<Result>;
};

const AddEmails = ({ isOpen, onClose, onVerify }: AddEmailsProps) => {
    const analyticsEvents = useLicensesAnalyticsEvents();
    const [step, setStep] = useState(1);
    const translate = useTranslate('Keys.LinkedEmails.AddEmails');
    const translateValidationErrors = useTranslateValidationErrors();
    const [values, setValues] = useState({
        emails: [''],
    });
    const [errors, setErrors] = useState<Errors>([]);
    const [formState, setFormState] = useState<'submit' | undefined>();
    const [addMoreButtonDisabled, setAddMoreButtonDisabled] = useState(false);
    const [verifications, setVerifications] = useState<string[]>([]);
    const verify = async (emails: string[]) => {
        try {
            setErrors([]);
            setVerifications(emails);
            await onVerify(emails);
            return true;
        } catch (e) {
            if (e instanceof CommonValidationError) {
                setErrors(e.data);
                return false;
            }

            setErrors([
                {
                    path: 'general',
                    type: 'linkEmailUnknownError',
                    category: 'licenses',
                    message: e.message,
                },
            ]);

            throw e;
        }
    };
    const handleSubmit = async (values: { emails: (string | undefined)[] }) => {
        const nextValues: Values = {
            emails: values.emails.filter((v, i) => (i === 0 ? true : v)).map((v) => v ?? ''),
        };
        setFormState('submit');
        setValues(nextValues);
        try {
            const isSuccess = await verify(nextValues.emails);
            if (isSuccess) {
                setStep(2);
            }
        } finally {
            setFormState(undefined);
        }
    };
    const handleVerify = (email: string) => async () => {
        await verify([email]);
    };

    useEffectOnce(() => {
        if (isOpen) {
            analyticsEvents.licensesLinkedEmailsLinkEmailsFormOpened();
        }
    });

    const generalErrors = errors.filter((e) => e.path === 'general');
    const formErrors = errors.filter((e) => e.path !== 'general');

    const form =
        step === 1
            ? {
                  applyButton: false,
                  submitButton: {
                      children: translate('stepOne.submitButton'),
                      'data-type': 'step-one-submit-button',
                  },
                  values,
                  errors: translateValidationErrors(
                      formErrors.map((err: ValidationError) => ({
                          ...err,
                          path: err.path.replace('.email', ''),
                      })),
                  ),
                  onSubmit: handleSubmit,
                  state: formState,
              }
            : {
                  applyButton: false,
                  submitButton: {
                      children: translate('stepTwo.submitButton'),
                      'data-type': 'step-two-submit-button',
                  },
                  cancelButton: false,
                  onSubmit: onClose,
              };
    const children =
        step === 1 ? (
            <FormFieldText
                className={classNames({
                    [styles.addMoreButtonDisabled ?? '']: addMoreButtonDisabled,
                })}
                name="emails"
                label={translate('stepOne.emailsLabel')}
                vertical
                size="lg"
                onChange={(emails: string[]) => {
                    setAddMoreButtonDisabled(emails.length >= 5);
                }}
                multi
                onRemoveRow={() => {
                    // todo: keep errors after remove an row
                    setErrors([]);
                }}
            />
        ) : (
            <>
                <Paragraph>{translate('stepTwo.description')}</Paragraph>
                <List<{ email: string }>
                    columns={[
                        { key: 'email', type: 'title', title: translate('stepTwo.emailColumn') },
                        {
                            key: 'timer',
                            align: 'right',
                            render: ({ email }) => {
                                if (verifications.includes(email)) {
                                    return (
                                        <RetryTimer
                                            onTimeEnd={() => {
                                                setVerifications((verifications) =>
                                                    verifications.filter((v) => v !== email),
                                                );
                                            }}
                                        >
                                            {({ seconds }: { seconds: number }) => (
                                                <Text intent="muted">{formatSeconds(seconds)}</Text>
                                            )}
                                        </RetryTimer>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            key: 'actions',
                            type: 'actions',
                            width: '1%',
                            render: ({ email }) => (
                                <ListActions>
                                    <ListAction
                                        primary
                                        icon="refresh"
                                        state={
                                            verifications.includes(email) ? 'loading' : undefined
                                        }
                                        onClick={handleVerify(email)}
                                        disabled={verifications.length > 0}
                                        data-type="resend-button"
                                    >
                                        {verifications.includes(email)
                                            ? null
                                            : translate('stepTwo.resendButton')}
                                    </ListAction>
                                </ListActions>
                            ),
                        },
                    ]}
                    data={values.emails.map((email) => ({ key: email, email }))}
                    rowKey="key"
                />
            </>
        );

    return (
        <Drawer
            isOpen={isOpen}
            title={step === 1 ? translate('stepOne.title') : translate('stepTwo.title')}
            form={form}
            onClose={onClose}
            size="xs"
            data-type="add-emails-drawer"
        >
            <GeneralValidationErrors errors={generalErrors} />
            {children}
        </Drawer>
    );
};

const mapDispatchToProps = {
    onVerify: sendVerification,
};

export { AddEmails };
export default connect(null, mapDispatchToProps)(AddEmails);
