// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, Tooltip } from '@plesk/ui-library';
import { ComponentProps } from 'react';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';

export type SiteHealthStatusProps = ComponentProps<typeof Label<typeof DrawerLink>> & {
    tooltip?: string | null;
    to: string;
};

export const SiteHealthStatus = ({ tooltip, ...labelProps }: SiteHealthStatusProps) => {
    const label = <Label size="lg" component={DrawerLink} view="light" {...labelProps} />;

    if (tooltip) {
        return <Tooltip title={tooltip}>{label}</Tooltip>;
    }

    return label;
};
