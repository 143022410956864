// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { updateSettingsLevelValues, isUpdateSettingsLevel, UpdateSettingsLevels } = makeEnum(
    'UpdateSettingsLevel',
    // Global autoupdate settings level.
    'global',
    // Server autoupdate settings level.
    'server',
);

export type UpdateSettingsLevel = UnionOf<typeof updateSettingsLevelValues>;
