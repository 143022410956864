// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'react';
import { AdjustActions } from '@platform360/licenses/web/units/adjust/reducer/reducer';
import doRequest from '@platform360/licenses/web/api/doRequest';
import { Properties } from '@platform360/licenses/web/api/ka/responseTypes/Property';
import getAll from '@platform360/licenses/web/api/ka/keys/properties/getAll';
import { NEUTRAL } from '@platform360/licenses/web/units/adjust/constants/productState';
import { NewValuesOfProducts } from '@platform360/licenses/web/units/adjust/reducer/initialState';
import { calculateSummaryCost } from '@platform360/licenses/web/units/adjust/actions/calculateSummaryCost';
import {
    failedInitialization,
    initialization,
    initialized,
} from '@platform360/licenses/web/units/adjust/actions/initialization';
import extensionPending from '@platform360/licenses/web/api/ka/extensions/extensionPending';

export const onInit =
    (licenseId: number, subscriptionId: string) =>
    async (dispatch: Dispatch<AdjustActions>): Promise<void> => {
        dispatch(initialization(licenseId));

        try {
            const [response, isAdjustPending] = await Promise.all([
                doRequest<Properties>(getAll(licenseId)),
                doRequest<boolean>(extensionPending(licenseId)),
            ]);

            dispatch(
                initialized({
                    licenseId,
                    subscriptionId,
                    isAdjustPending,
                    propertiesOfLicense: response,
                    propertiesForChange: response.keyProperties,
                    newValues: response.keyProperties.reduce<Record<number, NewValuesOfProducts>>(
                        (curr, next) => {
                            curr[Number(next.productId)] = {
                                quantity: Number(next.currentValue),
                                state: NEUTRAL,
                            };

                            return curr;
                        },
                        {},
                    ),
                }),
            );

            if (!isAdjustPending) {
                // @ts-expect-error TS2345
                dispatch(calculateSummaryCost());
            }
        } catch {
            dispatch(failedInitialization());
        }
    };
