// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import useApiDefaultErrorHandler from '@platform360/libs/shared-web/helpers/useApiDefaultErrorHandler';
import { UnprocessableInstallationsDialogData } from '@platform360/security-dashboard/web/components/UnprocessableInstallationsDialog';
import { getCountOfAffectedSitesByIds } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { VulnerabilityInstallationOperationType } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';

export const useUnprocessableInstallationsDialog = () => {
    const handleError = useApiDefaultErrorHandler();
    const [data, setData] = useState<UnprocessableInstallationsDialogData | null>(null);

    const dialogProps = {
        isOpen: !!data,
        onClose: () => setData(null),
        data,
    };

    const { data: license, isLoading: isLicenseLoading } = useLicenseQuery();

    const handleLicenseTerminated = () => {
        const isLicenseTerminated = !isLicenseLoading && license?.status === 'terminated';
        if (!isLicenseTerminated) return;
        setData({ notOperable: 0, operableNotManaged: 0 });
    };

    const handleUnprocessableInstallations = async (
        vulnerabilitiesIds: string[],
        operation: VulnerabilityInstallationOperationType,
    ) => {
        const variables = { vulnerabilitiesIds, operation };
        try {
            const { operableNotManaged } = await getCountOfAffectedSitesByIds({ variables });
            if (operableNotManaged > 0) {
                setData({ notOperable: 0, operableNotManaged });
            }
        } catch (error) {
            handleError(error);
        }
    };

    return [dialogProps, handleUnprocessableInstallations, handleLicenseTerminated] as const;
};
