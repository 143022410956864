// Copyright 2024. WebPros International GmbH. All rights reserved.

import { lightsailInstall } from '@platform360/web-installer/web/api/web-installer';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useMutation } from '@tanstack/react-query';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

export type UseLightsailInstallOptions = {
    onSuccess: (id: string) => void;
};

const useLightsailInstall = ({ onSuccess }: UseLightsailInstallOptions) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { error, ...rest } = useMutation({
        mutationFn: lightsailInstall,
        onSuccess: ({ id }) => onSuccess(id),
    });

    return { ...rest, errors: translateValidationErrors(getValidationErrors(error)) };
};

export default useLightsailInstall;
