// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { getInstallationSettings } from '@platform360/security-dashboard/web/api/installations';
import { useQuery } from '@tanstack/react-query';

export type UseInstallationSettingsQueryVariables = {
    installationId: number;
};

export type UseInstallationSettingsQueryData = PromiseValue<
    ReturnType<typeof getInstallationSettings>
>;

const useInstallationSettingsQuery = createQuery<
    UseInstallationSettingsQueryVariables,
    UseInstallationSettingsQueryData
>({
    queryName: 'security-dashboard/useInstallationSettingsQuery',
    fetcher: getInstallationSettings,
    useQuery,
});

export default useInstallationSettingsQuery;
