// Copyright 2024. WebPros International GmbH. All rights reserved.

import { LoginRedirect } from '@platform360/auth/web/components/PrivateRoutes/PrivateRoutes';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

const HomePage = () => {
    const { isAuthorized } = useAuth();
    const {
        homePage: { isPrivate, component },
    } = useApplicationSettings();

    if (isPrivate && !isAuthorized) {
        return <LoginRedirect />;
    }

    return component;
};

export default HomePage;
