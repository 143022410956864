// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { getServer } from '@platform360/security-dashboard/web/api/servers';
import { useQuery } from '@tanstack/react-query';

export type UseServerQueryVariables = {
    serverId: number;
};

export type UseServerQueryData = PromiseValue<ReturnType<typeof getServer>>;

const useServerQuery = createQuery<UseServerQueryVariables, UseServerQueryData>({
    queryName: 'security-dashboard/useServerQuery',
    fetcher: getServer,
    useQuery,
});

export default useServerQuery;
