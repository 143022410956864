// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { ReactNode } from 'react';
import { Button, Paragraph, TranslationProps } from '@plesk/ui-library';

export type CancelConfirmationLocaleProp = {
    cancelButton: TranslationProps;
    continueButton: TranslationProps;
    description: TranslationProps;
};

type CancelConfirmationProps = {
    onClick: () => void;
    buttonText: string | ReactNode[];
    product: string;
    locale: CancelConfirmationLocaleProp;
};

const CancelConfirmation = ({ onClick, buttonText, locale }: CancelConfirmationProps) => (
    <ConfirmationPopover
        onClick={onClick}
        target={
            <Button icon="remove" size="lg" data-type="cancel-button">
                {buttonText}
            </Button>
        }
        actionButtonText={locale.cancelButton}
        cancelButtonText={locale.continueButton}
    >
        <Paragraph>{locale.description}</Paragraph>
    </ConfirmationPopover>
);

export default CancelConfirmation;
