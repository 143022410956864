// Copyright 2024. WebPros International GmbH. All rights reserved.

import merge from 'lodash/merge';
import { LocaleCode, Locales, Locale } from '@platform360/libs/shared-web/locale/types';

export const findLocaleByCode = (
    localeCode: LocaleCode,
    locales: Locales,
    fallbackLocaleCode: LocaleCode,
): Locale =>
    merge(
        {},
        locales[fallbackLocaleCode],
        localeCode === fallbackLocaleCode ? {} : locales[localeCode],
    );
