// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, useState } from 'react';
import {
    Button,
    Label,
    ListAction,
    ListActions,
    ListEmptyView,
    SearchBar,
    Toolbar,
    ToolbarExpander,
    ToolbarGroup,
    TranslationProps,
} from '@plesk/ui-library';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import { Label as LabelType } from '@platform360/security-dashboard/web/types';
import { useDeleteLabelsMutation } from '@platform360/security-dashboard/web/mutations';
import { SEARCH_LABELS_PARAM } from '@platform360/security-dashboard/web/constants/searchParams';
import DeleteLabelsPopover from '../DeleteLabelsPopover';
import ListEmptyViewFilter from '@platform360/security-dashboard/web/components/ListEmptyViewFilter';
import styles from './LabelsList.module.css';
import emptyListImg from './empty-list.png';

type LabelsListProps = {
    data: LabelType[];
    isLoading: boolean;
    isPreviousData: boolean;
};

const LabelsList = ({ data, isLoading, isPreviousData }: LabelsListProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.LabelsDrawer.LabelsList');
    const [selection, setSelection] = useState<number[]>([]);
    const [removingIds, setRemovingIds] = useState<number[]>([]);
    const search = useSearch(SEARCH_LABELS_PARAM, () => analyticsEvents.wpLabelsListSearchSubmit());

    const { mutate: deleteLabels } = useDeleteLabelsMutation({
        onSuccess: () => setSelection([]),
        onSettled: (labelIds) =>
            setRemovingIds((ids) => ids.filter((id) => !labelIds.includes(id))),
    });
    const handleDeleteConfirm = (labelIds: number[]) => {
        setRemovingIds([...removingIds, ...labelIds]);
        deleteLabels(labelIds);
    };

    const filtered = !!search.value;

    const customLabels = data.filter((l) => l.type === 'custom');
    const filteredData = filtered
        ? customLabels.filter(({ title }) => {
              const searchValue = search.value.toLowerCase();

              return title.toLowerCase().includes(searchValue);
          })
        : customLabels;

    const handleSearch = (value: string) => {
        if (value === search.value) return;
        search.onSearch(value);
        setSelection([]);
    };

    const columns: ListProps<LabelType>['columns'] = [
        {
            key: 'title',
            type: 'title',
            title: translate('columns.title'),
            render: ({ title }) => (
                <div className={styles.labelContainer}>
                    <Label size="sm" view="outline" caps={false}>
                        {title}
                    </Label>
                </div>
            ),
        },
        {
            key: 'actions',
            type: 'actions',
            render: (item) => {
                const isActionDisabled = removingIds.includes(item.id);
                return (
                    <ListActions>
                        <ListAction
                            component={DrawerLink}
                            to={`${item.id}/edit`}
                            icon="pencil"
                            primary
                            disabled={isActionDisabled}
                        >
                            {translate('edit')}
                        </ListAction>
                        <ListAction icon="recycle" primary disabled={isActionDisabled}>
                            {({ wrap }: { wrap: (text: TranslationProps) => ReactElement }) => (
                                <DeleteLabelsPopover
                                    labelIds={[item.id]}
                                    target={wrap(translate('delete'))}
                                    placement="left"
                                    onConfirmationShown={() =>
                                        analyticsEvents.wpSglKbbDeleteLabelClick()
                                    }
                                    onConfirm={(labelIds) => {
                                        handleDeleteConfirm(labelIds);
                                        analyticsEvents.wpSglKbbDeleteLabelConfirmationClick();
                                    }}
                                />
                            )}
                        </ListAction>
                    </ListActions>
                );
            },
        },
    ];

    const createButton = (
        <Button component={DrawerLink} to="create" intent="primary">
            {translate('create')}
        </Button>
    );

    return (
        <DataList<LabelType>
            toolbar={
                <Toolbar>
                    <ToolbarGroup title="">{createButton}</ToolbarGroup>
                    <ToolbarGroup title={translate('delete')}>
                        <DeleteLabelsPopover
                            labelIds={selection}
                            target={
                                <NoSelectionPopover
                                    selectionCount={selection.length}
                                    target={<Button icon="recycle" />}
                                >
                                    {translate('noSelectedLabels')}
                                </NoSelectionPopover>
                            }
                            onConfirmationShown={() => analyticsEvents.wpMltDeleteLabelsClick()}
                            onConfirm={(labelIds) => {
                                handleDeleteConfirm(labelIds);
                                analyticsEvents.wpMltDeleteLabelsConfirmationClick();
                            }}
                        />
                    </ToolbarGroup>

                    <ToolbarExpander />
                    <ToolbarGroup title={translate('search.title')}>
                        <SearchBar
                            inputProps={{
                                value: search.value,
                                placeholder: translate('search.placeholder'),
                                'data-type': 'labels-list-search-bar',
                                autoFocus: true,
                            }}
                            minWidth={186}
                            maxWidth={186}
                            onSearch={handleSearch}
                            onTyping={handleSearch}
                        />
                    </ToolbarGroup>
                </Toolbar>
            }
            search={search}
            rowKey="id"
            data={filteredData}
            loading={isLoading || isPreviousData}
            loadingRows={removingIds}
            isPreviousData={isPreviousData}
            selection={selection}
            onSelectionChange={setSelection}
            totalRows={filteredData.length}
            columns={columns}
            filteredEmptyView={
                <ListEmptyViewFilter
                    title={translate('filteredEmptyList.title')}
                    description={translate('filteredEmptyList.description')}
                />
            }
            emptyView={
                <ListEmptyView
                    title={translate('emptyList.title')}
                    description={translate('emptyList.description')}
                    actions={createButton}
                    image={emptyListImg}
                />
            }
            filtered={filtered}
        />
    );
};

export default LabelsList;
