// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, ReactNode, useState } from 'react';
import StatusMessageButtons from './StatusMessageButtons';
import { Link, Paragraph, StatusMessage, TranslateProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useServerInventoryErrorsTranslate } from '@platform360/monitoring/web/helpers/useServerInventoryErrorsTranslate';

type StatusMessageWithActionsProps = {
    intent: 'danger' | 'warning' | 'success' | 'info';
    message: ReactElement<TranslateProps>;
    errorCode: string;
    errorDetail?: string;
    fixMessage: ReactElement<TranslateProps>;
    actions?: ReactNode;
};

const ServerStatusMessage = ({
    message,
    errorCode,
    errorDetail,
    fixMessage,
    actions,
    ...props
}: StatusMessageWithActionsProps) => {
    const translate = useTranslate('ViewServer');
    const isUnknownError = errorCode === 'unknown';
    const siErrorsTranslate = useServerInventoryErrorsTranslate();
    const [isShowDetail, setIsShowDetail] = useState(false);
    const hasErrorDetail = errorDetail !== undefined;
    const showDetailBtn = (
        <Link onClick={() => setIsShowDetail(true)} data-type="show-more-button">
            {translate('showErrorDetail')}
        </Link>
    );

    return (
        <StatusMessage {...props}>
            <Paragraph>
                {message}
                {hasErrorDetail && !isUnknownError && (
                    <> {siErrorsTranslate(errorCode, errorDetail)}</>
                )}
                {hasErrorDetail && !isShowDetail && isUnknownError && <> {showDetailBtn}</>}
            </Paragraph>
            {hasErrorDetail && isShowDetail && <Paragraph>{errorDetail}</Paragraph>}
            <Paragraph>{fixMessage}</Paragraph>
            {actions && <StatusMessageButtons>{actions}</StatusMessageButtons>}
        </StatusMessage>
    );
};

export default ServerStatusMessage;
