// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server } from '@platform360/server-inventory/web/types';

export const ServerViewModel = {
    isInstallationRunning: (server: Server) => server.installationStatus === 'running',
    isInstallationFailed: (server: Server) => server.installationStatus === 'failure',
    isInstallationLicenseRequired: (server: Server) =>
        server.installationStatus === 'license-required',
    isReady: (server: Server) =>
        !['running', 'failure', 'license-required'].includes(server.installationStatus ?? ''),
};
