// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Alert,
    Grid,
    GridCol,
    Icon,
    Media,
    Popover,
    Toolbar,
    ToolbarGroup,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import orderInfo from '@platform360/licenses/web/WizardCommon/order-info.png';
import Cost from '@platform360/licenses/web/components/Cost';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';

type Price = {
    current: number;
    next: number;
};

export type AdditionalInformationProps = {
    grossPrice: Price;
    vatPrice: Price;
    currency: Currency;
    period: BillingPeriod | null;
    nextDate: string;
};

const AdditionalInformation = ({
    grossPrice,
    vatPrice,
    currency,
    period,
    nextDate,
}: AdditionalInformationProps) => {
    const translate = useTranslate();

    return (
        <div data-type="Confirm">
            <Alert intent="info">
                <Media image={<Icon src={orderInfo} size="16" />}>
                    <Grid>
                        <GridCol xl={12}>
                            {translate('chargeNow', {
                                grossPrice: <Cost value={grossPrice.current} currency={currency} />,
                                vatPrice: <Cost value={vatPrice.current} currency={currency} />,
                            })}
                        </GridCol>
                        <GridCol xl={12}>
                            <Toolbar>
                                <ToolbarGroup title={translate('why-icon', undefined, 'why-icon')}>
                                    {/* eslint-disable-next-line react/jsx-max-depth */}
                                    <Icon name={'question-mark-circle'} size={'12'} />
                                </ToolbarGroup>
                                <ToolbarGroup
                                    title={translate('why-popup', undefined, 'why-popup')}
                                >
                                    {/* eslint-disable-next-line react/jsx-max-depth */}
                                    <Popover target={<a>{translate('why')}</a>}>
                                        {translate('whyCharge')}
                                    </Popover>
                                </ToolbarGroup>
                            </Toolbar>
                        </GridCol>
                        <GridCol xl={12}>
                            {translate('nextCharge', {
                                grossPrice: period ? (
                                    <BillingInterval period={period}>
                                        <Cost value={grossPrice.next} currency={currency} />
                                    </BillingInterval>
                                ) : (
                                    <Cost value={grossPrice.next} currency={currency} />
                                ),
                                vatPrice: <Cost value={vatPrice.next} currency={currency} />,
                                nextBillingDate: nextDate,
                            })}
                        </GridCol>
                    </Grid>
                </Media>
            </Alert>
        </div>
    );
};

export default AdditionalInformation;
