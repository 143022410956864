// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import initialized from '@platform360/licenses/web/units/extensions/actions/initialized';
import {
    Upsells as BackendUpsells,
    UpsellSubscription as BackendUpsellSubscription,
} from '@platform360/licenses/web/api/ka/responseTypes/Upsells';
import { License as BackendLicense } from '@platform360/licenses/web/api/ka/responseTypes/License';
import doRequest from '@platform360/licenses/web/api/doRequest';
import getAllUpsells from '@platform360/licenses/web/api/ka/keys/upsells/getAll';
import getOne from '@platform360/licenses/web/api/ka/keys/getOne';
import transformUpsells from '@platform360/licenses/web/units/extensions/helpers/transformUpsells';
import failedInitialization from '@platform360/licenses/web/units/extensions/actions/failedInitialization';
import { StoreState } from '@platform360/licenses/web/store/types';
import { captureException } from '@sentry/browser';
import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import { InitialState } from '@platform360/licenses/web/units/extensions/reducer/initialState';
import { ACT } from '@platform360/licenses/web/api/ka/const/cleverbridgeItemStatus';

const initialization =
    (licenseId: number) =>
    async (dispatch: ThunkDispatch<StoreState, unknown, AnyAction>): Promise<void> => {
        try {
            const [upsells, licenseDetails] = await Promise.all([
                doRequest<BackendUpsells>(getAllUpsells(licenseId)),
                doRequest<BackendLicense>(getOne(licenseId)),
            ]);

            const hasSubscriptions =
                Array.isArray(upsells.subscriptionItems) && upsells.subscriptionItems.length > 0;
            const { monthly, yearly } = transformUpsells(upsells.groups);

            const payload: Partial<InitialState> = {
                license: {
                    id: licenseId,
                    keyNumber: licenseDetails.keyNumber,
                    name: licenseDetails.type,
                    code: licenseDetails.code,
                },
                monthly,
                yearly,
                currencies: [],
                subscriptions: upsells.subscriptionItems,
                licenseId,
            };

            if (hasSubscriptions) {
                const _currencies = new Set<Currency>();
                const _periods = new Set<BillingPeriod>();
                upsells.subscriptionItems.forEach((s: BackendUpsellSubscription) => {
                    _currencies.add(s.currency);
                    _periods.add(s.billingPeriod);
                });
                payload.currencies = Array.from(_currencies);

                payload.currency =
                    licenseDetails.cbItemStatusId === ACT
                        ? licenseDetails.componentInfo?.currency
                        : payload.currencies[0];
                payload.period = licenseDetails.billingPeriod || Array.from(_periods)[0];

                payload.subscriptionByDefault =
                    licenseDetails.cbItemStatusId === ACT
                        ? licenseDetails.componentInfo?.subscriptionId
                        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          upsells.subscriptionItems[0]!.subscriptionId;
            }

            dispatch(initialized(payload));
        } catch (error) {
            captureException(error);
            dispatch(failedInitialization());
        }
    };

export default initialization;
