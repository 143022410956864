// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    VULNERABILITY_PROVIDER_PATCHSTACK,
    VulnerabilityProvider,
    VulnerabilityProviderType,
} from '@platform360/security-dashboard/web/types';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { Patchstack, Wordfence } from '@platform360/security-dashboard/web/components/Providers';

type VulnerabilitySourceProps = {
    url: string;
    disclosedAt: DateTime | null;
    providers?: VulnerabilityProvider[];
};

export const VulnerabilitySources = ({ url, disclosedAt, providers }: VulnerabilitySourceProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.VulnerabilitySources');
    const formatDateTime = useFormatDateTime();

    const providerLogo = (provider: VulnerabilityProviderType) => {
        if (provider === VULNERABILITY_PROVIDER_PATCHSTACK) {
            return <Patchstack />;
        }
        return <Wordfence />;
    };

    const providerLinks = (providers: VulnerabilityProvider[]) => {
        const separator = ', ';
        const groupedByProvider = providers.reduce((acc, provider) => {
            const providersForProviderType: VulnerabilityProvider[] =
                acc.get(provider.provider) ?? [];
            providersForProviderType.push(provider);
            acc.set(provider.provider, providersForProviderType);
            return acc;
        }, new Map<VulnerabilityProviderType, VulnerabilityProvider[]>());

        return [...groupedByProvider.entries()].map(([providerType, sources], index) => {
            const firstSource = sources.at(0);
            if (!firstSource) {
                return null;
            }
            const logo = providerLogo(providerType);

            const content =
                sources.length === 1 ? (
                    <a href={firstSource.directUrl} target="_blank" rel="noopener noreferrer">
                        {logo}
                    </a>
                ) : (
                    <>
                        {logo} {'('}
                        {sources.map((source, sourceIndex) => (
                            <Fragment key={source.directUrl}>
                                {!!sourceIndex && <Text>{separator}</Text>}
                                <a
                                    href={source.directUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {sourceIndex + 1}
                                </a>
                            </Fragment>
                        ))}
                        {')'}
                    </>
                );

            return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                    {!!index && <Text>{separator}</Text>}
                    {content}
                </Fragment>
            );
        });
    };
    const urlLink = (url: string) => {
        const provider = url.includes('patchstack') ? 'Patchstack' : null;
        return (
            provider && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <Patchstack />
                </a>
            )
        );
    };
    const date = disclosedAt
        ? translate('discoveryDate', { date: formatDateTime(disclosedAt, 'dd.LL.yyyy') })
        : null;
    const source = providers?.length ? providerLinks(providers) : urlLink(url);

    return (
        <Text intent="muted" component="div" fontSize="sm" bold>
            {translate('source')}&nbsp;
            {source}&nbsp;
            {date}
        </Text>
    );
};
