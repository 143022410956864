// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import SiteHealthStatus from '@platform360/security-dashboard/web/components/SiteHealthStatus';
import TaskProgress from '@platform360/security-dashboard/web/components/TaskProgress';
import { useLastCheckAtTooltip } from '@platform360/security-dashboard/web/hooks/useLastCheckAtTooltip';

import { Installation } from '@platform360/security-dashboard/web/types';
import { Status as UiStatus, Text, Label } from '@plesk/ui-library';
import styles from './SiteHealthColumn.module.css';

type SiteHealthColumnProps = {
    installation: Installation;
    onNotOperableClick?: () => void;
};

const SiteHealthColumn = ({ installation, onNotOperableClick }: SiteHealthColumnProps) => {
    const {
        id,
        operable,
        vulnerable,
        outdated,
        tasks,
        server: { unsupportedAgent },
        lastVulnerabilityCheckAt,
        resolvableVulnerabilitiesCount,
        outdatedCount,
    } = installation;
    const translate = useTranslate('security-dashboard.Installations.SiteHealthColumn');

    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const lastCheckAtTooltip = useLastCheckAtTooltip(lastVulnerabilityCheckAt);

    if (tasks.length > 0) {
        const [firstTask] = tasks;

        if (!firstTask) {
            return null;
        }

        return <TaskProgress task={firstTask} />;
    }

    if (unsupportedAgent) {
        return (
            <UiStatus
                icon="question-mark-circle"
                intent="danger"
                className={styles.status}
                // @ts-expect-error TS2322
                onClick={() => analyticsEvents.wpSglUnsupportedAgentClick()}
            >
                <Text intent="muted">{translate('unsupportedAgent')}</Text>
            </UiStatus>
        );
    }

    if (!operable) {
        return (
            <Label icon="question-mark-circle" view="light" size="lg" onClick={onNotOperableClick}>
                {translate('notOperable')}
            </Label>
        );
    }

    const showVulnerable =
        vulnerable && resolvableVulnerabilitiesCount !== null && resolvableVulnerabilitiesCount > 0;
    const showOutdated = outdated && outdatedCount !== null && outdatedCount > 0;

    if (vulnerable || outdated) {
        return (
            <div className={styles.wrap}>
                {showVulnerable && (
                    <SiteHealthStatus
                        to={`health/${id}/vulnerabilities`}
                        intent="danger"
                        icon="exclamation-mark-circle"
                        onClick={() => analyticsEvents.wpSglVulnerableClick()}
                        tooltip={lastCheckAtTooltip}
                    >
                        {translate('vulnerabilities', {
                            count: resolvableVulnerabilitiesCount,
                        })}
                    </SiteHealthStatus>
                )}
                {showOutdated && (
                    <SiteHealthStatus
                        to={`health/${id}/outdated`}
                        intent="warning"
                        icon="triangle-exclamation-mark"
                        onClick={() => analyticsEvents.wpSglOutdatedClick()}
                        tooltip={lastCheckAtTooltip}
                    >
                        {translate('outdated', { count: outdatedCount })}
                    </SiteHealthStatus>
                )}
            </div>
        );
    }

    return null;
};

export default SiteHealthColumn;
