// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Auth token is missing or not valid.',
        invitationNotExist: 'Invitation does not exist.',
        invitationExpired: 'The invitation is expired.',
        invitationConflict: 'Invited user is already registered.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'Your password was changed.',
    },
    ChangePasswordForm: {
        resetTitle: 'New password',
        setTitle: 'Set password',
        passwordLabel: 'Password:',
        submitButton: 'Confirm',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'Password reset email sent',
        successSendResetText: 'We sent an email with a password reset link to {email}. Click the link to log in to Plesk 360 and set a new password.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Expired link',
        resetText: 'The password reset link has expired.',
        setText: 'The link to set a password is no longer valid. For your security, the link to set a password is valid for 4 hours. If you did not have time to use the link to set your password, you can re-enter your email below and set the password through the password change procedure.',
        emailLabel: 'Email:',
        submitButton: 'Send',
        bottomText: 'Do you remember the password? You can <a>Log in now</a>.',
    },
};
