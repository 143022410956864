// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';

import {
    SitesHealthFilter,
    SITES_HEALTH_FILTER_ALERTS,
    SITES_HEALTH_FILTER_ALL,
} from '@platform360/security-dashboard/web/api/servers';
import ListActions from '@platform360/security-dashboard/web/components/Servers/ListActions';
import ServerLabelsList from '@platform360/security-dashboard/web/components/Servers/ServerLabelsList';
import ServerNameColumn from '@platform360/security-dashboard/web/components/Servers/ServerNameColumn';
import SiteHealthColumn from '@platform360/security-dashboard/web/components/Servers/SiteHealthColumn';
import Onboarding from '@platform360/security-dashboard/web/components/Onboarding';
import { RoutableLabelsDrawer } from '@platform360/security-dashboard/web/components/LabelsDrawer';
import { FilteredDataListTitle } from '@platform360/security-dashboard/web/components/FilteredDataListTitle';
import { useServersQuery } from '@platform360/security-dashboard/web/queries';
import { Server } from '@platform360/security-dashboard/web/types';
import { useLoadingRows } from '@platform360/security-dashboard/web/hooks/useLoadingRows';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import AddServerDrawer from './AddServerDrawer';
import styles from './Servers.module.less';
import Toolbar, { FilterPanel } from './Toolbar';
import ServerDetails from './ServerDetails';
import { ServerListOperation } from './constants';
import { AssignRemoveLabelsOptions } from '@platform360/security-dashboard/web/components/LabelsPopover';
import ListEmptyViewFilter from '@platform360/security-dashboard/web/components/ListEmptyViewFilter';
import {
    useAssignServerLabelsMutation,
    useRemoveServerLabelsMutation,
} from '@platform360/security-dashboard/web/mutations';
import {
    UnprocessableInstallationsDialog,
    UnprocessableInstallationsDialogData,
} from '@platform360/security-dashboard/web/components/UnprocessableInstallationsDialog';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import SingleSettingsDrawer from '@platform360/security-dashboard/web/components/Servers/SettingsDrawer/SingleSettingsDrawer';

const RoutableAddServerDrawer = withRouteBinding(AddServerDrawer);
const RoutableSettingsDrawer = withRouteBinding(SingleSettingsDrawer);

const Servers = () => {
    const [selection, setSelection] = useState<number[]>([]);
    const translate = useTranslate('security-dashboard.Servers');
    const pagination = usePagination('wpsd-all-servers');
    const queryClient = useQueryClient();
    const [sitesHealthFilter, setSitesHealthFilter] =
        useState<SitesHealthFilter>(SITES_HEALTH_FILTER_ALL);
    const [labelIdsFilter, setLabelIdsFilter] = useState<number[]>([]);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const searchFilter = useSearch('search', () => analyticsEvents.wpSearchSubmit());

    const [searchParams, setSearchParams] = useSearchParams();

    const searchVulnerabilityQuery = searchParams.get('searchVulnerability');

    const search = {
        ...searchFilter,
        onSearch: (newValue: string) => {
            searchParams.delete('searchVulnerability');
            newValue ? searchParams.set('search', newValue) : searchParams.delete('search');

            setSearchParams(searchParams);
            searchFilter.onSearch(newValue);
        },
    };

    const searchField =
        search.value && searchVulnerabilityQuery === search.value ? 'vulnerability' : 'query';

    const vertical = useMediaQuery('(max-width: 768px)');

    const handleFiltersChange = (
        sitesHealth: SitesHealthFilter,
        labelIds: number[],
        placement = 'servers-page',
    ) => {
        setSitesHealthFilter(sitesHealth);
        setLabelIdsFilter(labelIds);
        setSidebarOpen(false);
        if (sitesHealth === SITES_HEALTH_FILTER_ALL) {
            analyticsEvents.wpFilterAllClick();
        }
        if (sitesHealth === SITES_HEALTH_FILTER_ALERTS) {
            analyticsEvents.wpFilterAlertsClick();
        }
        if (labelIds.length !== 0) {
            analyticsEvents.wpFilterLabelsClick(placement);
        }
        clearSelection();
        pagination.resetPagination();
    };

    const onFiltersReset = () => {
        handleFiltersChange(SITES_HEALTH_FILTER_ALL, []);
    };

    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
        refetch,
    } = useServersQuery({
        variables: {
            sitesHealthFilter,
            filter: search.debouncedValue,
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
            labelIdsFilter: labelIdsFilter.map((i) => i.toString()),
        },
        placeholderData: (prev) => prev,
        refetchOnWindowFocus: false,
    });
    useEffectOnce(() => {
        analyticsEvents.wpServersShown();
    });

    const { mutate: assignServerLabels } = useAssignServerLabelsMutation();
    const { mutate: removeServerLabels } = useRemoveServerLabelsMutation();

    const [unprocessableDialogData, setUnprocessableDialogData] =
        useState<UnprocessableInstallationsDialogData | null>(null);

    const handleAssignServerLabels = (data: AssignRemoveLabelsOptions) => {
        const { labelIds, entityIds: serverIds } = data;
        assignServerLabels({ serverIds, labelIds });

        analyticsEvents.wpLabelsAssignedToServers(serverIds.length, labelIds.length);
    };

    const handleRemoveServerLabels = (data: AssignRemoveLabelsOptions) => {
        const { labelIds, entityIds: serverIds } = data;
        removeServerLabels({ serverIds, labelIds });

        analyticsEvents.wpLabelsRemovedFromServers(serverIds.length, labelIds.length);
    };

    const {
        loadingRows: serverIdsWithStartingTask,
        setLoadingRows,
        unsetLoadingRows,
    } = useLoadingRows<number, ServerListOperation>();
    const { warningToast } = useToaster();
    const clearSelection = () => setSelection([]);

    const handleTaskStarting = (serversIds: number[], operation: ServerListOperation) => {
        setLoadingRows(serversIds, operation);
        clearSelection();
    };

    const servers = data?.data || [];

    const handleUnprocessableInstallationsModalVisibility = (serverIds: number[]) => {
        const affectedServers = servers.filter((server) => serverIds.includes(server.id));

        const operableNotManaged = affectedServers.reduce(
            (acc, server) => acc + server.operableNotManagedCount,
            0,
        );
        const notOperable = affectedServers.reduce(
            (acc, server) => acc + server.notOperableCount,
            0,
        );

        if (operableNotManaged) {
            setUnprocessableDialogData({
                notOperable,
                operableNotManaged,
            });
        } else if (notOperable) {
            warningToast(translate('sitesAreNotOperable', { notOperable }));
        }
    };

    const columns: ListProps<Server>['columns'] = [
        {
            key: 'name',
            type: 'title',
            width: '25%',
            title: translate('columns.server'),
            render: (server) => (
                <>
                    <ServerNameColumn
                        name={server.displayTitle}
                        status={server.status}
                        unsupportedAgent={server.unsupportedAgent}
                    />
                    <ServerLabelsList
                        server={server}
                        onRemoveLabel={(label) => {
                            removeServerLabels({ serverIds: [server.id], labelIds: [label.id] });
                            analyticsEvents.wpServerLabelMenuActionClick(label.type, 'remove');
                        }}
                        onFilterByLabel={(label) => {
                            handleFiltersChange(
                                sitesHealthFilter,
                                [label.id],
                                'servers-label-dropdown',
                            );
                        }}
                    />
                </>
            ),
        },
        {
            key: 'sitesHealth',
            title: '',
            render: (server) => <SiteHealthColumn server={server} />,
        },
        {
            key: 'actions',
            type: 'actions',
            render: (server) => (
                <ListActions
                    server={server}
                    onServerRemoved={handleServersRemoved}
                    onTaskStarting={handleTaskStarting}
                    onTaskStarted={unsetLoadingRows}
                    handleAssignServerLabels={handleAssignServerLabels}
                    handleRemoveServerLabels={handleRemoveServerLabels}
                    handleUnprocessableInstallations={
                        handleUnprocessableInstallationsModalVisibility
                    }
                    vertical={vertical}
                />
            ),
        },
    ];

    const handleServersRemoved = (removedServersCount: number) => {
        const { onSelect, current, itemsPerPage } = pagination;

        if (!data || removedServersCount === 0) {
            return;
        }

        const totalPages = Math.ceil(data.totalCount / itemsPerPage);

        if (removedServersCount >= data.data.length && current > 1 && current >= totalPages) {
            // Reset page to the previous one in case if all servers
            // from the last page were deleted.
            onSelect(current - 1);
            return;
        }

        // Removes servers pages query cache and refetch the current page data.
        queryClient.removeQueries({
            queryKey: useServersQuery.getQueryKey(),
        });
        void refetch();
    };

    const getLoadingRowsIds = (servers: Server[]) =>
        servers
            .filter((server) => server.tasks.length > 0 || server.status === 'installingAgent')
            .map((server) => server.id)
            .concat(serverIdsWithStartingTask);

    return (
        <div className={styles.container}>
            <RoutableSettingsDrawer
                openPath=":serverId/settings/:tabKey"
                closePath="/security-dashboard/servers"
            />
            <RoutableAddServerDrawer openPath="add" closePath="/security-dashboard/servers" />
            <RoutableLabelsDrawer closePath="/security-dashboard/servers" />
            {!!search.value && (
                <FilteredDataListTitle
                    searchField={searchField}
                    searchValue={search.value}
                    onReset={() => search.onSearch('')}
                />
            )}
            <DataList<Server>
                toolbar={
                    <Toolbar
                        selection={selection}
                        servers={servers}
                        onServersRemoved={handleServersRemoved}
                        onTaskStarting={handleTaskStarting}
                        onTaskStarted={unsetLoadingRows}
                        handleAssignServerLabels={handleAssignServerLabels}
                        handleRemoveServerLabels={handleRemoveServerLabels}
                        handleUnprocessableInstallations={
                            handleUnprocessableInstallationsModalVisibility
                        }
                        sitesHealthFilter={sitesHealthFilter}
                        labelIdsFilter={labelIdsFilter}
                        isSidebarOpen={isSidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                }
                search={search}
                rowKey="id"
                loadingRows={getLoadingRowsIds(servers)}
                emptyView={<Onboarding />}
                selection={selection}
                onSelectionChange={setSelection}
                data={servers}
                filteredEmptyView={<ListEmptyViewFilter />}
                loading={isLoading}
                isPreviousData={isPreviousData}
                pagination={pagination}
                totalRows={Number(data?.totalCount)}
                columns={columns}
                filtered={
                    sitesHealthFilter !== SITES_HEALTH_FILTER_ALL || labelIdsFilter.length > 0
                }
                renderRowBody={(server) => <ServerDetails server={server} />}
                vertical={vertical}
                sidebar={
                    isSidebarOpen ? (
                        <FilterPanel
                            sitesHealthFilter={sitesHealthFilter}
                            labelIds={labelIdsFilter}
                            onFiltersChange={handleFiltersChange}
                            onReset={onFiltersReset}
                        />
                    ) : undefined
                }
            />
            <UnprocessableInstallationsDialog
                isOpen={!!unprocessableDialogData}
                onClose={() => {
                    setUnprocessableDialogData(null);
                }}
                data={unprocessableDialogData}
            />
        </div>
    );
};

export default Servers;
