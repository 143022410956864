// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import GlobalSearch from './GlobalSearch';
import useGlobalSearchQuery from './useGlobalSearchQuery';
import GlobalSearchButton from './SearchButton';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';

export const GLOBAL_SEARCH_PARAM_NAME = 'gs';

const GlobalSearchContainer = () => {
    const { value: searchQuery, debouncedValue, onSearch } = useSearch(GLOBAL_SEARCH_PARAM_NAME);
    const { isFetching: isLoading, data } = useGlobalSearchQuery({
        variables: {
            filter: debouncedValue,
        },
        enabled: debouncedValue.length >= 3,
    });
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [searchOpened, setSearchOpened] = useState(searchParams.has(GLOBAL_SEARCH_PARAM_NAME));
    const analyticsEvents = useServerInventoryAnalyticsEvents();

    useEffect(() => {
        if (data) {
            analyticsEvents.globalSearchMade();
        }
    }, [analyticsEvents, data]);

    const handleOpenSearch = () => setSearchOpened(true);
    const handleCloseSearch = () => {
        setSearchOpened(false);
        searchParams.delete(GLOBAL_SEARCH_PARAM_NAME);
        navigate({
            search: searchParams.toString(),
        });
    };

    const onClickResult = () => {
        analyticsEvents.globalSearchResultClicked();
    };

    useEffect(() => {
        if (!data) {
            return;
        }
        const count = data.clients.totalCount + data.domains.totalCount + data.servers.totalCount;
        if (count > 0) {
            analyticsEvents.globalSearchResultsFound(count);
        } else {
            analyticsEvents.globalSearchResultsEmpty();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (!searchOpened) {
        return <GlobalSearchButton openSearch={handleOpenSearch} />;
    }

    return (
        <GlobalSearch
            onClickResult={onClickResult}
            onSearch={onSearch}
            closeSearch={handleCloseSearch}
            isLoading={isLoading}
            data={data}
            searchQuery={searchQuery}
        />
    );
};

export default GlobalSearchContainer;
