// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Text, Link, Status, TranslationProps } from '@plesk/ui-library';
import { useVulnerabilitiesSummaryQuery } from '@platform360/security-dashboard/web/queries';
import CardLoader from '../CardLoader';
import styles from './VulnerabilitiesCard.module.css';
import CvssScore from '@platform360/security-dashboard/web/components/CvssScore';
import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    VulnerabilitiesStateFilter,
    VULNERABILITY_STATE_FILTER_ID,
} from '@platform360/security-dashboard/web/types';

const translateTimestamp = (
    lastUpdated: DateTime,
    translate: ReturnType<typeof useTranslate>,
): TranslationProps => {
    const diff = DateTime.now().diff(lastUpdated);
    const minutes = Math.round(diff.as('minutes'));
    if (minutes === 0) {
        return translate('momentAgo');
    }
    if (minutes < 120) {
        return translate('lastUpdateMinutes', { minutes });
    }
    const hours = Math.round(diff.as('hours'));
    if (hours < 48) {
        return translate('lastUpdateHours', { hours });
    }
    const days = Math.round(diff.as('days'));
    return translate('lastUpdateDays', { days });
};

const VulnerabilitiesCard = () => {
    const translate = useTranslate('security-dashboard.Layout.Header.VulnerabilitiesCard');
    const { data, isLoading } = useVulnerabilitiesSummaryQuery();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const renderLastUpdate = (lastUpdateAt: DateTime | null) => {
        if (lastUpdateAt === null) {
            return <Text>{translate('noData')}</Text>;
        }

        return (
            <div>
                <div>
                    <Text>{translate('databaseUpdate')}</Text>
                </div>
                <div>
                    <Text className={styles.lastUpdate} bold>
                        {translateTimestamp(lastUpdateAt, translate)}
                    </Text>
                </div>
            </div>
        );
    };

    const renderWarnings = (exploited: number, highestScore: number, needsProtection: number) => {
        if (exploited === 0 && highestScore === 0 && needsProtection === 0) {
            return null;
        }
        const linkToVulnerabilities = '/security-dashboard/vulnerabilities';
        const exploitedUrl = buildUrl(linkToVulnerabilities, {
            [VULNERABILITY_STATE_FILTER_ID]: VulnerabilitiesStateFilter.Exploited,
        });
        const canBeProtectedUrl = buildUrl(linkToVulnerabilities, {
            [VULNERABILITY_STATE_FILTER_ID]: VulnerabilitiesStateFilter.CanBeProtected,
        });

        return (
            <>
                <div className={styles.divider} />
                <div className={styles.stats}>
                    {exploited !== 0 && (
                        <div>
                            <Status icon="flag" intent="danger">
                                <Link
                                    component={RouterLink}
                                    to={exploitedUrl}
                                    onClick={() => analyticsEvents.wpDshExploitedClick(exploited)}
                                >
                                    {translate('exploited', { count: exploited })}
                                </Link>
                            </Status>
                        </div>
                    )}
                    {needsProtection !== 0 && (
                        <div>
                            <Status icon="shield" intent="info">
                                <Link
                                    component={RouterLink}
                                    to={canBeProtectedUrl}
                                    onClick={() =>
                                        analyticsEvents.wpDshProtectionClick(needsProtection)
                                    }
                                >
                                    {translate('needsProtection', { count: needsProtection })}
                                </Link>
                            </Status>
                        </div>
                    )}
                    {highestScore !== 0 && (
                        <div className={styles.highestScore}>
                            <CvssScore cvssScore={highestScore.toFixed(1)} />
                            <Link
                                component={RouterLink}
                                to={linkToVulnerabilities}
                                onClick={() => analyticsEvents.wpDshCvssClick(highestScore)}
                            >
                                {translate('highestScore')}
                            </Link>
                        </div>
                    )}
                </div>
            </>
        );
    };

    if (isLoading || !data) {
        return <CardLoader />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.vulnerabilities}>
                <Text intent="muted" className={styles.vulnerabilitiesTitle}>
                    {translate('title')}
                </Text>
                {renderLastUpdate(data.lastUpdateAt)}
            </div>
            {renderWarnings(data.exploited, data.highestScore, data.needsProtection)}
        </div>
    );
};

export default VulnerabilitiesCard;
