// Copyright 2024. WebPros International GmbH. All rights reserved.

export class EmailNotVerifiedException extends Error {
    public constructor(
        message: string,
        public readonly email: string,
    ) {
        super(message);

        // Because we are extending a built-in class.
        Object.setPrototypeOf(this, EmailNotVerifiedException.prototype);
    }
}

export default EmailNotVerifiedException;
