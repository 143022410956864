// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label as UILibLabel, Icon, Tooltip, IconProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useUpdateSettingsOptionTranslate from './useUpdateSettingsOptionTranslate';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import styles from './Label.module.css';

const SafeUpdateIcon = ({ tooltipTitle, ...props }: IconProps & { tooltipTitle: string }) => (
    <Tooltip title={tooltipTitle}>
        <Icon name="lock-closed-check" {...props} />
    </Tooltip>
);

export type LabelProps = {
    safeUpdate: boolean | null;
    components: string[];
    option: UpdateSettingsOption;
};

export const Label = ({ components, option, safeUpdate }: LabelProps) => {
    const translate = useTranslate('security-dashboard.UpdateSettingsCard.Label');
    const translateOption = useUpdateSettingsOptionTranslate();

    const componentTranslations: Record<string, string> = {
        core: translate('components.core'),
        plugins: translate('components.plugins'),
        themes: translate('components.themes'),
    };

    const renderComponents = (components: string[]) => {
        if (components.length === 3) {
            return translate('allComponents');
        }

        return components.map((component) => componentTranslations[component]).join(', ');
    };

    return (
        <UILibLabel
            icon={safeUpdate ? <SafeUpdateIcon tooltipTitle={translate('tooltip')} /> : undefined}
            view="outline"
            intent="info"
            caps={false}
        >
            <span className={styles.components}>
                {translate('labelPlaceholder', { components: renderComponents(components) })}
            </span>
            <span className={styles.option}>{translateOption(option)}</span>
        </UILibLabel>
    );
};
