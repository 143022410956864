// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ThunkAction } from 'redux-thunk';
import { StoreState } from '@platform360/licenses/web/store/types';
import { Action } from 'redux';
import { SET_VALUE_FOR_PRODUCT } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';
import calculate from '@platform360/licenses/web/units/extensions/actions/buyExtension/calculate';

export type SetValueAction = {
    type: typeof SET_VALUE_FOR_PRODUCT;
    payload: {
        productId: number;
        value: number;
    };
};

export const set = (productId: number, value: number): SetValueAction => ({
    type: SET_VALUE_FOR_PRODUCT,
    payload: { productId, value },
});

let timeoutId: NodeJS.Timeout | null = null;

const setValue =
    (productId: number, value: number): ThunkAction<void, StoreState, unknown, Action<string>> =>
    (dispatch): void => {
        dispatch(set(productId, value));

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            dispatch(calculate());
            timeoutId = null;
        }, 1000);
    };

export default setValue;
