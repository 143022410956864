// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { vulnerabilityInstallationOperationValues, VulnerabilityInstallationOperations } =
    makeEnum(
        'VulnerabilityInstallationOperation',
        'update',
        'patch',
        'enablePluginDisabledByMitigation',
        'disablePluginByMitigation',
    );

export type VulnerabilityInstallationOperationType = UnionOf<
    typeof vulnerabilityInstallationOperationValues
>;
