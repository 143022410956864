// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Navigate, RouteObject } from 'react-router-dom';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import AuthGateway from '@platform360/web-installer/web/components/AuthGateway';
import EmailConfirmationPage from '@platform360/web-installer/web/components/EmailConfirmationPage';
import LightsailInstallation from '@platform360/web-installer/web/components/LightsailInstallation';
import Installation from '@platform360/web-installer/web/components/Installation';
import UserInfoConfirmationPage from '@platform360/web-installer/web/components/UserInfoConfirmationPage';
import PleskAutoInstallationFAQ from '@platform360/web-installer/web/components/PleskAutoInstallationFAQ';
import CpanelAutoInstallationFAQ from '@platform360/web-installer/web/components/CpanelAutoInstallationFAQ';

export const routes: RouteObject[] = [
    {
        path: 'web-installer',
        children: [
            {
                path: 'auth-gateway',
                element: <AuthGateway />,
            },
            {
                path: 'email-confirmation',
                element: <EmailConfirmationPage />,
            },
            {
                path: 'plesk-installation-faq',
                element: <PleskAutoInstallationFAQ />,
            },
            {
                path: 'cpanel-installation-faq',
                element: <CpanelAutoInstallationFAQ />,
            },
            {
                element: <PrivateRoutes />,
                children: [
                    {
                        path: 'user-info',
                        element: <UserInfoConfirmationPage />,
                    },
                    {
                        path: 'install',
                        children: [
                            {
                                index: true,
                                element: <Navigate to="lightsail" />,
                            },
                            {
                                path: 'lightsail',
                                element: <LightsailInstallation />,
                            },
                        ],
                    },
                    {
                        path: 'installations/:id',
                        element: <Installation />,
                    },
                ],
            },
        ],
    },
];
