// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext, useContext } from 'react';
import { Config } from '@platform360/libs/common/initial-state';

export const ConfigContext = createContext<Config | undefined>(undefined);

const useConfig = (): Config => {
    const config = useContext(ConfigContext);

    if (config === undefined) {
        throw new Error('useConfig must be used within a ConfigContext.Provider');
    }

    return config;
};

export default useConfig;
