// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import useInstallationInfoQuery from '@platform360/security-dashboard/web/queries/useInstallationInfoQuery';
import { updateInstallationsProtectionStatus } from '@platform360/security-dashboard/web/api/installations';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

const useUpdateInstallationsProtectionStatusMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateInstallationsProtectionStatus,
        onSuccess: async () => {
            refetchList(useInstallationsQuery.getQueryKey());

            await queryClient.invalidateQueries({
                queryKey: useInstallationInfoQuery.getQueryKey(),
            });
        },
    });
};

export default useUpdateInstallationsProtectionStatusMutation;
