// Copyright 2024. WebPros International GmbH. All rights reserved.

export const REFRESH_INSTALLATIONS_TASK_TYPE = 'refresh-installations';

export const DETACH_INSTALLATIONS_TASK_TYPE = 'detach-installations';
export const UPDATE_INSTANCE_TASK_TYPE = 'update-instance';
export const UPDATE_ITEMS_TASK_TYPE = 'task\\updateitems';
export const AGENT_SCAN_NEW_INSTALLATIONS_TASK_TYPE = 'agent-scan-new-installations';
export const APPLY_VIRTUAL_PATCHES_TASK_TYPE = 'apply-virtual-patches';
export const DISABLE_VIRTUAL_PATCHES_TASK_TYPE = 'disable-virtual-patches';
export const MITIGATE_VULNERABILITIES_TASK_TYPE = 'vulnerability-mitigation';
export const UPDATE_SETTINGS_TASK_TYPE = 'update-autoupdate-settings';
export const SET_DAILY_TASK_START_TIME = 'set-daily-task-start-time';

export const VULNERABILITY_PROVIDER_PATCHSTACK = 'patchstack';
export const VULNERABILITY_PROVIDER_WORDFENCE = 'wordfence';
