// Copyright 2024. WebPros International GmbH. All rights reserved.

import { getLightsailOptions } from '@platform360/web-installer/web/api/web-installer';
import { createQuery } from '@platform360/libs/shared-web/query';
import { useQuery } from '@tanstack/react-query';

export type UseLightsailOptionsVariables = undefined;

export type UseLightsailOptionsData = PromiseValue<ReturnType<typeof getLightsailOptions>>;

const useLightsailOptions = createQuery<UseLightsailOptionsVariables, UseLightsailOptionsData>({
    queryName: 'web-installer/useLightsailOptions',
    fetcher: getLightsailOptions,
    useQuery,
});

export default useLightsailOptions;
