// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, useRef, useEffect } from 'react';
import { useWebsocket } from '@platform360/libs/shared-web/websocket';
import {
    EventsMap,
    WpgAgentRequestCompletedEventPayload,
} from '@platform360/admin-panel/shared/websocket';
import { WpAgentRequestMethod } from '@platform360/admin-panel/shared/constants';

export type RequestInfo = {
    requestId: string;
    method: WpAgentRequestMethod;
    url: string;
    state: 'running' | 'done' | 'timeoutReached';
    time: number | null;
    request: WpgAgentRequestCompletedEventPayload['request'] | null;
    response: WpgAgentRequestCompletedEventPayload['response'];
    server: WpgAgentRequestCompletedEventPayload['server'] | null;
    serverUnreachableError?: WpgAgentRequestCompletedEventPayload['serverUnreachableError'];
    isResponseLarge?: boolean;
};

const downloadFile = (data: string, fileName: string) => {
    const link = document.createElement('a');
    const file = new Blob([data], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
};

const RESPONSE_WAITING_TIMEOUT = 630000;

export const useRequestStorage = () => {
    const [requests, setRequests] = useState<RequestInfo[]>([]);
    const timeouts = useRef<Record<string, number>>({});

    useEffect(() => () => Object.values(timeouts.current).forEach(clearTimeout), []);

    const handleRequestSent = (request: Pick<RequestInfo, 'requestId' | 'method' | 'url'>) => {
        setRequests((requests) => [
            {
                ...request,
                time: null,
                request: null,
                response: null,
                server: null,
                state: 'running',
            },
            ...requests,
        ]);

        timeouts.current[request.requestId] = window.setTimeout(() => {
            setRequests((requests) =>
                requests.map((currentRequest) => {
                    if (currentRequest.requestId !== request.requestId) return currentRequest;

                    return { ...currentRequest, state: 'timeoutReached' };
                }),
            );
        }, RESPONSE_WAITING_TIMEOUT);
    };

    const handleRequestCompleted = (request: WpgAgentRequestCompletedEventPayload) => {
        window.clearTimeout(timeouts.current[request.requestId]);

        let isResponseLarge = false;
        if (request.response?.data) {
            const stringifiedData =
                typeof request.response.data === 'string'
                    ? request.response.data
                    : JSON.stringify(request.response.data, undefined, 2);

            isResponseLarge = stringifiedData.length > 300000;

            let fileName =
                `${request.server.hostname || request.server.guid}-` +
                `${request.method}-` +
                `${new URL(request.url, 'https://base.url').pathname}-${new Date().toLocaleTimeString(
                    undefined,
                    { timeStyle: 'medium' },
                )}`;
            fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-').replace(/--/g, '-');

            if (isResponseLarge) {
                downloadFile(
                    stringifiedData,
                    typeof request.response.data === 'object'
                        ? `${fileName}.json`
                        : `${fileName}.txt`,
                );
                request.response.data = null;
            }
        }

        setRequests((currentRequests) => {
            const isRequestExist = currentRequests.some(
                ({ requestId }) => requestId === request.requestId,
            );

            if (!isRequestExist) {
                return [{ ...request, state: 'done', isResponseLarge }, ...currentRequests];
            }

            return currentRequests.map((currentRequest) => {
                if (currentRequest.requestId !== request.requestId) return currentRequest;

                return {
                    ...currentRequest,
                    ...request,
                    isResponseLarge,
                    state: 'done',
                };
            });
        });
    };

    useWebsocket<EventsMap>([
        {
            name: 'wpg-agent-request-completed',
            handler: handleRequestCompleted,
        },
    ]);

    return {
        requests,
        handleRequestSent,
    };
};
