// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import CountableForm from '@platform360/licenses/web/units/productsForms/countable';
import { StoreState } from '@platform360/licenses/web/store/types';
import setValue from '@platform360/licenses/web/units/extensions/actions/buyExtension/setValue';

const mstp = ({
    licenses: {
        buyExtension: { products, period, currency, selectedPaygrProducts },
    },
}: StoreState) => {
    const [product] = products;

    return {
        // @ts-expect-error TS7006
        list: product.properties?.map((property) => ({
            id: property.productId,
            name: property.productName,
            description: property.description,
            price: property.price,
            value: selectedPaygrProducts[property.productId],
            rangeValues: {
                min: property.rangeValue.minimumNumber,
                max: property.rangeValue.maximumNumber,
            },
        })),
        period,
        currency,
        isAdjustPending: false,
    };
};

const mdtp = (dispatch: Dispatch) => ({
    onProductChange: (productId: number, value: number) => {
        // @ts-expect-error TS2345
        dispatch(setValue(productId, value));
    },
});

export default connect(mstp, mdtp)(CountableForm);
