// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { License } from '@platform360/security-dashboard/web/types';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';
import { DateTime } from 'luxon';
import { CustomerType } from '@platform360/security-dashboard/shared/customer-type';

export type LicenseResponse = {
    id: number;
    licenseKey: number | null;
    product: string;
    sitesLimitHard: number | null;
    sitesLimitSoft: number | null;
    status: string;
    expiredAt: string;
    nextUpdateAt: string;
    trial: boolean;
    customerType: CustomerType;
};

const normalizeLicense = ({ expiredAt, nextUpdateAt, ...license }: LicenseResponse): License => ({
    expiredAt: DateTime.fromISO(expiredAt),
    nextUpdateAt: DateTime.fromISO(nextUpdateAt),
    ...license,
});

export const getLicense = async (): Promise<License | null> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<LicenseResponse | null>>('/security-dashboard/license');

    if (data === null) {
        return null;
    }

    return normalizeLicense(data);
};

export const activateTrial = async (): Promise<void> => {
    await apiClient.post<ApiResponse<never>>('/security-dashboard/license/trial');
};
