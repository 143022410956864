// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server } from '@platform360/security-dashboard/web/types';
import { Button, Panel, StatusMessage, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import OutdatedAgentError from '@platform360/security-dashboard/web/components/Servers/OutdatedAgentError';
import styles from './Servers.module.less';
import UpdateSettingsCard from '@platform360/security-dashboard/web/components/UpdateSettingsCard';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import { useLicenseIsActive } from '@platform360/security-dashboard/web/hooks/useLicenseIsActive';
import { SECURITY_DASHBOARD_FEEDBACK_LINK } from '@platform360/libs/common/constants/security-dashboard';

type ServerDetailsProps = {
    server: Server;
};

const ServerStatusError = ({ server }: { server: Server }) => {
    const translate = useTranslate('security-dashboard');
    switch (server.status) {
        case 'ok':
        case 'installingAgent':
            return null;
        case 'connectionError':
            return (
                <StatusMessage intent="danger">
                    <Text bold component="p">
                        {translate('Servers.state.connectionErrorStatusMessage')}
                    </Text>
                    <Text component="p">
                        {translate('Servers.state.connectionErrorFixDescription', {
                            ipAddress: server.ipAddress,
                        })}
                    </Text>
                </StatusMessage>
            );
        case 'connectionBlocked':
            return (
                <StatusMessage intent="danger">
                    <Text bold component="p">
                        {translate('Servers.state.connectionBlockedStatusMessage')}
                    </Text>
                    <Text component="p">
                        {translate('Servers.state.connectionBlockedFixDescription', {
                            ipAddress: server.ipAddress,
                        })}
                    </Text>
                </StatusMessage>
            );
        case 'agentInstallationError':
            return (
                <StatusMessage intent="danger">
                    <Text bold component="p">
                        {translate('Servers.state.agentInstallationErrorMessage')}
                    </Text>
                    <Text component="p">
                        {translate('Servers.state.agentInstallationErrorFixDescription')}
                    </Text>
                    <div className={styles.actions}>
                        <Button
                            icon="feedback"
                            component="a"
                            href={SECURITY_DASHBOARD_FEEDBACK_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate('Onboarding.feedbackButton')}
                        </Button>
                    </div>
                </StatusMessage>
            );
    }
    return null;
};

const ServerDetails = ({ server }: ServerDetailsProps) => {
    const translate = useTranslate('security-dashboard.Servers.ServerDetails');
    const licenseIsActive = useLicenseIsActive();
    const haveLicenseRestrictions = licenseIsActive === undefined || !licenseIsActive;
    const {
        updateSettings: { core, plugins, themes, safeUpdate },
    } = server;

    return (
        <>
            {server.unsupportedAgent ? (
                <OutdatedAgentError serverVersion={server.agentVersion} />
            ) : null}
            {!haveLicenseRestrictions && (
                <div>
                    <Panel className={styles.card}>
                        <UpdateSettingsCard
                            title={translate('updateSettingsCardTitle')}
                            updateSettings={{
                                core: core.value ?? core.parent,
                                themes: themes.value ?? themes.parent,
                                plugins: plugins.value ?? plugins.parent,
                                safeUpdate: safeUpdate.value ?? safeUpdate.parent,
                            }}
                            button={
                                <Button
                                    ghost
                                    icon="gear"
                                    component={DrawerLink}
                                    to={`${server.id}/settings/updates`}
                                />
                            }
                        />
                    </Panel>
                </div>
            )}
            <ServerStatusError server={server} />
            <div className={styles.detailsContainer}>
                {translate('ipAddress', {
                    address: server.ipAddress,
                })}
            </div>
        </>
    );
};

export default ServerDetails;
