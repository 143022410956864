// Copyright 2024. WebPros International GmbH. All rights reserved.

import { License as BackendLicense } from '@platform360/licenses/web/api/ka/responseTypes/License';
import { DEA } from '@platform360/licenses/web/api/ka/const/cleverbridgeItemStatus';
import { UNIQUENESS_OF_360_MONITORING } from '@platform360/licenses/web/units/buy360MonitoringButton/constants/uniqueness';

const isReactivatePossible = (license: BackendLicense) =>
    license.cbItemStatusId === DEA &&
    license.renewPossible &&
    !license.type.startsWith(UNIQUENESS_OF_360_MONITORING);

export default isReactivatePossible;
