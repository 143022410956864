// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Page from './../components/Page';
import initialization from '@platform360/licenses/web/units/extensions/actions/initialization';
import finalization from '@platform360/licenses/web/units/extensions/actions/finalization';
import { StoreState } from '@platform360/licenses/web/store/types';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';

const mstp = (state: StoreState) => {
    const { initialized, failedInitialization, selectedItemPath } = upsellSelector(state);

    return {
        initialized,
        failedInitialization,
        selectedItemPath,
    };
};

const mdtp = (dispatch: Dispatch) => ({
    initialization: (licenseId: number) => {
        // @ts-expect-error TS2345
        dispatch(initialization(licenseId));
    },
    finalization: () => {
        dispatch(finalization());
    },
});

export default connect(mstp, mdtp)(Page);
