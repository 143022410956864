// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { findServers } from '@platform360/admin-panel/web/api/security-dashboard/servers';

export type UseFindServersQueryVariables = { searchQuery: string };
export type UseFindServersQueryData = PromiseValue<ReturnType<typeof findServers>>;

const useFindServersQuery = createQuery<UseFindServersQueryVariables, UseFindServersQueryData>({
    queryName: 'admin-panel/useFindServersQuery',
    fetcher: findServers,
    useQuery,
});

export default useFindServersQuery;
