// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { Popover, PopoverProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';

type LinkEmailPopoverProps = Omit<PopoverProps, 'onClose'>;

const LinkEmailPopover = (props: LinkEmailPopoverProps) => {
    const translate = useTranslate('security-dashboard.LicensePage.LinkEmailPopover');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    return (
        <Popover placement="left" {...props} data-type="link-email-popover">
            {translate('linkEmailDescription')}{' '}
            <Link
                to="/licensing/emails/add"
                onClick={() => {
                    window.scrollTo(0, 0);
                    analyticsEvents.wpLicensePageLinkEmailClicked();
                }}
            >
                {translate('linkEmail')}
            </Link>
        </Popover>
    );
};

export default LinkEmailPopover;
