// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, createContext, ComponentType } from 'react';

type Modals = Record<string, ComponentType>;

export const UserMenuModalsContext = createContext<Modals>({});

type UserMenuProviderProps = {
    modals: Modals;
    children: ReactElement;
};

export const UserMenuModalsProvider = ({ modals, children }: UserMenuProviderProps) => (
    <UserMenuModalsContext.Provider value={modals}>{children}</UserMenuModalsContext.Provider>
);
