// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'No open alerts',
        openAlerts: 'Open alerts: {alerts}',
        loadingError: 'Service not available',
        connectServer: 'Connect server',
        notSupported: 'Not supported',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'View in 360 Monitoring',
        connectServerTitle: 'This server is not connected to 360 Monitoring',
        connectServerDescription: 'When the server is connected, this page will display a brief overview of server status and current open alerts.',
        connectServerButton: 'Connect server',
        pausedServerTitle: 'This server is not being monitored',
        pausedServerDescription: 'Use 360 Monitoring user interface to determine the reason.',
        preparingServerTitle: 'We are doing science',
        preparingServerDescription1: 'Your Plesk server has been connected to 360 Monitoring.',
        preparingServerDescription2: 'We are doing science to prepare a dashboard. Try to refresh the page or get back in 10-30 minutes.',
        refreshButton: 'Refresh',
        loadingError: 'Something went wrong. Please try again later.',
        loadAverageTitle: 'Load Average',
        alertOpened: 'Opened {timeAgo}.',
        viewAlertsInMonitoring: 'View alerts in 360 Monitoring',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Monitoring',
            description: 'Monitor your servers and websites from a single dashboard',
        },
        DynamicWidget: {
            title: 'Monitoring',
            WidgetContent: {
                servers: 'Servers',
                websites: 'Websites',
                lastUpdate: 'Last update: {lastUpdate}',
                loading: 'Loading...',
                Loader: {
                    loading: 'Loading...',
                    notRespondingText1: '360 Monitoring is not responding.',
                    notRespondingText2: 'Trying to reconnect.',
                },
                InfoAggregateColumn: {
                    allActive: 'All active',
                    noAlerts: 'No alerts',
                    alerts: 'Open alerts: {alerts}',
                    down: '{down, plural, one{# is not responding} other{# are not responding}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: 'Activate 360 Monitoring',
        description: '360 Monitoring Lite is free of charge, now and always. By activating your account today, you will also enjoy a complimentary 14-day Pro trial including all premium features. You will be automatically switched back to the free Lite version after two weeks, unless you choose to stay with the upgrade!',
        beginTrialButton: 'Activate 360 Monitoring *',
        footnote: '* No credit card details are required to activate 360 Monitoring Lite or the Pro trial.',
        backButton: 'Back to Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Cancel',
            confirmationText: 'Please try configuring your 360 Monitoring account by opening Monitoring menu item or submit a support request.',
            acceptButtonText: 'OK',
            declineButtonText: 'Cancel',
        },
        retryButtonText: 'Retry',
        inProgressText: 'Your 360 Monitoring account is being configured',
        successText: 'Redirecting you to 360 Monitoring',
        failedText: 'Something went wrong during the monitoring account activation. Please try again or submit a support request.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Confirm activating the 360 Monitoring',
            description: 'The "360 Monitoring" agent will be installed to you Plesk control panel at <b>{address}</b> and connected to the <b>{email}</b> account at Plesk 360.',
        },
        monitoringInactive: {
            title: 'Activate 360 Monitoring and add the server',
            description: 'Start using 360 Monitoring with a <tooltip>trial period</tooltip> and confirm installing the "360 Monitoring" agent to your Plesk control panel at <b>{address}</b> connected to the <b>{email}</b> account at Plesk 360.',
            trialTooltip: 'During 14 days trial period you get a fully functional service with all the premium features for 14 days. After the trial period ends 360 Monitoring will be switched to free “Lite” version with basic features available. You can also activate a “Pro” version anytime by buying a license.',
        },
        buttons: {
            confirm: 'Confirm',
            deny: 'Deny',
            cancel: 'Cancel',
        },
        errors: {
            apiKey: 'The server connection to Plesk 360 seems broken. Reconnect your server to Plesk 360 and then activate monitoring',
            default: 'Failed to install 360 Monitoring, please try again later',
        },
    },
};
