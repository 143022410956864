// Copyright 2024. WebPros International GmbH. All rights reserved.

import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link } from 'react-router-dom';
import image from './zero-state.svg';

const Onboarding = () => {
    const translate = useTranslate('Team.Invitations.Onboarding');

    return (
        <ListEmptyView
            title={translate('title')}
            image={image}
            description={translate('description')}
            actions={[
                <Button
                    key="1"
                    component={Link}
                    to={'/organization/invitations/add'}
                    data-type="add-invitations-button"
                    intent="primary"
                >
                    {translate('addButton')}
                </Button>,
            ]}
        />
    );
};

export default Onboarding;
