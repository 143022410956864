// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { IdsFilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getVulnerabilities } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { createQuery } from '@platform360/libs/shared-web/query';
import {
    VulnerabilitiesComponentFilter,
    VulnerabilitiesStateFilter,
} from '@platform360/security-dashboard/web/types';

export type UseVulnerabilitiesQueryVariables = {
    filter?: string;
    vulnerabilityStateFilter: VulnerabilitiesStateFilter[];
    vulnerabilityComponentFilter: VulnerabilitiesComponentFilter[];
} & PaginatedQuery &
    IdsFilterQuery;

export type UseVulnerabilitiesQueryData = PromiseValue<ReturnType<typeof getVulnerabilities>>;

const useVulnerabilitiesQuery = createQuery<
    UseVulnerabilitiesQueryVariables,
    UseVulnerabilitiesQueryData
>({
    queryName: 'security-dashboard/useVulnerabilitiesQuery',
    fetcher: getVulnerabilities,
    useQuery: (options) =>
        useQuery({
            placeholderData: (prev) => prev,
            refetchOnWindowFocus: false,
            ...options,
        }),
});

export default useVulnerabilitiesQuery;
