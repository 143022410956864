// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import classNames from 'classnames';
import { FormField, Select, SelectOption } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ComboBoxTimePicker from '@platform360/security-dashboard/web/components/ComboBoxTimePicker';
import { DailyTaskTimeTypes } from '@platform360/security-dashboard/web/constants/dailyTaskTime';
import styles from './DailyTaskTimeSection.module.less';

type Value =
    | {
          taskStartTimeHours: number | null;
          taskStartTimeMinutes: number | null;
      }
    | undefined;

export type DailyTaskTimeSectionProps = {
    initialValue: Value;
    value: Value;
    onChange: (value: Value) => void;
    isSaving?: boolean;
    isCompact?: boolean;
    unsetOptionTranslation?: ReactNode;
    className?: string;
    isMassOperation?: boolean;
};

const DailyTaskTimeSection = ({
    initialValue,
    value,
    onChange,
    isSaving,
    isCompact,
    unsetOptionTranslation,
    className,
    isMassOperation,
}: DailyTaskTimeSectionProps) => {
    const translate = useTranslate('security-dashboard.DailyTaskTimeSection');

    const handleTypeChange = (type: unknown) => {
        if (type === DailyTaskTimeTypes.doNotTouch) {
            onChange(undefined);
        } else if (type === DailyTaskTimeTypes.unset) {
            onChange({ taskStartTimeHours: null, taskStartTimeMinutes: null });
        } else {
            onChange({
                taskStartTimeHours: initialValue?.taskStartTimeHours ?? 0,
                taskStartTimeMinutes: initialValue?.taskStartTimeMinutes ?? 0,
            });
        }
    };

    const handleHoursChange = (hours: number) => {
        onChange({
            taskStartTimeHours: hours,
            taskStartTimeMinutes: value?.taskStartTimeMinutes ?? 0,
        });
    };

    const handleMinutesChange = (minutes: number) => {
        onChange({
            taskStartTimeHours: value?.taskStartTimeHours ?? 0,
            taskStartTimeMinutes: minutes,
        });
    };

    const getType = (value: Value) => {
        if (value === undefined) {
            return DailyTaskTimeTypes.doNotTouch;
        }
        if (value.taskStartTimeHours === null && value.taskStartTimeMinutes === null) {
            return DailyTaskTimeTypes.unset;
        }
        return DailyTaskTimeTypes.custom;
    };

    const type = getType(value);

    return (
        <div className={classNames(className, { [styles.compactContainer ?? '']: isCompact })}>
            <FormField
                name="dailyTaskType"
                label={isCompact ? null : translate('typeLabel')}
                description={
                    type === DailyTaskTimeTypes.custom
                        ? translate('timezoneDescription')
                        : undefined
                }
                vertical={isCompact}
                className={classNames({ [styles.compactField ?? '']: isCompact })}
            >
                <Select
                    value={type}
                    size={isCompact ? 'md' : 'fill'}
                    onChange={handleTypeChange}
                    disabled={isSaving}
                    id="dailyTaskType"
                    data-type="dailyTaskType"
                >
                    {isMassOperation && (
                        <SelectOption value={DailyTaskTimeTypes.doNotTouch}>
                            {translate('typeOptions.doNotTouch')}
                        </SelectOption>
                    )}
                    <SelectOption value={DailyTaskTimeTypes.unset}>
                        {unsetOptionTranslation || translate('typeOptions.unset')}
                    </SelectOption>
                    <SelectOption value={DailyTaskTimeTypes.custom}>
                        {translate('typeOptions.custom')}
                    </SelectOption>
                </Select>
            </FormField>
            {type === DailyTaskTimeTypes.custom && (
                <FormField
                    label={isCompact ? null : translate('timeLabel')}
                    vertical={isCompact}
                    className={classNames({ [styles.compactField ?? '']: isCompact })}
                >
                    <ComboBoxTimePicker
                        disabled={isSaving}
                        hours={value?.taskStartTimeHours ?? 0}
                        minutes={value?.taskStartTimeMinutes ?? 0}
                        onHoursChange={handleHoursChange}
                        onMinutesChange={handleMinutesChange}
                        data-type="daily-task-time-picker"
                    />
                </FormField>
            )}
        </div>
    );
};

export default DailyTaskTimeSection;
