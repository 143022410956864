// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    UpdateSettingsOption,
    UpdateSettingsOptions,
} from '@platform360/security-dashboard/shared/update-settings-option';

const useUpdateSettingsOptionTranslate = () => {
    const translate = useTranslate(
        'security-dashboard.UpdateSettingsCard.Label.useUpdateSettingsOptionTranslate',
    );

    const translationsMap: Record<UpdateSettingsOption, TranslationProps> = {
        [UpdateSettingsOptions.minor]: translate('minor'),
        [UpdateSettingsOptions.major]: translate('major'),
        [UpdateSettingsOptions.notSet]: translate('notSet'),
        [UpdateSettingsOptions.disabled]: translate('disabled'),
        [UpdateSettingsOptions.doNotTouch]: translate('doNotTouch'),
    };

    return (option: UpdateSettingsOption) => translationsMap[option];
};

export default useUpdateSettingsOptionTranslate;
