// Copyright 2024. WebPros International GmbH. All rights reserved.

const scope = {
    /**
     * Terms & conditions
     */
    TERMS: 'optandc',

    /**
     * Contact
     */
    CONTACT: 'opcuseco',

    /**
     * Right of Revocation
     */
    REVOCATION: 'opcancelation',

    /**
     * Security
     */
    SECURITY: 'opsecurity',

    /**
     * Legal Info
     */
    LEGAL: 'opimprint',

    /**
     * Privacy Policy
     */
    POLICY: 'opprivacy',
} as const;

export type Scope = typeof scope;

export default scope;
