// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import CompanyName from '@platform360/licenses/web/units/cleverbridge/components/CompanyName';
import { MerchantOfRecordType } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';

const PaymentInfo = ({ merchantOfRecordType }: { merchantOfRecordType: MerchantOfRecordType }) => {
    const translate = useTranslate('Keys.CB');

    return (
        <Paragraph>
            {translate('paymentInfo', {
                companyName: <CompanyName merchantOfRecordType={merchantOfRecordType} />,
            })}
        </Paragraph>
    );
};

export default PaymentInfo;
