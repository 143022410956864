// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getInstallationOutdatedItems } from '@platform360/security-dashboard/web/api/installations';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseInstallationOutdatedItemsQueryVariables = {
    installationId: number;
};

export type UseInstallationOutdatedItemsQueryData = PromiseValue<
    ReturnType<typeof getInstallationOutdatedItems>
>;

const useInstallationOutdatedItemsQuery = createQuery<
    UseInstallationOutdatedItemsQueryVariables,
    UseInstallationOutdatedItemsQueryData
>({
    queryName: 'security-dashboard/useInstallationOutdatedItemsQuery',
    fetcher: getInstallationOutdatedItems,
    useQuery,
});

export default useInstallationOutdatedItemsQuery;
