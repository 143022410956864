// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';
import { SetPasswordUseCase } from '@platform360/auth/web/types';

type FetchTokenStatusResponse = {
    email: string;
};

export const resetPassword = (email: string) =>
    apiClient.post('/auth/passwords/start-reset', { email });
export const changePassword = ({
    password,
    useCase,
}: {
    password: string;
    useCase: SetPasswordUseCase;
}) => apiClient.post('/auth/passwords', { password, useCase });
export const fetchTokenStatus = async () => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<FetchTokenStatusResponse>>('/auth/passwords');

    return data;
};

export const logSetPassword = async (expired: boolean) => {
    await apiClient.get('/auth/passwords/log-set-password', {
        params: { expired },
    });
};
