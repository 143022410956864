// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Action } from 'redux';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';
import toggleFetching from '@platform360/licenses/web/units/extensions/actions/buyExtension/toggleFetching';
import doRequest from '@platform360/licenses/web/api/doRequest';
import setOnlineStoreData from '@platform360/licenses/web/units/extensions/actions/buyExtension/setOnlineStoreData';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '@platform360/licenses/web/store/types';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import previewCreate from '@platform360/licenses/web/api/ka/extensions/previewCreate';

const calculate =
    (): ThunkAction<void, StoreState, unknown, Action<string>> => async (dispatch, getState) => {
        const state = getState();
        const {
            selectedProduct,
            selectedPaygrProducts = {},
            selectedSubscription: subscriptionId,
        } = buyExtensionSelector(state);
        const { licenseId } = upsellSelector(state);

        if (selectedProduct && subscriptionId) {
            dispatch(toggleFetching(true));

            const calculatedSubscriptionState = await doRequest<CalculatedSubscriptionState>(
                previewCreate({
                    keyNumber: licenseId,
                    subscriptionId,
                    items: [
                        { productId: Number(selectedProduct), quantity: 1 },
                        ...Object.entries(selectedPaygrProducts)
                            .filter(([, quantity]) => Number(quantity) > 0)
                            .map(([productId, quantity]) => ({
                                productId: Number(productId),
                                quantity,
                            })),
                    ],
                }),
            );

            dispatch(setOnlineStoreData(calculatedSubscriptionState));

            dispatch(toggleFetching(false));
        }
    };

export default calculate;
