// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    ApplicationSettings,
    FailedLoginHandler,
} from '@platform360/libs/shared-web/application-settings/context';
import WebProsLogo from '@platform360/libs/shared-web/components/WebProsLogo';
import Footer from '@platform360/libs/shared-web/components/Footer';
import AuthLayout from '@platform360/auth/web/components/AuthLayout';
import PlatformLogo from '@platform360/libs/shared-web/components/PlatformLogo';
import PleskHomePage from '@platform360/web-installer/web/components/PleskHomePage';
import PlatformNotFoundPage from '@platform360/libs/shared-web/components/PlatformNotFoundPage';
import Sidebar from '@platform360/auth/web/components/AuthLayout/Sidebar';
import { useSignUp } from '@platform360/auth/web/mutations';
import { legalDocumentsLoader } from '@platform360/app/web/helpers/legalDocumentsLoader';
import {
    CookiesList,
    cookieModify,
    cookieWithdraw,
} from '@platform360/app/web/components/CookieManager';
import { lazy } from 'react';
import qs from 'qs';
import { TermsOfUseLink } from '@platform360/auth/web/components/TermsOfUseLink';

const PleskEula = lazy(async () => ({ default: (await legalDocumentsLoader()).PleskEula }));
const Privacy = lazy(async () => ({ default: (await legalDocumentsLoader()).Privacy }));
const Terms = lazy(async () => ({ default: (await legalDocumentsLoader()).Terms }));
const DataProcessingAgreement = lazy(async () => ({
    default: (await legalDocumentsLoader()).DataProcessingAgreement,
}));
const CookiesContent = lazy(async () => ({
    default: (await legalDocumentsLoader()).CookiesContent,
}));
const failedLoginHandler: FailedLoginHandler = ({ redirectUrl, navigate }): void => {
    if (!redirectUrl) {
        navigate('/auth/login');
        return;
    }
    const redirectUrlQuery = redirectUrl.substring(redirectUrl.indexOf('?') + 1);
    const { installId, authChecked } = qs.parse(redirectUrlQuery);
    navigate(`/web-installer/email-confirmation?${qs.stringify({ authChecked, installId })}`);
};

const getPleskApplicationSettings: ApplicationSettings = {
    document: {
        title: 'Plesk Web Installer',
        favicon: '/favicon.ico',
        darkThemeDataAttribute: 'legacy-dark',
    },
    logo: <PlatformLogo />,
    homePage: {
        isPrivate: false,
        component: <PleskHomePage />,
    },
    // TODO replace with get-plesk-com not-found page
    notFoundPage: <PlatformNotFoundPage />,
    header: {
        hideDarkThemeSwitcher: true,
        apiTokensEnabled: false,
        businessProfileEnabled: false,
        globalSearchEnabled: false,
        dotsMenuEnabled: false,
        mainMenuEnabled: false,
        baseUrl: '/',
    },
    footer: <Footer logo={<WebProsLogo isLink />} />,
    authLayout: AuthLayout,
    authSidebar: Sidebar,
    auth: {
        signUpMutation: useSignUp,
        branding: 'platform',
        termsOfUseLink: <TermsOfUseLink />,
        preferredSignup: false,
        areConsentsShown: true,
        signUpEmailFieldDescriptionShown: true,
        shouldNotifyLogout: true,
    },
    defaultLoginRedirect: '/',
    failedLoginHandler,
    // TODO legal pages layout should be reworked
    legalPage: {
        dpa: <DataProcessingAgreement />,
        pleskEula: <PleskEula />,
        privacy: <Privacy />,
        terms: <Terms />,
        cookiesContent: <CookiesContent cookiesList={<CookiesList />} />,
    },
    cookieManager: {
        cookieModify,
        cookieWithdraw,
    },
    licencesModule: {},
    usePlatform360LocaleCode: true,
};

export default getPleskApplicationSettings;
