// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FormFieldSelect, SelectOption, Text, TranslationProps } from '@plesk/ui-library';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import './UpdateSettingsSelect.module.css';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';
import { ReactNode } from 'react';

export type UpdateSettingsSelectOpion = {
    label: TranslationProps;
    value: UpdateSettingsOption | SafeUpdateOption | 'parent';
    description?: TranslationProps;
};

type UpdateSettingsSelectProps = {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fill';
    name: string;
    label: ReactNode | null;
    fullDescription?: ReactNode;
    options: UpdateSettingsSelectOpion[];
    className?: string;
};

export const UpdateSettingsSelect = ({
    options,
    size = 'fill',
    ...props
}: UpdateSettingsSelectProps) => (
    <FormFieldSelect size={size} {...props}>
        {options.map(({ value, label, description }) => (
            <SelectOption key={value} value={value} label={label}>
                <div>
                    {label}
                    {description !== undefined && (
                        <Text
                            className="label-description"
                            component="div"
                            fontSize="sm"
                            intent="muted"
                        >
                            {description}
                        </Text>
                    )}
                </div>
            </SelectOption>
        ))}
    </FormFieldSelect>
);
