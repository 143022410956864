// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TimezoneDialog } from './TimezoneDialog';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useMemo, useState } from 'react';
import { parseTimezone, getTimezones } from './helpers';
import { TimezoneView } from './TimezoneView';
import { useUpdateCurrentUserMutation } from '@platform360/accounts/web/mutations';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const TimezoneContainer = () => {
    const translate = useTranslate('accounts.profile.Timezone');
    const currentUser = useCurrentUser();
    const [timezone, setTimezone] = useState(currentUser.timezone);
    const [defaultRegion] = parseTimezone(currentUser.timezone);
    const [region, setRegion] = useState(defaultRegion);
    const [allTimezones, regions] = useMemo(() => getTimezones(), []);
    const timezones = allTimezones.filter((zone) => zone.region === region);
    const [isEdit, setIsEdit] = useState(false);
    const { successToast } = useToaster();
    const { mutate, isPending: isLoading } = useUpdateCurrentUserMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
            setIsEdit(false);
        },
    });
    const handleEdit = () => {
        setRegion(defaultRegion);
        setTimezone(currentUser.timezone);
        setIsEdit(true);
    };
    const handleSave = () => {
        mutate({ timezone });
    };
    const handleRegionChange = (region: string) => {
        setRegion(region);
        setTimezone(
            allTimezones.find((timezone) => timezone.region === region)?.name ||
                currentUser.timezone,
        );
    };
    const handleTimezoneChange = (timezone: string) => {
        setTimezone(timezone);
    };
    const handleCancel = () => {
        setIsEdit(false);
    };

    return (
        <>
            <TimezoneView timezone={currentUser.timezone} onEdit={handleEdit} />
            <TimezoneDialog
                timezone={timezone}
                region={region}
                timezones={timezones}
                regions={regions}
                isOpen={isEdit}
                isLoading={isLoading}
                onRegionChange={handleRegionChange}
                onTimezoneChange={handleTimezoneChange}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        </>
    );
};
