// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiTokenWithSecret, createApiToken } from '@platform360/accounts/web/api/api-tokens';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import useApiTokensQuery from '@platform360/accounts/web/queries/useApiTokensQuery';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

type UseCreateApiTokenMutationOptions = {
    onSuccess: (apiToken: ApiTokenWithSecret) => void;
};

const useCreateApiTokenMutation = ({ onSuccess }: UseCreateApiTokenMutationOptions) => {
    const queryClient = useQueryClient();
    const translateValidationErrors = useTranslateValidationErrors();

    const { error, ...rest } = useMutation({
        mutationFn: createApiToken,
        onSuccess: async (apiToken) => {
            await queryClient.cancelQueries({
                queryKey: useApiTokensQuery.getQueryKey(),
            });
            useApiTokensQuery.setQueriesData(
                queryClient,
                useApiTokensQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return [];
                    }

                    if (cache.find(({ id }) => id === apiToken.id)) {
                        return cache;
                    }

                    return [apiToken, ...cache];
                },
            );

            onSuccess(apiToken);
        },
    });

    return { ...rest, errors: translateValidationErrors(getValidationErrors(error)) };
};

export default useCreateApiTokenMutation;
