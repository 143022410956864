// Copyright 2024. WebPros International GmbH. All rights reserved.

import { fetchAllKeysOnce, resetKeyLists } from '@platform360/licenses/web/store/index';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '@platform360/licenses/web/store/types';
import { Action } from 'redux';

const refreshKeyList =
    (): ThunkAction<void, StoreState, undefined, Action<string>> => (dispatch) => {
        dispatch(resetKeyLists());
        dispatch(fetchAllKeysOnce());
    };

export default refreshKeyList;
