// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';
import { isCertErrorCode } from '@platform360/libs/common/cert-error-codes';
import InvalidCertificateKBArticleLink from '@platform360/libs/shared-web/components/InvalidCertificateKBArticleLink';
import {
    addingServer,
    AddingServerPayload,
    AddingServerResponse,
} from '@platform360/server-inventory/web/api/servers';
import { ValidationError } from '@platform360/libs/shared-web/helpers/translateErrors';

export type UseAddingServerOptions = {
    onSuccess?: (data: AddingServerResponse) => void;
    onSettled?: (data: AddingServerResponse | undefined, error: AxiosError | null) => void;
};

/**
 * Manually process form validation errors to mix kb article link to the certificate validation errors.
 */
const toValidationErrors = (
    // eslint-disable-next-line deprecation/deprecation
    axiosError: UntypedAxiosError | null,
): ValidationError[] | undefined => {
    if (axiosError?.response?.data?.type !== 'validation-error') {
        return undefined;
    }

    const errors = axiosError.response.data.errors.map((error: { type?: string }) => {
        if (error.type && isCertErrorCode(error.type)) {
            return {
                ...error,
                params: {
                    kbLink: <InvalidCertificateKBArticleLink />,
                },
            };
        }

        return error;
    });

    return errors;
};

const useAddingServer = ({ onSuccess, onSettled }: UseAddingServerOptions = {}) => {
    const { error, ...rest } = useMutation({
        mutationFn: (payload: AddingServerPayload) => addingServer(payload),
        onSuccess,
        onSettled,
    });

    return {
        errors: toValidationErrors(error),
        ...rest,
    };
};

export default useAddingServer;
