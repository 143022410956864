// Copyright 2024. WebPros International GmbH. All rights reserved.
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { StatusMessage, Text } from '@plesk/ui-library';
import KbInstructionsFixLink from '@platform360/libs/shared-web/components/KbInstructionsFixLink';

type OutdatedAgentErrorOptions = {
    serverVersion: string | null;
};

const OutdatedAgentError = ({ serverVersion }: OutdatedAgentErrorOptions) => {
    const {
        securityDashboard: { wpAgentMinSupportedVersion },
    } = useConfig();
    const translate = useTranslate('security-dashboard.Servers.OutdatedAgentError');

    return (
        <StatusMessage intent="danger">
            <Text bold component="p">
                {translate('message')}
            </Text>
            <Text component="p">
                {translate('agentVersion', {
                    version: serverVersion || '_',
                })}{' '}
                {translate('updateDescription', {
                    wpAgentMinSupportedVersion,
                })}{' '}
                <KbInstructionsFixLink href="https://support.plesk.com/hc/en-us/articles/15415210762391" />
            </Text>
        </StatusMessage>
    );
};
export default OutdatedAgentError;
