// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { updateProfile } from '@platform360/accounts/web/api/me';
import { UpdateProfileResponse } from '@platform360/accounts/shared/api-contract';
import { usePatchCurrentUserData } from '@platform360/accounts/web/queries/useCurrentUserQuery';

type UseUpdateCurrentUserMutationOptions = {
    onSuccess?: (data: UpdateProfileResponse) => void;
    onMutate?: () => void;
    onError?: () => void;
};

export const useUpdateCurrentUserMutation = (options: UseUpdateCurrentUserMutationOptions = {}) => {
    const patchCurrentUserData = usePatchCurrentUserData();

    return useMutation({
        mutationFn: updateProfile,
        onSuccess: async (data) => {
            await patchCurrentUserData(data);
            options.onSuccess?.(data);
        },
        onMutate: options.onMutate,
        onError: options.onError,
    });
};
