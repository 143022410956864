// Copyright 2024. WebPros International GmbH. All rights reserved.

import { List, Radio } from '@plesk/ui-library';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import Cost from '@platform360/licenses/web/components/Cost';
import Product from '@platform360/licenses/web/units/extensions/types/Product';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Currency from '@platform360/licenses/web/types/currency';

type ProductsListProps = {
    selected: string;
    products: Product[];
    onSelect: (productId: string) => void;

    period: BillingPeriod;
    currency: Currency;

    hasSubscriptions: boolean;
};

const ProductsList = ({
    selected,
    products,
    onSelect,

    period,
    currency,

    hasSubscriptions,
}: ProductsListProps) => (
    <List
        selection={[selected]}
        columns={[{ key: 'buy' }, { key: 'product' }, { key: 'price' }]}
        data={products.map(({ productName, productId, price }: Product) => ({
            buy: (
                <Radio
                    data-type={'MultiOffer--button'}
                    name={productName}
                    checked={selected === productId}
                    disabled={!hasSubscriptions}
                    onChange={() => onSelect(productId)}
                />
            ),
            product: productName,
            key: productId,
            price: (
                <BillingInterval period={period}>
                    <Cost value={price} currency={currency} />
                </BillingInterval>
            ),
        }))}
        vertical={false}
    />
);

export default ProductsList;
