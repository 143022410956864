// Copyright 2024. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import Page from '@platform360/libs/shared-web/components/Page';
import { Outlet } from 'react-router-dom';
import Header from '@platform360/security-dashboard/web/components/Layout/Header';
import Onboarding from '@platform360/security-dashboard/web/components/Onboarding';
import useWebsocketEvents from './useWebsocketEvents';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import Loading from '@platform360/libs/shared-web/components/Loading';
import ActivateTrial from './ActivateTrial';
import { useServersSummaryQuery } from '@platform360/security-dashboard/web/queries';
import RetentlyWidget from '@platform360/libs/shared-web/components/RetentlyWidget';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import styles from './Layout.module.less';
import { CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';

const Layout = () => {
    const { data: licenseData, isLoading: isLoadingLicense } = useLicenseQuery();
    const { data: serversSummaryData } = useServersSummaryQuery();
    const { auth0Id, teamGuid, createdAt } = useCurrentUser();

    useWebsocketEvents();

    if (isLoadingLicense) {
        return <Loading />;
    }

    if (licenseData === null) {
        return (
            <Page tabs={[]}>
                <ActivateTrial />
            </Page>
        );
    }

    const isEmpty = serversSummaryData?.total === 0;

    return (
        <Page
            contentAddon={<Header isEmpty={isEmpty} serversSummaryData={serversSummaryData} />}
            hideSupportChat={licenseData?.customerType === CustomerTypes.vps}
        >
            <div className={classNames({ [styles.emptyContainer ?? '']: isEmpty })}>
                {isEmpty && <Onboarding />}
                <div className={styles.content}>
                    <Outlet />
                </div>
            </div>
            <RetentlyWidget
                tags="wpguardian"
                campaignId="65d86d102d986eb8807104d2"
                trackingId="606c61a4c8c2d36d01246bc4"
                userId={auth0Id ?? ''}
                email={`team-${teamGuid}@wpguardian.io`}
                userCreationDate={createdAt ? new Date(createdAt) : new Date()}
            />
        </Page>
    );
};

export default Layout;
