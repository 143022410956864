// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Section } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { isCertErrorCode } from '@platform360/libs/common/cert-error-codes';
import { ValidationError } from '@platform360/libs/shared-web/helpers/translateErrors';
import { StatusInfo, StatusSuccess } from './status';
import { ServerAccessError } from './ServerAccessError';
import { ServerCertificateError } from './ServerCertificateError';

export type AddServerControlStatusProps = {
    error?: ValidationError;
};

const AddServerControlStatus = ({ error }: AddServerControlStatusProps) => {
    const translate = useTranslate('Servers.AddServerControlStatus');
    if (error === undefined) {
        return (
            <>
                <Section>
                    <StatusInfo>{translate('info.access')}</StatusInfo>
                </Section>
                <Section>
                    <StatusInfo>{translate('info.certificate')}</StatusInfo>
                </Section>
            </>
        );
    }

    if (isCertErrorCode(error.type)) {
        return (
            <>
                <Section>
                    <StatusSuccess>{translate('success.access')}</StatusSuccess>
                </Section>
                <Section>
                    <ServerCertificateError errorCode={error.type} />
                </Section>
            </>
        );
    }

    return (
        <>
            <Section>
                <ServerAccessError error={error} />
            </Section>
            <Section>
                <StatusInfo>{translate('info.certificate')}</StatusInfo>
            </Section>
        </>
    );
};

export default AddServerControlStatus;
