// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Products } from '@platform360/server-inventory/shared/product-type';
import { Server } from '@platform360/server-inventory/web/types';
import { productName } from '@platform360/server-inventory/web/helpers/productName';
import ServerStatusMessage from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/ServerStatusMessage';
import ServerUpdatesButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/ServerUpdatesButton';
import { UpdateServerTriggers } from '@platform360/server-inventory/web/helpers/analytics/ServerInventoryAnalyticsEvents';

export type OutdatedStatusMessageProps = {
    server: Server;
};

const OutdatedStatusMessage = ({ server }: OutdatedStatusMessageProps) => {
    const translate = useTranslate(
        'server-inventory.Servers.ViewServer.GeneralInfo.StatusMessages.OutdatedStatusMessage',
    );
    if (server.product !== Products.plesk) {
        return null;
    }

    return (
        <ServerStatusMessage
            intent="warning"
            data-type="outdated-status-message"
            errorCode={'unknown'}
            message={translate('message', {
                version: server.productVersion?.version ?? '_',
            })}
            fixMessage={translate('fixDescription', {
                link: (text) => (
                    <Link href="https://www.plesk.com/lifecycle-policy/" target="_blank">
                        {text}
                    </Link>
                ),
            })}
            actions={
                <ServerUpdatesButton
                    intent="primary"
                    server={server}
                    trigger={UpdateServerTriggers.serverInfoOutdatedWarningUpdateServerButton}
                >
                    {translate('goToUpdatesButton', { product: productName[server.product] })}
                </ServerUpdatesButton>
            }
        />
    );
};

export default OutdatedStatusMessage;
