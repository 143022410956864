// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import { createLabel } from '@platform360/security-dashboard/web/api/labels';
import { isValidationError } from '@platform360/libs/shared-web/helpers/apiErrors';

const useCreateLabelMutation = () => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useCreateLabelMutation');
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: createLabel,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: useLabelsQuery.getQueryKey(),
            });

            successToast(translate('success'));
        },
        onError: (e) => {
            if (isValidationError(e)) return;

            errorToast(translate('failed'));
        },
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useCreateLabelMutation;
