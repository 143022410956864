// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getTeamInvitations } from '@platform360/accounts/web/api/team';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseTeamInvitationsQueryVariables = undefined;

export type UseTeamInvitationsQueryData = PromiseValue<ReturnType<typeof getTeamInvitations>>;

const useTeamInvitationsQuery = createQuery<
    UseTeamInvitationsQueryVariables,
    UseTeamInvitationsQueryData
>({
    queryName: 'accounts/useTeamInvitationsQuery',
    fetcher: getTeamInvitations,
    useQuery: (options) =>
        useQuery({
            ...options,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            meta: {
                defaultErrorHandler: false,
            },
        }),
});

export default useTeamInvitationsQuery;
