// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Drawer, Section, Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import FormFieldSelect from './FormFieldSelect';
import useFormSections, {
    SectionConfig,
} from '@platform360/accounts/web/components/BusinessProfile/useFormSections';
import { UpdateBusinessProfilePayload } from '@platform360/accounts/web/api/me';
import { FormValue, FormValues } from './types';
import { BusinessProfileValidName } from '@platform360/libs/common/business-profile-valid-name';

export type BusinessProfileProps = {
    isOpen: boolean;
    defaultValues: UpdateBusinessProfilePayload;
    onClose: () => void;
    onSubmit: (values: UpdateBusinessProfilePayload) => void;
    onLater?: () => void;
    state?: 'submit';
};

const isNotEmptyValue = (value: FormValue): value is string | string[] => {
    if (Array.isArray(value)) {
        return value.length > 0;
    }

    return value !== undefined;
};

const clearUnusedValues = (
    values: FormValues,
    sections: SectionConfig[],
): UpdateBusinessProfilePayload => {
    const result: UpdateBusinessProfilePayload = {};

    sections.forEach((section) => {
        section.fields.forEach((f) => {
            const value = values[f.name];
            if (isNotEmptyValue(value) && (f.isVisible === undefined || f.isVisible(result))) {
                // TODO fix the typing
                result[f.name as BusinessProfileValidName] = value;
            }
        });
    });

    return result;
};

const BusinessProfile = ({
    isOpen,
    onLater,
    defaultValues,
    onClose,
    onSubmit,
    state,
    ...props
}: BusinessProfileProps) => {
    const translate = useTranslate('accounts.BusinessProfile');
    const [values, setValues] = useState<UpdateBusinessProfilePayload>(defaultValues);
    const [isEmpty, setIsEmpty] = useState(() => Object.keys(defaultValues).length === 0);
    const sections = useFormSections();

    type Key = keyof UpdateBusinessProfilePayload;
    const handleFormFieldChange = (key: Key, value: UpdateBusinessProfilePayload[Key]) => {
        const newValues = clearUnusedValues({ ...values, [key]: value }, sections);
        setIsEmpty(Object.keys(newValues).length === 0);
        setValues(newValues);
    };
    const handleSubmit = () => {
        onSubmit(values);
    };
    const laterButton = onLater ? (
        <Button onClick={onLater} key="laterButton" data-type="answer-later-button">
            {translate('answerLater')}
        </Button>
    ) : undefined;

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onLater ? undefined : onClose}
            title={translate('title')}
            data-type="business-profile-drawer"
            form={{
                values,
                state,
                onSubmit: handleSubmit,
                vertical: true,
                submitButton: {
                    children: onLater ? translate('done') : translate('save'),
                    disabled: isEmpty,
                    'data-type': 'save-button',
                },
                applyButton: false,
                cancelButton: onLater ? false : undefined,
                additionalButtons: [laterButton],
                onFieldChange: handleFormFieldChange,
            }}
            {...props}
        >
            {sections.map(({ title, fields }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Section title={title} key={i.toString()}>
                    {fields.map(
                        ({ isVisible, ...props }, i) =>
                            (!isVisible || isVisible(values)) && (
                                // eslint-disable-next-line react/no-array-index-key
                                <FormFieldSelect key={i.toString()} size="xl" {...props} />
                            ),
                    )}
                </Section>
            ))}
        </Drawer>
    );
};

export default BusinessProfile;
