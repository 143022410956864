// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ItemList } from '@plesk/ui-library';
import styles from './WidgetsList.module.css';
import useWidgetsList from './useWidgetsList';
import HomePageWidget from '@platform360/libs/shared-web/components/HomePageWidget';
import MonitoringWidget from '@platform360/monitoring/web/components/HomePageWidget';
import { ReactElement } from 'react';

const WidgetsList = () => {
    const widgets = useWidgetsList();

    return (
        <ItemList className={styles.root} gap="xl" stretchable minColumnWidth={300}>
            <MonitoringWidget />
            {
                widgets.map(({ navigateTo, ...rest }) => (
                    <HomePageWidget key={navigateTo} navigateTo={navigateTo} {...rest} />
                )) as unknown as ReactElement
            }
        </ItemList>
    );
};

export default WidgetsList;
