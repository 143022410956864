// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SetStateAction, useState } from 'react';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import useFilter from '@platform360/libs/shared-web/helpers/useFilter';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { RoutableLabelsDrawer } from '@platform360/security-dashboard/web/components/LabelsDrawer';
import InstallationLabelsList from '@platform360/security-dashboard/web/components/Installations/InstallationLabelsList';
import InstallationSummary from '@platform360/security-dashboard/web/components/Installations/InstallationSummary';
import Onboarding from '@platform360/security-dashboard/web/components/Installations/Onboarding';
import SiteHealthColumn from '@platform360/security-dashboard/web/components/Installations/SiteHealthColumn';
import Toolbar from '@platform360/security-dashboard/web/components/Installations/Toolbar';
import WebsiteDrawer from '@platform360/security-dashboard/web/components/Installations/WebsiteDrawer';
import {
    useAssignInstallationLabelsMutation,
    useInstallationsRefreshMutation,
    useInstallationsUpdaterMutation,
    useRemoveInstallationLabelsMutation,
    useUpdateInstallationsProtectionStatusMutation,
} from '@platform360/security-dashboard/web/mutations';
import useInstallationsDetachMutation from '@platform360/security-dashboard/web/mutations/useInstallationsDetachMutation';
import {
    useInstallationsQuery,
    useServersQuery,
} from '@platform360/security-dashboard/web/queries';
import { useLoadingRows } from '@platform360/security-dashboard/web/hooks/useLoadingRows';
import {
    Installation,
    SitesHealthFilter,
    SITE_HEALTH_FILTER_ID,
    Task,
    UPDATE_TASKS,
    LABEL_IDS_FILTER,
    DRAWER_TAB_VULNERABILITIES_COMPONENT_FILTER_ID,
    DRAWER_TAB_OUTDATED_FILTER_ID,
} from '@platform360/security-dashboard/web/types';
import { Status, Text } from '@plesk/ui-library';
import { useQueryClient } from '@tanstack/react-query';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import punycode from 'punycode/';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import styles from './Installations.module.css';
import SiteActionsColumn from './SiteActionsColumn';
import FilterPanel from './Toolbar/FilterPanel';
import { AssignRemoveLabelsOptions } from '@platform360/security-dashboard/web/components/LabelsPopover';
import SingleSettingsDrawer from '@platform360/security-dashboard/web/components/Installations/SettingsDrawer/SingleSettingsDrawer';
import MassSettingsDrawer from '@platform360/security-dashboard/web/components/Installations/SettingsDrawer/MassSettingsDrawer';
import ListEmptyViewFilter from '@platform360/security-dashboard/web/components/ListEmptyViewFilter';
import { getInstallations } from '@platform360/security-dashboard/web/api/installations';
import { useListFetcher } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import {
    UnprocessableInstallationsDialog,
    UnprocessableInstallationsDialogData,
} from '@platform360/security-dashboard/web/components/UnprocessableInstallationsDialog';
import { FilteredDataListTitle } from '@platform360/security-dashboard/web/components/FilteredDataListTitle';
import { useSearchParams } from 'react-router-dom';
import {
    DETACH_INSTALLATIONS_TASK_TYPE,
    REFRESH_INSTALLATIONS_TASK_TYPE,
} from '@platform360/security-dashboard/shared/constants';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

type InstallationWithIntent = Installation & {
    intent: 'inactive' | undefined;
};

const RoutableWebsiteDrawer = withRouteBinding(WebsiteDrawer);
const RoutableSingleSettingsDrawer = withRouteBinding(SingleSettingsDrawer);
const RoutableMassSettingsDrawer = withRouteBinding(MassSettingsDrawer);

type InstallationListOperation = 'update' | 'protectionSwitch' | 'refresh' | 'detach';

const hasActiveTask = (tasks: Task[]) =>
    tasks.some(({ type }) =>
        [...UPDATE_TASKS, REFRESH_INSTALLATIONS_TASK_TYPE, DETACH_INSTALLATIONS_TASK_TYPE].includes(
            type,
        ),
    );

const Installations = () => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const [selection, setSelection] = useState<number[]>([]);
    const [expandedRows, setExpandedRows] = useState<(number | string)[]>([]);
    const expandRows = (ids: number[]) => setExpandedRows([...expandedRows, ...ids]);
    const [unprocessableDialogData, setUnprocessableDialogData] =
        useState<UnprocessableInstallationsDialogData | null>(null);

    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const translate = useTranslate('security-dashboard.Installations');
    const searchFilter = useSearch('search', () => analyticsEvents.wpSearchSubmit());

    const [searchParams, setSearchParams] = useSearchParams();

    const getSearchField = () => {
        const { value } = searchFilter;
        if (!value) {
            return undefined;
        }
        if (value === searchParams.get('searchVulnerability')) {
            return 'vulnerability';
        }
        if (value === searchParams.get('searchServer')) {
            return 'server';
        }
        return 'query';
    };

    const searchField = getSearchField();

    const search = {
        ...searchFilter,
        onSearch: (newValue: string) => {
            searchParams.delete('searchVulnerability');
            searchParams.delete('searchServer');
            newValue ? searchParams.set('search', newValue) : searchParams.delete('search');

            setSearchParams(searchParams);
            searchFilter.onSearch(newValue);
        },
    };

    const queryClient = useQueryClient();
    const {
        setFilter,
        resetFilter,
        filter: sitesHealthFilter,
    } = useFilter<SitesHealthFilter>(SITE_HEALTH_FILTER_ID);
    const {
        setFilter: setLabelIdsFilter,
        resetFilter: resetLabelIdsFilter,
        filter: labelIdsFilter,
    } = useFilter(LABEL_IDS_FILTER);
    const vulnerabilitiesComponentFilter = useFilter<InstallationComponent>(
        DRAWER_TAB_VULNERABILITIES_COMPONENT_FILTER_ID,
    );
    const outdatedFilter = useFilter<InstallationComponent>(DRAWER_TAB_OUTDATED_FILTER_ID);
    const pagination = usePagination('wpsd-all-installations');
    const { loadingRows, loadingRowsState, setLoadingRows, unsetLoadingRows } = useLoadingRows<
        number,
        InstallationListOperation
    >();

    const { warningToast } = useToaster();

    const { mutate: assignInstallationLabels } = useAssignInstallationLabelsMutation();
    const { mutate: removeInstallationLabels } = useRemoveInstallationLabelsMutation();

    const clearSelection = () => setSelection([]);

    const handleFilterSet = (
        filter: SetStateAction<SitesHealthFilter[]>,
        labelsId: number[],
        placement = 'installations-page',
    ) => {
        setSidebarOpen(false);
        if (filter.length === 0) {
            analyticsEvents.wpFilterAllClick();
        } else {
            analyticsEvents.wpFilterAlertsClick();
        }
        if (labelsId.length !== 0) {
            analyticsEvents.wpFilterLabelsClick(placement);
        }
        clearSelection();
        pagination.resetPagination();
        setFilter(filter);
        setLabelIdsFilter(labelsId.map((i) => i.toString()));
    };
    const handleFilterReset = () => {
        setSidebarOpen(false);
        analyticsEvents.wpFilterAllClick();
        clearSelection();
        pagination.resetPagination();
        resetFilter();
        resetLabelIdsFilter();
    };

    useEffectOnce(() => {
        analyticsEvents.wpWordpressSitesShown();
    });

    const variables = {
        filter: search.debouncedValue,
        page: pagination.current,
        pageSize: pagination.itemsPerPage,
        sitesHealthFilter,
        labelIdsFilter,
    };

    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
        refetch,
    } = useInstallationsQuery({
        variables,
    });

    useListFetcher(useInstallationsQuery.getQueryKey(variables), getInstallations);

    const installations: InstallationWithIntent[] =
        data?.data.map(({ managed, operable, ...installationData }) => ({
            ...installationData,
            managed,
            operable,
            intent: managed && operable ? undefined : 'inactive',
        })) || [];

    const selectedInstallations = installations.filter(({ id }) => selection.includes(id));

    const selectedInstallation = selection.length === 1 ? selectedInstallations[0] : undefined;

    const { mutateAsync: checkSecurityInstallations } = useInstallationsRefreshMutation();
    const { mutateAsync: detachInstallations } = useInstallationsDetachMutation();
    const { mutateAsync: updateInstallations } = useInstallationsUpdaterMutation();
    const { mutateAsync: switchProtectStatus } = useUpdateInstallationsProtectionStatusMutation();

    const handleInstallationsUpdate = async (installationsIds: number[]) => {
        clearSelection();
        setLoadingRows(installationsIds, 'update');
        handleUnprocessableInstallationsModalVisibility(installationsIds, {
            isSingleOperationAllowed: true,
        });
        try {
            await updateInstallations(installationsIds);
        } finally {
            unsetLoadingRows(installationsIds, 'update');
        }
    };

    const handleRefresh = async (installationsIds: number[]) => {
        clearSelection();
        setLoadingRows(installationsIds, 'refresh');
        try {
            await checkSecurityInstallations(installationsIds);
        } finally {
            unsetLoadingRows(installationsIds, 'refresh');
        }
    };

    const handleDetach = async (installationsIds: number[]) => {
        setLoadingRows(installationsIds, 'detach');
        try {
            await detachInstallations(installationsIds);
        } finally {
            handleInstallationsDetached();
            unsetLoadingRows(installationsIds, 'detach');
        }
    };

    const handleInstallationsDetached = () => {
        const { onSelect, current, itemsPerPage } = pagination;

        if (!data) {
            return;
        }

        const totalPages = Math.ceil(data.totalCount / itemsPerPage);

        if (current > 1 && current >= totalPages) {
            // Reset page to the previous one in case if all installations
            // from the last page were deleted.
            onSelect(current - 1);
            return;
        }

        // Removes installations pages query cache and refetch the current page data.
        queryClient.removeQueries({
            queryKey: useServersQuery.getQueryKey(),
        });
        void refetch();
    };

    const handleSwitchProtectionState = async (id: number, protect: boolean) => {
        setLoadingRows([id], 'protectionSwitch');
        try {
            await switchProtectStatus({
                installationIds: [id],
                protect,
                ignoreDoNotProtect: true,
            });
        } finally {
            unsetLoadingRows([id], 'protectionSwitch');
        }
    };

    const handleUnprocessableInstallationsModalVisibility = (
        installationIds: number[],
        { isSingleOperationAllowed }: { isSingleOperationAllowed: boolean },
    ) => {
        const affectedInstallations = installations.filter((installation) =>
            installationIds.includes(installation.id),
        );

        const operableNotManaged = affectedInstallations.filter(
            (installation) => !installation.managed && installation.operable,
        ).length;
        const notOperable = affectedInstallations.filter(
            (installation) => !installation.operable,
        ).length;

        if (operableNotManaged && (!isSingleOperationAllowed || installationIds.length > 1)) {
            setUnprocessableDialogData({
                notOperable,
                operableNotManaged,
            });
        } else if (notOperable) {
            warningToast(translate('sitesAreNotOperable', { notOperable }));
        }
    };

    const handleEnableInstallationsProtection = async (
        installationIds: number[],
        ignoreDoNotProtect: boolean,
        doNotProtectCount: number,
    ) => {
        analyticsEvents.wpMltProtectInstallationsConfirmationClick(installationIds.length, {
            ignoreDoNotProtect,
            doNotProtectCount,
            protectionDisabledTextShown: doNotProtectCount !== 0,
        });
        clearSelection();
        setLoadingRows(installationIds, 'protectionSwitch');
        handleUnprocessableInstallationsModalVisibility(installationIds, {
            isSingleOperationAllowed: false,
        });
        try {
            await switchProtectStatus({
                installationIds,
                protect: true,
                ignoreDoNotProtect,
            });
        } finally {
            unsetLoadingRows(installationIds, 'protectionSwitch');
        }
    };

    const handleAssignInstallationLabels = (data: AssignRemoveLabelsOptions) => {
        const { labelIds, entityIds: installationIds } = data;
        assignInstallationLabels({ installationIds, labelIds });

        analyticsEvents.wpLabelsAssignedToSites(installationIds.length, labelIds.length);
    };

    const handleRemoveInstallationLabels = (data: AssignRemoveLabelsOptions) => {
        const { labelIds, entityIds: installationIds } = data;
        removeInstallationLabels({ installationIds, labelIds });

        analyticsEvents.wpLabelsRemovedFromSites(installationIds.length, labelIds.length);
    };

    const columns: ListProps<Installation>['columns'] = [
        {
            key: 'website',
            type: 'title',
            title: translate('columns.website'),
            render: (installation) => {
                const title = (
                    <>
                        <Text bold intent={installation.operable ? undefined : 'muted'}>
                            {punycode.toUnicode(installation.displayTitle)}
                        </Text>
                        <InstallationLabelsList
                            installation={installation}
                            removeInstallationLabels={removeInstallationLabels}
                            onRequestProtection={() => {
                                void handleSwitchProtectionState(installation.id, true);
                            }}
                            onFilterByLabel={(label) => {
                                handleFilterSet(
                                    sitesHealthFilter,
                                    [label.id],
                                    'installations-label-dropdown',
                                );
                            }}
                        />
                    </>
                );

                if (installation.server.status === 'ok' && !installation.server.unsupportedAgent) {
                    return title;
                }

                return (
                    <span data-type="server-status-danger">
                        <Status intent="danger" compact>
                            &nbsp;
                        </Status>
                        {title}
                    </span>
                );
            },
        },
        {
            key: 'siteHealth',
            type: 'title',
            title: '',
            render: (installation) => {
                const isRowExpanded = expandedRows.includes(installation.id);

                const handleNotOperableClick = () => {
                    expandRows([installation.id]);
                    analyticsEvents.wpSglUnknownClick();
                };

                return (
                    <SiteHealthColumn
                        installation={installation}
                        onNotOperableClick={isRowExpanded ? undefined : handleNotOperableClick}
                    />
                );
            },
        },
        {
            key: 'actions',
            type: 'actions',

            render: (installation) => (
                <SiteActionsColumn
                    installation={installation}
                    installationsWithStartingRefresh={loadingRowsState.refresh}
                    installationsWithStartingUpdates={loadingRowsState.update}
                    installationsWithProtectSwitch={loadingRowsState.protectionSwitch}
                    installationsWithStartingDetach={loadingRowsState.detach}
                    handleInstallationsRefresh={handleRefresh}
                    handleInstallationsUpdate={handleInstallationsUpdate}
                    handleSwitchProtectionState={handleSwitchProtectionState}
                    handleAssignInstallationLabels={handleAssignInstallationLabels}
                    handleRemoveInstallationLabels={handleRemoveInstallationLabels}
                    handleDetach={handleDetach}
                />
            ),
        },
    ];

    const loadingRowsIds = installations
        .filter((installation) => hasActiveTask(installation.tasks))
        .map((installation) => installation.id)
        .concat(loadingRows);

    const mappedLabelIds = labelIdsFilter.map((i) => parseInt(i));

    return (
        <>
            <RoutableWebsiteDrawer
                openPath="health/:installationId/*"
                closePath="/security-dashboard/installations"
                vulnerabilitiesComponentFilter={vulnerabilitiesComponentFilter}
                outdatedFilter={outdatedFilter}
            />
            <RoutableSingleSettingsDrawer
                openPath=":installationId/update-settings"
                closePath="/security-dashboard/installations"
            />
            <RoutableMassSettingsDrawer
                openPath="mass-update-settings/:installationIds"
                closePath="/security-dashboard/installations"
                handleUnprocessableInstallationsModalVisibility={
                    handleUnprocessableInstallationsModalVisibility
                }
            />
            <RoutableLabelsDrawer closePath="/security-dashboard/installations" />
            {!!searchField && (
                <FilteredDataListTitle
                    searchField={searchField}
                    searchValue={search.value}
                    onReset={() => search.onSearch('')}
                />
            )}
            <div className={styles.installations}>
                <DataList<InstallationWithIntent>
                    toolbar={
                        <Toolbar
                            handleUnprocessableInstallationsModalVisibility={
                                handleUnprocessableInstallationsModalVisibility
                            }
                            selectedInstallations={selectedInstallations}
                            handleAssignInstallationLabels={handleAssignInstallationLabels}
                            handleRemoveInstallationLabels={handleRemoveInstallationLabels}
                            selectedInstallationDisplayTitle={selectedInstallation?.displayTitle}
                            onEnableProtection={handleEnableInstallationsProtection}
                            onInstallationsUpdate={(installationsIds) => {
                                analyticsEvents.wpMltUpdateSiteConfirmationClick(
                                    installationsIds.length,
                                );
                                void handleInstallationsUpdate(installationsIds);
                            }}
                            onInstallationsUpdateConfirmationShown={(installationsIds) => {
                                analyticsEvents.wpMltUpdateSiteClick(installationsIds.length);
                            }}
                            onProtectConfirmationShown={(installationsIds) => {
                                analyticsEvents.wpMltProtectInstallationsClick(
                                    installationsIds.length,
                                );
                            }}
                            onRefresh={(installationsIds) => {
                                analyticsEvents.wpMltRefreshSiteClick(installationsIds.length);
                                void handleRefresh(installationsIds);
                            }}
                            isSidebarOpen={isSidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                            filter={sitesHealthFilter}
                            labelIdsFilter={mappedLabelIds}
                        />
                    }
                    search={search}
                    rowKey="id"
                    loadingRows={loadingRowsIds}
                    emptyView={<Onboarding />}
                    filteredEmptyView={<ListEmptyViewFilter />}
                    columns={columns}
                    data={installations}
                    totalRows={Number(data?.totalCount)}
                    pagination={pagination}
                    loading={isLoading}
                    isPreviousData={isPreviousData}
                    filtered={sitesHealthFilter.length !== 0 || mappedLabelIds.length !== 0}
                    renderRowBody={(installation) => (
                        <InstallationSummary installation={installation} />
                    )}
                    sidebar={
                        isSidebarOpen ? (
                            <FilterPanel
                                healthFilter={sitesHealthFilter}
                                labelIds={mappedLabelIds}
                                onFiltersChange={handleFilterSet}
                                onReset={handleFilterReset}
                            />
                        ) : undefined
                    }
                    selection={selection}
                    onSelectionChange={setSelection}
                    expandedRows={expandedRows}
                    onExpandedRowsChange={setExpandedRows}
                />
            </div>
            <UnprocessableInstallationsDialog
                isOpen={!!unprocessableDialogData}
                onClose={() => {
                    setUnprocessableDialogData(null);
                }}
                data={unprocessableDialogData}
            />
        </>
    );
};

export default Installations;
