// Copyright 2024. WebPros International GmbH. All rights reserved.

import { GetRedirectUrlResponse } from '@platform360/app/web/api/redirect-gateway';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    Button,
    Heading,
    Paragraph,
    Status,
    StatusMessage,
    Toolbar,
    ToolbarGroup,
} from '@plesk/ui-library';

export type DefaultDestinationProps = {
    rootTranslate: ReturnType<typeof useTranslate>;
    translate: ReturnType<typeof useTranslate>;
    isRedirectUrlError: boolean;
    redirectUrl?: GetRedirectUrlResponse;
    redirectDirectly?: string;
};

const DefaultDestination = ({
    rootTranslate,
    translate,
    redirectUrl,
    isRedirectUrlError,
    redirectDirectly,
}: DefaultDestinationProps) => (
    <>
        <h2>{rootTranslate('title', { destination: translate('destinationTitle') })}</h2>
        {redirectUrl && <Status progress>{rootTranslate('progress')}</Status>}
        {isRedirectUrlError && (
            <>
                <StatusMessage intent="danger" data-type="error-message">
                    <Heading level={5}>{translate('errorTitle')}</Heading>
                    <Paragraph>{translate('errorDescription')}</Paragraph>
                </StatusMessage>
                {redirectDirectly && (
                    <Toolbar>
                        <ToolbarGroup title={translate('btnGroupRedirect')}>
                            <Button intent="primary" component="a" href={redirectDirectly}>
                                {translate('redirectDirectly')}
                            </Button>
                        </ToolbarGroup>
                    </Toolbar>
                )}
            </>
        )}
    </>
);

export default DefaultDestination;
