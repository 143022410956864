// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'Ya existe una invitación para este correo.',
        invitationMemberExist: 'Ya existe un usuario para este email registrado como miembro del equipo.',
        bounceOrComplaint: 'Esta dirección de email no existe o ha bloqueado nuestro mensaje porque lo considera spam. Póngase en contacto con el equipo de soporte de Plesk 360.',
        emailsAreEqual: 'El email debería ser distinto al actual.',
        consentKeeperNotAvailable: 'No es posible verificar que el contrato de procesamiento de datos se haya firmado. Inténtelo de nuevo más tarde.',
    },
    profile: {
        AccountRemoval: {
            removeAccountButton: 'Eliminar cuenta',
            removeAccount: '¿Quiere eliminar su cuenta de <b>{email}</b> de Plesk 360?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{¿Quiere eliminar su cuenta de <b>{email}</b> y a # miembro de la organización de Plesk 360?} other{¿Quiere eliminar su cuenta de <b>{email}</b> y a # miembros de la organización de Plesk 360?}}',
            removeAccountDescription1: 'Se eliminarán sus cuentas de servicio 360 y licencias 360 junto con su cuenta. Además, eliminaremos su dirección de email y otros datos personales de Plesk 360.',
            removeAccountDescription2: 'Sus licencias de Plesk y de extensiones no se verán afectadas por la eliminación de su cuenta Plesk 360, si bien ya no podrá verlas ni gestionarlas en Plesk 360.',
            removingAccount: 'Eliminando la cuenta...',
            loading: 'Cargando...',
        },
        ChangePassword: {
            title: 'Cambio de contraseña',
            submit: 'Cambiar la contraseña',
            labelCurrentPassword: 'Contraseña actual',
            labelNewPassword: 'Contraseña nueva',
            labelRepeatPassword: 'Confirme la contraseña',
            forgotPassword: '¿Ha olvidado la contraseña?',
            successMessage: 'Su contraseña ha sido cambiada',
            passwordMatch: 'Las contraseñas indicadas no coinciden.',
        },
        ViewProfile: {
            title: 'Perfil personal',
            changePasswordLink: 'Cambiar la contraseña',
            removeAccountLink: 'Eliminar',
            emailAddressField: 'Dirección de email',
            nameField: 'Nombre',
            timezoneField: 'Zona horaria',
            mfaField: 'Autenticación multifactor',
            mfaEnable: 'Enviar email de inscripción',
            mfaDisable: 'Desactivar MFA',
            mfaCheckEmail: 'Compruebe si buzón de correo electrónico para completar la inscripción',
            mfaConfirmation: 'Desactivar MFA?',
            mfaDisableSuccessToast: 'MFA desactivada.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'Desactivar MFA',
                description: '¿Desactivar la autenticación multifactor?',
                currentPassword: 'Contraseña actual',
                otp: 'Código de un solo uso',
                otpDescription: 'Código de un solo uso para la autenticación de doble factor que proporciona su aplicación de autenticación.',
                buttonSave: 'Confirmar',
                buttonCancel: 'Cancelar',
            },
        },
        ChangeableEmail: {
            buttonEdit: 'Editar',
            dialogTitle: 'Cambiar email',
            description: 'Le enviaremos un email con las indicaciones para actualizar su dirección.',
            email: 'Email nuevo',
            currentPassword: 'Contraseña actual',
            otp: 'Código de un solo uso',
            otpDescription: 'Código de un solo uso utilizado como parte de la autenticación de doble factor. Puede encontrarlo en su aplicación de autenticación.',
            consentsTitle: 'Gestión de consentimiento',
            buttonSave: 'Enviar',
            buttonCancel: 'Cancelar',
            successMessage: 'Hemos enviado un email de verificación a <b>{email}</b>. Siga las indicaciones para verificar su nuevo email.',
        },
        DpaManager: {
            title: 'Contrato de procesamiento de datos',
            accepted: 'Aceptado',
            notAccepted: 'No aceptado',
            buttonEdit: 'Editar',
            tooltipDpaBlocked: 'Debe introducir su nombre y apellidos antes de continuar',
        },
        DpaDialog: {
            title: 'Contrato de procesamiento de datos',
            subtitle: 'Para usar algunas de nuestras funciones, es necesario que acepte los términos del contrato de procesamiento de datos (DPA).',
            dpaLinkText: 'Contrato de procesamiento de datos',
            buttonAccept: 'Acepto',
            buttonDecline: 'No acepto',
            successMessage: 'DPA actualizado correctamente.',
        },
        ConsentManager: {
            error: 'Se ha producido un error mientras se cargaban los datos de consentimiento',
            successMessage: 'Los datos de consentimiento se han actualizado correctamente',
            subscribed: 'Suscrito',
            notSubscribed: 'No suscrito',
            buttonEdit: 'Editar',
            dialogTitle: 'Gestión de consentimiento',
            buttonSave: 'Guardar',
        },
        ChangeableName: {
            successMessage: 'Nombre actualizado correctamente',
            updating: 'Actualizando',
            firstName: 'Nombre',
            lastName: 'Apellidos',
        },
        Timezone: {
            buttonEdit: 'Editar',
            timezone: 'Zona horaria',
            dialogTitle: 'Cambiar zona horaria',
            buttonCancel: 'Cancelar',
            buttonSave: 'Guardar',
            successMessage: 'Zona horaria actualizada correctamente.',
        },
    },
    BusinessProfile: {
        title: 'Queremos conocer más de usted',
        save: 'Guardar',
        done: 'Hecho',
        answerLater: 'Responder más tarde',
        tour: {
            gotIt: 'Entendido',
            text: 'Más adelante puede actualizar el perfil corporativo mediante el menú de la cuenta.',
        },
        firstSectionTitle: 'Queremos conocer su empresa',
        secondSectionTitle: 'Queremos conocer cómo usa Plesk',
        whoDoYouCreateWebsitesFor: '¿Para quién crea sitios web?',
        clients: 'Clientes',
        ownPurposes: 'Finalidades propias',
        whatKindOfBusinessDoYouDo: '¿A qué se dedica su empresa?',
        hosting: 'Hosting',
        agency: 'Agencia',
        isTheWebsiteForBusinessOrPrivatePurposes: '¿Es su sitio web para uso corporativo o privado?',
        business: 'Empresa',
        private: 'Privado',
        whatIsYourCoreBusiness: '¿Cuál es su negocio principal?',
        webHosting: 'Hosting web',
        vpsAndServerHosting: 'VPS y hosting de servidores',
        iaas: 'Infrastructure as a Service Provider (IaaS)',
        saas: 'Software as a Service Provider (SaaS)',
        other: 'Otro',
        webDevelopment: 'Desarrollo web',
        webDesignOrManagement: 'Diseño y gestión web',
        marketingOrMedia: 'Marketing y multimedia',
        whichDepartmentDoYouBelongTo: '¿A qué departamento pertenece?',
        itAndSystemAdministration: 'TI y administración de sistemas',
        systemsIntegration: 'Integración de sistemas',
        softwareDevelopment: 'Desarrollo de software',
        marketingOrCommunication: 'Marketing y comunicaciones',
        forWhichKindOfOrganisationIsTheWebsite: '¿Para quién es el sitio web?',
        privatePersonOrFamily: 'Particular o familia',
        association: 'Asociación',
        nonProfitOrganisation: 'ONG',
        howManyEmployeesDoesYourCompanyHave: '¿Cuántos empleados tiene su empresa?',
        selfEmployed: 'Soy autónomo/a',
        howManyMembersDoesYourOrganisationHave: '¿De cuántos miembros dispone su empresa?',
        howManyServersDoYouManage: '¿Cuántos servidores gestiona?',
        howManyServersDoYouManageDescription: 'con y sin Plesk',
        areTheyYourServers: '¿Son estos servidores de su propiedad?',
        yes: 'Sí, soy el propietario de estos servidores.',
        no: 'No, gestiono estos servidores para terceros.',
        partly: 'Ambos. Algunos servidores son de mi propiedad y gestiono servidores para terceros.',
        whatIsYourMainPurposeOfPlesk: '¿Para qué usa Plesk?',
        whatIsYourMainPurposeOfPleskDescription: 'Elija cualquier opción válida',
        webhosting: 'Hosting web',
        emailHosting: 'Hosting de email',
        eCommerceHosting: 'Hosting e-Commerce',
        wordPressManagement: 'Gestión de WordPress',
        systemAdministration: 'Administración de sistemas',
        collaboration: 'Colaboración',
        automation: 'Automatización',
        reselling: 'Reventa',
        howWouldYouDescribeYourTechnicalKnowledge: '¿Cómo describiría sus conocimientos técnicos?',
        notTechnicalAtAll: 'No dispongo de conocimientos técnicos.',
        basicKnowledge: 'Dispongo de conocimientos básicos de Linux o Windows.',
        expert: 'Soy experto y uso Plesk para automatización.',
    },
    ApiTokens: {
        title: 'Tokens de API',
        name: 'Nombre',
        creationDate: 'Hora de generación',
        lastUsageDate: 'Último uso',
        tokenNeverUsed: 'Nunca',
        addButton: 'Generar token de API',
        addButtonLimitExceededTooltip: 'Se ha alcanzado el límite de tokens de API. Elimine algún token existente para así poder crear tokens.',
        countTooltip: '{max, plural, one{Máximo # token por cuenta.} other{Máximo # tokens por cuenta.}}',
        totalAmount: 'total {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Token <info>{name}</info> generado correctamente. ¡Guárdelo ahora en un sitio seguro, puesto que no lo volverá a ver!',
        emptyListTitle: 'Genere tokens de API para que sus aplicaciones o scripts puedan acceder a la API de Platform 360',
        emptyListDescription: 'Aquí se mostrarán sus tokens de API.',
        createToken: 'Generar token de API',
        successCopied: 'Copiado al portapapeles.',
        successTokenDelete: 'Token eliminado correctamente',
        expireAtTooltip: 'El token expirará a las {expireAt}.',
        AddTokenDialog: {
            title: 'Nuevo token de API',
            nameLabel: 'Nombre del token',
            nameDescription: '¿Para qué se usará este token?',
            addButton: 'Generar',
            closeButton: 'Cerrar',
            tokenInfo: 'Guárdelo en un sitio seguro, puesto que no volverá a verlo. De comprometerse un token, genere uno nuevo de inmediato. Su nuevo token:',
            close: 'Cerrar',
            tokenSuccessCopied: 'Token copiado al portapapeles.',
        },
        DeleteButton: {
            deleteButton: 'Eliminar token',
            deleteConfirmation: '¿Seguro que desea eliminar el token <tooltip>{name}</tooltip>? Los scripts o aplicaciones que usen este token ya no podrán acceder a la API de Platform 360.',
            copy: 'Copiar al portapapeles',
            deleteConfirmationInputDesc: 'Introduzca el nombre del token para confirmar la eliminación.',
            deleteConfirmationButton: 'Eliminar token',
        },
    },
    UpdateEmail: {
        loadingText: 'Cambiando email...',
        ErrorMessage: {
            tryAgainButton: 'Volver a intentar',
            errors: {
                forbidden: 'El enlace de modificación del email ha expirado. Inténtelo de nuevo.',
                changingEmailConflict: 'Ya se ha registrado un usuario con este email.',
                unexpected: 'El servicio está temporalmente fuera de servicio. Inténtelo de nuevo transcurridos unos minutos.',
            },
        },
    },
};