// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './PlatformPageIntro.module.less';
import { Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { PageIntro } from '../PageIntro';
import src from './dashboard.png';
import src2x from './dashboard@2x.png';

export const PlatformPageIntro = () => {
    const translate = useTranslate('Auth.PageIntro.PlatformPageIntro');

    return (
        <PageIntro title={translate('title')} subTitle={translate('text1')} image={{ src, src2x }}>
            <ul className={styles.features}>
                <li>{translate('text2')}</li>
                <li>{translate('text3')}</li>
                <li>{translate('text4')}</li>
            </ul>
            <Paragraph>{translate('text5')}</Paragraph>
        </PageIntro>
    );
};

export default PlatformPageIntro;
