// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { Tooltip } from '@plesk/ui-library';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useLocale } from '@platform360/libs/shared-web/locale';
import styles from './DateColumn.module.css';

export type DateColumnProps = {
    date: DateTime;
    onClick: () => void;
};

export const DateColumn = ({ date, onClick, ...props }: DateColumnProps) => {
    const { timezone } = useCurrentUser();
    const [locale] = useLocale();
    const today = DateTime.local();
    const isToday =
        date.hasSame(today, 'day') && date.hasSame(today, 'month') && date.hasSame(today, 'year');
    const format = isToday ? DateTime.TIME_24_SIMPLE : { ...DateTime.DATE_MED, year: undefined };
    const dateWithZone = date.setZone(timezone);

    return (
        <Tooltip title={dateWithZone.toFormat('yyyy-LL-dd HH:mm:ss ZZ')} {...props}>
            <div className={styles.root} onClick={onClick}>
                {dateWithZone.toLocaleString(format, { locale })}
            </div>
        </Tooltip>
    );
};
