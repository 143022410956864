// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getLicenses } from '@platform360/admin-panel/web/api/security-dashboard/licenses';
import { GetSecurityDashboardLicensesRequestDto } from '@platform360/admin-panel/shared/api-contract';

export type UseLicensesQueryVariables = GetSecurityDashboardLicensesRequestDto;
export type UseLicensesQueryData = PromiseValue<ReturnType<typeof getLicenses>>;

const useLicensesQuery = createQuery<UseLicensesQueryVariables, UseLicensesQueryData>({
    queryName: 'admin-panel/useLicensesQuery',
    fetcher: getLicenses,
    useQuery,
});

export default useLicensesQuery;
