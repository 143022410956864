// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { saveInstallationsSettings } from '@platform360/security-dashboard/web/api/installations';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';

type UseSaveInstallationsSettingsMutationOptions = {
    onSuccess: () => void;
};

const useSaveInstallationsSettingsMutation = ({
    onSuccess,
}: UseSaveInstallationsSettingsMutationOptions) =>
    useMutation({
        mutationFn: saveInstallationsSettings,
        onSuccess: () => {
            refetchList(useInstallationsQuery.getQueryKey());
            onSuccess();
        },
    });

export default useSaveInstallationsSettingsMutation;
