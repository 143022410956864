// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useMemo, useRef } from 'react';

export const useAnimatedHide = (selector: string, hidden: boolean) => {
    const nodeRef = useRef<HTMLDivElement>();
    const intervalRef = useRef<NodeJS.Timeout>();
    const updateHeight = (hidden: boolean) => {
        if (!nodeRef.current) {
            return;
        }

        nodeRef.current.style.maxHeight = hidden ? '0' : nodeRef.current.style.height;
    };
    const initNode = useMemo(
        () => (selector: string, hidden: boolean) => {
            const node = document.querySelector<HTMLDivElement>(selector);

            if (
                !node ||
                // Prevent to init node twice
                node.style.height
            ) {
                return;
            }

            node.style.overflowY = 'hidden';
            node.style.transitionProperty = 'all';
            node.style.transitionDuration = '.4s';
            node.style.transitionTimingFunction = 'ease';
            node.style.height = `${node.clientHeight}px`;
            nodeRef.current = node;
            updateHeight(hidden);
        },
        [],
    );

    useEffect(() => {
        initNode(selector, hidden);

        intervalRef.current = setInterval(() => {
            initNode(selector, hidden);
        }, 100);

        return () => {
            // Init node again if it was replaced by React
            clearInterval(intervalRef.current);
        };
    }, [hidden, initNode, selector]);

    useEffect(() => {
        updateHeight(hidden);
    }, [hidden]);
};
