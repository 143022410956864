// Copyright 2024. WebPros International GmbH. All rights reserved.

import { MenuItem } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import LabelsList from '@platform360/security-dashboard/web/components/LabelsList';
import { Installation, Label } from '@platform360/security-dashboard/web/types';
import { LabelTypes } from '@platform360/security-dashboard/shared/label-type';
import { useRemoveInstallationLabelsMutation } from '@platform360/security-dashboard/web/mutations';

type InstallationLabelsListProps = {
    installation: Installation;
    removeInstallationLabels: ReturnType<typeof useRemoveInstallationLabelsMutation>['mutate'];
    onFilterByLabel: (label: Label) => void;
    onRequestProtection: () => void;
};

const InstallationLabelsList = ({
    installation,
    removeInstallationLabels,
    onRequestProtection,
    onFilterByLabel,
}: InstallationLabelsListProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.Installations.InstallationLabelsList');

    const handleRemoveLabel = (label: Label, { onSuccess }: { onSuccess?: () => void } = {}) => {
        removeInstallationLabels(
            {
                installationIds: [installation.id],
                labelIds: [label.id],
            },
            { onSuccess },
        );
    };

    return (
        <LabelsList
            labels={installation.labels}
            shouldDisplayUnmanagedLabel={!installation.managed && installation.operable}
            updates={installation.updateSettings}
            renderMenuItems={({ label, localisation: { filter, remove } }) => {
                const shouldShowDoNotProtect =
                    label.type === LabelTypes.doNotProtect && !installation.tasks.length;

                const result = [
                    <MenuItem key="filter" icon="filter" onClick={() => onFilterByLabel(label)}>
                        {filter}
                    </MenuItem>,
                ];

                if (
                    (shouldShowDoNotProtect && !installation.server.protected) ||
                    label.type === LabelTypes.custom
                ) {
                    result.push(
                        <MenuItem
                            key="remove-label"
                            icon="recycle"
                            onClick={() => {
                                handleRemoveLabel(label);
                                analyticsEvents.wpSiteLabelMenuActionClick(label.type, 'remove');
                            }}
                        >
                            {remove}
                        </MenuItem>,
                    );
                }

                if (shouldShowDoNotProtect && installation.server.protected) {
                    result.push(
                        <MenuItem
                            key="enable-patching"
                            onClick={() => {
                                onRequestProtection();
                                analyticsEvents.wpSiteLabelMenuActionConfirmationClick(
                                    label.type,
                                    'enablePatching',
                                );
                            }}
                            icon="shield"
                        >
                            {translate('enablePatching')}
                        </MenuItem>,
                    );
                }

                return result;
            }}
        />
    );
};

export default InstallationLabelsList;
