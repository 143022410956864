// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useUpdateSettingsQuery } from '@platform360/security-dashboard/web/queries';
import CardLoader from '@platform360/security-dashboard/web/components/Layout/Header/CardLoader';
import useModal from '@platform360/libs/shared-web/helpers/useModal';
import SettingsDrawer from '@platform360/security-dashboard/web/components/SettingsDrawer';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import Card from '@platform360/security-dashboard/web/components/UpdateSettingsCard';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const UpdateSettingsCard = ({ ...props }) => {
    const { data, isLoading } = useUpdateSettingsQuery();
    const translate = useTranslate('security-dashboard.Layout.Header.UpdateSettingsCard');
    const [isSettingsDrawerOpen, setSettingsDrawerOpen] = useModal('settings');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const handleOpenSettings = () => {
        setSettingsDrawerOpen(true);
        analyticsEvents.wpGloAutoupdateSettingsClick();
    };

    if (isLoading || !data) {
        return <CardLoader />;
    }

    return (
        <div data-type="update-settings-card">
            <SettingsDrawer
                isOpen={isSettingsDrawerOpen}
                onClose={() => setSettingsDrawerOpen(false)}
            />
            <Card
                title={translate('title')}
                updateSettings={data}
                onButtonClick={handleOpenSettings}
                {...props}
            />
        </div>
    );
};
