// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Button,
    ButtonGroup,
    Card,
    Icon,
    Media,
    Section,
    Toolbar,
    ToolbarGroup,
} from '@plesk/ui-library';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from '@platform360/licenses/web/components/Img';
import MoreDetails from './MoreDetails';
import Content from './Content';
import styles from './style.module.css';
import { hide, own, terminated } from '@platform360/licenses/web/helpers/utils';
import { Link } from 'react-router-dom';
import { licensesUri } from '@platform360/licenses/web/helpers';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const KeyHeader = ({ keyInfo }) => (
    <span className={styles.cardTitle} data-type="Keys--keyHeader" id={`license_${keyInfo.id}`}>
        {keyInfo.type} <span className={styles.cardTitleKeyNumber}>{keyInfo.keyNumber}</span>
    </span>
);

KeyHeader.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

const KeyContent = (props) => {
    const translate = useTranslate();

    return (
        <Media
            className={styles.media}
            image={<Img code={props.keyInfo.code} />}
            imageSpacing="xxl"
        >
            <div className={styles.mediaBody}>
                <Content keyInfo={props.keyInfo} />
                <Toolbar className={styles.mediaAction}>
                    <ToolbarGroup title={translate('keyAdditionalActionsToolbarTitle')}>
                        <ButtonGroup>
                            <MoreDetails
                                keyInfo={props.keyInfo}
                                target={
                                    <Button
                                        onClick={props.onClickMoreDetails}
                                        data-type="Keys--keyMoreDetails"
                                    >
                                        <Icon name="info-circle" size="16" />
                                        &nbsp;
                                        {translate('keyMoreDetails')}
                                    </Button>
                                }
                                placement="bottom-left"
                            />
                            {/* TODO that button hided now, because such function will be available in future*/}
                            {own(props.keyInfo) && hide() && (
                                <Button icon="cross-mark" disabled={terminated(props.keyInfo)} />
                            )}
                        </ButtonGroup>
                    </ToolbarGroup>
                </Toolbar>
            </div>
        </Media>
    );
};

KeyContent.propTypes = {
    keyInfo: PropTypes.any.isRequired,
    onClickMoreDetails: PropTypes.func.isRequired,
};

const KeyBatch = ({ className = null, keyBatch = null, ...props }) => {
    const translate = useTranslate();
    const analyticsEvents = useLicensesAnalyticsEvents();

    const handleUpsellButtonClicked = () => {
        analyticsEvents.licensesItemAddExtensionClicked();
    };

    const handleLicenseMoreDetailsClicked = () => {
        analyticsEvents.licensesItemMoreDetailsClicked('License');
    };

    const handleExtensionMoreDetailsClicked = () => {
        analyticsEvents.licensesItemMoreDetailsClicked('Extension');
    };

    return (
        <Card
            {...props}
            title={<KeyHeader keyInfo={keyBatch.mainKey} />}
            className={classNames(styles.card, className)}
            data-type="Keys--key"
        >
            <div className={styles.cardContent}>
                <Section data-type="Keys--mainKey">
                    <KeyContent
                        keyInfo={keyBatch.mainKey}
                        onClickMoreDetails={handleLicenseMoreDetailsClicked}
                    />
                </Section>
                {keyBatch.mainKey.hasUpsells && !terminated(keyBatch.mainKey) && (
                    <Section className={styles.addUpsellBlock}>
                        <Button
                            size="md"
                            intent="primary"
                            component={Link}
                            to={`${licensesUri}/${keyBatch.mainKey.id}/upsell`}
                            className={styles.upsellButton}
                            onClick={handleUpsellButtonClicked}
                        >
                            {translate('upsellButton')}
                        </Button>
                        {translate('upsellText')}
                    </Section>
                )}
                {keyBatch.additionalKeys.map((key) => (
                    <Section
                        key={key.id}
                        title={<KeyHeader keyInfo={key} />}
                        data-type="Keys--additionalKey"
                    >
                        <KeyContent
                            keyInfo={key}
                            onClickMoreDetails={handleExtensionMoreDetailsClicked}
                        />
                    </Section>
                ))}
            </div>
        </Card>
    );
};

KeyBatch.propTypes = {
    className: PropTypes.string,
    keyBatch: PropTypes.object,
};

export default KeyBatch;
