// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Status, ListActions, ListAction, Toolbar, Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import AddEmails from '@platform360/licenses/web/LinkedEmails/AddEmails';
import Onboarding from '@platform360/licenses/web/LinkedEmails/Onboarding';
import { emailsUri } from '@platform360/licenses/web/helpers';
import { useLinkedEmailsQuery } from '@platform360/licenses/web/queries/useLinkedEmailsQuery';
import { useDeleteLinkedEmailMutation } from '@platform360/licenses/web/mutations/useDeleteLinkedEmailMutation';
import DataList from '@platform360/libs/shared-web/components/DataList';

const RoutableAddEmails = withRouteBinding(AddEmails);

const LinkedEmails = () => {
    const translate = useTranslate('Keys.LinkedEmails');
    const analyticsEvents = useLicensesAnalyticsEvents();
    const [deletions, setDeletions] = useState<string[]>([]);
    const { data, isLoading } = useLinkedEmailsQuery();
    const { mutate } = useDeleteLinkedEmailMutation();

    const handleDelete = (email: string) => () => {
        analyticsEvents.licensesLinkedEmailsItemUnlinkEmailClicked();
        setDeletions([...deletions, email]);
        mutate(email);
    };
    return (
        <>
            <DataList
                columns={[
                    {
                        key: 'email',
                        title: translate('emailColumn'),
                        type: 'title',
                    },
                    {
                        key: 'status',
                        title: translate('statusColumn'),
                        render: () => (
                            <Status intent="success">{translate('verifiedStatus')}</Status>
                        ),
                    },
                    {
                        key: 'actions',
                        type: 'actions',
                        render: ({ email }) => (
                            <ListActions>
                                <ListAction
                                    icon="recycle"
                                    primary
                                    onClick={handleDelete(email)}
                                    state={deletions.includes(email) ? 'loading' : undefined}
                                    data-type="delete-email-button"
                                >
                                    {deletions.includes(email) ? null : translate('deleteButton')}
                                </ListAction>
                            </ListActions>
                        ),
                    },
                ]}
                data={data?.map((email) => ({ id: email, email }))}
                totalRows={data?.length}
                loading={isLoading}
                toolbar={
                    <Toolbar>
                        <Button
                            intent="primary"
                            icon="plus"
                            component={Link}
                            to={emailsUri.concat('/add')}
                        >
                            {translate('addButton')}
                        </Button>
                    </Toolbar>
                }
                locale={{
                    totalRows: (t) => translate('totalRows', { totalRows: t?.totalRows }),
                }}
                emptyView={<Onboarding />}
                data-type="emails-list"
            />
            <RoutableAddEmails openPath="add" closePath={emailsUri} />
        </>
    );
};

export { LinkedEmails };
export default LinkedEmails;
