// Copyright 2024. WebPros International GmbH. All rights reserved.

import DynamicWidget from './DynamicWidget';
import useMonitoringInfoQuery from '@platform360/monitoring/web/queries/useMonitoringInfoQuery';

const DynamicWidgetContainer = ({ ...props }) => {
    const {
        data,
        isLoading,
        dataUpdatedAt: updatedAt,
        isError: hasError,
    } = useMonitoringInfoQuery({
        // Refetch every minute
        refetchInterval: 60000,
        // Disable toaster and default error handler.
        // We handle errors in widget manually, by displaying a Loader + text: 360 monitoring not responding
        meta: {
            defaultErrorHandler: false,
        },
    });

    return (
        <DynamicWidget
            info={data}
            isLoading={isLoading}
            hasError={hasError}
            updatedAt={updatedAt}
            {...props}
        />
    );
};

export default DynamicWidgetContainer;
