// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, LabelIntent } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Fragment } from 'react';
import { Tag } from '@platform360/accounts/web/api/team';
import styles from './SpecialMarks.module.css';

const tagIntents: Record<Tag, LabelIntent | undefined> = {
    you: 'success',
    owner: 'info',
};

type SpecialMarkProps = {
    tag: Tag;
    className?: string;
};

export const SpecialMark = ({ tag, ...props }: SpecialMarkProps) => {
    const translate = useTranslate('Team.Members.SpecialMarks');

    return (
        <Label intent={tagIntents[tag] || 'inactive'} {...props}>
            {translate(tag)}
        </Label>
    );
};

type SpecialMarksProps = {
    tags: Tag[];
};

const SpecialMarks = ({ tags }: SpecialMarksProps) => (
    <Fragment>
        {tags.map((tag) => (
            <SpecialMark key={tag} tag={tag} className={styles.specialMark} />
        ))}
    </Fragment>
);

export default SpecialMarks;
