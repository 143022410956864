// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import SiteHealthStatus, {
    SiteHealthStatusProps,
} from '@platform360/security-dashboard/web/components/SiteHealthStatus';
import TaskProgress from '@platform360/security-dashboard/web/components/TaskProgress';
import {
    Server,
    SitesHealthFilter,
    SITE_HEALTH_FILTER_ID,
} from '@platform360/security-dashboard/web/types';
import { ProgressBar, Status as UILibStatus, Text } from '@plesk/ui-library';
import { getServerError } from './getServerError';
import styles from './SiteHealthColumn.module.less';
import { useLastCheckAtTooltip } from '@platform360/security-dashboard/web/hooks/useLastCheckAtTooltip';
import { AGENT_SCAN_NEW_INSTALLATIONS_TASK_TYPE } from '@platform360/security-dashboard/shared/constants';

type StatusProps = Omit<SiteHealthStatusProps, 'to'> & {
    serverName: string;
    siteHealthFilter?: SitesHealthFilter;
};

const Status = (props: StatusProps) => (
    <SiteHealthStatus
        to={buildUrl('/security-dashboard/installations', {
            search: encodeURIComponent(props.serverName),
            searchServer: encodeURIComponent(props.serverName),
            [SITE_HEALTH_FILTER_ID]: props.siteHealthFilter,
        })}
        {...props}
    />
);

type SiteHealthColumnProps = {
    server: Server;
};

const SiteHealthColumn = ({ server }: SiteHealthColumnProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.Servers.SiteHealthColumn');
    const statusTranslate = useTranslate('security-dashboard.Servers.state');

    const { installationsCount, vulnerableCount, outdatedCount, notOperableCount, tasks } = server;

    const serverName = server.hostname ?? server.address.hostname;

    const lastCheckAtTooltip = useLastCheckAtTooltip(server.lastVulnerabilityCheckAt);
    const error = getServerError({
        status: server.status,
        unsupportedAgent: server.unsupportedAgent,
    });

    if (server.status === 'installingAgent') {
        return (
            <div className={styles.installing}>
                <UILibStatus className={styles.status}>
                    <Text bold>{statusTranslate('installingAgent')}</Text>
                </UILibStatus>
                <ProgressBar />
            </div>
        );
    }
    if (server.status === 'agentInstallationError') {
        return (
            <Text bold intent="muted">
                {statusTranslate('agentInstallationError')}
            </Text>
        );
    }

    const [firstTask] = tasks;
    const taskProgress = firstTask ? <TaskProgress task={firstTask} /> : null;

    const isScanningNewInstallations = tasks.some(
        ({ type }) => type === AGENT_SCAN_NEW_INSTALLATIONS_TASK_TYPE,
    );

    // hide vulnerable, outdated and notOperable
    const hideAdditionalInfo = error || !!tasks.length;

    const hideWPSites = installationsCount === 0 && isScanningNewInstallations;
    const hideVulnerable = vulnerableCount === 0 || hideAdditionalInfo;
    const hideOutdated = outdatedCount === 0 || hideAdditionalInfo;
    const hideNotOperable = notOperableCount === 0 || hideAdditionalInfo;

    return (
        <div className={styles.content}>
            {Boolean(error) && (
                <Text bold intent="muted">
                    {error}
                </Text>
            )}
            {!hideWPSites && (
                <Status
                    serverName={serverName}
                    onClick={() => analyticsEvents.wpSglFilterWpSitesClick(installationsCount)}
                >
                    {translate('wpSitesCount', { count: installationsCount })}
                </Status>
            )}
            {!hideVulnerable && (
                <Status
                    intent="danger"
                    icon="exclamation-mark-circle"
                    serverName={serverName}
                    siteHealthFilter={SitesHealthFilter.Vulnerable}
                    onClick={() => analyticsEvents.wpSglFilterVulnerableClick(vulnerableCount)}
                    tooltip={lastCheckAtTooltip}
                >
                    {translate('vulnerable', { count: vulnerableCount })}
                </Status>
            )}
            {!hideOutdated && (
                <Status
                    intent="warning"
                    icon="triangle-exclamation-mark"
                    serverName={serverName}
                    siteHealthFilter={SitesHealthFilter.Outdated}
                    onClick={() => analyticsEvents.wpSglFilterOutdatedClick(outdatedCount)}
                    tooltip={lastCheckAtTooltip}
                >
                    {translate('outdated', { count: outdatedCount })}
                </Status>
            )}
            {!hideNotOperable && (
                <Status
                    icon="question-mark-circle"
                    serverName={serverName}
                    siteHealthFilter={SitesHealthFilter.NotOperable}
                    onClick={() => analyticsEvents.wpSglFilterUnknownClick(notOperableCount)}
                    tooltip={lastCheckAtTooltip}
                >
                    {translate('notOperable', { count: notOperableCount })}
                </Status>
            )}
            {taskProgress}
        </div>
    );
};

export default SiteHealthColumn;
