// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { useQuery } from '@tanstack/react-query';
import { getKaKeys } from '@platform360/licenses/web/api/ka-keys';

export type UseKaKeysQueryVariables = Parameters<typeof getKaKeys>[0]['variables'];

export type UseKaKeysQueryData = PromiseValue<ReturnType<typeof getKaKeys>>;

const useKaKeysQuery = createQuery<UseKaKeysQueryVariables, UseKaKeysQueryData>({
    queryName: 'licenses/useKaKeysQuery',
    fetcher: getKaKeys,
    useQuery,
});

export default useKaKeysQuery;
