// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'Detectou uma chave pública em vez da chave privada esperada.',
        passphrasePrivateKeyError: 'Forneça uma chave não protegida por uma senha.',
        unsupportedPrivateKeyError: 'O tipo de chave fornecida não é compatível.',
        invalidPrivateKeyError: 'Certifique-se de estar usando a chave privada SSH correta.',
        emptyPrivateKeyError: 'A chave privada SSH fornecida é inválida porque está vazia.',
        tooBigPrivateKeyError: 'Forneça um arquivo de chave com menos de 30 KB.',
    },
    Installation: {
        startAnotherInstallation: 'Iniciar outra instalação',
        steps: {
            running: {
                title: 'Plesk está Sendo Instalado',
                description: 'Instalando o Plesk em <b>{host}</b>',
            },
            error: {
                title: 'Erro de Instalação',
            },
            completed: {
                title: 'Instalação Completa',
                pleskHostText: 'Plesk foi instalado em {host}.',
                goToPleskText: 'Clique em "Vá para o Plesk" para fazer login. Após um curto processo de configuração, você pode começar a usar o Plesk.',
                goToPleskButton: 'Ir para o Plesk',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Servidor desconectado',
            description: 'A conexão SSH com o servidor foi perdida.',
        },
        connectFail: {
            title: 'Falha na conexão do servidor',
            description: '<p>Não foi possível se conectar ao servidor: {details}.</p><p>Se o servidor usar uma porta SSH personalizada, especifique-a adicionando dois pontos e o número da porta após o nome do host ou endereço IP (por exemplo, example.com:1024 ou 192.0.2.0:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk já instalado',
            description: 'Ocorreu um erro inesperado ao detectar se o Plesk está instalado: {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com não está disponível',
            description: 'Não foi possível buscar os distributivos do Plesk. O website autoinstall.plesk.com não está disponível: {details}.',
        },
        pleskInstalled: {
            title: 'O Plesk está instalado',
            description: 'O Web Installer instala apenas o {productType} e <b>{host}</b> já tem a versão estável do Plesk instalada. {productType, select, Plesk {Você esperava que ele fizesse outra coisa? Informe-nos: <surveyLink>responda nossa pesquisa, possui uma pergunta apenas</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'O upgrade está disponível',
            description: 'O Web Installer instala apenas {productType} e <b>{host}</b> já tem o Plesk instalado, que não é a versão estável mais recente. {productType, select, Plesk {Você esperava que o Plesk Web Installer o atualizasse? Nos informe <surveyLink>respondendo a uma pesquisa de apenas uma pergunta</surveyLink>.} other {}}',
        },
        unsupportedArch: {
            title: 'Arquitetura de servidor não suportada',
            description: 'Você está tentando instalar o {productType} em uma arquitetura que não é suportada: {arch}.',
        },
        unsupportedOs: {
            title: 'Sistema operacional não suportado',
            description: 'Você está tentando instalar o {productType} em um sistema operacional (arquitetura) que não é suportado: {os}. <supportedOsLink>Veja a lista de sistemas operacionais suportados</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Sem espaço suficiente no disco',
        },
        cpanelInstalled: {
            title: 'cPanel está instalado',
            description: 'O Web Installer instala {productType} apenas em servidores limpos e <b>{host}</b> já possui o cPanel instalado. {productType, select, Plesk {Aprenda como transferir websites hospedados para o Plesk lendo nosso <migrationGuideLink>Guia de Migração</migrationGuideLink>} other {Você esperava que fizesse outra coisa? Nos informe <surveyLink>respondendo a uma pesquisa de apenas uma pergunta</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Verifique o status do gerenciador de pacotes',
            description: 'O gerenciador de pacotes está ocupado ou não está respondendo: {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Falha na configuração do repositório do gerenciador de pacotes',
            description: 'Ocorreu um erro inesperado ao configurar o repositório do gerenciador de pacotes: {details}.',
        },
        dbConfigurationFailed: {
            title: 'Falha na configuração do banco de dados',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'Você está completamente seguro: o WebPros não armazena seu login e senha após a conclusão da instalação.',
            p2: 'Você pode instalar o {productType} em <a>qualquer sistema operacional Linux-based compatível</a>.',
        },
        hostLabel: 'Endereço IP do servidor ou nome de host',
        portLabel: 'Porta',
        loginLabel: 'Login SSH',
        installationMethodLabel: 'Conecte-se ao servidor usando',
        sshPasswordLabel: 'Senha SSH',
        sshKeyLabel: 'Chave SSH privada',
        licenseTypeLabel: 'Selecione o tipo da licença',
        trialLicenseLabel: 'Prossiga com a licença de avaliação completa',
        trialLicenseDescription: 'Para emitir uma licença de teste, a WebPros International GmbH usará e processará as informações fornecidas em seus sistemas internos de acordo com a <privacyPolicyLink>Política de Privacidade da WebPros</privacyPolicyLink>.',
        paidLicenseLabel: 'Prossiga com o pagamento da licença',
        paidLicenseDescription: 'Se você já comprou uma licença do {productType}, cole o código de ativação que recebeu aqui. Caso contrário, você pode comprar uma nova licença em nossa <onlineStoreLink>Loja Online</onlineStoreLink>.',
        installOsUpdatesLabel: 'Instale as atualizações do SO antes de iniciar a instalação do {productType}',
        installOsUpdatesDescription: 'A seleção desta opção pode retardar significativamente o processo de instalação ou causar falha.',
        notice: 'A instalação geralmente leva cerca de 15 minutos.',
        techDomainDescription: 'Seu servidor será adicionado com o nome <b>{techDomain}</b>.',
        serverConnectionSection: 'Servidor Conectado',
        licenseSection: 'Licença',
        advancedSection: 'Avançado',
        submitButton: 'Próximo',
    },
    ServerInstallationPage: {
        submitButton: 'Próximo',
        panelTitle: 'Instalar {productType} automaticamente em um servidor Linux',
        licenceLabel: 'Licença de teste',
        installationWillTake: 'A instalação geralmente leva cerca de 15 minutos',
        helpUsImprove: 'Ajude-nos a melhorar o Web Installer {productType}',
        takeOurSurvey: 'Responda a nossa pesquisa',
        links: {
            manualInstallationInstructions: 'Instruções de instalação manual',
            softwareRequirements: {
                plesk: 'Requisitos do software',
                cpanel: 'Requisitos do sistema',
            },
            versionsAndComponents: 'Versões e componentes',
            knownIssues: 'Problemas conhecidos',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'Seu e-mail',
        legalAgreementTitle: 'Concordo com os <termsOfUseLink>Termos de Uso</termsOfUseLink>, <privacyPolicyLink>Política de Privacidade</privacyPolicyLink> e concordo em assinar o <dpaLink>Contrato de Processamento de Dados</dpaLink> do Plesk',
        emailHint: 'Se você já possui uma conta WebPros, use este e-mail. Caso contrário, uma conta será criada para você.',
        serversMonitoring: 'Monitore a disponibilidade, a integridade e o desempenho dos servidores gratuitamente para 1 servidor e 5 websites',
        loginNoCredentials: 'Crie sua conta WebPros para facilitar o acesso a todos os serviços da empresa com apenas um clique',
        login: 'Faça login na instalação do {productType} sem inserir credenciais',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'Primeiro nome',
        lastName: 'Sobrenome',
        submitButton: 'Iniciar instalação',
        marketingConsent: {
            title: 'A WebPros pode me fornecer boletins informativos e ofertas individuais de acordo com a <a>Política de Privacidade</a> da WebPros.',
            text: 'Posso revogar este consentimento clicando no link em qualquer comunicação recebida',
        },
    },
    LightsailInstallation: {
        title: 'Instalar Automaticamente em um Servidor em Nuvem',
        awsAccessKey: 'ID da chave de acesso',
        awsSecretKey: 'Chave de acesso secreta',
        region: 'Região',
        bundleTitle: 'Plano de instância',
        publicKeyTitle: 'Chave pública SSH',
        instanceName: 'Nome da instância',
        submitButton: 'Continuar',
        BundleSelect: {
            price: '${value} por mês', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Memória {value} GB',
            storage: 'Armazenamento {value} GB',
            transfer: 'Transferir {value} GB',
            descriptions: {
                micro: 'Mais adequado para experimentar o Plesk.',
                small: 'Para um ou mais websites pessoais.',
                medium: 'Para profissionais da web ou agências da web que trabalham em um pequeno número de websites.',
                large: 'Para grandes agências da web ou pequenos hosters focados em vários websites de baixo tráfego ou alguns de alto tráfego.',
                xlarge: 'Para hosters que gerenciam vários websites de alto tráfego e alta carga.',
                '2xlarge': 'Para os projetos mais ambiciosos.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'Para fazer login na sua conta cPanel, clique no botão “Fazer login no cPanel”. <b>NOTA:</b> O botão só funcionará uma vez!',
        loginButton: 'Faça login no cPanel',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'Perguntas Frequentes sobre Instalação Automática do Plesk',
            versionsAndComponents: 'Versões E Componentes',
            knownIssues: 'Problemas Conhecidos',
            whatVersionIsInstalledTitle: 'Qual versão do Plesk é instalada em modo automático?',
            whatVersionIsInstalledText: 'A última versão estável do Plesk com todas as atualizações disponíveis no momento. Para instalar uma versão diferente do Plesk, siga as <link>instruções de instalação</link>.',
            whatComponentsAreInstalledTitle: 'Quais componentes são instalados no modo automático?',
            whatComponentsAreInstalledText: 'The "recommended preset" which includes all services required for hosting websites (Web, DNS, FTP, and Mail), along with the most popular components and extensions like WordPress Toolkit, Advisor, Let’s Encrypt, and so on.',
            canISelectComponentsTitle: 'Posso selecionar quais componentes do Plesk instalar?',
            canISelectComponentsText: 'Não no momento. Estamos cientes dessa limitação e planejamos solucioná-la no futuro. Por enquanto, a solução alternativa é adicionar ou remover os componentes necessários após a conclusão do processo de instalação.',
            doesInstalledPleskHaveLicenseTitle: 'O Plesk instalado vem com licença?',
            doesInstalledPleskHaveLicenseText: 'Você pode solicitar um teste pessoal de 14 dias do Web Pro Edition logo após seu primeiro login no Plesk, caso ainda não tenha feito isso. Você também pode usar sua própria licença adquirida.',
            supportedOSsTitle: 'Quais Sistemas Operacionais são suportados no modo automático? Posso instalar o Plesk para Windows?',
            supportedOSsText: 'Qualquer <a>SO baseado em Linux suportado pelo Plesk</a>. Para instalar o Plesk para Windows, siga as <link>instruções de instalação</link>.',
            usePrivateKeyTitle: 'Posso usar uma chave SSH privada criptografada?',
            usePrivateKeyText: 'Não no momento. Estamos cientes dessa limitação e planejamos solucioná-la no futuro.',
            isItSafeToSubmitPasswordTitle: 'É seguro enviar uma senha ou chave SSH privada ao instalar o Plesk no modo automático?',
            isItSafeToSubmitPasswordText: 'Sim. Essas credenciais são usadas apenas para conectar-se ao servidor e instalar o Plesk e não são armazenadas. Todos os dados são transmitidos com segurança por canais SSH e HTTPS criptografados. Para máxima segurança, considere alterar as credenciais de acesso ao servidor após a instalação.',
            helpUsMakeWebInstallerBetter: 'Ajude-nos a tornar o Plesk Web Installer melhor para todos <a>respondendo a uma breve pesquisa.</a>',
        },
        cpanel: {
            header: 'Perguntas Frequentes sobre Instalação Automática do cPanel',
            versionsAndComponents: 'Versões E Componentes',
            knownIssues: 'Problemas Conhecidos',
            whatVersionIsInstalledTitle: 'Qual versão do cPanel é instalada em modo automático?',
            whatVersionIsInstalledText: 'A última versão do cPanel com todas as atualizações disponíveis no momento. Para instalar uma versão diferente do cPanel, siga as <link>instruções de instalação</link>.',
            canICustomizeParametersOfInstallationTitle: 'Posso personalizar os parâmetros de instalação?',
            canICustomizeParametersOfInstallationText: 'Atualmente, não há como personalizar a instalação com o Web Installer. Para personalizar sua instalação, siga as instruções em <link>nossa documentação</link>.',
            doesInstalledCpanelHaveLicenseTitle: 'O cPanel instalado vem com licença?',
            doesInstalledCpanelHaveLicenseText: 'Se estiver instalando uma nova instância cPanel e WHM em um servidor, você terá direito a uma licença de teste de 15 dias. Siga as instruções <link>neste guia</link> para se inscrever para uma licença de teste. Você também pode usar sua própria licença adquirida.',
            supportedOSsTitle: 'Quais Sistemas operacionais são suportados no modo automático?',
            supportedOSsText: 'Qualquer Sistema Operacional baseado em Linux <link>suportado pelo cPanel</link>.',
            usePrivateKeyTitle: 'Posso usar uma chave SSH privada criptografada?',
            usePrivateKeyText: 'Não no momento. Estamos cientes dessa limitação e planejamos solucioná-la no futuro.',
            isItSafeToSubmitPasswordTitle: 'É seguro enviar senha ou chave SSH privada ao instalar o cPanel no modo automático?',
            isItSafeToSubmitPasswordText: 'Sim. Essas credenciais são usadas apenas para conectar-se ao servidor e instalar o cPanel e não são armazenadas. Todos os dados são transmitidos com segurança por canais SSH e HTTPS criptografados. Para máxima segurança, considere alterar as credenciais de acesso ao servidor após a instalação.',
            helpUsMakeWebInstallerBetter: 'Ajude-nos a tornar o cPanel Web Installer melhor para todos <a>respondendo a uma breve pesquisa.</a>',
        },
    },
};