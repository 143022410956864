// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';

export class AccountsAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    /* User profile */

    public dpaSigned() {
        this.client.logEvent('DPA Signed');
    }

    public dpaDeclined() {
        this.client.logEvent('DPA Declined');
    }
}
