// Copyright 2024. WebPros International GmbH. All rights reserved.

import { updateInstallations } from '@platform360/security-dashboard/web/api/installations';
import { useMutation } from '@tanstack/react-query';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

const useInstallationsUpdaterMutation = () =>
    useMutation({
        mutationFn: updateInstallations,
        onSuccess: () => {
            refetchList(useInstallationsQuery.getQueryKey());
        },
    });

export default useInstallationsUpdaterMutation;
