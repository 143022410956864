// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { installationMethodValues, isInstallationMethod, InstallationMethods } = makeEnum(
    'InstallationMethod',
    'password',
    'private-key',
);

export type InstallationMethod = UnionOf<typeof installationMethodValues>;
