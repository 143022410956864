// Copyright 2024. WebPros International GmbH. All rights reserved.

import { memo } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import UserMenu from '@platform360/libs/shared-web/components/UserMenu';
import MainMenu from '@platform360/libs/shared-web/components/Page/MainMenu';
import DotsMenu from '@platform360/libs/shared-web/components/Page/DotsMenu';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { useGlobalSearch } from '@platform360/libs/shared-web/helpers/useGlobalSearch';
import { Button } from '@plesk/ui-library';
import useDarkTheme from '@platform360/libs/shared-web/helpers/useDarkTheme';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

type HeaderProps = {
    hideUserMenu?: boolean;
};

export const Header = ({ hideUserMenu }: HeaderProps) => {
    const GlobalSearch = useGlobalSearch();
    const { toggle, isDarkMode, isSupported } = useDarkTheme();
    const {
        header: {
            globalSearchEnabled,
            mainMenuEnabled,
            dotsMenuEnabled,
            addon,
            baseUrl,
            hideDarkThemeSwitcher,
        },
        logo,
        logoRightAddon,
    } = useApplicationSettings();

    return (
        <div className={styles.root}>
            {dotsMenuEnabled && <DotsMenu />}
            <Link className={styles.brand} to={baseUrl} data-type="Page--logo">
                {logo}
            </Link>
            {logoRightAddon}
            {mainMenuEnabled && <MainMenu />}
            {globalSearchEnabled && <GlobalSearch />}
            <div className={styles.options}>
                {addon}
                {!hideUserMenu && <UserMenu />}
                <LocaleSwitcher />
                {isSupported && !hideDarkThemeSwitcher && (
                    <Button ghost icon={isDarkMode ? 'lightbulb' : 'sleep'} onClick={toggle} />
                )}
            </div>
        </div>
    );
};

export default memo(Header);
