// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { CurrentUser } from '@platform360/libs/shared-web/user/user-state';
import { ContentLoader } from '@plesk/ui-library';
import { UseMutateAsyncFunction, UseQueryResult } from '@tanstack/react-query';
import type { SetNonNullable } from 'type-fest';
import { clearSessionInLocalStorage } from '@platform360/libs/shared-web/auth/components/AuthProvider';
import { isTokenRenewalError } from '@platform360/libs/shared-web/helpers/httpErrors';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { UseMutationResult } from '@tanstack/react-query/src/types';

export type UserContextValue = {
    refetchCurrentUser: () => Promise<CurrentUser>;
    depersonate: UseMutateAsyncFunction;
} & SetNonNullable<CurrentUser, 'timezone'>;

export const UserContext = createContext<UserContextValue | undefined>(undefined);

export type UseCurrentUserQuery = () => UseQueryResult<CurrentUser>;

export type UseDepersonateMutation = (options: {
    onSuccess: () => void;
}) => UseMutationResult<unknown, Error, void>;

type UserProviderProps = {
    useCurrentUserQuery: UseCurrentUserQuery;
    useDepersonateMutation: UseDepersonateMutation;
    children: ReactNode;
};

export const UserProvider = ({
    useCurrentUserQuery,
    children,
    useDepersonateMutation,
}: UserProviderProps) => {
    const { isAuthorized, notifyExternalServicesOnLogout } = useAuth();
    const { data: currentUser, refetch, error } = useCurrentUserQuery();

    useEffect(() => {
        if (!error) {
            return;
        }

        // We should manually redirect the user to the home page for the edge-case
        // when 401 error happened while frontend application is not fully loaded
        // or the API token failed to renew.
        if (isTokenRenewalError(error)) {
            const { pathname, search, hash } = window.location;
            const redirectUrl = `${pathname}${search}${hash}`;

            // Clear auth session completely to avoid cycle redirects.
            clearSessionInLocalStorage();
            window.location.href = redirectUrl;
        }
    }, [error]);

    const { mutateAsync: depersonate } = useDepersonateMutation({
        onSuccess: () => {
            void refetch();
            void notifyExternalServicesOnLogout();
        },
    });

    const value = useMemo(
        (): UserContextValue | undefined =>
            currentUser
                ? {
                      ...currentUser,
                      timezone:
                          currentUser.timezone ||
                          new Intl.DateTimeFormat().resolvedOptions().timeZone,
                      refetchCurrentUser: async () => {
                          const { data } = await refetch();
                          if (!data) {
                              throw new Error('Could not refetch current user.');
                          }

                          return data;
                      },
                      depersonate,
                  }
                : undefined,
        [currentUser, depersonate, refetch],
    );

    useEffect(() => {
        if (!currentUser) {
            void refetch();
        }
    }, [currentUser, refetch]);

    useEffect(() => {
        void refetch();
    }, [isAuthorized, refetch]);

    const shouldDisplayLoader = !currentUser || (isAuthorized && !currentUser.isAuthorized);

    return (
        <UserContext.Provider value={value}>
            {shouldDisplayLoader ? <ContentLoader data-type="loader" /> : children}
        </UserContext.Provider>
    );
};

export default UserProvider;
