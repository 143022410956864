// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getLoginLink } from '@platform360/web-installer/web/api/web-installer';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseLoginLinkQueryVariables = {
    guid: string;
};

export type UseLoginLinkQueryData = PromiseValue<ReturnType<typeof getLoginLink>>;

const useLoginLinkQuery = createQuery<UseLoginLinkQueryVariables, UseLoginLinkQueryData>({
    queryName: 'web-installer/useLoginLinkQuery',
    fetcher: getLoginLink,
    useQuery,
});

export default useLoginLinkQuery;
