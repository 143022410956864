// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Paragraph } from '@plesk/ui-library';
import { ReactElement, cloneElement } from 'react';

type UpdateInstallationPopoverProps = {
    onClick: () => void;
    target: ReactElement;
    selectedInstallationDisplayTitle?: string;
    selection: number[];
    onConfirmationShown: () => void;
};

const UpdateInstallationPopover = ({
    onClick,
    onConfirmationShown,
    selectedInstallationDisplayTitle,
    selection,
    target,
    ...props
}: UpdateInstallationPopoverProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.Toolbar.UpdateInstallationPopover',
    );

    const renderPopoverDescription = () => {
        if (selection.length === 1 && selectedInstallationDisplayTitle) {
            return translate('updateSingleInstallationConfirmation', {
                selectedInstallationTitle: selectedInstallationDisplayTitle,
            });
        }

        return translate('updateBatchInstallationsConfirmation', {
            selectedInstallationsCount: selection.length,
        });
    };

    return (
        <ConfirmationPopover
            onClick={onClick}
            onShow={onConfirmationShown}
            target={cloneElement(target, props)}
            actionButtonText={translate('confirmButton')}
            cancelButtonText={translate('cancelButton')}
            confirmationButtonIntent="primary"
        >
            <Paragraph>{renderPopoverDescription()}</Paragraph>
        </ConfirmationPopover>
    );
};

export default UpdateInstallationPopover;
