// Copyright 2024. WebPros International GmbH. All rights reserved.

import useResendTeamInvitationMutation from '@platform360/accounts/web/mutations/useResendTeamInvitationMutation';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { toGeneralError } from '@platform360/libs/shared-web/helpers/apiErrors';
import { Button, ListActions as UILibListActions, ListAction } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ListActionsProps = {
    id: number;
};

const ListActions = ({ id }: ListActionsProps) => {
    const translate = useTranslate('Team.Invitations.ListActions');
    const { successToast, errorToast } = useToaster();
    const { mutate: handleResend, isPending: isLoading } = useResendTeamInvitationMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
        },
        onError: (error) => {
            errorToast(toGeneralError(error));
        },
    });

    return (
        <UILibListActions>
            <ListAction
                icon="send"
                onClick={() => handleResend(id)}
                component={Button}
                state={isLoading ? 'loading' : undefined}
                data-type="re-send-invitation"
                primary
            >
                {translate('resendInvitationTooltip')}
            </ListAction>
        </UILibListActions>
    );
};

export default ListActions;
