// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getOperations } from '@platform360/security-dashboard/web/api/operations';
import {
    OperationsStatusFilter,
    OperationsTypeFilter,
} from '@platform360/security-dashboard/web/types';

export type UseOperationsQueryVariables = {
    operationStatusFilter: OperationsStatusFilter[];
    operationTypeFilter: OperationsTypeFilter[];
} & PaginatedQuery;

export type UseOperationsQueryData = PromiseValue<ReturnType<typeof getOperations>>;

const useOperationsQuery = createQuery<UseOperationsQueryVariables, UseOperationsQueryData>({
    queryName: 'security-dashboard/operations',
    fetcher: getOperations,
    useQuery,
});

export default useOperationsQuery;
