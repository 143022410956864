// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { markAllFinishedOperationsAsRead } from '@platform360/security-dashboard/web/api/operations';
import { useOperationsSummaryQuery } from '@platform360/security-dashboard/web/queries';

const useMarkAllFinishedOperationsAsRead = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: markAllFinishedOperationsAsRead,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: useOperationsSummaryQuery.getQueryKey(),
            });
        },
    });
};

export default useMarkAllFinishedOperationsAsRead;
