// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useWebsocket } from '@platform360/libs/shared-web/websocket';
import { useQueryClient } from '@tanstack/react-query';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import { getServersSummary } from '@platform360/security-dashboard/web/api/servers';
import useShowOperationToast from '@platform360/security-dashboard/web/hooks/useShowOperationToast';
import {
    EventsMap,
    TaskCompletedEventPayload,
    TaskUpdatedEventPayload,
} from '@platform360/security-dashboard/shared/websocket';
import {
    OperationType,
    OperationTypes,
} from '@platform360/security-dashboard/shared/operation-type';
import {
    useInstallationInfoQuery,
    useInstallationsQuery,
    useServersQuery,
    useServersSummaryQuery,
    useVulnerabilitiesQuery,
    useVulnerabilitiesSummaryQuery,
    useInstallationsVulnerabilitiesQuery,
    useInstallationsSummaryQuery,
    useNotificationsQuery,
    useOperationsSummaryQuery,
} from '@platform360/security-dashboard/web/queries';

const MITIGATION_OPERATION_TYPES = [
    OperationTypes.mitigationDeactivateAsset,
    OperationTypes.mitigationActivateAsset,
] as OperationType[];

/**
 * Connects to a WebSocket and listens for events.
 */
const useWebsocketEvents = () => {
    const queryClient = useQueryClient();
    const showOperationToast = useShowOperationToast();

    const handleInstallationsFound = useMemo(() => {
        // Throttle for cases when multiple servers are searching for new sites
        const wait = 7000;
        return debounce(
            () => {
                void queryClient.invalidateQueries({
                    queryKey: useInstallationsSummaryQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useVulnerabilitiesSummaryQuery.getQueryKey(),
                });
            },
            wait,
            { leading: true, trailing: true, maxWait: wait },
        );
    }, [queryClient]);

    useWebsocket<EventsMap>([
        {
            name: 'connect',
            handler: () => {
                // eslint-disable-next-line no-console
                console.info('Connection to ws established...');
            },
        },
        {
            name: 'server-added',
            handler: async () => {
                const [serversSummary] = await Promise.allSettled([
                    getServersSummary(),
                    queryClient.invalidateQueries({
                        queryKey: useServersQuery.getQueryKey(),
                    }),
                ]);

                if (serversSummary.status === 'fulfilled') {
                    queryClient.setQueryData(
                        useServersSummaryQuery.getQueryKey(),
                        serversSummary.value,
                    );
                }
            },
        },
        {
            name: 'notification-created',
            handler: () => {
                void queryClient.invalidateQueries({
                    queryKey: useNotificationsQuery.getQueryKey(),
                });
            },
        },
        {
            name: 'task-updated',
            handler: ({ id, info }: TaskUpdatedEventPayload) => {
                const installationInfoQueryKey = useInstallationInfoQuery.getQueryKey();

                useInstallationInfoQuery.setQueriesData(
                    queryClient,
                    installationInfoQueryKey,
                    (data) => {
                        if (!data?.tasks || data.tasks.length === 0) {
                            return data;
                        }

                        const taskExists = data.tasks.some((task) => task.id === id);
                        if (!taskExists) {
                            return data;
                        }

                        return {
                            ...data,
                            tasks: data.tasks.map((task) =>
                                task.id === id ? { ...task, info } : task,
                            ),
                        };
                    },
                );
            },
        },
        {
            name: 'task-completed',
            handler: ({ operation }: TaskCompletedEventPayload) => {
                handleInstallationsFound.cancel();

                if (operation) {
                    showOperationToast(operation);
                }

                void queryClient.invalidateQueries({
                    queryKey: useInstallationInfoQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useInstallationsSummaryQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useVulnerabilitiesSummaryQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useOperationsSummaryQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                });

                refetchList(useInstallationsQuery.getQueryKey());

                if (operation?.type === OperationTypes.fixVulnerabilitiesViaUpdate) {
                    void queryClient.invalidateQueries({
                        queryKey: useVulnerabilitiesQuery.getQueryKey(),
                    });
                } else {
                    refetchList(useVulnerabilitiesQuery.getQueryKey());
                }

                if (operation?.type && MITIGATION_OPERATION_TYPES.includes(operation.type)) {
                    refetchList(useInstallationsVulnerabilitiesQuery.getQueryKey());
                } else {
                    void queryClient.invalidateQueries({
                        queryKey: useInstallationsVulnerabilitiesQuery.getQueryKey(),
                    });
                }
            },
        },
        {
            name: 'installations-found',
            handler: handleInstallationsFound,
        },
        {
            name: 'installation-detached',
            handler: () => {
                void queryClient.invalidateQueries({
                    queryKey: useInstallationsQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useInstallationsSummaryQuery.getQueryKey(),
                });
                void queryClient.invalidateQueries({
                    queryKey: useVulnerabilitiesSummaryQuery.getQueryKey(),
                });
            },
        },
    ]);
};

export default useWebsocketEvents;
