// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { customInstall } from '@platform360/web-installer/web/api/web-installer';

export type UseCustomInstallOptions = {
    onSuccess: (id: string) => void;
    onError?: (err: unknown) => void;
};

const useCustomInstall = ({ onSuccess, onError }: UseCustomInstallOptions) =>
    useMutation({
        mutationFn: customInstall,
        onSuccess: ({ id }) => onSuccess(id),
        onError,
    });

export default useCustomInstall;
