// Copyright 2024. WebPros International GmbH. All rights reserved.

import { assignServerLabels } from '@platform360/security-dashboard/web/api/servers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useServersQuery } from '@platform360/security-dashboard/web/queries';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';

const useAssignServerLabelsMutation = () => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useAssignServerLabelsMutation');
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: assignServerLabels,
        onSuccess: async (_, opts) => {
            await queryClient.invalidateQueries({
                queryKey: useServersQuery.getQueryKey(),
            });

            successToast(translate('success', { count: opts.labelIds.length }));
        },
        onError: (_, opts) => errorToast(translate('failed', { count: opts.labelIds.length })),
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useAssignServerLabelsMutation;
