// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import {
    Button,
    ExtendedStatusMessage,
    FormFieldCheckbox,
    Status,
    Text,
    Label,
    Heading,
    ButtonProps,
} from '@plesk/ui-library';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { VulnerabilityInstallationOperations } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import { useCountOfAffectedSitesByVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './UpdateButton.module.less';
import { useNavigate } from 'react-router-dom';

type UpdateButtonProps = {
    selectedItems: string[];
    onHandleUpdate: (enableAfterUpdate: boolean) => void;
    onConfirmationShown: () => void;
} & ButtonProps;

export const UpdateButton = ({
    selectedItems,
    onHandleUpdate,
    onConfirmationShown: onConfirmationShownParent,
    ...props
}: UpdateButtonProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Toolbar.UpdateButton');
    const navigate = useNavigate();
    const [enableAfterUpdate, setEnableAfterUpdate] = useState(true);
    const {
        data = {
            affectedSitesCount: 0,
            affectedSitesWithPluginsCount: 0,
            operableNotManaged: 0,
        },
        refetch,
        status,
    } = useCountOfAffectedSitesByVulnerabilitiesQuery({
        variables: {
            vulnerabilitiesIds: selectedItems,
            operation: VulnerabilityInstallationOperations.update,
        },
        enabled: false,
    });
    const btn = <Button {...props}>{translate('update')}</Button>;

    if (props.onClick) return btn;

    const updateAvailable =
        data.affectedSitesCount > 0 && data.affectedSitesCount > data.operableNotManaged;
    const checkShouldShowLicenseInfo = ({ operableNotManaged, affectedSitesCount }: typeof data) =>
        affectedSitesCount === operableNotManaged && affectedSitesCount > 0;
    const isLicenseInfoShown = checkShouldShowLicenseInfo(data);

    if (selectedItems.length === 0) {
        return (
            <NoSelectionPopover selectionCount={selectedItems.length} target={btn}>
                {translate('noSelection')}
            </NoSelectionPopover>
        );
    }

    const onConfirmationShown = () => {
        onConfirmationShownParent();
        void refetch();
    };

    const renderAffectedSites = () => {
        if (status === 'pending') {
            return <Status progress>{translate('sitesCalculating')}</Status>;
        }
        if (status === 'error') {
            return (
                <Status intent="danger" compact>
                    {translate('sitesCalculatingError')}
                </Status>
            );
        }
        return (
            <Label view="light" caps={false} size="md">
                {translate('affectedSites', { count: data.affectedSitesCount })}
            </Label>
        );
    };

    if (isLicenseInfoShown) {
        return (
            <ConfirmationPopover
                onClick={() => {
                    navigate('/security-dashboard/license-page');
                }}
                onShow={onConfirmationShown}
                target={btn}
                popoverClassName={styles.popover}
                actionButtonText={translate('upgradeLicense')}
                cancelButtonText={translate('cancel')}
                confirmationButtonIntent="primary"
            >
                <div className={styles.confirmationSection}>
                    {translate('vulnerabilitiesOnUnmanagedSites')}
                </div>
            </ConfirmationPopover>
        );
    }

    return (
        <ConfirmationPopover
            onClick={() => {
                onHandleUpdate(enableAfterUpdate);
                setEnableAfterUpdate(true);
            }}
            onShow={onConfirmationShown}
            target={btn}
            popoverClassName={styles.popover}
            actionButtonText={translate('update')}
            cancelButtonText={translate('cancel')}
            actionButtonDisabled={!updateAvailable}
            confirmationButtonIntent="primary"
        >
            <div className={styles.confirmationSection}>
                <div className={styles.confirmationSection}>
                    {translate('updateConfirmation', {
                        totalVulnerabilities: selectedItems.length,
                    })}
                </div>

                <div className={styles.confirmationSection}>
                    <Text intent="muted" className={styles.sitesHint}>
                        {translate(
                            data.operableNotManaged ? 'numberOfSitesUnmanaged' : 'numberOfSites',
                        )}
                    </Text>
                    <div className={styles.labelsContainer}>
                        {renderAffectedSites()}
                        {!!data.operableNotManaged && (
                            <Label view="outline" caps={false} size="sm" intent="danger">
                                {translate('unmanagedLabel', {
                                    count: data.operableNotManaged,
                                })}
                            </Label>
                        )}
                    </div>
                </div>

                {data.affectedSitesWithPluginsCount > 0 && (
                    <ExtendedStatusMessage className={styles.pluginSection} intent="info">
                        <Heading level={5}>{translate('updateConfirmationPluginTitle')}</Heading>
                        {translate('updateConfirmationPluginCheckboxHint')}
                        <FormFieldCheckbox
                            label={translate('updateConfirmationPluginCheckbox')}
                            value={enableAfterUpdate}
                            onChange={() => {
                                setEnableAfterUpdate(!enableAfterUpdate);
                            }}
                        />
                    </ExtendedStatusMessage>
                )}
            </div>
        </ConfirmationPopover>
    );
};

export default UpdateButton;
