// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { customVerify } from '@platform360/web-installer/web/api/web-installer';
import { CustomVerifyResponse } from '@platform360/web-installer/shared/api-contract';

export type UseCustomVerifyOptions = {
    onSuccess: (data: CustomVerifyResponse) => void;
    onError: () => void;
};

const useCustomVerify = ({ onSuccess, onError }: UseCustomVerifyOptions) =>
    useMutation({
        mutationFn: customVerify,
        onSuccess,
        onError,
    });

export default useCustomVerify;
