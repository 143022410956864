// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { Notifications } from './Notifications';
import { useNotificationsQuery } from '@platform360/security-dashboard/web/queries';
import { useNavigate } from 'react-router-dom';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import {
    useReadNotificationsMutation,
    useUnreadNotificationMutation,
    useDeleteNotificationMutation,
} from '@platform360/security-dashboard/web/mutations';

export const NotificationsContainer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const { isLoading, data } = useNotificationsQuery({
        refetchInterval: 60000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
    const { mutate: readNotifications } = useReadNotificationsMutation();
    const { mutate: unreadNotification } = useUnreadNotificationMutation();
    const { mutate: deleteNotification } = useDeleteNotificationMutation();
    const handleDeleteRow = (id: number) => {
        deleteNotification(id);
        analyticsEvents.wpSglNotificationsRemoveClick();
    };
    const handleChangeReadRow = (id: number, value: boolean) => {
        if (value) {
            readNotifications([id]);
            analyticsEvents.wpSglNotificationsMarkReadClick();
        } else {
            unreadNotification(id);
            analyticsEvents.wpSglNotificationsMarkUnreadClick();
        }
    };
    const handleMarkAllAsRead = () => {
        readNotifications(data?.map(({ id }) => id) ?? []);
        analyticsEvents.wpMltNotificationsMarkReadClick();
    };
    const handleClose = () => {
        setIsOpen(false);
        analyticsEvents.wpNotificationsClosed();
    };
    const handleSelectRow = (id: number) => {
        analyticsEvents.wpNotificationDetailsClick();
        handleClose();

        const notification = data?.find((n) => n.id === id);
        if (!notification) {
            return;
        }

        if (!notification.isRead) {
            readNotifications([id]);
        }

        switch (notification.code) {
            case 'newExploitedVulnerabilities':
                navigate(
                    '/security-dashboard/vulnerabilities?vulnerability-state-filter=exploited',
                );
                break;
            case 'licenseCreated':
            case 'licenseLimitReached':
            case 'vpsLicenseLimitReached':
            case 'licenseExpired':
            case 'vpsLicenseExpired':
            case 'licenseTerminated':
            case 'vpsLicenseTerminated':
            case 'retailLicenseTerminated':
            case 'trialCreated':
            case 'trialExpired':
            case 'retailTrialExpired':
            case 'trialExpiredSoon':
                navigate('/security-dashboard/license-page');
                break;
        }
    };

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpNotificationsShown();
        }
    }, [isOpen, analyticsEvents]);

    return (
        <Notifications
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            isLoading={isLoading}
            notifications={data}
            onChangeReadRow={handleChangeReadRow}
            onDeleteRow={handleDeleteRow}
            onMarkAllAsRead={handleMarkAllAsRead}
            onSelectRow={handleSelectRow}
        />
    );
};
