// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import { CurrentUser } from '@platform360/libs/shared-web/user/user-state';
import { updateProfile } from '@platform360/accounts/web/api/me';
import { createQuery } from '@platform360/libs/shared-web/query';
import { analyticsClient } from '@platform360/libs/shared-web/analytics';

export const getCurrentUser = async ({ signal }: FetcherOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<CurrentUser>>('/accounts/me', {
        signal,
    });

    return data;
};

export type UseCurrentUserQueryVariables = undefined;

export type UseCurrentUserQueryData = CurrentUser;

export const useCurrentUserQuery = createQuery<
    UseCurrentUserQueryVariables,
    UseCurrentUserQueryData
>({
    queryName: 'accounts/useCurrentUserQuery',
    fetcher: getCurrentUser,
    useQuery: ({ queryFn, ...options }) =>
        useQuery({
            ...options,
            queryFn: async (params) => {
                const data = await queryFn(params);

                const { timezone, teamGuid, webprosUser, monitoring } = data;

                if (monitoring?.userId) {
                    // Temporary solution to be able to retrieve monitoring user id in license reducer.
                    // TODO: get rid of it, propagate monitoring user id from the component.
                    localStorage.setItem('monitoring-user-id', monitoring.userId);
                }

                if (timezone === null) {
                    void updateProfile({
                        timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                }

                if (teamGuid) {
                    analyticsClient.setUserProperties({
                        teamId: teamGuid,
                        webpros_user: !!webprosUser,
                    });
                }

                return data;
            },
            ...options,
            enabled: false,
        }),
});

export const usePatchCurrentUserData = () => {
    const queryClient = useQueryClient();

    return async (data: Partial<CurrentUser>) => {
        await queryClient.cancelQueries({ queryKey: useCurrentUserQuery.getQueryKey() });
        queryClient.setQueryData<UseCurrentUserQueryData | undefined>(
            useCurrentUserQuery.getQueryKey(),
            (oldData) => {
                if (!oldData) {
                    return undefined;
                }

                return { ...oldData, ...data };
            },
        );
    };
};
