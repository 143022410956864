// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './Item.module.css';
import classnames from 'classnames';

export type ItemProps = {
    children: ReactNode;
    intent?: 'danger' | 'success' | 'info' | 'warning';
    layout?: 'header' | 'sidebar';
};

const Item = ({ children, intent, layout = 'header' }: ItemProps) => (
    <li
        className={classnames(styles.root, {
            [styles.intent ?? '']: intent,
            [styles[intent ?? ''] ?? '']: intent,
            [styles[layout] ?? '']: layout,
        })}
    >
        {children}
    </li>
);

export default Item;
