// Copyright 2024. WebPros International GmbH. All rights reserved.

export const PRODUCT_MONITORING_BUSINESS = '360 Monitoring Business';
export const PRODUCT_MONITORING_ENTERPRISE = '360 Monitoring Enterprise';
export const PRODUCT_MONITORING_ENTERPRISE_FOR_PARTNERS = '360 Monitoring Enterprise for Partners';
export const PRODUCT_MONITORING_PRO = '360 Monitoring Pro';
export const PRODUCT_MONITORING_PRO_FLEXIBLE = '360 Monitoring Pro flexible';
export const PRODUCT_MONITORING_LITE = '360 Monitoring Lite';
export const PRODUCT_MONITORING_LITE_CUSTOM = '360 Monitoring Lite Custom';
export const PRODUCT_MONITORING_LITE_CUSTOM_NO_ALERTS = '360 Monitoring Lite Custom No Alerts';
export const PRODUCT_MONITORING_PRO_SMALL = '360 Monitoring Pro Small';
export const PRODUCT_MONITORING_PRO_DAILY_FLEXIBLE = '360 Monitoring Pro daily flexible';

export const MONITORING_PRODUCTS = [
    PRODUCT_MONITORING_BUSINESS,
    PRODUCT_MONITORING_ENTERPRISE,
    PRODUCT_MONITORING_ENTERPRISE_FOR_PARTNERS,
    PRODUCT_MONITORING_PRO,
    PRODUCT_MONITORING_PRO_FLEXIBLE,
    PRODUCT_MONITORING_LITE,
    PRODUCT_MONITORING_LITE_CUSTOM,
    PRODUCT_MONITORING_LITE_CUSTOM_NO_ALERTS,
    PRODUCT_MONITORING_PRO_SMALL,
    PRODUCT_MONITORING_PRO_DAILY_FLEXIBLE,
];

export const PRODUCT_WP_GUARDIAN_1 = 'WP Guardian 1 Site';
export const PRODUCT_WP_GUARDIAN_5 = 'WP Guardian 5 Sites';
export const PRODUCT_WP_GUARDIAN_10 = 'WP Guardian 10 Sites';
export const PRODUCT_WP_GUARDIAN_20 = 'WP Guardian 20 Sites';
export const PRODUCT_WP_GUARDIAN_30 = 'WP Guardian 30 Sites';
export const PRODUCT_WP_GUARDIAN_50 = 'WP Guardian 50 Sites';
export const PRODUCT_WP_GUARDIAN_100 = 'WP Guardian 100 Sites';
export const PRODUCT_WP_GUARDIAN_1_RETAIL = 'WP Guardian 1 Site for Online Store';
export const PRODUCT_WP_GUARDIAN_5_RETAIL = 'WP Guardian 5 Sites for Online Store';
export const PRODUCT_WP_GUARDIAN_10_RETAIL = 'WP Guardian 10 Sites for Online Store';
export const PRODUCT_WP_GUARDIAN_30_RETAIL = 'WP Guardian 30 Sites for Online Store';
export const PRODUCT_WP_GUARDIAN_50_RETAIL = 'WP Guardian 50 Sites for Online Store';
export const PRODUCT_WP_GUARDIAN_500 = 'WP Guardian 500 Sites';
export const PRODUCT_WP_GUARDIAN_1000 = 'WP Guardian 1000 Sites';
export const PRODUCT_WP_GUARDIAN_10000 = 'WP Guardian 10000 Sites';
export const PRODUCT_WP_GUARDIAN_UNLIMITED_30 = 'WP Guardian Pay-as-you-go 30+ Sites';
export const PRODUCT_WP_GUARDIAN_UNLIMITED = 'WP Guardian Pay-as-you-go 10000+ Sites';

export const WP_GUARDIAN_PRODUCTS = [
    PRODUCT_WP_GUARDIAN_1,
    PRODUCT_WP_GUARDIAN_5,
    PRODUCT_WP_GUARDIAN_10,
    PRODUCT_WP_GUARDIAN_20,
    PRODUCT_WP_GUARDIAN_30,
    PRODUCT_WP_GUARDIAN_50,
    PRODUCT_WP_GUARDIAN_100,
    PRODUCT_WP_GUARDIAN_1_RETAIL,
    PRODUCT_WP_GUARDIAN_5_RETAIL,
    PRODUCT_WP_GUARDIAN_10_RETAIL,
    PRODUCT_WP_GUARDIAN_30_RETAIL,
    PRODUCT_WP_GUARDIAN_50_RETAIL,
    PRODUCT_WP_GUARDIAN_500,
    PRODUCT_WP_GUARDIAN_1000,
    PRODUCT_WP_GUARDIAN_10000,
    PRODUCT_WP_GUARDIAN_UNLIMITED_30,
    PRODUCT_WP_GUARDIAN_UNLIMITED,
];
