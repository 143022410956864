// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Token di autorizzazione mancante o non valido.',
        invitationNotExist: 'L\'invito non esiste.',
        invitationExpired: 'L\'invito è scaduto.',
        invitationConflict: 'L\'utente invitato è già registrato.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'La tua password è stata modificata.',
    },
    ChangePasswordForm: {
        resetTitle: 'Nuova password',
        setTitle: 'Impostare la password',
        passwordLabel: 'Password:',
        submitButton: 'Conferma',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-mail di reimpostazione password inviata',
        successSendResetText: 'Abbiamo inviato un\'e-mail con un link per reimpostare la password all\'indirizzo {email}. Fai clic sul link per accedere a Plesk 360 e impostare una nuova password.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Link scaduto.',
        resetText: 'Il link di reimpostazione della password è scaduto.',
        setText: 'Il link di reimpostazione della password non è più valido. Per la tua sicurezza, il link scade dopo 4 ore. Se non hai tempo per usare il link, puoi digitare nuovamente la tua e-mail qui sotto e impostare la password seguendo la relativa procedura di modifica.',
        emailLabel: 'E-mail:',
        submitButton: 'Invia',
        bottomText: 'Ricordi la password? Puoi <a>accedere subito</a>.',
    },
};