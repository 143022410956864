// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getMarketingConsent } from '@platform360/web-installer/web/api/web-installer';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseGetMarketingConsentQueryVariables = undefined;

export type UseGetMarketingConsentQueryData = PromiseValue<ReturnType<typeof getMarketingConsent>>;

const useGetMarketingConsentQuery = createQuery<
    UseGetMarketingConsentQueryVariables,
    UseGetMarketingConsentQueryData
>({
    queryName: 'web-installer/consents/marketing',
    fetcher: getMarketingConsent,
    useQuery,
});

export default useGetMarketingConsentQuery;
