// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DEA } from '@platform360/licenses/web/api/ka/const/cleverbridgeItemStatus';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import useKaKeysQuery from '@platform360/licenses/web/queries/useKaKeysQuery';
import isReactivatePossible from '@platform360/licenses/web/units/reactivateSubscription/helpers/isReactivatePossible';

export const useKaKey = () => {
    const {
        data: licenseData,
        isLoading: isLicenseLoading,
        isError: isLicenseError,
    } = useLicenseQuery();

    const {
        data: kaKeys,
        isLoading: isKaKeysLoading,
        isError: isKaKeysError,
    } = useKaKeysQuery({
        variables: { activeOnly: true },
    });

    const kaKey = kaKeys?.find(({ mainKey }) => mainKey.id === licenseData?.licenseKey);

    return {
        kaKey,
        isDeactivated: kaKey ? kaKey.mainKey.cbItemStatusId === DEA : null,
        isReactivatePossible: kaKey ? isReactivatePossible(kaKey.mainKey) : null,
        isLoading: isLicenseLoading || isKaKeysLoading,
        isError: isLicenseError || isKaKeysError,
    } as const;
};
