// Copyright 2024. WebPros International GmbH. All rights reserved.

import SyncServersOperation from '@platform360/server-inventory/web/components/Servers/SyncServersOperation';
import DisconnectServersOperation from '@platform360/server-inventory/web/components/Servers/DisconnectServersOperation';
import { Server } from '@platform360/server-inventory/web/types';
import {
    ToolbarGroup,
    Toolbar as UILibToolbar,
    ToolbarExpander,
    SearchBar,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { AddingServerButton } from '@platform360/server-inventory/web/components/Servers/AddingServer';

type ToolbarProps = {
    selection: number[];
    servers: Server[];
    onServersSync: (serverIds: number[]) => void;
    onServersDisconnectConfirm?: () => void;
    onServersDisconnected: (disconnectedServersCount: number) => void;
    searchValue?: string;
    onSearch?: (value: string) => void;
};

export const Toolbar = ({
    selection,
    servers,
    onServersSync,
    onServersDisconnectConfirm,
    onServersDisconnected,
    searchValue,
    onSearch,
}: ToolbarProps) => {
    const translate = useTranslate('Servers.Toolbar');

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('add')}>
                <AddingServerButton />
            </ToolbarGroup>
            <ToolbarGroup title={translate('sync')}>
                <SyncServersOperation
                    selectionCount={selection.length}
                    onOperation={() => {
                        if (selection.length > 0) {
                            onServersSync(selection);
                        } else {
                            onServersSync(servers.map(({ id }) => id));
                        }
                    }}
                />
            </ToolbarGroup>
            <ToolbarGroup title={translate('disconnect')}>
                <DisconnectServersOperation
                    onConfirm={onServersDisconnectConfirm}
                    onSuccess={onServersDisconnected}
                    servers={servers.filter(({ id }) => selection.includes(id))}
                />
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('groupSearch')}>
                <SearchBar
                    inputProps={{
                        value: searchValue,
                    }}
                    onSearch={(value: string) => onSearch?.(value)}
                    onTyping={onSearch}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
