// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Media, Text, Tooltip, Status } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    NotificationCode,
    NotificationsParams,
} from '@platform360/security-dashboard/shared/notifications';
import { Intent } from '../helpers';
import styles from './TitleColumn.module.css';

const codeContextKey = {
    newExploitedVulnerabilities: 'newExploitedVulnerabilities',
    licenseCreated: 'licenseCreated',
    licenseLimitReached: 'licenseLimitReached',
    vpsLicenseLimitReached: 'licenseLimitReached',
    licenseExpired: 'licenseExpired',
    vpsLicenseExpired: 'licenseExpired',
    licenseTerminated: 'licenseTerminated',
    vpsLicenseTerminated: 'licenseTerminated',
    retailLicenseTerminated: 'licenseTerminated',
    trialCreated: 'trialCreated',
    trialExpired: 'trialExpired',
    retailTrialExpired: 'trialExpired',
    trialExpiredSoon: 'trialExpiredSoon',
};
const codeTitleKey = {
    newExploitedVulnerabilities: 'newExploitedVulnerabilities',
    licenseCreated: 'licenseCreated',
    licenseLimitReached: 'licenseLimitReached',
    vpsLicenseLimitReached: 'vpsLicenseLimitReached',
    licenseExpired: 'licenseExpired',
    vpsLicenseExpired: 'vpsLicenseExpired',
    licenseTerminated: 'licenseTerminated',
    vpsLicenseTerminated: 'vpsLicenseTerminated',
    retailLicenseTerminated: 'retailLicenseTerminated',
    trialCreated: 'trialCreated',
    trialExpired: 'trialExpired',
    retailTrialExpired: 'trialExpired',
    trialExpiredSoon: 'trialExpiredSoon',
};

type NotificationType = keyof NotificationsParams;
export type TitleColumnProps = {
    id: number;
    code: NotificationCode;
    params: NotificationsParams[NotificationType];
    isRead: boolean;
    intent: Intent;
    onChangeReadRow: (id: number, value: boolean) => void;
    onSelectRow: (id: number) => void;
};

const fixMissingNotificationParamsFields = <T extends NotificationType>(
    codeTitle: T,
    params: NotificationsParams[T],
) => {
    if (codeTitle === 'trialCreated' && !('sitesLimit' in params)) {
        (params as NotificationsParams['trialCreated']).sitesLimit = 10000;
    }

    return params;
};

export const TitleColumn = ({
    id,
    code,
    params,
    isRead,
    intent,
    onChangeReadRow,
    onSelectRow,
    ...props
}: TitleColumnProps) => {
    const translate = useTranslate('security-dashboard.Notifications.TitleColumn');
    const textIntent = intent === 'inactive' ? undefined : intent;

    const fixedParams = fixMissingNotificationParamsFields(code, params);
    return (
        <Media
            image={
                <Tooltip title={translate(isRead ? 'markAsUnread' : 'markAsRead')}>
                    <div className={styles.status} onClick={() => onChangeReadRow(id, !isRead)}>
                        {isRead ? (
                            <span
                                className={styles.readStatus}
                                data-type="notification-status-read"
                            />
                        ) : (
                            <Status
                                compact
                                intent={intent}
                                data-type="notification-status-unread"
                            />
                        )}
                    </div>
                </Tooltip>
            }
            {...props}
        >
            <div className={styles.title} onClick={() => onSelectRow(id)}>
                <Text intent={textIntent}>
                    {translate(`notificationContext.${codeContextKey[code]}`, fixedParams)}
                </Text>
                <br />
                <Text intent={textIntent} bold={!isRead} data-type="notification-title">
                    {translate(`notificationTitle.${codeTitleKey[code]}`, fixedParams)}
                </Text>
            </div>
        </Media>
    );
};
