// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { operationSubTypeValues, OperationSubTypes } = makeEnum(
    'OperationSubType',
    'servers',
    'installations',
    'vulnerabilities',
);

export type OperationSubType = UnionOf<typeof operationSubTypeValues>;
