// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ProgressBar } from '@plesk/ui-library';
import { Task } from '@platform360/security-dashboard/web/types';
import styles from './TaskProgress.module.css';
import useTaskTypesTranslate from '@platform360/security-dashboard/web/hooks/useTaskTypesTranslate';

export type TaskProgressProps = {
    task: Task;
};
const TaskProgress = ({ task }: TaskProgressProps) => {
    const taskTypeTranslate = useTaskTypesTranslate();

    return (
        <div className={styles.progress}>
            <div className={styles.taskType}>
                {taskTypeTranslate(task.type) || task.info?.title}
            </div>
            <ProgressBar className={styles.progressBar} />
        </div>
    );
};
export default TaskProgress;
