// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import styles from './ConnectCpanelServerStep.module.less';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';
import { Link } from 'react-router-dom';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import InputCopy from '@platform360/libs/shared-web/components/InputCopy';
import StepForm from '@platform360/server-inventory/web/components/Servers/AddingServer/StepForm';

export type ConnectCpanelServerStepProps = {
    onBack: () => void;
    onClose: () => void;
};

export const ConnectCpanelServerStep = ({
    onBack,
    onClose,
    ...props
}: ConnectCpanelServerStepProps) => {
    const translate = useTranslate(
        'server-inventory.AddingServer.AddingServerWizard.ConnectCpanelServerStep',
    );
    const { repoUrl } = useConfig();
    const getModalUrl = useGetModalUrl();
    const analyticsEvents = useServerInventoryAnalyticsEvents();

    const pluginInstallerUrl = concatUrl('/cpanel-plugin-installer.sh', repoUrl);
    const installationSnippet = `sh <(curl '${pluginInstallerUrl}' || wget -O - '${pluginInstallerUrl}') && platform360-cpanel-plugin connect-server`;

    useEffectOnce(() => {
        analyticsEvents.addServerFormOpen('cpanel');
    });

    return (
        <StepForm
            {...props}
            title={translate('title')}
            onSubmit={onClose}
            backButton={{ children: translate('back'), onClick: onBack }}
            forwardButton={{
                children: translate('close'),
            }}
            data-type="add-cpanel-step"
        >
            <ol className={styles.list}>
                <li>
                    {translate('paragraph1')}
                    <InputCopy
                        value={installationSnippet}
                        successMessage={translate('copied')}
                        className={styles.snippet}
                    />
                </li>
                <li>
                    {translate('paragraph2', {
                        a: (chunk) => <Link to={getModalUrl('api-tokens', true)}>{chunk}</Link>,
                    })}
                </li>
                <li>{translate('paragraph3')}</li>
            </ol>
        </StepForm>
    );
};

export default ConnectCpanelServerStep;
