// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Navigate, RouteObject } from 'react-router-dom';
import * as Auth from '@platform360/auth/web/components';
import Login from '@platform360/auth/web/components/Login';
import SignUp from '@platform360/auth/web/components/SignUp';

export const routes: RouteObject[] = [
    {
        path: 'auth',
        children: [
            {
                path: 'callback',
                element: <Auth.Callback />,
            },
            {
                path: 'logout',
                element: <Auth.Logout />,
            },
            {
                path: 'logout-callback',
                element: <Auth.LogoutCallback />,
            },
            {
                path: 'emailVerified',
                element: <Auth.EmailVerified />,
            },
            {
                path: 'resetPassword',
                element: <Auth.ChangePassword />,
            },
            {
                path: 'setPassword',
                element: <Auth.ChangePassword useCase="set" />,
            },
            {
                element: <Auth.Auth />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="/not-found" replace />,
                    },
                    {
                        // * in the path for `login` and `signup` is for compatibility with previous code version
                        path: 'login/*',
                        element: <Login />,
                    },
                    {
                        path: 'signup/*',
                        element: <SignUp />,
                    },
                ],
            },
        ],
    },
];
