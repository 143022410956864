// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon, Popover, Text } from '@plesk/ui-library';
import styles from './InstallationsCard.module.css';
import TotalCount from '@platform360/security-dashboard/web/components/Layout/Header/TotalCount';
import ProgressBar from '@platform360/security-dashboard/web/components/Layout/Header/InstallationsCard/ProgressBar';
import { SitesHealthFilter } from '@platform360/security-dashboard/web/types';
import CardLoader from '@platform360/security-dashboard/web/components/Layout/Header/CardLoader';
import { InstallationsSummaryResponse } from '@platform360/security-dashboard/web/api/installations';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';

type InstallationsCardProps = {
    data: InstallationsSummaryResponse | undefined;
    isLoading: boolean;
};
const InstallationsCard = ({ data, isLoading }: InstallationsCardProps) => {
    const translate = useTranslate('security-dashboard.Layout.Header.InstallationsCard');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    if (isLoading || !data) {
        return <CardLoader />;
    }

    return (
        <div className={styles.container} data-type="installations-widget">
            <div className={styles.installations}>
                <Text intent="muted" className={styles.title}>
                    {translate('title')}
                </Text>
                <TotalCount icon={'site-page'} count={data.total} />
            </div>
            <div className={styles.divider} />
            <div className={styles.stats}>
                <ProgressBar
                    total={data.total}
                    current={data.vulnerable}
                    intent="danger"
                    icon="exclamation-mark-circle"
                    siteHealthFilter={SitesHealthFilter.Vulnerable}
                    onClick={() => analyticsEvents.wpDshVulnerableClick(data.vulnerable)}
                >
                    {translate('vulnerable', { count: data.vulnerable })}
                </ProgressBar>
                <ProgressBar
                    total={data.total}
                    current={data.outdated}
                    intent="warning"
                    icon="triangle-exclamation-mark"
                    siteHealthFilter={SitesHealthFilter.Outdated}
                    onClick={() => analyticsEvents.wpDshOutdatedClick(data.outdated)}
                >
                    {translate('outdated', { count: data.outdated })}
                </ProgressBar>
                <ProgressBar
                    total={data.total}
                    current={data.protected}
                    intent="success"
                    icon={
                        <Popover target={<Icon name={'shield'} intent="success" />}>
                            {translate('poweredBy', {
                                providerLogo: <Patchstack />,
                                whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                            })}
                        </Popover>
                    }
                    siteHealthFilter={SitesHealthFilter.Protected}
                    onClick={() => analyticsEvents.wpDshProtectedClick(data.protected)}
                >
                    {translate('protected', { count: data.protected })}
                </ProgressBar>
                <ProgressBar
                    total={data.total}
                    current={data.notOperable}
                    intent="muted"
                    icon="question-mark-circle"
                    siteHealthFilter={SitesHealthFilter.NotOperable}
                    onClick={() => analyticsEvents.wpDshUnknownClick(data.notOperable)}
                >
                    {translate('notOperable', { count: data.notOperable })}
                </ProgressBar>
                <ProgressBar
                    total={data.total}
                    current={data.safe}
                    intent="info"
                    icon="check-mark-circle"
                    siteHealthFilter={SitesHealthFilter.Safe}
                    onClick={() => analyticsEvents.wpDshSafeClick(data.safe)}
                >
                    {translate('safe', { count: data.safe })}
                </ProgressBar>
            </div>
        </div>
    );
};

export default InstallationsCard;
