// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Layout as UILibLayout } from '@plesk/ui-library';
import Header from '@platform360/libs/shared-web/components/Page/Header';
import ImpersonationBanner from '@platform360/libs/shared-web/components/Page/ImpersonationBanner';
import TopMessages from '@platform360/libs/shared-web/components/Page/TopMessages';
import ZendeskChat from '@platform360/libs/shared-web/components/ZendeskChat';

import { useAuth } from '@platform360/libs/shared-web/auth';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

import styles from './Layout.module.css';

type LayoutProps = {
    children: ReactNode;
    hideUserMenu?: boolean;
    showUnauthorizedHeader?: boolean;
};

const Layout = ({ hideUserMenu, showUnauthorizedHeader, children }: LayoutProps) => {
    const { footer, zendeskChat } = useApplicationSettings();
    const {
        name,
        email,
        features: { hiddenText, topMessages },
    } = useCurrentUser();
    const { isAuthorized } = useAuth();

    return (
        <>
            <UILibLayout
                className={styles.layoutContainer}
                header={
                    (isAuthorized || showUnauthorizedHeader) && (
                        <>
                            <ImpersonationBanner />
                            <TopMessages messages={topMessages} />
                            <Header hideUserMenu={hideUserMenu} />
                        </>
                    )
                }
            >
                {children}
                {zendeskChat && <ZendeskChat options={zendeskChat} customer={{ name, email }} />}
                {hiddenText ? (
                    <div data-type="hidden-text" style={{ display: 'none' }}>
                        {hiddenText}
                    </div>
                ) : null}
            </UILibLayout>
            {/* Footer is outside of Layout to be page wide instead of central column wide */}
            {footer}
        </>
    );
};

export default Layout;
