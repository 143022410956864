// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useKaKey } from '@platform360/security-dashboard/web/hooks/useKaKey';
import LinkEmailPopover from '@platform360/security-dashboard/web/components/LicensePage/LinkEmailPopover';
import styles from './UpgradeButton.module.css';

type UpgradeButtonProps = {
    onClick?: () => void;
    size?: 'md' | 'lg';
    productNamePrefix: string;
    children?: React.ReactNode;
};

const UpgradeButton = ({ onClick, productNamePrefix, ...props }: UpgradeButtonProps) => {
    const translate = useTranslate('security-dashboard.LicensePage.UpgradeButton');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const { kaKey, isLoading: isKaKeyLoading, isError: isKaKeyError } = useKaKey();

    const commonButtonProps = {
        intent: 'primary',
        children: translate('upgrade'),
        ...props,
    } as const;

    if (isKaKeyLoading || isKaKeyError) {
        return <Button className={styles.hiddenButton} {...commonButtonProps} />;
    }

    if (!kaKey) {
        return (
            <LinkEmailPopover
                target={
                    <Button
                        {...commonButtonProps}
                        onClick={() => {
                            onClick?.();
                            analyticsEvents.wpLicensePageUpgradeLinkEmailPopoverShown();
                        }}
                    />
                }
            />
        );
    }

    return (
        <Button
            component={Link}
            to={`/licensing/licenses/${kaKey.mainKey.id}/upgrade?product=${productNamePrefix}`}
            onClick={() => {
                window.scrollTo(0, 0);
                onClick?.();
            }}
            {...commonButtonProps}
        />
    );
};

export default UpgradeButton;
