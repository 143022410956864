// Copyright 2024. WebPros International GmbH. All rights reserved.

import { PopoverProps } from '@plesk/ui-library';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { ReactElement } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type DeleteLabelsPopoverProps = {
    labelIds: number[];
    target: (() => ReactElement) | ReactElement;
    placement?: PopoverProps['placement'];
    onConfirmationShown?: () => void;
    onConfirm: (labelIds: number[]) => void;
};

export const DeleteLabelsPopover = ({
    labelIds,
    target,
    onConfirmationShown,
    onConfirm,
    ...props
}: DeleteLabelsPopoverProps) => {
    const translate = useTranslate('security-dashboard.LabelsDrawer.DeleteLabelsPopover');

    const handleConfirmationClick = () => {
        onConfirm(labelIds);
    };

    return (
        <ConfirmationPopover
            onClick={handleConfirmationClick}
            onShow={onConfirmationShown}
            target={typeof target === 'function' ? target() : target}
            actionButtonText={translate('delete')}
            {...props}
        >
            {translate('confirmation', { count: labelIds.length })}
        </ConfirmationPopover>
    );
};

export default DeleteLabelsPopover;
