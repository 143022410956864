// Copyright 2024. WebPros International GmbH. All rights reserved.

import { StatusMessage } from '@plesk/ui-library';
import {
    translateError,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';

type GeneralValidationErrorsProps = {
    errors: ValidationError[];
};

const GeneralValidationErrors = ({ errors }: GeneralValidationErrorsProps) =>
    errors.length > 0 ? (
        <>
            {errors.map((error, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StatusMessage key={i.toString()} intent="danger" data-type="general-error">
                    {translateError(error)}
                </StatusMessage>
            ))}
        </>
    ) : null;

export default GeneralValidationErrors;
