// Copyright 2024. WebPros International GmbH. All rights reserved.
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';

import { EVENT_NAME, EventPayload } from '@platform360/libs/shared-web/toaster';
import { useEffect } from 'react';

const ELIGIBLE_TOAST_EVENT_TYPES = new Set([
    'send-success-toast',
    'send-error-toast',
    'send-warning-toast',
]);
export const useToasterAnalytics = () => {
    const securityDashboardAnalyticsEvents = useSecurityDashboardAnalyticsEvents();

    useEffect(() => {
        const handleEvent = ({ detail: { type } }: CustomEvent<EventPayload>) => {
            if (ELIGIBLE_TOAST_EVENT_TYPES.has(type)) {
                securityDashboardAnalyticsEvents.wpToasterShown(type);
            }
        };
        document.addEventListener(EVENT_NAME, handleEvent);

        return () => {
            document.removeEventListener(EVENT_NAME, handleEvent);
        };
    }, [securityDashboardAnalyticsEvents]);
};
