// Copyright 2024. WebPros International GmbH. All rights reserved.

import { WebInstallerProducts } from '@platform360/web-installer/shared/web-installer-product-type';

export const longSurveyLink = {
    [WebInstallerProducts.plesk]: 'https://pt-research.typeform.com/to/NOiicZ',
    [WebInstallerProducts.cpanel]: 'https://pt-research.typeform.com/to/uRXLgLb0',
} as const;
export const shortSurveyLink = {
    [WebInstallerProducts.plesk]: 'https://pt-research.typeform.com/to/wUiSuO',
    [WebInstallerProducts.cpanel]: 'https://pt-research.typeform.com/to/NbvIpDU0',
} as const;
export const alreadyInstalledPanelSurveyLink = {
    [WebInstallerProducts.plesk]: 'https://pt-research.typeform.com/to/pPY9id',
    [WebInstallerProducts.cpanel]: 'https://pt-research.typeform.com/to/ZbOhoVB2',
} as const;
