// Copyright 2024. WebPros International GmbH. All rights reserved.

import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Extension from '@platform360/licenses/web/units/extensions/types/Extension';
import { ExtensionProps } from '@platform360/licenses/web/units/extensions/components/Extension';

const prepareDataForComponent =
    (currency: Currency, period: BillingPeriod) =>
    (extension: Extension): Omit<ExtensionProps, 'onClick'> => {
        const { catalogCode, storeLink, link, name, subProducts } = extension;
        const products = subProducts[`${currency}-${period}`];

        return {
            catalogCode,
            link,
            storeLink,
            name,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            minPrice: products!.minPrice,
            period,
            currency,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            hasSubset: products!.upsells.length > 0,
        };
    };

export default prepareDataForComponent;
