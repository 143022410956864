// Copyright 2024. WebPros International GmbH. All rights reserved.

import { MONTHLY } from '@platform360/licenses/web/constants/billingPeriod';
import Subscription from '@platform360/licenses/web/units/extensions/types/Subscription';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Currency from '@platform360/licenses/web/types/currency';
import ExtensionType from '@platform360/licenses/web/units/extensions/types/Extension';
import LicenseType from '@platform360/licenses/web/units/extensions/types/License';

type GroupMap = Record<string, Group>;

type Group = Record<string, ExtensionType>;

type InternalState = {
    initialized: boolean;
    selectedItemPath: string;
    failedInitialization: boolean;
};

export type InitialState = {
    monthly: GroupMap;
    yearly: GroupMap;
    keyNumber?: string;
    subscriptions: Subscription[];
    subscriptionByDefault: string | undefined;
    period: BillingPeriod;
    currency: Currency;
    currencies: Currency[] | [];
    licenseId: number;
    license: LicenseType;
};

export type FullInitialState = InternalState & InitialState;

const initialState = {
    initialized: false,
    monthly: {},
    yearly: {},
    keyNumber: '',
    subscriptions: [],
    subscriptionByDefault: undefined,
    period: MONTHLY,
    selectedItemPath: null,
    currency: 'EUR',
    currencies: [],

    failedInitialization: false,
};

export default initialState;
