// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FLUSH_ITEM } from '@platform360/licenses/web/units/extensions/constants/actions';

export type FlushItemAction = {
    type: typeof FLUSH_ITEM;
};

const flushItem = (): FlushItemAction => ({
    type: FLUSH_ITEM,
});

export default flushItem;
