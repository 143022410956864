// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, FormFieldSelect, SelectOption } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ParsedTimezone } from './helpers';

export type TimezoneDialogProps = {
    timezone: string;
    region: string;
    timezones: ParsedTimezone[];
    regions: string[];
    isOpen: boolean;
    isLoading: boolean;
    onRegionChange: (region: string) => void;
    onTimezoneChange: (timezone: string) => void;
    onSave: () => void;
    onCancel: () => void;
};

export const TimezoneDialog = ({
    timezone,
    region,
    timezones,
    regions,
    isOpen,
    isLoading,
    onRegionChange,
    onTimezoneChange,
    onCancel,
    onSave,
}: TimezoneDialogProps) => {
    const translate = useTranslate('accounts.profile.Timezone');

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onCancel}
            title={translate('dialogTitle')}
            size="xs"
            data-type="timezone-edit-dialog"
            form={{
                values: { timezone },
                state: isLoading ? 'submit' : undefined,
                onSubmit: () => onSave(),
                submitButton: { children: translate('buttonSave'), 'data-type': 'save-button' },
                cancelButton: {
                    children: translate('buttonCancel'),
                    'data-type': 'cancel-button',
                },
            }}
        >
            <FormFieldSelect
                label={translate('timezone')}
                searchable
                size="lg"
                value={region}
                onChange={onRegionChange}
            >
                {regions.map((region) => (
                    <SelectOption key={region} value={region}>
                        {region}
                    </SelectOption>
                ))}
            </FormFieldSelect>
            <FormFieldSelect name="timezone" searchable size="lg" onChange={onTimezoneChange}>
                {timezones.map(({ name, title }) => (
                    <SelectOption key={name} value={name}>
                        {title}
                    </SelectOption>
                ))}
            </FormFieldSelect>
        </Dialog>
    );
};
