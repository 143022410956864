// Copyright 2024. WebPros International GmbH. All rights reserved.

export const parseTimezone = (timezone: string): [string, string] => {
    const [region, ...city] = timezone.split('/');
    if (!region) {
        throw new Error(`Wrong timezone format '${timezone}'.`);
    }

    return [region, city.join('/').replaceAll('_', ' ')];
};

export type ParsedTimezone = {
    name: string;
    region: string;
    title: string;
    offset: number;
};

export const getTimezones = (): [ParsedTimezone[], string[]] => {
    const regions = new Set<string>();
    const zones: ParsedTimezone[] = [];
    Intl.supportedValuesOf('timeZone')
        .map((zone): [string, string, string] => [zone, ...parseTimezone(zone)])
        // Skip Etc/GMT+1, CET, etc.
        .filter(([, region, title]) => region !== 'Etc' && title !== '')
        .forEach(([zone, region, title]) => {
            const offset = parseOffset(zone);
            zones.push({
                name: zone,
                region,
                offset: offset[1],
                title: `(GMT${offset[0]}) ${title}`,
            });
            regions.add(region);
        });

    return [zones.sort(sortZonesByOffset), Array.from(regions)];
};

const parseOffset = (timeZone: string): [string, number] => {
    const timeZoneNamePart = new Intl.DateTimeFormat('ia', {
        timeZoneName: 'shortOffset',
        timeZone,
    })
        .formatToParts()
        .find(({ type }) => type === 'timeZoneName');

    if (!timeZoneNamePart) {
        throw new Error(`Could not format timezone '${timeZone}'.`);
    }

    const timeZoneName = timeZoneNamePart.value;
    const [, sign = '+', first = '00', second = '00'] =
        /^GMT([+-])?(\d{1,2})?:?(\d{1,2})?/.exec(timeZoneName) || [];

    const formattedFirst = `0${first}`.slice(-2);
    return [`${sign}${formattedFirst}:${second}`, parseFloat(`${sign}${first}.${second}`)];
};

const sortZonesByOffset = (z1: ParsedTimezone, z2: ParsedTimezone) => {
    if (z1.offset < z2.offset) {
        return -1;
    }

    if (z1.offset > z2.offset) {
        return 1;
    }

    return 0;
};
