// Copyright 2024. WebPros International GmbH. All rights reserved.

/**
 * Concatenate path and baseURL in different way from the specification
 * https://developer.mozilla.org/ru/docs/Web/API/URL/URL
 * It is always merges pathname parts, no matter slashes
 * @param path
 * @param baseUrl
 */
export const concatUrl = (path: string, baseUrl: string): string => {
    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    const url = new URL(baseUrl);
    if (!url.pathname.endsWith('/')) {
        url.pathname += '/';
    }

    return new URL(path, url).href;
};
