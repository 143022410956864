// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'I 360 Tools',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Inventario server',
            description: 'Tutte le istanze registrate da un\'unica posizione e un insieme di credenziali',
        },
        licenseManagement: {
            title: 'Gestione di licenza',
            description: 'Compra e gestisci tutte le tue licenze relative a Plesk, le sue estensioni e i 360 Tools',
        },
        feedback: {
            title: 'Condividi il tuo feedback',
            description: 'Questo ci sarà d\'aiuto per lo sviluppo futuro',
        },
        websiteInventory: {
            title: 'Inventatio dei siti web',
            description: 'Visualizza e gestisci tutti i tuoi siti web',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Monitora tutti i tuoi server e i tuoi siti web da un\'unica dashboard',
            description: 'Una soluzione centralizzata per tenere traccia delle prestazioni e prevenire le interruzioni, che offre dashboard configurabili, notifiche personalizzate, controlli ogni minuto, cronologia della rete e altro ancora',
            primaryButton: 'Effettua una prova gratuita di 14 giorni',
            secondaryButton: 'Per saperne di più',
        },
        licenses: {
            title: 'Gestisci le tue licenze',
            description: 'Acquista, regola e termina tutte le tue licenze relative a Plesk, le sue estensioni e 360 Tools da un unico luogo',
            primaryButton: 'Avanti',
            secondaryButton: 'Per saperne di più',
        },
        serverInventory: {
            title: 'Tutte le istanze registrate da un\'unica posizione e un insieme di credenziali',
            description: 'Connetti tutti i tuoi server Plesk all\'inventario server per ottenere la lista completa dei tuoi siti web e configurare un accesso con un clic per ciascuno di essi',
            primaryButton: 'Connetti i server',
            secondaryButton: 'Per saperne di più',
        },
        websiteInventory: {
            title: 'Tutti i tuoi siti web e i tuoi domini in un unico elenco',
            description: 'Visualizza, cerca, filtra i tuoi siti web e passa a gestirli con un solo clic',
            primaryButton: 'Visualizza siti web',
            secondaryButton: 'Per saperne di più',
        },
    },
};