// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { verifyNotificationsEmail } from '@platform360/security-dashboard/web/api/notifications-settings';

type UseVerifyNotificationsEmailMutationOptions = {
    onSuccess: (data: { email: string }) => void;
};

const useVerifyNotificationsEmailMutation = (options: UseVerifyNotificationsEmailMutationOptions) =>
    useMutation({ mutationFn: verifyNotificationsEmail, ...options });

export default useVerifyNotificationsEmailMutation;
