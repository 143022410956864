// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer, StatusMessage, ContentLoader } from '@plesk/ui-library';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Img from '@platform360/licenses/web/components/Img';
import UpgradeOffer from '@platform360/licenses/web/UpgradeWizard/UpgradeOffer';
import Confirm from '@platform360/licenses/web/WizardCommon/Confirm';
import { ConfirmPopoverButton } from '@platform360/licenses/web/WizardCommon/ConfirmPopoverButton';
import isCleverbridgePaymentExpired from '@platform360/licenses/web/helpers/cleverbridge/isCleverbridgePaymentExpired';

const getUpgradeImage = (offer, multiOffer) => {
    if (offer) {
        return offer.extensionCatalogCode;
    }
    if (multiOffer) {
        return multiOffer.extensionCatalogCode;
    }
    return 'default';
};

class Upgrade extends Component {
    state = {
        inConfirming: false,
    };

    handleConfirmProgress = () => {
        this.setState({
            inConfirming: true,
        });
    };

    handleOfferChange = (extension) => {
        const { onOfferChange } = this.props;
        onOfferChange(extension);
    };

    render() {
        const {
            mainKey,
            offer,
            multiOffer,
            priceCalculation,
            priceCalculationError,
            onOfferReset,
            onMultiOfferReset,
            onSubscriptionItemConfirm,
            preSelectedProductNamePrefix,
        } = this.props;

        const inProgress = {
            title: <Translate content="planUpdate" />,
            steps: [
                {
                    title: <Translate content="waitAMoment" />,
                    status: 'running',
                },
            ],
        };

        const confirmButton = (
            <ConfirmPopoverButton
                key="confirmPopoverButton"
                priceCalculation={priceCalculation}
                offer={offer}
                priceCalculationError={priceCalculationError}
                onConfirmProgress={this.handleConfirmProgress}
                onSubscriptionItemConfirm={onSubscriptionItemConfirm}
                disableUpgrade={isCleverbridgePaymentExpired(priceCalculation?.paymentInfo)}
            />
        );

        return (
            <Drawer
                isOpen
                size="sm"
                onClose={() => {
                    onOfferReset();
                    onMultiOfferReset();
                }}
                form={{
                    applyButton: false,
                    hideRequiredLegend: true,
                    submitButton: false,
                    onCancel: () => {
                        onOfferReset();
                        onMultiOfferReset();
                    },
                    cancelButton: {
                        'data-type': 'Upsell--upsellCancel',
                    },
                    additionalButtons: [confirmButton],
                }}
                progress={this.state.inConfirming ? inProgress : null}
                title={offer ? offer.productName : (multiOffer && multiOffer.productName) || ''}
                subtitle={<Translate content="upgradeMultiOfferSuggestion" />}
                data-type="upgrade"
            >
                {mainKey && (offer !== null || multiOffer !== null) ? (
                    <>
                        <Img code={getUpgradeImage(offer, multiOffer)} />
                        {(multiOffer || offer) && (
                            <UpgradeOffer
                                offer={offer}
                                multiOffer={multiOffer}
                                onOfferChange={this.handleOfferChange}
                                disableSelect={this.state.inConfirming}
                                preSelectedProductNamePrefix={preSelectedProductNamePrefix}
                            />
                        )}
                        {offer && priceCalculation && (
                            <Confirm
                                offer={offer}
                                billingPeriod={mainKey.billingPeriod}
                                priceCalculation={priceCalculation}
                                mainKey={mainKey}
                            />
                        )}
                        {priceCalculationError === 'pendingOperationFound' ? (
                            <div>
                                <StatusMessage intent="warning">
                                    {<Translate content="pendingOperationFound" />}
                                </StatusMessage>
                                {priceCalculation && priceCalculation.changePaymentUrl && (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={priceCalculation.changePaymentUrl}
                                    >
                                        <Translate content="editPaymentMethod" />
                                    </a>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    <ContentLoader />
                )}
            </Drawer>
        );
    }
}

Upgrade.propTypes = {
    preSelectedProductNamePrefix: PropTypes.string,
    mainKey: PropTypes.object,
    offer: PropTypes.object,
    multiOffer: PropTypes.object,
    priceCalculation: PropTypes.object,
    priceCalculationError: PropTypes.string,
    onOfferChange: PropTypes.func.isRequired,
    onOfferReset: PropTypes.func.isRequired,
    onMultiOfferReset: PropTypes.func.isRequired,
    onSubscriptionItemConfirm: PropTypes.func.isRequired,
};

Upgrade.defaultProps = {
    mainKey: null,
    offer: null,
    multiOffer: null,
    priceCalculation: null,
    priceCalculationError: null,
    preSelectedProductNamePrefix: null,
};

export default Upgrade;
