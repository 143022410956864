// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ReactNode } from 'react';

type SuccessMessageNotification = {
    content: string;
    params: Record<string, ReactNode>;
    onRefresh?: () => void;
};

const Message = ({ content, params, onRefresh = () => true }: SuccessMessageNotification) => {
    const translate = useTranslate();

    return (
        <div>
            {translate(content, params)}
            <div>
                <br />
                {translate('refreshSuggestion', {
                    a: (chunk) => <a onClick={() => onRefresh()}>{chunk}</a>,
                })}
            </div>
        </div>
    );
};

export default Message;
