// Copyright 2024. WebPros International GmbH. All rights reserved.
import { FormField, Input } from '@plesk/ui-library';
import { FocusEvent, ChangeEvent } from 'react';
type FormFieldNumberTypes = {
    name: string;
    label: string;
    min?: number;
    max?: number;
    size?: number | 'sm' | 'md' | 'lg' | 'xl' | 'fill';
    placeholder?: string;
};

const FormFieldNumber = ({ name, label, min, max, ...props }: FormFieldNumberTypes) => {
    const handleBlur =
        (setValue: (value?: number) => void) => (e: FocusEvent<HTMLInputElement>) => {
            const { target } = e;

            if (target.value === '') {
                setValue(undefined);
            }

            if (max !== undefined && Number(target.value) > max) {
                setValue(max);
            }

            if (min !== undefined && Number(target.value) < min) {
                setValue(min);
            }
        };

    return (
        <FormField name={name} label={label}>
            {({ getValue, setValue, isDisabled }) => (
                <Input
                    name={name}
                    type="number"
                    value={getValue()}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                    onBlur={handleBlur(setValue)}
                    disabled={isDisabled()}
                    {...props}
                />
            )}
        </FormField>
    );
};

export default FormFieldNumber;
