// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Panel, TranslationProps } from '@plesk/ui-library';
import styles from './VulnerabilityWidget.module.less';

type VulnerabilityWidgetProps = React.PropsWithChildren & {
    title: TranslationProps;
    dataType: 'plugin-widget' | 'update-widget' | 'patch-widget';
};

const VulnerabilityWidget = ({ title, children, dataType }: VulnerabilityWidgetProps) => (
    <div data-type={dataType}>
        <Panel className={styles.card}>
            <div className={styles.cardContainer}>
                <div className={styles.cardTitle}>{title}</div>
                {children}
            </div>
        </Panel>
    </div>
);

export default VulnerabilityWidget;
