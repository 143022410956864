// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, FormFieldCheckbox, Paragraph, ProgressBar, Text } from '@plesk/ui-library';
import VulnerabilityWidget from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets/VulnerabilityWidget/VulnerabilityWidget';
import styles from './UpdatesWidget.module.less';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useState } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    InstallationComponent,
    InstallationComponents,
} from '@platform360/security-dashboard/shared/installation-component';

export type UpdatesWidgetProps = {
    component: InstallationComponent;
    vulnerabilityId: string;
    installationsCount: number;
    hasUpdate: boolean;
    isUpdateStarting: boolean;
    updateInProgress: boolean;
    onUpdate: (enableAfterUpdate: boolean) => void;
    installationsWithPluginDisabledByMitigationCount: number;
};
const UpdatesWidget = ({
    component,
    vulnerabilityId,
    hasUpdate,
    onUpdate,
    isUpdateStarting,
    updateInProgress,
    installationsCount,
    installationsWithPluginDisabledByMitigationCount,
}: UpdatesWidgetProps) => {
    const [enableAfterUpdate, setEnableAfterUpdate] = useState(true);
    const translate = useTranslate('security-dashboard.Vulnerabilities.Widgets.updates');
    const translateConfirmation = useTranslate(
        'security-dashboard.Vulnerabilities.Toolbar.UpdateButton',
    );
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const getConfirmationText = () => {
        const confirmationText = translateConfirmation('updateConfirmation', {
            totalVulnerabilities: 1,
            count: installationsCount,
        });

        if (
            component !== InstallationComponents.plugin ||
            installationsWithPluginDisabledByMitigationCount === 0
        ) {
            return confirmationText;
        }

        const label = translateConfirmation('updateConfirmationPluginCheckbox');
        const hint = translateConfirmation('updateConfirmationPluginCheckboxHint');

        return (
            <>
                <Paragraph>
                    <FormFieldCheckbox
                        label={label}
                        value={enableAfterUpdate}
                        onChange={() => {
                            setEnableAfterUpdate(!enableAfterUpdate);
                        }}
                    />
                    {hint}
                </Paragraph>
                <Paragraph>{confirmationText}</Paragraph>
            </>
        );
    };

    const onConfirmationShown = () =>
        analyticsEvents.wpSglUpdateVulnerabilityClick(
            vulnerabilityId,
            installationsCount,
            enableAfterUpdate,
        );

    const getContent = () => {
        if (updateInProgress) {
            return <ProgressBar className={styles.progressBar} />;
        }
        return (
            <ConfirmationPopover
                target={
                    <Button fill disabled={isUpdateStarting}>
                        {translate('buttonName')}
                    </Button>
                }
                onClick={() => {
                    onUpdate(
                        installationsWithPluginDisabledByMitigationCount > 0 && enableAfterUpdate,
                    );
                    setEnableAfterUpdate(true);
                }}
                onShow={onConfirmationShown}
                actionButtonText={translate('buttonName')}
                confirmationButtonIntent="primary"
            >
                <Paragraph>{getConfirmationText()}</Paragraph>
            </ConfirmationPopover>
        );
    };

    const getSitesCountText = () => (
        <Text intent="muted" fontSize="sm">
            {translate('installations', { count: installationsCount })}
        </Text>
    );

    if (hasUpdate) {
        return (
            <VulnerabilityWidget dataType="update-widget" title={translate('title')}>
                <div className={styles.cardContent}>
                    {getSitesCountText()}
                    {getContent()}
                </div>
            </VulnerabilityWidget>
        );
    }

    return (
        <VulnerabilityWidget dataType="update-widget" title={translate('waitingForUpdatesTitle')}>
            {installationsCount > 0 && (
                <div className={styles.cardContent}>{getSitesCountText()}</div>
            )}
        </VulnerabilityWidget>
    );
};

export default UpdatesWidget;
