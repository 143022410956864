// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentProps } from 'react';
import { ListEmptyView as BaseListEmptyView } from '@plesk/ui-library';
import image from './image.svg';

type ListEmptyViewProps = ComponentProps<typeof BaseListEmptyView>;

const ListEmptyView = ({ ...props }: ListEmptyViewProps) => (
    <BaseListEmptyView image={image} {...props} />
);

export default ListEmptyView;
