// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getUsers } from '@platform360/admin-panel/web/api/users';
import { FilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';

export type UseUsersQueryVariables = PaginatedQuery & FilterQuery;
export type UseUsersQueryData = PromiseValue<ReturnType<typeof getUsers>>;

const useUsersQuery = createQuery<UseUsersQueryVariables, UseUsersQueryData>({
    queryName: 'admin-panel/useUsersQuery',
    fetcher: getUsers,
    useQuery,
});

export default useUsersQuery;
