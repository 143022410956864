// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useMemo } from 'react';
import { ContentLoader, Drawer, Heading, Section, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ConfirmPopoverButton from '@platform360/licenses/web/components/ConfirmPopoverButton';
import CountableForm from '@platform360/licenses/web/units/productsForms/countable';
import Confirm from '@platform360/licenses/web/UpgradePropertiesWizard/Confirm';
import {
    ConfirmSubscriptionInfo,
    Footer,
    PaymentInfo as CbPaymentInfo,
} from '@platform360/licenses/web/units/cleverbridge';
import { CleverBridgePaymentMethod } from '@platform360/licenses/web/components/PaymentMethod';
import { initialState, reducer } from '@platform360/licenses/web/units/adjust/reducer';
import {
    finalization as onFinish,
    onInit,
    setValue,
    submit,
} from '@platform360/licenses/web/units/adjust/actions';
import useThunkReducer from 'react-hook-thunk-reducer';
import { InitialState } from '@platform360/licenses/web/units/adjust/reducer/initialState';
import { AdjustActions } from '@platform360/licenses/web/units/adjust/reducer/reducer';
import { cancelAdjust } from '@platform360/licenses/web/actions';
import { useDispatch } from 'react-redux';
import {
    getDelayedChangesProducts,
    getInstantChangesProducts,
    getNotChangedProducts,
} from '@platform360/licenses/web/units/adjust/selectors/getChangedProductsByState';
import { Property } from '@platform360/licenses/web/api/ka/responseTypes/Property';
import Message from '@platform360/licenses/web/WizardCommon/Message';
import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';
import getProductsToSubmit from '@platform360/licenses/web/units/adjust/helpers/getProductsToSubmit';
import { DECREASE } from '@platform360/licenses/web/units/adjust/constants/productState';
import { AG } from '@platform360/licenses/web/units/cleverbridge/constants/merchantOfRecordType';
import isCleverbridgePaymentExpired from '@platform360/licenses/web/helpers/cleverbridge/isCleverbridgePaymentExpired';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

type AdjustFormProps = {
    licenseId?: number;
    subscriptionId?: string;
};

const AdjustForm = ({ licenseId, subscriptionId }: AdjustFormProps) => {
    const translate = useTranslate();
    const analyticsEvents = useLicensesAnalyticsEvents();
    const [state, dispatch] = useThunkReducer<InitialState, AdjustActions>(reducer, initialState);
    const { successToast, clearToasts } = useToaster();
    const {
        failedInitialization,
        propertiesOfLicense,
        propertiesForChange: properties,
        recalculation,
        submitting,
        submitted,
        calculatedSubscriptionState,
        isAdjustPending,
    } = state;

    const cardExpired = useMemo(
        () => isCleverbridgePaymentExpired(calculatedSubscriptionState?.paymentInfo),
        [calculatedSubscriptionState],
    );

    const licensingDispatcher = useDispatch();

    const isNeutralState = useMemo(
        () => getNotChangedProducts(state).length === properties.length,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state],
    );

    useEffect(() => {
        if (licenseId && subscriptionId) {
            void dispatch(onInit(licenseId, subscriptionId));
        }

        return () => dispatch(onFinish());
    }, [licenseId, subscriptionId, dispatch]);

    useEffect(() => {
        if (submitted || failedInitialization) {
            if (submitted && submitted === 'success') {
                getProductsToSubmit(state).forEach(({ description, productId, currentValue }) => {
                    const content =
                        state.newValues[Number(productId)]?.state === DECREASE
                            ? 'propertyUpgradedDecreaseMessage'
                            : 'propertyUpgradedIncreaseMessage';

                    successToast(
                        <Message
                            content={content}
                            params={{
                                keyName: propertiesOfLicense?.keyName,
                                to: currentValue,
                                name: description,
                            }}
                            onRefresh={() => {
                                licensingDispatcher(refreshKeyList());
                                clearToasts();
                            }}
                        />,
                        { autoCloseTimeout: 20000 },
                    );
                });
            }
            licensingDispatcher(cancelAdjust());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, failedInitialization, licensingDispatcher]);

    return (
        <Drawer
            isOpen={Boolean(licenseId)}
            size="sm"
            onClose={() => licensingDispatcher(cancelAdjust())}
            form={{
                applyButton: false,
                hideRequiredLegend: true,
                submitButton: false,
                additionalButtons: [
                    <ConfirmPopoverButton
                        key={'confirmPopoverButton_adjust'}
                        tooltip={isAdjustPending ? translate('adjustPending') : undefined}
                        onSubmit={() => {
                            analyticsEvents.licensesAdjustDialogConfirmClicked();
                            void dispatch(submit());
                        }}
                        disabled={cardExpired || recalculation || isNeutralState || isAdjustPending}
                    />,
                ],
            }}
            progress={
                submitting
                    ? {
                          title: translate('planUpdate'),
                          steps: [
                              {
                                  title: translate('waitAMoment'),
                                  status: 'running',
                              },
                          ],
                      }
                    : null
            }
            title={properties.length > 0 ? propertiesOfLicense?.productName : ''}
            subtitle={translate('upgradeMultiOfferSuggestion')}
            data-type="upgrade"
        >
            {properties.length > 0 && propertiesOfLicense ? (
                <CountableForm
                    period={propertiesOfLicense.billingPeriod}
                    currency={propertiesOfLicense.currency}
                    list={properties.map((property: Property) => ({
                        id: Number(property.productId),
                        description: property.description,
                        name: property.productName,
                        price: property.price,
                        value: Number(property.currentValue),
                        rangeValues: {
                            min: property.rangeValue.minimumNumber,
                            max: property.rangeValue.maximumNumber,
                        },
                    }))}
                    isAdjustPending={isAdjustPending}
                    onProductChange={(productId, value) => dispatch(setValue(productId, value))}
                />
            ) : (
                <ContentLoader />
            )}
            {properties.length > 0 && propertiesOfLicense && calculatedSubscriptionState ? (
                <>
                    {!isNeutralState && (
                        <Confirm
                            billingPeriod={propertiesOfLicense.billingPeriod}
                            calculatedSubscriptionState={calculatedSubscriptionState}
                            delayedChangesProducts={getDelayedChangesProducts(state)}
                            instantChangesProducts={getInstantChangesProducts(state)}
                        />
                    )}
                    <Section>
                        <Heading level={4}>{translate('Keys.headers.payment')}</Heading>
                        <CbPaymentInfo
                            merchantOfRecordType={
                                calculatedSubscriptionState.merchantOfRecordType || AG
                            }
                        />
                    </Section>
                    <Section>
                        <CleverBridgePaymentMethod
                            paymentInfo={calculatedSubscriptionState.paymentInfo}
                            changePaymentUrl={calculatedSubscriptionState.changePaymentUrl}
                        />
                    </Section>
                    <Section>
                        <Heading level={4}>
                            <Text intent="muted">
                                {translate('Keys.headers.confirmSubscription')}
                            </Text>
                        </Heading>
                        <ConfirmSubscriptionInfo />
                        <Footer
                            merchantOfRecordType={
                                calculatedSubscriptionState.merchantOfRecordType || AG
                            }
                        />
                    </Section>
                </>
            ) : (
                !isNeutralState && <ContentLoader />
            )}
        </Drawer>
    );
};

export default AdjustForm;
