// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Токен авторизации недействительный или отсутствует.',
        invitationNotExist: 'Приглашения не существует.',
        invitationExpired: 'Срок действия приглашения истек.',
        invitationConflict: 'Приглашенный пользователь уже зарегистрирован.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'Ваш пароль изменен.',
    },
    ChangePasswordForm: {
        resetTitle: 'Новый пароль',
        setTitle: 'Задать пароль',
        passwordLabel: 'Пароль:',
        submitButton: 'Подтвердить',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'Письмо сброса пароля отправлено',
        successSendResetText: 'Мы отправили письмо со ссылкой для сброса пароля на адрес {email}. Перейдите по этой ссылке, чтобы войти в Plesk 360 и установить новый пароль.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Срок действия ссылки истек',
        resetText: 'Срок действия ссылки для сброса пароля истек.',
        setText: 'Ссылка для установки пароля больше недействительна. В целях безопасности ссылка для настройки пароля действует в течение 4 часов. Если вы не успели воспользоваться ссылкой, чтобы создать пароль, повторно введите свой адрес электронной почты в поле ниже и установите пароль, воспользовавшись процедурой изменения пароля.',
        emailLabel: 'Электронная почта:',
        submitButton: 'Отправить',
        bottomText: 'Вспомнили пароль? Вы можете <a>войти сейчас</a>.',
    },
};