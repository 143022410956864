// Copyright 2024. WebPros International GmbH. All rights reserved.

import ViewProfile from './ViewProfile';
import { useCurrentUser } from '@platform360/libs/shared-web/user';

export const ViewProfileContainer = () => {
    const { isOwner } = useCurrentUser();

    return <ViewProfile isShowDpa={isOwner} />;
};

export default ViewProfileContainer;
