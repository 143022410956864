// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Navigate,
    Route,
    // eslint-disable-next-line no-restricted-imports
    Routes,
    useNavigate,
} from 'react-router-dom';
import KeysView from '@platform360/licenses/web/Keys/KeysView';
import LinkedEmails from '@platform360/licenses/web/LinkedEmails/LinkedEmails';
import { UpsellsPage } from '@platform360/licenses/web/units/extensions';
import { licensesUri } from '@platform360/licenses/web/helpers';
import RedirectToAdjustProperty from '@platform360/licenses/web/units/adjust/components/RedirectToAdjustProperty';
import ActivateLicense from '@platform360/licenses/web/components/ActivateLicense';
import Layout from '@platform360/licenses/web/components/Layout';
import { Provider } from 'react-redux';
import { store } from '@platform360/licenses/web/store/configureStore';
import { useEventListener } from 'usehooks-ts';
import { NAVIGATE_EVENT } from '@platform360/licenses/web/store/middleware/history';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';

/**
 * Licenses module router
 * @constructor
 */
const Router = () => (
    <Routes>
        <Route element={<PrivateRoutes />}>
            <Route path="activate-license" element={<ActivateLicense />} />
            <Route element={<Layout />}>
                <Route path="emails/*" element={<LinkedEmails />} />
                <Route path="licenses">
                    <Route path="*" index element={<KeysView />} />
                    <Route path=":licenseId/adjust/*" element={<RedirectToAdjustProperty />} />
                    <Route path=":licenseId/upsell" element={<UpsellsPage />} />
                </Route>
                <Route path="*" element={<Navigate to={licensesUri} replace />} />
            </Route>
        </Route>
    </Routes>
);

const App = () => {
    const navigate = useNavigate();

    useEventListener(
        NAVIGATE_EVENT,
        ({ detail: { url, replace } }: CustomEvent<{ url: string; replace: boolean }>) => {
            navigate(url, { replace });
        },
    );

    return (
        <Provider store={store}>
            <Router />
        </Provider>
    );
};

export default App;
