// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { IconName } from '@plesk/ui-library/types/src/components/Icon/Icon';
import styles from './HomePageWidget.module.css';
import { Item, Icon, Heading, Text, TranslationProps } from '@plesk/ui-library';

export type HomePageWidgetProps = {
    navigateTo: string;
    title: TranslationProps;
    description: TranslationProps;
    icon: IconName;
    isExternal?: boolean;
};

const HomePageWidget = ({
    title,
    description,
    icon,
    navigateTo,
    isExternal,
    ...props
}: HomePageWidgetProps) => (
    <Item
        view="card"
        component={isExternal ? 'a' : Link}
        // @ts-expect-error fix type in UI Library
        to={isExternal ? undefined : navigateTo}
        href={navigateTo}
        target={isExternal ? '_blank' : '_self'}
        {...props}
    >
        <div>
            <Icon className={styles.icon} name={icon} intent="inactive" size="48" />
            <Heading className={styles.title} level={4}>
                {title}
            </Heading>
            <Text intent="muted">{description}</Text>
        </div>
    </Item>
);

export default HomePageWidget;
