// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    UPDATE_INSTANCE_TASK_TYPE,
    UPDATE_ITEMS_TASK_TYPE,
    AGENT_SCAN_NEW_INSTALLATIONS_TASK_TYPE,
    REFRESH_INSTALLATIONS_TASK_TYPE,
    APPLY_VIRTUAL_PATCHES_TASK_TYPE,
    DISABLE_VIRTUAL_PATCHES_TASK_TYPE,
    DETACH_INSTALLATIONS_TASK_TYPE,
    MITIGATE_VULNERABILITIES_TASK_TYPE,
    UPDATE_SETTINGS_TASK_TYPE,
    SET_DAILY_TASK_START_TIME,
} from '@platform360/security-dashboard/shared/constants';
import { TaskType } from '@platform360/security-dashboard/shared/task-type';

const useTaskTypesTranslate = () => {
    const translate = useTranslate('security-dashboard.taskTypes');

    const translationsMap: Record<TaskType, TranslationProps> = {
        [UPDATE_ITEMS_TASK_TYPE]: translate('updateInstallation'),
        [UPDATE_INSTANCE_TASK_TYPE]: translate('massUpdateInstallations'),
        [AGENT_SCAN_NEW_INSTALLATIONS_TASK_TYPE]: translate('scanForNewSites'),
        [REFRESH_INSTALLATIONS_TASK_TYPE]: translate('refreshInstallations'),
        [DETACH_INSTALLATIONS_TASK_TYPE]: translate('detachInstallations'),
        [APPLY_VIRTUAL_PATCHES_TASK_TYPE]: translate('applyVirtualPatches'),
        [DISABLE_VIRTUAL_PATCHES_TASK_TYPE]: translate('disableVirtualPatches'),
        [MITIGATE_VULNERABILITIES_TASK_TYPE]: translate('mitigateVulnerabilities'),
        [UPDATE_SETTINGS_TASK_TYPE]: translate('saveUpdateSettings'),
        [SET_DAILY_TASK_START_TIME]: translate('setDailyTaskStartTime'),
    };

    return (taskType: TaskType) => translationsMap[taskType];
};

export default useTaskTypesTranslate;
