// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch, useReducer } from 'react';
import intersection from 'lodash/intersection';
import { Label } from '@platform360/security-dashboard/web/types';

export type AssignedStatus = 'no' | 'partially' | 'all';

export type LabelWithStatus = Label & {
    assigned: AssignedStatus;
};

export type AssignLabelAction = {
    type: AssignedStatus;
    label: LabelWithStatus;
};

export type SelectionWithLabels = {
    id: number;
    labels: number[];
}[];

type State = {
    labels: LabelWithStatus[];
    assignedOnEach: number[];
    assignedPartially: number[];
    toAssign: number[];
    toRemove: number[];
};

const updateAssignedLabels = (
    state: State,
    status: AssignedStatus,
    labelId: number,
): LabelWithStatus[] =>
    state.labels.map((label) => ({
        ...label,
        assigned: label.id === labelId ? status : label.assigned,
    }));

const reducer = (state: State, action: AssignLabelAction) => {
    const labelId = action.label.id;
    const isAssignedPartially = state.assignedPartially.includes(labelId);
    const isAssignedOnEach = state.assignedOnEach.includes(labelId);

    // through action.type passes the current status of the checkbox,
    // the next status calculates in the reducer
    switch (action.type) {
        case 'no': {
            if (isAssignedOnEach || isAssignedPartially) {
                const nextStatus = isAssignedOnEach ? 'all' : 'partially';

                return {
                    ...state,
                    toRemove: state.toRemove.filter((id) => id !== labelId),
                    labels: updateAssignedLabels(state, nextStatus, labelId),
                };
            }

            return {
                ...state,
                toAssign: [...state.toAssign, labelId],
                labels: updateAssignedLabels(state, 'all', labelId),
            };
        }
        case 'partially': {
            return {
                ...state,
                toAssign: [...state.toAssign, labelId],
                labels: updateAssignedLabels(state, 'all', labelId),
            };
        }
        case 'all': {
            if (isAssignedOnEach || isAssignedPartially) {
                return {
                    ...state,
                    toAssign: state.toAssign.filter((id) => id !== labelId),
                    toRemove: [...state.toRemove, labelId],
                    labels: updateAssignedLabels(state, 'no', labelId),
                };
            }

            return {
                ...state,
                toAssign: state.toAssign.filter((id) => id !== labelId),
                labels: updateAssignedLabels(state, 'no', labelId),
            };
        }
        default:
            return state;
    }
};

export const useLabelsReducer = (
    selection: SelectionWithLabels,
    labels: Label[],
): [State, Dispatch<AssignLabelAction>] => {
    const assignedLabelIds = selection.map((s) => s.labels);
    const assignedLabelIdsOnEach = intersection(...assignedLabelIds);
    const assignedLabelIdsPartially = assignedLabelIds
        .flat()
        .filter((id) => !assignedLabelIdsOnEach.includes(id));

    const labelsWithStatus: LabelWithStatus[] = labels.map<LabelWithStatus>((label) => {
        let assigned: AssignedStatus = 'no';
        if (assignedLabelIdsOnEach.includes(label.id)) {
            assigned = 'all';
        } else if (assignedLabelIdsPartially.includes(label.id)) {
            assigned = 'partially';
        }

        return { ...label, assigned };
    });

    const initState: State = {
        labels: labelsWithStatus,
        assignedOnEach: assignedLabelIdsOnEach,
        assignedPartially: assignedLabelIdsPartially,
        toAssign: [],
        toRemove: [],
    };

    return useReducer(reducer, initState);
};
