// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, ReactElement } from 'react';
import RemoveServersPopover from '@platform360/security-dashboard/web/components/Servers/RemoveServersButton';
import { Server } from '@platform360/security-dashboard/web/types';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import UpdateButton from '@platform360/security-dashboard/web/components/Servers/Toolbar/UpdateButton';
import ProtectButton from '@platform360/security-dashboard/web/components/Servers/Toolbar/ProtectButton';
import { useDisableProtectionDialog } from '@platform360/security-dashboard/web/components/Servers/DisableProtectionDialog/useDisableProtectionDialog';
import {
    useRunScannerMutation,
    useRefreshMutation,
    useServersSitesProtectMutation,
} from '@platform360/security-dashboard/web/mutations';
import {
    Label,
    ListActions as UILibListActions,
    ListAction,
    TranslationProps,
    Icon,
    Popover,
} from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useProtectionVisibility } from './useProtectionVisibility';
import {
    LabelsPopover,
    AssignRemoveLabelsOptions,
} from '@platform360/security-dashboard/web/components/LabelsPopover';
import { useLicenseIsActive } from '@platform360/security-dashboard/web/hooks/useLicenseIsActive';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import { ServerListOperation } from '@platform360/security-dashboard/web/components/Servers/constants';

import styles from './ListActions.module.css';

type ListActionsProps = {
    onServerRemoved: (removedServersCount: number) => void;
    server: Server;
    onTaskStarting: (serversIds: number[], operation: ServerListOperation) => void;
    onTaskStarted: (serversIds: number[], operation: ServerListOperation) => void;
    handleUnprocessableInstallations: (serversIds: number[]) => void;
    handleAssignServerLabels: (data: AssignRemoveLabelsOptions) => void;
    handleRemoveServerLabels: (data: AssignRemoveLabelsOptions) => void;
    vertical: boolean;
};

const ListActions = ({
    server,
    onServerRemoved,
    onTaskStarting,
    onTaskStarted,
    vertical,
    handleUnprocessableInstallations,
    handleAssignServerLabels,
    handleRemoveServerLabels,
}: ListActionsProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.Servers.ListActions');
    const { mutateAsync: runServersScanner } = useRunScannerMutation();
    const { mutateAsync: runVulnerabilitiesChecker } = useRefreshMutation();
    const { mutateAsync: runServersUnprotect } = useServersSitesProtectMutation({ enable: false });
    const licenseIsActive = useLicenseIsActive();

    const [showLabelsPopover, setShowLabelsPopover] = useState(false);
    const handleLabelsPopoverShown = () => {
        setShowLabelsPopover(true);
        analyticsEvents.wpLabelsPopoverShown('SERVER_TAB');
    };
    const handleLabelsPopoverClosed = () => {
        setShowLabelsPopover(false);
        analyticsEvents.wpLabelsPopoverClosed('SERVER_TAB');
    };

    const handleRunScanner = async () => {
        analyticsEvents.wpSglKbbFindSitesClick();
        onTaskStarting([server.id], 'findNewSites');
        try {
            await runServersScanner([server]);
        } finally {
            onTaskStarted([server.id], 'findNewSites');
        }
    };

    const handleProtectionDisable = async () => {
        analyticsEvents.wpSglKbbFindSitesClick();
        onTaskStarting([server.id], 'disableProtection');
        try {
            await runServersUnprotect([server]);
        } finally {
            onTaskStarted([server.id], 'disableProtection');
        }
    };

    const refresh = async () => {
        analyticsEvents.wpSglKbbFindVulnerabilitiesClick();
        onTaskStarting([server.id], 'refresh');
        try {
            await runVulnerabilitiesChecker([server]);
        } finally {
            onTaskStarted([server.id], 'refresh');
        }
    };

    const [disableProtectionDialog, openDisableProtectionDialog] = useDisableProtectionDialog({
        onSubmit: () => {
            void handleProtectionDisable();
            analyticsEvents.wpSglKbbDisablePatchConfirmationClick();
        },
    });

    const protectButtonProps = {
        onTaskStarting,
        onTaskStarted,
        selectedServers: [server],
        onConfirm: (
            serversIds: number[],
            ignoreDoNotProtect: boolean,
            doNotProtectCount: number,
        ) => {
            handleUnprocessableInstallations(serversIds);
            analyticsEvents.wpSglKbbPatchConfirmationClick({
                ignoreDoNotProtect,
                doNotProtectCount,
                protectionDisabledTextShown: doNotProtectCount !== 0,
            });
        },
        onConfirmationShown: () => analyticsEvents.wpSglKbbPatchClick(),
    };

    const { showProtect, showProtectedLabel, showDisableProtection } = useProtectionVisibility({
        server,
    });

    const haveLicenseRestrictions = licenseIsActive === undefined || !licenseIsActive;

    return (
        <div className={styles.container}>
            {disableProtectionDialog}
            {!vertical && showProtect && !haveLicenseRestrictions && (
                <ProtectButton
                    icon={{ name: 'shield', size: '16' }}
                    className={styles.patch}
                    intent={'primary'}
                    {...protectButtonProps}
                />
            )}
            {!vertical && showProtectedLabel && !haveLicenseRestrictions && (
                <Popover
                    target={
                        <Label size="lg" view="light" intent="success" className={styles.patch}>
                            <Icon name="shield" />
                        </Label>
                    }
                >
                    {translate('protectionEnabled', {
                        providerLogo: <Patchstack />,
                        whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                    })}
                </Popover>
            )}
            <LabelsPopover
                visible={showLabelsPopover}
                selectionWithLabels={[
                    {
                        id: server.id,
                        labels: server.labels.map((l) => l.id),
                    },
                ]}
                onAssign={handleAssignServerLabels}
                onRemove={handleRemoveServerLabels}
                onClose={handleLabelsPopoverClosed}
                placement="bottom-left"
            >
                <UILibListActions>
                    <ListAction primary icon="refresh" onClick={refresh}>
                        {translate('refresh')}
                    </ListAction>
                    <ListAction icon="arrow-up-circle">
                        {({ wrap }: { wrap: (text: TranslationProps) => ReactElement }) => (
                            <UpdateButton
                                selectedServers={[server]}
                                onTaskStarting={(serversIds: number[], operation: 'update') => {
                                    handleUnprocessableInstallations(serversIds);
                                    onTaskStarting(serversIds, operation);
                                    analyticsEvents.wpSglKbbUpdateConfirmationClick();
                                }}
                                onTaskStarted={onTaskStarted}
                                onConfirmationShown={() => analyticsEvents.wpSglKbbUpdateClick()}
                                wrap={wrap}
                            />
                        )}
                    </ListAction>

                    <ListAction onClick={handleRunScanner} icon="wordpress">
                        {translate('findNewSites')}
                    </ListAction>
                    {vertical && showProtect && !haveLicenseRestrictions && (
                        <ProtectButton
                            icon="shield"
                            component={ListAction}
                            {...protectButtonProps}
                        />
                    )}
                    {showDisableProtection && !haveLicenseRestrictions && (
                        <ListAction
                            onClick={() => {
                                analyticsEvents.wpSglKbbDisablePatchClick();
                                openDisableProtectionDialog();
                            }}
                            icon="shield"
                        >
                            {translate('disablePatch')}
                        </ListAction>
                    )}
                    {!haveLicenseRestrictions && (
                        <ListAction
                            component={DrawerLink}
                            to={`${server.id}/settings/updates`}
                            onClick={() => analyticsEvents.wpSrvKbbAutoupdateSettingsClick()}
                            icon="gear"
                        >
                            {translate('settings')}
                        </ListAction>
                    )}
                    <ListAction
                        data-type="manage-labels"
                        icon="pin"
                        onClick={handleLabelsPopoverShown}
                    >
                        {translate('manageLabels')}
                    </ListAction>

                    <ListAction icon="chain-broken">
                        {({ wrap }: { wrap: (text: TranslationProps) => ReactElement }) => (
                            <RemoveServersPopover
                                servers={[server]}
                                onSuccess={onServerRemoved}
                                target={wrap(translate('remove'))}
                                placement="left"
                                onConfirmationShown={() =>
                                    analyticsEvents.wpSglKbbDisconnectServerClick()
                                }
                                onConfirm={() =>
                                    analyticsEvents.wpSglKbbDisconnectServerConfirmationClick()
                                }
                            />
                        )}
                    </ListAction>
                </UILibListActions>
            </LabelsPopover>
        </div>
    );
};

export default ListActions;
