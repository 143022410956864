// Copyright 2024. WebPros International GmbH. All rights reserved.
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

export const useWebInstallerProduct = (): WebInstallerProduct => {
    const { application } = useConfig();

    switch (application) {
        case 'get-cpanel':
        case 'get-plesk':
            return application.split('-').pop() as WebInstallerProduct;
        default:
            throw Error(
                '`useWebInstallerProduct` hook can be used only in web-installer application.',
            );
    }
};
