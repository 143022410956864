// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, FormErrors, FormFieldText } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Label } from '@platform360/security-dashboard/web/types';
import { SEARCH_LABELS_PARAM } from '@platform360/security-dashboard/web/constants/searchParams';

export type FormValues = {
    title: string;
};

export type LabelFormProps = {
    label?: Label | null;
    state?: 'loading';
    onSubmit: (values: FormValues) => void;
    errors?: FormErrors;
};

const LabelForm = ({ label = null, onSubmit, state, ...props }: LabelFormProps) => {
    const translate = useTranslate('security-dashboard.LabelsDrawer.LabelForm');
    const [searchParams] = useSearchParams();
    const searchLabel = searchParams.get(SEARCH_LABELS_PARAM) || '';

    const [values] = useState<FormValues>(() => ({ title: label?.title || searchLabel }));

    return (
        <Form
            vertical
            values={values}
            submitButton={{ children: translate('save') }}
            cancelButton={{ children: translate('cancel') }}
            applyButton={false}
            onSubmit={({ title }) => {
                onSubmit({ title: title.trim() });
            }}
            state={state === 'loading' ? 'submit' : undefined}
            {...props}
        >
            <FormFieldText
                inputProps={{
                    maxLength: 30,
                    autoFocus: true,
                }}
                name="title"
                label={translate('fields.title.label')}
                size="fill"
                description={translate('fields.title.description')}
            />
        </Form>
    );
};

export default LabelForm;
