// Copyright 2024. WebPros International GmbH. All rights reserved.

import Loading from '@platform360/libs/shared-web/components/Loading';
import { Alert, Heading } from '@plesk/ui-library';
import Layout from '@platform360/libs/shared-web/components/Public';
import { useNavigate } from 'react-router-dom';
import { useConfirmNotificationsEmailMutation } from '@platform360/security-dashboard/web/mutations';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useRef } from 'react';

export const ConfirmNotificationsEmail = () => {
    const { successToast } = useToaster();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.ConfirmNotificationsEmail');
    const navigate = useNavigate();
    const didInitialise = useRef(false);
    const { mutate, isError } = useConfirmNotificationsEmailMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
            navigate('/security-dashboard/servers/notifications-settings');
        },
        onError: () => {
            analyticsEvents.wpNotificationSettingsChangeEmailConfirmationErrorShown();
        },
    });

    useEffectOnce(() => {
        // Avoid duplicate renders, especially in strict mode.
        if (didInitialise.current) {
            return;
        }

        didInitialise.current = true;

        mutate();
    });

    if (isError) {
        return (
            <Layout>
                <Heading level={4}>{translate('title')}</Heading>
                <Alert intent="danger">{translate('error')}</Alert>
            </Layout>
        );
    }

    return <Loading />;
};
