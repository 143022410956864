// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Button,
    ExtendedStatusMessage,
    ExtendedStatusMessageActions,
    ExtendedStatusMessageDescription,
    ExtendedStatusMessageSeparator,
    ExtendedStatusMessageTitle,
} from '@plesk/ui-library';
import PropTypes from 'prop-types';
import paymentDone from './payment-done.png';
import { Link } from 'react-router-dom';
import { licensesUri } from '@platform360/licenses/web/helpers';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const ReturnToPlesk = ({ returnUrl }) => {
    const translate = useTranslate();

    return (
        <ExtendedStatusMessage intent="success" image={<img src={paymentDone} alt={''} />}>
            <ExtendedStatusMessageTitle>
                {translate('upgradeDoneRedirectToPleskTitle')}
            </ExtendedStatusMessageTitle>
            <ExtendedStatusMessageDescription>
                {translate('upgradeDoneRedirectToPleskDescription')}
            </ExtendedStatusMessageDescription>
            <ExtendedStatusMessageActions>
                <Button intent="primary" component="a" href={returnUrl}>
                    {translate('upgradeDoneRedirectToPleskButton')}
                </Button>
            </ExtendedStatusMessageActions>
            <ExtendedStatusMessageSeparator />
            <ExtendedStatusMessageDescription>
                <Link to={licensesUri}>{translate('upgradeDoneRedirectToKeys')}</Link>
            </ExtendedStatusMessageDescription>
        </ExtendedStatusMessage>
    );
};

ReturnToPlesk.propTypes = {
    returnUrl: PropTypes.string.isRequired,
};

export default ReturnToPlesk;
