// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { inactiveReasonValues, InactiveReasons } = makeEnum(
    'InactiveReason',
    'unreachable',
    'unsupported',
);

/**
 * Disable reason codes.
 *
 * - 'unreachable' -- the server cannot be pinged for 14 days or more.
 * - 'unsupported' -- the server product version is behind five latest releases
 */
export type InactiveReason = UnionOf<typeof inactiveReasonValues>;
