// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './Loader.module.css';
import { Text, ContentLoader } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type LoaderProps = {
    hasError: boolean;
};

const Loader = ({ hasError }: LoaderProps) => {
    const translate = useTranslate('monitoring.HomePageWidget.DynamicWidget.WidgetContent.Loader');

    return (
        <div className={styles.root}>
            <div className={styles.loader}>
                <ContentLoader text={translate('loading')} />
                {hasError && (
                    <Text intent="muted" fontSize="sm">
                        {translate('notRespondingText1')}
                        <br />
                        {translate('notRespondingText2')}
                    </Text>
                )}
            </div>
        </div>
    );
};

export default Loader;
