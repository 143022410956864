// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const ConfirmSubscriptionInfo = () => {
    const translate = useTranslate('Keys.CB');

    return (
        <Paragraph>
            <Text intent="muted">{translate('confirmSubscription')}</Text>
        </Paragraph>
    );
};

export default ConfirmSubscriptionInfo;
