// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Button, Icon, Label, Paragraph, Popover, ProgressBar, Text } from '@plesk/ui-library';
import VulnerabilityWidget from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets/VulnerabilityWidget';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { VulnerabilityInstallationOperations } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useCountOfAffectedSitesByVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { IgnoreDoNotProtectMessage } from '@platform360/security-dashboard/web/components/IgnoreDoNotProtectMessage';
import styles from './PatchesWidget.module.less';

export type PatchesWidgetProps = {
    vulnerabilityId: string;
    installationsWithMitigationPatchAvailable: number;
    protectedInstallationsCount: number;
    handlePatch: (options: {
        affectedSitesCount: number;
        ignoreDoNotProtect: boolean;
        doNotProtectCount: number;
        protectionDisabledTextShown: boolean;
    }) => void;
    patchInProgress: boolean;
    isPatchStarting: boolean;
};

const PatchesWidget = ({
    vulnerabilityId,
    installationsWithMitigationPatchAvailable,
    protectedInstallationsCount,
    handlePatch,
    patchInProgress,
    isPatchStarting,
}: PatchesWidgetProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Widgets.patches');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const [ignoreDoNotProtect, setIgnoreDoNotProtect] = useState(false);
    const {
        data = { doNotProtect: 0 },
        refetch,
        isLoading,
    } = useCountOfAffectedSitesByVulnerabilitiesQuery({
        variables: {
            vulnerabilitiesIds: [vulnerabilityId],
            operation: VulnerabilityInstallationOperations.patch,
        },
        enabled: false,
    });

    const canPatch =
        installationsWithMitigationPatchAvailable > 0 &&
        protectedInstallationsCount !== installationsWithMitigationPatchAvailable;
    const isAllProtected =
        installationsWithMitigationPatchAvailable > 0 &&
        protectedInstallationsCount === installationsWithMitigationPatchAvailable;
    const titleKey = isAllProtected ? 'titleAllProtected' : 'title';

    const renderIgnoreDoNotProtectMessage = () => {
        if (data.doNotProtect === 0) return null;
        return (
            <IgnoreDoNotProtectMessage
                doNotProtectCount={data.doNotProtect}
                ignoreDoNotProtect={ignoreDoNotProtect}
                onIgnoreDoNotProtectChange={setIgnoreDoNotProtect}
                installationsLinkQueryParams={{ search: encodeURIComponent(vulnerabilityId) }}
            />
        );
    };

    const getAllProtectedLabel = () => {
        if (!isAllProtected) {
            return null;
        }

        const target = (
            <Label size="lg" view="light" intent="success" className={styles.labelContainer}>
                <span className={styles.labelContainer}>
                    <Icon name={'shield-filled'} />
                    &nbsp;
                    <Text>{translate('allProtected', { count: protectedInstallationsCount })}</Text>
                </span>
            </Label>
        );

        return (
            <Popover target={target} placement={'top'}>
                {translate('allProtectedPopover', {
                    providerLogo: <Patchstack />,
                    whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                })}
            </Popover>
        );
    };

    const getActionText = () => {
        const target = (
            <Button fill disabled={isPatchStarting} intent="primary">
                {translate('protectAllSites')}
            </Button>
        );

        const affectedSitesCount =
            installationsWithMitigationPatchAvailable - protectedInstallationsCount;

        return isPatchStarting ? (
            target
        ) : (
            <ConfirmationPopover
                onClick={() =>
                    handlePatch({
                        affectedSitesCount,
                        ignoreDoNotProtect,
                        doNotProtectCount: data.doNotProtect,
                        protectionDisabledTextShown: data.doNotProtect !== 0,
                    })
                }
                target={target}
                onShow={() => {
                    setIgnoreDoNotProtect(false);
                    void refetch();
                    analyticsEvents.wpSglProtectVulnerabilityClick(
                        vulnerabilityId,
                        affectedSitesCount,
                    );
                }}
                actionButtonText={translate('enableButton')}
                cancelButtonText={translate('cancelButton')}
                actionButtonState={isLoading ? 'loading' : undefined}
                confirmationButtonIntent={'primary'}
            >
                <Paragraph>
                    {translate('protectAllSitesConfirmation', {
                        count: affectedSitesCount,
                        providerLogo: <Patchstack />,
                        whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                    })}
                </Paragraph>
                {renderIgnoreDoNotProtectMessage()}
            </ConfirmationPopover>
        );
    };

    const getContent = () => {
        if (!canPatch) {
            return null;
        }

        if (patchInProgress) {
            return <ProgressBar intent="success" className={styles.enabledProgressBar} />;
        }

        const percent =
            (protectedInstallationsCount / installationsWithMitigationPatchAvailable) * 100;

        return (
            <>
                <Text intent="muted" fontSize="sm">
                    {translate('installations', {
                        count: protectedInstallationsCount,
                        total: installationsWithMitigationPatchAvailable,
                    })}
                </Text>
                <ProgressBar
                    intent="success"
                    progress={percent}
                    className={styles.enabledProgressBar}
                />
                {getActionText()}
            </>
        );
    };
    return (
        <VulnerabilityWidget dataType="patch-widget" title={translate(titleKey)}>
            {getAllProtectedLabel()}
            {getContent()}
        </VulnerabilityWidget>
    );
};

export default PatchesWidget;
