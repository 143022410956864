// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';

export class MonitoringAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    public setMonitoringPartnerCustomer(value: boolean) {
        this.client.setUserProperties({ monitoringPartnerCustomer: value });
    }

    /* Monitoring Activation */

    public monitoringActivationPageOpen(monitoringActive: boolean) {
        this.client.logEvent('Monitoring activation page open', { monitoringActive });
    }

    public monitoringActivationActivateButtonClicked() {
        this.client.logEvent('Monitoring activation activate button clicked');
    }

    /* Monitoring License Activation */

    public monitoringLicenseActivationLinkVisit(product: string) {
        this.client.logEvent('Monitoring License Activation Link visit', { product });
    }

    public monitoringLicenseActivationDialogShown(product: string) {
        this.client.logEvent('Monitoring License Activation Dialog shown', { product });
    }

    public monitoringLicenseActivationConfirmed(product: string) {
        this.client.logEvent('Monitoring License Activation confirmed', { product });
    }

    public monitoringLicenseActivationCancelled(product: string) {
        this.client.logEvent('Monitoring License Activation cancelled', { product });
    }

    public monitoringLicenseActivationSuccessful(product: string) {
        this.client.logEvent('Monitoring License Activation successful', { product });
    }

    public monitoringLicenseActivationFailed(product: string) {
        this.client.logEvent('Monitoring License Activation failed', { product });
    }
}
