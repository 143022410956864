// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import { ContentLoader } from '@plesk/ui-library';
import { useAuth } from '@platform360/libs/shared-web/auth';

const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        logout();
    }, [logout]);

    return <ContentLoader />;
};

export default Logout;
