// Copyright 2024. WebPros International GmbH. All rights reserved.

import hostedImage from './hosted.svg';
import loginImage from './login-to-installation.svg';
import loginNoCredentials from './login-no-credentionals.svg';
import monitorImage from './monitor-servers.svg';
import styles from './InfoSection.module.css';

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

type InfoSectionProps = {
    productType: WebInstallerProduct;
    displayName: string;
};

const InfoSection = ({ productType, displayName }: InfoSectionProps) => {
    const translate = useTranslate('web-installer.EmailConfirmationPage');

    return (
        <div className={styles.infoSectionContainer}>
            {productType === 'plesk' && (
                <div className={styles.infoSectionContainerSection}>
                    <img
                        alt=""
                        src={monitorImage}
                        className={styles.infoSectionContainerSectionImage}
                    />
                    <div className={styles.infoSectionContainerSectionText}>
                        {translate('serversMonitoring')}
                    </div>
                </div>
            )}
            {productType === 'cpanel' && (
                <div className={styles.infoSectionContainerSection}>
                    <img
                        alt=""
                        src={loginNoCredentials}
                        className={styles.infoSectionContainerSectionImage}
                    />
                    <div className={styles.infoSectionContainerSectionText}>
                        {translate('loginNoCredentials')}
                    </div>
                </div>
            )}
            <div className={styles.infoSectionContainerSection}>
                <img alt="" src={loginImage} className={styles.infoSectionContainerSectionImage} />
                <div className={styles.infoSectionContainerSectionText}>
                    {translate('login', { productType: displayName })}
                </div>
            </div>
            <div className={styles.infoSectionContainerSection}>
                <img alt="" src={hostedImage} className={styles.infoSectionContainerSectionImage} />
                <div className={styles.infoSectionContainerSectionText}>{translate('hosted')}</div>
            </div>
        </div>
    );
};

export default InfoSection;
