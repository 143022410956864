// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, MenuItem, Text, MenuDivider } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useMediaQuery } from 'usehooks-ts';
import { useUserMenuModals } from './useUserMenuModals';
import GhostButton from '@platform360/libs/shared-web/components/GhostButton';
import IconGravatar from './IconGravatar';
import styles from './UserMenu.module.css';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export const DATA_TYPE_USER_MENU = 'user-menu';

type UserMenuProps = {
    showBusinessProfile?: boolean;
};

export const UserMenu = ({ showBusinessProfile = true }: UserMenuProps) => {
    const {
        header: { businessProfileEnabled, apiTokensEnabled, userMenuAddons },
    } = useApplicationSettings();
    const isHideText = useMediaQuery('(max-width: 767px)');
    const currentUser = useCurrentUser();
    const { isAuthorized } = useAuth();
    const getModal = useUserMenuModals();
    const showTeamItem = currentUser.isOwner;
    const translate = useTranslate('Page.UserMenu');
    const [viewProfileUrl, ViewProfile] = getModal('profile');
    const [changePasswordUrl, ChangePassword] = getModal('change-password');
    const [businessProfileUrl, BusinessProfile] = getModal('business-profile');
    const [apiTokensUrl, ApiTokens] = getModal('api-tokens');
    const isBusinessProfileShown =
        businessProfileEnabled && showBusinessProfile && currentUser.isOwner;

    if (!isAuthorized) {
        return null;
    }

    return (
        <Fragment>
            <Dropdown
                menu={
                    <Menu>
                        {isBusinessProfileShown && (
                            <MenuItem
                                component={Link}
                                to={businessProfileUrl}
                                data-type="business-profile"
                            >
                                {translate('businessProfile')}
                            </MenuItem>
                        )}
                        <MenuItem component={Link} to={viewProfileUrl} data-type="view-profile">
                            {translate('viewProfile')}
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to={changePasswordUrl}
                            data-type="change-password"
                        >
                            {translate('changePassword')}
                        </MenuItem>
                        {apiTokensEnabled && (
                            <MenuItem component={Link} to={apiTokensUrl} data-type="api-tokens">
                                {translate('apiTokens')}
                            </MenuItem>
                        )}
                        {userMenuAddons}
                        {showTeamItem ? (
                            <>
                                <MenuDivider />
                                <MenuItem component={Link} to="/organization">
                                    {translate('team')}
                                </MenuItem>
                            </>
                        ) : undefined}
                        <MenuDivider />
                        <MenuItem
                            component={Link}
                            to="/auth/logout"
                            icon="cross-mark"
                            data-type="logout"
                        >
                            {translate('logout')}
                        </MenuItem>
                    </Menu>
                }
                menuPlacement="bottom-end"
                data-type={DATA_TYPE_USER_MENU}
            >
                <GhostButton caret>
                    <IconGravatar userProfile={currentUser} />
                    {!isHideText && (
                        <Text truncate className={styles.userName} data-type="Page--userName">
                            {currentUser.name}
                        </Text>
                    )}
                </GhostButton>
            </Dropdown>
            <ViewProfile />
            <ChangePassword />
            {apiTokensEnabled && <ApiTokens />}
            {isBusinessProfileShown && <BusinessProfile />}
        </Fragment>
    );
};

export default UserMenu;
