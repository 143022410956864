// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    GetSecurityDashboardLicensesRequestDto,
    GetSecurityDashboardLicensesResponse,
    UpdateSecurityDashboardLicenseRequest,
} from '@platform360/admin-panel/shared/api-contract';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';

type GetLicensesOptions = FetcherOptions<GetSecurityDashboardLicensesRequestDto>;

export const getLicenses = async ({
    variables,
    signal,
}: GetLicensesOptions): Promise<GetSecurityDashboardLicensesResponse> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<GetSecurityDashboardLicensesResponse>>(
        '/admin-panel/security-dashboard/get-licenses',
        variables,
        {
            signal,
        },
    );

    return data;
};

type UpdateLicenseOptions = {
    licenseId: number;
    data: UpdateSecurityDashboardLicenseRequest;
};

export const updateLicense = async ({
    licenseId,
    data: updatedData,
}: UpdateLicenseOptions): Promise<void> => {
    await apiClient.patch(`/admin-panel/security-dashboard/licenses/${licenseId}`, updatedData);
};
