// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment } from 'react';

const useInstallationSnippet = (installerUrl: string, repoUrl: string, token: string) => {
    const installerOptions = repoUrl
        ? ` --url ${repoUrl} --platform-url '${window.location.origin}'`
        : '';
    const agentOptions = repoUrl ? ` -base-api-url '${window.location.origin}'` : '';
    const snippet = `bash <(curl '${installerUrl}' || wget -O - '${installerUrl}') --token '${token}'${installerOptions} && wp-agent --connect-server${agentOptions}`;

    const tokenArgumentName = ' -token ';
    const tokenArgument = `'${token}'`;

    return {
        snippet: `${snippet}${tokenArgumentName}${tokenArgument}`,
        formattedSnippet: (
            <>
                {/* Break urls by '/' with <wbr> */}
                {snippet.split(/\//).map((part, index, array) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {index > 0 && (
                            <>
                                {'/'}
                                {part !== '' && array[index - 1] !== '' && <wbr />}
                            </>
                        )}
                        {part}
                    </Fragment>
                ))}
                {tokenArgumentName}
                <span style={{ wordBreak: 'break-all' }}>{tokenArgument}</span>
            </>
        ),
    };
};

export default useInstallationSnippet;
