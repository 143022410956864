// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import { UserMenuModalsContext } from './UserMenuModalsProvider';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';

export const useUserMenuModals = () => {
    const modals = useContext(UserMenuModalsContext);
    const getModalUrl = useGetModalUrl();

    return (path: string) => {
        const modal = modals[path];

        if (!modal) {
            throw new Error(`Modal [${path}] not found in registry`);
        }

        return [getModalUrl(path), modal] as const;
    };
};
