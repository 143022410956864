// Copyright 2024. WebPros International GmbH. All rights reserved.

import { concatUrl } from '@platform360/libs/common/concat-url';

export const AGREEMENT_TYPE_DPA = 'data_processing_agreement';
export const AGREEMENT_TYPE_MARKETING = 'send_platform360_announce';

type AgreementWithTranslationKeys = {
    type: string;
    titleLocale: string;
    textLocale: string;
};

const agreements: AgreementWithTranslationKeys[] = [
    {
        type: AGREEMENT_TYPE_DPA,
        titleLocale: `${AGREEMENT_TYPE_DPA}.title`,
        textLocale: `${AGREEMENT_TYPE_DPA}.text`,
    },
];

export const getDataProcessingAgreementText = (origin: string): string =>
    concatUrl('/legal/data-processing-agreement', origin);

export default agreements;
