// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { FormFieldCheckbox, Paragraph } from '@plesk/ui-library';
import React, { ReactElement } from 'react';
import { InstallationVulnerability } from '@platform360/security-dashboard/web/types';
import { InstallationComponents } from '@platform360/security-dashboard/shared/installation-component';

type UpdateConfirmationProps = {
    onClick: () => void;
    onShow?: () => void;
    siteDisplayTitle: string;
    target: ReactElement;
    selectedItems: string[];
    enableAfterUpdate: boolean;
    setEnableAfterUpdate: (b: boolean) => void;
    data: InstallationVulnerability[];
};

const UpdateConfirmation = ({
    onClick,
    onShow,
    siteDisplayTitle,
    target,
    selectedItems,
    enableAfterUpdate,
    setEnableAfterUpdate,
    data,
}: UpdateConfirmationProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.WebsiteDrawer.tabs.vulnerabilities.updateConfirmation',
    );

    const getUpdateConfirmationText = () => {
        const countDeactivatedPluginsSelected = data.filter(
            (item) =>
                item.component === InstallationComponents.plugin &&
                selectedItems.includes(item.vulnerabilityId) &&
                item.deactivationReason === 'mitigation',
        ).length;

        const countDeactivatedPluginsInList = data.filter(
            (item) =>
                item.component === InstallationComponents.plugin &&
                item.deactivationReason === 'mitigation',
        ).length;

        const rowsWithUpdateAvailable = data.filter(
            (item) => item.mitigationUpdateAvailable,
        ).length;

        const confirmationText = translate('description', {
            count: selectedItems.length || rowsWithUpdateAvailable,
            website: siteDisplayTitle,
        });

        if (
            countDeactivatedPluginsSelected > 0 ||
            (selectedItems.length === 0 && countDeactivatedPluginsInList > 0)
        ) {
            const countDeactivatedPlugins =
                selectedItems.length === 0
                    ? countDeactivatedPluginsInList
                    : countDeactivatedPluginsSelected;

            const label = translate('updateConfirmationPluginCheckbox', {
                count: countDeactivatedPlugins,
            });
            const hint = translate('updateConfirmationPluginCheckboxHint', {
                count: countDeactivatedPlugins,
            });

            return (
                <>
                    <Paragraph>
                        <FormFieldCheckbox
                            label={label}
                            value={enableAfterUpdate}
                            onChange={() => {
                                setEnableAfterUpdate(!enableAfterUpdate);
                            }}
                        />
                        {hint}
                    </Paragraph>
                    <Paragraph>{confirmationText}</Paragraph>
                </>
            );
        }

        return confirmationText;
    };

    return (
        <ConfirmationPopover
            onShow={onShow}
            onClick={onClick}
            target={target}
            actionButtonText={translate('updateButton')}
            cancelButtonText={translate('cancelButton')}
            confirmationButtonIntent={'primary'}
        >
            <Paragraph>{getUpdateConfirmationText()}</Paragraph>
        </ConfirmationPopover>
    );
};

export default UpdateConfirmation;
