// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

export type GaOptOutLinkProps = {
    children: ReactNode;
};

export const GaOptOutLink = ({ children }: GaOptOutLinkProps) => {
    const translate = useTranslate('GaOptOutLink');
    const { successToast } = useToaster();
    const { google } = useConfig();

    const gaOptout = () => {
        const disableStr = `ga-disable-${google.analyticsId || ''}`;
        document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)[disableStr] = true;

        successToast(translate('gaSuccess'));
    };

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a onClick={gaOptout}>{children}</a>;
};

export default GaOptOutLink;
