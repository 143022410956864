// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Heading, Label } from '@plesk/ui-library';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import styles from './HomePage.module.css';

export const HomePage = () => {
    const translate = useTranslate('admin-panel.HomePage');
    const { name, permissions = [] } = useCurrentUser();

    return (
        <div>
            <Heading>{translate('welcomeMessage', { name })}</Heading>
            <Heading level={5}>{translate('availablePermissionsDescription')}</Heading>
            {permissions.map((permission) => (
                <div key={permission} className={styles.label}>
                    <Label view="outline" intent="info" key={permission}>
                        {permission}
                    </Label>
                </div>
            ))}
        </div>
    );
};
