// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FINALIZATION } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type FinalizationAction = {
    type: typeof FINALIZATION;
};

const finalization = (): FinalizationAction => ({
    type: FINALIZATION,
});

export default finalization;
