// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Rechtliches',
        eula: 'EULA',
        pleskEula: 'Plesk EULA',
        cPanelEula: 'cPanel EULA',
        terms: 'Nutzungsbedingungen',
        privacyPolicy: 'Datenschutzrichtlinie',
        dpa: 'Vereinbarung zur Datenverarbeitung',
        optOuts: 'Zustimmung aufheben',
        cookies: 'Erklärung zu Cookies',
        gaHeader: 'Zustimmung zur Verwendung von Google Analytics aufheben',
        gaOptOut: 'Hier klicken, um die Zustimmung zur Verwendung von Google Analytics aufzuheben',
        cookieHeader: 'Einwilligung zur Verwendung von Cookies anpassen',
        cookieModify: 'Einwilligung anpassen',
        cookieWithdraw: 'Einwilligung aufheben',
        cookieSuccess: 'Die Einwilligung wurde zurückgenommen',
    },
    RedirectGateway: {
        title: 'Sie werden weitergeleitet an {destination}',
        progress: 'Dies kann einige Sekunden dauern',
        genericError: 'Ein Fehler ist aufgetreten',
        serverDetails: {
            destinationTitle: 'die Serverseite im Serverinventar',
            errorTitle: 'Fehler beim Öffnen der Serverseite',
            errorDescription: 'Der Server ist nicht im Serverinventar vorhanden',
            redirectDirectly: 'Weiter zum Serverinventar',
        },
        monitoringServerSetup: {
            destinationTitle: 'die Konfigurationsseite',
            errorTitle: 'Fehler beim Öffnen der Bestätigungsseite des Server-Monitorings',
            errorDescription: 'Der Server ist nicht im Serverinventar vorhanden',
        },
    },
};