// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AxiosRequestConfig } from 'axios';

/**
 * @param licenseId
 * @Response CalculatedSubscriptionState
 */
const get = (licenseId: number): AxiosRequestConfig => ({
    url: `/ka/keys/${licenseId}/resume`,
});

export default get;
