// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import LegalInfoLink from '../LegalInfoLink';
import scope from '../../constants/scope';

const { POLICY, REVOCATION, TERMS } = scope;

const BuyInfo = () => {
    const translate = useTranslate('Keys.CB');

    return translate('buyInfo', {
        termsLink: (chunk) => <LegalInfoLink scope={TERMS}>{chunk}</LegalInfoLink>,
        policyLink: (chunk) => <LegalInfoLink scope={POLICY}>{chunk}</LegalInfoLink>,
        revocationLink: (chunk) => <LegalInfoLink scope={REVOCATION}>{chunk}</LegalInfoLink>,
    });
};

export default BuyInfo;
