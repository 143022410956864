// Copyright 2024. WebPros International GmbH. All rights reserved.

import { UpdateUserRequest, UsersResponse } from '@platform360/admin-panel/shared/api-contract';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions, FilterQuery } from '@platform360/libs/shared-web/typings/api';

type GetUsersOptions = FetcherOptions<FilterQuery>;

export const getUsers = async ({ variables, signal }: GetUsersOptions): Promise<UsersResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<UsersResponse>>('/admin-panel/users', {
        params: variables,
        signal,
    });

    return data;
};

type UpdateUserOptions = {
    userId: number;
    data: UpdateUserRequest;
};

export const updateUser = async ({
    userId,
    data: updatedData,
}: UpdateUserOptions): Promise<void> => {
    await apiClient.patch(`/admin-panel/users/${userId}`, updatedData);
};

export const deleteUser = async (userId: number): Promise<void> => {
    await apiClient.delete(`/admin-panel/users/${userId}`);
};

export const impersonate = async (userId: number): Promise<void> => {
    await apiClient.post(`/admin-panel/users/${userId}/impersonate`);
};
