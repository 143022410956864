// Copyright 2024. WebPros International GmbH. All rights reserved.

import { RouteObject } from 'react-router-dom';
import HomePage from './components/HomePage';

export const routes: RouteObject[] = [
    {
        index: true,
        element: <HomePage />,
    },
];
