// Copyright 2024. WebPros International GmbH. All rights reserved.

import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import extensionSelector from '@platform360/licenses/web/units/extensions/selectors/extension';
import filterSubscriptionsForBuy from '@platform360/licenses/web/units/extensions/helpers/filterSubscriptionsForBuy';
import { InitialState } from '@platform360/licenses/web/units/extensions/reducer/buyExtension/initialState';
import initialized from '@platform360/licenses/web/units/extensions/actions/buyExtension/initialized';
import calculate from '@platform360/licenses/web/units/extensions/actions/buyExtension/calculate';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '@platform360/licenses/web/store/types';
import { Action } from 'redux';
import { PAYGR_EXTENSION } from '@platform360/licenses/web/api/ka/responseTypes/Upsells';

const initialization =
    (): ThunkAction<void, StoreState, unknown, Action<string>> =>
    (dispatch, getState): void => {
        const state = getState();
        const {
            period: mainPeriod,
            currency: mainCurrency,
            subscriptions,
            selectedItemPath,
            subscriptionByDefault,
        } = upsellSelector(state);

        const { period = mainPeriod, currency = mainCurrency } = buyExtensionSelector(state);

        const { upsells: products, ...extension } = extensionSelector(
            period,
            currency,
            selectedItemPath,
        )(state);
        const isPaygr = products.some((product) => product.type === PAYGR_EXTENSION);
        const inCondition = subscriptions.filter(filterSubscriptionsForBuy(period));
        const hasSubscriptions = Boolean(Array.from(inCondition).length);
        let selectedSubscription = undefined;

        if (hasSubscriptions) {
            if (
                inCondition.some(
                    (subscription) => subscription.subscriptionId === subscriptionByDefault,
                )
            ) {
                selectedSubscription = subscriptionByDefault;
            } else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                selectedSubscription = inCondition[0]!.subscriptionId;
            }
        }

        const payload = {
            isPaygr,
            currency,
            period,

            subscriptions: inCondition,
            hasSubscriptions,

            selectedSubscription,
            selectedProduct:
                hasSubscriptions && Array.from(products).length === 1
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      products[0]!.productId
                    : false,

            selectedPaygrProducts: isPaygr
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  products[0]!.properties?.reduce((curr, next) => {
                      // @ts-expect-error TS7053
                      curr[next.productId] = next.rangeValue.minimumNumber || 1;

                      return curr;
                  }, {})
                : {},

            extension,
            products,
        } as InitialState;

        dispatch(initialized(payload));

        if (payload.selectedProduct) {
            dispatch(calculate());
        }
    };

export default initialization;
