// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import {
    GenerateTechDomainRequest,
    GenerateTechDomainResponse,
} from '@platform360/web-installer/shared/api-contract';

type GenHostnameOptions = FetcherOptions<GenerateTechDomainRequest>;
export const genHostname = async ({ variables: { ip, vendor }, signal }: GenHostnameOptions) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<GenerateTechDomainResponse>>(
        `/web-installer/tech-domain/gen-hostname`,
        {
            ip,
            vendor,
        },
        {
            signal,
        },
    );
    return data;
};
