// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ContentLoader } from '@plesk/ui-library';
import styles from './Loading.module.css';

const Loading = () => (
    <div className={styles.root}>
        <ContentLoader />
    </div>
);

export default Loading;
