// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postConsents } from '@platform360/accounts/web/api/consents';
import { useGetConsentsQuery } from '@platform360/accounts/web/queries';

type UsePostConsentsMutationParams = {
    onSuccess: () => void;
    onError?: () => void;
    onMutate?: () => void;
};

export const usePostConsentsMutation = (options?: UsePostConsentsMutationParams) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: postConsents,
        onSuccess: (data) => {
            useGetConsentsQuery.setQueryData(
                queryClient,
                useGetConsentsQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return undefined;
                    }

                    const newCache = cache.filter(
                        (cached) => !data.find((updated) => cached.type === updated.type),
                    );

                    return [...newCache, ...data.map(({ type, enabled }) => ({ type, enabled }))];
                },
            );

            options?.onSuccess();
        },
        onError: options?.onError,
        onMutate: options?.onMutate,
    });
};
