// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import GaOptOutLink from '@platform360/libs/shared-web/components/GaOptOutLink/GaOptOutLink';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export const OptOuts = () => {
    const translate = useTranslate('app.Legal');
    const { successToast } = useToaster();
    const { google } = useConfig();
    const {
        cookieManager: { cookieModify, cookieWithdraw },
    } = useApplicationSettings();

    const handleCookieWithdraw = async () => {
        await cookieWithdraw();
        successToast(translate('cookieSuccess'));
    };

    return (
        <section>
            {google.analyticsId ? (
                <>
                    <Heading level={2}>{translate('gaHeader')}</Heading>
                    <ul>
                        <li>
                            <GaOptOutLink>{translate('gaOptOut')}</GaOptOutLink>
                        </li>
                    </ul>
                </>
            ) : undefined}
            <Heading level={2}>{translate('cookieHeader')}</Heading>
            <ul>
                <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={cookieModify}>{translate('cookieModify')}</a>
                </li>
                <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={handleCookieWithdraw}>{translate('cookieWithdraw')}</a>
                </li>
            </ul>
        </section>
    );
};

export default OptOuts;
