// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import {
    NotificationsSettingsPatchRequest,
    NotificationsSettingsResponse,
} from '@platform360/server-inventory/shared/api-contract';

export type GetNotificationsSettingsOptions = FetcherOptions;

export const getNotificationsSettings = async ({
    signal,
}: GetNotificationsSettingsOptions): Promise<NotificationsSettingsResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<NotificationsSettingsResponse>>(
        '/si/notifications-settings',
        { signal },
    );

    return data;
};

export const updateNotificationsSettings = async (
    payload: NotificationsSettingsPatchRequest,
): Promise<NotificationsSettingsResponse> => {
    const {
        data: { data },
    } = await apiClient.patch<ApiResponse<NotificationsSettingsResponse>>(
        '/si/notifications-settings',
        payload,
    );
    return data;
};
