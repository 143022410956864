// Copyright 2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { useEffect } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { SimpleLayout } from '@platform360/libs/shared-web/components/SimpleLayout';
import { ContentLoader, Heading, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useRedirectUrlQuery from '@platform360/app/web/queries/useRedirectUrlQuery';
import {
    DefaultDestinationProps,
    MonitoringServerSetupDestination,
    ServerDetailsDestination,
} from './destinations';

const DestinationErrorType = 'redirect-gateway-destination-error';

const destinations: Record<string, React.FC<DefaultDestinationProps>> = {
    serverDetails: ServerDetailsDestination,
    monitoringServerSetup: MonitoringServerSetupDestination,
};

const RedirectGateway = () => {
    const [urlSearchParams] = useSearchParams();
    const searchParams = Object.fromEntries(urlSearchParams.entries());

    const { destination = '' } = searchParams;

    const rootTranslate = useTranslate('app.RedirectGateway');
    const translate = useTranslate(`app.RedirectGateway.${destination}`);

    const {
        data,
        isLoading: isRedirectUrlLoading,
        isError: isRedirectUrlError,
        error: redirectUrlError,
    } = useRedirectUrlQuery({
        variables: {
            params: searchParams,
        },
    });

    useEffect(() => {
        if (data) {
            window.location.href = data.redirectUrl;
        }
    }, [data]);

    const renderContent = () => {
        if (isRedirectUrlLoading) {
            return <ContentLoader />;
        }

        if (isRedirectUrlError && redirectUrlError.response?.data?.type === DestinationErrorType) {
            return (
                <StatusMessage intent="danger" data-type="error-message">
                    <Heading level={5}>{rootTranslate('genericError')}</Heading>
                </StatusMessage>
            );
        }

        const DestinationComponent = destinations[destination];
        if (!DestinationComponent) {
            return <Navigate to="/not-found" />;
        }

        return (
            <DestinationComponent
                {...{
                    rootTranslate,
                    translate,
                    redirectUrl: data,
                    isRedirectUrlError,
                }}
            />
        );
    };

    return <SimpleLayout>{renderContent()}</SimpleLayout>;
};

export default RedirectGateway;
