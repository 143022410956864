// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import image from './image.png';
import ConnectServersButton from '@platform360/security-dashboard/web/components/ConnectServersButton';

const Onboarding = () => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Onboarding');

    return (
        <ListEmptyView
            title={translate('title')}
            description={translate('description')}
            image={image}
            actions={<ConnectServersButton />}
        />
    );
};

export default Onboarding;
