// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useLocalStorage } from 'usehooks-ts';
import { useCallback } from 'react';
import { Action, reducer } from './reducer';
import { AuthState, initialAuthState } from '@platform360/libs/shared-web/auth/auth-state';

export const SESSION_KEY = 'session_v3';

const usePersistReducer = (): [AuthState, (action: Action) => void] => {
    const [savedState, saveState] = useLocalStorage(SESSION_KEY, initialAuthState);
    const reducerLocalStorage = useCallback(
        (state: AuthState, action: Action): AuthState => {
            const newState = reducer(state, action);

            saveState(newState);

            return newState;
        },
        [saveState],
    );
    const dispatch = useCallback(
        (action: Action) => {
            reducerLocalStorage(savedState, action);
        },
        [reducerLocalStorage, savedState],
    );

    return [savedState, dispatch];
};

export default usePersistReducer;
