// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'Já existe um convite para este e-mail.',
        invitationMemberExist: 'O usuário deste e-mail já está registrado como membro da equipe.',
        bounceOrComplaint: 'Este endereço de e-mail não existe ou bloqueou nossa mensagem como spam. Entre em contato com a equipe de suporte do Plesk 360.',
        emailsAreEqual: 'O e-mail deve ser diferente do atual.',
        consentKeeperNotAvailable: 'Não é possível verificar se o Contrato de Processamento de Dados foi assinado. Por favor, tente novamente mais tarde.',
    },
    profile: {
        AccountRemoval: {
            removeAccountButton: 'Excluir Conta',
            removeAccount: 'Excluir sua conta <b>{email}</b> no Plesk 360?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{Excluir sua conta <b>{email}</b> e # membro da organização do Plesk 360?} other{Excluir sua conta <b>{email}</b> e # membros da organização do Plesk 360?}}',
            removeAccountDescription1: 'Suas contas de serviço 360 e licenças 360 serão excluídas junto com sua conta. Também removeremos seu endereço de e-mail e outros dados pessoais do Plesk 360.',
            removeAccountDescription2: 'Suas licenças Plesk e extensões não serão afetadas pela exclusão de sua conta Plesk 360, mas você não poderá mais visualizá-las e gerenciá-las no Plesk 360.',
            removingAccount: 'Removendo conta...',
            loading: 'Carregando...',
        },
        ChangePassword: {
            title: 'Mudança de senha',
            submit: 'Mudar senha',
            labelCurrentPassword: 'Senha Atual',
            labelNewPassword: 'Nova Senha',
            labelRepeatPassword: 'Confirme a Senha',
            forgotPassword: 'Esqueceu a senha?',
            successMessage: 'Sua senha foi alterada',
            passwordMatch: 'As senhas que você digitou não coincidem.',
        },
        ViewProfile: {
            title: 'Perfil pessoal',
            changePasswordLink: 'Mudar senha',
            removeAccountLink: 'Apagar',
            emailAddressField: 'Endereço de e-mail',
            nameField: 'Nome',
            timezoneField: 'Fuso horário',
            mfaField: 'Autenticação multifator',
            mfaEnable: 'Enviar e-mail de inscrição',
            mfaDisable: 'Desativar MFA',
            mfaCheckEmail: 'Verifique o e-mail para concluir a inscrição',
            mfaConfirmation: 'Desativar MFA?',
            mfaDisableSuccessToast: 'A MFA foi desativada.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'Desativar MFA',
                description: 'Desativar a autenticação multifator?',
                currentPassword: 'Senha atual',
                otp: 'Código único',
                otpDescription: 'Código único para autenticação de dois fatores fornecido pelo seu aplicativo autenticador.',
                buttonSave: 'Confirmar',
                buttonCancel: 'Cancelar',
            },
        },
        ChangeableEmail: {
            buttonEdit: 'Editar',
            dialogTitle: 'Mudar e-mail',
            description: 'Enviaremos um e-mail com instruções sobre como atualizar seu endereço.',
            email: 'Novo e-mail',
            currentPassword: 'Senha atual',
            otp: 'Código único',
            otpDescription: 'Código único usado como parte da autenticação de dois fatores. Você pode encontrá-lo em seu aplicativo autenticador.',
            consentsTitle: 'Gerenciamento de consentimento',
            buttonSave: 'Enviar',
            buttonCancel: 'Cancelar',
            successMessage: 'Enviamos um e-mail de verificação para <b>{email}</b>. Siga as instruções para verificar seu novo e-mail.',
        },
        DpaManager: {
            title: 'Acordo de Processamento de Dados',
            accepted: 'Aceito',
            notAccepted: 'Não aceito',
            buttonEdit: 'Editar',
            tooltipDpaBlocked: 'Você deve inserir seu nome e sobrenome antes de continuar',
        },
        DpaDialog: {
            title: 'Acordo de Processamento de Dados',
            subtitle: 'Para usar alguns de nossos recursos, você precisa aceitar os termos do Contrato de Processamento de Dados (DPA).',
            dpaLinkText: 'Acordo de Processamento de Dados',
            buttonAccept: 'Aceito',
            buttonDecline: 'Rejeito',
            successMessage: 'O Acordo de Processamento de Dados foi atualizado com sucesso.',
        },
        ConsentManager: {
            error: 'Ocorreu um erro ao carregar os dados de consentimento',
            successMessage: 'Os dados de consentimento foram atualizados com sucesso',
            subscribed: 'Subscrito',
            notSubscribed: 'Não inscrito',
            buttonEdit: 'Editar',
            dialogTitle: 'Gerenciamento de consentimento',
            buttonSave: 'Salvar',
        },
        ChangeableName: {
            successMessage: 'Nome atualizado com sucesso',
            updating: 'Atualizando',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
        },
        Timezone: {
            buttonEdit: 'Editar',
            timezone: 'Fuso Horário',
            dialogTitle: 'Alterar fuso horário',
            buttonCancel: 'Cancelar',
            buttonSave: 'Salvar',
            successMessage: 'Fuso Horário atualizado com sucesso.',
        },
    },
    BusinessProfile: {
        title: 'Conte-nos algumas coisas sobre você',
        save: 'Salvar',
        done: 'Feito',
        answerLater: 'Responda mais tarde',
        tour: {
            gotIt: 'Entendi',
            text: 'Você pode atualizar o Perfil da Empresa posteriormente no menu da conta.',
        },
        firstSectionTitle: 'Conte-nos sobre o seu negócio',
        secondSectionTitle: 'Conte-nos como você usa o Plesk',
        whoDoYouCreateWebsitesFor: 'Para quem você cria websites?',
        clients: 'Clientes',
        ownPurposes: 'Propósitos próprios',
        whatKindOfBusinessDoYouDo: 'O que seu negócio faz?',
        hosting: 'Hospedagem',
        agency: 'Agência',
        isTheWebsiteForBusinessOrPrivatePurposes: 'Seu website é para uso comercial ou privado?',
        business: 'Empresarial',
        private: 'Privado',
        whatIsYourCoreBusiness: 'Qual é o sua principal atividade?',
        webHosting: 'Hospedagem web',
        vpsAndServerHosting: 'VPS & Servidor de Hospedagem',
        iaas: 'Infraestrutura como provedor de serviços (IaaS)',
        saas: 'Software como Provedor de Serviços (SaaS)',
        other: 'Outro',
        webDevelopment: 'Desenvolvimento web',
        webDesignOrManagement: 'Web Design e Gerenciamento',
        marketingOrMedia: 'Marketing e Mídia',
        whichDepartmentDoYouBelongTo: 'A qual departamento você pertence?',
        itAndSystemAdministration: 'Administração de TI e Sistemas',
        systemsIntegration: 'Integração de Sistemas',
        softwareDevelopment: 'Desenvolvimento de Software',
        marketingOrCommunication: 'Marketing e Comunicações',
        forWhichKindOfOrganisationIsTheWebsite: 'Para quem é o website?',
        privatePersonOrFamily: 'Particular ou Familiar',
        association: 'Associação',
        nonProfitOrganisation: 'Organização Sem Fins Lucrativos',
        howManyEmployeesDoesYourCompanyHave: 'Quantos funcionários há na sua empresa?',
        selfEmployed: 'Autônomo',
        howManyMembersDoesYourOrganisationHave: 'Quantos membros há em sua organização?',
        howManyServersDoYouManage: 'Quantos servidores você gerencia?',
        howManyServersDoYouManageDescription: 'com e sem Plesk',
        areTheyYourServers: 'Você possui esses servidores?',
        yes: 'Sim, eu possuo esses servidores.',
        no: 'Não, eu gerencio esses servidores para outras pessoas.',
        partly: 'Ambos, possuo alguns servidores e gerencio servidores para outros.',
        whatIsYourMainPurposeOfPlesk: 'Para que você usa o Plesk?',
        whatIsYourMainPurposeOfPleskDescription: 'Escolha qualquer um que se aplique',
        webhosting: 'Hospedagem web',
        emailHosting: 'Hospedagem de e-mail',
        eCommerceHosting: 'Hospedagem de e-Commerce',
        wordPressManagement: 'Gerenciamento WordPress',
        systemAdministration: 'Administração de Sistema',
        collaboration: 'Colaboração',
        automation: 'Automação',
        reselling: 'Revenda',
        howWouldYouDescribeYourTechnicalKnowledge: 'Como você descreveria seu conhecimento técnico?',
        notTechnicalAtAll: 'Eu não sou técnico em tudo.',
        basicKnowledge: 'Tenho conhecimentos básicos de Linux ou Windows.',
        expert: 'Sou especialista e uso o Plesk para automação.',
    },
    ApiTokens: {
        title: 'Tokens de API',
        name: 'Nome',
        creationDate: 'Tempo de geração',
        lastUsageDate: 'Usado por último',
        tokenNeverUsed: 'Nunca',
        addButton: 'Gerar token de API',
        addButtonLimitExceededTooltip: 'O limite de tokens de API foi atingido. Exclua os tokens existentes para criar novos.',
        countTooltip: '{max, plural, one{Máximo de # token por conta.} other{Máximo de # tokens por conta.}}',
        totalAmount: 'total {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Token <info>{name}</info> gerado com sucesso. Certifique-se de salvá-lo agora em um local seguro. Você não será capaz de vê-lo novamente!',
        emptyListTitle: 'Gere tokens de API para seus aplicativos ou scripts para acessar a API do Platform 360',
        emptyListDescription: 'Seus tokens de API aparecerão aqui.',
        createToken: 'Gerar token de API',
        successCopied: 'Copiado para a área de transferência.',
        successTokenDelete: 'Token excluído com sucesso',
        expireAtTooltip: 'O token expirará em: {expireAt}.',
        AddTokenDialog: {
            title: 'Novo token de API',
            nameLabel: 'Nome do token',
            nameDescription: 'Para que esse token será usado?',
            addButton: 'Gerar',
            closeButton: 'Fechar',
            tokenInfo: 'Certifique-se de salvá-lo agora em um local seguro. Você não será capaz de vê-lo novamente. Se um token for comprometido, gere um novo imediatamente. Seu novo token:',
            close: 'Fechar',
            tokenSuccessCopied: 'Token copiado para a área de transferência.',
        },
        DeleteButton: {
            deleteButton: 'Excluir token',
            deleteConfirmation: 'Tem certeza de que deseja excluir o token <tooltip>{name}</tooltip>? Qualquer aplicativo ou script que use esse token não poderá mais acessar a API do Platform 360.',
            copy: 'Copiar para área de transferência',
            deleteConfirmationInputDesc: 'Insira o nome do token para confirmar a exclusão.',
            deleteConfirmationButton: 'Excluir token',
        },
    },
    UpdateEmail: {
        loadingText: 'Alterando e-mail...',
        ErrorMessage: {
            tryAgainButton: 'Tente novamente',
            errors: {
                forbidden: 'O link de e-mail de alteração expirou. Por favor, tente novamente.',
                changingEmailConflict: 'O usuário com este e-mail já está cadastrado.',
                unexpected: 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde.',
            },
        },
    },
};