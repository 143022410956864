// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'No se ha encontrado el token de autenticación o este no es válido.',
        invitationNotExist: 'La invitación no existe.',
        invitationExpired: 'La invitación ha expirado.',
        invitationConflict: 'El usuario invitado ya está registrado.',
    },
    ChangePassword: {
        passwordChangedSuccess: 'Su contraseña ha sido modificada.',
    },
    ChangePasswordForm: {
        resetTitle: 'Contraseña nueva',
        setTitle: 'Establecer contraseña',
        passwordLabel: 'Contraseña:',
        submitButton: 'Confirmar',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'El email de restablecimiento de contraseña ha sido enviado',
        successSendResetText: 'Hemos enviado un email con un enlace para el restablecimiento de la contraseña a {email}. Haga clic en el enlace para iniciar sesión en Plesk 360 y establecer una contraseña nueva.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Enlace expirado',
        resetText: 'El enlace para el restablecimiento de la contraseña ha expirado.',
        setText: 'El enlace para establecer la contraseña ya no es válido. Por su seguridad, el enlace solo es válido durante 4 horas. Si no le ha dado tiempo a utilizarlo, puede volver a proporcionar su dirección email en el espacio de abajo para establecer la contraseña mediante el proceso estándar de cambio de contraseña.',
        emailLabel: 'Email:',
        submitButton: 'Enviar',
        bottomText: '¿Recuerda la contraseña? Puede <a>iniciar sesión ahora</a>.',
    },
};