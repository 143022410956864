// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { FilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';
import { getDomains } from '@platform360/server-inventory/web/api/servers';

export type UseServerDomainsQueryVariables = {
    serverId: number;
} & PaginatedQuery &
    FilterQuery;

export type UseServerDomainsQueryData = PromiseValue<ReturnType<typeof getDomains>>;

const useServerDomainsQuery = createQuery<
    UseServerDomainsQueryVariables,
    UseServerDomainsQueryData
>({
    queryName: 'server-inventory/useServerDomainsQuery',
    fetcher: getDomains,
    useQuery,
});

export default useServerDomainsQuery;
