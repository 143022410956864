// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';

const SEARCH_DEBOUNCE_MS = 300;

const useSearch = (queryParamName = 'search', onSearch: () => void = () => {}) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(searchParams.get(queryParamName) ?? '');
    const [debouncedValue] = useDebounceValue<string>(value, SEARCH_DEBOUNCE_MS);

    useEffect(() => {
        debouncedValue === ''
            ? searchParams.delete(queryParamName)
            : searchParams.set(queryParamName, debouncedValue);
        navigate(
            {
                search: searchParams.toString(),
            },
            {
                replace: true,
            },
        );
        if (debouncedValue !== '') {
            onSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue, queryParamName]);
    useEffect(() => {
        setValue(searchParams.get(queryParamName) ?? '');
    }, [searchParams, queryParamName]);
    const handleSearch = (newValue: string) => {
        setValue(newValue);
    };

    return { value, debouncedValue, onSearch: handleSearch };
};

export default useSearch;
