// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import RetryTimer, { formatSeconds } from '@platform360/libs/shared-web/components/RetryTimer';
import ResetPasswordForm from '@platform360/auth/web/components/ResetPassword/ResetPasswordForm';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import styles from './ResetPassword.module.css';
import { useResetPassword } from '@platform360/auth/web/mutations';
import { Button, Paragraph, Drawer } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { RESET_PASSWORD_TTL } from '@platform360/auth/shared/throttler-limits';

type ResetPasswordProps = {
    email?: string;
    isUserLoggedIn?: boolean;
} & ClosableProps;

const DataTestIds = {
    RESEND_BUTTON: 'ResetPassword--resendButton',
    LOGIN_LINK: 'ResetPassword--loginLink',
};

export const ResetPassword = ({
    email: userEmail,
    onClose,
    isOpen,
    isUserLoggedIn,
}: ResetPasswordProps) => {
    const {
        document: { title: brandTitle },
    } = useApplicationSettings();
    const translate = useTranslate('Auth.ResetPassword');
    const [email, setEmail] = useState<string>(userEmail || '');
    const [complete, setComplete] = useState(false);
    const [resendAvailable, setResendAvailable] = useState(false);

    const {
        mutate: resetPassword,
        isPending: isLoading,
        errors,
    } = useResetPassword(() => {
        setComplete(true);
        setResendAvailable(false);
    });

    const handleResend = () => resetPassword(email);

    const handleResendEnable = () => setResendAvailable(true);

    const renderForm = () => (
        <ResetPasswordForm
            errors={errors}
            isLoading={isLoading}
            email={email}
            onEmailChange={setEmail}
            onSubmit={resetPassword}
            isUserLoggedIn={isUserLoggedIn}
        />
    );

    const renderSuccess = () => (
        <>
            <Paragraph>{translate('successText1', { email, brand: brandTitle })}</Paragraph>
            <Paragraph>{translate('successText2')}</Paragraph>
            <div className={styles.form}>
                <Button
                    intent="primary"
                    onClick={handleResend}
                    state={isLoading ? 'loading' : undefined}
                    data-type={DataTestIds.RESEND_BUTTON}
                    size="lg"
                    disabled={!resendAvailable}
                >
                    {translate('resendButton')}
                </Button>
                {!resendAvailable && (
                    <RetryTimer onTimeEnd={handleResendEnable} retryInterval={RESET_PASSWORD_TTL}>
                        {({ seconds }: { seconds: number }) => (
                            <span className={styles.retryCountdown}>
                                {translate('retryCountdown', {
                                    time: formatSeconds(seconds),
                                })}
                            </span>
                        )}
                    </RetryTimer>
                )}
            </div>
        </>
    );

    return (
        <Drawer
            isOpen={isOpen}
            size="xs"
            title={translate(complete ? 'successTitle' : 'title')}
            onClose={onClose}
            data-type="reset-password"
        >
            {complete ? renderSuccess() : renderForm()}
        </Drawer>
    );
};

export default ResetPassword;
