// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text, Panel } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { RequestInfo } from '@platform360/admin-panel/web/components/security-dashboard/Requests/useRequestStorage';
import Status from './Status';
import RequestDetails from './RequestDetails';
import styles from './Network.module.less';

type NetworkProps = {
    requests: RequestInfo[];
    className?: string;
};

export const Network = ({ requests, className }: NetworkProps) => {
    const translate = useTranslate('admin-panel.security-dashboard.Requests.Network');

    const panels = requests.map((request) => (
        <Panel
            key={request.requestId}
            collapsible
            title={
                <div className={styles.title}>
                    <Text bold component="span">
                        {request.method}
                    </Text>
                    <Text truncate>{request.url}</Text>
                </div>
            }
            extra={
                <div className={styles.additionalInfo}>
                    {request.time !== null && (
                        <span>
                            {translate('time', {
                                time: request.time,
                                formattedTime: (time) => <Text intent="success">{time}</Text>,
                            })}
                        </span>
                    )}
                    {request.response && (
                        <Status
                            status={request.response.status}
                            statusText={request.response.statusText}
                        />
                    )}
                </div>
            }
            className={styles.network}
        >
            <RequestDetails request={request} />
        </Panel>
    ));

    return <div className={className}>{panels}</div>;
};
