// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Extensions, { GroupProps } from '../components/Extensions';
import periodToText from '../helpers/periodToText';
import prepareDataForComponent from '../helpers/prepareDataForComponent';
import selectItem from '@platform360/licenses/web/units/extensions/actions/selectItem';
import Extension from '@platform360/licenses/web/units/extensions/types/Extension';
import { StoreState } from '@platform360/licenses/web/store/types';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';

const mstp = (state: StoreState) => {
    const { currency, period, ...other } = upsellSelector(state);
    const namedPeriod = periodToText(period);
    const transformFunc = prepareDataForComponent(currency, period);

    return {
        groups: Object.entries(other[namedPeriod]).map(([groupName, extensions]) => {
            const extensionsList: Extension[] = Object.values(extensions);
            return {
                name: groupName,
                list: extensionsList.map(transformFunc),
            } as GroupProps;
        }),
    };
};

const mdtp = (dispatch: Dispatch) => ({
    onExtensionSelect: (id: string) => {
        dispatch(selectItem(id));
    },
});

export default connect(mstp, mdtp)(Extensions);
