// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import getLocalePathByMerchantOfRecordType from '@platform360/licenses/web/units/cleverbridge/helpers/getLocalePathByMerchantOfRecordType';
import { MerchantOfRecordType } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';

const CompanyName = ({ merchantOfRecordType }: { merchantOfRecordType: MerchantOfRecordType }) => {
    const translate = useTranslate();

    return translate(
        getLocalePathByMerchantOfRecordType(merchantOfRecordType).concat('companyName'),
    );
};

export default CompanyName;
