// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { FormValues } from './types';

export type FieldConfig = {
    name: string;
    label: TranslationProps;
    description?: TranslationProps;
    children: ReactNode;
    multiple?: boolean;
    isVisible?: (values: FormValues) => boolean;
};

export type SectionConfig = {
    title: TranslationProps;
    fields: FieldConfig[];
};

const useFormSections = (): SectionConfig[] => {
    const strings2options = (values: string[]) =>
        values.map((value) => (
            <option value={value} key={value}>
                {translate(value, undefined, value)}
            </option>
        ));
    const translate = useTranslate('accounts.BusinessProfile');

    return [
        {
            title: translate('firstSectionTitle'),
            fields: [
                {
                    name: 'whoDoYouCreateWebsitesFor',
                    label: translate('whoDoYouCreateWebsitesFor'),
                    children: strings2options(['clients', 'ownPurposes']),
                },
                {
                    name: 'whatKindOfBusinessDoYouDo',
                    label: translate('whatKindOfBusinessDoYouDo'),
                    children: strings2options(['hosting', 'agency']),
                    isVisible: (values) => values.whoDoYouCreateWebsitesFor === 'clients',
                },
                {
                    name: 'isTheWebsiteForBusinessOrPrivatePurposes',
                    label: translate('isTheWebsiteForBusinessOrPrivatePurposes'),
                    children: strings2options(['business', 'private']),
                    isVisible: (values) => values.whoDoYouCreateWebsitesFor === 'ownPurposes',
                },
                {
                    name: 'whatIsYourCoreBusinessHosting',
                    label: translate('whatIsYourCoreBusiness'),
                    children: strings2options([
                        'webHosting',
                        'vpsAndServerHosting',
                        'iaas',
                        'saas',
                        'other',
                    ]),
                    isVisible: (values) =>
                        values.whoDoYouCreateWebsitesFor === 'clients' &&
                        values.whatKindOfBusinessDoYouDo === 'hosting',
                },
                {
                    name: 'whatIsYourCoreBusinessAgency',
                    label: translate('whatIsYourCoreBusiness'),
                    children: strings2options([
                        'webDevelopment',
                        'webDesignOrManagement',
                        'marketingOrMedia',
                        'other',
                    ]),
                    isVisible: (values) =>
                        values.whoDoYouCreateWebsitesFor === 'clients' &&
                        values.whatKindOfBusinessDoYouDo === 'agency',
                },
                {
                    name: 'whichDepartmentDoYouBelongTo',
                    label: translate('whichDepartmentDoYouBelongTo'),
                    children: strings2options([
                        'itAndSystemAdministration',
                        'systemsIntegration',
                        'softwareDevelopment',
                        'marketingOrCommunication',
                        'other',
                    ]),
                    isVisible: (values) =>
                        values.whoDoYouCreateWebsitesFor === 'ownPurposes' &&
                        values.isTheWebsiteForBusinessOrPrivatePurposes === 'business',
                },
                {
                    name: 'forWhichKindOfOrganisationIsTheWebsite',
                    label: translate('forWhichKindOfOrganisationIsTheWebsite'),
                    children: strings2options([
                        'privatePersonOrFamily',
                        'association',
                        'nonProfitOrganisation',
                        'other',
                    ]),
                    isVisible: (values) =>
                        values.whoDoYouCreateWebsitesFor === 'ownPurposes' &&
                        values.isTheWebsiteForBusinessOrPrivatePurposes === 'private',
                },
                {
                    name: 'howManyEmployeesDoesYourCompanyHave',
                    label: translate('howManyEmployeesDoesYourCompanyHave'),
                    children: strings2options([
                        'selfEmployed',
                        '2-10',
                        '10-50',
                        '50-100',
                        '100-500',
                        '> 500',
                    ]),
                    isVisible: (values) =>
                        (values.whatKindOfBusinessDoYouDo !== undefined ||
                            values.isTheWebsiteForBusinessOrPrivatePurposes !== undefined) &&
                        values.isTheWebsiteForBusinessOrPrivatePurposes !== 'private',
                },
                {
                    name: 'howManyMembersDoesYourOrganisationHave',
                    label: translate('howManyMembersDoesYourOrganisationHave'),
                    children: strings2options(['1', '2-10', '10-50', '50-100', '100-500', '> 500']),
                    isVisible: (values) =>
                        values.isTheWebsiteForBusinessOrPrivatePurposes === 'private',
                },
            ],
        },
        {
            title: translate('secondSectionTitle'),
            fields: [
                {
                    name: 'howManyServersDoYouManage',
                    label: translate('howManyServersDoYouManage'),
                    description: translate('howManyServersDoYouManageDescription'),
                    children: strings2options(['1', '2-5', '5-10', '10-50', '> 50']),
                },
                {
                    name: 'areTheyYourServers',
                    label: translate('areTheyYourServers'),
                    children: strings2options(['yes', 'no', 'partly']),
                },
                {
                    name: 'whatIsYourMainPurposeOfPlesk',
                    label: translate('whatIsYourMainPurposeOfPlesk'),
                    description: translate('whatIsYourMainPurposeOfPleskDescription'),
                    multiple: true,
                    children: strings2options([
                        'webhosting',
                        'emailHosting',
                        'eCommerceHosting',
                        'wordPressManagement',
                        'softwareDevelopment',
                        'systemAdministration',
                        'collaboration',
                        'automation',
                        'reselling',
                        'other',
                    ]),
                },
                {
                    name: 'howWouldYouDescribeYourTechnicalKnowledge',
                    label: translate('howWouldYouDescribeYourTechnicalKnowledge'),
                    children: strings2options(['notTechnicalAtAll', 'basicKnowledge', 'expert']),
                },
            ],
        },
    ];
};

export default useFormSections;
