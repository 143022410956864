// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Drawer, Tabs, Tab, ContentLoader, StatusMessage, setIn } from '@plesk/ui-library';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import {
    ServerSettingsResponse,
    SaveServerSettingsRequest,
} from '@platform360/security-dashboard/web/api/servers';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';
import Updates from './Updates';
import DailyTaskTime from './DailyTaskTime';

import styles from './SettingsDrawer.module.css';
import { UpdateSettings } from '@platform360/security-dashboard/web/types';
import { useNavigate, useParams } from 'react-router-dom';

export type FormValues = {
    updates: {
        core: UpdateSettingsOption | 'parent';
        plugins: UpdateSettingsOption | 'parent';
        themes: UpdateSettingsOption | 'parent';
        safeUpdate: SafeUpdateOption | 'parent';
    };
    dailyTask?: ServerSettingsResponse['dailyTask'];
};
type PartialFormValues = {
    updates: Partial<FormValues['updates']>;
    dailyTask?: FormValues['dailyTask'];
};

const defaultValues: PartialFormValues = {
    updates: {},
};

export type SettingsDrawerProps = {
    subTitle: ReactNode;
    isMassOperation?: boolean;
    initialFormValues: FormValues;
    parentUpdateSettings?: UpdateSettings;
    drawerBaseUrl: string;
    isLoadError?: boolean;
    isLoading?: boolean;
    isSaving: boolean;
    onSave: (requestData: SaveServerSettingsRequest, onSuccess: () => void) => void;
} & ClosableProps;

const SettingsDrawer = ({
    isOpen,
    onClose,
    subTitle,
    isMassOperation,
    initialFormValues,
    parentUpdateSettings,
    drawerBaseUrl,
    isLoadError,
    isLoading,
    isSaving,
    onSave,
    ...props
}: SettingsDrawerProps) => {
    const translate = useTranslate('security-dashboard.Servers.SettingsDrawer');
    const { successToast } = useToaster();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState<PartialFormValues>(defaultValues);
    const [overrideDescendants, setOverrideDescendants] = useState(false);
    const { tabKey } = useParams<{ tabKey: string }>();

    const handleFieldChange = (key: string, value: FormValues[keyof FormValues]) =>
        setFormValues(setIn(formValues, key, value));

    const values: FormValues = {
        ...initialFormValues,
        ...formValues,
        updates: {
            ...initialFormValues.updates,
            ...formValues.updates,
        },
    };

    const handleSave = () => {
        const isUpdatesDirty =
            (!isMassOperation && overrideDescendants) ||
            !isEqual(values.updates, initialFormValues.updates);
        const isDailyTaskDirty = !isEqual(values.dailyTask, initialFormValues.dailyTask);

        const isDirty = isUpdatesDirty || isDailyTaskDirty;

        if (!isDirty) {
            onClose();
            return;
        }

        const request: SaveServerSettingsRequest = {};

        if (isUpdatesDirty) {
            const {
                updates: { core, plugins, themes, safeUpdate },
            } = values;

            request.updates = {
                core: core === 'parent' ? null : core,
                plugins: plugins === 'parent' ? null : plugins,
                themes: themes === 'parent' ? null : themes,
                safeUpdate: safeUpdate === 'parent' ? null : safeUpdate,
                overrideDescendants,
            };

            analyticsEvents.wpSrvAutoupdateSettingsSave(
                values.updates.safeUpdate,
                overrideDescendants,
            );
        }

        if (isDailyTaskDirty && values.dailyTask !== undefined) {
            request.dailyTask = values.dailyTask;
            const { taskStartTimeHours, taskStartTimeMinutes } = values.dailyTask;
            const isTimeSet = taskStartTimeHours !== null && taskStartTimeMinutes !== null;
            analyticsEvents.wpSrvDailyTaskTimeSettingsSave(isTimeSet);
        }

        onSave(request, () => {
            successToast(translate('successToast'));
            setFormValues(defaultValues);
            onClose();
        });
    };

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpSrvSettingsShown();
        } else {
            analyticsEvents.wpSrvSettingsClosed();
        }
    }, [isOpen, analyticsEvents]);

    const tabs = [
        {
            key: 'updates',
            title: translate('tabs.updates'),
            children: (
                <Updates
                    parentSettings={parentUpdateSettings}
                    overrideDescendants={overrideDescendants}
                    onOverrideDescendantsChange={setOverrideDescendants}
                    isMassOperation={isMassOperation}
                />
            ),
        },
        {
            key: 'daily-task',
            title: translate('tabs.dailyTaskTime'),
            children: (
                <DailyTaskTime
                    initialValue={initialFormValues.dailyTask}
                    value={values.dailyTask}
                    onChange={(dailyTask) => handleFieldChange('dailyTask', dailyTask)}
                    isSaving={isSaving}
                    isMassOperation={isMassOperation}
                />
            ),
        },
    ];

    const activeTabIndex = tabs.findIndex(({ key }) => key === tabKey);
    const activeTab = activeTabIndex === -1 ? null : tabs[activeTabIndex];

    if (!activeTab) return null;

    const renderContent = () => {
        if (isLoadError) {
            return <StatusMessage intent="danger">{translate('loadFailed')}</StatusMessage>;
        }

        if (isLoading) {
            return <ContentLoader />;
        }

        return activeTab.children;
    };

    return (
        <Drawer
            title={translate('title')}
            subtitle={subTitle}
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
            form={{
                values,
                applyButton: false,
                submitButton: { children: translate('buttonSave'), disabled: isLoadError },
                onFieldChange: handleFieldChange,
                state: isSaving ? 'submit' : undefined,
                onSubmit: handleSave,
                className: styles.form,
            }}
            tabs={
                <Tabs active={activeTabIndex + 1}>
                    {tabs.map(({ title, key }) => (
                        <Tab
                            key={key}
                            onClick={() => {
                                navigate(`${drawerBaseUrl}/${key}`);
                                analyticsEvents.wpSrvSettingsTabClick(key);
                            }}
                            title={title}
                        />
                    ))}
                </Tabs>
            }
            {...props}
        >
            {renderContent()}
        </Drawer>
    );
};

export default SettingsDrawer;
