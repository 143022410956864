// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Icon, Item, ItemList } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const FeedbackButton = () => {
    const translate = useTranslate('Servers.FeedbackButton');

    return (
        // @ts-expect-error fix type in UI Library
        <ItemList minColumnWidth={400}>
            <Item
                component="a"
                href="https://pt-research.typeform.com/to/BVtcGgRL"
                target="_blank"
                rel="noopener noreferrer"
                view="card"
                icon={<Icon name="feedback" intent="success" size="32" />}
                title={<Heading level={3}>{translate('heading')}</Heading>}
                description={translate('subHeading')}
            />
        </ItemList>
    );
};

export default FeedbackButton;
