// Copyright 2024. WebPros International GmbH. All rights reserved.

import { initialState } from './initialState';
import {
    CALCULATE_SUMMARY_COST,
    FAILED_INITIALIZATION,
    FINALIZATION,
    INITIALIZATION,
    INITIALIZED,
    SET_CALCULATED_COST,
    SET_VALUE,
    SUBMIT,
    SUBMITTED,
} from '@platform360/licenses/web/units/adjust/constants/actions';
import {
    CalculateAction,
    CalculatedAction,
    FailedInitializationAction,
    FinalizationAction,
    InitializationAction,
    InitializedAction,
    SetValueAction,
    SubmitAction,
    SubmittedAction,
} from '@platform360/licenses/web/units/adjust/actions';

export type AdjustActions =
    | FailedInitializationAction
    | InitializationAction
    | InitializedAction
    | CalculateAction
    | CalculatedAction
    | SetValueAction
    | SubmitAction
    | SubmittedAction
    | FinalizationAction;

export const reducer = (state = initialState, action: AdjustActions) => {
    switch (action.type) {
        case INITIALIZATION: {
            return { ...state, initialization: true };
        }
        case FAILED_INITIALIZATION: {
            return { ...state, failedInitialization: true };
        }
        case INITIALIZED: {
            return {
                ...state,
                initialization: false,
                licenseId: action.payload.licenseId,
                isAdjustPending: action.payload.isAdjustPending,
                subscriptionId: action.payload.subscriptionId,
                propertiesOfLicense: action.payload.propertiesOfLicense,
                propertiesForChange: action.payload.propertiesForChange,
                newValues: action.payload.newValues,
            };
        }
        case SET_VALUE: {
            return {
                ...state,
                newValues: {
                    ...state.newValues,
                    [action.payload.productId]: action.payload.value,
                },
            };
        }
        case CALCULATE_SUMMARY_COST: {
            return { ...state, recalculation: true, calculatedSubscriptionState: null };
        }
        case SET_CALCULATED_COST: {
            return { ...state, recalculation: false, calculatedSubscriptionState: action.payload };
        }
        case SUBMIT: {
            return { ...state, submitting: true };
        }
        case SUBMITTED: {
            return { ...state, submitted: action.payload };
        }
        case FINALIZATION:
            return initialState;
        default:
            return state;
    }
};
