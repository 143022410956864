// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, ReactNode, useState } from 'react';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import Loading from '@platform360/libs/shared-web/components/Loading';

import classNames from 'classnames';
import { Heading } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import StartStep from '../StartStep';
import SecurityCheckScheduleStep from '../SecurityCheckScheduleStep';
import VirtualPatchesStep from '../VirtualPatchesStep';
import UpdatesStep from '../UpdatesStep';
import { NotificationsStep } from '../NotificationsStep/NotificationsStep';

import styles from './QuickStartWizard.module.less';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { QuickStartStepProps } from '../QuickStartStep/QuickStartStep';

export type QuickStartWizardProps = {
    title: ReactNode;
    onClose: () => void;
};

type Step = {
    component: (props: QuickStartStepProps) => ReactElement;
    name: ReactNode;
    key: string;
};

export const QuickStartWizard = ({ title, onClose }: QuickStartWizardProps) => {
    const { data } = useLicenseQuery();
    const [activeStepIndex, setStep] = useState(0);
    const translate = useTranslate('security-dashboard.QuickStartDialog.QuickStartWizard');

    const analytics = useSecurityDashboardAnalyticsEvents();

    useEffectOnce(() => {
        analytics.wpQuickStartShown();

        return () => analytics.wpQuickStartClosed();
    });

    if (!data) {
        return <Loading />;
    }

    const steps = [
        {
            component: StartStep,
            name: translate('startStepName'),
            key: 'start',
        },
        {
            component: SecurityCheckScheduleStep,
            name: translate('securityCheckScheduleStepName'),
            key: 'securityCheckSchedule',
        },
        data.status === 'active' && {
            component: UpdatesStep,
            name: translate('updateStepName'),
            key: 'update',
        },
        {
            component: VirtualPatchesStep,
            name: translate('virtualPatchesStepName'),
            key: 'virtualPatches',
        },
        {
            component: NotificationsStep,
            name: translate('notificationsStepName'),
            key: 'notifications',
        },
    ].filter((step): step is Step => !!step);

    const activeStep = steps[activeStepIndex];

    if (!activeStep) {
        return null;
    }

    const selectStep = (stepIndex: number) => {
        const newStep = steps[stepIndex];
        if (!newStep) {
            return;
        }
        analytics.wpQuickStartStepOpened(newStep.key);
        setStep(stepIndex);
    };

    const { component: ActiveStepComponent } = activeStep;
    const isLastStep = activeStepIndex === steps.length - 1;

    const sidebar = (
        <div className={styles.sidebar}>
            <Heading className={styles.title} level={3}>
                {title}
            </Heading>
            <div>
                {steps.map((step, index) => (
                    <div
                        className={classNames(styles.sidebarItem, {
                            [styles.active ?? '']: index === activeStepIndex,
                        })}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => selectStep(index)}
                    >
                        {step.name}
                    </div>
                ))}
            </div>
        </div>
    );

    const onSubmit = () => {
        analytics.wpQuickStartStepSubmitted(activeStep.key);
        if (isLastStep) {
            onClose();
        } else {
            selectStep(activeStepIndex + 1);
        }
    };

    const goBack = () => selectStep(Math.max(0, activeStepIndex - 1));

    return (
        <ActiveStepComponent
            onSubmit={onSubmit}
            onBackClick={goBack}
            sidebar={sidebar}
            onClose={onClose}
            activeStepIndex={activeStepIndex}
            stepsCount={steps.length}
            heading={activeStep.name}
        />
    );
};
