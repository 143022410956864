// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { useVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { VulnerabilityInstallationOperations } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import { Badge, Button, Checkbox, Icon, Link, Text, CheckboxProps } from '@plesk/ui-library';
import InfoColumn from './InfoColumn';
import Onboarding from './Onboarding';
import Toolbar from './Toolbar';
import Filters from './Filters';
import RiskRankTitle from '@platform360/security-dashboard/web/components/RiskRankTitle';
import RiskRank from '@platform360/security-dashboard/web/components/RiskRank';
import InstallationComponentFlag from '@platform360/security-dashboard/web/components/InstallationComponentFlag';
import { useLoadingRows } from '@platform360/security-dashboard/web/hooks/useLoadingRows';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import useFilter from '@platform360/libs/shared-web/helpers/useFilter';
import {
    UPDATE_TASKS,
    VIRTUAL_PATCHES_TASKS,
    VulnerabilitiesComponentFilter,
    VulnerabilitiesStateFilter,
    Vulnerability,
    VULNERABILITY_COMPONENT_FILTER_ID,
    VULNERABILITY_STATE_FILTER_ID,
} from '@platform360/security-dashboard/web/types';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useMediaQuery } from 'usehooks-ts';
import {
    useRemoveVulnerabilityLabelsMutation,
    useIgnoreVulnerabilitiesMutation,
    useMitigateVulnerabilitiesMutation,
} from '@platform360/security-dashboard/web/mutations';
import styles from './Vulnerabilities.module.less';
import Widgets from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets';
import {
    getVulnerabilities,
    MITIGATION_OPERATION_ACTIVATE_ASSET,
    MITIGATION_OPERATION_DEACTIVATE_ASSET,
    MITIGATION_OPERATION_UPDATE,
    MITIGATION_POLICY_ACTIVATE_AFTER_UPDATE,
    MITIGATION_TYPE_DEACTIVATE_ASSET,
    MITIGATION_TYPE_UPDATE,
    MitigationParams,
    VulnerabilityMitigationsParams,
} from '@platform360/security-dashboard/web/api/vulnerabilities';
import { isIgnoredVulnerability } from '@platform360/security-dashboard/web/helpers/isIgnoredVulnerability';
import ListEmptyViewFilter from '@platform360/security-dashboard/web/components/ListEmptyViewFilter';
import { UnprocessableInstallationsDialog } from '@platform360/security-dashboard/web/components/UnprocessableInstallationsDialog';
import { useListFetcher } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import {
    OperationType,
    OperationTypes,
} from '@platform360/security-dashboard/shared/operation-type';
import { useUnprocessableInstallationsDialog } from './useUnprocessableInstallationsDialog';
import usePatchVulnerabilitiesMutation from '@platform360/security-dashboard/web/mutations/usePatchVulnerabilitiesMutation';
import { OperationSubTypes } from '@platform360/security-dashboard/shared/operation-sub-type';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';

const MITIGATION_OPERATIONS = [
    OperationTypes.mitigationDeactivateAsset,
    OperationTypes.mitigationActivateAsset,
] as OperationType[];

type VulnerabilityWithIntent = Vulnerability & {
    intent: 'inactive' | undefined;
};

type VulnerabilityListOperation = 'update' | 'mitigation' | 'ignore' | 'removeLabels' | 'patch';

const Vulnerabilities = () => {
    const [selection, setSelection] = useState<string[]>([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const vertical = useMediaQuery('(max-width: 992px)');
    const { loadingRows, loadingRowsState, setLoadingRows, unsetLoadingRows } = useLoadingRows<
        string,
        VulnerabilityListOperation
    >();
    const translate = useTranslate('security-dashboard.Vulnerabilities');
    const pagination = usePagination('wpsd-all-vulnerabilities');
    const search = useSearch('search', () => analyticsEvents.wpSearchSubmit());
    const {
        resetFilter: resetVulnerabilityStateFilter,
        setFilter: setVulnerabilityStateFilter,
        filter: vulnerabilityStateFilter,
    } = useFilter<VulnerabilitiesStateFilter>(VULNERABILITY_STATE_FILTER_ID);
    const {
        resetFilter: resetVulnerabilityComponentFilter,
        setFilter: setVulnerabilityComponentFilter,
        filter: vulnerabilityComponentFilter,
    } = useFilter<VulnerabilitiesComponentFilter>(VULNERABILITY_COMPONENT_FILTER_ID);
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const isVulnerabilitiesShown = useRef<boolean>(false);

    const [
        unprocessableInstallationsDialogProps,
        handleUnprocessableInstallations,
        handleLicenseTerminated,
    ] = useUnprocessableInstallationsDialog();

    const clearSelection = () => setSelection([]);

    const handleSearch = (value: string) => search.onSearch(value);

    const handleVulnerabilityFilterChange = ([stateFilter, componentFilter]: [
        VulnerabilitiesStateFilter[],
        VulnerabilitiesComponentFilter[],
    ]) => {
        setVulnerabilityStateFilter(stateFilter);
        setVulnerabilityComponentFilter(componentFilter);

        clearSelection();
        pagination.resetPagination();
        setIsFilterOpen(false);

        for (const option of stateFilter) {
            switch (option) {
                case VulnerabilitiesStateFilter.Ignored:
                    analyticsEvents.wpVulnerabilitiesFilterIgnoredApply();
                    break;
                case VulnerabilitiesStateFilter.Exploited:
                    analyticsEvents.wpVulnerabilitiesFilterExploitedApply();
                    break;
                case VulnerabilitiesStateFilter.CanBeProtected:
                    analyticsEvents.wpVulnerabilitiesFilterNeedProtectionApply();
                    break;
            }
        }

        for (const component of componentFilter) {
            switch (component) {
                case VulnerabilitiesComponentFilter.Core:
                    analyticsEvents.wpVulnerabilitiesFilterCoreApply();
                    break;
                case VulnerabilitiesComponentFilter.Plugin:
                    analyticsEvents.wpVulnerabilitiesFilterPluginApply();
                    break;
                case VulnerabilitiesComponentFilter.Theme:
                    analyticsEvents.wpVulnerabilitiesFilterThemeApply();
                    break;
                // To avoid linter validation error
                case VulnerabilitiesComponentFilter.Header:
                    break;
            }
        }
    };

    const resetVulnerabilityFilter = () => {
        resetVulnerabilityStateFilter();
        resetVulnerabilityComponentFilter();
        clearSelection();
        pagination.resetPagination();
        setIsFilterOpen(false);
        analyticsEvents.wpVulnerabilitiesFilterReset();
    };

    const variables = {
        filter: search.debouncedValue,
        vulnerabilityStateFilter,
        vulnerabilityComponentFilter,
        page: pagination.current,
        pageSize: pagination.itemsPerPage,
    };
    const { data: license, isLoading: isLicenseLoading } = useLicenseQuery();

    const {
        data = { data: [], totalCount: 0 },
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useVulnerabilitiesQuery({
        variables,
        placeholderData: (prev) => prev,
    });

    useEffect(() => {
        if (!isVulnerabilitiesShown.current) {
            isVulnerabilitiesShown.current = true;
            analyticsEvents.wpVulnerabilitiesShown(
                data.totalCount,
                data.data.filter((item) => item.mitigated).length,
            );
        }
    }, [analyticsEvents, data]);

    useListFetcher(useVulnerabilitiesQuery.getQueryKey(variables), getVulnerabilities);

    const isLicenseTerminated = !isLicenseLoading && license?.status === 'terminated';

    const renderSelectionCheckbox = (props: CheckboxProps) => {
        const { checked, disabled, onChange, inputProps } = props;
        return (
            <Checkbox
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                inputProps={inputProps}
            />
        );
    };

    const loadingRowsIds: string[] = [
        ...loadingRows,
        ...data.data.reduce<string[]>(
            (ids, { vulnerabilityId, tasks }) => (tasks.length ? [...ids, vulnerabilityId] : ids),
            [],
        ),
    ];

    const columns: ListProps<Vulnerability>['columns'] = [
        {
            key: 'cvssScore',
            width: '1%',
            title: <RiskRankTitle className={styles.riskRankTitle} />,
            render: (vulnerability) => (
                <RiskRank
                    riskRank={vulnerability.riskRank}
                    cvssScore={vulnerability.cvssScore}
                    intent={vulnerability.mitigated ? 'inactive' : undefined}
                />
            ),
        },
        {
            key: 'info',
            title: '',
            className: styles.infoColumn,
            render: (vulnerability) => (
                <InfoColumn
                    vulnerability={vulnerability}
                    vertical={vertical}
                    onRemoveLabel={
                        loadingRowsIds.includes(vulnerability.vulnerabilityId)
                            ? undefined
                            : (labelId) =>
                                  handleRemoveLabels([vulnerability.vulnerabilityId], [labelId])
                    }
                />
            ),
        },
        {
            key: 'component',
            title: translate('columns.position'),
            render: ({ component, installationsCount, serversCount, vulnerabilityId }) => (
                <div
                    className={classNames(styles.positionContent, {
                        [styles.positionContentVertical ?? '']: vertical,
                    })}
                >
                    <InstallationComponentFlag component={component} />
                    <Link
                        className={styles.positionLink}
                        component={RouterLink}
                        to={`/security-dashboard/installations?search=${encodeURIComponent(
                            vulnerabilityId,
                        )}&searchVulnerability=${encodeURIComponent(vulnerabilityId)}`}
                        onClick={() =>
                            analyticsEvents.wpFilterSitesVulnerableClick(installationsCount)
                        }
                    >
                        <Icon
                            intent="inactive"
                            name="site-page"
                            className={styles.positionLinkIcon}
                        />
                        <Text bold>
                            {translate('installations', { count: installationsCount })}
                        </Text>
                    </Link>
                    <Link
                        className={styles.positionLink}
                        component={RouterLink}
                        to={`/security-dashboard/servers?search=${encodeURIComponent(
                            vulnerabilityId,
                        )}&searchVulnerability=${encodeURIComponent(vulnerabilityId)}`}
                        onClick={() => analyticsEvents.wpFilterServersVulnerableClick(serversCount)}
                    >
                        <Icon intent="inactive" name="server" className={styles.positionLinkIcon} />
                        <Text bold>{translate('servers', { count: serversCount })}</Text>
                    </Link>
                </div>
            ),
        },
        {
            key: 'widgets',
            width: '30%',
            title: '',
            render: ({
                installationsCount,
                installationsWithEnabledPluginCount,
                installationsWithPluginDisabledByMitigationCount,
                vulnerabilityId,
                tasks,
                component,
                installationsWithMitigationUpdateAvailable,
                installationsWithMitigationPatchAvailable,
                protectedInstallationsCount,
            }) => (
                <Widgets
                    component={component}
                    vulnerabilityId={vulnerabilityId}
                    installationsCount={installationsCount}
                    installationsWithEnabledPluginCount={installationsWithEnabledPluginCount}
                    installationsWithPluginDisabledByMitigationCount={
                        installationsWithPluginDisabledByMitigationCount
                    }
                    installationsWithMitigationUpdateAvailable={
                        installationsWithMitigationUpdateAvailable
                    }
                    installationsWithMitigationPatchAvailable={
                        installationsWithMitigationPatchAvailable
                    }
                    protectedInstallationsCount={protectedInstallationsCount}
                    hasUpdate={installationsWithMitigationUpdateAvailable > 0}
                    onUpdate={(enableAfterUpdate: boolean) => {
                        analyticsEvents.wpSglUpdateVulnerabilityConfirmationClick(
                            vulnerabilityId,
                            installationsCount,
                            enableAfterUpdate,
                        );
                        void handleUpdate([vulnerabilityId], enableAfterUpdate);
                        void handleUnprocessableInstallations(
                            [vulnerabilityId],
                            VulnerabilityInstallationOperations.update,
                        );
                    }}
                    handlePatch={({
                        affectedSitesCount,
                        ignoreDoNotProtect,
                        doNotProtectCount,
                        protectionDisabledTextShown,
                    }) => {
                        analyticsEvents.wpSglProtectVulnerabilityConfirmationClick(
                            vulnerabilityId,
                            affectedSitesCount,
                            {
                                ignoreDoNotProtect,
                                doNotProtectCount,
                                protectionDisabledTextShown,
                            },
                        );
                        void handlePatch([vulnerabilityId], ignoreDoNotProtect);
                        void handleUnprocessableInstallations(
                            [vulnerabilityId],
                            VulnerabilityInstallationOperations.patch,
                        );
                    }}
                    onDisablePlugins={() => {
                        analyticsEvents.wpSglVulnerabilityDisablePluginsConfirmationClick(
                            vulnerabilityId,
                            installationsWithEnabledPluginCount,
                        );
                        void handleDisablePlugins(vulnerabilityId);
                        void handleUnprocessableInstallations(
                            [vulnerabilityId],
                            VulnerabilityInstallationOperations.disablePluginByMitigation,
                        );
                    }}
                    onEnablePlugins={() => {
                        analyticsEvents.wpSglVulnerabilityEnablePluginsConfirmationClick(
                            vulnerabilityId,
                            installationsCount - installationsWithEnabledPluginCount,
                        );
                        void handleEnablePlugins(vulnerabilityId);
                        void handleUnprocessableInstallations(
                            [vulnerabilityId],
                            VulnerabilityInstallationOperations.enablePluginDisabledByMitigation,
                        );
                    }}
                    isUpdateStarting={loadingRowsState.update.includes(vulnerabilityId)}
                    isPluginsMitigationStarting={loadingRowsState.mitigation.includes(
                        vulnerabilityId,
                    )}
                    updateInProgress={tasks.some(
                        ({ type, operationType }) =>
                            UPDATE_TASKS.includes(type) ||
                            operationType === OperationTypes.fixVulnerabilitiesViaUpdate,
                    )}
                    pluginsMitigationInProgress={tasks.some(
                        ({ operationType }) =>
                            operationType && MITIGATION_OPERATIONS.includes(operationType),
                    )}
                    isPatchStarting={loadingRowsState.patch.includes(vulnerabilityId)}
                    patchInProgress={tasks.some(
                        ({ type, operationSubType }) =>
                            VIRTUAL_PATCHES_TASKS.includes(type) &&
                            operationSubType === OperationSubTypes.vulnerabilities,
                    )}
                />
            ),
        },
    ];

    const { mutateAsync: mitigateVulnerabilities } = useMitigateVulnerabilitiesMutation();
    const { mutateAsync: ignore } = useIgnoreVulnerabilitiesMutation();
    const { mutateAsync: removeLabels } = useRemoveVulnerabilityLabelsMutation();
    const { mutateAsync: patch } = usePatchVulnerabilitiesMutation();

    const handleUpdate = async (vulnerabilitiesIds: string[], enableAfterUpdate: boolean) => {
        setLoadingRows(vulnerabilitiesIds, 'update');
        clearSelection();
        const mitigations: MitigationParams[] = [{ type: MITIGATION_TYPE_UPDATE, applied: true }];
        const vulnerabilityMitigations: VulnerabilityMitigationsParams[] = vulnerabilitiesIds.map(
            (vulnerabilityId) => ({
                vulnerabilityId,
                mitigations,
                policy: enableAfterUpdate ? MITIGATION_POLICY_ACTIVATE_AFTER_UPDATE : undefined,
            }),
        );
        try {
            await mitigateVulnerabilities({
                operation: MITIGATION_OPERATION_UPDATE,
                vulnerabilityMitigations,
            });
        } finally {
            unsetLoadingRows(vulnerabilitiesIds, 'update');
        }
    };
    const handlePatch = async (vulnerabilityIds: string[], ignoreDoNotProtect: boolean) => {
        setLoadingRows(vulnerabilityIds, 'patch');
        clearSelection();
        try {
            await patch({ vulnerabilityIds, ignoreDoNotProtect });
        } finally {
            unsetLoadingRows(vulnerabilityIds, 'patch');
        }
    };

    const handleDisablePlugins = async (vulnerabilityId: string) => {
        setLoadingRows([vulnerabilityId], 'mitigation');
        clearSelection();
        try {
            await mitigateVulnerabilities({
                operation: MITIGATION_OPERATION_DEACTIVATE_ASSET,
                vulnerabilityMitigations: [
                    {
                        vulnerabilityId,
                        mitigations: [{ type: MITIGATION_TYPE_DEACTIVATE_ASSET, applied: true }],
                    },
                ],
            });
        } finally {
            unsetLoadingRows([vulnerabilityId], 'mitigation');
        }
    };

    const handleEnablePlugins = async (vulnerabilityId: string) => {
        setLoadingRows([vulnerabilityId], 'mitigation');
        clearSelection();
        try {
            await mitigateVulnerabilities({
                operation: MITIGATION_OPERATION_ACTIVATE_ASSET,
                vulnerabilityMitigations: [
                    {
                        vulnerabilityId,
                        mitigations: [{ type: MITIGATION_TYPE_DEACTIVATE_ASSET, applied: false }],
                    },
                ],
            });
        } finally {
            unsetLoadingRows([vulnerabilityId], 'mitigation');
        }
    };

    const handleIgnore = async (vulnerabilitiesIds: string[]) => {
        analyticsEvents.wpVulnerabilitiesSetIgnoreClick(vulnerabilitiesIds.length);
        setLoadingRows(vulnerabilitiesIds, 'ignore');
        clearSelection();
        try {
            await ignore({ vulnerabilityIds: vulnerabilitiesIds });
        } finally {
            unsetLoadingRows(vulnerabilitiesIds, 'ignore');
        }
    };

    const handleRemoveLabels = async (vulnerabilityIds: string[], labelIds: number[]) => {
        analyticsEvents.wpVulnerabilitiesUnsetIgnoreClick(vulnerabilityIds.length);
        setLoadingRows(vulnerabilityIds, 'removeLabels');
        clearSelection();

        try {
            await removeLabels({ vulnerabilityIds, labelIds });
        } finally {
            unsetLoadingRows(vulnerabilityIds, 'removeLabels');
        }
    };

    const allVulnerabilities: VulnerabilityWithIntent[] = data.data.map((vulnerability) => ({
        intent:
            vulnerability.mitigated || isIgnoredVulnerability(vulnerability)
                ? 'inactive'
                : undefined,
        ...vulnerability,
    }));

    const filtered = vulnerabilityStateFilter.length + vulnerabilityComponentFilter.length > 0;

    const filters = (
        <Filters
            stateFilter={vulnerabilityStateFilter}
            componentFilter={vulnerabilityComponentFilter}
            onFilterApply={handleVulnerabilityFilterChange}
            onReset={resetVulnerabilityFilter}
        />
    );

    const list = (
        <DataList<VulnerabilityWithIntent>
            className={styles.list}
            vertical={vertical}
            sidebar={isFilterOpen && !vertical ? filters : undefined}
            toolbar={
                <>
                    <Toolbar
                        vulnerabilities={allVulnerabilities}
                        selectedItems={selection}
                        onSearch={handleSearch}
                        searchValue={search.value}
                        filtersButton={
                            <Badge intent="info" hidden={!filtered}>
                                <Button
                                    icon={filtered ? 'filter-check-mark' : 'filter'}
                                    state={isFilterOpen ? 'active' : undefined}
                                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                                    data-type="vulnerabilities-filter"
                                />
                            </Badge>
                        }
                        onHandleUpdate={(enableAfterUpdate: boolean) => {
                            analyticsEvents.wpMltUpdateVulnerabilitiesConfirmationClick(
                                selection.length,
                            );
                            void handleUpdate(selection, enableAfterUpdate);
                        }}
                        onUpdateConfirmationShown={() => {
                            analyticsEvents.wpMltUpdateVulnerabilitiesClick(selection.length);
                        }}
                        onHandlePatch={({ ignoreDoNotProtect }) => {
                            void handlePatch(selection, ignoreDoNotProtect);
                        }}
                        handleLicenseTerminated={handleLicenseTerminated}
                        isLicenseTerminated={isLicenseTerminated}
                        onIgnore={handleIgnore}
                        onCancelIgnore={handleRemoveLabels}
                    />
                    {isFilterOpen && vertical && filters}
                </>
            }
            search={search}
            rowKey="vulnerabilityId"
            loadingRows={loadingRowsIds}
            selection={selection}
            onSelectionChange={setSelection}
            data={allVulnerabilities}
            loading={isLoading}
            isPreviousData={isPreviousData}
            filtered={filtered}
            pagination={pagination}
            totalRows={data.totalCount}
            columns={columns}
            emptyView={<Onboarding />}
            filteredEmptyView={<ListEmptyViewFilter />}
            renderSelectionCheckbox={renderSelectionCheckbox}
            data-type="vulnerabilities-list"
        />
    );

    return (
        <>
            {list}
            <UnprocessableInstallationsDialog {...unprocessableInstallationsDialogProps} />
        </>
    );
};

export default Vulnerabilities;
