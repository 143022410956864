// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { Brandings, brandingValues, isBranding } = makeEnum(
    'Branding',
    'platform',
    'monitoring',
    'security-dashboard',
    'get-plesk',
    'get-cpanel',
);

export type Branding = UnionOf<typeof brandingValues>;

export type DateTimeProps<T> = { [Key in keyof T]: Exclude<T[Key] | DateTime, string> };

export type WithDateTimeProps<T, Keys extends keyof T> = Omit<T, Keys> &
    DateTimeProps<Pick<T, Keys>>;
