// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addTeamInvitations, TeamInvitation } from '@platform360/accounts/web/api/team';
import useTeamInvitationsQuery from '@platform360/accounts/web/queries/useTeamInvitationsQuery';

type UseAddTeamInvitationsMutation = {
    onSuccess: (teamInvitations: TeamInvitation[]) => void;
};

const useAddTeamInvitationsMutation = ({ onSuccess }: UseAddTeamInvitationsMutation) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: addTeamInvitations,
        onSuccess: async (teamInvitations) => {
            await queryClient.cancelQueries({
                queryKey: useTeamInvitationsQuery.getQueryKey(),
            });
            useTeamInvitationsQuery.setQueriesData(
                queryClient,
                useTeamInvitationsQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return [];
                    }

                    return [...teamInvitations, ...cache];
                },
            );

            onSuccess(teamInvitations);
        },
    });
};

export default useAddTeamInvitationsMutation;
