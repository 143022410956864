// Copyright 2024. WebPros International GmbH. All rights reserved.

import Invitations from './Invitations';
import { useTeamInvitationsQuery } from '@platform360/accounts/web/queries';
import { useDeleteTeamInvitationMutation } from '@platform360/accounts/web/mutations';

export const InvitationsContainer = ({ ...props }) => {
    const { data, isLoading, isError } = useTeamInvitationsQuery();
    const { mutateAsync } = useDeleteTeamInvitationMutation();

    return (
        <Invitations
            invitations={(!isError && data) || []}
            isLoading={isLoading}
            onInvitationRevoke={mutateAsync}
            {...props}
        />
    );
};

export default InvitationsContainer;
