// Copyright 2024. WebPros International GmbH. All rights reserved.

const utmNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'] as const;

type UtmNames = (typeof utmNames)[number];
export type UtmProperties = {
    [key in UtmNames]?: string;
};

export const getUtmProperties = (searchParams: URLSearchParams): UtmProperties => {
    const utmProperties: UtmProperties = {};

    for (const name of utmNames) {
        const value = searchParams.get(name);
        if (value) {
            utmProperties[name] = value;
        }
    }

    return utmProperties;
};
