// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Button,
    Dropdown,
    Icon,
    Menu,
    MenuItem,
    Paragraph,
    ProgressBar,
    Text,
    Tooltip,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import VulnerabilityWidget from '@platform360/security-dashboard/web/components/Vulnerabilities/Widgets/VulnerabilityWidget/VulnerabilityWidget';
import styles from './PluginWidget.module.less';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';

export type PluginWidgetProps = {
    vulnerabilityId: string;
    installationsWithEnabledPluginCount: number;
    installationsWithPluginDisabledByMitigationCount: number;
    installationsCount: number;
    isPluginsMitigationStarting: boolean;
    pluginsMitigationInProgress: boolean;
    onDisablePlugins: () => void;
    onEnablePlugins: () => void;
};
const PluginWidget = ({
    vulnerabilityId,
    onDisablePlugins,
    onEnablePlugins,
    isPluginsMitigationStarting,
    pluginsMitigationInProgress,
    installationsWithEnabledPluginCount,
    installationsWithPluginDisabledByMitigationCount,
    installationsCount,
}: PluginWidgetProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Widgets.disablePlugin');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const onDisableConfirmationShown = () =>
        analyticsEvents.wpSglVulnerabilityDisablePluginsClick(
            vulnerabilityId,
            installationsWithEnabledPluginCount,
        );

    const onEnableConfirmationShown = () =>
        analyticsEvents.wpSglVulnerabilityEnablePluginsClick(
            vulnerabilityId,
            installationsWithPluginDisabledByMitigationCount,
        );

    const getContent = () => {
        if (pluginsMitigationInProgress) {
            return <ProgressBar className={styles.progressBar} />;
        }

        return (
            <>
                {getCountText()}
                {getButtonContainer()}
            </>
        );
    };

    const getCountText = () => {
        const tooltipDeactivatedByUserContainer = (
            <div className={styles.tooltip}>
                <Tooltip delay={1000} title={translate('tooltipAllPluginsDeactivatedByUser')}>
                    <Icon name="info-circle" size="12" />
                </Tooltip>
            </div>
        );

        if (installationsWithEnabledPluginCount === 0) {
            return (
                <div className={styles.enabledCountContainer}>
                    <Text intent="muted" fontSize="sm">
                        {translate('installationsDeactivated', {
                            count: installationsCount,
                        })}
                    </Text>
                    {installationsWithPluginDisabledByMitigationCount === 0 &&
                        tooltipDeactivatedByUserContainer}
                </div>
            );
        }

        const enabledPercent =
            installationsCount > 0
                ? (installationsWithEnabledPluginCount / installationsCount) * 100
                : 0;

        return (
            <div className={styles.enabledCountContainer}>
                <Text intent="muted" fontSize="sm">
                    {translate('installations', {
                        enabledCount: installationsWithEnabledPluginCount,
                    })}
                </Text>
                <ProgressBar progress={enabledPercent} className={styles.enabledProgressBar} />
            </div>
        );
    };

    const getButtonContainer = () => {
        // All deactivated by user, no button
        if (
            installationsWithEnabledPluginCount === 0 &&
            installationsWithPluginDisabledByMitigationCount === 0
        ) {
            return null;
        }

        return <div className={styles.buttonContainer}>{getButton()}</div>;
    };

    const getButton = () => {
        // Deactivate all
        if (
            installationsWithEnabledPluginCount === installationsCount &&
            installationsWithPluginDisabledByMitigationCount === 0
        ) {
            return (
                <ConfirmationPopover
                    target={
                        <Button
                            ghost
                            disabled={isPluginsMitigationStarting}
                            tooltip={translate('buttonDeactivateAll')}
                            fill
                        >
                            <span className={styles.buttonContent}>
                                {translate('buttonDeactivateAll')}
                            </span>
                        </Button>
                    }
                    onClick={onDisablePlugins}
                    onShow={onDisableConfirmationShown}
                    actionButtonText={translate('buttonDeactivateAll')}
                    confirmationButtonIntent="primary"
                >
                    <Paragraph>
                        {translate('confirmationDeactivation', {
                            count: installationsWithEnabledPluginCount,
                        })}
                    </Paragraph>
                </ConfirmationPopover>
            );
        }

        // Reactivate all
        if (
            installationsWithPluginDisabledByMitigationCount === installationsCount &&
            installationsWithEnabledPluginCount === 0
        ) {
            return (
                <ConfirmationPopover
                    target={
                        <Button
                            disabled={isPluginsMitigationStarting}
                            tooltip={translate('buttonReactivate')}
                            fill
                        >
                            <span className={styles.buttonContent}>
                                {translate('buttonReactivate')}
                            </span>
                        </Button>
                    }
                    onClick={onEnablePlugins}
                    onShow={onEnableConfirmationShown}
                    actionButtonText={translate('buttonReactivate')}
                    confirmationButtonIntent="primary"
                >
                    <Paragraph>
                        {translate('confirmationReactivation', {
                            count: installationsWithPluginDisabledByMitigationCount,
                        })}
                    </Paragraph>
                </ConfirmationPopover>
            );
        }

        // Deactivate active, not all deactivated, mitigated = 0
        if (
            installationsWithEnabledPluginCount > 0 &&
            installationsWithPluginDisabledByMitigationCount === 0
        ) {
            return (
                <ConfirmationPopover
                    target={
                        <Button
                            disabled={isPluginsMitigationStarting}
                            tooltip={translate('buttonDeactivateWithCount', {
                                count: installationsWithEnabledPluginCount,
                            })}
                            fill
                        >
                            <span className={styles.buttonContent}>
                                {translate('buttonDeactivateWithCount', {
                                    count: installationsWithEnabledPluginCount,
                                })}
                            </span>
                        </Button>
                    }
                    onClick={onDisablePlugins}
                    onShow={onDisableConfirmationShown}
                    actionButtonText={translate('buttonDeactivateWithCount', {
                        count: installationsWithEnabledPluginCount,
                    })}
                    confirmationButtonIntent="primary"
                >
                    <Paragraph>
                        {translate('confirmationDeactivation', {
                            count: installationsWithEnabledPluginCount,
                        })}
                    </Paragraph>
                </ConfirmationPopover>
            );
        }

        //  Reactivate only deactivated by mitigation, active = 0
        if (
            installationsWithPluginDisabledByMitigationCount > 0 &&
            installationsWithEnabledPluginCount === 0
        ) {
            return (
                <ConfirmationPopover
                    target={
                        <Button
                            disabled={isPluginsMitigationStarting}
                            tooltip={translate('buttonReactivateWithCount', {
                                count: installationsWithPluginDisabledByMitigationCount,
                            })}
                            fill
                        >
                            <span className={styles.buttonContent}>
                                {translate('buttonReactivateWithCount', {
                                    count: installationsWithPluginDisabledByMitigationCount,
                                })}
                            </span>
                        </Button>
                    }
                    onClick={onEnablePlugins}
                    onShow={onEnableConfirmationShown}
                    actionButtonText={translate('buttonReactivateWithCount', {
                        count: installationsWithPluginDisabledByMitigationCount,
                    })}
                    confirmationButtonIntent="primary"
                >
                    <Paragraph>
                        {translate('confirmationReactivation', {
                            count: installationsWithPluginDisabledByMitigationCount,
                        })}
                    </Paragraph>
                </ConfirmationPopover>
            );
        }

        const menu = (
            <Menu className={styles.menu}>
                <ConfirmationPopover
                    target={
                        <MenuItem disabled={isPluginsMitigationStarting}>
                            {translate('buttonDeactivateWithCount', {
                                count: installationsWithEnabledPluginCount,
                            })}
                        </MenuItem>
                    }
                    onClick={onDisablePlugins}
                    onShow={onDisableConfirmationShown}
                    actionButtonText={translate('buttonDeactivateWithCount', {
                        count: installationsWithEnabledPluginCount,
                    })}
                    confirmationButtonIntent="primary"
                >
                    <Paragraph>
                        {translate('confirmationDeactivation', {
                            count: installationsWithEnabledPluginCount,
                        })}
                    </Paragraph>
                </ConfirmationPopover>
                <ConfirmationPopover
                    target={
                        <MenuItem disabled={isPluginsMitigationStarting}>
                            {translate('buttonReactivateWithCount', {
                                count: installationsWithPluginDisabledByMitigationCount,
                            })}
                        </MenuItem>
                    }
                    onClick={onEnablePlugins}
                    onShow={onEnableConfirmationShown}
                    actionButtonText={translate('buttonReactivateWithCount', {
                        count: installationsWithPluginDisabledByMitigationCount,
                    })}
                    confirmationButtonIntent="primary"
                >
                    <Paragraph>
                        {translate('confirmationReactivation', {
                            count: installationsWithPluginDisabledByMitigationCount,
                        })}
                    </Paragraph>
                </ConfirmationPopover>
            </Menu>
        );

        return (
            <Dropdown menu={menu}>
                <Button caret fill>
                    {translate('dropdownDeactivate')}
                </Button>
            </Dropdown>
        );
    };

    return (
        <VulnerabilityWidget dataType="plugin-widget" title={translate('title')}>
            {getContent()}
        </VulnerabilityWidget>
    );
};

export default PluginWidget;
