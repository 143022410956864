// Copyright 2024. WebPros International GmbH. All rights reserved.

import { NavLink } from 'react-router-dom';
import { MenuHeader, MenuItem } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { BaseResultGroupProps } from '@platform360/server-inventory/web/components/GlobalSearch/types';
import { Domain } from '@platform360/server-inventory/web/types';

type DomainResultGroupProps = BaseResultGroupProps & {
    domains: Domain[];
};

const DomainResultGroup = ({
    onClick,
    domains,
    totalCount,
    searchQuery,
}: DomainResultGroupProps) => {
    const translate = useTranslate('Page.GlobalSearch.DomainResultGroup');
    const showMore = totalCount - domains.length;
    return (
        <>
            <MenuHeader>{translate('domains')}</MenuHeader>
            {domains.map((item) => (
                <MenuItem
                    key={item.id}
                    component={NavLink}
                    to={`/websites?${new URLSearchParams({ search: item.name }).toString()}`}
                    onClick={onClick}
                >
                    {item.name}
                </MenuItem>
            ))}
            {Boolean(showMore) && (
                <MenuItem
                    component={NavLink}
                    to={`/websites?${new URLSearchParams({ search: searchQuery }).toString()}`}
                    onClick={onClick}
                >
                    {translate('domainsShowMore', { showMore })}
                </MenuItem>
            )}
        </>
    );
};

export default DomainResultGroup;
