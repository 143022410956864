// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Правовая информация',
        eula: 'Лицензионное соглашение',
        pleskEula: 'Лицензионное соглашение Plesk',
        cPanelEula: 'Лицензионное соглашение cPanel',
        terms: 'Условия использования',
        privacyPolicy: 'Политика конфиденциальности',
        dpa: 'Соглашение на обработку данных',
        optOuts: 'Исключения',
        cookies: 'Сообщение о файлах cookie',
        gaHeader: 'Отказ от использования Google Analytics',
        gaOptOut: 'Нажмите здесь, чтобы отказаться от использования Google Analytics',
        cookieHeader: 'Изменение согласия на использование файлов cookie',
        cookieModify: 'Измените ваше согласие на использование файлов cookie',
        cookieWithdraw: 'Аннулировать согласие на использование файлов cookie',
        cookieSuccess: 'Согласие аннулировано успешно',
    },
    RedirectGateway: {
        title: 'Перенаправление {destination}',
        progress: 'Это может занять несколько секунд',
        genericError: 'Что-то пошло не так',
        serverDetails: {
            destinationTitle: 'на страницу сервера в Перечне серверов',
            errorTitle: 'При попытке открыть страницу сервера произошла ошибка',
            errorDescription: 'Сервер отсутствует в Перечне серверов',
            redirectDirectly: 'Продолжить добавление в Перечень серверов',
        },
        monitoringServerSetup: {
            destinationTitle: 'на страницу подтверждения',
            errorTitle: 'При попытке открыть страницу подтверждения мониторинга сервера произошла ошибка',
            errorDescription: 'Сервер отсутствует в Перечне серверов',
        },
    },
};