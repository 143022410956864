// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Errore sconosciuto: {message}.',
        newServer: 'Esiste già un server con lo stesso indirizzo.',
        domainName: 'Il nome di dominio non può essere risolto.',
        serverMisconfigured: 'Il server non è configurato correttamente e non può essere aggiunto.',
        pleskConfiguration: 'La versione attuale di Plesk non è supportata (precedente alla versione {pleskMinVersion}) o la configurazione non è stata eseguita correttamente.',
        unsupportedServerVersion: 'La versione installata di Plesk è obsoleta e non può essere aggiunta. {kbLink}',
        connection: 'La connessione di rete è stata interrotta.',
        connectionRefused: 'La connessione di rete è stata rifiutata. Controlla il dominio e la porta specificati.',
        timeout: 'La connessione di rete al server è scaduta.',
        apiKey: 'Il server remoto non ha autorizzato la connessione. Aggiungi nuovamente il server all\'Inventario Server.',
        httpsRequired: 'Il server non supporta HTTPS.',
        certHasExpired: 'Il certificato è scaduto. {kbLink}',
        errTlsCertAltnameInvalid: 'Il nome host o l\'indirizzo IP del server non corrisponde ai nomi alternativi del certificato. {kbLink}',
        depthZeroSelfSignedCert: 'È stato installato un certificato autofirmato. {kbLink}',
        selfSignedCertInChain: 'Nella catena dei certificati è presente un certificato autofirmato. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'Le impostazioni di notifica sono state aggiornate.',
        },
    },
    Layout: {
        title: 'Inventario server',
        serversTitle: 'Server',
        clientsTitle: 'Clienti',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Impostazioni di notifica',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Notifiche',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Quali notifiche inviare',
        notifyServerUnreachable: 'Server irraggiungibile',
        notifyServerUnsupported: 'Server non supportato',
    },
    AllDomains: {
        title: 'Siti web',
        name: 'Nome',
        hostingType: 'Tipologia di hosting',
        virtualHostingType: 'Sito web',
        forwardingHostingType: 'Inoltro',
        noneHostingType: 'Nessun hosting',
        mainDomainType: 'Dominio principale',
        subDomainType: 'Sottodominio',
        addonDomainType: 'Dominio aggiuntivo',
        parkedDomainType: 'Alias',
        hostingServer: 'Server di hosting',
        manage: 'Gestisci',
        manageLinkUnavailableHint: 'Per generare un collegamento diretto al pannello Plesk, è necessario Plesk RESTful API 1.4.0 o una versione successiva. Consulta la documentazione per maggiori dettagli.',
        open: 'Apri',
    },
    AllClients: {
        name: 'Nome del contatto',
        email: 'Indirizzo e-mail',
        login: 'Nome utente',
        type: 'Tipo',
        status: 'Stato',
        typeAdmin: 'Admin',
        typeReseller: 'Rivenditore',
        typeCustomer: 'Cliente',
        typeCustomerWithOwner: 'Cliente di {owner}',
        statusActive: 'Attivo',
        statusSuspended: 'Sospeso',
        hostingServer: 'Server di hosting',
        manage: 'Gestisci',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Sincronizzazione in corso',
            installationRunning: 'Installazione in corso',
            installationFailure: 'Errore d\'installazione',
            installationLicenseRequired: 'Licenza necessaria',
            active: 'Attiva, sicura',
            inactiveUnreachable: 'Inattivo, irraggiungibile',
            inactiveUnreachableTooltip: 'Impossibile raggiungere il server per almeno quattordici giorni',
            inactiveUnsupported: 'Inattivo, non supportato',
            inactiveUnsupportedTooltip: 'Il server sta eseguendo una versione di Plesk non supportata',
            insecure: 'Attiva, non sicura',
            outdated: 'Attivo, obsoleto',
            outdatedTooltip: '{version, select, _ {La versione di Plesk in uso è obsoleta.} other {La versione {version} di Plesk in uso è obsoleta.}} Ti consigliamo di aggiornare il server all\'ultima versione disponibile di Plesk Obsidian e di abilitare gli aggiornamenti automatici.',
            unsupportedVersion: 'Attivo, non supportato',
            unsupportedVersionTooltip: '{version, select, _ {La versione di Plesk in uso non è supportata} other {La versione {version} di Plesk in uso non è supportata}} e il tuo server verrà disattivato fra 14 giorni. Ti consigliamo di aggiornare il server all\'ultima versione disponibile di Plesk Obsidian e di abilitare gli aggiornamenti automatici.',
            certificateWarning: 'Avvertimento riguardante il certificato',
            certificateError: 'Errore del certificato',
            connectionBlocked: 'Connessione bloccata',
            connectionError: 'Errore di connessione',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'Registri di installazione non trovati.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {La versione di Plesk in uso è obsoleta.} other {La versione {version} di Plesk in uso è obsoleta.}}',
                        fixDescription: 'Ti consigliamo di aggiornare il server all\'ultima versione disponibile di Plesk Obsidian e di abilitare gli aggiornamenti automatici. Consulta le <link>Norme sul ciclo di vita di Plesk</link> per maggiori informazioni.',
                        goToUpdatesButton: 'Vai agli aggiornamenti di {product}',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {La versione di Plesk in uso non è supportata} other {La versione {version} di Plesk in uso non è supportata}} e verrà disattivata tra 14 giorni.',
                        fixDescription: 'Ti consigliamo di aggiornare il server all\'ultima versione disponibile di Plesk Obsidian e di abilitare gli aggiornamenti automatici. Consulta le <link>Norme sul ciclo di vita di Plesk</link> per maggiori informazioni.',
                        goToUpdatesButton: 'Vai agli aggiornamenti di {product}',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'Poiché non siamo riusciti a collegarci al server per 14 giorni, lo abbiamo contrassegnato come inattivo.',
                        lastCheckMessage: 'Ultimo controllo dello stato della connessione: {lastCheckConnection}',
                        fixDescription: 'Per ricominciare a utilizzarlo, fai clic su "Verifica nuovamente la connessione"',
                        recheckConnectionButton: 'Verifica nuovamente la connessione',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'Il server è contrassegnato come inattivo perché è in esecuzione su {version, select, _ {una versione di Plesk non supportata} other {una versione {version} di Plesk non supportata}}.',
                        fixDescription: 'Per attivare il server, aggiorna Plesk ed esegui la sincronizzazione del server.',
                        goToUpdatesButton: 'Vai agli aggiornamenti di {product}',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Aggiungi Server',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Aggiungi nuovo server',
                closeButton: 'Chiudi',
                serverInstallTitle: 'Installa {productType}',
                installNewProduct: 'Installa un nuovo prodotto su un server Linux',
                connectInstallation: 'Connetti un server a un prodotto installato',
                serverInstallDescription: 'Installa {productType} su un server fisico o virtuale e aggiungilo all\'Inventario server.',
                connectPleskTitle: 'Collega Plesk',
                connectPleskDescription: 'Aggiungi un server con Plesk installato all\'Inventario server.',
                connectCPanelTitle: 'Collega cPanel',
                connectCPanelDescription: 'Aggiungi un server con cPanel installato all\'Inventario server.',
            },
            CustomInstallationStep: {
                title: 'Installa automaticamente {productType} su un server Linux',
                backButton: 'Indietro',
                submitButton: 'Installa',
                successMessage: '{productType} verrà installato su <b>{host}</b>.',
            },
            AddServerWithRedirectStep: {
                title: 'Connetti un server Plesk',
                description: 'Dopo aver fatto clic su "Connetti", ti reindirizzeremo a Plesk. Dovrai accedervi con permessi di Amministratore.',
                proceed: 'Connetti',
                proceedAnyway: 'Collega comunque',
                nextStep: 'Fase successiva',
                back: 'Indietro',
                addressLabel: 'Nome host del server',
                addressDescription: 'URL e porta del server a cui connettersi. Ad esempio: "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'Connetti un server cPanel',
                close: 'Chiudi',
                back: 'Indietro',
                paragraph1: 'Scarica ed esegui il programma d\'installazione del plugin cPanel: copia e incolla il seguente frammento utilizzando i privilegi root.',
                paragraph2: 'Ti serve un <a>token API di Plesk 360</a>. Puoi generarne un altro o utilizzarne uno esistente.',
                paragraph3: 'Il server appena creato comparirà nell\'Inventario server.',
                copied: 'Copiato.',
            },
        },
    },
};