// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import { UserContext } from './components/UserProvider';

const useCurrentUser = () => {
    const currentUser = useContext(UserContext);

    if (currentUser === undefined) {
        throw new Error('useCurrentUser must be used within a `UserProvider`');
    }

    return currentUser;
};

export default useCurrentUser;
