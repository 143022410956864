// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Img from '@platform360/licenses/web/components/Img';
import {
    ContentLoader,
    Heading,
    Section,
    StatusMessage,
    Toolbar,
    ToolbarExpander,
    ToastProps,
    Drawer,
    Button,
    Text,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import PeriodControl from '@platform360/licenses/web/units/extensions/containers/buyExtension/PeriodControl';
import ProductsList from '@platform360/licenses/web/units/extensions/containers/buyExtension/ProductsList';
import SubscriptionSelector from '@platform360/licenses/web/units/extensions/containers/buyExtension/SubscriptionSelector';
import CardItem from '@platform360/licenses/web/units/extensions/containers/buyExtension/CardItem';
import GrossVat from '@platform360/licenses/web/units/extensions/containers/buyExtension/GrossVat';
import AdditionalInformation from '@platform360/licenses/web/units/extensions/containers/buyExtension/AdditionalInformation';
import Message from '@platform360/licenses/web/WizardCommon/Message';
import { licensesUri } from '@platform360/licenses/web/helpers';
import { BuyProcessing } from '@platform360/licenses/web/units/extensions/reducer/buyExtension/initialState';
import {
    isFinished,
    isStarted,
} from '@platform360/licenses/web/units/extensions/helpers/processing';
import {
    ConfirmSubscriptionInfo as CbConfirmSubscriptionInfo,
    Footer as CbFooter,
    PaymentInfo as CbPaymentInfo,
} from '@platform360/licenses/web/units/cleverbridge';
import CountableForm from '@platform360/licenses/web/units/extensions/containers/buyExtension/CountableForm';
import { MerchantOfRecordType } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import { AG } from '@platform360/licenses/web/units/cleverbridge/constants/merchantOfRecordType';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import ConfirmPopoverButton from '@platform360/licenses/web/components/ConfirmPopoverButton';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

type ForDrawer = {
    open: boolean;
    processing: boolean;
    hasSubscriptions: boolean;
    canSubmit: boolean;
    title: string;
};

export type Notify = {
    licenseName: string;
    productName: string;
    keyNumber: string;
};

type BuyExtensionProps = {
    initialized: boolean;
    isFetching: boolean;

    isPaygr: boolean;
    catalogCode: string;

    showBillingPeriod: boolean;

    hasSubscriptions: boolean;
    hasOnlineStoreData: boolean;

    initialization: () => void;
    finalization: () => void;

    merchantOfRecordType?: MerchantOfRecordType;

    processing: BuyProcessing;
    notify: Notify;
    addSuccess: (toast: ToastProps) => void;
    refreshPage: () => void;
    forDrawer: ForDrawer;
    onItemFlush: () => void;
    goToOnlineStore: (email: string) => void;
    onStartBuyProcessing: () => void;
};

const BuyExtension = ({
    initialized,
    catalogCode,
    isPaygr,

    showBillingPeriod,

    initialization,
    finalization,

    hasSubscriptions,
    isFetching,

    hasOnlineStoreData,
    merchantOfRecordType = AG,

    processing,
    notify,

    addSuccess,
    refreshPage,
    forDrawer,
    onItemFlush,
    goToOnlineStore,
    onStartBuyProcessing,
}: BuyExtensionProps) => {
    const translate = useTranslate();
    const analyticsEvents = useLicensesAnalyticsEvents();
    const [redirect, setRedirect] = useState(false);
    const [message, setMessage] = useState({ content: 'keyAddedMessage', params: {} });
    const { email } = useCurrentUser();

    useEffect(() => {
        // saving the intermediate state of the one selected upon purchase
        if (isStarted(processing)) {
            setMessage({
                ...message,
                params: {
                    parentId: notify.keyNumber,
                    addon: notify.productName,
                    parent: notify.licenseName,
                },
                // @ts-expect-error TS2345: Argument of type
                onRefresh: () => refreshPage(),
            });
        }

        // finished process with using saved state
        if (isFinished(processing)) {
            addSuccess({ message: <Message {...message} />, autoCloseTimeout: 20000 });
            setRedirect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processing]);

    useEffectOnce(() => {
        initialization();
        return finalization;
    });

    if (redirect) {
        return <Navigate to={licensesUri} />;
    }

    return (
        <Drawer
            isOpen={forDrawer.open}
            hideButton={false}
            size={'sm'}
            onClose={onItemFlush}
            title={forDrawer.title}
            subtitle={translate('upgradeSuggestion')}
            data-type={'upsell'}
            progress={
                forDrawer.processing
                    ? {
                          title: translate('planUpdate'),
                          steps: [
                              {
                                  title: translate('waitAMoment'),
                                  status: 'running',
                              },
                          ],
                      }
                    : null
            }
            form={{
                key: 'drawer',
                applyButton: false,
                hideRequiredLegend: true,
                submitButton: false,
                additionalButtons: [
                    forDrawer.hasSubscriptions ? (
                        <ConfirmPopoverButton
                            key={'confirmPopoverButton'}
                            onSubmit={() => {
                                analyticsEvents.licensesAddExtensionDialogConfirmClicked();
                                onStartBuyProcessing();
                            }}
                            disabled={!forDrawer.canSubmit}
                        />
                    ) : (
                        // this button is used for two actions at the same time:
                        // 1. redirect to online-store by default
                        // 2. redirect to the main page
                        <Button
                            component={Link}
                            to={licensesUri}
                            data-type={'Upsell--toOnlineStore'}
                            key={'to-online-store'}
                            intent="primary"
                            onClick={() => {
                                analyticsEvents.licensesAddExtensionDialogGoToOnlineStoreClicked();
                                goToOnlineStore(email);
                            }}
                        >
                            {translate('toOnlineStoreButton')}
                        </Button>
                    ),
                ],
            }}
        >
            <Toolbar>
                <Img code={catalogCode} />
                <ToolbarExpander />
                {showBillingPeriod && <PeriodControl />}
            </Toolbar>
            {(isFetching || !initialized) && <ContentLoader />}
            {!isFetching && initialized && (
                <>
                    {isPaygr ? <CountableForm /> : <ProductsList />}
                    {Boolean(hasOnlineStoreData) && <GrossVat />}
                    {hasSubscriptions ? (
                        <SubscriptionSelector />
                    ) : (
                        <StatusMessage data-type="Upsell--noSubscriptionAlert" intent="info">
                            {translate('subscriptionForBillingPeriodNotFound')}
                        </StatusMessage>
                    )}
                    {Boolean(hasOnlineStoreData) && (
                        <>
                            <AdditionalInformation />
                            <Section>
                                <Heading level={4}>{translate('Keys.headers.payment')}</Heading>
                                <CbPaymentInfo merchantOfRecordType={merchantOfRecordType} />
                            </Section>
                            <Section>
                                <CardItem />
                            </Section>
                            <Section>
                                <Heading level={4}>
                                    <Text intent="muted">
                                        {translate('Keys.headers.confirmSubscription')}
                                    </Text>
                                </Heading>
                                <CbConfirmSubscriptionInfo />
                            </Section>
                            <CbFooter merchantOfRecordType={merchantOfRecordType} />
                        </>
                    )}
                </>
            )}
        </Drawer>
    );
};

export default BuyExtension;
