// Copyright 2024. WebPros International GmbH. All rights reserved.

import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Button, ButtonProps, Heading } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './QuickStartStep.module.less';

export type QuickStartStepProps = {
    onSubmit?: () => void;
    onBackClick: () => void;
    onClose: () => void;
    sidebar: ReactNode;
    stepsCount: number;
    activeStepIndex: number;
    heading: ReactNode;
};

type QuickStartBaseStepProps = {
    subheading?: ReactNode;
    ignoreContentIndents?: boolean;
    closeButtonProps?: ButtonProps;
    submitButton?: ReactNode;
    submitButtonProps?: ButtonProps;
} & PropsWithChildren<QuickStartStepProps>;

export type QuickStartStepCommonProps = Omit<QuickStartBaseStepProps, 'children' | 'title'>;

export const QuickStartStep = ({
    ignoreContentIndents,
    closeButtonProps = {},
    sidebar,
    children,
    heading,
    subheading,
    onClose,
    stepsCount,
    activeStepIndex,
    onSubmit,
    onBackClick,
    submitButton,
    submitButtonProps = {},
}: QuickStartBaseStepProps) => {
    const isLastStep = activeStepIndex === stepsCount - 1;

    const translate = useTranslate('security-dashboard.QuickStartDialog.QuickStartStep');

    return (
        <div className={styles.container}>
            <div className={styles.stepsContainer}>
                {sidebar}
                <div
                    className={classNames(styles.content, {
                        [styles.contentIndented ?? '']: !ignoreContentIndents,
                    })}
                >
                    {heading && (
                        <div className={styles.contentHeader}>
                            <Heading level={3} className={styles.heading}>
                                {heading}
                            </Heading>
                            {subheading}
                        </div>
                    )}
                    {children}
                </div>
            </div>
            <div className={styles.footer}>
                <span>
                    {translate('pagination', {
                        current: activeStepIndex + 1,
                        total: stepsCount,
                    })}
                </span>
                <div className={styles.buttons}>
                    {activeStepIndex > 0 && (
                        <Button ghost className={styles.backButton} onClick={onBackClick}>
                            {translate('back')}
                        </Button>
                    )}
                    {submitButton ?? (
                        <Button
                            onClick={onSubmit}
                            intent="primary"
                            arrow={isLastStep ? undefined : 'backward'}
                            {...submitButtonProps}
                        >
                            {isLastStep ? translate('ok') : translate('next')}
                        </Button>
                    )}
                </div>
            </div>
            <Button
                onClick={onClose}
                className={styles.closeButton}
                ghost
                icon="cross-mark"
                {...closeButtonProps}
                data-type={'quick-start-close'}
            />
        </div>
    );
};
