// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon } from '@plesk/ui-library';
import pleskLogo from './pleskLogo.svg';
import cpanelLogo from './cpanelLogo.svg';
import wpLogo from './wordpressLogo.svg';
import styles from './ProductIcon.module.css';
import { ProductType } from '@platform360/server-inventory/shared/product-type';

const getLogo = (product: ProductType): string => {
    switch (product) {
        case 'plesk':
            return pleskLogo;
        case 'cpanel':
            return cpanelLogo;
        case 'wp':
            return wpLogo;
        default:
            return pleskLogo;
    }
};

const getLogoSize = (product: ProductType): string | undefined => {
    switch (product) {
        case 'plesk':
            return styles.pleskSize;
        case 'cpanel':
            return styles.cpanelSize;
        case 'wp':
            return styles.wpSize;
        default:
            return styles.pleskSize;
    }
};

export type ProductIconProps = {
    product: ProductType;
};

const ProductIcon = ({ product }: ProductIconProps) => (
    <Icon
        data-type="view-server-product"
        className={getLogoSize(product)}
        src={getLogo(product)}
        alt={product}
    />
);

export default ProductIcon;
