// Copyright 2024. WebPros International GmbH. All rights reserved.

import WidgetsList from '@platform360/home-page/web/components/WidgetsList';
import Carousel from '@platform360/home-page/web/components/Carousel';
import Page from '@platform360/libs/shared-web/components/Page';
import { Heading } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './PlatformHomePage.module.css';

const PlatformHomePage = () => {
    const translate = useTranslate('home-page.PlatformHomePage');

    return (
        <Page>
            <Carousel />
            <Heading className={styles.title} level={2}>
                {translate('title')}
            </Heading>
            <WidgetsList />
        </Page>
    );
};

export default PlatformHomePage;
