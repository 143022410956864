// Copyright 2024. WebPros International GmbH. All rights reserved.

import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import { Dialog, Heading, Label, Paragraph, Text } from '@plesk/ui-library';
import Banner from './assets/not-managed-sites.png';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './UnprocessableInstallationsDialog.module.less';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { LicenseTerminatedDialog } from './LicenseTerminatedDialog';
import { CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';

export type UnprocessableInstallationsDialogData = {
    notOperable: number;
    operableNotManaged: number;
};

export type UnprocessableInstallationsDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    data: UnprocessableInstallationsDialogData | null;
};

export const UnprocessableInstallationsDialog = ({
    isOpen,
    onClose,
    data: siteCounters,
}: UnprocessableInstallationsDialogProps) => {
    const navigate = useNavigate();
    const { data } = useLicenseQuery();

    const countersRef = useRef(siteCounters);
    if (siteCounters && siteCounters !== countersRef.current) countersRef.current = siteCounters;
    const counters = countersRef.current;

    const translate = useTranslate('security-dashboard.UnprocessableInstallationsDialog');

    const handleLicenseChoose = () => {
        navigate('/security-dashboard/license-page');
        onClose();
    };

    if (data?.status === 'terminated') {
        return (
            <LicenseTerminatedDialog
                isOpen={isOpen}
                onClose={onClose}
                onLicenseChoose={handleLicenseChoose}
                customerType={data.customerType}
            />
        );
    }

    const getMessageKeys = (): [string, string] => {
        if (counters?.notOperable && counters.operableNotManaged) {
            return ['notOperableNotManagedSitesTitle', 'notOperableNotManagedSitesMessage'];
        }
        return ['operableNotManagedSitesTitle', 'operableNotManagedSitesMessage'];
    };

    const [titleKey, messageKey] = getMessageKeys();

    return (
        <Dialog
            isOpen={isOpen}
            cancelButton={false}
            onClose={onClose}
            banner={<img src={Banner} alt="" />}
            title={<span />}
            form={
                data?.customerType === CustomerTypes.vps
                    ? undefined
                    : {
                          onSubmit: handleLicenseChoose,
                          applyButton: false,
                          submitButton: { children: translate('chooseLicenseButton') },
                      }
            }
        >
            <Heading level={2}>
                {translate(titleKey, {
                    notOperableCount: counters?.notOperable,
                    unmanagedCount: counters?.operableNotManaged,
                })}
            </Heading>
            {!!data?.sitesLimitHard && (
                <Label icon="exclamation-mark-circle-filled" size="lg" view="light" intent="danger">
                    {translate('notManagedSitesLicenseMessage', { count: data.sitesLimitHard })}
                </Label>
            )}
            <Paragraph className={styles.DialogParagraph}>{translate(messageKey)}</Paragraph>
            <Paragraph>{translate('managedSitesWillBePerformed')}</Paragraph>
            {data?.customerType === CustomerTypes.vps && (
                <Text intent="muted">{translate('contactResellerToUpgrade')}</Text>
            )}
        </Dialog>
    );
};
