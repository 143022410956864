// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import QuickStartStep, { QuickStartStepCommonProps } from '../QuickStartStep';
import { Section } from '../Section';

type VirtualPatchesStepProps = QuickStartStepCommonProps;

export const VirtualPatchesStep = (props: VirtualPatchesStepProps) => {
    const translate = useTranslate('security-dashboard.QuickStartDialog.VirtualPatchesStep');

    return (
        <QuickStartStep
            {...props}
            subheading={
                <Text fontSize="sm" intent="muted">
                    {translate('poweredBy', { providerLogo: <Patchstack /> })}
                </Text>
            }
        >
            <Section title={translate('automatedProtection')} icon="shield">
                {translate('automatedProtectionText')}
            </Section>
            <Section title={translate('howItWorks')} icon="question-mark-circle">
                {translate('howItWorksText')}
            </Section>
        </QuickStartStep>
    );
};
