// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { useQuery } from '@tanstack/react-query';
import { getLicense } from '@platform360/security-dashboard/web/api/license';

export type UseLicenseQueryVariables = undefined;

export type UseLicenseQueryData = PromiseValue<ReturnType<typeof getLicense>>;

const useLicenseQuery = createQuery<UseLicenseQueryVariables, UseLicenseQueryData>({
    queryName: 'security-dashboard/useLicenseQuery',
    fetcher: getLicense,
    useQuery,
});

export default useLicenseQuery;
