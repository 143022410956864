// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AxiosRequestConfig } from 'axios';

const getAll = (active: boolean, uid: string | null = null): AxiosRequestConfig => {
    const search = new URLSearchParams();
    search.append('activeOnly', active.toString());

    if (uid) {
        search.append('uid', uid.toString());
    }

    return { url: `/ka/keys?${search.toString()}` };
};

export default getAll;
