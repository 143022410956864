// Copyright 2024. WebPros International GmbH. All rights reserved.

import { StatusMessage, Markdown } from '@plesk/ui-library';
import styles from './TopMessages.module.css';
import { Messages } from '@platform360/libs/shared-web/user/user-state';

export type TopMessagesProps = {
    messages?: Messages;
};

const TopMessages = ({ messages = {} }: TopMessagesProps) => {
    const keys = Object.keys(messages);

    if (keys.length === 0) {
        return null;
    }

    return (
        <>
            {Object.entries(messages).map(([key, sourceMessage]) => {
                const message =
                    typeof sourceMessage === 'string' ? { text: sourceMessage } : sourceMessage;

                return (
                    <StatusMessage
                        key={key}
                        intent={message.intent ?? 'warning'}
                        className={styles.statusMessage}
                        data-type="top-messages-message"
                    >
                        <Markdown>{message.text}</Markdown>
                    </StatusMessage>
                );
            })}
        </>
    );
};

export default TopMessages;
