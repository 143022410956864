// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'Такой почтовый адрес уже зарегистирован.',
        linkEmailMinimalInterval: 'Сообщения о действиях для связанных адресов электронной почты нельзя отправлять слишком часто.',
        linkEmailUnknownError: 'Произошла ошибка. Проверьте подключение к Интернету или повторите попытку позже.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Подтверждение отмены активации',
                description: 'Вы собираетесь отменить активацию лицензии {product}. Вы можете продолжить активацию лицензии в любое время позже, повторно перейдя по ссылке активации. Если лицензия приобретена для другой учетной записи, войдите в учетную запись и щелкните по ссылке активации.',
                cancelButton: 'Отмена',
                continueButton: 'Продолжить активацию',
            },
            MonitoringActivateLicense: {
                title: 'Подтверждение активации лицензии Monitoring',
                description: 'Пожалуйста, подтвердите активацию лицензии <b>{product}</b> для вашей учетной записи 360 <mailto>{userEmail}</mailto>. Лицензию можно активировать только для одной учетной записи 360.',
                fixableErrorDescription: 'Во время активации учетной записи Monitoring что-то пошло не так. Пожалуйста, попробуйте еще раз или отправьте запрос в службу поддержки.',
                nonFixableErrorDescription: 'Во время активации лицензии что-то пошло не так. Пожалуйста, свяжитесь со своим поставщиком лицензий или отправьте запрос в службу поддержки.',
                loaderText: 'Ваша учетная запись 360 Monitoring настраивается',
                redirectText: 'Перенаправляем вас на 360 Monitoring',
                confirmButton: 'Подтвердить',
                cancelButton: 'Отмена',
                retryButton: 'Повторить попытку',
                gotItButton: 'Понятно',
                errors: {
                    linkError: 'Ссылка активации недействительна.',
                    unknownError: 'Внутренняя ошибка службы активации.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'Ошибка активации лицензии',
            description: 'Ссылка активации неверна. Обратитесь к поставщику лицензии или отправьте запрос в службу поддержки.',
            gotIt: 'Понятно',
        },
    },
};