// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Popover, Section, SectionItem, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { BuyInfo } from '@platform360/licenses/web/units/cleverbridge';

export type ConfirmButtonProps = {
    onSubmit: () => void;
    disabled?: boolean;
};

const ConfirmButton = ({ onSubmit, disabled = false }: ConfirmButtonProps) => {
    const translate = useTranslate();

    return (
        <Popover
            key="popover"
            placement="top"
            target={
                <Button data-type="Renew--confirm" intent="primary" size="lg" disabled={disabled}>
                    {translate('Keys.general.confirm')}
                </Button>
            }
        >
            <Section key={'description'}>
                <SectionItem>
                    <Text fontSize={'sm'}>
                        <BuyInfo />
                    </Text>
                </SectionItem>
            </Section>
            <Section key={'button'}>
                <SectionItem>
                    <Button
                        data-type={'Renew--confirmRenew'}
                        intent={'success'}
                        onClick={onSubmit}
                        size={'lg'}
                    >
                        {translate('Keys.Renew.form.submitButton')}
                    </Button>
                </SectionItem>
            </Section>
        </Popover>
    );
};

export default ConfirmButton;
