// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { DateTime, Interval } from 'luxon';
const SCRIPT_ID = 'retently-jssdk';

type RetenlyWidgetProps = {
    trackingId: string;
    campaignId: string;
    email: string;
    userId: string;
    userCreationDate: Date;
    tags: string;
};

const RetentlyWidget = ({
    campaignId,
    email,
    userId,
    userCreationDate,
    tags,
    trackingId,
}: RetenlyWidgetProps) => {
    const userCreationDateTime = DateTime.fromJSDate(userCreationDate);
    const interval = Interval.fromDateTimes(userCreationDateTime, DateTime.now());

    const initWidget = () => {
        // Check if the script is already loaded
        if (document.getElementById(SCRIPT_ID)) {
            return;
        }

        const script = document.createElement('script');

        script.defer = true;
        script.id = SCRIPT_ID;
        script.src = 'https://cdn.retently.com/public/components/embed/sdk.min.js';

        document.body.appendChild(script);
    };
    useEffectOnce(() => {
        initWidget();
    });

    return (
        <div
            id="retently-survey-embed"
            data-href={`https://app.retently.com/api/remote/tracking/${trackingId}`}
            data-rel="dialog"
            data-campaignId={campaignId}
            data-email={email}
            data-prop-user-id={userId}
            data-prop-days-since-creation={Math.floor(interval.length('days'))}
            data-tags={tags}
        />
    );
};

export default RetentlyWidget;
