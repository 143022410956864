// Copyright 2024. WebPros International GmbH. All rights reserved.

import AddInvitations from './AddInvitations';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useAddTeamInvitationsMutation } from '@platform360/accounts/web/mutations';
import withDpaAccepted from '@platform360/accounts/web/helpers/withDpaAccepted';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';

export type AddInvitationsContainerProps = {
    onClose: () => void;
};

export const AddInvitationsContainer = ({ onClose, ...props }: AddInvitationsContainerProps) => {
    const { successToast } = useToaster();
    const translate = useTranslate('Team.Invitations.AddInvitations');
    const {
        mutate: handleSubmit,
        isPending: isLoading,
        error,
    } = useAddTeamInvitationsMutation({
        onSuccess: (teamInvitations) => {
            const [teamInvitation] = teamInvitations;

            if (!teamInvitation) {
                return;
            }

            successToast(
                translate('successMessage', {
                    email: teamInvitation.userEmail,
                    count: teamInvitations.length,
                }),
                { autoCloseTimeout: 3000 },
            );
            onClose();
        },
    });

    return (
        <AddInvitations
            errors={getValidationErrors(error) ?? []}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={onClose}
            {...props}
        />
    );
};

export default withDpaAccepted(AddInvitationsContainer);
