// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { ButtonProps, ContentLoader, setIn } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useLicenseIsActive } from '@platform360/security-dashboard/web/hooks/useLicenseIsActive';
import { useSettingsQuery } from '@platform360/security-dashboard/web/queries';
import { useSaveSettingsMutation } from '@platform360/security-dashboard/web/mutations';
import DailyTaskTimeSection from '@platform360/security-dashboard/web/components/DailyTaskTimeSection';
import { SettingsResponse } from '@platform360/security-dashboard/web/api/settings';
import QuickStartStep, { QuickStartStepCommonProps } from '../QuickStartStep';
import { Section } from '../Section';
import styles from './SecurityCheckScheduleStep.module.less';

type SecurityCheckScheduleStepProps = QuickStartStepCommonProps;

type FormValues = Pick<SettingsResponse, 'dailyTask'>;
type PartialFormValues = {
    dailyTask: Partial<FormValues['dailyTask']>;
};

const defaultValues: PartialFormValues = { dailyTask: {} };

const defaultSettings: Pick<SettingsResponse, 'dailyTask'> = {
    dailyTask: {
        taskStartTimeHours: null,
        taskStartTimeMinutes: null,
    },
};

export const SecurityCheckScheduleStep = ({
    onSubmit,
    ...props
}: SecurityCheckScheduleStepProps) => {
    const translate = useTranslate('security-dashboard.QuickStartDialog.SecurityCheckScheduleStep');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const isLicenseActive = useLicenseIsActive();
    const isFormShown = !!isLicenseActive;

    const { data: settings = defaultSettings, isLoading } = useSettingsQuery();

    const [formValues, setFormValues] = useState<PartialFormValues>(defaultValues);

    const values = {
        dailyTask: {
            ...settings.dailyTask,
            ...formValues.dailyTask,
        },
    };

    const handleFieldChange = (key: string, value: FormValues[keyof FormValues]) =>
        setFormValues(setIn(formValues, key, value));

    const { mutate, isPending: isSaving } = useSaveSettingsMutation();

    const handleSave = () => {
        const isDirty = !isEqual(settings.dailyTask, values.dailyTask);

        if (!isDirty) {
            onSubmit?.();
            return;
        }

        mutate(values, {
            onSuccess: () => {
                onSubmit?.();
            },
        });

        const { taskStartTimeHours, taskStartTimeMinutes } = values.dailyTask;
        const isTimeSet = taskStartTimeHours !== null && taskStartTimeMinutes !== null;
        analyticsEvents.wpQuickStartDailyTaskTimeSettingsSave(isTimeSet);
    };

    const submitButtonProps: ButtonProps = {
        state: isSaving ? 'loading' : undefined,
        onClick: () => (isFormShown ? handleSave() : onSubmit?.()),
    };

    const renderDailyTask = () => {
        if (!isFormShown) return null;

        if (isLoading) {
            return <ContentLoader className={styles.formSection} />;
        }

        return (
            <DailyTaskTimeSection
                value={values.dailyTask}
                initialValue={settings.dailyTask}
                onChange={(dailyTask) => {
                    if (dailyTask) {
                        handleFieldChange('dailyTask', dailyTask);
                    }
                }}
                isSaving={isSaving}
                isCompact
                className={styles.formSection}
            />
        );
    };

    return (
        <QuickStartStep submitButtonProps={submitButtonProps} {...props}>
            <Section icon="clock" title={translate('checkingTimeTitle')}>
                {translate('checkingTimeText')}
            </Section>
            {renderDailyTask()}
            <Section icon="security-check" title={translate('vulnerabilitiesUpdateTitle')}>
                {translate('vulnerabilitiesUpdateText')}
            </Section>
        </QuickStartStep>
    );
};
