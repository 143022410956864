// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import ApiTokens, { MAX_TOKEN_AMOUNT } from './ApiTokens';
import AddTokenDialog from './AddTokenDialog';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import {
    useCreateApiTokenMutation,
    useDeleteApiTokenMutation,
} from '@platform360/accounts/web/mutations';
import { Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useApiTokensQuery } from '@platform360/accounts/web/queries';

const ApiTokensContainer = () => {
    const translate = useTranslate('accounts.ApiTokens');
    const { successToast } = useToaster();
    const [showAddTokenDialog, setShowAddTokenDialog] = useState(false);
    const { data, isLoading } = useApiTokensQuery();

    const {
        mutate: handleCreateToken,
        errors,
        data: newToken,
        isPending: isTokenAdding,
        reset,
    } = useCreateApiTokenMutation({
        onSuccess: (token) => {
            successToast(
                translate('tokenAddingSuccess', {
                    info: (chunk) => <Text intent="info">{chunk}</Text>,
                    name: token.name,
                }),
            );
        },
    });

    const { mutate: handleTokenDelete, deletingTokenIds } = useDeleteApiTokenMutation({
        onSuccess: () => {
            successToast(translate('successTokenDelete'));
        },
    });

    const handleCloseDialog = () => {
        setShowAddTokenDialog(false);
        reset();
    };

    const handleOpenAddDialog = () => setShowAddTokenDialog(true);

    return (
        <>
            {showAddTokenDialog && (
                <AddTokenDialog
                    isLoading={isTokenAdding}
                    errors={errors}
                    token={newToken?.token}
                    onClose={handleCloseDialog}
                    onSubmit={handleCreateToken}
                />
            )}
            <ApiTokens
                deletingTokenIds={deletingTokenIds}
                isLimitExceeded={Number(data?.length) >= MAX_TOKEN_AMOUNT}
                isLoading={isLoading}
                data={data ?? []}
                handleOpenAddDialog={handleOpenAddDialog}
                handleTokenDelete={handleTokenDelete}
            />
        </>
    );
};

export default ApiTokensContainer;
