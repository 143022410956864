// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { concatUrl } from '@platform360/libs/common/concat-url';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useServerInventoryErrorsTranslate } from '@platform360/monitoring/web/helpers/useServerInventoryErrorsTranslate';

export type CertificateWarningStatusMessageProps = {
    errorCode?: string;
};

const CertificateWarningStatusMessage = ({
    errorCode,
    ...props
}: CertificateWarningStatusMessageProps) => {
    const siErrorsTranslate = useServerInventoryErrorsTranslate();
    const translate = useTranslate('ViewServer');
    const { docs } = useConfig();

    return (
        <StatusMessage intent="warning" data-type="certificate-warning-status-message" {...props}>
            <Paragraph>
                {translate('allowedInvalidCertificateWarning')}{' '}
                {errorCode ? siErrorsTranslate(errorCode, '') : null}
            </Paragraph>
            <Paragraph>
                {translate('allowedInvalidCertificateRisksNote')}{' '}
                <a
                    href={concatUrl('/scp/knowledge-base/certificate-issues.html', docs.baseUrl)}
                    target="_blank"
                    rel="noreferrer"
                >
                    {translate('allowedInvalidCertificateLinkToDocs')}
                </a>
            </Paragraph>
        </StatusMessage>
    );
};

export default CertificateWarningStatusMessage;
