// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ListEmptyView, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import collectingDataImage from './images/collecting-data.svg';

type PreparingServerProps = {
    monitoringServerLink: string;
    isRefreshing: boolean;
    onRefresh: () => void;
};

const PreparingServer = ({
    monitoringServerLink,
    isRefreshing,
    onRefresh,
}: PreparingServerProps) => {
    const translate = useTranslate('monitoring.ServerViewMonitoring');

    return (
        <ListEmptyView
            image={collectingDataImage}
            title={translate('preparingServerTitle')}
            description={
                <>
                    <Paragraph>{translate('preparingServerDescription1')}</Paragraph>
                    <Paragraph>{translate('preparingServerDescription2')}</Paragraph>
                </>
            }
            actions={
                <>
                    <Button
                        intent="primary"
                        data-type="refresh-btn"
                        onClick={onRefresh}
                        state={isRefreshing ? 'loading' : undefined}
                    >
                        {translate('refreshButton')}
                    </Button>
                    <Button
                        component="a"
                        rel="noopener noreferrer"
                        href={monitoringServerLink}
                        data-type="view-in-monitoring-link"
                    >
                        {translate('viewInMonitoring')}
                    </Button>
                </>
            }
            data-type="preparing-server-view"
        />
    );
};

export default PreparingServer;
