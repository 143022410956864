// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { Navigate } from 'react-router-dom';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { ContentLoader } from '@plesk/ui-library';
import { Brandings } from '@platform360/libs/shared-web/types';
import { getCookie } from '@platform360/libs/shared-web/cookies';
import { digitalOceanUserEmailCookie } from '@platform360/security-dashboard/shared/cookies';

export const DigitalOceanLogin = () => {
    const { login } = useAuth();
    const email = getCookie(digitalOceanUserEmailCookie);

    useEffectOnce(() => {
        if (!email) {
            return;
        }

        void login({
            email,
            branding: Brandings.securityDashboard,
            passwordless: true,
            redirectUrl: '/security-dashboard/servers',
        });
    });

    if (!email) {
        return <Navigate to={'/auth/login'} />;
    }

    return <ContentLoader />;
};
