// Copyright 2024. WebPros International GmbH. All rights reserved.

import GlobalTaskManagerDrawer from '@platform360/security-dashboard/web/components/GlobalTaskManagerDrawer';
import QuickStartDialog from '@platform360/security-dashboard/web/components/QuickStartDialog';
import Notifications from '@platform360/security-dashboard/web/components/Notifications';
import OperationsButton from '@platform360/security-dashboard/web/components/Layout/HeaderAddon/OperationsButton/OperationsButton';
import styles from './HeaderAddon.module.css';
import { useMediaQuery } from 'usehooks-ts';
import NotificationsSettingsDrawer from '@platform360/security-dashboard/web/components/NotificationsSettingsDrawer';
import useModal from '@platform360/libs/shared-web/helpers/useModal';
import FeedbackButton from './FeedbackButton';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';

const HeaderAddon = () => {
    const isFeedbackButtonShown = !useMediaQuery('(max-width: 1150px)');
    const [taskManagerDrawerOpen, setTaskManagerDrawerOpen] = useModal('task-manager');
    const { data: licenseExist } = useLicenseQuery();
    const [notificationsSettingsDrawerOpen, setNotificationsSettingsDrawerOpen] =
        useModal('notifications-settings');

    if (!licenseExist) {
        return null;
    }

    return (
        <div className={styles.root}>
            <GlobalTaskManagerDrawer
                isOpen={taskManagerDrawerOpen}
                onClose={() => setTaskManagerDrawerOpen(false)}
            />
            <NotificationsSettingsDrawer
                isOpen={notificationsSettingsDrawerOpen}
                onClose={() => setNotificationsSettingsDrawerOpen(false)}
            />
            <OperationsButton />
            <Notifications />
            <span className={styles.delimiter} />
            {isFeedbackButtonShown && <FeedbackButton />}

            <QuickStartDialog />
        </div>
    );
};

export default HeaderAddon;
