// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getDpa } from '@platform360/web-installer/web/api/web-installer';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseGetDpaQueryVariables = undefined;

export type UseGetDpaQueryData = PromiseValue<ReturnType<typeof getDpa>>;

const useGetDpaQuery = createQuery<UseGetDpaQueryVariables, UseGetDpaQueryData>({
    queryName: 'web-installer/consents/dpa',
    fetcher: getDpa,
    useQuery,
});

export default useGetDpaQuery;
