// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import activateLicenseRequest from '@platform360/licenses/web/components/ActivateLicense/common/api/activateLicenseRequest';
import { AxiosError } from 'axios';

type MutationOptions = {
    onSuccess?: () => void;
    onError?: (err: AxiosError) => void;
    onSettled?: () => void;
};

export const useActivateLicenseMutation = ({ onSuccess, onError, onSettled }: MutationOptions) =>
    useMutation({
        mutationFn: activateLicenseRequest,
        onSuccess,
        onError,
        onSettled,
        meta: {
            defaultErrorHandler: false,
        },
    });
