// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { wpAgentRequestMethodValues, WpAgentRequestMethods } = makeEnum(
    'WpAgentRequestMethod',
    'GET',
    'POST',
    'PUT',
    'PATCH',
    'DELETE',
);

export type WpAgentRequestMethod = UnionOf<typeof wpAgentRequestMethodValues>;
