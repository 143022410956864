// Copyright 2024. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import Upgrade from '@platform360/licenses/web/UpgradeWizard/Upgrade';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

const UpgradeView = ({
    mainKey = null,
    offer = null,
    multiOffer = null,
    priceCalculation = null,
    priceCalculationError = null,
    onOfferReset,
    onOfferChange,
    onMultiOfferReset,
    onSubscriptionItemConfirm,
    preSelectedProductNamePrefix = null,
}) => {
    const analyticsEvents = useLicensesAnalyticsEvents();

    return (
        <Upgrade
            offer={offer}
            multiOffer={multiOffer}
            priceCalculation={priceCalculation}
            priceCalculationError={priceCalculationError}
            onOfferChange={onOfferChange}
            onMultiOfferReset={onMultiOfferReset}
            mainKey={mainKey}
            onSubscriptionItemConfirm={() => {
                analyticsEvents.licensesUpgradeDialogConfirmClicked();
                onSubscriptionItemConfirm();
            }}
            onOfferReset={onOfferReset}
            preSelectedProductNamePrefix={preSelectedProductNamePrefix}
        />
    );
};

UpgradeView.propTypes = {
    mainKey: PropTypes.object,
    offer: PropTypes.object,
    multiOffer: PropTypes.object,
    priceCalculation: PropTypes.object,
    preSelectedProductNamePrefix: PropTypes.object,
    priceCalculationError: PropTypes.string,
    onOfferChange: PropTypes.func.isRequired,
    onOfferReset: PropTypes.func.isRequired,
    onMultiOfferReset: PropTypes.func.isRequired,
    onSubscriptionItemConfirm: PropTypes.func.isRequired,
};

export default UpgradeView;
