// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SELECT_SUBSCRIPTION } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type SelectSubscriptionAction = {
    type: typeof SELECT_SUBSCRIPTION;
    payload: string;
};

const selectSubscription = (subscriptionId: string): SelectSubscriptionAction => ({
    type: SELECT_SUBSCRIPTION,
    payload: subscriptionId,
});

export default selectSubscription;
