// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Error desconocido: {message}.',
        newServer: 'Ya existe un servidor con la misma dirección.',
        domainName: 'No es posible resolver el nombre de dominio.',
        serverMisconfigured: 'El servidor no está configurado correctamente y no se puede añadir.',
        pleskConfiguration: 'No se soporta la versión de Plesk (anterior a {pleskMinVersion}) o Plesk no está configurado correctamente.',
        unsupportedServerVersion: 'La versión de Plesk instalada es obsoleta y no se puede añadir. {kbLink}',
        connection: 'La conexión de red ha sido abortada.',
        connectionRefused: 'La conexión de red ha sido rechazada. Compruebe el puerto y el dominio indicado.',
        timeout: 'Se ha agotado el tiempo de espera de la conexión de red.',
        apiKey: 'La conexión no está autorizada por el servidor remoto. Vuelva a añadir el servidor al inventario de servidores.',
        httpsRequired: 'El servidor no soporta HTTPS.',
        certHasExpired: 'El certificado ha expirado. {kbLink}',
        errTlsCertAltnameInvalid: 'El nombre de host del servidor o la dirección IP no coinciden con los altnames del certificado. {kbLink}',
        depthZeroSelfSignedCert: 'Se ha instalado un certificado autofirmado. {kbLink}',
        selfSignedCertInChain: 'En la cadena de certificados hay un certificado autofirmado. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'La configuración de notificaciones ha sido actualizada.',
        },
    },
    Layout: {
        title: 'Inventario de servidores',
        serversTitle: 'Servidores',
        clientsTitle: 'Clientes',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Configuración de notificaciones',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Notificaciones',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Qué notificaciones enviar',
        notifyServerUnreachable: 'Servidor inaccesible',
        notifyServerUnsupported: 'Servidor no compatible',
    },
    AllDomains: {
        title: 'Sitios web',
        name: 'Nombre',
        hostingType: 'Tipo de hosting',
        virtualHostingType: 'Sitio web',
        forwardingHostingType: 'Redireccionamiento',
        noneHostingType: 'Sin hosting',
        mainDomainType: 'Dominio principal',
        subDomainType: 'Subdominio',
        addonDomainType: 'Dominio complementario',
        parkedDomainType: 'Alias',
        hostingServer: 'Servidor de hosting',
        manage: 'Gestionar',
        manageLinkUnavailableHint: 'Para generar un enlace directo al panel de Plesk, es necesario disponer de Plesk RESTful API 1.4.0 o una versión posterior. Para más información, consulte la documentación.',
        open: 'Abrir',
    },
    AllClients: {
        name: 'Nombre de contacto',
        email: 'Dirección de email',
        login: 'Nombre de usuario',
        type: 'Tipo',
        status: 'Estado',
        typeAdmin: 'Admin',
        typeReseller: 'Revendedor',
        typeCustomer: 'Cliente',
        typeCustomerWithOwner: 'Cliente de {owner}',
        statusActive: 'Activo',
        statusSuspended: 'Suspendido',
        hostingServer: 'Servidor de hosting',
        manage: 'Gestionar',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Sincronización en curso',
            installationRunning: 'Instalación en curso',
            installationFailure: 'Error de instalación',
            installationLicenseRequired: 'Licencia requerida',
            active: 'Activo, seguro',
            inactiveUnreachable: 'Inactivo, inaccesible',
            inactiveUnreachableTooltip: 'No se ha podido acceder al servidor durante al menos 14 días',
            inactiveUnsupported: 'Inactivo, no compatible',
            inactiveUnsupportedTooltip: 'El servidor está ejecutando una versión de Plesk no compatible',
            insecure: 'Activo, inseguro',
            outdated: 'Activo, obsoleto',
            outdatedTooltip: '{version, select, _ {Su versión actual de Plesk está obsoleta.} other {Su versión actual de Plesk {version} está obsoleta.}} Le recomendamos que actualice su servidor a la última versión disponible de Plesk Obsidian y que active las actualizaciones automáticas.',
            unsupportedVersion: 'Activo, no compatible',
            unsupportedVersionTooltip: '{version, select, _ {Su versión actual de Plesk no es compatible} other {Su versión actual de Plesk {version} no es compatible}} y su servidor se desactivará en 14 días. Le recomendamos que actualice su servidor a la última versión disponible de Plesk Obsidian y que active las actualizaciones automáticas.',
            certificateWarning: 'Advertencia de certificado',
            certificateError: 'Error de certificado',
            connectionBlocked: 'Conexión bloqueada',
            connectionError: 'Error de conexión',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'No se encuentran los registros de instalación.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {Su versión actual de Plesk está obsoleta.} other {Su versión actual de Pesk {version} está obsoleta.}}',
                        fixDescription: 'Le recomendamos que actualice su servidor a la última versión disponible de Plesk Obsidian y que active las actualizaciones automáticas. Lea la <link>Política de ciclo de vida de Plesk</link> para obtener más información.',
                        goToUpdatesButton: 'Ir a Actualizaciones de {product}',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {Su versión actual de Plesk no es compatible} other {Su versión actual de Plesk {version} no es compatible}} y se desactivará en 14 días.',
                        fixDescription: 'Le recomendamos que actualice su servidor a la última versión disponible de Plesk Obsidian y que active las actualizaciones automáticas. Lea la <link>Política de ciclo de vida de Plesk</link> para obtener más información.',
                        goToUpdatesButton: 'Ir a Actualizaciones de {product}',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'Como no hemos podido acceder al servidor durante 14 días, lo hemos marcado como inactivo.',
                        lastCheckMessage: 'Última vez que comprobamos el estado de la conexión: {lastCheckConnection}',
                        fixDescription: 'Para empezar a usarlo de nuevo, haga clic en "Volver a comprobar la conexión"',
                        recheckConnectionButton: 'Volver a comprobar la conexión',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'El servidor está marcado como inactivo porque está ejecutando {version, select, _ {una versión de Plesk no compatible} other {una versión {version} de Plesk no compatible}}.',
                        fixDescription: 'Para activar el servidor, actualice Plesk y lleve a cabo la sincronización del servidor.',
                        goToUpdatesButton: 'Ir a Actualizaciones de {product}',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Añadir servidor',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Añadir servidor nuevo',
                closeButton: 'Cerrar',
                serverInstallTitle: 'Instale {productType}',
                installNewProduct: 'Instale un nuevo producto en un servidor Linux',
                connectInstallation: 'Conecte un servidor con un producto instalado',
                serverInstallDescription: 'Instale {productType} en un servidor físico o virtual y añádalo al inventario de servidores.',
                connectPleskTitle: 'Conectar con Plesk',
                connectPleskDescription: 'Añade un servidor con Plesk ya instalado al inventario de servidores.',
                connectCPanelTitle: 'Conectar con cPanel',
                connectCPanelDescription: 'Añade un servidor con cPanel ya instalado al inventario de servidores.',
            },
            CustomInstallationStep: {
                title: 'Instalación automática de {productType} en un servidor Linux',
                backButton: 'Atrás',
                submitButton: 'Instalar',
                successMessage: '{productType} se está instalando en <b>{host}</b>.',
            },
            AddServerWithRedirectStep: {
                title: 'Conectar un servidor Plesk',
                description: 'Tras hacer clic en "Conectar", se le redireccionará a Plesk, donde deberá iniciar sesión con permisos de administrador.',
                proceed: 'Conectar',
                proceedAnyway: 'Conectar de todos modos',
                nextStep: 'Próximo paso',
                back: 'Atrás',
                addressLabel: 'Nombre de host del servidor',
                addressDescription: 'El puerto y la URL del servidor al que conectarse. Por ejemplo, "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'Conectar un servidor cPanel',
                close: 'Cerrar',
                back: 'Atrás',
                paragraph1: 'Descargue y ejecute el installer de instalación del plugin cPanel: copie y pegue el siguiente snippet a la consola del servidor usando privilegios root.',
                paragraph2: 'Necesitará un <a>token de API de Plesk 360</a>. Puede generar uno nuevo o utilizar un token ya existente.',
                paragraph3: 'El nuevo servidor aparecerá en el inventario de servidores.',
                copied: 'Copiado.',
            },
        },
    },
};