// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Legale',
        eula: 'EULA',
        pleskEula: 'EULA di Plesk',
        cPanelEula: 'EULA di cPanel',
        terms: 'Condizioni d\'uso',
        privacyPolicy: 'Informativa sulla privacy',
        dpa: 'Accordo per l\'elaborazione dei dati',
        optOuts: 'Clausola di dissociazione',
        cookies: 'Informativa sui Cookie',
        gaHeader: 'Disocciazione da Google Analytics',
        gaOptOut: 'Clicca qui per dissociarti da Google Analytics',
        cookieHeader: 'Adeguamenti del consenso ai cookie',
        cookieModify: 'Modifica il consenso ai cookie',
        cookieWithdraw: 'Ritira il consenso ai cookie',
        cookieSuccess: 'Ritiro del consenso riuscito',
    },
    RedirectGateway: {
        title: 'Ti stiamo reindirizzando {destination}',
        progress: 'L’operazione può richiedere diversi minuti',
        genericError: 'Si è verificato un problema',
        serverDetails: {
            destinationTitle: 'alla pagina del server nell\'Inventario server',
            errorTitle: 'Si è verificato un errore nel tentativo di aprire la pagina del server',
            errorDescription: 'Il server non esiste nell\'Inventario server',
            redirectDirectly: 'Passa all\'Inventario server',
        },
        monitoringServerSetup: {
            destinationTitle: 'alla pagina di conferma',
            errorTitle: 'Si è verificato un errore nel tentativo di aprire la pagina di conferma del monitoraggio del server',
            errorDescription: 'Il server non esiste nell\'Inventario server',
        },
    },
};