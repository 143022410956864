// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import LabelItem from '@platform360/security-dashboard/web/components/LabelsList/LabelItem';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import { UpdateSettingsOptions } from '@platform360/security-dashboard/shared/update-settings-option';
import { UpdateSettings } from '@platform360/security-dashboard/web/types';

export type UpdatesLabelProps = {
    updates: UpdateSettings;
};

export const UpdatesLabel = ({
    updates: { core, plugins, themes, safeUpdate },
}: UpdatesLabelProps) => {
    const translate = useTranslate('security-dashboard.LabelsList.UpdatesLabel');

    const assets = [core.value, themes.value, plugins.value];
    const allAssetsHaveSomeOfValues = (...values: string[]) =>
        assets.every((asset) => values.some((value) => asset === value));

    const areSafeUpdatesEnabled = () => {
        if (safeUpdate.value === null) {
            return safeUpdate.parent === SafeUpdateOptions.enabled;
        }

        return safeUpdate.value === SafeUpdateOptions.enabled;
    };

    const getLabel = () => {
        if (allAssetsHaveSomeOfValues(UpdateSettingsOptions.notSet)) {
            return translate('notSet');
        }

        if (allAssetsHaveSomeOfValues(UpdateSettingsOptions.minor, UpdateSettingsOptions.major)) {
            return translate('autoupdate');
        }

        if (allAssetsHaveSomeOfValues(UpdateSettingsOptions.disabled)) {
            return translate('manual');
        }

        return translate('custom');
    };

    if (assets.every((a) => !a)) {
        return null;
    }

    return (
        <LabelItem icon={areSafeUpdatesEnabled() ? 'lock-closed' : undefined} intent="info">
            {getLabel()}
        </LabelItem>
    );
};
