// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';
import { deleteLabels } from '@platform360/security-dashboard/web/api/labels';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

type MutationOptions = {
    onSuccess: () => void;
    onSettled: (onSettled: number[]) => void;
};

const useDeleteLabelsMutation = ({ onSuccess, onSettled }: MutationOptions) => {
    const queryClient = useQueryClient();
    const translate = useTranslate('security-dashboard.useDeleteLabelsMutation');
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: deleteLabels,
        onSettled: (_, __, labelIds) => onSettled(labelIds),
        onSuccess: async (_, labelIds) => {
            onSuccess();

            refetchList(useInstallationsQuery.getQueryKey());

            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: useLabelsQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
            ]);
            successToast(
                translate('success', {
                    count: labelIds.length,
                }),
            );
        },
        onError: (_, labelIds) => {
            errorToast(
                translate('failed', {
                    count: labelIds.length,
                }),
            );
        },
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useDeleteLabelsMutation;
