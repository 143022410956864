// Copyright 2024. WebPros International GmbH. All rights reserved.

import { removeInstallationLabels } from '@platform360/security-dashboard/web/api/installations';
import { useMutation } from '@tanstack/react-query';
import { useInstallationsQuery } from '@platform360/security-dashboard/web/queries';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';

const useRemoveInstallationLabelsMutation = () => {
    const translate = useTranslate('security-dashboard.useRemoveInstallationLabelsMutation');
    const { successToast, errorToast } = useToaster();

    return useMutation({
        mutationFn: removeInstallationLabels,
        onSuccess: (_, opts) => {
            refetchList(useInstallationsQuery.getQueryKey());

            successToast(translate('success', { count: opts.labelIds.length }));
        },
        onError: (_, opts) => errorToast(translate('failed', { count: opts.labelIds.length })),
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useRemoveInstallationLabelsMutation;
