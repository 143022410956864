// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Reducer } from 'redux';
import initialState from '@platform360/licenses/web/units/extensions/reducer/initialState';
import {
    CHANGE_CURRENCY,
    CHANGE_PERIOD,
    FAILED_INITIALIZATION,
    FINALIZATION,
    FLUSH_ITEM,
    INITIALIZATION,
    INITIALIZED,
    SELECT_ITEM,
} from '@platform360/licenses/web/units/extensions/constants/actions';
import { ChangeCurrencyAction } from '@platform360/licenses/web/units/extensions/actions/changeCurrency';
import { ChangePeriodAction } from '@platform360/licenses/web/units/extensions/actions/changePeriod';
import { FailedInitializationAction } from '@platform360/licenses/web/units/extensions/actions/failedInitialization';
import { FinalizationAction } from '@platform360/licenses/web/units/extensions/actions/finalization';
import { FlushItemAction } from '@platform360/licenses/web/units/extensions/actions/flushItem';
import { InitializedAction } from '@platform360/licenses/web/units/extensions/actions/initialized';
import { SelectItemAction } from '@platform360/licenses/web/units/extensions/actions/selectItem';

type Action =
    | ChangeCurrencyAction
    | ChangePeriodAction
    | FailedInitializationAction
    | FinalizationAction
    | FlushItemAction
    | InitializedAction
    | SelectItemAction;

// @todo delete @ts-expect-error when the issue is resolved
// @issue https://jira.plesk.ru/browse/PAUX-2998

const reducer: Reducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case INITIALIZED:
            return {
                ...state,
                initialized: true,
                ...action.payload,
            };
        case CHANGE_PERIOD: {
            return { ...state, period: action.payload };
        }
        case CHANGE_CURRENCY: {
            return { ...state, currency: action.payload };
        }
        case SELECT_ITEM: {
            return { ...state, selectedItemPath: action.payload };
        }
        case FLUSH_ITEM: {
            return { ...state, selectedItemPath: initialState.selectedItemPath };
        }
        case FAILED_INITIALIZATION: {
            return {
                ...initialState,
                initialized: true,
                failedInitialization: true,
            };
        }
        // @ts-expect-error
        case INITIALIZATION:
        case FINALIZATION:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
