// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './Header.module.css';

type HeaderProps = {
    children: ReactNode;
};

const Header = ({ children }: HeaderProps) => <header className={styles.root}>{children}</header>;

export default Header;
