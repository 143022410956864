// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    LeikaEmailHashesRequest,
    LeikaEmailHashesResponse,
} from '@platform360/admin-panel/shared/api-contract';

export const generateLeikaEmailHashes = async (
    params: LeikaEmailHashesRequest,
): Promise<LeikaEmailHashesResponse> => {
    const {
        data: { data },
    } = await apiClient.post('/admin-panel/leika-email-hashes', params);
    return data;
};
