// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import {
    ToolbarGroup,
    Toolbar as UILibToolbar,
    SearchBar,
    ToolbarExpander,
} from '@plesk/ui-library';
import { UpdateButton } from './UpdateButton';
import { IgnoreButton } from './IgnoreButton';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Vulnerability } from '@platform360/security-dashboard/web/types';
import {
    PatchButton,
    PatchButtonProps,
} from '@platform360/security-dashboard/web/components/Vulnerabilities/Toolbar/PatchButton';

type ToolbarProps = {
    selectedItems: string[];
    searchValue?: string;
    onSearch?: (value: string) => void;
    onHandleUpdate: (enableAfterUpdate: boolean) => void;
    onIgnore: (vulnerabilitiesIds: string[]) => void;
    onCancelIgnore: (vulnerabilitiesIds: string[], labelIds: number[]) => void;
    onUpdateConfirmationShown: () => void;
    onHandlePatch: PatchButtonProps['onHandlePatch'];
    handleLicenseTerminated: () => void;
    isLicenseTerminated: boolean;
    filtersButton: ReactNode;
    vulnerabilities: Vulnerability[];
};
export const Toolbar = ({
    selectedItems,
    searchValue,
    onSearch,
    onHandleUpdate,
    onIgnore,
    onCancelIgnore,
    filtersButton,
    onUpdateConfirmationShown,
    onHandlePatch,
    handleLicenseTerminated,
    isLicenseTerminated,
    vulnerabilities,
}: ToolbarProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Toolbar');
    const terminatedLicenseHandler = isLicenseTerminated ? handleLicenseTerminated : undefined;

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('patch')}>
                <PatchButton
                    selectedItems={selectedItems}
                    vulnerabilities={vulnerabilities}
                    onHandlePatch={onHandlePatch}
                    onClick={terminatedLicenseHandler}
                />
            </ToolbarGroup>
            <ToolbarGroup title={translate('manage')}>
                <UpdateButton
                    selectedItems={selectedItems}
                    onHandleUpdate={onHandleUpdate}
                    onConfirmationShown={onUpdateConfirmationShown}
                    onClick={terminatedLicenseHandler}
                />
                <IgnoreButton
                    selectedItems={selectedItems}
                    vulnerabilities={vulnerabilities}
                    onIgnore={onIgnore}
                    onCancelIgnore={onCancelIgnore}
                />
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('groupFilterMenu')}>{filtersButton}</ToolbarGroup>
            <ToolbarGroup title={translate('groupSearch')}>
                <SearchBar
                    inputProps={{
                        placeholder: translate('searchPlaceholder'),
                        value: searchValue,
                    }}
                    onSearch={(value: string) => onSearch?.(value)}
                    onTyping={onSearch}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
