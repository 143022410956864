// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server } from '@platform360/security-dashboard/web/types';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import MassSettingsDrawer from '@platform360/security-dashboard/web/components/Servers/SettingsDrawer/MassSettingsDrawer';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useNavigate } from 'react-router-dom';
const RoutableSettingsDrawer = withRouteBinding(MassSettingsDrawer);

type SettingsButtonProps = {
    selectedServers: Server[];
    onSave: (serversIds: number[]) => void;
};

const SettingsButton = ({ selectedServers, onSave, ...props }: SettingsButtonProps) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar.SettingsButton');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const navigate = useNavigate();

    const openDrawer = () => {
        if (selectedServers.length === 1) {
            navigate(`/security-dashboard/servers/${selectedServers[0]?.id}/settings/updates`);
            return;
        }
        navigate(
            `/security-dashboard/servers/mass-settings/${selectedServers.map((server) => server.id).join(',')}/updates`,
        );
    };
    return (
        <>
            <RoutableSettingsDrawer
                openPath="mass-settings/:serverIds/:tabKey"
                closePath="/security-dashboard/servers"
                onSave={onSave}
            />
            <NoSelectionPopover
                selectionCount={selectedServers.length}
                target={
                    <Button
                        icon="gear"
                        tooltip={translate('buttonText')}
                        onClick={() => {
                            openDrawer();
                            analyticsEvents.wpMltServerSettingsClick();
                        }}
                    />
                }
                {...props}
            >
                {translate('noSelectedServers')}
            </NoSelectionPopover>
        </>
    );
};

export default SettingsButton;
