// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import {
    transformError,
    TransformedError,
    ActivateLicenseDialog,
} from '@platform360/licenses/web/components/ActivateLicense/common';
import { AxiosError } from 'axios';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useMonitoringAnalyticsEvents } from '@platform360/monitoring/web/helpers/analytics';
import {
    PRODUCT_MONITORING_PRO,
    PRODUCT_MONITORING_BUSINESS,
    PRODUCT_MONITORING_ENTERPRISE,
    PRODUCT_MONITORING_ENTERPRISE_FOR_PARTNERS,
    PRODUCT_MONITORING_PRO_FLEXIBLE,
    PRODUCT_MONITORING_LITE,
    PRODUCT_MONITORING_LITE_CUSTOM,
    PRODUCT_MONITORING_LITE_CUSTOM_NO_ALERTS,
    PRODUCT_MONITORING_PRO_SMALL,
    PRODUCT_MONITORING_PRO_DAILY_FLEXIBLE,
} from '@platform360/libs/shared-web/constants/products';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useActivateLicenseMutation } from '@platform360/licenses/web/mutations/useActivateLicenseMutation';
import useMonitoringSetup from '@platform360/monitoring/web/mutations/useMonitoringSetup';

type MonitoringActivateLicenseContainerProps = {
    product:
        | typeof PRODUCT_MONITORING_PRO_FLEXIBLE
        | typeof PRODUCT_MONITORING_PRO
        | typeof PRODUCT_MONITORING_BUSINESS
        | typeof PRODUCT_MONITORING_ENTERPRISE
        | typeof PRODUCT_MONITORING_ENTERPRISE_FOR_PARTNERS
        | typeof PRODUCT_MONITORING_LITE
        | typeof PRODUCT_MONITORING_LITE_CUSTOM
        | typeof PRODUCT_MONITORING_LITE_CUSTOM_NO_ALERTS
        | typeof PRODUCT_MONITORING_PRO_SMALL
        | typeof PRODUCT_MONITORING_PRO_DAILY_FLEXIBLE;
};

const MonitoringActivateLicenseContainer = ({
    product,
}: MonitoringActivateLicenseContainerProps) => {
    const [searchParams] = useSearchParams();
    const code = String(searchParams.get('code'));
    const { monitoring, email } = useCurrentUser();
    const [error, setError] = useState<TransformedError | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const {
        monitoring: { baseUrl: monitoringUrl },
    } = useConfig();
    const monitoringAnalyticsEvents = useMonitoringAnalyticsEvents();
    const translate = useTranslate('licenses.ActivateLicense.monitoring');

    const navigate = useNavigate();

    const { mutate: activateLicense, isError } = useActivateLicenseMutation({
        onSuccess: () => {
            monitoringAnalyticsEvents.setMonitoringPartnerCustomer(true);
            monitoringAnalyticsEvents.monitoringLicenseActivationSuccessful(product);
            setIsRedirecting(true);
            window.location.href = monitoringUrl;
        },
        onError: (err: AxiosError) => {
            monitoringAnalyticsEvents.monitoringLicenseActivationFailed(product);
            setError(transformError(err));
        },
        onSettled: () => setIsLoading(false),
    });

    const { mutate: setupMonitoringAndActivateLicense, isError: isMonitoringSetupError } =
        useMonitoringSetup({
            onSuccess: (setupResponse) => {
                const { userId } = setupResponse;
                activateLicense({ activationCode: code, uid: userId });
            },
            onError: (err: AxiosError) => {
                monitoringAnalyticsEvents.monitoringLicenseActivationFailed(product);
                setError(transformError(err));
                setIsLoading(false);
            },
            defaultErrorHandler: false,
        });

    useEffect(() => {
        monitoringAnalyticsEvents.monitoringLicenseActivationDialogShown(product);
    }, [monitoringAnalyticsEvents, product]);

    useEffect(() => {
        if (!isError && !isMonitoringSetupError) {
            setError(undefined);
        }
    }, [isError, isMonitoringSetupError]);

    const handleConfirm = () => {
        setIsLoading(true);
        monitoringAnalyticsEvents.monitoringLicenseActivationConfirmed(product);
        const uid = monitoring?.userId;
        if (uid) {
            activateLicense({ activationCode: code, uid });
        } else {
            setupMonitoringAndActivateLicense();
        }
    };

    const handleCancel = () => {
        monitoringAnalyticsEvents.monitoringLicenseActivationCancelled(product);
        navigate('/');
    };

    return (
        <ActivateLicenseDialog
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            isLoading={isLoading}
            isRedirecting={isRedirecting}
            error={error}
            userEmail={email}
            product={product}
            dataType={'monitoring-activate-license'}
            locale={{
                activateLicenseDialog: {
                    cancelButton: translate('MonitoringActivateLicense.cancelButton'),
                    confirmButton: translate('MonitoringActivateLicense.confirmButton'),
                    description: translate('MonitoringActivateLicense.description', {
                        mailto: (chunk) => <a href={`mailto:${email}`}>{chunk}</a>,
                        userEmail: email,
                        product,
                    }),
                    errors: {
                        linkError: translate('MonitoringActivateLicense.errors.linkError'),
                        unknownError: translate('MonitoringActivateLicense.errors.unknownError'),
                    },
                    fixableErrorDescription: translate(
                        'MonitoringActivateLicense.fixableErrorDescription',
                    ),
                    gotItButton: translate('MonitoringActivateLicense.gotItButton'),
                    loaderText: translate('MonitoringActivateLicense.loaderText'),
                    nonFixableErrorDescription: translate(
                        'MonitoringActivateLicense.nonFixableErrorDescription',
                    ),
                    redirectText: translate('MonitoringActivateLicense.redirectText'),
                    retryButton: translate('MonitoringActivateLicense.retryButton'),
                    title: translate('MonitoringActivateLicense.title'),
                },
                cancelConfirmation: {
                    cancelButton: translate('CancelConfirmation.cancelButton'),
                    continueButton: translate('CancelConfirmation.continueButton'),
                    description: translate('CancelConfirmation.description', { product }),
                },
            }}
        />
    );
};

export default MonitoringActivateLicenseContainer;
