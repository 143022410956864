// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SimpleLayout } from '@platform360/libs/shared-web/components/SimpleLayout';
import styles from './MonitoringActivation.module.less';
import logo from '@platform360/monitoring/web/components/MonitoringActivation/360monitoring.svg';
import { Button, Heading, Paragraph, StatusMessage, TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link } from 'react-router-dom';

type MonitoringActivationProps = {
    error?: TranslationProps;
    onTrialBegin: () => void;
    isTrialBeginProcessing: boolean;
};

// eslint-disable-next-line jsx-a11y/alt-text
const Logo = () => <img src={logo} />;

const MonitoringActivation = ({
    error,
    onTrialBegin,
    isTrialBeginProcessing,
}: MonitoringActivationProps) => {
    const translate = useTranslate('monitoring.MonitoringActivation');
    const content = error ? (
        <>
            <StatusMessage intent="danger">{error}</StatusMessage>
            <Button component={Link} to="/" intent="primary">
                {translate('backButton')}
            </Button>
        </>
    ) : (
        <>
            <Heading level={2}>{translate('title')}</Heading>
            <Paragraph className={styles.description}>{translate('description')}</Paragraph>
            <div className={styles.buttonGroup}>
                <Button
                    size="lg"
                    intent="primary"
                    onClick={onTrialBegin}
                    state={isTrialBeginProcessing ? 'loading' : undefined}
                >
                    {translate('beginTrialButton')}
                </Button>
            </div>
            <Paragraph>{translate('footnote')}</Paragraph>
        </>
    );

    return <SimpleLayout logo={<Logo />}>{content}</SimpleLayout>;
};

export default MonitoringActivation;
