// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SkeletonText, Text, Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Server } from '@platform360/monitoring/web/api/monitoring';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

export type MonitoringColumnProps = {
    isLoading: boolean;
    hasError: boolean;
    serverGuid: string;
    serverAddress: string;
    monitoringServer?: Server;
    isMonitoringConnectionSupported?: boolean;
};

const MonitoringColumn = ({
    isLoading,
    isMonitoringConnectionSupported,
    serverGuid,
    serverAddress,
    monitoringServer,
    hasError,
}: MonitoringColumnProps) => {
    const translate = useTranslate('monitoring.MonitoringColumn');
    const {
        monitoring: { baseUrl },
    } = useConfig();

    if (isLoading || isMonitoringConnectionSupported === undefined) {
        return <SkeletonText lines={1} />;
    }

    if (hasError) {
        return <Text intent="danger">{translate('loadingError')}</Text>;
    }

    if (!isMonitoringConnectionSupported) {
        return <Text intent="muted">{translate('notSupported')}</Text>;
    }

    if (!monitoringServer) {
        return (
            <Link
                target="_blank"
                href={`/monitoring/server/${serverGuid}?address=${serverAddress}`}
            >
                {translate('connectServer')}
            </Link>
        );
    }

    const notificationsUrl = `${baseUrl}server/${monitoringServer.monitoringServerId}/notifications`;

    if (monitoringServer.alerts.length === 0) {
        return (
            <Link target="_blank" href={notificationsUrl}>
                {translate('noAlerts')}
            </Link>
        );
    }

    return (
        <Link target="_blank" href={notificationsUrl}>
            {translate('openAlerts', {
                alerts: monitoringServer.alerts.length,
            })}
        </Link>
    );
};

export default MonitoringColumn;
