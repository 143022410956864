// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Reducer } from 'redux';
import {
    CHANGE_CURRENCY,
    CHANGE_PERIOD,
    FINALIZATION,
    FINISH_PROCESSING,
    FLUSH_ONLINE_STORE_DATA,
    INITIALIZED,
    SELECT_PRODUCT,
    SELECT_SUBSCRIPTION,
    SET_ONLINE_STORE_DATA,
    SET_PRODUCTS,
    SET_SUBSCRIPTIONS,
    SET_VALUE_FOR_PRODUCT,
    START_PROCESSING,
    TOGGLE_FETCHING,
} from '../../constants/buyExtensionActions';
import { initialState } from './initialState';
import { ChangeCurrencyAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/changeCurrency';
import { ChangePeriodAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/changePeriod';
import { FinalizationAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/finalization';
import { FinishProcessingAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/finishProcessing';
import { FlushOnlineStoreDataAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/flushOnlineStoreData';
import { InitializedAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/initialized';
import { SelectProductAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/selectProduct';
import { SelectSubscriptionAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/selectSubscription';
import { SetOnlineStoreDataAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/setOnlineStoreData';
import { SetProductsAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/setProducts';
import { SetSubscriptionsAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/setSubscriptions';
import { StartProcessingAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/startProcessing';
import { ToggleFetchingAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/toggleFetching';
import { SetValueAction } from '@platform360/licenses/web/units/extensions/actions/buyExtension/setValue';

type Action =
    | ChangeCurrencyAction
    | ChangePeriodAction
    | FinalizationAction
    | FinishProcessingAction
    | FlushOnlineStoreDataAction
    | InitializedAction
    | SelectProductAction
    | SelectSubscriptionAction
    | SetOnlineStoreDataAction
    | SetProductsAction
    | SetSubscriptionsAction
    | StartProcessingAction
    | SetValueAction
    | ToggleFetchingAction;

// @todo delete @ts-expect-error when the issue is resolved
// @issue https://jira.plesk.ru/browse/PAUX-2998

const reducer: Reducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case INITIALIZED: {
            return { ...state, initialized: true, ...action.payload };
        }
        case FINALIZATION: {
            return initialState;
        }
        case START_PROCESSING: {
            return { ...state, processing: 'started' };
        }
        case FINISH_PROCESSING: {
            return {
                ...state,
                processing: 'finished',
            };
        }

        case CHANGE_CURRENCY: {
            return { ...state, currency: action.payload };
        }
        case CHANGE_PERIOD: {
            return { ...state, initialized: false, period: action.payload, onlineStoreData: false };
        }
        case SELECT_PRODUCT: {
            return { ...state, selectedProduct: action.payload };
        }
        case SET_VALUE_FOR_PRODUCT: {
            return {
                ...state,
                selectedPaygrProducts: {
                    ...state.selectedPaygrProducts,
                    [action.payload.productId]: action.payload.value,
                },
            };
        }
        case SELECT_SUBSCRIPTION: {
            return { ...state, selectedSubscription: action.payload };
        }
        case SET_SUBSCRIPTIONS: {
            return { ...state, subscriptions: action.payload };
        }
        case SET_PRODUCTS: {
            return { ...state, products: action.payload };
        }
        case TOGGLE_FETCHING: {
            return {
                ...state,
                isFetching: action.payload,
            };
        }

        case SET_ONLINE_STORE_DATA: {
            // @ts-expect-error TS2571: Object is of type 'unknown'
            return { ...state, onlineStoreData: action.payload, currency: action.payload.currency };
        }
        case FLUSH_ONLINE_STORE_DATA: {
            return { ...state, onlineStoreData: false };
        }
        default:
            return state;
    }
};

export default reducer;
