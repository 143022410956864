// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useMemo } from 'react';
import { Alert, Button, ContentLoader, Heading, Section, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import ConfirmButton from '@platform360/licenses/web/units/reactivateSubscription/components/ConfirmButton';
import { CleverBridgePaymentMethod } from '@platform360/licenses/web/components/PaymentMethod';
import NonBreakingSpace from '@platform360/licenses/web/components/HTMLEntities/NonBreakingSpace';
import {
    ConfirmSubscriptionInfo,
    Footer,
    PaymentInfo as CleverbridgePaymentInfo,
} from '@platform360/licenses/web/units/cleverbridge';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import Cost from '@platform360/licenses/web/components/Cost';
import { AG } from '@platform360/licenses/web/units/cleverbridge/constants/merchantOfRecordType';
import isCleverbridgePaymentExpired from '@platform360/licenses/web/helpers/cleverbridge/isCleverbridgePaymentExpired';
import useSubscriptionRenewQuery from '@platform360/licenses/web/queries/useSubscriptionRenewQuery';

export type ReactivateFormProps = {
    licenseId: number;
    period: BillingPeriod;
    onSubmit: () => void;
    onCancel: () => void;
};

const ReactivateForm = ({ licenseId, period, onSubmit, onCancel }: ReactivateFormProps) => {
    const translate = useTranslate();
    const {
        data: calculatedCost,
        isLoading,
        isError,
    } = useSubscriptionRenewQuery({
        variables: {
            licenseId,
        },
    });

    useEffect(() => {
        if (isError) {
            onCancel();
        }
    }, [isError, onCancel]);

    const cardExpired = useMemo(
        () => isCleverbridgePaymentExpired(calculatedCost?.paymentInfo),
        [calculatedCost],
    );

    return (
        <>
            <div>
                {isLoading && <ContentLoader />}
                {!isLoading && calculatedCost && (
                    <>
                        <Alert data-type="Confirm">
                            <Text fontSize="md">
                                {translate('nextCharge', {
                                    grossPrice: (
                                        <BillingInterval period={period}>
                                            <Cost
                                                value={calculatedCost.nextBillingGrossPrice}
                                                currency={calculatedCost.currency as Currency}
                                            />
                                        </BillingInterval>
                                    ),
                                    vatPrice: (
                                        <Cost
                                            value={calculatedCost.nextBillingVatPrice}
                                            currency={calculatedCost.currency as Currency}
                                        />
                                    ),
                                    nextBillingDate: calculatedCost.nextBillingDate,
                                })}
                            </Text>
                        </Alert>
                        <Heading level={4}>{translate('Keys.headers.payment')}</Heading>
                        <CleverbridgePaymentInfo
                            merchantOfRecordType={calculatedCost.merchantOfRecordType || AG}
                        />
                        <CleverBridgePaymentMethod
                            paymentInfo={calculatedCost.paymentInfo}
                            changePaymentUrl={calculatedCost.changePaymentUrl}
                        />
                    </>
                )}
            </div>
            <Section>
                <Heading level={4}>
                    <Text intent="muted">{translate('Keys.headers.confirmSubscription')}</Text>
                </Heading>
                <ConfirmSubscriptionInfo />
                <Footer merchantOfRecordType={calculatedCost?.merchantOfRecordType || AG} />
            </Section>
            <footer>
                <ConfirmButton onSubmit={onSubmit} disabled={isLoading || cardExpired} />
                <NonBreakingSpace />
                <Button size="lg" data-type="Renew--cancelRenew" onClick={onCancel}>
                    {translate('Keys.general.cancel')}
                </Button>
            </footer>
        </>
    );
};

export default ReactivateForm;
