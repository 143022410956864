// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link, useLocation } from 'react-router-dom';
import { Tabs as UILibTabs, Tab, TranslationProps } from '@plesk/ui-library';

export type TabProps = {
    to: string;
    title: TranslationProps;
    onActivate?: () => void;
};

export type TabsProps = {
    tabs: TabProps[];
    className?: string;
};

const Tabs = ({ tabs, className }: TabsProps) => {
    const { pathname } = useLocation();

    return (
        <UILibTabs
            className={className}
            active={tabs.findIndex(({ to }) => pathname.startsWith(to)) + 1}
        >
            {tabs.map(({ to, title, onActivate }) => (
                <Tab key={to} component={Link} to={to} title={title} onActivate={onActivate} />
            ))}
        </UILibTabs>
    );
};

export default Tabs;
