// Copyright 2024. WebPros International GmbH. All rights reserved.
import Button from '../components/Button';
import { StoreState } from '@platform360/licenses/web/store/types';
import { useSelector } from 'react-redux';
import has360Monitoring from '../selector/has360Monitoring';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

const ButtonContainer = () => {
    const monitoringAccount = useCurrentUser();
    const hasMonitoring = useSelector<StoreState, boolean>(has360Monitoring);
    const analyticsEvents = useLicensesAnalyticsEvents();

    return (
        <Button
            monitoringAccount={monitoringAccount.monitoring?.userId}
            has360Monitoring={hasMonitoring}
            onClick={() => {
                analyticsEvents.licensesBuy360MonitoringClicked();
            }}
        />
    );
};

export default ButtonContainer;
