// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import image from './server-inventory.svg';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { AddingServerButton } from '@platform360/server-inventory/web/components/Servers/AddingServer';

const Onboarding = () => {
    const translate = useTranslate('Servers.Onboarding');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { docs } = useConfig();

    return (
        <ListEmptyView
            title={translate('title')}
            description={
                <Paragraph>
                    {translate('description')}{' '}
                    <a
                        href={concatUrl('/scp/servers/servers.html', docs.baseUrl)}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => analyticsEvents.documentationLinkClicked()}
                    >
                        {translate('docsLink')}
                    </a>
                </Paragraph>
            }
            image={image}
            actions={[
                <AddingServerButton key="1" />,
                <Button
                    key="2"
                    icon="feedback"
                    component="a"
                    href="https://pt-research.typeform.com/to/BVtcGgRL"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {translate('feedbackButton')}
                </Button>,
            ]}
        />
    );
};

export { Onboarding };
export default Onboarding;
