// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon, Text } from '@plesk/ui-library';
import useInstallationComponentTranslate from '@platform360/security-dashboard/web/hooks/useInstallationComponentTranslate';
import { INSTALLATION_COMPONENT_ICONS_MAP } from '@platform360/security-dashboard/web/constants/installationComponent';
import styles from './InstallationComponentFlag.module.less';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

export type InstallationComponentFlagProps = {
    component: InstallationComponent;
};

const InstallationComponentFlag = ({ component }: InstallationComponentFlagProps) => {
    const translate = useInstallationComponentTranslate();

    return (
        <div className={styles.container}>
            <Icon intent="inactive" name={INSTALLATION_COMPONENT_ICONS_MAP[component]} />
            &nbsp;<Text>{translate(component)}</Text>
        </div>
    );
};

export default InstallationComponentFlag;
