// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';

type ActivateLicenseRequestProps = {
    activationCode: string;
    uid: string;
};

const activateLicenseRequest = async (params: ActivateLicenseRequestProps) =>
    apiClient.post(
        '/ka/activate-license',
        {},
        {
            params,
        },
    );

export default activateLicenseRequest;
