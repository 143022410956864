// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createQuery } from '@platform360/libs/shared-web/query';
import { getServerLoginLink } from '@platform360/server-inventory/web/api/servers';
import { useQuery } from '@tanstack/react-query';
import { LoginLinkParams } from '@platform360/server-inventory/shared/login-link-params';

export type UseServerLoginLinkQueryData = PromiseValue<ReturnType<typeof getServerLoginLink>>;

const useServerLoginLinkQuery = createQuery<LoginLinkParams, UseServerLoginLinkQueryData>({
    queryName: 'server-inventory/useServerLoginLinkQuery',
    fetcher: getServerLoginLink,
    useQuery,
});

export default useServerLoginLinkQuery;
