// Copyright 2024. WebPros International GmbH. All rights reserved.

import axios from 'axios';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';
import keyBy from 'lodash/keyBy';
import * as Sentry from '@sentry/browser';
import {
    ApiListResponse,
    FetcherOptions,
    IdsFilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';

export const LIST_FETCHER_EVENT = 'list-fetcher-event';

type ResultItem = {
    id: number;
};

type ListApiResponse = ApiListResponse<ResultItem>;
type FetcherFn = (
    options: FetcherOptions<IdsFilterQuery & PaginatedQuery>,
) => Promise<ListApiResponse>;

/**
 * Function to dispatch a refetch event for a given query key.
 * @param {QueryKey} queryKey - React Query key to be refreshed.
 */
export const refetchList = (queryKey: QueryKey) => {
    document.dispatchEvent(
        new CustomEvent(LIST_FETCHER_EVENT, {
            cancelable: false,
            detail: queryKey,
        }),
    );
};

/**
 * Hook to facilitate the fetching of lists.
 */
export const useListFetcher = (queryKey: QueryKey, fetcher: FetcherFn) => {
    const documentRef = useRef<Document>(document);
    const abortControllerRef = useRef<AbortController | null>(null);
    const queryClient = useQueryClient();

    const handleFetch = ({ detail: evtQueryKey }: CustomEvent<QueryKey>) => {
        if (evtQueryKey[0] !== queryKey[0]) {
            return;
        }

        const fetchData = async () => {
            const queryData = queryClient.getQueryData<ListApiResponse>(queryKey);
            const itemIds = queryData?.data.map(({ id }) => id.toString()) || [];

            if (itemIds.length === 0) {
                return;
            }

            const abortController = new AbortController();

            abortControllerRef.current = abortController;

            try {
                const { data } = await fetcher({
                    signal: abortController.signal,
                    variables: {
                        idsFilter: itemIds,
                        // TODO: Fine for now. Handle the real pagination in the future.
                        pageSize: 100,
                    },
                });

                const dataById = keyBy(data, 'id');

                queryClient.setQueryData<ListApiResponse>(queryKey, (oldData) => {
                    if (!oldData) {
                        return undefined;
                    }

                    const { data, totalCount } = oldData;

                    return {
                        data: data.map((item) => dataById[item.id] || item),
                        totalCount,
                    };
                });
            } catch (e) {
                if (!axios.isCancel(e)) {
                    Sentry.captureException(e);
                }
            }
        };

        void fetchData();
    };

    useEventListener(LIST_FETCHER_EVENT, handleFetch, documentRef);

    // Cleanup: Abort any ongoing fetch operation when the component is unmounted.
    useEffect(() => {
        const controller = abortControllerRef.current;

        return () => {
            controller?.abort();
        };
    }, []);
};
