// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useInstallationsQuery } from '@platform360/security-dashboard/web/queries';

import {
    SafeUpdateOption,
    SafeUpdateOptions,
} from '@platform360/security-dashboard/shared/safe-update-option';
import {
    UpdateSettingsOption,
    UpdateSettingsOptions,
} from '@platform360/security-dashboard/shared/update-settings-option';
import { SaveInstallationSettingsRequest } from '@platform360/security-dashboard/web/api/installations';
import SettingsDrawer from '@platform360/security-dashboard/web/components/Installations/SettingsDrawer/SettingsDrawer';
import { useSaveInstallationsSettings } from '@platform360/security-dashboard/web/mutations';
import { Skeleton } from '@plesk/ui-library';
import { useParams } from 'react-router-dom';

export type MassSettingsDrawerProps = ClosableProps & {
    handleUnprocessableInstallationsModalVisibility: (
        installationIds: number[],
        { isSingleOperationAllowed }: { isSingleOperationAllowed: boolean },
    ) => void;
};

type MassSettingsFormValues = {
    updates: {
        core: UpdateSettingsOption | 'parent';
        plugins: UpdateSettingsOption | 'parent';
        themes: UpdateSettingsOption | 'parent';
        safeUpdate: SafeUpdateOption | 'parent';
    };
};

const formValuesDefaultState: MassSettingsFormValues = Object.freeze({
    updates: {
        core: UpdateSettingsOptions.doNotTouch,
        plugins: UpdateSettingsOptions.doNotTouch,
        themes: UpdateSettingsOptions.doNotTouch,
        safeUpdate: SafeUpdateOptions.doNotTouch,
    },
});

const MassSettingsDrawer = ({
    onClose,
    handleUnprocessableInstallationsModalVisibility,
    ...props
}: MassSettingsDrawerProps) => {
    const { installationIds: siteIds = '' } = useParams<{ installationIds: string }>();
    const installationIds: number[] = siteIds.split(',').map(Number);

    const translate = useTranslate('security-dashboard.Installations.SettingsDrawer');

    const {
        data: installations,
        isError,
        error,
        isLoading: areIntallationsLoading,
    } = useInstallationsQuery({
        variables: {
            idsFilter: installationIds,
        },
    });

    const { successToast } = useToaster();

    const processableInstallationsCount =
        installations?.data.filter(({ managed, operable }) => managed && operable).length ?? 0;

    const inoperableInstallationIds =
        installations?.data.filter(({ operable }) => !operable).map(({ id }) => id) ?? [];
    const unmanagedInstallationIds =
        installations?.data
            .filter(({ managed, operable }) => !managed && operable)
            .map(({ id }) => id) ?? [];

    const unoperableInstallationsCount = inoperableInstallationIds.length;
    const unmanagedInstallationsCount = unmanagedInstallationIds.length;

    const { mutate: saveSettings, isPending: isSaving } = useSaveInstallationsSettings({
        onSuccess: () => {
            successToast(translate('successToast'));
            onClose();
            if (unoperableInstallationsCount || unmanagedInstallationsCount) {
                const unprocessableInstallationIds = [
                    ...inoperableInstallationIds,
                    ...unmanagedInstallationIds,
                ];
                handleUnprocessableInstallationsModalVisibility(unprocessableInstallationIds, {
                    isSingleOperationAllowed: false,
                });
            }
        },
    });

    const handleSave = (request: SaveInstallationSettingsRequest) => {
        saveSettings({
            installationIds,
            ...request,
        });
    };

    const getUnprocessableInstallationsText = () => {
        if (unmanagedInstallationsCount && unoperableInstallationsCount) {
            return 'subTitle.massUnmanagedAndUnoperableSitesWillBeSkiped';
        }
        if (unmanagedInstallationsCount) {
            return 'subTitle.massUnmanagedSitesWillBeSkiped';
        }
        if (unoperableInstallationsCount) {
            return 'subTitle.massUnoperableSitesWillBeSkiped';
        }
        return '';
    };

    const renderSubtitle = () => {
        if (!installations) {
            return <Skeleton height={15} />;
        }

        const unprocessableInstallationsText = getUnprocessableInstallationsText();

        return (
            <>
                {translate('subTitle.mass', { count: processableInstallationsCount })}{' '}
                {unprocessableInstallationsText &&
                    translate(unprocessableInstallationsText, {
                        unoperableCount: unoperableInstallationsCount,
                        unmanagedCount: unmanagedInstallationsCount,
                    })}
            </>
        );
    };

    return (
        <SettingsDrawer
            {...props}
            isMassOperation
            isError={isError}
            isLoading={areIntallationsLoading}
            error={error}
            onClose={onClose}
            isSaving={isSaving}
            formValuesDefaultState={formValuesDefaultState}
            subtitle={renderSubtitle()}
            confirmationPopoverMessage={translate('confirmationPopover.description.mass', {
                count: processableInstallationsCount,
            })}
            onSave={handleSave}
        />
    );
};

export default MassSettingsDrawer;
