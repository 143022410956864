// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { Features } from '@platform360/libs/shared-web/user/user-state';

const useFeatureFlagEnabled = <T extends keyof Features>(featureFlag: T): Features[T] => {
    const currentUser = useCurrentUser();

    // TODO Rework the hook to better types
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!currentUser) {
        // @ts-expect-error
        return false;
    }

    return currentUser.features[featureFlag];
};

export default useFeatureFlagEnabled;
