// Copyright 2024. WebPros International GmbH. All rights reserved.

import { expected } from './ka/expectedErrorsInResponse';
import { captureException } from '@sentry/browser';
import { toaster } from '@platform360/libs/shared-web/toaster';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';
import { takeRequestIdFromAxiosError } from '@platform360/libs/shared-web/helpers/takeRequestIdFromAxiosError';

// eslint-disable-next-line deprecation/deprecation
const axiosErrorHandler = (error: UntypedAxiosError, expectedErrors: string[] = expected) => {
    const requestId = takeRequestIdFromAxiosError(error);
    let namedError = requestId ? 'errorKAUnavailableWithReqId' : 'errorKAUnavailable';

    if (expectedErrors.includes(error.response?.data.details)) {
        namedError = error.response?.data.details;
    }

    captureException(error);
    // eslint-disable-next-line deprecation/deprecation
    toaster.error(<Translate content={namedError} params={{ requestId }} />);
};

export default axiosErrorHandler;
