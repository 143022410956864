// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'As Ferramentas 360',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Inventário do servidor',
            description: 'Todas as instâncias registradas de um único local e um conjunto de credenciais',
        },
        licenseManagement: {
            title: 'Gerenciamento de licenças',
            description: 'Compre e gerencie todas as suas licenças do Plesk, suas extensões e 360 Tools',
        },
        feedback: {
            title: 'Envie seus comentários',
            description: 'Isso nos ajudará com o desenvolvimento futuro',
        },
        websiteInventory: {
            title: 'Inventário do Website',
            description: 'Visualize e gerencie todos os seus websites',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Monitore seus servidores e websites a partir de um único painel',
            description: 'Solução centralizada para acompanhar o desempenho e evitar o tempo de inatividade, que oferece painéis configuráveis, alertas personalizados, verificações de um minuto, histórico de rede e muito mais',
            primaryButton: 'Experimente gratuitamente por 14 dias',
            secondaryButton: 'Saiba mais',
        },
        licenses: {
            title: 'Gerencie suas licenças',
            description: 'Compre, ajuste e encerre todas as suas licenças do Plesk, suas extensões e 360 Tools em um só lugar',
            primaryButton: 'Vamos lá',
            secondaryButton: 'Saiba mais',
        },
        serverInventory: {
            title: 'Todas as instâncias registradas de um único local e um conjunto de credenciais',
            description: 'Conecte todos os seus servidores Plesk ao inventário do servidor para obter a lista completa de seus websites e organizar um acesso com um clique a eles',
            primaryButton: 'Conectar servidores',
            secondaryButton: 'Saiba mais',
        },
        websiteInventory: {
            title: 'Todos os seus websites e domínios em uma única lista',
            description: 'Visualize, pesquise, filtre seus websites e gerencie-os em um clique',
            primaryButton: 'Ver websites',
            secondaryButton: 'Saiba mais',
        },
    },
};