// Copyright 2024. WebPros International GmbH. All rights reserved.

import Keys from '@platform360/licenses/web/api/ka/responseTypes/Keys';

const getSubscriptionIdByLicenseNumber = (
    keys?: Keys | null,
    licenseNumberForAdjust?: number | null,
) => {
    if (keys) {
        for (const element of Object.values(keys)) {
            if (element.mainKey.id === licenseNumberForAdjust) {
                return element.mainKey.componentInfo?.subscriptionId;
            }

            for (const additionalKey of Object.values(element.additionalKeys)) {
                if (additionalKey.id === licenseNumberForAdjust) {
                    return additionalKey.componentInfo?.subscriptionId;
                }
            }
        }
    }
    return null;
};

export default getSubscriptionIdByLicenseNumber;
