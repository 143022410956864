// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';

export type RevokeInvitationsOperationContainerProps = {
    selectedEmail: string;
    totalSelected?: number;
    loading: boolean;
    onRevoke: () => Promise<void>;
};

export const RevokeInvitationsOperationContainer = ({
    selectedEmail,
    totalSelected = 1,
    loading,
    onRevoke,
    ...props
}: RevokeInvitationsOperationContainerProps) => {
    const translate = useTranslate('Team.Invitations.RevokeInvitationsOperation');
    const { successToast } = useToaster();

    const handleClick = async () => {
        await onRevoke();

        successToast(
            translate('successRevokeInvitation', {
                email: selectedEmail,
                count: totalSelected,
            }),
            { autoCloseTimeout: 3000 },
        );
    };

    return (
        <ConfirmationPopover
            onClick={handleClick}
            target={
                <NoSelectionPopover
                    selectionCount={totalSelected}
                    target={
                        <Button
                            state={loading ? 'loading' : undefined}
                            data-type="revoke-invitations-button"
                        >
                            {translate('revoke')}
                        </Button>
                    }
                >
                    {translate('noSelectedInvitations')}
                </NoSelectionPopover>
            }
            actionButtonText={translate('revoke')}
            {...props}
        >
            <Paragraph>
                {translate('revokeInvitationConfirmation', {
                    email: selectedEmail,
                    count: totalSelected,
                })}
            </Paragraph>
        </ConfirmationPopover>
    );
};

export default RevokeInvitationsOperationContainer;
