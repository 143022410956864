// Copyright 2024. WebPros International GmbH. All rights reserved.

import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';

// eslint-disable-next-line deprecation/deprecation
export const takeRequestIdFromAxiosError = (error: UntypedAxiosError): string | undefined =>
    // There is undefined headers object in some cypress test, because of intercept.
    // That's why this nullish safe assertion required.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    error.response?.headers?.['x-request-id'];
