// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Form, FormInstanceHandles, FormProps, FormValues } from '@plesk/ui-library';
import { ReactNode, useContext, useEffect, useRef } from 'react';
import { StepFormContext } from '@platform360/server-inventory/web/components/Servers/AddingServer/StepForm/StepFormContext';

export type StepFormProps<FV extends FormValues> = {
    title?: ReactNode;
    subTitle?: ReactNode;
    closeButton?: { children: ReactNode; onClick: () => void; 'data-type'?: unknown };
    backButton?: {
        children: ReactNode;
        onClick: () => void;
        arrow?: 'backward';
        'data-type'?: unknown;
    };
    forwardButton?: { children: ReactNode; arrow?: 'forward'; 'data-type'?: unknown };
} & Omit<FormProps<FV>, 'cancelButton' | 'applyButton' | 'submitButton' | 'additionalButtons'>;

export const StepForm = <FV extends FormValues>({
    title,
    subTitle,
    closeButton,
    backButton,
    forwardButton,
    ...props
}: StepFormProps<FV>) => {
    const { setStepTitle, setStepSubTitle } = useContext(StepFormContext);
    const formRef = useRef<FormInstanceHandles>(null);
    const handleSubmit = () => formRef.current?.submit();

    useEffect(() => {
        setStepTitle(title);
    }, [setStepTitle, title]);

    useEffect(() => {
        setStepSubTitle(subTitle);
    }, [setStepSubTitle, subTitle]);

    return (
        <Form
            vertical
            {...props}
            ref={formRef}
            submitButton={false}
            applyButton={false}
            cancelButton={false}
            additionalButtons={[
                closeButton ? (
                    <Button key="close" disabled={!!props.state} {...closeButton} />
                ) : undefined,
                backButton ? (
                    <Button key="back" disabled={!!props.state} arrow="backward" {...backButton} />
                ) : undefined,
                forwardButton ? (
                    <Button
                        key="submit"
                        disabled={!!props.state}
                        state={props.state === 'submit' ? 'loading' : undefined}
                        intent="primary"
                        arrow="forward"
                        onClick={handleSubmit}
                        {...forwardButton}
                    />
                ) : undefined,
            ].filter((btn) => btn)}
        />
    );
};
