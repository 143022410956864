// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveSettings } from '@platform360/security-dashboard/web/api/settings';
import { useUpdateSettingsQuery } from '@platform360/security-dashboard/web/queries';
import useServersQuery from '@platform360/security-dashboard/web/queries/useServersQuery';
import useSettingsQuery from '@platform360/security-dashboard/web/queries/useSettingsQuery';

type UseSaveSettingsMutationOptions = {
    onSuccess?: () => void;
    onError?: () => void;
};

const useSaveSettingsMutation = ({ onSuccess, onError }: UseSaveSettingsMutationOptions = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: saveSettings,
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: useUpdateSettingsQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                queryClient.invalidateQueries({
                    queryKey: useSettingsQuery.getQueryKey(),
                }),
            ]);

            onSuccess?.();
        },
        onError,
    });
};

export default useSaveSettingsMutation;
