// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { safeUpdateOptionValues, SafeUpdateOptions, isSafeUpdateOption } = makeEnum(
    'SafeUpdateOption',
    // Defined on site level.
    'not-set',
    // Enable safe updates.
    'enabled',
    // Disable safe updates.
    'disabled',
    // Do not touch the current setting.
    'do-not-touch',
);

export type SafeUpdateOption = UnionOf<typeof safeUpdateOptionValues>;
