// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

const SEARCH_DEBOUNCE_MS = 300;

const useSearch = (onSearch: () => void = () => {}) => {
    const [value, setValue] = useState('');
    const [debouncedValue] = useDebounceValue<string>(value, SEARCH_DEBOUNCE_MS);

    useEffect(() => {
        setValue(debouncedValue);

        if (debouncedValue !== '') {
            onSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);
    const handleSearch = (newValue: string) => {
        setValue(newValue);
    };

    return { value, debouncedValue, onSearch: handleSearch };
};

export default useSearch;
