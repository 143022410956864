// Copyright 2024. WebPros International GmbH. All rights reserved.

import { BEGIN_ADJUST_BY_KEY } from '@platform360/licenses/web/constants/actions';

export type BeginAdjustByKey = {
    type: typeof BEGIN_ADJUST_BY_KEY;
    payload: number;
};

export const beginAdjustByKey = (licenseId: number): BeginAdjustByKey => ({
    type: BEGIN_ADJUST_BY_KEY,
    payload: licenseId,
});
