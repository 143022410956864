// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Layout, TranslationProps } from '@plesk/ui-library';
import { ReactNode } from 'react';
import { TabProps } from './Tabs';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import Header from './Header';
import TopMessages from '@platform360/libs/shared-web/components/Page/TopMessages';
import ZendeskChat from '@platform360/libs/shared-web/components/ZendeskChat';
import ContentHeader from './ContentHeader';
import ImpersonationBanner from './ImpersonationBanner';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

type PageProps = {
    title?: TranslationProps;
    tabs?: TabProps[];
    contentAddon?: ReactNode;
    children: ReactNode;
    hideSupportChat?: boolean;
};

const Page = ({ title, children, tabs, contentAddon, hideSupportChat }: PageProps) => {
    const { footer, zendeskChat } = useApplicationSettings();
    const {
        name,
        email,
        features: { hiddenText, topMessages },
    } = useCurrentUser();

    return (
        <>
            <Layout
                header={
                    <>
                        <ImpersonationBanner />
                        <TopMessages messages={topMessages} />
                        <Header />
                    </>
                }
                contentHeader={
                    <ContentHeader contentAddon={contentAddon} title={title} tabs={tabs} />
                }
            >
                {children}
                {!hideSupportChat && !!zendeskChat && (
                    <ZendeskChat options={zendeskChat} customer={{ name, email }} />
                )}
                {hiddenText ? (
                    <div data-type="hidden-text" style={{ display: 'none' }}>
                        {hiddenText}
                    </div>
                ) : null}
            </Layout>
            {/* Footer is outside of Layout to be page wide instead of central column wide */}
            {footer}
        </>
    );
};

export default Page;
