// Copyright 2024. WebPros International GmbH. All rights reserved.

export const NAVIGATE_EVENT = 'navigate';

const middleware = () => (next) => (action) => {
    const { meta, payload } = action;
    if (meta && meta.history) {
        const { url, replace = false } = payload;
        window.dispatchEvent(
            new CustomEvent(NAVIGATE_EVENT, {
                cancelable: false,
                detail: {
                    url,
                    replace,
                },
            }),
        );

        return undefined;
    }
    return next(action);
};

export default middleware;
