// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentProps } from 'react';
import classNames from 'classnames';
import style from './LicenseList.module.css';
import { Button, Label, Link, Panel, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import List from '@platform360/libs/shared-web/components/List';
import { Link as RouterLink } from 'react-router-dom';
import { SALES_LINK } from '@platform360/security-dashboard/shared/external-links';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import { useLicenseTranslate } from '@platform360/security-dashboard/web/hooks/useLicenseTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { getRecommendedRetailLicense } from '@platform360/security-dashboard/web/helpers/getRecommendedRetailLicense';
import getLicenseData, {
    LicenseData,
} from '@platform360/security-dashboard/web/components/LicensePage/LicenseList/getLicenseData';
import UpgradeButton from '@platform360/security-dashboard/web/components/LicensePage/UpgradeButton';
import { useKaKey } from '@platform360/security-dashboard/web/hooks/useKaKey';

type LicenseListProps = {
    totalInstallationsCount: number;
    onFaqLinkClick: () => void;
};

const Column = ({
    className,
    disabled,
    ...props
}: ComponentProps<typeof Text> & { disabled?: boolean }) => (
    <Text
        component="div"
        className={classNames(className, { [style.disabled ?? '']: disabled })}
        {...props}
    />
);

const LicenseList = ({ totalInstallationsCount, onFaqLinkClick }: LicenseListProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.LicensePage.LicenseList');
    const translateLicense = useLicenseTranslate();

    const license = useLicenseQuery();
    const { isDeactivated: isCurrentLicenseDeactivated, isReactivatePossible } = useKaKey();

    const recommendedRetailLicense = getRecommendedRetailLicense(totalInstallationsCount);

    const currentActiveProduct =
        license.data && license.data.status !== 'terminated' ? license.data.product : null;

    const licenseData = getLicenseData({
        currentActiveProduct,
        recommendedRetailLicense,
    });

    return (
        <div data-type="license-offer-list">
            <List<LicenseData>
                rowKey="key"
                className={style.licenseList}
                columns={[
                    {
                        key: 'name',
                        type: 'title',
                        title: translate('columns.license'),
                        width: '50%',
                        render: ({ key, recommended, active, disabled }) => (
                            <Column className={style.licenseName} disabled={disabled}>
                                <Text bold>{translateLicense(key)}</Text>
                                {active && !isCurrentLicenseDeactivated && (
                                    <Label intent="inactive" view="light" size="md">
                                        {translate('activeLicense')}
                                    </Label>
                                )}
                                {active && isCurrentLicenseDeactivated && (
                                    <Label intent="inactive" view="light" size="md">
                                        {translate('canceled')}
                                    </Label>
                                )}
                                {recommended && !isCurrentLicenseDeactivated && (
                                    <Label intent="success" view="light" size="md">
                                        {translate('recommendedToYou')}
                                    </Label>
                                )}
                            </Column>
                        ),
                    },
                    {
                        key: 'sites',
                        title: translate('columns.sitesIncluded'),
                        align: 'right',
                        render: ({ sites, disabled }) => (
                            <Column disabled={disabled}>{translate('sites', { sites })}</Column>
                        ),
                    },
                    {
                        key: 'price',
                        title: translate('columns.pricePerSite'),
                        render: ({ pricePerSite, disabled }) => (
                            <Column disabled={disabled} intent="muted">
                                {translate('pricePerSite', { price: pricePerSite })}
                            </Column>
                        ),
                    },
                    {
                        key: 'pricePerMonth',
                        title: translate('columns.pricePerMonth'),
                        render: ({ pricePerMonth, disabled }) => (
                            <Column disabled={disabled} bold>
                                {translate('pricePerMonth', { price: pricePerMonth })}
                            </Column>
                        ),
                    },
                    {
                        key: 'actions',
                        align: 'center',
                        render: ({ key, disabled, active, upgradable, productNamePrefix }) => {
                            if (isCurrentLicenseDeactivated) {
                                if (!active) return null;

                                return (
                                    <Button
                                        intent="primary"
                                        component={RouterLink}
                                        to="/licensing/licenses"
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        {isReactivatePossible
                                            ? translate('reactivate')
                                            : translate('details')}
                                    </Button>
                                );
                            }

                            if (disabled || active) return null;

                            if (upgradable) {
                                return (
                                    <UpgradeButton
                                        productNamePrefix={productNamePrefix}
                                        onClick={() => {
                                            analyticsEvents.wpLicensePageLicenseRetailListUpgradeClicked(
                                                key,
                                            );
                                        }}
                                    />
                                );
                            }

                            return (
                                <Button
                                    intent="primary"
                                    component={RouterLink}
                                    to={`/security-dashboard/license-page/buy/${key}`}
                                    onClick={() =>
                                        analyticsEvents.wpLicensePageLicenseRetailListBuyClicked(
                                            key,
                                        )
                                    }
                                >
                                    {translate('buyButton')}
                                </Button>
                            );
                        },
                    },
                ]}
                data={licenseData}
                data-type="license-list"
            />
            <Panel>
                <div className={style.licenseOffer}>
                    <Text>{translate('licenseOffer')}</Text>
                    <span>
                        {translate('pricingLink', {
                            partnerLink: (partnerLinkText) => (
                                <Link
                                    href={SALES_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        analyticsEvents.wpLicensePageLicenseRetailLinkBecomePartnerClicked()
                                    }
                                >
                                    {partnerLinkText}
                                </Link>
                            ),
                            faqLink: (faqLinkText) => (
                                <Link onClick={onFaqLinkClick}>{faqLinkText}</Link>
                            ),
                        })}
                    </span>
                </div>
            </Panel>
        </div>
    );
};

export default LicenseList;
