// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getNotifications } from '@platform360/security-dashboard/web/api/notifications';

export type UseNotificationsQueryVariables = undefined;

export type UseNotificationsQueryData = PromiseValue<ReturnType<typeof getNotifications>>;

const useNotificationsQuery = createQuery<
    UseNotificationsQueryVariables,
    UseNotificationsQueryData
>({
    queryName: 'security-dashboard/useNotificationsQuery',
    fetcher: getNotifications,
    useQuery,
});

export default useNotificationsQuery;
