// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { ApplicationSettings, ApplicationSettingsContext } from './context';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import getUrlPathname from '@platform360/libs/shared-web/helpers/getUrlPathname';
import MonitoringLogo from '@platform360/libs/shared-web/components/MonitoringLogo';
import {
    PRODUCT_WP_GUARDIAN_1,
    PRODUCT_WP_GUARDIAN_5,
    PRODUCT_WP_GUARDIAN_10,
    PRODUCT_WP_GUARDIAN_20,
    PRODUCT_WP_GUARDIAN_30,
    PRODUCT_WP_GUARDIAN_50,
    PRODUCT_WP_GUARDIAN_1_RETAIL,
    PRODUCT_WP_GUARDIAN_5_RETAIL,
    PRODUCT_WP_GUARDIAN_10_RETAIL,
    PRODUCT_WP_GUARDIAN_30_RETAIL,
    PRODUCT_WP_GUARDIAN_50_RETAIL,
    PRODUCT_WP_GUARDIAN_100,
    PRODUCT_WP_GUARDIAN_500,
    PRODUCT_WP_GUARDIAN_1000,
    PRODUCT_WP_GUARDIAN_10000,
    PRODUCT_WP_GUARDIAN_UNLIMITED_30,
    PRODUCT_WP_GUARDIAN_UNLIMITED,
} from '@platform360/libs/shared-web/constants/products';
import getUrlSearchParams from '@platform360/libs/shared-web/helpers/getUrlSearchParams';
import { Application } from '@platform360/libs/common/application';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const MonitoringTermsOfUseLink = () => {
    const translate = useTranslate('Auth.SignUp');

    return translate('termsMonitoring');
};

const mergeMonitoringAppSettings = (
    appSettings: ApplicationSettings,
    auth: Partial<ApplicationSettings['auth']> = {},
): ApplicationSettings => ({
    ...appSettings,
    logo: <MonitoringLogo />,
    document: {
        darkThemeDataAttribute: 'legacy-dark',
        title: '360 Monitoring',
        favicon: '/favicon-360-monitoring.ico',
    },
    auth: {
        ...appSettings.auth,
        branding: 'monitoring',
        areConsentsShown: true,
        preferredSignup: false,
        signUpEmailFieldDescriptionShown: false,
        termsOfUseLink: <MonitoringTermsOfUseLink />,
        ...auth,
    },
});

export const handleAuthPath = (
    applicationSettingsContext: Record<Application, ApplicationSettings>,
    app: Application,
    pathname: string,
    searchParams: URLSearchParams,
): ApplicationSettings => {
    const appSettings = applicationSettingsContext[app];
    // The query parameter "product" is passed for "/licensing/activate-license" route and contains a product name from KA license
    const product = searchParams.get('product') || 'unknown';

    switch (pathname) {
        case '/digitalocean/login':
            return mergeMonitoringAppSettings(appSettings);
        case '/digitalocean/sign-up':
            return mergeMonitoringAppSettings(appSettings, {
                preferredSignup: true,
                acquisitionSource: 'digital-ocean',
            });
        case '/monitoring/promo':
            return mergeMonitoringAppSettings(appSettings, {
                preferredSignup: true,
                acquisitionSource: 'monitoring-promo',
            });
        case '/licensing/activate-license':
            if (
                [
                    PRODUCT_WP_GUARDIAN_1,
                    PRODUCT_WP_GUARDIAN_5,
                    PRODUCT_WP_GUARDIAN_10,
                    PRODUCT_WP_GUARDIAN_20,
                    PRODUCT_WP_GUARDIAN_30,
                    PRODUCT_WP_GUARDIAN_50,
                    PRODUCT_WP_GUARDIAN_1_RETAIL,
                    PRODUCT_WP_GUARDIAN_5_RETAIL,
                    PRODUCT_WP_GUARDIAN_10_RETAIL,
                    PRODUCT_WP_GUARDIAN_30_RETAIL,
                    PRODUCT_WP_GUARDIAN_50_RETAIL,
                    PRODUCT_WP_GUARDIAN_100,
                    PRODUCT_WP_GUARDIAN_500,
                    PRODUCT_WP_GUARDIAN_1000,
                    PRODUCT_WP_GUARDIAN_10000,
                    PRODUCT_WP_GUARDIAN_UNLIMITED_30,
                    PRODUCT_WP_GUARDIAN_UNLIMITED,
                ].includes(product)
            ) {
                return {
                    ...applicationSettingsContext['security-dashboard'],
                    auth: {
                        ...applicationSettingsContext['security-dashboard'].auth,
                        areConsentsShown: false,
                        preferredSignup: true,
                    },
                };
            }
            return mergeMonitoringAppSettings(appSettings, {
                areConsentsShown: false,
                preferredSignup: true,
            });
        default:
            return appSettings;
    }
};

const useApplicationSettings = (): ApplicationSettings => {
    const { application } = useConfig();
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl') ?? '';
    const { pathname, search } = useLocation();
    const applicationSettingsContext = useContext(ApplicationSettingsContext);
    const authPath = pathname.startsWith('/auth/') ? getUrlPathname(redirectUrl) : pathname;
    const authSearchParams = pathname.startsWith('/auth/')
        ? getUrlSearchParams(redirectUrl)
        : searchParams;

    if (applicationSettingsContext === undefined) {
        throw new Error('Application settings context must be initialized.');
    }

    // Workaround for Development Mode (`yarn:dev`):
    // In the development environment, the application name is inferred based on the URL sub-path.
    const app =
        pathname.startsWith('/security-dashboard') || search.includes('security-dashboard')
            ? 'security-dashboard'
            : application;

    // Adjustment for Development Mode (`yarn:dev`):
    // In a production environment, the `className` of the `<html>` element matches the current application.
    // However, in `yarn:dev`, the current application defaults to the platform. This means we need to dynamically
    // override the `className` to ensure styles, especially those of the security dashboard, render correctly
    // during Cypress tests.
    if (document.documentElement.className !== app) {
        document.documentElement.className = app;
    }

    return handleAuthPath(applicationSettingsContext, app, authPath, authSearchParams);
};

export default useApplicationSettings;
