// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentProps } from 'react';
import { Button, ProgressStep } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { InstallationResponse } from '@platform360/web-installer/shared/api-contract';
import InstallationErrorMessage from '@platform360/web-installer/web/components/InstallationErrorMessage';

const useProgressStep = (
    info?: InstallationResponse,
): ComponentProps<typeof ProgressStep> | null => {
    const translate = useTranslate('web-installer.Installation');

    if (!info) {
        return null;
    }

    const { host, loginLink } = info.params || {};

    switch (info.status) {
        case 'running':
            return {
                title: translate('steps.running.title'),
                status: 'running',
                children: host ? translate('steps.running.description', { host }) : null,
            };
        case 'error':
            return {
                title: translate('steps.error.title'),
                status: 'error',
                children: <InstallationErrorMessage host={host || ''} error={info.error ?? null} />,
            };
        case 'completed':
            return {
                title: translate('steps.completed.title'),
                status: 'done',
                children: (
                    <>
                        {host && translate('steps.completed.pleskHostText', { host })}{' '}
                        {loginLink && translate('steps.completed.goToPleskText')}
                        {loginLink && (
                            <div>
                                <br />
                                <Button
                                    component="a"
                                    href={loginLink}
                                    target="_blank"
                                    intent="primary"
                                    rel="noopener noreferrer"
                                >
                                    {translate('steps.completed.goToPleskButton')}
                                </Button>
                            </div>
                        )}
                    </>
                ),
            };
        default:
            return null;
    }
};

export default useProgressStep;
