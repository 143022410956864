// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import Cookies from 'js-cookie';
import { captureMessage } from '@sentry/browser';
import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const EXTERNAL_REDIRECT_MAX_COUNT = 3;
export const EXTERNAL_REDIRECT_COOKIE_NAME_PREFIX = 'external-redirect:';
export const EXTERNAL_REDIRECT_COOKIE_EXPIRATION_DELAY = 10;

export const useExternalRedirect = () => {
    const translate = useTranslate('common.errors');
    const [redirectError, setRedirectError] = useState<TranslationProps | undefined>();
    const externalRedirect = (url: string) => {
        const cookieName = `${EXTERNAL_REDIRECT_COOKIE_NAME_PREFIX}${url}`;
        const redirectsCount = Number(Cookies.get(cookieName) ?? '0');

        if (redirectsCount >= EXTERNAL_REDIRECT_MAX_COUNT) {
            setRedirectError(translate('externalRedirect'));
            captureMessage('Too many external redirects.', {
                extra: {
                    url,
                },
            });
            return;
        }

        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + EXTERNAL_REDIRECT_COOKIE_EXPIRATION_DELAY);
        Cookies.set(cookieName, (redirectsCount + 1).toString(), { expires });
        window.location.href = url;
    };

    return { externalRedirect, redirectError };
};
