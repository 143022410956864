// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, Button, FormField, Popover } from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { BuyInfo as CbBuyInfo } from '@platform360/licenses/web/units/cleverbridge';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const ConfirmPopoverButton = ({
    priceCalculation = null,
    offer = null,
    priceCalculationError = null,
    onConfirmProgress,
    onSubscriptionItemConfirm,
    disableUpgrade = false,
}) => {
    const translate = useTranslate();

    return (
        <Popover
            key="popover"
            title={translate('confirmationPopupTitle')}
            target={
                <Button
                    data-type="Upsell--upsellAccept"
                    intent="primary"
                    size="lg"
                    disabled={
                        !(
                            priceCalculation &&
                            offer &&
                            priceCalculationError !== 'pendingOperationFound'
                        ) || disableUpgrade
                    }
                >
                    {translate('Keys.general.confirm')}
                </Button>
            }
            placement="bottom"
        >
            <Form
                hideRequiredLegend
                cancelButton={false}
                applyButton={false}
                submitButton={{
                    'data-type': 'Uupsell--upsellConfirm',
                    intent: 'success',
                    children: translate('Keys.general.buyNow'),
                }}
                onSubmit={() => {
                    onConfirmProgress();
                    onSubscriptionItemConfirm();
                }}
            >
                <FormField label={<CbBuyInfo />} />
            </Form>
        </Popover>
    );
};

ConfirmPopoverButton.propTypes = {
    offer: PropTypes.object,
    priceCalculation: PropTypes.object,
    priceCalculationError: PropTypes.string,
    onConfirmProgress: PropTypes.func.isRequired,
    onSubscriptionItemConfirm: PropTypes.func.isRequired,
    disableUpgrade: PropTypes.bool,
};
