// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AuthState, Session } from './auth-state';

// Event is used to dispatch reducer actions using async data flow.
// It's necessary for refresh token operation.
export const EVENT_NAME = 'platform-auth';

export type Action = { type: 'LOGIN' | 'UPDATE_SESSION'; session: Session } | { type: 'LOGOUT' };

/**
 * Handles how that state changes in the `useAuth` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'LOGIN':
        case 'UPDATE_SESSION':
            return {
                ...state,
                session: action.session,
                isAuthorized: !!action.session,
            };
        case 'LOGOUT':
            return {
                session: undefined,
                isAuthorized: false,
            };
        default:
            return state;
    }
};

/**
 * Produces custom event based async redux updates
 */
export const dispatchAction = (action: Action) => {
    document.dispatchEvent(
        new CustomEvent(EVENT_NAME, {
            cancelable: false,
            detail: action,
        }),
    );
};
