// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'È stata rilevata una chiave pubblica al posto di quella privata prevista.',
        passphrasePrivateKeyError: 'Fornisci una chiave non protetta da passphrase.',
        unsupportedPrivateKeyError: 'Il tipo di chiave fornito non è supportato.',
        invalidPrivateKeyError: 'Assicurati di utilizzare la chiave privata SSH corretta.',
        emptyPrivateKeyError: 'La chiave privata SSH fornita non è valida perché è vuota.',
        tooBigPrivateKeyError: 'Fornisci un file chiave di dimensioni inferiori a 30 KB.',
    },
    Installation: {
        startAnotherInstallation: 'Avvia un\'altra installazione',
        steps: {
            running: {
                title: 'Plesk è in fase d\'installazione',
                description: 'Installazione di Plesk su <b>{host}</b>',
            },
            error: {
                title: 'Errore d\'installazione',
            },
            completed: {
                title: 'Installazione completata',
                pleskHostText: 'Plesk è stato installato su {host}.',
                goToPleskText: 'Clicca su "Vai a Plesk" per accedere. Dopo un breve processo di configurazione, potrai iniziare a utilizzare Plesk.',
                goToPleskButton: 'Vai a Plesk',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Server disconnesso',
            description: 'La connessione SSH al server è stata interrotta.',
        },
        connectFail: {
            title: 'Connessione al server non riuscita',
            description: '<p>Impossibile connettersi al server: {details}.</p><p>Se il server utilizza una porta SSH personalizzata, specificala aggiungendo i due punti e il numero della porta dopo il nome dell\'host o l\'indirizzo IP (per es.: esempio.com:1024 oppure 192.0.2.0:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk è già installato',
            description: 'Si è verificato un errore imprevisto durante il rilevamento dell\'installazione di Plesk: {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com non è disponibile',
            description: 'Impossibile recuperare i distributivi di Plesk. Il sito web autoinstall.plesk.com non è disponibile: {details}.',
        },
        pleskInstalled: {
            title: 'Plesk è installato',
            description: 'Web Installer installa solo {productType} e <b>{host}</b> ha già installato la versione stabile di Plesk. {productType, select, Plesk {Ti aspettavi che facesse qualcos\'altro? Faccelo sapere <surveyLink>rispondendo a questa domanda</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'Upgrade disponibile',
            description: 'Web Installer installa solo {productType} e <b>{host}</b> ha già una versione di Plesk installata che non corrisponde alla versione stabile più recente. {productType, select, Plesk {Ti aspettavi che Plesk Web Installer la aggiornasse? Faccelo sapere <surveyLink>rispondendo a questa domanda</surveyLink>.} other {}}',
        },
        unsupportedArch: {
            title: 'Architettura del server non supportata',
            description: 'Stai cercando d\'installare {productType} su un\'architettura non supportata: {arch}.',
        },
        unsupportedOs: {
            title: 'Sistema operativo non supportato',
            description: 'Stai cercando d\'installare {productType} su un sistema operativo (architettura) non supportato: {os}. <supportedOsLink>Scopri l\'elenco dei sistemi operativi supportati</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Spazio su disco insufficiente',
        },
        cpanelInstalled: {
            title: 'cPanel è installato',
            description: 'Web Installer installa {productType} solo su server puliti e <b>{host}</b> ha già cPanel installato. {productType, select, Plesk {Scopri come trasferire o siti web ospitati su Plesk leggendo la nostra <migrationGuideLink>guida sulla migrazione</migrationGuideLink>.} other {Ti aspettavi che facesse qualcos\'altro? Faccelo sapere <surveyLink>rispondendo a questa domanda</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Controlla lo stato del gestore dei pacchetti',
            description: 'Il gestore di pacchetti è occupato o non risponde: {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Configurazione dell\'archivio del gestore di pacchetti non riuscita',
            description: 'Si è verificato un errore imprevisto durante la configurazione dell\'archivio del gestore di pacchetti: {details}.',
        },
        dbConfigurationFailed: {
            title: 'Configurazione del database non riuscita',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'La tua sicurezza è garantita: WebPros non memorizza il tuo login e la tua password dopo l\'installazione.',
            p2: 'Puoi installare {productType} su <a>qualsiasi SO supportato basato su Linux</a>.',
        },
        hostLabel: 'Indirizzo IP o nome host del server',
        portLabel: 'Porta',
        loginLabel: 'Accesso SSH',
        installationMethodLabel: 'Connettiti al server utilizzando',
        sshPasswordLabel: 'Password SSH',
        sshKeyLabel: 'Chiave privata SSH',
        licenseTypeLabel: 'Seleziona il tuo tipo di licenza',
        trialLicenseLabel: 'Continua con una licenza di prova dotata di funzionalità complete',
        trialLicenseDescription: 'Per rilasciare una licenza di prova, WebPros International GmbH utilizzerà e tratterà le informazioni fornite nei propri sistemi interni in conformità con l\'<privacyPolicyLink>Informativa sulla privacy di WebPros</privacyPolicyLink>.',
        paidLicenseLabel: 'Continua con una licenza pagata',
        paidLicenseDescription: 'Se hai già acquistato una licenza di {productType}, incolla qui il codice di attivazione che hai ricevuto. In caso contrario, puoi acquistare una nuova licenza nel nostro <onlineStoreLink>Negozio online</onlineStoreLink>.',
        installOsUpdatesLabel: 'Installa gli aggiornamenti del sistema operativo prima di avviare l\'installazione di {productType}',
        installOsUpdatesDescription: 'Selezionando questa opzione il processo d\'installazione può venire notevolmente rallentato o non riuscire.',
        notice: 'Solitamente l\'installazione richiede circa 15 minuti.',
        techDomainDescription: 'Il tuo server verrà aggiunto con il nome: <b>{techDomain}</b>.',
        serverConnectionSection: 'Connessione al server',
        licenseSection: 'Licenza',
        advancedSection: 'Opzioni avanzate',
        submitButton: 'Avanti',
    },
    ServerInstallationPage: {
        submitButton: 'Avanti',
        panelTitle: 'Installa automaticamente {productType} su un server Linux',
        licenceLabel: 'Licenza di prova',
        installationWillTake: 'Solitamente l\'installazione richiede circa 15 minuti',
        helpUsImprove: 'Aiutaci a migliorare {productType} Web Installer',
        takeOurSurvey: 'Partecipa al sondaggio',
        links: {
            manualInstallationInstructions: 'Istruzioni per l\'installazione manuale',
            softwareRequirements: {
                plesk: 'Requisiti del software',
                cpanel: 'Requisiti di sistema',
            },
            versionsAndComponents: 'Versioni e componenti',
            knownIssues: 'Problemi comuni',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'La tua e-mail',
        legalAgreementTitle: 'Accetto le <termsOfUseLink>Condizioni d\'uso</termsOfUseLink>, l\'<privacyPolicyLink>Informativa sulla privacy</privacyPolicyLink> e acconsento a firmare l\'<dpaLink>Accordo per l\'elaborazione dei dati</dpaLink> di Plesk',
        emailHint: 'Se hai già un account WebPros, usa questa e-mail. Altrimenti verrà creato un account per te.',
        serversMonitoring: 'Monitora la disponibilità, la salute e il rendimento dei server gratuitamente per 1 server e 5 siti web',
        loginNoCredentials: 'Crea il tuo account WebPros per un accesso semplice a tutti i servizi dell\'azienda in un solo clic',
        login: 'Accedi alla tua installazione di {productType} senza inserire le credenziali',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'Nome',
        lastName: 'Cognome',
        submitButton: 'Avvia installazione',
        marketingConsent: {
            title: 'Acconsento alla ricezione da parte di WebPros di newsletter e offerte individuali in conformità con la sua <a>Informativa sulla privacy</a>.',
            text: 'Potrò revocare tale consenso in qualunque momento facendo clic sul link presente nelle comunicazioni che riceverò',
        },
    },
    LightsailInstallation: {
        title: 'Installazione automatica su un server Cloud',
        awsAccessKey: 'ID della chiave di accesso',
        awsSecretKey: 'Chiave di accesso segreta',
        region: 'Regione',
        bundleTitle: 'Piano d\'istanza',
        publicKeyTitle: 'Chiave pubblica SSH',
        instanceName: 'Nome d\'istanza',
        submitButton: 'Continua',
        BundleSelect: {
            price: '${value} al mese', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Memoria: {value} GB',
            storage: 'Spazio di archiviazione: {value} GB',
            transfer: 'Trasferimento: {value} GB',
            descriptions: {
                micro: 'L\'opzione migliore se vuoi provare Plesk.',
                small: 'Per uno o più siti web personali.',
                medium: 'Per i professionisti del web o le agenzie web che lavorano su un piccolo numero di siti web.',
                large: 'Per le grandi agenzie web o per i piccoli hoster che si occupano di più siti web a basso traffico o di un paio di siti ad alto traffico.',
                xlarge: 'Per gli hoster che gestiscono più siti web ad alto traffico e ad alto carico.',
                '2xlarge': 'Per i progetti più ambiziosi.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'Per accedere al tuo account cPanel, fai clic su "Accedi a cPanel". <b>NOTA:</b> il pulsante funzionerà solo una volta!',
        loginButton: 'Accedi a cPanel',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'Domande frequenti sull\'installazione automatica di Plesk',
            versionsAndComponents: 'Versioni e componenti',
            knownIssues: 'Problemi comuni',
            whatVersionIsInstalledTitle: 'Quale versione di Plesk viene installata in modalità automatica?',
            whatVersionIsInstalledText: 'L\'ultima versione stabile di Plesk con tutti gli aggiornamenti disponibili al momento. Per installare un\'altra versione di Plesk, segui le <link>istruzioni per l\'installazione</link>.',
            whatComponentsAreInstalledTitle: 'Quali componenti vengono installati in modalità automatica?',
            whatComponentsAreInstalledText: 'La "preimpostazione consigliata", che include tutti i servizi necessari per l\'hosting dei siti web (Web, DNS, FTP e Posta) insieme ai componenti e alle estensioni più popolari come WordPress Toolkit, Advisor e Let\'s Encrypt.',
            canISelectComponentsTitle: 'Posso selezionare i componenti di Plesk da installare?',
            canISelectComponentsText: 'Al momento non è possibile. Siamo a conoscenza di questa limitazione e abbiamo intenzione di occuparcene in futuro. Per ora, è possibile risolvere il problema aggiungendo o rimuovendo i componenti richiesti al termine del processo di installazione.',
            doesInstalledPleskHaveLicenseTitle: 'La versione installata di Plesk è fornita di licenza?',
            doesInstalledPleskHaveLicenseText: 'Puoi richiedere una prova di 14 giorni dell\'edizione Web Pro dopo il primo accesso a Plesk, se non lo hai fatto in precedenza. Puoi anche utilizzare la licenza acquistata.',
            supportedOSsTitle: 'Quali sistemi operativi sono supportati in modalità automatica? Posso installare Plesk per Windows?',
            supportedOSsText: 'Qualsiasi <a>sistema operativo basato su Linux e supportato da Plesk</a>. Per installare Plesk per Windows, segui le <link>istruzioni per l\'installazione</link>.',
            usePrivateKeyTitle: 'Posso usare una chiave SSH privata e crittografata?',
            usePrivateKeyText: 'Al momento non è possibile. Siamo a conoscenza di questa limitazione e abbiamo intenzione di occuparcene in futuro.',
            isItSafeToSubmitPasswordTitle: 'È sicuro inviare una password o una chiave SSH privata durante l\'installazione di Plesk in modalità automatica?',
            isItSafeToSubmitPasswordText: 'Sì. Queste credenziali vengono utilizzate solo per la connessione al server e l\'installazione di Plesk, perciò non vengono conservate. Tutti i dati vengono trasmessi in sicurezza tramite canali SSH e HTTPS crittografati. Per la massima sicurezza, puoi modificare le credenziali di accesso al server dopo l\'installazione.',
            helpUsMakeWebInstallerBetter: 'Aiutaci a migliorare l\'esperienza con Plesk Web Installer <a>partecipando a un breve sondaggio</a>.',
        },
        cpanel: {
            header: 'Domande frequenti sull\'installazione automatica di cPanel',
            versionsAndComponents: 'Versioni e componenti',
            knownIssues: 'Problemi comuni',
            whatVersionIsInstalledTitle: 'Quale versione di cPanel viene installata in modalità automatica?',
            whatVersionIsInstalledText: 'L\'ultima versione stabile di cPanel con tutti gli aggiornamenti disponibili al momento. Per installare un\'altra versione di cPanel, segui le <link>istruzioni per l\'installazione</link>.',
            canICustomizeParametersOfInstallationTitle: 'Posso personalizzare i parametri di installazione?',
            canICustomizeParametersOfInstallationText: 'Al momento non c\'è modo di personalizzare l\'installazione con Web Installer. Per farlo, segui le istruzioni nella <link>documentazione</link>.',
            doesInstalledCpanelHaveLicenseTitle: 'La versione installata di cPanel è fornita di licenza?',
            doesInstalledCpanelHaveLicenseText: 'Se stai installando una nuova istanza di cPanel e WHM su un server, hai la possibilità di ottenere la licenza di prova di 15 giorni. Segui le istruzioni in <link>questa guida</link> e registrati per una licenza di prova. Puoi anche utilizzare la licenza che hai acquistato.',
            supportedOSsTitle: 'Quali sistemi operativi sono supportati in modalità automatica?',
            supportedOSsText: 'Qualsiasi sistema operativo basato su Linux e <link>supportato da cPanel</link>.',
            usePrivateKeyTitle: 'Posso usare una chiave SSH privata e crittografata?',
            usePrivateKeyText: 'Al momento non è possibile. Siamo a conoscenza di questa limitazione e abbiamo intenzione di occuparcene in futuro.',
            isItSafeToSubmitPasswordTitle: 'È sicuro inviare una password o una chiave SSH privata durante l\'installazione di cPanel in modalità automatica?',
            isItSafeToSubmitPasswordText: 'Sì. Queste credenziali vengono utilizzate solo per la connessione al server e l\'installazione di cPanel, perciò non vengono conservate. Tutti i dati vengono trasmessi in sicurezza tramite canali SSH e HTTPS crittografati. Per la massima sicurezza, puoi modificare le credenziali di accesso al server dopo l\'installazione.',
            helpUsMakeWebInstallerBetter: 'Aiutaci a migliorare l\'esperienza con cPanel Web Installer <a>partecipando a un breve sondaggio</a>.',
        },
    },
};