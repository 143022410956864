// Copyright 2024. WebPros International GmbH. All rights reserved.

import { authorizedRequest, request } from './api';
import { ValidationError } from '@platform360/libs/shared-web/helpers/errors';

const BASE_URL = '/linked-emails';

export const sendVerification = (emails) => () =>
    authorizedRequest({
        url: `${BASE_URL}/verifications`,
        method: 'POST',
        data: {
            emails: emails.map((email) => ({
                email,
            })),
        },
    }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
            throw new ValidationError(error.response.data.errors);
        } else {
            throw error;
        }
    });

export const linkEmail = () =>
    request({
        url: BASE_URL,
        method: 'POST',
    }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
            throw new ValidationError(error.response.data.errors);
        } else {
            throw error;
        }
    });
