// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import monitoring from '@platform360/monitoring/web/api/monitoring';

export type UseMonitoringInfoQueryVariables = undefined;

export type UseMonitoringInfoQueryData = PromiseValue<ReturnType<typeof monitoring.info>>;

const useMonitoringInfoQuery = createQuery<
    UseMonitoringInfoQueryVariables,
    UseMonitoringInfoQueryData
>({
    queryName: 'monitoring/useMonitoringInfoQuery',
    fetcher: monitoring.info,
    useQuery,
});

export default useMonitoringInfoQuery;
