// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';

export class AuthAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    public userComesFromPlesk() {
        this.client.logEvent('The user comes with Adding redirect link');
    }

    public monitoringPromoUserSignUp() {
        this.client.logEvent('User from Monitoring Landing Page Signs Up');
    }

    public monitoringPromoUserLogin() {
        this.client.logEvent('User from Monitoring Landing Page Login');
        this.client.setUserProperties({ MonitoringLPCustomer: 'True' });
    }

    /* Digital Ocean */

    public digitalOceanUserLogin() {
        this.client.logEvent('DO User Login');
        this.client.setUserProperties({ 'DO User': 'True' });
    }

    public digitalOceanUserSignUp() {
        this.client.logEvent('DO User Sign Up');
    }
}
