// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ResetMfaRequest } from '@platform360/accounts/shared/api-contract';

export const enableMfa = async () => {
    await apiClient.post('/accounts/me/mfa/ticket');
};

export const disableMfa = async (payload: ResetMfaRequest) => {
    await apiClient.delete('/accounts/me/mfa', {
        data: payload,
    });
};
