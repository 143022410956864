// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Section, SectionItem } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ChangeableNotificationsEmail from '@platform360/security-dashboard/web/components/ChangeableNotificationsEmail';
import useNotificationsSettingsQuery from '@platform360/security-dashboard/web/queries/useNotificationsSettingsQuery';
import Loading from '@platform360/libs/shared-web/components/Loading';
import { NotificationSettingsEventSection } from '@platform360/security-dashboard/web/components/NotificationSettingsEventSection';

export const Content = () => {
    const translate = useTranslate('security-dashboard.NotificationsSettingsDrawer.Content');
    const { isLoading, data } = useNotificationsSettingsQuery();

    if (isLoading || !data) return <Loading />;

    return (
        <>
            <NotificationSettingsEventSection settings={data} />
            <Heading icon="mail" level={5}>
                {translate('sendSectionTitle')}
            </Heading>
            <Section>
                <SectionItem title={translate('email')}>
                    <ChangeableNotificationsEmail email={data.email} />
                </SectionItem>
            </Section>
        </>
    );
};
