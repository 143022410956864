// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSearchParams, Link, To } from 'react-router-dom';
import { ComponentProps } from 'react';

type DrawerLinkProps = Omit<ComponentProps<typeof Link>, 'to'> & {
    to: string;

    /** Allows to block link navigation in case of disabled button. */
    disabled?: boolean;
};

const mergeUrlSearchParams = (src: URLSearchParams, dst: URLSearchParams): string => {
    for (const [key, value] of dst.entries()) {
        src.set(key, value);
    }

    return src.toString();
};

/**
 * React router {@link Link} wrapper that propagates query params to the URL.
 */
export const DrawerLink = ({ children, disabled, to, ...props }: DrawerLinkProps) => {
    const [searchParams] = useSearchParams();
    const [toPath, toSearch] = to.split('?');
    const mergedSearchParams = mergeUrlSearchParams(searchParams, new URLSearchParams(toSearch));

    const linkTo: To = {
        pathname: toPath,
        search: mergedSearchParams,
    };

    return (
        <Link to={disabled ? '#' : linkTo} {...props}>
            {children}
        </Link>
    );
};
