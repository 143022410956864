// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon } from '@plesk/ui-library';
import ToolbarButton from '@platform360/security-dashboard/web/components/ToolbarButton';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Vulnerability } from '@platform360/security-dashboard/web/types';
import { ignoreVulnerabilityLabelTypes } from '@platform360/security-dashboard/shared/label-type';

type IgnoreButtonProps = {
    selectedItems: string[];
    vulnerabilities: Vulnerability[];
    onIgnore: (vulnerabilitiesIds: string[]) => void;
    onCancelIgnore: (vulnerabilitiesIds: string[], labelIds: number[]) => void;
};

export const IgnoreButton = ({
    selectedItems,
    vulnerabilities,
    onIgnore,
    onCancelIgnore,
}: IgnoreButtonProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Toolbar.IgnoreButton');

    const ignored = vulnerabilities.filter(
        ({ vulnerabilityId, labels }) =>
            selectedItems.includes(vulnerabilityId) &&
            labels.some(({ type }) => ignoreVulnerabilityLabelTypes.includes(type)),
    );

    const handleCancelIgnore = () => {
        const labelIdSet = new Set<number>();

        ignored.forEach((vulnerability) => {
            vulnerability.labels
                .filter(({ type }) => ignoreVulnerabilityLabelTypes.includes(type))
                .forEach(({ id }) => labelIdSet.add(id));
        });

        onCancelIgnore(
            ignored.map(({ vulnerabilityId }) => vulnerabilityId),
            [...labelIdSet],
        );
    };

    const hasIgnored = ignored.length > 0;

    return (
        <ToolbarButton
            selectedItems={selectedItems}
            noSelectionText={translate('noSelection')}
            buttonText={translate('ignore')}
            onClick={hasIgnored ? handleCancelIgnore : () => onIgnore(selectedItems)}
            caret={hasIgnored && <Icon name="cross-mark" />}
        />
    );
};

export default IgnoreButton;
