// Copyright 2024. WebPros International GmbH. All rights reserved.

import LoginForm from './LoginForm';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { useOutletContext } from 'react-router-dom';
import { AuthOutletContext } from '@platform360/auth/web/types';

export const Login = () => {
    const { setEmail } = useOutletContext<AuthOutletContext>();

    const { login, error } = useAuth();
    const {
        auth: { branding },
    } = useApplicationSettings();

    const handleLogin = async (email: string) => {
        await login({
            email,
            branding,
        });
    };

    return (
        <LoginForm onEmailChange={setEmail} onSubmit={handleLogin} errorMessage={error?.message} />
    );
};

export default Login;
