// Copyright 2024. WebPros International GmbH. All rights reserved.

// eslint-disable-next-line no-restricted-imports
import { useTranslate as useBaseTranslate, Text, TranslationProps } from '@plesk/ui-library';
import React, { ReactNode, useCallback } from 'react';
import IntlMessageFormat, {
    PrimitiveType,
    FormatXMLElementFn,
    ErrorCode,
} from 'intl-messageformat';
import useLocale from '@platform360/libs/shared-web/locale/useLocale';
import { captureMessage, captureException } from '@sentry/browser';

type TranslationParams = Record<string, PrimitiveType | ReactNode | FormatXMLElementFn<ReactNode>>;

export type TranslateFunction = (
    translationKey: string,
    params?: TranslationParams,
    fallback?: string,
) => TranslationProps;

export const useTranslate = (namespace?: string): TranslateFunction => {
    const baseTranslate = useBaseTranslate(namespace);
    const [locale] = useLocale();

    return useCallback(
        (key: string, params?: TranslationParams, fallback?: string) => {
            const message = baseTranslate(key, params, fallback);
            const fullKey = (namespace ? `${namespace}.` : '') + key;

            if (typeof message !== 'string') {
                const warning = `Intl format skipped due to the UI Library translate returning a non-string value for the key '${fullKey}'.`;

                if (process.env.NODE_ENV !== 'production') {
                    // eslint-disable-next-line no-console
                    console.warn(warning, message);
                }
                captureMessage(warning);

                return message;
            } else if (message === `[${fullKey}]`) {
                const warning = `Translation is not defined for locale key: ${fullKey}`;
                captureMessage(warning);
            }

            try {
                const nodes = new IntlMessageFormat(message, locale).format<ReactNode>({
                    b: (parts) => <Text bold>{parts}</Text>,
                    ...params,
                });
                return Array.isArray(nodes) ? React.Children.toArray(nodes) : nodes;
            } catch (e) {
                if (process.env.NODE_ENV !== 'production') {
                    throw e;
                }

                if (e.code === ErrorCode.MISSING_VALUE) {
                    captureException(e);
                    return message;
                }

                throw e;
            }
        },
        [baseTranslate, locale, namespace],
    );
};
