// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type MainButtonProps = {
    disabled?: boolean;
    onClick: () => void;
};

const MainButton = ({ disabled = false, onClick }: MainButtonProps) => {
    const translate = useTranslate();

    return (
        <Button
            ghost
            icon={'reload'}
            disabled={disabled}
            onClick={onClick}
            data-type={'Renew--Reactivate-button'}
        >
            {translate('Keys.Renew.buttonTitle')}
        </Button>
    );
};

export default MainButton;
