// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, LabelIntent, Tooltip } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    VulnerabilityRiskRank,
    VulnerabilityRiskRanks,
} from '@platform360/security-dashboard/shared/vulnerability-risk-rank';
import styles from './RiskRank.module.css';

export type RiskRankProps = {
    riskRank: VulnerabilityRiskRank;
    cvssScore: string | null;
    exploited?: boolean | null;
    intent?: LabelIntent;
};

const RISK_INTENT_MAP: Record<VulnerabilityRiskRank, LabelIntent> = {
    [VulnerabilityRiskRanks.critical]: 'danger',
    [VulnerabilityRiskRanks.high]: 'danger',
    [VulnerabilityRiskRanks.medium]: 'warning',
    [VulnerabilityRiskRanks.low]: 'success',
};

const RiskRank = ({ riskRank, cvssScore, intent }: RiskRankProps) => {
    const translate = useTranslate('security-dashboard.RiskRank');

    const label = (
        <Label
            size="lg"
            view="light"
            className={styles.label}
            intent={intent ?? RISK_INTENT_MAP[riskRank]}
        >
            <div>{translate(`label.${riskRank}`)}</div>
        </Label>
    );

    return (
        <Tooltip
            title={
                <>
                    {translate(`${riskRank}Risk`)}
                    <div className={styles.metrics}>
                        {translate('cvss', { score: cvssScore ?? translate('emptyCvss') })}
                    </div>
                </>
            }
        >
            {label}
        </Tooltip>
    );
};

export default RiskRank;
