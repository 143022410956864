// Copyright 2024. WebPros International GmbH. All rights reserved.

import { StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ErrorMessageProps = {
    children: string;
};

const DPA_EMAIL = 'dpa@plesk.com';

const ErrorMessage = ({ children, ...props }: ErrorMessageProps) => {
    const translate = useTranslate();

    const message =
        children === 'user is blocked'
            ? translate(`userBlocked`, {
                  emailLink: <a href={`mailto:${DPA_EMAIL}`}>{DPA_EMAIL}</a>,
              })
            : translate(children, undefined, children);

    return (
        <StatusMessage intent="danger" {...props}>
            {message}
        </StatusMessage>
    );
};

export default ErrorMessage;
