// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { dailyTaskTimeTypeValues, DailyTaskTimeTypes } = makeEnum(
    'DailyTaskTimeType',
    'do-not-touch',
    'unset',
    'custom',
);

export type DailyTaskTimeType = UnionOf<typeof dailyTaskTimeTypeValues>;
