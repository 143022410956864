// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'Les Outils 360',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Inventaire des serveurs',
            description: 'Toutes les instances enregistrées depuis un emplacement unique et un jeu d\'identifiants',
        },
        licenseManagement: {
            title: 'Gestion des licences',
            description: 'Achetez et gérez l\'ensemble de vos licences pour Plesk, ses extensions et les Outils 360.',
        },
        feedback: {
            title: 'Partager votre avis',
            description: 'Vos commentaires et votre feedback nous seront très utiles pour poursuivre le développement de notre produit.',
        },
        websiteInventory: {
            title: 'Inventaire des contenus',
            description: 'Voir et gérer tous vos sites Web',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Surveillez vos serveurs et vos sites Web depuis un tableau de bord unique.',
            description: 'Cette solution centralisée permet d\'effectuer le suivi des performances et d\'éviter les interruptions. Elle inclut notamment des tableaux de bord configurables, des alertes personnalisées, des vérifications toutes les minutes, l\'historique du réseau, etc.',
            primaryButton: 'Tester gratuitement pendant 14 jours',
            secondaryButton: 'En savoir plus',
        },
        licenses: {
            title: 'Gérer vos licences',
            description: 'Achetez, ajustez et résiliez toutes vos licences pour Plesk, ses extensions et les Outils 360 depuis un emplacement unique.',
            primaryButton: 'C\'est parti !',
            secondaryButton: 'En savoir plus',
        },
        serverInventory: {
            title: 'Toutes les instances enregistrées depuis un emplacement unique et un jeu d\'identifiants',
            description: 'Connectez tous vos serveurs Plesk à l\'Inventaire des serveurs pour obtenir la liste complète de vos sites Web et organiser un accès en un clic à vos sites.',
            primaryButton: 'Connecter les serveurs',
            secondaryButton: 'En savoir plus',
        },
        websiteInventory: {
            title: 'Tous vos sites Web et domaines en une liste unique',
            description: 'Affichez, recherchez, filtrez et gérez tous vos sites Web en un clic.',
            primaryButton: 'Voir les sites Web',
            secondaryButton: 'En savoir plus',
        },
    },
};