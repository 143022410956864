// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { updateLicense } from '@platform360/admin-panel/web/api/security-dashboard/licenses';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';

type UseUpdateLicenseMutationOptions = {
    onSuccess: () => void;
};

const useUpdateLicenseMutation = ({ onSuccess }: UseUpdateLicenseMutationOptions) => {
    const translateValidationErrors = useTranslateValidationErrors();

    const { error, ...rest } = useMutation({
        mutationFn: updateLicense,
        onSuccess,
    });

    const errors = translateValidationErrors(getValidationErrors(error));

    return {
        ...rest,
        errors,
    };
};

export default useUpdateLicenseMutation;
