// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ExtendedStatusMessage, Heading, Icon, Label, Popover, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useInstallationsVulnerabilitiesQuery from '@platform360/security-dashboard/web/queries/useInstallationsVulnerabilitiesQuery';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import styles from './ProtectInfoPopover.module.less';
import { SECURITY_DASHBOARD_FEEDBACK_LINK } from '@platform360/libs/common/constants/security-dashboard';

type ProtectInfoPopoverProps = {
    installationId: number;
};

const SECTIONS = ['noPatch', 'unlikelyExploited', 'dbMatch'];

const ProtectInfoPopover = ({ installationId }: ProtectInfoPopoverProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.ProtectControlCompact.ProtectInfoPopover',
    );
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const { data: responseData } = useInstallationsVulnerabilitiesQuery({
        variables: { installationId },
    });
    const data = responseData?.data || [];

    if (
        !data.length ||
        data.every(
            ({ mitigatedByDeactivation, mitigatedByProtection }) =>
                mitigatedByProtection || mitigatedByDeactivation,
        )
    )
        return null;

    return (
        <Popover
            target={
                <Label
                    intent="inactive"
                    icon="question-mark-circle-filled"
                    size="lg"
                    view="outline"
                    onClick={() => analyticsEvents.wpTabSiteVulnerabilitiesNotPatchedInfoClick()}
                >
                    {translate('label')}
                </Label>
            }
            placement="bottom-right"
            width="xs"
        >
            <ExtendedStatusMessage className={styles.message} intent="success">
                <div className={styles.messageBody}>
                    <Icon className={styles.messageIcon} name="shield" size="48" intent="success" />
                    {translate('message')}
                </div>
            </ExtendedStatusMessage>
            {SECTIONS.map((section) => (
                <div key={section} className={styles.section}>
                    <Heading level={5}>{translate(`${section}.title`)}</Heading>
                    <Text fontSize="sm">{translate(`${section}.description`)}</Text>
                </div>
            ))}
            <Text intent="muted">
                {translate(`duplicatesHint`, {
                    link: (
                        <a
                            href={SECURITY_DASHBOARD_FEEDBACK_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate('letUsKnow')}
                        </a>
                    ),
                })}
            </Text>
        </Popover>
    );
};

export default ProtectInfoPopover;
