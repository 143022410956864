// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ListEmptyView } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import sadServerImage from './images/sad-server.svg';

type PausedServerProps = {
    monitoringServerLink: string;
};

const PausedServer = ({ monitoringServerLink }: PausedServerProps) => {
    const translate = useTranslate('monitoring.ServerViewMonitoring');

    return (
        <ListEmptyView
            image={sadServerImage}
            title={translate('pausedServerTitle')}
            description={translate('pausedServerDescription')}
            actions={
                <Button
                    component="a"
                    intent="primary"
                    rel="noopener noreferrer"
                    href={monitoringServerLink}
                    data-type="view-in-monitoring-link"
                >
                    {translate('viewInMonitoring')}
                </Button>
            }
            data-type="paused-server-view"
        />
    );
};

export default PausedServer;
