// Copyright 2024. WebPros International GmbH. All rights reserved.

import Router from './routes';
import { WS_NAMESPACE } from '@platform360/security-dashboard/shared/websocket';
import { useToasterAnalytics } from '@platform360/security-dashboard/web/helpers/useToasterAnalytics';
import { WebsocketProvider } from '@platform360/libs/shared-web/websocket';

const App = () => {
    useToasterAnalytics();
    return (
        <WebsocketProvider namespace={WS_NAMESPACE}>
            <Router />
        </WebsocketProvider>
    );
};

export default App;
