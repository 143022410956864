// Copyright 2024. WebPros International GmbH. All rights reserved.

import amplitude from 'amplitude-js';
import AnalyticsClient from './AnalyticsClient';
import { createContext } from 'react';
import findAuthUserIdInLocalStorage from '@platform360/libs/shared-web/helpers/findAuthUserIdInLocalStorage';

// TODO Rework AnalyticsClient initialization to prevent direct usage of __INITIAL_STATE__.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const amplitudeKey = window.__INITIAL_STATE__?.amplitude?.amplitudeKey;
let client: amplitude.AmplitudeClient | undefined = undefined;

if (amplitudeKey) {
    amplitude.getInstance().init(amplitudeKey, findAuthUserIdInLocalStorage(), {
        apiEndpoint: `${window.location.host}/api/a/ampl`,
        forceHttps: false,
        storage: 'none',
        saveEvents: false,
        transport: 'beacon',
    });
    client = amplitude.getInstance();
}

export const analyticsClient = new AnalyticsClient(client);
analyticsClient.setUtmProperties(new URLSearchParams(window.location.search));
export const AnalyticsClientContext = createContext(analyticsClient);
