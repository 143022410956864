// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ProductsList from '@platform360/licenses/web/units/extensions/components/extensionDetails/ProductsList';
import selectProduct from '@platform360/licenses/web/units/extensions/actions/buyExtension/selectProduct';
import { StoreState } from '@platform360/licenses/web/store/types';
import calculate from '@platform360/licenses/web/units/extensions/actions/buyExtension/calculate';

const mstp = ({
    licenses: {
        buyExtension: { selectedProduct, products, period, currency, hasSubscriptions },
    },
}: StoreState) => ({
    selected: selectedProduct,
    products,
    period,
    currency,
    hasSubscriptions,
});

const mdtp = (dispatch: Dispatch) => ({
    onSelect: (productId: string) => {
        dispatch(selectProduct(productId));
        //  @ts-expect-error TS2345
        dispatch(calculate());
    },
});

export default connect(mstp, mdtp)(ProductsList);
