// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    AVAILABLE_RETAIL_LICENSES,
    RetailLicenseData,
} from '@platform360/security-dashboard/web/constants/licenses';

export type LicenseData = {
    key: string;
    sites: number;
    pricePerSite: number;
    pricePerMonth: number;
    disabled: boolean;
    active: boolean;
    recommended: boolean;
    upgradable: boolean;
    productNamePrefix: string;
};

const getLicenseData = ({
    currentActiveProduct,
    recommendedRetailLicense,
}: {
    currentActiveProduct: string | null;
    recommendedRetailLicense: Pick<RetailLicenseData, 'cleverbridgeKey'> | undefined;
}): LicenseData[] => {
    const currentActiveProductIndex = AVAILABLE_RETAIL_LICENSES.findIndex(
        ({ cleverbridgeKey }) => `${cleverbridgeKey}_retail` === currentActiveProduct,
    );

    return AVAILABLE_RETAIL_LICENSES.map(
        ({ cleverbridgeKey, sites, pricePerSite, pricePerMonth, productNamePrefix }, index) => {
            const active = index === currentActiveProductIndex;

            return {
                key: cleverbridgeKey,
                sites,
                pricePerSite,
                pricePerMonth,
                disabled: index < currentActiveProductIndex,
                active,
                recommended:
                    !active &&
                    cleverbridgeKey === recommendedRetailLicense?.cleverbridgeKey &&
                    index > currentActiveProductIndex,
                upgradable: currentActiveProductIndex > -1 && index > currentActiveProductIndex,
                productNamePrefix,
            };
        },
    );
};

export default getLicenseData;
