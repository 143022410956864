// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, LabelIntent, IconName, Tooltip } from '@plesk/ui-library';

type LabelItemProps = {
    children: string;
    intent: LabelIntent;
    view?: 'filled' | 'light' | 'outline' | undefined;
    caps?: boolean;
    icon?: IconName;
    tooltip?: string;
};

export const LabelItem = ({
    children,
    view = 'outline',
    caps = false,
    tooltip,
    ...props
}: LabelItemProps) => {
    const label = (
        <Label view={view} caps={caps} {...props}>
            {children}
        </Label>
    );

    if (tooltip) {
        return <Tooltip title={tooltip}>{label}</Tooltip>;
    }

    return label;
};

export default LabelItem;
