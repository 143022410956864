// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import PeriodControl from '@platform360/licenses/web/components/PeriodControl';
import changePeriod from '@platform360/licenses/web/units/extensions/actions/buyExtension/changePeriod';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import { StoreState } from '@platform360/licenses/web/store/types';
import initialization from '@platform360/licenses/web/units/extensions/actions/buyExtension/initialization';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';

const mstp = (state: StoreState) => {
    const { period: bePeriod, isFetching, initialized } = buyExtensionSelector(state);

    return {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        selected: bePeriod || upsellSelector(state).period,
        disabled: !initialized || isFetching,
    };
};

const mdtp = (dispatch: Dispatch) => ({
    onChange: (period: BillingPeriod) => {
        dispatch(changePeriod(period));
        //  @ts-expect-error TS2345
        dispatch(initialization());
    },
});

export default connect(mstp, mdtp)(PeriodControl);
