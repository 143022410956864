// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { disableMfa } from '@platform360/accounts/web/api/mfa';
import { usePatchCurrentUserData } from '@platform360/accounts/web/queries/useCurrentUserQuery';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

type UseDisableMfaMutationOptions = {
    onSuccess?: () => void;
};

export const useDisableMfaMutation = ({ onSuccess }: UseDisableMfaMutationOptions = {}) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const patchCurrentUserData = usePatchCurrentUserData();
    const { error, ...rest } = useMutation({
        mutationFn: disableMfa,
        onSuccess: async () => {
            await patchCurrentUserData({ isMfaEnabled: false });
            onSuccess?.();
        },
    });

    return { errors: translateValidationErrors(getValidationErrors(error)), ...rest };
};
