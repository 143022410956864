// Copyright 2024. WebPros International GmbH. All rights reserved.

import extensionSelector from '@platform360/licenses/web/units/extensions/selectors/extension';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '@platform360/licenses/web/store/types';
import { Action } from 'redux';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';

const goToStore =
    (email: string): ThunkAction<void, StoreState, unknown, Action<string>> =>
    (_, getState) => {
        const state = getState();

        const { period, currency, selectedProduct: productId = '' } = buyExtensionSelector(state);

        const { selectedItemPath, licenseId = '' } = upsellSelector(state);

        const { storeLink } = extensionSelector(period, currency, selectedItemPath)(state);

        window.open(
            `${storeLink}?a=buy&currency=${currency}&productId=${productId}&kn=${String(
                licenseId,
            )}&email=${email}`,
            '_blank',
        );
    };

export default goToStore;
