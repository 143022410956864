// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ProgressBar, Section, SectionItem, Tabs, Tab } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { RequestInfo } from '@platform360/admin-panel/web/components/security-dashboard/Requests/useRequestStorage';
import ResponseDataPreview from './ResponseDataPreview';
import styles from './RequestDetails.module.less';

type RequestDetailsProps = {
    request: RequestInfo;
};

const RequestDetails = ({
    request: {
        requestId,
        url,
        state,
        request,
        response,
        server,
        isResponseLarge,
        serverUnreachableError,
    },
}: RequestDetailsProps) => {
    const translate = useTranslate(
        'admin-panel.security-dashboard.Requests.Network.RequestDetails',
    );

    if (state === 'running') {
        return (
            <>
                {translate('requestProgress')}
                <ProgressBar className={styles.progress} />
            </>
        );
    }

    if (state === 'timeoutReached') {
        return translate('timeoutReached', {
            requestId,
            requestInfo: (requestInfo) => (
                <>
                    <br />
                    {requestInfo}
                </>
            ),
        });
    }

    if (!response || serverUnreachableError) {
        return (
            <>
                {translate('serverUnreachableError')}{' '}
                {serverUnreachableError?.code &&
                    translate('errorCode', { code: serverUnreachableError.code })}
            </>
        );
    }

    const { data, headers } = response;
    const { searchParams } = new URL(url, 'https://base.url');

    return (
        <Tabs>
            <Tab title={translate('response')}>
                <ResponseDataPreview data={data} isResponseLarge={isResponseLarge} />
            </Tab>
            <Tab title={translate('headers')}>
                {request && !!Object.entries(request.headers).length && (
                    <Section title={translate('requestHeaders')} collapsible>
                        {Object.entries(request.headers).map(([key, value]) => (
                            <SectionItem key={key} title={key}>
                                {value}
                            </SectionItem>
                        ))}
                    </Section>
                )}
                <Section title={translate('responseHeaders')} collapsible>
                    {Object.entries(headers).map(([key, value]) => (
                        <SectionItem key={key} title={key}>
                            {value}
                        </SectionItem>
                    ))}
                </Section>
            </Tab>
            <Tab title={translate('payload')}>
                {searchParams.size === 0 && !request?.body && translate('noPayload')}
                {searchParams.size > 0 && (
                    <Section title={translate('queryParameters')} collapsible>
                        {[...searchParams].map(([key, value]) => (
                            <SectionItem key={key} title={key}>
                                {value}
                            </SectionItem>
                        ))}
                    </Section>
                )}
                {request?.body && (
                    <Section title={translate('body')} collapsible>
                        <pre>{JSON.stringify(JSON.parse(request.body), undefined, 2)}</pre>
                    </Section>
                )}
            </Tab>
            <Tab title={translate('additionalInfo')}>
                <Section title={translate('request')} collapsible>
                    <SectionItem title={translate('requestId')}>{requestId}</SectionItem>
                </Section>

                {server && (
                    <Section title={translate('server')} collapsible>
                        <SectionItem title={translate('hostname')}>
                            {server.hostname ?? '-'}
                        </SectionItem>
                        <SectionItem title={translate('ip')}>
                            {new URL(server.address).hostname}
                        </SectionItem>
                        <SectionItem title={translate('guid')}>{server.guid}</SectionItem>
                    </Section>
                )}
            </Tab>
        </Tabs>
    );
};

export default RequestDetails;
