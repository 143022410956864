// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getEmailMetas } from '@platform360/admin-panel/web/api/email-metas';
import { FilterQuery } from '@platform360/libs/shared-web/typings/api';

export type UseEmailMetasQueryVariables = FilterQuery;
export type UseEmailMetasQueryData = PromiseValue<ReturnType<typeof getEmailMetas>>;

const useEmailMetasQuery = createQuery<UseEmailMetasQueryVariables, UseEmailMetasQueryData>({
    queryName: 'admin-panel/useEmailMetasQuery',
    fetcher: getEmailMetas,
    useQuery,
});

export default useEmailMetasQuery;
