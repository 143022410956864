// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { TranslationProps } from '@plesk/ui-library';

import { LabelType, LabelTypes } from '@platform360/security-dashboard/shared/label-type';
import { Label } from '../types';

type LabelTypeTranslateData = {
    title: TranslationProps;
    tooltip?: TranslationProps;
};
const useTranslateLabel = () => {
    const translate = useTranslate('security-dashboard.labelTypes');

    const translationsMap: Partial<Record<LabelType, LabelTypeTranslateData>> = {
        [LabelTypes.doNotProtect]: {
            title: translate('doNotProtect.title'),
            tooltip: translate('doNotProtect.tooltip'),
        },
        [LabelTypes.ignore]: { title: translate('ignore.title') },
        [LabelTypes.ignoredDueToLowRisk]: { title: translate('ignoredDueToLowRisk.title') },
        [LabelTypes.doNotIgnoreAutomatically]: {
            title: translate('doNotIgnoreAutomatically.title'),
        },
    };

    return ({ title, type }: Label) => translationsMap[type] || { title };
};

export default useTranslateLabel;
