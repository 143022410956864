// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { MerchantOfRecordType } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import getLocalePathByMerchantOfRecordType from '@platform360/licenses/web/units/cleverbridge/helpers/getLocalePathByMerchantOfRecordType';

const CompanyAddress = ({
    merchantOfRecordType,
}: {
    merchantOfRecordType: MerchantOfRecordType;
}) => {
    const translate = useTranslate();

    return translate(
        getLocalePathByMerchantOfRecordType(merchantOfRecordType).concat('companyAddress'),
    );
};

export default CompanyAddress;
