// Copyright 2024. WebPros International GmbH. All rights reserved.

import { LocaleProvider, fallbackLocaleCode } from '@platform360/libs/shared-web/locale';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { locales } from '@scp/frontend/locales';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { captureException } from '@sentry/browser';
import { ReactElement, useCallback } from 'react';
import axios from 'axios';

export type ApplicationAwareLocaleProviderProps = {
    children: ReactElement;
};

export const ApplicationAwareLocaleProvider = ({
    children,
}: ApplicationAwareLocaleProviderProps) => {
    const { usePlatform360LocaleCode } = useApplicationSettings();
    const {
        platform: { baseUrl: platform360BaseUrl },
    } = useConfig();
    const handleLocaleChange = useCallback(
        async (localeCode: string) => {
            try {
                await axios.put(
                    '/api/v1/accounts/user/locale',
                    {
                        localeCode,
                    },
                    {
                        withCredentials: true,
                        baseURL: usePlatform360LocaleCode ? platform360BaseUrl : undefined,
                    },
                );
            } catch (e) {
                captureException(e);
            }
        },
        [usePlatform360LocaleCode, platform360BaseUrl],
    );
    const getInitialLocaleCode = useCallback(async () => {
        try {
            const { data } = await axios.get('/api/v1/accounts/user/locale', {
                withCredentials: true,
                baseURL: platform360BaseUrl,
            });

            return data.data;
        } catch (e) {
            captureException(e);
            return fallbackLocaleCode;
        }
    }, [platform360BaseUrl]);

    return (
        <LocaleProvider
            locales={locales}
            fallbackLocaleCode={fallbackLocaleCode}
            getInitialLocaleCode={usePlatform360LocaleCode ? getInitialLocaleCode : undefined}
            onLocaleChange={handleLocaleChange}
        >
            {children}
        </LocaleProvider>
    );
};
