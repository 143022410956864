// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'Une clé publique a été détectée à la place de la clé privée attendue.',
        passphrasePrivateKeyError: 'Veuillez fournir une clé non protégée par une phrase passe.',
        unsupportedPrivateKeyError: 'Le type de clé fourni n\'est pas pris en charge.',
        invalidPrivateKeyError: 'Vérifiez si vous utilisez bien la clé privée SSH appropriée.',
        emptyPrivateKeyError: 'La clé privée SSH fournie n\'est pas valide, car elle est vide.',
        tooBigPrivateKeyError: 'Veuillez fournir un fichier de clé de moins de 30 Ko.',
    },
    Installation: {
        startAnotherInstallation: 'Démarrer une autre installation',
        steps: {
            running: {
                title: 'Plesk est en cours d\'installation',
                description: 'Installation de Plesk sur <b>{host}</b>',
            },
            error: {
                title: 'Erreur lors de l\'installation',
            },
            completed: {
                title: 'Installation terminée',
                pleskHostText: 'Plesk a été installé sur {host}.',
                goToPleskText: 'Cliquez sur "Accéder à Plesk" pour vous connecter. Après quelques courtes étapes de configuration, vous pourrez commencer à utiliser Plesk.',
                goToPleskButton: 'Accéder à Plesk',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Serveur déconnecté',
            description: 'La connexion SSH au serveur a été perdue.',
        },
        connectFail: {
            title: 'Échec de la connexion au serveur',
            description: '<p>Impossible de se connecter au serveur : {details}.</p><p>Si le serveur utilise un port SSH personnalisé, spécifiez-le. Pour cela, ajoutez un deux-points puis le numéro du port après le nom d\'hôte ou l\'adresse IP (par exemple : 192.0.2.0:1024 ou example.com:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk est déjà installé.',
            description: 'Une erreur inattendue s\'est produite pendant que nous essayions de détecter si Plesk est déjà installé : {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com n\'est pas disponible.',
            description: 'Impossible de récupérer les distributions Plesk. Le site web autoinstall.plesk.com n\'est pas disponible : {details}.',
        },
        pleskInstalled: {
            title: 'Plesk est installé.',
            description: 'Web Installer installe uniquement {productType}. Or, une version stable de Plesk est déjà installée sur <b>{host}</b>. {productType, select, Plesk {Vous attendiez une autre version ? Nous vous invitons à répondre à <surveyLink>cette question</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'Une mise à niveau est disponible.',
            description: 'Web Installer installe uniquement {productType}. La version de Plesk est actuellement installée sur<b>{host}</b>. Il ne s\'agit pas de la version stable la plus récente. {productType, select, Plesk {Pensiez-vous que Plesk Web Installer procèderait à la mise à niveau ? Nous vous invitons à répondre à <surveyLink>cette question</surveyLink>.}other {}}',
        },
        unsupportedArch: {
            title: 'Architecture serveur non prise en charge',
            description: 'Vous essayez d\'installer {productType} sur une architecture non prise en charge : {arch}.',
        },
        unsupportedOs: {
            title: 'Système d\'exploitation non pris en charge ',
            description: 'Vous essayez d\'installer {productType} sur un système d\'exploitation (architecture) non pris en charge : {os}. <supportedOsLink>Consultez la liste des systèmes d\'exploitation pris en charge</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Espace disque insuffisant',
        },
        cpanelInstalled: {
            title: 'cPanel est installé.',
            description: 'Web Installer installe {productType} uniquement sur des serveurs propres. Or, cPanel est déjà installé sur <b>{host}</b>. {productType, select, Plesk {Découvrez comment transférer des sites web hébergés sur Plesk. Pour cela, consultez notre <migrationGuideLink>Guide de la migration</migrationGuideLink>} other {Vous aviez d\'autres besoins ? Nous vous invitons à répondre à <surveyLink>cette question</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Vérifier le statut du gestionnaire de packages',
            description: 'Le gestionnaire de packages n\'est pas disponible ou ne répond pas : {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Échec de la configuration du dépôt du gestionnaire de packages',
            description: 'Une erreur inattendue s\'est produite pendant la configuration du dépôt du gestionnaire de package : {details}.',
        },
        dbConfigurationFailed: {
            title: 'Échec de la configuration de la base de données',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'Votre sécurité est assurée : WebPros ne stocke pas votre identifiant ni votre mot de passe après l\'installation.',
            p2: 'Vous pouvez installer {productType} sur <a>tout OS basé sur Linux et pris en charge</a>.',
        },
        hostLabel: 'Adresse IP ou nom d\'hôte du serveur',
        portLabel: 'Port',
        loginLabel: 'Connexion SSH',
        installationMethodLabel: 'Se connecter au serveur via',
        sshPasswordLabel: 'Mot de passe SSH',
        sshKeyLabel: 'Clé privée SSH',
        licenseTypeLabel: 'Sélectionner votre type de licence',
        trialLicenseLabel: 'Continuer avec une licence de test complète',
        trialLicenseDescription: 'Pour émettre une licence de test, WebPros International GmbH utilise et traite les informations fournies dans ses systèmes internes, conformément à la <privacyPolicyLink>Politique de confidentialité de WebPros</privacyPolicyLink>.',
        paidLicenseLabel: 'Continuer avec une licence payante',
        paidLicenseDescription: 'Si vous avez déjà acheté une licence {productType}, collez ici le code d\'activation que vous avez reçu. Si vous n\'en avez pas encore, vous pouvez acheter une nouvelle licence dans notre <onlineStoreLink>boutique en ligne</onlineStoreLink>.',
        installOsUpdatesLabel: 'Installer les mises à jour de l\'OS avant de démarrer l\'installation de {productType}',
        installOsUpdatesDescription: 'Cette option risque de ralentir significativement le processus d\'installation, voire de le faire échouer.',
        notice: 'L\'installation dure environ 15 minutes.',
        techDomainDescription: 'Votre serveur sera ajouté avec le nom <b>{techDomain}</b>.',
        serverConnectionSection: 'Connexion au serveur',
        licenseSection: 'Licence',
        advancedSection: 'Avancée',
        submitButton: 'Suivant',
    },
    ServerInstallationPage: {
        submitButton: 'Suivant',
        panelTitle: 'Installer automatiquement {productType} sur un serveur Linux',
        licenceLabel: 'Licence de test',
        installationWillTake: 'L\'installation dure environ 15 minutes',
        helpUsImprove: 'Aidez-nous à améliorer {productType} Web Installer',
        takeOurSurvey: 'Répondre à notre enquête',
        links: {
            manualInstallationInstructions: 'Instructions d\'installation manuelle',
            softwareRequirements: {
                plesk: 'Prérequis logiciels',
                cpanel: 'Prérequis système',
            },
            versionsAndComponents: 'Versions et composants',
            knownIssues: 'Problèmes connus',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'Votre adresse e-mail',
        legalAgreementTitle: 'J\'accepte les <termsOfUseLink>Conditions d\'utilisation</termsOfUseLink>, la <privacyPolicyLink>Politique de confidentialité</privacyPolicyLink> et j\'accepte par la présente le <dpaLink>Data Processing Agreement</dpaLink> (DPA, Contrat relatif au traitement des données).',
        emailHint: 'Si vous avez déjà un compte WebPros, utilisez l\'adresse e-mail associée. Si vous n\'avez pas encore de compte, nous en créerons un pour vous.',
        serversMonitoring: 'Surveillance de la disponibilité, de la santé et des performances du serveur gratuitement pour 1 serveur et 5 sites web',
        loginNoCredentials: 'Créez votre compte WebPros afin d\'accéder facilement et en un clic à l\'ensemble des services de l\'entreprise',
        login: 'Connexion à votre installation {productType} sans saisir d\'identifiants',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        submitButton: 'Démarrer l\'installation',
        marketingConsent: {
            title: 'WebPros peut m\'envoyer des newsletters et des offres ciblées conformément à la <a>Politique de confidentialité</a> de WebPros.',
            text: 'Je peux révoquer ce consentement en cliquant sur le lien disponible dans toute communication reçue.',
        },
    },
    LightsailInstallation: {
        title: 'Installer automatiquement sur un serveur Cloud',
        awsAccessKey: 'ID de la clé d\'accès',
        awsSecretKey: 'Clé d\'accès secrète',
        region: 'Région',
        bundleTitle: 'Pack de l\'instance',
        publicKeyTitle: 'Clé publique SSH',
        instanceName: 'Nom de l\'instance',
        submitButton: 'Continuer',
        BundleSelect: {
            price: '${value} par mois', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Mémoire {value} Go',
            storage: 'Stockage {value} Go',
            transfer: 'Transfert {value} Go',
            descriptions: {
                micro: 'Meilleure solution pour tester Plesk.',
                small: 'Pour un ou plusieurs sites personnels.',
                medium: 'Pour les pros du Web ou les agences Web qui travaillent avec un petit nombre de sites.',
                large: 'Pour les grandes agences Web ou les petits hébergeurs qui travaillent avec plusieurs sites Web à faible trafic ou quelques sites avec un trafic élevé.',
                xlarge: 'Pour les hébergeurs qui gèrent plusieurs sites web avec un trafic et une charge élevés.',
                '2xlarge': 'Pour les projets plus ambitieux.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'Pour vous connecter à votre compte cPanel, cliquez sur le bouton "Se connecter à cPanel". <b>ATTENTION :</b> ce bouton est à usage unique ! Vous ne pouvez cliquer dessus qu\'une seule fois.',
        loginButton: 'Se connecter à cPanel',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'FAQ - Installation automatique de Plesk',
            versionsAndComponents: 'Versions et composants',
            knownIssues: 'Problèmes connus',
            whatVersionIsInstalledTitle: 'Quelle version de Plesk est installée en mode automatique ?',
            whatVersionIsInstalledText: 'La dernière version stable de Plesk avec toutes les mises à jour disponibles à ce moment. Pour installer une autre version, suivez les <link>instructions relatives à l\'installation</link>.',
            whatComponentsAreInstalledTitle: 'Quels sont les composants installés en mode automatique ?',
            whatComponentsAreInstalledText: 'Les "prérequis recommandés" qui incluent tous les services obligatoires pour l\'hébergement de sites web (Web, DNS, FTP et Mail) ainsi que les composants et extensions les plus populaires comme WordPress Toolkit, Advisor, Let\'s Encrypt, etc.',
            canISelectComponentsTitle: 'Puis-je sélectionner les composants de Plesk à installer ?',
            canISelectComponentsText: 'Pour le moment, ce n\'est pas possible. Toutefois, nous prévoyons de modifier cela à l\'avenir. Vous pouvez néanmoins ajouter ou supprimer le ou les composants requis une fois que l\'installation est terminée.',
            doesInstalledPleskHaveLicenseTitle: 'Est-ce que la version de Plesk installée est fournie avec une licence ?',
            doesInstalledPleskHaveLicenseText: 'Vous pouvez demander une version de test personnelle de 14 jours pour Web Pro Edition immédiatement après votre première connexion à Plesk, si vous ne l\'avez pas fait auparavant. Vous pouvez également utiliser votre propre licence achetée.',
            supportedOSsTitle: 'Quels sont les OS pris en charge par le mode automatique ? Puis-je installer Plesk pour Windows ?',
            supportedOSsText: 'Tous les <a>OS basés sur Linux pris en charge par Plesk</a>. Pour installer Plesk pour Windows, suivez les <link>instructions relatives à l\'installation</link>.',
            usePrivateKeyTitle: 'Puis-je utiliser une clé SSH privée chiffrée ?',
            usePrivateKeyText: 'Pour le moment, ce n\'est pas possible. Toutefois, nous prévoyons de modifier cela à l\'avenir.',
            isItSafeToSubmitPasswordTitle: 'Est-ce sûr de saisir un mot de passe ou une clé SSH privée lors d\'une installation de Plesk en mode automatique ?',
            isItSafeToSubmitPasswordText: 'Oui. Ces identifiants sont utilisés uniquement pour se connecter au serveur et installer Plesk. Ils ne sont pas stockés. Toutes les données sont transmises de manière sécurisée à l\'aide de canaux HTTPS et SSH chiffrés. Pour une sécurité maximale, nous recommandons de changer les identifiants d\'accès au serveur une fois l\'installation terminée.',
            helpUsMakeWebInstallerBetter: 'Aidez-nous à améliorer Plesk Web Installer pour tous en <a>répondant à notre courte enquête.</a>',
        },
        cpanel: {
            header: 'FAQ - Installation automatique de cPanel',
            versionsAndComponents: 'Versions et composants',
            knownIssues: 'Problèmes connus',
            whatVersionIsInstalledTitle: 'Quelle version de cPanel est installée en mode automatique ?',
            whatVersionIsInstalledText: 'La dernière version de cPanel avec toutes les mises à jour disponibles à ce moment. Pour installer une autre version, suivez les <link>instructions relatives à l\'installation</link>.',
            canICustomizeParametersOfInstallationTitle: 'Puis-je personnaliser les paramètres d\'installation ?',
            canICustomizeParametersOfInstallationText: 'Actuellement, vous ne pouvez pas personnaliser l\'installation avec Web Installer. Pour personnaliser votre installation, suivez les instructions disponibles dans <link>notre documentation</link>.',
            doesInstalledCpanelHaveLicenseTitle: 'Est-ce que la version de cPanel installée est fournie avec une licence ?',
            doesInstalledCpanelHaveLicenseText: 'Si vous installez une nouvelle instance cPanel & WHM sur un serveur, vous pouvez bénéficier d\'une version de test de 15 jours. Suivez les instructions fournies dans <link>ce guide</link> pour vous inscrire et bénéficier d\'une version de test. Vous pouvez également utiliser votre propre licence achetée.',
            supportedOSsTitle: 'Quels sont les OS pris en charge en mode automatique ?',
            supportedOSsText: 'Tous les OS basés sur Linux et <link>pris en charge par cPanel</link>.',
            usePrivateKeyTitle: 'Puis-je utiliser une clé SSH privée chiffrée ?',
            usePrivateKeyText: 'Pour le moment, ce n\'est pas possible. Toutefois, nous prévoyons de modifier cela à l\'avenir.',
            isItSafeToSubmitPasswordTitle: 'Est-ce sûr de saisir un mot de passe ou une clé SSH privée lors d\'une installation de cPanel en mode automatique ?',
            isItSafeToSubmitPasswordText: 'Oui. Ces identifiants sont utilisés uniquement pour se connecter au serveur et installer cPanel. Ils ne sont pas stockés. Toutes les données sont transmises de manière sécurisée à l\'aide de canaux HTTPS et SSH chiffrés. Pour une sécurité maximale, nous recommandons de changer les identifiants d\'accès au serveur une fois l\'installation terminée.',
            helpUsMakeWebInstallerBetter: 'Aidez-nous à améliorer cPanel Web Installer pour tous en <a>répondant à notre courte enquête.</a>',
        },
    },
};