// Copyright 2024. WebPros International GmbH. All rights reserved.

import HomePageWidget from '@platform360/libs/shared-web/components/HomePageWidget';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const StaticWidget = ({ ...props }) => {
    const translate = useTranslate('monitoring.HomePageWidget.StaticWidget');

    return (
        <HomePageWidget
            title={translate('title')}
            description={translate('description')}
            icon="monitoring"
            navigateTo="/monitoring"
            isExternal
            {...props}
        />
    );
};

export default StaticWidget;
