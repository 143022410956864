// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import NavLink, { NavLinkProps } from '@platform360/libs/shared-web/components/Page/NavLink';
import { MenuItem as UILibMenuItem, MenuHeader, MenuDivider } from '@plesk/ui-library';

type MenuLink = {
    type: 'link';
    icon?: string;
} & NavLinkProps;

type Divider = {
    type: 'groupDivider';
    children?: ReactNode;
};

export type MenuItemProps = MenuLink | Divider;

const MenuItem = (item: MenuItemProps) => {
    if (item.type === 'link') {
        return (
            <UILibMenuItem component={NavLink} activeClassName="pul-menu__item--active" {...item}>
                {item.children}
            </UILibMenuItem>
        );
    }

    return (
        <>
            <MenuDivider />
            {item.children && <MenuHeader>{item.children}</MenuHeader>}
        </>
    );
};

export default MenuItem;
