// Copyright 2024. WebPros International GmbH. All rights reserved.

import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Currency from '@platform360/licenses/web/types/currency';
import periodToText from '@platform360/licenses/web/units/extensions/helpers/periodToText';
import { StoreState } from '@platform360/licenses/web/store/types';
import Extension from '@platform360/licenses/web/units/extensions/types/Extension';
import { ExtensionProduct } from '@platform360/licenses/web/api/ka/responseTypes/Upsells';
import { STATE_KEY } from '@platform360/licenses/web/index';

type ReturnedExtension = Extension & {
    upsells: ExtensionProduct[];
};

const extensionSelector =
    (period: BillingPeriod, currency: Currency, path: string) =>
    (state: StoreState): ReturnedExtension => {
        const [group, key] = path.split('.') as [string, string];

        const { subProducts, ...extension } =
            state[STATE_KEY].upsell[periodToText(period)][group][key];

        return {
            ...extension,
            ...subProducts[`${currency}-${period}`],
        };
    };

export default extensionSelector;
