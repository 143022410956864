// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { ChangeableEmail } from './ChangeableEmail';
import { ChangeableEmailDialog, Values } from './ChangeableEmailDialog';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useUpdateEmailMutation } from '@platform360/accounts/web/mutations';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const initialValues: Values = {
    email: '',
    currentPassword: '',
    consents: [],
};

export const ChangeableEmailContainer = () => {
    const currentUser = useCurrentUser();
    const translate = useTranslate('accounts.profile.ChangeableEmail');
    const translateValidationErrors = useTranslateValidationErrors();
    const { successToast } = useToaster();
    const [isEdit, setIsEdit] = useState(false);
    const [values, setValues] = useState(initialValues);
    const {
        mutate,
        isPending: isLoading,
        error,
        reset,
    } = useUpdateEmailMutation({
        onSuccess: ({ email }) => {
            setIsEdit(false);
            successToast(translate('successMessage', { email }), {
                'data-type': 'update-email-success',
            });
        },
    });
    const handleEdit = () => {
        setValues(initialValues);
        reset();
        setIsEdit(true);
    };
    const handleSave = (values: Values) => {
        setValues(values);
        mutate({
            email: values.email ?? '',
            currentPassword: values.currentPassword ?? '',
            // eslint-disable-next-line deprecation/deprecation
            otp: currentUser.isMfaEnabled ? values.otp : undefined,
            consents: values.consents ?? [],
        });
    };
    const handleCancel = () => {
        setIsEdit(false);
    };

    return (
        <>
            <ChangeableEmail email={currentUser.email} onEdit={handleEdit} />
            <ChangeableEmailDialog
                isOpen={isEdit}
                // eslint-disable-next-line deprecation/deprecation
                isMfaEnabled={currentUser.isMfaEnabled || false}
                isLoading={isLoading}
                values={values}
                onSave={handleSave}
                onCancel={handleCancel}
                errors={translateValidationErrors(getValidationErrors(error))}
            />
        </>
    );
};
