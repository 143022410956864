// Copyright 2024. WebPros International GmbH. All rights reserved.

import { cloneElement, ReactElement, MouseEvent } from 'react';
import { Popover, ContentLoader, PopoverProps } from '@plesk/ui-library';
import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import { LabelsBlock, LabelsBlockProps } from './LabelsBlock';

export type LabelsPopoverProps = Omit<LabelsBlockProps, 'labels'> & {
    children: ReactElement;
    placement: PopoverProps['placement'];
    className?: string;
};

export type LabelsPopoverWrapperProps = LabelsPopoverProps & {
    visible: boolean;
};

const LabelsPopover = ({ children, placement, onClose, ...props }: LabelsPopoverProps) => {
    const {
        data: responseData,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useLabelsQuery({
        placeholderData: (prev) => prev,
    });
    const customLabels = responseData?.data.filter((l) => l.type === 'custom') || [];

    const isPending = isLoading || isPreviousData;

    return (
        <Popover
            target={cloneElement(children, {
                className: props.className,
                onClick: (e: MouseEvent) => {
                    e.stopPropagation();
                },
            })}
            placement={placement}
            onClose={onClose}
            visible
        >
            {isPending ? (
                <ContentLoader />
            ) : (
                <LabelsBlock labels={customLabels} onClose={onClose} {...props} />
            )}
        </Popover>
    );
};

// Popover component from the lib renders every time
// when renders parent component, prop "visible" doesn't role.
// It's overhead when the component applies to a list.
const Wrapper = ({ visible, children, ...props }: LabelsPopoverWrapperProps) => {
    if (!visible) {
        return cloneElement(children, {
            className: props.className,
            onClick: (e: MouseEvent) => {
                e.stopPropagation();
                children.props.onClick?.(e);
            },
        });
    }

    return <LabelsPopover {...props}>{children}</LabelsPopover>;
};

export default Wrapper;
