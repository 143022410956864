// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Paragraph } from '@plesk/ui-library';
import { ReactElement } from 'react';

type UpdateConfirmationProps = {
    onClick: () => void;
    siteDisplayTitle: string;
    target: ReactElement;
    affectedRowsCount: number | undefined;
};

const UpdateConfirmation = ({
    onClick,
    siteDisplayTitle,
    target,
    affectedRowsCount,
}: UpdateConfirmationProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.WebsiteDrawer.tabs.outdated.updateConfirmation',
    );

    return (
        <ConfirmationPopover
            onClick={onClick}
            target={target}
            actionButtonText={translate('updateButton')}
            cancelButtonText={translate('cancelButton')}
            confirmationButtonIntent={'primary'}
        >
            <Paragraph>
                {translate('description', {
                    count: affectedRowsCount,
                    website: siteDisplayTitle,
                })}
            </Paragraph>
        </ConfirmationPopover>
    );
};

export default UpdateConfirmation;
