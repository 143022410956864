// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: 'Detected a public key instead of the expected private one.',
        passphrasePrivateKeyError: 'Please provide a key not protected by a passphrase.',
        unsupportedPrivateKeyError: 'The provided key type is not supported.',
        invalidPrivateKeyError: 'Please make sure that you are using the correct SSH private key.',
        emptyPrivateKeyError: 'The provided SSH private key is invalid because it is empty.',
        tooBigPrivateKeyError: 'Please provide a key file less than 30 KB.',
    },
    Installation: {
        startAnotherInstallation: 'Start another installation',
        steps: {
            running: {
                title: 'Plesk is Being Installed',
                description: 'Installing Plesk on <b>{host}</b>',
            },
            error: {
                title: 'Installation Error',
            },
            completed: {
                title: 'Installation Completed',
                pleskHostText: 'Plesk was installed on {host}.',
                goToPleskText: 'Click "Go to Plesk" to log in. After a short setup process, you can start using Plesk.',
                goToPleskButton: 'Go to Plesk',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'Server disconnected',
            description: 'SSH connection to the server was lost.',
        },
        connectFail: {
            title: 'Server connection failed',
            description: '<p>Unable to connect to the server: {details}.</p><p>If the server uses a custom SSH port, specify it by adding a colon and the port number after the hostname or IP address (for example, example.com:1024 or 192.0.2.0:1024).</p>',
        },
        pleskDetectError: {
            title: 'Plesk already installed',
            description: 'An unexpected error occurred while detecting if Plesk is installed: {details}.',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com is not available',
            description: 'Unable to fetch Plesk distributives. The autoinstall.plesk.com website is unavailable: {details}.',
        },
        pleskInstalled: {
            title: 'Plesk is installed',
            description: 'Web Installer only installs {productType}, and <b>{host}</b> already has the stable version of Plesk installed. {productType, select, Plesk {Did you expect it to do something else? Let us know by <surveyLink>taking a one-question survey</surveyLink>.} other {}}',
        },
        pleskOutdated: {
            title: 'Upgrade is available',
            description: 'Web Installer only installs {productType}, and <b>{host}</b> already has Plesk installed, which is not the latest stable version. {productType, select, Plesk {Did you expect Plesk Web Installer to upgrade it? Let us know by <surveyLink>taking a one-question survey</surveyLink>.} other {}}',
        },
        unsupportedArch: {
            title: 'Unsupported server architecture',
            description: 'You are trying to install {productType} on an architecture that is not supported: {arch}.',
        },
        unsupportedOs: {
            title: 'Unsupported operating system',
            description: 'You are trying to install {productType} on an operating system (architecture) that is not supported: {os}. <supportedOsLink>See the list of supported operating systems</supportedOsLink>.',
        },
        notEnoughDiskSpace: {
            title: 'Not enough diskspace',
        },
        cpanelInstalled: {
            title: 'cPanel is installed',
            description: 'Web Installer only installs {productType} on clean servers, and <b>{host}</b> already has cPanel installed. {productType, select, Plesk {Learn how to transfer hosted websites to Plesk by reading our <migrationGuideLink>Migration Guide</migrationGuideLink>} other {Did you expect it to do something else? Let us know by <surveyLink>taking a one-question survey</surveyLink>.}}',
        },
        packageManagerBusy: {
            title: 'Check the package manager status',
            description: 'The package manager is busy or not responding: {details}.',
        },
        packageManagerConfigurationFailed: {
            title: 'Package manager repository configuration failed',
            description: 'An unexpected error occurred while configuring the package manager repository: {details}.',
        },
        dbConfigurationFailed: {
            title: 'Database configuration failed',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: 'You are completely safe: WebPros does not store your login and password after the installation is complete.',
            p2: 'You can install {productType} on <a>any supported Linux-based OS</a>.',
        },
        hostLabel: 'Server IP address or hostname',
        portLabel: 'Port',
        loginLabel: 'SSH login',
        installationMethodLabel: 'Connect to the server using',
        sshPasswordLabel: 'SSH password',
        sshKeyLabel: 'SSH private key',
        licenseTypeLabel: 'Select your license type',
        trialLicenseLabel: 'Proceed with a full-featured trial license',
        trialLicenseDescription: 'To issue a trial license, WebPros International GmbH will use and process the provided information in its internal systems according to the <privacyPolicyLink>WebPros Privacy Policy</privacyPolicyLink>.',
        paidLicenseLabel: 'Proceed with a paid license',
        paidLicenseDescription: 'If you have already purchased a {productType} license, paste the activation code you received here. If not, you can buy a new license in our <onlineStoreLink>Online Store</onlineStoreLink>.',
        installOsUpdatesLabel: 'Install OS updates before starting the {productType} installation',
        installOsUpdatesDescription: 'Selecting this option can significantly slow down the installation process or cause it to fail.',
        notice: 'The installation usually takes around 15 minutes.',
        techDomainDescription: 'Your server will be added with the <b>{techDomain}</b> name.',
        serverConnectionSection: 'Server Connection',
        licenseSection: 'License',
        advancedSection: 'Advanced',
        submitButton: 'Next',
    },
    ServerInstallationPage: {
        submitButton: 'Next',
        panelTitle: 'Install {productType} Automatically on a Linux Server',
        licenceLabel: 'Trial license',
        installationWillTake: 'The installation usually takes about 15 minutes',
        helpUsImprove: 'Help us improve {productType} Web Installer',
        takeOurSurvey: 'Take our survey',
        links: {
            manualInstallationInstructions: 'Manual installation instructions',
            softwareRequirements: {
                plesk: 'Software requirements',
                cpanel: 'System requirements',
            },
            versionsAndComponents: 'Versions and components',
            knownIssues: 'Known issues',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'Your Email',
        legalAgreementTitle: 'I agree with <termsOfUseLink>Terms of Use</termsOfUseLink>, <privacyPolicyLink>Privacy Policy</privacyPolicyLink> and hereby agree to sign the Plesk <dpaLink>Data Processing Agreement</dpaLink>',
        emailHint: 'If you already have a WebPros account, use this email. If not, an account will be created for you.',
        serversMonitoring: 'Monitor servers\' availability, health, and performance for free for 1 server and 5 websites',
        loginNoCredentials: 'Create your WebPros Account for easy access to all company\'s services with one click',
        login: 'Log in to your {productType} installation without entering credentials',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: 'First name',
        lastName: 'Last name',
        submitButton: 'Start installation',
        marketingConsent: {
            title: 'WebPros may provide me with newsletters and individual offers in accordance to the WebPros <a>Privacy Policy</a>.',
            text: 'I may revoke this consent by clicking the link in any communication received',
        },
    },
    LightsailInstallation: {
        title: 'Install Automatically on a Cloud Server',
        awsAccessKey: 'Access key ID',
        awsSecretKey: 'Secret access key',
        region: 'Region',
        bundleTitle: 'Instance plan',
        publicKeyTitle: 'SSH public key',
        instanceName: 'Instance name',
        submitButton: 'Continue',
        BundleSelect: {
            price: '${value} per month', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'Memory {value} GB',
            storage: 'Storage {value} GB',
            transfer: 'Transfer {value} GB',
            descriptions: {
                micro: 'Best suited for trying out Plesk.',
                small: 'For one or more personal websites.',
                medium: 'For web pros or web agencies working on a small number of websites.',
                large: 'For large web agencies or small hosters focused on multiple low-traffic websites or a couple of high-traffic ones.',
                xlarge: 'For hosters who manage multiple high-traffic and high-load websites.',
                '2xlarge': 'For the most ambitious projects.',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'To log in to your cPanel account, click the "Log in to cPanel" button. <b>NOTE:</b> The button will only work once!',
        loginButton: 'Log in to cPanel',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'Plesk Automatic Installation FAQ',
            versionsAndComponents: 'Versions And Components',
            knownIssues: 'Known Issues',
            whatVersionIsInstalledTitle: 'What Plesk version is installed in automatic mode?',
            whatVersionIsInstalledText: 'The latest stable version of Plesk with all updates available at the moment. To install a different Plesk version, follow the <link>installation instructions</link>.',
            whatComponentsAreInstalledTitle: 'What components are installed in automatic mode?',
            whatComponentsAreInstalledText: 'The "recommended preset" which includes all services required for hosting websites (Web, DNS, FTP, and Mail), along with the most popular components and extensions like WordPress Toolkit, Advisor, Let’s Encrypt, and so on.',
            canISelectComponentsTitle: 'Can I select which Plesk components to install?',
            canISelectComponentsText: 'Not at the moment. We are aware of this limitation and plan to address it in the future. For now, the workaround is to add or remove the required components after the installation process is finished.',
            doesInstalledPleskHaveLicenseTitle: 'Does the installed Plesk come with a license?',
            doesInstalledPleskHaveLicenseText: 'You can request a personal 14-day trial of the Web Pro Edition right after your first login to Plesk if you have not done so before. You can also use your own purchased license.',
            supportedOSsTitle: 'What OSes are supported in automatic mode? Can I install Plesk for Windows?',
            supportedOSsText: 'Any <a>Linux-based OS supported by Plesk</a>. To install Plesk for Windows, follow the <link>installation instructions</link>.',
            usePrivateKeyTitle: 'Can I use an encrypted private SSH key?',
            usePrivateKeyText: 'Not at the moment. We are aware of this limitation and plan to address it in the future.',
            isItSafeToSubmitPasswordTitle: 'Is it safe to submit password or private SSH key when installing Plesk in automatic mode?',
            isItSafeToSubmitPasswordText: 'Yes. These credentials are used only for connecting to the server and installing Plesk, and are not stored. All data is transmitted securely over encrypted SSH and HTTPS channels. For maximum security, consider changing the server access credentials after the installation.',
            helpUsMakeWebInstallerBetter: 'Help us make Plesk Web Installer better for everyone by <a>taking a short survey.</a>',
        },
        cpanel: {
            header: 'cPanel Automatic Installation FAQ',
            versionsAndComponents: 'Versions And Components',
            knownIssues: 'Known Issues',
            whatVersionIsInstalledTitle: 'What cPanel version is installed in automatic mode?',
            whatVersionIsInstalledText: 'The latest version of cPanel with all updates available at the moment. To install a different cPanel version, follow the <link>installation instructions</link>.',
            canICustomizeParametersOfInstallationTitle: 'Can I customize the installation parameters?',
            canICustomizeParametersOfInstallationText: 'Currently, there is no way to customize the installation with Web Installer. To customize your installation, follow the instructions in <link>our documentation</link>.',
            doesInstalledCpanelHaveLicenseTitle: 'Does the installed cPanel come with a license?',
            doesInstalledCpanelHaveLicenseText: 'If you are installing a new cPanel & WHM instance on a server, you are eligible for a 15-day trial license. Follow the instructions in <link>this guide</link> to sign up for a trial license. You can also use your own purchased license.',
            supportedOSsTitle: 'What OSes are supported in automatic mode?',
            supportedOSsText: 'Any Linux-based OS <link>supported by cPanel</link>.',
            usePrivateKeyTitle: 'Can I use an encrypted private SSH key?',
            usePrivateKeyText: 'Not at the moment. We are aware of this limitation and plan to address it in the future.',
            isItSafeToSubmitPasswordTitle: 'Is it safe to submit password or private SSH key when installing cPanel in automatic mode?',
            isItSafeToSubmitPasswordText: 'Yes. These credentials are used only for connecting to the server and installing cPanel, and are not stored. All data is transmitted securely over encrypted SSH and HTTPS channels. For maximum security, consider changing the server access credentials after the installation.',
            helpUsMakeWebInstallerBetter: 'Help us make cPanel Web Installer better for everyone by <a>taking a short survey.</a>',
        },
    },
};
