// Copyright 2024. WebPros International GmbH. All rights reserved.

import KbInstructionsFixLink from '@platform360/libs/shared-web/components/KbInstructionsFixLink';

export const HowToUpgradePleskKBArticleHref =
    'https://support.plesk.com/hc/en-us/articles/12377672451223-How-to-upgrade-Plesk-to-the-next-release';

const HowToUpgradePleskKBArticleLink = () => (
    <KbInstructionsFixLink href={HowToUpgradePleskKBArticleHref} />
);

export default HowToUpgradePleskKBArticleLink;
