// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Неизвестная ошибка: {message}.',
        newServer: 'Сервер с таким адресом уже существует.',
        domainName: 'Имя домена не может быть преобразовано.',
        serverMisconfigured: 'Сервер настроен неправильно и не может быть добавлен.',
        pleskConfiguration: 'Неподдерживаемая версия Plesk (ниже {pleskMinVersion}) или ошибка конфигурации Plesk.',
        unsupportedServerVersion: 'Установленная версия Plesk устарела и не может быть добавлена. {kbLink}',
        connection: 'Сетевое подключение прервано.',
        connectionRefused: 'Попытка соединения отклонена. Проверьте, верно ли указаны домен и порт.',
        timeout: 'Сетевое подключение к серверу прекращено по таймауту.',
        apiKey: 'Подключение не авторизовано удаленным сервером. Пожалуйста, добавьте этот сервер заново в перечень серверов.',
        httpsRequired: 'Этот сервер не поддерживает HTTPS.',
        certHasExpired: 'Срок действия сертификата истек. {kbLink}',
        errTlsCertAltnameInvalid: 'Имя хоста сервера или его IP-адрес не соответствует альтернативным именам сертификата. {kbLink}',
        depthZeroSelfSignedCert: 'Установлен самозаверенный сертификат. {kbLink}',
        selfSignedCertInChain: 'В цепочке сертификатов присутствует самозаверенный сертификат. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'Настройки уведомлений обновлены.',
        },
    },
    Layout: {
        title: 'Перечень серверов',
        serversTitle: 'Серверы',
        clientsTitle: 'Клиенты',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Настройки уведомлений',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Уведомления',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Какие уведомления отправлять',
        notifyServerUnreachable: 'Сервер недоступен',
        notifyServerUnsupported: 'Сервер не поддерживается',
    },
    AllDomains: {
        title: 'Сайты',
        name: 'Имя',
        hostingType: 'Тип хостинга',
        virtualHostingType: 'Веб-сайт',
        forwardingHostingType: 'Перенаправление',
        noneHostingType: 'Нет хостинга',
        mainDomainType: 'Основной домен',
        subDomainType: 'Субдомен',
        addonDomainType: 'Дополнительный домен',
        parkedDomainType: 'Псевдоним',
        hostingServer: 'Сервер хостинга',
        manage: 'Управлять',
        manageLinkUnavailableHint: 'Для создания прямой ссылки в панель Plesk требуется Plesk RESTful API версии 1.4.0 или выше. Более подробную информацию смотрите в документации.',
        open: 'Открыть',
    },
    AllClients: {
        name: 'Контактное имя',
        email: 'Адрес электронной почты',
        login: 'Имя пользователя',
        type: 'Тип',
        status: 'Статус',
        typeAdmin: 'Администратор',
        typeReseller: 'Реселлер',
        typeCustomer: 'Клиент',
        typeCustomerWithOwner: 'Клиент владельца {owner}',
        statusActive: 'Активен',
        statusSuspended: 'Приостановлен',
        hostingServer: 'Сервер хостинга',
        manage: 'Управлять',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Синхронизация выполняется',
            installationRunning: 'Установка запущена',
            installationFailure: 'Ошибка установки',
            installationLicenseRequired: 'Требуется лицензия',
            active: 'Активно, безопасно',
            inactiveUnreachable: 'Неактивный, недоступен',
            inactiveUnreachableTooltip: 'К серверу невозможно подключиться в течение как минимум 14 дней',
            inactiveUnsupported: 'Неактивный, не поддерживается',
            inactiveUnsupportedTooltip: 'На сервере запущена неподдерживаемая версия Plesk',
            insecure: 'Активно, небезопасно',
            outdated: 'Активный, устаревший',
            outdatedTooltip: '{version, select, _ {Ваша текущая версия Plesk устарела.} other {Ваша текущая версия Plesk {version} устарела.}} Рекомендуем обновить сервер до последней доступной версии Plesk Obsidian и включить автообновление.',
            unsupportedVersion: 'Активный, не поддерживается',
            unsupportedVersionTooltip: '{version, select, _ {Ваша текущая версия Plesk не поддерживается} other {Ваша текущая версия Plesk {version} не поддерживается}}, и ваш сервер будет отключен через 14 дней. Рекомендуем обновить сервер до последней доступной версии Plesk Obsidian и включить автообновление.',
            certificateWarning: 'Предупреждение сертификата',
            certificateError: 'Ошибка сертификата',
            connectionBlocked: 'Подключение заблокировано',
            connectionError: 'Ошибка соединения',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'Журналы установки не найдены.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {Ваша текущая версия Plesk устарела.} other {Ваша текущая версия Plesk {version} устарела.}}',
                        fixDescription: 'Рекомендуем обновить сервер до последней доступной версии Plesk Obsidian и включить автообновление. Дополнительную информацию можно найти на странице <link>Политика управления жизненным циклом Plesk</link>.',
                        goToUpdatesButton: 'Перейти к обновлениям {product}',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {Ваша текущая версия Plesk не поддерживается} other {Ваша текущая версия Plesk {version} не поддерживается}} и будет отключена через 14 дней.',
                        fixDescription: 'Рекомендуем обновить сервер до последней доступной версии Plesk Obsidian и включить автообновление. Дополнительную информацию можно найти на странице <link>Политика управления жизненным циклом Plesk</link>.',
                        goToUpdatesButton: 'Перейти к обновлениям {product}',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'С сервером не было связи в течение 14 дней, поэтому он был помечен как неактивный.',
                        lastCheckMessage: 'Последняя проверка статуса подключения: {lastCheckConnection}',
                        fixDescription: 'Чтобы снова начать использовать его, нажмите «Повторно проверить подключение»',
                        recheckConnectionButton: 'Повторно проверить подключение',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'Сервер помечен как неактивный, потому что на нем запущена {version, select, _ {неподдерживаемая версия Plesk} other {неподдерживаемая версия Plesk {version}}}.',
                        fixDescription: 'Чтобы сервер стал активным, обновите Plesk и выполните синхронизацию сервера.',
                        goToUpdatesButton: 'Перейти к обновлениям {product}',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Добавить сервер',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Добавить новый сервер',
                closeButton: 'Закрыть',
                serverInstallTitle: 'Установить {productType}',
                installNewProduct: 'Установить новый продукт на сервер Linux',
                connectInstallation: 'Подключить сервер с установленным продуктом',
                serverInstallDescription: 'Установить {productType} на физический или виртуальный сервер и добавить его в Перечень серверов.',
                connectPleskTitle: 'Подключить Plesk',
                connectPleskDescription: 'Добавить сервер с Plesk в Перечень серверов.',
                connectCPanelTitle: 'Подключить cPanel',
                connectCPanelDescription: 'Добавить сервер с cPanel в Перечень серверов.',
            },
            CustomInstallationStep: {
                title: 'Установить {productType} автоматически на сервер Linux',
                backButton: 'Назад',
                submitButton: 'Установить',
                successMessage: '{productType} устанавливается на <b>{host}</b>.',
            },
            AddServerWithRedirectStep: {
                title: 'Подключить сервер Plesk',
                description: 'После нажатия кнопки "Подключить" вы будете перенаправлены в Plesk, куда вам необходимо войти с правами администратора.',
                proceed: 'Подключить',
                proceedAnyway: 'Все равно подключить',
                nextStep: 'Следующий шаг',
                back: 'Назад',
                addressLabel: 'Имя хоста сервера',
                addressDescription: 'URL-адрес сервера и порт для подключения. Например: "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'Подключить сервер cPanel',
                close: 'Закрыть',
                back: 'Назад',
                paragraph1: 'Скачайте и запустите установщик плагина cPanel: скопируйте и вставьте следующее описание в консоль сервера, используя права root.',
                paragraph2: 'Вам потребуется <a>токен API Plesk 360</a>. Вы можете создать новый или использовать имеющийся.',
                paragraph3: 'Созданный сервер появится в перечне серверов.',
                copied: 'Скопировано.',
            },
        },
    },
};