// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, TranslationProps } from '@plesk/ui-library';
import styles from './ContentHeader.module.less';
import Tabs, { TabProps } from '@platform360/libs/shared-web/components/Page/Tabs';
import { ReactNode } from 'react';

export type ContentHeaderProps = {
    title?: TranslationProps;
    tabs?: TabProps[];
    contentAddon?: ReactNode;
};

export const ContentHeader = ({ title, contentAddon, tabs }: ContentHeaderProps) => (
    <div>
        {contentAddon}
        {title && (
            <Heading className={styles.title} level={1}>
                {title}
            </Heading>
        )}
        {tabs && <Tabs className={styles.tabs} tabs={tabs} />}
    </div>
);
