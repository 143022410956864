// Copyright 2024. WebPros International GmbH. All rights reserved.

import { License } from '@platform360/licenses/web/api/ka/responseTypes/License';
import doRequest from '@platform360/licenses/web/api/doRequest';
import { Button, Grid, GridCol, Popover } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useState } from 'react';
import isCancelPossible from '@platform360/licenses/web/units/cancelation/helpers/isCancelPossible';
import CancelButton from '@platform360/licenses/web/units/cancelation/components/CancelButton/CancelButton';
import NonBreakingSpace from '@platform360/licenses/web/components/HTMLEntities/NonBreakingSpace';
import cancelKeySubscription from '@platform360/licenses/web/api/ka/keys/cancelKeySubscription';
import getTypeformLinkByLicense from '@platform360/licenses/web/units/cancelation/helpers/getTypeformLinkByLicense';
import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';
import { useDispatch } from 'react-redux';

type CancelKeySubscriptionProps = {
    license: License;
    onConfirm?: () => void;
};

const CancelKeySubscription = ({ license, onConfirm }: CancelKeySubscriptionProps) => {
    const translate = useTranslate();
    const [popoverOpened, setPopoverState] = useState(false);
    const dispatch = useDispatch();

    const hidePopover = () => {
        setPopoverState(false);
    };
    const showPopover = () => {
        setPopoverState(true);
    };

    const handleSubmit = async () => {
        hidePopover();
        onConfirm?.();
        await doRequest(cancelKeySubscription(license.id));
        window.open(getTypeformLinkByLicense(license), '_blank');
        dispatch(refreshKeyList());
        setTimeout(() => {
            // Refresh key list after 5 seconds for the case when the key is not yet updated
            dispatch(refreshKeyList());
        }, 5000);
    };

    if (!isCancelPossible(license)) {
        return null;
    }

    return (
        <Popover
            title={translate('Keys.Cancel.popover.title', { product: license.type })}
            target={<CancelButton data-type={'Cancel--Unsubscribe-button'} onClick={showPopover} />}
            placement="top-right"
            visible={popoverOpened}
            onClose={hidePopover}
        >
            <Grid gap={'xs'}>
                <GridCol>
                    {translate('Keys.Cancel.popover.message', { date: license.nextBillingDate })}
                </GridCol>
            </Grid>
            <GridCol>
                <Button
                    data-type={'Cancel--Confirm-button'}
                    intent={'primary'}
                    onClick={handleSubmit}
                >
                    {translate('Keys.general.confirmAndFeedback')}
                </Button>
                <NonBreakingSpace />
                <Button data-type={'Cancel--Cancel-button'} onClick={hidePopover}>
                    {translate('Keys.general.cancel')}
                </Button>
            </GridCol>
        </Popover>
    );
};

export default CancelKeySubscription;
