// Copyright 2024. WebPros International GmbH. All rights reserved.

export const moduleId = '/licensing';
export const licensesPath = '/licenses';
export const emailsPath = '/emails';
export const activateLicense = '/activate-license';

export const licensesUri = moduleId.concat(licensesPath);
export const emailsUri = moduleId.concat(emailsPath);
export const activateLicenseUri = moduleId.concat(activateLicense);
