// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ServerStatus } from '@platform360/security-dashboard/web/types';

const mapServerStatus = (serverStatus: string, connectionStatus: string): ServerStatus => {
    const [generalState] = serverStatus.split(':');
    if (generalState === 'ok') {
        if (connectionStatus === 'connecting') {
            return 'installingAgent';
        }
        if (connectionStatus === 'error') {
            return 'agentInstallationError';
        }
        return 'ok';
    }

    if (serverStatus === 'error:unknown:EHOSTUNREACH') {
        return 'connectionBlocked';
    }

    return 'connectionError';
};

export default mapServerStatus;
