// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import BuyExtension, {
    Notify,
} from '@platform360/licenses/web/units/extensions/components/BuyExtension';
import getExtension from '@platform360/licenses/web/units/extensions/selectors/getExtension';
import initialization from '@platform360/licenses/web/units/extensions/actions/buyExtension/initialization';
import { StoreState } from '@platform360/licenses/web/store/types';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';
import { toaster } from '@platform360/libs/shared-web/toaster';
import { ToastProps } from '@plesk/ui-library';
import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';
import finalization from '@platform360/licenses/web/units/extensions/actions/buyExtension/finalization';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import { AG } from '@platform360/licenses/web/units/cleverbridge/constants/merchantOfRecordType';
import isCleverbridgePaymentExpired from '@platform360/licenses/web/helpers/cleverbridge/isCleverbridgePaymentExpired';
import flushItem from '@platform360/licenses/web/units/extensions/actions/flushItem';
import goToStore from '@platform360/licenses/web/units/extensions/actions/buyExtension/goToStore';
import submit from '@platform360/licenses/web/units/extensions/actions/buyExtension/submit';

const mstp = (state: StoreState) => {
    const { currencies, license, selectedItemPath } = upsellSelector(state);

    const {
        initialized,
        isFetching,
        onlineStoreData,
        processing,
        subscriptions,
        isPaygr,
        selectedSubscription,
        selectedProduct,
    } = buyExtensionSelector(state);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const cardExpired = isCleverbridgePaymentExpired(onlineStoreData?.paymentInfo);

    const extension = getExtension(state);

    return {
        initialized,
        isFetching,

        isPaygr,

        catalogCode: extension.catalogCode,

        showCurrencyPeriod: Array.from(currencies).length > 1,
        showBillingPeriod: Array.from(extension.periods).length > 1,

        hasSubscriptions: Array.from(subscriptions).length > 0,

        hasOnlineStoreData: Boolean(onlineStoreData),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        merchantOfRecordType: onlineStoreData ? onlineStoreData.merchantOfRecordType : AG,

        processing,

        notify: {
            licenseName: String(license.name),
            productName: String(extension.name),
            keyNumber: String(license.keyNumber),
        } as Notify,

        forDrawer: {
            open: Boolean(selectedItemPath),
            processing: Boolean(processing),
            hasSubscriptions: Array.from(subscriptions).length > 0,
            disabled: !initialized || isFetching,
            canSubmit:
                !cardExpired && Boolean(selectedSubscription && selectedProduct && !isFetching),
            title: selectedItemPath ? getExtension(state).name : '',
        },
    };
};

const mdtp = (dispatch: Dispatch) => ({
    initialization: () => {
        //  @ts-expect-error TS2345
        dispatch(initialization());
    },
    finalization: () => {
        dispatch(finalization());
    },
    addSuccess: ({ message, ...rest }: ToastProps) => {
        toaster.success(message, rest);
    },
    refreshPage: () => {
        // @ts-expect-error TS2345
        dispatch(refreshKeyList());
        toaster.clear();
    },
    onItemFlush: () => {
        dispatch(flushItem());
    },
    goToOnlineStore: (email: string) => {
        // @ts-expect-error TS2345
        dispatch(goToStore(email));
    },
    onStartBuyProcessing: () => {
        // @ts-expect-error TS2345
        dispatch(submit());
    },
});

export default connect(mstp, mdtp)(BuyExtension);
