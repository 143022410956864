// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    ApiListResponse,
    ApiResponse,
    FetcherOptions,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';
import {
    OperationsApiResponseRow,
    OperationsStatusFilter,
    OperationsTypeFilter,
} from '@platform360/security-dashboard/web/types';

export type OperationsSummaryResponse = {
    unreadNotificationsCount: number;
};

export const getOperationsSummary = async (): Promise<OperationsSummaryResponse> => {
    const { data } = await apiClient.get<ApiResponse<OperationsSummaryResponse>>(
        '/security-dashboard/operations/summary',
    );

    return data.data;
};

export const markAllFinishedOperationsAsRead = async (): Promise<void> => {
    await apiClient.post<ApiResponse<void>>('/security-dashboard/operations/summary');
};

type GetOperationsOptions = FetcherOptions<
    PaginatedQuery & OperationStatusQuery & OperationTypeQuery
>;

type OperationStatusQuery = {
    operationStatusFilter?: OperationsStatusFilter[];
};

type OperationTypeQuery = {
    operationTypeFilter?: OperationsTypeFilter[];
};

export const getOperations = async ({
    variables,
}: GetOperationsOptions): Promise<ApiListResponse<OperationsApiResponseRow>> => {
    const { data } = await apiClient.get<ApiListResponse<OperationsApiResponseRow>>(
        '/security-dashboard/operations',
        {
            params: {
                ...variables,
                operationStatusFilter: variables.operationStatusFilter?.join(','),
                operationTypeFilter: variables.operationTypeFilter?.join(','),
            },
        },
    );

    return data;
};
