// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ContentLoader, ListEmptyView, Text, Button } from '@plesk/ui-library';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'usehooks-ts';
import useInstallationOutdatedItemsQuery from '@platform360/security-dashboard/web/queries/useInstallationOutdatedItemsQuery';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import {
    InstallationOutdatedItemsResponse,
    InstallationUpdateRequest,
} from '@platform360/security-dashboard/web/api/installations';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DataList from '@platform360/libs/shared-web/components/DataList';
import InstallationComponentFlag from '@platform360/security-dashboard/web/components/InstallationComponentFlag';
import { useInstallationUpdaterMutation } from '@platform360/security-dashboard/web/mutations/useInstallationUpdaterMutation';
import image from './your-website-is-up-to-date.png';
import { UseFilterProps } from '@platform360/libs/shared-web/helpers/useFilter';
import Filters from '@platform360/security-dashboard/web/components/Installations/ComponentFilters/Filters';
import Toolbar from '@platform360/security-dashboard/web/components/Installations/WebsiteDrawer/TabOutdated/Toolbar';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import styles from './TabOutdated.module.less';
import {
    InstallationComponent,
    InstallationComponents,
} from '@platform360/security-dashboard/shared/installation-component';

type TabOutdatedProps = {
    displayTitle: string;
    installationId: number;
    filter: UseFilterProps<InstallationComponent>;
};

const prepareUpdateInstallationRequest = (
    selectedItems: InstallationOutdatedItemsResponse[],
): InstallationUpdateRequest => {
    const selectedPlugins = selectedItems.filter(
        (outdated) => outdated.component === InstallationComponents.plugin,
    );
    const selectedThemes = selectedItems.filter(
        (outdated) => outdated.component === InstallationComponents.theme,
    );
    const selectedCore = selectedItems.filter(
        (outdated) => outdated.component === InstallationComponents.core,
    );
    const request: InstallationUpdateRequest = {
        [InstallationComponents.core]: false,
        plugins: [],
        themes: [],
    };

    if (selectedCore.length) {
        request[InstallationComponents.core] = true;
    }
    if (selectedPlugins.length) {
        request.plugins = selectedPlugins.map((plugin) => plugin.slug);
    }
    if (selectedThemes.length) {
        request.themes = selectedThemes.map((theme) => theme.slug);
    }

    return request;
};

const TabOutdated = ({ installationId, displayTitle, filter }: TabOutdatedProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const isOutdatedShown = useRef<boolean>(false);
    const {
        data: responseData,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useInstallationOutdatedItemsQuery({
        variables: { installationId },
        placeholderData: (prev) => prev,
    });
    const data = responseData?.data || [];
    const [selection, setSelection] = useState<string[]>([]);
    const translate = useTranslate('security-dashboard.Installations.WebsiteDrawer.tabs.outdated');
    const search = useSearch('searchItem', () => analyticsEvents.wpTabSiteOutdatedSearchSubmit());
    const { resetFilter, setFilter, filter: componentFilter } = filter;
    const { mutate: updateInstallation, isPending: isInstallationUpdating } =
        useInstallationUpdaterMutation();
    const vertical = useMediaQuery('(max-width: 768px)');
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const searchApplied = !!search.value;
    const filterApplied = componentFilter.length !== 0;
    const filtered = searchApplied || filterApplied;
    const filteredBySearchData = data.filter((item) =>
        searchApplied ? item.title.toLowerCase().includes(search.value.toLowerCase()) : true,
    );
    const filteredData = filteredBySearchData.filter((item) =>
        filterApplied ? componentFilter.includes(item.component) : true,
    );
    const totalRows = filteredData.length;

    useEffect(() => {
        if (!responseData) {
            return;
        }

        if (!isOutdatedShown.current) {
            isOutdatedShown.current = true;
            analyticsEvents.wpSglSiteOutdatedShown(installationId, responseData.totalCount);
        }
    }, [analyticsEvents, installationId, responseData]);

    useEffect(() => {
        const filteredSelection = selection.filter((slug) =>
            filteredData.some((element) => element.slug === slug),
        );
        if (!selection.every((element) => filteredSelection.includes(element))) {
            setSelection(filteredSelection);
        }
    }, [selection, filteredData]);

    if (isInstallationUpdating) {
        return <ContentLoader />;
    }

    const resetSelection = () => setSelection([]);

    const updateOutdatedHandler = () => {
        const selectedItems = selection.length
            ? filteredData.filter((item) => selection.includes(item.slug))
            : filteredData;
        resetSelection();
        const request = prepareUpdateInstallationRequest(selectedItems);
        updateInstallation({ installationId, request });
        analyticsEvents.wpSglSiteUpdateClick(selectedItems.length);
    };

    const handleUpdateItem = (item: InstallationOutdatedItemsResponse) => {
        resetSelection();
        const request = prepareUpdateInstallationRequest([item]);
        updateInstallation({ installationId, request });
        analyticsEvents.wpSglSiteItemUpdateClick();
    };

    const columns: ListProps<InstallationOutdatedItemsResponse>['columns'] = [
        {
            key: 'title',
            title: translate('columns.name'),
            type: 'title',
            render: ({ title, version }) => (
                <Text bold>
                    {title} {version}
                </Text>
            ),
        },
        {
            key: 'type',
            title: translate('columns.type'),
            render: ({ component }) => <InstallationComponentFlag component={component} />,
        },
        {
            key: 'update',
            title: '',
            width: '1%',
            align: 'right',
            render: (item) => (
                <Button fill={!vertical} onClick={() => handleUpdateItem(item)}>
                    {translate('updateTo', { version: item.availableVersion })}
                </Button>
            ),
        },
    ];

    const handleSearch = (value: string) => search.onSearch(value);

    const handleSelectionChange = (selection: React.SetStateAction<string[]>) =>
        setSelection(selection);

    const handleFilter = (filter: InstallationComponent[]) => {
        analyticsEvents.wpTabSiteOutdatedFilterFormSubmit();
        setFilter(filter);
        resetSelection();
        setIsFilterOpen(false);
    };

    const handleFilterReset = () => {
        resetFilter();
        resetSelection();
        setIsFilterOpen(false);
        analyticsEvents.wpTabSiteOutdatedFilterResetClick();
    };

    const filters = (
        <Filters<InstallationOutdatedItemsResponse>
            filter={componentFilter}
            onFilterApply={handleFilter}
            onReset={handleFilterReset}
            filteredData={filteredBySearchData}
        />
    );

    return (
        <DataList
            rowKey="slug"
            className={classNames(styles.list, vertical && styles.listVertical)}
            columns={columns}
            data={filteredData}
            selection={selection}
            onSelectionChange={handleSelectionChange}
            loading={isLoading || isPreviousData}
            totalRows={totalRows}
            toolbar={
                <>
                    <Toolbar
                        selectedItems={selection}
                        filteredData={filteredData}
                        displayTitle={displayTitle}
                        totalRows={totalRows}
                        searchValue={search.value}
                        onSearch={handleSearch}
                        filter={componentFilter}
                        isFilterOpen={isFilterOpen}
                        onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
                        updateOutdatedHandler={updateOutdatedHandler}
                    />
                    {isFilterOpen && vertical && filters}
                </>
            }
            search={search}
            filtered={filtered}
            emptyView={
                <ListEmptyView
                    title={translate('emptyList.title')}
                    description={translate('emptyList.description')}
                    image={image}
                />
            }
            vertical={vertical}
            sidebar={isFilterOpen && !vertical ? filters : undefined}
        />
    );
};

export default TabOutdated;
