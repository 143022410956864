// Copyright 2024. WebPros International GmbH. All rights reserved.
import { StoreState } from '@platform360/licenses/web/store/types';
import { STATE_KEY } from '@platform360/licenses/web/store';
import { ACTIVE, TERMINATED } from '@platform360/licenses/web/api/ka/const/license';
import { Key } from '@platform360/licenses/web/api/ka/responseTypes/Keys';
import { UNIQUENESS_OF_360_MONITORING } from '@platform360/licenses/web/units/buy360MonitoringButton/constants/uniqueness';

const has360Monitoring = (state: StoreState): boolean =>
    state[STATE_KEY].keyLists.active.some(
        (key: Key) =>
            (key.mainKey.status === ACTIVE || key.mainKey.status === TERMINATED) &&
            key.mainKey.type.startsWith(UNIQUENESS_OF_360_MONITORING),
    );

export default has360Monitoring;
