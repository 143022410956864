// Copyright 2024. WebPros International GmbH. All rights reserved.
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import SettingsDrawer, {
    FormValues,
} from '@platform360/security-dashboard/web/components/Servers/SettingsDrawer/SettingsDrawer';
import { Skeleton } from '@plesk/ui-library';
import {
    useServerQuery,
    useServerSettingsQuery,
} from '@platform360/security-dashboard/web/queries';
import { useParams } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { UpdateSettingsOptions } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import {
    SaveServerSettingsRequest,
    ServerSettingsResponse,
} from '@platform360/security-dashboard/web/api/servers';
import { useSaveServerSettingsMutation } from '@platform360/security-dashboard/web/mutations';

export const defaultInitialFormValues: FormValues = Object.freeze({
    updates: {
        core: UpdateSettingsOptions.notSet,
        plugins: UpdateSettingsOptions.notSet,
        themes: UpdateSettingsOptions.notSet,
        safeUpdate: SafeUpdateOptions.notSet,
    },
    dailyTask: {
        taskStartTimeHours: null,
        taskStartTimeMinutes: null,
    },
});

const getInitialFormValues = (settings?: ServerSettingsResponse): FormValues => {
    if (!settings) return defaultInitialFormValues;

    const {
        updates: { core, plugins, themes, safeUpdate },
    } = settings;

    return {
        ...settings,
        updates: {
            core: core.value ?? 'parent',
            themes: themes.value ?? 'parent',
            plugins: plugins.value ?? 'parent',
            safeUpdate: safeUpdate.value ?? 'parent',
        },
    };
};

export type SingleSettingsDrawerProps = ClosableProps;

const SingleSettingsDrawer = ({ isOpen, onClose, ...props }: SingleSettingsDrawerProps) => {
    const translate = useTranslate('security-dashboard.Servers.SettingsDrawer');

    const { serverId: srvId } = useParams<{ serverId: string }>();
    const serverId = Number(srvId);

    const { data: serverData, isLoading: isServerLoading } = useServerQuery({
        variables: { serverId },
    });

    const { mutate, isPending: isSaving } = useSaveServerSettingsMutation();

    const {
        data: settings,
        isLoading,
        isError,
    } = useServerSettingsQuery({
        variables: { serverId },
    });
    const initialFormValues = getInitialFormValues(settings);

    const handleSave = (requestData: SaveServerSettingsRequest, onSuccess: () => void) => {
        mutate(
            { variables: { serverId, request: requestData } },
            {
                onSuccess,
            },
        );
    };

    const renderSubtitle = () => {
        if (isServerLoading) {
            return <Skeleton height={15} />;
        }
        return translate('subTitle.single', { serverName: serverData?.displayTitle });
    };

    return (
        <SettingsDrawer
            subTitle={renderSubtitle()}
            initialFormValues={initialFormValues}
            parentUpdateSettings={settings?.updates}
            drawerBaseUrl={`/security-dashboard/servers/${serverId}/settings`}
            isLoading={isLoading}
            isLoadError={isError}
            isSaving={isSaving}
            onSave={handleSave}
            isOpen={isOpen}
            onClose={onClose}
            {...props}
        />
    );
};

export default SingleSettingsDrawer;
