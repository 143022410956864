// Copyright 2024. WebPros International GmbH. All rights reserved.

import { CANCEL_ADJUST } from '@platform360/licenses/web/constants/actions';

export type CancelAdjust = {
    type: typeof CANCEL_ADJUST;
};

export const cancelAdjust = (): CancelAdjust => ({
    type: CANCEL_ADJUST,
});
