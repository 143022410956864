// Copyright 2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import GrossVat, {
    GrossVatProps,
} from '@platform360/licenses/web/units/extensions/components/extensionDetails/GrossVat';
import { StoreState } from '@platform360/licenses/web/store/types';
import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';

const mstp = (state: StoreState): GrossVatProps => {
    const {
        currency,
        period,
        onlineStoreData: { nextBillingGrossPrice: gross, nextBillingVatPrice: vat },
    } = buyExtensionSelector(state);

    return {
        currency,
        period,
        vat,
        gross,
    };
};

export default connect(mstp)(GrossVat);
