// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import LabelItem from '@platform360/security-dashboard/web/components/LabelsList/LabelItem';

export type MitigatedLabelProps = {
    mitigationType?: 'deactivation' | 'protection';
    tooltip?: string;
};

export const MitigatedLabel = ({ mitigationType, ...props }: MitigatedLabelProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.InfoColumn.MitigatedLabel');

    let titleKey = 'mitigatedTitle';

    if (mitigationType === 'deactivation') {
        titleKey = 'mitigatedByDeactivationTitle';
    }
    if (mitigationType === 'protection') {
        titleKey = 'mitigatedByProtectionTitle';
    }

    return (
        <LabelItem {...props} intent="success">
            {translate(titleKey)}
        </LabelItem>
    );
};
