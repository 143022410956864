// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Filters from '@platform360/security-dashboard/web/components/Filters';
import { OperationTypes } from '@platform360/security-dashboard/shared/operation-type';
import {
    SegmentedControl,
    Toolbar as UILibToolbar,
    ToolbarExpander,
    ToolbarGroup,
} from '@plesk/ui-library';
import {
    OPERATION_STATUS_FAILED,
    OperationsStatusFilter,
    OperationsTypeFilter,
} from '@platform360/security-dashboard/web/types';

export const OPERATION_GENERAL_FILTER_ALL = 0;
export const OPERATION_GENERAL_FILTER_FAILED = 1;
export const OPERATION_GENERAL_FILTER_NOT_SELECTED = 2;

export type OperationsGeneralFilter =
    | typeof OPERATION_GENERAL_FILTER_ALL
    | typeof OPERATION_GENERAL_FILTER_FAILED
    | typeof OPERATION_GENERAL_FILTER_NOT_SELECTED;

const mapFilterToGeneral = (
    statusFilter: OperationsStatusFilter[],
    typeFilter: OperationsTypeFilter[],
) => {
    if (statusFilter.length === 0 && typeFilter.length === 0) {
        return OPERATION_GENERAL_FILTER_ALL;
    }
    if (
        statusFilter.length === 1 &&
        statusFilter.includes(OPERATION_STATUS_FAILED) &&
        typeFilter.length === 0
    ) {
        return OPERATION_GENERAL_FILTER_FAILED;
    }
    return OPERATION_GENERAL_FILTER_NOT_SELECTED;
};

export const mapGeneralToFilter = (
    generalFilter: OperationsGeneralFilter,
): { statusFilter: OperationsStatusFilter[]; typeFilter: OperationsTypeFilter[] } => {
    if (generalFilter === OPERATION_GENERAL_FILTER_FAILED) {
        return { statusFilter: [OPERATION_STATUS_FAILED], typeFilter: [] };
    }
    return { statusFilter: [], typeFilter: [] };
};

type ToolbarProps = {
    statusFilter: OperationsStatusFilter[];
    typeFilter: OperationsTypeFilter[];
    onResetFilter: () => void;
    onChangeFilter: (value: OperationsStatusFilter | OperationsTypeFilter) => void;
    onChangeGeneralFilter: (
        statusFilter: OperationsStatusFilter[],
        typeFilter: OperationsTypeFilter[],
    ) => void;
};

export const Toolbar = ({
    statusFilter,
    typeFilter,
    onResetFilter,
    onChangeFilter,
    onChangeGeneralFilter,
}: ToolbarProps) => {
    const translate = useTranslate('security-dashboard.GlobalTaskManagerDrawer.Toolbar');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const typeFilters: { name: OperationsTypeFilter; title: string }[] = [
        {
            name: OperationTypes.update,
            title: translate('filters.typeUpdate'),
        },
        {
            name: OperationTypes.fixVulnerabilitiesViaUpdate,
            title: translate('filters.typeFixVulnerabilitiesViaUpdate'),
        },
        {
            name: OperationTypes.refresh,
            title: translate('filters.refresh'),
        },
        {
            name: OperationTypes.findNewSites,
            title: translate('filters.typeFindNewSites'),
        },
        {
            name: OperationTypes.configureUpdates,
            title: translate('filters.typeConfigureUpdates'),
        },
        {
            name: OperationTypes.mitigationDeactivateAsset,
            title: translate('filters.mitigationDeactivateAsset'),
        },
        {
            name: OperationTypes.mitigationActivateAsset,
            title: translate('filters.mitigationActivateAsset'),
        },
        {
            name: OperationTypes.ignoreVulnerabilities,
            title: translate('filters.ignoreVulnerabilities'),
        },
        {
            name: OperationTypes.cancelIgnoreVulnerabilities,
            title: translate('filters.cancelIgnoreVulnerabilities'),
        },
        {
            name: OperationTypes.setDailyTaskTime,
            title: translate('filters.setDailyTaskTime'),
        },
    ];

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('buttons.groupTitle')}>
                <SegmentedControl
                    buttons={[
                        {
                            value: OPERATION_GENERAL_FILTER_ALL,
                            title: translate('buttons.allTasks'),
                        },
                        {
                            value: OPERATION_GENERAL_FILTER_FAILED,
                            title: translate('buttons.failed'),
                        },
                    ]}
                    selected={mapFilterToGeneral(statusFilter, typeFilter)}
                    onChange={(value: OperationsGeneralFilter) => {
                        if (value === OPERATION_GENERAL_FILTER_ALL) {
                            analyticsEvents.wpFilterAllClick();
                        } else if (value === OPERATION_GENERAL_FILTER_FAILED) {
                            analyticsEvents.wpFilterFailedClick();
                        }
                        const { statusFilter, typeFilter } = mapGeneralToFilter(value);
                        onChangeGeneralFilter(statusFilter, typeFilter);
                    }}
                />
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('filters.groupTitle')}>
                <Filters<OperationsTypeFilter>
                    onReset={onResetFilter}
                    onFilterChange={onChangeFilter}
                    filters={[...typeFilters]}
                    selected={[...typeFilter]}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
