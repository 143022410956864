// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './AuthLayout.module.less';
import { Branding } from '@platform360/libs/shared-web/types';
import classNames from 'classnames';
import { MonitoringPageIntro, PlatformPageIntro } from '@platform360/auth/web/components/PageIntro';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

const pageIntros: Record<Branding, ReactNode> = {
    platform: <PlatformPageIntro />,
    // fallback, security dashboard has its own layout component.
    'security-dashboard': <PlatformPageIntro />,
    monitoring: <MonitoringPageIntro />,
    'get-plesk': <PlatformPageIntro />,
    'get-cpanel': <PlatformPageIntro />,
};

type AuthLayoutProps = {
    sidebar: ReactNode;
    footer: ReactNode;
    children: ReactNode;
};

const AuthLayout = ({ sidebar, footer, children }: AuthLayoutProps) => {
    const {
        auth: { branding },
    } = useApplicationSettings();

    return (
        <>
            <div
                className={classNames(styles.root, {
                    [styles.monitoringRoot ?? '']: branding === 'monitoring',
                })}
            >
                <div className={styles.content}>
                    <div className={styles.contentI}>
                        {pageIntros[branding]}
                        {children}
                    </div>
                </div>
                {sidebar}
            </div>
            {footer}
        </>
    );
};

export default AuthLayout;
