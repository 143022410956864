// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { readNotification } from '@platform360/security-dashboard/web/api/notifications';
import { useNotificationsQuery } from '@platform360/security-dashboard/web/queries';

const useReadNotificationsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: readNotification,
        onMutate: async (ids) => {
            await queryClient.cancelQueries({ queryKey: useNotificationsQuery.getQueryKey() });
            useNotificationsQuery.setQueryData(
                queryClient,
                useNotificationsQuery.getQueryKey(),
                (data) => {
                    if (!data) {
                        return data;
                    }

                    return data.map((notification) => {
                        if (ids.includes(notification.id)) {
                            return { ...notification, isRead: true };
                        }

                        return notification;
                    });
                },
            );
        },
    });
};

export default useReadNotificationsMutation;
