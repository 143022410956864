// Copyright 2024. WebPros International GmbH. All rights reserved.

import Loading from '@platform360/libs/shared-web/components/Loading';
import { useAuth, EmailNotVerifiedException } from '@platform360/libs/shared-web/auth';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/browser';
import { setSentryUser } from '@platform360/libs/shared-web/helpers/sentry';
import { analyticsClient } from '@platform360/libs/shared-web/analytics';
import {
    useAuthAnalyticsEvents,
    AuthAnalyticsEvents,
} from '@platform360/auth/web/helpers/analytics';
import getUrlPathname from '@platform360/libs/shared-web/helpers/getUrlPathname';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { isRedirectUrlAllowed } from './isRedirectUrlAllowed';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { Session } from '@platform360/libs/shared-web/auth/auth-state';
import { useEffect, useCallback } from 'react';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { useSendVerification } from '@platform360/auth/web/mutations';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';

const notifyAmplitudeOnSuccessLogin = (
    { userId }: Session,
    analyticsEvents: AuthAnalyticsEvents,
    redirectUrl?: string,
) => {
    setSentryUser(userId);
    analyticsClient.setUserId(userId);
    analyticsClient.sessionStart(userId);

    if (redirectUrl) {
        const url = new URL(redirectUrl, 'http://localhost');
        analyticsClient.setUtmProperties(url.searchParams);
    }

    /* Analytics Events */
    const redirectPathname = getUrlPathname(redirectUrl || '');
    if (redirectPathname === '/monitoring/promo') {
        analyticsEvents.monitoringPromoUserLogin();
    }
    if (
        redirectPathname === '/digitalocean/login' ||
        redirectPathname === '/digitalocean/sign-up'
    ) {
        analyticsEvents.digitalOceanUserLogin();
    }
};

const parseRedirectUrl = (str: string): string | undefined => {
    const { redirectUrl } = qs.parse(str);
    if (typeof redirectUrl !== 'string') {
        return undefined;
    }

    if (redirectUrl === '/') {
        return undefined;
    }

    return redirectUrl;
};

let didInitialise = false;

export const Callback = () => {
    const { processAuthentication } = useAuth();
    const currentUser = useCurrentUser();
    const { mutateAsync: sendVerificationEmail } = useSendVerification();
    const redirectUrl = parseRedirectUrl(window.location.search.substring(1));
    const { allowLoginRedirectsTo } = useConfig();
    const navigate = useNavigate();
    const { defaultLoginRedirect, failedLoginHandler } = useApplicationSettings();
    const analyticsEvents = useAuthAnalyticsEvents();
    const processRedirectUrl = useCallback(
        async (savedRedirectUrl?: string): Promise<string> => {
            if (savedRedirectUrl) {
                try {
                    await apiClient.delete('/accounts/me/redirect-url');
                } catch (e) {
                    captureException(e);
                }
            }

            for (const redirect of [redirectUrl, savedRedirectUrl]) {
                if (redirect && isRedirectUrlAllowed(redirect, allowLoginRedirectsTo)) {
                    return redirect;
                }
            }
            return defaultLoginRedirect;
        },
        [allowLoginRedirectsTo, defaultLoginRedirect, redirectUrl],
    );

    useEffectOnce(() => {
        // Avoid duplicate renders, especially in strict mode.
        if (didInitialise) {
            return;
        }
        didInitialise = true;

        const authenticate = async () => {
            try {
                const session = await processAuthentication();

                notifyAmplitudeOnSuccessLogin(session, analyticsEvents, redirectUrl);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn(`Failed to process authentication: ${e.message}`);

                if (e instanceof EmailNotVerifiedException) {
                    await sendVerificationEmail({
                        email: e.email,
                    });
                }

                if (failedLoginHandler !== undefined) {
                    failedLoginHandler({ redirectUrl, navigate });
                    return;
                }

                navigate(`/auth/login?${qs.stringify({ redirectUrl })}`);
            }
        };

        void authenticate();
    });

    useEffect(() => {
        const navigateToRedirectUrl = async () => {
            const redirectTo = await processRedirectUrl(currentUser.redirectUrl);

            if (redirectTo.startsWith('/')) {
                navigate(redirectTo);
            } else {
                location.replace(redirectTo);
            }
        };

        if (currentUser.isAuthorized) {
            void navigateToRedirectUrl();
        }
    }, [currentUser, navigate, processRedirectUrl]);

    return (
        <>
            {'loading...'}
            <Loading />
        </>
    );
};

export default Callback;
