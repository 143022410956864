// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Une étiquette portant ce nom existe déjà',
        invitationNotExist: 'Invitation inexistante.',
        wpApiClientUnreachable: 'Ce serveur ou ce site est indiqué comme étant hors ligne actuellement. Veuillez vérifier si votre serveur ou votre site est accessible et essayez à nouveau. Si le serveur ou le site fonctionne correctement et s\'il ne semble pas y avoir de problème avec sa connexion à WP Guardian, alors contactez notre assistance.',
    },
    termsOfUseLink: 'Conditions d\'utilisation de WP Guardian',
    NotFoundPage: {
        title: 'Page introuvable',
        description: "Oups ! Impossible de trouver la page demandée. Vous avez peut-être cliqué sur un lien incorrect ; ou la page a été supprimée.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Confirmer l\'annulation de l\'activation de la licence',
            description: 'Vous êtes sur le point d\'annuler l\'activation de la licence : {product}. Vous pourrez activer la licence à tout moment ultérieurement en cliquant à nouveau sur le lien d\'activation. Si vous avez acheté la licence pour un autre compte, connectez-vous à ce compte et suivez le lien d\'activation.',
            cancelButton: 'Annuler',
            continueButton: 'Poursuivre l\'activation',
        },
        SecurityDashboardActivateLicense: {
            title: 'Confirmer l\'activation de la licence WP Guardian',
            description: 'Confirmer l\'activation de la licence <b>{product}</b> pour le compte suivant : <mailto>{userEmail}</mailto>. La licence peut être activée pour un seul compte uniquement.',
            fixableErrorDescription: 'Un problème est survenu pendant l\'activation de la licence WP Guardian. Veuillez essayer à nouveau ou envoyer une demande d\'assistance.',
            nonFixableErrorDescription: 'Un problème est survenu pendant l\'activation de la licence WP Guardian. Veuillez envoyer une demande d\'assistance.',
            loaderText: 'Votre compte WP Guardian est en cours de configuration',
            redirectText: 'Redirection vers WP Guardian',
            confirmButton: 'Confirmer',
            cancelButton: 'Annuler',
            retryButton: 'Réessayer',
            gotItButton: 'J\'ai compris.',
            errors: {
                linkError: 'Le lien d\'activation n\'est pas valide.',
                unknownError: 'Erreur interne du service d\'activation.',
            },
        },
    },
    Filters: {
        all: 'Tout',
    },
    FiltersForm: {
        title: 'Filtre',
        clear: 'Effacer',
        apply: 'Appliquer le filtre',
    },
    Invitation: {
        activationFailure: 'Impossible d\'activer le lien d\'invitation',
    },
    UnderConstructionPopover: {
        title: 'En construction',
        description: "Nous n'avons pas tout à fait terminé !",
    },
    RiskRankTitle: {
        title: 'Risque',
        description: 'Le niveau de risque est une évaluation moyenne des impacts d\'une vulnérabilité. Il repose sur l\'évaluation CVCS, l\'évaluation EPSS, la priorité Patchstack Patch Priority et d\'autres facteurs.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'Cette vulnérabilité est déjà exploitée activement, nous vous recommandons vivement de réagir immédiatement.',
        highRisk: 'Cette vulnérabilité présente des risques élevés auxquels il faut remédier au plus vite.',
        mediumRisk: 'Cette vulnérabilité présente des risques moyens auxquels il faut remédier, mais n\'est pas urgente.',
        lowRisk: 'Cette vulnérabilité présente des risques faibles qui peuvent être ignorés en toute sécurité.',
        cvss: 'CVSS: {score}',
        emptyCvss: 'N/A',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protection désactivée',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Ignorée',
        },
        ignoredDueToLowRisk: {
            title: 'Ignorée automatiquement',
        },
        doNotIgnoreAutomatically: {
            title: 'Ne pas ignorer automatiquement',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: 'Détection des nouveaux sites WordPress',
        refreshInstallations: 'Actualisation',
        detachInstallations: 'Dissociation en cours',
        updateInstallation: 'Mise à jour en cours',
        massUpdateInstallations: 'Mise à jour en cours',
        applyVirtualPatches: 'Activer la protection contre les vulnérabilités',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Enregistrement des paramètres de mise à jour',
        clearInstallationCache: 'Suppression du cache de l\'installation en cours',
        checkVulnerability: 'Vérification des vulnérabilités',
        mitigateVulnerabilities: 'Réduction de l\'exposition aux vulnérabilités',
        setDailyTaskStartTime: 'Modification de la planification des vérifications de sécurité',
    },
    installationComponents: {
        core: 'Core',
        plugin: 'Plug-in',
        theme: 'Thème',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {L\'étiquette a été assignée} other {Les étiquettes ont été assignées}}',
        failed: '{count, select, 1 {Impossible d\'assigner l\'étiquette} other {Impossible d\'assigner les étiquettes}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {L\'étiquette a été retirée} other {Les étiquettes ont été retirées}}',
        failed: '{count, select, 1 {Impossible de retirer l\'étiquette} other {Impossible de retirer les étiquettes}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {L\'étiquette a été assignée} other {Les étiquettes ont été assignées}}',
        failed: '{count, select, 1 {Impossible d\'assigner l\'étiquette} other {Impossible d\'assigner les étiquettes}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {L\'étiquette a été retirée} other {Les étiquettes ont été retirées}}',
        failed: '{count, select, 1 {Impossible de retirer l\'étiquette} other {Impossible de retirer les étiquettes}}',
    },
    useCreateLabelMutation: {
        success: 'L\'étiquette a été créée.',
        failed: 'Impossible de créer l\'étiquette',
    },
    useEditLabelMutation: {
        success: 'L\'étiquette a été modifiée.',
        failed: 'Impossible de modifier l\'étiquette',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {L\'étiquette a été supprimée} other {Les étiquettes ont été supprimées}}.',
        failed: '{count, select, 1 {Impossible de supprimer l\'étiquette} other {Impossible de supprimer les étiquettes}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'Les paramètres de notifications ont été mis à jour.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'Impossible de planifier l\'actualisation des données du site sur le serveur <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Impossible de planifier l\'actualisation des données du site sur <b>#</b> serveur.} other {Impossible de planifier l\'actualisation des données du site sur <b>#</b> serveurs.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'Impossible de planifier la recherche de nouveaux sites WordPress sur le serveur <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Impossible de planifier la recherche de nouveaux sites WordPress sur <b>#</b> serveur.} other {Impossible de planifier la recherche de nouveaux sites WordPress sur <b>#</b> serveurs.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'Le serveur <b>{selectedServerTitle}</b> a été déconnecté.',
        notifySeveralServers: '{selectedServersCount, plural, one {<b>#</b> serveur a été déconnecté.} other {<b>#</b> serveurs ont été déconnectés.}}',
        notifySingleServerFail: 'Le serveur <b>{selectedServerTitle}</b> n\'a pas été déconnecté.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {<b>#</b> serveur n\'a pas été déconnecté.} other {<b>#</b> serveurs n\'ont pas été déconnectés.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'Impossible de planifier les mises à jour de sites sur le serveur <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Impossible de planifier les mises à jour de sites sur <b>#</b> serveur.} other {Impossible de planifier les mises à jour de sites sur <b>#</b> serveurs.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Global',
            server: 'Serveur',
        },
        coreLabels: {
            disabled: 'Ne pas installer automatiquement les mises à jour',
            major: 'Installer automatiquement toutes les mises à jour',
            minor: 'Installer les mises à jour mineures (sécurité) automatiquement',
            notSet: 'Défini au niveau du site',
            minorDescription: 'Par exemple, installer automatiquement la version 4.7.1, mais ne pas installer la version 4.8.',
            doNotTouch: 'Ne pas modifier',
        },
        pluginsLabels: {
            disabled: 'Laisser l\'admin du site décider des plug-ins à mettre à jour automatiquement',
            disabledDescription: 'WordPress permet aux admins de sites d\'activer les mises à jour automatiques séparément pour chaque plug-in',
            major: 'Installer automatiquement toutes les mises à jour des plug-ins',
            majorDescription: 'Ignorer les paramètres de mises à jour automatiques pour chaque plug-in',
            minor: 'Installer automatiquement seulement les mises à jour de sécurité',
            minorDescription: 'Utiliser les paramètres individuels de mises à jour automatiques des plug-ins, si ces paramètres corrigent une vulnérabilité connue',
            notSet: 'Défini au niveau du site',
            doNotTouch: 'Ne pas modifier',
        },
        themesLabels: {
            disabled: 'Laisser l\'admin du site décider des thèmes à mettre à jour automatiquement',
            disabledDescription: 'WordPress permet aux admins de sites d\'activer les mises à jour automatiques pour chaque thème séparément',
            major: 'Installer automatiquement les mises à jour de tous les thèmes',
            majorDescription: 'Ignorer les paramètres de mises à jour automatiques de thèmes individuels pour chaque thème',
            minor: 'Installer automatiquement seulement les mises à jour de sécurité',
            minorDescription: 'Utiliser les paramètres de mises à jour automatiques de thèmes individuels, mais installer automatiquement les mises à jour si elles corrigent une vulnérabilité connue',
            notSet: 'Défini au niveau du site',
            doNotTouch: 'Ne pas modifier',
        },
        safeUpdatesLabels: {
            notSet: 'Défini au niveau du site',
            enabled: 'Activer les mises à jour sécurisées',
            disabled: 'Désactiver les mises à jour sécurisées',
            doNotTouch: 'Ne pas modifier',
        },
        safeUpdates: {
            title: 'Mises à jour fiables',
            description: 'Sauvegardez le site avant de le mettre à jour. Si une mise à jour met votre site hors service, la sauvegarde sera restaurée automatiquement.',
            checkbox: 'Activer les mises à jour sécurisées',
        },
        labels: {
            core: 'WordPress Core',
            plugins: 'Plug-ins',
            themes: 'Thèmes',
        },
        doNotTouch: 'Ne pas modifier',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'Les paramètres de mise à jour seront appliqués à tous les nouveaux serveurs ainsi qu\'aux serveurs actuels sans paramètres de mise à jour personnalisés.',
        checkboxDescription: 'Appliquer également les paramètres à tous les serveurs et sites avec des paramètres de mise à jour personnalisés',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Planification des vérifications de sécurité',
        typeOptions: {
            doNotTouch: 'Ne pas modifier',
            unset: 'Définie par serveur',
            custom: 'Personnaliser l\'heure',
        },
        timeLabel: 'Système horaire (24 heures)',
        timezoneDescription: 'Fuseau horaire : zone par défaut du serveur',
    },
    SettingsDrawer: {
        title: 'Paramètres',
        subTitle: 'Paramètres pour tous les serveurs et sites connectés',
        loadFailed: 'Échec du chargement des paramètres.',
        tabs: {
            updates: 'Mises à jour',
            dailyTaskTime: 'Planification',
            vulnerabilities: 'Vulnérabilités ',
        },
        DailyTaskTime: {
            description: 'WP Guardian vérifie s\'il y a de nouvelles vulnérabilités sur tous les serveurs et recherche les mises à jour disponibles toutes les 24 heures. Cette opération est gourmande en performances. Vous pouvez donc l\'exécuter à une heure spécifique par défaut sur tous les serveurs.',
        },
        successToast: 'Les paramètres ont été mis à jour.',
        buttonSave: 'Enregistrer',
    },
    Operations: {
        openTaskManagerLinkText: 'En savoir plus',
        operationsFailed: '{count, plural, one {Il y a # opération ayant échoué} other {Il y a # opérations ayant échoué}}',
        fallback: {
            success: 'L\'opération a été exécutée.',
            failed: 'L\'opération a échoué.',
        },
        servers: {
            demo: {
                success: 'Une opération a été exécutée sur un serveur démo.',
                failed: 'Une opération sur un serveur démo a échoué.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Impossible d\'activer la protection contre les vulnérabilités',
            },
            update: {
                success: '{count, plural, one {Installations sur # serveur ont été mises à jour} other {Installations sur # serveurs ont été mises à jour}}',
                failed: '{count, plural, one {Échec de la mise à jour des installations sur # serveur} other {Échec de la mise à jour des installations sur # serveurs}}',
            },
            refresh: {
                success: 'Actualisation des données du site terminée',
                failed: 'Échec de la récupération des données du site',
            },
            findNewSites: {
                success: '{count, plural, one {Recherche de nouvelles installations WordPress sur # serveur terminée} other {Recherche de nouvelles installations WordPress sur # serveurs terminée}}',
                failed: '{count, plural, one {Échec de la recherche de nouvelles installations WordPress sur # serveur} other {Échec de la recherche de nouvelles installations WordPress sur # serveurs}}',
            },
            configureUpdates: {
                success: 'Paramètres de mises à jour configurés',
                failed: 'Échec de la configuration des paramètres de mises à jour',
            },
            disconnect: {
                success: '{count, plural, one {# serveur a été déconnecté} other {# serveurs ont été déconnectés}}',
                failed: '{count, plural, one {# serveur n\'a pas pu être déconnecté} other {# serveurs n\'ont pas pu être déconnectés}}',
            },
        },
        installations: {
            demo: {
                success: 'L\'opération sur un serveur démo a bien fonctionné.',
                failed: 'Une opération sur un serveur démo a échoué.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'Toutes les installations WordPress sélectionnées ont été mises à jour.',
                failed: 'La mise à jour des installations WordPress sélectionnées a échoué.',
            },
            configureUpdates: {
                success: 'Les paramètres de mise à jour ont été modifiés',
                failed: 'La modification des paramètres de mises à jour a échoué.',
            },
            detach: {
                success: 'L\'installation WordPress a été dissociée de WP Guardian. Elle sera ignorée pendant les analyses serveur à venir. Pour que cette installation soit visible par WP Guardian, supprimez le fichier .wp-toolkit-ignore du répertoire de l\'installation.',
                failed: 'La dissociation de l\'installation WordPress a échoué.',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'Les vulnérabilités ont été corrigées lors de l\'installation des mises à jour.',
                failed: '{count, plural, one {Impossible de corriger les vulnérabilités sur # installation} other {Impossible de corriger les vulnérabilités sur # installations}}',
            },
            mitigationDeactivateAsset: {
                success: 'L\'exposition aux vulnérabilités a été réduite grâce à la désactivation du plug-in.',
                failed: '{count, plural, one {Impossible de réduire le risque d\'exposition à # vulnérabilité en désactivant le plug-in sur # installation} other {Impossible de réduire le risque d\'exposition aux vulnérabilités en désactivant le plug-in sur # installations}}',
            },
            mitigationActivateAsset: {
                success: 'Les plug-ins qui avaient été désactivés en raison de vulnérabilités ont été réactivés.',
                failed: '{count, plural, one {Impossible de réactiver les plug-ins qui étaient vulnérables auparavant sur # installation} other {Impossible de réactiver les plug-ins qui étaient vulnérables auparavant sur # installations}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'Les vulnérabilités ont été corrigées lors de l\'installation des mises à jour.',
                failed: '{count, plural, one {La correction d\'# vulnérabilité a échoué} other {La correction de # vulnérabilités a échoué}}.',
            },
            mitigationDeactivateAsset: {
                success: 'L\'exposition aux vulnérabilités a été réduite grâce à la désactivation du plug-in.',
                failed: '{count, plural, one {Échec de la réduction du risque d\'exposition à # vulnérabilité en désactivant le plug-in} other {Échec de la réduction du risque d\'exposition à # vulnérabilités en désactivant le plug-in}}',
            },
            mitigationActivateAsset: {
                success: 'Les plug-ins qui avaient été désactivés en raison de vulnérabilités ont été réactivés.',
                failed: '{count, plural, one {La réactivation des plug-ins concernés par # vulnérabilité a échoué} other {La réactivation des plug-ins concernés par # vulnérabilités a échoué}}.',
            },
            ignoreVulnerabilities: {
                success: 'Les vulnérabilités ont été ignorées.',
                failed: '{count, plural, one {Impossible d\'ignorer # vulnérabilité} other {Impossible d\'ignorer # vulnérabilités}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'Les vulnérabilités ne sont plus ignorées.',
                failed: '{count, plural, one {Impossible de ne plus ignorer # vulnérabilité} other {Impossible de ne plus ignorer # vulnérabilités}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Démarrage rapide',
        QuickStartWizard: {
            startStepName: 'Démarrage',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Notifications',
            securityCheckScheduleStepName: 'Vérification de sécurité',
            updateStepName: 'Mises à jour',
        },
        QuickStartStep: {
            pagination: '{current} sur {total}',
            ok: 'OK',
            next: 'Suivant',
            back: 'Retour',
        },
        StartStep: {
            title: 'Bienvenue dans WP Guardian',
            text: 'Configurez tous les éléments qui vous permettent de préserver la sécurité de vos sites WordPress.',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Heure des vérifications de sécurité',
            checkingTimeText: 'WP Guardian recherche toutes les 24 heures les modifications apportées à tous les serveurs et sites. Cette opération est gourmande en performances, en temps et consomme de nombreuses ressources.',
            vulnerabilitiesUpdateTitle: 'Mises à jour de la base de données des vulnérabilités',
            vulnerabilitiesUpdateText: 'La base de données des vulnérabilités est mise à jour automatiquement toutes les heures. Cette opération n\'affecte pas les performances des serveurs et des sites.',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'Principes de fonctionnement',
            howItWorksText: 'Une extension est installée dans WordPress et agit comme un pare-feu applicatif. Ni les fichiers ni le contenu du site WordPress ne sont modifiés : seules les règles du pare-feu qui ciblent les vulnérabilités présentes sur le site sont appliquées.',
            poweredBy: 'propulsé par {providerLogo}',
        },
        UpdatesStep: {
            next: "Suivant",
            updateSettings: 'Paramètres de mise à jour',
            updateSettingsText: 'Vous pouvez forcer les mises à jour automatiques de tous les sites connectés à WP Guardian, y compris les sites ajoutés ultérieurement. Vous pouvez configurer cette option par site ou par serveur par la suite.',
            backup: 'Sauvegardes avant mises à jour',
            backupText: 'Lorsque vous activez les mises à jour fiables, WP Guardian effectue une sauvegarde du site avant de procéder à la mise à jour. Si un problème se produit, le site est restauré automatiquement depuis la sauvegarde.',
            safeUpdates: 'Mises à jour fiables',
            customizedSettings: 'Paramètres personnalisés',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Journal des tâches',
        Toolbar: {
            buttons: {
                groupTitle: 'Boutons',
                allTasks: 'Toutes les tâches',
                failed: 'Échec',
            },
            filters: {
                groupTitle: 'Filtres',
                typeUpdate: 'Installer les mises à jour',
                refresh: 'Actualiser les données',
                typeFindNewSites: 'Rechercher de nouveaux sites',
                typeConfigureUpdates: 'Configurer les mises à jour',
                typeFixVulnerabilitiesViaUpdate: 'Corriger les vulnérabilités via la mise à jour',
                mitigationDeactivateAsset: 'Réduire l\'exposition aux vulnérabilités en désactivant le plug-in',
                mitigationActivateAsset: 'Réactiver les plug-ins désactivés à cause de vulnérabilités',
                ignoreVulnerabilities: 'Ignorer les vulnérabilités',
                cancelIgnoreVulnerabilities: 'Ne plus ignorer les vulnérabilités',
                setDailyTaskTime: 'Modifier la planification des vérifications de sécurité',
            },
            search: {
                title: 'Rechercher',
                placeholder: 'Rechercher',
            },
        },
        TasksList: {
            columns: {
                task: 'Tâches',
                status: 'Statut',
                started: 'Début',
                finished: 'Fin',
            },
            taskTypes: {
                fallBack: "Opération avec le code ''{type}''",
                demo: 'Opérations sur un serveur démo',
                update: 'Installer les mises à jour',
                refresh: 'Actualiser les données',
                detach: 'Dissocier',
                findNewSites: 'Rechercher de nouveaux sites',
                configureUpdates: 'Configurer les mises à jour',
                setDailyTaskTime: 'Modifier la planification des vérifications de sécurité',
                protect: 'Activer la protection contre les vulnérabilités',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Déconnecter',
                fixVulnerabilitiesViaUpdate: 'Corriger les vulnérabilités via la mise à jour',
                changeInstallationPluginStatus: 'Modifier le statut du plug-in',
                mitigationDeactivateAsset: 'Réduire l\'exposition aux vulnérabilités en désactivant le plug-in',
                mitigationActivateAsset: 'Réactiver les plug-ins désactivés à cause de vulnérabilités',
                ignoreVulnerabilities: 'Ignorer les vulnérabilités',
                cancelIgnoreVulnerabilities: 'Ne plus ignorer les vulnérabilités',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# serveur} other {# serveurs}}',
                sites: '{count, plural, one {# site} other {# sites}}',
                vulnerabilities: '{count, plural, one {# vulnérabilité} other {# vulnérabilités}}',
            },
            statuses: {
                success: 'Terminée',
                inProgress: 'En cours',
                failed: 'Échec',
                errors: 'Erreurs',
                unknown: 'Inconnu',
            },
            emptyList: {
                title: 'Aucune tâche',
                description: 'Aucune tâche n\'a été exécutée pour le moment.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Sites dont la protection est désactivée',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Réactiver la protection sur ce site} other {Réactiver la protection sur ces sites}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "Vous n'avez pas de licence.",
        trialDaysLeft: '{days, plural, one {Version de test (# jour restant)} other {Version de test (# jours restants)}}',
        trialUntil: 'Version de test',
    },
    PromoCodeForm: {
        promoCode: 'Votre code promo',
        apply: 'Appliquer',
        activationFailure: 'Impossible d\'activer le code promo',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Appliquer',
            trialTitle: 'Tester 14 jours',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial:'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Choisir une licence',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: 'Votre période de test a démarré !',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: 'Votre version de test a expiré.',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'Aucune licence',
            expired: '(expiré)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Partager votre avis',
            },
        },
        tabs: {
            servers: 'Serveurs',
            installations: 'Sites WordPress',
            vulnerabilities: 'Vulnérabilités ',
        },
        Footer: {
            Links: {
                followUs: 'Suivez-nous',
                company: 'Raison sociale',
                aboutWebpros: 'À propos de WebPros',
                cookiehub: 'Paramètres des cookies',
                privacyPolicy: 'Politique de confidentialité',
                knowledgeBase: 'Base de connaissances',
                documentation: 'Documentation',
                helpCenter: 'Help Center',
                contactUs: 'Nous contacter',
                legal: 'Informations légales',
            },
            copyright: '© {year} WebPros International GmbH. Tous droits réservés. Le logo WP Guardian et WP Guardian sont des marques déposées de WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Paramètres de mises à jour automatiques',
            },
            CardLoader: {
                loading: 'Chargement...',
            },
            InstallationsCard: {
                title: 'Sites WordPress',
                vulnerable: 'Vulnérables : {count}',
                outdated: 'Obsolètes : {count}',
                protected: 'Protégés : {count}',
                safe: 'OK : {count}',
                notOperable: 'Non opérationnels : {count}',
                poweredBy: '<whiteSpaceNoWrap>Propulsé par {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Vulnérabilités ',
                databaseUpdate: 'Mise à jour de la base de données',
                momentAgo: 'il y a quelques instants',
                noData: 'Aucune donnée',
                lastUpdateMinutes: '{minutes, plural, one {Il y a # min} other {Il y a # min}}',
                lastUpdateHours: '{hours, plural, one {Il y a # heure} other {Il y a # heures}}',
                lastUpdateDays: '{days, plural, one {Il y a # jour} other {Il y a # jours}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Score CVSS le plus élevé',
                needsProtection: '{count, plural, one {# : protection requise} other {# : protection requise}}',
            },
            QuickStartCard: {
                title: 'Démarrage rapide',
                ctaBlockTitle: 'Configurez la sécurité',
                ctaBlockText: 'en quelques clics !',
            },
        },
        UserMenuAddon: {
            quickStart: 'Démarrage rapide',
            license: 'Détails de la licence',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'Actuellement, aucune mise à jour ne corrige cette vulnérabilité.',
        columns: {
            position: 'Emplacement',
        },
        Onboarding: {
            title: 'Aucune vulnérabilité détectée',
            description: 'Connectez les serveurs pour détecter les sites WordPress, vérifier leurs mises à jour et détecter les vulnérabilités.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Exposition réduite',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Exposition réduite en désactivant le plug-in',
                tooltip: {
                    'singleSiteMitigatedByOther': 'Le site web concerné par cette vulnérabilité est actuellement sécurisé à l\'aide de moyens externes à WP Guardian.',
                    'mitigatedByOther': 'Les sites web concernés par cette vulnérabilité sont actuellement sécurisés à l\'aide de moyens externes à WP Guardian.',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Source:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'Cet enregistrement comporte du contenu soumis au copyright.',
            wordfenceCopyright: 'Copyright 2012-2024 Defiant Inc. Licence : par la présente, Defiant vous accorde une licence de droit d\'auteur perpétuelle, mondiale, non exclusive, gratuite, libre de redevances et irrévocable pour reproduire, préparer des travaux dérivés, afficher publiquement, exécuter publiquement, accorder des sous-licences et distribuer ces informations sur la vulnérabilité du logiciel. Toute copie d\'informations sur la vulnérabilité du logiciel que vous effectuez à ces fins est autorisée à condition que vous incluiez un lien hypertexte vers cet enregistrement de vulnérabilité et que vous reproduisiez la désignation de copyright de Defiant et cette licence dans une telle copie.',
            readMore: 'En savoir plus',
        },
        Toolbar: {
            groupFilterMenu: 'Filtre',
            groupSearch: 'Rechercher',
            manage: 'Gérer',
            patch: 'Protection',
            searchPlaceholder: 'Chercher une vulnérabilité…',
            UpdateButton: {
                update: 'Installer les mises à jour',
                cancel: 'Annuler',
                noSelection: 'Sélectionnez au moins une vulnérabilité à corriger à l\'aide d\'une mise à jour.',
                updateConfirmation: '{totalVulnerabilities, plural, one {Vous êtes sur le point de mettre à jour plusieurs sites pour corriger # vulnérabilité} other {Vous êtes sur le point de mettre à jour plusieurs sites pour corriger # vulnérabilités}}. Ce processus de mise à jour ne peut pas être interrompu lorsqu\'il a commencé.',
                updateConfirmationPluginTitle: 'Plusieurs plug-ins ont été désactivés.',
                updateConfirmationPluginCheckbox: 'Réactiver les plug-ins désactivés par les politiques de sécurité',
                updateConfirmationPluginCheckboxHint: 'Les plug-ins vulnérables désactivés par les politiques de sécurité seront automatiquement réactivés si leurs vulnérabilités ont été corrigées dans les mises à jour.',
                numberOfSites: 'Nombre de sites à mettre à jour :',
                numberOfSitesUnmanaged: 'Nombre de sites à mettre à jour (les sites non gérés seront ignorés) :',
                sitesCalculating: 'Calcul en cours…',
                sitesCalculatingError: 'Impossible de compter les sites',
                affectedSites: '{count, plural, one {{count} site} other {{count} sites}}',
                unmanagedLabel: '{count, plural, one {# non géré} other {# non gérés}}',
                updateByProtect: 'Actuellement, aucune mise à jour ne corrige cette vulnérabilité.',
                upgradeLicense: 'Mettre à niveau la licence',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Ignorer',
                noSelection: 'Sélectionnez au moins une vulnérabilité à ignorer ou à ne plus ignorer.',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} site} other {{count} sites}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Annuler',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Enable Protection',
                providerLogo: '<whiteSpaceNoWrap>Propulsé par {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Calcul en cours…',
                sitesCalculatingError: 'Impossible de calculer le nombre de sites concernés',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# non géré} other {# non gérés}}',
                update: 'Enable Protection',
                upgradeLicense: 'Mettre à niveau la licence',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Protection requise',
            filterStateIgnored: 'Ignorée',
            filterComponentCore: 'Core',
            filterComponentPlugin: 'Plug-in',
            filterComponentTheme: 'Thème',
            everywhere: 'Partout',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {Vous êtes sur le point de mettre à jour # site web.} other {Vous êtes sur le point de mettre à jour # sites web.}} Le processus de mise à jour ne peut pas être interrompu lorsqu\'il a démarré.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Plug-ins',
                installations: '{enabledCount, plural, one {# actif} other {# actifs}}',
                installationsDeactivated: '{count, plural, one {Désactivé sur # site web} other {Désactivé sur # sites web}}',
                confirmationDeactivation: '{count, plural, one {Vous êtes sur le point de désactiver un plug-in vulnérable sur # site web.} other {Vous êtes sur le point de désactiver un plug-in vulnérable sur # sites web.}} Ce processus ne peut pas être interrompu lorsqu\'il a démarré.',
                confirmationReactivation: '{count, plural, one {Vous êtes sur le point de réactiver un plug-in qui avait été désactivé pour des raisons de sécurité sur # site web. Cette action exposera le site web a des exploits potentiels.} other {Vous êtes sur le point de réactiver un plug-in qui avait été désactivé pour des raisons de sécurité sur # sites web. Cette action exposera les sites web a des exploits potentiels.}} Ce processus ne peut pas être interrompu lorsqu\'il a démarré.',
                dropdownDeactivate: 'Désactiver',
                buttonDeactivateWithCount: 'Désactiver {count}',
                buttonReactivateWithCount: 'Réactiver {count}',
                buttonDeactivateAll: 'Tout désactiver',
                buttonReactivate: 'Réactiver',
                tooltipAllPluginsDeactivatedByUser: 'WP Guardian ne réactive pas les plug-ins désactivés manuellement pas les admins de sites.',
            },
            updates: {
                title: 'Installer les mises à jour',
                installations: '{count, plural, one {# site} other {# sites}}',
                waitingForUpdatesTitle: 'Aucune mise à jour actuellement',
                buttonName: 'Tout mettre à jour',
            },
            patches: {
                allProtected: '{count, plural, one {# site} other {# sites}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Annuler',
                enableButton: 'Activer',
                installations: '{count} sur {total}',
                protectAllSites: 'Tout protéger',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Sites protégés',
                titleAllProtected: 'Tous les sites protégés',
            },
        },
        installations: '{count, plural, one {# site} other {# sites}}',
        servers: '{count, plural, one {# serveur} other {# serveurs}}',
    },
    Onboarding: {
        title: 'Aucun serveur connecté',
        description: 'Connectez les serveurs pour détecter les sites WordPress, vérifier leurs mises à jour et détecter les vulnérabilités.',
        demoServerWasAddedSuccessfully: 'Le serveur démo a été ajouté.',
        connectServers: '<addServerButton>Connecter votre propre serveur</addServerButton> <delimiter>ou</delimiter> <addDemoServerButton>utiliser notre serveur démo</addDemoServerButton>',
        hint: 'Le serveur démo est fourni par l\'équipe WP Guardian. Il comporte plusieurs sites WordPress. Cela vous permet d\'explorer les fonctionnalités du produit avant de connecter votre propre serveur.',
        feedbackButton: 'Partager votre avis',
        demoServerPopover: {
            description: 'Le serveur démo fonctionne en mode lecture seule. Les opérations invasives comme l\'installation de mises à jour ne modifieront pas le statut des sites web sur le serveur démo.',
            connectBtn: 'Connecter',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Recherche : {searchValue}',
        searchByServer: 'Serveur : {searchValue}',
        searchByVulnerability: 'Vulnérabilité : {searchValue}',
        showAll: 'Tout afficher',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# site sur les serveurs sélectionnés n\'est pas opérable actuellement} other {# sites sur les serveurs sélectionnés ne sont pas opérables actuellement}}. L\'opération ne sera pas exécutée sur ces sites, car ils sont soit cassés, soit non disponibles pour une autre raison.',
        state: {
            installingAgent: 'Installation de l\'agent',
            connectionError: 'Erreur de connexion',
            agentInstallationError: 'Installation de l\'agent non terminée',
            connectionBlocked: 'Connexion bloquée',
            synchronizationInProgress: 'Synchronisation en cours',
            connectionErrorStatusMessage: 'La connexion au serveur ne peut pas être établie correctement.',
            connectionErrorFixDescription: 'Veuillez vérifier les journaux et les paramètres sur votre serveur ({ipAddress}) et patienter pendant la nouvelle vérification.',
            connectionBlockedStatusMessage: 'La connexion au serveur est bloquée par le pare-feu.',
            connectionBlockedFixDescription: 'Veuillez vérifier si le port 878 est ouvert sur le serveur {ipAddress} et patienter pendant la nouvelle vérification.',
            agentInstallationErrorMessage: 'Installation de l\'agent non terminée',
            agentInstallationErrorFixDescription: "Aucune réponse de l'agent WP Guardian concernant une installation réussie ne nous est parvenue sous 5 minutes. Autrement dit, soit l'installation est encore en cours, soit elle a échoué. Vérifiez la progression de l'installation dans la console du serveur ou consultez le journal dans le fichier '/root/wp-agent-install.log'.",
        },
        AddServerDrawer: {
            title: 'Connecter votre serveur',
            done: 'Effectué',
            paragraph1: 'Ouvrez le port 878 pour les connexions entrantes depuis les IP suivantes sur chaque serveur que vous voulez connecter : 34.254.37.129, 52.51.23.204 et 52.213.169.7',
            paragraph2: 'Copiez et collez le snippet suivant dans la console des serveurs et patientez pendant que le processus d\'installation termine.',
            errors: {
                failedToGenerateToken: 'La génération de la commande d\'installation a échoué.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Copier',
            copied: 'Snippet copié',
            refreshTooltip: 'La commande de connexion est valide pour un nombre illimité de serveurs pendant ce temps. Actualisez la commande si vous avez besoin de plus de temps.',
        },
        SettingsDrawer: {
            title: 'Paramètres',
            subTitle: {
                single: 'Serveur : {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Mises à jour',
                dailyTaskTime: 'Planification',
            },
            loadFailed: 'Échec du chargement des paramètres.',
            buttonSave: 'Enregistrer',
            successToast: 'Les paramètres ont été mis à jour.',
            Updates: {
                customizedSection: {
                    description: 'Les paramètres de mise à jour seront appliqués à tous les sites actuels et à tous les nouveaux sites sur ce serveur, sans paramètres de mise à jour personnalisés.',
                    checkboxDescription: 'Appliquer les paramètres également à tous les sites avec des paramètres de mises à jour personnalisés',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian vérifie s\'il y a de nouvelles vulnérabilités et recherche les mises à jour disponibles toutes les 24 heures. Cette opération est gourmande en performances. Vous pouvez donc l\'exécuter à une heure spécifique par défaut sur tous les serveurs.',
                unsetOption: 'Définie par les paramètres globaux',
            },
        },
        RemoveServersButton: {
            remove: 'Déconnecter',
            noSelectedServers: 'Sélectionnez au moins un serveur à déconnecter.',
            removeDescription: 'L\'agent WP Guardian n\'est pas supprimé automatiquement lors de la déconnexion du serveur. Utilisez le script de désinstallation pour supprimer l\'agent WP Guardian (<a>instructions</a>).',
            removeSingleServerConfirmation: 'Déconnecter {selectedServerTitle} ?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {Voulez-vous déconnecter <b>#</b> serveur ?} other {Voulez-vous déconnecter les <b>#</b> serveurs ?}}',
        },
        ListActions: {
            remove: 'Déconnecter',
            refresh: 'Actualiser',
            findNewSites: 'Rechercher de nouveaux sites',
            settings: 'Paramètres',
            disablePatch: 'Unprotect',
            update: 'Mettre à jour tous les sites',
            manageLabels: 'Appliquer les étiquettes',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Annuler',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# site WP} other {# sites WP}}',
            notOperable: 'Non opérationnels : {count}',
            vulnerable: 'Vulnérables : {count}',
            vulnerabilities: 'Vulnérabilités : {count}',
            outdated: 'Obsolètes : {count}',
            lastCheck: 'Dernière vérification : {dateTime}',
            lastCheckToday: 'Dernière vérification : aujourd\'hui à {time}',
            unsupportedAgent: 'Version de l\'agent non prise en charge',
            coreOutdated: 'Obsolète',
        },
        columns: {
            server: 'Serveur',
        },
        OutdatedAgentError: {
            message: 'La version de l\'agent WP Guardian (wp-agent) sur le serveur est obsolète.',
            agentVersion: '{version, select, _ {Votre version de l\'agent WP Guardian est : N/A.} other {Votre version de l\'agent WP Guardian est : {version}.}}',
            updateDescription: 'Mettez à jour le package wp-agent vers la version {wpAgentMinSupportedVersion} ou une version ultérieure.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Paramètres de mise à jour',
            ipAddress: '<b>IP du serveur :</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Filtre',
            manage: 'Gérer',
            remove: 'Déconnecter',
            add: 'Connecter',
            groupFilter: 'Filtre',
            groupSearch: 'Rechercher',
            searchPlaceholder: 'Chercher un serveur…',
            noSelectedServers: 'Sélectionnez au moins un serveur à déconnecter.',
            FilterPanel: {
                filterAlerts: 'Alertes',
                labelsPlaceholder: 'Toutes les étiquettes',
            },
            RefreshButton: {
                buttonText: 'Actualiser',
                noSelectedServers: 'Sélectionnez au moins un serveur à actualiser.',
            },
            FindNewSitesButton: {
                buttonText: 'Rechercher de nouveaux sites',
                noSelectedServers: 'Sélectionnez au moins un serveur pour détecter de nouveaux sites WordPress.',
            },
            UpdateButton: {
                buttonText: 'Installer les mises à jour',
                confirmButtonText: 'Tout mettre à jour',
                noSelectedServers: 'Sélectionnez au moins un serveur pour installer toutes les mises à jour disponibles sur tous les sites WordPress.',
                updateSingleServerConfirmation: 'Toutes les mises à jour disponibles seront installées sur tous les sites WordPress situés sur le serveur <b>{selectedServerTitle}</b>.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {Tous les mises à jour disponibles seront installées sur tous les sites WordPress sur <b>#</b> serveur sélectionné.} other {Tous les mises à jour disponibles seront installées sur tous les sites WordPress sur <b>#</b> serveurs sélectionnés.}}',
            },
            ProtectButton: {
                buttonText: 'Protéger',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Appliquer les étiquettes',
            },
            SettingsButton: {
                buttonText: 'Paramètres',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Connecter',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Ignorer automatiquement les vulnérabilités à risque faible',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Ignorer automatiquement les vulnérabilités à risque faible',
            warningMessage: 'Si ce paramètre est désactivé, toutes les étiquettes "Ignorée automatiquement" seront supprimées et toutes les vulnérabilités ignorées automatiquement seront à nouveau entièrement visibles.',
        },
    },
    Installations: {
        yes: 'Oui',
        no: 'Non',
        update: 'Installer les mises à jour',
        refresh: 'Actualiser',
        detach: 'Dissocier',
        updateSettings: 'Paramètres de mise à jour',
        sitesAreNotOperable: '{notOperable, plural, one {# des sites sélectionnés n\'est pas opérable actuellement} other {# des sites sélectionnés ne sont pas opérables actuellement}}. L\'opération ne sera pas exécutée sur ces sites, car ils sont soit cassés, soit non disponibles pour une autre raison.',

        DetachInstallationsButton: {
            detach: 'Dissocier',
            detachSingleInstallationConfirmation: 'Voulez-vous dissocier l\'installation <b>{selectedInstallationTitle}</b> de WP Guardian ? Les installations dissociées seront ignorées lors des analyses ultérieures du serveur.',
            detachSeveralInstallationsConfirmation: 'Voulez-vous dissocier <b>{selectedInstallationsCount}</b> installations de WP Guardian ? Les installations dissociées seront ignorées lors des analyses ultérieures du serveur.',
        },
        Onboarding: {
            title: 'Aucun site WordPress trouvé',
            description: 'Connectez les serveurs pour chercher les sites WordPress et détecter leurs vulnérabilités ainsi que les composants obsolètes.',
        },
        columns: {
            website: 'Site',
            server: 'Serveur',
            synchronized: 'Connecté',
            lastCheck: 'Dernière vérification',
            protect: 'Protéger',
            siteHealth: 'Santé du site',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Menu des filtres',
                filterAll: 'Tout',
                filterVulnerable: 'Vulnérables',
                filterOutdated: 'Obsolètes',
                filterNotOperable: 'Non opérationnels',
                filterProtected: 'Protégés',
                filterUnmanaged: 'Non gérés',
                filterNotProtected: 'Non protégés',
                filterSafe: 'Aucun problème',
            },
            labelsPlaceholder: 'Toutes les étiquettes',
        },
        Toolbar: {
            groupFilterMenu: 'Filtre',
            groupSearch: 'Rechercher',
            searchPlaceholder: 'Chercher un site web...',
            groupActions: 'Actions',
            groupUpdate: 'Installer les mises à jour',
            groupProtect: 'Protéger',
            subGroupActions: 'Actions',
            buttons: {
                update: {
                    text: 'Installer les mises à jour',
                    noSelection: 'Sélectionnez au moins un site pour installer toutes les mises à jour disponibles.',
                },
                refresh: {
                    text: 'Actualiser',
                    noSelection: 'Sélectionnez au moins un site à actualiser.',
                },
                protect: {
                    text: 'Protéger',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Appliquer les étiquettes',
                },
                settings: {
                    text: 'Paramètres',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: 'Mettre à jour <b>{selectedInstallationTitle}</b> ?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {Vous êtes sur le point de mettre à jour <b>#</b> site WordPress} other {Vous êtes sur le point de mettre à jour <b>#</b> sites WordPress}}',
                confirmButton: 'Mettre à jour',
                cancelButton: 'Annuler',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Protéger',
                cancelButton: 'Annuler',
            },
        },
        SiteHealthColumn: {
            outdated: 'Obsolètes : {count}',
            safe: 'OK',
            notOperable: 'Non opérationnels',
            unsupportedAgent: 'Version de l\'agent non prise en charge',
            vulnerabilities: 'Vulnérabilités : {count}',
            lastCheckAtTooltip: 'Dernière vérification : {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'Les paramètres ont été mis à jour.',
            title: 'Paramètres de mise à jour',
            subTitle: {
                single: 'Site web : {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, one {# site non opérationnel sera ignoré} other {# sites non opérationnels seront ignorés}} et {unmanagedCount, plural, one {# site non géré sera ignoré} other {# sites non gérés seront ignorés}}.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {# site non géré sera ignoré} other {# sites non gérés seront ignorés} }.',
            },
            buttonSave: 'Enregistrer',
            confirmationPopover: {
                confirmBtn: 'Enregistrer',
                description: {
                    single: 'Vous allez modifier les paramètres de mise à jour du site WordPress {installationName}. Voulez-vous continuer ?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Mises à jour',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Activer la protection contre les vulnérabilités',
        },
        InstallationSummary: {
            updateAll: 'Tout mettre à jour',
            updateSettings: 'Paramètres de mise à jour',
            upToDate: 'À jour',
            AssetSummary: {
                plugin: {
                    title: 'Plug-ins [{count}]',
                    update: 'Installer les mises à jour des plug-ins',
                },
                theme: {
                    title: 'Thèmes [{count}]',
                    update: 'Installer les mises à jour des thèmes',
                },
                safe: 'OK',
                vulnerable: 'Vulnérables : {count}',
                outdated: 'Obsolètes : {count}',
                vulnerabilities: 'Vulnérabilités : {count}',
                isOutdated: 'Obsolètes',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Mettre à jour vers {version}',
                updateSettingsTitle: 'Paramètres de mise à jour',
                vulnerabilities: '{count, plural, one {# vulnérabilité} other {# vulnérabilités}}',
                isOutdated: 'Obsolète',
            },
            unsupportedAgentError: 'La version de wp-agent sur le serveur est obsolète. Version minimale requise : {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'Une erreur inconnue s\'est produite pendant l\'analyse du site lors de la recherche des vulnérabilités.',
            hostname: 'Nom d\'hôte du serveur:',
            ipAddress: 'IP du serveur :',
            UpdateConfirmation: {
                title: 'Confirmer la mise à jour',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Annuler',
                continueButton: 'Mettre à jour',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Protection contre les vulnérabilités par <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'La protection contre les vulnérabilités est désactivée.',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Enable protection',
            },
            ProtectInfoPopover: {
                label: 'Vulnérabilité toujours active ?',
                message: 'Pour neutraliser une vulnérabilité, il est nécessaire d\'activer des règles de protection spécifiques. Pour certaines raisons répertoriées ci-dessous, les règles de protection pourraient ne pas être disponibles pour une vulnérabilité spécifique :',
                noPatch: {
                    title: 'En cours',
                    description: 'Les règles pour des vulnérabilités à fort impact sont généralement disponibles quelques heures après la révélation du problème. Les règles pour les vulnérabilités à moindre impact nécessitent généralement plus de temps pour être créées.',
                },
                unlikelyExploited: {
                    title: 'Risque faible',
                    description: 'Certaines vulnérabilités ont un faible impact ou présentent peu de risques d\'être exploitées. Comme elles ne représentent pas de véritable menace pour les sites web, elles ne requièrent pas de règles de protection.',
                },
                dbMatch: {
                    title: 'Absente de la base de données',
                    description: 'Les vulnérabilités présentes uniquement dans la base de données Wordfence ou ne correspondant à aucune entrée de la base de données Patchstack ne bénéficient d\'aucune règle de protection.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'contactez-nous',
            },
        },
        WebsiteDrawer: {
            title: 'Détails des sites web WordPress',
            titleUpdateInProgress: 'Mise à jour de {title}',
            subtitleUpdateInProgress: 'Mise à jour des éléments sélectionnés',
            errors: {
                installationNotFound: "Impossible de charger les informations concernant l'installation avec l'ID {id}",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Annuler',
                        updateButton: 'Mettre à jour',
                        description: '{count, plural, one {Vous allez corriger # vulnérabilité à l\'aide de la mise à jour sur <b>{website}</b>.} other {Vous allez corriger # vulnérabilités à l\'aide de la mise à jour sur <b>{website}</b>.}} Ce processus de mise à jour ne peut pas être interrompu lorsqu\'il a commencé.',
                        updateConfirmationPluginCheckbox: '{count, plural, one {Réactiver le plug-in désactivé par la politique de sécurité} other {Réactiver les plug-ins désactivés par la politique de sécurité}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {Le plug-in vulnérable désactivé par votre politique de sécurité sera automatiquement réactivé si sa vulnérabilité est corrigée dans la mise à jour.} other {Les plug-ins vulnérables désactivés par votre politique de sécurité seront automatiquement réactivés si leurs vulnérabilités sont corrigées dans les mises à jour.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Actions',
                            buttons: {
                                update: 'Mettre à jour',
                                processing: 'En cours de traitement',
                            },
                        },
                        filters: {
                            title: 'Filtres',
                            groupFiltersTitle: 'Filtre',
                            search: {
                                title: 'Rechercher',
                                placeholder: 'Chercher une vulnérabilité…',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Protection requise',
                                filterStateIgnored: 'Ignorées',
                                filterComponentHeader: 'Emplacement',
                            },
                        },
                    },
                    title: 'Vulnérabilités ',
                    columns: {
                        component: 'Emplacement',
                    },
                    emptyList: {
                        title: 'Tout est sécurisé',
                        description: 'Aucune vulnérabilité connue détectée sur ce site.',
                    },
                    waitingForUpdate: 'Aucune mise à jour n\'est disponible',
                    updateTo: 'Mettre à jour vers {availableVersion}',
					update: 'Mettre à jour',
                    disablePlugin: 'Désactiver le plug-in',
                    enablePlugin: 'Activer le plug-in',
                    updateByProtect: 'Actuellement, aucune mise à jour ne corrige cette vulnérabilité.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {Vous allez mettre à jour # élément sur <b>{website}</b>.} other {Vous allez mettre à jour # éléments sur <b>{website}</b>.}} Ce processus de mise à jour ne peut pas être interrompu lorsqu\'il a commencé.',
                        cancelButton: 'Annuler',
                        updateButton: 'Mettre à jour',
                    },
                    title: 'Obsolète',
                    toolbar: {
                        actions: {
                            title: 'Actions',
                            buttons: {
                                update: 'Mettre à jour',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Filtres',
                            groupSearchTitle: 'Rechercher',
                            search: {
                                title: 'Rechercher',
                                placeholder: 'Chercher un composant obsolète…',
                            },
                        },
                    },
                    columns: {
                        name: 'Nom complet',
                        type: 'Emplacement',
                    },
                    emptyList: {
                        title: 'Tout est à jour',
                        description: 'Les versions les plus récentes de WordPress core, des plug-ins et des thèmes sont installées sur ce site.',
                    },
                    updateTo: 'Mettre à jour vers {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Protéger',
            disableProtectButton: 'Unprotect',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Appliquer les étiquettes',
        },
        DisableProtectionButton: {
            confirmButton: 'Unprotect',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Notifications',
        listEmptyTitle: 'Rester informé des événements les plus importants',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Tout marquer comme lu',
        deleteNotification: 'Supprimer la notification',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'Nouvelle licence active',
                licenseLimitReached: 'Limite de sites web atteinte',
                licenseExpired: 'Licence expirée',
                licenseTerminated: 'Licence résiliée',
                trialCreated: 'Version de test démarrée',
                trialExpired: 'Version de test terminée',
                trialExpiredSoon: 'La version de test expire le {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'Votre nouvelle clé de licence #{licenseKey} {licenseName} est désormais active.',
                licenseLimitReached: '{limit, plural, one {Avec votre licence WP Guardian vous pouvez gérer # site,} other {Avec votre licence WP Guardian vous pouvez gérer # sites,}} {total, plural, one {mais vous avez actuellement # site connecté.} other {mais vous avez actuellement # sites connectés.}} Mettez à niveau votre licence pour gérer tous vos sites.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Votre période de test de WP Guardian a démarré. Vous pouvez accéder intégralement à toutes les fonctionnalités de WP Guardian pour {sitesLimit, plural, one {# site web} other {# sites web}} pendant toute la durée de la période de test.',
                trialExpired: "Votre période de test de WP Guardian a expiré. Sélectionnez et achetez une licence pour continuer à utiliser le produit. Vous n'êtes pas convaincu ? Partagez votre avis via le formulaire de feedback ci-dessous.",
                trialExpiredSoon: 'Votre période de test de WP Guardian expire le {expirationDate}. Nous vous invitons à acheter une licence afin de continuer à utiliser le produit.',
            },
            markAsRead: 'Marquer comme lu',
            markAsUnread: 'Marquer comme non lu',
        },
        settings: 'Paramètres',
    },
    ConfirmNotificationsEmail: {
        title: 'Confirmation de notifications par e-mail',
        error: 'Lien expiré',
        successMessage: 'L\'adresse e-mail de notification a été modifiée.',
    },
    NotificationsSettingsDrawer: {
        title: 'Paramètres des notifications',
        Content: {
            sendSectionTitle: 'Adresse pour m\'envoyer une notification',
            email: 'Adresse mail',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Notifications à envoyer hors connexion au produit',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'Notifications relatives à la licence',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'Modifier l\'e-mail pour les notifications',
        buttonSave: 'Envoyer l\'e-mail',
        buttonCancel: 'Annuler',
        buttonEdit: 'Éditer',
        email: 'Adresse mail',
        description: 'Nous vous enverrons un e-mail avec des instructions afin de mettre à jour l\'adresse e-mail utilisée pour recevoir des notifications.',
        successMessage: 'Un e-mail a été envoyé à <b>{email}</b>. Suivez les instructions de cet e-mail pour confirmer votre nouvelle adresse e-mail.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Détection des vulnérabilités',
            description: 'Mises à jour en temps réel concernant les nouvelles vulnérabilités WordPress sur les sites connectés',
        },
        secureSites: {
            title: 'Préserver la sécurité des sites WordPress',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Mises à jour automatiques',
            description: 'Paramètres de mises à jour automatiques flexibles disponibles pour les serveurs et les sites web',
        },
        safeUpdates: {
            title: 'Mises à jour fiables',
            description: 'Les sauvegardes effectuées avant les mises à jour permettent un retour en arrière automatique si la mise à jour rencontre des problèmes.',
        },
        notifications: {
            title: 'Statut et notifications relatives à la santé',
            description: 'Restez toujours informé de l\'état de vos sites WordPress',
        },
    },
    LicensePage: {
        benefits: {
            title: 'S\'assurer de la sécurité des sites WordPress',
        },
        ExpirationLabel: {
            noLicense: "Vous n'avez pas de licence.",
            expiredLicense: 'Votre licence a expiré.',
        },
        LicenseHeading: {
            welcome: 'Bienvenue dans WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Choisissez votre licence ',
            trial: 'Choisissez votre licence ',
            trialLicenseExpired: 'Votre période de test a expiré.',
        },
        LicenseDescription: {
            licenseKey: 'Clé de licence #{number}',
            nextUpdate: 'Prochaine mise à jour de la licence : {date}',
            expirationDate: 'Date d\'expiration : {date}',
            noLicense: 'La version gratuite de WP Guardian inclut uniquement les informations relatives aux vulnérabilités et les mises à jour manuelles de sites. Achetez une licence pour accéder à toutes les fonctionnalités de WP Guardian.',
            trialJustStarted: 'Pendant la période de test, vous disposez d\'un accès à toutes les fonctionnalités de WP Guardian. À la fin de cette période, nous vous recommanderons une licence adaptée à vos besoins. Vous pouvez également continuer à utiliser WP Guardian gratuitement, mais avec certaines limitations.',
            trial: '{sites, plural, one {Votre version de test est limitée dans le temps et vous permet de gérer # site WordPress.} other {Votre version de test est limitée dans le temps et vous permet de gérer jusqu\'à # sites WordPress.}} Achetez une licence afin de continuer à bénéficier de toutes les fonctionnalités WP Guardian actuelles et à venir.',
            limit: '{sites, plural, one {Avec votre licence, vous pouvez gérer # site WordPress.} other {Avec votre licence, vous pouvez gérer jusqu\'à # sites WordPress.}}',
            unlimited: 'Avec votre licence, vous pouvez gérer un nombre illimité de sites WordPress. {sites, plural, one {Le tarif de la licence de base inclut la gestion d\'/# site.} other {Le tarif de la licence de base inclut la gestion de # sites.}} Au-delà de cette limite, les sites sont facturés selon le principe du paiement à l\'utilisation (Pay-As-You-Go)',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'Voir les détails',
            nextBillingDate: 'Prochaine date de facturation : {date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Utilisation actuelle :',
            sitesTotal: '{sites, plural, one {# site web en tout} other {# sites web en tout}}',
            allowedToManage: '{sites, plural, one {Gestion de # site web autorisée} other {Gestion de # sites web autorisée}}',
            outOfLimit: '{sites, plural, one {# site web au-delà de la limite de la licence} other {# sites web au-delà de la limite de la licence}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: 'À partir de 1 000 sites',
                feature1: '10 000 sites inclus',
                feature2: 'Paiement à l\'utilisation pour les sites au-delà du 10 000e',
                feature3: 'Toutes les fonctionnalités incluses',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: 'Jusqu\'à 1 000 sites',
                feature1: 'Paiement avant utilisation',
                feature2: 'Toutes les fonctionnalités incluses',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Utiliser ce pack',
            changePlan: 'Modifier le pack',
            chooseAnotherOption: 'Choisissez une autre option pour mettre à niveau votre pack',
        },
        LicenseList: {
            columns: {
                license: 'Licence',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Acheter',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Réactiver',
            details: 'Details',
        },
        chooseLicenseNow: 'Choisissez votre licence ',
        licensesTitle: 'Sélectionnez la licence qui correspond à vos besoins',
        faq: {
            title: 'Questions fréquentes',
            question1: {
                question: 'Que se passe-t-il une fois que la période de test a expiré ?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'Que se passe-t-il si j\'ai atteint les limites de ma licence ?',
                answer: 'Les sites ajoutés à WP Guardian une fois que la limite de la licence a été dépassée seront marqués comme non gérés. Ce type de site continue de disposer des informations à jour concernant les vulnérabilités et peut uniquement être mis à jour manuellement. Aucune autre fonctionnalité WP Guardian ne sera disponible pour ces sites.',
            },
            question4: {
                question: 'Comment fonctionne le mode Pay As You Go ?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'Vous pouvez obtenir votre licence dans <kaLink>Key Administrator</kaLink>, <manage2Link>Manage2</manage2Link> ou auprès de votre représentant commercial.',
            standard: {
                title: '{sites, plural, one {Vous voulez choisir une licence standard pour # site} other {Vous voulez choisir une licence standard pour # sites}}.',
                licenseLimit: '{sites, plural, one {La licence inclut # site uniquement} other {La licence inclut # sites uniquement}}.',
                feature1: 'Une licence standard inclut un nombre spécifique de sites web qui n\'entrent pas dans la comptabilisation à l\'utilisation (Pay-As-You-Go).',
                feature2: 'Tous les sites web ajoutés au-delà de la limite de la licence seront marqués comme Non gérés.',
                feature3: 'Les sites web non gérés ne peuvent pas être gérés par WP Guardian sans mise à niveau de votre licence.',
                feature4: 'Les sites web non gérés actuellement seront gérés dès lors que la limite de la licence sera relevée ou qu\'un autre site web ne sera plus géré et libèrera une place.',
                feature5: 'Si un site web a été connecté à WP Guardian il y a plusieurs jours, il sera comptabilisé dans le cadre de l\'utilisation de votre licence.',
                feature6: 'Les sites web hors service ou non disponibles pour d\'autres raisons ne sont pas comptabilisés dans le cadre de l\'utilisation de votre licence.',
            },
            unlimited: {
                title: 'Vous avez opté pour une licence illimitée.',
                feature1: 'Les licences illimitées incluent jusqu\'à 10 000 sites web dans leur tarif de référence.',
                feature2: 'Tous les sites web ajoutés au-delà des 10 000 sites inclus seront facturés à l\'utilisation (Pay-As-You-Go).',
                feature3: 'Si un site web a été connecté à WP Guardian il y a plusieurs jours, il sera comptabilisé dans le cadre de l\'utilisation de votre licence.',
                feature4: 'Les sites web hors service ou non disponibles pour d\'autres raisons ne sont pas comptabilisés dans le cadre de l\'utilisation de votre licence.',
            },
        },
        UpgradeButton: {
            upgrade: 'Mettre à niveau',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'Toutes les fonctionnalités incluses',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Acheter une licence',
            upgradeLicense: 'Mettre à niveau la licence',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Revenir à l\'écran d\'accueil',
    },
    LabelsDrawer: {
        title: 'Gérer les étiquettes',
        LabelsList: {
            create: 'Créer une étiquette',
            edit: 'Éditer',
            delete: 'Supprimer',
            noSelectedLabels: 'Sélectionnez au moins une étiquette à supprimer.',
            columns: {
                title: 'Étiquette',
            },
            search: {
                title: 'Rechercher',
                placeholder: 'Chercher une étiquette',
            },
            emptyList: {
                title: 'Créer une étiquette',
                description: "Vous n'avez pas encore d'étiquette pour différencier vos serveurs et vos sites. Voulez-vous en créer ?",
            },
            filteredEmptyList: {
                title: 'Aucun élément ne correspond aux critères du filtre',
                description: 'Modifiez le texte de l\'étiquette que vous cherchez et essayez à nouveau.',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Supprimer',
            confirmation: '{count, select, 1 {Lorsqu\'une étiquette est supprimée, celle-ci est également supprimée de tous les objets auxquels elle s\'appliquait.} other {Lorsque des étiquettes sont supprimées, celles-ci sont également supprimées de tous les objets auxquels elles s\'appliquaient.}} Continuer ?',
        },
        CreateLabelDrawer: {
            title: 'Créer une étiquette',
        },
        EditLabelDrawer: {
            title: 'Modifier une étiquette',
            notFound: 'Étiquette non trouvée',
        },
        LabelForm: {
            save: 'Enregistrer',
            cancel: 'Annuler',
            fields: {
                title: {
                    label: 'Nom de l\'étiquette',
                    description: '30 caractères maximum',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'TYPE D\'ÉTIQUETTE',
        noSelection: 'Sélectionnez au moins un élément dans la liste pour ajouter ou supprimer des étiquettes.',
        buttons: {
            apply: 'Appliquer',
            create: 'Créer nouveau',
            manage: 'Gérer les étiquettes',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'tout',
            tooltip: 'Les mises à jour sécurisées sont activées.',
            components: {
                core: 'core',
                themes: 'thèmes',
                plugins: 'plug-ins',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'mises à jour de sécurité',
                major: 'toutes les mises à jour',
                disabled: "ne pas installer",
                notSet: 'au niveau du site',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {Votre licence inclut # site géré} other {Votre licence inclut # sites gérés}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {# site non géré sera ignoré} other {# sites non gérés seront ignorés}}',
        operableNotManagedSitesMessage: 'Les sites non gérés sont ignorés par WP Guardian, car ils ne sont pas inclus dans le cadre de votre licence. Ce type de site est ignoré lors des actions en masse. ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, one {# site non opérationnel sera ignoré} other {# sites non opérationnels seront ignorés}} et {unmanagedCount, plural, one {# site non géré sera ignoré} other {# sites non gérés seront ignorés}}',
        notOperableNotManagedSitesMessage: 'L\'opération que vous avez sélectionnée ne sera pas exécutée pour ce type de site, car ils sont soit non opérationnels, soit au-delà de la limite de votre licence. Ce type de site est ignoré lors des actions en masse.',
        managedSitesWillBePerformed: 'L\'opération que vous avez sélectionnée sera appliquée sans restrictions pour tous les sites gérés.',
        chooseLicenseButton: 'Choisir une licence',
        LicenseTerminatedDialog: {
            title: 'Votre licence a expiré',
            message: 'Veuillez acquérir une licence pour continuer à utiliser les fonctionnalités avancées de WP Guardian.',
            chooseLicenseButton: 'Choisir une licence',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Mises à jour définies au niveau du site',
            autoupdate: 'Mises à jour automatiques',
            manual: 'Mettre à jour manuellement',
            custom: 'Mises à jour personnalisées',
        },
        unmanaged: {
            title: 'Non gérés',
            tooltip: 'Les sites non gérés sont ignorés par WP Guardian, car ils ne sont pas inclus dans le cadre de votre licence. Les sites identifiés comme "non gérés", car ils ne sont pas inclus dans le cadre de la licence, bénéficieront encore des informations actuelles concernant leurs vulnérabilités, toutefois, ils pourront uniquement être mis à jour manuellement. Ce type de site est identifié comme "géré" dès lors que la licence est augmentée ou qu\'une place se libère dans la licence actuelle.',
        },
        menu: {
            remove: 'Supprimer',
            filter: 'Filtrer avec cette étiquette',
        },
    },
    Cuttable: {
        showMore: 'Afficher plus',
        showLess: 'Afficher moins',
    },
};