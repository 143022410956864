// Copyright 2024. WebPros International GmbH. All rights reserved.

import React, { ReactNode } from 'react';
import { ItemList, Item, Text, FormField, Icon } from '@plesk/ui-library';
import { LightsailRegion } from '@platform360/web-installer/shared/api-contract';
import australia from './flags/flag-australia.png';
import canada from './flags/flag-canada.png';
import france from './flags/flag-france.png';
import germany from './flags/flag-germany.png';
import india from './flags/flag-india.png';
import ireland from './flags/flag-ireland.png';
import japan from './flags/flag-japan.png';
import singapore from './flags/flag-singapore.png';
import southKorea from './flags/flag-south-korea.png';
import uk from './flags/flag-uk.png';
import usa from './flags/flag-usa.png';

const region2flag: Record<string, string> = {
    'us-east-1': usa,
    'us-east-2': usa,
    'us-west-2': usa,
    'eu-west-1': ireland,
    'eu-west-2': uk,
    'eu-west-3': france,
    'eu-central-1': germany,
    'ap-southeast-1': singapore,
    'ap-southeast-2': australia,
    'ap-northeast-1': japan,
    'ap-northeast-2': southKorea,
    'ap-south-1': india,
    'ca-central-1': canada,
};

type FormFieldApi = {
    getValue: () => string;
    setValue: (value: string) => void;
};

export type RegionSelectProps = {
    name: string;
    label: ReactNode;
    required: boolean;
    regions: LightsailRegion[];
};

const RegionSelect = ({ name, label, required, regions }: RegionSelectProps) => (
    <FormField name={name} label={label} required={required}>
        {({ getValue, setValue }: FormFieldApi) => (
            <ItemList
                minColumnWidth={220}
                gap="md"
                selectable
                value={getValue()}
                // @ts-expect-error
                onSelect={setValue}
            >
                {regions.map((region) => (
                    <Item
                        key={region.Name}
                        value={region.Name}
                        title={<Text bold>{region.DisplayName}</Text>}
                        icon={<Icon src={region2flag[region.Name]} size="32" />}
                    >
                        {region.Name}
                    </Item>
                ))}
            </ItemList>
        )}
    </FormField>
);

export default RegionSelect;
