// Copyright 2024. WebPros International GmbH. All rights reserved.

import { cloneElement, useState } from 'react';
import { Button, Popover, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type SyncServersOperationProps = {
    onOperation: () => void;
    selectionCount: number;
};
const SyncServersOperation = ({ onOperation, selectionCount }: SyncServersOperationProps) => {
    const translate = useTranslate('Servers');
    const [visible, setVisible] = useState(false);
    const operationButton = (
        <Button icon="refresh" onClick={onOperation} data-type="sync-servers-button">
            {translate('sync')}
        </Button>
    );

    if (selectionCount > 0) {
        return operationButton;
    }

    return (
        <Popover
            target={cloneElement(operationButton, {
                onClick: () => setVisible(true),
            })}
            placement="top"
            visible={visible}
            onClose={() => setVisible(false)}
        >
            <Paragraph>{translate('syncNoSelection')}</Paragraph>
            <div>
                <Button
                    onClick={() => {
                        setVisible(false);
                        onOperation();
                    }}
                    intent="primary"
                >
                    {translate('syncAll')}
                </Button>{' '}
                <Button ghost onClick={() => setVisible(false)}>
                    {translate('cancel')}
                </Button>
            </div>
        </Popover>
    );
};

export default SyncServersOperation;
