// Copyright 2024. WebPros International GmbH. All rights reserved.

import GeneralInfo from './GeneralInfo';
import { useParams } from 'react-router-dom';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';
import { ClosableContext } from '@platform360/server-inventory/web/helpers/ClosableContext';
import { useContext } from 'react';

const GeneralInfoContainer = () => {
    const { serverId } = useParams<{ serverId: string }>();
    const { data } = useServerQuery({
        // Parent ViewServer component checks serverId is valid
        variables: { id: Number(serverId) },
    });
    const onClose = useContext(ClosableContext);

    if (!data) {
        return null;
    }

    return <GeneralInfo server={data} onRemoveServerSuccess={onClose} />;
};

export default GeneralInfoContainer;
