// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: '360 ツール',
    },
    WidgetsList: {
        serverInventory: {
            title: 'サーバインベントリ',
            description: '単一の場所から、単一のログイン情報で登録されたすべてのインスタンス',
        },
        licenseManagement: {
            title: 'ライセンス管理',
            description: 'Plesk、拡張、360 ツールのライセンスをすべて購入・管理します',
        },
        feedback: {
            title: 'フィードバックする',
            description: '今後の開発の参考にさせていただきます',
        },
        websiteInventory: {
            title: 'ウェブサイトインベントリ',
            description: 'すべてのウェブサイトを表示・管理します',
        },
    },
    Carousel: {
        monitoring: {
            title: '単一のダッシュボードからサーバとウェブサイトをモニタリング',
            description: 'パフォーマンスをトラッキングし、ダウンタイムを防ぐための一元化されたソリューション。構成可能なダッシュボード、パーソナライズされたアラート、1 分間のチェック、ネットワーク履歴などの機能を提供します。',
            primaryButton: '14 日間無料で試す',
            secondaryButton: 'さらに詳しく',
        },
        licenses: {
            title: 'ライセンスを管理',
            description: 'Plesk、拡張、360 ツールのすべてのライセンスの購入、調整、解約を一箇所で行えます',
            primaryButton: '始めましょう',
            secondaryButton: 'さらに詳しく',
        },
        serverInventory: {
            title: '単一の場所から、単一のログイン情報で登録されたすべてのインスタンス',
            description: 'すべての Plesk サーバをサーバインベントリに接続して、すべてのウェブサイトのリストを表示し、ワンクリックでアクセスできるようにします',
            primaryButton: 'サーバを接続',
            secondaryButton: 'さらに詳しく',
        },
        websiteInventory: {
            title: 'すべてのウェブサイトとドメインが 1 つのリストに',
            description: 'ウェブサイトを表示、検索、フィルタリングし、ワンクリックで管理できるようにします',
            primaryButton: 'ウェブサイトを表示',
            secondaryButton: 'さらに詳しく',
        },
    },
};