// Copyright 2024. WebPros International GmbH. All rights reserved.

import { load } from '@platform360/libs/shared-web/helpers/localStorage';
import { SESSION_KEY } from '@platform360/libs/shared-web/auth/usePersistReducer';

const findAuthUserIdInLocalStorage = (): string | undefined => {
    const session = load(SESSION_KEY);

    return session?.session?.userId ?? undefined;
};

export default findAuthUserIdInLocalStorage;
