// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Spot, Tour } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useLocalStorage } from 'usehooks-ts';

const EditPaymentMethodTour = () => {
    const translate = useTranslate('Keys.Tours.moreDetails.editPaymentMethod');
    const selector = '[data-type=Keys--keyMoreDetails]:first-of-type';
    const innerKey = 'license-more-details-editPaymentMethod';
    // 1 day * 60 days, ~2 month
    const delay = 864e5 * 60;

    const [localStorageForTour, setLocalStorageForTour] = useLocalStorage(`${innerKey}-tour`, {
        ttl: 0,
    });

    const hideTour = () => {
        setLocalStorageForTour({
            ttl: Date.now() + delay,
        });
    };

    if (localStorageForTour.ttl > Date.now()) {
        return null;
    }

    const popup = {
        children: translate('text'),
        buttons: [
            <Button key={`${innerKey}-confirm`} intent="primary" onClick={hideTour}>
                {translate('confirm')}
            </Button>,
        ],
        'data-type': `${innerKey}-popup`,
    };

    return (
        <Tour current={0} onClose={hideTour}>
            {[<Spot key={`${innerKey}-spot`} selector={selector} popup={popup} />]}
        </Tour>
    );
};

export default EditPaymentMethodTour;
