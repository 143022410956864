// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, Heading, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Banner from './assets/license-terminated.png';
import { CustomerType, CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';

export type LicenseTerminatedDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onLicenseChoose: () => void;
    customerType: CustomerType;
};

export const LicenseTerminatedDialog = ({
    isOpen,
    onClose,
    onLicenseChoose,
    customerType,
}: LicenseTerminatedDialogProps) => {
    const translate = useTranslate(
        'security-dashboard.UnprocessableInstallationsDialog.LicenseTerminatedDialog',
    );

    return (
        <Dialog
            isOpen={isOpen}
            cancelButton={false}
            onClose={onClose}
            banner={<img src={Banner} alt="" />}
            title={<span />}
            form={
                customerType === CustomerTypes.vps
                    ? undefined
                    : {
                          onSubmit: onLicenseChoose,
                          applyButton: false,
                          submitButton: { children: translate('chooseLicenseButton') },
                      }
            }
        >
            <Heading level={2}>{translate('title')}</Heading>
            <Paragraph>{translate('message')}</Paragraph>
            {customerType === CustomerTypes.vps && (
                <Paragraph>{translate('contactResellerToUpgrade')}</Paragraph>
            )}
        </Dialog>
    );
};
