// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Alert, FormFieldCheckbox } from '@plesk/ui-library';
import { CertErrorCode, CertErrors } from '@platform360/libs/common/cert-error-codes';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link } from './Link';
import { StatusDanger, StatusWarning } from './status';
import InvalidCertificateKBArticleLink, {
    InvalidCertificateKBArticleHref,
} from '@platform360/libs/shared-web/components/InvalidCertificateKBArticleLink';

export type ServerCertificateStatusProps = {
    errorCode: CertErrorCode;
};

export const ServerCertificateError = ({ errorCode }: ServerCertificateStatusProps) => {
    const translate = useTranslate('Servers.AddServerControlStatus.ServerCertificateError');

    switch (errorCode) {
        case CertErrors.CertSelfSigned: {
            return (
                <>
                    <StatusWarning>{translate('certSelfSigned.title')}</StatusWarning>
                    <Alert intent="warning">
                        {translate('certSelfSigned.description')}
                        <br />
                        <InvalidCertificateKBArticleLink />
                        <br />
                        <FormFieldCheckbox
                            name="allowUnsecureConnection"
                            label={translate('agreeInvalidCertCheckbox')}
                        />
                    </Alert>
                </>
            );
        }
        case CertErrors.CertSelfSignedInChain: {
            return (
                <>
                    <StatusWarning>{translate('certUntrustedCa.title')}</StatusWarning>
                    <Alert intent="warning">
                        {translate('certUntrustedCa.description')}
                        <br />
                        <InvalidCertificateKBArticleLink />
                        <br />
                        <FormFieldCheckbox
                            name="allowUnsecureConnection"
                            label={translate('agreeInvalidCertCheckbox')}
                        />
                    </Alert>
                </>
            );
        }
        case CertErrors.CertExpired: {
            return (
                <>
                    <StatusDanger>{translate('certExpired.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('certExpired.description')}
                        <br />
                        <InvalidCertificateKBArticleLink />
                        <br />
                        <FormFieldCheckbox
                            name="allowUnsecureConnection"
                            label={translate('agreeInvalidCertCheckbox')}
                        />
                    </Alert>
                </>
            );
        }
        case CertErrors.CertAltNameInvalid: {
            return (
                <>
                    <StatusDanger>{translate('certInvalid.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('certInvalid.description', {
                            a: (parts) => (
                                <Link href={InvalidCertificateKBArticleHref}>{parts}</Link>
                            ),
                        })}
                        <br />
                        <FormFieldCheckbox
                            name="allowUnsecureConnection"
                            label={translate('agreeInvalidCertCheckbox')}
                        />
                    </Alert>
                </>
            );
        }
        default:
            return null;
    }
};
