// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FormFieldCheckbox, Text } from '@plesk/ui-library';
import FiltersForm, {
    FiltersFormProps,
} from '@platform360/security-dashboard/web/components/FiltersForm';
import useInstallationComponentTranslate from '@platform360/security-dashboard/web/hooks/useInstallationComponentTranslate';
import {
    InstallationComponent,
    installationComponentValues,
} from '@platform360/security-dashboard/shared/installation-component';

const COMPONENTS = installationComponentValues;

type FormValues = Record<InstallationComponent, boolean>;

type DataWithComponentType = {
    component: InstallationComponent;
};

type FiltersProps<T extends DataWithComponentType> = {
    filter: InstallationComponent[];
    onFilterApply: (components: InstallationComponent[]) => void;
    filteredData: T[];
} & FiltersFormProps<FormValues>;

export const Filters = <T extends DataWithComponentType>({
    filter,
    onFilterApply,
    filteredData,
    ...filtersFormProps
}: FiltersProps<T>) => {
    const translateComponent = useInstallationComponentTranslate();

    const componentsCounts = filteredData.reduce<{ [key in InstallationComponent]?: number }>(
        (counts, item) => ({ ...counts, [item.component]: (counts[item.component] || 0) + 1 }),
        {},
    );

    const values = COMPONENTS.reduce(
        (values, component) => ({ ...values, [component]: filter.includes(component) }),
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
        {} as FormValues,
    );

    const handleSubmit = (values: FormValues) => {
        const result = COMPONENTS.reduce<InstallationComponent[]>((result, component) => {
            if (values[component]) result.push(component);
            return result;
        }, []);

        onFilterApply(result);
    };

    return (
        <FiltersForm<FormValues> values={values} onSubmit={handleSubmit} {...filtersFormProps}>
            {COMPONENTS.map((component) => (
                <FormFieldCheckbox
                    key={component}
                    name={component}
                    label={
                        <>
                            {translateComponent(component)}{' '}
                            <Text intent="muted">{componentsCounts[component] || 0}</Text>
                        </>
                    }
                />
            ))}
        </FiltersForm>
    );
};

export default Filters;
