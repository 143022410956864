// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { cpanelInstall } from '@platform360/web-installer/web/api/web-installer';

export type UseCpanelInstallOptions = {
    onSuccess: (id: string) => void;
    onError?: (err: unknown) => void;
};

const useCpanelInstall = ({ onSuccess, onError }: UseCpanelInstallOptions) =>
    useMutation({
        mutationFn: cpanelInstall,
        onSuccess: ({ id }) => onSuccess(id),
        onError,
    });

export default useCpanelInstall;
