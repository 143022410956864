// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps } from '@plesk/ui-library';
import toaster, { ToastOptions } from './toaster';

const useToaster = () => ({
    successToast: (message: TranslationProps, options?: ToastOptions) =>
        toaster.success(message, options),
    warningToast: (message: TranslationProps, options?: ToastOptions) =>
        toaster.warning(message, options),
    errorToast: (message: TranslationProps, options?: ToastOptions) =>
        toaster.error(message, options),
    clearToasts: () => toaster.clear(),
});

export default useToaster;
