// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { ContentLoader } from '@plesk/ui-library';
import { startPleskTrial } from '@platform360/server-inventory/web/api/servers';

export const StartPleskTrialContainer = () => {
    const { login } = useAuth();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');

    useEffectOnce(() => {
        if (!email) {
            return;
        }

        const startTrial = async () => {
            try {
                await startPleskTrial(email);
            } finally {
                void login({
                    email,
                    passwordless: true,
                    redirectUrl: '/si/servers/add/server-installation',
                });
            }
        };

        void startTrial();
    });

    if (!email) {
        return <Navigate to={'/auth/login'} />;
    }

    return <ContentLoader />;
};
