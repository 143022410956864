// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getNotificationsSettings } from '@platform360/security-dashboard/web/api/notifications-settings';

export type UseNotificationsSettingsQueryVariables = undefined;
export type UseNotificationsSettingsQueryData = PromiseValue<
    ReturnType<typeof getNotificationsSettings>
>;

const useNotificationsSettingsQuery = createQuery<
    UseNotificationsSettingsQueryVariables,
    UseNotificationsSettingsQueryData
>({
    queryName: 'security-dashboard/useNotificationsSettingsQuery',
    fetcher: getNotificationsSettings,
    useQuery,
});

export default useNotificationsSettingsQuery;
