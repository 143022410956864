// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import {
    Form,
    FormField,
    FormFieldText,
    FormFieldSelect,
    Tabs,
    Tab,
    CodeEditor,
    setIn,
    Icon,
} from '@plesk/ui-library';
import {
    WpAgentRequestMethod,
    WpAgentRequestMethods,
    wpAgentRequestMethodValues,
} from '@platform360/admin-panel/shared/constants';
import { useRequestWpAgentMutation } from '@platform360/admin-panel/web/mutations/security-dashboard';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { NameValuePair } from './NameValuePair';
import styles from './RequestForm.module.css';

export type FormValues = {
    method: WpAgentRequestMethod;
    url: string;
    body: string;
    headers: { name: string; value: string }[];
};

const initialValues: FormValues = {
    method: WpAgentRequestMethods.get,
    url: '',
    body: '',
    headers: [],
};

type RequestFormProps = {
    serverId?: number;
    onSent: (request: { requestId: string; url: string; method: WpAgentRequestMethod }) => void;
};

export const RequestForm = ({ serverId, onSent }: RequestFormProps) => {
    const translate = useTranslate('admin-panel.security-dashboard.Requests.RequestForm');

    const { mutate, errors, isPending: isRequesting } = useRequestWpAgentMutation();

    const [values, setValues] = useState(initialValues);
    const handleFieldChange = (key: keyof FormValues, value: FormValues[keyof FormValues]) =>
        setValues(setIn(values, key, value));

    const handleSubmit = (values: FormValues) => {
        if (serverId === undefined) return;

        mutate(
            {
                data: {
                    serverId,
                    ...values,
                    headers: values.headers
                        .filter(Boolean)
                        .filter(({ name, value }) => name.trim() || value.trim()),
                    body: values.body.replace(/(\r\n|\n|\r|\t)/g, '').trim() || undefined,
                },
            },
            {
                onSuccess: ({ requestId }) => {
                    onSent({
                        requestId,
                        url: values.url,
                        method: values.method,
                    });
                },
            },
        );
    };

    const errorIcon = (
        <Icon
            name="exclamation-mark-circle-filled"
            intent="danger"
            className={styles.errorIcon}
            size="12"
        />
    );

    return (
        <Form<FormValues>
            onSubmit={handleSubmit}
            values={values}
            onFieldChange={handleFieldChange}
            vertical
            errors={errors}
            submitButton={{ children: translate('send'), size: 'md' }}
            cancelButton={false}
            applyButton={false}
            state={isRequesting ? 'submit' : undefined}
        >
            <div className={styles.methodUrlContainer}>
                <FormFieldSelect size="sm" name="method">
                    {wpAgentRequestMethodValues.map((method) => (
                        <option key={method} value={method}>
                            {method}
                        </option>
                    ))}
                </FormFieldSelect>
                <FormFieldText
                    name="url"
                    size="fill"
                    placeholder={translate('url')}
                    className={styles.url}
                />
            </div>
            <Tabs className={styles.payload}>
                <Tab title={translate('body')} label={errors?.body && errorIcon}>
                    <FormField name="body">
                        {({ getValue, setValue }) => (
                            <div className={styles.codeEditor}>
                                <CodeEditor onChange={setValue}>{getValue()}</CodeEditor>
                            </div>
                        )}
                    </FormField>
                </Tab>
                <Tab title={translate('headers')} label={errors?.headers && errorIcon}>
                    <FormField name="headers" multi>
                        {({ getValue, setValue }) => (
                            <NameValuePair value={getValue()} onChange={setValue} />
                        )}
                    </FormField>
                </Tab>
            </Tabs>
        </Form>
    );
};
