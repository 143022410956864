// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'Нет открытых оповещений',
        openAlerts: 'Открытых оповещений: {alerts}',
        loadingError: 'Служба недоступна',
        connectServer: 'Подключить сервер',
        notSupported: 'Не поддерживается',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'Посмотреть в 360 Monitoring',
        connectServerTitle: 'Этот сервер не подключен к 360 Monitoring',
        connectServerDescription: 'Когда сервер будет подключен, на этой странице будет отображаться краткий обзор состояния сервера и текущих открытых оповещений.',
        connectServerButton: 'Подключить сервер',
        pausedServerTitle: 'Этот сервер не отслеживается',
        pausedServerDescription: 'Использовать пользовательский интерфейс 360 Monitoring для определения причины.',
        preparingServerTitle: 'Идет подготовка',
        preparingServerDescription1: 'Ваш сервер Plesk подключен к 360 Monitoring.',
        preparingServerDescription2: 'Идет подготовка приборной панели. Попробуйте обновить страницу или вернуться через 10-30 минут.',
        refreshButton: 'Обновить данные',
        loadingError: 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
        loadAverageTitle: 'Средняя нагрузка',
        alertOpened: 'Открыто {timeAgo}.',
        viewAlertsInMonitoring: 'Посмотреть оповещения в 360 Monitoring',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Мониторинг',
            description: 'Информация о ваших серверах и сайтах в одной панели',
        },
        DynamicWidget: {
            title: 'Мониторинг',
            WidgetContent: {
                servers: 'Серверы',
                websites: 'Сайты',
                lastUpdate: 'Последнее обновление: {lastUpdate}',
                loading: 'Загрузка...',
                Loader: {
                    loading: 'Загрузка...',
                    notRespondingText1: '360 Monitoring не отвечает.',
                    notRespondingText2: 'Попытка повторного подключения.',
                },
                InfoAggregateColumn: {
                    allActive: 'Все активны',
                    noAlerts: 'Оповещений нет',
                    alerts: 'Открытых оповещений: {alerts}',
                    down: '{down, plural, one{# не отвечает} few {# не отвечают} many {# не отвечают} other{# не отвечают}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: 'Активировать 360 Monitoring',
        description: '360 Monitoring Lite теперь и всегда бесплатен. Активировав свою учетную запись сегодня, вы также получите бесплатную 14-дневную пробную версию Pro, включающую все премиальные функции. Вы будете автоматически переключены обратно на бесплатную версию Lite через две недели, если не решите оставить повышенную версию.',
        beginTrialButton: 'Активировать 360 Monitoring *',
        footnote: '* Для активации 360 Monitoring Lite или пробной версии Pro данные кредитной карты не требуются.',
        backButton: 'Вернуться в Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Отмена',
            confirmationText: 'Пожалуйста, попробуйте настроить свою учетную запись 360 Monitoring, открыв пункт меню Monitoring, или отправьте запрос в службу поддержки.',
            acceptButtonText: 'OK',
            declineButtonText: 'Отмена',
        },
        retryButtonText: 'Повторить попытку',
        inProgressText: 'Ваша учетная запись 360 Monitoring настраивается',
        successText: 'Перенаправляем вас на 360 Monitoring',
        failedText: 'Во время активации учетной записи Monitoring что-то пошло не так. Пожалуйста, попробуйте еще раз или отправьте запрос в службу поддержки.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Подтвердите активацию 360 Monitoring',
            description: 'Агент "360 Monitoring" будет установлен в Plesk по адресу <b>{address}</b> и подключен к учетной записи <b>{email}</b> в Plesk 360.',
        },
        monitoringInactive: {
            title: 'Активируйте 360 Monitoring и добавьте сервер',
            description: 'Начните использовать 360 Monitoring в течение <tooltip>пробного периода</tooltip> и подтвердите установку агента "360 Monitoring" в Plesk по адресу <b>{address}</b>, подключенном к учетной записи <b>{email}</b> в Plesk 360.',
            trialTooltip: 'В течение 14-дневного пробного периода вам будет предоставлен доступ к полнофункциональной службе (в том числе к ее премиум-функциям) на 14 дней. По окончании пробного периода 360 Monitoring переключится на бесплатную версию “Lite”, в которой доступны только основные функции. Вы также можете в любой момент активировать версию “Pro”, купив лицензию.',
        },
        buttons: {
            confirm: 'Подтвердить',
            deny: 'Запретить',
            cancel: 'Отмена',
        },
        errors: {
            apiKey: 'Похоже, соединение сервера с Plesk 360 прервано. Подключите свой сервер к Plesk 360 еще раз, а затем активируйте мониторинг',
            default: 'Установить 360 Monitoring не удалось. Попробуйте еще раз позже.',
        },
    },
};