// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { vulnerabilityRiskRankValues, VulnerabilityRiskRanks } = makeEnum(
    'VulnerabilityRiskRank',
    'low',
    'medium',
    'high',
    'critical',
);

export type VulnerabilityRiskRank = UnionOf<typeof vulnerabilityRiskRankValues>;
