// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ToolbarGroup } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ServerStatusMessage from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/ServerStatusMessage';
import ServerUpdatesButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/ServerUpdatesButton';
import SyncButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/SyncButton';
import { UpdateServerTriggers } from '@platform360/server-inventory/web/helpers/analytics/ServerInventoryAnalyticsEvents';
import { productName } from '@platform360/server-inventory/web/helpers/productName';
import { Server } from '@platform360/server-inventory/web/types';

export type InactiveStatusMessageProps = {
    server: Server;
};

const InactiveUnsupportedStatusMessage = ({ server, ...props }: InactiveStatusMessageProps) => {
    const translate = useTranslate(
        'server-inventory.Servers.ViewServer.GeneralInfo.StatusMessages.InactiveUnsupportedStatusMessage',
    );

    const product = productName[server.product];
    const version = server.productVersion?.version ?? '_';

    return (
        <ServerStatusMessage
            {...props}
            intent="danger"
            data-type="inactive-unsupported-status-message"
            errorCode={'unknown'}
            message={translate('message', { version })}
            fixMessage={translate('fixDescription')}
            actions={
                <ToolbarGroup title="">
                    <ServerUpdatesButton
                        intent="primary"
                        server={server}
                        trigger={UpdateServerTriggers.serverInfoOutdatedWarningUpdateServerButton}
                    >
                        {translate('goToUpdatesButton', { product })}
                    </ServerUpdatesButton>
                    <SyncButton serverId={server.id} />
                </ToolbarGroup>
            }
        />
    );
};

export default InactiveUnsupportedStatusMessage;
