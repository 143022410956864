// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { ReactNode, useCallback } from 'react';
import { PrimitiveType } from 'intl-messageformat';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type TrialDisplayInfo = {
    text: ReactNode;
    params?: Record<string, PrimitiveType>;
    intent: 'danger' | 'warning' | undefined;
    daysLeft: number;
};

export const useDateToTrialDisplayInfo = () => {
    const formatDateTime = useFormatDateTime();
    const translate = useTranslate('security-dashboard.useDateToTrialDisplayInfo');

    return useCallback(
        (date: DateTime, dateFormat?: string): TrialDisplayInfo => {
            const daysLeft = Math.ceil(date.diff(DateTime.now(), 'days').days);
            if (daysLeft <= 0) {
                return { text: translate('noLicense'), intent: 'danger', daysLeft: 0 };
            }
            if (daysLeft <= 14) {
                let intent: TrialDisplayInfo['intent'] = undefined;
                if (daysLeft <= 3) {
                    intent = 'danger';
                } else if (daysLeft <= 7) {
                    intent = 'warning';
                }
                return {
                    text: translate('trialDaysLeft', { days: daysLeft }),
                    intent,
                    daysLeft,
                };
            }
            return {
                text: translate('trialUntil', {
                    date: formatDateTime(date, dateFormat ?? 'D'),
                }),
                intent: undefined,
                daysLeft,
            };
        },
        [formatDateTime, translate],
    );
};
