// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FormField, InputNumber } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import Cost from '@platform360/licenses/web/components/Cost';
import Currency from '@platform360/licenses/web/types/currency';

export type Product = {
    id: number;
    description: string;
    price: number;
    name: string;
    value: number;
    rangeValues: {
        min: number;
        max: number;
    };
};

export type CountableFormProps = {
    list: Product[];
    onProductChange: (productId: number, value: number) => void;
    period: BillingPeriod;
    currency: Currency;
    isAdjustPending: boolean;
};

const CountableForm = ({
    list,
    onProductChange,
    period,
    currency,
    isAdjustPending,
}: CountableFormProps) => {
    const translate = useTranslate();

    return (
        <>
            {list.map((product) => (
                <FormField
                    key={`${product.name}${product.id}`}
                    label={product.description}
                    description={
                        <>
                            <BillingInterval period={period}>
                                <Cost value={product.price} currency={currency} />
                            </BillingInterval>
                            &nbsp;
                            {translate('perOne')}
                        </>
                    }
                >
                    <InputNumber
                        key={product.id}
                        defaultValue={product.value}
                        min={product.rangeValues.min}
                        max={product.rangeValues.max}
                        onChange={(value) => {
                            onProductChange(product.id, value);
                        }}
                        disabled={isAdjustPending}
                    />
                </FormField>
            ))}
        </>
    );
};

export default CountableForm;
