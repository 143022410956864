// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getApiTokens } from '@platform360/accounts/web/api/api-tokens';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseApiTokensQueryVariables = undefined;

export type UseApiTokensQueryData = PromiseValue<ReturnType<typeof getApiTokens>>;

const useApiTokensQuery = createQuery<UseApiTokensQueryVariables, UseApiTokensQueryData>({
    queryName: 'accounts/useApiTokensQuery',
    fetcher: getApiTokens,
    useQuery,
});

export default useApiTokensQuery;
