// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, FormErrors, FormFieldText, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type Values = {
    email?: string;
};

export type ChangeableNotificationsEmailDialogProps = {
    isOpen: boolean;
    values: Values;
    onSave: (values: Values) => void;
    onCancel: () => void;
    errors?: FormErrors;
};

export const ChangeableNotificationsEmailDialog = ({
    isOpen,
    onCancel,
    values,
    errors,
    onSave,
}: ChangeableNotificationsEmailDialogProps) => {
    const translate = useTranslate('security-dashboard.ChangeableNotificationsEmail');

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onCancel}
            title={translate('dialogTitle')}
            size="xs"
            data-type="notifications-email-edit-dialog"
            form={{
                values,
                errors,
                onSubmit: (values: Values) => onSave(values),
                submitButton: { children: translate('buttonSave'), 'data-type': 'save-button' },
                cancelButton: {
                    children: translate('buttonCancel'),
                    'data-type': 'cancel-button',
                },
            }}
        >
            <Paragraph>{translate('description')}</Paragraph>
            <FormFieldText label={translate('email')} name="email" size="lg" autoFocus required />
        </Dialog>
    );
};
