// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getAllClients } from '@platform360/server-inventory/web/api/clients';
import { FilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';

export type UseAllClientsQueryVariables = PaginatedQuery & FilterQuery;

export type UseAllClientsQueryData = PromiseValue<ReturnType<typeof getAllClients>>;

const useAllClientsQuery = createQuery<UseAllClientsQueryVariables, UseAllClientsQueryData>({
    queryName: 'server-inventory/useAllClientsQuery',
    fetcher: getAllClients,
    useQuery,
});

export default useAllClientsQuery;
