// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Ein Label mit diesem Namen ist bereits vorhanden',
        invitationNotExist: 'Einladung existiert nicht.',
        wpApiClientUnreachable: 'Dieser Server oder diese Website scheint im Moment offline zu sein. Bitte prüfen Sie, ob der Server oder die Website erreichbar ist, und versuchen Sie es erneut. Falls der Server oder die Website ordnungsgemäß funktioniert und es keine Probleme mit der Verbindung zu WP Guardian zu geben scheint, kontaktieren Sie bitte unseren Support.',
    },
    termsOfUseLink: 'WP Guardian-Nutzungsbedingungen',
    NotFoundPage: {
        title: 'Seite nicht gefunden',
        description: "Die angeforderte Seite wurde nicht gefunden. Möglicherweise haben Sie auf einen nicht funktionierenden Link geklickt oder die Seite wurde gelöscht.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Abbrechen der Lizenzaktivierung bestätigen',
            description: 'Sie sind dabei, die Aktivierung der {product}-Lizenz abzubrechen. Sie können diese Lizenz jederzeit später aktivieren, indem Sie erneut auf den Aktivierungslink klicken. Wenn die Lizenz für ein anderes Konto erworben wurde, melden Sie sich in diesem Konto an und klicken Sie auf den Aktivierungslink.',
            cancelButton: 'Abbrechen',
            continueButton: 'Aktivierung fortsetzen',
        },
        SecurityDashboardActivateLicense: {
            title: 'Aktivierung der Lizenz für WP Guardian bestätigen',
            description: 'Bestätigen Sie, dass Sie die <b>{product}</b>-Lizenz für das Konto <mailto>{userEmail}</mailto> aktivieren möchten. Die Lizenz kann nur für ein Konto aktiviert werden.',
            fixableErrorDescription: 'Bei der Aktivierung der Lizenz für WP Guardian ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder stellen Sie eine Supportanfrage.',
            nonFixableErrorDescription: 'Bei der Aktivierung der Lizenz für WP Guardian ist ein Fehler aufgetreten. Bitte stellen Sie eine Supportanfrage.',
            loaderText: 'Ihr WP Guardian-Konto wird konfiguriert',
            redirectText: 'Sie werden weitergeleitet zu WP Guardian',
            confirmButton: 'Bestätigen',
            cancelButton: 'Abbrechen',
            retryButton: 'Erneut versuchen',
            gotItButton: 'OK',
            errors: {
                linkError: 'Der Aktivierungslink ist nicht gültig.',
                unknownError: 'Interner Servicefehler bei der Aktivierung.',
            },
        },
    },
    Filters: {
        all: 'Alle',
    },
    FiltersForm: {
        title: 'Filter',
        clear: 'Löschen',
        apply: 'Filter anwenden',
    },
    Invitation: {
        activationFailure: 'Einladungslink konnte nicht aktiviert werden',
    },
    UnderConstructionPopover: {
        title: 'In Bearbeitung',
        description: "Wir sind noch nicht ganz fertig!",
    },
    RiskRankTitle: {
        title: 'Risiko',
        description: 'Die Risikobewertung ist eine zusammenfassende Einschätzung der Auswirkungen von Sicherheitslücken. Sie basiert auf Bewertungen von CVSS, EPSS, Patchstack Patch Priority und anderen Faktoren.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'Diese Sicherheitslücke wird bereits aktiv ausgenutzt. Eine sofortige Reaktion wird dringend empfohlen.',
        highRisk: 'Dies ist eine Sicherheitslücke mit hohem Risiko, die so schnell wie möglich behoben werden sollte.',
        mediumRisk: 'Es handelt sich um eine Sicherheitslücke mit mittlerem Risiko, die kein dringendes Eingreifen erfordert, aber dennoch beachtet werden sollte.',
        lowRisk: 'Dies ist eine Schwachstelle mit geringem Risiko, die problemlos ignoriert werden kann.',
        cvss: 'CVSS: {score}',
        emptyCvss: 'n. v.',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protection disabled',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Ignoriert',
        },
        ignoredDueToLowRisk: {
            title: 'Automatisch ignoriert',
        },
        doNotIgnoreAutomatically: {
            title: 'Nicht automatisch ignorieren',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: 'Neue WordPress-Websites werden gesucht',
        refreshInstallations: 'Wird aktualisiert',
        detachInstallations: 'Wird getrennt',
        updateInstallation: 'Wird aktualisiert',
        massUpdateInstallations: 'Wird aktualisiert',
        applyVirtualPatches: 'Schutz vor Sicherheitslücken aktivieren',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Update-Einstellungen werden gespeichert',
        clearInstallationCache: 'Installations-Cache wird gelöscht',
        checkVulnerability: 'Sicherheitslücken werden gesucht',
        mitigateVulnerabilities: 'Sicherheitslücken eindämmen',
        setDailyTaskStartTime: 'Zeitpunkt der Sicherheitsüberprüfung ändern',
    },
    installationComponents: {
        core: 'Core',
        plugin: 'Plugin',
        theme: 'Theme',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {Label wurde zugewiesen} other {Labels wurden zugewiesen}}',
        failed: '{count, select, 1 {Label konnte nicht zugewiesen werden} other {Labels konnten nicht zugewiesen werden}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {Label wurde entfernt} other {Labels wurden entfernt}}',
        failed: '{count, select, 1 {Label konnte nicht entfernt werden} other {Labels konnten nicht entfernt werden}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {Label wurde zugewiesen} other {Labels wurden zugewiesen}}',
        failed: '{count, select, 1 {Label konnte nicht zugewiesen werden} other {Labels konnten nicht zugewiesen werden}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {Label wurde entfernt} other {Labels wurden entfernt}}',
        failed: '{count, select, 1 {Label konnte nicht entfernt werden} other {Labels konnten nicht entfernt werden}}',
    },
    useCreateLabelMutation: {
        success: 'Label wurde erstellt',
        failed: 'Label konnte nicht erstellt werden',
    },
    useEditLabelMutation: {
        success: 'Label wurde geändert',
        failed: 'Label konnte nicht geändert werden',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {Label wurde gelöscht} other {Labels wurden gelöscht}}',
        failed: '{count, select, 1 {Label konnte nicht gelöscht werden} other {Labels konnten nicht gelöscht werden}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'Einstellungen für Benachrichtigungen wurden aktualisiert.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'Aktualisierung der Websitedaten auf dem Server <b>{selectedServerTitle}</b> konnte nicht geplant werden.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Aktualisierung der Websitedaten auf <b>#</b> Server konnte nicht geplant werden.} other {Aktualisierung der Websitedaten auf <b>#</b> Servern konnte nicht geplant werden.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'Suche nach neuen WordPress-Websites auf dem Server <b>{selectedServerTitle}</b> konnte nicht geplant werden.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Suche nach neuen WordPress-Websites auf <b>#</b> Server konnte nicht geplant werden.} other {Suche nach neuen WordPress-Websites auf <b>#</b> Servern konnte nicht geplant werden.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'Die Verknüpfung mit dem Server <b>{selectedServerTitle}</b> wurde getrennt.',
        notifySeveralServers: '{selectedServersCount, plural, one {Verknüpfung mit <b>#</b> Server wurde getrennt.} other {Verknüpfung mit <b>#</b> Servern wurde getrennt.}}',
        notifySingleServerFail: 'Die Verknüpfung mit dem Server <b>{selectedServerTitle}</b> wurde nicht getrennt.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Verknüpfung mit <b>#</b> Server wurde nicht getrennt.} other {Verknüpfung mit <b>#</b> Servern wurde nicht getrennt.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'Website-Updates auf dem Server <b>{selectedServerTitle}</b> konnten nicht geplant werden.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Website-Updates auf <b>#</b> Server konnten nicht geplant werden.} other {Website-Updates auf <b>#</b> Servern konnten nicht geplant werden.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Global',
            server: 'Server',
        },
        coreLabels: {
            disabled: 'Updates nicht automatisch installieren',
            major: 'Alle Updates automatisch installieren',
            minor: 'Kleinere Sicherheitsupdates automatisch installieren',
            notSet: 'Auf Website-Ebene festgelegt',
            minorDescription: 'Beispielsweise wird 4.7.1, aber nicht 4.8 automatisch installiert.',
            doNotTouch: 'Nicht ändern',
        },
        pluginsLabels: {
            disabled: 'Website-Admin entscheidet, welche Plugins automatisch aktualisiert werden sollen',
            disabledDescription: 'In WordPress können Website-Admins automatische Updates für einzelne Plugins separat aktivieren',
            major: 'Alle Plugin-Updates automatisch installieren',
            majorDescription: 'Individuelle Einstellungen für automatische Updates von Plugins ignorieren',
            minor: 'Nur Sicherheitsupdates automatisch installieren',
            minorDescription: 'Individuelle Einstellungen für automatische Updates von Plugins verwenden, aber Updates automatisch installieren, wenn damit eine bekannte Sicherheitslücke behoben wird',
            notSet: 'Auf Website-Ebene festgelegt',
            doNotTouch: 'Nicht ändern',
        },
        themesLabels: {
            disabled: 'Website-Admin entscheidet, welche Themes automatisch aktualisiert werden sollen',
            disabledDescription: 'In WordPress können Website-Admins automatische Updates für einzelne Themes separat aktivieren',
            major: 'Alle Theme-Updates automatisch installieren',
            majorDescription: 'Individuelle Einstellungen für automatische Updates von Themes ignorieren',
            minor: 'Nur Sicherheitsupdates automatisch installieren',
            minorDescription: 'Individuelle Einstellungen für automatische Updates von Themes verwenden, aber Updates automatisch installieren, wenn damit eine bekannte Sicherheitslücke behoben wird',
            notSet: 'Auf Website-Ebene festgelegt',
            doNotTouch: 'Nicht ändern',
        },
        safeUpdatesLabels: {
            notSet: 'Auf Website-Ebene festgelegt',
            enabled: 'Sichere Updates aktivieren',
            disabled: 'Sichere Updates deaktivieren',
            doNotTouch: 'Nicht ändern',
        },
        safeUpdates: {
            title: 'Sichere Updates',
            description: 'Erstellen Sie vor dem Update ein Backup der Website. Sollte die Website durch das Update beschädigt werden, wird das Backup automatisch wiederhergestellt.',
            checkbox: 'Sichere Updates aktivieren',
        },
        labels: {
            core: 'WordPress-Core',
            plugins: 'Plugins',
            themes: 'Themes',
        },
        doNotTouch: 'Nicht ändern',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'Die Update-Einstellungen werden für alle neu hinzugefügten und aktuellen Server ohne benutzerdefinierte Update-Einstellungen übernommen',
        checkboxDescription: 'Einstellungen auch für alle Server und Websites mit benutzerdefinierten Update-Einstellungen übernehmen',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Zeitpunkt der Sicherheitsüberprüfung',
        typeOptions: {
            doNotTouch: 'Nicht ändern',
            unset: 'Von Server festgelegt',
            custom: 'Selbst festlegen',
        },
        timeLabel: 'Zeitpunkt (24-Stunden-Format)',
        timezoneDescription: 'Zeitzone: Standardzeitzone des Servers',
    },
    SettingsDrawer: {
        title: 'Einstellungen',
        subTitle: 'Einstellungen für alle verknüpften Server und Websites',
        loadFailed: 'Die Einstellungen konnten nicht geladen werden.',
        tabs: {
            updates: 'Updates',
            dailyTaskTime: 'Zeitplan',
            vulnerabilities: 'Sicherheitslücken',
        },
        DailyTaskTime: {
            description: 'WP Guardian prüft die Server alle 24 Stunden auf neue Sicherheitslücken und verfügbare Updates. Dieser Check ist rechenintensiv. Planen Sie ihn daher so, dass er standardmäßig zu einer bestimmten Zeit auf allen Servern ausgeführt wird.',
        },
        successToast: 'Die Einstellungen wurden gespeichert.',
        buttonSave: 'Speichern',
    },
    Operations: {
        openTaskManagerLinkText: 'Weitere Informationen',
        operationsFailed: '{count, plural, one {# Vorgang ist fehlgeschlagen} other {# Vorgänge sind fehlgeschlagen}}',
        fallback: {
            success: 'Der Vorgang wurde durchgeführt',
            failed: 'Vorgang ist fehlgeschlagen',
        },
        servers: {
            demo: {
                success: 'Aktion auf einem Demoserver wurde durchgeführt.',
                failed: 'Vorgang auf einem Demoserver ist fehlgeschlagen.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Failed to enable vulnerability protection',
            },
            update: {
                success: '{count, plural, one {Installationen auf # Server wurden aktualisiert} other {Installationen auf # Servern wurden aktualisiert}}',
                failed: '{count, plural, one {Installationen auf # Server konnten nicht aktualisiert werden} other {Installationen auf # Servern konnten nicht aktualisiert werden}}',
            },
            refresh: {
                success: 'Aktualisierung der Websitedaten ist abgeschlossen',
                failed: 'Websitedaten konnten nicht aktualisiert werden',
            },
            findNewSites: {
                success: '{count, plural, one {Suche nach neuen WordPress-Installationen auf # Server wurde abgeschlossen} other {Suche nach neuen WordPress-Installationen auf # Servern wurde abgeschlossen}}',
                failed: '{count, plural, one {Suche nach neuen WordPress-Installationen auf # Server fehlgeschlagen} other {Suche nach neuen WordPress-Installationen auf # Servern fehlgeschlagen}}',
            },
            configureUpdates: {
                success: 'Update-Einstellungen wurden konfiguriert',
                failed: 'Update-Einstellungen konnten nicht konfiguriert werden',
            },
            disconnect: {
                success: '{count, plural, one {Verknüpfung mit # Server wurde getrennt} other {Verknüpfung mit # Servern wurde getrennt}}',
                failed: '{count, plural, one {Verknüpfung mit # Server konnte nicht getrennt werden} other {Verknüpfung mit # Servern konnte nicht getrennt werden}}',
            },
        },
        installations: {
            demo: {
                success: 'Aktion auf einem Demoserver war erfolgreich.',
                failed: 'Vorgang auf einem Demoserver ist fehlgeschlagen.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'Alle ausgewählten WordPress-Installationen wurden aktualisiert',
                failed: 'Die ausgewählten WordPress-Installationen konnten nicht aktualisiert werden',
            },
            configureUpdates: {
                success: 'Die Update-Einstellungen wurden geändert',
                failed: 'Update-Einstellungen konnten nicht geändert werden',
            },
            detach: {
                success: 'Die WordPress-Installation wurde von WP Guardian getrennt. Sie wird bei den folgenden Serverscans ignoriert. Damit diese Installation wieder für WP Guardian sichtbar ist, entfernen Sie die Datei .wp-toolkit-ignore aus dem Installationsverzeichnis.',
                failed: 'Die Verknüpfung zur WordPress-Installation konnte nicht getrennt werden',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'Sicherheitslücken wurden durch die Installation von Updates behoben',
                failed: '{count, plural, one {Sicherheitslücken in # Installation konnten nicht behoben werden} other {Sicherheitslücken in # Installationen konnten nicht behoben werden}}',
            },
            mitigationDeactivateAsset: {
                success: 'Die Sicherheitslücken wurden durch die Deaktivierung des Plugins eingedämmt',
                failed: '{count, plural, one {Sicherheitslücke in # Installation konnte nicht durch die Deaktivierung des Plugins eingedämmt werden} other {Sicherheitslücken in # Installationen konnten nicht durch die Deaktivierung des Plugins eingedämmt werden}}',
            },
            mitigationActivateAsset: {
                success: 'Aufgrund von Sicherheitslücken deaktivierte Plugins wurden wieder aktiviert',
                failed: '{count, plural, one {Zuvor als anfällig eingestufte Plugins in # Installation konnten nicht erneut aktiviert werden} other {Zuvor als anfällig eingestufte Plugins in # Installationen konnten nicht erneut aktiviert werden}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'Sicherheitslücken wurden durch die Installation von Updates behoben',
                failed: '{count, plural, one {# Sicherheitslücke wurde nicht behoben} other {# Sicherheitslücken wurden nicht behoben}}',
            },
            mitigationDeactivateAsset: {
                success: 'Die Sicherheitslücken wurden durch die Deaktivierung des Plugins eingedämmt',
                failed: '{count, plural, one {# Sicherheitslücke konnte nicht durch die Deaktivierung des Plugins eingedämmt werden} other {# Sicherheitslücken konnten nicht durch die Deaktivierung des Plugins eingedämmt werden}}',
            },
            mitigationActivateAsset: {
                success: 'Aufgrund von Sicherheitslücken deaktivierte Plugins wurden wieder aktiviert',
                failed: '{count, plural, one {Plugins, die von # Sicherheitslücke betroffen sind, konnten nicht wieder aktiviert werden} other {Plugins, die von # Sicherheitslücken betroffen sind, konnten nicht wieder aktiviert werden}}',
            },
            ignoreVulnerabilities: {
                success: 'Sicherheitslücken wurden ignoriert',
                failed: '{count, plural, one {# Sicherheitslücke wurde nicht ignoriert} other {# Sicherheitslücken wurden nicht ignoriert}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'Sicherheitslücken werden nicht mehr ignoriert',
                failed: '{count, plural, one {Das Ignorieren von # Sicherheitslücke konnte nicht aufgehoben werden} other {Das Ignorieren von # Sicherheitslücken konnte nicht aufgehoben werden}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Schnelleinstieg',
        QuickStartWizard: {
            startStepName: 'Erste Schritte',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Benachrichtigungen',
            securityCheckScheduleStepName: 'Sicherheitsüberprüfung',
            updateStepName: 'Updates',
        },
        QuickStartStep: {
            pagination: '{current} von {total}',
            ok: 'OK',
            next: 'Weiter',
            back: 'Zurück',
        },
        StartStep: {
            title: 'Willkommen bei WP Guardian',
            text: 'Legen Sie hier alle Einstellungen fest, um für die Sicherheit Ihrer WordPress-Websites zu sorgen.',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Zeitpunkt der Sicherheitsüberprüfung',
            checkingTimeText: 'WP Guardian sucht alle 24 Stunden nach Änderungen an den Servern und Websites. Dieser Vorgang ist rechenintensiv und nimmt eine bestimmte Zeit sowie Serverressourcen in Anspruch.',
            vulnerabilitiesUpdateTitle: 'Aktualisierungen der Datenbank für Sicherheitslücken',
            vulnerabilitiesUpdateText: 'Die Datenbank der Sicherheitslücken wird automatisch einmal pro Stunde aktualisiert. Dies hat keine Auswirkungen auf die Leistung von Servern und Websites.',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'So funktioniert es',
            howItWorksText: 'Ein Plugin wird in WordPress installiert und als Web Application Firewall eingesetzt. Es werden dabei Firewallregeln angewendet, die auf Sicherheitslücken einer Website abzielen. WordPress-Dateien oder Website-Inhalte werden nicht geändert.',
            poweredBy: 'mit der Technologie von {providerLogo}',
        },
        UpdatesStep: {
            next: "Weiter",
            updateSettings: 'Update-Einstellungen',
            updateSettingsText: 'Sie können automatische Updates für alle mit WP Guardian verbundenen Websites erzwingen, auch für nachträglich hinzugefügte Websites. Dies kann später für einzelne Websites oder Server konfiguriert werden.',
            backup: 'Vor dem Update ein Backup erstellen',
            backupText: 'Wenn Sie sichere Updates aktivieren, erstellt WP Guardian vor dem Update ein Backup der Website. Sollte dann etwas nicht wie erwartet funktionieren, wird die Website automatisch aus dem Backup wiederhergestellt.',
            safeUpdates: 'Sichere Updates',
            customizedSettings: 'Benutzerdefinierte Einstellungen',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Aufgabenprotokoll',
        Toolbar: {
            buttons: {
                groupTitle: 'Buttons',
                allTasks: 'Alle Aufgaben',
                failed: 'Fehler',
            },
            filters: {
                groupTitle: 'Filter',
                typeUpdate: 'Updates installieren',
                refresh: 'Daten aktualisieren',
                typeFindNewSites: 'Neue Websites finden',
                typeConfigureUpdates: 'Updates konfigurieren',
                typeFixVulnerabilitiesViaUpdate: 'Sicherheitslücken über ein Update beheben',
                mitigationDeactivateAsset: 'Sicherheitslücken durch die Plugin-Deaktivierung eindämmen',
                mitigationActivateAsset: 'Plugins wieder aktivieren, die aufgrund von Sicherheitslücken deaktiviert wurden',
                ignoreVulnerabilities: 'Sicherheitslücken ignorieren',
                cancelIgnoreVulnerabilities: 'Ignorieren von Sicherheitslücken aufheben',
                setDailyTaskTime: 'Zeitpunkt der Sicherheitsüberprüfung ändern',
            },
            search: {
                title: 'Suchen',
                placeholder: 'Suchen',
            },
        },
        TasksList: {
            columns: {
                task: 'Aufgabe',
                status: 'Status',
                started: 'Start',
                finished: 'Ende',
            },
            taskTypes: {
                fallBack: "Aktion mit Code ''{type}''",
                demo: 'Aktion auf einem Demoserver',
                update: 'Updates installieren',
                refresh: 'Daten aktualisieren',
                detach: 'Trennen',
                findNewSites: 'Neue Websites finden',
                configureUpdates: 'Updates konfigurieren',
                setDailyTaskTime: 'Zeitpunkt der Sicherheitsüberprüfung ändern',
                protect: 'Schutz vor Sicherheitslücken aktivieren',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Trennen',
                fixVulnerabilitiesViaUpdate: 'Sicherheitslücken über ein Update beheben',
                changeInstallationPluginStatus: 'Plugin-Status ändern',
                mitigationDeactivateAsset: 'Sicherheitslücken durch die Plugin-Deaktivierung eindämmen',
                mitigationActivateAsset: 'Plugins wieder aktivieren, die aufgrund von Sicherheitslücken deaktiviert wurden',
                ignoreVulnerabilities: 'Sicherheitslücken ignorieren',
                cancelIgnoreVulnerabilities: 'Ignorieren von Sicherheitslücken aufheben',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# Server} other {# Server}}',
                sites: '{count, plural, one {# Website} other {# Websites}}',
                vulnerabilities: '{count, plural, one {# Sicherheitslücke} other {# Sicherheitslücken}}',
            },
            statuses: {
                success: 'Erfolgreich',
                inProgress: 'In Bearbeitung',
                failed: 'Fehlgeschlagen',
                errors: 'Fehler',
                unknown: 'Unbekannt',
            },
            emptyList: {
                title: 'Keine Aufgaben',
                description: 'Es wurden noch keine Aufgaben ausgeführt.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Websites mit deaktiviertem Schutz',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Schutz auf dieser Website wieder aktivieren} other {Schutz auf diesen Websites wieder aktivieren}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "Sie haben keine Lizenz",
        trialDaysLeft: '{days, plural, one {Testversion (noch # Tag)} other {Testversion (noch # Tage)}}',
        trialUntil: 'Testversion',
    },
    PromoCodeForm: {
        promoCode: 'Ihr Gutscheincode',
        apply: 'Anwenden',
        activationFailure: 'Die Aktivierung des Gutscheincodes ist fehlgeschlagen',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Anwenden',
            trialTitle: '14 Tage testen',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial:'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Lizenz auswählen',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: 'Sie nutzen jetzt die Testversion!',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: 'Ihr Testzeitraum endet',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'Keine Lizenz',
            expired: '(abgelaufen)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Feedback geben',
            },
        },
        tabs: {
            servers: 'Server',
            installations: 'WordPress-Websites',
            vulnerabilities: 'Sicherheitslücken',
        },
        Footer: {
            Links: {
                followUs: 'Folgen Sie uns:',
                company: 'Unternehmen',
                aboutWebpros: 'Über WebPros',
                cookiehub: 'Cookie-Einstellungen',
                privacyPolicy: 'Datenschutzrichtlinie',
                knowledgeBase: 'Knowledge Base',
                documentation: 'Dokumentation',
                helpCenter: 'Hilfe',
                contactUs: 'Kontakt',
                legal: 'Rechtliches',
            },
            copyright: '© {year} WebPros International GmbH. Alle Rechte vorbehalten. WP Guardian und das WP Guardian Logo sind Marken von WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Einstellungen für automatische Updates',
            },
            CardLoader: {
                loading: 'Wird geladen...',
            },
            InstallationsCard: {
                title: 'WordPress-Websites',
                vulnerable: 'Anfällig: {count}',
                outdated: 'Veraltet: {count}',
                protected: 'Geschützt: {count}',
                safe: 'OK: {count}',
                notOperable: 'Nicht funktionsfähig: {count}',
                poweredBy: '<whiteSpaceNoWrap>Mit der Technologie von {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Sicherheitslücken',
                databaseUpdate: 'Datenbankupdate',
                momentAgo: 'gerade eben',
                noData: 'Keine Daten',
                lastUpdateMinutes: '{minutes, plural, one {Vor # Minute} other {Vor # Minuten}}',
                lastUpdateHours: '{hours, plural, one {Vor # Stunde} other {Vor # Stunden}}',
                lastUpdateDays: '{days, plural, one {Vor # Tag} other {Vor # Tagen}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Höchster CVSS-Wert',
                needsProtection: '{count, plural, one {Braucht Schutz: #} other {Brauchen Schutz: #}}',
            },
            QuickStartCard: {
                title: 'Schnelleinstieg',
                ctaBlockTitle: 'Sicherheitseinstellungen einrichten',
                ctaBlockText: 'mit nur ein paar Klicks',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'Lizenzdetails',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'Derzeit gibt es kein Update, mit dem diese Sicherheitslücke behoben werden kann.',
        columns: {
            position: 'Ort',
        },
        Onboarding: {
            title: 'Keine Sicherheitslücken gefunden',
            description: 'Verknüpfen Sie Server, um WordPress-Websites zu finden und sie auf Sicherheitslücken und Updates zu überprüfen.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Eingedämmt',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Eingedämmt durch Plugin-Deaktivierung',
                tooltip: {
                    'singleSiteMitigatedByOther': 'Die von dieser Sicherheitslücke betroffene Website wird derzeit über ein anderes Tool als WP Guardian geschützt.',
                    'mitigatedByOther': 'Die von dieser Sicherheitslücke betroffenen Websites werden derzeit über ein anderes Tool als WP Guardian geschützt',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Quelle:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'Dieser Eintrag enthält Material, das dem Urheberrecht unterliegt.',
            wordfenceCopyright: 'Copyright 2012-2024 Defiant Inc. Lizenz: Defiant gewährt Ihnen hiermit eine unbefristete, weltweite, nicht-exklusive, kostenlose, lizenzgebührenfreie, unwiderrufliche Urheberrechtslizenz zur Vervielfältigung, Erstellung abgeleiteter Werke, öffentlichen Darstellung, öffentlichen Aufführung, Unterlizenzierung und Verbreitung dieser Informationen über Softwaresicherheitslücken. Kopien der Informationen über Softwaresicherheitslücken, die Sie zu diesen Zwecken anfertigen, sind zulässig, sofern Sie einen Hyperlink zu diesem Protokoll der Sicherheitslücken einfügen und die Urheberrechtskennzeichnung von Defiant und diese Lizenz in jeder solchen Kopie wiedergeben.',
            readMore: 'Mehr erfahren',
        },
        Toolbar: {
            groupFilterMenu: 'Filter',
            groupSearch: 'Suchen',
            manage: 'Verwalten',
            patch: 'Protection',
            searchPlaceholder: 'Sicherheitslücke suchen...',
            UpdateButton: {
                update: 'Updates installieren',
                cancel: 'Abbrechen',
                noSelection: 'Wählen Sie mindestens eine Sicherheitslücke aus, die mit einem Update behoben werden soll.',
                updateConfirmation: '{totalVulnerabilities, plural, one {Sie sind dabei, mehrere Websites zu aktualisieren, um # Sicherheitslücke zu beheben} other {Sie sind dabei, mehrere Websites zu aktualisieren, um # Sicherheitslücken zu beheben}}. Nachdem das Update begonnen hat, kann es nicht mehr unterbrochen werden.',
                updateConfirmationPluginTitle: 'Mehrere Plugins wurden deaktiviert',
                updateConfirmationPluginCheckbox: 'Plugins wieder aktivieren, die durch Sicherheitsrichtlinien deaktiviert wurden',
                updateConfirmationPluginCheckboxHint: 'Anfällige Plugins, die aufgrund von Sicherheitsrichtlinien deaktiviert wurden, werden automatisch wieder aktiviert, wenn die Sicherheitslücken durch die Updates behoben werden.',
                numberOfSites: 'Anzahl der Websites, die aktualisiert werden:',
                numberOfSitesUnmanaged: 'Anzahl der Websites, die aktualisiert werden (nicht verwaltete Websites werden übersprungen):',
                sitesCalculating: 'Wird berechnet...',
                sitesCalculatingError: 'Berechnung fehlgeschlagen',
                affectedSites: '{count, plural, one {{count} Website} other {{count} Websites}}',
                unmanagedLabel: '{count, plural, one {# nicht verwaltet} other {# nicht verwaltet}}',
                updateByProtect: 'Derzeit gibt es kein Update, mit dem diese Sicherheitslücke behoben werden kann.',
                upgradeLicense: 'Lizenz-Upgrade',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Ignorieren',
                noSelection: 'Wählen Sie mindestens eine Sicherheitslücke aus, die ignoriert oder für die dies aufgehoben werden soll',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} Website} other {{count} Websites}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Abbrechen',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Enable Protection',
                providerLogo: '<whiteSpaceNoWrap>Mit der Technologie von {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Wird berechnet...',
                sitesCalculatingError: 'Die Anzahl der betroffenen Websites konnte nicht berechnet werden',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# nicht verwaltet} other {# nicht verwaltet}}',
                update: 'Enable Protection',
                upgradeLicense: 'Lizenz-Upgrade',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Braucht Schutz',
            filterStateIgnored: 'Ignoriert',
            filterComponentCore: 'Core',
            filterComponentPlugin: 'Plugin',
            filterComponentTheme: 'Theme',
            everywhere: 'Alles',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {Sie sind dabei, # Website zu aktualisieren.} other {Sie sind dabei, # Websites zu aktualisieren.}} Nachdem das Update begonnen hat, kann es nicht mehr unterbrochen werden.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Plugins',
                installations: '{enabledCount, plural, one {Aktiv: #} other {Aktiv: #}}',
                installationsDeactivated: '{count, plural, one {Deaktiviert auf # Website} other {Deaktiviert auf # Websites}}',
                confirmationDeactivation: '{count, plural, one {Sie sind dabei, ein anfälliges Plugin auf # Website zu deaktivieren.} other {Sie sind dabei, ein anfälliges Plugin auf # Websites zu deaktivieren.}} Nachdem der Vorgang begonnen hat, kann er nicht mehr unterbrochen werden.',
                confirmationReactivation: '{count, plural, one {Sie sind dabei, ein Plugin wieder zu aktivieren, das zuvor aus Sicherheitsgründen auf # Website deaktiviert wurde. Damit wird die Website potenziellen Angriffen ausgesetzt.} other {Sie sind dabei, ein Plugin wieder zu aktivieren, das zuvor aus Sicherheitsgründen auf # Websites deaktiviert wurde. Damit werden die Websites potenziellen Angriffen ausgesetzt.}} Nachdem der Vorgang begonnen hat, kann er nicht mehr unterbrochen werden.',
                dropdownDeactivate: 'Deaktivieren',
                buttonDeactivateWithCount: '{count} deaktivieren',
                buttonReactivateWithCount: '{count} wieder aktivieren',
                buttonDeactivateAll: 'Alle deaktivieren',
                buttonReactivate: 'Erneut aktivieren',
                tooltipAllPluginsDeactivatedByUser: 'In WP Guardian werden Plugins, die von Website-Administratoren manuell deaktiviert wurden, nicht wieder aktiviert.',
            },
            updates: {
                title: 'Updates installieren',
                installations: '{count, plural, one {# Website} other {# Websites}}',
                waitingForUpdatesTitle: 'Noch keine Updates',
                buttonName: 'Alle aktualisieren',
            },
            patches: {
                allProtected: '{count, plural, one {# Website} other {# Websites}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Abbrechen',
                enableButton: 'Aktivieren',
                installations: '{count} von {total}',
                protectAllSites: 'Alle schützen',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Geschützte Websites',
                titleAllProtected: 'Alle geschützt',
            },
        },
        installations: '{count, plural, one {# Website} other {# Websites}}',
        servers: '{count, plural, one {# Server} other {# Server}}',
    },
    Onboarding: {
        title: 'Keine Server verknüpft',
        description: 'Verknüpfen Sie Server, um WordPress-Websites zu finden und sie auf Sicherheitslücken und Updates zu überprüfen.',
        demoServerWasAddedSuccessfully: 'Demoserver wurde hinzugefügt.',
        connectServers: '<addServerButton>Eigenen Server verwenden</addServerButton> <delimiter>oder</delimiter> <addDemoServerButton>Unseren Demoserver verwenden</addDemoServerButton>',
        hint: 'Der Demoserver wird vom WP Guardian-Team bereitgestellt und enthält mehrere WordPress-Websites. So können Sie die Funktionen ganz einfach erkunden, bevor Sie Ihren eigenen Server verknüpfen.',
        feedbackButton: 'Feedback senden',
        demoServerPopover: {
            description: 'Der Demoserver ist schreibgeschützt. Aktionen, die größere Auswirkungen zur Folge haben, wie die Installation von Updates, haben auf dem Demoserver keinen Einfluss auf den Status der Websites.',
            connectBtn: 'Verknüpfen',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Suche: {searchValue}',
        searchByServer: 'Server: {searchValue}',
        searchByVulnerability: 'Sicherheitslücke: {searchValue}',
        showAll: 'Alle anzeigen',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# der Websites auf den ausgewählten Servern ist derzeit nicht funktionsfähig. Der Vorgang wird auf dieser Website nicht durchgeführt, da sie entweder beschädigt oder aus anderen Gründen nicht verfügbar ist.} other {# der Websites auf den ausgewählten Servern sind derzeit nicht funktionsfähig. Der Vorgang wird auf diesen Websites nicht durchgeführt, da sie entweder beschädigt oder aus anderen Gründen nicht verfügbar sind.}}',
        state: {
            installingAgent: 'Agenten installieren',
            connectionError: 'Verbindungsfehler',
            agentInstallationError: 'Agent-Installation nicht abgeschlossen',
            connectionBlocked: 'Verbindung blockiert',
            synchronizationInProgress: 'Wird synchronisiert',
            connectionErrorStatusMessage: 'Die Verbindung zum Server kann nicht korrekt hergestellt werden.',
            connectionErrorFixDescription: 'Bitte überprüfen Sie die Protokolle und Einstellungen auf Ihrem Server ({ipAddress}) und warten Sie auf eine erneute Überprüfung.',
            connectionBlockedStatusMessage: 'Die Verbindung zum Server wird von der Firewall blockiert.',
            connectionBlockedFixDescription: 'Bitte überprüfen Sie, ob der Port 878 auf dem Server {ipAddress} geöffnet ist, und warten Sie auf eine erneute Überprüfung.',
            agentInstallationErrorMessage: 'Agent-Installation nicht abgeschlossen',
            agentInstallationErrorFixDescription: "Der WP Guardian-Agent hat nicht innerhalb von 5 Minuten über den erfolgreichen Abschluss der Installation informiert. Das bedeutet, dass die Installation entweder noch im Gange ist oder fehlgeschlagen ist. Überprüfen Sie den Installationsfortschritt in der Serverkonsole oder sehen Sie sich das Installationsprotokoll in der Datei '/root/wp-agent-install.log' an.",
        },
        AddServerDrawer: {
            title: 'Server verknüpfen',
            done: 'Fertig',
            paragraph1: 'Öffnen Sie Port 878 für eingehende Verbindungen von den folgenden IPs auf jedem Server, mit dem Sie eine Verbindung herstellen möchten: 34.254.37.129, 52.51.23.204 und 52.213.169.7',
            paragraph2: 'Kopieren Sie den folgenden Ausschnitt und fügen Sie ihn in die Serverkonsole ein. Warten Sie dann, bis der Installationsvorgang abgeschlossen ist.',
            errors: {
                failedToGenerateToken: 'Installationsbefehl konnte nicht generiert werden.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Kopieren',
            copied: 'Snippet wurde kopiert',
            refreshTooltip: 'Der Verbindungsbefehl ist während dieser Zeit für eine unbegrenzte Anzahl von Servern gültig. Aktualisieren Sie den Befehl, falls Sie mehr Zeit benötigen.',
        },
        SettingsDrawer: {
            title: 'Einstellungen',
            subTitle: {
                single: 'Server: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Updates',
                dailyTaskTime: 'Zeitplan',
            },
            loadFailed: 'Die Einstellungen konnten nicht geladen werden.',
            buttonSave: 'Speichern',
            successToast: 'Die Einstellungen wurden gespeichert.',
            Updates: {
                customizedSection: {
                    description: 'Die Update-Einstellungen werden für alle neu hinzugefügten und aktuellen Websites ohne benutzerdefinierte Update-Einstellungen übernommen',
                    checkboxDescription: 'Einstellungen auch für alle Websites mit benutzerdefinierten Update-Einstellungen übernehmen',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian sucht alle 24 Stunden nach neuen Sicherheitslücken und verfügbaren Updates. Dieser Check ist rechenintensiv. Planen Sie ihn daher so, dass er zu einer bestimmten Zeit ausgeführt wird.',
                unsetOption: 'Über allgemeine Einstellungen festgelegt',
            },
        },
        RemoveServersButton: {
            remove: 'Trennen',
            noSelectedServers: 'Wählen Sie mindestens einen Server aus, dessen Verknüpfung getrennt werden soll.',
            removeDescription: 'Der WP Guardian-Agent wird nicht automatisch entfernt, wenn die Verbindung zu einem Server getrennt wird. Verwenden Sie das uninstaller-Skript, um den WP Guardian-Agent zu entfernen (<a>Zur Anleitung</a>).',
            removeSingleServerConfirmation: 'Verknüpfung mit {selectedServerTitle} trennen?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {Verbindung zu <b>#</b> Server trennen?} other {Verbindung zu <b>#</b> Servern trennen?}}',
        },
        ListActions: {
            remove: 'Trennen',
            refresh: 'Aktualisieren',
            findNewSites: 'Neue Websites finden',
            settings: 'Einstellungen',
            disablePatch: 'Unprotect',
            update: 'Alle Websites aktualisieren',
            manageLabels: 'Labels anwenden',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Abbrechen',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# WP-Website} other {# WP-Websites}}',
            notOperable: 'Nicht funktionsfähig: {count}',
            vulnerable: 'Anfällig: {count}',
            vulnerabilities: 'Sicherheitslücken: {count}',
            outdated: 'Veraltet: {count}',
            lastCheck: 'Zuletzt geprüft: {dateTime}',
            lastCheckToday: 'Zuletzt geprüft: heute um {time}',
            unsupportedAgent: 'Nicht unterstützte Agent-Version',
            coreOutdated: 'Veraltet',
        },
        columns: {
            server: 'Server',
        },
        OutdatedAgentError: {
            message: 'Die Version des WP Guardian-Agents (wp-agent) auf dem Server ist veraltet',
            agentVersion: '{version, select, _ {Die Version Ihres WP Guardian-Agents ist nicht verfügbar.} other {Version Ihres WP Guardian-Agents: {version}.}}',
            updateDescription: 'Aktualisieren Sie das wp-agent-Paket auf die Version {wpAgentMinSupportedVersion} oder höher.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Update-Einstellungen',
            ipAddress: '<b>Server-IP:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Filter',
            manage: 'Verwalten',
            remove: 'Trennen',
            add: 'Verknüpfen',
            groupFilter: 'Filter',
            groupSearch: 'Suchen',
            searchPlaceholder: 'Server suchen...',
            noSelectedServers: 'Wählen Sie mindestens einen Server aus, dessen Verknüpfung getrennt werden soll.',
            FilterPanel: {
                filterAlerts: 'Warnungen',
                labelsPlaceholder: 'Alle Label',
            },
            RefreshButton: {
                buttonText: 'Aktualisieren',
                noSelectedServers: 'Wählen Sie mindestens einen Server aus, der aktualisiert werden soll.',
            },
            FindNewSitesButton: {
                buttonText: 'Neue Websites finden',
                noSelectedServers: 'Wählen Sie mindestens einen Server aus, um neue WordPress-Websites zu finden.',
            },
            UpdateButton: {
                buttonText: 'Updates installieren',
                confirmButtonText: 'Alle aktualisieren',
                noSelectedServers: 'Wählen Sie mindestens einen Server aus, um alle verfügbaren Updates auf allen WordPress-Websites zu installieren.',
                updateSingleServerConfirmation: 'Alle verfügbaren Updates werden auf den WordPress-Websites installiert, die sich auf dem Server <b>{selectedServerTitle}</b> befinden.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {Alle verfügbaren Updates werden auf allen WordPress-Websites installiert, die sich auf <b>#</b> ausgewählten Server befinden.} other {Alle verfügbaren Updates werden auf allen WordPress-Websites installiert, die sich auf den <b>#</b> ausgewählten Servern befinden.}}',
            },
            ProtectButton: {
                buttonText: 'Schützen',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Labels anwenden',
            },
            SettingsButton: {
                buttonText: 'Einstellungen',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Verknüpfen',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Sicherheitslücken mit geringem Risiko automatisch ignorieren',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Sicherheitslücken mit geringem Risiko automatisch ignorieren',
            warningMessage: 'Wenn diese Option deaktiviert ist, werden die Labels "Automatisch ignoriert" entfernt und alle automatisch ignorierten Sicherheitslücken sind wieder vollständig sichtbar.',
        },
    },
    Installations: {
        yes: 'Ja',
        no: 'Nein',
        update: 'Updates installieren',
        refresh: 'Aktualisieren',
        detach: 'Trennen',
        updateSettings: 'Update-Einstellungen',
        sitesAreNotOperable: '{notOperable, plural, one {# der ausgewählten Websites ist derzeit nicht funktionsfähig. Der Vorgang wird auf dieser Website nicht durchgeführt, da sie entweder beschädigt oder aus anderen Gründen nicht verfügbar ist.} other {# der ausgewählten Websites sind derzeit nicht funktionsfähig. Der Vorgang wird auf diesen Websites nicht durchgeführt, da sie entweder beschädigt oder aus anderen Gründen nicht verfügbar sind.}}',

        DetachInstallationsButton: {
            detach: 'Trennen',
            detachSingleInstallationConfirmation: 'Möchten Sie <b>{selectedInstallationTitle}</b> von WP Guardian trennen? Getrennte Installationen werden bei den nächsten Serverscans ignoriert.',
            detachSeveralInstallationsConfirmation: 'Möchten Sie <b>{selectedInstallationsCount}</b> Installationen von WP Guardian trennen? Getrennte Installationen werden bei den nächsten Serverscans ignoriert.',
        },
        Onboarding: {
            title: 'Keine WordPress-Websites gefunden',
            description: 'Verknüpfen Sie Server, um WordPress-Websites zu finden und sie auf Sicherheitslücken und veraltete Komponenten zu überprüfen.',
        },
        columns: {
            website: 'Website',
            server: 'Server',
            synchronized: 'Verknüpft',
            lastCheck: 'Letzte Überprüfung',
            protect: 'Schützen',
            siteHealth: 'Zustand der Website',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Filtermenü',
                filterAll: 'Alle',
                filterVulnerable: 'Anfällig',
                filterOutdated: 'Veraltet',
                filterNotOperable: 'Nicht funktionsfähig',
                filterProtected: 'Geschützt',
                filterUnmanaged: 'Nicht verwaltet',
                filterNotProtected: 'Nicht geschützt',
                filterSafe: 'Keine Probleme',
            },
            labelsPlaceholder: 'Alle Label',
        },
        Toolbar: {
            groupFilterMenu: 'Filter',
            groupSearch: 'Suchen',
            searchPlaceholder: 'Website suchen...',
            groupActions: 'Aktionen',
            groupUpdate: 'Updates installieren',
            groupProtect: 'Schützen',
            subGroupActions: 'Aktionen',
            buttons: {
                update: {
                    text: 'Updates installieren',
                    noSelection: 'Wählen Sie mindestens eine Website aus, um alle verfügbaren Updates zu installieren.',
                },
                refresh: {
                    text: 'Aktualisieren',
                    noSelection: 'Wählen Sie mindestens eine Website aus, die aktualisiert werden soll.',
                },
                protect: {
                    text: 'Schützen',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Labels anwenden',
                },
                settings: {
                    text: 'Einstellungen',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: '<b>{selectedInstallationTitle}</b> aktualisieren?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {Sie sind dabei, <b>#</b> WordPress-Website zu aktualisieren} other {Sie sind dabei, <b>#</b> WordPress-Websites zu aktualisieren}}',
                confirmButton: 'Aktualisieren',
                cancelButton: 'Abbrechen',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Schützen',
                cancelButton: 'Abbrechen',
            },
        },
        SiteHealthColumn: {
            outdated: 'Veraltet: {count}',
            safe: 'OK',
            notOperable: 'Nicht funktionsfähig',
            unsupportedAgent: 'Nicht unterstützte Agent-Version',
            vulnerabilities: 'Sicherheitslücken: {count}',
            lastCheckAtTooltip: 'Zuletzt geprüft: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'Die Einstellungen wurden gespeichert.',
            title: 'Update-Einstellungen',
            subTitle: {
                single: 'Website: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, one {# nicht funktionsfähige Website} other {# nicht funktionsfähige Websites}} and {unmanagedCount, plural, one {# nicht verwaltete Website} other {# nicht verwaltete Websites}} werden übersprungen.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {# nicht verwaltete Website wird übersprungen} other {# nicht verwaltete Websites werden übersprungen} }.',
            },
            buttonSave: 'Speichern',
            confirmationPopover: {
                confirmBtn: 'Speichern',
                description: {
                    single: 'Sie sind dabei, die Update-Einstellungen für die WordPress-Website {installationName} zu ändern. Möchten Sie fortfahren?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Updates',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Enable Vulnerability Protection',
        },
        InstallationSummary: {
            updateAll: 'Alle aktualisieren',
            updateSettings: 'Update-Einstellungen',
            upToDate: 'Auf aktuellem Stand',
            AssetSummary: {
                plugin: {
                    title: 'Plugins [{count}]',
                    update: 'Plugin-Updates installieren',
                },
                theme: {
                    title: 'Themes [{count}]',
                    update: 'Theme-Updates installieren',
                },
                safe: 'OK',
                vulnerable: 'Anfällig: {count}',
                outdated: 'Veraltet: {count}',
                vulnerabilities: 'Sicherheitslücken: {count}',
                isOutdated: 'Veraltet',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Update auf {version}',
                updateSettingsTitle: 'Update-Einstellungen',
                vulnerabilities: '{count, plural, one {# Sicherheitslücke} other {# Sicherheitslücken}}',
                isOutdated: 'Veraltet',
            },
            unsupportedAgentError: 'Die wp-agent-Version auf einem Server ist veraltet. Erforderliche Mindestversion: {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'Beim Suchen nach Sicherheitslücken auf der Website ist ein unbekannter Fehler aufgetreten.',
            hostname: 'Hostname des Servers:',
            ipAddress: 'Server-IP:',
            UpdateConfirmation: {
                title: 'Update bestätigen',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Abbrechen',
                continueButton: 'Aktualisieren',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Vulnerability protection by <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'Schutz vor Sicherheitslücken ist deaktiviert',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Enable protection',
            },
            ProtectInfoPopover: {
                label: 'Ist die Sicherheitslücke noch aktiv?',
                message: 'Für das Neutralisieren einer Sicherheitslücke sind spezifische Schutzregeln erforderlich. Gründe dafür, warum für eine bestimmte Sicherheitslücke möglicherweise keine Schutzregeln verfügbar sind:',
                noPatch: {
                    title: 'Regeln werden gerade ausgearbeitet',
                    description: 'Regeln für besonders schwerwiegende Sicherheitslücken werden normalerweise innerhalb weniger Stunden nach Bekanntwerden des Problems zur Verfügung gestellt. Das Erstellen von Regeln für weniger schwerwiegende Sicherheitslücken kann länger dauern.',
                },
                unlikelyExploited: {
                    title: 'Geringes Risiko',
                    description: 'Einige Sicherheitslücken haben nur minimale Auswirkungen oder es gibt keine wirklichen Methoden zur Ausnutzung der Schwachstelle. Da sie keine echte Bedrohung für Websites darstellen, sind Schutzregeln für sie nicht erforderlich.',
                },
                dbMatch: {
                    title: 'Nicht in Datenbank vorhanden',
                    description: 'Sicherheitslücken, die nur in der Wordfence-Datenbank vorhanden sind oder nicht mit entsprechenden Einträgen aus der Patchstack-Datenbank abgeglichen werden können, erhalten keine Schutzregeln.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'wenden Sie sich einfach an uns',
            },
        },
        WebsiteDrawer: {
            title: 'Details zur WordPress-Website',
            titleUpdateInProgress: '{title} wird aktualisiert',
            subtitleUpdateInProgress: 'Die ausgewählten Elemente werden aktualisiert',
            errors: {
                installationNotFound: "Informationen zur Installation mit der ID {id} konnten nicht geladen werden",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Abbrechen',
                        updateButton: 'Aktualisieren',
                        description: '{count, plural, one {Sie sind dabei, # Sicherheitslücke auf der Website <b>{website}</b> über ein Update zu beheben.} other {Sie sind dabei, # Sicherheitslücken auf der Website <b>{website}</b> über ein Update zu beheben.}} Nachdem das Update begonnen hat, kann es nicht mehr unterbrochen werden.',
                        updateConfirmationPluginCheckbox: '{count, plural, one {Plugin wieder aktivieren, das aufgrund der Sicherheitsrichtlinie deaktiviert wurde} other {Plugins wieder aktivieren, die aufgrund der Sicherheitsrichtlinie deaktiviert wurden}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {Ein anfälliges Plugin, das aufgrund Ihrer Sicherheitsrichtlinie deaktiviert wurde, wird automatisch wieder aktiviert, wenn die Sicherheitslücke durch das Update behoben wird.} other {Anfällige Plugins, die aufgrund Ihrer Sicherheitsrichtlinie deaktiviert wurden, werden automatisch wieder aktiviert, wenn die Sicherheitslücken durch das Update behoben werden.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Aktionen',
                            buttons: {
                                update: 'Aktualisieren',
                                processing: 'Wird verarbeitet',
                            },
                        },
                        filters: {
                            title: 'Filter',
                            groupFiltersTitle: 'Filter',
                            search: {
                                title: 'Suchen',
                                placeholder: 'Sicherheitslücke suchen...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Braucht Schutz',
                                filterStateIgnored: 'Ignoriert',
                                filterComponentHeader: 'Ort',
                            },
                        },
                    },
                    title: 'Sicherheitslücken',
                    columns: {
                        component: 'Ort',
                    },
                    emptyList: {
                        title: 'Alles ist sicher',
                        description: 'Auf dieser Website wurden keine bekannten Sicherheitslücken gefunden.',
                    },
                    waitingForUpdate: 'Keine Updates verfügbar',
                    updateTo: 'Update auf {availableVersion}',
					update: 'Aktualisieren',
                    disablePlugin: 'Plugin deaktivieren',
                    enablePlugin: 'Plugin aktivieren',
                    updateByProtect: 'Derzeit gibt es kein Update, mit dem diese Sicherheitslücke behoben werden kann.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {Sie sind dabei, # Asset auf der Website <b>{website}</b> zu aktualisieren.} other {Sie sind dabei, # Assets auf der Website <b>{website}</b> zu aktualisieren.}} Nachdem das Update begonnen hat, kann es nicht mehr unterbrochen werden.',
                        cancelButton: 'Abbrechen',
                        updateButton: 'Aktualisieren',
                    },
                    title: 'Veraltet',
                    toolbar: {
                        actions: {
                            title: 'Aktionen',
                            buttons: {
                                update: 'Aktualisieren',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Filter',
                            groupSearchTitle: 'Suchen',
                            search: {
                                title: 'Suchen',
                                placeholder: 'Veraltete Komponente finden...',
                            },
                        },
                    },
                    columns: {
                        name: 'Name',
                        type: 'Ort',
                    },
                    emptyList: {
                        title: 'Alles auf dem neuesten Stand',
                        description: 'Auf dieser Website sind die neuesten Versionen des WordPress-Cores sowie der Plugins und Themes installiert.',
                    },
                    updateTo: 'Update auf {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Schützen',
            disableProtectButton: 'Unprotect',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Labels anwenden',
        },
        DisableProtectionButton: {
            confirmButton: 'Unprotect',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Benachrichtigungen',
        listEmptyTitle: 'Bleiben Sie in Bezug auf wichtige Ereignisse auf dem Laufenden',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Alle als gelesen markieren',
        deleteNotification: 'Benachrichtigung löschen',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'Neue Lizenz aktiv',
                licenseLimitReached: 'Website-Limit erreicht',
                licenseExpired: 'Lizenz abgelaufen',
                licenseTerminated: 'Lizenz beendet',
                trialCreated: 'Testversion gestartet',
                trialExpired: 'Testzeitraum abgelaufen',
                trialExpiredSoon: 'Testversion endet am {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'Ihr neuer Schlüssel #{licenseKey} für die Lizenz "{licenseName}" ist jetzt aktiv.',
                licenseLimitReached: '{limit, plural, one {Mit Ihrer WP Guardian-Lizenz kann # Website verwaltet werden,} other {Mit Ihrer WP Guardian-Lizenz können # Websites verwaltet werden,}} {total, plural, one {derzeit ist jedoch # Website verknüpft.} other {derzeit sind jedoch # Websites verknüpft.}} Führen Sie ein Upgrade für Ihre Lizenz durch, um alle Ihre Websites zu verwalten.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Sie können jetzt die WP Guardian-Testversion verwenden. Während des Testzeitraums haben Sie für bis zu {sitesLimit, plural, one {# Website} other {# Websites}} vollen Zugriff auf alle WP Guardian-Funktionen.',
                trialExpired: "Ihr WP Guardian-Testzeitraum ist zu Ende. Wenn Sie das Produkt weiter nutzen möchten, erwerben Sie eine für Sie passende Lizenz. Oder lassen Sie uns über das Feedback-Formular oben wissen, warum wir Sie nicht überzeugen konnten.",
                trialExpiredSoon: 'Ihr WP Guardian-Testzeitraum läuft am {expirationDate} ab. Erwägen Sie den Kauf einer Lizenz, um das Produkt weiter zu nutzen.',
            },
            markAsRead: 'Als gelesen markieren',
            markAsUnread: 'Als ungelesen markieren',
        },
        settings: 'Einstellungen',
    },
    ConfirmNotificationsEmail: {
        title: 'Bestätigung der E-Mail-Benachrichtigung',
        error: 'Link ist abgelaufen',
        successMessage: 'E-Mail-Adresse für Benachrichtigungen wurde geändert.',
    },
    NotificationsSettingsDrawer: {
        title: 'Einstellungen für Benachrichtigungen',
        Content: {
            sendSectionTitle: 'Wo möchten Sie die Benachrichtigungen erhalten?',
            email: 'E-Mail-Adresse',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Welche Benachrichtigungen möchten Sie außerhalb des Produkts erhalten?',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'Benachrichtigungen zu Lizenzen',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'E-Mail-Adresse für Benachrichtigungen ändern',
        buttonSave: 'E-Mail senden',
        buttonCancel: 'Abbrechen',
        buttonEdit: 'Bearbeiten',
        email: 'E-Mail-Adresse',
        description: 'Wir senden Ihnen eine E-Mail mit der Anleitung zum Aktualisieren der E-Mail-Adresse für Benachrichtigungen.',
        successMessage: 'Eine E-Mail wurde an <b>{email}</b> gesendet. Folgen Sie der Anleitung in dieser E-Mail, um Ihre neue E-Mail-Adresse zu bestätigen.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Erkennung von Sicherheitslücken',
            description: 'Echtzeit-Updates über neue WordPress-Sicherheitslücken auf verknüpften Websites',
        },
        secureSites: {
            title: 'Sicherheit für WordPress-Websites',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Automatische Updates',
            description: 'Flexible Einstellungen für automatische Updates für Server und Websites',
        },
        safeUpdates: {
            title: 'Sichere Updates',
            description: 'Backups vor dem Update ermöglichen ein automatisches Rollback, falls Probleme auftreten',
        },
        notifications: {
            title: 'Benachrichtigungen und Website-Status',
            description: 'Immer über Ihre WordPress-Websites informiert sein',
        },
    },
    LicensePage: {
        benefits: {
            title: 'Schutz von WordPress-Websites',
        },
        ExpirationLabel: {
            noLicense: "Sie haben keine Lizenz",
            expiredLicense: 'Ihre Lizenz ist abgelaufen',
        },
        LicenseHeading: {
            welcome: 'Willkommen bei WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Wählen Sie Ihre Lizenz aus',
            trial: 'Wählen Sie Ihre Lizenz aus',
            trialLicenseExpired: 'Der Testzeitraum ist zu Ende',
        },
        LicenseDescription: {
            licenseKey: 'Lizenzschlüssel #{number}',
            nextUpdate: 'Nächste Aktualisierung des Lizenzschlüssels: {date}',
            expirationDate: 'Ablaufdatum: {date}',
            noLicense: 'Die kostenlose Version von WP Guardian enthält Informationen zu Sicherheitslücken, Websites müssen jedoch manuell aktualisiert werden. Kaufen Sie eine Lizenz, um alle WP Guardian-Funktionen zu nutzen.',
            trialJustStarted: 'Während des Testzeitraums haben Sie Zugriff auf alle WP Guardian-Funktionen. Nach Ablauf des Zeitraums wird Ihnen eine Lizenz empfohlen, die am besten zu Ihnen passt. Sie können WP Guardian anschließend mit bestimmten Einschränkungen auch kostenlos nutzen.',
            trial: '{sites, plural, one {Ihre Testversion ist zeitlich begrenzt und ermöglicht Ihnen, # WordPress-Website zu verwalten.} other {Ihre Testversion ist zeitlich begrenzt und ermöglicht Ihnen, # WordPress-Websites zu verwalten.}} Erwerben Sie eine Lizenz, wenn Sie weiterhin alle aktuellen und zukünftigen WP Guardian-Funktionen nutzen möchten.',
            limit: '{sites, plural, one {Mit Ihrer Lizenz können Sie bis zu # WordPress-Website verwalten.} other {Mit Ihrer Lizenz können Sie bis zu # WordPress-Websites verwalten.}}',
            unlimited: 'Mit Ihrer Lizenz können Sie unbegrenzt viele WordPress-Websites verwalten. {sites, plural, one {Der Basispreis der Lizenz beinhaltet die Verwaltung von # Website.} other {Der Basispreis der Lizenz beinhaltet die Verwaltung von # Websites.}} Alle Websites, die über diesen Wert hinausgehen, werden auf einer Pay-As-You-Go-Basis abgerechnet.',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'Details anzeigen',
            nextBillingDate: 'Nächstes Rechnungsdatum: {date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Aktuelle Auslastung:',
            sitesTotal: '{sites, plural, one {# Website insgesamt} other {# Websites insgesamt}}',
            allowedToManage: '{sites, plural, one {# Website kann verwaltet werden} other {# Websites können verwaltet werden}}',
            outOfLimit: '{sites, plural, one {# Website über dem Lizenzlimit} other {# Websites über dem Lizenzlimit}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: 'Für mehr als 1.000 Websites',
                feature1: '10.000 Websites im Preis enthalten',
                feature2: 'Pay-As-You-Go ab Website 10.001',
                feature3: 'Beinhaltet alle Funktionen',
                pricePerSite: '{price, number, ::currency/USD} ab Website Nummer 10.001',
            },
            standard: {
                title: 'Standard',
                label: 'Für bis zu 1.000 Websites',
                feature1: 'Prepaid-Modell',
                feature2: 'Beinhaltet alle Funktionen',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Diesen Tarif auswählen',
            changePlan: 'Tarif ändern',
            chooseAnotherOption: 'Wählen Sie eine andere Option aus, um Ihren Tarif upzugraden',
        },
        LicenseList: {
            columns: {
                license: 'Lizenz',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Kaufen',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Erneut aktivieren',
            details: 'Details',
        },
        chooseLicenseNow: 'Wählen Sie Ihre Lizenz aus',
        licensesTitle: 'Wählen Sie die Lizenz aus, die Ihren Anforderungen entspricht',
        faq: {
            title: 'Häufig gestellte Fragen',
            question1: {
                question: 'Was geschieht nach Ablauf des Testzeitraums?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'Was passiert, wenn ich die Lizenzlimits überschreite?',
                answer: 'Websites, die über das Lizenzlimit hinaus zu WP Guardian hinzugefügt werden, werden als nicht verwaltet gekennzeichnet. Für solche Websites sind weiterhin aktuelle Informationen zu Sicherheitslücken verfügbar, sie können jedoch nur manuell aktualisiert werden. Alle anderen Funktionen von WP Guardian sind für diese Websites nicht verfügbar.',
            },
            question4: {
                question: 'Wie funktioniert das Pay-As-You-Go-Modell?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'Sie erhalten Ihre Lizenz in <kaLink>Key Administrator</kaLink>, <manage2Link>Manage2</manage2Link> oder von Ihrem Ansprechpartner im Vertrieb.',
            standard: {
                title: '{sites, plural, one {Sie wählen eine Standardlizenz für # Website aus} other {Sie wählen eine Standardlizenz für # Websites aus}}',
                licenseLimit: '{sites, plural, one {Die Lizenz enthält nur # Website} other {Die Lizenz enthält nur # Websites}}',
                feature1: 'Die Standardlizenz umfasst eine bestimmte Anzahl von Websites ohne die Möglichkeit der Abrechnung via Pay-As-You-Go.',
                feature2: 'Alle Websites, die über das Lizenzlimit hinaus hinzugefügt werden, werden als nicht verwaltet gekennzeichnet.',
                feature3: 'Nicht verwaltete Websites können nicht über WP Guardian verwaltet werden, wenn Sie Ihre Lizenz nicht aktualisieren.',
                feature4: 'Nicht verwaltete Websites werden verwaltet, sobald das Lizenzlimit erhöht wurde oder ein freier Platz in der aktuellen Lizenz verfügbar wird.',
                feature5: 'Wenn eine Website länger als ein paar Tage in WP Guardian verknüpft ist, wird sie auf Ihre Lizenznutzung angerechnet.',
                feature6: 'Nicht funktionierende oder anderweitig nicht verfügbare Websites werden nicht auf Ihr Lizenzlimit angerechnet.',
            },
            unlimited: {
                title: 'Sie haben die unlimitierte Lizenz ausgewählt',
                feature1: 'Die unlimitierte Lizenz beinhaltet 10.000 Websites im Basispreis.',
                feature2: 'Alle Websites, die nach den ersten 10.000 hinzugefügt werden, werden auf einer Pay-As-You-Go-Basis abgerechnet.',
                feature3: 'Wenn eine Website länger als ein paar Tage in WP Guardian verknüpft ist, wird sie auf Ihre Lizenznutzung angerechnet.',
                feature4: 'Nicht funktionierende oder anderweitig nicht verfügbare Websites werden nicht auf Ihr Lizenzlimit angerechnet.',
            },
        },
        UpgradeButton: {
            upgrade: 'Upgrade',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'Beinhaltet alle Funktionen',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Lizenz kaufen',
            upgradeLicense: 'Lizenz-Upgrade',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Zurück zum Startbildschirm',
    },
    LabelsDrawer: {
        title: 'Labels verwalten',
        LabelsList: {
            create: 'Label erstellen',
            edit: 'Bearbeiten',
            delete: 'Löschen',
            noSelectedLabels: 'Wählen Sie mindestens ein Label aus, das gelöscht werden soll.',
            columns: {
                title: 'Label',
            },
            search: {
                title: 'Suchen',
                placeholder: 'Label suchen',
            },
            emptyList: {
                title: 'Label erstellen',
                description: "Sie verwenden keine Labels zur Unterscheidung Ihrer Server und Websites. Möchten Sie einige von ihnen hervorheben?",
            },
            filteredEmptyList: {
                title: 'Keine mit dem Filter übereinstimmende Elemente gefunden',
                description: 'Ändern Sie den gesuchten Labeltext und versuchen Sie es erneut',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Löschen',
            confirmation: '{count, select, 1 {Wenn Sie ein Label löschen, wird es von allen Elementen entfernt, für die es eingesetzt wurde.} other {Wenn Sie Labels löschen, werden sie von allen Elementen entfernt, für die sie eingesetzt wurden.}} Möchten Sie fortfahren?',
        },
        CreateLabelDrawer: {
            title: 'Label erstellen',
        },
        EditLabelDrawer: {
            title: 'Label bearbeiten',
            notFound: 'Label nicht gefunden',
        },
        LabelForm: {
            save: 'Speichern',
            cancel: 'Abbrechen',
            fields: {
                title: {
                    label: 'Labelname',
                    description: 'Max. 30 Zeichen',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'LABEL ANWENDEN',
        noSelection: 'Wählen Sie mindestens ein Element in der Liste aus, um Labels hinzuzufügen oder zu entfernen.',
        buttons: {
            apply: 'Anwenden',
            create: 'Neues Label erstellen',
            manage: 'Labels verwalten',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'alle',
            tooltip: 'Sichere Updates sind aktiviert',
            components: {
                core: 'core',
                themes: 'themes',
                plugins: 'Plugins',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'Sicherheitsupdates',
                major: 'Alle Updates',
                disabled: "Nicht installieren",
                notSet: 'Website-Ebene',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {Ihre Lizenz umfasst # verwaltete Website} other {Ihre Lizenz umfasst # verwaltete Websites}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {# nicht verwaltete Website wird übersprungen} other {# nicht verwaltete Websites werden übersprungen}}',
        operableNotManagedSitesMessage: 'Nicht verwaltete Websites werden von WP Guardian ignoriert, weil sie Ihre Lizenzlimits überschreiten. Solche Websites werden bei der Durchführung von Massenaktionen übersprungen. ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, one {# nicht funktionsfähige Website} other {# nicht funktionsfähige Websites}} and {unmanagedCount, plural, one {# nicht verwaltete Website} other {# nicht verwaltete Websites}} werden übersprungen',
        notOperableNotManagedSitesMessage: 'Die von Ihnen gewählte Aktion wird für solche Websites nicht durchgeführt, da sie entweder nicht funktionsfähig sind oder Ihre Lizenzlimits überschreiten. Solche Websites werden bei der Durchführung von Massenaktionen übersprungen.',
        managedSitesWillBePerformed: 'Der ausgewählte Vorgang wird für alle verwalteten Websites ohne Einschränkungen durchgeführt.',
        chooseLicenseButton: 'Lizenz auswählen',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'Bitte erwerben Sie eine Lizenz, wenn Sie die erweiterten Funktionen von WP Guardian weiterhin nutzen möchten.',
            chooseLicenseButton: 'Lizenz auswählen',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Auf Website-Ebene festgelegte Updates',
            autoupdate: 'Automatische Updates',
            manual: 'Manuelle Updates',
            custom: 'Benutzerdefinierte Updates',
        },
        unmanaged: {
            title: 'Nicht verwaltet',
            tooltip: 'Nicht verwaltete Websites werden von WP Guardian ignoriert, weil Ihre Lizenzlimits überschritten werden. Für Websites, die aufgrund von Lizenzbeschränkungen als nicht verwaltet markiert sind, werden weiterhin aktuelle Informationen über ihre Schwachstellen bereitgestellt. Diese Websites können nur manuell aktualisiert werden. Sie werden als verwaltet markiert, wenn das Lizenzlimit erhöht wird oder Platz in der aktuellen Lizenz verfügbar wird.',
        },
        menu: {
            remove: 'Entfernen',
            filter: 'Nach diesem Label filtern',
        },
    },
    Cuttable: {
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
    },
};