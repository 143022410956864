// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useNavigate } from 'react-router-dom';
import { useAcceptInvitationMutation } from '@platform360/security-dashboard/web/mutations';
import { useCallback, useRef } from 'react';
import Loading from '@platform360/libs/shared-web/components/Loading';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const Invitation = () => {
    const didInitialise = useRef(false);
    const { errorToast } = useToaster();
    const translate = useTranslate('security-dashboard.Invitation');
    const navigate = useNavigate();
    const navigateHome = useCallback(() => navigate('/security-dashboard/servers'), [navigate]);

    const { mutate: activateLicense } = useAcceptInvitationMutation({
        onSuccess: navigateHome,
        onError: () => {
            errorToast(translate('activationFailure'));
            navigateHome();
        },
    });

    useEffectOnce(() => {
        // Avoid duplicate renders, especially in strict mode.
        if (didInitialise.current) {
            return;
        }

        didInitialise.current = true;

        activateLicense();
    });

    return <Loading />;
};
