// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentType, ReactElement, useEffect } from 'react';
import { AxiosError, isAxiosError } from 'axios';
import { useTeamInvitationsQuery } from '@platform360/accounts/web/queries';
import { DpaDialog } from '@platform360/accounts/web/profile/DpaDialog';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { toGeneralError } from '@platform360/libs/shared-web/helpers/apiErrors';

export type WithDpaAcceptedProps = {
    onClose: () => void;
    children: ReactElement;
};

const isDpaNotAcceptedError = (error: AxiosError<{ type: string }> | null): boolean =>
    isAxiosError(error) &&
    error.response?.status === 403 &&
    error.response.data.type === 'dpaRequired';

const WithDpaAccepted = ({ onClose, children }: WithDpaAcceptedProps) => {
    const { isLoading, error, refetch } = useTeamInvitationsQuery();

    const isDpaNotAccepted = isDpaNotAcceptedError(error);
    const { errorToast } = useToaster();

    useEffect(() => {
        if (error) {
            if (!isAxiosError(error)) {
                return;
            }

            if (Number(error.response?.status) > 500) {
                errorToast(toGeneralError(error));
            }
        }
    }, [error, errorToast]);

    if (isLoading) return null;

    if (isDpaNotAccepted) {
        return (
            <DpaDialog
                isOpen={isDpaNotAccepted}
                initialValue={false}
                onAccept={refetch}
                onDecline={refetch}
                onClose={onClose}
            />
        );
    }
    return children;
};

const withDpaAccepted = <T extends ClosableProps>(WrappedComponent: ComponentType<T>) => {
    const Wrapper = (props: T) => (
        <WithDpaAccepted onClose={props.onClose}>
            <WrappedComponent {...props} />
        </WithDpaAccepted>
    );
    Wrapper.displayName = `withDpaAccepted(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    return Wrapper;
};

export default withDpaAccepted;
