// Copyright 2024. WebPros International GmbH. All rights reserved.

import { isCertErrorCode } from '@platform360/libs/common/cert-error-codes';
import { ServerLastRequestResultType } from '@platform360/server-inventory/web/types';

/**
 * See ServerDto on backend for details.
 * TODO need to move this parsing to Server Inventory backend
 *
 * @param requestResultType
 * @param lastRequestResultDetail
 */
const parseRequestResultType = (
    requestResultType: string,
    lastRequestResultDetail: string | null,
): ServerLastRequestResultType => {
    const [generalState, ...rest] = requestResultType.split(':');
    const errorCode = rest[1] || rest[0] || '';
    const errorDetail = lastRequestResultDetail || 'Unknown error';

    if (generalState === 'ok') {
        return {
            status: 'ok',
        };
    }

    if (isCertErrorCode(errorCode)) {
        return {
            status: 'certificateError',
            errorCode,
            errorDetail,
        };
    }

    if (requestResultType === 'error:ApiClient:forbidden') {
        return {
            status: 'connectionBlocked',
            errorCode,
            errorDetail,
        };
    }

    return {
        status: 'connectionError',
        errorCode,
        errorDetail,
    };
};

export default parseRequestResultType;
