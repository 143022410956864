// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, LabelIntent } from '@plesk/ui-library';

export type CvssScoreProps = {
    cvssScore: string;
};

const getIntent = (score: number): LabelIntent => {
    switch (true) {
        case score >= 8.0:
            return 'danger';

        case score >= 4.0:
            return 'warning';

        default:
            return 'success';
    }
};

const CvssScore = ({ cvssScore }: CvssScoreProps) => {
    const scoreIntent = cvssScore ? getIntent(parseFloat(cvssScore)) : 'inactive';

    return (
        <Label
            size="lg"
            view="light"
            style={{
                gap: '1px',
                width: cvssScore ? '42px' : 'auto',
                whiteSpace: 'nowrap',
                justifyContent: 'center',
            }}
            intent={scoreIntent}
        >
            {cvssScore}
        </Label>
    );
};

export default CvssScore;
