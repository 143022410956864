// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { Paragraph, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import RecheckConnectionButton from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/RecheckConnectionButton';
import StatusMessageButtons from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo/StatusMessages/ServerStatusMessage/StatusMessageButtons';

export type InactiveStatusMessageProps = {
    serverId: number;
    lastCheckConnection: DateTime | null;
};

const InactiveUnreachableStatusMessage = ({
    serverId,
    lastCheckConnection,
    ...props
}: InactiveStatusMessageProps) => {
    const translate = useTranslate(
        'server-inventory.Servers.ViewServer.GeneralInfo.StatusMessages.InactiveUnreachableStatusMessage',
    );
    const formatDateTime = useFormatDateTime();

    const lastCheckMessage = lastCheckConnection && (
        <Paragraph>
            {translate('lastCheckMessage', {
                lastCheckConnection: formatDateTime(lastCheckConnection, 'yyyy-LL-dd HH:mm'),
            })}
        </Paragraph>
    );

    return (
        <StatusMessage intent="warning" data-type="inactive-status-message" {...props}>
            <Paragraph>{translate('message')}</Paragraph>
            {lastCheckMessage}
            <Paragraph>{translate('fixDescription')}</Paragraph>
            <StatusMessageButtons>
                <RecheckConnectionButton
                    serverId={serverId}
                    title={translate('recheckConnectionButton')}
                />
            </StatusMessageButtons>
        </StatusMessage>
    );
};

export default InactiveUnreachableStatusMessage;
