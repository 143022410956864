// Copyright 2024. WebPros International GmbH. All rights reserved.

import { InstallationLabelTypes } from '@platform360/security-dashboard/shared/label-type';
import FiltersForm from '@platform360/security-dashboard/web/components/FiltersForm';
import { SitesHealthFilter } from '@platform360/security-dashboard/web/types';
import { FormFieldCheckbox, FormFieldSelect, SelectOption, setIn } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useState } from 'react';

import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import useTranslateLabel from '@platform360/security-dashboard/web/hooks/useTranslateLabel';

type FilterPanelProps = {
    onFiltersChange: (filters: SitesHealthFilter[], labelIds: number[]) => void;
    healthFilter: SitesHealthFilter[];
    labelIds: number[];
    onReset: () => void;
};

type HealthFilter = Partial<Record<SitesHealthFilter, boolean>>;

type FormValues = {
    healthFilter: HealthFilter;
    labelIds: number[];
};

const FilterPanel = ({ healthFilter, labelIds, onFiltersChange, onReset }: FilterPanelProps) => {
    const translate = useTranslate('security-dashboard.Installations.FiltersPanel');
    const translateLabel = useTranslateLabel();

    const [formValues, setFormValues] = useState<FormValues>({
        healthFilter: healthFilter.reduce<HealthFilter>(
            (acc, key) => ({ ...acc, [key]: true }),
            {},
        ),
        labelIds,
    });
    const { data: labelsData } = useLabelsQuery();
    const labels = (labelsData?.data ?? []).filter(({ type }) => !!InstallationLabelTypes[type]);

    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const filterValues = [
        {
            name: SitesHealthFilter.Vulnerable,
            title: translate('groupFilterMenu.filterVulnerable'),
        },
        {
            name: SitesHealthFilter.Outdated,
            title: translate('groupFilterMenu.filterOutdated'),
        },
        {
            name: SitesHealthFilter.NotOperable,
            title: translate('groupFilterMenu.filterNotOperable'),
        },
        {
            name: SitesHealthFilter.Protected,
            title: translate('groupFilterMenu.filterProtected'),
        },
        {
            name: SitesHealthFilter.NotProtected,
            title: translate('groupFilterMenu.filterNotProtected'),
        },
        {
            name: SitesHealthFilter.Unmanaged,
            title: translate('groupFilterMenu.filterUnmanaged'),
        },
        {
            name: SitesHealthFilter.Safe,
            title: translate('groupFilterMenu.filterSafe'),
        },
    ];

    const handleHealthFilterChange = (key: string) => {
        const filter = key.replace('healthFilter[', '').replace(']', '');
        const analyticsHandlers: Record<SitesHealthFilter, () => void> = {
            [SitesHealthFilter.Vulnerable]: () => analyticsEvents.wpFilterVulnerableClick(),
            [SitesHealthFilter.Outdated]: () => analyticsEvents.wpFilterOutdatedClick(),
            [SitesHealthFilter.Protected]: () => analyticsEvents.wpFilterProtectedClick(),
            [SitesHealthFilter.NotProtected]: () => analyticsEvents.wpFilterNotProtectedClick(),
            [SitesHealthFilter.NotOperable]: () => analyticsEvents.wpFilterUnknownClick(),
            [SitesHealthFilter.Unmanaged]: () => analyticsEvents.wpFilterUnmanagedClick(),
            [SitesHealthFilter.Safe]: () => analyticsEvents.wpFilterOkClick(),
        };
        analyticsHandlers[filter as SitesHealthFilter]();
    };

    const onFieldChange = (key: keyof FormValues, value: FormValues[typeof key]) => {
        setFormValues(setIn(formValues, key, value));
        if (key !== 'labelIds') {
            handleHealthFilterChange(key);
        }
    };

    return (
        <FiltersForm<FormValues>
            values={formValues}
            onReset={onReset}
            role="menu"
            onFieldChange={onFieldChange}
            onSubmit={(values) => {
                const filters = Object.entries(values.healthFilter).reduce<SitesHealthFilter[]>(
                    (acc, [key, value]) => {
                        if (value) {
                            return [...acc, key as SitesHealthFilter];
                        }
                        return acc;
                    },
                    [],
                );
                onFiltersChange(filters, values.labelIds);
            }}
        >
            {filterValues.map((value) => (
                <FormFieldCheckbox
                    key={value.name}
                    name={`healthFilter[${value.name}]`}
                    label={value.title}
                />
            ))}
            {labels.length > 0 && (
                <FormFieldSelect
                    multiple
                    name={'labelIds'}
                    placeholder={translate('labelsPlaceholder')}
                >
                    {labels.map((l) => (
                        <SelectOption key={l.id} value={l.id}>
                            {translateLabel(l).title}
                        </SelectOption>
                    ))}
                </FormFieldSelect>
            )}
        </FiltersForm>
    );
};

export default FilterPanel;
