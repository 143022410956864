// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { confirmNotificationEmail } from '@platform360/security-dashboard/web/api/notifications-settings';

type MutationOptions = {
    onSuccess?: () => void;
    onError?: () => void;
};

const useConfirmNotificationsEmailMutation = ({ onSuccess, onError }: MutationOptions = {}) =>
    useMutation({
        mutationFn: confirmNotificationEmail,
        onSuccess,
        onError,
        meta: {
            defaultErrorHandler: false,
        },
    });

export default useConfirmNotificationsEmailMutation;
