// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCallback } from 'react';
import { Drawer } from '@plesk/ui-library';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useLocale } from '@platform360/libs/shared-web/locale';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import { useQueryClient } from '@tanstack/react-query';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import styles from './BuyLicenseDrawer.module.css';

type BuyLicenseDrawerProps = {
    isOpen: boolean;
    onClose: () => Promise<void>;
};

const SCRIPT_ID = 'cleverbridge-sdk';

const BuyLicenseDrawer = ({ isOpen, onClose }: BuyLicenseDrawerProps) => {
    const translate = useTranslate('security-dashboard.BuyLicenseDrawer');
    const { product } = useParams<{ product: string }>();
    const {
        securityDashboard: { cleverBridgeUrl },
    } = useConfig();

    const queryClient = useQueryClient();

    const currentUser = useCurrentUser();
    const [locale] = useLocale();

    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    useEffectOnce(() => {
        analyticsEvents.wpLicensePageRetailPurchaseDrawerShown(product ?? '');
    });

    const initIframe = useCallback(() => {
        const initialize = () => {
            const cleverBridge = window.Cleverbridge;
            if (!cleverBridge) {
                return;
            }
            const { hostname } = new URL(cleverBridgeUrl);

            cleverBridge.reset();
            cleverBridge.init('cleverbridge-block', {
                debug: true,
                storeDomain: hostname,
                showLoadAnimation: 'true',
                iframe: {
                    mode: 'embed',
                    width: '100%',
                    height: '100%',
                    style: 'border:0;',
                },
            });
            const language = locale;
            const encodedTeamGuid = btoa(currentUser.teamGuid);
            const deliveryFirstname = encodeURIComponent(currentUser.firstName);
            const deliveryLastname = encodeURIComponent(currentUser.lastName);
            const deliveryEmail = encodeURIComponent(currentUser.email);
            cleverBridge.handleCTAClick(
                `${cleverBridgeUrl}/purl-wpg-${product}?scope=checkout&cfg=base_iframe_or&x-activation-data=${encodedTeamGuid}&language=${language}&deliveryFirstname=${deliveryFirstname}&deliveryLastname=${deliveryLastname}&deliveryEmail=${deliveryEmail}`,
            );

            cleverBridge.on('purchasecomplete', () => {
                if (product) {
                    analyticsEvents.wpLicensePurchaseCompleted(product);
                }
            });
            cleverBridge.on('error', () => {
                analyticsEvents.wpCleverBridgeError();
            });
        };

        // Check if the script is already loaded
        if (document.getElementById(SCRIPT_ID)) {
            initialize();
            return;
        }

        const script = document.createElement('script');

        script.defer = true;
        script.id = SCRIPT_ID;
        script.onload = initialize;
        script.src =
            'https://static-cf.cleverbridge.com/mycontent/1/cbFrameCheckout-client-v1.05.js';

        document.body.appendChild(script);
    }, [
        analyticsEvents,
        currentUser.email,
        currentUser.firstName,
        currentUser.lastName,
        currentUser.teamGuid,
        locale,
        product,
        cleverBridgeUrl,
    ]);

    const closeDrawer = () => {
        analyticsEvents.wpLicensePageRetailPurchaseDrawerClosed(product ?? '');
        void queryClient.invalidateQueries({
            queryKey: useLicenseQuery.getQueryKey(),
        });
        void onClose();
    };

    return (
        <Drawer
            className={styles.drawer}
            isOpen={isOpen}
            onClose={closeDrawer}
            title={translate('title')}
        >
            <div ref={initIframe} className={styles.iframeContainer} id="cleverbridge-block" />
        </Drawer>
    );
};

export default BuyLicenseDrawer;
