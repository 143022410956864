// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentProps } from 'react';
import { Icon } from '@plesk/ui-library';
import {
    InstallationComponent,
    InstallationComponents,
} from '@platform360/security-dashboard/shared/installation-component';

export const INSTALLATION_COMPONENT_ICONS_MAP: {
    [key in InstallationComponent]: ComponentProps<typeof Icon>['name'];
} = {
    [InstallationComponents.core]: 'wordpress',
    [InstallationComponents.plugin]: 'gear',
    [InstallationComponents.theme]: 'monitoring',
};
