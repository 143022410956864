// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type KbInstructionsFixLinkProps = {
    href?: string;
};

const KbInstructionsFixLink = ({ href }: KbInstructionsFixLinkProps) => {
    const translate = useTranslate('components');
    if (!href) {
        return undefined;
    }

    return translate('KbInstructionsFixLink', {
        a: (parts) => (
            <a href={href} target="_blank" rel="noopener noreferrer">
                {parts}
            </a>
        ),
    });
};

export default KbInstructionsFixLink;
