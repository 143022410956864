// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentType, ReactElement } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { isRedirectUrlAllowed } from '@platform360/auth/web/components/Callback/isRedirectUrlAllowed';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

export type WithGuestProps = {
    children: ReactElement;
};

export const WithGuest = ({ children }: WithGuestProps) => {
    const { isAuthorized } = useAuth();
    const [searchParams] = useSearchParams();
    const { allowLoginRedirectsTo } = useConfig();

    if (isAuthorized) {
        const redirectUrl = searchParams.get('redirectUrl');
        const to =
            redirectUrl && isRedirectUrlAllowed(redirectUrl, allowLoginRedirectsTo)
                ? redirectUrl
                : '/';
        return <Navigate to={to} />;
    }

    return children;
};

const withGuest = <T extends Record<string, unknown>>(Component: ComponentType<T>) => {
    const C = (props: T) => (
        <WithGuest>
            <Component {...props} />
        </WithGuest>
    );

    C.displayName = `withGuest(${Component.displayName || Component.name || 'Component'})`;

    return C;
};

export default withGuest;
