// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

import { Button, Text, Icon, Media } from '@plesk/ui-library';

import { CardListItem } from '@platform360/security-dashboard/web/components/Layout/Header/CardList/CardList';
import useModal from '@platform360/libs/shared-web/helpers/useModal';

import styles from './QuickStartCard.module.css';
import { MouseEvent } from 'react';

type QuickStartCardProps = {
    onHide: () => void;
};

export const QuickStartCard = ({ onHide }: QuickStartCardProps) => {
    const translate = useTranslate('security-dashboard.Layout.Header.QuickStartCard');

    const [_, setModalOpen] = useModal('quick-start');

    return (
        <div
            className={styles.wrapper}
            onClick={() => {
                setModalOpen(true);
            }}
        >
            <CardListItem className={styles.panel}>
                <div className={styles.titleContainer}>
                    <Text className={styles.title}>{translate('title')}</Text>
                    <Button
                        className={styles.closeButton}
                        onClick={(e: MouseEvent) => {
                            e.stopPropagation();
                            onHide();
                        }}
                        intent="primary"
                        icon="cross-mark"
                        data-type="quick-start-widget-hide"
                    />
                </div>
                <Media
                    className={styles.body}
                    title={translate('ctaBlockTitle')}
                    image={<Icon name="sliders" intent="info" size="32" />}
                >
                    {translate('ctaBlockText')}
                </Media>
            </CardListItem>
        </div>
    );
};
