// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import monitoring, { SetupResponse } from '@platform360/monitoring/web/api/monitoring';
import { AxiosError } from 'axios';

type UseMonitoringSetupProps = {
    scenario?: string;
    onSuccess?: (response: SetupResponse) => void;
    onError?: (error: AxiosError) => void;
    defaultErrorHandler?: boolean;
};

const useMonitoringSetup = ({
    scenario,
    onSuccess,
    onError,
    defaultErrorHandler,
}: UseMonitoringSetupProps) =>
    useMutation({
        mutationFn: () => monitoring.setup(scenario),
        onSuccess,
        onError,
        meta: {
            defaultErrorHandler,
        },
    });

export default useMonitoringSetup;
