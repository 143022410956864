// Copyright 2024. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import styles from './RecommendedLicense.module.css';
import { Button, Heading, Icon, Label, Text } from '@plesk/ui-library';
import { useLicenseTranslate } from '@platform360/security-dashboard/web/hooks/useLicenseTranslate';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useNavigate } from 'react-router-dom';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { getRecommendedRetailLicense } from '@platform360/security-dashboard/web/helpers/getRecommendedRetailLicense';
import { useKaKey } from '@platform360/security-dashboard/web/hooks/useKaKey';
import { AVAILABLE_RETAIL_LICENSES } from '@platform360/security-dashboard/web/constants/licenses';
import UpgradeButton from '@platform360/security-dashboard/web/components/LicensePage/UpgradeButton';
import { License } from '@platform360/security-dashboard/web/types';

type RecommendedLicenseProps = {
    totalInstallationsCount: number;
    license: License | null;
};
const RecommendedLicense = ({ totalInstallationsCount, license }: RecommendedLicenseProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.LicensePage.RecommendedLicense');
    const translateLicense = useLicenseTranslate();
    const navigate = useNavigate();

    const {
        isDeactivated: isCurrentLicenseDeactivated,
        isLoading: isKaKeyLoading,
        isError: isKaKeyError,
    } = useKaKey();

    if (!license || license.sitesLimitHard === null) {
        return null;
    }

    const licenseLimit = license.sitesLimitHard;

    const isPurchase = license.trial || license.status === 'terminated';
    const isRecommended = isPurchase || totalInstallationsCount > licenseLimit;

    const licenseToDisplay = isRecommended
        ? getRecommendedRetailLicense(totalInstallationsCount)
        : AVAILABLE_RETAIL_LICENSES.find(({ sites }) => sites > licenseLimit);

    if (!licenseToDisplay) {
        return null;
    }

    const handleBuyLicense = () => {
        analyticsEvents.wpLicensePageLicenseRetailRecommendedBuyClicked(
            licenseToDisplay.cleverbridgeKey,
        );
        navigate(`/security-dashboard/license-page/buy/${licenseToDisplay.cleverbridgeKey}`);
    };

    const handleUpgradeLicense = () => {
        analyticsEvents.wpLicensePageLicenseRetailRecommendedUpgradeClicked(
            licenseToDisplay.cleverbridgeKey,
        );
    };

    const renderHeading = () => {
        if (isRecommended) {
            return (
                <>
                    <Heading level={2}>
                        {translateLicense(licenseToDisplay.cleverbridgeKey)}
                    </Heading>
                    <Label intent="success" view="light" size="lg">
                        {translate('recommendedToYou')}
                    </Label>
                </>
            );
        }
        return (
            <>
                <Text>{translate('needMoreSites')}</Text>
                <Heading level={2}>
                    {translate('upgradeTo', { sites: licenseToDisplay.sites })}
                </Heading>
            </>
        );
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.hiddenContainer ?? '']:
                    isKaKeyLoading || isKaKeyError || isCurrentLicenseDeactivated,
            })}
            data-type="recommended-license"
        >
            <div className={styles.top}>{renderHeading()}</div>
            <div className={styles.feature}>
                <Icon intent="info" name="tools" />
                <Text>{translate('allFeaturesIncluded')}</Text>
            </div>
            <div className={styles.feature}>
                <Icon intent="info" name="calendar-clock" />
                <Text>{translate('monthlySubscription')}</Text>
            </div>
            <div className={styles.bottom}>
                <div>
                    <Text data-type={'price-displayed'} className={styles.price}>
                        {translate('pricePerMonth', {
                            price: licenseToDisplay.pricePerMonth,
                        })}
                    </Text>
                    <Text fontSize="sm">
                        {translate('pricePerSite', {
                            price: licenseToDisplay.pricePerSite,
                        })}
                    </Text>
                </div>
                {isPurchase ? (
                    <Button intent="primary" size="lg" onClick={handleBuyLicense}>
                        {translate('buyLicense')}
                    </Button>
                ) : (
                    <UpgradeButton
                        productNamePrefix={licenseToDisplay.productNamePrefix}
                        size="lg"
                        onClick={handleUpgradeLicense}
                    >
                        {translate('upgradeLicense')}
                    </UpgradeButton>
                )}
            </div>
        </div>
    );
};

export default RecommendedLicense;
