// Copyright 2024. WebPros International GmbH. All rights reserved.

import { getAllClients } from '@platform360/server-inventory/web/api/clients';
import { getAllDomains } from '@platform360/server-inventory/web/api/domains';
import { getServers } from '@platform360/server-inventory/web/api/servers';
import { Client, Domain, Server } from '@platform360/server-inventory/web/types';
import { ApiListResponse } from '@platform360/libs/shared-web/typings/api';
import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseGlobalSearchQueryVariables = {
    filter: string;
};

export type UseGlobalSearchQueryData = {
    clients: ApiListResponse<Client>;
    domains: ApiListResponse<Domain>;
    servers: ApiListResponse<Server>;
};

const useGlobalSearchQuery = createQuery<UseGlobalSearchQueryVariables, UseGlobalSearchQueryData>({
    queryName: 'server-inventory/useGlobalSearchQuery',
    fetcher: async ({ variables: { filter } }) => {
        const variables = { pageSize: 3, filter };
        const [clients, domains, servers] = await Promise.all([
            getAllClients({ variables }),
            getAllDomains({ variables }),
            getServers({ variables }),
        ]);
        return { clients, domains, servers };
    },
    useQuery: (options) =>
        useQuery({
            ...options,
            refetchOnWindowFocus: false,
        }),
});

export default useGlobalSearchQuery;
