// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ApplicationSettings } from '@platform360/libs/shared-web/application-settings/context';
import HeaderAddon from '@platform360/security-dashboard/web/components/Layout/HeaderAddon';
import Footer from '@platform360/security-dashboard/web/components/Layout/Footer';
import Logo from '@platform360/security-dashboard/web/components/Layout/Logo';
import QuickStartAddon from '@platform360/security-dashboard/web/components/Layout/QuickStartAddon';
import LicenseAddon from '@platform360/security-dashboard/web/components/Layout/LicenseAddon';
import NotFoundPage from '@platform360/security-dashboard/web/components/NotFoundPage';
import AuthLayout from '@platform360/security-dashboard/web/components/AuthLayout';
import { Navigate } from 'react-router-dom';
import LicenseName from '@platform360/security-dashboard/web/components/Layout/LicenseName';
import Sidebar from '@platform360/security-dashboard/web/components/AuthLayout/Sidebar';
import { useSignUpMutation } from '@platform360/security-dashboard/web/mutations';
import { legalDocumentsLoader } from '@platform360/security-dashboard/web/helpers/legalDocumentsLoader';
import {
    cookieModify,
    cookieWithdraw,
    CookiesList,
} from '@platform360/app/web/components/CookieManager';
import { lazy } from 'react';
import { TermsOfUseLink } from '@platform360/security-dashboard/web/components/TermsOfUseLink';

const Eula = lazy(async () => ({ default: (await legalDocumentsLoader()).Eula }));
const Privacy = lazy(async () => ({ default: (await legalDocumentsLoader()).Privacy }));
const Terms = lazy(async () => ({ default: (await legalDocumentsLoader()).Terms }));
const DataProcessingAgreement = lazy(async () => ({
    default: (await legalDocumentsLoader()).DataProcessingAgreement,
}));
const CookiesContent = lazy(async () => ({
    default: (await legalDocumentsLoader()).CookiesContent,
}));

const wpsdApplicationSettings: ApplicationSettings = {
    document: {
        title: 'WP Guardian',
        favicon: '/wpsd.ico',
        darkThemeDataAttribute: 'dark',
    },
    logo: <Logo />,
    logoRightAddon: <LicenseName />,
    homePage: {
        isPrivate: true,
        component: <Navigate to={'/security-dashboard/servers'} />,
    },
    notFoundPage: <NotFoundPage />,
    header: {
        apiTokensEnabled: true,
        businessProfileEnabled: false,
        baseUrl: '/security-dashboard/servers',
        globalSearchEnabled: false,
        dotsMenuEnabled: false,
        mainMenuEnabled: false,
        addon: <HeaderAddon />,
        userMenuAddons: [
            <LicenseAddon key="license-addon" />,
            <QuickStartAddon key="quick-start-addon" />,
        ],
    },
    footer: <Footer />,
    authLayout: AuthLayout,
    authSidebar: Sidebar,
    auth: {
        signUpMutation: useSignUpMutation,
        branding: 'security-dashboard',
        termsOfUseLink: <TermsOfUseLink />,
        preferredSignup: false,
        areConsentsShown: true,
        signUpEmailFieldDescriptionShown: false,
        shouldNotifyLogout: false,
    },
    defaultLoginRedirect: '/security-dashboard/servers',
    zendeskChat: {
        title: 'Chat with WP Guardian!',
        apiKey: 'b9c5db3b-c176-446f-9c1e-1c1f41a0be7d',
        department: '[Plesk] Plesk 360 Chat',
        welcomeMessage: (name: string) =>
            `Hello, ${name}! You have contacted WP Guardian Technical Support. One of our representatives will be with you shortly. Thank you for your patience.`,
        concierge: {
            title: 'WP Guardian Support',
            avatar: 'wpsd-avatar.png',
        },
    },
    legalPage: {
        dpa: <DataProcessingAgreement />,
        eula: <Eula />,
        privacy: <Privacy />,
        terms: <Terms />,
        cookiesContent: <CookiesContent cookiesList={<CookiesList />} />,
    },
    cookieManager: {
        cookieModify,
        cookieWithdraw,
    },
    licencesModule: {
        simpleView: true,
        hideDocumentationLinks: true,
        homePageLink: '/security-dashboard/servers',
    },
    usePlatform360LocaleCode: false,
};

export default wpsdApplicationSettings;
