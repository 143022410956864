// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    ACTIVE,
    EXPIRED,
    EXPIRING_SOON,
    SUSPENDED,
    TERMINATED,
} from '@platform360/licenses/web/api/ka/const/license';
import { License } from '@platform360/licenses/web/api/ka/responseTypes/License';

export const hide = () => false;

export const own = (keyInfo: License) => keyInfo.ownLicense;

export const hasBilling = (keyInfo: License) =>
    keyInfo.renewalCostGross !== null &&
    keyInfo.renewalCurrency !== null &&
    keyInfo.billingPeriod !== null &&
    keyInfo.nextBillingDate !== null;

export const active = (keyInfo: License) =>
    keyInfo.status === ACTIVE || keyInfo.status === EXPIRING_SOON;

export const terminated = (keyInfo: License) => keyInfo.status === TERMINATED;

export const suspended = (keyInfo: License) => keyInfo.status === SUSPENDED;

export const expired = (keyInfo: License) => keyInfo.status === EXPIRED;

export const trial = (keyInfo: License) => keyInfo.isTrial;

export const hasReports = (keyInfo: License) =>
    keyInfo.ips !== null && keyInfo.reportedVersion !== null;
