// Copyright 2024. WebPros International GmbH. All rights reserved.

const isUrl = (uri: string): boolean => {
    try {
        new URL(uri);
        return true;
    } catch {
        return false;
    }
};

/**
 * Check is redirect URL is allowed.
 * 1. Relative redirects are always allowed.
 * 2. Redirects matching white list are allowed.
 */
export const isRedirectUrlAllowed = (url: string, allowLoginRedirectsTo: string[]): boolean => {
    if (!isUrl(url)) {
        // 0. relative (internal) urls are always allowed.
        return true;
    }

    for (const whiteUrl of allowLoginRedirectsTo) {
        if (!whiteUrl) {
            // 1. skip empty and nullable urls
            continue;
        }
        // 2. remove trailing slashes from whitelist URL
        const trimmedWhiteUrl = whiteUrl.replace(/\/+$/, '');
        // 3. OK if url equals trimmed whitelist URL
        // 4. OK if url starts with whitelist URL (note trailing '/')
        if (url === trimmedWhiteUrl || url.startsWith(`${trimmedWhiteUrl}/`)) {
            return true;
        }
    }
    return false;
};
