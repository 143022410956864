// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Item as UIItem } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from '@platform360/licenses/web/WizardCommon/style.module.css';
import Img from '@platform360/licenses/web/components/Img';
import { HTMLAttributes } from 'react';
import ExtensionCost from '../ExtensionCost';
import classNames from 'classnames';
import Currency from '@platform360/licenses/web/types/currency';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';

export type ExtensionProps = HTMLAttributes<unknown> & {
    catalogCode: string;
    link: string;
    storeLink: string;
    name: string;
    minPrice: number;
    onClick: (id: string) => void;

    currency: Currency;
    hasSubset: boolean;
    period: BillingPeriod;
};

const Extension = ({
    catalogCode,
    link,
    storeLink,
    name,
    minPrice,
    onClick,

    currency,
    hasSubset,
    period,

    // must be passed to the descendant
    className,
    ...other
}: ExtensionProps) => {
    const translate = useTranslate();
    const classes = classNames(styles.suggestionItem, className);
    return (
        <UIItem
            {...other}
            key={catalogCode}
            className={classes}
            icon={<Img code={catalogCode} />}
            title={
                link ? (
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.suggestionTitle}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {name}
                    </a>
                ) : (
                    name
                )
            }
        >
            <div className={styles.suggestionCost}>
                <ExtensionCost
                    currency={currency}
                    hasSubset={hasSubset}
                    minPrice={minPrice}
                    period={period}
                />
            </div>
            <Button
                className={styles.suggestionButton}
                onClick={onClick}
                data-type="Product--button"
            >
                {translate('select')}
            </Button>
        </UIItem>
    );
};

export default Extension;
