// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import { SaveInstallationSettingsRequest } from '@platform360/security-dashboard/web/api/installations';
import UpdatesTab from '@platform360/security-dashboard/web/components/UpdatesTab';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { Button, ContentLoader, Drawer, StatusMessage, Tab, Tabs, setIn } from '@plesk/ui-library';
import { ReactNode, useEffect, useState } from 'react';
import { UpdateSettings } from '@platform360/security-dashboard/web/types';

import { toGeneralError } from '@platform360/libs/shared-web/helpers/apiErrors';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';

export type SettingsFormValues = {
    updates: {
        core: UpdateSettingsOption | 'parent';
        plugins: UpdateSettingsOption | 'parent';
        themes: UpdateSettingsOption | 'parent';
        safeUpdate: SafeUpdateOption | 'parent';
    };
};

export type SettingsDrawerProps = ClosableProps & {
    formValuesDefaultState: SettingsFormValues;
    subtitle: ReactNode;
    onSave: (values: SaveInstallationSettingsRequest) => void;
    isSaving: boolean;
    confirmationPopoverMessage: ReactNode;
    isMassOperation?: boolean;
    parentSettings?: UpdateSettings;
    isError: boolean;
    isLoading: boolean;
    // eslint-disable-next-line deprecation/deprecation
    error?: UntypedAxiosError | null;
};

const SettingsDrawer = ({
    isOpen,
    onClose,
    formValuesDefaultState,
    subtitle,
    onSave,
    isSaving,
    confirmationPopoverMessage,
    isMassOperation,
    parentSettings,
    isError,
    isLoading,
    error,
    ...props
}: SettingsDrawerProps) => {
    const [formValues, setFormValues] = useState<SettingsFormValues>(formValuesDefaultState);

    useEffect(() => {
        setFormValues(formValuesDefaultState);
    }, [formValuesDefaultState]);

    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const translate = useTranslate('security-dashboard.Installations.SettingsDrawer');

    const [activeTabId, setActiveTabId] = useState(0);

    const tabs = [
        {
            title: translate('tabs.updates'),
            children: (
                <UpdatesTab
                    key={'tabs.updates'}
                    isMassOperation={isMassOperation}
                    isInstallationLevel
                    parentSettings={parentSettings}
                />
            ),
        },
    ];

    const sendAmplitudeSaveEvent = () => {
        const isDefaultSettings = Object.keys(formValuesDefaultState.updates).every(
            (key) =>
                formValues.updates[key as keyof SettingsFormValues['updates']] ===
                formValuesDefaultState.updates[key as keyof SettingsFormValues['updates']],
        );

        if (!isDefaultSettings) {
            analyticsEvents.wpSglAutoupdateSettingsSave(formValues.updates.safeUpdate);
        }
    };

    const handleSave = () => {
        sendAmplitudeSaveEvent();

        const {
            updates: { core, plugins, themes, safeUpdate },
        } = formValues;
        onSave({
            updates: {
                core: core === 'parent' ? null : core,
                plugins: plugins === 'parent' ? null : plugins,
                themes: themes === 'parent' ? null : themes,
                safeUpdate: safeUpdate === 'parent' ? null : safeUpdate,
            },
        });
    };

    const onFieldChange = (key: string, value: SettingsFormValues[keyof SettingsFormValues]) =>
        setFormValues(setIn(formValues, key, value));

    const renderContent = () => {
        if (isError) {
            return <StatusMessage intent="danger">{toGeneralError(error)}</StatusMessage>;
        }

        if (isLoading) {
            return <ContentLoader />;
        }

        return tabs.map(({ children }, tabId) => tabId === activeTabId && children);
    };

    return (
        <Drawer<SettingsFormValues>
            data-type={'settings-drawer'}
            title={translate('title')}
            subtitle={subtitle}
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
            form={{
                onFieldChange,
                vertical: false,
                applyButton: false,
                submitButton: false,
                values: formValues,
                additionalButtons: [
                    <ConfirmationPopover
                        key="1"
                        onClick={handleSave}
                        target={
                            <Button
                                intent="primary"
                                size="lg"
                                key="1"
                                state={isSaving ? 'loading' : undefined}
                            >
                                {translate('buttonSave')}
                            </Button>
                        }
                        actionButtonText={translate('confirmationPopover.confirmBtn')}
                        placement="top-left"
                    >
                        {confirmationPopoverMessage}
                    </ConfirmationPopover>,
                ],
            }}
            tabs={
                <Tabs active={activeTabId}>
                    {tabs.map(({ title }, id) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Tab onClick={() => setActiveTabId(id)} key={id.toString()} title={title} />
                    ))}
                </Tabs>
            }
            {...props}
        >
            {renderContent()}
        </Drawer>
    );
};

export default SettingsDrawer;
