// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteApiToken } from '@platform360/accounts/web/api/api-tokens';
import useApiTokensQuery from '@platform360/accounts/web/queries/useApiTokensQuery';
import { useState } from 'react';

type UseDeleteApiTokenMutationOptions = {
    onSuccess: (id: number) => void;
};

const useDeleteApiTokenMutation = ({ onSuccess }: UseDeleteApiTokenMutationOptions) => {
    const queryClient = useQueryClient();
    const [deletingTokenIds, setDeletingTokenIds] = useState<number[]>([]);

    const apiMutation = useMutation({
        mutationFn: async (id: number) => {
            try {
                setDeletingTokenIds((prevState) => [...prevState, id]);
                await deleteApiToken(id);
            } finally {
                setDeletingTokenIds((prevState) =>
                    prevState.filter((apiTokenId) => apiTokenId !== id),
                );
            }

            return id;
        },
        onSuccess: async (apiTokenId) => {
            await queryClient.cancelQueries({
                queryKey: useApiTokensQuery.getQueryKey(),
            });
            useApiTokensQuery.setQueriesData(
                queryClient,
                useApiTokensQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return [];
                    }

                    return cache.filter(({ id }) => id !== apiTokenId);
                },
            );

            onSuccess(apiTokenId);
        },
    });

    return { ...apiMutation, deletingTokenIds };
};

export default useDeleteApiTokenMutation;
