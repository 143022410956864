// Copyright 2024. WebPros International GmbH. All rights reserved.

export type Consent = DisabledConsent | EnabledConsent;

export type DisabledConsent = {
    enabled: false;
    type: string;
};

export type EnabledConsent = {
    enabled: true;
    type: string;
    text: string;
    data: Record<string, unknown>;
};

type ConsentWithTranslationKeys = {
    type: string;
    titleLocale: string;
    textLocale: string;
};

const consents: ConsentWithTranslationKeys[] = [
    {
        type: 'send_platform360_announce',
        titleLocale: 'sendPlatform360Announce.title',
        textLocale: 'sendPlatform360Announce.text',
    },
];

export default consents;
