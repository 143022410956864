// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mitigateVulnerabilities } from '@platform360/security-dashboard/web/api/installations';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import {
    useInstallationInfoQuery,
    useInstallationsQuery,
} from '@platform360/security-dashboard/web/queries';

type MutationOptions = {
    onSuccess?: () => void;
    onSettled?: () => void;
};

const useMitigateInstallationVulnerabilitiesMutation = ({
    onSuccess,
    onSettled,
}: MutationOptions = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: mitigateVulnerabilities,
        onSuccess: async () => {
            refetchList(useInstallationsQuery.getQueryKey());

            await queryClient.invalidateQueries({
                queryKey: useInstallationInfoQuery.getQueryKey(),
            });

            if (onSuccess) {
                onSuccess();
            }
        },
        onSettled,
    });
};

export default useMitigateInstallationVulnerabilitiesMutation;
