// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Skeleton, Heading } from '@plesk/ui-library';

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { ResetDataLink } from './ResetDataLink';

type FilteredByVulnerabilityTitleProps = {
    searchValue: string;
    onReset: () => void;
};

export const FilteredByVulnerabilityTitle = ({
    searchValue,
    onReset,
}: FilteredByVulnerabilityTitleProps) => {
    const translate = useTranslate('security-dashboard.FilteredDataListTitle');
    const { data } = useVulnerabilitiesQuery({
        variables: {
            filter: searchValue,
            vulnerabilityComponentFilter: [],
            vulnerabilityStateFilter: [],
            page: 1,
            pageSize: 1,
        },
    });

    const resolvedName = data ? (
        (data.data[0]?.title ?? searchValue)
    ) : (
        <Skeleton component="span" width="300px" />
    );

    return (
        <Heading level={3}>
            {translate('searchByVulnerability', {
                searchValue: resolvedName,
            })}{' '}
            <ResetDataLink onReset={onReset} />
        </Heading>
    );
};
