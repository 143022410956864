// Copyright 2024. WebPros International GmbH. All rights reserved.

import { CamelCase } from 'type-fest';
import camelCase from 'lodash/camelCase';

/**
 * Make a union from a list of types.
 */
// export type UnionOf<T extends unknown[]> = T extends [infer Head, ...infer Tail]
//     ? Head | UnionOf<Tail>
//     : never;
export type UnionOf<T extends unknown[]> = T[number];

type KeysObject<T extends string[]> = T extends [infer Head, ...infer Tail]
    ? Tail extends string[]
        ? Head extends string
            ? Record<CamelCase<Head, { preserveConsecutiveUppercase: false }>, Head> &
                  KeysObject<Tail>
            : never
        : never
    : Record<string, never>;

const keysObject = <T extends string[]>(...keys: T): KeysObject<T> => {
    const result = {};
    for (const key of keys) {
        // @ts-expect-error
        result[camelCase(key)] = key;
    }
    return result as KeysObject<T>;
};

const uncapitalize = ([first, ...rest]: string): string =>
    first ? `${first.toLowerCase()}${rest.join('')}` : '';

type MakeEnumResult<Name extends string, T extends string[]> = Record<
    Uncapitalize<`${Name}Values`>,
    T
> &
    Record<`is${Name}`, (x: unknown) => x is UnionOf<T>> &
    Record<`${Name}s`, KeysObject<T>>;

/**
 * Make Enumeration type and helpers from the list of string constants.
 * Returns a tuple with the following elements:
 * - variable of Enumeration type.
 * - array with all Enumeration values.
 * - type guard that checks that value is a member of Enumeration.
 * - Object-accessor for Enumeration values.
 * @example
 *     const { colorValues, isColor, Colors } = makeEnum(
 *         'Color',
 *         'red',
 *         'green',
 *         'light-blue',
 *     );
 *     export type Color = UnionOf<typeof colorValues>;
 *     console.log(colorValues); // ['red', 'green', 'light-blue'];
 *     const x = 'red';
 *     if (isColor(x)) {
 *         const _colorX: Color = x;
 *     }
 *     console.log(Colors.red, Colors.green, Colors.lightBlue); // ['red', 'green', 'light-blue'];
 */
export const makeEnum = <Name extends string, T extends string[]>(
    name: Name,
    ...values: T
): MakeEnumResult<Name, T> => {
    const typeGuard = (x: unknown): x is UnionOf<T> => values.includes(x as string);
    const keysObj = keysObject<T>(...values);
    return {
        [uncapitalize(`${name}Values`)]: values,
        [`is${name}`]: typeGuard,
        [`${name}s`]: keysObj,
    } as MakeEnumResult<Name, T>;
};
