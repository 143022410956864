// Copyright 2024. WebPros International GmbH. All rights reserved.

import buyExtensionSelector from '@platform360/licenses/web/units/extensions/selectors/buyExtension';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';
import doRequest from '@platform360/licenses/web/api/doRequest';
import finishProcessing from '@platform360/licenses/web/units/extensions/actions/buyExtension/finishProcessing';
import flushItem from '@platform360/licenses/web/units/extensions/actions/flushItem';
import initialization from '@platform360/licenses/web/units/extensions/actions/initialization';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '@platform360/licenses/web/store/types';
import { Action } from 'redux';
import startProcessing from '@platform360/licenses/web/units/extensions/actions/buyExtension/startProcessing';
import delay from '@platform360/licenses/web/helpers/delay';
import create from '@platform360/licenses/web/api/ka/extensions/create';

const submit =
    (): ThunkAction<void, StoreState, unknown, Action<string>> => async (dispatch, getState) => {
        dispatch(startProcessing());
        const state = getState();
        const {
            selectedProduct,
            selectedPaygrProducts = {},
            selectedSubscription: subscriptionId,
        } = buyExtensionSelector(state);

        const {
            license: { id: licenseId },
        } = upsellSelector(state);

        const items = Object.entries(selectedPaygrProducts).map(([productId, quantity]) => ({
            productId: Number(productId),
            quantity,
        }));

        const extension = {
            keyNumber: licenseId,
            subscriptionId,
            items: [{ productId: Number(selectedProduct), quantity: 1 }],
        };

        extension.items = extension.items.concat(items);

        await doRequest(create(extension));

        dispatch(finishProcessing());

        // so that the component has time to redraw and display a notification
        await delay(500);
        dispatch(flushItem());
        await dispatch(initialization(licenseId));
    };

export default submit;
