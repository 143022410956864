// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './SelectInstallationMethodFormField.module.css';
import { cloneElement, isValidElement, ReactNode } from 'react';
import { FormField, Select } from '@plesk/ui-library';

export type SelectInstallationMethodFormFieldProps = {
    name: string;
    label: ReactNode;
    required: boolean;
    children: ReactNode;
    fields: Record<string, ReactNode>;
};

export const SelectInstallationMethodFormField = ({
    name,
    label,
    required,
    children,
    fields,
    ...props
}: SelectInstallationMethodFormFieldProps) => (
    <FormField name={name} label={label} required={required} {...props}>
        {({ getName, getValue, setValue, isDisabled }) => {
            const field = Object.entries(fields).find(([key]) => key === getValue())?.[1];
            return (
                <div className={styles.container}>
                    <Select
                        name={getName()}
                        value={getValue()}
                        onChange={(value) => setValue(value as string)}
                        className={styles.select}
                        disabled={isDisabled()}
                    >
                        {children}
                    </Select>
                    {isValidElement<{ className: string }>(field)
                        ? cloneElement(field, { className: styles.secondField })
                        : field}
                </div>
            );
        }}
    </FormField>
);
