// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';

export const request = ({ url, method = 'get', params, data, headers, timeout = 30000 }) => {
    const config = {
        url,
        method,
        params,
        data,
        headers,
        timeout,
    };
    return apiClient.request(config).then((res) => res.data);
};

export const authorizedRequest = (params) => request(params);
