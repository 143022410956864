// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useOperationsSummaryQuery } from '@platform360/security-dashboard/web/queries';
import { Badge, Button, Label } from '@plesk/ui-library';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';

const OperationsButton = () => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const { data } = useOperationsSummaryQuery();
    const getModalUrl = useGetModalUrl();
    const taskManagerModalUrl = getModalUrl('task-manager');
    const unreadNotificationsCount = data?.unreadNotificationsCount || 0;

    return (
        <Badge
            hidden={unreadNotificationsCount === 0}
            intent="danger"
            label={
                <Label data-type="summary-operations-count">
                    {unreadNotificationsCount > 9 ? '9+' : unreadNotificationsCount}
                </Label>
            }
        >
            <Button
                component={DrawerLink}
                ghost
                icon="list"
                to={taskManagerModalUrl}
                onClick={() => analyticsEvents.wpGloTaskManagerClick(unreadNotificationsCount)}
                data-type="open-task-manager-button"
            />
        </Badge>
    );
};

export default OperationsButton;
