// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { enableMfa } from '@platform360/accounts/web/api/mfa';

type UseEnableMfaMutationOptions = {
    onSuccess?: () => void;
};
export const useEnableMfaMutation = (options: UseEnableMfaMutationOptions = {}) =>
    useMutation({ mutationFn: enableMfa, ...options });
