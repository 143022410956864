// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useParams, Navigate } from 'react-router-dom';

const AcquisitionSourceRedirect = () => {
    const { acquisitionSource } = useParams<{ acquisitionSource: string }>();

    return <Navigate to={`/auth/signup?acquisitionSource=${acquisitionSource || ''}`} />;
};

export default AcquisitionSourceRedirect;
