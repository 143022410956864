// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useState } from 'react';
import { useVerifyNotificationsEmailMutation } from '@platform360/security-dashboard/web/mutations';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ChangeableNotificationsEmailDialog, Values } from './ChangeableNotificationsEmailDialog';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const initialValues: Values = {
    email: '',
};

export type ChangeableNotificationsEmailProps = {
    email: string;
};

export const ChangeableNotificationsEmail = ({ email }: ChangeableNotificationsEmailProps) => {
    const translate = useTranslate('security-dashboard.ChangeableNotificationsEmail');
    const { successToast } = useToaster();
    const [isEdit, setIsEdit] = useState(false);
    const translateValidationErrors = useTranslateValidationErrors();
    const { mutate, error, reset } = useVerifyNotificationsEmailMutation({
        onSuccess: ({ email }) => {
            setIsEdit(false);
            successToast(translate('successMessage', { email }), {
                'data-type': 'update-notifications-email-success',
            });
        },
    });

    const handleEdit = () => {
        reset();
        setIsEdit(true);
    };
    const handleSave = (values: Values) => {
        mutate({
            email: values.email ?? '',
        });
    };
    const handleCancel = () => {
        setIsEdit(false);
    };

    return (
        <>
            {email}
            <Button
                ghost
                icon="pencil"
                data-type="edit-notifications-email-button"
                onClick={() => handleEdit()}
                title={translate('buttonEdit')}
            />
            <ChangeableNotificationsEmailDialog
                isOpen={isEdit}
                values={initialValues}
                onSave={handleSave}
                onCancel={handleCancel}
                errors={translateValidationErrors(getValidationErrors(error))}
            />
        </>
    );
};
