// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveServersSettings } from '@platform360/security-dashboard/web/api/servers';
import {
    useServersQuery,
    useServerSettingsQuery,
} from '@platform360/security-dashboard/web/queries';

const useSaveServersSettingsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: saveServersSettings,
        onSuccess: async (_, { serverIds }) => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: useServersQuery.getQueryKey(),
                }),
                ...serverIds.map((serverId) =>
                    queryClient.invalidateQueries({
                        queryKey: useServerSettingsQuery.getQueryKey({ serverId }),
                    }),
                ),
            ]);
        },
    });
};

export default useSaveServersSettingsMutation;
