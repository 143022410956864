// Copyright 2024. WebPros International GmbH. All rights reserved.

import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

const NotFound = () => {
    const { notFoundPage } = useApplicationSettings();

    return notFoundPage;
};

export default NotFound;
