// Copyright 2024. WebPros International GmbH. All rights reserved.

import KbInstructionsFixLink from '@platform360/libs/shared-web/components/KbInstructionsFixLink';

export const InvalidCertificateKBArticleHref =
    'https://support.plesk.com/hc/en-us/articles/4408516491154';

const InvalidCertificateKBArticleLink = () => (
    <KbInstructionsFixLink href={InvalidCertificateKBArticleHref} />
);

export default InvalidCertificateKBArticleLink;
