// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import { ServerStatus } from '@platform360/security-dashboard/web/types';

const STATES_MAP: { [key in ServerStatus]: ReactNode | null } = {
    ok: null,
    installingAgent: null,
    agentInstallationError: null,
    // eslint-disable-next-line deprecation/deprecation
    connectionError: <Translate content="security-dashboard.Servers.state.connectionError" />,
    // eslint-disable-next-line deprecation/deprecation
    connectionBlocked: <Translate content="security-dashboard.Servers.state.connectionBlocked" />,
};

type GetServerErrorOptions = {
    status: ServerStatus;
    unsupportedAgent: boolean;
};

export const getServerError = ({
    status,
    unsupportedAgent,
}: GetServerErrorOptions): ReactNode | null =>
    STATES_MAP[status] ??
    (unsupportedAgent ? (
        // eslint-disable-next-line deprecation/deprecation
        <Translate content="security-dashboard.Servers.SiteHealthColumn.unsupportedAgent" />
    ) : null);
