// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import {
    transformError,
    TransformedError,
    ActivateLicenseDialog,
} from '@platform360/licenses/web/components/ActivateLicense/common';
import { AxiosError } from 'axios';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import {
    PRODUCT_WP_GUARDIAN_1,
    PRODUCT_WP_GUARDIAN_5,
    PRODUCT_WP_GUARDIAN_10,
    PRODUCT_WP_GUARDIAN_20,
    PRODUCT_WP_GUARDIAN_30,
    PRODUCT_WP_GUARDIAN_50,
    PRODUCT_WP_GUARDIAN_1_RETAIL,
    PRODUCT_WP_GUARDIAN_5_RETAIL,
    PRODUCT_WP_GUARDIAN_10_RETAIL,
    PRODUCT_WP_GUARDIAN_30_RETAIL,
    PRODUCT_WP_GUARDIAN_50_RETAIL,
    PRODUCT_WP_GUARDIAN_100,
    PRODUCT_WP_GUARDIAN_500,
    PRODUCT_WP_GUARDIAN_1000,
    PRODUCT_WP_GUARDIAN_10000,
    PRODUCT_WP_GUARDIAN_UNLIMITED_30,
    PRODUCT_WP_GUARDIAN_UNLIMITED,
} from '@platform360/libs/shared-web/constants/products';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useActivateLicenseMutation } from '@platform360/licenses/web/mutations/useActivateLicenseMutation';

type ActivateLicenseProps = {
    product:
        | typeof PRODUCT_WP_GUARDIAN_1
        | typeof PRODUCT_WP_GUARDIAN_5
        | typeof PRODUCT_WP_GUARDIAN_10
        | typeof PRODUCT_WP_GUARDIAN_20
        | typeof PRODUCT_WP_GUARDIAN_30
        | typeof PRODUCT_WP_GUARDIAN_50
        | typeof PRODUCT_WP_GUARDIAN_1_RETAIL
        | typeof PRODUCT_WP_GUARDIAN_5_RETAIL
        | typeof PRODUCT_WP_GUARDIAN_10_RETAIL
        | typeof PRODUCT_WP_GUARDIAN_30_RETAIL
        | typeof PRODUCT_WP_GUARDIAN_50_RETAIL
        | typeof PRODUCT_WP_GUARDIAN_100
        | typeof PRODUCT_WP_GUARDIAN_500
        | typeof PRODUCT_WP_GUARDIAN_1000
        | typeof PRODUCT_WP_GUARDIAN_10000
        | typeof PRODUCT_WP_GUARDIAN_UNLIMITED_30
        | typeof PRODUCT_WP_GUARDIAN_UNLIMITED;
};

const productDisplayNames: Record<string, string> = {
    [PRODUCT_WP_GUARDIAN_1_RETAIL]: 'WP Guardian 1 site',
    [PRODUCT_WP_GUARDIAN_5_RETAIL]: 'WP Guardian 5 sites',
    [PRODUCT_WP_GUARDIAN_10_RETAIL]: 'WP Guardian 10 sites',
    [PRODUCT_WP_GUARDIAN_30_RETAIL]: 'WP Guardian 30 sites',
    [PRODUCT_WP_GUARDIAN_50_RETAIL]: 'WP Guardian 50 sites',
};

const ActivateLicense = ({ product }: ActivateLicenseProps) => {
    const [searchParams] = useSearchParams();
    const code = String(searchParams.get('code'));
    const { email, teamGuid } = useCurrentUser();
    const [error, setError] = useState<TransformedError | undefined>(undefined);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const securityDashboardAnalyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.ActivateLicense');
    const navigate = useNavigate();

    const {
        mutate: activateLicense,
        isError,
        isPending: isLoading,
    } = useActivateLicenseMutation({
        onSuccess: () => {
            securityDashboardAnalyticsEvents.setSecurityDashboardPartnerCustomer(true);
            securityDashboardAnalyticsEvents.wpLicenseActivationSuccessful(product);
            setIsRedirecting(true);
            navigate('/security-dashboard/servers');
        },
        onError: (err: AxiosError) => {
            securityDashboardAnalyticsEvents.wpLicenseActivationFailed(product);
            setError(transformError(err));
        },
    });

    useEffect(() => {
        securityDashboardAnalyticsEvents.wpLicenseActivationDialogShown(product);
    }, [securityDashboardAnalyticsEvents, product]);

    useEffect(() => {
        if (!isError) {
            setError(undefined);
        }
    }, [isError]);

    const handleConfirm = () => {
        securityDashboardAnalyticsEvents.wpLicenseActivationConfirmed(product);
        activateLicense({ activationCode: code, uid: teamGuid });
    };

    const handleCancel = () => {
        securityDashboardAnalyticsEvents.wpLicenseActivationCancelled(product);
        navigate('/security-dashboard/servers');
    };

    const productName = productDisplayNames[product] || product;

    return (
        <ActivateLicenseDialog
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            isLoading={isLoading}
            isRedirecting={isRedirecting}
            error={error}
            userEmail={email}
            product={product}
            dataType={'security-dashboard-activate-license'}
            locale={{
                activateLicenseDialog: {
                    cancelButton: translate('SecurityDashboardActivateLicense.cancelButton'),
                    confirmButton: translate('SecurityDashboardActivateLicense.confirmButton'),
                    description: translate('SecurityDashboardActivateLicense.description', {
                        mailto: (chunk) => <a href={`mailto:${email}`}>{chunk}</a>,
                        userEmail: email,
                        product: productName,
                    }),
                    errors: {
                        linkError: translate('SecurityDashboardActivateLicense.errors.linkError'),
                        unknownError: translate(
                            'SecurityDashboardActivateLicense.errors.unknownError',
                        ),
                    },
                    fixableErrorDescription: translate(
                        'SecurityDashboardActivateLicense.fixableErrorDescription',
                    ),
                    gotItButton: translate('SecurityDashboardActivateLicense.gotItButton'),
                    loaderText: translate('SecurityDashboardActivateLicense.loaderText'),
                    nonFixableErrorDescription: translate(
                        'SecurityDashboardActivateLicense.nonFixableErrorDescription',
                    ),
                    redirectText: translate('SecurityDashboardActivateLicense.redirectText'),
                    retryButton: translate('SecurityDashboardActivateLicense.retryButton'),
                    title: translate('SecurityDashboardActivateLicense.title'),
                },
                cancelConfirmation: {
                    cancelButton: translate('CancelConfirmation.cancelButton'),
                    continueButton: translate('CancelConfirmation.continueButton'),
                    description: translate('CancelConfirmation.description', {
                        product: productName,
                    }),
                },
            }}
        />
    );
};

export default ActivateLicense;
