// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';

export type MainMenuItem = {
    to: string;
    children: ReactNode;
    target?: HTMLAttributeAnchorTarget;
    isExternal?: boolean;
    beta?: boolean;
};

const useMainMenuItems = (): MainMenuItem[] => {
    const translate = useTranslate('Page.MainMenu');

    return [
        {
            to: '/si',
            children: translate('servers'),
        },
        {
            to: '/websites',
            children: translate('websites'),
        },
        {
            children: translate('licenses'),
            to: '/licensing',
        },
        {
            children: translate('monitoring'),
            to: '/monitoring',
        },
    ];
};

export default useMainMenuItems;
