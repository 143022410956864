// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'Esiste già un invito per questa e-mail.',
        invitationMemberExist: 'L\'utente di questa email è già registrato come membro del team.',
        bounceOrComplaint: 'Questo indirizzo e-mail non esiste o il messaggio è stato bloccato come spam. Contatta il team di supporto di Plesk 360.',
        emailsAreEqual: 'L\'email deve essere diversa da quella corrente.',
        consentKeeperNotAvailable: 'Impossibile verificare la firma dell\'Accordo per l\'elaborazione dei dati. Ti preghiamo di riprovare più tardi.',
    },
    profile: {
        AccountRemoval: {
            removeAccountButton: 'Elimina account',
            removeAccount: 'Eliminare il tuo account <b>{email}</b> da Plesk 360?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{Vuoi eliminare il tuo account <b>{email}</b> e # membro dell\'organizzazione da Plesk 360?} other{Vuoi eliminare il tuo account <b>{email}</b> e # membri dell\'organizzazione da Plesk 360?}}',
            removeAccountDescription1: 'I tuoi account di servizio 360 e le tue licenze 360 verranno eliminati assieme al tuo account. Inoltre, il tuo indirizzo e-mail e altri dati personali da te inseriti verranno eliminati da Plesk 360.',
            removeAccountDescription2: 'Le tue licenze relative a Plesk e alle estensioni non verranno influenzate dall\'eliminazione del tuo account Plesk 360, ma non sarai più in grado di gestirle all\'interno di Plesk 360.',
            removingAccount: 'Rimozione dell\'account...',
            loading: 'Caricamento in corso...',
        },
        ChangePassword: {
            title: 'Cambio della password',
            submit: 'Modifica password',
            labelCurrentPassword: 'Password attuale',
            labelNewPassword: 'Nuova password',
            labelRepeatPassword: 'Conferma password',
            forgotPassword: 'Password dimenticata?',
            successMessage: 'La password è stata modificata.',
            passwordMatch: 'Le password inserite non coincidono.',
        },
        ViewProfile: {
            title: 'Profilo personale',
            changePasswordLink: 'Modifica password',
            removeAccountLink: 'Elimina',
            emailAddressField: 'Indirizzo e-mail',
            nameField: 'Nome',
            timezoneField: 'Fuso Orario',
            mfaField: 'Autenticazione multifattoriale',
            mfaEnable: 'Invia l\'email di adesione',
            mfaDisable: 'Disabilita MFA',
            mfaCheckEmail: 'Verifica l\'email per completare l\'adesione',
            mfaConfirmation: 'Vuoi disabilitare la MFA?',
            mfaDisableSuccessToast: 'Autenticazione multifattoriale disabilitata.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'Disabilita MFA',
                description: 'Disabilitare l\'autenticazione multifattoriale?',
                currentPassword: 'Password attuale',
                otp: 'Codice monouso',
                otpDescription: 'Codice monouso per l\'autenticazione a due fattori fornito dall\'app di autenticazione.',
                buttonSave: 'Conferma',
                buttonCancel: 'Annulla',
            },
        },
        ChangeableEmail: {
            buttonEdit: 'Modificare',
            dialogTitle: 'Cambia email',
            description: 'Ti invieremo un\'email con le istruzioni su come aggiornare il tuo indirizzo.',
            email: 'Nuova email',
            currentPassword: 'Password attuale',
            otp: 'Codice monouso',
            otpDescription: 'Codice monouso usato nell\'ambito dell\'autenticazione a due fattori. Puoi trovarlo nella tua applicazione di autenticazione.',
            consentsTitle: 'Gestione dei consensi',
            buttonSave: 'Invia',
            buttonCancel: 'Annulla',
            successMessage: 'Abbiamo inviato un\'email di verifica a <b>{email}</b>. Segui le istruzioni in essa contenute per verificare la tua nuova email.',
        },
        DpaManager: {
            title: 'Accordo per l\'elaborazione dei dati',
            accepted: 'Accettato',
            notAccepted: 'Non accettato',
            buttonEdit: 'Modificare',
            tooltipDpaBlocked: 'Devi inserire nome e cognome per continuare',
        },
        DpaDialog: {
            title: 'Accordo per l\'elaborazione dei dati',
            subtitle: 'Per utilizzare alcune delle nostre funzioni, devi accettare i termini dell\'Accordo per l\'elaborazione dei dati (DPA).',
            dpaLinkText: 'Accordo per l\'elaborazione dei dati',
            buttonAccept: 'Accetto',
            buttonDecline: 'Rifiuto',
            successMessage: 'Accordo per l\'elaborazione dei dati aggiornato con successo.',
        },
        ConsentManager: {
            error: 'Si è verificato un errore durante il caricamento dei dati dei consensi',
            successMessage: 'I dati del consenso sono stati aggiornati con successo',
            subscribed: 'Iscrizione eseguita',
            notSubscribed: 'Iscrizione non eseguita',
            buttonEdit: 'Modificare',
            dialogTitle: 'Gestione dei consensi',
            buttonSave: 'Salva',
        },
        ChangeableName: {
            successMessage: 'Nome aggiornato con successo',
            updating: 'Aggiornamento in corso',
            firstName: 'Nome',
            lastName: 'Cognome',
        },
        Timezone: {
            buttonEdit: 'Modificare',
            timezone: 'Fuso orario',
            dialogTitle: 'Cambia fuso orario',
            buttonCancel: 'Annulla',
            buttonSave: 'Salva',
            successMessage: 'Fuso orario aggiornato con successo.',
        },
    },
    BusinessProfile: {
        title: 'Parlaci di te',
        save: 'Salva',
        done: 'Fatto',
        answerLater: 'Rispondi più tardi',
        tour: {
            gotIt: 'OK',
            text: 'Puoi aggiornare il profilo aziendale in un secondo momento dal menu account.',
        },
        firstSectionTitle: 'Parlaci della tua azienda',
        secondSectionTitle: 'Parlaci del tuo utilizzo di Plesk',
        whoDoYouCreateWebsitesFor: 'Quali sono i clienti per cui crei siti web?',
        clients: 'Clienti',
        ownPurposes: 'Uso personale',
        whatKindOfBusinessDoYouDo: 'Di cosa si occupa la tua azienda?',
        hosting: 'Hosting',
        agency: 'Agenzia',
        isTheWebsiteForBusinessOrPrivatePurposes: 'Il tuo sito web è per uso aziendale o privato?',
        business: 'Azienda',
        private: 'Privato',
        whatIsYourCoreBusiness: 'Qual è il tuo ambito di lavoro principale?',
        webHosting: 'Hosting Web',
        vpsAndServerHosting: 'Hosting di server e VPS',
        iaas: 'Provider di Infrastrutture distribuite come servizio (IaaS)',
        saas: 'Provider di Software distribuiti come servizio (SaaS)',
        other: 'Altro',
        webDevelopment: 'Sviluppo Web',
        webDesignOrManagement: 'Web design e management',
        marketingOrMedia: 'Marketing e media',
        whichDepartmentDoYouBelongTo: 'A quale dipartimento appartieni?',
        itAndSystemAdministration: 'IT e amministrazione di sistema',
        systemsIntegration: 'Integrazione sistemi',
        softwareDevelopment: 'Sviluppo software',
        marketingOrCommunication: 'Marketing e comunicazione',
        forWhichKindOfOrganisationIsTheWebsite: 'A chi è rivolto il sito web?',
        privatePersonOrFamily: 'Persona fisica o famiglia',
        association: 'Associazione',
        nonProfitOrganisation: 'Organizzazione no profit',
        howManyEmployeesDoesYourCompanyHave: 'Quanti dipendenti ci sono nella tua azienda?',
        selfEmployed: 'Lavoratore autonomo',
        howManyMembersDoesYourOrganisationHave: 'Quanti membri ci sono nella tua organizzazione?',
        howManyServersDoYouManage: 'Quanti server gestisci?',
        howManyServersDoYouManageDescription: 'con e senza Plesk',
        areTheyYourServers: 'Questi server sono di tua proprietà?',
        yes: 'Sì, questi server sono di mia proprietà.',
        no: 'No, gestisco questi server per conto di terzi.',
        partly: 'Entrambe le cose: alcuni dei server sono di mia proprietà, altri in sola gestione per conto di terzi.',
        whatIsYourMainPurposeOfPlesk: 'Per quali scopi utilizzi Plesk?',
        whatIsYourMainPurposeOfPleskDescription: 'Seleziona un qualsiasi numero di risposte',
        webhosting: 'Web hosting',
        emailHosting: 'Hosting email',
        eCommerceHosting: 'Hosting E-commerce',
        wordPressManagement: 'Gestione di WordPress',
        systemAdministration: 'Amministrazione di sistema',
        collaboration: 'Collaborazione',
        automation: 'Automazione',
        reselling: 'Rivendita',
        howWouldYouDescribeYourTechnicalKnowledge: 'Come descriveresti le tue conoscenze tecniche?',
        notTechnicalAtAll: 'Non ho conoscenze tecniche.',
        basicKnowledge: 'Ho competenze di base di Linux o Windows.',
        expert: 'Sono un esperto e utilizzo Plesk per ragioni di automazione.',
    },
    ApiTokens: {
        title: 'Token API',
        name: 'Nome',
        creationDate: 'Ora di generazione',
        lastUsageDate: 'Ultimo utilizzo',
        tokenNeverUsed: 'Mai',
        addButton: 'Genera token API',
        addButtonLimitExceededTooltip: 'Il limite di token API è stato raggiunto. Elimina i token esistenti per crearne di nuovi.',
        countTooltip: '{max, plural, one{Massimo # token per account.} other{Massimo # token per account.}}',
        totalAmount: 'totale {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Token <info>{name}</info> generato correttamente. Conservalo in un luogo sicuro, perché non lo vedrai più!',
        emptyListTitle: 'Genera token API per le tue applicazioni o i tuoi script per accedere all\'API di Platform 360',
        emptyListDescription: 'I tuoi token API appariranno qui.',
        createToken: 'Genera token API',
        successCopied: 'Copiato negli appunti.',
        successTokenDelete: 'Token eliminato correttamente',
        expireAtTooltip: 'Il token scadrà alle: {expireAt}.',
        AddTokenDialog: {
            title: 'Nuovo token API',
            nameLabel: 'Nome del token',
            nameDescription: 'Per cosa verrà utilizzato questo token?',
            addButton: 'Genera',
            closeButton: 'Chiudi',
            tokenInfo: 'Conservalo in un luogo sicuro, perché non lo rivedrai più. Se un token viene compromesso, generane subito uno nuovo. Il tuo nuovo token:',
            close: 'Chiudi',
            tokenSuccessCopied: 'Token copiato negli appunti.',
        },
        DeleteButton: {
            deleteButton: 'Elimina token',
            deleteConfirmation: 'Vuoi davvero eliminare il token <tooltip>{name}</tooltip>? Qualsiasi applicazione o script che utilizzi questo token non sarà più in grado di accedere all\'API di Platform 360.',
            copy: 'Copia negli appunti',
            deleteConfirmationInputDesc: 'Inserisci il nome del token per confermarne l\'eliminazione.',
            deleteConfirmationButton: 'Elimina token',
        },
    },
    UpdateEmail: {
        loadingText: 'Modifica e-mail in corso...',
        ErrorMessage: {
            tryAgainButton: 'Riprovare',
            errors: {
                forbidden: 'Il link per la modifica dell\'email è scaduto. Riprova.',
                changingEmailConflict: 'Un utente con questa email è già registrato.',
                unexpected: 'Il servizio non è al momento disponibile. Riprova più tardi.',
            },
        },
    },
};