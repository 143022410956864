// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import useConsentItems from '@platform360/libs/shared-web/helpers/useConsentItems';
import { useGetConsentsQuery } from '@platform360/accounts/web/queries';
import ConsentManager from './ConsentManager';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { Consent } from '@platform360/accounts/web/types';
import { usePostConsentsMutation } from '@platform360/accounts/web/mutations';

const ConsentManagerContainer = () => {
    const { successToast } = useToaster();
    const frontendConsents = useConsentItems();
    const translate = useTranslate('accounts.profile.ConsentManager');
    const [isEditing, setIsEditing] = useState(false);
    const [editingConsent, setEditingConsent] = useState<Consent | undefined>();
    const { isLoading, isError, data } = useGetConsentsQuery({
        meta: {
            defaultErrorHandler: false,
        },
    });

    const userConsents = data
        ? frontendConsents.map(({ type, text, title, renderText }) => ({
              type,
              title,
              text,
              renderText,
              enabled: !!data.find((item) => item.type === type && item.enabled),
          }))
        : [];
    const { isPending: isSaving, mutateAsync: handleSaveConsents } = usePostConsentsMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
        },
    });
    const handleStartEditing = (consent: Consent) => {
        setEditingConsent(consent);
        setIsEditing(true);
    };
    const handleStopEditing = () => {
        setIsEditing(false);
    };
    const handleChange = (enabled: boolean) => {
        if (!editingConsent) {
            return;
        }

        setEditingConsent({
            ...editingConsent,
            enabled,
        });
    };
    const handleSave = async (consent: Consent) => {
        await handleSaveConsents({
            consents: [
                {
                    type: consent.type,
                    enabled: consent.enabled,
                    text: consent.enabled ? consent.text : undefined,
                },
            ],
        });
        setIsEditing(false);
    };

    return (
        <ConsentManager
            consents={userConsents}
            isLoading={isLoading}
            isEditing={isEditing}
            editingConsent={editingConsent}
            isSaving={isSaving}
            isError={isError}
            onStartEditing={handleStartEditing}
            onStopEditing={handleStopEditing}
            onChange={handleChange}
            onSave={handleSave}
        />
    );
};

export default ConsentManagerContainer;
