// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text, ListActions, ListAction } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    Client,
    CLIENT_TYPE_ADMIN,
    CLIENT_TYPE_RESELLER,
} from '@platform360/server-inventory/web/types';
import DataList from '@platform360/libs/shared-web/components/DataList';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import useServerClientsQuery from '@platform360/server-inventory/web/queries/useServerClientsQuery';
import { useParams } from 'react-router-dom';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';

const Clients = () => {
    const translate = useTranslate('ViewServer.Clients');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { serverId } = useParams<{ serverId: string }>();
    const pagination = usePagination('si-clients');
    const search = useSearch();
    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useServerClientsQuery({
        variables: {
            // Parent ViewServer component checks serverId is valid
            serverId: Number(serverId),
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
            filter: search.debouncedValue,
        },
        placeholderData: (prev) => prev,
    });

    const onSearch = (term: string) => {
        if (term) {
            analyticsEvents.searchClients();
        }
        search.onSearch(term);
    };

    return (
        <DataList<Client>
            data={data?.data}
            totalRows={data?.totalCount}
            isPreviousData={isPreviousData}
            loading={isLoading}
            pagination={pagination}
            search={{
                value: search.value,
                debouncedValue: search.debouncedValue,
                onSearch,
            }}
            columns={[
                {
                    key: 'name',
                    type: 'title',
                    title: translate('name'),
                    render: ({ fields }) => (
                        <Text bold>{fields.product === 'plesk' ? fields.name : null}</Text>
                    ),
                },
                {
                    key: 'email',
                    title: translate('email'),
                },
                {
                    key: 'login',
                    title: translate('login'),
                },
                {
                    key: 'type',
                    title: translate('type'),
                    render: ({ type, owner }) => {
                        if (type === CLIENT_TYPE_ADMIN) {
                            return translate('typeAdmin');
                        }

                        if (type === CLIENT_TYPE_RESELLER) {
                            return translate('typeReseller');
                        }

                        if (owner) {
                            return translate('typeCustomerWithOwner', { owner: owner.name });
                        }

                        return translate('typeCustomer');
                    },
                },
                {
                    key: 'status',
                    title: translate('status'),
                    render: ({ type, isSuspended }) => {
                        if (type === CLIENT_TYPE_ADMIN) {
                            return null;
                        }

                        if (isSuspended) {
                            return translate('statusSuspended');
                        }

                        return translate('statusActive');
                    },
                },
                {
                    key: 'actions',
                    type: 'actions',
                    render: ({ id }) => (
                        <ListActions>
                            <ListAction
                                icon="sliders"
                                primary
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/si/servers/${serverId}/login/${LoginTargets.client}?clientId=${id}`}
                                data-type="manage-client-link"
                                onClick={() => analyticsEvents.serverClientsManageClicked()}
                            >
                                {translate('manage')}
                            </ListAction>
                        </ListActions>
                    ),
                },
            ]}
        />
    );
};

export default Clients;
