// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import Cost from '@platform360/licenses/web/components/Cost';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Currency from '@platform360/licenses/web/types/currency';
import NonBreakingSpace from '@platform360/licenses/web/components/HTMLEntities/NonBreakingSpace';

type ExtensionCostProps = {
    minPrice: number | boolean;
    hasSubset: boolean;
    period: BillingPeriod;
    currency: Currency;
};

const ExtensionCost = ({ minPrice, hasSubset, period, currency }: ExtensionCostProps) => {
    const translate = useTranslate();

    if (minPrice === 0) {
        return (
            <div>
                <b>{translate('upgradeSuggestionFreeCost')}</b>
                <br />
                {translate('upgradeSuggestionDescription')}
            </div>
        );
    }

    if (!minPrice) {
        return <div />;
    }

    return (
        <>
            {hasSubset && (
                <>
                    {translate('startingFrom')}
                    <NonBreakingSpace />
                </>
            )}
            <BillingInterval period={period}>
                <Cost value={Number(minPrice)} currency={currency} />
            </BillingInterval>
        </>
    );
};

export default ExtensionCost;
