// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import UpdateSettingsSelect from './UpdateSettingsSelect';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import {
    UpdateSettingsLevel,
    UpdateSettingsLevels,
} from '@platform360/security-dashboard/shared/update-settings-level';
import { UpdateSettingsOptions } from '@platform360/security-dashboard/shared/update-settings-option';
import {
    UpdateSettings,
    SafeUpdateSetting,
    Setting,
} from '@platform360/security-dashboard/web/types';
import { UpdateSettingsSelectOpion } from '@platform360/security-dashboard/web/components/UpdatesTab/UpdateSettingsSelect/UpdateSettingsSelect';

type UpdatesTabProps = {
    parentSettings?: UpdateSettings;
    isInstallationLevel?: boolean;
    isMassOperation?: boolean;
    formFieldClassName?: string;
    safeUpdatesDescription?: ReactNode;
    simpleSafeUpdates?: boolean;
};

export const UpdatesTab = ({
    parentSettings,
    isInstallationLevel,
    isMassOperation,
    formFieldClassName,
    safeUpdatesDescription,
    simpleSafeUpdates,
}: UpdatesTabProps) => {
    const translate = useTranslate('security-dashboard.UpdatesTab');
    const settingLevelTranslation: Record<UpdateSettingsLevel, string> = {
        [UpdateSettingsLevels.global]: translate('settingLevel.global'),
        [UpdateSettingsLevels.server]: translate('settingLevel.server'),
    };

    const attachParentLabel = (
        labels: UpdateSettingsSelectOpion[],
        setting?: Setting | SafeUpdateSetting,
    ) => {
        if (isInstallationLevel) {
            labels = labels.filter((item) => item.value !== UpdateSettingsOptions.notSet);
        }

        if (isMassOperation) {
            labels = [
                {
                    label: translate('doNotTouch'),
                    value: 'do-not-touch',
                },
                {
                    label: isInstallationLevel
                        ? translate('serverLevel')
                        : translate('globalLevel'),
                    value: 'parent',
                },
                ...labels,
            ];
        }

        if (!setting || (isInstallationLevel && setting.parent === UpdateSettingsOptions.notSet)) {
            return labels;
        }

        const { parentLevel, parent } = setting;
        const foundLabel = labels.find(({ value }) => value === parent);
        // Unknown should never be happened.
        const lbl = foundLabel?.label || 'unknown';

        const parentLabel = {
            label: `[${settingLevelTranslation[parentLevel]}] ${lbl}`,
            value: 'parent' as const,
        };

        return [parentLabel, ...labels];
    };

    const coreLabels = [
        {
            label: translate('coreLabels.notSet'),
            value: UpdateSettingsOptions.notSet,
        },
        {
            label: translate('coreLabels.disabled'),
            value: UpdateSettingsOptions.disabled,
        },
        {
            label: translate('coreLabels.minor'),
            value: UpdateSettingsOptions.minor,
            description: translate('coreLabels.minorDescription'),
        },
        {
            label: translate('coreLabels.major'),
            value: UpdateSettingsOptions.major,
        },
    ];

    const pluginsLabels = [
        {
            label: translate('pluginsLabels.notSet'),
            value: UpdateSettingsOptions.notSet,
        },
        {
            label: translate('pluginsLabels.disabled'),
            value: UpdateSettingsOptions.disabled,
            description: translate('pluginsLabels.disabledDescription'),
        },
        {
            label: translate('pluginsLabels.minor'),
            value: UpdateSettingsOptions.minor,
            description: translate('pluginsLabels.minorDescription'),
        },
        {
            label: translate('pluginsLabels.major'),
            value: UpdateSettingsOptions.major,
            description: translate('pluginsLabels.majorDescription'),
        },
    ];

    const themesLabels = [
        {
            label: translate('themesLabels.notSet'),
            value: UpdateSettingsOptions.notSet,
        },
        {
            label: translate('themesLabels.disabled'),
            value: UpdateSettingsOptions.disabled,
            description: translate('themesLabels.disabledDescription'),
        },
        {
            label: translate('themesLabels.minor'),
            value: UpdateSettingsOptions.minor,
            description: translate('themesLabels.minorDescription'),
        },
        {
            label: translate('themesLabels.major'),
            value: UpdateSettingsOptions.major,
            description: translate('themesLabels.majorDescription'),
        },
    ];

    const safeUpdatesLabels = [
        {
            label: translate('safeUpdatesLabels.notSet'),
            value: SafeUpdateOptions.notSet,
        },
        {
            label: translate('safeUpdatesLabels.enabled'),
            value: SafeUpdateOptions.enabled,
        },
        {
            label: translate('safeUpdatesLabels.disabled'),
            value: SafeUpdateOptions.disabled,
        },
    ];

    return (
        <>
            <UpdateSettingsSelect
                name="updates[core]"
                label={translate('labels.core')}
                options={attachParentLabel(coreLabels, parentSettings?.core)}
                className={formFieldClassName}
                data-type="updates[core]"
            />
            <UpdateSettingsSelect
                name="updates[plugins]"
                label={translate('labels.plugins')}
                options={attachParentLabel(pluginsLabels, parentSettings?.plugins)}
                className={formFieldClassName}
                data-type="updates[plugins]"
            />
            <UpdateSettingsSelect
                name="updates[themes]"
                label={translate('labels.themes')}
                options={attachParentLabel(themesLabels, parentSettings?.themes)}
                className={formFieldClassName}
                data-type="updates[themes]"
            />
            {safeUpdatesDescription}
            <UpdateSettingsSelect
                fullDescription={
                    simpleSafeUpdates ? undefined : translate('safeUpdates.description')
                }
                name="updates[safeUpdate]"
                label={translate('safeUpdates.title')}
                options={attachParentLabel(safeUpdatesLabels, parentSettings?.safeUpdate)}
                className={formFieldClassName}
                data-type="updates[safeUpdate]"
            />
        </>
    );
};
