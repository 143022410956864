// Copyright 2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { CardList, ContentLoader, Paragraph } from '@plesk/ui-library';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { Component } from 'react';
import PropTypes from 'prop-types';
import KeyBatch from './KeyBatch.js';
import KeysToolbar from './KeysToolbar';
import FooterPurposeExtension from './FooterPurposeExtension';
import {
    fetchAllKeysOnce,
    FILTER_ACTIVE,
    FILTER_ALL,
    getFilter,
    getKeyLists,
    STATE_KEY,
    changeFilter,
} from '@platform360/licenses/web/store';

import UpgradeWizard from '@platform360/licenses/web/UpgradeWizard';

import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';
import { AdjustForm } from '@platform360/licenses/web/units/adjust';
import Onboarding from './Onboarding';
import EditPaymentMethodTour from '@platform360/licenses/web/components/Tours/EditPaymentMethodTour';
import getSubscriptionIdByLicenseNumber from '@platform360/licenses/web/helpers/getSubscriptionIdByLicenseNumber';
import { licensesUri } from '@platform360/licenses/web/helpers';
import AddEmails from '@platform360/licenses/web/LinkedEmails/AddEmails';
import { useParams } from 'react-router-dom';

// stub hoc for wizards to time of refactoring
const withLicenseId = (Wrapped) => {
    const Wrapper = (props) => {
        const { licenseId } = useParams();

        return <Wrapped {...props} licenseId={licenseId} />;
    };
    Wrapper.displayName = `withLicenseId(${Wrapped.displayName || Wrapped.name || 'Component'})`;
    return Wrapper;
};

const RoutableAddEmails = withRouteBinding(AddEmails);
const RoutableUpgradeWizard = withRouteBinding(withLicenseId(UpgradeWizard));

class KeysView extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    componentDidUpdate() {
        if (
            window.location.hash &&
            document.body.contains(document.querySelector(window.location.hash))
        ) {
            document.querySelector(window.location.hash).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center',
            });
        }

        return true;
    }

    render() {
        const { filter, keyLists, licenseNumberForAdjust, simpleView } = this.props;
        const keys = keyLists[filter];
        const subscriptionIdForAdjust = getSubscriptionIdByLicenseNumber(
            keys,
            licenseNumberForAdjust,
        );

        if (!keys) {
            return (
                <ContentLoader
                    loader={{
                        type: 'icons',
                        icons: [
                            'calendar',
                            'card-ribbon',
                            'credit-cards',
                            'dollar',
                            'shopping-cart',
                            'emoticon-smile',
                            'web',
                        ],
                    }}
                />
            );
        }

        return (
            <div>
                <RoutableUpgradeWizard openPath=":licenseId/upgrade" closePath={licensesUri} />
                <RoutableAddEmails openPath="add-emails" closePath={licensesUri} />
                {!simpleView && (
                    <>
                        <Paragraph>{<Translate content="keysPageDescription" />}</Paragraph>
                        <KeysToolbar
                            filter={filter}
                            onRefresh={this.props.onRefresh}
                            onChangeFilter={this.props.onFilterChange}
                        />
                    </>
                )}

                {keys.length === 0 ? (
                    <Onboarding />
                ) : (
                    <>
                        <CardList
                            id="keyList"
                            toolbarGroups={[]}
                            items={keys}
                            totalItems={1}
                            loading={!keys}
                            itemKey=""
                            itemRender={(item) => (
                                <KeyBatch key={item.mainKey.id} keyBatch={item} />
                            )}
                        />
                        <EditPaymentMethodTour />
                    </>
                )}
                {licenseNumberForAdjust && subscriptionIdForAdjust && (
                    <AdjustForm
                        licenseId={licenseNumberForAdjust}
                        subscriptionId={subscriptionIdForAdjust}
                    />
                )}
                {!simpleView && <FooterPurposeExtension />}
            </div>
        );
    }
}

KeysView.propTypes = {
    filter: PropTypes.oneOf([FILTER_ACTIVE, FILTER_ALL]),
    keyLists: PropTypes.object.isRequired,
    onRefresh: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    licenseNumberForAdjust: PropTypes.oneOfType([
        PropTypes.shape({
            licenseId: PropTypes.number,
            productId: PropTypes.string,
        }),
        PropTypes.any,
    ]),
    simpleView: PropTypes.bool.isRequired,
};

KeysView.defaultProps = {
    filter: 'active',
    licenseNumberForAdjust: null,
};

const mapStateToProps = (state) => ({
    filter: getFilter(state),
    keyLists: getKeyLists(state),
    licenseNumberForAdjust: state[STATE_KEY].licenseNumberForAdjust,
});

const mapDispatchToProps = (dispatch) => ({
    onMount: () => {
        dispatch(fetchAllKeysOnce());
    },
    onFilterChange: (filter) => dispatch(changeFilter(filter)),
    onRefresh: () => dispatch(refreshKeyList()),
});

const KeysViewWrapper = (props) => {
    const {
        licencesModule: { simpleView = false },
    } = useApplicationSettings();

    return <KeysView simpleView={simpleView} {...props} />;
};

export { KeysView };
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(KeysViewWrapper);
