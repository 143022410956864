// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph, FormFieldText, Icon } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import AddServerControlStatus from '@platform360/server-inventory/web/components/Servers/AddServerControlStatus';
import UnsecureConfirmation from '@platform360/server-inventory/web/components/Servers/UnsecureConfirmation';
import { ValidationError } from '@platform360/libs/shared-web/helpers/translateErrors';
import styles from './AddServerWithRedirectStep.module.css';
import { CertErrors } from '@platform360/libs/common/cert-error-codes';
import StepForm from '@platform360/server-inventory/web/components/Servers/AddingServer/StepForm';

export type DialogStep = 'form' | 'unsecureConfirmation';

export type AddServerWithRedirectStepProps = {
    isLoading: boolean;
    step: DialogStep;
    setStep: (step: DialogStep) => void;
    values: FormValues;
    errors?: ValidationError[];
    onSubmit: (values: FormValues) => void;
    onChange: (key: string, value: unknown) => void;
    onBack: () => void;
};

export type FormValues = {
    address?: string;
    allowUnsecureConnection?: boolean;
};

const AddServerWithRedirectStep = ({
    isLoading,
    step = 'form',
    setStep,
    values,
    errors = [],
    onSubmit,
    onChange,
    onBack,
    ...props
}: AddServerWithRedirectStepProps) => {
    const translate = useTranslate(
        'server-inventory.AddingServer.AddingServerWizard.AddServerWithRedirectStep',
    );

    const addressError = errors.find((e) => e.path === 'address');

    const renderSubmitButton = () => {
        if (step === 'unsecureConfirmation') {
            return {
                children: translate('proceedAnyway'),
                'data-type': 'proceed-anyway-button',
                intent: 'danger',
            };
        }

        if (addressError && values.allowUnsecureConnection) {
            switch (addressError.type) {
                case CertErrors.CertSelfSigned:
                case CertErrors.CertSelfSignedInChain: {
                    return {
                        children: translate('proceedAnyway'),
                        'data-type': 'proceed-anyway-button',
                    };
                }
                case 'isHttps':
                case CertErrors.CertExpired:
                case CertErrors.CertAltNameInvalid: {
                    return {
                        children: translate('nextStep'),
                        'data-type': 'next-step-button',
                        intent: 'danger',
                        onClick: () => setStep('unsecureConfirmation'),
                    };
                }
            }
        }
        return { children: translate('proceed'), 'data-type': 'proceed-button' };
    };

    return (
        <StepForm
            title={translate('title')}
            state={isLoading ? 'submit' : undefined}
            values={values}
            onSubmit={onSubmit}
            forwardButton={{ ...renderSubmitButton() }}
            onFieldChange={onChange}
            backButton={{
                children: translate('back'),
                onClick: onBack,
            }}
            className={styles.root}
            data-type="connect-server-with-redirect-step"
            {...props}
        >
            {step === 'form' && (
                <>
                    <FormFieldText
                        name="address"
                        required
                        label={translate('addressLabel')}
                        description={translate('addressDescription')}
                        size="fill"
                        autoFocus
                        inputProps={{
                            prefix: (
                                <Icon name="globe" animation={isLoading ? 'spin' : undefined} />
                            ),
                        }}
                    />
                    <AddServerControlStatus error={addressError} />
                    <Paragraph>{translate('description')}</Paragraph>
                </>
            )}

            {step === 'unsecureConfirmation' && <UnsecureConfirmation address={values.address} />}
        </StepForm>
    );
};

export default AddServerWithRedirectStep;
