// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { ServerResponse } from '@platform360/security-dashboard/web/api/servers';
import {
    InstallationResponse,
    InstallationVulnerabilityResponse,
} from '@platform360/security-dashboard/web/api/installations';
import {
    VulnerabilitiesResponse,
    VulnerabilitiesSummaryResponse,
} from '@platform360/security-dashboard/web/api/vulnerabilities';
import {
    NotificationCategory,
    NotificationCode,
    NotificationsParams,
} from '@platform360/security-dashboard/shared/notifications';
import { LicenseResponse } from '@platform360/security-dashboard/web/api/license';
import { LabelType } from '@platform360/security-dashboard/shared/label-type';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import { UpdateSettingsLevel } from '@platform360/security-dashboard/shared/update-settings-level';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';
import { TaskInfo } from '@platform360/security-dashboard/shared/websocket';
import { OperationSubType } from '@platform360/security-dashboard/shared/operation-sub-type';
import {
    OperationType,
    OperationTypes,
} from '@platform360/security-dashboard/shared/operation-type';
import {
    APPLY_VIRTUAL_PATCHES_TASK_TYPE,
    DISABLE_VIRTUAL_PATCHES_TASK_TYPE,
    UPDATE_INSTANCE_TASK_TYPE,
    UPDATE_ITEMS_TASK_TYPE,
} from '@platform360/security-dashboard/shared/constants';
import { TaskType } from '@platform360/security-dashboard/shared/task-type';

export type ServerStatus =
    | 'connectionError'
    | 'connectionBlocked'
    | 'ok'
    | 'installingAgent'
    | 'agentInstallationError';

export type Server = Omit<
    ServerResponse,
    'address' | 'lastRequestResultType' | 'lastVulnerabilityCheckAt'
> & {
    address: URL;
    ipAddress: string;
    status: ServerStatus;
    lastVulnerabilityCheckAt: DateTime | null;
};

export const SITE_HEALTH_FILTER_ID = 'site-health-filter';
export const SERVER_HEALTH_FILTER_ID = 'server-health-filter';
export const LABEL_IDS_FILTER = 'label-ids-filter';
export const VULNERABILITY_STATE_FILTER_ID = 'vulnerability-state-filter';
export const VULNERABILITY_COMPONENT_FILTER_ID = 'vulnerability-component-filter';
export const DRAWER_TAB_VULNERABILITIES_COMPONENT_FILTER_ID =
    'drawer-tab-vulnerabilities-component-filter';
export const DRAWER_TAB_OUTDATED_FILTER_ID = 'drawer-tab-outdated-filter';

export enum SitesHealthFilter {
    Vulnerable = 'vulnerable',
    Outdated = 'outdated',
    Protected = 'protected',
    NotProtected = 'notProtected',
    NotOperable = 'notOperable',
    Unmanaged = 'unmanaged',
    Safe = 'safe',
}

export enum VulnerabilitiesStateFilter {
    Exploited = 'exploited',
    CanBeProtected = 'canBeProtected',
    Ignored = 'ignored',
}

export enum ComponentsFilterHeader {
    Header = 'filterHeader',
}

export enum VulnerabilitiesComponentFilter {
    Header = 'filterHeader',
    Core = 'core',
    Plugin = 'plugin',
    Theme = 'theme',
}

export type InstallationServer = {
    id: number;
    displayTitle: string;
    hostname: string | null;
    status: ServerStatus;
    ipAddress: string;
    unsupportedAgent: boolean;
    agentVersion: string | null;
    protected: boolean;
};

export type Installation = Omit<InstallationResponse, 'server' | 'lastVulnerabilityCheckAt'> & {
    server: InstallationServer;
    lastVulnerabilityCheckAt: DateTime | null;
};

export type InstallationVulnerability = Omit<InstallationVulnerabilityResponse, 'disclosedAt'> & {
    disclosedAt: DateTime | null;
};

export const UPDATE_TASKS: TaskType[] = [UPDATE_INSTANCE_TASK_TYPE, UPDATE_ITEMS_TASK_TYPE];
export const VIRTUAL_PATCHES_TASKS: TaskType[] = [
    APPLY_VIRTUAL_PATCHES_TASK_TYPE,
    DISABLE_VIRTUAL_PATCHES_TASK_TYPE,
];

export type Task = {
    id: number;
    type: TaskType;
    operationType: OperationType | null;
    operationSubType: OperationSubType | null;
    info: TaskInfo | null;
};

export type Vulnerability = Omit<VulnerabilitiesResponse, 'disclosedAt'> & {
    disclosedAt: DateTime | null;
};

export type VulnerabilitiesSummary = Omit<VulnerabilitiesSummaryResponse, 'lastUpdateAt'> & {
    lastUpdateAt: DateTime | null;
};

export type CountOfAffectedSitesResponse = {
    affectedSitesCount: number;
    operableNotManaged: number;
    affectedSitesWithPluginsCount: number;
    doNotProtect: number;
};

export type OperationStatus = {
    success: number;
    failed: number;
};

export const OPERATION_STATUS_FAILED = 'failed';

export const FILTER_ID_OPERATION_STATUS = 'task-manager-operation-status-filter';
export type OperationsStatusFilter = typeof OPERATION_STATUS_FAILED;

export const FILTER_ID_OPERATION_TYPE = 'task-manager-operation-type-filter';
export type OperationsTypeFilter =
    | typeof OperationTypes.update
    | typeof OperationTypes.fixVulnerabilitiesViaUpdate
    | typeof OperationTypes.refresh
    | typeof OperationTypes.findNewSites
    | typeof OperationTypes.configureUpdates
    | typeof OperationTypes.mitigationDeactivateAsset
    | typeof OperationTypes.mitigationActivateAsset
    | typeof OperationTypes.ignoreVulnerabilities
    | typeof OperationTypes.cancelIgnoreVulnerabilities
    | typeof OperationTypes.setDailyTaskTime;

export type TaskManagerListRow = {
    id: number;
    key: string;
    title?: string;
    subTitle?: string | null;
    type?: OperationType;
    subType?: OperationSubType;
    status: OperationStatus;
    createdAt: string;
    finishedAt: string;
    errors: string[];
    itemsAffected?: number;
    data?: TaskManagerListRow[];
};

export type OperationsApiResponseRow = {
    id: number;
    type: OperationType;
    subType: OperationSubType;
    status: OperationStatus;
    createdAt: string;
    finishedAt: string;
    errors: string[];
    itemsAffected: number;
    items: OperationsApiResponseSubRow[];
};

export type OperationsApiResponseSubRow = {
    id: number;
    title: string;
    subTitle: string | null;
    status: OperationStatus;
    createdAt: string;
    finishedAt: string;
    errors: string[];
};

export type Notification = {
    id: number;
    createdAt: DateTime;
    isRead: boolean;
    category: NotificationCategory;
    code: NotificationCode;
    params: NotificationsParams[keyof NotificationsParams];
};

export type NotificationsSettings = {
    email: string;
    newExploitedVulnerabilities: boolean;
    licenseNotifications: boolean;
};

export type License = Omit<LicenseResponse, 'expiredAt' | 'nextUpdateAt'> & {
    expiredAt: DateTime;
    nextUpdateAt: DateTime;
};

export type Label = {
    id: number;
    title: string;
    type: LabelType;
};

export const VULNERABILITY_PROVIDER_PATCHSTACK = 'patchstack';
export const VULNERABILITY_PROVIDER_WORDFENCE = 'wordfence';

export type VulnerabilityProviderType =
    | typeof VULNERABILITY_PROVIDER_PATCHSTACK
    | typeof VULNERABILITY_PROVIDER_WORDFENCE;

export type VulnerabilityProvider = {
    provider: VulnerabilityProviderType;
    directUrl: string;
};

export type Setting = {
    value: UpdateSettingsOption | null;
    parent: UpdateSettingsOption;
    parentLevel: UpdateSettingsLevel;
};

export type SafeUpdateSetting = {
    value: SafeUpdateOption | null;
    parent: SafeUpdateOption;
    parentLevel: UpdateSettingsLevel;
};

export type UpdateSettings = {
    core: Setting;
    plugins: Setting;
    themes: Setting;
    safeUpdate: SafeUpdateSetting;
};

export type DailyTaskTimeSettingsResponse = {
    taskStartTimeHours: number | null;
    taskStartTimeMinutes: number | null;
};

export type DailyTaskTimeSettingsSaveData = {
    taskStartTimeHours: number | null;
    taskStartTimeMinutes: number | null;
};

export type VulnerabilitySettingsResponse = {
    ignoreLowRisk: boolean;
};

export type VulnerabilitySettingsSaveData = {
    ignoreLowRisk: boolean;
};
