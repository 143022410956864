// Copyright 2024. WebPros International GmbH. All rights reserved.

import Public from '@platform360/libs/shared-web/components/Public';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Heading, Paragraph } from '@plesk/ui-library';
import styles from './NotFoundPage.module.css';
import notFound from './not-found.png';

const NotFoundPage = () => {
    const translate = useTranslate('security-dashboard.NotFoundPage');

    return (
        <Public isWide>
            <div className={styles.root}>
                <img className={styles.image} src={notFound} alt="page not found" />
                <div className={styles.content}>
                    <Heading level={2} className={styles.title}>
                        {translate('title')}
                    </Heading>
                    <Paragraph>{translate('description')}</Paragraph>
                </div>
            </div>
        </Public>
    );
};

export default NotFoundPage;
