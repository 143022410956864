// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Avisos legales',
        eula: 'EULA',
        pleskEula: 'EULA de Plesk',
        cPanelEula: 'EULA de cPanel',
        terms: 'Términos de uso',
        privacyPolicy: 'política de privacidad',
        dpa: 'Contrato de procesamiento de datos',
        optOuts: 'Opt-Outs',
        cookies: 'Declaración de cookies',
        gaHeader: 'Opt- out de Google Analytics',
        gaOptOut: 'Haga clic aquí para desactivar Google Analytics',
        cookieHeader: 'Ajustes de consentimiento de cookies',
        cookieModify: 'Modificar su consentimiento de cookies',
        cookieWithdraw: 'Retirar su consentimiento de cookies',
        cookieSuccess: 'Retirada correcta del consentimiento',
    },
    RedirectGateway: {
        title: 'Redireccionándole a {destination}',
        progress: 'Esto puede tardar unos segundos',
        genericError: 'Algo ha salido mal',
        serverDetails: {
            destinationTitle: 'a la página del servidor en el inventario de servidores',
            errorTitle: 'Se ha producido un error mientras se intentaba abrir la página del servidor',
            errorDescription: 'El servidor no existe en el inventario de servidores',
            redirectDirectly: 'Ir al inventario de servidores',
        },
        monitoringServerSetup: {
            destinationTitle: 'a la página de confirmación',
            errorTitle: 'Se ha producido un error mientras se intentaba abrir la página de confirmación de monitorización del servidor',
            errorDescription: 'El servidor no existe en el inventario de servidores',
        },
    },
};