// Copyright 2024. WebPros International GmbH. All rights reserved.

/**
 * Builds URL from provided base path and query parameters, omits undefined values.
 */
const buildUrl = (basePath: string, queryParams: Record<string, string | undefined>): string => {
    const queryString = Object.entries(queryParams)
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');

    return queryString ? `${basePath}?${queryString}` : basePath;
};

export default buildUrl;
