// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Toaster from '@platform360/libs/shared-web/toaster';
import { AnalyticsClientContext, analyticsClient } from '@platform360/libs/shared-web/analytics';
import { ConfigContext } from '@platform360/libs/shared-web/helpers/useConfig';
import ChangePassword from '@platform360/accounts/web/profile/ChangePassword';
import ViewProfile from '@platform360/accounts/web/profile/ViewProfile';
import BusinessProfile from '@platform360/accounts/web/components/BusinessProfile';
import { UserMenuModalsProvider } from '@platform360/libs/shared-web/components/UserMenu';
import { GlobalSearchProvider } from '@platform360/libs/shared-web/helpers/useGlobalSearch';
import GlobalSearch from '@platform360/server-inventory/web/components/GlobalSearch';
import ApiTokens from '@platform360/accounts/web/components/ApiTokens';
import { AuthProvider } from '@platform360/libs/shared-web/auth';
import { UserProvider } from '@platform360/libs/shared-web/user';
import { useCurrentUserQuery } from '@platform360/accounts/web/queries/useCurrentUserQuery';
import { useDepersonateMutation } from '@platform360/accounts/web/mutations';
import { QueryProvider } from '@platform360/libs/shared-web/query';
import {
    adminPanelAppSettings,
    wpsdAppSettings,
    platformAppSettings,
    getPleskAppSettings,
    getCpanelApplicationSettings,
} from '@scp/frontend/applications-settings';
import { Application } from '@platform360/libs/common/application';
import {
    ApplicationSettings,
    ApplicationSettingsContext,
} from '@platform360/libs/shared-web/application-settings/context';
import Document from '@platform360/libs/shared-web/components/Document';
import AnalyticsRouteHandler from '@scp/frontend/components/AnalyticsRouteHandler';
import { Outlet } from 'react-router-dom';
import { ToolbarBetaProvider } from '@plesk/ui-library';
import {
    ActivateLicenseComponentContext,
    ActivateLicenseComponentContextValue,
} from '@platform360/licenses/web/components/ActivateLicense';
import MonitoringActivateLicense from '@platform360/licenses/web/components/ActivateLicense/monitoring';
import SecurityDashboardActivateLicense from '@platform360/security-dashboard/web/components/ActivateLicense';
import {
    MONITORING_PRODUCTS,
    WP_GUARDIAN_PRODUCTS,
} from '@platform360/libs/shared-web/constants/products';
import { ApplicationAwareLocaleProvider } from '@scp/frontend/components/ApplicationAwareLocaleProvider';

const userMenuModals = {
    profile: ViewProfile,
    'change-password': ChangePassword,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'business-profile': () => <BusinessProfile modalPath="business-profile" />,
    'api-tokens': ApiTokens,
};

const applicationSettings: Record<Application, ApplicationSettings> = {
    'admin-panel': adminPanelAppSettings,
    'security-dashboard': wpsdAppSettings,
    platform: platformAppSettings,
    'get-plesk': getPleskAppSettings,
    'get-cpanel': getCpanelApplicationSettings,
};

const activateLicenseComponentContextValue: ActivateLicenseComponentContextValue = {
    monitoring: {
        products: MONITORING_PRODUCTS,
        component: MonitoringActivateLicense,
    },
    'security-dashboard': {
        products: WP_GUARDIAN_PRODUCTS,
        component: SecurityDashboardActivateLicense,
    },
};

const App = () => (
    <ConfigContext.Provider value={window.__INITIAL_STATE__.config}>
        <ApplicationSettingsContext.Provider value={applicationSettings}>
            <ApplicationAwareLocaleProvider>
                <ToolbarBetaProvider>
                    <QueryProvider>
                        {/* https://react-query.tanstack.com/devtools#import-the-devtools */}
                        <ReactQueryDevtools initialIsOpen={false} />
                        <AnalyticsClientContext.Provider value={analyticsClient}>
                            <AuthProvider>
                                <GlobalSearchProvider globalSearch={GlobalSearch}>
                                    <UserMenuModalsProvider modals={userMenuModals}>
                                        <ActivateLicenseComponentContext.Provider
                                            value={activateLicenseComponentContextValue}
                                        >
                                            <>
                                                <Toaster />
                                                <UserProvider
                                                    useCurrentUserQuery={useCurrentUserQuery}
                                                    useDepersonateMutation={useDepersonateMutation}
                                                >
                                                    <Document />
                                                    <AnalyticsRouteHandler />
                                                    <Outlet />
                                                </UserProvider>
                                            </>
                                        </ActivateLicenseComponentContext.Provider>
                                    </UserMenuModalsProvider>
                                </GlobalSearchProvider>
                            </AuthProvider>
                        </AnalyticsClientContext.Provider>
                    </QueryProvider>
                </ToolbarBetaProvider>
            </ApplicationAwareLocaleProvider>
        </ApplicationSettingsContext.Provider>
    </ConfigContext.Provider>
);

export default App;
