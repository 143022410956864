// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Dialog, FormProps, FormValues } from '@plesk/ui-library';
import styles from './DialogLayout.module.css';
import UserMenu from '@platform360/libs/shared-web/components/UserMenu';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

type DialogLayoutProps<TFormValues extends FormValues> = {
    children: ReactNode;
    form?: FormProps<TFormValues>;
    'data-type'?: string;
};

const DialogLayout = <TFormValues extends FormValues>({
    children,
    form,
    ...props
}: DialogLayoutProps<TFormValues>) => {
    const { logo } = useApplicationSettings();

    return (
        <Dialog
            title={
                <div className={styles.header}>
                    {logo}
                    <div className={styles.options}>
                        <UserMenu showBusinessProfile={false} />
                        <LocaleSwitcher />
                    </div>
                </div>
            }
            form={form}
            size="sm"
            closable={false}
            isOpen
            {...props}
        >
            <div className={styles.content}>{children}</div>
        </Dialog>
    );
};

export default DialogLayout;
