// Copyright 2024. WebPros International GmbH. All rights reserved.

import Header from '@platform360/auth/web/components/Header';
import { Link } from 'react-router-dom';
import { Form, FormErrors, FormFieldText, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { SetPasswordUseCase } from '@platform360/auth/web/types';

type ChangePasswordInvalidTokenFormProps = {
    errors?: FormErrors;
    onEmailChange: (email: string) => void;
    onSubmit: (email: string) => void;
    isLoading: boolean;
    useCase: SetPasswordUseCase;
};

type FormValues = {
    email: string;
};

const DataTestIds = {
    FORM: 'ChangePassword--invalidTokenForm',
};

export const ChangePasswordInvalidTokenForm = ({
    useCase,
    errors,
    onSubmit,
    onEmailChange,
    isLoading,
}: ChangePasswordInvalidTokenFormProps) => {
    const translate = useTranslate('auth.ChangePasswordInvalidTokenForm');
    const handleSubmit = ({ email }: FormValues) => onSubmit(email);

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                state={isLoading ? 'submit' : undefined}
                errors={errors}
                applyButton={false}
                cancelButton={false}
                submitButton={{
                    children: translate('submitButton'),
                    type: 'submit',
                }}
                hideRequiredLegend
                vertical
                data-type={DataTestIds.FORM}
            >
                <Header>{translate('title')}</Header>
                <Paragraph>
                    {useCase === 'reset' ? translate('resetText') : translate('setText')}
                </Paragraph>
                <FormFieldText
                    name="email"
                    onChange={onEmailChange}
                    label={translate('emailLabel')}
                    autoFocus
                />
            </Form>
            {useCase === 'reset' ? (
                <>
                    <br />
                    <Paragraph>
                        {translate('bottomText', {
                            a: (chunk) => <Link to="/auth/login">{chunk}</Link>,
                        })}
                    </Paragraph>
                </>
            ) : undefined}
        </>
    );
};

export default ChangePasswordInvalidTokenForm;
