// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon, Text } from '@plesk/ui-library';
import styles from './CopyText.module.less';
import { useClipboard } from '@platform360/libs/shared-web/helpers/useClipboard';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type CopyTextProps = {
    text: string;
    intent?: 'info' | 'success' | 'warning' | 'danger' | 'muted';
};

export const CopyText = ({ text, intent }: CopyTextProps) => {
    const { copyText } = useClipboard();
    const translate = useTranslate('CopyText');

    return (
        <div
            className={styles.root}
            onClick={() =>
                copyText(text, {
                    successMessage: translate('copied'),
                })
            }
        >
            <div>
                <Text intent={intent}>{text}</Text>
            </div>
            <Icon className={styles.copyIcon} intent="inactive" name="copy" size="12" />
        </div>
    );
};
