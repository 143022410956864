// Copyright 2024. WebPros International GmbH. All rights reserved.

import { SELECT_PRODUCT } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type SelectProductAction = {
    type: typeof SELECT_PRODUCT;
    payload: string;
};

const selectProduct = (productId: string): SelectProductAction => ({
    type: SELECT_PRODUCT,
    payload: productId,
});

export default selectProduct;
