// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './CarouselCard.module.less';
import CarouselButton, { CarouselButtonProps } from './CarouselButton';
import { Heading, Paragraph, TranslationProps } from '@plesk/ui-library';

export type CarouselCardProps = {
    title: TranslationProps;
    description: TranslationProps;
    background: string;
    previewImages: [string, string];
    primaryButton: CarouselButtonProps;
    secondaryButton?: CarouselButtonProps;
};

const CarouselCard = ({
    title,
    description,
    background,
    primaryButton,
    secondaryButton,
    previewImages: [imgSrc, imgSrc2x],
}: CarouselCardProps) => (
    <div className={styles.root} style={{ background }}>
        <div className={styles.content}>
            <Heading className={styles.title} level={1}>
                {title}
            </Heading>
            <Paragraph>{description}</Paragraph>
            <CarouselButton
                intent="primary"
                to={primaryButton.to}
                isExternal={primaryButton.isExternal}
            >
                {primaryButton.children}
            </CarouselButton>
            {secondaryButton && (
                <CarouselButton
                    className={styles.secondaryButton}
                    to={secondaryButton.to}
                    isExternal={secondaryButton.isExternal}
                    // eslint-disable-next-line react/jsx-handler-names
                    onClick={secondaryButton.onClick}
                    icon={{ ...secondaryButton.icon, className: styles.secondaryButtonIcon }}
                    ghost
                >
                    {secondaryButton.children}
                </CarouselButton>
            )}
        </div>
        <div className={styles.banner}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={imgSrc} srcSet={`${imgSrc2x} 2x`} />
        </div>
    </div>
);

export default CarouselCard;
