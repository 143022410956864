// Copyright 2024. WebPros International GmbH. All rights reserved.

import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { MouseEvent } from 'react';

type CancelButtonProps = {
    onClick: (e: MouseEvent) => void;
    disabled?: boolean;
};

const CancelButton = ({ onClick, disabled }: CancelButtonProps) => {
    const translate = useTranslate('monitoring.MonitoringPromoActivation.CancelButton');

    return (
        <ConfirmationPopover
            target={
                <Button icon="remove" size="lg" disabled={disabled}>
                    {translate('cancelButtonText')}
                </Button>
            }
            actionButtonText={translate('acceptButtonText')}
            cancelButtonText={translate('declineButtonText')}
            onClick={onClick}
        >
            <Paragraph>{translate('confirmationText')}</Paragraph>
        </ConfirmationPopover>
    );
};

export default CancelButton;
