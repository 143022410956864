// Copyright 2024. WebPros International GmbH. All rights reserved.

import { EmailsMetaResponse } from '@platform360/admin-panel/shared/api-contract';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    ApiResponse,
    FetcherOptions,
    FilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';

type GetEmailMetasOptions = FetcherOptions<PaginatedQuery & FilterQuery>;

export const getEmailMetas = async ({
    variables,
    signal,
}: GetEmailMetasOptions): Promise<EmailsMetaResponse> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<EmailsMetaResponse>>(
        '/admin-panel/email-metas',
        variables,
        {
            signal,
        },
    );

    return data;
};

export const deleteEmailMeta = (email: string) =>
    apiClient.delete(`/admin-panel/email-metas/${email}`);
