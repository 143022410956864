// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import useApiDefaultErrorHandler from '@platform360/libs/shared-web/helpers/useApiDefaultErrorHandler';

type MutationFunction<TData extends unknown> = (id: number) => Promise<TData>;
type UseListActionMutationOptions<TData> = {
    onSuccess?: (data: TData) => void;
    onSettled?: (results: PromiseSettledResult<TData>[]) => void;
};

const useListActionMutation = <TData extends { id: number }>(
    mutationFn: MutationFunction<TData>,
    options?: UseListActionMutationOptions<TData>,
) => {
    const defaultErrorHandler = useApiDefaultErrorHandler();
    const [loadingIds, setLoadingIds] = useState<Set<number>>(new Set());
    const runMutations = (ids: number[]) => {
        const promises = ids.map((id) => mutationFn(id));
        return Promise.allSettled(promises)
            .then((results) => {
                results.forEach((result) => {
                    if (result.status === 'fulfilled') {
                        options?.onSuccess?.(result.value);
                    } else {
                        defaultErrorHandler(result.reason);
                    }
                });
                options?.onSettled?.(results);
                setLoadingIds(
                    (loadingIds) =>
                        new Set(Array.from(loadingIds).filter((id) => !ids.includes(id))),
                );

                return results;
            })
            .catch(defaultErrorHandler);
    };
    const mutate = (ids: number[]) => {
        ids = ids.filter((id) => !loadingIds.has(id));
        setLoadingIds(new Set([...Array.from(loadingIds), ...ids]));
        runMutations(ids).catch(defaultErrorHandler);
    };

    return { mutate, loadingIds: Array.from(loadingIds) };
};

export default useListActionMutation;
