// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Spot, Tour } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useState } from 'react';
import { DATA_TYPE_USER_MENU } from '@platform360/libs/shared-web/components/UserMenu';

const useTour = () => {
    const translate = useTranslate('accounts.BusinessProfile.tour');
    const [current, setCurrent] = useState<number | undefined>(undefined);
    const hideTour = () => {
        setCurrent(undefined);
    };
    const showTour = () => {
        setCurrent(0);
    };
    const gotItButton = (
        <Button key="gotIt" intent="primary" onClick={hideTour}>
            {translate('gotIt')}
        </Button>
    );

    const popup = {
        children: translate('text'),
        buttons: [gotItButton],
        'data-type': 'business-profile-popup',
    };
    const tour = (
        <Tour current={current} onClose={hideTour}>
            {[<Spot key={1} selector={`[data-type=${DATA_TYPE_USER_MENU}]`} popup={popup} />]}
        </Tour>
    );

    return { tour, showTour, hideTour };
};

export default useTour;
