// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Branding } from '@platform360/libs/shared-web/types';
import RetryTimer, { formatSeconds } from '@platform360/libs/shared-web/components/RetryTimer';
import ErrorMessage from '@platform360/auth/web/components/ErrorMessage';
import styles from './SignUpVerification.module.css';
import { useSendVerification } from '@platform360/auth/web/mutations';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { SEND_VERIFICATION_EMAIL_TTL } from '@platform360/auth/shared/throttler-limits';
import { isAxiosError } from 'axios';

type SendVerificationProps = {
    email: string;
    clientId?: string;
    branding?: Branding;
};

const SignUpVerification = ({ email, clientId, branding }: SendVerificationProps) => {
    const translate = useTranslate('Auth.SignUp');

    const [isResendBlocked, setResendBlocked] = useState(false);
    const blockResend = () => setResendBlocked(true);
    const unblockResend = () => setResendBlocked(false);
    const {
        auth: { signUpVerificationDrawerAddon },
    } = useApplicationSettings();

    const {
        mutate: sendVerificationEmail,
        isPending: isLoading,
        error,
    } = useSendVerification(blockResend);

    return (
        <>
            <Paragraph data-type="SignUp--verifyAccountText">
                {translate('verifyAccountText', { email })}
            </Paragraph>
            <Paragraph>{translate('resendDescription')}</Paragraph>
            {isAxiosError(error) && error.response?.data.error ? (
                <ErrorMessage>{error.response.data.error}</ErrorMessage>
            ) : null}
            {signUpVerificationDrawerAddon}
            <Paragraph>
                <Button
                    intent="primary"
                    data-type="SignUp--resendButton"
                    onClick={() => sendVerificationEmail({ email, clientId, branding })}
                    disabled={isResendBlocked || isLoading}
                    state={isLoading ? 'loading' : undefined}
                    size="lg"
                >
                    {translate('resendLabel')}
                </Button>
                <DrawerLink to="/auth/login" className={styles.loginLink}>
                    {translate('loginLink')}
                </DrawerLink>
            </Paragraph>
            <Paragraph>
                {isResendBlocked && (
                    <RetryTimer
                        onTimeEnd={unblockResend}
                        retryInterval={SEND_VERIFICATION_EMAIL_TTL}
                    >
                        {({ seconds }: { seconds: number }) => (
                            <span className={styles.retryCountdown}>
                                {translate('retryCountdown', {
                                    time: formatSeconds(seconds),
                                })}
                            </span>
                        )}
                    </RetryTimer>
                )}
            </Paragraph>
        </>
    );
};

export default SignUpVerification;
