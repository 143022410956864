// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getInstallationsSummary } from '@platform360/security-dashboard/web/api/installations';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseInstallationsSummaryQueryVariables = undefined;

export type UseInstallationsSummaryQueryData = PromiseValue<
    ReturnType<typeof getInstallationsSummary>
>;

const useInstallationsSummaryQuery = createQuery<
    UseInstallationsSummaryQueryVariables,
    UseInstallationsSummaryQueryData
>({
    queryName: 'security-dashboard/useInstallationsSummaryQuery',
    fetcher: getInstallationsSummary,
    useQuery: (options) =>
        useQuery({
            ...options,
            // Refetch every minute.
            refetchInterval: 60000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            meta: {
                defaultErrorHandler: false,
            },
        }),
});

export default useInstallationsSummaryQuery;
