// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ContentLoader, Drawer, StatusMessage } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Label } from '@platform360/security-dashboard/web/types';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useEditLabelMutation } from '@platform360/security-dashboard/web/mutations';
import LabelForm, { FormValues } from '../LabelForm';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

export type EditLabelDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    labels: Label[];
};

const EditLabelDrawer = ({ isOpen, onClose, isLoading, labels }: EditLabelDrawerProps) => {
    const translate = useTranslate('security-dashboard.LabelsDrawer.EditLabelDrawer');
    const translateValidationErrors = useTranslateValidationErrors();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpEditLabelDrawerShown();
        } else {
            analyticsEvents.wpEditLabelDrawerClosed();
        }
    }, [analyticsEvents, isOpen]);

    const params = useParams<{ labelId: string }>();
    const labelId = Number(params.labelId);
    const label = labels.find(({ id }) => id === labelId) || null;

    const { mutate: editLabel, isPending: isEditing, error } = useEditLabelMutation();

    const handleSubmit = (values: FormValues) => {
        editLabel(
            { ...values, labelId },
            {
                onSuccess: () => {
                    onClose();
                },
            },
        );
        analyticsEvents.wpEditLabelDrawerSubmit();
    };

    const isLabelNotFound = !isLoading && !label;

    const renderContent = () => {
        if (isLoading) return <ContentLoader />;

        if (isLabelNotFound) {
            return <StatusMessage intent="info">{translate('notFound')}</StatusMessage>;
        }

        return null;
    };

    const shouldRenderForm = !isLoading && !isLabelNotFound;

    const form = shouldRenderForm ? (
        <LabelForm
            label={label}
            onSubmit={handleSubmit}
            state={isEditing ? 'loading' : undefined}
            errors={translateValidationErrors(getValidationErrors(error))}
        />
    ) : undefined;

    return (
        <Drawer
            data-type="wp-edit-label-drawer"
            title={translate('title')}
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
            form={form}
        >
            {renderContent()}
        </Drawer>
    );
};

export default EditLabelDrawer;
