// Copyright 2024. WebPros International GmbH. All rights reserved.

import AuthContext, { LoginOptions } from '@platform360/libs/shared-web/auth/context';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import usePersistReducer, {
    SESSION_KEY,
} from '@platform360/libs/shared-web/auth/usePersistReducer';
import { setSentryUser } from '@platform360/libs/shared-web/helpers/sentry';
import { analyticsClient } from '@platform360/libs/shared-web/analytics';
import axios from 'axios';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { captureException } from '@sentry/browser';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { Action, EVENT_NAME } from '@platform360/libs/shared-web/auth/reducer';
import { toSession } from '@platform360/libs/shared-web/auth/auth-state';
import { AuthClient } from '@platform360/libs/shared-web/auth/AuthClient';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { AuthConfig } from '@platform360/libs/common/initial-state';
import parseEmailNotVerifiedJson from './parse-email-not-verified-json';
import useNotifyLogout from '@platform360/libs/shared-web/application-settings/useNotifyLogout';
import { useLocale } from '@platform360/libs/shared-web/locale';

type AuthProviderProps = {
    children: ReactNode;
};

export const clearSessionInLocalStorage = () => localStorage.removeItem(SESSION_KEY);

export const createAuth0ClientFromAuthConfig = ({
    domain,
    clientId,
    audience,
}: AuthConfig): AuthClient => {
    const auth0Options: Auth0ClientOptions = {
        domain,
        clientId,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        authorizationParams: {
            audience,
            scope: 'openid profile email read:messages write:messages',
            redirect_uri: `${window.location.origin}/auth/callback`,
            response_type: 'token id_token',
        },
    };

    return window.__CYPRESS__?.AuthClient
        ? new window.__CYPRESS__.AuthClient()
        : new Auth0Client(auth0Options);
};

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [state, dispatch] = usePersistReducer();
    const [error, setError] = useState<Error | undefined>();
    const { auth: authConfig, monitoring } = useConfig();
    const [auth0Client] = useState<AuthClient>(() => createAuth0ClientFromAuthConfig(authConfig));
    const [locale] = useLocale();
    const shouldNotifyLogout = useNotifyLogout();

    const notifyExternalServicesOnLogout = useCallback(async () => {
        const httpClient = axios.create({
            timeout: 5000,
        });

        const notifications = [
            httpClient.get(concatUrl('/logout', monitoring.baseUrl), {
                withCredentials: true,
            }),
        ];
        const results = await Promise.allSettled(notifications);
        results.forEach((result) => {
            if (result.status === 'rejected') {
                // TODO: make notification about logout error
                captureException(result.reason);
            }
        });
    }, [monitoring.baseUrl]);

    const logout = useCallback(async () => {
        await auth0Client.logout({
            logoutParams: {
                returnTo: `${window.origin}/auth/logout-callback`,
            },
        });
    }, [auth0Client]);

    const processLogout = useCallback(async () => {
        dispatch({ type: 'LOGOUT' });

        if (shouldNotifyLogout) {
            await notifyExternalServicesOnLogout();
        }

        setSentryUser();
        analyticsClient.setUserId(null);
    }, [dispatch, notifyExternalServicesOnLogout, shouldNotifyLogout]);

    // The event is fired using browser CustomEvent.
    // see: reducer.dispatchAction()
    const handleAction = useCallback(
        ({ detail }: CustomEvent<Action>) => {
            if (detail.type === 'LOGOUT') {
                void processLogout();
                return;
            }

            if (detail.type === 'UPDATE_SESSION') {
                const { userId } = detail.session;
                setSentryUser(userId);
                analyticsClient.setUserId(userId);
                analyticsClient.sessionStart(userId);
            }

            dispatch(detail);
        },
        [dispatch, processLogout],
    );

    // Updates reducer state when local storage data is changed in the separate browser tab.
    const handleLocalStorageChange = useCallback(
        (e: StorageEvent) => {
            if (e.key !== SESSION_KEY || e.newValue === null) {
                return;
            }

            try {
                const { session } = JSON.parse(e.newValue);

                if (!session) {
                    void processLogout();
                    return;
                }

                if (!e.oldValue) {
                    dispatch({ type: 'UPDATE_SESSION', session });
                }
            } catch (e) {
                void processLogout();
                captureException(e);
            }
        },
        [dispatch, processLogout],
    );

    useEffect(() => {
        document.addEventListener(EVENT_NAME, handleAction);

        return () => {
            document.removeEventListener(EVENT_NAME, handleAction);
        };
    }, [handleAction]);

    useEffect(() => {
        window.addEventListener('storage', handleLocalStorageChange);

        return () => {
            window.removeEventListener('storage', handleLocalStorageChange);
        };
    }, [handleLocalStorageChange]);

    const handleLogin = useCallback(
        async ({ redirectUrl, ...opts }: LoginOptions = {}) => {
            try {
                // clear session in localStorage to prevent requests with an old token
                // but keep in redux to prevent showing the login form before the redirect
                clearSessionInLocalStorage();

                const locationQuery = qs.parse(window.location.search.substring(1));
                const redirectQuery = { redirectUrl: redirectUrl ?? locationQuery.redirectUrl };
                const redirectUri = `${window.location.origin}/auth/callback?${qs.stringify(
                    redirectQuery,
                )}`;

                await auth0Client.loginWithRedirect({
                    authorizationParams: {
                        redirect_uri: redirectUri,
                        origin: window.location.origin,
                        locale,
                        ...opts,
                    },
                });
            } catch (e) {
                setError(e);
            }
        },
        [auth0Client, locale],
    );

    const processAuthentication = useCallback(async () => {
        try {
            await auth0Client.handleRedirectCallback();

            const authToken = await auth0Client.getTokenSilently();
            const tokenClaims = await auth0Client.getIdTokenClaims();

            if (!tokenClaims) {
                throw new Error('Failed to retrieve auth token claims.');
            }

            const session = toSession(authToken, tokenClaims);

            dispatch({ type: 'LOGIN', session });

            return session;
        } catch (e) {
            const ex = parseEmailNotVerifiedJson(e) ?? e;

            setError(ex);

            throw ex;
        }
    }, [auth0Client, dispatch]);

    const contextValue = useMemo(
        () => ({
            ...state,
            error,
            login: handleLogin,
            processAuthentication,
            processLogout,
            logout,
            notifyExternalServicesOnLogout,
        }),
        [
            state,
            error,
            handleLogin,
            processAuthentication,
            processLogout,
            logout,
            notifyExternalServicesOnLogout,
        ],
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
