// Copyright 2024. WebPros International GmbH. All rights reserved.

import { NavLink } from 'react-router-dom';
import { MenuHeader, MenuItem } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { BaseResultGroupProps } from '@platform360/server-inventory/web/components/GlobalSearch/types';
import { Server } from '@platform360/server-inventory/web/types';

const filterUrl = (search: string) => `/si/servers?${new URLSearchParams({ search }).toString()}`;

type ServersResultGroupProps = BaseResultGroupProps & {
    servers: Server[];
};

const ServersResultGroup = ({
    onClick,
    servers,
    totalCount,
    searchQuery,
}: ServersResultGroupProps) => {
    const translate = useTranslate('Page.GlobalSearch.ServersResultGroup');
    const showMore = totalCount - servers.length;

    return (
        <>
            <MenuHeader>{translate('servers')}</MenuHeader>
            {servers.map(({ address: { hostname }, id }) => (
                <MenuItem key={id} component={NavLink} to={filterUrl(hostname)} onClick={onClick}>
                    {hostname}
                </MenuItem>
            ))}
            {Boolean(showMore) && (
                <MenuItem component={NavLink} to={filterUrl(searchQuery)} onClick={onClick}>
                    {translate('serversShowMore', { showMore })}
                </MenuItem>
            )}
        </>
    );
};

export default ServersResultGroup;
