// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './StatusMessageButtons.module.css';

export type StatusMessageButtonsProps = {
    children: ReactNode;
};

const StatusMessageButtons = ({ ...props }: StatusMessageButtonsProps) => (
    <div className={styles.root} {...props} />
);

export default StatusMessageButtons;
