// Copyright 2024. WebPros International GmbH. All rights reserved.

import Layout from '@platform360/web-installer/web/components/Layout';
import ServerInstallationPage from '@platform360/web-installer/web/components/ServerInstallationPage';

const CpanelHomePage = () => (
    <Layout>
        <ServerInstallationPage productType="cpanel" />
    </Layout>
);

export default CpanelHomePage;
