// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';

export class HomePageAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    public documentationLinkClicked() {
        this.client.logEvent('Documentation Link Clicked');
    }
}
