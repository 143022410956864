// Copyright 2024. WebPros International GmbH. All rights reserved.
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Installation } from '@platform360/security-dashboard/web/types';
import { ReactElement } from 'react';
import { Paragraph, PopoverProps } from '@plesk/ui-library';

type DisableProtectionButtonProps = {
    installation: Installation;
    onButtonClick: (withConfirmation: boolean) => void;
    onConfirm: () => void;
    handleSwitchProtection: () => void;
    target: ReactElement<{ onClick: () => void }>;
    placement?: PopoverProps['placement'];
};

const DisableProtectionButton = ({
    installation,
    onButtonClick,
    onConfirm,
    handleSwitchProtection,
    target,
    ...props
}: DisableProtectionButtonProps) => {
    const translate = useTranslate('security-dashboard.Installations.DisableProtectionButton');

    if (!installation.server.protected) {
        return (
            <span
                onClick={() => {
                    onButtonClick(false);
                    handleSwitchProtection();
                }}
            >
                {target}
            </span>
        );
    }

    return (
        <ConfirmationPopover
            onClick={() => {
                onConfirm();
                handleSwitchProtection();
                target.props.onClick();
            }}
            onShow={() => onButtonClick(true)}
            target={target}
            actionButtonText={translate('confirmButton')}
            {...props}
        >
            <Paragraph>{translate('confirmationText')}</Paragraph>
        </ConfirmationPopover>
    );
};

export default DisableProtectionButton;
