// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TemplateName } from '@platform360/libs/common/emails/emails';
import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

/**
 * Notification categories enumeration implementation.
 */
export const { NotificationCategorys: NotificationCategories, notificationCategoryValues } =
    makeEnum('NotificationCategory', 'Information', 'Warning', 'Alert', 'Promotion', 'Feedback');
export type NotificationCategory = UnionOf<typeof notificationCategoryValues>;

/**
 * Mapping of supported notification codes into notification parameters.
 */
export type NotificationsParams = {
    newExploitedVulnerabilities: {
        count: number;
        link: string;
    };
    licenseCreated: {
        link: string;
        licenseKey: number;
        licenseName: string;
    };
    licenseLimitReached: {
        link: string;
        limit: number;
        total: number;
    };
    vpsLicenseLimitReached: {
        link: string;
        limit: number;
        total: number;
    };
    licenseExpired: {
        link: string;
        dateExpired: string;
    };
    vpsLicenseExpired: {
        link: string;
        dateExpired: string;
    };
    licenseTerminated: {
        link: string;
    };
    vpsLicenseTerminated: {
        link: string;
    };
    retailLicenseTerminated: {
        link: string;
    };
    trialCreated: {
        link: string;
        dateExpired: string;
        sitesLimit: number;
    };
    trialExpired: {
        link: string;
    };
    retailTrialExpired: {
        link: string;
    };
    trialExpiredSoon: {
        link: string;
        expirationDate: string;
    };
};

/**
 * Union of supported notifications codes.
 */
export type NotificationCode = keyof NotificationsParams;

/**
 * Mapping of notification code into category.
 */
export const notificationsCategories: Record<NotificationCode, NotificationCategory> = {
    newExploitedVulnerabilities: NotificationCategories.alert,
    licenseCreated: NotificationCategories.information,
    licenseLimitReached: NotificationCategories.warning,
    vpsLicenseLimitReached: NotificationCategories.warning,
    licenseExpired: NotificationCategories.alert,
    vpsLicenseExpired: NotificationCategories.alert,
    licenseTerminated: NotificationCategories.alert,
    vpsLicenseTerminated: NotificationCategories.alert,
    retailLicenseTerminated: NotificationCategories.alert,
    trialCreated: NotificationCategories.information,
    trialExpired: NotificationCategories.alert,
    retailTrialExpired: NotificationCategories.alert,
    trialExpiredSoon: NotificationCategories.warning,
};

/**
 * Email templates for notifications.
 */
export const newExploitedVulnerabilities: TemplateName = 'new-exploited-vulnerabilities';
export const licenseCreated: TemplateName = 'license-created';
export const licenseLimitReached: TemplateName = 'license-limit-reached';
export const vpsLicenseLimitReached: TemplateName = 'vps-license-limit-reached';
export const licenseExpired: TemplateName = 'license-expired';
export const vpsLicenseExpired: TemplateName = 'vps-license-expired';
export const licenseTerminated: TemplateName = 'license-terminated';
export const vpsLicenseTerminated: TemplateName = 'vps-license-terminated';
export const retailLicenseTerminated: TemplateName = 'retail-license-terminated';
export const trialCreated: TemplateName = 'trial-created';
export const trialExpired: TemplateName = 'trial-expired';
export const retailTrialExpired: TemplateName = 'retail-trial-expired';
export const trialExpiredSoon: TemplateName = 'trial-expired-soon';

export const notificationsEmailTemplates = {
    newExploitedVulnerabilities,
    licenseCreated,
    licenseLimitReached,
    vpsLicenseLimitReached,
    licenseExpired,
    licenseTerminated,
    trialCreated,
    trialExpired,
    retailTrialExpired,
    trialExpiredSoon,
    vpsLicenseExpired,
    vpsLicenseTerminated,
    retailLicenseTerminated,
} as const;
