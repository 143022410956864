// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    InstallationVulnerability,
    Vulnerability,
} from '@platform360/security-dashboard/web/types';
import { ignoreVulnerabilityLabelTypes } from '@platform360/security-dashboard/shared/label-type';

export const isIgnoredVulnerability = ({ labels }: Vulnerability | InstallationVulnerability) =>
    labels.some((l) => ignoreVulnerabilityLabelTypes.includes(l.type));
