// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { getConsentsToSend } from './getConsentsToSend';
import SignUpForm, { FormValues } from './SignUpForm';
import SignUpVerification from './SignUpVerification';
import { useAuthAnalyticsEvents } from '@platform360/auth/web/helpers/analytics';
import getUrlPathname from '@platform360/libs/shared-web/helpers/getUrlPathname';
import useConsentItems from '@platform360/libs/shared-web/helpers/useConsentItems';
import { Drawer } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export const SignUp = () => {
    const translate = useTranslate('Auth.SignUp');
    const knownConsents = useConsentItems();

    const [isVerificationOpen, setVerificationOpen] = useState(false);
    const openVerification = () => setVerificationOpen(true);
    const closeVerification = () => setVerificationOpen(false);

    const [email, setEmail] = useState<string>('');
    const analyticsEvents = useAuthAnalyticsEvents();
    const searchParams = new URLSearchParams(window.location.search);
    const {
        auth: {
            areConsentsShown,
            signUpEmailFieldDescriptionShown,
            branding,
            signUpMutation: useSignUp,
        },
    } = useApplicationSettings();
    const clientId = searchParams.get('clientId') ?? undefined;
    // The `acquisitionSource` provided by useScenario() is only used by withAuth()
    // HOC to deduce acquisition source from URL path and append it to the URL query.
    // Here we should get it from the URL query.
    const acquisitionSource = searchParams.get('acquisitionSource') ?? undefined;
    const redirectUrl = searchParams.get('redirectUrl') ?? undefined;

    const reportUserSignUp = (redirectUrl: string) => {
        switch (getUrlPathname(redirectUrl)) {
            case '/monitoring/promo':
                analyticsEvents.monitoringPromoUserSignUp();
                break;
            case '/digitalocean/login':
            case '/digitalocean/sign-up':
                analyticsEvents.digitalOceanUserSignUp();
                break;
        }
    };

    const {
        mutate: signUpUser,
        errors,
        isPending: isLoading,
    } = useSignUp(() => {
        openVerification();
        reportUserSignUp(redirectUrl || '');
    });

    const handleSignUp = ({ privacyPolicyAgreement, consents, ...rest }: FormValues) => {
        if (!privacyPolicyAgreement) {
            return;
        }

        signUpUser({
            ...rest,
            consents: getConsentsToSend(knownConsents, consents),
            redirectUrl,
            branding,
            clientId,
            acquisitionSource,
        });
    };

    return (
        <>
            <SignUpForm
                onSubmit={handleSignUp}
                onEmailChange={setEmail}
                consentItems={areConsentsShown ? knownConsents : []}
                isLoading={isLoading}
                errors={errors}
                emailFieldDescription={
                    signUpEmailFieldDescriptionShown && translate('emailDescription')
                }
            />
            <Drawer
                size="xs"
                isOpen={isVerificationOpen}
                title={translate('verifyAccountTitle')}
                onClose={closeVerification}
                data-type="sign-up"
            >
                <SignUpVerification email={email} branding={branding} clientId={clientId} />
            </Drawer>
        </>
    );
};

export default SignUp;
