// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        ispublicPrivateKeyError: '秘密鍵が期待されていますが、公開鍵が検出されました。',
        passphrasePrivateKeyError: 'パスフレーズで保護されていない鍵を提供してください。',
        unsupportedPrivateKeyError: '提供された鍵のタイプはサポートされていません。',
        invalidPrivateKeyError: '正しい SSH 秘密鍵を使用していることを確認してください。',
        emptyPrivateKeyError: '提供された SSH 秘密鍵は無効です。この鍵は空です。',
        tooBigPrivateKeyError: '30 KB 未満の鍵ファイルを提供してください。',
    },
    Installation: {
        startAnotherInstallation: '別のインストールを開始',
        steps: {
            running: {
                title: 'Plesk はインストール中です',
                description: '<b>{host}</b> に Plesk をインストール',
            },
            error: {
                title: 'インストールエラー',
            },
            completed: {
                title: 'インストールが完了しました',
                pleskHostText: 'Plesk が {host} にインストールされました。',
                goToPleskText: '［Plesk に移動］をクリックしてログインしてください。簡単なセットアッププロセスの後、Plesk の使用を開始できます。',
                goToPleskButton: 'Plesk に移動',
            },
        },
    },
    useTranslateInstallationError: {
        sshDisconnect: {
            title: 'サーバが接続解除されました',
            description: 'サーバへの SSH 接続が失われました。',
        },
        connectFail: {
            title: 'サーバ接続に失敗しました',
            description: '<p>サーバに接続できません：{details}。</p><p>このサーバでカスタム SSH ポートを使用している場合、ホスト名または IP アドレスの後にコロンとポート番号を追加することでポートを指定してください（例：example.com:1024、192.0.2.0:1024）。</p>',
        },
        pleskDetectError: {
            title: 'Plesk はインストール済みです',
            description: 'Plesk がインストールされているか検出中に、予期しないエラーが発生しました：{details}。',
        },
        autoinstallUnavailable: {
            title: 'autoinstall.plesk.com がありません',
            description: 'Plesk 配布キットを取得できませんでした。ウェブサイト autoinstall.plesk.com が利用できません：{details}。',
        },
        pleskInstalled: {
            title: 'Plesk がインストール済み',
            description: 'ウェブインストーラは {productType} のインストールのみを行うツールですが、<b>{host}</b> には既に安定バージョンの Plesk がインストールされています。{productType, select, Plesk {インストーラで他の作業を実行することをお望みだった場合、<surveyLink>こちらで質問に答えて</surveyLink>その内容をお知らせください。} other {}}',
        },
        pleskOutdated: {
            title: 'アップグレード可能です',
            description: 'ウェブインストーラは {productType} のインストールのみを行うツールですが、<b>{host}</b> には既に、最新の安定バージョンではない Plesk がインストールされています。{productType, select, Plesk {ウェブインストーラでアップグレードの実行をお望みの場合、<surveyLink>こちらの質問に答えるかたちで</surveyLink>その内容をお知らせください。} other {}}',
        },
        unsupportedArch: {
            title: 'サポート対象外のサーバアーキテクチャ',
            description: 'サポート対象外のアーキテクチャに {productType} をインストールしようとしています：{arch}。',
        },
        unsupportedOs: {
            title: 'サポートされない OS',
            description: 'サポート対象外のオペレーティングシステム（アーキテクチャ）に {productType} をインストールしようとしています：{os}。<supportedOsLink>こちら</supportedOsLink>でサポートされるオペレーティングシステムをご確認ください。',
        },
        notEnoughDiskSpace: {
            title: '空きディスク容量が十分にありません',
        },
        cpanelInstalled: {
            title: 'cPanel がインストール済み',
            description: 'ウェブインストーラはクリーンサーバへの {productType} のインストールのみを行うツールですが、<b>{host}</b> には既に cPanel がインストールされています。{productType, select, Plesk {<migrationGuideLink>移行ガイド</migrationGuideLink>をお読みになり、ホストされているウェブサイトを Plesk に移管する方法をご確認ください。} other {インストーラで他の作業を実行することをお望みの場合、<surveyLink>こちらの質問に回答するかたちで</surveyLink>その内容をお知らせください。}}',
        },
        packageManagerBusy: {
            title: 'パッケージマネージャのステータスを確認',
            description: 'パッケージマネージャがビジー状態か応答していません：{details}。',
        },
        packageManagerConfigurationFailed: {
            title: 'パッケージマネージャのリポジトリ構成に失敗しました',
            description: 'パッケージマネージャのリポジトリを構成中に、予期しないエラーが発生しました：{details}。',
        },
        dbConfigurationFailed: {
            title: 'データベースの構成に失敗しました',
        },
    },
    CustomInstallationForm: {
        description: {
            p1: '完全に安全です。インストールの完了後にユーザのログインやパスワードが WebPros に保存されることはありません。',
            p2: '<a>サポート対象の任意の Linux ベース OS</a> に {productType} をインストールできます。',
        },
        hostLabel: 'サーバの IP アドレスまたはホスト名',
        portLabel: 'ポート',
        loginLabel: 'SSH ログイン',
        installationMethodLabel: 'サーバの接続に使用：',
        sshPasswordLabel: 'SSH パスワード',
        sshKeyLabel: 'SSH 秘密鍵',
        licenseTypeLabel: 'ライセンスタイプを選択',
        trialLicenseLabel: 'すべての機能を使用できる評価版ライセンスで続ける',
        trialLicenseDescription: 'WebPros International GmbH は、評価版ライセンスを発行するために、<privacyPolicyLink>WebPros プライバシーポリシー</privacyPolicyLink>に従い、提供された情報を内部システムで使用、処理します。',
        paidLicenseLabel: '有料ライセンスで続ける',
        paidLicenseDescription: '購入済みの {productType} ライセンスがある場合、受信したアクティベーションコードをここに貼り付けてください。購入していない場合、<onlineStoreLink>オンラインストア</onlineStoreLink>で新規ライセンスを購入できます。',
        installOsUpdatesLabel: '{productType} のインストールを開始する前に OS アップデートをインストールします',
        installOsUpdatesDescription: 'このオプションを選択すると、インストールプロセスが極端に遅くなり、インストールの失敗につながる場合もあります。',
        notice: 'インストールは通常約 15 分ほどかかります。',
        techDomainDescription: 'サーバは <b>{techDomain}</b> 名で追加されます。',
        serverConnectionSection: 'サーバ接続',
        licenseSection: 'ライセンス',
        advancedSection: '高度',
        submitButton: '次へ',
    },
    ServerInstallationPage: {
        submitButton: '次へ',
        panelTitle: 'Linux サーバへの {productType} の自動インストール',
        licenceLabel: '体験版ライセンス',
        installationWillTake: 'インストールは通常約 15 分ほどかかります',
        helpUsImprove: '{productType} ウェブインストーラの改善にご協力ください',
        takeOurSurvey: 'アンケートに答える',
        links: {
            manualInstallationInstructions: '手動インストールの手順',
            softwareRequirements: {
                plesk: 'ソフトウェア要件',
                cpanel: 'システム要件',
            },
            versionsAndComponents: 'バージョンとコンポーネント',
            knownIssues: '既知の問題',
        },
    },
    EmailConfirmationPage: {
        panelTitle: 'Create Plesk 360 Account',
        submitButton: 'Create Account',
        yourEmail: 'メール',
        legalAgreementTitle: '<termsOfUseLink>利用規約</termsOfUseLink>、<privacyPolicyLink>プライバシーポリシー</privacyPolicyLink>に同意し、Plesk <dpaLink>データ処理契約</dpaLink>に署名することに同意します',
        emailHint: '既に WebPros アカウントがある場合、このメールアドレスを使用してください。アカウントがない場合はアカウントが作成されます。',
        serversMonitoring: '1 つのサーバと 5 つのウェブサイトを対象に、サーバの可用性、健全性、パフォーマンスを無料でモニタリング',
        loginNoCredentials: 'WebPros アカウントを作成して、すべての会社のサービスにワンクリックで簡単にアクセス可能',
        login: 'ログイン情報を入力せずに {productType} インストールにログイン',
        hosted: 'Search for your servers, websites, and clients across the Server Inventory',
    },
    UserDataConfirmationPage: {
        panelTitle: 'Enter your full name',
        firstName: '名',
        lastName: '姓',
        submitButton: 'インストールを開始',
        marketingConsent: {
            title: 'WebPros は WebPros の<a>プライバシーポリシー</a>に従ってニュースレターと個別オファーを提供することができます。',
            text: '私は、受信した任意のコミュニケーションでリンクをクリックすることにより、この同意を撤回できるものとします。',
        },
    },
    LightsailInstallation: {
        title: 'クラウドサーバへの自動インストール',
        awsAccessKey: 'アクセスキー ID',
        awsSecretKey: 'シークレットアクセスキー',
        region: '地域',
        bundleTitle: 'インスタンスプラン',
        publicKeyTitle: 'SSH 公開キー',
        instanceName: 'インスタンス名',
        submitButton: '続ける',
        BundleSelect: {
            price: '${value} / 月', // eslint-disable-line no-template-curly-in-string
            cpu: '{value} vCPU',
            memory: 'メモリ {value} GB',
            storage: 'ストレージ {value} GB',
            transfer: '転送 {value} GB',
            descriptions: {
                micro: 'Plesk を試すのに最適です。',
                small: '担当者が 1 人以上のウェブサイト向け。',
                medium: '小数のウェブサイトを管理しているウェブプロまたはウェブエージェンシー向け。',
                large: '複数の低トラフィックウェブサイトまたは小数の高トラフィックウェブサイトに集中する大型ウェブエージェンシーまたは小型ホスティング事業者向け。',
                xlarge: '複数の高トラフィック・高負荷ウェブサイトを管理するホスティング事業者向け。',
                '2xlarge': '最も意欲的なプロジェクト向け。',
            },
        },
    },
    CPanelOneTimeLoginAlert: {
        loginMessage: 'cPanel アカウントにログインするには、[cPanel にログイン] ボタンをクリックしてください。<b>注:</b> このボタンは一度だけ機能します。',
        loginButton: 'cPanel にログイン',
    },
    AutoInstallationFAQ: {
        plesk: {
            header: 'Plesk 自動インストールの FAQ',
            versionsAndComponents: 'バージョンとコンポーネント',
            knownIssues: '既知の問題',
            whatVersionIsInstalledTitle: '自動モードでは、どの Plesk バージョンがインストールされますか？',
            whatVersionIsInstalledText: '最新の安定バージョンの Plesk と現時点で利用可能なすべてのアップデートです。別の Plesk バージョンをインストールするには、<link>インストール手順</link>に従ってください。',
            whatComponentsAreInstalledTitle: '自動モードでは、どのコンポーネントがインストールされますか？',
            whatComponentsAreInstalledText: '「推奨プリセット」には、ウェブサイトのホスティングに必要なすべてのサービス (ウェブ、DNS、FTP、メール) と、WordPress Toolkit、Advisor、Let\'s Encrypt 拡張などの最も人気のあるコンポーネントや拡張が含まれています。',
            canISelectComponentsTitle: 'インストールする Plesk コンポーネントを選択できますか？',
            canISelectComponentsText: '現時点ではできません。当社はこの制限について認識しており、将来的に対処する予定です。現時点での回避策としては、インストールプロセスが終了した後に必要なコンポーネントを追加または削除してください。',
            doesInstalledPleskHaveLicenseTitle: 'Plesk はライセンス付きでインストールされますか？',
            doesInstalledPleskHaveLicenseText: 'Web Pro Edition の 14 日間の個人的なトライアルを、(まだご利用いただいていなければ) Plesk への初回ログイン後すぐにお申し込みいただけます。また、ご購入いただいたライセンスを使用することもできます。',
            supportedOSsTitle: '自動モードではどの OS がサポートされますか？Plesk for Windows をインストールできますか？',
            supportedOSsText: '<a>Plesk でサポートされるすべての Linux ベースの OS</a> です。Plesk for Windows をインストールするには、<link>インストール手順</link>に従ってください。',
            usePrivateKeyTitle: '暗号化されたプライベート SSH キーを使用できますか？',
            usePrivateKeyText: '現時点ではご利用いただけません。当社はこの制限について認識しており、将来的に対応する予定です。',
            isItSafeToSubmitPasswordTitle: 'Plesk を自動モードでインストールする際、パスワードやプライベート SSH キーを送信しても安全ですか？',
            isItSafeToSubmitPasswordText: 'はい。これらの認証情報は、サーバへの接続と Plesk のインストールにのみ使用され、保存されることはありません。すべてのデータは、暗号化された SSH チャネルおよび HTTPS チャネルを介して安全に送信されます。セキュリティを最大限に高めるには、インストール後にサーバへのアクセス情報を変更することを検討してください。',
            helpUsMakeWebInstallerBetter: 'Plesk Web Installer をすべてのユーザーにとって使いやすいものに改善するために、<a>簡単なアンケートにご回答ください</a>。',
        },
        cpanel: {
            header: 'cPanel 自動インストールの FAQ',
            versionsAndComponents: 'バージョンとコンポーネント',
            knownIssues: '既知の問題',
            whatVersionIsInstalledTitle: '自動モードでは、どの cPanel バージョンがインストールされますか？',
            whatVersionIsInstalledText: '最新バージョンの cPanel と現時点で利用可能なすべてのアップデートです。別の cPanel バージョンをインストールするには、<link>インストール手順</link>に従ってください。',
            canICustomizeParametersOfInstallationTitle: 'インストールパラメータをカスタマイズできますか？',
            canICustomizeParametersOfInstallationText: '現在のところ、Web Installer を使用してインストールをカスタマイズする方法はありません。インストールをカスタマイズするには、<link>こちらのドキュメント</link>の指示に従ってください。',
            doesInstalledCpanelHaveLicenseTitle: 'cPanel はライセンス付きでインストールされますか？',
            doesInstalledCpanelHaveLicenseText: '新しい cPanel & WHM インスタンスをサーバにインストールしている場合、15 日間のトライアルをご利用いただけます。トライアルライセンスを申し込むには、<link>こちらのガイド</link>の指示に従ってください。購入されたライセンスを使用することもできます。',
            supportedOSsTitle: '自動モードでは、どの OS がサポートされますか？',
            supportedOSsText: '<link>cPanel でサポートされる</link>すべての Linux ベースの OS です。',
            usePrivateKeyTitle: '暗号化されたプライベート SSH キーを使用できますか？',
            usePrivateKeyText: '現時点ではご利用いただけません。当社はこの制限について認識しており、将来的に対応する予定です。',
            isItSafeToSubmitPasswordTitle: 'cPanel を自動モードでインストールする際、パスワードやプライベート SSH キーを送信しても安全ですか？',
            isItSafeToSubmitPasswordText: 'はい。これらの認証情報は、サーバへの接続と cPanel のインストールにのみ使用され、保存されることはありません。すべてのデータは、暗号化された SSH チャネルおよび HTTPS チャネルを介して安全に送信されます。セキュリティを最大限に高めるには、インストール後にサーバへのアクセス情報を変更することを検討してください。',
            helpUsMakeWebInstallerBetter: 'cPanel Web Installer をすべてのユーザーにとって使いやすいものに改善するために、<a>簡単なアンケートにご回答ください</a>。',
        },
    },
};