// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    AVAILABLE_RETAIL_LICENSES,
    RetailLicenseData,
} from '@platform360/security-dashboard/web/constants/licenses';

export const getRecommendedRetailLicense = (
    totalInstallationsCount: number,
): RetailLicenseData | undefined => {
    if (totalInstallationsCount === 0) {
        return undefined;
    }
    return AVAILABLE_RETAIL_LICENSES.find(({ sites }) => sites >= totalInstallationsCount);
};
