// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    FAILED_INITIALIZATION,
    INITIALIZATION,
    INITIALIZED,
} from '@platform360/licenses/web/units/adjust/constants/actions';
import { Properties, Property } from '@platform360/licenses/web/api/ka/responseTypes/Property';
import { NewValuesOfProducts } from '@platform360/licenses/web/units/adjust/reducer/initialState';

export type InitializationAction = {
    type: typeof INITIALIZATION;
    payload: { licenseId: number };
};

export const initialization = (licenseId: number): InitializationAction => ({
    type: INITIALIZATION,
    payload: { licenseId },
});

export type InitializedActionPayload = {
    licenseId: number;
    subscriptionId: string;
    isAdjustPending: boolean;
    propertiesOfLicense: Properties;
    propertiesForChange: Property[];
    newValues: Record<number, NewValuesOfProducts>;
};

export type InitializedAction = {
    type: typeof INITIALIZED;
    payload: InitializedActionPayload;
};

export const initialized = ({
    licenseId,
    subscriptionId,
    isAdjustPending,
    propertiesOfLicense,
    propertiesForChange,
    newValues,
}: InitializedActionPayload): InitializedAction => ({
    type: INITIALIZED,
    payload: {
        licenseId,
        subscriptionId,
        isAdjustPending,
        propertiesOfLicense,
        propertiesForChange,
        newValues,
    },
});

export type FailedInitializationAction = {
    type: typeof FAILED_INITIALIZATION;
};

export const failedInitialization = (): FailedInitializationAction => ({
    type: FAILED_INITIALIZATION,
});
