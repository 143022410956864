// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Text, ListActions, ListAction } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DataList from '@platform360/libs/shared-web/components/DataList';
import {
    Domain,
    HOSTING_TYPE_VIRTUAL,
    HOSTING_TYPE_STANDARD_FORWARDING,
    HOSTING_TYPE_FRAME_FORWARDING,
    HOSTING_TYPE_NONE,
    DOMAIN_TYPE_MAIN,
    DOMAIN_TYPE_SUB,
    DOMAIN_TYPE_ADDON,
    DOMAIN_TYPE_PARKED,
    isPleskFields,
} from '@platform360/server-inventory/web/types';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import useServerDomainsQuery from '@platform360/server-inventory/web/queries/useServerDomainsQuery';
import { useParams } from 'react-router-dom';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';

const hostingTypeTitles = {
    [HOSTING_TYPE_VIRTUAL]: 'virtualHostingType',
    [HOSTING_TYPE_STANDARD_FORWARDING]: 'forwardingHostingType',
    [HOSTING_TYPE_FRAME_FORWARDING]: 'forwardingHostingType',
    [HOSTING_TYPE_NONE]: 'noneHostingType',
};

const domainTypeTitles = {
    [DOMAIN_TYPE_MAIN]: 'mainDomainType',
    [DOMAIN_TYPE_SUB]: 'subDomainType',
    [DOMAIN_TYPE_ADDON]: 'addonDomainType',
    [DOMAIN_TYPE_PARKED]: 'parkedDomainType',
};

const Domains = () => {
    const translate = useTranslate('ViewServer.Domains');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { serverId } = useParams<{ serverId: string }>();
    const pagination = usePagination('si-domains');
    const search = useSearch();
    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useServerDomainsQuery({
        variables: {
            // Parent ViewServer component checks serverId is valid
            serverId: Number(serverId),
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
            filter: search.debouncedValue,
        },
        placeholderData: (prev) => prev,
    });

    const onSearch = (term: string) => {
        if (term) {
            analyticsEvents.searchDomains();
        }
        search.onSearch(term);
    };

    return (
        <DataList<Domain>
            data={data?.data}
            totalRows={data?.totalCount}
            isPreviousData={isPreviousData}
            loading={isLoading}
            pagination={pagination}
            search={{
                value: search.value,
                debouncedValue: search.debouncedValue,
                onSearch,
            }}
            columns={[
                {
                    key: 'name',
                    type: 'title',
                    title: translate('name'),
                    render: ({ name }) => <Text bold>{name}</Text>,
                },
                {
                    key: 'hostingType',
                    title: translate('hostingType'),
                    render: ({ fields }) =>
                        isPleskFields(fields)
                            ? translate(
                                  hostingTypeTitles[fields.hostingType],
                                  {},
                                  fields.hostingType,
                              )
                            : translate(domainTypeTitles[fields.domainType], {}, fields.domainType),
                },
                {
                    key: 'actions',
                    type: 'actions',
                    render: ({ id, name, fields }) => (
                        <ListActions>
                            <ListAction
                                icon="web"
                                primary
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`http://${name}`}
                                onClick={() => {
                                    analyticsEvents.serverWebsitesOpenClicked();
                                }}
                            >
                                {translate('open')}
                            </ListAction>
                            <ListAction
                                icon="sliders"
                                primary
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/si/servers/${serverId}/login/${LoginTargets.domain}?domainId=${id}`}
                                data-type="manage-domain-link"
                                disabled={
                                    isPleskFields(fields) ? fields.baseDomainId === null : undefined
                                }
                                onClick={() => {
                                    analyticsEvents.serverWebsitesManageClicked();
                                }}
                            >
                                {translate(
                                    isPleskFields(fields) && fields.baseDomainId === null
                                        ? 'manageLinkUnavailableHint'
                                        : 'manage',
                                )}
                            </ListAction>
                        </ListActions>
                    ),
                },
            ]}
        />
    );
};

export default Domains;
