// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useLocalStorage } from 'usehooks-ts';
import { useCallback } from 'react';

export const isSafariBrowser = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari/') && !userAgent.includes('chrome/');
};

export const useDarkTheme = () => {
    const [isDarkMode, setDarkMode] = useLocalStorage<boolean>('usehooks-ts-dark-mode', false);

    const toggle = useCallback(() => setDarkMode((x) => !x), [setDarkMode]);

    return {
        isDarkMode: isSafariBrowser() ? false : isDarkMode,
        isSupported: !isSafariBrowser(),
        toggle,
    };
};

export default useDarkTheme;
