// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useEmailMetasQuery } from '@platform360/admin-panel/web/queries';
import { useDeleteEmailMetaMutation } from '@platform360/admin-panel/web/mutations';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { Heading, SearchBar } from '@plesk/ui-library';
import useSearchByState from './useSearchByState';
import EmailMetaCard from './EmailMetaCard';
import EmptyView from './EmptyView';

export const EmailMetas = () => {
    const translate = useTranslate('admin-panel.EmailMetas');
    const { debouncedValue: filter, onSearch } = useSearchByState();
    const { successToast } = useToaster();
    const { data = [], isLoading } = useEmailMetasQuery({
        variables: {
            filter,
        },
    });

    const { mutate: deleteEmailMeta, isPending: isDeleting } = useDeleteEmailMetaMutation({
        onSuccess: () => {
            successToast(translate('successfullyRemovedToast'));
        },
    });

    return (
        <>
            <Heading>{translate('title')}</Heading>
            <SearchBar
                loading={isLoading}
                onTyping={onSearch}
                inputProps={{
                    placeholder: translate('searchPlaceholder'),
                    autoFocus: true,
                }}
            />
            {data.length === 0 && !isLoading && <EmptyView notFound={!!filter} />}
            <br />
            {data.map((emailMeta) => (
                <EmailMetaCard
                    key={emailMeta.email}
                    data={emailMeta}
                    isDeleting={isDeleting}
                    onEmailMetaDelete={deleteEmailMeta}
                />
            ))}
        </>
    );
};
