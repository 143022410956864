// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, VulnerabilityProvider } from '@platform360/security-dashboard/web/types';
import { Text, MenuItem } from '@plesk/ui-library';
import styles from './InfoColumn.module.css';
import { DateTime } from 'luxon';
import { VulnerabilitySources } from '@platform360/security-dashboard/web/components/Vulnerabilities/VulnerabilitySources';
import { VulnerabilityDescription } from '@platform360/security-dashboard/web/components/Vulnerabilities/VulnerabilityDescription';
import LabelsList from '@platform360/security-dashboard/web/components/LabelsList';
import {
    ignoreVulnerabilityLabelTypes,
    LabelTypes,
} from '@platform360/security-dashboard/shared/label-type';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import MitigatedLabel from './MitigatedLabel';

type VulnerabilityProps = {
    title: string;
    url: string;
    description: string;
    cve: string[];
    disclosedAt: DateTime | null;
    vulnerabilityId: string;
    labels?: Label[];
    providers?: VulnerabilityProvider[];
    mitigatedByProtectionCount: number;
    mitigatedByDeactivationCount: number;
    unknownMitigationsCount: number;
    installationsCount: number;
    mitigated: boolean;
};

export type InfoColumnProps = {
    vulnerability: VulnerabilityProps;
    vertical: boolean;
    onRemoveLabel?: (labelId: number) => void;
};

const REMOVABLE_LABEL_TYPES = [
    ...ignoreVulnerabilityLabelTypes,
    LabelTypes.doNotIgnoreAutomatically,
];

const InfoColumn = ({
    vulnerability: {
        title,
        description,
        disclosedAt,
        cve,
        url,
        labels = [],
        providers = [],
        mitigated,
        mitigatedByDeactivationCount,
        mitigatedByProtectionCount,
        installationsCount,
        unknownMitigationsCount,
    },
    onRemoveLabel,
}: InfoColumnProps) => {
    // Display only the first cve from the list if present.
    const cvePrefix = cve.length > 0 ? `${cve[0] ?? ''} ` : '';

    const translate = useTranslate(
        'security-dashboard.Vulnerabilities.InfoColumn.MitigatedLabel.tooltip',
    );

    const mitigatedByProtection = mitigatedByProtectionCount === installationsCount;
    const mitigatedByDeactivation = mitigatedByDeactivationCount === installationsCount;

    const hasUnknownMitigations = unknownMitigationsCount > 0;

    const severalMitigationsApplied =
        mitigatedByProtectionCount > 0 || mitigatedByDeactivationCount > 0;

    let mitigatedLabelReasonKey: string | undefined = undefined;

    if (mitigated && !mitigatedByProtection && !mitigatedByDeactivation) {
        if (installationsCount === 1) {
            mitigatedLabelReasonKey = 'singleSiteMitigatedByOther';
        } else if (hasUnknownMitigations) {
            mitigatedLabelReasonKey = severalMitigationsApplied
                ? 'mitigatedByOtherAndSeveral'
                : 'mitigatedByOther';
        } else if (severalMitigationsApplied) {
            mitigatedLabelReasonKey = 'mitigatedBySeveral';
        }
    }

    const mitigationLabels = (
        <>
            {mitigatedLabelReasonKey && (
                <MitigatedLabel tooltip={translate(mitigatedLabelReasonKey)} />
            )}
            {mitigatedByProtection && <MitigatedLabel mitigationType="protection" />}
            {mitigatedByDeactivation && <MitigatedLabel mitigationType="deactivation" />}
        </>
    );
    return (
        <div className={styles.content}>
            <Text bold component="div">
                {cvePrefix}
                {title}
            </Text>
            <VulnerabilityDescription description={description} providers={providers} />
            <VulnerabilitySources url={url} disclosedAt={disclosedAt} providers={providers} />
            <LabelsList
                mitigationLabels={mitigationLabels}
                labels={labels}
                renderMenuItems={({ label, localisation: { remove } }) => {
                    if (REMOVABLE_LABEL_TYPES.includes(label.type) && onRemoveLabel) {
                        return [
                            <MenuItem
                                key="remove"
                                onClick={() => onRemoveLabel(label.id)}
                                icon="circle-diagonal"
                            >
                                {remove}
                            </MenuItem>,
                        ];
                    }

                    return null;
                }}
            />
        </div>
    );
};

export default InfoColumn;
