// Copyright 2024. WebPros International GmbH. All rights reserved.

import UpdatesTab from '@platform360/security-dashboard/web/components/UpdatesTab';
import UpdateSettingsCustomizedCheckbox from '@platform360/security-dashboard/web/components/UpdateSettingsCustomizedCheckbox';
import styles from './Updates.module.css';

type UpdatesProps = {
    overrideDescendants: boolean;
    onOverrideDescendantsChange: (newValue: boolean) => void;
};

const Updates = ({ overrideDescendants, onOverrideDescendantsChange }: UpdatesProps) => (
    <>
        <UpdatesTab />
        <UpdateSettingsCustomizedCheckbox
            checked={overrideDescendants}
            onChange={onOverrideDescendantsChange}
            className={styles.customizedSection}
        />
    </>
);

export default Updates;
