// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, Text, Tooltip } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import style from './CurrentUsage.module.css';

type CurrentUsageProps = {
    total: number;
    sitesLimitHard?: number;
    outOfLimit?: number;
    notOperable?: number;
    terminated: boolean;
};
const CurrentUsage = ({
    total,
    outOfLimit,
    sitesLimitHard,
    terminated,
    notOperable,
}: CurrentUsageProps) => {
    const translate = useTranslate('security-dashboard.LicensePage.CurrentUsage');
    const showAllowedToManage = Boolean(sitesLimitHard) && !terminated;
    const showOutOfLimit = Boolean(outOfLimit) && !terminated;
    const showNotOperable = Boolean(notOperable) && !terminated;

    return (
        <div>
            <Text intent="muted" fontSize="sm">
                {translate('youAreUsingNow')}
            </Text>
            <div className={style.usage}>
                <Text bold>{translate('sitesTotal', { sites: total })}</Text>
                {showAllowedToManage && (
                    <Label
                        intent={outOfLimit === undefined ? undefined : 'success'}
                        view="light"
                        size="lg"
                    >
                        {translate('allowedToManage', { sites: sitesLimitHard })}
                    </Label>
                )}

                {showOutOfLimit && (
                    <Label intent="danger" view="light" size="lg">
                        {translate('outOfLimit', { sites: outOfLimit })}
                    </Label>
                )}

                {showNotOperable && (
                    <Tooltip title={translate('notOperableTooltip', { sites: notOperable })}>
                        <Label intent="inactive" view="light" size="lg">
                            {translate('notOperable', { sites: notOperable })}
                        </Label>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default CurrentUsage;
