// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'Diese E-Mail-Adresse ist bereits registriert.',
        linkEmailMinimalInterval: 'Nachrichten zu Aktionen für verknüpfte E-Mail-Adressen können nicht zu häufig gesendet werden.',
        linkEmailUnknownError: 'Ein Fehler ist aufgetreten. Überprüfen Sie Ihre Internetverbindung oder versuchen Sie es später erneut.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Abbruch der Aktivierung bestätigen',
                description: 'Sie sind dabei, die Aktivierung der {product}-Lizenz abzubrechen. Sie können die Aktivierung der Lizenz zu einem späteren Zeitpunkt fortsetzen, indem Sie erneut auf den Aktivierungslink klicken. Wenn die Lizenz für ein anderes Konto erworben wurde, melden Sie sich in diesem Konto an und klicken Sie auf den Aktivierungslink.',
                cancelButton: 'Abbrechen',
                continueButton: 'Aktivierung fortsetzen',
            },
            MonitoringActivateLicense: {
                title: 'Aktivierung der Monitoring-Lizenz bestätigen',
                description: 'Bitte bestätigen Sie, dass Sie die <b>{product}</b>-Lizenz für Ihr 360-Konto (<mailto>{userEmail}</mailto>) aktivieren möchten. Die Lizenz kann nur für ein 360-Konto aktiviert werden.',
                fixableErrorDescription: 'Bei der Aktivierung des Monitoring-Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder stellen Sie eine Supportanfrage.',
                nonFixableErrorDescription: 'Bei der Lizenzaktivierung ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Lizenzanbieter oder stellen Sie eine Supportanfrage.',
                loaderText: 'Ihr 360 Monitoring-Konto wird konfiguriert',
                redirectText: 'Sie werden zu 360 Monitoring weitergeleitet',
                confirmButton: 'Bestätigen',
                cancelButton: 'Abbrechen',
                retryButton: 'Erneut versuchen',
                gotItButton: 'OK',
                errors: {
                    linkError: 'Der Aktivierungslink ist nicht gültig.',
                    unknownError: 'Interner Servicefehler bei der Aktivierung.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'Fehler bei der Lizenzaktivierung',
            description: 'Der Aktivierungslink ist nicht korrekt. Bitte wenden Sie sich an den Lizenzanbieter oder stellen Sie eine Supportanfrage.',
            gotIt: 'OK',
        },
    },
};