// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: '法的情報',
        eula: 'EULA',
        pleskEula: 'Plesk EULA',
        cPanelEula: 'cPanel EULA',
        terms: '利用規約',
        privacyPolicy: 'プライバシーポリシー',
        dpa: 'データ処理契約',
        optOuts: 'オプトアウト',
        cookies: 'Cookie ステートメント',
        gaHeader: 'Google アナリティクスのオプトアウト',
        gaOptOut: 'Google アナリティクスからオプトアウトするには、こちらをクリックしてください',
        cookieHeader: 'Cookie の同意の調整',
        cookieModify: 'Cookie の同意を調整します',
        cookieWithdraw: 'Cookie の同意を取り消します',
        cookieSuccess: '同意の取り消しに成功しました',
    },
    RedirectGateway: {
        title: 'リダイレクト先：{destination}',
        progress: '数秒かかる可能性があります',
        genericError: '問題が発生しました',
        serverDetails: {
            destinationTitle: 'サーバインベントリのサーバページ',
            errorTitle: 'サーバページを開こうと試みてエラーが発生しました',
            errorDescription: 'サーバがサーバインベントリに存在しません',
            redirectDirectly: 'サーバインベントリに移動します',
        },
        monitoringServerSetup: {
            destinationTitle: '確認ページ',
            errorTitle: 'サーバのモニタリング確認ページを開こうと試みてエラーが発生しました',
            errorDescription: 'サーバがサーバインベントリに存在しません',
        },
    },
};