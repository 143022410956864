// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createRef } from 'react';
import { Carousel as UILibraryCarousel } from '@plesk/ui-library';
import CarouselCard from './CarouselCard';
import useCarouselCards from './useCarouselCards';
import styles from './Carousel.module.less';

const Carousel = () => {
    const cards = useCarouselCards();
    const ref = createRef<UILibraryCarousel>();

    const handleMouseEnter = () => {
        ref.current?.stop();
    };

    const handleMouseLeave = () => {
        ref.current?.play();
    };

    return (
        <UILibraryCarousel
            // @ts-expect-error fix type in UI Library
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={ref}
            className={styles.carousel}
        >
            {cards.map((item) => (
                <CarouselCard key={item.primaryButton.to} {...item} />
            ))}
        </UILibraryCarousel>
    );
};

export default Carousel;
