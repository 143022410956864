// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { OperationTypes } from '@platform360/security-dashboard/shared/operation-type';
import { Operation } from '@platform360/security-dashboard/shared/websocket';
import { Link } from '@plesk/ui-library';
import {
    OperationStatus,
    OperationStatuses,
} from '@platform360/security-dashboard/shared/operation-status';

const FINISHED_OPERATIONS_STATUSES = [
    OperationStatuses.success,
    OperationStatuses.failed,
] as OperationStatus[];

const useShowOperationToast = () => {
    const translate = useTranslate('security-dashboard.Operations');
    const { successToast, errorToast } = useToaster();
    const getModalUrl = useGetModalUrl();
    const taskManagerModalUrl = getModalUrl('task-manager');
    const navigate = useNavigate();

    const createToastMessage = useCallback(
        (operation: Operation) => {
            const isFailed = operation.failed > 0 && !(operation.type === OperationTypes.demo);
            const messageType = isFailed ? 'failed' : 'success';

            return translate(
                `${operation.subType}.${operation.type}.${messageType}`,
                {
                    count: operation[messageType],
                },
                translate(`fallback.${messageType}`),
            );
        },
        [translate],
    );

    return useCallback(
        (operation: Operation) => {
            if (!FINISHED_OPERATIONS_STATUSES.includes(operation.status)) {
                return;
            }

            if (operation.failed > 0) {
                errorToast(
                    <div>
                        {createToastMessage(operation)}
                        <br />
                        <Link onClick={() => navigate(taskManagerModalUrl)}>
                            {translate('openTaskManagerLinkText')}
                        </Link>
                    </div>,
                );
            } else {
                successToast(createToastMessage(operation));
            }
        },
        [errorToast, createToastMessage, translate, navigate, taskManagerModalUrl, successToast],
    );
};

export default useShowOperationToast;
