// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { labelTypeValues, LabelTypes } = makeEnum(
    'LabelType',
    'ignore',
    'ignoredDueToLowRisk',
    'doNotIgnoreAutomatically',
    'doNotProtect',
    'custom',
);

export type LabelType = UnionOf<typeof labelTypeValues>;

export const { installationLabelTypeValues, InstallationLabelTypes } = makeEnum(
    'InstallationLabelType',
    LabelTypes.custom,
    LabelTypes.doNotProtect,
);

export type InstallationLabelType = UnionOf<typeof installationLabelTypeValues>;

export const { serverLabelTypeValues, ServerLabelTypes } = makeEnum(
    'ServerLabelType',
    LabelTypes.custom,
);

export type ServerLabelType = UnionOf<typeof serverLabelTypeValues>;

export const ignoreVulnerabilityLabelTypes: LabelType[] = [
    LabelTypes.ignore,
    LabelTypes.ignoredDueToLowRisk,
];

export type Label = {
    id: number;
    title: string;
    type: LabelType;
};
