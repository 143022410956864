// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'Nenhum alerta aberto',
        openAlerts: 'Alertas abertos: {alerts}',
        loadingError: 'Serviço não disponível',
        connectServer: 'Conectar servidor',
        notSupported: 'Não suportado',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'Visualizar no 360 Monitoring',
        connectServerTitle: 'Este servidor não está conectado ao 360 Monitoring',
        connectServerDescription: 'Quando o servidor estiver conectado, esta página exibirá uma breve visão geral do status do servidor e dos alertas abertos atuais.',
        connectServerButton: 'Conectar servidor',
        pausedServerTitle: 'Este servidor não está sendo monitorado',
        pausedServerDescription: 'Use a interface de usuário do 360 Monitoring para determinar o motivo.',
        preparingServerTitle: 'Estamos fazendo ciência',
        preparingServerDescription1: 'Seu servidor Plesk foi conectado ao 360 Monitoring.',
        preparingServerDescription2: 'Estamos fazendo ciência para preparar um painel. Tente atualizar a página ou volte em 10 ou 30 minutos.',
        refreshButton: 'Atualizar',
        loadingError: 'Algo deu errado. Por favor, tente novamente mais tarde.',
        loadAverageTitle: 'Carga Média',
        alertOpened: 'Aberto {timeAgo}.',
        viewAlertsInMonitoring: 'Ver alertas no 360 Monitoring',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Monitoramento',
            description: 'Monitore seus servidores e websites a partir de um único painel',
        },
        DynamicWidget: {
            title: 'Monitoramento',
            WidgetContent: {
                servers: 'Servidores',
                websites: 'Websites',
                lastUpdate: 'Última atualização: {lastUpdate}',
                loading: 'Carregando...',
                Loader: {
                    loading: 'Carregando...',
                    notRespondingText1: '360 Monitoring não está respondendo.',
                    notRespondingText2: 'Tentando reconectar.',
                },
                InfoAggregateColumn: {
                    allActive: 'Todos ativos',
                    noAlerts: 'Sem alertas',
                    alerts: 'Alertas abertos: {alerts}',
                    down: '{down, plural, one{# não está respondendo} other{# não estão respondendo}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: 'Ativar o 360 Monitoring',
        description: 'O 360 Monitoring Lite é gratuito, agora e sempre. Ao ativar sua conta hoje, você também desfrutará de um teste Pro gratuito de 14 dias, incluindo todos os recursos premium. Você voltará automaticamente para a versão Lite gratuita após duas semanas, a menos que escolha ficar com o upgrade!',
        beginTrialButton: 'Ativar o 360 Monitoring *',
        footnote: '* Não são necessários detalhes do cartão de crédito para ativar o 360 Monitoring Lite ou o teste Pro.',
        backButton: 'Voltar para Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Cancelar',
            confirmationText: 'Tente configurar sua conta do 360 Monitoring abrindo o item de menu Monitoring ou envie uma solicitação de suporte.',
            acceptButtonText: 'OK',
            declineButtonText: 'Cancelar',
        },
        retryButtonText: 'Tentar novamente',
        inProgressText: 'Sua conta do 360 Monitoring está sendo configurada',
        successText: 'Redirecionando você para o 360 Monitoring',
        failedText: 'Algo deu errado durante a ativação da conta de monitoramento. Tente novamente ou envie uma solicitação de suporte.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Confirme a ativação do 360 Monitoring',
            description: 'O agente "360 Monitoring" será instalado no painel de controle do Plesk em <b>{address}</b> e conectado à conta <b>{email}</b> no Plesk 360.',
        },
        monitoringInactive: {
            title: 'Ative o 360 Monitoring e adicione o servidor',
            description: 'Comece a usar o Monitoramento 360 com um <tooltip>período de teste</tooltip> e confirme a instalação do agente "360 Monitoring" em seu painel de controle Plesk em <b>{address}</b> conectado a conta no Plesk 360 <b>{email}</b>.',
            trialTooltip: 'Durante o período de teste de 14 dias, você obtém um serviço totalmente funcional com todos os recursos premium por 14 dias. Após o término do período de avaliação, o 360 Monitoring será alterado para a versão “Lite” gratuita com recursos básicos disponíveis. Você também pode ativar uma versão “Pro” a qualquer momento comprando uma licença.',
        },
        buttons: {
            confirm: 'Confirmar',
            deny: 'Negar',
            cancel: 'Cancelar',
        },
        errors: {
            apiKey: 'A conexão do servidor com o Plesk 360 parece quebrada. Reconecte seu servidor ao Plesk 360 e ative o monitoramento',
            default: 'Falha ao instalar o 360 Monitoring, tente novamente mais tarde',
        },
    },
};