// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Alert, Icon, Status } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

const FooterPurposeExtension = () => {
    const translate = useTranslate('footerPurposeExtension');
    const analyticsEvents = useLicensesAnalyticsEvents();

    return (
        <Alert>
            <Status icon={<Icon name="globe" size="16" />}>
                {translate('text', {
                    a: (chunk) => (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.plesk.com/extensions/"
                            onClick={() =>
                                analyticsEvents.licensesPleskExtensionsCatalogLinkClicked()
                            }
                        >
                            {chunk}
                        </a>
                    ),
                })}
            </Status>
        </Alert>
    );
};

export default FooterPurposeExtension;
