// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { VulnerabilityInstallationOperationType } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import { getCountOfAffectedSitesByIds } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseCountOfAffectedSitesByVulnerabilitiesQueryVariables = {
    vulnerabilitiesIds: string[];
    operation: VulnerabilityInstallationOperationType;
};

export type UseCountOfAffectedSitesByVulnerabilitiesQueryData = PromiseValue<
    ReturnType<typeof getCountOfAffectedSitesByIds>
>;

const useCountOfAffectedSitesByVulnerabilitiesQuery = createQuery<
    UseCountOfAffectedSitesByVulnerabilitiesQueryVariables,
    UseCountOfAffectedSitesByVulnerabilitiesQueryData
>({
    queryName: 'security-dashboard/useVulnerabilitiesSummaryQuery',
    fetcher: getCountOfAffectedSitesByIds,
    useQuery,
});

export default useCountOfAffectedSitesByVulnerabilitiesQuery;
