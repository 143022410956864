// Copyright 2024. WebPros International GmbH. All rights reserved.

import Cookies from 'js-cookie';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { acceptInvitation } from '@platform360/security-dashboard/web/api/invitation';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import { invitationTokenCookie } from '@platform360/security-dashboard/web/constants/invitation';

type MutationOptions = {
    onSuccess?: () => void;
    onError: () => void;
};

const useAcceptInvitationMutation = ({ onSuccess, onError }: MutationOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: acceptInvitation,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: useLicenseQuery.getQueryKey(),
            });
            onSuccess?.();
        },
        onError: () => {
            Cookies.remove(invitationTokenCookie);
            onError();
        },
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useAcceptInvitationMutation;
