// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './ProtectControlCompact.module.css';
import { Button, Label, StatusMessage, Text } from '@plesk/ui-library';
import { InstallationInfoResponse } from '@platform360/security-dashboard/web/api/installations';
import { useUpdateInstallationsProtectionStatusMutation } from '@platform360/security-dashboard/web/mutations';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import ProtectInfoPopover from './ProtectInfoPopover';
import { APPLY_VIRTUAL_PATCHES_TASK_TYPE } from '@platform360/security-dashboard/shared/constants';

export type ProtectControlCompactProps = {
    installation: InstallationInfoResponse;
    showProtectInfoPopover?: boolean;
};

const ProtectControlCompact = ({
    installation,
    showProtectInfoPopover,
}: ProtectControlCompactProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.Installations.ProtectControlCompact');
    const { mutate, isPending: isLoading } = useUpdateInstallationsProtectionStatusMutation();
    const { operable, managed } = installation;

    if (!operable || !managed) return null;

    if (installation.protected) {
        return (
            <div className={styles.protectedContainer}>
                <Label icon="shield" intent="success" view="light" size="lg">
                    {translate('protectedLabel', {
                        providerLogo: <Patchstack />,
                        whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                    })}
                </Label>
                {showProtectInfoPopover && <ProtectInfoPopover installationId={installation.id} />}
            </div>
        );
    }

    const protectTasks = installation.tasks.filter(
        ({ type }) => type === APPLY_VIRTUAL_PATCHES_TASK_TYPE,
    );

    const handleProtect = () => {
        mutate({ installationIds: [installation.id], protect: true, ignoreDoNotProtect: true });
        analyticsEvents.wpTabSiteHealthDrawerProtectClick();
    };

    return (
        <StatusMessage intent="info">
            <Text bold component="div">
                {translate('notProtected.title')}
            </Text>
            {translate('notProtected.message', {
                providerLogo: <Patchstack />,
                whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
            })}
            <br />
            <Button
                icon="shield"
                intent="primary"
                className={styles.protectButton}
                onClick={handleProtect}
                state={isLoading || protectTasks.length ? 'loading' : undefined}
            >
                {translate('notProtected.patchBtn')}
            </Button>
        </StatusMessage>
    );
};

export default ProtectControlCompact;
