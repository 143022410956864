// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useModal from '@platform360/libs/shared-web/helpers/useModal';
import ChangePasswordContainer from './ChangePasswordContainer';

const ChangePasswordDrawer = () => {
    const translate = useTranslate('accounts.profile.ChangePassword');
    const [isOpen, setIsOpen] = useModal('change-password');

    return (
        <Drawer
            title={translate('title')}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            data-type="change-password-drawer"
            size="sm"
            form={<ChangePasswordContainer />}
        />
    );
};

export default ChangePasswordDrawer;
