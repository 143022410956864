// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Paragraph, StatusMessage, Popover } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DialogLayout from '@platform360/libs/shared-web/components/DialogLayout';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';
import { useServerInventoryErrorsTranslate } from '@platform360/monitoring/web/helpers/useServerInventoryErrorsTranslate';

export type MonitoringServerSetupProps = {
    onConfirm: () => void;
    onDeny: () => void;
    hasError: boolean;
    isLoading: boolean;
    email: string;
    address: string;
    isMonitoringActive: boolean;
    // eslint-disable-next-line deprecation/deprecation
    error: UntypedAxiosError | null;
};

const MonitoringServerSetup = ({
    email,
    address,
    onConfirm,
    onDeny,
    isLoading,
    isMonitoringActive,
    hasError,
    error,
}: MonitoringServerSetupProps) => {
    const translate = useTranslate('monitoring.MonitoringServerSetup');
    const translationPrefix = isMonitoringActive ? 'monitoringActive' : 'monitoringInactive';
    const serverErrorsTranslate = useServerInventoryErrorsTranslate();

    const getErrorMessage = () => {
        const errorType: string = error?.response?.data.type;
        // Special texts for SI errors
        return (
            translate(`errors.${errorType}`, {}, '') ||
            // SI based errors
            serverErrorsTranslate(errorType, '') ||
            // Default unhandled errors
            translate('errors.default')
        );
    };

    return (
        <DialogLayout
            form={{
                state: isLoading ? 'submit' : undefined,
                // HACK: We should use cancelButton instead of applyButton, but
                //       when we set `closable` to false UI, Library hides cancel button as well
                applyButton: {
                    onClick: onDeny,
                    icon: 'remove',
                    intent: undefined,
                    children: translate(hasError ? 'buttons.cancel' : 'buttons.deny'),
                    'data-type': 'cancel-button',
                },
                submitButton: {
                    onClick: onConfirm,
                    icon: 'check-mark',
                    children: translate('buttons.confirm'),
                    'data-type': 'proceed-button',
                    disabled: hasError,
                },
            }}
        >
            <div>
                <Heading level={2}>{translate(`${translationPrefix}.title`)}</Heading>
                {hasError && <StatusMessage intent="danger">{getErrorMessage()}</StatusMessage>}
                <br />
                <div>
                    <Paragraph>
                        {translate(`${translationPrefix}.description`, {
                            tooltip: (chunk) => (
                                <Popover
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    target={<a>{chunk}</a>}
                                    placement="top"
                                >
                                    {translate('monitoringInactive.trialTooltip')}
                                </Popover>
                            ),
                            email,
                            address,
                        })}
                    </Paragraph>
                </div>
            </div>
        </DialogLayout>
    );
};

export default MonitoringServerSetup;
