// Copyright 2024. WebPros International GmbH. All rights reserved.

import PleskLogo from '@platform360/web-installer/web/components/PleskLogo';
import CPanelLogo from '@platform360/web-installer/web/components/CPanelLogo';
import type { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';

import styles from './ControlPanelLogo.module.css';

type ControlPanelLogoProps = {
    productType: WebInstallerProduct;
};

const ControlPanelLogo = ({ productType }: ControlPanelLogoProps) =>
    productType === 'plesk' ? (
        <PleskLogo className={styles.logo} />
    ) : (
        <CPanelLogo className={styles.logo} />
    );

export default ControlPanelLogo;
