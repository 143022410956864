// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTeamInvitation } from '@platform360/accounts/web/api/team';
import useTeamInvitationsQuery from '@platform360/accounts/web/queries/useTeamInvitationsQuery';

const useDeleteTeamInvitationMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (invitationId: number) => {
            await deleteTeamInvitation(invitationId);

            return invitationId;
        },
        onSuccess: async (invitationId) => {
            await queryClient.cancelQueries({
                queryKey: useTeamInvitationsQuery.getQueryKey(),
            });
            useTeamInvitationsQuery.setQueriesData(
                queryClient,
                useTeamInvitationsQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return [];
                    }

                    return cache.filter(({ id }) => id !== invitationId);
                },
            );
        },
    });
};

export default useDeleteTeamInvitationMutation;
