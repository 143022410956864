// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'Aucune alerte ouverte',
        openAlerts: 'Alertes ouvertes : {alerts}',
        loadingError: 'Service non disponible',
        connectServer: 'Connecter le serveur',
        notSupported: 'Non pris en charge',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'Voir dans 360 Monitoring',
        connectServerTitle: 'Ce serveur n\'est pas connecté à 360 Monitoring.',
        connectServerDescription: 'Quand le serveur sera connecté, cette page affichera un aperçu du statut du serveur et des alertes actuellement ouvertes.',
        connectServerButton: 'Connecter le serveur',
        pausedServerTitle: 'Ce serveur n\'est pas surveillé actuellement.',
        pausedServerDescription: 'Utilisez l\'interface utilisateur de 360 Monitoring afin de déterminer la cause.',
        preparingServerTitle: 'Préparation en cours...',
        preparingServerDescription1: 'Votre serveur Plesk a été connecté à 360 Monitoring.',
        preparingServerDescription2: 'Nous préparons votre tableau de bord. Essayez d\'actualiser la page ou de revenir dans 10 à 30 minutes.',
        refreshButton: 'Actualiser',
        loadingError: 'Un problème est survenu. Veuillez réessayer ultérieurement.',
        loadAverageTitle: 'Charge moyenne',
        alertOpened: 'Ouverte : {timeAgo}.',
        viewAlertsInMonitoring: 'Voir les alertes dans 360 Monitoring',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Monitoring',
            description: 'Surveillez vos serveurs et vos sites Web depuis un tableau de bord unique.',
        },
        DynamicWidget: {
            title: 'Monitoring',
            WidgetContent: {
                servers: 'Serveurs',
                websites: 'Sites Web',
                lastUpdate: 'Dernière mise à jour : {lastUpdate}',
                loading: 'Chargement...',
                Loader: {
                    loading: 'Chargement...',
                    notRespondingText1: '360 Monitoring ne répond pas.',
                    notRespondingText2: 'Essayez de vous reconnecter.',
                },
                InfoAggregateColumn: {
                    allActive: 'Tous actifs',
                    noAlerts: 'Aucune alerte',
                    alerts: 'Alertes ouvertes : {alerts}',
                    down: '{down, plural, one{# ne répond pas} other{# ne répondent pas}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: 'Activer 360 Monitoring',
        description: '360 Monitoring Lite est une version gratuite et le restera. En activant votre compte aujourd\'hui, vous bénéficierez également de l\'accès à la version Pro et à toutes les fonctionnalités premium pendant 14 jours. Une fois cette période de test écoulée, vous reviendrez automatiquement à la version Lite, sauf si vous optez pour conserver la mise à niveau.',
        beginTrialButton: 'Activez 360 Monitoring *',
        footnote: '* L\'activation de 360 Monitoring Lite ou de la version de test Pro ne requiert pas de coordonnées de carte bancaire.',
        backButton: 'Revenir à Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Annuler',
            confirmationText: 'Essayez de configurer votre compte 360 Monitoring depuis l\'élément du menu Monitoring ou envoyez une demande d\'assistance.',
            acceptButtonText: 'OK',
            declineButtonText: 'Annuler',
        },
        retryButtonText: 'Réessayer',
        inProgressText: 'Votre compte 360 Monitoring est en cours de configuration.',
        successText: 'Redirection vers 360 Monitoring',
        failedText: 'Un problème est survenu pendant l\'activation du compte Monitoring. Veuillez réessayer ou envoyer une demande d\'assistance.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Confirmer l\'activation de 360 Monitoring',
            description: 'L\'agent "360 Monitoring" sera installé sur votre panneau de contrôle Plesk sur <b>{address}</b> et connecté au compte <b>{email}</b> sur Plesk 360.',
        },
        monitoringInactive: {
            title: 'Activer 360 Monitoring et ajouter le serveur',
            description: 'Commencez à utiliser 360 Monitoring grâce à la <tooltip>période de test</tooltip> et confirmez l\'installation de l\'agent "360 Monitoring" sur votre panneau de contrôle de Plesk sous <b>{address}</b> connectée au compte <b>{email}</b> sur Plesk 360.',
            trialTooltip: 'Pendant la période de test de 14 jours vous bénéficiez d\'un service entièrement fonctionnel avec les fonctionnalités premium pendant 14 jours. Une fois la période de test écoulée, 360 Monitoring passe à la version "Lite" avec les fonctionnalités basiques. Vous pouvez également activer une version "Pro" à tout moment en achetant une licence.',
        },
        buttons: {
            confirm: 'Confirmer',
            deny: 'Refuser',
            cancel: 'Annuler',
        },
        errors: {
            apiKey: 'La connexion du serveur à Plesk 360 semble interrompue. Reconnectez votre serveur à Plesk 360, puis activez la surveillance.',
            default: 'Échec de l\'installation de 360 Monitoring, veuillez réessayez ultérieurement',
        },
    },
};