// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSearchParams } from 'react-router-dom';
import { useState, useCallback, useMemo } from 'react';

import { Heading, Label, Panel, Button, Form } from '@plesk/ui-library';
import ControlPanelLogo from '@platform360/web-installer/web/components/ControlPanelLogo';
import Loading from '@platform360/libs/shared-web/components/Loading';
import CustomInstallationForm, {
    DEFAULT_FORM_VALUES,
    FormValues,
} from '@platform360/web-installer/web/components/CustomInstallationForm';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { InstallationMethods } from '@platform360/web-installer/shared/installation-method';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import {
    useTranslateValidationErrors,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';
import useGenHostname from '@platform360/web-installer/web/queries/useGenHostname';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useInstallationApi from '@platform360/web-installer/web/components/ServerInstallationPage/useInstallationApi';
import { WebInstallerProduct } from '@platform360/web-installer/shared/web-installer-product-type';
import styles from './ServerInstallationPage.module.css';
import { CustomInstallRequest } from '@platform360/web-installer/shared/api-contract';
import {
    ProductTypesTranslations,
    longSurveyLink,
    shortSurveyLink,
} from '@platform360/web-installer/web/constants';
import WebInstallerBottomLinks from '@platform360/web-installer/web/components/WebInstallerBottomLinks';

const isIp = (value: string) => Boolean(value && /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.exec(value));

type ServerInstallationPageProps = {
    productType: WebInstallerProduct;
};

const ServerInstallationPage = ({ productType }: ServerInstallationPageProps) => {
    const translate = useTranslate('web-installer.ServerInstallationPage');

    const translateValidationErrors = useTranslateValidationErrors();
    const [searchParams] = useSearchParams();
    const installId = searchParams.get('installId');

    const { isAuthorized } = useAuth();

    const [host, setHost] = useState('');
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [submittedHost, setSubmittedHost] = useState('');

    const productTypeName = ProductTypesTranslations[productType];

    const {
        isPending: isLoading,
        error,
        mutate: submitForm,
        reset,
        storageValue,
        sendSubmitEventToAnalytics,
    } = useInstallationApi(productType);

    const validationErrors = [...(getValidationErrors(error) ?? []), ...errors];
    const installationErrors = validationErrors.filter((e) => e.path === 'general');

    const { data: techDomain } = useGenHostname({
        variables: {
            ip: host,
            vendor: productType,
        },
        enabled: isIp(host),
    });

    const labels = useMemo(
        () => (
            <div className={styles.serverInstallationContainerLabels}>
                {/* TODO update layout when information about licences will be available */}
                <Label intent="success" size="md">
                    {translate('licenceLabel')}
                </Label>
                <Label intent="info" icon={{ name: 'clock' }} size="md">
                    {translate('installationWillTake')}
                </Label>
            </div>
        ),
        [translate],
    );

    const handleSubmit = useCallback(
        ({ host, port, login, key, updateOs, installationMethod, password }: FormValues) => {
            if (
                installationMethod === InstallationMethods.privateKey &&
                key &&
                key.length / 1024 > 30
            ) {
                reset();
                setErrors([
                    {
                        category: 'web-installer',
                        path: 'key',
                        type: 'tooBigPrivateKeyError',
                        message: 'File too big',
                    },
                ]);
                return;
            }

            setErrors([]);
            setSubmittedHost(host);

            /* TODO pass licences when information will be available */
            const data: CustomInstallRequest = {
                login,
                host,
                port: port ? Number(port) : undefined,
                updateOs,
                installationMethod,
                ...(installationMethod === InstallationMethods.password ? { password } : { key }),
                licenseType: 'trial',
                licenseKey: '',
                setHostname: isIp(host) ? techDomain : undefined,
            };

            submitForm(data);
            sendSubmitEventToAnalytics();
        },
        [reset, setErrors, submitForm, techDomain, sendSubmitEventToAnalytics],
    );

    useEffectOnce(() => {
        const data = installId && storageValue[installId];

        let timerId = 0;
        if (data) {
            // Workaround for react-query to work with StrictMode
            // TODO investigate a good way to fix it without such hack
            timerId = window.setTimeout(() => {
                submitForm(data);
                sendSubmitEventToAnalytics();
            }, 0);
        }

        return () => {
            clearTimeout(timerId);
        };
    });

    const defaultValues = installId
        ? storageValue[installId] || DEFAULT_FORM_VALUES
        : DEFAULT_FORM_VALUES;

    if (installId && !validationErrors.length) {
        return <Loading />;
    }

    return (
        <div className={styles.serverInstallationContainer}>
            <div className={styles.serverInstallationContainerHorizontalSideBlock} />
            <div className={styles.serverInstallationContainerCenterBlock}>
                <div className={styles.serverInstallationContainerVerticalSideBlock} />
                <Panel className={styles.serverInstallationContainerOuterPanel}>
                    <ControlPanelLogo productType={productType} />
                    <Panel
                        className={styles.serverInstallationContainerInnerPanel}
                        data-type="add-server-panel"
                    >
                        <Heading level={2} className={styles.serverInstallationContainerHeader}>
                            <div>
                                {translate('panelTitle', {
                                    productType: productTypeName,
                                })}
                            </div>
                            {!isAuthorized && <LocaleSwitcher />}
                        </Heading>
                        <Form
                            onSubmit={handleSubmit}
                            submitButton={{
                                children: translate('submitButton'),
                                type: 'submit',
                            }}
                            applyButton={false}
                            cancelButton={false}
                            values={defaultValues}
                            errors={translateValidationErrors(validationErrors)}
                            state={isLoading ? 'submit' : undefined}
                            vertical
                        >
                            {labels}
                            <CustomInstallationForm
                                productType={productType}
                                installationErrors={installationErrors}
                                submittedHost={submittedHost}
                                onHostChange={setHost}
                                techDomain={techDomain}
                            />
                        </Form>
                    </Panel>
                </Panel>
                <div className={styles.serverInstallationContainerVerticalSideBlock}>
                    <Panel
                        className={styles.serverInstallationContainerSurvey}
                        data-type="goto-survey-panel"
                    >
                        <div className={styles.serverInstallationContainerSurveyText}>
                            {translate('helpUsImprove', {
                                productType: productTypeName,
                            })}
                        </div>
                        <Button
                            component="a"
                            href={
                                Math.random() < 0.5
                                    ? longSurveyLink[productType]
                                    : shortSurveyLink[productType]
                            }
                            ghost
                            target="_blank"
                        >
                            {translate('takeOurSurvey')}
                        </Button>
                    </Panel>
                </div>
            </div>
            <div className={styles.serverInstallationContainerHorizontalSideBlock}>
                <WebInstallerBottomLinks productType={productType} />
            </div>
        </div>
    );
};

export default ServerInstallationPage;
