// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FormField, Checkbox, Hint, TranslationProps } from '@plesk/ui-library';
import styles from './FormFieldConsents.module.css';
import useConsentItems from '@platform360/libs/shared-web/helpers/useConsentItems';

type Consent = {
    type: string;
    text: string;
};

export type FormFieldConsentsProps = {
    name: string;
    label: TranslationProps;
    required?: boolean;
};

export const FormFieldConsents = (props: FormFieldConsentsProps) => {
    const consents = useConsentItems();

    return (
        <FormField vertical {...props}>
            {({ getName, getValue, setValue }) =>
                consents.map(({ type, title, text, renderText }) => (
                    <Checkbox
                        key={type}
                        name={getName()}
                        checked={
                            getValue()?.find((value: Consent) => value.type === type) !== undefined
                        }
                        onChange={(isChecked) => {
                            if (isChecked) {
                                setValue([
                                    ...(getValue() || []),
                                    { type, text: [title, text].join('\n') },
                                ]);
                            } else {
                                setValue(getValue()?.filter((v: Consent) => v.type !== type) || []);
                            }
                        }}
                    >
                        {title}
                        {
                            <Hint component="div" className={styles.description}>
                                {renderText()}
                            </Hint>
                        }
                    </Checkbox>
                ))
            }
        </FormField>
    );
};
