// Copyright 2024. WebPros International GmbH. All rights reserved.

import ComboBoxTime from './ComboBoxTime';
import styles from './ComboBoxTimePicker.module.less';

type ComboBoxTimePickerProps = {
    hours: number;
    minutes: number;
    onHoursChange: (hours: number) => void;
    onMinutesChange: (minutes: number) => void;
    disabled?: boolean;
    'data-type'?: string;
};

const ComboBoxTimePicker = ({
    hours,
    minutes,
    onHoursChange,
    onMinutesChange,
    disabled,
    ...props
}: ComboBoxTimePickerProps) => (
    <div className={styles.picker} {...props}>
        <ComboBoxTime
            value={hours}
            onChange={onHoursChange}
            max={23}
            className={styles.comboBox}
            disabled={disabled}
        />
        {':'}
        <ComboBoxTime
            value={minutes}
            onChange={onMinutesChange}
            max={59}
            className={styles.comboBox}
            disabled={disabled}
        />
    </div>
);

export default ComboBoxTimePicker;
