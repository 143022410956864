// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FAILED_INITIALIZATION } from '@platform360/licenses/web/units/extensions/constants/actions';

export type FailedInitializationAction = {
    type: typeof FAILED_INITIALIZATION;
};

const failedInitialization = (): FailedInitializationAction => ({
    type: FAILED_INITIALIZATION,
});

export default failedInitialization;
