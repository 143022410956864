// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, Status, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { TaskManagerListRow } from '@platform360/security-dashboard/web/types';
import { OperationSubTypes } from '@platform360/security-dashboard/shared/operation-sub-type';
import { OperationTypes } from '@platform360/security-dashboard/shared/operation-type';

type RowStatusProps = {
    row: TaskManagerListRow;
};

export const RowStatus = ({ row }: RowStatusProps) => {
    const translate = useTranslate('security-dashboard.GlobalTaskManagerDrawer.TasksList.statuses');
    const { status } = row;
    const isOperation = row.type && row.subType;

    const getSuccessLabel = () => {
        if (row.type === OperationTypes.demo) {
            return (
                <Status icon={{ size: '16' }} intent="success">
                    <Text fontSize={'md'}>{translate('success')}</Text>
                </Status>
            );
        }

        if (isOperation) {
            if (status.success === 1 && status.failed === 0) {
                return (
                    <Status icon={{ size: '16' }} intent="success">
                        <Text fontSize={'md'}>{translate('success')}</Text>
                    </Status>
                );
            } else if (status.success >= 1) {
                return (
                    <>
                        <Label intent="success">{status.success}</Label>
                        {'  '}
                        <Text fontSize={'md'}>{translate('success')}</Text>
                    </>
                );
            }
        } else if (status.success >= 1) {
            return (
                <Text intent={'success'} fontSize={'md'}>
                    {translate('success')}
                </Text>
            );
        }

        return null;
    };

    const getFailedLabel = () => {
        const failedMessage =
            row.subType === OperationSubTypes.installations
                ? translate('errors')
                : translate('failed');

        if (isOperation) {
            if (status.success === 0 && status.failed === 1) {
                return (
                    <Status icon={{ size: '16' }} intent="danger">
                        <Text fontSize={'md'}>{failedMessage}</Text>
                    </Status>
                );
            } else if (status.failed >= 1) {
                return (
                    <>
                        <Label intent="danger">{status.failed}</Label>
                        {'  '}
                        <Text fontSize={'md'}>{failedMessage}</Text>
                    </>
                );
            }
        } else if (status.failed >= 1) {
            return (
                <Text intent={'danger'} fontSize={'md'}>
                    <Text fontSize={'md'}>{failedMessage}</Text>
                </Text>
            );
        }

        return null;
    };

    return (
        <>
            {getSuccessLabel()}
            {'  '}
            {getFailedLabel()}
        </>
    );
};

export default RowStatus;
