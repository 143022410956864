// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AxiosRequestConfig } from 'axios';
import { Extension } from '@platform360/licenses/web/api/ka/extensions/types/create';

const create = (extension: Extension): AxiosRequestConfig => ({
    url: '/ka/extension',
    method: 'POST',
    data: extension,
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
});

export default create;
