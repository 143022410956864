// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { WebInstallerProducts, webInstallerProductValues, isWebInstallerProduct } = makeEnum(
    'WebInstallerProduct',
    'plesk',
    'cpanel',
);

export type WebInstallerProduct = UnionOf<typeof webInstallerProductValues>;
