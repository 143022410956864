// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import { Server } from '@platform360/server-inventory/web/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeServer } from '@platform360/server-inventory/web/api/servers';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServersQuery from '@platform360/server-inventory/web/queries/useServersQuery';

export type DisconnectServersOperationProps = {
    servers: Server[];
    onConfirm?: () => void;
    onSuccess?: (disconnectedServersCount: number) => void;
    disabled?: boolean;
};

export const DisconnectServersOperation = ({
    servers,
    onConfirm,
    onSuccess,
    ...props
}: DisconnectServersOperationProps) => {
    const translate = useTranslate('DisconnectServersOperation');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const queryClient = useQueryClient();
    const { successToast, errorToast } = useToaster();

    const { mutate, isPending: isLoading } = useMutation({
        mutationFn: () =>
            Promise.all(
                servers.map((server) =>
                    removeServer(server.id)
                        .then(() => {
                            analyticsEvents.removeServerSuccess(
                                { address: server.address.href },
                                server.product,
                            );
                            return {
                                serverId: server.id,
                                hostname: server.address.hostname,
                                deleted: true,
                            };
                        })
                        .catch((error) => {
                            const { type } = error.response.data;
                            analyticsEvents.removeServerFail(
                                { address: server.address.href },
                                type,
                                server.product,
                            );
                            return {
                                serverId: server.id,
                                hostname: server.address.hostname,
                                deleted: false,
                            };
                        }),
                ),
            ),
        onSuccess: async (results: { serverId: number; hostname: string; deleted: boolean }[]) => {
            const successServers = results.filter(({ deleted }) => deleted);
            const successServersCount = successServers.length;
            const failServersCount = servers.length - successServersCount;
            await queryClient.cancelQueries({
                queryKey: useServersQuery.getQueryKey(),
            });

            onSuccess?.(successServersCount);

            const [successServer] = successServers;
            if (successServer) {
                successToast(
                    translate('notifyDisconnectSuccess', {
                        title: successServer.hostname,
                        count: successServersCount,
                    }),
                    { autoCloseTimeout: 3000 },
                );
            }

            const failServer = results.find((res) => !res.deleted);
            if (failServer) {
                errorToast(
                    translate('notifyDisconnectFail', {
                        title: failServer.hostname,
                        count: failServersCount,
                    }),
                );
            }
        },
    });

    const handleConfirmationClick = () => {
        onConfirm?.();
        mutate();
    };

    return (
        <ConfirmationPopover
            onClick={handleConfirmationClick}
            target={
                <NoSelectionPopover
                    selectionCount={servers.length}
                    target={
                        <Button
                            state={isLoading ? 'loading' : undefined}
                            data-type="remove-servers-button"
                        >
                            {translate('disconnect')}
                        </Button>
                    }
                >
                    {translate('noSelectedServers')}
                </NoSelectionPopover>
            }
            actionButtonText={translate('disconnect')}
            {...props}
        >
            <Paragraph>
                {translate('disconnectConfirmation', {
                    title: servers[0]?.address.hostname,
                    count: servers.length,
                })}
            </Paragraph>
        </ConfirmationPopover>
    );
};

export default DisconnectServersOperation;
