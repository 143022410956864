// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useLocalStorage } from 'usehooks-ts';
import { useState } from 'react';

const ITEMS_PER_PAGE_OPTIONS = [10, 25, 100];
const INITIAL_PAGE = 1;
const ITEMS_PER_PAGE = 10;

const usePagination = (name: string) => {
    const [current, setCurrent] = useState(INITIAL_PAGE);
    const [itemsPerPage, setItemsPerPage] = useLocalStorage<number>(
        `items-per-page-${name}`,
        ITEMS_PER_PAGE,
    );

    const onItemsPerPageChange = (value: number) => {
        setCurrent(INITIAL_PAGE);
        setItemsPerPage(value);
    };

    const resetPagination = () => {
        setCurrent(1);
    };

    return {
        itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        itemsPerPage,
        current,
        onItemsPerPageChange,
        onSelect: setCurrent,
        resetPagination,
    };
};

export default usePagination;
