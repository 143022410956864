// Copyright 2024. WebPros International GmbH. All rights reserved.

import logo from './logo.png';
import styles from './PageIntro.module.less';

export const PageIntro = () => (
    <div className={styles.root}>
        <img className={styles.logo} src={logo} alt="WP Guardian logo" />
    </div>
);

export default PageIntro;
