// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Layout from '@platform360/libs/shared-web/components/Public';
import Loading from '@platform360/libs/shared-web/components/Loading';
import ChangePasswordForm from './ChangePasswordForm';
import ChangePasswordInvalidToken from './ChangePasswordInvalidToken';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useResetPassword, useChangePassword } from '@platform360/auth/web/mutations';
import { fetchTokenStatus, logSetPassword } from '@platform360/auth/web/api/passwords';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { SetPasswordUseCase } from '@platform360/auth/web/types';

enum TokenStatus {
    None = 0,
    Valid = 1,
    Invalid = 2,
}

type State = {
    email?: string;
    tokenStatus: TokenStatus;
};

type ChangePasswordProps = {
    useCase?: SetPasswordUseCase;
};

export const ChangePassword = ({ useCase = 'reset' }: ChangePasswordProps) => {
    const translate = useTranslate('auth.ChangePassword');
    const { successToast } = useToaster();
    const { login } = useAuth();
    const {
        auth: { branding },
    } = useApplicationSettings();
    const [isComplete, setIsComplete] = useState(false);
    const [state, setState] = useState<State>({
        tokenStatus: TokenStatus.None,
    });

    const {
        mutate: resetPassword,
        isPending: isLoading,
        errors,
    } = useResetPassword(() => {
        setIsComplete(true);
    });

    const {
        mutate: changePassword,
        isPending: isPasswordChanging,
        errors: changePasswordErrors,
        isSuccess,
    } = useChangePassword(() => {
        handleSuccess(state.email || '');
    });

    useEffectOnce(() => {
        const fetch = async () => {
            try {
                const { email } = await fetchTokenStatus();

                setState({
                    email,
                    tokenStatus: TokenStatus.Valid,
                });

                if (useCase === 'set') {
                    void logSetPassword(false);
                }
            } catch {
                setState({ tokenStatus: TokenStatus.Invalid });

                if (useCase === 'set') {
                    void logSetPassword(true);
                }
            }
        };

        void fetch();
    });

    const handleSuccess = (email: string) => {
        successToast(translate('passwordChangedSuccess'), {
            onClose: () => login({ email, branding }),
        });
    };

    if (state.tokenStatus === TokenStatus.None) {
        return <Loading />;
    }

    const child =
        state.tokenStatus === TokenStatus.Valid ? (
            <ChangePasswordForm
                onSubmit={(password) => changePassword({ password, useCase })}
                isLoading={isPasswordChanging}
                isSuccess={isSuccess}
                errors={changePasswordErrors}
                useCase={useCase}
            />
        ) : (
            <ChangePasswordInvalidToken
                errors={errors}
                isLoading={isLoading}
                isComplete={isComplete}
                resetPassword={resetPassword}
                useCase={useCase}
            />
        );

    return <Layout>{child}</Layout>;
};

export default ChangePassword;
