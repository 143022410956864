// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import styles from './AuthLayout.module.less';
import PageIntro from './PageIntro';

type AuthLayoutProps = {
    sidebar: ReactNode;
    children: ReactNode;
    footer: ReactNode;
};

const AuthLayout = ({ sidebar, footer, children }: AuthLayoutProps) => (
    <>
        <div className={styles.root}>
            <div className={styles.content}>
                <PageIntro />
                {sidebar}
                {children}
            </div>
        </div>
        {footer}
    </>
);

export default AuthLayout;
