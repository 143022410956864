// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { CalculatedSubscriptionState } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';
import doRequest from '@platform360/licenses/web/api/doRequest';
import get from '@platform360/licenses/web/api/ka/keys/resume/get';

export type UseSubscriptionRenewQueryVariables = {
    licenseId: number;
};

export type UseSubscriptionRenewQueryData = CalculatedSubscriptionState;

const useSubscriptionRenewQuery = createQuery<
    UseSubscriptionRenewQueryVariables,
    UseSubscriptionRenewQueryData
>({
    queryName: 'licenses/useSubscriptionRenewQuery',
    fetcher: ({ variables }) => doRequest<CalculatedSubscriptionState>(get(variables.licenseId)),
    useQuery,
});

export default useSubscriptionRenewQuery;
