// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const cookieModify = () => {
    if (window.UC_UI) {
        window.UC_UI.showSecondLayer();
    }
};

export const cookieWithdraw = async () => {
    if (window.UC_UI) {
        await window.UC_UI.denyAllConsents();
    }
};

export const CookiesList = () => {
    useEffectOnce(() => {
        if (window.UC_UI) {
            window.UC_UI.restartCMP();
        }
    });

    return (
        /* eslint-disable-next-line react/no-unknown-property */
        <div className="uc-embed" uc-layout="1" uc-data="all" />
    );
};
