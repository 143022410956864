// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { License } from '@platform360/security-dashboard/web/types';
import { CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';
import { useDateToTrialDisplayInfo } from '@platform360/security-dashboard/web/helpers/useDateToTrialDisplayInfo';
import { useLicenseTranslate } from '@platform360/security-dashboard/web/hooks/useLicenseTranslate';

type LicenseHeadingProps = {
    license: License | null;
    trialJustStarted: boolean;
};
const LicenseHeading = ({ license, trialJustStarted }: LicenseHeadingProps) => {
    const translate = useTranslate('security-dashboard');
    const translateLicense = useLicenseTranslate();
    const dateToTrialDisplayInfo = useDateToTrialDisplayInfo();

    const getRetailTrialJustStartedText = (license: License) => {
        const { daysLeft } = dateToTrialDisplayInfo(license.expiredAt);
        return daysLeft > 0
            ? translate('LicensePage.LicenseHeading.welcomeRetailTrial', { days: daysLeft })
            : translate('LicensePage.LicenseHeading.trialLicenseExpired');
    };

    const getText = () => {
        if (license === null) {
            return translate('LicensePage.LicenseHeading.noLicense');
        }

        if (license.status === 'terminated') {
            return license.trial
                ? translate('LicensePage.LicenseHeading.trialLicenseExpired')
                : translate('LicensePage.LicenseHeading.noLicense');
        }

        if (trialJustStarted) {
            return license.customerType === CustomerTypes.retail
                ? getRetailTrialJustStartedText(license)
                : translate('LicensePage.LicenseHeading.welcome');
        }

        if (license.trial) {
            return translate('LicensePage.LicenseHeading.trial');
        }

        return translateLicense(license.product);
    };

    return <Heading>{getText()}</Heading>;
};

export default LicenseHeading;
