// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '@platform360/admin-panel/web/api/users';
import { useUsersQuery } from '@platform360/admin-panel/web/queries';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import {
    getValidationErrors,
    toGeneralError,
} from '@platform360/libs/shared-web/helpers/apiErrors';

type UseUpdateUserMutationOptions = {
    onSuccess: () => void;
};

const useUpdateUserMutation = ({ onSuccess }: UseUpdateUserMutationOptions) => {
    const queryClient = useQueryClient();
    const translateValidationErrors = useTranslateValidationErrors();
    const { errorToast } = useToaster();

    const { error, ...rest } = useMutation({
        mutationFn: updateUser,
        onError: (error) => {
            const formattedError = toGeneralError(error);

            if (formattedError) {
                errorToast(formattedError);
            }
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: useUsersQuery.getQueryKey(),
            });
            onSuccess();
        },
    });

    const errors = translateValidationErrors(getValidationErrors(error));

    return {
        ...rest,
        errors,
    };
};

export default useUpdateUserMutation;
