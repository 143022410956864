// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { OperationStatuss: OperationStatuses, operationStatusValues } = makeEnum(
    'OperationStatus',
    'running',
    'success',
    'failed',
);

export type OperationStatus = UnionOf<typeof operationStatusValues>;
