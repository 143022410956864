// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type CancelButtonProps = {
    onClick?: () => void;
};

const CancelButton = ({ onClick, ...props }: CancelButtonProps) => {
    const translate = useTranslate();

    return (
        <Button ghost icon={'cross-mark'} onClick={onClick} {...props}>
            {translate('Keys.Cancel.buttonTitle')}
        </Button>
    );
};

export default CancelButton;
