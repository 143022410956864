// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Drawer, Tabs, Tab, ContentLoader, StatusMessage, setIn } from '@plesk/ui-library';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import {
    SettingsResponse,
    SaveSettingsRequest,
} from '@platform360/security-dashboard/web/api/settings';
import { UpdateSettingsOptions } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import { useSettingsQuery } from '@platform360/security-dashboard/web/queries';
import { useSaveSettingsMutation } from '@platform360/security-dashboard/web/mutations';
import IgnoreLowRiskSection from '@platform360/security-dashboard/web/components/Settings/IgnoreLowRiskSection';
import Updates from './Updates';
import DailyTaskTime from './DailyTaskTime';

import styles from './SettingsDrawer.module.css';

export type FormValues = SettingsResponse;
type PartialFormValues = {
    updates: Partial<FormValues['updates']>;
    dailyTask: Partial<FormValues['dailyTask']>;
    vulnerabilities: Partial<FormValues['vulnerabilities']>;
};

const defaultValues: PartialFormValues = {
    updates: {},
    dailyTask: {},
    vulnerabilities: {},
};

export const defaultInitialFormValues: FormValues = Object.freeze({
    updates: {
        core: UpdateSettingsOptions.notSet,
        plugins: UpdateSettingsOptions.notSet,
        themes: UpdateSettingsOptions.notSet,
        safeUpdate: SafeUpdateOptions.notSet,
    },
    dailyTask: {
        taskStartTimeHours: null,
        taskStartTimeMinutes: null,
    },
    vulnerabilities: {
        ignoreLowRisk: true,
    },
});

export type SettingsDrawerProps = ClosableProps;

const SettingsDrawer = ({ isOpen, onClose, ...props }: SettingsDrawerProps) => {
    const translate = useTranslate('security-dashboard.SettingsDrawer');
    const { successToast } = useToaster();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const {
        data: settings,
        isLoading,
        isError,
    } = useSettingsQuery({
        enabled: isOpen,
    });

    const [formValues, setFormValues] = useState<PartialFormValues>(defaultValues);
    const [overrideDescendants, setOverrideDescendants] = useState(false);

    const handleFieldChange = (key: string, value: FormValues[keyof FormValues]) =>
        setFormValues(setIn(formValues, key, value));

    const initialFormValues = settings || defaultInitialFormValues;
    const values: FormValues = {
        updates: {
            ...initialFormValues.updates,
            ...formValues.updates,
        },
        dailyTask: {
            ...initialFormValues.dailyTask,
            ...formValues.dailyTask,
        },
        vulnerabilities: {
            ...initialFormValues.vulnerabilities,
            ...formValues.vulnerabilities,
        },
    };

    const { mutate, isPending: isSaving } = useSaveSettingsMutation();

    const handleSave = () => {
        const isUpdatesDirty =
            overrideDescendants || !isEqual(values.updates, initialFormValues.updates);
        const isDailyTaskDirty = !isEqual(values.dailyTask, initialFormValues.dailyTask);
        const isVulnerabilitiesDirty = !isEqual(
            values.vulnerabilities,
            initialFormValues.vulnerabilities,
        );

        const isDirty = isUpdatesDirty || isDailyTaskDirty || isVulnerabilitiesDirty;

        if (!isDirty) {
            close();
            return;
        }

        const data: SaveSettingsRequest = {};

        if (isUpdatesDirty) {
            data.updates = { ...values.updates, overrideDescendants };

            const isDefaultSettings = isEqual(values.updates, defaultInitialFormValues.updates);
            analyticsEvents.wpGloAutoupdateSettingsSave(
                isDefaultSettings,
                values.updates.safeUpdate,
                overrideDescendants,
            );
        }

        if (isDailyTaskDirty) {
            data.dailyTask = values.dailyTask;

            const { taskStartTimeHours, taskStartTimeMinutes } = values.dailyTask;
            const isTimeSet = taskStartTimeHours !== null && taskStartTimeMinutes !== null;
            analyticsEvents.wpGloDailyTaskTimeSettingsSave(isTimeSet);
        }

        if (isVulnerabilitiesDirty) {
            data.vulnerabilities = values.vulnerabilities;
            analyticsEvents.wpGloVulnerabilitySettingsSave(values.vulnerabilities.ignoreLowRisk);
        }

        mutate(data, {
            onSuccess: () => {
                successToast(translate('successToast'));
                setFormValues(defaultValues);
                close();
            },
        });
    };

    useEffect(() => {
        if (isOpen) {
            setActiveTabIndex(0);
            setFormValues(defaultValues);
            setOverrideDescendants(false);
            analyticsEvents.wpGloSettingsShown();
        }
    }, [isOpen, analyticsEvents]);

    const close = () => {
        analyticsEvents.wpGloSettingsClosed();
        onClose();
    };

    const tabs = [
        {
            key: 'updates',
            title: translate('tabs.updates'),
            children: (
                <Updates
                    overrideDescendants={overrideDescendants}
                    onOverrideDescendantsChange={setOverrideDescendants}
                />
            ),
        },
        {
            key: 'daily-task',
            title: translate('tabs.dailyTaskTime'),
            children: (
                <DailyTaskTime
                    initialValue={initialFormValues.dailyTask}
                    value={values.dailyTask}
                    onChange={(dailyTask) => {
                        if (dailyTask) {
                            handleFieldChange('dailyTask', dailyTask);
                        }
                    }}
                    isSaving={isSaving}
                />
            ),
        },
        {
            key: 'vulnerabilities',
            title: translate('tabs.vulnerabilities'),
            children: (
                <IgnoreLowRiskSection
                    showWarning={
                        initialFormValues.vulnerabilities.ignoreLowRisk &&
                        !values.vulnerabilities.ignoreLowRisk
                    }
                />
            ),
        },
    ];

    const activeTab = tabs[activeTabIndex];

    if (!activeTab) return null;

    const handleDrawerClose = () => {
        if (isSaving) return;
        close();
    };

    const renderContent = () => {
        if (isError) {
            return <StatusMessage intent="danger">{translate('loadFailed')}</StatusMessage>;
        }

        if (isLoading) {
            return <ContentLoader />;
        }

        return activeTab.children;
    };

    return (
        <Drawer
            title={translate('title')}
            subtitle={translate('subTitle')}
            size="xs"
            isOpen={isOpen}
            onClose={handleDrawerClose}
            form={{
                values,
                applyButton: false,
                submitButton: {
                    children: translate('buttonSave'),
                    disabled: isError,
                    'data-type': 'save-settings-button',
                },
                onFieldChange: handleFieldChange,
                state: isSaving ? 'submit' : undefined,
                onSubmit: handleSave,
                className: styles.form,
            }}
            tabs={
                <Tabs active={activeTabIndex + 1}>
                    {tabs.map(({ title, key }, index) => (
                        <Tab
                            key={key}
                            onClick={() => {
                                setActiveTabIndex(index);
                                analyticsEvents.wpGloSettingsTabClick(key);
                            }}
                            title={title}
                            data-type={`tab-${key}`}
                        />
                    ))}
                </Tabs>
            }
            {...props}
        >
            {renderContent()}
        </Drawer>
    );
};

export default SettingsDrawer;
