// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon, MenuDivider, Hint, SearchBar, Checkbox, Label, Status } from '@plesk/ui-library';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import GhostButton from '@platform360/libs/shared-web/components/GhostButton';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { Label as LabelType } from '@platform360/security-dashboard/web/types';
import { useLabelsReducer, LabelWithStatus, SelectionWithLabels } from './useLabelsReducer';
import styles from './LabelsBlock.module.css';
import { SEARCH_LABELS_PARAM } from '@platform360/security-dashboard/web/constants/searchParams';

export type AssignRemoveLabelsOptions = {
    entityIds: number[];
    labelIds: number[];
};

export type LabelsBlockProps = {
    selectionWithLabels: SelectionWithLabels;
    labels: LabelType[];
    onAssign: (data: AssignRemoveLabelsOptions) => void;
    onRemove: (data: AssignRemoveLabelsOptions) => void;
    onClose: () => void;
};

export const LabelsBlock = ({
    selectionWithLabels,
    labels,
    onAssign,
    onRemove,
    onClose,
}: LabelsBlockProps) => {
    const translate = useTranslate('security-dashboard.LabelsPopover');

    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const search = useSearch(SEARCH_LABELS_PARAM, () => analyticsEvents.wpLabelsListSearchSubmit());
    const handleSearch = (v: string) => search.onSearch(v);
    const searchValue = search.value.toLowerCase();

    const [labelsState, dispatch] = useLabelsReducer(selectionWithLabels, labels);
    const filteredLabels: LabelWithStatus[] = labelsState.labels.filter(({ title }) =>
        title.toLowerCase().includes(searchValue),
    );

    const entityIds = selectionWithLabels.map((s) => s.id);
    const { toAssign, toRemove } = labelsState;
    const isApplyDisabled = toAssign.length === 0 && toRemove.length === 0;

    const handleApply = () => {
        if (toAssign.length > 0) {
            onAssign({ entityIds, labelIds: toAssign });
        }

        if (toRemove.length > 0) {
            onRemove({ entityIds, labelIds: toRemove });
        }

        onClose();
    };

    const manageBtnShown = search.debouncedValue === '';
    const createBtnShown =
        search.debouncedValue !== '' &&
        filteredLabels.every(
            (l) => l.title.toLocaleLowerCase() !== search.debouncedValue.toLocaleLowerCase(),
        );

    if (entityIds.length === 0) {
        return (
            <div className={styles.items}>
                <div className={styles.item}>
                    <Status intent="info">{translate('noSelection')}</Status>
                </div>
                <MenuDivider />
                <div className={styles.button}>
                    <GhostButton
                        component={DrawerLink}
                        to="labels"
                        icon={<Icon name="gear" size="16" />}
                        onClick={onClose}
                    >
                        {translate('buttons.manage')}
                    </GhostButton>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.items} onClick={(e) => e.stopPropagation()}>
            <div className={styles.item}>
                <Icon size="16" />
                <Hint>{translate('title')}</Hint>
            </div>
            <div className={styles.item}>
                <SearchBar
                    inputProps={{
                        value: search.debouncedValue,
                        autoFocus: true,
                    }}
                    onSearch={handleSearch}
                    onTyping={handleSearch}
                />
            </div>
            <div className={styles.labelList}>
                {filteredLabels.map((label) => (
                    <div key={label.id} className={styles.item}>
                        <Checkbox
                            indeterminate={label.assigned === 'partially'}
                            checked={label.assigned === 'all'}
                            onChange={() => dispatch({ type: label.assigned, label })}
                        >
                            <Label view="outline" intent="inactive" caps={false}>
                                {label.title}
                            </Label>
                        </Checkbox>
                    </div>
                ))}
            </div>
            {filteredLabels.length > 0 && (
                <>
                    <MenuDivider />
                    <div className={styles.button}>
                        <GhostButton
                            icon={<Icon size="16" />}
                            onClick={handleApply}
                            ghost
                            disabled={isApplyDisabled}
                        >
                            {translate('buttons.apply')}
                        </GhostButton>
                    </div>
                </>
            )}
            {manageBtnShown && (
                <>
                    <MenuDivider />
                    <div className={styles.button}>
                        <GhostButton
                            component={DrawerLink}
                            to="labels"
                            icon={<Icon name="gear" size="16" />}
                            onClick={onClose}
                        >
                            {translate('buttons.manage')}
                        </GhostButton>
                    </div>
                </>
            )}
            {createBtnShown && (
                <>
                    <MenuDivider />
                    <div className={styles.button}>
                        <GhostButton component={DrawerLink} to="labels/create" icon="plus">
                            {translate('buttons.create')}
                        </GhostButton>
                    </div>
                </>
            )}
        </div>
    );
};
