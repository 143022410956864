// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Button,
    ContentLoader,
    Dialog,
    Heading,
    Icon,
    Label,
    Link,
    Media,
    Panel,
    Text,
} from '@plesk/ui-library';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { IconName } from '@plesk/ui-library/types/src/components/Icon/constants';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useLicenseQuery from '@platform360/security-dashboard/web/queries/useLicenseQuery';
import ExpirationLabel from '@platform360/security-dashboard/web/components/ExpirationLabel';
import LicenseHeading from '@platform360/security-dashboard/web/components/LicensePage/LicenseHeading';
import LicenseDescription from '@platform360/security-dashboard/web/components/LicensePage/LicenseDescription';
import CurrentUsage from '@platform360/security-dashboard/web/components/LicensePage/CurrentUsage';
import { useInstallationsSummaryQuery } from '@platform360/security-dashboard/web/queries';
import LicenseInfo from '@platform360/security-dashboard/web/components/LicensePage/LicenseInfo';
import styles from './LicensePage.module.css';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import {
    LicenseBenefit,
    AvailableBenefits,
} from '@platform360/security-dashboard/web/components/LicenseBenefit';
import { CustomerTypes } from '@platform360/security-dashboard/shared/customer-type';
import LicenseList from '@platform360/security-dashboard/web/components/LicensePage/LicenseList';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import BuyLicenseDrawer from '@platform360/security-dashboard/web/components/BuyLicenseDrawer';
import RecommendedLicense from './RecommendedLicense';
import { SALES_LINK } from '@platform360/security-dashboard/shared/external-links';
import { License } from '@platform360/security-dashboard/web/types';
import RecommendedPartnerLicense from '@platform360/security-dashboard/web/components/LicensePage/RecommendedPartnerLicense';

const FAQ_LICENSE_BLOCK_INDEX = 1;
const VPS_LICENSE_HIDDEN_FAQ_ITEM_INDEXES = [0, 1, 3];
const RETAIL_LICENSE_HIDDEN_FAQ_ITEM_INDEXES = [3];

const BENEFITS: AvailableBenefits[] = [
    'unlimitedRange',
    'vulnerabilityDetection',
    'secureSites',
    'autoUpdates',
    'safeUpdates',
    'notifications',
];

const RoutableBuyLicenseDrawer = withRouteBinding(BuyLicenseDrawer);

const LicensePage = () => {
    const translate = useTranslate('security-dashboard.LicensePage');
    const license = useLicenseQuery();
    const installationsSummary = useInstallationsSummaryQuery();
    const navigate = useNavigate();

    const [isDialogStandardOpen, setDialogStandardOpen] = useState(false);
    const [isDialogUnlimitedOpen, setDialogUnlimitedOpen] = useState(false);
    const [selectedSitesNumber, setSelectedSitesNumber] = useState<number | undefined>(undefined);
    const [expandedFaqs, setExpandedFaqs] = useState<Record<number, boolean>>({ 0: true });
    const licensesRef = useRef<null | HTMLDivElement>(null);
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    useEffectOnce(() => {
        analyticsEvents.wpLicensePageShown();
    });

    useEffect(() => {
        if (license.data === null) {
            navigate('/');
        }
    }, [navigate, license.data]);

    const licenseFaqBlockRef = useRef<HTMLDivElement>(null);

    if (license.data === undefined || installationsSummary.data === undefined) {
        return <ContentLoader />;
    }

    if (license.data === null) {
        return null;
    }

    const handleStandardLicenseClick = (sitesNumber: number) => {
        analyticsEvents.wpLicenseDialogStandardShown(sitesNumber);
        setSelectedSitesNumber(sitesNumber);
        setDialogStandardOpen(true);
    };

    const handleUnlimitedLicenseClick = () => {
        analyticsEvents.wpLicenseDialogUnlimitedShown();
        setDialogUnlimitedOpen(true);
    };

    const terminated = license.data.status === 'terminated';

    const trialJustStarted = license.data.trial && installationsSummary.data.total === 0;

    const isRetailCustomer = license.data.customerType === CustomerTypes.retail;

    const showRecommendedPartnerLicense =
        license.data.customerType === CustomerTypes.partner &&
        !trialJustStarted &&
        (license.data.trial || terminated || license.data.product !== 'unlimited');

    const showLicenses = (license: License) => {
        if (license.customerType === CustomerTypes.retail) {
            return true;
        }
        if (license.customerType === CustomerTypes.partner) {
            return license.product !== 'unlimited' || license.status === 'terminated';
        }
        // Customer type VPS
        return false;
    };

    const operableCount = installationsSummary.data.total - installationsSummary.data.notOperable;
    const outOfLimit = (): number | undefined => {
        if (!license.data?.sitesLimitHard || operableCount <= license.data.sitesLimitHard) {
            return undefined;
        }
        return (
            installationsSummary.data.total -
            license.data.sitesLimitHard -
            installationsSummary.data.notOperable
        );
    };

    const expandLicenseFaqBlock = () => {
        setExpandedFaqs({
            ...expandedFaqs,
            1: true,
        });
        const element = licenseFaqBlockRef.current;
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const renderLicenseOffers = () => {
        if (isRetailCustomer) {
            return (
                <LicenseList
                    onFaqLinkClick={expandLicenseFaqBlock}
                    totalInstallationsCount={installationsSummary.data.total}
                />
            );
        }

        return (
            <div className={styles.licenses} data-type="partner-license-offer">
                <LicenseInfo
                    license={license.data}
                    licenseType="unlimited"
                    intent="primary"
                    showLabel
                    onClick={handleUnlimitedLicenseClick}
                />
                <LicenseInfo
                    license={license.data}
                    licenseType="standard"
                    totalInstallationsCount={installationsSummary.data.total}
                    showLabel
                    onClick={handleStandardLicenseClick}
                />
            </div>
        );
    };

    const getCurrentLicenseStyle = () => {
        if (license.data === null || terminated) {
            return styles.currentLicenseTerminated;
        }
        if (trialJustStarted) {
            return undefined;
        }
        if (license.data.trial) {
            return styles.currentLicenseTrial;
        }
        if (license.data.sitesLimitHard !== null) {
            return styles.currentLicenseStandard;
        }
        return styles.currentLicenseUnlimited;
    };

    const scrollToLicenses = () => {
        const element = licensesRef.current;
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const licenseLinks = {
        kaLink: (parts: ReactNode) => (
            <Link
                href="https://central.plesk.com/"
                target="_blank"
                onClick={() => analyticsEvents.wpKeyAdministratorLinkClicked()}
            >
                {parts}
            </Link>
        ),
        manage2Link: (parts: ReactNode) => (
            <Link
                href="https://manage2.cpanel.net/"
                target="_blank"
                onClick={() => analyticsEvents.wpManage2LinkClicked()}
            >
                {parts}
            </Link>
        ),
        partnerLink: (parts: ReactNode) => (
            <Link
                href={SALES_LINK}
                target="_blank"
                onClick={() => analyticsEvents.wpLicensePageLicenseRetailLinkBecomePartnerClicked()}
            >
                {parts}
            </Link>
        ),
    };

    const textGetLicense = translate('licenseDialog.getLicenseInKa', licenseLinks);

    return (
        <div>
            <div className={styles.section}>
                <RouterLink className={styles.goBackLink} to="/">
                    <Icon name="chevron-left" />
                    {translate('goBack')}
                </RouterLink>
                <div className={styles.currentLicense} data-type="container-product">
                    <div
                        className={classNames(
                            styles.currentLicenseBackground,
                            getCurrentLicenseStyle(),
                        )}
                    />
                    <div className={styles.currentLicenseInfo}>
                        <div>
                            <LicenseHeading
                                license={license.data}
                                trialJustStarted={trialJustStarted}
                            />
                            <LicenseDescription
                                license={license.data}
                                trialJustStarted={trialJustStarted}
                            />
                        </div>
                        {trialJustStarted && (
                            <div>
                                <ExpirationLabel license={license.data} />
                                <br />
                                {!terminated && (
                                    <Label caps={false} view="light" size="lg">
                                        {translate('CurrentUsage.allowedToManage', {
                                            sites: license.data.sitesLimitHard,
                                        })}
                                    </Label>
                                )}
                                <div>
                                    <Button
                                        onClick={scrollToLicenses}
                                        intent="primary"
                                        size="lg"
                                        className={styles.currentLicenseChooseBtn}
                                    >
                                        {translate('chooseLicenseNow')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!trialJustStarted && (
                            <CurrentUsage
                                total={installationsSummary.data.total}
                                sitesLimitHard={license.data.sitesLimitHard ?? undefined}
                                outOfLimit={outOfLimit()}
                                terminated={terminated}
                                notOperable={installationsSummary.data.notOperable}
                            />
                        )}
                    </div>
                    {showRecommendedPartnerLicense && (
                        <RecommendedPartnerLicense
                            totalInstallationsCount={installationsSummary.data.total}
                            license={license.data}
                            handleUnlimitedLicenseClick={handleUnlimitedLicenseClick}
                            handleStandardLicenseClick={handleStandardLicenseClick}
                        />
                    )}
                    {isRetailCustomer && (
                        <RecommendedLicense
                            totalInstallationsCount={installationsSummary.data.total}
                            license={license.data}
                        />
                    )}
                </div>
            </div>
            <div className={styles.section}>
                <Heading level={2} className={styles.sectionHeading}>
                    {translate('benefits.title')}
                </Heading>
                <div className={styles.benefitsRow}>
                    {BENEFITS.map((benefit) => (
                        <div key={benefit} className={styles.benefit}>
                            <LicenseBenefit benefit={benefit} />
                        </div>
                    ))}
                </div>
            </div>
            {showLicenses(license.data) && (
                <div className={styles.backgroundGray} data-type="license-offer">
                    <div className={styles.section} ref={licensesRef}>
                        <Heading level={2} className={styles.sectionHeading}>
                            {translate('licensesTitle')}
                        </Heading>
                        {renderLicenseOffers()}
                    </div>
                </div>
            )}
            <div className={styles.section}>
                <Heading level={2} className={styles.sectionHeading}>
                    {translate('faq.title')}
                </Heading>
                {Array.from({ length: 5 }).map((_, questionIndex) => {
                    if (
                        license.data?.customerType === CustomerTypes.vps &&
                        VPS_LICENSE_HIDDEN_FAQ_ITEM_INDEXES.includes(questionIndex)
                    ) {
                        return null;
                    }
                    if (
                        license.data?.customerType === CustomerTypes.retail &&
                        RETAIL_LICENSE_HIDDEN_FAQ_ITEM_INDEXES.includes(questionIndex)
                    ) {
                        return null;
                    }
                    return (
                        <Panel
                            // eslint-disable-next-line react/no-array-index-key
                            key={`faq${questionIndex}`}
                            extra={
                                questionIndex === FAQ_LICENSE_BLOCK_INDEX && (
                                    <div ref={licenseFaqBlockRef} />
                                )
                            }
                            title={translate(`faq.question${questionIndex + 1}.question`)}
                            collapsible
                            collapsed={!expandedFaqs[questionIndex]}
                            onToggle={(collapsed) => {
                                setExpandedFaqs({
                                    ...expandedFaqs,
                                    [questionIndex]: !collapsed,
                                });
                            }}
                        >
                            <Text component="div" intent="muted">
                                {translate(`faq.question${questionIndex + 1}.answer`, {
                                    br: <br />,
                                    providerLogo: <Patchstack />,
                                    whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                                    ...licenseLinks,
                                })}
                            </Text>
                        </Panel>
                    );
                })}
            </div>
            <Dialog
                isOpen={isDialogStandardOpen}
                title={
                    selectedSitesNumber
                        ? translate('licenseDialog.standard.title', { sites: selectedSitesNumber })
                        : undefined
                }
                sideBanner={
                    <div
                        className={classNames(
                            styles.sideBannerImage,
                            styles.sideBannerImageStandard,
                        )}
                    />
                }
                size="sm"
                cancelButton={false}
                onClose={() => setDialogStandardOpen(false)}
                data-type={'standard-dialog'}
            >
                <p>
                    <Label view="light" size="lg" intent="danger">
                        {selectedSitesNumber
                            ? translate('licenseDialog.standard.licenseLimit', {
                                  sites: selectedSitesNumber,
                              })
                            : null}
                    </Label>
                </p>
                <Text component="p" bold>
                    {textGetLicense}
                </Text>
                {Array.from({ length: 6 }, (_, featureIndex) => {
                    const icons: IconName[] = [
                        'site-page',
                        'circle-diagonal',
                        'arrow-up-circle',
                        'check-mark-circle',
                        'clock',
                        'cross-mark-circle',
                    ];
                    return (
                        <Media
                            image={<Icon name={icons[featureIndex]} />}
                            className={styles.dialogItem}
                            key={`standardFeature${featureIndex}`}
                        >
                            {translate(`licenseDialog.standard.feature${featureIndex + 1}`)}
                        </Media>
                    );
                })}
            </Dialog>
            <Dialog
                isOpen={isDialogUnlimitedOpen}
                title={translate('licenseDialog.unlimited.title')}
                sideBanner={
                    <div
                        className={classNames(
                            styles.sideBannerImage,
                            styles.sideBannerImageUnlimited,
                        )}
                    />
                }
                size="sm"
                cancelButton={false}
                onClose={() => setDialogUnlimitedOpen(false)}
            >
                <Text component="p" bold>
                    {textGetLicense}
                </Text>
                {Array.from({ length: 4 }, (_, featureIndex) => {
                    const icons: IconName[] = [
                        'site-page',
                        'bar-chart-vertical-arrow-up',
                        'clock',
                        'cross-mark-circle',
                    ];
                    return (
                        <Media
                            image={<Icon name={icons[featureIndex]} />}
                            className={styles.dialogItem}
                            key={`unlimitedFeature${featureIndex}`}
                        >
                            {translate(`licenseDialog.unlimited.feature${featureIndex + 1}`)}
                        </Media>
                    );
                })}
            </Dialog>
            <RoutableBuyLicenseDrawer
                openPath="buy/:product"
                closePath="/security-dashboard/license-page"
            />
        </div>
    );
};

export default LicensePage;
