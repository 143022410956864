// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './NotFound.module.css';
import octopus from './octopus.gif';
import { Button } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const NotFound = () => {
    const translate = useTranslate('admin-panel.NotFound');

    return (
        <div className={styles.root}>
            <img className={styles.image} src={octopus} alt="Octopus" />
            <div className={styles.content}>
                <div className={styles.code}>{'404'}</div>
                <div className={styles.text}>{'WHOOPS!'}</div>
                <br />
                <Button component={Link} size="lg" intent="primary" to="/auth/logout">
                    {translate('logoutBtn')}
                </Button>
            </div>
        </div>
    );
};
