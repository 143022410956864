// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactElement, useEffect, useRef } from 'react';
import styles from './GlobalSearch.module.less';
import { UseGlobalSearchQueryData } from './useGlobalSearchQuery';
import ClientResultGroup from './ClientResultGroup';
import DomainResultGroup from './DomainResultGroup';
import ServersResultGroup from './ServersResultGroup';
import { useBoolean } from 'usehooks-ts';
import {
    AutoClosable,
    Button,
    ContentLoader,
    Icon,
    Input,
    Menu,
    MenuItem,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type GlobalSearchProps = {
    onClickResult: () => void;
    onSearch: (e: string) => void;
    data?: UseGlobalSearchQueryData;
    closeSearch: () => void;
    isLoading?: boolean;
    searchQuery: string;
};

const GlobalSearch = ({
    onClickResult,
    closeSearch,
    onSearch,
    isLoading,
    data,
    searchQuery,
}: GlobalSearchProps) => {
    const translate = useTranslate('Page.GlobalSearch');
    const { value: isMenuShown, setTrue: showMenu, setFalse: hideMenu } = useBoolean(false);
    const globalSearchRef = useRef() as React.RefObject<HTMLElement>;

    let menu: ReactElement | undefined = undefined;

    const handleClickResult = () => {
        onClickResult();
        closeSearch();
    };

    useEffect(() => {
        if (data) {
            showMenu();
        }
    }, [data, showMenu]);

    if (data && isMenuShown) {
        const dataKeys = Object.keys(data) as (keyof UseGlobalSearchQueryData)[];
        const noResults = !dataKeys.some((key) => Boolean(data[key].totalCount));
        const { clients, domains, servers } = data;
        const menuContent = noResults ? (
            <MenuItem disabled>{translate('noResults')}</MenuItem>
        ) : (
            <>
                {Boolean(clients.totalCount) && (
                    <ClientResultGroup
                        clients={clients.data}
                        totalCount={clients.totalCount}
                        onClick={handleClickResult}
                        searchQuery={searchQuery}
                    />
                )}
                {Boolean(domains.totalCount) && (
                    <DomainResultGroup
                        domains={domains.data}
                        totalCount={domains.totalCount}
                        onClick={handleClickResult}
                        searchQuery={searchQuery}
                    />
                )}
                {Boolean(servers.totalCount) && (
                    <ServersResultGroup
                        servers={servers.data}
                        totalCount={servers.totalCount}
                        onClick={handleClickResult}
                        searchQuery={searchQuery}
                    />
                )}
            </>
        );
        menu = (
            <Menu className={styles.results} data-type="search-result">
                {menuContent}
            </Menu>
        );
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeSearch();
        }
    };
    const handleFocus = () => {
        if (data && !isMenuShown) {
            showMenu();
        }
    };
    const handleClose = () => {
        closeSearch();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        onSearch(query);
    };

    const prefix = isLoading ? (
        <ContentLoader data-type="search-input-loader" />
    ) : (
        <Icon name="search" />
    );

    return (
        <AutoClosable onClose={hideMenu} nodeRef={globalSearchRef}>
            <div className={styles.root}>
                <Input
                    autoFocus
                    className={styles.input}
                    prefix={prefix}
                    suffix={
                        <Button
                            icon="cross-mark"
                            ghost
                            onClick={handleClose}
                            data-type="search-close-button"
                        />
                    }
                    placeholder={translate('search')}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    value={searchQuery}
                    data-type="search-input"
                />
                {menu}
            </div>
        </AutoClosable>
    );
};

export default GlobalSearch;
