// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TaskManagerListRow } from '@platform360/security-dashboard/web/types';
import { List, Text } from '@plesk/ui-library';
import { ListProps } from '@platform360/libs/shared-web/components/List';

type RowBodyProps = {
    row: TaskManagerListRow;
};

type ErrorListRow = {
    id: number;
    error: string;
};

export const RowBody = ({ row }: RowBodyProps) => {
    const errors: ErrorListRow[] = row.errors.map((error, id) => ({ id, error }));

    const columns: ListProps<ErrorListRow>['columns'] = [
        {
            key: 'errors',
            render: ({ error }) => <Text>{error}</Text>,
        },
    ];

    if (errors.length === 1) {
        const [element] = errors;
        return <Text>{element?.error}</Text>;
    }

    return <List columns={columns} data={errors} rowKey={(row) => row.id.toString()} />;
};

export default RowBody;
