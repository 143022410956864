// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Label, Status, Text, ButtonProps } from '@plesk/ui-library';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { VulnerabilityInstallationOperations } from '@platform360/security-dashboard/shared/vulnerability-installation-operation';
import { useCountOfAffectedSitesByVulnerabilitiesQuery } from '@platform360/security-dashboard/web/queries';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { IgnoreDoNotProtectMessage } from '@platform360/security-dashboard/web/components/IgnoreDoNotProtectMessage';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { Vulnerability } from '@platform360/security-dashboard/web/types';
import styles from './PatchButton.module.less';

export type PatchButtonProps = {
    selectedItems: string[];
    vulnerabilities: Vulnerability[];
    onHandlePatch: (options: { ignoreDoNotProtect: boolean }) => void;
} & ButtonProps;

export const PatchButton = ({
    selectedItems,
    vulnerabilities,
    onHandlePatch,
    ...props
}: PatchButtonProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Toolbar.PatchButton');
    const navigate = useNavigate();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const [ignoreDoNotProtect, setIgnoreDoNotProtect] = useState(false);

    const {
        data = {
            affectedSitesCount: 0,
            operableNotManaged: 0,
            doNotProtect: 0,
        },
        refetch,
        status,
    } = useCountOfAffectedSitesByVulnerabilitiesQuery({
        variables: {
            vulnerabilitiesIds: selectedItems,
            operation: VulnerabilityInstallationOperations.patch,
        },
        enabled: false,
    });
    const btn = (
        <Button {...props} icon="shield-filled" intent="primary">
            {translate('patch')}
        </Button>
    );

    if (props.onClick) return btn;

    const vulnerabilitiesWithPatch = vulnerabilities.filter(
        (v) => v.installationsWithMitigationPatchAvailable > 0,
    );
    const selectedVulnerabilitiesWithPatchCount = vulnerabilitiesWithPatch.filter((v) =>
        selectedItems.includes(v.vulnerabilityId),
    ).length;
    const skippedVulnerabilitiesCount =
        selectedItems.length - selectedVulnerabilitiesWithPatchCount;

    const { affectedSitesCount, operableNotManaged } = data;
    const operableSitesCount = affectedSitesCount - operableNotManaged;
    const patchAvailable = affectedSitesCount !== operableNotManaged && affectedSitesCount > 0;

    const checkShouldShowLicenseInfo = ({ operableNotManaged, affectedSitesCount }: typeof data) =>
        affectedSitesCount === operableNotManaged && affectedSitesCount > 0;
    const isLicenseInfoShown = checkShouldShowLicenseInfo(data);

    if (selectedItems.length === 0) {
        return (
            <NoSelectionPopover selectionCount={selectedItems.length} target={btn}>
                {translate('noSelection')}
            </NoSelectionPopover>
        );
    }

    const handleConfirmationShown = async () => {
        const { data } = await refetch();
        if (!data) return;
        analyticsEvents.wpMltProtectVulnerabilitiesClick(
            selectedItems.length,
            checkShouldShowLicenseInfo(data),
        );
    };

    const renderIgnoreDoNotProtectMessage = () => {
        if (data.doNotProtect === 0) return null;

        let installationsLinkQueryParams: Record<string, string> | undefined = undefined;
        const singleSelectedVulnerability =
            selectedItems.length === 1 &&
            vulnerabilities.find(({ vulnerabilityId }) => vulnerabilityId === selectedItems[0]);
        if (singleSelectedVulnerability) {
            installationsLinkQueryParams = {
                search: encodeURIComponent(singleSelectedVulnerability.vulnerabilityId),
            };
        }

        return (
            <IgnoreDoNotProtectMessage
                doNotProtectCount={data.doNotProtect}
                ignoreDoNotProtect={ignoreDoNotProtect}
                onIgnoreDoNotProtectChange={setIgnoreDoNotProtect}
                installationsLinkQueryParams={installationsLinkQueryParams}
            />
        );
    };

    const renderAffectedSites = () => {
        if (status === 'pending') {
            return <Status progress>{translate('sitesCalculating')}</Status>;
        }
        if (status === 'error') {
            return (
                <Status intent="danger" compact>
                    {translate('sitesCalculatingError')}
                </Status>
            );
        }
        if (operableSitesCount === 0 || selectedVulnerabilitiesWithPatchCount === 0) {
            return <div className={styles.confirmationSection}>{translate('noAffectedSites')}</div>;
        }

        return (
            <div className={styles.confirmationSection}>
                <div className={styles.confirmationSection}>
                    {translate('affectedVulnerabilities', {
                        count: selectedVulnerabilitiesWithPatchCount,
                        total: skippedVulnerabilitiesCount > 0 ? selectedItems.length : 0,
                    })}
                </div>
                <div className={styles.confirmationSection}>
                    <Text intent="muted" className={styles.sitesHint}>
                        {translate(operableNotManaged ? 'numberOfSitesUnmanaged' : 'numberOfSites')}
                    </Text>
                    <div className={styles.labelsContainer}>
                        <Label view="light" caps={false} size="md">
                            {translate('affectedSites', { count: data.affectedSitesCount })}
                        </Label>
                        {operableNotManaged > 0 && (
                            <Label view="outline" caps={false} size="sm" intent="danger">
                                {translate('unmanagedLabel', {
                                    count: operableNotManaged,
                                })}
                            </Label>
                        )}
                    </div>
                </div>
                {skippedVulnerabilitiesCount > 0 && (
                    <div className={styles.confirmationSection}>
                        {translate('skippedVulnerabilities', {
                            count: skippedVulnerabilitiesCount,
                        })}
                    </div>
                )}
                <div className={styles.confirmationSection}>
                    {renderIgnoreDoNotProtectMessage()}
                </div>
                <div className={styles.confirmationSection}>
                    {translate('providerLogo', {
                        providerLogo: <Patchstack />,
                        whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                    })}
                </div>
            </div>
        );
    };

    if (isLicenseInfoShown) {
        return (
            <ConfirmationPopover
                onClick={() => {
                    navigate('/security-dashboard/license-page');
                    analyticsEvents.wpMltProtectVulnerabilitiesLicenseClick();
                }}
                onShow={handleConfirmationShown}
                popoverClassName={styles.popover}
                target={btn}
                actionButtonText={translate('upgradeLicense')}
                cancelButtonText={translate('cancel')}
                confirmationButtonIntent="primary"
            >
                <div className={styles.confirmationSection}>
                    {translate('vulnerabilitiesOnUnmanagedSites')}
                </div>
            </ConfirmationPopover>
        );
    }

    return (
        <ConfirmationPopover
            onClick={() => {
                onHandlePatch({ ignoreDoNotProtect });
                analyticsEvents.wpMltProtectVulnerabilitiesConfirmationClick(selectedItems.length, {
                    ignoreDoNotProtect,
                    doNotProtectCount: data.doNotProtect,
                    protectionDisabledTextShown: data.doNotProtect !== 0,
                });
            }}
            onShow={() => {
                void handleConfirmationShown();
                setIgnoreDoNotProtect(false);
            }}
            target={btn}
            popoverClassName={styles.popover}
            actionButtonText={translate('update')}
            cancelButtonText={translate('cancel')}
            actionButtonDisabled={!patchAvailable}
            confirmationButtonIntent="primary"
        >
            {renderAffectedSites()}
        </ConfirmationPopover>
    );
};

export default PatchButton;
