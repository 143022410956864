// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './Menu.module.less';
import { Icon, IconName, TranslateProps } from '@plesk/ui-library';
import { NavLink } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import {
    PERMISSION_ALL_EMAIL_METAS_MANAGE,
    PERMISSION_ALL_USERS_MANAGE,
    PERMISSION_WPG_LICENSES_MANAGE,
    PERMISSION_WPG_AGENT_REQUESTS_MANAGE,
    PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE,
} from '@platform360/libs/common/permissions';

type MenuItem = {
    to: string;
    icon: IconName;
    title: TranslateProps;
    permission: string;
};

export const Menu = () => {
    const translate = useTranslate('admin-panel.Layout.Menu');
    const { permissions: userPermissions } = useCurrentUser();

    const menu: MenuItem[] = [
        {
            to: '/admin-panel/email-metas',
            icon: 'book-email',
            title: translate('emailMetas'),
            permission: PERMISSION_ALL_EMAIL_METAS_MANAGE,
        },
        {
            to: '/admin-panel/leika-email-hashes',
            icon: 'book-email',
            title: translate('leikaEmailHashes'),
            permission: PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE,
        },
        {
            to: '/admin-panel/users',
            icon: 'user',
            title: translate('users'),
            permission: PERMISSION_ALL_USERS_MANAGE,
        },
        {
            to: '/admin-panel/security-dashboard/licenses',
            icon: 'ribbon',
            title: translate('wpgLicenses'),
            permission: PERMISSION_WPG_LICENSES_MANAGE,
        },
        {
            to: '/admin-panel/security-dashboard/requests',
            icon: 'arrows-opposite',
            title: translate('wpgAgentRequests'),
            permission: PERMISSION_WPG_AGENT_REQUESTS_MANAGE,
        },
    ];

    return (
        <ul className={styles.root}>
            {menu
                .filter(({ permission }) => userPermissions?.includes(permission))
                .map(({ to, icon, title }) => (
                    <li key={to}>
                        <NavLink
                            to={to}
                            className={({ isActive }) => (isActive ? styles.active : undefined)}
                        >
                            <>
                                <Icon className={styles.icon} name={icon} />
                                {title}
                            </>
                        </NavLink>
                    </li>
                ))}
        </ul>
    );
};
