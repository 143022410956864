// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link } from 'react-router-dom';
import { Checkbox, ExtendedStatusMessage, Heading } from '@plesk/ui-library';
import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { LabelTypes } from '@platform360/security-dashboard/shared/label-type';
import { LABEL_IDS_FILTER } from '@platform360/security-dashboard/web/types';
import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import styles from './IgnoreDoNotProtectMessage.module.less';

export type IgnoreDoNotProtectMessageProps = {
    doNotProtectCount: number;
    ignoreDoNotProtect: boolean;
    onIgnoreDoNotProtectChange: (value: boolean) => void;
    installationsLinkQueryParams?: Record<string, string>;
};

export const IgnoreDoNotProtectMessage = ({
    doNotProtectCount,
    ignoreDoNotProtect,
    onIgnoreDoNotProtectChange,
    installationsLinkQueryParams,
}: IgnoreDoNotProtectMessageProps) => {
    const translate = useTranslate('security-dashboard.IgnoreDoNotProtectMessage');
    const { data: labelsData, isLoading: isLoadingLabels } = useLabelsQuery();

    const renderDescription = () => {
        const doNotProtectLabel = labelsData?.data.find(
            ({ type }) => type === LabelTypes.doNotProtect,
        );

        return translate('description', {
            count: doNotProtectCount,
            a: (parts) => {
                if (!installationsLinkQueryParams) return parts;

                const content = <b>{parts}</b>;
                if (isLoadingLabels || !doNotProtectLabel) {
                    return content;
                }

                const installationsLink = buildUrl('/security-dashboard/installations', {
                    ...installationsLinkQueryParams,
                    [LABEL_IDS_FILTER]: String(doNotProtectLabel.id),
                });

                return <Link to={installationsLink}>{content}</Link>;
            },
        });
    };

    return (
        <ExtendedStatusMessage className={styles.message} intent="info">
            <Heading level={5}>{translate('title')}</Heading>
            <div>{renderDescription()}</div>
            <Checkbox
                checked={ignoreDoNotProtect}
                onChange={onIgnoreDoNotProtectChange}
                className={styles.checkbox}
            >
                {translate('ignoreCheckbox', { count: doNotProtectCount })}
            </Checkbox>
        </ExtendedStatusMessage>
    );
};
