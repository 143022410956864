// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import classNames from 'classnames';
import './InstallStep.less';

type InstallStepProps = {
    children: ReactNode;
    status: string;
};

const InstallStep = ({ children, status }: InstallStepProps) => {
    const bannerClasses = {
        'pwi-step__banner--process': status === 'running',
        'pwi-step__banner--completed': status === 'completed' || status === 'pleskinstalled',
        'pwi-step__banner--cancelled':
            status === 'error' || status === 'cancelled' || status === 'cpanelinstalled',
        'pwi-step__banner--unsupported-os': status === 'unsupportedos',
        'pwi-step__banner--not-found': status === 'notfound',
        'pwi-step__banner--plesk-outdated': status === 'pleskoutdated',
        'pwi-step__banner--mysql-failure': status === 'mysqlfailure',
        'pwi-step__banner--not-enough-disk-space': status === 'notenoughdiskspace',
    };

    return (
        <div className="pwi-container">
            <div className="container__inner">
                <div className="pwi-step">
                    <div className={classNames('pwi-step__banner', bannerClasses)} />
                    <div className="pwi-step__content">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default InstallStep;
