// Copyright 2024. WebPros International GmbH. All rights reserved.

import { memo } from 'react';
import styles from './Header.module.css';
import { Dropdown, Menu, MenuItem, Text } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import GhostButton from '@platform360/libs/shared-web/components/GhostButton';
import IconGravatar from '@platform360/libs/shared-web/components/UserMenu/IconGravatar';
import { useMediaQuery } from 'usehooks-ts';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const Header = () => {
    const isHideText = useMediaQuery('(max-width: 767px)');
    const currentUser = useCurrentUser();
    const translate = useTranslate('admin-panel.Layout.Header');

    return (
        <div className={styles.root}>
            <div className={styles.options}>
                <Dropdown
                    menu={
                        <Menu>
                            <MenuItem component={Link} to="/auth/logout" icon="cross-mark">
                                {translate('logoutBtn')}
                            </MenuItem>
                        </Menu>
                    }
                    menuPlacement="bottom-end"
                >
                    <GhostButton caret>
                        <IconGravatar userProfile={currentUser} />
                        {!isHideText && (
                            <Text truncate className={styles.userName}>
                                {currentUser.name}
                            </Text>
                        )}
                    </GhostButton>
                </Dropdown>
            </div>
        </div>
    );
};

export default memo(Header);
