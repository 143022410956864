// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import axiosErrorHandler from '@platform360/licenses/web/api/axiosErrorHandler';
import { AxiosRequestConfig } from 'axios';

/**
 * wrapper for the requester
 * @param request
 * @param errorHandler
 */
const doRequest = async <TReturn extends unknown>(
    request: AxiosRequestConfig,
    errorHandler = axiosErrorHandler,
): Promise<TReturn> => {
    try {
        const response = await apiClient.request(request);
        return response.data;
    } catch (error) {
        errorHandler(error);
        throw error;
    }
};

export default doRequest;
