// Copyright 2024. WebPros International GmbH. All rights reserved.

import Label from '@platform360/security-dashboard/web/components/UpdateSettingsCard/Label';
import { Button, Text, TranslationProps } from '@plesk/ui-library';
import styles from './UpdateSettingsCard.module.css';
import { UpdateSettingsResponse } from '@platform360/security-dashboard/web/api/settings';
import { SafeUpdateOptions } from '@platform360/security-dashboard/shared/safe-update-option';
import { ReactElement } from 'react';
import pick from 'lodash/pick';
import {
    isUpdateSettingsOption,
    UpdateSettingsOption,
} from '@platform360/security-dashboard/shared/update-settings-option';
import classNames from 'classnames';

const transformResponse = (data: UpdateSettingsResponse): UpdateSetting[] => {
    const result: Record<string, UpdateSetting> = {};
    const entries = Object.entries(pick(data, 'core', 'plugins', 'themes'));

    for (const [component, option] of entries) {
        if (!isUpdateSettingsOption(option)) {
            continue;
        }

        if (!result[option]) {
            result[option] = {
                option,
                components: [],
            };
        }

        result[option].components.push(component);
    }

    return Object.values(result);
};

type UpdateSetting = {
    option: UpdateSettingsOption;
    components: string[];
};

export type UpdateSettingsCardProps = {
    title: TranslationProps;
    updateSettings: UpdateSettingsResponse;
    button?: ReactElement;
    onButtonClick?: () => void;
    className?: string;
};

export const UpdateSettingsCard = ({
    button,
    title,
    updateSettings,
    onButtonClick,
    className,
    ...props
}: UpdateSettingsCardProps) => (
    <div className={classNames(className, styles.root)} {...props}>
        <div className={styles.container}>
            <Text intent="muted" className={styles.title}>
                {title}
            </Text>
            {button ? button : <Button ghost icon="gear" onClick={onButtonClick} />}
        </div>
        <div className={styles.labels}>
            {transformResponse(updateSettings).map(({ components, option }) => (
                <div key={option}>
                    <Label
                        safeUpdate={updateSettings.safeUpdate === SafeUpdateOptions.enabled}
                        components={components}
                        option={option}
                    />
                </div>
            ))}
        </div>
    </div>
);
