// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        unknown: 'Erro desconhecido: {message}.',
        newServer: 'Já existe um servidor com o mesmo endereço.',
        domainName: 'O nome de domínio não pode ser resolvido.',
        serverMisconfigured: 'O servidor está configurado incorretamente e não pode ser adicionado.',
        pleskConfiguration: 'A versão do Plesk não é suportada (abaixo de {pleskMinVersion}) ou o Plesk está mal configurado.',
        unsupportedServerVersion: 'A versão instalada do Plesk está desatualizada e não pode ser adicionada. {kbLink}',
        connection: 'A conexão de rede foi abortada.',
        connectionRefused: 'A conexão de rede foi recusada. Verifique o domínio e a porta especificados.',
        timeout: 'A conexão de rede com o servidor expirou.',
        apiKey: 'A conexão não é autorizada pelo servidor remoto. Adicione o servidor ao Inventário do Servidor novamente.',
        httpsRequired: 'O servidor não suporta HTTPS.',
        certHasExpired: 'O certificado expirou. {kbLink}',
        errTlsCertAltnameInvalid: 'O nome de host ou endereço IP do servidor não corresponde aos altnames do certificado. {kbLink}',
        depthZeroSelfSignedCert: 'Um certificado autoassinado é instalado. {kbLink}',
        selfSignedCertInChain: 'Há um certificado autoassinado na cadeia de certificados. {kbLink}',
    },
    mutations: {
        useUpdateNotificationsSettings: {
            successMessage: 'As configurações de notificação foram atualizadas.',
        },
    },
    Layout: {
        title: 'Inventário do Servidor',
        serversTitle: 'Servidores',
        clientsTitle: 'Clientes',
        HeaderAddon: {
            NotificationsSettingsDrawer: {
                title: 'Configuração de Notificações',
            },
        },
        NotificationsSettingsMenuItem: {
            title: 'Notificações',
        },
    },
    NotificationsSettings: {
        notifySectionTitle: 'Quais notificações enviar',
        notifyServerUnreachable: 'Servidor inacessível',
        notifyServerUnsupported: 'Servidor não suportado',
    },
    AllDomains: {
        title: 'Websites',
        name: 'Nome',
        hostingType: 'Tipo de Hospedagem',
        virtualHostingType: 'Website',
        forwardingHostingType: 'Redirecionamento',
        noneHostingType: 'Sem hospedagem',
        mainDomainType: 'Domínio Principal',
        subDomainType: 'Subdomínio',
        addonDomainType: 'Domínio Addon',
        parkedDomainType: 'Alias',
        hostingServer: 'Servidor de Hospedagem',
        manage: 'Gerenciar',
        manageLinkUnavailableHint: 'Plesk RESTful API 1.4.0 ou posterior é necessário para gerar um link direto para o painel Plesk. Verifique a Documentação para mais detalhes.',
        open: 'Abrir',
    },
    AllClients: {
        name: 'Nome de Contato',
        email: 'Endereço de E-mail',
        login: 'Nome de Usuário',
        type: 'Tipo',
        status: 'Status',
        typeAdmin: 'Admin',
        typeReseller: 'Revendedor',
        typeCustomer: 'Cliente',
        typeCustomerWithOwner: 'Cliente de {owner}',
        statusActive: 'Ativo',
        statusSuspended: 'Suspenso',
        hostingServer: 'Servidor de Hospedagem',
        manage: 'Gerenciar',
    },
    Servers: {
        ServerStatus: {
            synchronizationInProgress: 'Sincronização em andamento',
            installationRunning: 'Instalação em execução',
            installationFailure: 'Erro de Instalação',
            installationLicenseRequired: 'Licença necessária',
            active: 'Ativo, seguro',
            inactiveUnreachable: 'Inativo, inacessível',
            inactiveUnreachableTooltip: 'O servidor não pôde ser acessado por pelo menos quatorze dias',
            inactiveUnsupported: 'Inativo, não suportado',
            inactiveUnsupportedTooltip: 'O servidor está executando uma versão do Plesk não suportada',
            insecure: 'Ativo, não seguro',
            outdated: 'Ativo, desatualizado',
            outdatedTooltip: '{version, select, _ {Sua versão atual do Plesk está desatualizada.} other {Sua versão atual do Plesk {version} está desatualizada.}} Recomendamos que você atualize seu servidor para a versão mais recente disponível do Plesk Obsidian e habilite as atualizações automáticas.',
            unsupportedVersion: 'Ativo, não suportado',
            unsupportedVersionTooltip: '{version, select, _ {Sua versão atual do Plesk não é suportada} other {Sua versão atual do Plesk {version} não é suportada}} e seu servidor será desativado em 14 dias. Recomendamos que você atualize seu servidor para a versão mais recente disponível do Plesk Obsidian e habilite as atualizações automáticas.',
            certificateWarning: 'Aviso de certificado',
            certificateError: 'Erro de certificado',
            connectionBlocked: 'Conexão bloqueada',
            connectionError: 'Erro de conexão',
        },
        ViewServer: {
            InstallationLog: {
                logNotFound: 'Logs de instalação não encontrados.',
            },
            GeneralInfo: {
                StatusMessages: {
                    OutdatedStatusMessage: {
                        message: '{version, select, _ {Sua versão atual do Plesk está desatualizada.} other {Sua versão atual do Plesk {version} está desatualizada.}}',
                        fixDescription: 'Recomendamos que você atualize seu servidor para a versão mais recente disponível do Plesk Obsidian e habilite as atualizações automáticas. Leia a <link>Política de Ciclo de Vida do Plesk</link> para obter mais informações.',
                        goToUpdatesButton: 'Vá para atualizações do {product}',
                    },
                    UnsupportedStatusMessage: {
                        message: '{version, select, _ {Sua versão atual do Plesk não é compatível} other {Sua versão atual do Plesk {version} não é compatível}} e será desativado em 14 dias.',
                        fixDescription: 'Recomendamos que você atualize seu servidor para a versão mais recente disponível do Plesk Obsidian e habilite as atualizações automáticas. Leia a <link>Política de Ciclo de Vida do Plesk</link> para obter mais informações.',
                        goToUpdatesButton: 'Vá para atualizações do {product}',
                    },
                    InactiveUnreachableStatusMessage: {
                        message: 'Como não conseguimos acessar o servidor por 14 dias, marcamos ele como inativo.',
                        lastCheckMessage: 'Última vez que verificamos o status da conexão: {lastCheckConnection}',
                        fixDescription: 'Para começar a usá-lo novamente, clique em "Verificar novamente a conexão"',
                        recheckConnectionButton: 'Verifique novamente a conexão',
                    },
                    InactiveUnsupportedStatusMessage: {
                        message: 'O servidor está marcado como inativo porque está em execução {version, select, _ {uma versão Plesk não suportado} other {uma versão Plesk não suportado {version}}}.',
                        fixDescription: 'Para tornar o servidor ativo, atualize o Plesk e execute a sincronização do servidor.',
                        goToUpdatesButton: 'Vá para atualizações do {product}',
                    },
                },
            },
        },
    },
    AddingServer: {
        AddingServerButton: {
            title: 'Adicionar Servidor',
        },
        AddingServerWizard: {
            StartStep: {
                title: 'Adicionar novo servidor',
                closeButton: 'Fechar',
                serverInstallTitle: 'Instalar {productType}',
                installNewProduct: 'Instale um novo produto em um servidor Linux',
                connectInstallation: 'Conecte um servidor com um produto instalado',
                serverInstallDescription: 'Instale {productType} em um servidor físico ou virtual e adicione-o ao inventário do servidor.',
                connectPleskTitle: 'Conecte o Plesk',
                connectPleskDescription: 'Adicione um servidor com o Plesk instalado ao inventário do servidor.',
                connectCPanelTitle: 'Conectar cPanel',
                connectCPanelDescription: 'Adicione um servidor com cPanel instalado ao inventário do servidor.',
            },
            CustomInstallationStep: {
                title: 'Instalar {productType} automaticamente em um servidor Linux',
                backButton: 'Voltar',
                submitButton: 'Instalar',
                successMessage: '{productType} está sendo instalado em <b>{host}</b>.',
            },
            AddServerWithRedirectStep: {
                title: 'Conecte um servidor Plesk',
                description: 'Após clicar em “Conectar”, você será redirecionado para o Plesk. Será necessário fazer login com permissões de administrador.',
                proceed: 'Conectar',
                proceedAnyway: 'Conecte mesmo assim',
                nextStep: 'Próxima etapa',
                back: 'Voltar',
                addressLabel: 'Nome do host do servidor',
                addressDescription: 'A URL do servidor e a porta à qual se conectar. Por exemplo, "https://plesk.com:8443".',
            },
            ConnectCpanelServerStep: {
                title: 'Conectar um servidor cPanel',
                close: 'Fechar',
                back: 'Voltar',
                paragraph1: 'Baixe e execute o instalador do plugin cPanel: copie e cole o seguinte trecho no console do servidor usando privilégios de root.',
                paragraph2: 'Você precisará de um <a>token da API Plesk 360</a>. Você pode gerar um novo ou usar um token existente.',
                paragraph3: 'O servidor recém-criado aparecerá no Inventário de Servidores.',
                copied: 'Copiado.',
            },
        },
    },
};