// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { generateLeikaEmailHashes } from '@platform360/admin-panel/web/api/generate-leika-email-hashes';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const useLeikaEmailHashesMutation = () => {
    const translateValidationErrors = useTranslateValidationErrors();

    const { error, ...rest } = useMutation({
        mutationFn: generateLeikaEmailHashes,
    });

    const errors = translateValidationErrors(getValidationErrors(error));
    if (errors?.emails && typeof errors.emails !== 'string') {
        errors.emails = errors.emails.shift();
    }

    return { ...rest, errors };
};

export default useLeikaEmailHashesMutation;
