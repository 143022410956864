// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { formatDateTime } from '@platform360/libs/common/datetime';

export const useFormatDateTime = () => {
    const { timezone } = useCurrentUser();
    const formatter = useCallback(
        (dateTime: string | Date | DateTime, format?: string) =>
            formatDateTime(dateTime, timezone, {
                format,
            }),
        [timezone],
    );

    return formatter;
};
