// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Informations légales',
        eula: 'CLUF',
        pleskEula: 'CLUF Plesk',
        cPanelEula: 'CLUF cPanel',
        terms: 'Conditions d\'utilisation',
        privacyPolicy: 'Politique de confidentialité',
        dpa: 'Contrat relatif au traitement des données',
        optOuts: 'Opt-Outs',
        cookies: 'Déclaration sur la confidentialité et les cookies',
        gaHeader: 'Désactivation de Google Analytics',
        gaOptOut: 'Cliquez ici pour désactiver Google Analytics',
        cookieHeader: 'Ajustement du consentement aux cookies',
        cookieModify: 'Modifier votre consentement aux cookies',
        cookieWithdraw: 'Retirer votre consentement aux cookies',
        cookieSuccess: 'Votre consentement a bien été retiré',
    },
    RedirectGateway: {
        title: 'Redirection vers {destination}',
        progress: 'Cela peut prendre plusieurs secondes.',
        genericError: 'Un problème s\'est produit',
        serverDetails: {
            destinationTitle: 'la page du serveur dans l\'Inventaire des serveurs',
            errorTitle: 'Une erreur s\'est produite pendant la tentative d\'ouverture de la page du serveur',
            errorDescription: 'Le serveur n\'existe pas dans l\'Inventaire des serveurs',
            redirectDirectly: 'Ajouter à l\'Inventaire des serveurs',
        },
        monitoringServerSetup: {
            destinationTitle: 'la page de confirmation',
            errorTitle: 'Une erreur s\'est produite pendant la tentative d\'ouverture de la page de confirmation de la surveillance du serveur',
            errorDescription: 'Le serveur n\'existe pas dans l\'Inventaire des serveurs',
        },
    },
};