// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ContentLoader } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ReactElement, ReactNode } from 'react';

type AccountRemovalContentProps = {
    isLoading: boolean;
    isRemoving: boolean;
    children: ReactNode;
};

const AccountRemovalContent = ({ isLoading, isRemoving, children }: AccountRemovalContentProps) => {
    const translate = useTranslate('accounts.profile.AccountRemoval');

    if (isLoading) {
        return <ContentLoader text={translate('loading')} />;
    }

    if (isRemoving) {
        return <ContentLoader text={translate('removingAccount')} />;
    }

    return children as ReactElement;
};

export default AccountRemovalContent;
