// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import DisableProtectionDialog from './DisableProtectionDialog';

type Options = {
    onSubmit: () => void;
};

export const useDisableProtectionDialog = ({ onSubmit }: Options) => {
    const [isOpen, setIsOpen] = useState(false);

    const dialog = (
        <DisableProtectionDialog
            isOpen={isOpen}
            onSubmit={onSubmit}
            onClose={() => setIsOpen(false)}
        />
    );

    return [dialog, () => setIsOpen(true)] as const;
};
