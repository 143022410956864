// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import DialogLayout from '@platform360/libs/shared-web/components/DialogLayout';
import { useNavigate } from 'react-router-dom';

const NotFoundActivateLicense = () => {
    const translate = useTranslate('licenses.ActivateLicense.NotFoundActivateLicense');
    const navigate = useNavigate();

    return (
        <DialogLayout
            form={{
                submitButton: {
                    onClick: () => navigate('/'),
                    children: translate('gotIt'),
                    'data-type': 'got-it-button',
                },
            }}
            data-type="not-found-activate-license"
        >
            <h2>{translate('title')}</h2>
            <Paragraph>{translate('description')}</Paragraph>
        </DialogLayout>
    );
};

export default NotFoundActivateLicense;
