// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    // eslint-disable-next-line no-restricted-imports
    Routes as ReactRoutes,
    Route,
    Navigate,
} from 'react-router-dom';
import MonitoringActivation from '@platform360/monitoring/web/components/MonitoringActivation/MonitoringActivationContainer';
import MonitoringPromoActivation from '@platform360/monitoring/web/components/MonitoringPromoActivation/MonitoringPromoActivationContainer';
import MonitoringServerSetup from '@platform360/monitoring/web/components/MonitoringServerSetup/MonitoringServerSetupContainer';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';

const Routes = () => (
    <ReactRoutes>
        <Route element={<PrivateRoutes />}>
            <Route index element={<MonitoringActivation />} />
            <Route path="promo" element={<MonitoringPromoActivation />} />
            <Route path="server/:serverGuid" element={<MonitoringServerSetup />} />
            <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Route>
    </ReactRoutes>
);

export default Routes;
