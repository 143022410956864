// Copyright 2024. WebPros International GmbH. All rights reserved.

import Title from './Title';
import WidgetContent from './WidgetContent';
import { InfoResponse } from '@platform360/monitoring/web/api/monitoring';
import { Item } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type DynamicWidgetProps = {
    isLoading: boolean;
    hasError: boolean;
    hoverable?: boolean;
    // Last refetch timestamp
    updatedAt: number;
    info?: InfoResponse;
};

const DynamicWidget = ({
    isLoading,
    hasError,
    hoverable,
    info,
    updatedAt,
    ...props
}: DynamicWidgetProps) => {
    const translate = useTranslate('monitoring.HomePageWidget.DynamicWidget');

    return (
        <Item view="card" title={<Title text={translate('title')} />} {...props}>
            <WidgetContent
                isLoading={isLoading}
                hasError={hasError}
                updatedAt={updatedAt}
                info={info}
            />
        </Item>
    );
};

export default DynamicWidget;
