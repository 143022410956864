// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTeamMember } from '@platform360/accounts/web/api/team';
import useTeamMembersQuery from '@platform360/accounts/web/queries/useTeamMembersQuery';

type UseDeleteTeamMemberMutationParams = {
    onSuccess: () => void;
};
const useDeleteTeamMemberMutation = ({ onSuccess }: UseDeleteTeamMemberMutationParams) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (userId: number) => {
            await deleteTeamMember(userId);
            return userId;
        },
        onSuccess: async (userId) => {
            await queryClient.cancelQueries({
                queryKey: useTeamMembersQuery.getQueryKey(),
            });
            useTeamMembersQuery.setQueriesData(
                queryClient,
                useTeamMembersQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return [];
                    }

                    return cache.filter(({ id }) => id !== userId);
                },
            );
            onSuccess();
        },
    });
};

export default useDeleteTeamMemberMutation;
