// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server, VIRTUAL_PATCHES_TASKS } from '@platform360/security-dashboard/web/types';

type Options = {
    server: Pick<Server, 'tasks' | 'protected' | 'status'>;
};

export const useProtectionVisibility = ({ server }: Options) => {
    const isTaskProcessing = !!server.tasks.length;
    const isProtectTaskProcessing = server.tasks.some(({ type }) =>
        VIRTUAL_PATCHES_TASKS.includes(type),
    );

    const isInstallingAgent = server.status === 'installingAgent';

    return {
        showProtect: !isTaskProcessing && !server.protected && !isInstallingAgent,
        showProtectedLabel: !isProtectTaskProcessing && server.protected,
        showDisableProtection: !isTaskProcessing && server.protected,
    };
};
