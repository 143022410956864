// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Icon, IconName, Media } from '@plesk/ui-library';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';

export type AvailableBenefits =
    | 'unlimitedRange'
    | 'vulnerabilityDetection'
    | 'secureSites'
    | 'autoUpdates'
    | 'safeUpdates'
    | 'notifications';

const BENEFIT_ICONS: Record<AvailableBenefits, IconName> = {
    unlimitedRange: 'server',
    vulnerabilityDetection: 'search',
    secureSites: 'shield',
    autoUpdates: 'sliders',
    safeUpdates: 'backup2',
    notifications: 'bell',
};

type LicenseBenefitProps = {
    benefit: AvailableBenefits;
};

export const LicenseBenefit = ({ benefit }: LicenseBenefitProps) => {
    const translate = useTranslate('security-dashboard.LicenseBenefit');

    return (
        <Media
            title={translate(`${benefit}.title`)}
            image={<Icon name={BENEFIT_ICONS[benefit]} size="32" intent="info" />}
        >
            {translate(
                `${benefit}.description`,
                benefit === 'secureSites'
                    ? {
                          providerLogo: <Patchstack />,
                          whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                      }
                    : undefined,
            )}
        </Media>
    );
};
