// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Button } from '@plesk/ui-library';
import useCheckConnectionServer from '@platform360/server-inventory/web/mutations/useCheckConnectionServer';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';

export type RecheckConnectionButtonProps = {
    serverId: number;
    title: ReactNode;
};

const RecheckConnectionButton = ({ serverId, title, ...props }: RecheckConnectionButtonProps) => {
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { mutate, isPending: isLoading } = useCheckConnectionServer(serverId);

    const handleClick = () => {
        analyticsEvents.serverViewRecheckConnectionClicked();
        mutate();
    };

    return (
        <Button
            {...props}
            onClick={handleClick}
            state={isLoading ? 'loading' : undefined}
            data-type="recheck-button"
        >
            {title}
        </Button>
    );
};

export default RecheckConnectionButton;
