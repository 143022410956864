// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type TimezoneViewProps = {
    timezone: string;
    onEdit: () => void;
};

export const TimezoneView = ({ timezone, onEdit }: TimezoneViewProps) => {
    const translate = useTranslate('accounts.profile.Timezone');

    return (
        <>
            {timezone.replaceAll('_', ' ')}
            <Button
                ghost
                icon="pencil"
                data-type="edit-timezone-button"
                onClick={() => onEdit()}
                title={translate('buttonEdit')}
            />
        </>
    );
};
