// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Legal',
        eula: 'EULA',
        pleskEula: 'Plesk EULA',
        cPanelEula: 'cPanel EULA',
        terms: 'Terms of use',
        privacyPolicy: 'Privacy Policy',
        dpa: 'Data Processing Agreement',
        optOuts: 'Opt-Outs',
        cookies: 'Cookie Statement',
        gaHeader: 'Google Analytics Opt-out',
        gaOptOut: 'Click here to opt out of Google Analytics',
        cookieHeader: 'Cookie Consent Adjustments',
        cookieModify: 'Modify your cookie consent',
        cookieWithdraw: 'Withdraw your cookie consent',
        cookieSuccess: 'Successful consent withdraw',
    },
    RedirectGateway: {
        title: 'Redirecting you {destination}',
        progress: 'It can take several seconds',
        genericError: 'Something went wrong',
        serverDetails: {
            destinationTitle: 'to the server page in Server Inventory',
            errorTitle: 'An error has occurred when trying to open the server page',
            errorDescription: 'The server does not exist in Server Inventory',
            redirectDirectly: 'Proceed to Server Inventory',
        },
        monitoringServerSetup: {
            destinationTitle: 'to the confirmation page',
            errorTitle: 'An error has occurred when trying to open the server monitoring confirmation page',
            errorDescription: 'The server does not exist in Server Inventory',
        },
    },
};