// Copyright 2024. WebPros International GmbH. All rights reserved.

import { InitialState } from '@platform360/licenses/web/units/adjust/reducer/initialState';
import { NEUTRAL } from '@platform360/licenses/web/units/adjust/constants/productState';

const getProductsToSubmit = (state: InitialState) => {
    const { propertiesOfLicense: properties, newValues } = state;

    return (
        properties?.keyProperties
            .filter(({ productId }) => newValues[Number(productId)]?.state !== NEUTRAL)
            .map((product) => ({
                ...product,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                currentValue: newValues[Number(product.productId)]!.quantity,
            })) || []
    );
};

export default getProductsToSubmit;
