// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { Branding } from '@platform360/libs/shared-web/types';
import { AxiosRequestConfig } from 'axios';
import { Consent } from '@platform360/libs/common/consents';
import { SignUpRequest } from '@platform360/libs/shared-web/application-settings/context';

export type SignUpByInvitationRequest = {
    password: string;
    firstName: string;
    lastName: string;
    consents: Consent[];
};

export type SendVerificationRequest = {
    email: string;
    clientId?: string;
    branding?: Branding;
};

export const signUpByInvitation = (request: SignUpByInvitationRequest) =>
    apiClient.post('/auth/sign-up/invitation', request, {
        skipAuthRefresh: true,
    } as AxiosRequestConfig);

export const signUp = (request: SignUpRequest) => apiClient.post('/auth/sign-up', request);

export const sendVerification = (request: SendVerificationRequest) =>
    apiClient.post('/auth/sign-up/verification-email', request);
