// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { customerTypeValues, CustomerTypes } = makeEnum(
    'CustomerType',
    'partner',
    'retail',
    'vps',
);
export type CustomerType = UnionOf<typeof customerTypeValues>;
