// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export type UseFilterProps<T> = {
    filter: T[];
    onFilterChange: (option: T) => void;
    setFilter: Dispatch<SetStateAction<T[]>>;
    resetFilter: () => void;
};

const useFilter = <T extends string>(key: string): UseFilterProps<T> => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const requestQueryFilter = useMemo(
        () => (searchParams.get(key)?.split(',') || []) as T[],
        [key, searchParams],
    );

    const [filter, setFilter] = useState<T[]>(requestQueryFilter);
    const prevSearchParamFilter = useRef(requestQueryFilter);

    // Update filter state when the URL changes.
    useEffect(() => {
        const isFilterEqual =
            JSON.stringify(prevSearchParamFilter.current) === JSON.stringify(requestQueryFilter);

        if (!isFilterEqual) {
            setFilter(requestQueryFilter);
            prevSearchParamFilter.current = requestQueryFilter;
        }
    }, [requestQueryFilter]);

    useEffect(() => {
        // We must retrieve a fresh location search value to avoid race conditions
        // with multiple filters reset.
        // window.location is only known source to do it right now.
        const updatedSearchParams = new URLSearchParams(window.location.search);

        if (filter.length === 0) {
            updatedSearchParams.delete(key);
        } else {
            updatedSearchParams.set(key, filter.join(','));
        }

        const search = updatedSearchParams.toString();
        if (prevSearchParamFilter.current.length === 0 && filter.length === 0) {
            return;
        }

        navigate(
            {
                search,
            },
            {
                replace: true,
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, key]);

    const onFilterChange = useCallback(
        (filter: T) => {
            setFilter((prevFilter) => {
                const filterSet = new Set(prevFilter);

                if (filterSet.has(filter)) {
                    filterSet.delete(filter);
                } else {
                    filterSet.add(filter);
                }

                return Array.from(filterSet);
            });
        },
        [setFilter],
    );

    const resetFilter = () => {
        setFilter([]);
    };

    return {
        onFilterChange,
        setFilter,
        resetFilter,
        filter,
    };
};

export default useFilter;
