// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import AccountRemoval from './AccountRemoval';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useMutation } from '@tanstack/react-query';
import { deleteProfile } from '@platform360/accounts/web/api/me';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useTeamMembersQuery } from '@platform360/accounts/web/queries';
import { AccountRemovalText } from './AccountRemovalText';

const useAccountRemoval = () => {
    const { processLogout } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const { email, isOwner } = useCurrentUser();
    const { data: team, isLoading } = useTeamMembersQuery({
        enabled: isOpen && isOwner,
    });
    const { mutate: handleDeleteProfile, isPending: isRemoving } = useMutation({
        mutationFn: deleteProfile,
        onSuccess: processLogout,
    });

    const showAccountRemoval = () => {
        setIsOpen(true);
    };
    const handleRemove = () => {
        handleDeleteProfile();
    };
    const accountRemoval = (
        <AccountRemoval
            isOpen={isOpen}
            isLoading={isOwner && isLoading}
            isRemoving={isRemoving}
            onRemove={handleRemove}
            onCancel={() => setIsOpen(false)}
        >
            <AccountRemovalText
                email={email}
                isOwner={isOwner}
                teamMembersCount={team?.length || 0}
            />
        </AccountRemoval>
    );

    return { showAccountRemoval, accountRemoval };
};

export default useAccountRemoval;
