// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import { DateTime } from 'luxon';
import {
    SetupServerRequest,
    ServerMetricsImageRequest,
} from '@platform360/monitoring/shared/api-contract';

export type SetupResponse = {
    userId: string;
};

export type SetupPerServerResponse = {
    redirectUrl: string;
};

type BackendAlert = {
    id: string;
    summary: string;
    opened: number;
};

type BackendServer = {
    monitoringServerId: string;
    serverGuid: string;
    alerts: BackendAlert[];
};

type ServerMonitoringData = {
    isDataAvailable: boolean;
    inSubscription: boolean;
};

type WithMonitoringData<T> = T & ServerMonitoringData;
export type Alert = Omit<BackendAlert, 'opened'> & {
    opened: DateTime;
};

export type Server = Omit<BackendServer, 'alerts'> & {
    alerts: Alert[];
};

type InfoAggregateItem = {
    down: number;
    total: number;
    alerts: number;
};

export type InfoResponse = {
    websites: InfoAggregateItem;
    servers: InfoAggregateItem;
};

const normalizeServer = <T extends { alerts: BackendAlert[] }>(
    server: T,
): Omit<T, 'alerts'> & { alerts: Alert[] } => ({
    ...server,
    alerts: server.alerts.map((alert) => ({
        ...alert,
        opened: DateTime.fromSeconds(alert.opened),
    })),
});

type GetServersOptions = FetcherOptions<{
    serverGuids?: string[];
}>;

type GetServerOptions = FetcherOptions<{
    serverGuid: string;
}>;

type GetServerMetricsImageOptions = FetcherOptions<{
    serverGuid: string;
    params: ServerMetricsImageRequest;
}>;

const monitoring = {
    setup: async (scenario?: string) => {
        const {
            data: { data },
        } = await apiClient.post<ApiResponse<SetupResponse>>('/monitoring/setup', { scenario });
        return data;
    },
    setupServer: async (
        serverGuid: string,
        { monitoringUserId, monitoringServerId }: SetupServerRequest,
    ) => {
        const req = { monitoringUserId, monitoringServerId };
        const {
            data: { data },
        } = await apiClient.post<ApiResponse<SetupPerServerResponse>>(
            `/monitoring/servers/${serverGuid}/setup`,
            req,
        );
        return data;
    },
    info: async ({ signal }: FetcherOptions) => {
        const {
            data: { data },
        } = await apiClient.get<ApiResponse<InfoResponse>>('/monitoring/info', { signal });
        return data;
    },
    servers: async ({ variables, signal }: GetServersOptions) => {
        const {
            data: { data },
        } = await apiClient.get<ApiResponse<BackendServer[]>>(
            `/monitoring/servers?filter[serverGuids]=${(variables.serverGuids || []).join(',')}`,
            { signal },
        );

        return data.map(normalizeServer);
    },
    server: async ({ variables, signal }: GetServerOptions) => {
        const {
            data: { data },
        } = await apiClient.get<ApiResponse<WithMonitoringData<BackendServer>>>(
            `/monitoring/servers/${variables.serverGuid}`,
            { signal },
        );

        return normalizeServer(data);
    },
    serverMetricsImage: async ({ variables, signal }: GetServerMetricsImageOptions) => {
        const {
            data: { data },
        } = await apiClient.get<ApiResponse<string>>(
            `/monitoring/servers/${variables.serverGuid}/metrics-image`,
            {
                params: variables.params,
                signal,
            },
        );

        return data;
    },
};

export default monitoring;
