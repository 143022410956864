// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, Icon, Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import deleteAccount from './delete-account.png';
import AccountRemovalContent from './AccountRemovalContent';
import { ReactNode } from 'react';

export type AccountRemovalProps = {
    isOpen: boolean;
    isLoading?: boolean;
    isRemoving: boolean;
    onRemove: () => void;
    onCancel: () => void;
    children: ReactNode;
};

const AccountRemoval = ({
    isOpen,
    isLoading = false,
    isRemoving,
    onRemove,
    onCancel,
    children,
    ...props
}: AccountRemovalProps) => {
    const translate = useTranslate('accounts.profile.AccountRemoval');

    return (
        <Dialog
            isOpen={isOpen}
            image={<Icon src={deleteAccount} size="128" />}
            size="sm"
            buttons={
                <Button
                    onClick={() => onRemove()}
                    intent="danger"
                    data-type="remove-account-button"
                    state={isRemoving ? 'loading' : 'active'}
                    disabled={isLoading}
                >
                    {translate('removeAccountButton')}
                </Button>
            }
            onClose={onCancel}
            data-type="account-removal-dialog"
            {...props}
        >
            <AccountRemovalContent isLoading={isLoading} isRemoving={isRemoving}>
                {children}
            </AccountRemovalContent>
        </Dialog>
    );
};

export default AccountRemoval;
