// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './style.module.css';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import PropTypes from 'prop-types';
import { own } from '@platform360/licenses/web/helpers/utils';

const FeatureList = (props) => {
    const translate = useTranslate();
    const features =
        props.keyInfo.features.length > 0 ? (
            <div className={styles.secondRowInfoBlock}>
                {translate('features')}
                <ul>
                    {props.keyInfo.features.map((feature, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            </div>
        ) : (
            <div className={styles.secondRowInfoBlock}>{translate('noFeatures')}</div>
        );

    return <div>{props.keyInfo.isPlesk && own(props.keyInfo) ? features : ''}</div>;
};

FeatureList.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

export default FeatureList;
