// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ContentLoader, Toolbar, StatusMessage, Text, Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import LoadingError from './LoadingError';
import { useLocale } from '@platform360/libs/shared-web/locale';
import { Alert } from '@platform360/monitoring/web/api/monitoring';
import styles from './ServerViewMonitoring.module.css';
import PreparingServer from './PreparingServer';

export type ServerViewMonitoringProps = {
    monitoringServerLink: string;
    alerts: Alert[];
    metricsImageUrl?: string;
    isMetricsImageError: boolean;
    isDataAvailable: boolean;
    isMonitoringServerRefetching: boolean;
    onMonitoringServerRefresh: () => void;
};

const ServerViewMonitoring = ({
    monitoringServerLink,
    alerts,
    metricsImageUrl,
    isMetricsImageError,
    isDataAvailable,
    isMonitoringServerRefetching,
    onMonitoringServerRefresh,
}: ServerViewMonitoringProps) => {
    const translate = useTranslate('monitoring.ServerViewMonitoring');
    const [locale] = useLocale();
    const metrics = metricsImageUrl ? (
        <img
            src={metricsImageUrl}
            width="100%"
            alt={translate('loadAverageTitle') as string}
            data-type="load-average-image"
        />
    ) : (
        <ContentLoader />
    );
    const alertsFragment = alerts.length ? (
        <>
            {alerts.slice(0, 3).map(({ id, summary, opened }) => (
                <StatusMessage
                    intent="danger"
                    key={id}
                    data-type="monitoring-alert"
                    className={styles.alert}
                >
                    <Text bold>{summary}</Text>
                    <div>
                        {translate('alertOpened', {
                            timeAgo: opened.toRelative({ locale }),
                        })}
                    </div>
                </StatusMessage>
            ))}
            <Link
                rel="noopener noreferrer"
                href={`${monitoringServerLink}/notifications`}
                data-type="view-alerts-in-monitoring-link"
            >
                {translate('viewAlertsInMonitoring')}
            </Link>
        </>
    ) : undefined;

    if (!isDataAvailable) {
        return (
            <>
                {alertsFragment}
                <PreparingServer
                    monitoringServerLink={monitoringServerLink}
                    isRefreshing={isMonitoringServerRefetching}
                    onRefresh={onMonitoringServerRefresh}
                />
            </>
        );
    }

    return (
        <>
            <Toolbar>
                <Button
                    component="a"
                    rel="noopener noreferrer"
                    href={monitoringServerLink}
                    data-type="view-in-monitoring-link"
                >
                    {translate('viewInMonitoring')}
                </Button>
            </Toolbar>
            {alertsFragment}
            {isMetricsImageError ? <LoadingError /> : metrics}
        </>
    );
};

export default ServerViewMonitoring;
