// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { getAllDomains } from '@platform360/server-inventory/web/api/domains';
import { FilterQuery, PaginatedQuery } from '@platform360/libs/shared-web/typings/api';

export type UseAllDomainsQueryVariables = PaginatedQuery & FilterQuery;

export type UseAllDomainsQueryData = PromiseValue<ReturnType<typeof getAllDomains>>;

const useAllDomainsQuery = createQuery<UseAllDomainsQueryVariables, UseAllDomainsQueryData>({
    queryName: 'server-inventory/useAllDomainsQuery',
    fetcher: getAllDomains,
    useQuery,
});

export default useAllDomainsQuery;
