// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading } from '@plesk/ui-library';

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { FilteredByVulnerabilityTitle } from './FilteredByVulnerabilityTitle';
import { ResetDataLink } from './ResetDataLink';

type FilteredDataListTitleProps = {
    searchField: 'query' | 'vulnerability' | 'server';
    searchValue: string;
    onReset: () => void;
};

export const FilteredDataListTitle = ({
    searchField,
    searchValue,
    onReset,
}: FilteredDataListTitleProps) => {
    const translate = useTranslate('security-dashboard.FilteredDataListTitle');

    if (searchField === 'vulnerability') {
        return <FilteredByVulnerabilityTitle searchValue={searchValue} onReset={onReset} />;
    }

    const translationKey = searchField === 'server' ? 'searchByServer' : 'searchByQuery';

    return (
        <Heading level={3}>
            {translate(translationKey, { searchValue })} <ResetDataLink onReset={onReset} />
        </Heading>
    );
};
