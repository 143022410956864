// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AxiosRequestConfig } from 'axios';
import update from './update';
import { Extension } from '@platform360/licenses/web/api/ka/extensions/types/create';

const previewCreate = (extension: Extension): AxiosRequestConfig => {
    const request = update(extension);
    request.url += '/preview';
    return request;
};

export default previewCreate;
