// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiListResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import { Notification } from '@platform360/security-dashboard/web/types';
import {
    NotificationCategory,
    NotificationCode,
    NotificationsParams,
} from '@platform360/security-dashboard/shared/notifications';
import { DateTime } from 'luxon';

type NotificationResponse = {
    id: number;
    createdAt: string;
    isRead: boolean;
    category: NotificationCategory;
    code: NotificationCode;
    params: NotificationsParams[keyof NotificationsParams];
};

type GetNotificationsOptions = FetcherOptions;
export const getNotifications = async ({
    signal,
}: GetNotificationsOptions): Promise<Notification[]> => {
    const { data } = await apiClient.get<ApiListResponse<NotificationResponse>>(
        '/security-dashboard/notifications',
        {
            signal,
        },
    );

    return data.data.map((notification) => ({
        ...notification,
        createdAt: DateTime.fromISO(notification.createdAt),
    }));
};

export const readNotification = async (notifications: number[]) => {
    await apiClient.post<never>('/security-dashboard/notifications/read', { notifications });
};

export const unreadNotification = async (id: number) => {
    await apiClient.post<never>(`/security-dashboard/notifications/${id}/unread`);
};

export const deleteNotification = async (id: number) => {
    await apiClient.delete<never>(`/security-dashboard/notifications/${id}`);
};
