// Copyright 2024. WebPros International GmbH. All rights reserved.

export const INITIALIZATION = '@licensing/licenses/upsell/buyExtension/initialization';
export const INITIALIZED = '@licensing/licenses/upsell/buyExtension/initialized';
export const FINALIZATION = '@licensing/licenses/upsell/buyExtension/finalization';

export const CHANGE_PERIOD = '@licensing/licenses/upsell/buyExtension/period/change';
export const CHANGE_CURRENCY = '@licensing/licenses/upsell/buyExtension/currency/change';

export const SELECT_PRODUCT = '@licensing/licenses/upsell/buyExtension/product/select';
export const SET_VALUE_FOR_PRODUCT = '@licensing/licenses/upsell/buyExtension/product/setValue';

export const SELECT_SUBSCRIPTION = '@licensing/licenses/upsell/buyExtension/subscription/select';

export const START_PROCESSING = '@licensing/licenses/upsell/buyExtension/processing/start';
export const FINISH_PROCESSING = '@licensing/licenses/upsell/buyExtension/processing/finish';

export const SET_PRODUCTS = '@licensing/licenses/upsell/buyExtension/products/set';
export const SET_SUBSCRIPTIONS = '@licensing/licenses/upsell/buyExtension/subscriptions/set';

export const TOGGLE_FETCHING = '@licensing/licenses/upsell/buyExtension/isFetching/toggle';

export const GO_TO_ONLINE_STORE = '@licensing/licenses/upsell/buyExtension/goToOnlineStore';
export const SET_ONLINE_STORE_DATA = '@licensing/licenses/upsell/buyExtension/onlineStoreData/set';
export const FLUSH_ONLINE_STORE_DATA =
    '@licensing/licenses/upsell/buyExtension/onlineStoreData/flush';
