// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMemo } from 'react';
import { Paragraph, Text, Toolbar, ToolbarGroup } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import LegalInfoLink from '../LegalInfoLink';
import scopes from '../../constants/scope';
import CompanyName from '../CompanyName';
import CompanyAddress from '../CompanyAddress';
import { MerchantOfRecordType } from '@platform360/licenses/web/api/ka/responseTypes/CalculatedSubscriptionState';

export type FooterProps = {
    merchantOfRecordType: MerchantOfRecordType;
};

const Footer = ({ merchantOfRecordType }: FooterProps) => {
    const translate = useTranslate('Keys.CB');
    const year = useMemo<number>(() => new Date().getFullYear(), []);

    return (
        <>
            <Toolbar>
                {Object.entries(scopes).map(([link, scope]) => (
                    <ToolbarGroup
                        key={`legalInfoLinks${link}`}
                        groupable={false}
                        title={'outsideLinks'}
                    >
                        <LegalInfoLink scope={scope}>
                            {translate(`links.${link.toLowerCase()}`)}
                        </LegalInfoLink>
                    </ToolbarGroup>
                ))}
            </Toolbar>

            <Paragraph>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <Text intent="muted">
                    &copy; <CompanyName merchantOfRecordType={merchantOfRecordType} /> {year}
                </Text>
            </Paragraph>
            <Paragraph>
                <Text intent="muted">
                    {translate('footer', {
                        companyName: (
                            <CompanyName merchantOfRecordType={merchantOfRecordType} key={'cn'} />
                        ),
                        companyAddress: (
                            <CompanyAddress merchantOfRecordType={merchantOfRecordType} />
                        ),
                    })}
                </Text>
            </Paragraph>
        </>
    );
};

export default Footer;
