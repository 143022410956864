// Copyright 2024. WebPros International GmbH. All rights reserved.

import axios, { AxiosError } from 'axios';
import { load } from '@platform360/libs/shared-web/helpers/localStorage';
import { SESSION_KEY, dispatchAction } from '@platform360/libs/shared-web/auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { Session, toSession } from '@platform360/libs/shared-web/auth/auth-state';
import { createAuth0ClientFromAuthConfig } from '@platform360/libs/shared-web/auth/components/AuthProvider';
import { AuthClient } from '@platform360/libs/shared-web/auth/AuthClient';

// Specific axios code to detect API token renewal failure.
export const TOKEN_RENEWAL_FAILURE_CODE = 'token-renewal-failure';

const client = axios.create({
    baseURL: '/api',
});

// Take Authorization header from the user session and propagate it to the axios client.
client.interceptors.request.use((config) => {
    // The authorization token is already exist.
    if (config.headers.Authorization) {
        return config;
    }

    const data: { session?: Session } | undefined = load(SESSION_KEY);

    if (data?.session?.accessToken) {
        config.headers.Authorization = `Bearer ${data.session.accessToken}`;
    }

    return config;
});

// Function that will be called to refresh authorization.
const refreshAuthToken = async (failedRequest: AxiosError<{ type?: string }>) => {
    const { config } = window.__INITIAL_STATE__;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!config.auth) {
        throw new Error('Auth config is missed from the initial state');
    }

    const authClient: AuthClient = createAuth0ClientFromAuthConfig(config.auth);

    // If the failed HTTP request's response contains an error type 'invalid-token',
    // it indicates that token renewal is not possible or the token itself is invalid.
    if (failedRequest.response?.data.type === 'invalid-token') {
        dispatchAction({ type: 'LOGOUT' });

        throw new AxiosError(failedRequest.message, TOKEN_RENEWAL_FAILURE_CODE);
    }

    try {
        const authToken = await authClient.getTokenSilently();
        const claims = await authClient.getIdTokenClaims();

        if (failedRequest.response?.config.headers) {
            failedRequest.response.config.headers.Authorization = `Bearer ${authToken}`;
        }

        if (!claims) {
            dispatchAction({ type: 'LOGOUT' });
            return;
        }

        dispatchAction({
            type: 'UPDATE_SESSION',
            session: toSession(authToken, claims),
        });
    } catch (e) {
        dispatchAction({ type: 'LOGOUT' });

        throw new AxiosError(e.message, TOKEN_RENEWAL_FAILURE_CODE);
    }
};

createAuthRefreshInterceptor(client, refreshAuthToken, {
    pauseInstanceWhileRefreshing: true,
});

export default client;
