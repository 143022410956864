// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentProps, useEffect } from 'react';
import { Drawer } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import LabelsList from './LabelsList';
import CreateLabelDrawer from './CreateLabelDrawer';
import EditLabelDrawer from './EditLabelDrawer';

const RoutableCreateLabelDrawer = withRouteBinding(CreateLabelDrawer);
const RoutableEditLabelDrawer = withRouteBinding(EditLabelDrawer);

export type LabelsDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    baseClosePath: string;
};

const LabelsDrawer = ({ isOpen, onClose, baseClosePath }: LabelsDrawerProps) => {
    const translate = useTranslate('security-dashboard.LabelsDrawer');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const {
        data: responseData,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useLabelsQuery({
        placeholderData: (prev) => prev,
    });

    const data = responseData?.data || [];

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpLabelsDrawerShown();
        } else {
            analyticsEvents.wpLabelsDrawerClosed();
        }
    }, [analyticsEvents, isOpen]);

    const closePath = `${baseClosePath}/labels`;

    return (
        <>
            <Drawer
                data-type="wp-labels-drawer"
                title={translate('title')}
                size="xs"
                isOpen={isOpen}
                onClose={onClose}
            >
                <LabelsList data={data} isLoading={isLoading} isPreviousData={isPreviousData} />
            </Drawer>
            <RoutableCreateLabelDrawer openPath="create" closePath={closePath} />
            <RoutableEditLabelDrawer
                openPath=":labelId/edit"
                closePath={closePath}
                isLoading={isLoading}
                labels={data}
            />
        </>
    );
};

const LabelsDrawerWithRouteBinding = withRouteBinding(LabelsDrawer);

type RoutableLabelsDrawerProps = ComponentProps<typeof LabelsDrawerWithRouteBinding>;

export const RoutableLabelsDrawer = (
    props: Omit<RoutableLabelsDrawerProps, 'openPath' | 'baseClosePath'>,
) => (
    <LabelsDrawerWithRouteBinding openPath="labels/*" baseClosePath={props.closePath} {...props} />
);

export default LabelsDrawer;
