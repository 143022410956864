// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, Heading, Icon, Label, Select, SelectOption, Text } from '@plesk/ui-library';
import style from './LicenseInfo.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { License } from '@platform360/security-dashboard/web/types';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { DateTime } from 'luxon';
import { useLicenseTranslate } from '@platform360/security-dashboard/web/hooks/useLicenseTranslate';

type LicenseInfoProps = {
    license?: License | null;
    licenseType: 'unlimited' | 'standard';
    intent?: 'primary';
    showLabel?: boolean;
    isRecommended?: boolean;
    totalInstallationsCount?: number;
    onClick: (sitesNumber: number) => void;
};

const standardProducts = [
    { product: 'limit1', sitesNumber: 1, priceFull: '2.5' },
    { product: 'limit5', sitesNumber: 5, priceFull: '7.5' },
    { product: 'limit10', sitesNumber: 10, priceFull: '12.5' },
    { product: 'limit30', sitesNumber: 30, priceFull: '32.5' },
    { product: 'limit50', sitesNumber: 50, priceFull: '50' },
    { product: 'limit500', sitesNumber: 500, priceFull: '65' },
    { product: 'limit1000', sitesNumber: 1000, priceFull: '105' },
];

const priceFullUnlimited = '825';
const pricePerSiteUnlimited = '0.08';

const LicenseInfo = ({
    license,
    licenseType,
    intent,
    showLabel,
    isRecommended,
    totalInstallationsCount,
    onClick,
}: LicenseInfoProps) => {
    const translate = useTranslate('security-dashboard');
    const translateLicense = useLicenseTranslate();
    const formatDateTime = useFormatDateTime();
    const [selectedProduct, setSelectedProduct] = useState<string>('limit1');
    const limitExceeded = (totalInstallationsCount || 0) > (license?.sitesLimitHard || 0);
    const showGetMoreSites =
        isRecommended && !limitExceeded && !license?.trial && license?.status !== 'terminated';
    const showRecommendedToYou =
        isRecommended && (limitExceeded || license?.trial || license?.status === 'terminated');

    useEffectOnce(() => {
        let recommendedProduct = null;
        const getTrialRecommendedProduct = () =>
            standardProducts.find(
                ({ sitesNumber }) => sitesNumber >= (totalInstallationsCount || 0),
            );

        if (isRecommended) {
            if (!license?.trial && license?.status !== 'terminated' && license?.product) {
                recommendedProduct = standardProducts.find(
                    ({ sitesNumber }) => sitesNumber > (license.sitesLimitHard || 0),
                );
            }

            if (license?.trial || license?.status === 'terminated') {
                recommendedProduct = getTrialRecommendedProduct();
            }
        } else {
            if (!license?.trial && license?.status !== 'terminated' && license?.product) {
                recommendedProduct = standardProducts.find(
                    ({ product }) => product === license.product,
                );
            }

            if (license?.trial || license?.status === 'terminated') {
                recommendedProduct = getTrialRecommendedProduct();
            }
        }

        if (recommendedProduct) {
            setSelectedProduct(recommendedProduct.product);
        }
    });

    const isCurrentLicense = (license: License) =>
        license.status !== 'terminated' &&
        licenseType === 'standard' &&
        license.product === selectedProduct;

    const standardLabel = () => {
        if (!license || !isCurrentLicense(license)) {
            return (
                <Label intent="info" view="light" size="lg">
                    {translate('LicensePage.LicenseInfo.standard.label')}
                </Label>
            );
        }

        if (license.expiredAt < DateTime.now()) {
            return (
                <Label intent="danger" view="light" size="lg">
                    {translate('LicensePage.LicenseDescription.expirationDate', {
                        date: formatDateTime(license.expiredAt, 'D'),
                    })}
                </Label>
            );
        }

        return (
            <Label intent="success" view="light" size="lg">
                {translate('LicensePage.LicenseDescription.nextUpdate', {
                    date: formatDateTime(license.nextUpdateAt, 'D'),
                })}
            </Label>
        );
    };

    const buttonText =
        license === null ||
        license === undefined ||
        license.trial ||
        license.status === 'terminated'
            ? translate('LicensePage.LicenseInfo.useThisPlan')
            : translate('LicensePage.LicenseInfo.changePlan');

    const getPriceText = () => {
        let price = priceFullUnlimited;
        if (licenseType === 'standard') {
            const selectedPrice = standardProducts.find(
                (price) => price.product === selectedProduct,
            )?.priceFull;
            if (selectedPrice === undefined) {
                return null;
            }
            price = selectedPrice;
        }
        return `$${price}`;
    };

    let currentLicenseSitesNumber = 0;
    if (!license?.trial && license?.status !== 'terminated' && license?.sitesLimitHard) {
        currentLicenseSitesNumber = license.sitesLimitHard;
    }

    const handleSelectedProductChange = (value: unknown) => {
        setSelectedProduct(String(value));
    };

    const handleButtonClick = () => {
        const sitesNumber = standardProducts.find(
            (standardProduct) => standardProduct.product === selectedProduct,
        )?.sitesNumber;
        if (sitesNumber) {
            onClick(sitesNumber);
        }
    };

    const dataType = isRecommended
        ? `recommended-container-info-${licenseType}`
        : `container-info-${licenseType}`;

    return (
        <div
            className={classNames(
                style.container,
                intent === 'primary' ? style.primary : undefined,
            )}
            data-type={dataType}
        >
            {licenseType === 'unlimited' ? (
                <div className={style.top}>
                    {showGetMoreSites && (
                        <Text>{translate('LicensePage.LicenseInfo.gotMoreSites')}</Text>
                    )}
                    <Heading level={2}>
                        {translate('LicensePage.LicenseInfo.unlimited.title')}
                    </Heading>
                    {showLabel && (
                        <Label intent="info" view="light" size="lg">
                            {translate('LicensePage.LicenseInfo.unlimited.label')}
                        </Label>
                    )}
                    {showRecommendedToYou && (
                        <Label intent="success" view="light" size="lg">
                            {translate('LicensePage.LicenseInfo.recommendedToYou')}
                        </Label>
                    )}
                    <div className={style.feature}>
                        <Icon name="arrows-outward" />
                        <Text>{translate('LicensePage.LicenseInfo.unlimited.feature1')}</Text>
                    </div>
                    <div className={style.feature}>
                        <Icon name="bar-chart-vertical-arrow-up" />
                        <Text>{translate('LicensePage.LicenseInfo.unlimited.feature2')}</Text>
                    </div>
                    <div className={style.feature}>
                        <Icon name="tools" />
                        <Text>{translate('LicensePage.LicenseInfo.unlimited.feature3')}</Text>
                    </div>
                </div>
            ) : (
                <div className={style.top}>
                    {showGetMoreSites && (
                        <Text>{translate('LicensePage.LicenseInfo.gotMoreSites')}</Text>
                    )}
                    {isRecommended && (
                        <Heading level={2}>{translateLicense(selectedProduct)}</Heading>
                    )}
                    {!isRecommended && (
                        <Heading level={2}>
                            {translate('LicensePage.LicenseInfo.standard.title')}
                        </Heading>
                    )}
                    {showLabel && standardLabel()}
                    {showRecommendedToYou && (
                        <Label intent="success" view="light" size="lg">
                            {translate('LicensePage.LicenseInfo.recommendedToYou')}
                        </Label>
                    )}
                    <div className={style.feature}>
                        <Icon name="calendar-clock" />
                        <Text>{translate('LicensePage.LicenseInfo.standard.feature1')}</Text>
                    </div>
                    <div className={style.feature}>
                        <Icon name="tools" />
                        <Text>{translate('LicensePage.LicenseInfo.standard.feature2')}</Text>
                    </div>
                </div>
            )}

            {!isRecommended && licenseType === 'standard' && (
                <Select
                    onChange={handleSelectedProductChange}
                    value={selectedProduct}
                    defaultValue={selectedProduct}
                    data-type={'price-selector'}
                >
                    {standardProducts.map((standardProduct, standardProductIndex) => (
                        <SelectOption
                            key={standardProduct.product}
                            value={standardProduct.product}
                            disabled={standardProduct.sitesNumber < currentLicenseSitesNumber}
                            data-type={`price-option-${standardProductIndex}`}
                        >
                            {translateLicense(standardProduct.product)}
                        </SelectOption>
                    ))}
                </Select>
            )}

            <div className={style.bottom}>
                <div>
                    <Text data-type={'price-displayed'} className={style.price}>
                        {getPriceText()}
                    </Text>
                    {licenseType === 'unlimited' && (
                        <Text fontSize="sm">
                            {translate('LicensePage.LicenseInfo.unlimited.pricePerSite', {
                                price: pricePerSiteUnlimited,
                            })}
                        </Text>
                    )}
                </div>

                {license && isCurrentLicense(license) ? (
                    <Text intent="muted" fontSize="sm">
                        {translate('LicensePage.LicenseInfo.chooseAnotherOption')}
                    </Text>
                ) : (
                    <Button intent={intent} size="lg" onClick={handleButtonClick}>
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default LicenseInfo;
