// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Button, StatusMessage } from '@plesk/ui-library';
import { Link } from 'react-router-dom';

export type ErrorMessageProps = {
    type: string;
};

export const ErrorMessage = ({ type }: ErrorMessageProps) => {
    const translate = useTranslate('accounts.UpdateEmail.ErrorMessage');
    const errorTranslations: Record<string, string> = {
        forbidden: translate('errors.forbidden'),
        changingEmailConflict: translate('errors.changingEmailConflict'),
        unexpected: translate('errors.unexpected'),
    };

    return (
        <>
            <StatusMessage intent="danger">
                {errorTranslations[type] || errorTranslations.unexpected}
            </StatusMessage>
            <Button intent="primary" component={Link} to="/" data-type="try-again-button">
                {translate('tryAgainButton')}
            </Button>
        </>
    );
};
