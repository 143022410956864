// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { FormFieldCheckbox, FormField, Select } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import FiltersForm, {
    FiltersFormProps,
} from '@platform360/security-dashboard/web/components/FiltersForm';
import {
    VulnerabilitiesComponentFilter,
    VulnerabilitiesStateFilter,
} from '@platform360/security-dashboard/web/types';
import styles from './Filters.module.css';

type FormValues = Partial<Record<VulnerabilitiesStateFilter, boolean>> & {
    component: VulnerabilitiesComponentFilter | null;
};

type FiltersProps = {
    stateFilter: VulnerabilitiesStateFilter[];
    componentFilter: VulnerabilitiesComponentFilter[];
    onFilterApply: (
        filters: [VulnerabilitiesStateFilter[], VulnerabilitiesComponentFilter[]],
    ) => void;
} & FiltersFormProps<FormValues>;

export const Filters = ({
    stateFilter,
    componentFilter,
    onFilterApply,
    ...filtersFormProps
}: FiltersProps) => {
    const translate = useTranslate('security-dashboard.Vulnerabilities.Filters');

    const stateFilters = [
        {
            name: VulnerabilitiesStateFilter.Exploited,
            title: translate('filterStateExploited'),
        },
        {
            name: VulnerabilitiesStateFilter.CanBeProtected,
            title: translate('filterStateCanBeProtected'),
        },
        {
            name: VulnerabilitiesStateFilter.Ignored,
            title: translate('filterStateIgnored'),
        },
    ];

    const componentFilters = [
        {
            name: VulnerabilitiesComponentFilter.Core,
            title: translate('filterComponentCore'),
        },
        {
            name: VulnerabilitiesComponentFilter.Plugin,
            title: translate('filterComponentPlugin'),
        },
        {
            name: VulnerabilitiesComponentFilter.Theme,
            title: translate('filterComponentTheme'),
        },
    ];

    const handleSubmit = (values: FormValues) => {
        const stateFilter: VulnerabilitiesStateFilter[] = [];
        stateFilters.forEach(({ name }) => {
            if (values[name]) stateFilter.push(name);
        });

        const componentFilter = values.component ? [values.component] : [];

        onFilterApply([stateFilter, componentFilter]);
    };

    const [values, setValues] = useState<FormValues>(() => {
        const values: FormValues = {
            component: componentFilter[0] ?? null,
        };
        stateFilters.forEach(({ name }) => (values[name] = stateFilter.includes(name)));
        return values;
    });

    return (
        <FiltersForm<FormValues>
            values={values}
            onSubmit={handleSubmit}
            onFieldChange={(name: keyof FormValues, value: FormValues[typeof name]) =>
                setValues({ ...values, [name]: value })
            }
            {...filtersFormProps}
        >
            {stateFilters.map(({ name, title }) => (
                <FormFieldCheckbox key={name} name={name} label={title} />
            ))}
            <FormField name="component">
                {({
                    getValue,
                    setValue,
                }: {
                    getValue: () => string;
                    setValue: (value: string | null) => void;
                }) => (
                    <Select
                        clearable
                        value={getValue()}
                        onChange={(value) => setValue((value as string | undefined) || null)}
                        size="fill"
                        className={styles.select}
                        placeholder={translate('everywhere')}
                    >
                        {componentFilters.map(({ name, title }) => (
                            <option key={name} value={name}>
                                {title}
                            </option>
                        ))}
                    </Select>
                )}
            </FormField>
        </FiltersForm>
    );
};

export default Filters;
