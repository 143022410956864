// Copyright 2024. WebPros International GmbH. All rights reserved.

import { captureMessage } from '@sentry/browser';

type Event = {
    name: string;
    category: string;
    label: string;
};

const sendEvent = ({ category, name, label }: Event) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!window.gtag || typeof window.gtag !== 'function') {
        captureMessage(
            'Possibly google analytics is broken, gtag function is not attached to the window object.',
        );
        return;
    }

    gtag('event', name, {
        event_category: category,
        event_label: label,
    });
};

export const startMonitoringTrial = () =>
    sendEvent({
        name: 'click',
        category: 'button',
        label: '360Monitoring-Free',
    });
