// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';
import { useLinkedEmailsQuery } from '@platform360/licenses/web/queries/useLinkedEmailsQuery';

const deleteLinkedEmail = async (email: string) => {
    const { data } = await apiClient.delete<ApiResponse<string[]>>(`/linked-emails/${email}`);
    return data.data;
};

export const useDeleteLinkedEmailMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteLinkedEmail,
        onSuccess: async (emails) => {
            await queryClient.cancelQueries({
                queryKey: useLinkedEmailsQuery.getQueryKey(),
            });
            useLinkedEmailsQuery.setQueriesData(
                queryClient,
                useLinkedEmailsQuery.getQueryKey(),
                emails,
            );
        },
    });
};
