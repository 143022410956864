// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Layout as UILibLayout } from '@plesk/ui-library';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Logo from './Logo';
import Menu from './Menu';
import MfaRequired from './MfaRequired';
import styles from './Layout.module.css';
import Widgets from './Widgets';
import useHasPermission from '@platform360/admin-panel/web/hooks/useHasPermission';
import useFeatureFlagEnabled from '@platform360/libs/shared-web/helpers/useFeatureFlagEnabled';
import { useAuth } from '@platform360/libs/shared-web/auth';

export const Layout = () => {
    const hasPermissions = useHasPermission('all:users:impersonate');
    const checkMfaFeatureEnabled = useFeatureFlagEnabled('checkAdminMfa');
    const { session } = useAuth();

    if (checkMfaFeatureEnabled && !session?.isMfaEnabled) {
        return <MfaRequired />;
    }

    return (
        <UILibLayout
            header={<Header />}
            // There is only one widget now, and it should be only displayed
            // for the users with all:users:impersonate permission.
            contentAddon={hasPermissions && <Widgets />}
            sidebar={
                <div>
                    <Logo />
                    <Menu />
                </div>
            }
            footer={`Platform 360 Admin Panel ❤️`}
        >
            <div className={styles.root}>
                <Outlet />
            </div>
        </UILibLayout>
    );
};
