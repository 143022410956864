// Copyright 2024. WebPros International GmbH. All rights reserved.

import { InstallationLog } from './InstallationLog';
import { useParams } from 'react-router-dom';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';
import { ServerViewModel } from '@platform360/server-inventory/web/viewModels/ServerViewModel';

const InstallationLogContainer = () => {
    const { serverId } = useParams<{ serverId: string }>();
    const { data } = useServerQuery({
        // Parent ViewServer component checks serverId is valid
        variables: { id: Number(serverId) },
    });

    if (!data?.installationId) {
        return null;
    }

    return (
        <InstallationLog
            installationId={data.installationId}
            isRefetch={ServerViewModel.isInstallationRunning(data)}
        />
    );
};

export default InstallationLogContainer;
