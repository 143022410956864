// Copyright 2024. WebPros International GmbH. All rights reserved.

export const getCookie = (name: string): string | undefined => {
    const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));

    if (cookie) {
        const [, cookieValue] = cookie.split('=');
        return cookieValue;
    }

    return undefined;
};
