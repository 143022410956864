// Copyright 2024. WebPros International GmbH. All rights reserved.

export type ValidThru = {
    // it's 1-based input
    month: number;
    year: number;
};

const isCardExpired = (validThru: ValidThru): boolean => {
    // Credit cards expire at the end of the month written on the card
    // @notice We deliberately use a constructor that creates a date in the local time zone.
    // Because the code runs on the client in the browser with the local time zone
    const expirationDate = new Date(validThru.year, validThru.month);
    const currentDate = new Date();

    return currentDate.valueOf() >= expirationDate.valueOf();
};

export default isCardExpired;
