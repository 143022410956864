// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    ContentLoader,
    Drawer,
    DrawerProps,
    StatusMessage,
    Skeleton,
    Tab,
    Tabs,
    TranslationProps,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    Navigate,
    Route,
    // eslint-disable-next-line no-restricted-imports
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import TabVulnerabilities from './TabVulnerabilities';
import TabOutdated from './TabOutdated';
import { useInstallationInfoQuery } from '@platform360/security-dashboard/web/queries';
import { UPDATE_TASKS } from '@platform360/security-dashboard/web/types';
import ProtectControlCompact from '@platform360/security-dashboard/web/components/Installations/ProtectControlCompact';
import { UseFilterProps } from '@platform360/libs/shared-web/helpers/useFilter';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { ReactElement } from 'react';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

export type WebSiteDrawerProps = {
    isOpen: boolean;
    onClose: () => Promise<void>;
    vulnerabilitiesComponentFilter: UseFilterProps<InstallationComponent>;
    outdatedFilter: UseFilterProps<InstallationComponent>;
};

type TabType = {
    to: 'vulnerabilities' | 'outdated';
    title: TranslationProps;
    children: ReactElement | undefined;
    type: 'vulnerabilities' | 'outdated';
};

const WebsiteDrawer = ({
    isOpen,
    onClose,
    vulnerabilitiesComponentFilter,
    outdatedFilter,
}: WebSiteDrawerProps) => {
    const { installationId: instId } = useParams<{ installationId: string }>();
    const installationId = Number(instId);
    const translate = useTranslate('security-dashboard.Installations.WebsiteDrawer');
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const {
        data: installation,
        isLoading,
        isError,
    } = useInstallationInfoQuery({
        variables: {
            installationId,
        },
    });

    const tabs: TabType[] = [
        {
            to: 'vulnerabilities',
            title: translate('tabs.vulnerabilities.title'),
            children: installation && (
                <TabVulnerabilities
                    installation={installation}
                    installationId={installationId}
                    componentFilterProp={vulnerabilitiesComponentFilter}
                />
            ),
            type: 'vulnerabilities',
        },
        {
            to: 'outdated',
            title: translate('tabs.outdated.title'),
            children: installation && (
                <TabOutdated
                    displayTitle={installation.displayTitle}
                    installationId={installationId}
                    filter={outdatedFilter}
                />
            ),
            type: 'outdated',
        },
    ];
    const activeTabIndex = tabs.findIndex(({ to }) => pathname.includes(to));

    const getProgress = (): DrawerProps['progress'] => {
        if (!installation) return null;

        const [lastTask] = installation.tasks.filter(({ type }) => UPDATE_TASKS.includes(type));
        if (!lastTask?.info) {
            return null;
        }

        const steps = lastTask.info.steps.map((step) => ({
            key: step.code,
            title: step.title,
            status: step.status === 'started' ? 'running' : step.status,
        }));

        return {
            steps,
        };
    };

    const progress = getProgress();
    const renderSubtitle = () => {
        if (progress) {
            return translate('subtitleUpdateInProgress');
        }

        return (
            installation?.managed && (
                <ProtectControlCompact
                    installation={installation}
                    showProtectInfoPopover={tabs[activeTabIndex]?.type === 'vulnerabilities'}
                />
            )
        );
    };

    const handleClose = async () => {
        await onClose();
        outdatedFilter.resetFilter();
        vulnerabilitiesComponentFilter.resetFilter();

        const activeTab = tabs.find(({ to }) => pathname.includes(to));
        if (activeTab?.type === 'vulnerabilities') {
            analyticsEvents.wpSglSiteVulnerabilitiesClose();
        } else if (activeTab?.type === 'outdated') {
            analyticsEvents.wpSglSiteOutdatedClose();
        }
    };

    const handleClickOnTab = ({ type, to }: TabType) => {
        if (type === 'vulnerabilities') {
            analyticsEvents.wpTabSiteVulnerabilitiesClick();
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (type === 'outdated') {
            analyticsEvents.wpTabSiteOutdatedClick();
        }
        navigate({
            pathname: to,
            search,
        });
    };

    const renderTitle = () => {
        if (isLoading) {
            return <Skeleton width={300} />;
        }

        if (!installation) return null;

        if (progress) {
            return translate('titleUpdateInProgress', { title: installation.displayTitle });
        }

        return installation.displayTitle;
    };

    const renderContent = () => {
        if (isError) {
            return (
                <StatusMessage intent="danger">
                    {translate('errors.installationNotFound', { id: installationId })}
                </StatusMessage>
            );
        }

        if (isLoading) {
            return <ContentLoader />;
        }

        return (
            <Routes>
                {tabs.map(({ children, to }) => (
                    <Route key={to} path={`${to}/*`} element={children} />
                ))}
                <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Routes>
        );
    };

    return (
        <Drawer
            data-type="wp-website-drawer"
            className="websiteDrawer"
            title={renderTitle()}
            subtitle={renderSubtitle()}
            size="lg"
            isOpen={isOpen}
            onClose={handleClose}
            progress={progress}
            tabs={
                !progress &&
                installation && (
                    <Tabs active={activeTabIndex + 1}>
                        {tabs.map((tab) => (
                            <Tab
                                onClick={() => handleClickOnTab(tab)}
                                key={tab.to}
                                title={tab.title}
                            />
                        ))}
                    </Tabs>
                )
            }
        >
            {renderContent()}
        </Drawer>
    );
};

export default WebsiteDrawer;
