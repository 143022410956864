// Copyright 2024. WebPros International GmbH. All rights reserved.

import { NotificationCategories } from '@platform360/security-dashboard/shared/notifications';
import { Notification } from '@platform360/security-dashboard/web/types';

export type Intent = 'danger' | 'warning' | 'info' | 'inactive';

export const categoryToIntentsMap = {
    [NotificationCategories.alert]: 'danger',
    [NotificationCategories.warning]: 'warning',
    [NotificationCategories.promotion]: 'info',
    [NotificationCategories.feedback]: 'info',
    [NotificationCategories.information]: 'inactive',
} as const;

export const getMostIntent = (notifications: Notification[]): Intent => {
    const intents = notifications.map(({ category }) => categoryToIntentsMap[category]);

    if (intents.includes('danger')) {
        return 'danger';
    }

    if (intents.includes('warning')) {
        return 'warning';
    }

    if (intents.includes('info')) {
        return 'info';
    }

    return 'inactive';
};
