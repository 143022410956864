// Copyright 2024. WebPros International GmbH. All rights reserved.

import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import { CHANGE_PERIOD } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type ChangePeriodAction = {
    type: typeof CHANGE_PERIOD;
    payload: BillingPeriod;
};

const changePeriod = (period: BillingPeriod): ChangePeriodAction => ({
    type: CHANGE_PERIOD,
    payload: period,
});

export default changePeriod;
