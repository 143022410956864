// Copyright 2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { StoreState } from '@platform360/licenses/web/store/types';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';
import LicenseInfo from '@platform360/licenses/web/units/extensions/components/LicenseInfo';

const mstp = (state: StoreState) => {
    const { license } = upsellSelector(state);

    return {
        keyNumber: String(license.keyNumber),
        name: String(license.name),
        code: String(license.code),
    };
};

export default connect(mstp)(LicenseInfo);
