// Copyright 2024. WebPros International GmbH. All rights reserved.

import DefaultOnboarding from './DefaultOnboarding';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';

const Onboarding = () => {
    const analyticsEvents = useLicensesAnalyticsEvents();

    const handleBuyClick = () => {
        analyticsEvents.licensesBuyNewLicenseClicked();
    };

    return <DefaultOnboarding onBuyClick={handleBuyClick} />;
};

export default Onboarding;
