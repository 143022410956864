// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { saveInstallationSettings } from '@platform360/security-dashboard/web/api/installations';
import { refetchList } from '@platform360/security-dashboard/web/hooks/useListFetcher';
import useInstallationsQuery from '@platform360/security-dashboard/web/queries/useInstallationsQuery';

type UseSaveInstallationSettingsMutationOptions = {
    onSuccess: () => void;
};

const useSaveInstallationSettingsMutation = ({
    onSuccess,
}: UseSaveInstallationSettingsMutationOptions) =>
    useMutation({
        mutationFn: saveInstallationSettings,
        onSuccess: () => {
            refetchList(useInstallationsQuery.getQueryKey());
            onSuccess();
        },
    });

export default useSaveInstallationSettingsMutation;
