// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, ReactElement } from 'react';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Installation, Task, UPDATE_TASKS } from '@platform360/security-dashboard/web/types';
import { Icon, Label, ListAction, ListActions, Popover, TranslationProps } from '@plesk/ui-library';
import DetachInstallationsButton from '@platform360/security-dashboard/web/components/Installations/DetachInstallationsButton';

import styles from './SiteActionsColumn.module.less';
import {
    LabelsPopover,
    AssignRemoveLabelsOptions,
} from '@platform360/security-dashboard/web/components/LabelsPopover';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';
import { Patchstack } from '@platform360/security-dashboard/web/components/Providers';
import { Nowrap } from '@platform360/security-dashboard/web/components/Nowrap';
import { ProtectInstallationsPopover } from '@platform360/security-dashboard/web/components/Installations/Toolbar/ProtectInstallationsPopover';
import { DisableProtectionButton } from '@platform360/security-dashboard/web/components/Installations/DisableProtectionButton';
import ToolbarButton from '@platform360/security-dashboard/web/components/ToolbarButton';
import {
    APPLY_VIRTUAL_PATCHES_TASK_TYPE,
    DISABLE_VIRTUAL_PATCHES_TASK_TYPE,
    REFRESH_INSTALLATIONS_TASK_TYPE,
    UPDATE_INSTANCE_TASK_TYPE,
    UPDATE_ITEMS_TASK_TYPE,
} from '@platform360/security-dashboard/shared/constants';

const hasActiveTask = (tasks: Task[]) =>
    tasks.some(({ type }) => [...UPDATE_TASKS, REFRESH_INSTALLATIONS_TASK_TYPE].includes(type));

type SiteActionsColumnProps = {
    installation: Installation;
    handleDetach: (installationIds: number[]) => void;
    handleInstallationsUpdate: (installationIds: number[]) => void;
    handleInstallationsRefresh: (installationIds: number[]) => void;
    handleSwitchProtectionState: (installationId: number, isProtected: boolean) => void;
    handleAssignInstallationLabels: (data: AssignRemoveLabelsOptions) => void;
    handleRemoveInstallationLabels: (data: AssignRemoveLabelsOptions) => void;
    installationsWithStartingDetach: number[];
    installationsWithStartingUpdates: number[];
    installationsWithProtectSwitch: number[];
    installationsWithStartingRefresh: number[];
};

export const SiteActionsColumn = ({
    installation,
    handleInstallationsUpdate,
    handleDetach,
    handleSwitchProtectionState,
    handleInstallationsRefresh,
    handleAssignInstallationLabels,
    handleRemoveInstallationLabels,
    installationsWithStartingUpdates,
    installationsWithProtectSwitch,
    installationsWithStartingRefresh,
    installationsWithStartingDetach,
}: SiteActionsColumnProps) => {
    const { id, operable, outdated, tasks, protected: isProtected, managed } = installation;
    const hasActiveTasks = hasActiveTask(tasks);
    const translate = useTranslate('security-dashboard.Installations');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const [showLabelsPopover, setShowLabelsPopover] = useState(false);
    const handleLabelsPopoverShown = () => {
        setShowLabelsPopover(true);
        analyticsEvents.wpLabelsPopoverShown('SITE_TAB');
    };
    const handleLabelsPopoverClosed = () => {
        setShowLabelsPopover(false);
        analyticsEvents.wpLabelsPopoverClosed('SITE_TAB');
    };

    const isUpdateActive = operable && outdated && !hasActiveTasks;

    const isEnableProtectionTaskStarted = tasks.some(
        (task) => task.type === APPLY_VIRTUAL_PATCHES_TASK_TYPE,
    );
    const isDisableProtectionTaskStarted = tasks.some(
        (task) => task.type === DISABLE_VIRTUAL_PATCHES_TASK_TYPE,
    );

    const isEnablingProtection =
        isEnableProtectionTaskStarted || installationsWithProtectSwitch.includes(id);
    const isDisablingProtection =
        isDisableProtectionTaskStarted || installationsWithProtectSwitch.includes(id);

    const isUpdateTaskStarted = tasks.some(
        (task) => task.type === UPDATE_ITEMS_TASK_TYPE || task.type === UPDATE_INSTANCE_TASK_TYPE,
    );
    const isUpdating = isUpdateTaskStarted || installationsWithStartingUpdates.includes(id);

    return (
        <div data-type="actions-column" className={styles.actions}>
            {managed &&
                operable &&
                (isProtected ? (
                    <Popover
                        target={
                            <Label size="lg" view="light" intent="success">
                                <Icon name="shield" />
                            </Label>
                        }
                    >
                        {translate('Actions.protectionEnabled', {
                            providerLogo: <Patchstack />,
                            whiteSpaceNoWrap: (nowrap) => <Nowrap>{nowrap}</Nowrap>,
                        })}
                    </Popover>
                ) : (
                    <ProtectInstallationsPopover
                        onClick={() => {
                            analyticsEvents.wpSglProtectConfirmationClick();
                            handleSwitchProtectionState(installation.id, true);
                        }}
                        selection={[installation.id]}
                        selectedInstallationDisplayTitle={installation.displayTitle}
                        onConfirmationShown={() => analyticsEvents.wpSglProtectClick()}
                        target={
                            <ToolbarButton
                                selectedItems={[installation.id]}
                                buttonText={translate('Actions.enableProtectButton')}
                                noSelectionText={translate('Actions.enableProtectButton')}
                                intent="primary"
                                icon={{ name: 'shield', size: '16' }}
                                data-type="enable-protect"
                                state={isEnablingProtection ? 'loading' : undefined}
                            />
                        }
                    />
                ))}
            <LabelsPopover
                visible={showLabelsPopover}
                selectionWithLabels={[
                    {
                        id: installation.id,
                        labels: installation.labels.map((l) => l.id),
                    },
                ]}
                onAssign={handleAssignInstallationLabels}
                onRemove={handleRemoveInstallationLabels}
                onClose={handleLabelsPopoverClosed}
                placement="bottom-left"
            >
                <ListActions>
                    <ListAction
                        primary
                        icon="refresh"
                        state={
                            installationsWithStartingRefresh.includes(id) ? 'loading' : undefined
                        }
                        onClick={() => {
                            analyticsEvents.wpSglRefreshSiteClick();
                            handleInstallationsRefresh([id]);
                        }}
                        disabled={hasActiveTasks}
                    >
                        {translate('refresh')}
                    </ListAction>
                    <ListAction
                        data-type="update-installation"
                        icon="arrow-up-circle"
                        disabled={!isUpdateActive}
                        onClick={() => {
                            analyticsEvents.wpSglUpdateSiteClick();
                            handleInstallationsUpdate([id]);
                        }}
                        state={isUpdating ? 'loading' : undefined}
                    >
                        {translate('update')}
                    </ListAction>
                    {managed && isProtected && !isDisablingProtection && (
                        <ListAction data-type="disable-protect" icon="shield">
                            {({ wrap }: { wrap: (text: TranslationProps) => ReactElement }) => (
                                <DisableProtectionButton
                                    installation={installation}
                                    onButtonClick={(withConfirmation) => {
                                        analyticsEvents.wpSglProtectDisableClick(
                                            installation.id,
                                            withConfirmation,
                                        );
                                    }}
                                    target={wrap(translate('Actions.disableProtectButton'))}
                                    onConfirm={() => {
                                        analyticsEvents.wpSglProtectDisableConfirmationClick(
                                            installation.id,
                                        );
                                    }}
                                    handleSwitchProtection={() =>
                                        handleSwitchProtectionState(installation.id, false)
                                    }
                                    placement="left"
                                />
                            )}
                        </ListAction>
                    )}
                    {installation.managed && (
                        <ListAction
                            component={DrawerLink}
                            to={`${installation.id}/update-settings`}
                            onClick={() => analyticsEvents.wpSglKbbAutoupdateSettingsClick()}
                            icon="gear"
                        >
                            {translate('updateSettings')}
                        </ListAction>
                    )}

                    <ListAction
                        data-type="manage-labels"
                        icon="pin"
                        onClick={handleLabelsPopoverShown}
                    >
                        {translate('Actions.openLabelsPopover')}
                    </ListAction>
                    <ListAction disabled={hasActiveTasks} icon="remove">
                        {({ wrap }: { wrap: (text: TranslationProps) => ReactElement }) => (
                            <DetachInstallationsButton
                                installations={[installation]}
                                target={wrap(translate('detach'))}
                                placement="left"
                                onConfirmationShown={() =>
                                    analyticsEvents.wpSglKbbDetachInstallationClick()
                                }
                                onConfirm={(installationsIds) => {
                                    analyticsEvents.wpSglKbbDetachInstallationConfirmationClick();
                                    handleDetach(installationsIds);
                                }}
                                isLoading={installationsWithStartingDetach.includes(
                                    installation.id,
                                )}
                            />
                        )}
                    </ListAction>
                </ListActions>
            </LabelsPopover>
        </div>
    );
};
