// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import { NotificationsSettings } from '@platform360/security-dashboard/web/types';

export const confirmNotificationEmail = async () => {
    await apiClient.post<never>('/security-dashboard/notifications-settings/email');
};

type NotificationsSettingsResponse = {
    email: string;
    newExploitedVulnerabilities: boolean;
    licenseNotifications: boolean;
};

type GetNotificationsSettingsOptions = FetcherOptions;
export const getNotificationsSettings = async ({
    signal,
}: GetNotificationsSettingsOptions): Promise<NotificationsSettings> => {
    const { data } = await apiClient.get<ApiResponse<NotificationsSettingsResponse>>(
        '/security-dashboard/notifications-settings',
        {
            signal,
        },
    );
    return data.data;
};

type VerifyNotificationsEmailOptions = {
    email: string;
};
export const verifyNotificationsEmail = async (options: VerifyNotificationsEmailOptions) => {
    await apiClient.post<never>('/security-dashboard/notifications-settings/email/verify', options);
    return options;
};

type UpdateNotificationsSettingsOptions = {
    newExploitedVulnerabilities?: boolean;
    licenseNotifications?: boolean;
};

export const updateNotificationsSettings = async (options: UpdateNotificationsSettingsOptions) => {
    await apiClient.put('/security-dashboard/notifications-settings', options);
    return options;
};
