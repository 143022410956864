// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import useDarkTheme from '@platform360/libs/shared-web/helpers/useDarkTheme';

const Document = () => {
    const { isDarkMode } = useDarkTheme();
    const {
        document: { title, favicon, darkThemeDataAttribute },
    } = useApplicationSettings();

    useEffect(() => {
        document.title = title;
        document.getElementById('favicon')?.setAttribute('href', `${favicon}?1`);
    }, [title, favicon]);

    useEffect(() => {
        document.documentElement.dataset.theme = isDarkMode ? darkThemeDataAttribute : 'light';
    }, [darkThemeDataAttribute, isDarkMode]);

    return null;
};

export default Document;
