// Copyright 2024. WebPros International GmbH. All rights reserved.

import { UpdateBusinessProfilePayload } from '@platform360/accounts/web/api/me';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';

const useFirstTime = (modalPath: string, values?: UpdateBusinessProfilePayload) => {
    const navigate = useNavigate();
    const [localFlag, setLocalFlag] = useLocalStorage('answer-business-profile-later', false);
    const [isFirstTime, setIsFirstTime] = useState(false);
    const setAnswerLater = () => {
        setIsFirstTime(false);
        setLocalFlag(true);
    };
    const getModalUrl = useGetModalUrl();

    useEffect(() => {
        if (!localFlag && values && Object.keys(values).length === 0 && !isFirstTime) {
            setIsFirstTime(true);
            navigate(getModalUrl(modalPath));
        }
    }, [localFlag, values, navigate, modalPath, isFirstTime, getModalUrl]);

    return { isFirstTime, setAnswerLater };
};

export default useFirstTime;
