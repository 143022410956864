// Copyright 2024. WebPros International GmbH. All rights reserved.

import { memo } from 'react';
import Status from './Status';
import styles from './InfoAggregateColumn.module.css';
import { Column, Heading, TranslationProps, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type InfoAggregateColumnProps = {
    title: TranslationProps;
    total: number;
    down: number;
    alerts: number;
    href: string;
};

const InfoAggregateColumn = ({
    total,
    down,
    alerts,
    title,
    href,
    ...props
}: InfoAggregateColumnProps) => {
    const translate = useTranslate(
        'monitoring.HomePageWidget.DynamicWidget.WidgetContent.InfoAggregateColumn',
    );

    return (
        <Column {...props}>
            <Heading level={4} className={styles.title}>
                <a href={href} rel="noreferrer" target="_blank">
                    {title}
                </a>
                <Text fontSize="sm" intent="muted" className={styles.total}>
                    {total}
                </Text>
            </Heading>
            <Status
                count={down}
                errorIntent="danger"
                translations={{
                    error: translate('down', { down }),
                    success: translate('allActive'),
                }}
            />
            <Status
                count={alerts}
                errorIntent="warning"
                translations={{
                    error: translate('alerts', { alerts }),
                    success: translate('noAlerts'),
                }}
            />
        </Column>
    );
};

export default memo(InfoAggregateColumn);
