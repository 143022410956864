// Copyright 2024. WebPros International GmbH. All rights reserved.
import { ServerLabelTypes } from '@platform360/security-dashboard/shared/label-type';
import FiltersForm from '@platform360/security-dashboard/web/components/FiltersForm';
import { FormFieldCheckbox, FormFieldSelect, SelectOption, setIn } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useState } from 'react';

import useLabelsQuery from '@platform360/security-dashboard/web/queries/useLabelsQuery';
import useTranslateLabel from '@platform360/security-dashboard/web/hooks/useTranslateLabel';
import {
    SitesHealthFilter,
    SITES_HEALTH_FILTER_ALERTS,
    SITES_HEALTH_FILTER_ALL,
} from '@platform360/security-dashboard/web/api/servers';

type FilterPanelProps = {
    onFiltersChange: (sitesHealthFilter: SitesHealthFilter, labelIds: number[]) => void;
    sitesHealthFilter: SitesHealthFilter;
    labelIds: number[];
    onReset: () => void;
};

type FormValues = {
    sitesHealthFilter: boolean;
    labelIds: number[];
};

const FilterPanel = ({
    sitesHealthFilter,
    labelIds,
    onFiltersChange,
    onReset,
}: FilterPanelProps) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar.FilterPanel');
    const translateLabel = useTranslateLabel();

    const [formValues, setFormValues] = useState<FormValues>({
        sitesHealthFilter: sitesHealthFilter === SITES_HEALTH_FILTER_ALERTS,
        labelIds,
    });
    const { data: labelsData } = useLabelsQuery();
    const labels = (labelsData?.data ?? []).filter(({ type }) => !!ServerLabelTypes[type]);

    const onFieldChange = (key: keyof FormValues, value: FormValues[typeof key]) => {
        setFormValues(setIn(formValues, key, value));
    };

    return (
        <FiltersForm<FormValues>
            values={formValues}
            onReset={onReset}
            role="menu"
            onFieldChange={onFieldChange}
            onSubmit={(values) => {
                onFiltersChange(
                    formValues.sitesHealthFilter
                        ? SITES_HEALTH_FILTER_ALERTS
                        : SITES_HEALTH_FILTER_ALL,
                    values.labelIds,
                );
            }}
        >
            <FormFieldCheckbox name="sitesHealthFilter" label={translate('filterAlerts')} />
            {labels.length > 0 && (
                <FormFieldSelect
                    multiple
                    name={'labelIds'}
                    placeholder={translate('labelsPlaceholder')}
                >
                    {labels.map((l) => (
                        <SelectOption key={l.id} value={l.id}>
                            {translateLabel(l).title}
                        </SelectOption>
                    ))}
                </FormFieldSelect>
            )}
        </FiltersForm>
    );
};

export default FilterPanel;
