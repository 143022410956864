// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import InvalidCertificateKBArticleLink from '@platform360/libs/shared-web/components/InvalidCertificateKBArticleLink';
import { useCallback } from 'react';
import HowToUpgradePleskKBArticleLink from '@platform360/libs/shared-web/components/HowToUpgradePleskKBArticleLink';
import { isCertErrorCode } from '@platform360/libs/common/cert-error-codes';

/**
 * This hook should be part of Server Inventory module but moved here due to circular dependencies between modules.
 */
export const useServerInventoryErrorsTranslate = () => {
    const translate = useTranslate('server-inventory.errors');

    return useCallback(
        (errorCode: string, errorMessage: string) => {
            if (errorCode === 'unknown') {
                return translate('unknown', {
                    message: errorMessage,
                });
            }
            if (errorCode === 'newServer') {
                return translate('newServer');
            }
            if (errorCode === 'domainName') {
                return translate('domainName');
            }
            if (errorCode === 'unsupportedServerVersion') {
                return translate('unsupportedServerVersion', {
                    kbLink: <HowToUpgradePleskKBArticleLink />,
                });
            }
            if (errorCode === 'pleskConfiguration') {
                return translate('serverMisconfigured');
            }
            if (errorCode === 'connection') {
                return translate('connection');
            }
            if (errorCode === 'connectionRefused') {
                return translate('connectionRefused');
            }
            if (errorCode === 'timeout') {
                return translate('timeout');
            }
            if (errorCode === 'apiKey') {
                return translate('apiKey');
            }
            if (errorCode === 'httpsRequired') {
                return translate('httpsRequired');
            }
            if (isCertErrorCode(errorCode)) {
                return translate(errorCode, {
                    kbLink: <InvalidCertificateKBArticleLink />,
                });
            }

            return errorMessage;
        },
        [translate],
    );
};
