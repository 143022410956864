// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonGroup, Drawer, Icon, Popover } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ReactivateForm from './ReactivateForm';
import MainButton from './MainButton';
import isReactivateButtonDisabled from '../helpers/isReactivateButtonDisabled';
import isReactivatePossible from '../helpers/isReactivatePossible';
import { LicenseWithSubscription as BackendLicenseWithSubscription } from '@platform360/licenses/web/api/ka/responseTypes/License';
import doRequest from '@platform360/licenses/web/api/doRequest';
import renew from '@platform360/licenses/web/api/ka/keys/renew';
import styles from './Reactivate.module.css';
import refreshKeyList from '@platform360/licenses/web/store/refreshKeyList';
import { useToaster } from '@platform360/libs/shared-web/toaster';

const ReactivateSubscription = ({ license }: { license: BackendLicenseWithSubscription }) => {
    const translate = useTranslate();
    const [isOpen, toggleDrawer] = useState(false);
    const { successToast } = useToaster();
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        toggleDrawer(false);
        await doRequest(renew(license.id));
        successToast(translate('Keys.Renew.successMessage', { product: license.type }));
        dispatch(refreshKeyList());
        setTimeout(() => {
            // Refresh key list after 5 seconds for the case when the key is not yet updated
            dispatch(refreshKeyList());
        }, 5000);
    };

    const handleToggleDrawer = () => toggleDrawer(!isOpen);

    if (!isReactivatePossible(license)) {
        return null;
    }

    return (
        <>
            <ButtonGroup>
                <MainButton
                    disabled={isReactivateButtonDisabled(license)}
                    onClick={handleToggleDrawer}
                />
                {isReactivateButtonDisabled(license) && (
                    <Popover
                        intent={'info'}
                        target={<Icon name={'info-circle-filled'} intent={'info'} size={'16'} />}
                    >
                        {translate('Keys.Renew.unavailable')}
                    </Popover>
                )}
            </ButtonGroup>
            <Drawer
                isOpen={isOpen}
                size={'sm'}
                onClose={handleToggleDrawer}
                title={translate('Keys.Renew.drawer.title')}
                data-type={'upsell'}
                className={styles.reactivateDrawer}
            >
                {/* checking for openness is needed to timely destroy the component. Otherwise remains the artifact in the form of a confirm popover */}
                {isOpen && (
                    <ReactivateForm
                        licenseId={license.id}
                        onSubmit={handleSubmit}
                        onCancel={handleToggleDrawer}
                        period={license.billingPeriod}
                    />
                )}
            </Drawer>
        </>
    );
};

export default ReactivateSubscription;
