// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer } from '@plesk/ui-library';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import NotificationsSettings from '@platform360/server-inventory/web/components/NotificationsSettings';

export type NotificationsSettingsDrawerProps = ClosableProps;

export const NotificationsSettingsDrawer = (props: NotificationsSettingsDrawerProps) => {
    const translate = useTranslate(
        'server-inventory.Layout.HeaderAddon.NotificationsSettingsDrawer',
    );

    return (
        <Drawer
            title={translate('title')}
            size="sm"
            data-type="notifications-settings-drawer"
            {...props}
        >
            <NotificationsSettings />
        </Drawer>
    );
};
