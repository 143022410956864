// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer } from '@plesk/ui-library';
import { ClosableProps } from '@platform360/libs/shared-web/helpers/withRouteBinding';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Content } from '@platform360/security-dashboard/web/components/NotificationsSettingsDrawer/Content';

export type NotificationsSettingsDrawerProps = ClosableProps;

export const NotificationsSettingsDrawer = (props: NotificationsSettingsDrawerProps) => {
    const translate = useTranslate('security-dashboard.NotificationsSettingsDrawer');

    return (
        <Drawer
            title={translate('title')}
            size="sm"
            data-type="notifications-settings-drawer"
            {...props}
        >
            <Content />
        </Drawer>
    );
};
