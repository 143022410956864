// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Server } from '@platform360/security-dashboard/web/types';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import { useRefreshMutation } from '@platform360/security-dashboard/web/mutations';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';

type FindNewSitesButtonProps = {
    selectedServers: Server[];
    onTaskStarting: (serversIds: number[], operation: 'refresh') => void;
    onTaskStarted: (serversIds: number[], operation: 'refresh') => void;
};

export const RefreshButton = ({
    selectedServers,
    onTaskStarted,
    onTaskStarting,
    ...props
}: FindNewSitesButtonProps) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar.RefreshButton');
    const { mutateAsync: refresh, isPending: isLoading } = useRefreshMutation();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const serversIds = selectedServers.map((el) => el.id);
    const handleStartSecurityCheck = async () => {
        analyticsEvents.wpMltRefreshServerClick(selectedServers.length);
        onTaskStarting(serversIds, 'refresh');
        try {
            await refresh(selectedServers);
        } finally {
            onTaskStarted(serversIds, 'refresh');
        }
    };

    return (
        <NoSelectionPopover
            selectionCount={selectedServers.length}
            target={
                <Button
                    state={isLoading ? 'loading' : undefined}
                    onClick={handleStartSecurityCheck}
                    icon="refresh"
                    tooltip={translate('buttonText')}
                    {...props}
                />
            }
        >
            {translate('noSelectedServers')}
        </NoSelectionPopover>
    );
};

export default RefreshButton;
