// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import {
    DailyTaskTimeSettingsResponse,
    DailyTaskTimeSettingsSaveData,
    VulnerabilitySettingsResponse,
    VulnerabilitySettingsSaveData,
} from '@platform360/security-dashboard/web/types';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';

export type UpdateSettingsResponse = {
    core: UpdateSettingsOption;
    plugins: UpdateSettingsOption;
    themes: UpdateSettingsOption;
    safeUpdate: SafeUpdateOption;
};

type GetUpdateSettingsOptions = FetcherOptions;

export const getUpdateSettings = async ({
    signal,
}: GetUpdateSettingsOptions): Promise<UpdateSettingsResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<UpdateSettingsResponse>>(
        '/security-dashboard/settings/updates',
        {
            signal,
        },
    );

    return data;
};

export type SettingsResponse = {
    updates: UpdateSettingsResponse;
    dailyTask: DailyTaskTimeSettingsResponse;
    vulnerabilities: VulnerabilitySettingsResponse;
};

type GetSettingsOptions = FetcherOptions;

export const getSettings = async ({ signal }: GetSettingsOptions): Promise<SettingsResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<SettingsResponse>>('/security-dashboard/settings', {
        signal,
    });

    return data;
};

export type UpdateSettingsRequest = UpdateSettingsResponse & {
    overrideDescendants: boolean;
};

export type SaveSettingsRequest = {
    updates?: UpdateSettingsRequest;
    dailyTask?: DailyTaskTimeSettingsSaveData;
    vulnerabilities?: VulnerabilitySettingsSaveData;
};

export const saveSettings = async (data: SaveSettingsRequest): Promise<void> => {
    await apiClient.put('/security-dashboard/settings', data);
};
