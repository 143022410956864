// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import Toolbar from '@platform360/security-dashboard/web/components/GlobalTaskManagerDrawer/Toolbar';
import RowTitle from '@platform360/security-dashboard/web/components/GlobalTaskManagerDrawer/TasksList/RowTitle';
import RowDate from './RowDate/RowDate';
import RowStatus from './RowStatus';
import RowBody from '@platform360/security-dashboard/web/components/GlobalTaskManagerDrawer/TasksList/RowBody';
import taskListDataConverter from '@platform360/security-dashboard/web/components/GlobalTaskManagerDrawer/TasksList/taskListDataConverter';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import { useOperationsQuery } from '@platform360/security-dashboard/web/queries';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { useMarkAllFinishedOperationsAsRead } from '@platform360/security-dashboard/web/mutations';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useEffect, useRef } from 'react';
import { UseFilterProps } from '@platform360/libs/shared-web/helpers/useFilter';
import { ListEmptyView } from '@plesk/ui-library';
import imageEmptyList from './empty-list.png';
import ListEmptyViewFilter from '@platform360/security-dashboard/web/components/ListEmptyViewFilter';
import { OperationTypes } from '@platform360/security-dashboard/shared/operation-type';
import {
    OPERATION_STATUS_FAILED,
    OperationsStatusFilter,
    OperationsTypeFilter,
    TaskManagerListRow,
} from '@platform360/security-dashboard/web/types';

type FilterType = OperationsStatusFilter | OperationsTypeFilter;

const isOperationsStatusFilter = (value: FilterType): value is OperationsStatusFilter =>
    [OPERATION_STATUS_FAILED].includes(value as OperationsStatusFilter);

type TasksListProps = {
    operationsStatusFilter: UseFilterProps<OperationsStatusFilter>;
    operationsTypeFilter: UseFilterProps<OperationsTypeFilter>;
};

export const TasksList = ({ operationsStatusFilter, operationsTypeFilter }: TasksListProps) => {
    const translate = useTranslate('security-dashboard.GlobalTaskManagerDrawer.TasksList');
    const pagination = usePagination('task-manager');
    const { mutate: markAllFinishedAsRead } = useMarkAllFinishedOperationsAsRead();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const taskManagerShown = useRef<boolean>(false);
    const {
        setFilter: setOperationStatusFilter,
        resetFilter: resetOperationStatusFilter,
        onFilterChange: onOperationStatusFilterChange,
        filter: operationStatusFilter,
    } = operationsStatusFilter;
    const {
        setFilter: setOperationTypeFilter,
        resetFilter: resetOperationTypeFilter,
        onFilterChange: onOperationTypeFilterChange,
        filter: operationTypeFilter,
    } = operationsTypeFilter;

    const handleOperationFilterChange = (value: FilterType) => {
        if (isOperationsStatusFilter(value)) {
            onOperationStatusFilterChange(value);
        } else {
            switch (value) {
                case OperationTypes.update:
                    analyticsEvents.wpFilterUpdateClick();
                    break;
                case OperationTypes.fixVulnerabilitiesViaUpdate:
                    analyticsEvents.wpFilterFixVulnerabilityViaUpdateClick();
                    break;
                case OperationTypes.refresh:
                    analyticsEvents.wpFilterRefreshClick();
                    break;
                case OperationTypes.findNewSites:
                    analyticsEvents.wpFilterFindNewSitesClick();
                    break;
                case OperationTypes.configureUpdates:
                    analyticsEvents.wpFilterConfigureAutoupdatesClick();
                    break;
                case OperationTypes.setDailyTaskTime:
                    analyticsEvents.wpFiltersetDailyTaskTimeClick();
                    break;
                case OperationTypes.mitigationDeactivateAsset:
                    analyticsEvents.wpFilterMitigationDeactivateAssetClick();
                    break;
                case OperationTypes.mitigationActivateAsset:
                    analyticsEvents.wpFilterMitigationActivateAssetClick();
                    break;
                case OperationTypes.ignoreVulnerabilities:
                    analyticsEvents.wpFilterIgnoreVulnerabilitiesClick();
                    break;
                case OperationTypes.cancelIgnoreVulnerabilities:
                    analyticsEvents.wpFilterCancelIgnoreVulnerabilitiesClick();
                    break;
            }
            onOperationTypeFilterChange(value);
        }

        pagination.resetPagination();
    };

    const handleOperationGeneralFilterChange = (
        statusFilter: OperationsStatusFilter[],
        typeFilter: OperationsTypeFilter[],
    ) => {
        setOperationStatusFilter(statusFilter);
        setOperationTypeFilter(typeFilter);

        pagination.resetPagination();
    };

    const resetOperationsFilter = () => {
        resetOperationStatusFilter();
        resetOperationTypeFilter();
        pagination.resetPagination();
        analyticsEvents.wpFilterAllClick();
    };

    const {
        data: apiData,
        isLoading,
        isPlaceholderData: isPreviousData,
        refetch,
    } = useOperationsQuery({
        variables: {
            operationStatusFilter,
            operationTypeFilter,
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
        },
        placeholderData: (prev) => prev,
        // If the user looks into errors of the particular operation while other operations are executing (and getting completed),
        // auto refetch may move the operation which the user works with to the next page. So we disable it.
        enabled: false,
    });

    useEffect(() => {
        if (!apiData) {
            return;
        }

        if (!taskManagerShown.current) {
            taskManagerShown.current = true;
            markAllFinishedAsRead();
            analyticsEvents.wpTaskManagerShown(apiData.totalCount);
        }
    }, [analyticsEvents, apiData, markAllFinishedAsRead]);

    useEffect(() => {
        void refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.current,
        pagination.itemsPerPage,
        operationStatusFilter,
        operationTypeFilter,
        refetch,
    ]);

    const data = apiData?.data || [];
    const totalItems = apiData?.totalCount;
    const { data: listData, notExpandableRows } = taskListDataConverter({
        data,
    });

    const columns: ListProps<TaskManagerListRow>['columns'] = [
        {
            key: 'type',
            type: 'title',
            title: translate('columns.task'),
            render: (row) => <RowTitle row={row} />,
        },
        {
            key: 'status',
            title: translate('columns.status'),
            width: '150px',
            render: (row) => <RowStatus row={row} />,
        },
        {
            key: 'started',
            title: translate('columns.started'),
            width: '130px',
            render: ({ createdAt }) => <RowDate date={createdAt} />,
        },
        {
            key: 'finished',
            title: translate('columns.finished'),
            width: '130px',
            render: ({ finishedAt }) => <RowDate date={finishedAt} />,
        },
    ];

    return (
        <DataList
            rowKey={'key'}
            totalRows={totalItems}
            data={listData}
            columns={columns}
            renderRowBody={(row) => <RowBody row={row} />}
            data-type="task-manager-data"
            filtered={operationStatusFilter.length !== 0 || operationTypeFilter.length !== 0}
            emptyView={<ListEmptyView image={imageEmptyList} />}
            filteredEmptyView={<ListEmptyViewFilter />}
            toolbar={
                <Toolbar
                    onChangeFilter={handleOperationFilterChange}
                    onChangeGeneralFilter={handleOperationGeneralFilterChange}
                    onResetFilter={resetOperationsFilter}
                    statusFilter={operationStatusFilter}
                    typeFilter={operationTypeFilter}
                />
            }
            notExpandableRows={notExpandableRows}
            loading={isLoading || isPreviousData}
            pagination={pagination}
        />
    );
};

export default TasksList;
