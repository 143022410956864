// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link as RouterLink } from 'react-router-dom';
import { Text, Link } from '@plesk/ui-library';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useKaKey } from '@platform360/security-dashboard/web/hooks/useKaKey';
import LinkEmailPopover from '@platform360/security-dashboard/web/components/LicensePage/LinkEmailPopover';
import style from './RetailLicenseInfo.module.css';

const RetailLicenseInfo = () => {
    const translate = useTranslate('security-dashboard.LicensePage.RetailLicenseInfo');
    const formatDateTime = useFormatDateTime();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const {
        kaKey,
        isDeactivated: isCurrentLicenseDeactivated,
        isLoading: isKaKeyLoading,
    } = useKaKey();

    if (isKaKeyLoading) return <div>&nbsp;</div>;

    const handleDetailsClick = () => analyticsEvents.wpLicensePageRetailDetailsClicked();

    if (!kaKey) {
        return (
            <LinkEmailPopover
                target={
                    <Text fontSize="sm">
                        <Link
                            onClick={() => {
                                handleDetailsClick();
                                analyticsEvents.wpLicensePageSeeDetailsLinkEmailPopoverShown();
                            }}
                        >
                            {translate('seeDetails')}
                        </Link>
                    </Text>
                }
                placement="bottom-right"
            />
        );
    }

    const licensesLink = (
        <RouterLink to="/licensing/licenses" onClick={handleDetailsClick}>
            {translate('seeDetails')}
        </RouterLink>
    );

    if (isCurrentLicenseDeactivated) {
        return (
            <Text intent="muted" fontSize="sm">
                {translate('subscriptionCancelled')} {licensesLink}
            </Text>
        );
    }

    if (!kaKey.mainKey.nextBillingDate) return <Text fontSize="sm">{licensesLink}</Text>;

    return (
        <Text intent="muted" fontSize="sm" className={style.nextBillingDate}>
            {translate('nextBillingDate', {
                date: formatDateTime(kaKey.mainKey.nextBillingDate, 'D'),
            })}
            {licensesLink}
        </Text>
    );
};

export default RetailLicenseInfo;
