// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import qs from 'qs';

export const LoginRedirect = () => {
    const location = useLocation();
    const {
        auth: { preferredSignup, acquisitionSource },
    } = useApplicationSettings();
    const redirectPath = preferredSignup ? '/auth/signup' : '/auth/login';
    const redirectUrl = `${location.pathname}${location.search}${location.hash}`;

    if (redirectUrl !== '/') {
        return (
            <Navigate to={`${redirectPath}?${qs.stringify({ redirectUrl, acquisitionSource })}`} />
        );
    }

    return <Navigate to={redirectPath} />;
};

const PrivateRoutes = () => {
    const { isAuthorized } = useAuth();

    if (!isAuthorized) {
        return <LoginRedirect />;
    }

    return <Outlet />;
};

export default PrivateRoutes;
