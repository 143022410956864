// Copyright 2024. WebPros International GmbH. All rights reserved.

import { License } from '@platform360/security-dashboard/web/types';
import LicenseInfo from '@platform360/security-dashboard/web/components/LicensePage/LicenseInfo';

type RecommendedPartnerLicenseProps = {
    totalInstallationsCount: number;
    license: License | null;
    handleUnlimitedLicenseClick: () => void;
    handleStandardLicenseClick: (sitesNumber: number) => void;
};

const getRecommendedPartnerLicense = (
    totalInstallationsCount: number,
    product: string,
    isTrial: boolean,
    isTerminated: boolean,
): 'unlimited' | 'standard' =>
    totalInstallationsCount > 1000 || (product === 'limit1000' && !isTrial && !isTerminated)
        ? 'unlimited'
        : 'standard';

const RecommendedPartnerLicense = ({
    totalInstallationsCount,
    license,
    handleUnlimitedLicenseClick,
    handleStandardLicenseClick,
}: RecommendedPartnerLicenseProps) => {
    if (
        !license ||
        license.sitesLimitHard === null ||
        license.product === 'unlimited' ||
        (license.status === 'terminated' && totalInstallationsCount === 0)
    ) {
        return null;
    }

    const alwaysRecommendedProducts = ['limit500', 'limit1000'];
    const isRecommended =
        license.trial ||
        license.status === 'terminated' ||
        (alwaysRecommendedProducts.includes(license.product) && license.status !== 'terminated') ||
        totalInstallationsCount > license.sitesLimitHard;

    const licenseType = isRecommended
        ? getRecommendedPartnerLicense(
              totalInstallationsCount,
              license.product,
              license.trial,
              license.status === 'terminated',
          )
        : undefined;

    if (!licenseType) {
        return null;
    }

    return (
        <LicenseInfo
            license={license}
            licenseType={licenseType}
            intent="primary"
            isRecommended
            totalInstallationsCount={totalInstallationsCount}
            onClick={
                licenseType === 'standard'
                    ? handleStandardLicenseClick
                    : handleUnlimitedLicenseClick
            }
        />
    );
};

export default RecommendedPartnerLicense;
