// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Validator } from './validators';

type Rule = {
    validator: Validator;
    message: ReactNode;
};

type Rules = Record<string, Rule[]>;

type ValidationError = Record<string, string | ReactNode>;
export type ValidationErrors = Record<string, ValidationError>;

const validate = (values: Record<string, string>, rules: Rules) => {
    const errors = Object.keys(rules).reduce<ValidationErrors>((carry, key) => {
        const input = values[key] || '';

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const errors = rules[key]!.reduce<ValidationError>((carry, rule, index) => {
            if (!rule.validator(input.toString())) {
                carry[index] = rule.message;
            }

            return carry;
        }, {});

        if (Object.keys(errors).length > 0) {
            carry[key] = errors;
        }

        return carry;
    }, {});

    return {
        isValid: Object.keys(errors).length === 0,
        errors,
    };
};

export default validate;
