// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import { AnalyticsClientContext } from './AnalyticsClientContext';
import AnalyticsClient from './AnalyticsClient';

const cache = new Map();

export const useAnalyticsEvents = <T,>(EventsClass: new (client: AnalyticsClient) => T): T => {
    const analyticsClient = useContext(AnalyticsClientContext);

    if (!cache.has(EventsClass)) {
        cache.set(EventsClass, new EventsClass(analyticsClient));
    }

    return cache.get(EventsClass);
};
