// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Navigate, RouteObject, useNavigate, useResolvedPath } from 'react-router-dom';
import Servers from '@platform360/server-inventory/web/components/Servers';
import ViewServer from '@platform360/server-inventory/web/components/Servers/ViewServer';
import GeneralInfo from '@platform360/server-inventory/web/components/Servers/ViewServer/GeneralInfo';
import Clients from '@platform360/server-inventory/web/components/Servers/ViewServer/Clients';
import Domains from '@platform360/server-inventory/web/components/Servers/ViewServer/Domains';
import InstallationLog from '@platform360/server-inventory/web/components/Servers/ViewServer/InstallationLog';
import Monitoring from '@platform360/server-inventory/web/components/Servers/ViewServer/Monitoring';
import AllClients from '@platform360/server-inventory/web/components/AllClients';
import AllDomains from '@platform360/server-inventory/web/components/AllDomains';
import Layout from '@platform360/server-inventory/web/components/Layout';
import ServerLogin from '@platform360/server-inventory/web/components/Servers/ServerLogin';
import AddServerWithExternalRedirectDialog from '@platform360/server-inventory/web/components/Servers/AddServerWithExternalRedirectDialog';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import StartPleskTrial from '@platform360/server-inventory/web/components/StartPleskTrial';
import { ReactElement, useCallback, useState } from 'react';
import { ClosableContext } from '@platform360/server-inventory/web/helpers/ClosableContext';
import { AddingServerWizard } from '@platform360/server-inventory/web/components/Servers/AddingServer';

type RouteWrapperForModalsProps = {
    children: (params: { isOpen: boolean; onClose: () => void }) => ReactElement;
};

const RouteWrapperForModals = ({ children }: RouteWrapperForModalsProps) => {
    const { pathname } = useResolvedPath('..');
    const [closing, setClosing] = useState(false);
    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        setClosing(true);
        setTimeout(() => {
            navigate(pathname);
            setClosing(false);
        }, 300);
    }, [navigate, pathname]);

    return (
        <ClosableContext.Provider value={handleClose}>
            {children({ isOpen: !closing, onClose: handleClose })}
        </ClosableContext.Provider>
    );
};

const createViewServerRoute = (path: string) => ({
    path,
    element: (
        <RouteWrapperForModals>{(params) => <ViewServer {...params} />}</RouteWrapperForModals>
    ),
    children: [
        {
            index: true,
            element: <Navigate to="general" />,
        },
        {
            path: 'general',
            element: <GeneralInfo />,
        },
        {
            path: 'domains',
            element: <Domains />,
        },
        {
            path: 'clients',
            element: <Clients />,
        },
        {
            path: 'installation',
            element: <InstallationLog />,
        },
        {
            path: 'monitoring',
            element: <Monitoring />,
        },
    ],
});

export const routes: RouteObject[] = [
    {
        path: 'si',
        children: [
            {
                path: 'domains',
                element: <Navigate to="/websites" />,
            },
            {
                element: <PrivateRoutes />,
                children: [
                    {
                        path: 'servers/:serverId/login/:target',
                        element: <ServerLogin />,
                    },
                    {
                        path: 'servers/add-external',
                        element: <AddServerWithExternalRedirectDialog />,
                    },
                    {
                        element: <Layout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="servers" />,
                            },
                            {
                                path: 'servers',
                                element: <Servers />,
                                children: [
                                    {
                                        path: 'add/:step?',
                                        element: (
                                            <RouteWrapperForModals>
                                                {(params) => <AddingServerWizard {...params} />}
                                            </RouteWrapperForModals>
                                        ),
                                    },
                                    createViewServerRoute(':serverId'),
                                ],
                            },
                            {
                                path: 'clients',
                                element: <AllClients />,
                                children: [createViewServerRoute('server/:serverId')],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'start-plesk-trial',
                element: <StartPleskTrial />,
            },
        ],
    },
    {
        element: <PrivateRoutes />,
        children: [
            {
                path: 'websites',
                element: <AllDomains />,
                children: [createViewServerRoute('server/:serverId')],
            },
        ],
    },
];
