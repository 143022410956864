// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Link, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link as RouterLink } from 'react-router-dom';

export const PrivacyPolicy = () => {
    const translate = useTranslate('PrivacyPolicy');
    return (
        <p>
            <Text fontSize="sm" data-type="privacyPolicyText">
                {translate('privacyPolicyText', {
                    a: (chunk) => (
                        <Link component={RouterLink} to="/legal/privacy-policy" target="_blank">
                            {chunk}
                        </Link>
                    ),
                })}
            </Text>
        </p>
    );
};
