// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { resendTeamInvitation } from '@platform360/accounts/web/api/team';
import { AxiosError } from 'axios';
import useTeamInvitationsQuery from '@platform360/accounts/web/queries/useTeamInvitationsQuery';

type UseResendTeamInvitationsMutation = {
    onSuccess: () => void;
    onError: (error: AxiosError) => void;
};

const useResendTeamInvitationsMutation = ({
    onSuccess,
    onError,
}: UseResendTeamInvitationsMutation) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: resendTeamInvitation,
        onSuccess: async (updatedTeamInvitation) => {
            await queryClient.cancelQueries({
                queryKey: useTeamInvitationsQuery.getQueryKey(),
            });
            useTeamInvitationsQuery.setQueriesData(
                queryClient,
                useTeamInvitationsQuery.getQueryKey(),
                (cache) => {
                    if (!cache) {
                        return [];
                    }

                    return cache.map((teamInvitation) => {
                        if (teamInvitation.id === updatedTeamInvitation.id) {
                            return updatedTeamInvitation;
                        }

                        return teamInvitation;
                    });
                },
            );

            onSuccess();
        },
        onError,
        meta: {
            defaultErrorHandler: false,
        },
    });
};

export default useResendTeamInvitationsMutation;
