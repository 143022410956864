// Copyright 2024. WebPros International GmbH. All rights reserved.

import { MONTHLY, YEARLY } from '@platform360/licenses/web/constants/billingPeriod';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import Extension from '@platform360/licenses/web/units/extensions/types/Extension';
import {
    Extension as BackendExtension,
    GroupMap as BackendGroupMap,
} from '@platform360/licenses/web/api/ka/responseTypes/Upsells';

const inPeriod = (period: BillingPeriod) => (item: BackendExtension) =>
    Object.values(item.subProducts).some((sp) => sp.billingPeriod === period);

// writing a value to the map with checking the presence of a key
const setItemToMap =
    (item: Omit<Extension, 'minPrice'>, group: string) =>
    (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map: any,
    ) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!map.hasOwnProperty(group)) {
            map[group] = {};
        }
        map[group][item.catalogCode] = item;
    };

const transformItem = (item: BackendExtension): Omit<Extension, 'minPrice'> => ({
    catalogCode: item.extensionCatalogCode,
    link: item.descriptionLink,
    storeLink: item.onlineStoreLink,
    name: item.productName,
    subProducts: item.subProducts,
    periods: Array.from(
        new Set(Object.values(item.subProducts).map((product) => product.billingPeriod)),
    ),
});

/**
 * splitting data into monthly and yearly periods
 * @param data
 */
const transformUpsells = (data: BackendGroupMap) => {
    const monthly = {};
    const yearly = {};

    const isMonthly = inPeriod(MONTHLY);
    const isYearly = inPeriod(YEARLY);

    Object.entries(data).forEach(([group, items]) => {
        items.forEach((item) => {
            const writingTo = setItemToMap(transformItem(item), group);

            if (isMonthly(item)) {
                writingTo(monthly);
            }

            if (isYearly(item)) {
                writingTo(yearly);
            }
        });
    });

    return {
        monthly,
        yearly,
    };
};

export default transformUpsells;
