// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './DotsMenu.module.css';
import { Dropdown, Button, Menu } from '@plesk/ui-library';
import MenuItem from './MenuItem';
import useNineDotsMenu from './useNineDotsMenu';

const DotsMenu = () => {
    const items = useNineDotsMenu();

    return (
        <Dropdown
            menu={
                <Menu>
                    {items.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem key={index} {...item} />
                    ))}
                </Menu>
            }
            className={styles.root}
            data-type="Page--nineDotsMenu"
        >
            <Button icon="nine-dots" ghost />
        </Dropdown>
    );
};

export default DotsMenu;
