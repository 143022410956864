// Copyright 2024. WebPros International GmbH. All rights reserved.

import useForceSyncServers from '@platform360/server-inventory/web/mutations/useForceSyncServers';
import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';

type SyncButtonProps = {
    serverId: number;
};
const SyncButton = ({ serverId, ...props }: SyncButtonProps) => {
    const translate = useTranslate('ViewServer');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { mutate, loadingIds } = useForceSyncServers();

    const handleClick = () => {
        analyticsEvents.serverViewSyncClicked();
        mutate([serverId]);
    };

    return (
        <Button
            {...props}
            icon="refresh"
            state={loadingIds.includes(serverId) ? 'loading' : undefined}
            onClick={handleClick}
            data-type="sync-server-button"
        >
            {translate('sync')}
        </Button>
    );
};

export default SyncButton;
