// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Layout from '@platform360/web-installer/web/components/Layout';
import { longSurveyLink } from '@platform360/web-installer/web/constants';

import styles from './PleskAutoInstallationFAQ.module.css';
import { useScrollToAnchor } from '@platform360/web-installer/web/hooks/useScrollToAnchor';

const PleskAutoInstallationFAQ = () => {
    const translate = useTranslate('web-installer.AutoInstallationFAQ.plesk');

    // Hardcode header height at the moment not to deal with dynamic offset evaluation.
    const headerHeight = 40;
    useScrollToAnchor(headerHeight);

    return (
        <Layout showUnauthorizedHeader>
            <div className={styles.header}>{translate('header')}</div>
            <div className={styles.container}>
                <h1 className={styles.paragraphHeader} id="versions">
                    {translate('versionsAndComponents')}
                </h1>

                <ul>
                    <li>
                        <div>{translate('whatVersionIsInstalledTitle')}</div>
                        <p>
                            {translate('whatVersionIsInstalledText', {
                                link: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://docs.plesk.com/en-US/obsidian/deployment-guide/quick-start.76607/"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                            })}
                        </p>
                    </li>
                    <li>
                        <div>{translate('whatComponentsAreInstalledTitle')}</div>
                        <p>{translate('whatComponentsAreInstalledText')}</p>
                    </li>
                    <li>
                        <div>{translate('canISelectComponentsTitle')}</div>
                        <p>{translate('canISelectComponentsText')}</p>
                    </li>
                    <li>
                        <div>{translate('doesInstalledPleskHaveLicenseTitle')}</div>
                        <p>{translate('doesInstalledPleskHaveLicenseText')}</p>
                    </li>
                </ul>

                <h1 className={styles.paragraphHeader} id="issues">
                    {translate('knownIssues')}
                </h1>
                <ul>
                    <li>
                        <div>{translate('supportedOSsTitle')}</div>
                        <p>
                            {translate('supportedOSsText', {
                                a: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://docs.plesk.com/release-notes/obsidian/software-requirements/#s2-1"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                                link: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://docs.plesk.com/en-US/obsidian/deployment-guide/plesk-installation-and-upgrade-on-single-server/1click-plesk-installation/installing-plesk-for-windows-in-one-click.76448/"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                            })}
                        </p>
                    </li>
                    <li>
                        <div>{translate('usePrivateKeyTitle')}</div>
                        <p>{translate('usePrivateKeyText')}</p>
                    </li>
                    <li>
                        <div>{translate('isItSafeToSubmitPasswordTitle')}</div>
                        <p>{translate('isItSafeToSubmitPasswordText')}</p>
                    </li>
                </ul>
                <div className={styles.survey}>
                    <Icon name="list-check-mark" intent="info" size="32" />
                    <div>
                        {translate('helpUsMakeWebInstallerBetter', {
                            a: (chunk) => (
                                <a href={longSurveyLink.plesk} rel="noreferrer" target="_blank">
                                    {chunk}
                                </a>
                            ),
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PleskAutoInstallationFAQ;
