// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import {
    LocaleCodeStateContext,
    LocaleCodeDispatchContext,
} from './components/LocaleProvider/LocaleProvider';

const useLocale = () => {
    const localeCode = useContext(LocaleCodeStateContext);
    const setLocaleCode = useContext(LocaleCodeDispatchContext);

    if (setLocaleCode === undefined) {
        throw new Error('useLocale must be used within a LocaleProvider');
    }

    return [localeCode, setLocaleCode] as const;
};

export default useLocale;
