// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { PrimitiveType, FormatXMLElementFn } from 'intl-messageformat';

type TranslateProps = {
    namespace?: string;
    content?: string;
    params?: Record<string, PrimitiveType | FormatXMLElementFn<ReactNode>>;
    fallback?: string;
};

/**
 * @deprecated Use `useTranslate` hook instead
 */
export const Translate = ({ namespace, content, params, fallback, ...props }: TranslateProps) => {
    const translate = useTranslate(namespace);
    return <span {...props}>{translate(content || '', params, fallback)}</span>;
};
