// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AuxiliaryActions, Button, Grid, GridCol, Icon, Label, Popover } from '@plesk/ui-library';
import PropTypes from 'prop-types';
import {
    active,
    expired,
    hasBilling,
    hasReports,
    hide,
    own,
    suspended,
    terminated,
    trial,
} from '@platform360/licenses/web/helpers/utils';
import FeatureList from '@platform360/licenses/web/Keys/FeatureList';
import MoreDetails from '@platform360/licenses/web/Keys/MoreDetails';
import Cost from '@platform360/licenses/web/components/Cost';
import BillingInterval from '@platform360/licenses/web/components/BillingInterval';
import { Link } from 'react-router-dom';
import styles from './style.module.css';
import { licensesUri } from '@platform360/licenses/web/helpers';
import { DEA } from '@platform360/licenses/web/api/ka/const/cleverbridgeItemStatus';
import { ReactivateSubscription } from '@platform360/licenses/web/units/reactivateSubscription';
import { Properties } from '@platform360/licenses/web/units/adjust';
import { useDispatch } from 'react-redux';
import { beginAdjustByKey } from '@platform360/licenses/web/actions';
import { CancelKeySubscription } from '@platform360/licenses/web/units/cancelation';
import NonBreakingSpace from '@platform360/licenses/web/components/HTMLEntities/NonBreakingSpace';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const keyInfoIntent = (keyInfo) => {
    if (active(keyInfo)) {
        return 'success';
    }

    return terminated(keyInfo) ? 'danger' : 'warning';
};

const Content = ({ keyInfo }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const analyticsEvents = useLicensesAnalyticsEvents();

    const handleUpgradeClick = () => {
        analyticsEvents.licensesItemUpgradeClicked();
    };

    const handleCancelKeySubscriptionConfirm = () => {
        analyticsEvents.licensesItemUnsubscribeSubmitClicked();
    };

    const handleAdjustPropertiesClick = () => {
        analyticsEvents.licensesItemAdjustClicked();
        dispatch(beginAdjustByKey(keyInfo.id));
    };

    return (
        <Grid className={styles.mediaContent} xs={4} gap="xs">
            <GridCol xs={3}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {own(keyInfo) ? (
                    // eslint-disable-next-line no-nested-ternary
                    terminated(keyInfo) ? (
                        <TerminatedLicenseDescriptionCol keyInfo={keyInfo} />
                    ) : // eslint-disable-next-line no-nested-ternary
                    trial(keyInfo) ? (
                        <TrialLicenseDescriptionCol keyInfo={keyInfo} />
                    ) : hasBilling(keyInfo) ? (
                        <RenewalCol keyInfo={keyInfo} />
                    ) : (
                        <PartnerLicenseDescriptionCol />
                    )
                ) : (
                    <ForeignLicenseDescriptionCol />
                )}
            </GridCol>
            <GridCol xs={3}>
                {own(keyInfo) &&
                    keyInfo.propertiesUpgradeInfo &&
                    keyInfo.propertiesUpgradeInfo.length > 0 && (
                        <Properties license={keyInfo} onClick={handleAdjustPropertiesClick} />
                    )}
            </GridCol>
            <GridCol xs={3}>
                {own(keyInfo) && hasBilling(keyInfo) && !terminated(keyInfo) && (
                    <PaymentHistoryCol keyInfo={keyInfo} />
                )}
                <ReactivateSubscription license={keyInfo} />
                <CancelKeySubscription
                    license={keyInfo}
                    onConfirm={handleCancelKeySubscriptionConfirm}
                />
            </GridCol>
            <GridCol xs={3}>
                {/** @todo make LicenseStatus component */}
                <div>{translate('licenseStatus')}</div>
                <Label
                    intent={keyInfoIntent(keyInfo)}
                    className={styles.status}
                    data-type="Keys--licenseStatus"
                >
                    {active(keyInfo) ? 'active' : keyInfo.status}
                </Label>
                <div className={styles.note}>
                    {terminated(keyInfo) && translate('sinceNote', { date: keyInfo.terminateDate })}
                    {suspended(keyInfo) && translate('sinceNote', { date: keyInfo.suspendDate })}
                    {expired(keyInfo) && translate('sinceNote', { date: keyInfo.expirationDate })}
                    {active(keyInfo) &&
                        trial(keyInfo) &&
                        translate('expiresNote', { date: keyInfo.expirationDate })}
                </div>
            </GridCol>
            {!terminated(keyInfo) && (
                <UpgradeCol keyInfo={keyInfo} onUpgradeClick={handleUpgradeClick} />
            )}
            <GoToPleskCol keyInfo={keyInfo} />
        </Grid>
    );
};

Content.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

const RenewalCol = ({ keyInfo }) => {
    const translate = useTranslate();

    return (
        <>
            <div>{translate('renewalCost')}</div>
            <div>
                <BillingInterval period={keyInfo.billingPeriod}>
                    <b>
                        <Cost value={keyInfo.renewalCostGross} currency={keyInfo.renewalCurrency} />
                    </b>
                </BillingInterval>
            </div>
        </>
    );
};

RenewalCol.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

const ForeignLicenseDescriptionCol = () => {
    const translate = useTranslate();

    return (
        <>
            {translate('keyForeignCart')}{' '}
            <Popover
                intent="info"
                target={<Icon className={styles.infoIcon} name="info-circle-filled" size="16" />}
                placement="right"
            >
                <div>
                    {translate('keyForeignDescriptionTitle')}
                    <ul>
                        <li>{translate('keyForeignDescriptionHosting')}</li>
                        <li>{translate('keyForeignDescriptionWrongEmail')}</li>
                    </ul>
                </div>
            </Popover>
        </>
    );
};

const TrialLicenseDescriptionCol = ({ keyInfo }) => {
    const translate = useTranslate();

    return translate('keyTrialDescription', {
        // todo move link to backend in scope of standalone WPT
        link: (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                    keyInfo.code === 'solus-io'
                        ? 'https://www.solus.io/prices/'
                        : 'https://www.plesk.com/pricing/'
                }
            >
                {translate('keyTrialDescriptionLink')}
            </a>
        ),
    });
};

TrialLicenseDescriptionCol.propTypes = {
    keyInfo: PropTypes.object.isRequired,
};

const TerminatedLicenseDescriptionCol = ({ keyInfo }) => {
    const translate = useTranslate();

    return (
        <>
            {translate('keyTerminatedCart')}{' '}
            <Popover
                intent="info"
                target={<Icon className={styles.infoIcon} name="info-circle-filled" size="16" />}
                placement="bottom"
            >
                <div>
                    {translate('keyTerminatedDescription')}
                    {hasBilling(keyInfo) && translate('keyTerminatedDescriptionRetail')}
                </div>
            </Popover>
        </>
    );
};

TerminatedLicenseDescriptionCol.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

const PartnerLicenseDescriptionCol = () => {
    const translate = useTranslate();

    return (
        <>
            {translate('keyPartnerCart')}{' '}
            <Popover
                intent="info"
                target={<Icon className={styles.infoIcon} name="info-circle-filled" size="16" />}
                placement="bottom-left"
            >
                <div>{translate('keyPartnerDescription')}</div>
            </Popover>
        </>
    );
};

const PaymentHistoryCol = ({ keyInfo }) => {
    const translate = useTranslate();

    return (
        <div>
            <div>
                {(DEA === keyInfo.cbItemStatusId && translate('canceledSubscriptionDate')) ||
                    translate('nextBillingDate')}
            </div>
            <div>
                <b>{keyInfo.nextBillingDate}</b>
            </div>
            {/* TODO that button hided now, because such function will be available in future*/}
            {hide() && (
                <AuxiliaryActions separator=", ">
                    <a>{translate('paymentHistory')}</a>
                </AuxiliaryActions>
            )}
        </div>
    );
};

PaymentHistoryCol.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

const UpgradeCol = ({ keyInfo, onUpgradeClick }) => {
    const translate = useTranslate();

    return (
        <GridCol className={styles.secondRow} xs={4}>
            {keyInfo.hasUpgrades && (
                <Button
                    icon="arrow-up-tray"
                    intent="primary"
                    component={Link}
                    to={`${licensesUri}/${keyInfo.id}/upgrade`}
                    onClick={onUpgradeClick}
                >
                    {translate('upgradeButton')}
                </Button>
            )}
            <FeatureList keyInfo={keyInfo} />
        </GridCol>
    );
};

UpgradeCol.propTypes = {
    keyInfo: PropTypes.any.isRequired,
    onUpgradeClick: PropTypes.func.isRequired,
};

const GoToPleskCol = ({ keyInfo }) => {
    const translate = useTranslate();

    return (
        <GridCol className={styles.secondRow} xs={6}>
            {keyInfo.isPlesk && hasReports(keyInfo) && !terminated(keyInfo) && (
                <div>
                    {/* TODO that button hided now, because such function will be available in future*/}
                    {own(keyInfo) && hide() && (
                        <Button icon="arrow-diagonal-out">{translate('toPlesk')}</Button>
                    )}
                    <div className={styles.secondRowInfoBlock}>
                        {translate('plesk')} {keyInfo.reportedVersion}
                        <NonBreakingSpace />
                        {translate('on')}{' '}
                        {keyInfo.ips === null
                            ? ''
                            : (keyInfo.ips.length <= 2 && keyInfo.ips.join(', ')) ||
                              (keyInfo.ips.length > 2 && (
                                  <span>
                                      {keyInfo.ips[0]}
                                      {' and '}
                                      {keyInfo.ips.length - 1} {translate('addresses')}
                                      <div>
                                          <MoreDetails
                                              keyInfo={keyInfo}
                                              target={<a>{'[show details]'}</a>}
                                              placement="right"
                                          />
                                      </div>
                                  </span>
                              ))}
                    </div>
                </div>
            )}
        </GridCol>
    );
};

GoToPleskCol.propTypes = {
    keyInfo: PropTypes.any.isRequired,
};

export default Content;
