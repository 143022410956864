// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useServerViewMonitoring } from '@platform360/monitoring/web/components/ServerViewMonitoring';
import { ServerViewModel } from '@platform360/server-inventory/web/viewModels/ServerViewModel';
import { Navigate, useParams } from 'react-router-dom';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';
import { Server } from '@platform360/server-inventory/web/types';

type MonitoringWrapperProps = {
    server: Server;
};

const MonitoringWrapper = ({ server }: MonitoringWrapperProps) => {
    const monitoringPage = useServerViewMonitoring(server, ServerViewModel.isReady(server));

    if (!monitoringPage) {
        return <Navigate to=".." />;
    }

    return monitoringPage.element;
};

const Monitoring = () => {
    const { serverId } = useParams<{ serverId: string }>();
    const { data } = useServerQuery({
        // Parent ViewServer component checks serverId is valid
        variables: { id: Number(serverId) },
    });

    if (!data) {
        return null;
    }

    return <MonitoringWrapper server={data} />;
};

export default Monitoring;
