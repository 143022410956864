// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import ConnectServersButton from '@platform360/security-dashboard/web/components/ConnectServersButton';
import RemoveServersPopover from '@platform360/security-dashboard/web/components/Servers/RemoveServersButton';
import { Server } from '@platform360/security-dashboard/web/types';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import FindNewSitesButton from './FindNewSitesButton';
import UpdateButton from './UpdateButton';
import ProtectButton from './ProtectButton';
import RefreshButton from './RefreshButton';
import {
    ToolbarExpander,
    SearchBar,
    ToolbarGroup,
    Toolbar as UILibToolbar,
    Button,
    Badge,
} from '@plesk/ui-library';
import { SitesHealthFilter } from '@platform360/security-dashboard/web/api/servers';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    LabelsPopover,
    AssignRemoveLabelsOptions,
} from '@platform360/security-dashboard/web/components/LabelsPopover';
import { ServerListOperation } from '@platform360/security-dashboard/web/components/Servers/constants';
import SettingsButton from './SettingsButton';

type ToolbarProps = {
    servers: Server[];
    selection: number[];
    searchValue?: string;
    onSearch?: (value: string) => void;
    handleAssignServerLabels: (data: AssignRemoveLabelsOptions) => void;
    handleRemoveServerLabels: (data: AssignRemoveLabelsOptions) => void;
    handleUnprocessableInstallations: (serversIds: number[]) => void;
    onServersRemoved: (removedServersCount: number) => void;
    onTaskStarting: (serversIds: number[], operation: ServerListOperation) => void;
    onTaskStarted: (serversIds: number[], operation: ServerListOperation) => void;
    isSidebarOpen: boolean;
    setSidebarOpen: (newValue: boolean) => void;
    sitesHealthFilter: SitesHealthFilter;
    labelIdsFilter: number[];
};

export const Toolbar = ({
    searchValue,
    onSearch,
    onServersRemoved,
    servers,
    selection,
    onTaskStarted,
    onTaskStarting,
    handleAssignServerLabels,
    handleRemoveServerLabels,
    handleUnprocessableInstallations,
    sitesHealthFilter,
    labelIdsFilter,
    setSidebarOpen,
    isSidebarOpen,
}: ToolbarProps) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar');
    const selectedServers = servers.filter(({ id }) => selection.includes(id));
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const [showLabelsPopover, setShowLabelsPopover] = useState(false);
    const handleLabelsPopoverShown = () => {
        setShowLabelsPopover(true);
        analyticsEvents.wpLabelsPopoverShown('SERVER_TAB');
    };
    const handleLabelsPopoverClosed = () => {
        setShowLabelsPopover(false);
        analyticsEvents.wpLabelsPopoverClosed('SERVER_TAB');
    };

    const selectionWithLabels = servers
        .filter((s) => selection.includes(s.id))
        .map((s) => ({
            id: s.id,
            labels: s.labels.map((s) => s.id),
        }));

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('add')}>
                <ConnectServersButton icon={{ name: 'plus', size: '16' }} />
            </ToolbarGroup>
            <ToolbarGroup title={translate('manage')}>
                <ToolbarGroup title={translate('manage')}>
                    <ProtectButton
                        intent="primary"
                        icon={{ name: 'shield', size: '16' }}
                        selectedServers={selectedServers}
                        onTaskStarting={onTaskStarting}
                        onTaskStarted={onTaskStarted}
                        onConfirm={(serversIds, ignoreDoNotProtect, doNotProtectCount) => {
                            handleUnprocessableInstallations(serversIds);
                            analyticsEvents.wpMltPatchServerConfirmationClick(serversIds.length, {
                                ignoreDoNotProtect,
                                doNotProtectCount,
                                protectionDisabledTextShown: doNotProtectCount !== 0,
                            });
                        }}
                        onConfirmationShown={() =>
                            analyticsEvents.wpMltPatchServerClick(selectedServers.length)
                        }
                    />
                    <UpdateButton
                        selectedServers={selectedServers}
                        onTaskStarting={(serversIds, operation) => {
                            onTaskStarting(serversIds, operation);
                            analyticsEvents.wpMltUpdateServerConfirmationClick(serversIds.length);
                            handleUnprocessableInstallations(serversIds);
                        }}
                        onTaskStarted={onTaskStarted}
                        onConfirmationShown={() =>
                            analyticsEvents.wpMltUpdateServerClick(selectedServers.length)
                        }
                    />
                    <LabelsPopover
                        visible={showLabelsPopover}
                        selectionWithLabels={selectionWithLabels}
                        placement="bottom-right"
                        onAssign={handleAssignServerLabels}
                        onRemove={handleRemoveServerLabels}
                        onClose={handleLabelsPopoverClosed}
                    >
                        <Button onClick={handleLabelsPopoverShown}>
                            {translate('LabelButton.buttonText')}
                        </Button>
                    </LabelsPopover>
                    <FindNewSitesButton
                        selectedServers={selectedServers}
                        onTaskStarting={onTaskStarting}
                        onTaskStarted={onTaskStarted}
                    />
                </ToolbarGroup>
                <ToolbarGroup title="" groupable={false}>
                    <RefreshButton
                        selectedServers={selectedServers}
                        onTaskStarting={onTaskStarting}
                        onTaskStarted={onTaskStarted}
                    />
                    <SettingsButton
                        selectedServers={selectedServers}
                        onSave={handleUnprocessableInstallations}
                    />
                    <RemoveServersPopover
                        servers={selectedServers}
                        onSuccess={onServersRemoved}
                        target={({ isLoading }) => (
                            <NoSelectionPopover
                                selectionCount={selectedServers.length}
                                target={
                                    <Button
                                        state={isLoading ? 'loading' : undefined}
                                        icon="chain-broken"
                                        tooltip={translate('remove')}
                                    />
                                }
                            >
                                {translate('noSelectedServers')}
                            </NoSelectionPopover>
                        )}
                        onConfirmationShown={() =>
                            analyticsEvents.wpMltDisconnectServerClick(selectedServers.length)
                        }
                        onConfirm={() =>
                            analyticsEvents.wpMltDisconnectServerConfirmationClick(
                                selectedServers.length,
                            )
                        }
                    />
                </ToolbarGroup>
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('groupFilterMenu')}>
                <Badge
                    hidden={sitesHealthFilter === 'all' && labelIdsFilter.length === 0}
                    intent="info"
                >
                    <Button
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                        icon={sitesHealthFilter === 'all' ? 'filter' : 'filter-check-mark'}
                        data-type="filters"
                    />
                </Badge>
            </ToolbarGroup>
            <ToolbarGroup title={translate('groupSearch')}>
                <SearchBar
                    inputProps={{
                        placeholder: translate('searchPlaceholder'),
                        value: searchValue,
                    }}
                    onTyping={onSearch}
                    onSearch={(value: string) => onSearch?.(value)}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
