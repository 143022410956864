// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        linkedEmailNotExists: 'Cette adresse e-mail est déjà enregistrée.',
        linkEmailMinimalInterval: 'Les notifications d\'action pour les adresses e-mail associées ne peuvent pas être envoyées trop souvent.',
        linkEmailUnknownError: 'Une erreur s\'est produite. Vérifiez votre connexion Internet ou essayez à nouveau plus tard.',
    },
    ActivateLicense: {
        monitoring: {
            CancelConfirmation: {
                title: 'Confirmer l\'annulation de l\'activation',
                description: 'Vous êtes sur le point d\'annuler l\'activation de la licence : {product}. Vous pourrez activer la licence à tout moment ultérieurement en cliquant à nouveau sur le lien d\'activation. Si vous avez acheté la licence pour un autre compte, connectez-vous à ce compte et cliquez sur le lien d\'activation.',
                cancelButton: 'Annuler',
                continueButton: 'Poursuivre l\'activation',
            },
            MonitoringActivateLicense: {
                title: 'Confirmer l\'activation de la licence de Monitoring',
                description: 'Veuillez confirmer l\'activation de la licence <b>{product}</b> pour votre compte 360 <mailto>{userEmail}</mailto>. La licence peut être activée pour un compte 360 uniquement.',
                fixableErrorDescription: 'Un problème est survenu pendant l\'activation du compte Monitoring. Veuillez réessayer ou envoyer une demande d\'assistance.',
                nonFixableErrorDescription: 'Un problème est survenu pendant l\'activation de la licence. Veuillez contacter le fournisseur de licence ou envoyer une demande d\'assistance.',
                loaderText: 'Votre compte 360 Monitoring est en cours de configuration.',
                redirectText: 'Redirection vers 360 Monitoring',
                confirmButton: 'Confirmer',
                cancelButton: 'Annuler',
                retryButton: 'Réessayer',
                gotItButton: 'J\'ai compris.',
                errors: {
                    linkError: 'Le lien d\'activation n\'est pas valide.',
                    unknownError: 'Erreur interne du service d\'activation.',
                },
            },
        },
        NotFoundActivateLicense: {
            title: 'Erreur lors de l\'activation de la licence',
            description: 'Le lien d\'activation est incorrect. Veuillez contacter le fournisseur de licence ou envoyer une demande d\'assistance.',
            gotIt: 'J\'ai compris.',
        },
    },
};