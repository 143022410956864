// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMemo, useState } from 'react';
import { ListProps } from '@platform360/libs/shared-web/components/List';
import { Link, Outlet } from 'react-router-dom';
import { VersionStatuses } from '@platform360/server-inventory/shared/version-status';
import { Products } from '@platform360/server-inventory/shared/product-type';
import { productName } from '@platform360/server-inventory/web/helpers/productName';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import Onboarding from '@platform360/server-inventory/web/components/Servers/Onboarding';
import { Server } from '@platform360/server-inventory/web/types';
import FeedbackButton from '@platform360/server-inventory/web/components/Servers/FeedbackButton';
import ProductIcon from '@platform360/server-inventory/web/components/Servers/ProductIcon';
import useServersQuery from '@platform360/server-inventory/web/queries/useServersQuery';
import useForceSyncServers from '@platform360/server-inventory/web/mutations/useForceSyncServers';
import ServerStatus from './ServerStatus';
import Toolbar from './Toolbar';
import { useMonitoringColumn } from '@platform360/monitoring/web/components/MonitoringColumn';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import DataList from '@platform360/libs/shared-web/components/DataList';
import { ListAction, ListActions, Text } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useQueryClient } from '@tanstack/react-query';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import punycode from 'punycode/';
import styles from './Servers.module.css';
import { ServerViewModel } from '@platform360/server-inventory/web/viewModels/ServerViewModel';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';
import { UpdateServerTriggers } from '@platform360/server-inventory/web/helpers/analytics/ServerInventoryAnalyticsEvents';

// 1 minute.
const REFETCH_INTERVAL = 60000;

type ServerWithDisabled = Server & { disabled: boolean };

const Servers = () => {
    const translate = useTranslate('Servers');
    const [selection, setSelection] = useState<number[]>([]);
    const queryClient = useQueryClient();
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const search = useSearch();
    const pagination = usePagination('si-all-servers');
    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
        refetch,
    } = useServersQuery({
        variables: {
            filter: search.debouncedValue,
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
        },
        refetchInterval: REFETCH_INTERVAL,
        placeholderData: (prev) => prev,
    });
    const listData = useMemo(() => {
        if (!data) {
            return data;
        }

        return {
            ...data,
            data: data.data.map((server) => ({
                ...server,
                // disable selection for the row in List
                disabled: ServerViewModel.isInstallationRunning(server),
            })),
        };
    }, [data]);

    const monitoringColumn = useMonitoringColumn({
        serverGuids:
            listData?.data
                .filter((server) => ServerViewModel.isReady(server))
                .map(({ guid }) => guid) || [],
        refetchInterval: REFETCH_INTERVAL,
    });

    const { mutate: forceSyncServers, loadingIds: forceSyncServersIds } = useForceSyncServers();

    useEffectOnce(() => {
        analyticsEvents.pageOpen();
    });

    const columns: ListProps<ServerWithDisabled>['columns'] = [
        {
            key: 'name',
            type: 'title',
            title: translate('serverNameColumn'),
            render: (server) => (
                <Text bold>
                    <Link to={`/si/servers/${server.id}`} data-type="view-server-link">
                        {punycode.toUnicode(server.address.hostname)}
                    </Link>
                </Text>
            ),
        },
        {
            key: 'product',
            title: translate('productColumn'),
            render: (server) => <ProductIcon product={server.product} />,
        },
        {
            key: 'state',
            title: translate('stateColumn'),
            width: '200px',
            render: (server) => (
                <ServerStatus
                    server={server}
                    to={`/si/servers/${server.id}`}
                    className={styles.status}
                />
            ),
        },
        {
            ...monitoringColumn,
            render: (server: Server) => {
                if (!ServerViewModel.isReady(server)) {
                    return undefined;
                }

                return monitoringColumn.render(server);
            },
        },
        {
            key: 'actions',
            type: 'actions',
            render: (server) => {
                const { id, product } = server;
                if (!ServerViewModel.isReady(server)) {
                    return undefined;
                }

                const showServerUpdateAction =
                    server.product === Products.plesk &&
                    server.productVersion?.versionStatus !== VersionStatuses.latest;
                const listAction = showServerUpdateAction ? (
                    <ListAction
                        icon="arrow-up-circle"
                        primary
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/si/servers/${id}/login/${LoginTargets.updates}`}
                        data-type="updates-link"
                        onClick={() => {
                            analyticsEvents.serverItemUpdateServerClicked({
                                product,
                                address: server.address.toString(),
                                trigger: UpdateServerTriggers.serverListItemUpdateServerButton,
                            });
                        }}
                    >
                        {translate('updateServer', { product: productName[server.product] })}
                    </ListAction>
                ) : undefined;

                return (
                    <ListActions>
                        {listAction}
                        <ListAction
                            icon="panel-settings"
                            primary
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`/si/servers/${id}/login/${LoginTargets.home}`}
                            data-type="login-link"
                            onClick={() => {
                                analyticsEvents.serverItemGoToServerClicked(product);
                            }}
                        >
                            {translate(`login.${product}`)}
                        </ListAction>
                        <ListAction
                            icon="refresh"
                            primary
                            disabled={forceSyncServersIds.includes(id)}
                            onClick={() => {
                                analyticsEvents.serverItemSyncClicked();
                                forceSyncServers([id]);
                            }}
                            data-type="force-sync-action"
                        >
                            {translate('sync')}
                        </ListAction>
                    </ListActions>
                );
            },
        },
    ];

    const handleServersDisconnectConfirm = () => {
        analyticsEvents.serverListDisconnectClicked();
    };

    const handleServersDisconnected = (disconnectedServersCount: number) => {
        const { onSelect, current, itemsPerPage } = pagination;

        if (!listData) {
            return;
        }

        const totalPages = Math.ceil(listData.totalCount / itemsPerPage);

        if (
            disconnectedServersCount >= listData.data.length &&
            current > 1 &&
            current >= totalPages
        ) {
            // Reset page to the previous one in case if all servers
            // from the last page were deleted.
            onSelect(current - 1);
            return;
        }

        // Removes servers pages query cache and refetch the current page data.
        queryClient.removeQueries({
            queryKey: useServersQuery.getQueryKey(),
        });
        void refetch();
    };

    const handleServersSync = (serverIds: number[]) => {
        analyticsEvents.serverListSyncClicked();
        forceSyncServers(serverIds);
    };

    return (
        <>
            <DataList<ServerWithDisabled>
                toolbar={
                    <Toolbar
                        onServersDisconnectConfirm={handleServersDisconnectConfirm}
                        onServersDisconnected={handleServersDisconnected}
                        selection={selection}
                        onServersSync={handleServersSync}
                        servers={listData?.data || []}
                    />
                }
                search={search}
                rowKey="id"
                data={listData?.data}
                loading={isLoading}
                isPreviousData={isPreviousData}
                loadingRows={forceSyncServersIds}
                pagination={pagination}
                emptyView={<Onboarding />}
                selection={selection}
                onSelectionChange={setSelection}
                totalRows={listData?.totalCount}
                columns={columns}
                data-type="servers-list"
            />
            {listData && listData.totalCount > 0 ? <FeedbackButton /> : null}
            <Outlet />
        </>
    );
};

export default Servers;
