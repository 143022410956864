// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Status } from '@plesk/ui-library';

export const StatusSuccess = ({ children }: { children: ReactNode }) => (
    <Status icon={{ name: 'check-mark-circle-filled', size: '24' }} intent="success">
        {children}
    </Status>
);
export const StatusInfo = ({ children }: { children: ReactNode }) => (
    <Status icon={{ name: 'info-circle-filled', size: '24' }} intent="info">
        {children}
    </Status>
);
export const StatusDanger = ({ children }: { children: ReactNode }) => (
    <Status icon={{ name: 'triangle-exclamation-mark-filled', size: '24' }} intent="danger">
        {children}
    </Status>
);

export const StatusWarning = ({ children }: { children: ReactNode }) => (
    <Status icon={{ name: 'triangle-exclamation-mark-filled', size: '24' }} intent="warning">
        {children}
    </Status>
);
