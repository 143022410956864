// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect } from 'react';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { location } from '@platform360/libs/shared-web/helpers/location';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useMonitoringSetup } from '@platform360/monitoring/web/mutations';
import { useNavigate } from 'react-router-dom';
import MonitoringPromoActivation, {
    MonitoringPromoActivationState,
} from '@platform360/monitoring/web/components/MonitoringPromoActivation/MonitoringPromoActivation';

const MonitoringPromoActivationContainer = () => {
    const {
        monitoring: { baseUrl: monitoring360url },
    } = useConfig();
    const { monitoring } = useCurrentUser();
    const navigate = useNavigate();
    const isMonitoringActive = monitoring?.userId !== undefined;

    const {
        mutate: setupMonitoringTrial,
        isSuccess,
        isError,
    } = useMonitoringSetup({
        scenario: 'monitoring-promo',
        onSuccess: () => {
            location.assign(monitoring360url);
        },
        defaultErrorHandler: false,
    });

    useEffect(() => {
        if (isMonitoringActive) {
            location.assign(monitoring360url);
        } else {
            setupMonitoringTrial();
        }
    }, [isMonitoringActive, monitoring360url, setupMonitoringTrial]);

    if (isMonitoringActive) {
        return null;
    }

    const redirectHomePage = () => {
        navigate('/');
    };

    const getState = (): MonitoringPromoActivationState => {
        if (isSuccess) {
            return 'succeed';
        }

        if (isError) {
            return 'failed';
        }

        return 'in-progress';
    };

    return (
        <MonitoringPromoActivation
            state={getState()}
            onCancel={redirectHomePage}
            onRetry={setupMonitoringTrial}
        />
    );
};

export default MonitoringPromoActivationContainer;
