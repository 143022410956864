// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    PlatformHomePage: {
        title: 'Die Tools 360',
    },
    WidgetsList: {
        serverInventory: {
            title: 'Serverinventar',
            description: 'Sie erhalten die Übersicht über alle registrierten Instanzen und brauchen dazu nur Anmeldedaten für ein einziges Tool',
        },
        licenseManagement: {
            title: 'Lizenzverwaltung',
            description: 'Sie können alle Ihre Lizenzen für Plesk sowie Erweiterungen und die Tools von Plesk 360 hier erwerben und verwalten.',
        },
        feedback: {
            title: 'Feedback geben',
            description: 'Rückmeldungen helfen uns bei der weiteren Entwicklung des Produkts',
        },
        websiteInventory: {
            title: 'Websiteinventar',
            description: 'Alle Ihre Websites im Überblick anzeigen und verwalten',
        },
    },
    Carousel: {
        monitoring: {
            title: 'Überwachen Sie Ihre Server und Websites über ein einziges Dashboard',
            description: 'Zentralisierte Lösung, mit der die Leistung überwacht und Ausfälle verhindert werden können. Sie umfasst konfigurierbare Dashboards, personalisierte Warnungen, minütliche Scans, einen Netzwerkverlauf und vieles mehr.',
            primaryButton: '14 Tage kostenlos testen',
            secondaryButton: 'Weitere Informationen',
        },
        licenses: {
            title: 'Lizenzen verwalten',
            description: 'Sie können alle Ihre Lizenzen für Plesk sowie Erweiterungen und die Tools von Plesk 360 an einer zentralen Stelle erwerben, anpassen und deaktivieren.',
            primaryButton: 'Jetzt starten',
            secondaryButton: 'Weitere Informationen',
        },
        serverInventory: {
            title: 'Sie erhalten die Übersicht über alle registrierten Instanzen und brauchen dazu nur Anmeldedaten für ein einziges Tool',
            description: 'Verknüpfen Sie alle Ihre Plesk-Server mit dem Serverinventar, um eine vollständige Liste Ihrer Websites zu erstellen und mit nur einem Klick auf sie alle zuzugreifen.',
            primaryButton: 'Server verbinden',
            secondaryButton: 'Weitere Informationen',
        },
        websiteInventory: {
            title: 'Alle Ihre Websites und Domains in einer übersichtlichen Liste',
            description: 'Ihre Websites aufrufen, suchen und filtern und mit nur einem Klick verwalten',
            primaryButton: 'Websites anzeigen',
            secondaryButton: 'Weitere Informationen',
        },
    },
};