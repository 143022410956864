// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState, ReactNode, useMemo, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Panel, FormFieldText, Form, FormFieldCheckbox, Heading } from '@plesk/ui-library';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useSignUp from '@platform360/web-installer/web/mutations/useSignUp';
import useGetDpaQuery from '@platform360/web-installer/web/queries/useGetDpaQuery';
import Layout from '@platform360/web-installer/web/components/Layout';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';

import styles from './EmailConfirmationPage.module.css';
import Loading from '@platform360/libs/shared-web/components/Loading';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useWebInstallerAnalyticsEvents } from '@platform360/web-installer/web/helpers/analytics';
import { useWebInstallerProduct } from '@platform360/web-installer/web/hooks/useWebInstallerProduct';

import WebInstallerBottomLinks from '@platform360/web-installer/web/components/WebInstallerBottomLinks';
import ControlPanelLogo from '@platform360/web-installer/web/components/ControlPanelLogo';
import InfoSection from '@platform360/web-installer/web/components/EmailConfirmationPage/InfoSection';
import { ProductTypesTranslations } from '@platform360/web-installer/web/constants';
import { WebInstallerProducts } from '@platform360/web-installer/shared/web-installer-product-type';
import { Brandings } from '@platform360/libs/shared-web/types';

type FormValues = {
    email: string;
    agree: boolean;
};

const brandings = {
    [WebInstallerProducts.plesk]: Brandings.getPlesk,
    [WebInstallerProducts.cpanel]: Brandings.getCpanel,
} as const;

const EmailConfirmationPage = () => {
    const translate = useTranslate('web-installer.EmailConfirmationPage');
    const translateValidationErrors = useTranslateValidationErrors();
    const analyticsEvents = useWebInstallerAnalyticsEvents();
    const { platform } = useConfig();

    const productType = useWebInstallerProduct();
    const productTypeName = ProductTypesTranslations[productType];

    const [searchParams] = useSearchParams();
    const installId = searchParams.get('installId');
    const authChecked = searchParams.get('authChecked');

    const { isAuthorized, login } = useAuth();
    const { email } = useCurrentUser();

    const [formValues, setFormValues] = useState<FormValues>({
        email: email || '',
        agree: false,
    });

    const navigate = useNavigate();
    const { data: dpa, isLoading: isDpaLoading } = useGetDpaQuery();

    useEffectOnce(() => {
        analyticsEvents.emailConfirmationPageShown();
    });

    useEffect(() => {
        if (isDpaLoading) {
            return;
        }

        if (isAuthorized && dpa?.dpaSigned) {
            // User was authorized on auth0 side and DPA is signed.
            // Go to Server Installation page to start installation.
            navigate(`/?installId=${installId}`);
            return;
        }

        if (authChecked === 'true') {
            // User is not authorized on Auth0 side or DPA is not signed.
            // Continue with email confirmation and legal agreements.
            return;
        }

        // Check if user is authorized on Auth0 side.
        void login({
            prompt: 'none',
            redirectUrl: `/web-installer/email-confirmation?installId=${installId}&authChecked=true`,
        });
    }, [authChecked, installId, isAuthorized, isDpaLoading, dpa, login, navigate]);

    const {
        isPending: isLoading,
        error,
        mutate: submitForm,
    } = useSignUp({
        onSuccess: () => {
            analyticsEvents.emailSubmitSuccess();
            void login({
                email: formValues.email,
                passwordless: true,
                redirectUrl: `/web-installer/user-info?installId=${installId}`,
                backUrl: `/web-installer/email-confirmation?installId=${installId}&authChecked=true`,
                branding: brandings[productType],
            });
        },
        onError: () => {
            analyticsEvents.emailSubmitError();
        },
    });

    const validationErrors = useMemo(() => getValidationErrors(error) ?? [], [error]);

    const handleChange = useCallback(
        <K extends keyof FormValues>(key: K, value: FormValues[K]): void =>
            setFormValues({
                ...formValues,
                [key]: value,
            }),
        [formValues],
    );

    const handleSubmit = useCallback(
        ({ email }: FormValues): void => {
            if (isAuthorized) {
                // Proceed to user info page.
                navigate(`/web-installer/user-info?installId=${installId}`);
            } else {
                analyticsEvents.emailSubmit();
                submitForm({ email });
            }
        },
        [submitForm, analyticsEvents, isAuthorized, installId, navigate],
    );

    const licenceAgreementLabel = useMemo(() => {
        const termsOfUse = concatUrl('/legal/terms', platform.baseUrl);
        const termsOfUseLink = (chunk: ReactNode) => (
            <a href={termsOfUse} target="_blank" rel="noopener noreferrer">
                {chunk}
            </a>
        );
        const privacyPolicy = concatUrl('/legal/privacy-policy', platform.baseUrl);
        const privacyPolicyLink = (chunk: ReactNode) => (
            <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                {chunk}
            </a>
        );
        const dpa = concatUrl('/legal/data-processing-agreement', platform.baseUrl);
        const dpaLink = (chunk: ReactNode) => (
            <a href={dpa} target="_blank" rel="noopener noreferrer">
                {chunk}
            </a>
        );

        return translate('legalAgreementTitle', {
            termsOfUseLink,
            privacyPolicyLink,
            dpaLink,
        });
    }, [translate, platform.baseUrl]);

    const form = useMemo(
        () => (
            <Form
                vertical
                values={formValues}
                applyButton={false}
                cancelButton={false}
                submitButton={{
                    children: translate('submitButton'),
                    type: 'submit',
                    disabled: !formValues.agree,
                }}
                state={isLoading ? 'submit' : undefined}
                errors={translateValidationErrors(validationErrors)}
                onFieldChange={handleChange}
                onSubmit={handleSubmit}
            >
                <FormFieldText
                    label={translate('yourEmail')}
                    name="email"
                    size="fill"
                    disabled={isAuthorized}
                    autoFocus
                    required
                    description={translate('emailHint')}
                />
                <FormFieldCheckbox label={licenceAgreementLabel} name="agree" />
            </Form>
        ),
        [
            isAuthorized,
            isLoading,
            formValues,
            licenceAgreementLabel,
            translate,
            translateValidationErrors,
            validationErrors,
            handleChange,
            handleSubmit,
        ],
    );

    const title = useMemo(
        () => (
            <Heading level={2} className={styles.emailConfirmationContainerFormHeaderBlock}>
                <div>
                    {translate('panelTitle', {
                        productType: productTypeName,
                    })}
                </div>
                {!isAuthorized && <LocaleSwitcher />}
            </Heading>
        ),
        [translate, isAuthorized, productTypeName],
    );

    if (!authChecked || isDpaLoading) {
        return <Loading />;
    }

    return (
        <Layout>
            <div className={styles.emailConfirmationContainer}>
                <div>
                    <Panel className={styles.emailConfirmationContainerOuterPanel}>
                        <ControlPanelLogo productType={productType} />
                        <Panel
                            className={styles.emailConfirmationContainerInnerPanel}
                            data-type="email-confirmation-panel"
                        >
                            {title}
                            <InfoSection productType={productType} displayName={productTypeName} />
                            {form}
                        </Panel>
                    </Panel>
                    <WebInstallerBottomLinks productType={productType} />
                </div>
            </div>
        </Layout>
    );
};

export default EmailConfirmationPage;
