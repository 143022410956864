// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode, useCallback, useState } from 'react';
import useApiDefaultErrorHandler from '@platform360/libs/shared-web/helpers/useApiDefaultErrorHandler';
import axios from 'axios';
import {
    QueryMeta,
    MutationCache,
    QueryCache,
    QueryClient,
    QueryClientConfig,
    QueryClientProvider,
} from '@tanstack/react-query';

const queryClientCypressOptions: QueryClientConfig = {
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
};

type QueryProviderProps = {
    children: ReactNode;
};

export const QueryProvider = ({ children }: QueryProviderProps) => {
    const defaultErrorHandler = useApiDefaultErrorHandler();

    const handleError = useCallback(
        (error: unknown, meta: QueryMeta | undefined) => {
            if (meta?.defaultErrorHandler === false) {
                return;
            }

            if (axios.isAxiosError(error)) {
                defaultErrorHandler(error);
            }
        },
        [defaultErrorHandler],
    );

    const [queryClient] = useState(() => {
        const queryCache = new QueryCache({
            onError: (error, query) => handleError(error, query.meta),
        });

        const mutationCache = new MutationCache({
            onError: (error, _vars, _ctx, mutation) => handleError(error, mutation.meta),
        });

        const config: QueryClientConfig = {
            queryCache,
            mutationCache,
            defaultOptions: {
                queries: {
                    retry: false,
                },
            },
        };

        const cypressConfig = {
            ...queryClientCypressOptions,
            ...config,
        };

        return new QueryClient(window.Cypress ? cypressConfig : config);
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
