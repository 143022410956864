// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    Task,
    Server,
    Label,
    UpdateSettings,
    DailyTaskTimeSettingsResponse,
    DailyTaskTimeSettingsSaveData,
} from '@platform360/security-dashboard/web/types';
import {
    ApiListResponse,
    ApiResponse,
    FetcherOptions,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';
import { DateTime } from 'luxon';
import mapServerStatus from '@platform360/security-dashboard/web/helpers/mapServerStatus';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';

export type ServerResponse = {
    id: number;
    address: string;
    hostname: string | null;
    unsupportedAgent: boolean;
    displayTitle: string;
    installationsCount: number;
    vulnerableCount: number;
    outdatedCount: number;
    notOperableCount: number;
    operableNotManagedCount: number;
    withDoNotProtectLabelCount: number;
    protected: boolean;
    connectionStatus: string;
    lastRequestResultType: 'ok' | string;
    lastVulnerabilityCheckAt: number | null;
    tasks: Task[];
    labels: Label[];
    updateSettings: UpdateSettings;
    agentVersion: string | null;
};

export type GetServersResponse = ApiListResponse<ServerResponse>;

export const SITES_HEALTH_FILTER_ALL = 'all';
export const SITES_HEALTH_FILTER_ALERTS = 'alerts';

export type SitesHealthFilter = typeof SITES_HEALTH_FILTER_ALL | typeof SITES_HEALTH_FILTER_ALERTS;

export type GetServersOptions = FetcherOptions<
    {
        labelIdsFilter?: string[];
        sitesHealthFilter?: SitesHealthFilter;
        filter?: string;
    } & PaginatedQuery
>;

export const normalizeServer = ({
    lastRequestResultType,
    lastVulnerabilityCheckAt,
    connectionStatus,
    ...server
}: ServerResponse): Server => {
    const address = new URL(server.address);

    return {
        ...server,
        address,
        connectionStatus,
        status: mapServerStatus(lastRequestResultType, connectionStatus),
        ipAddress: address.hostname,
        lastVulnerabilityCheckAt: lastVulnerabilityCheckAt
            ? DateTime.fromSeconds(lastVulnerabilityCheckAt)
            : null,
    };
};

export const getServers = async ({
    signal,
    variables: { sitesHealthFilter = SITES_HEALTH_FILTER_ALERTS, ...variables },
}: GetServersOptions) => {
    const { data } = await apiClient.get<GetServersResponse>('/security-dashboard/servers', {
        params: {
            sitesHealthFilter,
            ...variables,
            labelIdsFilter: variables.labelIdsFilter?.join(','),
        },
        signal,
    });

    return {
        ...data,
        data: data.data.map(normalizeServer),
    };
};

export type SingleServerResponse = {
    displayTitle: string;
};

export type GetServerOptions = FetcherOptions<{
    serverId: number;
}>;

export const getServer = async ({
    variables: { serverId },
    signal,
}: GetServerOptions): Promise<SingleServerResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<SingleServerResponse>>(
        `/security-dashboard/servers/${serverId}`,
        {
            signal,
        },
    );

    return data;
};

export type ServersSummaryResponse = {
    total: number;
};

export const getServersSummary = async (): Promise<ServersSummaryResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<ServersSummaryResponse>>(
        '/security-dashboard/servers/summary',
    );

    return data;
};

export type ServerSettingsResponse = {
    updates: UpdateSettings;
    dailyTask: DailyTaskTimeSettingsResponse;
};

export type GetServerSettingsOptions = FetcherOptions<{
    serverId: number;
}>;

export const getServerSettings = async ({
    variables: { serverId },
    signal,
}: GetServerSettingsOptions): Promise<ServerSettingsResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<ServerSettingsResponse>>(
        `/security-dashboard/servers/${serverId}/settings`,
        {
            signal,
        },
    );

    return data;
};

export type SaveServerSettingsRequest = {
    updates?: {
        core: UpdateSettingsOption | null;
        plugins: UpdateSettingsOption | null;
        themes: UpdateSettingsOption | null;
        safeUpdate: SafeUpdateOption | null;
        overrideDescendants: boolean;
    };
    dailyTask?: DailyTaskTimeSettingsSaveData;
};

export type SaveServersSettingsRequest = {
    serverIds: number[];
    settings: SaveServerSettingsRequest;
};

export type SaveServerSettingsOptions = FetcherOptions<{
    serverId: number;
    request: SaveServerSettingsRequest;
}>;

export const saveServerSettings = async ({
    variables: { serverId, request },
}: SaveServerSettingsOptions): Promise<void> => {
    await apiClient.put(`/security-dashboard/servers/${serverId}/settings`, request);
};

export const saveServersSettings = async (request: SaveServersSettingsRequest): Promise<void> => {
    await apiClient.post('/security-dashboard/servers/settings', request);
};

export const addDemoServer = async (): Promise<void> => {
    await apiClient.post('/security-dashboard/servers/demo');
};

export const removeServer = async (serverId: number): Promise<void> => {
    await apiClient.delete(`/security-dashboard/servers/${serverId}`);
};

export const runScanner = async (servers: number[]): Promise<void> => {
    await apiClient.post('/security-dashboard/servers/scanner', { servers });
};

export const refreshServer = async (servers: number[]): Promise<void> => {
    await apiClient.post('/security-dashboard/servers/refresh', { servers });
};

export const runUpdater = async (servers: number[]): Promise<void> => {
    await apiClient.post('/security-dashboard/servers/updater', { servers });
};

type RunProtectServersOptions = {
    servers: number[];
    enable: boolean;
    ignoreDoNotProtect?: boolean;
};

export const runProtectServers = async ({
    servers,
    enable,
    ignoreDoNotProtect,
}: RunProtectServersOptions): Promise<void> => {
    await apiClient.post('/security-dashboard/servers/protect', {
        servers,
        enable,
        ignoreDoNotProtect,
    });
};

type AssignRemoveServerLabelsOptions = {
    serverIds: number[];
    labelIds: number[];
};

export const assignServerLabels = async ({
    serverIds,
    labelIds,
}: AssignRemoveServerLabelsOptions): Promise<void> => {
    await apiClient.post<ApiResponse<void>>('/security-dashboard/servers/assign-labels', {
        serverIds,
        labelIds,
    });
};

export const removeServerLabels = async ({
    serverIds,
    labelIds,
}: AssignRemoveServerLabelsOptions): Promise<void> => {
    await apiClient.post<ApiResponse<void>>('/security-dashboard/servers/remove-labels', {
        serverIds,
        labelIds,
    });
};
