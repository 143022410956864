// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ItemList } from '@plesk/ui-library';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import ImpersonationWidget from './ImpersonationWidget';
import styles from './Widgets.module.css';

export const Widgets = ({ ...props }) => {
    const { impersonation } = useCurrentUser();

    return (
        <div className={styles.root}>
            <ItemList gap="sm" minColumnWidth={400} stretchable hoverable={false} {...props}>
                <ImpersonationWidget impersonation={impersonation} />
                <div />
            </ItemList>
        </div>
    );
};
