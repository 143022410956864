// Copyright 2024. WebPros International GmbH. All rights reserved.

import AnalyticsClient from '@platform360/libs/shared-web/analytics/AnalyticsClient';

export class LicensesAnalyticsEvents {
    public constructor(private readonly client: AnalyticsClient) {}

    public documentationLinkClicked() {
        this.client.logEvent('Documentation Link Clicked');
    }

    public licenseManagementPageOpen() {
        this.client.logEvent('License management page open');
    }

    public licensesBuyPleskClicked() {
        this.client.logEvent('Licenses BuyPlesk Clicked');
    }

    public licensesBuy360MonitoringClicked() {
        this.client.logEvent('Licenses Buy360Monitoring Clicked');
    }

    public licensesBuyNewLicenseClicked() {
        this.client.logEvent('Licenses BuyNewLicense Clicked');
    }

    public licensesPleskExtensionsCatalogLinkClicked() {
        this.client.logEvent('Licenses Plesk Extensions Catalog Link Clicked');
    }

    public licensesItemUnsubscribeSubmitClicked() {
        this.client.logEvent('Licenses Item UnsubscribeSubmit Clicked');
    }

    public licensesItemEditPaymentMethodClicked() {
        this.client.logEvent('Licenses Item EditPaymentMethod Clicked');
    }

    public licensesItemMoreDetailsClicked(buttonType: 'License' | 'Extension') {
        this.client.logEvent('Licenses Item MoreDetails Clicked', { buttonType });
    }

    public licensesItemAddExtensionClicked() {
        this.client.logEvent('Licenses Item AddExtension Clicked');
    }

    public licensesAddExtensionDialogSelectClicked() {
        this.client.logEvent('Licenses AddExtensionDialog Select Clicked');
    }

    public licensesAddExtensionDialogConfirmClicked() {
        this.client.logEvent('Licenses AddExtensionDialog Confirm Clicked');
    }

    public licensesAddExtensionDialogGoToOnlineStoreClicked() {
        this.client.logEvent('Licenses AddExtensionDialog GoToOnlineStore Clicked');
    }

    public licensesItemUpgradeClicked() {
        this.client.logEvent('Licenses Item Upgrade Clicked');
    }

    public licensesUpgradeDialogConfirmClicked() {
        this.client.logEvent('Licenses UpgradeDialog Confirm Clicked');
    }

    public licensesItemAdjustClicked() {
        this.client.logEvent('Licenses Item Adjust Clicked');
    }

    public licensesAdjustDialogConfirmClicked() {
        this.client.logEvent('Licenses AdjustDialog Confirm Clicked');
    }

    public licensesLinkedEmailsLinkEmailsFormOpened() {
        this.client.logEvent('Licenses LinkedEmails LinkEmailsForm Opened');
    }

    public licensesLinkedEmailsItemUnlinkEmailClicked() {
        this.client.logEvent('Licenses LinkedEmails Item UnlinkEmail Clicked');
    }

    public licenseUpgradeSuccessful(extensionCatalogCode: string): void {
        this.client.logEvent('LIC:UPGRADE_SUCCESSFUL', { extensionCatalogCode });
    }

    public licenseUpgradeFailed(extensionCatalogCode: string): void {
        this.client.logEvent('LIC:UPGRADE_FAILED', { extensionCatalogCode });
    }
}
