// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'Keine offenen Warnungen',
        openAlerts: 'Offene Warnungen: {alerts}',
        loadingError: 'Service nicht verfügbar',
        connectServer: 'Server verknüpfen',
        notSupported: 'Nicht unterstützt',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'In 360 Monitoring anzeigen',
        connectServerTitle: 'Dieser Server ist nicht mit 360 Monitoring verknüpft',
        connectServerDescription: 'Wenn der Server verknüpft ist, finden Sie auf dieser Seite einen Überblick über den Serverstatus und die derzeit offenen Warnungen.',
        connectServerButton: 'Server verknüpfen',
        pausedServerTitle: 'Dieser Server wird nicht überwacht',
        pausedServerDescription: 'Weitere Details finden Sie in der Benutzeroberfläche von 360 Monitoring.',
        preparingServerTitle: 'Die Vorbereitungen laufen',
        preparingServerDescription1: 'Ihr Plesk-Server wurde mit 360 Monitoring verknüpft.',
        preparingServerDescription2: 'Das Dashboard wird gerade vorbereitet. Aktualisieren Sie die Seite oder schauen Sie in 10 bis 30 Minuten wieder vorbei.',
        refreshButton: 'Aktualisieren',
        loadingError: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        loadAverageTitle: 'Auslastungsrate',
        alertOpened: 'Geöffnet: {timeAgo}.',
        viewAlertsInMonitoring: 'Warnungen in 360 Monitoring anzeigen',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Monitoring',
            description: 'Überwachen Sie Ihre Server und Websites über ein einziges Dashboard',
        },
        DynamicWidget: {
            title: 'Monitoring',
            WidgetContent: {
                servers: 'Server',
                websites: 'Websites',
                lastUpdate: 'Zuletzt aktualisiert: {lastUpdate}',
                loading: 'Wird geladen...',
                Loader: {
                    loading: 'Wird geladen...',
                    notRespondingText1: '360 Monitoring reagiert nicht.',
                    notRespondingText2: 'Es wird versucht, die Verbindung wiederherzustellen.',
                },
                InfoAggregateColumn: {
                    allActive: 'Alle aktiv',
                    noAlerts: 'Keine Warnungen',
                    alerts: 'Offene Warnungen: {alerts}',
                    down: '{down, plural, one{# reagiert nicht} other{# reagieren nicht}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: '360 Monitoring aktivieren',
        description: '360 Monitoring Lite ist kostenlos – jetzt und immer. Wenn Sie Ihr Konto noch heute aktivieren, können Sie außerdem kostenlos 14 Tage die Pro-Version mit allen Premium-Funktionen ausprobieren. Nach zwei Wochen wird automatisch wieder die kostenlose Lite-Version aktiviert, es sei denn, Sie entscheiden sich für ein Upgrade.',
        beginTrialButton: '360 Monitoring aktivieren*',
        footnote: '* Für die Aktivierung von 360 Monitoring Lite oder der Pro-Testversion ist die Angabe von Kreditkartendaten nicht erforderlich.',
        backButton: 'Zurück zu Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Abbrechen',
            confirmationText: 'Versuchen Sie, Ihr 360 Monitoring-Konto zu konfigurieren, indem Sie die Menüoption "Monitoring" öffnen oder eine Supportanfrage einreichen.',
            acceptButtonText: 'OK',
            declineButtonText: 'Abbrechen',
        },
        retryButtonText: 'Erneut versuchen',
        inProgressText: 'Ihr 360 Monitoring-Konto wird konfiguriert',
        successText: 'Sie werden zu 360 Monitoring weitergeleitet',
        failedText: 'Bei der Aktivierung des Monitoring-Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder stellen Sie eine Supportanfrage.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Aktivierung von 360 Monitoring bestätigen',
            description: 'Der "360 Monitoring"-Agent wird im Control Panel von Plesk unter <b>{address}</b> installiert und mit dem Konto <b>{email}</b> in Plesk 360 verknüpft.',
        },
        monitoringInactive: {
            title: '360 Monitoring aktivieren und Server hinzufügen',
            description: 'Probieren Sie 360 Monitoring in einer <tooltip>Testversion</tooltip> aus und bestätigen Sie die Installation des "360 Monitoring"-Agent im Control Panel von Plesk unter <b>{address}</b>, die mit dem Konto <b>{email}</b> in Plesk 360 verknüpft ist.',
            trialTooltip: 'In der 14-Tage-Testversion können Sie alle Premium-Funktionen ausprobieren. Nach Ablauf des Testzeitraums wird 360 Monitoring auf eine kostenlose "Lite"-Version mit Basisfunktionen umgestellt. Sie können jedoch auch jederzeit die "Pro"-Version aktivieren, indem Sie eine Lizenz erwerben.',
        },
        buttons: {
            confirm: 'Bestätigen',
            deny: 'Verweigern',
            cancel: 'Abbrechen',
        },
        errors: {
            apiKey: 'Die Serververbindung mit Plesk 360 wurde scheinbar unterbrochen. Verbinden Sie Ihren Server erneut mit Plesk 360 und aktivieren Sie dann das Monitoring.',
            default: '360 Monitoring konnte nicht installiert werden. Versuchen Sie es später erneut.',
        },
    },
};