// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './NotFound.module.css';
import octopus from './octopus.gif';
import Public from '@platform360/libs/shared-web/components/Public';

const PlatformNotFoundPage = () => (
    <Public isWide>
        <div className={styles.root}>
            <img className={styles.image} src={octopus} alt="Octopus" />
            <div className={styles.content}>
                <div className={styles.code}>{'404'}</div>
                <div className={styles.text}>{'WHOOPS!'}</div>
            </div>
        </div>
    </Public>
);

export default PlatformNotFoundPage;
