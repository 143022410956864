// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FINALIZATION } from '@platform360/licenses/web/units/adjust/constants/actions';

export type FinalizationAction = {
    type: typeof FINALIZATION;
};

export const finalization = (): FinalizationAction => ({
    type: FINALIZATION,
});
