// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCallback, useEffect, useRef } from 'react';
import { useBoolean, useCounter, useIsomorphicLayoutEffect } from 'usehooks-ts';

type CountdownOption = {
    countStart: number;
    intervalMs?: number;
};

type CountdownControllers = {
    startCountdown: () => void;
    stopCountdown: () => void;
    resetCountdown: () => void;
    updateTimer: (ms: number) => void;
};

const useCountdown = ({
    countStart,
    intervalMs = 1000,
}: CountdownOption): [number, CountdownControllers] => {
    const { count, decrement, reset: resetCounter, setCount } = useCounter(countStart);
    const {
        value: isCountdownRunning,
        setTrue: startCountdown,
        setFalse: stopCountdown,
    } = useBoolean(false);

    const countdownCallback = useCallback(() => {
        if (!isCountdownRunning || count === 0) {
            return;
        }

        decrement();
    }, [count, decrement, isCountdownRunning]);

    const savedCallback = useRef(countdownCallback);

    // Remember the latest callback if it changes.
    useIsomorphicLayoutEffect(() => {
        savedCallback.current = countdownCallback;
    }, [countdownCallback]);

    /**
     * Will set running false and reset the seconds to initial value.
     */
    const resetCountdown = () => {
        stopCountdown();
        resetCounter();
    };

    useEffect(() => {
        const id = setInterval(() => savedCallback.current(), intervalMs);

        return () => {
            clearInterval(id);
        };
    }, [intervalMs]);

    return [
        count,
        {
            startCountdown,
            stopCountdown,
            resetCountdown,
            updateTimer: setCount,
        },
    ];
};

export default useCountdown;
