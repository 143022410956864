// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    PRODUCT_WP_GUARDIAN_1,
    PRODUCT_WP_GUARDIAN_10,
    PRODUCT_WP_GUARDIAN_30,
    PRODUCT_WP_GUARDIAN_5,
    PRODUCT_WP_GUARDIAN_50,
} from '@platform360/libs/shared-web/constants/products';

export type RetailLicenseData = {
    cleverbridgeKey: string;
    sites: number;
    pricePerSite: number;
    pricePerMonth: number;
    productNamePrefix: string;
};

export const AVAILABLE_RETAIL_LICENSES: RetailLicenseData[] = [
    {
        cleverbridgeKey: 'limit1',
        sites: 1,
        pricePerSite: 5,
        pricePerMonth: 5,
        productNamePrefix: PRODUCT_WP_GUARDIAN_1,
    },
    {
        cleverbridgeKey: 'limit5',
        sites: 5,
        pricePerSite: 3,
        pricePerMonth: 15,
        productNamePrefix: PRODUCT_WP_GUARDIAN_5,
    },
    {
        cleverbridgeKey: 'limit10',
        sites: 10,
        pricePerSite: 2.5,
        pricePerMonth: 25,
        productNamePrefix: PRODUCT_WP_GUARDIAN_10,
    },
    {
        cleverbridgeKey: 'limit30',
        sites: 30,
        pricePerSite: 2.17,
        pricePerMonth: 65,
        productNamePrefix: PRODUCT_WP_GUARDIAN_30,
    },
    {
        cleverbridgeKey: 'limit50',
        sites: 50,
        pricePerSite: 2,
        pricePerMonth: 100,
        productNamePrefix: PRODUCT_WP_GUARDIAN_50,
    },
];
