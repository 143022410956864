// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label } from '@plesk/ui-library';
import { License } from '@platform360/security-dashboard/web/types';
import styles from './ExpirationLabel.module.css';
import { useDateToTrialDisplayInfo } from '@platform360/security-dashboard/web/helpers/useDateToTrialDisplayInfo';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';

type ExpirationLabelProps = {
    license: License | null;
};

type ExpirationLabelInfo = {
    text: ReactNode;
    intent: 'danger' | 'warning' | 'success' | undefined;
};
const ExpirationLabel = ({ license }: ExpirationLabelProps) => {
    const translate = useTranslate('security-dashboard.LicensePage.ExpirationLabel');
    const dateToTrialDisplayInfo = useDateToTrialDisplayInfo();

    const getExpirationLabelInfo = (): ExpirationLabelInfo | null => {
        if (license === null || license.status === 'terminated') {
            return { text: translate('noLicense'), intent: 'danger' };
        }

        if (license.trial) {
            const displayInfo = dateToTrialDisplayInfo(license.expiredAt, 'D');
            return {
                text: displayInfo.text,
                intent: displayInfo.intent ?? 'success',
            };
        }

        if (license.expiredAt < DateTime.now()) {
            return { text: translate('expiredLicense'), intent: 'danger' };
        }

        return null;
    };

    const info = getExpirationLabelInfo();

    if (info === null) {
        return null;
    }

    return (
        <Label intent={info.intent} view="light" size="lg" className={styles.expirationLabel}>
            {info.text}
        </Label>
    );
};

export default ExpirationLabel;
