// Copyright 2024. WebPros International GmbH. All rights reserved.

import { VersionStatuses } from '@platform360/server-inventory/shared/version-status';
import { Server } from '@platform360/server-inventory/web/types';
import CertificateErrorStatusMessage from './CertificateErrorStatusMessage';
import ConnectionErrorStatusMessage from './ConnectionErrorStatusMessage';
import CertificateWarningStatusMessage from './CertificateWarningStatusMessage';
import ConnectionBlockedStatusMessage from './ConnectionBlockedStatusMessage';
import InactiveUnreachableStatusMessage from './InactiveUnreachableStatusMessage';
import InactiveUnsupportedStatusMessage from './InactiveUnsupportedStatusMessage';
import OutdatedStatusMessage from './OutdatedStatusMessage';
import { UnsupportedStatusMessage } from './UnsupportedStatusMessage/UnsupportedStatusMessage';
import { InactiveReasons } from '@platform360/server-inventory/shared/inactive-reason';

export type StatusMessagesProps = {
    server: Server;
};

const StatusMessages = ({ server }: StatusMessagesProps) => {
    const {
        id: serverId,
        address,
        active,
        inactiveReason,
        allowInvalidCertificate,
        lastRequestResultUpdatedAt: lastCheckConnection,
        lastRequestResultType,
    } = server;
    const { status } = lastRequestResultType;
    const versionStatus = server.productVersion?.versionStatus;

    const renderConnError = (isRecheckConnectionEnabled: boolean) =>
        status === 'connectionError' && (
            <ConnectionErrorStatusMessage
                serverId={serverId}
                serverName={address.hostname}
                errorCode={lastRequestResultType.errorCode}
                errorDetail={lastRequestResultType.errorDetail}
                isRecheckConnectionEnabled={isRecheckConnectionEnabled}
            />
        );

    const renderConnBlocked = (isRecheckConnectionEnabled: boolean) =>
        status === 'connectionBlocked' && (
            <ConnectionBlockedStatusMessage
                serverId={serverId}
                isRecheckConnectionEnabled={isRecheckConnectionEnabled}
            />
        );

    const renderCertError = (isRecheckConnectionEnabled: boolean) =>
        status === 'certificateError' &&
        !allowInvalidCertificate && (
            <CertificateErrorStatusMessage
                serverId={serverId}
                serverName={address.hostname}
                errorCode={lastRequestResultType.errorCode}
                errorDetail={lastRequestResultType.errorDetail}
                isRecheckConnectionEnabled={isRecheckConnectionEnabled}
            />
        );

    const renderCertWarn = () =>
        status === 'certificateError' &&
        allowInvalidCertificate && (
            <CertificateWarningStatusMessage errorCode={lastRequestResultType.errorCode} />
        );

    const renderInactiveUnreachable = () =>
        inactiveReason === InactiveReasons.unreachable && (
            <InactiveUnreachableStatusMessage
                serverId={serverId}
                lastCheckConnection={lastCheckConnection}
            />
        );

    const renderInactiveUnsupported = () =>
        inactiveReason === InactiveReasons.unsupported && (
            <InactiveUnsupportedStatusMessage server={server} />
        );

    const renderUnsupported = () =>
        versionStatus === VersionStatuses.unsupported && (
            <UnsupportedStatusMessage server={server} />
        );

    const renderOutdated = () =>
        versionStatus === VersionStatuses.outdated && <OutdatedStatusMessage server={server} />;

    if (active) {
        const connError = renderConnError(true);
        const connBlocked = renderConnBlocked(true);
        const certError = renderCertError(true);
        const certWarn = renderCertWarn();

        if (connError || certError || connBlocked) {
            // Connection problems outweigh version problems.
            return (
                <>
                    {connError}
                    {connBlocked}
                    {certError}
                    {certWarn}
                </>
            );
        }

        const unsupported = renderUnsupported();
        const outdated = renderOutdated();
        return (
            <>
                {certWarn}
                {unsupported}
                {outdated}
            </>
        );
    }

    // Inactive.
    const inactiveUnsupported = renderInactiveUnsupported();
    if (inactiveUnsupported) {
        return inactiveUnsupported;
    }

    const connError = renderConnError(false);
    const connBlocked = renderConnBlocked(false);
    const certError = renderCertError(false);
    const inactiveUnreachable = renderInactiveUnreachable();
    return (
        <>
            {connError}
            {connBlocked}
            {certError}
            {inactiveUnreachable}
        </>
    );
};

export default StatusMessages;
