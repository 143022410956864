// Copyright 2024. WebPros International GmbH. All rights reserved.

import { License as BackendLicense } from '@platform360/licenses/web/api/ka/responseTypes/License';
import getKeyPrefixByKeyNumber from '@platform360/licenses/web/helpers/getKeyPrefixByKeyNumber';
import { UNIQUENESS_OF_360_MONITORING } from '@platform360/licenses/web/units/buy360MonitoringButton/constants/uniqueness';
import {
    SECURITY_DASHBOARD_FEEDBACK_LINK,
    SECURITY_DASHBOARD_KA_PRODUCT_CODE,
} from '@platform360/libs/common/constants/security-dashboard';

const prefixes = {
    EXT: 'Cq0YeZ',
    SIO: 'Qkigys',
    360: 'LhR8YKt6',
};

const typeformBasePath = 'https://plesk.typeform.com/to/';

const getTypeformLinkByLicense = (license: BackendLicense): URL => {
    if (license.code === SECURITY_DASHBOARD_KA_PRODUCT_CODE) {
        return new URL(SECURITY_DASHBOARD_FEEDBACK_LINK);
    }

    let uri = prefixes.EXT;

    const queryParams = {
        product: license.componentInfo?.productId,
        productName: license.type,
        transactionId: license.componentInfo?.purchaseId,
        country: license.countryCode || undefined,
    };

    if (getKeyPrefixByKeyNumber(license.keyNumber) === 'SIO') {
        uri = prefixes.SIO;
    }

    if (license.type.startsWith(UNIQUENESS_OF_360_MONITORING)) {
        uri = prefixes['360'];
    }

    const url = new URL(typeformBasePath.concat(uri));

    for (const [param, value] of Object.entries(queryParams)) {
        url.searchParams.append(param, String(value));
    }

    return url;
};

export default getTypeformLinkByLicense;
