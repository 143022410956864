// Copyright 2024. WebPros International GmbH. All rights reserved.

export const prefix = 'licenses/upsell/';

export const INITIALIZATION = `${prefix}initialization`;
export const FAILED_INITIALIZATION = `${prefix}failed`;
export const INITIALIZED = `${prefix}initialized`;
export const FINALIZATION = `${prefix}finalization`;

export const CHANGE_PERIOD = `${prefix}period/change`;
export const CHANGE_CURRENCY = `${prefix}currency/change`;

export const SELECT_ITEM = `${prefix}item/select`;
export const FLUSH_ITEM = `${prefix}item/flush`;
