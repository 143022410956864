// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ListEmptyView } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type EmptyViewProps = {
    notFound: boolean;
};

export const EmptyView = ({ notFound }: EmptyViewProps) => {
    const translate = useTranslate('admin-panel.EmailMetas.EmptyView');

    if (notFound) {
        return (
            <ListEmptyView
                reason="filtered"
                title={translate('noResults.title')}
                description={translate('noResults.description')}
            />
        );
    }

    return (
        <ListEmptyView
            title={translate('initialState.title')}
            description={translate('initialState.description')}
        />
    );
};
