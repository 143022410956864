// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import { ServerResponse } from '@platform360/server-inventory/shared/api-contract';

export type ServerLastRequestResultType =
    | { status: 'ok' }
    | {
          status: 'certificateError' | 'connectionError' | 'connectionBlocked';
          errorCode: string;
          errorDetail: string;
      };

export type Server = Omit<
    ServerResponse,
    | 'address'
    | 'syncFinishedAt'
    | 'nextSyncAt'
    | 'syncStartedAt'
    | 'nextPingAt'
    | 'pingStartedAt'
    | 'lastRequestResultType'
    | 'lastRequestResultDetail'
    | 'lastRequestResultUpdatedAt'
> & {
    address: URL;
    syncFinishedAt: DateTime | null;
    lastRequestResultUpdatedAt: DateTime | null;
    nextSyncAt: DateTime;
    lastRequestResultType: ServerLastRequestResultType;
};

export const HOSTING_TYPE_VIRTUAL = 'virtual';
export const HOSTING_TYPE_STANDARD_FORWARDING = 'standard_forwarding';
export const HOSTING_TYPE_FRAME_FORWARDING = 'frame_forwarding';
export const HOSTING_TYPE_NONE = 'none';
export const DOMAIN_TYPE_MAIN = 'main';
export const DOMAIN_TYPE_SUB = 'sub';
export const DOMAIN_TYPE_ADDON = 'addon';
export const DOMAIN_TYPE_PARKED = 'parked';

export const isPleskFields = (
    fields: PleskDomainFields | CpanelDomainFields,
): fields is PleskDomainFields => fields.product === 'plesk';

type PleskDomainFields = {
    product: 'plesk';
    hostingType:
        | typeof HOSTING_TYPE_VIRTUAL
        | typeof HOSTING_TYPE_STANDARD_FORWARDING
        | typeof HOSTING_TYPE_FRAME_FORWARDING
        | typeof HOSTING_TYPE_NONE;
    baseDomainId: number | null;
};

type CpanelDomainFields = {
    product: 'cpanel';
    domainType:
        | typeof DOMAIN_TYPE_MAIN
        | typeof DOMAIN_TYPE_SUB
        | typeof DOMAIN_TYPE_ADDON
        | typeof DOMAIN_TYPE_PARKED;
};

export type DomainResponse = {
    id: number;
    name: string;
    fields: PleskDomainFields | CpanelDomainFields;
    server: {
        id: number;
        address: string;
    };
};

export type Domain = Omit<DomainResponse, 'server'> & {
    server: {
        id: number;
        address: URL;
    };
};

export const CLIENT_TYPE_ADMIN = 'admin';
export const CLIENT_TYPE_RESELLER = 'reseller';
export const CLIENT_TYPE_CUSTOMER = 'customer';

type PleskClientFields = {
    product: 'plesk';
    name: string;
};

type CpanelClientFields = {
    product: 'cpanel';
};

export type ClientResponse = {
    id: number;
    email: string | null;
    login: string;
    type: typeof CLIENT_TYPE_ADMIN | typeof CLIENT_TYPE_RESELLER | typeof CLIENT_TYPE_CUSTOMER;
    isSuspended: boolean;
    owner?: {
        name: string;
    };
    server: {
        id: number;
        address: string;
    };
    fields: PleskClientFields | CpanelClientFields;
};

export type Client = Omit<ClientResponse, 'server'> & {
    server: {
        id: number;
        address: URL;
    };
};

export type ValidationError = {
    type: string;
    path: string;
    message: string;
};
