// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import {
    CustomInstallRequest,
    CustomInstallResponse,
    CustomVerifyResponse,
    DpaResponse,
    MarketingConsentResponse,
    GetLoginLinkResponse,
    InstallationLogResponse,
    InstallationResponse,
    LightsailInstallRequest,
    LightsailOptionsResponse,
    SignUpRequest,
} from '@platform360/web-installer/shared/api-contract';

export const signUp = async (params: SignUpRequest): Promise<void> => {
    await apiClient.post<ApiResponse<void>>('/web-installer/auth/sign-up', params);
};

export const customInstall = async (params: CustomInstallRequest) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<CustomInstallResponse>>(
        '/web-installer/installations/custom',
        params,
    );

    return data;
};

export const customVerify = async (params: CustomInstallRequest) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<CustomVerifyResponse>>(
        '/web-installer/installations/custom/verify',
        params,
    );

    return data;
};

export const cpanelInstall = async (params: CustomInstallRequest) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<CustomInstallResponse>>(
        '/web-installer/installations/cpanel',
        params,
    );
    return data;
};

export const cpanelVerify = async (params: CustomInstallRequest) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<CustomVerifyResponse>>(
        '/web-installer/installations/cpanel/verify',
        params,
    );
    return data;
};

export const lightsailInstall = async (params: LightsailInstallRequest) => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<CustomInstallResponse>>(
        '/web-installer/installations/lightsail',
        params,
    );

    return data;
};

type GetInstallationOptions = FetcherOptions<{
    id: string;
}>;

export const getInstallation = async ({ variables, signal }: GetInstallationOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<InstallationResponse>>(
        `/web-installer/installations/${variables.id}`,
        {
            signal,
        },
    );

    return data;
};

type GetInstallationLogOptions = FetcherOptions<{
    id: string;
}>;

export const getInstallationLog = async ({ variables, signal }: GetInstallationLogOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<InstallationLogResponse>>(
        `/web-installer/installations/${variables.id}/log`,
        {
            signal,
        },
    );

    return data;
};

export const getLightsailOptions = async () => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<LightsailOptionsResponse>>(
        '/web-installer/lightsail/options',
    );

    return data;
};

type GetDpaOptions = FetcherOptions;

export const getDpa = async ({ signal }: GetDpaOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<DpaResponse>>('/web-installer/consents/dpa', {
        signal,
    });
    return data;
};

type GetMarketingConsentOptions = FetcherOptions;

export const getMarketingConsent = async ({ signal }: GetMarketingConsentOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<MarketingConsentResponse>>(
        '/web-installer/consents/marketing',
        {
            signal,
        },
    );
    return data;
};

type GetLoginLinkOptions = FetcherOptions<{
    guid: string;
}>;

export const getLoginLink = async ({ variables }: GetLoginLinkOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<GetLoginLinkResponse>>(
        `/web-installer/servers/${variables.guid}/loginLink`,
    );

    return data;
};
