// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    MonitoringColumn: {
        title: '360 Monitoring',
        noAlerts: 'Ninguna alerta abierta',
        openAlerts: 'Alertas abiertas: {alerts}',
        loadingError: 'Servicio no disponible',
        connectServer: 'Conectar servidor',
        notSupported: 'No soportado',
    },
    ServerViewMonitoring: {
        tabTitle: '360 Monitoring',
        viewInMonitoring: 'Ver en 360 Monitoring',
        connectServerTitle: 'Este servidor no está conectado a 360 Monitoring',
        connectServerDescription: 'Cuando el servidor está conectado, esta página mostrará una breve descripción sobre el estado del servidor y las alertas abiertas en este momento.',
        connectServerButton: 'Conectar servidor',
        pausedServerTitle: 'Este servidor no está monitorizándose',
        pausedServerDescription: 'Use la interfaz de usuario de 360 Monitoring para determinar el motivo.',
        preparingServerTitle: 'Estamos haciendo magia...',
        preparingServerDescription1: 'Su servidor Plesk se ha conectado a 360 Monitoring.',
        preparingServerDescription2: 'Estamos preparando un panel de información. Pruebe a actualizar la página o vuelva en 10-30 minutos.',
        refreshButton: 'Actualizar',
        loadingError: 'Algo ha salido mal. Inténtelo de nuevo en unos minutos.',
        loadAverageTitle: 'Promedio de carga',
        alertOpened: 'Se abrió {timeAgo}.',
        viewAlertsInMonitoring: 'Ver alertas en 360 Monitoring',
    },
    HomePageWidget: {
        StaticWidget: {
            title: 'Monitorización',
            description: 'Monitorice sus servidores y sitios web desde un único panel',
        },
        DynamicWidget: {
            title: 'Monitorización',
            WidgetContent: {
                servers: 'Servidores',
                websites: 'Sitios web',
                lastUpdate: 'Última actualización: {lastUpdate}',
                loading: 'Cargando...',
                Loader: {
                    loading: 'Cargando...',
                    notRespondingText1: '360 Monitoring no responde.',
                    notRespondingText2: 'Intentando volver a conectar.',
                },
                InfoAggregateColumn: {
                    allActive: 'Todo activo',
                    noAlerts: 'Ninguna alerta',
                    alerts: 'Alertas abiertas: {alerts}',
                    down: '{down, plural, one{# no responde} other{# no responden}}',
                },
            },
        },
    },
    MonitoringActivation: {
        title: 'Activar 360 Monitoring',
        description: '360 Monitoring Lite es gratuito ahora y siempre. Si activa su cuenta hoy, también disfrutará de una prueba gratuita de Pro válida durante 14 días que incluye todas las prestaciones premium. Una vez finalizado el periodo de prueba, pasará automáticamente a la versión Lite gratuita, a menos que decida proceder con la mejora.',
        beginTrialButton: 'Activar 360 Monitoring *',
        footnote: '* No es necesario indicar datos de pago para activar la prueba Pro o 360 Monitoring Lite.',
        backButton: 'Volver a Plesk 360',
    },
    MonitoringPromoActivation: {
        CancelButton: {
            cancelButtonText: 'Cancelar',
            confirmationText: 'Pruebe a configurar su cuenta de 360 Monitoring abriendo el elemento de menú de Monitoring o envíe una petición de soporte.',
            acceptButtonText: 'ACEPTAR',
            declineButtonText: 'Cancelar',
        },
        retryButtonText: 'Volver a intentar',
        inProgressText: 'Su cuenta 360 Monitoring está siendo configurada',
        successText: 'Redireccionándole a 360 Monitoring',
        failedText: 'Algo salió mal durante la activación de la cuenta de Monitoring. Inténtelo de nuevo o envíe una petición de soporte.',
    },
    MonitoringServerSetup: {
        monitoringActive: {
            title: 'Confirme la activación de 360 Monitoring',
            description: 'El agente "360 Monitoring" se instalará en su panel de control Plesk en <b>{address}</b> y se conectará a la cuenta <b>{email}</b> en Plesk 360.',
        },
        monitoringInactive: {
            title: 'Active 360 Monitoring y añada el servidor',
            description: 'Empiece a usar 360 Monitoring con un <tooltip>periodo de prueba</tooltip> y confirme la instalación del agente «360 Monitoring» en su panel de control de Plesk en <b>{address}</b> conectado a la cuenta <b>{email}</b> en Plesk 360.',
            trialTooltip: 'Durante el periodo de prueba de 14 días disfrutará de un servicio totalmente funcional con todas las prestaciones premium. Una vez finalizado este periodo, 360 Monitoring pasará a la versión gratuita "Lite", que ofrecerá las prestaciones básicas. Además, puede activar una versión "Pro" en cualquier momento comprando una licencia.',
        },
        buttons: {
            confirm: 'Confirmar',
            deny: 'Denegar',
            cancel: 'Cancelar',
        },
        errors: {
            apiKey: 'La conexión del servidor a Plesk 360 parece dañada. Vuelva a conectar su servidor a Plesk 360 y a continuación active la monitorización',
            default: 'No se ha podido instalar 360 Monitoring. Inténtelo de nuevo transcurridos unos minutos',
        },
    },
};