// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getInstallationToken } from '@platform360/security-dashboard/web/api/installation';

const INSTALLATION_TOKEN_QUERY_ID = ['/security-dashboard/installation/token'];

const useInstallationTokenQuery = () =>
    useQuery({
        queryKey: INSTALLATION_TOKEN_QUERY_ID,
        queryFn: getInstallationToken,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

export default useInstallationTokenQuery;
