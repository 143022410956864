// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useModal from '@platform360/libs/shared-web/helpers/useModal';
import QuickStartWizard from './QuickStartWizard';
import styles from './QuickStartDialog.module.less';

import { Dialog } from '@plesk/ui-library';

export const QuickStartDialog = () => {
    const translate = useTranslate('security-dashboard.QuickStartDialog');

    const [isOpen, setModalOpen] = useModal('quick-start');

    const closeModal = () => setModalOpen(false);

    return (
        <Dialog
            size="sm"
            isOpen={isOpen}
            onClose={closeModal}
            className={styles.dialog}
            data-type="quick-start-dialog"
            cancelButton={false}
        >
            <QuickStartWizard onClose={closeModal} title={translate('title')} />
        </Dialog>
    );
};
