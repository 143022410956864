// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Navigate,
    // eslint-disable-next-line no-restricted-imports
    Routes as ReactRoutes,
    Route,
} from 'react-router-dom';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import Layout from '@platform360/admin-panel/web/components/Layout';
import Users from '@platform360/admin-panel/web/components/Users';
import EmailMetas from '@platform360/admin-panel/web/components/EmailMetas';
import { LeikaEmailHashes } from '@platform360/admin-panel/web/components/LeikaEmailHashes/LeikaEmailHashes';
import ProtectedRoute from '@platform360/admin-panel/web/components/ProtectedRoute';
import HomePage from '@platform360/admin-panel/web/components/HomePage';
import { Licenses, Requests } from '@platform360/admin-panel/web/components/security-dashboard';
import {
    PERMISSION_ALL_USERS_MANAGE,
    PERMISSION_ALL_EMAIL_METAS_MANAGE,
    PERMISSION_WPG_LICENSES_MANAGE,
    PERMISSION_WPG_AGENT_REQUESTS_MANAGE,
    PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE,
} from '@platform360/libs/common/permissions';

const Routes = () => (
    <ReactRoutes>
        <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
                <Route path="/" element={<HomePage />} />
                <Route
                    path="/users/*"
                    element={
                        <ProtectedRoute permission={PERMISSION_ALL_USERS_MANAGE}>
                            <Users />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/email-metas/*"
                    element={
                        <ProtectedRoute permission={PERMISSION_ALL_EMAIL_METAS_MANAGE}>
                            <EmailMetas />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/leika-email-hashes/*"
                    element={
                        <ProtectedRoute permission={PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE}>
                            <LeikaEmailHashes />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/security-dashboard/licenses/*"
                    element={
                        <ProtectedRoute permission={PERMISSION_WPG_LICENSES_MANAGE}>
                            <Licenses />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/security-dashboard/requests/*"
                    element={
                        <ProtectedRoute permission={PERMISSION_WPG_AGENT_REQUESTS_MANAGE}>
                            <Requests />
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Route>
        </Route>
    </ReactRoutes>
);

export default Routes;
