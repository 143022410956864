// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ConsoleOutput, ContentLoader } from '@plesk/ui-library';
import styles from './InstallationLog.module.css';
import useInstallationLog from '@platform360/web-installer/web/queries/useInstallationLog';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useEffect, useLayoutEffect, useState } from 'react';

export type InstallationLogProps = {
    installationId: string;
    isRefetch: boolean;
};

export const InstallationLog = ({ installationId, isRefetch, ...props }: InstallationLogProps) => {
    const translate = useTranslate('server-inventory.Servers.ViewServer.InstallationLog');
    const [hasError, setHasError] = useState(false);
    const { data, error } = useInstallationLog({
        variables: { id: installationId },
        enabled: !hasError,
        refetchInterval: isRefetch ? 10000 : false,
    });
    const [headerHeight, setHeaderHeight] = useState<number>(132);

    useEffect(() => {
        if (error) {
            setHasError(true);
        }
    }, [error]);

    useLayoutEffect(() => {
        const header = document.querySelector('.pul-drawer-header');

        if (header) {
            setHeaderHeight(header.clientHeight);
        }
    }, []);

    if (error && error.response?.status === 404) {
        return translate('logNotFound');
    }

    if (!data) {
        return <ContentLoader />;
    }

    return (
        <ConsoleOutput
            {...props}
            className={styles.consoleOutput}
            // @ts-expect-error
            style={{ '--header-height': `${headerHeight}px` }}
        >
            {data}
        </ConsoleOutput>
    );
};
