// Copyright 2024. WebPros International GmbH. All rights reserved.

import { WebsocketProvider } from '@platform360/libs/shared-web/websocket';
import { WS_NAMESPACE } from '@platform360/admin-panel/shared/websocket';
import Router from './routes';

const App = () => (
    <WebsocketProvider namespace={WS_NAMESPACE}>
        <Router />
    </WebsocketProvider>
);

export default App;
