// Copyright 2024. WebPros International GmbH. All rights reserved.

import { getInstallationLog } from '@platform360/web-installer/web/api/web-installer';
import { createQuery } from '@platform360/libs/shared-web/query';
import { useQuery } from '@tanstack/react-query';

export type UseInstallationLogVariables = {
    id: string;
};

export type UseInstallationLogData = PromiseValue<ReturnType<typeof getInstallationLog>>;

const useInstallationLog = createQuery<UseInstallationLogVariables, UseInstallationLogData>({
    queryName: 'web-installer/useInstallationLog',
    fetcher: getInstallationLog,
    useQuery: (options) =>
        useQuery({
            refetchInterval: 10000,
            ...options,
        }),
});

export default useInstallationLog;
