// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link } from './Link';

export const FirewallLinks = () => {
    const translate = useTranslate('Servers.AddServerControlStatus.FirewallLinks');
    const pleskFirewallLinuxHref =
        'https://www.plesk.com/kb/support/how-to-manage-local-firewall-rules-using-plesk-firewall-in-plesk-for-linux-2/';
    const pleskFirewallWindowsHref = 'https://www.plesk.com/kb/docs/the-plesk-firewall-windows/';
    return (
        <>
            <br />
            <Link href={pleskFirewallLinuxHref}>{translate('plesk.linux')}</Link>
            <br />
            <Link href={pleskFirewallWindowsHref}>{translate('plesk.windows')}</Link>
        </>
    );
};
