// Copyright 2024. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import { Icon, Tooltip } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './RiskRankTitle.module.css';

type RiskRankTitleProps = {
    className?: string;
};

const RiskRankTitle = ({ className }: RiskRankTitleProps) => {
    const translate = useTranslate('security-dashboard.RiskRankTitle');

    return (
        <div className={classNames(styles.container, className)}>
            {translate('title')}&nbsp;
            <Tooltip title={translate('description')}>
                <Icon size="12" name="question-mark-circle" intent="inactive" />
            </Tooltip>
        </div>
    );
};

export default RiskRankTitle;
