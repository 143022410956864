// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copied to clipboard.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'You are impersonating a different account.',
            depersonateButton: 'Stop Impersonating',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'You are no longer impersonating a different account.',
        },
        MainMenu: {
            betaVersion: 'Beta version',
            servers: 'Servers',
            websites: 'Websites',
            licenses: 'Licenses',
            monitoring: 'Monitoring',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Monitoring',
            feedback: 'Your feedback',
            services: '360 services',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Search...',
            },
            ClientResultGroup: {
                clients: 'Clients',
                clientsShowMore: '{showMore, plural, one{# more client...} other{# more clients...}}',
            },
            DomainResultGroup: {
                domains: 'Websites',
                domainsShowMore: '{showMore, plural, one{# more website...} other{# more websites...}}',
            },
            ServersResultGroup: {
                servers: 'Servers',
                serversShowMore: '{showMore, plural, one{# more server...} other{# more servers...}}',
            },
            search: 'Search...',
            noResults: 'Nothing found...',
        },
        UserMenu: {
            team: 'Organization',
            logout: 'Log out',
            changePassword: 'Change password',
            viewProfile: 'Personal profile',
            businessProfile: 'Business profile',
            apiTokens: 'API tokens',
        },
    },
    limit: 'Limit:',
    upgradePropertiesButton: 'Adjust',

    common: {
        errors: {
            isNotEmpty: 'This required field is empty.',
            required: 'This required field is empty.',
            url: 'The value is not a valid URL.',
            isUrl: 'The value is not a valid URL.',
            email: 'The value must be a valid email address.',
            isEmail: 'The value must be a valid email address.',
            json: 'The value must be valid JSON.',
            min: '{min, plural, one{The value must be at least # character long.} other{The value must be at least # characters long.}}',
            maxLength: '{max, plural, one{The value must be at most # character long.} other{The value must be at most # characters long.}}',
            isHttps: 'The server’s hostname must begin with https://.',
            unique: 'The value must be unique.',
            isPublicIp: 'The server’s hostname should resolve to a public IP address.',
            unknown: 'Unknown error: {message}',
            externalRedirect: 'Something went wrong.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'You must enter a password.',
            errorPasswordIsWrong: 'Wrong password.',
            errorPasswordIsTooWeak: 'The password is too weak.',
            errorPasswordContainsPersonalInfo: 'The password must not contain personal information.',
            errorPasswordTooCommon: 'The password is too common.',
            errorPasswordPreviouslyUsed: 'The password has been used previously.',
            errorInvalidOtp: 'The code you entered is not valid.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Confirm subscription',
            payment: 'Payment',
        },
        general: {
            buyNow: 'Buy now',
            confirm: 'Confirm',
            confirmAndFeedback: 'Confirm and Submit Feedback',
            cancel: 'Cancel',
        },
        tabTitle: 'All licenses',
        LinkedEmails: {
            tabTitle: 'Linked emails',
            emailColumn: 'Email',
            statusColumn: 'Status',
            totalRows: '{totalRows, plural, one{# email total} other{# emails total}}',
            verifiedStatus: 'Verified',
            addButton: 'Link more emails',
            deleteButton: 'Unlink email',
            AddEmails: {
                stepOne: {
                    title: 'Linking emails with bound licenses',
                    emailsLabel: 'Email addresses used to purchase licenses',
                    submitButton: 'Send verification',
                },
                stepTwo: {
                    title: 'Verification letters have been sent',
                    description: 'Check the mailboxes for messages and follow the verification links. You might need to re-login to this account to complete adding new linked emails. The links will expire in 1 hour.',
                    emailColumn: 'Email',
                    submitButton: 'Done',
                    resendButton: 'Resend',
                },
            },
            Onboarding: {
                title: 'Link your email addresses to this account to see more licenses',
                description: 'To start managing more of your licenses, enter the email addresses which were used to purchase them.',
                docsLink: 'More info',
                addEmailsButton: 'Add email addresses',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Buy Plesk',
                    monitoring: 'Buy 360 Monitoring',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Here you can view and manage your licenses',
                    description1: 'Click "Buy a new license" if you do not have any licenses.',
                    description2: 'If you purchased licenses with other email addresses, click "Bind existing licenses" to add them.',
                    docsLink: 'More info',
                    buyButton: 'Buy a new license',
                    addEmailsButton: 'Bind existing licenses',
                },
            },
        },
        CB: {
            paymentInfo: 'The Plesk Online Store operated by {companyName} will process this order using the payment method attached to the selected subscription',
            confirmSubscription: 'By submitting this order, you agree that you are signing up for a subscription product that will be billed in regular intervals. Continued use of the purchased product is contingent upon your payment of the subscription. Details about the price and frequency of the billing appear in the above shopping cart.',
            buyInfo: 'By submitting this order, you agree to the <termsLink>Terms & Conditions</termsLink> of Cleverbridge and confirm that you have read and understood the <policyLink>Privacy Policy</policyLink> of Cleverbridge and your <revocationLink>Right of revocation</revocationLink> as a consumer.',
            links: {
                terms: 'Terms & Conditions',
                contact: 'Contact',
                revocation: 'Right of revocation',
                security: 'Security',
                legal: 'Legal Info',
                policy: 'Privacy Policy',
            },
            footer: 'You are ordering via our online reseller {companyName}. Payment processing and order fulfillment are done by {companyName}, {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Cologne, Germany',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654, United States',
            },
        },
        Renew: {
            drawer: {
                title: 'Reactivate the subscription',
            },
            form: {
                submitButton: 'Confirm Renewal',
                confirmText: 'By clicking the button below, you confirm the renewal of your subscription to WebPros International GmbH’s "{product}"',
            },
            buttonTitle: 'Reactivate subscription',
            successMessage: 'The subscription for {product} has been renewed. Its license key will take a while to become active',
            unavailable: 'It is not possible to renew this subscription (for example, it could have been discontinued). Please buy a new product.',
        },
        Adjust: {
            delayedChangesProducts: 'Until the end of the billing period ({date}), the following limit(-s) will remain the same',
        },
        Cancel: {
            buttonTitle: 'Unsubscribe',
            successMessage: 'The subscription for {product} has been canceled. You can keep using it until {date}',
            popover: {
                title: 'Unsubscribe from {product}',
                message: 'This will stop future charges. You can keep using the license until {date} (the current billing period will not be refunded).',
            },
        },
        Payment: {
            card: {
                expired: 'Your card has expired',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'You can change the payment method in the settings of your subscription',
                    confirm: 'Got it',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Cancel',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Platform 360 uses ZendeskChat, a third party application.',
            description2: 'By proceeding, I hereby agree to use ZendeskChat, a third party application. This may involve my personal data (for example, IP address) being transferred to third parties in or outside of Europe. For more information, read our <a>Privacy Policy</a>.',
            confirmButton: 'Launch ZendeskChat',
        },
        supportFormPopover: {
            description: 'No agents are available at the moment. Please wait or use our ticket form to submit a request.',
            confirmButton: 'Open Form',
        },
        ChatButton: {
            title: 'Support',
        },
    },

    keysPageTitle: 'Licenses',
    goBack: 'Back to Home Screen',
    keysPageDescription: 'Manage licenses bought in Plesk Online Store.',
    keysToolbarBuyTitle: 'Buy',
    keysToolbarFilterTitle: 'Filter',

    keyAdditionalActionsToolbarTitle: 'Additional actions',
    keyMoreDetails: 'More Details',

    keyForeignCart: 'This license is not owned by you',
    keyForeignDescriptionTitle: 'You cannot manage the license because it belongs to another account. This may happen because of the following reasons:',
    keyForeignDescriptionHosting: 'Your Plesk license belongs to your hosting provider.',
    keyForeignDescriptionWrongEmail: 'You bought the license using a different account. To see and manage the license, log in with the email corresponding to that account.',

    keyTrialDescription: 'This is a trial license. Order a {link}?',
    keyTrialDescriptionLink: 'paid license',

    keyTerminatedCart: 'This license has been terminated',
    keyTerminatedDescription: 'This license is no longer valid. ',
    keyTerminatedDescriptionRetail: 'You will not be charged for it anymore.',

    keyPartnerCart: 'This license was not bought in Plesk Online Store.',
    keyPartnerDescription: 'No billing details can be shown for this license.',

    keyLicenseDetailsTitle: 'License details',
    keyLicenseDetailsCreationDate: 'Creation date:',
    keyLicenseDetailsActivationCode: 'Activation code:',
    keyBillingDetailsTitle: 'Billing details',
    keyBillingDetailsTrial: 'This is a trial license, which expires on {date}. <a>Buy a license</a>.',
    keyBillingDetailsNotRetail: 'No billing details can be shown for this license because it was not bought in Plesk Online Store.',
    keyBillingDetailsTerminated: 'Your subscription was cancelled.',
    keyBillingDetailsSubscriptionId: 'Subscription ID:',
    keyBillingDetailsCycle: 'Billing cycle:',
    keyBillingDetailsForeign: 'No billing details can be shown for this license because it belongs to another account.',
    keyServerDetailsTitle: 'Server details',
    keyServerDetailsAddresses: 'Server IP addresses:',
    keyServerDetailsOs: 'Server OS:',
    keyServerDetailsProductVersion: 'Product version:',
    keyServerDetailsAbsent: 'This license does not seem to be installed on a Plesk server yet.',

    pleskUpgradedMessage: 'You have upgraded the {from} license ({id}) to {to}.',
    propertyUpgradedIncreaseMessage: 'The {keyName} license capacity has been increased to {to} {name}',
    propertyUpgradedDecreaseMessage: 'The {keyName} license capacity has been decreased to {to} {name}. The changes will take effect in the new billing period',
    keyUpgradedMessage: 'You have upgraded the {from} license ({id}) to {to}. Your {to} license ({id}) is assigned to the {parentId} Plesk license.',
    keyAddedMessage: 'You have purchased the {addon} license. It is assigned to the {parent} license: {parentId}.',
    refreshSuggestion: 'If you do not see these changes, <a>refresh the page</a>.',

    upsellPageTitle: 'Add new extension to your license',
    yearlyPrices: 'Yearly prices',
    monthlyPrices: 'Monthly prices',
    subscriptionForBillingPeriodNotFound: 'The billing periods of the extension license and the subscription do not match, or no Plesk licenses exist for that billing period. Choose the matching billing period or buy a Plesk license in the Online Store.',
    subscriptionToUpgradeHeader: 'Subscription to upgrade',
    selectedSubscription: '{subscriptionId} (currency: {billingDate}; billing cycle: {billingCycle})',
    toOnlineStoreButton: 'To Online Store',
    why: 'Why?',
    whyCharge: 'The charged amount has been prorated based on the number of days remaining in the current billing period.',
    chargeNow: 'You will now be charged <b>{grossPrice}</b> (incl. VAT {vatPrice}).',
    chargeNowShort: 'You will be charged now',
    inclVatShort: 'incl. VAT {vatPrice}',
    delayedCharge: 'The limit will remain at {limit} {property} until the end of the billing period.',
    nextCharge: '<b>{grossPrice}</b> (incl. VAT {vatPrice}), starting from {nextBillingDate}',
    nextChargeShort: 'Starting from {nextBillingDate}, you will be charged',
    noProductForBillingPeriod: 'There are no products for the selected billing period.',
    confirmationPopupTitle: 'Upgrade your current subscription',

    upgradePageTitle: 'Upgrade License',
    upgradePageDescription: 'Here you can upgrade your license.',
    upgradePanelBeforeTitle: 'Before Upgrade',
    upgradePanelOptionsTitle: 'Upgrade Options',
    upgradePanelAfterTitle: 'After Upgrade',
    upgradePanelOptionsRecommendationsTitle: 'Check our recommendations:',
    upgradePanelOptionsRecommendationsAlt: 'Or {link} below.',
    upgradePanelOptionsRecommendationsAltLink: 'browse all possible options',
    upgradeSuggestion: 'Add products to your license',
    upgradeSuggestionFreeCost: 'Try for free!',
    upgradeSuggestionDescription: '(see product details for info)',
    upgradeMultiOfferSuggestion: 'Choose a different plan',
    upgradeConfirm: 'Buy now',
    upgradeAccept: 'Confirm',
    upgradeBack: 'Back to Product List',
    planUpdate: 'Plan update',
    adjustPending: 'Adjust for this license is already pending. Please try again in 30 seconds.',
    waitAMoment: 'Wait a moment',
    upgradeConfirmPriceRecalculation: 'You will be now charged {chargeInfo} (total prorated price based on the number of days left until your next billing date, {nextBillingDate}). Starting from {nextBillingDate}, you will be charged {renewalInfo} for this product.',
    buyNowNotification: 'Click "Buy now" to proceed with the license purchase or upgrade. You will be redirected to Plesk Online Store, operated by Cleverbridge AG. The store will use the payment method previously used for purchasing or upgrading your license. All payments will be processed by Cleverbridge AG.',
    paymentDetailsLinkText: 'View and edit payment details for this transaction.',
    upgradeDoneRedirectToPleskTitle: 'Purchase successful',
    upgradeDoneRedirectToPleskDescription: 'The license installation might take some time.',
    upgradeDoneRedirectToPleskButton: 'Back to Plesk',
    upgradeDoneRedirectToKeys: 'View my licenses',
    total: 'TOTAL',
    vat: 'VAT',
    newTotal: 'NEW TOTAL',
    newVat: 'NEW VAT',

    firstLoginWithoutEmailVerification: 'Thank you for signing up for the Plesk 360 service. We sent you an email with a verification link. Follow the link to verify your account and log in. Then you can start managing your licenses in Plesk 360.',
    pleaseVerifyEmailTitle: 'Verify your Plesk 360 account',
    secondLoginWithoutEmailVerification: 'You are trying to log in to an account that has not yet been verified. Check your inbox for an email with a verification link. Follow the link to verify your account and log in. Then you can start managing your licenses in Plesk 360.',
    userBlocked: "The account was suspended due to a violation of the user's agreement. If you believe this is a mistake, please write to {emailLink}.",

    errorDefault: 'Something went wrong. Please try again later.',
    errorDefaultWithReqId: 'Something went wrong. Please try again later. Request ID: {requestId}.',
    errorTooManyRequests: 'API call limit reached for this IP address. Please try again in a minute.',
    errorTokenRenewal: 'Your session has expired. Please re-login.',
    errorNetworkIssue: 'It seems that you are having trouble connecting to the network right now.',

    errorKAUnavailable: 'The service is temporarily unavailable. Please try again later.',
    errorKAUnavailableWithReqId: 'The service is temporarily unavailable. Please try again later. Request ID: {requestId}.',

    footerPurposeExtension: {
        text: 'Find more extensions in the <a>Plesk Extensions Catalog</a>.',
    },

    filterAll: 'All Licenses',
    filterActive: 'Active Only',
    renewalCost: 'Renewal cost:',
    nextBillingDate: 'Next billing date:',
    canceledSubscriptionDate: 'The subscription was canceled. Your license is valid until',
    paymentHistory: 'payment history',
    licenseStatus: 'License status:',
    upgradeButton: 'Upgrade',
    upsellButton: 'Add Extension',
    upsellText: 'A new extension will be added to your Plesk',
    billingPeriod: 'Billing Period',
    features: 'Additional features:',
    noFeatures: 'No additional features',
    toPlesk: 'Go To Plesk',
    expiresNote: '(expires on {date})',
    startingFrom: 'starting from',
    billingIntervalMonth: '{value} / month',
    billingIntervalYear: '{value} / year',
    billingIntervalOnce: '{value} / once',
    perOne: 'per unit',

    paymentMethod: 'Payment method',
    editPaymentMethod: 'Edit payment method',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'added',
    addresses: 'addresses',
    back: 'Back',

    monthly: 'Monthly',
    on: 'on',
    plesk: 'Plesk',
    product: 'Product',
    price: 'Price',
    products: 'Products',
    purchased: 'Purchased',
    select: 'Select',
    sinceNote: '(since {date})',
    show: 'Show',
    upgrade: 'upgrade',
    yearly: 'Yearly',

    isEmpty: 'This field is required.',
    errorPasswordIsEmpty: 'The password is required.',
    errorPasswordIsWrong: 'Wrong password.',
    errorPasswordIsTooWeak: 'The password is too weak.',
    errorEmailOrPasswordIsIncorrect: 'Incorrect email or password.',
    errorIncorrectEmail: 'Incorrect email address.',
    errorUserDoesntExist: 'The user does not exist.',
    errorUserExist: 'The user already exists.',
    errorUserIsNotVerified: 'The user exists, but has not been verified. We have sent a new verification email. Check your mailbox.',
    errorUnknownError: 'Unknown error.',
    errorEmailIsAlreadyLinked: 'This email address is already linked to your account.',
    errorLinkIsOutdated: 'This verification link has expired.',
    errorBounceOrComplaint: 'This email address does not exist or has blocked our message as spam. Please contact the Plesk 360 support team.',
    errorUserEmailAlreadyVerified: 'This email address has already been verified.',
    errorDoResourceIdExist: 'This Digital Ocean account is already registered.',
    errorCopyToClipboard: 'A copying error occurred, try copying manually.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH will process your provided data to process your request in accordance to its <a>Privacy Policy</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'The user does not exist.',
            bounceOrComplaint: 'This email address does not exist or has blocked our message as spam. Please contact the Plesk 360 support team.',
            userIsNotVerified: 'The user exists, but has not been verified. We have sent a new verification email. Check your mailbox.',
            userExist: 'The user already exists.',
            errorPasswordIsEmpty: 'The password is required.',
            errorPasswordIsWrong: 'Wrong password.',
            errorPasswordIsTooWeak: 'The password is too weak.',
        },
        AuthContainer: {
            tabs: {
                login: 'Login',
                signUp: 'Sign-up',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Welcome to Plesk 360',
                text1: 'Empowering Builders to Monitor & Manage Servers, Easily.',
                text2: 'Get fully <b>integrated site & server monitoring</b> to keep track of performance and prevent downtime.',
                text3: 'Upgrade to <b>configurable dashboards</b>, personalized alerts, one-minute checks, network history, and more.',
                text4: 'Manage your <b>server inventory</b>, view all your Plesk licenses, and buy or update subscriptions.',
                text5: 'All from one platform under one single account.',
            },
            MonitoringPageIntro: {
                title: 'Welcome to 360 Monitoring',
                text1: 'Empowering Builders to Monitor & Manage Servers, Easily.',
                text2: 'Fully integrated <b>uptime & server monitoring</b> to track performance and prevent downtime.',
                text3: '<b>Configurable dashboards</b>, personalized alerts, one-minute checks, server metrics & I/O, networking performance and custom metrics through plugins. ',
                text4: 'Multi-cloud and platform <b>agnostic</b>.',
            },
        },
        SignUp: {
            signUp: 'Sign up',
            loginLink: 'Go to login screen',
            emailLabel: 'Email',
            emailDescription: 'Better use the email that you used for buying Plesk and extensions',
            passwordLabel: 'Password',
            privacyPolicyAgreementTitle: 'I agree to the <a>{termsLink}</a>',
            termsPlatform: 'Plesk 360 Terms of Use',
            termsMonitoring: '360 Monitoring Terms of Use',
            privacyPolicyAgreementDescription: 'For the purpose of creating and maintaining my account in the platform, WebPros International GmbH and other WebPros group companies will process the provided information in their internal systems according to the <a>Privacy Policy</a> for as long as my account is active.',
            verifyAccountTitle: 'Verify your account',
            verifyAccountText: 'We sent an email with a verification link to {email}. Click the link to verify your account and log in.',
            resendDescription: 'Did not receive the email? Check your Spam folder or click Resend to get a new one.',
            resendLabel: 'Resend',
            retryCountdown: 'Resend is possible in {time}',
            SignUpByInvitation: {
                emailDescription: 'You will be signed up with the email above',
                invitationSucceed: 'You successfully signed up. Please, log in now.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'First name',
                    lastNamePlaceholder: 'Last name',
                    nameLabel: 'Name',
                },
            },
        },
        Login: {
            signUpButton: 'Sign up',
            emailLabel: 'Email',
            passwordLabel: 'Password',
            submitButton: 'Log In',
            forgotYourPasswordLink: 'Forgot your password?',
        },
        ResetPassword: {
            title: 'Reset password',
            text: 'Enter your email address and we will send you an email with a password reset link.',
            emailLabel: 'Email:',
            submitButton: 'Send',
            successTitle: 'Password Reset Email Sent',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Resend',
            loginForm: 'Log in now',
            retryCountdown: 'Resend is possible in {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. All rights reserved. Plesk and the Plesk logo are trademarks of WebPros International GmbH.',
        followPlesk: 'Follow Plesk:',
        followCPanel: 'Follow cPanel:',
        company: 'Company',
        aboutCPanel: 'About cPanel',
        aboutPlesk: 'About Plesk',
        legal: 'Legal',
        cookiehub: 'Cookie Settings',
        privacyPolicy: 'Privacy Policy',
        impressum: 'Impressum',
        knowledgeBase: 'Knowledge Base',
        documentation: 'Documentation',
        helpCenterCPanel: 'cPanel Help Center',
        helpCenterPlesk: 'Plesk Help Center',
        contactUs: 'Contact Us',
        pleskLifecyclePolicy: 'Plesk Lifecycle Policy',
        community: 'Community',
        blogCPanel: 'cPanel Blog',
        blogPlesk: 'Plesk Blog',
        forumsCPanel: 'cPanel Forums',
        forumsPlesk: 'Plesk Forums',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Required fields',
        submitButton: 'OK',
        cancelButton: 'Cancel',
    },

    FormField: {
      addMore: 'Add one more',
      remove: 'Remove',
      clear: 'Clear',
    },

    FormFieldPassword: {
        improvePassword: 'For a more secure password:',
        passwordTooShort: 'Make the password longer.',
        lettersLowerCase: 'Use at least one lowercase character.',
        lettersUpperCase: 'Use at least one uppercase character.',
        numbers1: 'Use at least one number.',
        numbers3: 'Use at least three numbers.',
        specialChar1: 'Use at least one special character (for example: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Use at least two special characters (for example: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Use both uppercase and lowercase characters.',
        comboLettersAndNumbers: 'Use both numbers and characters.',
        comboLettersNumbersSpecial: 'Use numbers, characters, and special characters.',
        yourPasswordIsStrong: 'You can make it even stronger by increasing its length.',
        passwordStrength: 'The password strength is %%strength%%.',
        strengthVeryStrong: 'VERY STRONG',
        strengthStrong: 'STRONG',
        strengthVeryWeak: 'VERY WEAK',
        strengthWeak: 'WEAK',
        strengthMedium: 'MEDIUM',
        hidePassword: 'Hide password',
        showPassword: 'Show password',
        generateButton: 'Generate',
        generateButtonHint: 'Generate a strong password',
    },

    Pagination: {
        totalShort: 'of %%total%%',
        itemsPerPage: 'Items per page',
        all: 'All',
        total: 'Page %%current%% of %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Could not find any items matching the filter',
        emptyTitle: 'Looks like there is nothing here',
        filteredDescription: 'Refine your query or remove some of the criteria from the filter and try again.',
    },

    Dialog: {
        cancelButton: 'Cancel',
    },
    keyNotUnderOnlineStore: 'You cannot upgrade the license in Plesk 360 because the license was not bought in Plesk Online Store. If you bought the license from your hosting provider, contact them for upgrade options.',
    notOwnLicense: 'You cannot upgrade the license in Plesk 360 because the license belongs to a different account. If you bought the license from your hosting provider, contact them for upgrade options.',
    subscriptionNotFound: 'Could not find the subscription’s data',
    unableToUpgrade: 'The upgrade is not available. Please contact Plesk Support',
    unknownError: 'The service is temporarily unavailable. Please try again later.',
    pendingOperationFound: 'Cannot perform the current operation because the previous one (for example, the change of the payment method) was not finished. Finish the previous operation to proceed.',

    LinkEmail: {
        title: 'Link Email',
        tryAgain: 'Try again',
    },

    GaOptOutLink: {
        gaSuccess: 'Google Analytics has stopped tracking.',
    },

    Servers: {
        sync: 'Sync',
        syncNoSelection: 'You have not selected any server to synchronize. Do you want to synchronize them all?',
        syncAll: 'Sync all',
        serverNameColumn: 'Server name',
        productColumn: 'Product',
        panelColumn: 'Hosting control panel',
        stateColumn: 'Server state',
        stateLastSync: 'Last synced {duration}',
        stateLastSyncNow: 'Last synced just now',
        login: {
            plesk: 'Go to Plesk',
            cpanel: 'Go to cPanel',
            wp: 'Go to WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{The <b>{title}</b> server is scheduled to be synchronized.} one{<b>#</b> server is scheduled to be synchronized.} other{<b>#</b> servers are scheduled to be synchronized.}}',
        updateServer: 'Update {product}',
        cancel: 'Cancel',
        Toolbar: {
            add: 'Add',
            disconnect: 'Disconnect',
            sync: 'Sync',
            groupSearch: 'Search',
        },
        Onboarding: {
            title: 'Here you can add and manage your Plesk servers',
            description: 'Add your servers to have one-click access to the control panel.',
            docsLink: 'More info',
            addServersButton: 'Connect servers',
            feedbackButton: 'Leave feedback',
            installPleskButton: 'Install Plesk to a new server',
        },
        FeedbackButton: {
            heading: 'Leave your feedback',
            subHeading: 'This will help us with future development.',
        },
        Tour: {
            next: 'Next',
            previous: 'Previous',
            gotit: 'Got it',
            step1: {
                title: 'Introducing Server Inventory',
                description: 'A tool for multiple server management in Plesk 360. Add your Plesk servers securely from the Server tab or from Plesk.',
            },
            step2: {
                title: 'Introducing Server Inventory',
                description: 'The Server tab also contains searchable lists of all customers and domains hosted on your servers.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Confirm connecting your server to "Plesk 360"',
            description: 'Your {product} control panel at <b>{address}</b> will be connected to the <b>{email}</b> account at Plesk 360. Ensure that this account belongs to you before confirming!',
            accessTitle: 'What will be allowed',
            accessMessage1 : '<b>Plesk 360</b> will have administrative access to Plesk control panel on <b>{address}</b>.',
            accessMessage2 : '<b>Plesk 360</b> will allow <b>{email}</b> account to:',
            accessItem1: 'log in to Plesk as administrator or any other user without submitting credentials,',
            accessItem2: 'retrieve lists of clients and websites hosted on the server,',
            accessItem3: 'activate the server’s external monitoring,',
            accessItem4: '(in the future) perform some other actions.',
            buttons: {
                confirm: 'Confirm',
                confirmAnyway: 'Confirm anyway',
                nextStep: 'Next step',
                deny: 'Deny',
            },
            enableIpRestrictionTitle: 'API key details',
            fields: {
                enableIpRestriction: 'Enable API key IP address restriction, allowed IPs:',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'The server must be accessible from the Internet.',
                certificate: 'The server must be secured with a valid SSL/TLS certificate.',
            },
            success: {
                access: 'Plesk installation is accessible from the internet.',
            },
            ServerAccessError: {
                urlIsRequired: 'Please enter server’s URL.',
                urlMaxLength: "{max, plural, one{Server’s URL should be less than # character.} other{Server’s URL should be less than # characters.}}",

                urlInvalid: {
                    title: 'We can not work with provided server address.',
                    description: 'Usually, this happened when providing "http" instead of "https" in the address or mistyping in URL, like missing top-level domain (.com, .org etc).',
                },

                urlPrivateIp: {
                    title: 'Unfortunately, we can’t reach your server because it is not available.',
                    description: 'Server address’ host is an IP address that is not a public one.',
                },

                serverAlreadyAdded: 'This server is already added. <a>Open server details.</a>',

                domainNotResolved: {
                    title: 'Unfortunately, we can’t reach your server because it is not available.',
                    description: 'Server address domain name cannot be resolved by Plesk 360.',
                },

                domainResolvedPrivateIp: {
                    title: 'Unfortunately, we can’t reach your server because it is not available.',
                    description: 'Please make sure you have public IP assigned to this domain.',
                },

                timeout: {
                    title: 'Unfortunately, we can’t reach your server because it is not available.',
                    description: 'Please check your firewall settings and allow the specified port from {publicIps}.',
                },

                connectionAborted: {
                    title: 'Unfortunately, we can’t reach your server because it is not available.',
                    description: 'Please check your firewall settings and allow the specified port from {publicIps}.',
                },

                connectionRefused: {
                    title: 'The network connection was refused.',
                    description: 'Please check the specified domain and port.',
                },

                badProtocol: 'Provided address should be protected by valid SSL certificate.',

                notAuthenticated: {
                    title: 'The connection is not authorized by the remote server.',
                    description: 'Please add the server to Server Inventory again.',
                },

                notAuthorized: 'Connection blocked.',

                unsupportedServerVersion: {
                    title: 'The server cannot be added. Please contact support.',
                    description: 'The installed Plesk version is outdated and cannot be added.{minSupportedVersion, select, _ {} other { Only Plesk Obsidian {minSupportedVersion} and later is supported.}} <a>Learn how to update Plesk.</a>',
                },
                serverMisconfigured: {
                    title: 'The server cannot be added. Please contact support.',
                    description: 'The server is misconfigured and cannot be added.',
                },
                pleskConfiguration: {
                    title: 'We can’t add this server. Please contact support.',
                    description: 'Plesk version is not supported (below {pleskMinVersion}) or Plesk is misconfigured.',
                },

                unknownError:  {
                    title: 'The server cannot be added. Please contact support.',
                    description: 'Unknown error: {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'How to manage local firewall rules using Plesk Firewall in Plesk for Linux.',
                    windows: 'The Plesk Firewall (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'This Plesk installation has an expired certificate configured.',
                    description: 'It is not secure to use such a Plesk configuration and connect it to Plesk 360. We recommend to configure self-signed or CA-signed certificate. By clicking "Next", you agree that this configuration is insecure.',
                },
                certInvalid: {
                    title: 'This server\'s certificate is confirmed to work with another domain name.',
                    description: 'The certificate is issued not for this domain name, or you entered the IP address instead of the valid server\'s domain name. We included more information in <a>this article</a> if you are sure the provided URL is valid.',
                },
                certSelfSigned: {
                    title: 'Remote server has a self-signed certificate configured.',
                    description: 'You can add a Plesk installation to Plesk 360, but we strongly recommend using CA-signed certificate, like Let\'s Encrypt, to protect your Plesk from possible security issues.',
                },
                certUntrustedCa: {
                    title: 'Remote server certificate’s CA is not trusted by Plesk 360.',
                    description: 'You can add a Plesk installation to Plesk 360, but we strongly recommend using CA-signed certificate, like Let\'s Encrypt, to protect your Plesk from possible security issues.',
                },
                agreeInvalidCertCheckbox: 'I agree to add this server without a valid certificate',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'I would like to add an unsecured server {hostname} to my Plesk 360 account',
            understandSecurityIssues: 'I understand that my server is vulnerable to various network security issues',
            understandDataCanLost: 'I understand that I should add a valid certificate as soon as possible otherwise access to my Plesk installation and data can be lost',
            agreeNotResponsibility: 'By clicking "Connect anyway", I agree that Plesk 360 is not responsible for the safety of this server.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Disconnect',
        noSelectedServers: 'Select one or more servers to disconnect.',
        disconnectConfirmation: '{count, plural, =1{Disconnect <b>{title}</b>?} one{Disconnect <b>#</b> server?} other{Disconnect <b>#</b> servers?}} You cannot undo this action.',
        notifyDisconnectSuccess: '{count, plural, =1{The <b>{title}</b> server has been disconnected.} one{<b>#</b> server has been disconnected.} other{<b>#</b> servers have been disconnected.}}',
        notifyDisconnectFail: '{count, plural, =1{The <b>{title}</b> server has not been disconnected.} one{<b>#</b> server has not been disconnected.} other{<b>#</b> servers have not been disconnected.}}',
    },
    ViewServer: {
        title: 'Server details',
        inGeneral: 'Overview',
        domains: 'Websites',
        clients: 'Clients',
        installationLog: 'Installation logs',
        address: 'Address',
        notes: 'Notes',
        product: 'Product',
        version: 'Version',
        unknownVersion: 'unknown',
        showErrorDetail: 'Show more.',
        allowedInvalidCertificateWarning: 'Connecting to the server may be unsafe due to SSL/TLS certificate validation issues.',
        allowedInvalidCertificateRisksNote: 'You chose to ignore the certificate validation outcome and accepted all the related risks.',
        allowedInvalidCertificateLinkToDocs: 'Learn more about issues and risks.',
        certificateErrorStatusMessage: 'The connection to the server has been disabled after the following SSL/TLS certificate issue was detected when connecting to the server {serverName}',
        certificateErrorFixDescription: 'Please fix the error and recheck the certificate to restore the connection. Or ignore the certificate validation outcome and accept <a>all the related risks</a>.',
        relatedRisks: 'all the related risks',
        ignoreCertificateErrorsButton: 'Ignore the certificate check and restore',
        ignoreCertificateConfirmation: 'Confirm that you acknowledge and accept all the risks related to the certificate issues.',
        confirmAndProceed: 'Confirm and proceed',
        recheckCertificateButton: 'Recheck the certificate',
        recheckResultSuccess: 'The connection has been re-established successfully.',
        recheckResultCertificateError: 'Certificate validation failed with the following error: {errorMessage}',
        recheckResultConnectionError: 'Plesk 360 cannot establish a connection to {serverName}.',
        recheckResultConnectionBlocked: 'Connection to the server {serverName} is blocked',
        connectionErrorStatusMessage: 'The connection to the server {serverName} cannot be established correctly.',
        connectionErrorFixDescription: 'Please fix the error and press the button below to restore the connection.',
        updatesButton: '{product} Updates',
        login: {
            plesk: 'Go to Plesk',
            cpanel: 'Go to cPanel',
            wp: 'Go to WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'The server is configured so that it is impossible to establish a connection. Possible causes:',
            caseRestrictionManagement: 'Platform 360 IP addresses are filtered out for Administrator’s access (<a>instructions</a>)',
            casePanelIniApiSection: 'Remote access via Plesk API is disabled in panel.ini (<a>instructions</a>)',
            listPublicIps: 'Make sure, the access from the following IP addresses is allowed: <b>{publicIps}</b>.',
            fixDescription: 'To connect the server successfully, please adjust the corresponding configuration settings. When done, click the button below to check the connection.',
            recheckConnectionButton: 'Recheck the connection',
        },

        recheckConnectionButton: 'Recheck the connection',
        sync: 'Sync',
        state: 'Server state',
        Domains: {
            name: 'Name',
            hostingType: 'Hosting Type',
            virtualHostingType: 'Website',
            forwardingHostingType: 'Forwarding',
            noneHostingType: 'No hosting',
            mainDomainType: 'Main Domain',
            subDomainType: 'Subdomain',
            addonDomainType: 'Addon Domain',
            parkedDomainType: 'Alias',
            manage: 'Manage',
            manageLinkUnavailableHint: 'Plesk RESTful API 1.4.0 or later is required to generate a direct link to the Plesk panel. Check Documentation for more details.',
            open: 'Open',
        },
        Clients: {
            name: 'Contact Name',
            email: 'Email Address',
            login: 'User Name',
            type: 'Type',
            status: 'Status',
            typeAdmin: 'Admin',
            typeReseller: 'Reseller',
            typeCustomer: 'Customer',
            typeCustomerWithOwner: 'Customer of {owner}',
            statusActive: 'Active',
            statusSuspended: 'Suspended',
            manage: 'Manage',
        },
    },
    InPlaceText: {
        ok: 'Save (Ctrl+Enter)',
        cancel: 'Cancel (Esc)',
        edit: 'Edit',
        add: 'Add',
    },
    ServerLogin: {
        title: 'Redirecting you to the control panel at {address}',
        progress: 'This may take several seconds',
        clientNote: 'Remember that Customers / Resellers are not visible in Power User View.',
        errorTitle: 'An error has occurred when trying to connect to the server',
        errorDescription: 'Log in directly to the server by its server address using the proper credentials or try again to authorize it via Plesk 360 later.',
        btnGroupLogin: 'Log In',
        tryAgain: 'Try again',
        loginDirectly: 'Log in directly',
        btnGroupCancel: 'Cancel',
        cancel: 'Cancel',
    },
    Team: {
        Layout: {
            title: 'Organization',
            members: 'Members',
            invitations: 'Invitations',
        },
        settings: 'Settings',
        Members: {
            email: 'E-mail',
            fullName: 'Full name',
            mfaStatus: 'MFA Status',
            tags: 'Special marks',
            remove: 'Remove',
            removeMemberConfirmation: '<b>{name}</b> will no longer have access to the organization and shared resources within, servers, websites, and licenses.',
            SpecialMarks: {
                you: 'You',
                owner: 'Owner',
            },
            Onboarding: {
                title: 'Create your organization in our platform by adding your colleagues',
                description: 'Our platform offers you features for efficient organization management and collaboration. Your organization members can work with you on connected servers by accessing them directly from here. You no longer need to use insecure shared passwords or complicated access arrangements.',
                addMembersButton: 'Add organization members',
            },
            MultifactorStatus: {
                enabled: 'MFA Enabled',
                disabled: 'MFA Not Enabled',
            },
        },
        Invitations: {
            email: 'E-mail',
            expireAt: 'Expire At',
            expired: 'Expired',
            add: 'Add',
            revoke: 'Revoke',
            Onboarding: {
                title: 'Here you can invite your organizations members',
                description: 'Add your colleagues to share with them servers and websites.',
                addButton: 'Invite new member',
            },
            ListActions: {
                resendInvitationTooltip: 'Resend invite.',
                successMessage: 'Invite email sent again.',
            },
            AddInvitations: {
                title: 'Add new members to the organization',
                infoMessage: 'Invite your organization members to join by entering their work email addresses below.',
                infoDescription: 'Work email addresses are preferred to personal email addresses to safeguard users’ personal information.',
                emailsLabel: 'Email addresses for invitation link',
                submitButton: 'Send invitation',
                successMessage: '{count, plural, =1{Invite to {email} has been sent.} one{# invite has been sent.} other{# invites have been sent.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revoke',
                noSelectedInvitations: 'Select one or more invitations to revoke.',
                revokeInvitationConfirmation: '{count, plural, =1{Revoke <b>{email}</b>?} one{Revoke <b>#</b> invitation?} other{Revoke <b>#</b> invitations?}} You cannot undo this action.',
                successRevokeInvitation: '{count, plural, =1{The <b>{email}</b> email has been revoked.} one{<b>#</b> invitation has been revoked.} other{<b>#</b> invitations have been revoked.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Project updates and commercial communication',
            text: 'WebPros International GmbH and other WebPros group companies may store and process the data I provide for the purpose of delivering personalized marketing content such as newsletters and individual offers according to the <a>Privacy Policy</a>. Tailored offerings may be based on additional information like usage and behavior data (Profiling). I can revoke this consent at any time in the project preferences.',
        },
    },

    components: {
        List: {
            selectAll: 'Select all',
            deselectAll: 'Deselect',
            selectedRows: '{selectedRows, plural, one{# of {totalRows, plural, one{# item} other{# items}} selected} other{# of {totalRows, plural, one{# item} other{# items}} selected}}',
            totalRows: '{totalRows, plural, one{# item total} other{# items total}}',
            foundRows: '{foundRows, plural, one{# item found} other{# items found}}',
        },
        DataList: {
            groupSearch: 'Search',
        },
        KbInstructionsFixLink: '<a>Read this KB article</a> for instructions on how to fix this.',
    },
};
