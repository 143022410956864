// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    ToolbarGroup,
    Toolbar as UILibToolbar,
    SearchBar,
    ToolbarExpander,
    Badge,
    Button,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { InstallationOutdatedItemsResponse } from '@platform360/security-dashboard/web/api/installations';
import UpdateConfirmation from '@platform360/security-dashboard/web/components/Installations/WebsiteDrawer/TabOutdated/UpdateConfirmation';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';

type ToolbarProps = {
    selectedItems: string[];
    displayTitle: string;
    totalRows: number;
    searchValue?: string;
    onSearch?: (value: string) => void;
    filter: InstallationComponent[];
    isFilterOpen: boolean;
    onFilterClick: () => void;
    updateOutdatedHandler: () => void;
    filteredData: InstallationOutdatedItemsResponse[];
};

export const Toolbar = ({
    selectedItems,
    displayTitle,
    totalRows,
    searchValue,
    onSearch,
    filter,
    isFilterOpen,
    onFilterClick,
    updateOutdatedHandler,
    filteredData,
}: ToolbarProps) => {
    const translate = useTranslate(
        'security-dashboard.Installations.WebsiteDrawer.tabs.outdated.toolbar',
    );

    const renderUpdateButton = () => (
        <UpdateConfirmation
            onClick={updateOutdatedHandler}
            siteDisplayTitle={displayTitle}
            target={
                <Button intent="primary" disabled={filteredData.length === 0}>
                    {translate('actions.buttons.update')}
                </Button>
            }
            affectedRowsCount={selectedItems.length || totalRows}
        />
    );

    return (
        <UILibToolbar>
            <ToolbarGroup title={translate('actions.title')}>{renderUpdateButton()}</ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title={translate('filters.groupFiltersTitle')}>
                <Badge intent="info" hidden={filter.length === 0}>
                    <Button
                        icon={filter.length > 0 ? 'filter-check-mark' : 'filter'}
                        state={isFilterOpen ? 'active' : undefined}
                        onClick={onFilterClick}
                        data-type="outdated-component-filter"
                    />
                </Badge>
            </ToolbarGroup>
            <ToolbarGroup title={translate('filters.groupSearchTitle')}>
                <SearchBar
                    minWidth={350}
                    inputProps={{
                        value: searchValue,
                        placeholder: translate('filters.search.placeholder'),
                    }}
                    onSearch={(value: string) => onSearch?.(value)}
                    onTyping={onSearch}
                />
            </ToolbarGroup>
        </UILibToolbar>
    );
};

export default Toolbar;
