// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, Button, FormProps, FormValues, Panel } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './FiltersForm.module.less';

export type FiltersFormProps<TFormValues extends FormValues> = {
    onReset: () => void;
} & FormProps<TFormValues>;

export const FiltersForm = <TFormValues extends FormValues>({
    onReset,
    ...formProps
}: FiltersFormProps<TFormValues>) => {
    const translate = useTranslate('security-dashboard.FiltersForm');

    return (
        <Panel
            title={translate('title')}
            className={styles.panel}
            extra={[
                <Button ghost icon="clean" key="button" onClick={onReset}>
                    {translate('clear')}
                </Button>,
            ]}
        >
            <Form<TFormValues>
                applyButton={false}
                submitButton={{
                    children: translate('apply'),
                    size: 'md',
                }}
                cancelButton={false}
                {...formProps}
            />
        </Panel>
    );
};
