// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AxiosRequestConfig } from 'axios';

const cancelKeySubscription = (licenseId: number): AxiosRequestConfig => ({
    url: `/ka/keys/${licenseId}`,
    method: 'PUT',
    data: { keySubscriptionCancelled: true },
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
});

export default cancelKeySubscription;
