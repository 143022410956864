// Copyright 2024. WebPros International GmbH. All rights reserved.

/**
 * Checking for type belonging to the card
 * CCA is a "Credit Card Application", i think so ;-)
 * @link https://reference.cleverbridge.com/reference/payment-type
 * @param paymentTypeId
 */
const isCardPaymentType = (paymentTypeId: string): boolean => paymentTypeId.startsWith('CCA');

export default isCardPaymentType;
