// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Panel, TranslationProps } from '@plesk/ui-library';
import style from './InstallationSummary.module.css';

type InstallationSummaryCardProps = React.PropsWithChildren & {
    title: TranslationProps;
};

const InstallationSummaryCard = ({ title, children }: InstallationSummaryCardProps) => (
    <div className={style.tableItem}>
        <Panel>
            <div className={style.cardTitle}>{title}</div>
            <div className={style.cardContent}>{children}</div>
        </Panel>
    </div>
);

export default InstallationSummaryCard;
