// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { InstallationComponents, installationComponentValues } = makeEnum(
    'InstallationComponent',
    'core',
    'plugin',
    'theme',
);
export type InstallationComponent = UnionOf<typeof installationComponentValues>;
