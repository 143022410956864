// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import monitoring, { Server } from '@platform360/monitoring/web/api/monitoring';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseServersQueryVariables = {
    serverGuids: string[];
};

export type UseServersQueryData = Record<string, Server>;

const useServersQuery = createQuery<UseServersQueryVariables, UseServersQueryData>({
    queryName: 'monitoring/useServersQuery',
    fetcher: async (options) => {
        const data = await monitoring.servers(options);

        return data.reduce<Record<string, Server>>(
            (carry, server) => ({ ...carry, [server.serverGuid]: server }),
            {},
        );
    },
    useQuery,
});

export default useServersQuery;
