// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import PeriodControl from '@platform360/licenses/web/components/PeriodControl';
import changePeriod from '@platform360/licenses/web/units/extensions/actions/changePeriod';
import { StoreState } from '@platform360/licenses/web/store/types';
import BillingPeriod from '@platform360/licenses/web/types/billingPeriod';
import upsellSelector from '@platform360/licenses/web/units/extensions/selectors/upsells';

const mstp = (state: StoreState) => {
    const { period } = upsellSelector(state);

    return {
        selected: period,
    };
};

const mdtp = (dispatch: Dispatch) => ({
    onChange: (period: BillingPeriod) => {
        dispatch(changePeriod(period));
    },
});

export default connect(mstp, mdtp)(PeriodControl);
