// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Label, ProgressBar, Text, TranslationProps, Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import style from './InstallationSummary.module.css';

import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import {
    DRAWER_TAB_OUTDATED_FILTER_ID,
    DRAWER_TAB_VULNERABILITIES_COMPONENT_FILTER_ID,
} from '@platform360/security-dashboard/web/types';
import InstallationSummaryCard from './InstallationSummaryCard';
import { useInstallationUpdateComponentMutation } from '@platform360/security-dashboard/web/mutations/useInstallationUpdateComponentMutation';
import UpdateConfirmation from '@platform360/security-dashboard/web/components/Installations/InstallationSummary/UpdateConfirmation';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';
import DrawerLink from '@platform360/libs/shared-web/components/DrawerLink';

type InstallationSummaryPanelProps = {
    installationId: number;
    title: React.ReactNode;
    vulnerableText?: React.ReactNode;
    outdatedText?: React.ReactNode;
    onClickVulnerable: () => void;
    onClickUpdate: () => void;
    onClickUpdateConfirmation: () => void;
    onClickOutdated: () => void;
    updateButtonText: TranslationProps;
    componentType: InstallationComponent;
    isUpdating: boolean;
};

const InstallationSummaryPanel = ({
    installationId,
    title,
    vulnerableText,
    outdatedText,
    onClickUpdate,
    onClickUpdateConfirmation,
    onClickOutdated,
    onClickVulnerable,
    updateButtonText,
    componentType,
    isUpdating,
}: InstallationSummaryPanelProps) => {
    const translate = useTranslate('security-dashboard.Installations.InstallationSummary');
    const { mutate: updateInstallationComponent } = useInstallationUpdateComponentMutation();

    const linkToDrawerTabOutdated = `health/${installationId}/outdated`;

    const linkToOutdates = buildUrl(linkToDrawerTabOutdated, {
        [DRAWER_TAB_OUTDATED_FILTER_ID]: componentType,
    });

    const linkToDrawerTabVulnerabilities = `health/${installationId}/vulnerabilities`;

    const linkToVulnerabilities = buildUrl(linkToDrawerTabVulnerabilities, {
        [DRAWER_TAB_VULNERABILITIES_COMPONENT_FILTER_ID]: componentType,
    });

    const footerContent = (component: InstallationComponent) => {
        const onUpdateComponentHandler = () => {
            onClickUpdateConfirmation();
            updateInstallationComponent({ installationId, request: { component } });
        };

        return outdatedText ? (
            <UpdateConfirmation
                onClick={onUpdateComponentHandler}
                onShow={onClickUpdate}
                target={
                    <Link>
                        <Text bold>{updateButtonText}</Text>
                    </Link>
                }
                component={componentType}
            />
        ) : (
            <Text>{translate('upToDate')}</Text>
        );
    };
    return (
        <InstallationSummaryCard title={title}>
            {vulnerableText ? (
                <div className={style.cardLabel}>
                    <Label
                        intent="danger"
                        icon="exclamation-mark-circle"
                        size="lg"
                        view="light"
                        component={DrawerLink}
                        to={linkToVulnerabilities}
                        onClick={onClickVulnerable}
                    >
                        {vulnerableText}
                    </Label>
                </div>
            ) : null}
            {outdatedText ? (
                <div className={style.cardLabel}>
                    <Label
                        intent="warning"
                        icon="triangle-exclamation-mark"
                        size="lg"
                        view="light"
                        component={DrawerLink}
                        to={linkToOutdates}
                        onClick={onClickOutdated}
                    >
                        {outdatedText}
                    </Label>
                </div>
            ) : null}
            <div className={style.cardFooter}>
                {isUpdating ? <ProgressBar /> : footerContent(componentType)}
            </div>
        </InstallationSummaryCard>
    );
};

export default InstallationSummaryPanel;
