// Copyright 2024. WebPros International GmbH. All rights reserved.

import { START_PROCESSING } from '@platform360/licenses/web/units/extensions/constants/buyExtensionActions';

export type StartProcessingAction = {
    type: typeof START_PROCESSING;
};

const startProcessing = (): StartProcessingAction => ({
    type: START_PROCESSING,
});

export default startProcessing;
