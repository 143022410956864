// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { Label } from '@platform360/security-dashboard/web/types';
import { ApiListResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';

type GetLabelsOptions = FetcherOptions;

export type GetLabelsResponse = ApiListResponse<Label>;

export const getLabels = async ({ signal }: GetLabelsOptions): Promise<ApiListResponse<Label>> => {
    const { data } = await apiClient.get<GetLabelsResponse>('/security-dashboard/labels', {
        signal,
    });

    return data;
};

export const createLabel = async (data: { title: string }): Promise<void> => {
    await apiClient.post('/security-dashboard/labels/create', data);
};

export const editLabel = async ({
    labelId,
    ...data
}: {
    labelId: number;
    title: string;
}): Promise<void> => {
    await apiClient.put(`/security-dashboard/labels/${labelId}`, data);
};

export const deleteLabels = async (labelIds: number[]): Promise<void> => {
    await apiClient.post('/security-dashboard/labels/delete', { labelIds });
};
