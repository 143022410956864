// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Outlet, Link } from 'react-router-dom';
import { Icon } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { emailsPath, licensesUri, moduleId } from '@platform360/licenses/web/helpers';
import Page from '@platform360/libs/shared-web/components/Page';
import { useLicensesAnalyticsEvents } from '@platform360/licenses/web/helpers/analytics';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import styles from './Layout.module.css';

const Layout = () => {
    const translate = useTranslate();
    const analyticsEvents = useLicensesAnalyticsEvents();
    useEffectOnce(() => {
        analyticsEvents.licenseManagementPageOpen();
    });

    const {
        licencesModule: { homePageLink },
    } = useApplicationSettings();

    return (
        <Page
            contentAddon={
                homePageLink && (
                    <Link to={homePageLink} className={styles.homePageLink}>
                        <Icon name="chevron-left" />
                        {translate('goBack')}
                    </Link>
                )
            }
            title={translate('keysPageTitle')}
            tabs={[
                {
                    to: licensesUri,
                    title: translate('Keys.tabTitle'),
                },
                {
                    to: moduleId.concat(emailsPath),
                    title: translate('Keys.LinkedEmails.tabTitle'),
                },
            ]}
        >
            <Outlet />
        </Page>
    );
};

export default Layout;
