// Copyright 2024. WebPros International GmbH. All rights reserved.

import useListActionMutation from '@platform360/libs/shared-web/helpers/useListActionMutation';
import { forceSyncServer } from '@platform360/server-inventory/web/api/servers';
import { useQueryClient } from '@tanstack/react-query';
import { Server } from '@platform360/server-inventory/web/types';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useServersQuery from '@platform360/server-inventory/web/queries/useServersQuery';
import useServerQuery from '@platform360/server-inventory/web/queries/useServerQuery';

const useForceSyncServers = () => {
    const translate = useTranslate('Servers');
    const queryClient = useQueryClient();
    const { successToast } = useToaster();
    return useListActionMutation(forceSyncServer, {
        onSuccess: async (data) => {
            await queryClient.cancelQueries({
                queryKey: useServerQuery.getQueryKey({ id: data.id }),
            });

            await queryClient.cancelQueries({
                queryKey: useServersQuery.getQueryKey(),
            });

            useServerQuery.setQueryData(
                queryClient,
                useServerQuery.getQueryKey({ id: data.id }),
                data,
            );

            useServersQuery.setQueriesData(queryClient, useServersQuery.getQueryKey(), (cache) => {
                if (!cache) {
                    return {
                        data: [],
                        totalCount: 0,
                    };
                }

                return {
                    ...cache,
                    data: cache.data.map((server) => (server.id === data.id ? data : server)),
                };
            });
        },
        onSettled: (results) => {
            const servers: Server[] = [];
            results.forEach((result) => {
                if (result.status === 'fulfilled') {
                    servers.push(result.value);
                }
            });

            const [firstServer] = servers;
            if (!firstServer) {
                return;
            }

            successToast(
                translate('forceSyncScheduledSuccess', {
                    title: firstServer.address.hostname,
                    count: servers.length,
                }),
            );
        },
    });
};

export default useForceSyncServers;
