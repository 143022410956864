// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Checkbox } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { UpdateSettings } from '@platform360/security-dashboard/web/types';
import UpdatesTab from '@platform360/security-dashboard/web/components/UpdatesTab';
import styles from './Updates.module.css';

type UpdatesProps = {
    parentSettings?: UpdateSettings;
    overrideDescendants: boolean;
    onOverrideDescendantsChange: (newValue: boolean) => void;
    isMassOperation?: boolean;
};

const Updates = ({
    parentSettings,
    overrideDescendants,
    onOverrideDescendantsChange,
    isMassOperation,
}: UpdatesProps) => {
    const translate = useTranslate('security-dashboard.Servers.SettingsDrawer.Updates');

    return (
        <>
            <UpdatesTab parentSettings={parentSettings} isMassOperation={isMassOperation} />
            <div className={styles.customizedSection}>
                {translate('customizedSection.description')}
                <Checkbox checked={overrideDescendants} onChange={onOverrideDescendantsChange}>
                    {translate('customizedSection.checkboxDescription')}
                </Checkbox>
            </div>
        </>
    );
};

export default Updates;
