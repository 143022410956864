// Copyright 2024. WebPros International GmbH. All rights reserved.

import { CALCULATE_SUMMARY_COST } from '@platform360/licenses/web/units/adjust/constants/actions';

export type CalculateAction = {
    type: typeof CALCULATE_SUMMARY_COST;
};

export const calculate = (): CalculateAction => ({
    type: CALCULATE_SUMMARY_COST,
});
