// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Server } from '@platform360/security-dashboard/web/types';
import NoSelectionPopover from '@platform360/libs/shared-web/components/NoSelectionPopover';
import { ComponentType, ComponentProps, ReactElement } from 'react';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useServerUpdaterMutation } from '@platform360/security-dashboard/web/mutations';

type BaseProps = {
    selectedServers: Server[];
    onTaskStarting: (serversIds: number[], operation: 'update') => void;
    onTaskStarted: (serversIds: number[], operation: 'update') => void;
    onConfirmationShown?: () => void;
    wrap?: (text: TranslationProps) => ReactElement;
};

export type UpdatePopoverProps<C extends ComponentType> = BaseProps & ComponentProps<C>;

export const UpdateButton = <C extends ComponentType = typeof Button>({
    selectedServers,
    onTaskStarted,
    onTaskStarting,
    onConfirmationShown,
    wrap = (text: TranslationProps) => <Button {...props}>{text}</Button>,
    ...props
}: UpdatePopoverProps<C>) => {
    const translate = useTranslate('security-dashboard.Servers.Toolbar.UpdateButton');
    const { mutateAsync: runServerUpdater } = useServerUpdaterMutation();
    const serversIds = selectedServers.map((el) => el.id);
    const target = wrap(translate('buttonText'));
    const handleUpdate = async () => {
        onTaskStarting(serversIds, 'update');
        try {
            await runServerUpdater(selectedServers);
        } finally {
            onTaskStarted(serversIds, 'update');
        }
        target.props.onClick?.();
    };

    if (selectedServers.length === 0) {
        return (
            <NoSelectionPopover selectionCount={selectedServers.length} target={target}>
                {translate('noSelectedServers')}
            </NoSelectionPopover>
        );
    }

    const renderPopoverDescription = () => {
        if (selectedServers.length === 1) {
            return translate('updateSingleServerConfirmation', {
                selectedServerTitle: selectedServers[0]?.displayTitle,
            });
        }

        return translate('updateSeveralServersConfirmation', {
            selectedServersCount: selectedServers.length,
        });
    };

    return (
        <ConfirmationPopover
            target={target}
            actionButtonText={translate('buttonText')}
            onClick={handleUpdate}
            onShow={onConfirmationShown}
            confirmationButtonIntent="primary"
        >
            {renderPopoverDescription()}
        </ConfirmationPopover>
    );
};

export default UpdateButton;
