// Copyright 2024. WebPros International GmbH. All rights reserved.

import {
    Badge,
    Button,
    Dropdown,
    Menu,
    MenuDivider,
    MenuHeader,
    MenuSelectableItem,
    TranslationProps,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type Divider = null;

type F<T> = {
    name: T;
    title: TranslationProps;
    isHeader?: boolean;
};

type Filter<T> = F<T> | Divider;

const isDivider = (filter: Filter<unknown>): filter is Divider => filter === null;

export type FiltersProps<T> = {
    onFilterChange: (filter: T) => void;
    onReset: () => void;
    filters: Filter<T>[];
    selected: T[];
};

export const Filters = <T extends string>({
    filters,
    selected,
    onFilterChange,
    onReset,
}: FiltersProps<T>) => {
    const translate = useTranslate('security-dashboard.Filters');

    return (
        <Dropdown
            data-type="filters"
            menu={
                <Menu>
                    {filters.map((filter, index) => {
                        if (isDivider(filter)) {
                            // eslint-disable-next-line react/no-array-index-key
                            return <MenuDivider key={index} />;
                        }

                        if (filter.isHeader) {
                            // eslint-disable-next-line react/no-array-index-key
                            return <MenuHeader key={index}>{filter.title}</MenuHeader>;
                        }

                        const { name, title } = filter;

                        return (
                            <MenuSelectableItem
                                key={name}
                                selected={selected.includes(name)}
                                onClick={() => onFilterChange(name)}
                            >
                                {title}
                            </MenuSelectableItem>
                        );
                    })}
                    <MenuDivider />
                    <MenuSelectableItem onClick={onReset} selected={selected.length === 0}>
                        {translate('all')}
                    </MenuSelectableItem>
                </Menu>
            }
        >
            <Badge intent="info" hidden={selected.length === 0}>
                <Button icon={selected.length > 0 ? 'filter-check-mark' : 'filter'} />
            </Badge>
        </Dropdown>
    );
};
