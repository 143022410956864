// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import mapServerStatus from '@platform360/security-dashboard/web/helpers/mapServerStatus';
import { InstallationComponent } from '@platform360/security-dashboard/shared/installation-component';
import {
    ApiListResponse,
    ApiResponse,
    FetcherOptions,
    FilterQuery,
    IdsFilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';
import {
    Installation,
    Task,
    SitesHealthFilter,
    InstallationVulnerability,
    Label,
    VulnerabilityProvider,
    UpdateSettings,
} from '@platform360/security-dashboard/web/types';
import { UpdateSettingsOption } from '@platform360/security-dashboard/shared/update-settings-option';
import { SafeUpdateOption } from '@platform360/security-dashboard/shared/safe-update-option';
import { MitigateVulnerabilitiesParams } from '@platform360/security-dashboard/web/api/vulnerabilities';
import { VulnerabilityRiskRank } from '@platform360/security-dashboard/shared/vulnerability-risk-rank';

export type InstallationResponseCoreSummary = {
    version: string;
    availableVersion: string | null;
    vulnerabilities: number;
};

export type AssetInfo = {
    slug: string;
    title: string;
    version: string;
    availableVersion: string | null;
    status: boolean;
};

export type InstallationResponseAssetsSummary = {
    total: number;
    vulnerable: number;
    outdated: number;
    vulnerabilities: number;
};

export type InstallationResponse = {
    id: number;
    displayTitle: string;
    server: {
        id: number;
        displayTitle: string;
        address: string;
        hostname: string | null;
        lastRequestResultType: string;
        unsupportedAgent: boolean;
        agentVersion: string | null;
        protected: boolean;
    };
    operable: boolean;
    lastVulnerabilityCheckAt: number | null;
    vulnerable: boolean;
    outdated: boolean;
    protected: boolean;
    managed: boolean;
    core: InstallationResponseCoreSummary | null;
    plugins: InstallationResponseAssetsSummary | null;
    themes: InstallationResponseAssetsSummary | null;
    tasks: Task[];
    error: string | null | undefined;
    resolvableVulnerabilitiesCount: number | null;
    outdatedCount: number | null;
    labels: Label[];
    updateSettings: UpdateSettings;
};

export type InstallationInfoResponse = {
    id: number;
    displayTitle: string;
    operable: boolean;
    lastVulnerabilityCheckAt: number | null;
    vulnerable: boolean;
    outdated: boolean;
    protected: boolean;
    managed: boolean;
    plugins: AssetInfo[];
    themes: AssetInfo[];
    tasks: Task[];
};

export type InstallationVulnerabilityResponse = {
    id: number;
    availableVersion: string | null;
    availableMinorVersion: string | null;
    component: InstallationComponent;
    slug: string;
    description: string;
    title: string;
    url: string;
    version: string;
    vulnerabilityId: string;
    cvssScore: string | null;
    exploited: boolean | null;
    disclosedAt: string | null;
    cve: string[];
    mitigated: boolean;
    mitigatedByDeactivation: boolean;
    mitigatedByProtection: boolean;
    labels: Label[];
    providers: VulnerabilityProvider[];
    deactivationReason: string | null;
    mitigationUpdateAvailable: boolean;
    riskRank: VulnerabilityRiskRank;
};

export type InstallationOutdatedItemsResponse = {
    slug: string;
    title: string;
    version: string;
    availableVersion: string;
    component: InstallationComponent;
};

export type InstallationsSummaryResponse = {
    total: number;
    vulnerable: number;
    outdated: number;
    protected: number;
    safe: number;
    notOperable: number;
};

const normalizeInstallation = ({
    lastVulnerabilityCheckAt,
    server,
    ...installation
}: InstallationResponse): Installation => ({
    lastVulnerabilityCheckAt: lastVulnerabilityCheckAt
        ? DateTime.fromSeconds(lastVulnerabilityCheckAt)
        : null,
    server: {
        status: mapServerStatus(server.lastRequestResultType, 'connected'),
        ...server,
        ipAddress: new URL(server.address).hostname,
    },
    ...installation,
});

type SitesHealthFilterQuery = {
    sitesHealthFilter?: SitesHealthFilter[];
};

type LabelIdsFilterQuery = {
    labelIdsFilter?: string[];
};

export type InstallationUpdateResponse = {
    id: number;
};

type InstallationsUpdateResponse = {
    installations: InstallationUpdateResponse[];
};

export type InstallationUpdateRequest = {
    core: boolean;
    plugins: string[];
    themes: string[];
};

export type InstallationUpdateComponentRequest = {
    component: InstallationComponent;
};

type GetInstallationOptions = FetcherOptions<
    PaginatedQuery & FilterQuery & SitesHealthFilterQuery & LabelIdsFilterQuery & IdsFilterQuery
>;

type GetInstallationsResponse = ApiListResponse<InstallationResponse>;

export const getInstallations = async ({ variables, signal }: GetInstallationOptions) => {
    const { data } = await apiClient.get<GetInstallationsResponse>(
        '/security-dashboard/installations',
        {
            params: {
                ...variables,
                sitesHealthFilter: variables.sitesHealthFilter?.join(','),
                labelIdsFilter: variables.labelIdsFilter?.join(','),
                idsFilter: variables.idsFilter?.join(','),
            },
            signal,
        },
    );

    return {
        ...data,
        data: data.data.map(normalizeInstallation),
    };
};

type GetInstallationOutdatedItemsOptions = FetcherOptions<{
    installationId: number;
}>;

export type GetInstallationOutdatedItemsResponse =
    ApiListResponse<InstallationOutdatedItemsResponse>;

export const getInstallationOutdatedItems = async ({
    variables,
    signal,
}: GetInstallationOutdatedItemsOptions): Promise<GetInstallationOutdatedItemsResponse> => {
    const { data } = await apiClient.get<GetInstallationOutdatedItemsResponse>(
        `/security-dashboard/installations/${variables.installationId}/updates`,
        {
            signal,
        },
    );

    return {
        ...data,
        data: data.data,
    };
};

type GetInstallationsVulnerabilitiesOptions = FetcherOptions<
    {
        installationId: number;
    } & IdsFilterQuery
>;

type GetInstallationsVulnerabilitiesResponse = ApiListResponse<InstallationVulnerabilityResponse>;

const normalizeInstallationVulnerability = ({
    disclosedAt,
    ...installationVulnerability
}: InstallationVulnerabilityResponse): InstallationVulnerability => ({
    disclosedAt: disclosedAt ? DateTime.fromISO(disclosedAt) : null,
    ...installationVulnerability,
});

export const getInstallationsVulnerabilities = async ({
    variables,
    signal,
}: GetInstallationsVulnerabilitiesOptions): Promise<ApiListResponse<InstallationVulnerability>> => {
    const { data } = await apiClient.get<GetInstallationsVulnerabilitiesResponse>(
        `/security-dashboard/installations/${variables.installationId}/vulnerabilities`,
        {
            params: {
                idsFilter: variables.idsFilter?.join(','),
            },
            signal,
        },
    );

    return {
        ...data,
        data: data.data.map(normalizeInstallationVulnerability),
    };
};

type GetInstallationInfoOptions = FetcherOptions<{
    installationId: number;
}>;
export const getInstallationInfo = async ({
    variables,
    signal,
}: GetInstallationInfoOptions): Promise<InstallationInfoResponse> => {
    const { data } = await apiClient.get<ApiResponse<InstallationInfoResponse>>(
        `/security-dashboard/installations/${variables.installationId}/info`,
        {
            signal,
        },
    );

    return data.data;
};

export const updateInstallation = async ({
    installationId,
    request,
}: {
    installationId: number;
    request: InstallationUpdateRequest;
}): Promise<InstallationUpdateResponse> => {
    const { data } = await apiClient.post<ApiResponse<InstallationUpdateResponse>>(
        `/security-dashboard/installations/${installationId}/updater`,
        request,
    );

    return data.data;
};

export const updateInstallationComponent = async ({
    installationId,
    request,
}: {
    installationId: number;
    request: InstallationUpdateComponentRequest;
}): Promise<void> => {
    await apiClient.post<ApiResponse<InstallationUpdateResponse>>(
        `/security-dashboard/installations/${installationId}/update-component`,
        request,
    );
};

export const updateInstallations = async (
    installationIds: number[],
): Promise<InstallationsUpdateResponse> => {
    const { data } = await apiClient.post<InstallationsUpdateResponse>(
        '/security-dashboard/installations/updater',
        { installations: installationIds },
    );

    return data;
};

export const getInstallationsSummary = async (): Promise<InstallationsSummaryResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<InstallationsSummaryResponse>>(
        '/security-dashboard/installations/summary',
    );

    return data;
};

export const refreshInstallations = async (installations: number[]): Promise<void> => {
    await apiClient.post('/security-dashboard/installations/refresh', {
        installations,
    });
};

export const detachInstallations = async (installations: number[]): Promise<void> => {
    await apiClient.post('/security-dashboard/installations/detacher', {
        installations,
    });
};

type UpdateInstallationsProtectionStatusParams = {
    installationIds: number[];
    protect: boolean;
    ignoreDoNotProtect?: boolean;
};

export const updateInstallationsProtectionStatus = async (
    params: UpdateInstallationsProtectionStatusParams,
): Promise<void> => {
    await apiClient.post('/security-dashboard/installations/protect', params);
};

type AssignRemoveInstallationLabelsOptions = {
    installationIds: number[];
    labelIds: number[];
};

export const assignInstallationLabels = async ({
    installationIds,
    labelIds,
}: AssignRemoveInstallationLabelsOptions): Promise<void> => {
    await apiClient.post<ApiResponse<void>>('/security-dashboard/installations/assign-labels', {
        installationIds,
        labelIds,
    });
};

export const removeInstallationLabels = async ({
    installationIds,
    labelIds,
}: AssignRemoveInstallationLabelsOptions): Promise<void> => {
    await apiClient.post<ApiResponse<void>>('/security-dashboard/installations/remove-labels', {
        installationIds,
        labelIds,
    });
};

export type InstallationSettingsResponse = {
    updates: UpdateSettings;
};

export type GetInstallationSettingsOptions = FetcherOptions<{
    installationId: number;
}>;

export const getInstallationSettings = async ({
    variables: { installationId },
    signal,
}: GetInstallationSettingsOptions): Promise<InstallationSettingsResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<InstallationSettingsResponse>>(
        `/security-dashboard/installations/${installationId}/settings`,
        {
            signal,
        },
    );

    return data;
};

export type SaveInstallationSettingsRequest = {
    updates: {
        core: UpdateSettingsOption | null;
        plugins: UpdateSettingsOption | null;
        themes: UpdateSettingsOption | null;
        safeUpdate: SafeUpdateOption | null;
    };
};

export type SaveInstallationSettingsOptions = FetcherOptions<{
    installationId: number;
    request: SaveInstallationSettingsRequest;
}>;

export type SaveInstallationsSettingsRequest = SaveInstallationSettingsRequest & {
    installationIds: number[];
};

export const saveInstallationSettings = async ({
    variables: { installationId, request },
}: SaveInstallationSettingsOptions): Promise<void> => {
    await apiClient.put(`/security-dashboard/installations/${installationId}/settings`, request);
};

export const saveInstallationsSettings = async (
    request: SaveInstallationsSettingsRequest,
): Promise<void> => {
    await apiClient.post('/security-dashboard/installations/settings', request);
};

type MitigateInstallationVulnerabilitiesOptions = {
    installationId: number;
    data: MitigateVulnerabilitiesParams;
};
export const mitigateVulnerabilities = async ({
    installationId,
    data,
}: MitigateInstallationVulnerabilitiesOptions): Promise<void> => {
    await apiClient.post(`/security-dashboard/installations/${installationId}/mitigator`, data);
};
