// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copiato negli appunti.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Stai impersonando un altro account.',
            depersonateButton: 'Smetti d\'impersonare',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Non stai più impersonando un altro account.',
        },
        MainMenu: {
            betaVersion: 'Versione beta',
            servers: 'Server',
            websites: 'Siti web',
            licenses: 'Licenze',
            monitoring: 'Monitoraggio',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Monitoraggio',
            feedback: 'I tuoi commenti',
            services: 'Servizi 360',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Cerca...',
            },
            ClientResultGroup: {
                clients: 'Clienti',
                clientsShowMore: '{showMore, plural, one{# altro client...} other{altri # client...}}',
            },
            DomainResultGroup: {
                domains: 'Siti web',
                domainsShowMore: '{showMore, plural, one{# altro sito web...} other{altri # siti web...}}',
            },
            ServersResultGroup: {
                servers: 'Server',
                serversShowMore: '{showMore, plural, one{# altro server...} other{altri # server...}}',
            },
            search: 'Cerca...',
            noResults: 'Nessun risultato...',
        },
        UserMenu: {
            team: 'Organizzazione',
            logout: 'Disconnetti',
            changePassword: 'Modifica password',
            viewProfile: 'Profilo personale',
            businessProfile: 'Profilo aziendale',
            apiTokens: 'Token API',
        },
    },
    limit: 'Limite:',
    upgradePropertiesButton: 'Regola',

    common: {
        errors: {
            isNotEmpty: 'Questo campo obbligatorio è vuoto.',
            required: 'Questo campo obbligatorio è vuoto.',
            url: 'Il valore non è un URL valido.',
            isUrl: 'Il valore non è un URL valido.',
            email: 'Il valore deve essere un indirizzo e-mail valido.',
            isEmail: 'Il valore deve essere un indirizzo e-mail valido.',
            json: 'Il valore deve essere conforme al formato JSON.',
            min: '{min, plural, one{Deve contenere almeno # carattere.} other{Deve contenere almeno # caratteri.}}',
            maxLength: '{max, plural, one{Deve contenere al massimo # carattere.} other{Deve contenere al massimo # caratteri.}}',
            isHttps: 'Il nome host del server deve iniziare con https://.',
            unique: 'Il valore deve essere unico.',
            isPublicIp: 'Il nome host del server dovrebbe risolvere verso un indirizzo IP pubblico.',
            unknown: 'Errore sconosciuto: {message}',
            externalRedirect: 'Si è verificato un problema.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Devi inserire una password.',
            errorPasswordIsWrong: 'Password errata.',
            errorPasswordIsTooWeak: 'La password è troppo debole.',
            errorPasswordContainsPersonalInfo: 'La password non può contenere dati personali.',
            errorPasswordTooCommon: 'La password è troppo comune.',
            errorPasswordPreviouslyUsed: 'La password è stata già usata in precedenza.',
            errorInvalidOtp: 'Il codice inserito non è valido.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Conferma abbonamento',
            payment: 'Pagamento',
        },
        general: {
            buyNow: 'Acquista adesso',
            confirm: 'Conferma',
            confirmAndFeedback: 'Conferma e invia feedback',
            cancel: 'Annulla',
        },
        tabTitle: 'Tutte le licenze',
        LinkedEmails: {
            tabTitle: 'E-mail collegate',
            emailColumn: 'E-mail',
            statusColumn: 'Stato',
            totalRows: '{totalRows, plural, one {# e-mail in totale} other {# e-mail in totale}}',
            verifiedStatus: 'Verificato',
            addButton: 'Collega più e-mail',
            deleteButton: 'Scollega l\'e-mail',
            AddEmails: {
                stepOne: {
                    title: 'Collegamento di e-mail con licenze associate',
                    emailsLabel: 'Indirizzi e-mail utilizzati per l\'acquisto delle licenze',
                    submitButton: 'Invia la verifica',
                },
                stepTwo: {
                    title: 'Sono state inviate email di verifica',
                    description: 'Controlla la tua casella di posta elettronica e segui i link di verifica. Per completare l\'aggiunta dei nuovi indirizzi e-mail associati, probabilmente sarà necessario effettuare nuovamente l\'accesso a questo account. I link scadranno tra 1 ora.',
                    emailColumn: 'E-mail',
                    submitButton: 'Fatto',
                    resendButton: 'Invia di nuovo',
                },
            },
            Onboarding: {
                title: 'Collega i tuoi indirizzi e-mail a questo account per vedere più licenze',
                description: 'Per iniziare a gestire più licenze, aggiungi gli indirizzi e-mail utilizzati per acquistarle.',
                docsLink: 'Più info',
                addEmailsButton: 'Aggiungi indirizzi e-mail',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Acquista Plesk',
                    monitoring: 'Acquista 360 Monitoring',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Qui puoi visualizzare e gestire le tue licenze',
                    description1: 'Se non possiedi ancora delle licenze, fai clic su "Acquista una nuova licenza".',
                    description2: 'Se hai acquistato delle licenze con altri indirizzi email, fai clic su "Associa licenze esistenti" per aggiungerle.',
                    docsLink: 'Più info',
                    buyButton: 'Acquista una nuova licenza',
                    addEmailsButton: 'Associa licenze esistenti',
                },
            },
        },
        CB: {
            paymentInfo: 'Il negozio online di Plesk gestito da {companyName} processerà l\'ordine utilizzando il metodo di pagamento associato a questo abbonamento',
            confirmSubscription: 'Inviando questo ordine, accetti di abbonarti a un prodotto in abbonamento che sarà fatturato a intervalli regolari. L\'uso continuato del prodotto acquistato è subordinato al pagamento dell\'abbonamento. I dettagli sul prezzo e sulla frequenza della fatturazione compaiono nel carrello qui sopra.',
            buyInfo: 'Inviando quest\'ordine, accetterai <termsLink>Termini e condizioni</termsLink> di Cleverbridge, confermando inoltre di aver letto e accettato l\'<policyLink>Informativa sulla privacy</policyLink> di Cleverbridge e il tuo <revocationLink>Diritto di recesso</revocationLink> in qualità di consumatore.',
            links: {
                terms: 'Termini e condizioni',
                contact: 'Contatto',
                revocation: 'Diritto di recesso',
                security: 'Sicurezza',
                legal: 'Informazioni legali',
                policy: 'Informativa sulla privacy',
            },
            footer: 'Stai effettuando un ordine tramite il rivenditore online {companyName}. L\'elaborazione del pagamento e l\'evasione dell\'ordine sono gestite da {companyName}, {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Colonia, Germania',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654, Stati Uniti',
            },
        },
        Renew: {
            drawer: {
                title: 'Riattiva l\'abbonamento',
            },
            form: {
                submitButton: 'Conferma il rinnovo',
                confirmText: 'Facendo clic sul pulsante qui sotto, confermi il rinnovo del tuo abbonamento a WebPros International GmbH "{product}"',
            },
            buttonTitle: 'Riattiva abbonamento',
            successMessage: 'È stato rinnovato l\'abbonamento per {product}. La sua chiave di licenza impiegherà un po\' di tempo per diventare attiva',
            unavailable: 'Non è possibile rinnovare questo abbonamento (per esempio, potrebbe essere stato interrotto). Acquista un nuovo prodotto.',
        },
        Adjust: {
            delayedChangesProducts: 'I seguenti limiti rimarranno invariati fino alla fine del periodo di fatturazione ({date})',
        },
        Cancel: {
            buttonTitle: 'Annullare la sottoscrizione',
            successMessage: 'L\'abbonamento a {product} è stato annullato. Puoi continuare a usarlo fino al {date}',
            popover: {
                title: 'Annulla la sottoscrizione a {product}',
                message: 'Questo interromperà addebiti futuri. Puoi continuare a usare la licenza fino al {date} (l\'attuale periodo di fatturazione non verrà rimborsato).',
            },
        },
        Payment: {
            card: {
                expired: 'La tua carta è scaduta',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'Puoi cambiare il metodo di pagamento nelle impostazioni del tuo abbonamento',
                    confirm: 'OK',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Annulla',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Platform 360 fa uso di ZendeskChat, un\'applicazione di terze parti.',
            description2: 'Procedendo, acconsento all\'utilizzo di ZendeskChat, un\'applicazione di terze parti. Ciò potrebbe comportare il trasferimento dei miei dati personali (ad esempio l\'indirizzo IP) a terzi all\'interno o all\'esterno dell\'Europa. È possibile consultare l\'<a>Informativa sulla privacy</a> per maggiori informazioni.',
            confirmButton: 'Avvia ZendeskChat',
        },
        supportFormPopover: {
            description: 'Non ci sono operatori disponibili al momento. Ti preghiamo di attendere o usare il modulo ticket per inviare una richiesta.',
            confirmButton: 'Apri modulo',
        },
        ChatButton: {
            title: 'Supporto',
        },
    },

    keysPageTitle: 'Licenze',
    goBack: 'Torna alla schermata home',
    keysPageDescription: 'Gestisci le licenze acquistate nel Negozio online Plesk.',
    keysToolbarBuyTitle: 'Acquista',
    keysToolbarFilterTitle: 'Filtro',

    keyAdditionalActionsToolbarTitle: 'Azioni aggiuntive',
    keyMoreDetails: 'Maggiori dettagli',

    keyForeignCart: 'Questa licenza non è di tua proprietà',
    keyForeignDescriptionTitle: 'Non è possibile gestire la licenza poiché appartiene a un altro account. Ciò può accadere per i seguenti motivi:',
    keyForeignDescriptionHosting: 'La tua licenza Plesk appartiene al tuo provider di hosting.',
    keyForeignDescriptionWrongEmail: 'Hai acquistato la licenza utilizzando un altro account. Per visualizzare e gestire la licenza, accedi tramite l\'indirizzo e-mail corrispondente a tale account.',

    keyTrialDescription: 'Questa è una licenza di prova. Vuoi ordinare una {link}?',
    keyTrialDescriptionLink: 'licenza a pagamento',

    keyTerminatedCart: 'Questa licenza è stata revocata',
    keyTerminatedDescription: 'Questa licenza non è più valida.',
    keyTerminatedDescriptionRetail: 'Non riceverai ulteriori addebiti in merito.',

    keyPartnerCart: 'Questa licenza non è stata acquistata nel Negozio online Plesk.',
    keyPartnerDescription: 'Per questa licenza non è possibile visualizzare i dettagli di fatturazione.',

    keyLicenseDetailsTitle: 'Dettagli della licenza',
    keyLicenseDetailsCreationDate: 'Data di creazione:',
    keyLicenseDetailsActivationCode: 'Codice di attivazione:',
    keyBillingDetailsTitle: 'Dettagli di fatturazione',
    keyBillingDetailsTrial: 'Questa è una licenza di prova, che scade il {date}. <a>Acquista una licenza</a>.',
    keyBillingDetailsNotRetail: 'Non è possibile visualizzare dettagli di fatturazione per questa licenza, poiché non è stata acquistata nel Negozio online Plesk.',
    keyBillingDetailsTerminated: 'Il tuo abbonamento è stato annullato.',
    keyBillingDetailsSubscriptionId: 'ID abbonamento:',
    keyBillingDetailsCycle: 'Ciclo di fatturazione:',
    keyBillingDetailsForeign: 'Non è possibile visualizzare dettagli di fatturazione per questa licenza, poiché appartiene a un altro account.',
    keyServerDetailsTitle: 'Dettagli server:',
    keyServerDetailsAddresses: 'Indirizzi IP server:',
    keyServerDetailsOs: 'SO server:',
    keyServerDetailsProductVersion: 'Versione prodotto:',
    keyServerDetailsAbsent: 'Questa licenza non sembra ancora installata su un server Plesk.',

    pleskUpgradedMessage: 'Hai eseguito l\'upgrade della licenza da {from} ({id}) a {to}.',
    propertyUpgradedIncreaseMessage: 'La capacità di licenza {keyName} è stata aumentata a {to} {name}',
    propertyUpgradedDecreaseMessage: 'La capacità della licenza {keyName} è stata ridotta al {to} {name}. Le modifiche entreranno in vigore nel nuovo periodo di fatturazione',
    keyUpgradedMessage: 'Hai eseguito l\'upgrade della licenza da {from} ({id}) a {to}. La tua licenza {to} ({id}) è stata assegnata alla licenza Plesk {parentId}.',
    keyAddedMessage: 'Hai acquistato la licenza {addon}. Tale licenza viene assegnata alla licenza {parent}: {parentId}.',
    refreshSuggestion: 'Se non vedi le modifiche, <a>aggiorna la pagina</a>.',

    upsellPageTitle: 'Aggiungi una nuova estensione alla tua licenza',
    yearlyPrices: 'Prezzi all’anno',
    monthlyPrices: 'Prezzi al mese',
    subscriptionForBillingPeriodNotFound: 'I periodi di fatturazione della licenza dell\'estensione e dell\'abbonamento non corrispondono o non esiste nessuna licenza di Plesk per il periodo di fatturazione selezionato. Scegli il periodo di fatturazione corrispondente o acquista una licenza di Plesk nel negozio online.',
    subscriptionToUpgradeHeader: 'Upgrade aggiornamento',
    selectedSubscription: '{subscriptionId} (valuta: {billingDate}; ciclo di fatturazione: {billingCycle})',
    toOnlineStoreButton: 'Al negozio online',
    why: 'A cosa può essere dovuto?',
    whyCharge: 'L\'importo addebitato è stato rateizzato in base al numero di giorni rimanenti nel periodo di fatturazione corrente.',
    chargeNow: 'Ora ti saranno addebitati <b>{grossPrice}</b> ({vatPrice} IVA incl.).',
    chargeNowShort: 'Il costo ti verrà addebitato ora',
    inclVatShort: 'IVA incl. {vatPrice}',
    delayedCharge: 'Il limite rimarrà a {limit} {property} fino alla fine del periodo di fatturazione.',
    nextCharge: '<b>{grossPrice}</b> (IVA inclusa {vatPrice}), a partire dal giorno {nextBillingDate}',
    nextChargeShort: 'Il costo ti verrà addebitato a partire da {nextBillingDate}',
    noProductForBillingPeriod: 'Non vi sono prodotti per il periodo di fatturazione selezionato.',
    confirmationPopupTitle: 'Esegui l’upgrade del tuo abbonamento attuale',

    upgradePageTitle: 'Esegui l\'upgrade della licenza',
    upgradePageDescription: 'Qui puoi fare l\'upgrade della licenza.',
    upgradePanelBeforeTitle: 'Prima dell\'upgrade',
    upgradePanelOptionsTitle: 'Opzioni di upgrade',
    upgradePanelAfterTitle: 'Dopo l\'upgrade',
    upgradePanelOptionsRecommendationsTitle: 'Leggi i nostri consigli:',
    upgradePanelOptionsRecommendationsAlt: 'O {link} di seguito.',
    upgradePanelOptionsRecommendationsAltLink: 'sfoglia tutte le opzioni possibili',
    upgradeSuggestion: 'Aggiungi prodotti alla tua licenza',
    upgradeSuggestionFreeCost: 'Provalo gratuitamente!',
    upgradeSuggestionDescription: '(per informazioni, vedi i dettagli del prodotto)',
    upgradeMultiOfferSuggestion: 'Scegli un piano diverso',
    upgradeConfirm: 'Acquista adesso',
    upgradeAccept: 'Conferma',
    upgradeBack: 'Torna all\'elenco di prodotti',
    planUpdate: 'Pianifica aggiornamento',
    adjustPending: 'È già in corso una modifica per questa licenza. Riprova tra 30 secondi.',
    waitAMoment: 'Attendi un istante',
    upgradeConfirmPriceRecalculation: 'Ora ti addebiteremo {chargeInfo} (prezzo totale rateizzato in base al numero di giorni rimanenti fino alla data di fatturazione successiva, {nextBillingDate}). A partire dal {nextBillingDate}, per questo prodotto ti addebiteremo {renewalInfo}.',
    buyNowNotification: 'Fai clic su "Acquista adesso" per procedere all\'acquisto o all\'aggiornamento della licenza. Verrai reindirizzato nel Negozio online Plesk, gestito da Cleverbridge AG. Il negozio propone il metodo di pagamento già utilizzato in precedenza per l\'acquisto o l\'aggiornamento della licenza. Tutti i pagamenti vengono elaborati da Cleverbridge AG.',
    paymentDetailsLinkText: 'Visualizza e modifica dettagli di pagamento per questa transazione.',
    upgradeDoneRedirectToPleskTitle: 'Acquisto riuscito',
    upgradeDoneRedirectToPleskDescription: 'L\'installazione della licenza può richiedere tempo.',
    upgradeDoneRedirectToPleskButton: 'Torna a Plesk',
    upgradeDoneRedirectToKeys: 'Visualizza le mie licenze',
    total: 'TOTALE',
    vat: 'IVA',
    newTotal: 'NUOVO TOTALE',
    newVat: 'NUOVA IVA',

    firstLoginWithoutEmailVerification: 'Grazie per aver effettuato la registrazione al servizio Plesk 360. Ti abbiamo inviato un\'e-mail con un link di verifica. Segui il link per verificare il tuo account e accedere. Poi, potrai iniziare a gestire le tue licenze all\'interno di Plesk 360.',
    pleaseVerifyEmailTitle: 'Verifica il tuo account Plesk 360',
    secondLoginWithoutEmailVerification: 'Stai provando ad accedere a un account non verificato. Controlla se la casella della posta in arrivo contiene un link di verifica. Segui il link per verificare il tuo account e accedere. Poi, potrai iniziare a gestire le tue licenze all\'interno di Plesk 360.',
    userBlocked: "L'account è stato sospeso a causa di una violazione dell'Accordo con l'utente. Se pensi che si tratti di un errore, scrivici a {emailLink}.",

    errorDefault: 'Si è verificato un problema. Riprova più tardi.',
    errorDefaultWithReqId: 'Qualcosa non ha funzionato. Riprova più tardi. ID richiesta: {requestId}.',
    errorTooManyRequests: 'Il limite di chiamate API è stato raggiunto per questo indirizzo IP. Riprova tra un minuto.',
    errorTokenRenewal: 'Sessione scaduta. Effettua nuovamente l\'accesso.',
    errorNetworkIssue: 'Sembra che tu stia avendo problemi a collegarti alla rete.',

    errorKAUnavailable: 'Il servizio non è al momento disponibile. Riprova più tardi.',
    errorKAUnavailableWithReqId: 'Il servizio non è al momento disponibile. Riprova più tardi. ID richiesta: {requestId}.',

    footerPurposeExtension: {
        text: 'Trova ulteriori estensioni nel <a>Catalogo di estensioni</a>.',
    },

    filterAll: 'Tutte le licenze',
    filterActive: 'Solo attivo',
    renewalCost: 'Costo del rinnovo:',
    nextBillingDate: 'Data di fatturazione successiva:',
    canceledSubscriptionDate: 'L\'abbonamento è stato annullato. La tua licenza sarà valida fino al',
    paymentHistory: 'cronologia pagamenti',
    licenseStatus: 'Stato licenza:',
    upgradeButton: 'Upgrade',
    upsellButton: 'Aggiungi estensione',
    upsellText: 'Una nuova estensione verrà aggiunta al tuo Plesk',
    billingPeriod: 'Periodo di fatturazione',
    features: 'Funzionalità aggiuntive:',
    noFeatures: 'Nessuna funzionalità aggiuntiva',
    toPlesk: 'Vai a Plesk',
    expiresNote: '(scade il {date})',
    startingFrom: 'a partire da',
    billingIntervalMonth: '{value} / mese',
    billingIntervalYear: '{value} / anno',
    billingIntervalOnce: '{value} / una volta',
    perOne: 'per unità',

    paymentMethod: 'Metodo di pagamento',
    editPaymentMethod: 'Modifica metodo di pagamento',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'aggiunto/i',
    addresses: 'indirizzi',
    back: 'Indietro',

    monthly: 'Ogni mese',
    on: 'attivato',
    plesk: 'Plesk',
    product: 'Prodotto',
    price: 'Prezzo',
    products: 'Prodotti',
    purchased: 'Acquistato/i',
    select: 'Seleziona',
    sinceNote: '(da {date})',
    show: 'Mostra',
    upgrade: 'upgrade',
    yearly: 'Ogni anno',

    isEmpty: 'Questo campo è obbligatorio.',
    errorPasswordIsEmpty: 'La password è obbligatoria.',
    errorPasswordIsWrong: 'Password errata.',
    errorPasswordIsTooWeak: 'La password è troppo debole.',
    errorEmailOrPasswordIsIncorrect: 'Indirizzo e-mail o password scorretti.',
    errorIncorrectEmail: 'Indirizzo e-mail scorretto.',
    errorUserDoesntExist: 'L\'utente non esiste.',
    errorUserExist: 'L\'utente esiste già.',
    errorUserIsNotVerified: 'L\'utente esiste ma non è stato verificato. Abbiamo appena inviato una nuova e-mail di verifica. Controlla la tua casella di posta elettronica.',
    errorUnknownError: 'Errore sconosciuto:',
    errorEmailIsAlreadyLinked: 'Questo indirizzo e-mail è già collegato al tuo account.',
    errorLinkIsOutdated: 'Questo link di verifica è scaduto.',
    errorBounceOrComplaint: 'Questo indirizzo e-mail non esiste o il messaggio è stato bloccato come spam. Contatta il team di supporto di Plesk 360.',
    errorUserEmailAlreadyVerified: 'Questo indirizzo e-mail è già stato verificato.',
    errorDoResourceIdExist: 'Questo account Digital Ocean è già registrato.',
    errorCopyToClipboard: 'Si è verificato un errore di copia, prova a copiare manualmente.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH tratterà i dati da te forniti per elaborare la tua richiesta in conformità con la sua <a>Informativa sulla privacy</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'L\'utente non esiste.',
            bounceOrComplaint: 'Questo indirizzo e-mail non esiste o il messaggio è stato bloccato come spam. Contatta il team di supporto di Plesk 360.',
            userIsNotVerified: 'L\'utente esiste ma non è stato verificato. Abbiamo appena inviato una nuova e-mail di verifica. Controlla la tua casella di posta elettronica.',
            userExist: 'L\'utente esiste già.',
            errorPasswordIsEmpty: 'La password è obbligatoria.',
            errorPasswordIsWrong: 'Password errata.',
            errorPasswordIsTooWeak: 'La password è troppo debole.',
        },
        AuthContainer: {
            tabs: {
                login: 'Accedi',
                signUp: 'Iscriviti',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Benvenuto su Plesk 360',
                text1: 'Lo strumento dei leader per monitorare e gestire i server in semplicità.',
                text2: 'Ottieni un <b>sito completamente integrato e la possibilità di monitorare il server</b> per tenere traccia delle prestazioni e prevenire le interruzioni.',
                text3: 'Effettua l\'upgrade e ottieni <b>dashboard configurabili</b>, notifiche personalizzate, controlli ogni minuto, cronologia della rete e altro ancora.',
                text4: 'Gestisci il tuo <b>inventario server</b>, visualizza tutte le tue licenze Plesk e acquista o aggiorna gli abbonamenti.',
                text5: 'Tutto da una singola piattaforma con un solo account.',
            },
            MonitoringPageIntro: {
                title: 'Ti diamo il benvenuto a 360 Monitoring',
                text1: 'Lo strumento dei leader per monitorare e gestire i server in semplicità.',
                text2: '<b>Monitoraggio di server e tempi di attività</b> completamente integrato per tenere traccia delle prestazioni e prevenire le interruzioni.',
                text3: '<b>Dashboard configurabili</b>, notifiche personalizzate, controlli ogni minuto, metriche e I/O dei server, prestazioni di rete e metriche personalizzate tramite plug-in. ',
                text4: 'Multicloud e <b>compatibile</b> con tutte le piattaforme.',
            },
        },
        SignUp: {
            signUp: 'Iscriviti',
            loginLink: 'Vai alla schermata di accesso',
            emailLabel: 'E-mail',
            emailDescription: 'È meglio usare l\'email che hai utilizzato per acquistare Plesk e le estensioni',
            passwordLabel: 'Password',
            privacyPolicyAgreementTitle: 'Accetto i <a>{termsLink}</a>',
            termsPlatform: 'Termini di utilizzo di Plesk 360',
            termsMonitoring: 'Termini di utilizzo di 360 Monitoring',
            privacyPolicyAgreementDescription: 'Al fine di creare e mantenere attivo il mio account sulla piattaforma, WebPros International GmbH e le altre compagnie del gruppo WebPros elaboreranno le informazioni fornite nel loro sistema interno secondo le modalità definite dall\'<a>Informativa sulla Privacy</a> fintanto che il mio account resterà attivo.',
            verifyAccountTitle: 'Verifica il tuo account',
            verifyAccountText: 'Abbiamo inviato una e-mail con un link di verifica all\'indirizzo {email}. Fai clic sul link per verificare il tuo account e accedi.',
            resendDescription: 'Non hai ricevuto l\'e-mail? Controlla nella tua cartella della Posta indesiderata oppure fai clic su Ripeti invio per ricevere una nuova e-mail.',
            resendLabel: 'Invia di nuovo',
            retryCountdown: 'La ripetizione dell’invio sarà possibile tra {time}',
            SignUpByInvitation: {
                emailDescription: 'Ti registrerai utilizzando l\'indirizzo email qui sopra',
                invitationSucceed: 'Registrazione effettuata con successo. Accedi.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Nome',
                    lastNamePlaceholder: 'Cognome',
                    nameLabel: 'Nome',
                },
            },
        },
        Login: {
            signUpButton: 'Iscriviti',
            emailLabel: 'E-mail',
            passwordLabel: 'Password',
            submitButton: 'Accedi',
            forgotYourPasswordLink: 'Password dimenticata?',
        },
        ResetPassword: {
            title: 'Reimposta password',
            text: 'Inserisci il tuo indirizzo e-mail e ti invieremo un\'e-mail con un link di reimpostazione password.',
            emailLabel: 'E-mail:',
            submitButton: 'Invia',
            successTitle: 'E-mail di reimpostazione password inviata',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Invia di nuovo',
            loginForm: 'Accedi ora',
            retryCountdown: 'La ripetizione dell’invio sarà possibile tra {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros Internazionale GmbH. Tutti i diritti riservati. Plesk e il logo Plesk sono marchi registrati di WebPros International GmbH.',
        followPlesk: 'Segui Plesk:',
        followCPanel: 'Segui cPanel:',
        company: 'Società',
        aboutCPanel: 'Informazioni su cPanel',
        aboutPlesk: 'Informazioni su Plesk',
        legal: 'Legale',
        cookiehub: 'Impostazioni cookie',
        privacyPolicy: 'Informativa sulla privacy',
        impressum: 'Impressum',
        knowledgeBase: 'Base di Conoscenza',
        documentation: 'Documentazione',
        helpCenterCPanel: 'Centro assistenza di cPanel',
        helpCenterPlesk: 'Centro assistenza di Plesk',
        contactUs: 'Contattaci',
        pleskLifecyclePolicy: 'Norme sul ciclo di vita di Plesk',
        community: 'Comunità',
        blogCPanel: 'Blog di cPanel',
        blogPlesk: 'Blog di Plesk',
        forumsCPanel: 'Forum di cPanel',
        forumsPlesk: 'Forum di Plesk',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Campi richiesti',
        submitButton: 'OK',
        cancelButton: 'Annulla',
    },

    FormField: {
      addMore: 'Aggiungine un altro',
      remove: 'Elimina',
      clear: 'Elimina',
    },

    FormFieldPassword: {
        improvePassword: 'Per una password più sicura:',
        passwordTooShort: 'Aggiungi caratteri alla password.',
        lettersLowerCase: 'Usa almeno un carattere minuscolo.',
        lettersUpperCase: 'Usa almeno un carattere maiuscolo.',
        numbers1: 'Usa almeno un numero.',
        numbers3: 'Usa almeno tre numeri.',
        specialChar1: 'Usa almeno un carattere speciale (per esempio: !,@,#,$,%,^,&,*,?,_,~).',
        specialChar2: 'Usa almeno due caratteri speciali (per esempio: !,@,#,$,%,^,&,*,?,_,~).',
        comboUpperAndLower: 'Usa maiuscole e minuscole.',
        comboLettersAndNumbers: 'Usa sia numeri che caratteri.',
        comboLettersNumbersSpecial: 'Usa numeri, lettere e caratteri speciali.',
        yourPasswordIsStrong: 'Puoi renderla ancora più forte aumentando la sua lunghezza.',
        passwordStrength: 'La forza della password è %%strength%%.',
        strengthVeryStrong: 'MOLTO SICURA',
        strengthStrong: 'SICURA',
        strengthVeryWeak: 'MOLTO DEBOLE',
        strengthWeak: 'DEBOLE',
        strengthMedium: 'MEDIO',
        hidePassword: 'Nascondi password',
        showPassword: 'Mostra password',
        generateButton: 'Genera',
        generateButtonHint: 'Genera una password sicura',
    },

    Pagination: {
        totalShort: 'di %%total%%',
        itemsPerPage: 'Elementi per pagina',
        all: 'Tutte',
        total: 'Pagina %%current%% di %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Impossibile trovare elementi corrispondenti al filtro',
        emptyTitle: 'Sembra che non ci sia nulla qui',
        filteredDescription: 'Perfezionare la query o rimuovere alcuni criteri dal filtro e riprovare.',
    },

    Dialog: {
        cancelButton: 'Annulla',
    },
    keyNotUnderOnlineStore: 'Non è possibile eseguire l\'upgrade della licenza tramite Plesk 360 poiché non è stata acquista nel Negozio online Plesk. Se hai acquistato la licenza dal tuo provider di hosting, contattalo per conoscerne le opzioni di upgrade.',
    notOwnLicense: 'Non è possibile eseguire l\'upgrade della licenza tramite Plesk 360 poiché appartiene a un account diverso. Se hai acquistato la licenza dal tuo provider di hosting, contattalo per conoscerne le opzioni di upgrade.',
    subscriptionNotFound: 'Impossibile trovare i dati dell’abbonamento',
    unableToUpgrade: 'L’upgrade non è disponibile. Contatta il supporto di Plesk',
    unknownError: 'Il servizio non è al momento disponibile. Riprova più tardi.',
    pendingOperationFound: 'Impossibile eseguire l’operazione attuale perché quella precedente (ad esempio, la modifica del metodo di pagamento) non è stata completata. Per procedere, completa l’operazione precedente.',

    LinkEmail: {
        title: 'Indirizzo e-mail link',
        tryAgain: 'Riprovare',
    },

    GaOptOutLink: {
        gaSuccess: 'Google Analytics ha interrotto il suo monitoraggio.',
    },

    Servers: {
        sync: 'Sincronizza',
        syncNoSelection: 'Non hai selezionato nessun server da sincronizzare. Vuoi sincronizzarli tutti?',
        syncAll: 'Sincronizzali tutti',
        serverNameColumn: 'Nome di server',
        productColumn: 'Prodotto',
        panelColumn: 'Pannello di controllo Hosting',
        stateColumn: 'Stato server',
        stateLastSync: 'Ultima sincronizzazione {duration}',
        stateLastSyncNow: 'Ultima sincronizzazione: proprio ora',
        login: {
            plesk: 'Vai a Plesk',
            cpanel: 'Vai su cPanel',
            wp: 'Vai a WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{La sincronizzazione del server <b>{title}</b> è stata pianificata.} one{La sincronizzazione di <b>#</b> server è stata pianificata.} other{La sincronizzazione di <b>#</b> server è stata pianificata.}}',
        updateServer: 'Aggiorna {product}',
        cancel: 'Annulla',
        Toolbar: {
            add: 'OK',
            disconnect: 'Disconnetti',
            sync: 'Sincronizza',
            groupSearch: 'Cerca',
        },
        Onboarding: {
            title: 'Qui puoi aggiungere e gestire i tuoi server Plesk',
            description: 'Aggiungi i tuoi server per accedere in un clic al pannello di controllo.',
            docsLink: 'Più info',
            addServersButton: 'Connetti i server',
            feedbackButton: 'Lascia feedback',
            installPleskButton: 'Installa Plesk su un nuovo server',
        },
        FeedbackButton: {
            heading: 'Condividi il tuo feedback',
            subHeading: 'Questo ci sarà d\'aiuto per lo sviluppo futuro.',
        },
        Tour: {
            next: 'Avanti',
            previous: 'Indietro',
            gotit: 'OK',
            step1: {
                title: 'Introduzione dell\'inventario server',
                description: 'Uno strumento per la gestione di più server all\'interno di Plesk 360. Aggiungi i tuoi server Plesk in sicurezza dal menu server o da Plesk.',
            },
            step2: {
                title: 'Introduzione dell\'inventario server',
                description: 'Il menu server contiene anche le liste consultabili di tutti i clienti e i domini ospitati sui tuoi server.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Conferma la connessione del tuo server a "Plesk 360"',
            description: 'Il pannello di controllo di {product} all\'indirizzo <b>{address}</b> verrà connesso all\'account <b>{email}</b> di Plesk 360. Assicurati che questo account sia tuo prima di confermare!',
            accessTitle: 'Cosa potrai fare',
            accessMessage1 : '<b>Plesk 360</b> riceverà l\'accesso amministrativo al pannello di controllo di Plesk su <b>{address}</b>.',
            accessMessage2 : '<b>Plesk 360</b> consentirà all\'account <b>{email}</b> di:',
            accessItem1: 'accedere a Plesk in qualità di amministratore o come utente qualsiasi senza inviare le credenziali;',
            accessItem2: 'recuperare le liste di client e siti ospitati sul server;',
            accessItem3: 'attivare il monitoraggio esterno del server;',
            accessItem4: '(in futuro) eseguire diverse altre azioni.',
            buttons: {
                confirm: 'Conferma',
                confirmAnyway: 'Conferma comunque',
                nextStep: 'Fase successiva',
                deny: 'Nega',
            },
            enableIpRestrictionTitle: 'Dettagli chiave API',
            fields: {
                enableIpRestriction: 'Abilita la restrizione dell\'indirizzo IP della chiave API, IP consentiti:',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'Il server deve essere accessibile da Internet.',
                certificate: 'Il server deve essere protetto con un certificato SSL/TLS valido.',
            },
            success: {
                access: 'L\'installazione di Plesk è accessibile da Internet.',
            },
            ServerAccessError: {
                urlIsRequired: 'Inserisci l\'URL del server.',
                urlMaxLength: "{max, plural, one{L'URL del server non deve essere più lungo di # caratteri.} other{L'URL del server non deve essere più lungo di # caratteri.}}",

                urlInvalid: {
                    title: 'Impossibile usare l\'indirizzo del server fornito.',
                    description: 'Questo di solito accade quando l\'indirizzo contiene "http" anziché "https", o quando nell\'URL è presente un errore di battitura, come la mancanza del dominio di livello superiore (.com, .org, ecc.).',
                },

                urlPrivateIp: {
                    title: 'Purtroppo non possiamo raggiungere il server perché non è disponibile.',
                    description: 'L\'host dell\'indirizzo del server è un indirizzo IP non pubblico.',
                },

                serverAlreadyAdded: 'Questo server è già stato aggiunto. <a>Apri dettagli del server.</a>',

                domainNotResolved: {
                    title: 'Purtroppo non possiamo raggiungere il server perché non è disponibile.',
                    description: 'Il nome del dominio dell\'indirizzo del server non può essere risolto da Plesk 360.',
                },

                domainResolvedPrivateIp: {
                    title: 'Purtroppo non possiamo raggiungere il server perché non è disponibile.',
                    description: 'Assicurati di avere un IP pubblico assegnato a questo dominio.',
                },

                timeout: {
                    title: 'Purtroppo non possiamo raggiungere il server perché non è disponibile.',
                    description: 'Verifica le impostazioni del firewall e consenti la porta specificata da {publicIps}.',
                },

                connectionAborted: {
                    title: 'Purtroppo non possiamo raggiungere il server perché non è disponibile.',
                    description: 'Verifica le impostazioni del firewall e consenti la porta specificata da {publicIps}.',
                },

                connectionRefused: {
                    title: 'La connessione di rete è stata rifiutata.',
                    description: 'Controlla il dominio e la porta specificati.',
                },

                badProtocol: 'L\'indirizzo fornito dovrebbe essere protetto da un certificato SSL valido.',

                notAuthenticated: {
                    title: 'La connessione non è autorizzata dal server remoto.',
                    description: 'Aggiungi di nuovo il server all\'Inventario server.',
                },

                notAuthorized: 'Connessione bloccata.',

                unsupportedServerVersion: {
                    title: 'Impossibile aggiungere il server. Contatta l\'assistenza.',
                    description: 'La versione installata di Plesk è obsoleta e non può essere aggiunta.{minSupportedVersion, select, _ {} other { Il supporto è fornito solo per Plesk Obsidian {minSupportedVersion} e le versioni successive.}} <a>Scopri come aggiornare Plesk.</a>',
                },
                serverMisconfigured: {
                    title: 'Impossibile aggiungere il server. Contatta l\'assistenza.',
                    description: 'Il server non è configurato correttamente e non può essere aggiunto.',
                },
                pleskConfiguration: {
                    title: 'Non possiamo aggiungere questo server. Contatta il supporto.',
                    description: 'La versione attuale di Plesk non è supportata (precedente alla versione {pleskMinVersion}) o la configurazione non è stata eseguita correttamente.',
                },

                unknownError:  {
                    title: 'Impossibile aggiungere il server. Contatta l\'assistenza.',
                    description: 'Errore sconosciuto: {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Come gestire le regole del firewall locale utilizzando Plesk Firewall in Plesk per Linux.',
                    windows: 'Il Firewall Plesk (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'Questa installazione di Plesk ha configurato un certificato scaduto.',
                    description: 'Non è sicuro utilizzare una configurazione Plesk simile e connetterla a Plesk 360. Consigliamo di configurare un certificato autofirmato o firmato da una CA. Cliccando su "Avanti", accetti che questa configurazione non è sicura.',
                },
                certInvalid: {
                    title: 'Il certificato di questo server viene usato con un altro nome di dominio.',
                    description: 'Il certificato è stato rilasciato per un altro nome di dominio, oppure hai inserito l\'indirizzo IP anziché il nome di dominio valido del server. Se hai la certezza di aver fornito l\'URL valido, abbiamo incluso altre informazioni all\'interno di <a>questo articolo</a>.',
                },
                certSelfSigned: {
                    title: 'Il server remoto ha configurato un certificato autofirmato.',
                    description: 'Puoi aggiungere un\'installazione di Plesk a Plesk 360, ma consigliamo caldamente di usare un certificato firmato da una CA, come Let\'s Encrypt, per proteggere il tuo Plesk da possibili problemi di sicurezza.',
                },
                certUntrustedCa: {
                    title: 'La CA del certificato del server remoto non è ritenuta attendibile da Plesk 360.',
                    description: 'Puoi aggiungere un\'installazione di Plesk a Plesk 360, ma consigliamo caldamente di usare un certificato firmato da una CA, come Let\'s Encrypt, per proteggere il tuo Plesk da possibili problemi di sicurezza.',
                },
                agreeInvalidCertCheckbox: 'Accetto di aggiungere questo server senza un certificato valido',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'Vorrei aggiungere un server {hostname} non sicuro al mio account Plesk 360',
            understandSecurityIssues: 'Comprendo che il mio server è vulnerabile a vari problemi di sicurezza di rete',
            understandDataCanLost: 'Comprendo che dovrei aggiungere un certificato valido il prima possibile, altrimenti l\'accesso alla mia installazione di Plesk e ai dati potrebbe andare perso.',
            agreeNotResponsibility: 'Facendo clic su "Collega comunque", accetto che Plesk 360 non sia responsabile della sicurezza del server.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Disconnetti',
        noSelectedServers: 'Seleziona uno o più server da disconnettere.',
        disconnectConfirmation: '{count, plural, =1{Vuoi disconnettere <b>{title}</b>?} one{Vuoi disconnettere <b>#</b> server?} other{Vuoi disconnettere <b>#</b> server?}} Quest\'azione non può essere annullata.',
        notifyDisconnectSuccess: '{count, plural, =1{Il server <b>{title}</b> è stato disconnesso.} one{<b>#</b> server è stato disconnesso.} other{<b>#</b> server sono stati disconnessi.}}',
        notifyDisconnectFail: '{count, plural, =1{Il server <b>{title}</b> non è stato disconnesso.} one{<b>#</b> server non è stato disconnesso.} other{<b>#</b> server non sono stati disconnessi.}}',
    },
    ViewServer: {
        title: 'Dettagli server:',
        inGeneral: 'Panoramica',
        domains: 'Siti web',
        clients: 'Clienti',
        installationLog: 'Registri di installazione',
        address: 'Indirizzo',
        notes: 'Note',
        product: 'Prodotto',
        version: 'Versione',
        unknownVersion: 'sconosciuta',
        showErrorDetail: 'Visualizza altro.',
        allowedInvalidCertificateWarning: 'La connessione al server potrebbe essere non sicura a causa di problemi di convalida del certificato SSL/TLS.',
        allowedInvalidCertificateRisksNote: 'Hai scelto di ignorare il risultato della validazione del certificato e accettato i relativi rischi.',
        allowedInvalidCertificateLinkToDocs: 'Scopri di più su problemi e rischi.',
        certificateErrorStatusMessage: 'La connessione al server è stata disabilitata dopo che è stato rilevato il seguente problema riguardante il certificato SSL/TLS durante la connessione al server {serverName}',
        certificateErrorFixDescription: 'Correggi l\'errore e ricontrolla il certificato per ristabilire la connessione. Se decidi di ignorare il risultato della convalida, accetti <a>tutti i rischi correlati</a>.',
        relatedRisks: 'tutti i rischi correlati',
        ignoreCertificateErrorsButton: 'Ignora la verifica del certificato e ripristina',
        ignoreCertificateConfirmation: 'Confermi di essere a conoscenza e di accettare tutti i rischi associati ai problemi del certificato.',
        confirmAndProceed: 'Conferma e procedi',
        recheckCertificateButton: 'Ricontrolla il certificato',
        recheckResultSuccess: 'La connessione è stata ristabilita con successo.',
        recheckResultCertificateError: 'La verifica del certificato non è riuscita a causa del seguente errore: {errorMessage}',
        recheckResultConnectionError: 'Plesk 360 non è riuscito a stabilire una connessione con {serverName}.',
        recheckResultConnectionBlocked: 'La connessione al server {serverName} è bloccata',
        connectionErrorStatusMessage: 'Impossibile stabilire correttamente una connessione al server {serverName}.',
        connectionErrorFixDescription: 'Correggi l\'errore e fai clic sul pulsante qui sotto per ripristinare la connessione.',
        updatesButton: 'Aggiornamenti di {product}',
        login: {
            plesk: 'Vai a Plesk',
            cpanel: 'Vai su cPanel',
            wp: 'Vai a WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'Il server è configurato in modo che sia impossibile stabilire una connessione. Possibili cause:',
            caseRestrictionManagement: 'Gli indirizzi IP di Platform 360 sono filtrati per l\'accesso dell\'amministratore (<a>istruzioni</a>)',
            casePanelIniApiSection: 'L\'accesso remoto tramite API Plesk è disabilitato in panel.ini (<a>istruzioni</a>)',
            listPublicIps: 'Assicurati che l\'accesso dai seguenti indirizzi IP sia consentito: <b>{publicIps}</b>.',
            fixDescription: 'Per connettere un server con successo, regola le impostazioni di configurazione corrispondenti. Dopo averlo fatto, fai clic sul pulsante qui sotto per verificare la connessione.',
            recheckConnectionButton: 'Verifica nuovamente la connessione',
        },

        recheckConnectionButton: 'Verifica nuovamente la connessione',
        sync: 'Sincronizza',
        state: 'Stato server',
        Domains: {
            name: 'Nome',
            hostingType: 'Tipologia di hosting',
            virtualHostingType: 'Sito web',
            forwardingHostingType: 'Inoltro',
            noneHostingType: 'Nessun hosting',
            mainDomainType: 'Dominio principale',
            subDomainType: 'Sottodominio',
            addonDomainType: 'Dominio aggiuntivo',
            parkedDomainType: 'Alias',
            manage: 'Gestisci',
            manageLinkUnavailableHint: 'Per generare un collegamento diretto al pannello Plesk, è necessario Plesk RESTful API 1.4.0 o una versione successiva. Consulta la documentazione per maggiori dettagli.',
            open: 'Apri',
        },
        Clients: {
            name: 'Nome del contatto',
            email: 'Indirizzo e-mail',
            login: 'Nome utente',
            type: 'Tipo',
            status: 'Stato',
            typeAdmin: 'Admin',
            typeReseller: 'Rivenditore',
            typeCustomer: 'Cliente',
            typeCustomerWithOwner: 'Cliente di {owner}',
            statusActive: 'Attivo',
            statusSuspended: 'Sospeso',
            manage: 'Gestisci',
        },
    },
    InPlaceText: {
        ok: 'Salvare (Ctrl+Enter)',
        cancel: 'Annullare (Esc)',
        edit: 'Modificare',
        add: 'OK',
    },
    ServerLogin: {
        title: 'Reindirizzamento al pannello di controllo all\'indirizzo {address}',
        progress: 'L’operazione può richiedere diversi minuti',
        clientNote: 'Ricorda che i clienti/rivenditori non sono visibili in visualizzazione Power User.',
        errorTitle: 'Si è verificato un errore nel tentativo di connettersi al server',
        errorDescription: 'Accedi direttamente al server tramite l\'indirizzo del server utilizzando le credenziali corrispondenti o prova di nuovo ad autorizzarlo tramite Plesk 360 in un secondo momento.',
        btnGroupLogin: 'Accedi',
        tryAgain: 'Riprovare',
        loginDirectly: 'Accedi direttamente',
        btnGroupCancel: 'Annulla',
        cancel: 'Annulla',
    },
    Team: {
        Layout: {
            title: 'Organizzazione',
            members: 'Utenti',
            invitations: 'Inviti',
        },
        settings: 'Impostazioni',
        Members: {
            email: 'E-mail',
            fullName: 'Nome e cognome',
            mfaStatus: 'Stato MFA',
            tags: 'Segni speciali',
            remove: 'Elimina',
            removeMemberConfirmation: '<b>{name}</b> non avrà più accesso all\'organizzazione e alle risorse condivise al suo interno, ai server, ai siti web e alle licenze.',
            SpecialMarks: {
                you: 'Tu',
                owner: 'Proprietario',
            },
            Onboarding: {
                title: 'Crea la tua organizzazione sulla nostra piattaforma aggiungendo i tuoi colleghi',
                description: 'La nostra piattaforma ti offre funzionalità per una gestione efficiente della tua organizzazione e per la collaborazione tra colleghi. I membri della tua organizzazione possono lavorare con te sui server collegati accedendovi direttamente da qui. Non è più necessario utilizzare password condivise non sicure o complicate modalità di accesso.',
                addMembersButton: 'Aggiungi membri dell\'organizzazione',
            },
            MultifactorStatus: {
                enabled: 'MFA abilitata',
                disabled: 'MFA non abilitata',
            },
        },
        Invitations: {
            email: 'E-mail',
            expireAt: 'Scade il',
            expired: 'Scaduta',
            add: 'OK',
            revoke: 'Revoca',
            Onboarding: {
                title: 'Qui puoi invitare i membri della tua organizzazione',
                description: 'Aggiungi i tuoi colleghi per condividere con loro server e siti web.',
                addButton: 'Invita un nuovo membro',
            },
            ListActions: {
                resendInvitationTooltip: 'Invita di nuovo.',
                successMessage: 'Email d\'invito inviata nuovamente.',
            },
            AddInvitations: {
                title: 'Aggiungi nuovi membri all\'organizzazione',
                infoMessage: 'Invita i membri dell\'organizzazione a entrare inserendo i loro indirizzi email professionali di seguito.',
                infoDescription: 'Ti consigliamo di inserire gli indirizzi email professionali per proteggere i dati personali degli utenti.',
                emailsLabel: 'Indirizzi e-mail per il link di invito',
                submitButton: 'Invia invito',
                successMessage: '{count, plural, =1{L\'invito è stato inviato a {email}.} one{È stato inviato # invito.} other{Sono stati inviati # inviti.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revoca',
                noSelectedInvitations: 'Seleziona uno o più inviti da revocare.',
                revokeInvitationConfirmation: '{count, plural, =1{Vuoi revocare l\'invito a <b>{email}</b>?} one{Vuoi revocare <b>#</b> invito?} other{Vuoi revocare <b>#</b> inviti?}} Quest\'azione non può essere annullata.',
                successRevokeInvitation: '{count, plural, =1{L\'invito a <b>{email}</b> è stato revocato.} one{È stato revocato <b>#</b> invito.} other{Sono stati revocati <b>#</b> inviti.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Aggiornamenti al progetto e comunicazioni commerciali',
            text: 'WebPros International GmbH e le altre società del gruppo WebPros possono conservare ed elaborare i dati che fornisco per offrire contenuti pubblicitari personalizzati come newsletter e offerte individuali in base all\'<a>Informativa sulla privacy</a>. Le offerte mirate possono basarsi su informazioni aggiuntive come i dati sull\'uso e sul comportamento (profilazione). Posso revocare questo consenso in qualsiasi momento all\'interno delle preferenze del progetto.',
        },
    },

    components: {
        List: {
            selectAll: 'Seleziona tutto',
            deselectAll: 'Deseleziona',
            selectedRows: '{selectedRows, plural, one{Hai selezionato # di {totalRows, plural, one{# elemento} other{# elementi}}} other{Hai selezionato # di {totalRows, plural, one{# elemento} other{# elementi}}}}',
            totalRows: '{totalRows, plural, one {# elemento in totale} other {# elementi in totale}}',
            foundRows: '{foundRows, plural, one {# elemento trovato} other {# elementi trovati}}',
        },
        DataList: {
            groupSearch: 'Cerca',
        },
        KbInstructionsFixLink: '<a>Leggi questo articolo della Base di Conoscenza</a> per scoprire come risolvere il problema.',
    },
};