// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer, Heading, Tab, Tabs } from '@plesk/ui-library';
import { useSearchParams, Link, Outlet } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Server } from '@platform360/server-inventory/web/types';
import { useServerViewMonitoring } from '@platform360/monitoring/web/components/ServerViewMonitoring';
import punycode from 'punycode/';
import { ServerViewModel } from '@platform360/server-inventory/web/viewModels/ServerViewModel';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export type ViewServerProps = {
    server: Server;
    isOpen: boolean;
    onClose: () => void;
    location: {
        pathname: string;
    };
};

export const ViewServer = ({ isOpen, onClose, server, location }: ViewServerProps) => {
    const translate = useTranslate('ViewServer');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const [searchParams] = useSearchParams();
    const monitoringPage = useServerViewMonitoring(server, ServerViewModel.isReady(server));
    const generalPage = {
        url: 'general',
        title: translate('inGeneral'),
    };
    const pages = [generalPage];
    const isServerReady = ServerViewModel.isReady(server);

    useEffectOnce(() => {
        const medium = searchParams.get('medium') || undefined;
        const campaign = searchParams.get('campaign') || undefined;

        analyticsEvents.serverViewOpened({
            medium,
            campaign,
        });
    });

    if (isServerReady) {
        pages.push(
            {
                url: 'domains',
                title: translate('domains'),
            },
            {
                url: 'clients',
                title: translate('clients'),
            },
        );
    }

    if (isServerReady && monitoringPage) {
        pages.push(monitoringPage);
    }

    if (server.installationId) {
        pages.push({
            url: 'installation',
            title: translate('installationLog'),
        });
    }

    // TODO Improve activeTab detection
    const activeTab = pages.findIndex(({ url }) => location.pathname.includes(url)) + 1;
    return (
        <Drawer
            data-type="view-server-drawer"
            title={translate('title')}
            subtitle={<Heading level={3}>{punycode.toUnicode(server.address.hostname)}</Heading>}
            tabs={
                <Tabs active={activeTab}>
                    {pages.map(({ url, title }) => (
                        <Tab key={url} title={title} component={Link} to={url} />
                    ))}
                </Tabs>
            }
            onClose={onClose}
            isOpen={isOpen}
        >
            <Outlet />
        </Drawer>
    );
};

export default ViewServer;
