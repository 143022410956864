// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Layout from '@platform360/web-installer/web/components/Layout';
import { longSurveyLink } from '@platform360/web-installer/web/constants';

import styles from './CpanelAutoInstallationFAQ.module.css';
import { useScrollToAnchor } from '@platform360/web-installer/web/hooks/useScrollToAnchor';

const CpanelAutoInstallationFAQ = () => {
    const translate = useTranslate('web-installer.AutoInstallationFAQ.cpanel');

    // Hardcode header height at the moment not to deal with dynamic offset evaluation.
    const headerHeight = 40;
    useScrollToAnchor(headerHeight);

    return (
        <Layout showUnauthorizedHeader>
            <div className={styles.header}>{translate('header')}</div>
            <div className={styles.container}>
                <h1 className={styles.paragraphHeader} id="versions">
                    {translate('versionsAndComponents')}
                </h1>

                <ul>
                    <li>
                        <div>{translate('whatVersionIsInstalledTitle')}</div>
                        <p>
                            {translate('whatVersionIsInstalledText', {
                                link: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://support.cpanel.net/hc/en-us/articles/1500001216582-What-operating-systems-are-supported-by-cPanel"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                            })}
                        </p>
                    </li>
                    <li>
                        <div>{translate('canICustomizeParametersOfInstallationTitle')}</div>
                        <p>
                            {translate('canICustomizeParametersOfInstallationText', {
                                link: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://docs.cpanel.net/installation-guide/customize-your-installation/"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                            })}
                        </p>
                    </li>
                    <li>
                        <div>{translate('doesInstalledCpanelHaveLicenseTitle')}</div>
                        <p>
                            {translate('doesInstalledCpanelHaveLicenseText', {
                                link: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://docs.cpanel.net/knowledge-base/cpanel-product/how-to-sign-up-for-a-trial-license/"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                            })}
                        </p>
                    </li>
                </ul>

                <h1 className={styles.paragraphHeader} id="issues">
                    {translate('knownIssues')}
                </h1>
                <ul>
                    <li>
                        <div>{translate('supportedOSsTitle')}</div>
                        <p>
                            {translate('supportedOSsText', {
                                link: (chunk) => (
                                    <a
                                        target="_blank"
                                        href="https://support.cpanel.net/hc/en-us/articles/1500001216582-What-operating-systems-are-supported-by-cPanel"
                                        rel="noreferrer"
                                    >
                                        {chunk}
                                    </a>
                                ),
                            })}
                        </p>
                    </li>
                    <li>
                        <div>{translate('usePrivateKeyTitle')}</div>
                        <p>{translate('usePrivateKeyText')}</p>
                    </li>
                    <li>
                        <div>{translate('isItSafeToSubmitPasswordTitle')}</div>
                        <p>{translate('isItSafeToSubmitPasswordText')}</p>
                    </li>
                </ul>
                <div className={styles.survey}>
                    <Icon name="list-check-mark" intent="info" size="32" />
                    <div>
                        {translate('helpUsMakeWebInstallerBetter', {
                            a: (chunk) => (
                                <a href={longSurveyLink.cpanel} rel="noreferrer" target="_blank">
                                    {chunk}
                                </a>
                            ),
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CpanelAutoInstallationFAQ;
