// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import {
    ApiListResponse,
    ApiResponse,
    FetcherOptions,
    FilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';
import { normalizeDomain } from './domains';
import { normalizeClient } from './clients';
import { DateTime } from 'luxon';
import parseRequestResultType from '@platform360/server-inventory/web/helpers/parseRequestResultType';
import { Server, DomainResponse, ClientResponse } from '@platform360/server-inventory/web/types';
import {
    ServerResponse,
    PatchServerRequest,
} from '@platform360/server-inventory/shared/api-contract';
import { LoginLinkParams } from '@platform360/server-inventory/shared/login-link-params';

export const normalizeServer = ({
    lastRequestResultDetail,
    ...server
}: ServerResponse): Server => ({
    ...server,
    address: new URL(server.address),
    syncFinishedAt:
        server.syncFinishedAt === null
            ? null
            : DateTime.fromISO(server.syncFinishedAt).setZone('UTC'),
    nextSyncAt: DateTime.fromISO(server.nextSyncAt).setZone('UTC'),
    lastRequestResultType: parseRequestResultType(
        server.lastRequestResultType,
        lastRequestResultDetail,
    ),
    lastRequestResultUpdatedAt: DateTime.fromISO(server.lastRequestResultUpdatedAt).setZone('UTC'),
});

export type AddingServerPayload = {
    address: string;
    panelChallenge?: string;
    product: string;
    enableIpRestriction?: boolean;
    allowUnsecureConnection?: boolean;
};

export type AddingServerResponse = {
    url: string;
    jwt: string;
};

export const addingServer = async (payload: AddingServerPayload) => {
    const { data } = await apiClient.post<ApiResponse<AddingServerResponse>>(
        '/si/servers/redirect-url',
        payload,
    );
    return data.data;
};

type GetServerOptions = FetcherOptions<{
    id: number;
}>;

export const getServer = async ({ variables, signal }: GetServerOptions) => {
    const { data } = await apiClient.get<ApiResponse<ServerResponse>>(
        `/si/servers/${variables.id}`,
        {
            signal,
        },
    );
    return normalizeServer(data.data);
};

export type GetServersResponse = ApiListResponse<ServerResponse>;

type GetServersOptions = FetcherOptions<PaginatedQuery & FilterQuery>;

export const getServers = async ({ variables, signal }: GetServersOptions) => {
    const { data } = await apiClient.get<GetServersResponse>('/si/servers', {
        params: variables,
        signal,
    });

    return {
        ...data,
        data: data.data.map(normalizeServer),
    };
};

export const updateServer = async (serverId: number, payload: PatchServerRequest) => {
    const { data } = await apiClient.patch<ApiResponse<ServerResponse>>(
        `/si/servers/${serverId}`,
        payload,
    );
    return normalizeServer(data.data);
};

export const forceSyncServer = async (serverId: number) => {
    const { data } = await apiClient.post<ApiResponse<ServerResponse>>(
        `/si/servers/${serverId}/force-sync`,
    );
    return normalizeServer(data.data);
};

export const checkConnectionServer = async (serverId: number) => {
    const { data } = await apiClient.post<ApiResponse<ServerResponse>>(
        `/si/servers/${serverId}/check-connection`,
    );
    return normalizeServer(data.data);
};

export const removeServer = async (serverId: number) => {
    await apiClient.delete(`/si/servers/${serverId}`);
};

type GetServerLoginLinkOptions = FetcherOptions<LoginLinkParams>;

export const getServerLoginLink = async ({ variables, signal }: GetServerLoginLinkOptions) => {
    const { target, serverId, ...params } = variables;
    const { data } = await apiClient.get<ApiResponse<string>>(
        `/si/servers/${serverId}/login/${target}`,
        { params, signal },
    );
    return data.data;
};

type GetDomainsOptions = FetcherOptions<
    {
        serverId: number;
    } & PaginatedQuery &
        FilterQuery
>;

export const getDomains = async ({
    variables: { serverId, ...params },
    signal,
}: GetDomainsOptions) => {
    const { data } = await apiClient.get<ApiListResponse<DomainResponse>>(
        `/si/servers/${serverId}/domains`,
        { params, signal },
    );
    return {
        ...data,
        data: data.data.map(normalizeDomain),
    };
};

type GetClientsOptions = FetcherOptions<
    {
        serverId: number;
    } & PaginatedQuery &
        FilterQuery
>;

export const getClients = async ({
    variables: { serverId, ...params },
    signal,
}: GetClientsOptions) => {
    const { data } = await apiClient.get<ApiListResponse<ClientResponse>>(
        `/si/servers/${serverId}/clients`,
        { params, signal },
    );
    return {
        ...data,
        data: data.data.map(normalizeClient),
    };
};

export const startPleskTrial = async (email: string) => {
    await apiClient.post('/si/servers/start-plesk-trial', {
        email,
    });
};
