// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps, IconProps } from '@plesk/ui-library';

export type CarouselButtonProps = {
    to: string;
    children: ReactNode;
    isExternal?: boolean;
    icon?: IconProps;
} & Omit<ButtonProps<typeof Link>, 'icon'>;

const CarouselButton = ({ to, isExternal, children, ...props }: CarouselButtonProps) => (
    <Button component={Link} target={isExternal ? '_blank' : '_self'} to={to} size="lg" {...props}>
        {children}
    </Button>
);

export default CarouselButton;
