// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Heading, IconName } from '@plesk/ui-library';

import styles from './Section.module.css';

type SectionProps = {
    title: ReactNode;
    icon?: IconName;
    children: ReactNode;
};

export const Section = ({ title, icon, children }: SectionProps) => (
    <div className={styles.section}>
        <Heading level={5} icon={icon} className={styles.heading}>
            {title}
        </Heading>
        {children}
    </div>
);
