// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment, useState } from 'react';
import { TeamInvitation } from '@platform360/accounts/web/api/team';
import List from '@platform360/libs/shared-web/components/List';
import { Link } from 'react-router-dom';
import AddInvitations from './AddInvitations';
import withRouteBinding from '@platform360/libs/shared-web/helpers/withRouteBinding';
import RevokeInvitationsOperation from './RevokeInvitationsOperation';
import ListActions from './ListActions';
import Onboarding from './Onboarding';
import { Text, ContentLoader, Toolbar, ToolbarGroup, Button, Label } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { DateTime } from 'luxon';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';

const RoutableAddInvitations = withRouteBinding(AddInvitations);

export type InvitationsProps = {
    isLoading: boolean;
    invitations: TeamInvitation[];
    onInvitationRevoke: (id: number) => Promise<number>;
};

const Invitations = ({
    isLoading,
    invitations,
    onInvitationRevoke,
    ...props
}: InvitationsProps) => {
    const currentTime = DateTime.local();
    const translate = useTranslate('Team.Invitations');
    const formatDateTime = useFormatDateTime();
    const [selection, setSelection] = useState<number[]>([]);
    const [revokeLoading, setRevokeLoading] = useState(false);
    const handleRevoke = async () => {
        setRevokeLoading(true);
        await Promise.all(selection.map(onInvitationRevoke));
        setRevokeLoading(false);
    };

    return (
        <Fragment>
            {isLoading ? (
                <ContentLoader />
            ) : (
                <List<TeamInvitation>
                    columns={[
                        {
                            key: 'userEmail',
                            type: 'title',
                            title: translate('email'),
                            render: ({ userEmail }) => <Text bold>{userEmail}</Text>,
                        },
                        {
                            key: 'expireAt',
                            title: translate('expireAt'),
                            render: ({ expireAt }) => {
                                if (expireAt > currentTime) {
                                    return <Text>{formatDateTime(expireAt, 'yyyy-LL-dd')}</Text>;
                                }
                                return <Label intent="danger">{translate('expired')}</Label>;
                            },
                        },
                        {
                            key: 'actions',
                            type: 'actions',
                            render: ({ id }) => <ListActions id={id} />,
                        },
                    ]}
                    toolbar={
                        <Toolbar>
                            <ToolbarGroup title={translate('add')}>
                                <Button
                                    component={Link}
                                    to={'/organization/invitations/add'}
                                    data-type="add-invitations-button"
                                    intent="primary"
                                    icon="plus"
                                />
                            </ToolbarGroup>
                            <ToolbarGroup title={translate('revoke')}>
                                <RevokeInvitationsOperation
                                    selectedEmail={
                                        invitations.find(
                                            (invitation) => invitation.id === selection[0],
                                        )?.userEmail || ''
                                    }
                                    totalSelected={selection.length}
                                    onRevoke={handleRevoke}
                                    loading={revokeLoading}
                                />
                            </ToolbarGroup>
                        </Toolbar>
                    }
                    data={invitations}
                    data-type="team-invitations-list"
                    emptyView={<Onboarding />}
                    selection={selection}
                    onSelectionChange={setSelection}
                    {...props}
                />
            )}
            <RoutableAddInvitations openPath="add" closePath="/organization/invitations" />
        </Fragment>
    );
};

export default Invitations;
