// Copyright 2024. WebPros International GmbH. All rights reserved.

import { getInstallation } from '@platform360/web-installer/web/api/web-installer';
import { createQuery } from '@platform360/libs/shared-web/query';
import { useQuery } from '@tanstack/react-query';

export type UseInstallationVariables = {
    id: string;
};

export type UseInstallationData = PromiseValue<ReturnType<typeof getInstallation>>;

const useInstallation = createQuery<UseInstallationVariables, UseInstallationData>({
    queryName: 'web-installer/useInstallation',
    fetcher: getInstallation,
    useQuery: (options) =>
        useQuery({
            ...options,
            refetchInterval: 10000,
        }),
});

export default useInstallation;
