// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { deleteUser } from '@platform360/admin-panel/web/api/users';
import { useErrorToast } from './helpers/useErrorToast';

type UseDeleteMutationOptions = {
    onSuccess: () => void;
};

export const useDeleteUserMutation = ({ onSuccess }: UseDeleteMutationOptions) =>
    useMutation({
        mutationFn: deleteUser,
        onError: useErrorToast,
        onSuccess,
    });
