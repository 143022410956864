// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';

type GetRedirectUrlParams = {
    variables: {
        params: Record<string, string>;
    };
};

export type GetRedirectUrlResponse = {
    redirectUrl: string;
};

export const getRedirectUrl = async ({ variables }: GetRedirectUrlParams) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<GetRedirectUrlResponse>>('/redirect-gateway', {
        params: variables.params,
    });
    return data;
};
